/* eslint-disable react-hooks/exhaustive-deps */
import { captureException } from '@sentry/react';
import { debounce } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useAuth } from '@/pages/auth/context/AuthProvider';
import { conversationSearch } from '@/shared/api/conversations';
import {
  ConversationSortTypes,
  ConversationsSearchV2ApiResponse,
  SearchV2Type,
  WhippyQueryLanguage,
  WhippyQueryLanguageFilterSchema,
  WhippyQueryLanguageSortSchema,
} from '@/shared/types/conversations';

import { useConversation } from '../context/ConversationContext';
import { mergeConversations } from '../context/ConversationReducer';
import { ConversationsTabsContent, TabProps } from './';
import {
  EmptyConversationPreview,
  LoadingConversationPreview,
} from './ConversationPreview';
import { generateSort } from './ConversationSearchSort';
import { ErrorConversation } from './ErrorConversation';
import { generateSearchClauses } from './utils';
import { VirtualisedConversationsList } from './VirtualisedConversationsList';

export function FilteredMessages(props: TabProps) {
  const { filter, tab } = props;
  const { searchV2 } = useConversation();

  const auth = useAuth();

  // State for search results, loading status, and pagination offset
  const [searchedMessages, setSearchedMessages] =
    useState<ConversationsSearchV2ApiResponse>({
      data: [],
      total: 0,
    });
  const [isLoading, setIsLoading] = useState(false);
  const [offset, setOffset] = useState(0);
  const [error, setError] = useState({ hasError: false, error: '' });

  // Searches conversations based on input value, filter, and pagination offset
  const searchConversations = async (searchV2: SearchV2Type, offset: number) => {
    try {
      const userId = auth?.tokens?.user_id ?? null;
      const additionalFilters = generateSearchClauses(userId, filter);

      const conversationFilter: WhippyQueryLanguageFilterSchema = {
        resource: 'message',
        column: 'body',
        comparison: 'ilike',
        value: `${searchV2.inputValue}%`,
        or: [
          {
            resource: 'message',
            column: 'body',
            comparison: 'ilike',
            value: `%${searchV2.inputValue}%`,
          },
        ],
        and: [...additionalFilters],
      };

      const storedSort = localStorage.getItem(
        'conversations-search-sort'
      ) as ConversationSortTypes;
      const sortFilter: WhippyQueryLanguageSortSchema[] =
        searchV2.sort && searchV2.sort.length > 0
          ? searchV2.sort
          : generateSort(storedSort);

      const conversationPayload: WhippyQueryLanguage = {
        filter: [conversationFilter],
        limit: 30,
        offset,
        ...(sortFilter && sortFilter[0].column != 'relevance'
          ? { sort: sortFilter }
          : {}),
      };

      const conversationResults = await conversationSearch(conversationPayload);
      setSearchedMessages((previousConversations) => {
        const merged = mergeConversations(
          previousConversations.data,
          conversationResults.data
        );

        return {
          data: merged,
          total: previousConversations.total + conversationResults.total,
        } as ConversationsSearchV2ApiResponse;
      });
      setIsLoading(false);
    } catch (error) {
      const errorMessage =
        error?.response?.data?.errors?.[0]?.description ?? 'Failed to search messages';
      setIsLoading(false);
      setError({ hasError: true, error: errorMessage });
      captureException(error, {
        tags: {
          area: 'inboxSearch',
          feature: 'messages',
        },
      });
    }
  };

  // Debounce search to reduce API calls during typing
  const debounceConversationSearch = useCallback(
    debounce(async (searchV2: SearchV2Type, offset: number) => {
      searchConversations(searchV2, offset);
    }, 350),
    [filter, setIsLoading]
  );

  // Trigger search on input change or filter/tab change
  useEffect(() => {
    if (searchV2.inputValue) {
      // Immediately set loading state to true for instant feedback
      setIsLoading(true);
      // Clear the previous search results
      setSearchedMessages({
        data: [],
        total: 0,
      });
      setOffset(0);
      // Debounce the search
      debounceConversationSearch(searchV2, 0);
    } else {
      // If input is cleared, stop loading state and don't search
      setIsLoading(false);
    }
  }, [searchV2.inputValue, filter, searchV2.sort]);

  // Trigger search on pagination offset change (without debouncing & side effects)
  useEffect(() => {
    if (offset === 0) return;
    searchConversations(searchV2, offset);
  }, [offset]);

  // get the conversation id from the url
  const conversation_id = useParams<{ id?: string }>();

  return (
    <ConversationsTabsContent value="filtered:messages">
      {isLoading && <LoadingConversationPreview />}
      {error.hasError && <ErrorConversation errorMessage={error?.error} />}
      {!isLoading && searchedMessages.total > 0 && (
        <VirtualisedConversationsList
          conversations={searchedMessages.data}
          length={searchedMessages.total}
          setOffset={setOffset}
          offset={offset}
          slug={conversation_id}
          filter={filter}
          tab={tab}
          searchType="messages"
        />
      )}
      {!isLoading && searchedMessages.total === 0 && <EmptyConversationPreview />}
    </ConversationsTabsContent>
  );
}
