import React from 'react';
import { HiCheck, HiOutlineMailOpen } from 'react-icons/hi';

import {
  IconButton,
  Tooltip,
  TooltipArrow,
  TooltipContent,
  TooltipTrigger,
} from '@/shared/ui';

import { useConversation } from '../../context/ConversationContext';

type ConversationStatusProps = {
  /** should we navigate in the inbox when closing a conversation - false in the drawer component */
  closeAndNavigate?: boolean;
  /** should we navigate in the inbox when opening a conversation - false in the drawer component */
  openAndNavigate?: boolean;
};

export function ConversationStatus(props: ConversationStatusProps): JSX.Element {
  const { closeAndNavigate, openAndNavigate } = props;
  const { conversationState, handleMarkedClosed, handleMarkedOpen } = useConversation();
  const { current } = conversationState;

  return (
    <>
      {current?.status === 'open' ? (
        <Tooltip>
          <TooltipTrigger asChild>
            <IconButton
              data-intercom-target="close-conversation-button"
              onClick={() => handleMarkedClosed(closeAndNavigate)}
              size={2}
            >
              <HiCheck />
            </IconButton>
          </TooltipTrigger>
          <TooltipContent>
            Close Conversation
            <TooltipArrow />
          </TooltipContent>
        </Tooltip>
      ) : (
        <Tooltip>
          <TooltipTrigger asChild>
            <IconButton onClick={() => handleMarkedOpen(openAndNavigate)} size={2}>
              <HiOutlineMailOpen size={15} />
            </IconButton>
          </TooltipTrigger>
          <TooltipContent>
            Open Conversation
            <TooltipArrow />
          </TooltipContent>
        </Tooltip>
      )}
    </>
  );
}
