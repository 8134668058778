import dayjs from 'dayjs';
import React from 'react';

import { CampaignStatus, ScheduleOptions } from '@/shared/types/campaigns';
import { Badge, Flex, Tooltip, TooltipContent, TooltipTrigger } from '@/shared/ui';

type CampaignStatusBadgeProps = {
  status: string;
  scheduleParams: ScheduleOptions | null;
};

const CampaignStatusBadge = (props: CampaignStatusBadgeProps) => {
  const { status, scheduleParams } = props;

  const getBadgeVariant = (status: string) => {
    if (status === CampaignStatus.DRAFT) {
      return 'gray';
    } else if (status === CampaignStatus.SCHEDULED) {
      return 'purple';
    } else if (status === CampaignStatus.IN_PROGRESS) {
      return 'blue';
    } else if (status === CampaignStatus.COMPLETE) {
      return 'green';
    } else if (status === CampaignStatus.ARCHIVED) {
      return 'yellow';
    } else if (status === CampaignStatus.PAUSED) {
      return 'orange';
    } else if (status === CampaignStatus.QUEUED) {
      return 'gray';
    } else if (status === CampaignStatus.FAILED) {
      return 'red';
    } else {
      return 'gray';
    }
  };

  const variant = getBadgeVariant(status);

  let scheduleTimeString = '';
  let scheduleString = '';
  if (scheduleParams) {
    const { month, day, year, hour, minute, timezone } = scheduleParams;
    scheduleTimeString = `${dayjs(`${month} ${day} ${year} ${hour}:${minute}`).format(
      'dddd, MMM DD, hh:mm A'
    )} - ${timezone}`;
    scheduleString = `${dayjs(`${month} ${day} ${year} ${hour}:${minute}`).format(
      'MMM D, hh:mm a'
    )}`;
  }

  return (
    <Flex justify="start" css={{ width: 60 }}>
      <Badge variant={variant} css={{ textTransform: 'capitalize' }}>
        {status === CampaignStatus.DRAFT && 'Draft'}
        {status === CampaignStatus.SCHEDULED && (
          <Tooltip>
            <TooltipTrigger>Scheduled - {scheduleString}</TooltipTrigger>
            <TooltipContent>{scheduleTimeString}</TooltipContent>
          </Tooltip>
        )}
        {status === CampaignStatus.IN_PROGRESS && 'In Progress'}
        {status === CampaignStatus.COMPLETE && 'Complete'}
        {status === CampaignStatus.ARCHIVED && 'Archived'}
        {status === CampaignStatus.PAUSED && 'Paused'}
        {status === CampaignStatus.QUEUED && 'Queued'}
        {status === CampaignStatus.FAILED && 'Failed'}
      </Badge>
    </Flex>
  );
};

export default CampaignStatusBadge;
