/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { HiSortDescending } from 'react-icons/hi';

import {
  ConversationSortTypes,
  WhippyQueryLanguageSortSchema,
} from '@/shared/types/conversations';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  IconButton,
} from '@/shared/ui';

import { useConversation } from '../context/ConversationContext';

// Generate sort schema based on the selected sort type
export const generateSort = (
  sortBy: ConversationSortTypes
): WhippyQueryLanguageSortSchema[] => {
  return sortBy === ConversationSortTypes.RELEVANCE
    ? [
        {
          column: 'relevance',
          order: 'desc',
          resource: 'message',
        },
      ]
    : [
        {
          column: 'inserted_at',
          order: sortBy === ConversationSortTypes.NEWEST ? 'desc' : 'asc',
          resource: 'message',
        },
      ];
};

export const ConversationSearchSort = () => {
  const conversations = useConversation();
  const { searchV2, setSearchV2 } = conversations;

  const [sort, setSort] = useState<ConversationSortTypes>(
    ConversationSortTypes.RELEVANCE
  );

  // Handle sort option click
  const onSortClick = (sortBy: ConversationSortTypes) => {
    localStorage.setItem('conversations-search-sort', sortBy);
    const sort = generateSort(sortBy);

    const newState = {
      ...searchV2, // Merge existing search state
      sort,
    };

    setSearchV2(newState);
    setSort(sortBy);
  };

  // Load saved sort preference on component mount
  useEffect(() => {
    setSort(
      (localStorage.getItem('conversations-search-sort') as ConversationSortTypes) ||
        ConversationSortTypes.NEWEST
    );
  }, []);

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <IconButton size="2" variant="outline">
          <HiSortDescending />
        </IconButton>
      </DropdownMenuTrigger>
      <DropdownMenuContent sideOffset={2}>
        <DropdownMenuItem
          onClick={() => onSortClick(ConversationSortTypes.NEWEST)}
          css={{ fontWeight: sort === ConversationSortTypes.NEWEST ? 600 : 400 }}
        >
          <span>Newest</span>
        </DropdownMenuItem>
        <DropdownMenuItem
          onClick={() => onSortClick(ConversationSortTypes.OLDEST)}
          css={{ fontWeight: sort === ConversationSortTypes.OLDEST ? 600 : 400 }}
        >
          <span>Oldest</span>
        </DropdownMenuItem>
        <DropdownMenuItem
          onClick={() => onSortClick(ConversationSortTypes.RELEVANCE)}
          css={{ fontWeight: sort === ConversationSortTypes.RELEVANCE ? 600 : 400 }}
        >
          <span>Relevance</span>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
