/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { HiX } from 'react-icons/hi';

import { useChannels } from '@/pages/settings/organization/channels/context/ChannelContext';
import { MultiSelect } from '@/shared/components/MultiSelect';
import { Channel } from '@/shared/types/channels';
import {
  Box,
  Button,
  Dialog,
  DialogClose,
  DialogCloseIcon,
  DialogContent,
  DialogFooter,
  DialogOverlay,
  DialogPortal,
  DialogTitle,
  DialogTrigger,
  Flex,
  Input,
  Label,
  VStack,
} from '@/shared/ui';

import { useUploads } from '../contacts/uploads/context/UploadContext';

type CreateUploadProps = {
  open: boolean;
  setOpen: (value: boolean) => void;
  children: React.ReactNode;
  onUpload: () => void;
};

export const CreateUpload = (props: CreateUploadProps) => {
  const { children, open, setOpen, onUpload } = props;
  const { uploadName, setUploadName, setUploadLocations } = useUploads();

  const locationsContext = useChannels();
  const { channelsState } = locationsContext;

  const locations = channelsState.allChannels || [];

  const [uploadNameLength, setUploadNameLength] = React.useState(0);
  const [selectedLocations, setSelectedLocations] = React.useState({
    locations: locations.map((location) => location.id) || [],
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUploadName(e.target.value);
    setUploadNameLength(e.target.value.length);
  };

  const closeDialog = (setLocationsToDefault: boolean) => {
    setOpen ? setOpen(false) : null;
    setLocationsToDefault
      ? setSelectedLocations({
          locations: locations.map((location) => location.id) || [],
        })
      : null;
  };

  useEffect(() => {
    setUploadLocations(selectedLocations.locations);
  }, [selectedLocations.locations]);

  return (
    <>
      <Dialog open={open} modal={false}>
        <DialogTrigger asChild>
          <Box onClick={() => setOpen(true)}>{children}</Box>
        </DialogTrigger>
        <DialogPortal>
          <DialogOverlay as="div">
            <DialogContent
              onEscapeKeyDown={() => closeDialog(true)}
              onPointerDownOutside={() => closeDialog(true)}
            >
              <DialogTitle variant="bold">Create New Contact List</DialogTitle>
              <VStack gap={6}>
                <VStack gap={3}>
                  <Box>
                    <Label htmlFor="name">
                      <Flex>
                        Name Contact List{' '}
                        <Box css={{ color: 'red', ml: 5 }}>
                          {uploadNameLength > 28
                            ? '(max 30 characters)'
                            : '(name must be unique)'}
                        </Box>
                      </Flex>
                    </Label>
                    <Input
                      id="name"
                      name="name"
                      type="text"
                      value={uploadName}
                      maxLength={29}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleChange(e)
                      }
                    />
                  </Box>
                  <Box>
                    <Label htmlFor="locations">
                      <Flex>
                        Opt in to{' '}
                        <Box css={{ color: 'red', ml: 5 }}>
                          {selectedLocations.locations.length < 1
                            ? '(at least one channel is required)'
                            : ''}
                        </Box>
                      </Flex>
                    </Label>
                    {locations.length > 0 && (
                      <MultiSelect
                        defaultPlaceholder="Channels"
                        defaultSelectedItems={selectedLocations.locations}
                        isDropdown={true}
                        options={locations.map((location: Channel) => ({
                          type: location?.name || '',
                          value: location.id,
                        }))}
                        parentSelectedItems={selectedLocations}
                        setParentSelectedItems={setSelectedLocations}
                        isCampaigns={true}
                      />
                    )}
                  </Box>
                  <DialogFooter justify="end">
                    <DialogClose asChild>
                      <Button
                        type="button"
                        variant="gray"
                        css={{ mr: 10 }}
                        onClick={() => closeDialog(true)}
                      >
                        Close
                      </Button>
                    </DialogClose>
                    <DialogClose asChild>
                      <Button
                        onClick={() => {
                          onUpload ? onUpload() : null;
                          closeDialog(false);
                        }}
                      >
                        Create Upload
                      </Button>
                    </DialogClose>
                  </DialogFooter>
                </VStack>
              </VStack>
              <DialogClose asChild>
                <DialogCloseIcon onClick={() => closeDialog(true)} size="2">
                  <HiX size="15px" />
                </DialogCloseIcon>
              </DialogClose>
            </DialogContent>
          </DialogOverlay>
        </DialogPortal>
      </Dialog>
    </>
  );
};
