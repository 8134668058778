import { FilterType } from '@/shared/types/filter';

import {
  relativeBirthDateOptions,
  relativeDateOptions,
} from '../utils/relativeDateOptions';
import { ValueCombobox } from './Combobox';

type DateValueProps = {
  onChange: (field: keyof FilterType, value: FilterType[keyof FilterType]) => void;
  value: string | number | null;
  selectorStyles?: { [key: string]: any };
};

export const BirthDateValue = (props: DateValueProps): JSX.Element => {
  const { onChange, value, selectorStyles } = props;

  const getSelectedValue = () => {
    const option = {
      label: relativeDateOptions.find((op) => op.value === value)?.label as string,
      value: value ?? '',
    };
    return option;
  };

  return (
    <ValueCombobox
      selectorStyles={selectorStyles}
      selectLabel="Select a value"
      options={relativeBirthDateOptions.filter((o) => o.value !== 'date')}
      selected={getSelectedValue()}
      onSelect={(option) => onChange('value', option.value)}
    />
  );
};
