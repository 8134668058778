/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from 'react';
import { useClickAway } from 'react-use';

import { OpeningHour } from '@/shared/types';

import {
  Box,
  ScrollAreaCorner,
  ScrollAreaScrollbar,
  ScrollAreaThumb,
  ScrollAreaViewport,
} from '../../ui';
import { TimeItem, TimePickerInput, TimePickerScrollArea } from './TimePicker';
import { convertTime12to24 } from './utils/convertTime12to24';
import { getOpeningHours } from './utils/getOpeningHours';

type OpeningHoursTimePickerProps = {
  // Indicates if the timepicker is for opening or closing time
  opening?: boolean;
  // The opening hour for the day
  openingHour: OpeningHour;
  // Function to call when the timepicker value changes
  request: (openingHour: OpeningHour) => void;
  // The value of the timepicker
  value: string;
  // Indicates if the shop is open 24hours
  open24Hours?: boolean;
  // Indicates if the timepicker is disabled
  state?: boolean;
};

// Task: Ability to add 12 AM as closing time in business hours
export const OpeningHoursTimePicker = (props: OpeningHoursTimePickerProps) => {
  const { openingHour, request, value, open24Hours, state } = props;

  const [time, setTime] = useState(getOpeningHours()[0]);
  const [open, setOpen] = useState(false);

  // Define a function that updates the time state whenever the component props change
  useEffect(() => {
    // If the location is open 24 hours, set the time to 12:00 AM.
    if (open24Hours) {
      setTime('12:00 AM');
      return;
    }

    // Extract the hours and minutes from the "value" prop and convert them to numbers.
    const [hours, minutes] = props.value.split(':').map(Number);

    // Calculate the formatted hours and determine whether the time is in the AM or PM.
    const formattedHours = hours % 12 || 12;
    const amPm = hours < 12 ? 'AM' : 'PM';

    // Format the time string with the calculated hours, minutes, and AM/PM value.
    setTime(`${formattedHours}:${minutes < 10 ? '0' : ''}${minutes} ${amPm}`);
  }, [open24Hours, value, time]);

  const toggleSelect = () => {
    setOpen(!open);
  };

  const onSelect = (time: string) => {
    // Set the "time" state to the selected time and close the time picker.
    setTime(time);
    setOpen(false);

    // Extract the AM/PM value from the selected time.
    const ampm = time.split(' ')[1];

    // Convert the selected time to 24-hour format.
    const makeTime24 = convertTime12to24(time);

    // Split the 24-hour time into hours and minutes.
    const splitTime = makeTime24.split(':');

    // If the selected time is 12:xx AM, change the hour to 00.
    if (splitTime[0] === '12' && ampm === 'AM') {
      splitTime[0] = '00';
    }

    // Join the hours and minutes back together into a formatted time string.
    const formattedTime = splitTime.join(':');

    // If the location is currently open, update the opening time.
    if (props.opening) {
      request({ ...openingHour, ...{ opens_at: formattedTime } });
    }
    // Otherwise, update the closing time.
    else {
      request({ ...openingHour, ...{ closes_at: formattedTime } });
    }
  };

  const on24HoursSelect = () => {
    request({
      ...openingHour,
      ...{ opens_at: '00:00', closes_at: '00:00' },
    });
    setOpen(false);
  };

  const ref = useRef(null);

  useClickAway(ref, () => {
    toggleSelect();
  });

  return (
    <Box css={{ position: 'relative' }}>
      <TimePickerInput
        css={{
          maxWidth: '85px',
          color: state ? 'black' : 'lightgray',
        }}
        value={open24Hours ? '12:00 AM' : time}
        onClick={toggleSelect}
      />
      {open && (
        <TimePickerScrollArea
          ref={ref}
          css={{
            mt:
              openingHour.weekday === 'Friday' ||
              openingHour.weekday === 'Saturday' ||
              openingHour.weekday === 'Sunday'
                ? '-260px'
                : '5px',
          }}
        >
          <ScrollAreaViewport css={{ backgroundColor: 'white' }}>
            <Box css={{ p: '5px 0' }}>
              <TimeItem onClick={on24HoursSelect}>24 Hours</TimeItem>
              {getOpeningHours().map((time: string, index: number) => (
                <TimeItem key={`time-${index}`} onClick={() => onSelect(time)}>
                  {time}
                </TimeItem>
              ))}
            </Box>
          </ScrollAreaViewport>
          <ScrollAreaScrollbar orientation="vertical">
            <ScrollAreaThumb />
          </ScrollAreaScrollbar>
          <ScrollAreaScrollbar orientation="horizontal">
            <ScrollAreaThumb />
          </ScrollAreaScrollbar>
          <ScrollAreaCorner />
        </TimePickerScrollArea>
      )}
    </Box>
  );
};
