/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { captureException } from '@sentry/react';
import React, { useEffect, useRef, useState } from 'react';
import { HiExternalLink } from 'react-icons/hi';
import { toast } from 'sonner';

import { useAuth } from '@/auth/context/AuthProvider';
import { MessageEditorWrapper } from '@/shared/components/editor/Styles';
import { MessageEditorV2 } from '@/shared/components/editor/v2';
import { SingleSelect } from '@/shared/components/SingleSelect';
import { Signature } from '@/shared/types';
import { NewConversationParamsType } from '@/shared/types/conversations';
import { Box, Flex, HStack, IconButton } from '@/shared/ui';
import { toE164 } from '@/shared/utils/validations/validations';

import { useConversation } from '../inbox/context/ConversationContext';
import { AssignConversationModal } from '../inbox/conversation/assign/AssignConversationModal';
import { ConversationEditor } from '../inbox/conversation/editor/ConversationEditor';
import { ConversationHeaderContainer } from '../inbox/conversation/header';
import { ConversationHeaderContact } from '../inbox/conversation/header';
import { ConversationStatus } from '../inbox/conversation/header/ConversationStatus';
import { ConversationItems } from '../inbox/conversation/items';
import { useChannels } from '../settings/organization/channels/context/ChannelContext';

type ConversationDrawerProps = {
  /* contact uuid */
  contact_id: string;
  /* contact name */
  contact_name: string;
  /* contact phone */
  contact_phone: string;
  /* location uuid */
  location_id: string;
};

export const Conversation = (props: ConversationDrawerProps) => {
  const { contact_id, contact_name, contact_phone, location_id } = props;

  const auth = useAuth();

  const conversationsContext = useConversation();
  const {
    getConversationByLocationAndContact,
    conversationState,
    getOrCreateConversation,
    getConversationMessages,
    scheduleNewConversationMessage,
  } = conversationsContext;
  const { current } = conversationState;

  const { channelsState, getChannelById } = useChannels();
  const { channels } = channelsState;

  const [locationId, setLocationId] = useState<string>(location_id);
  const [signature, setSignature] = useState<Signature | null>(null);

  const [message, setMessage] = useState('');

  useEffect(() => {
    if (contact_id && location_id) {
      getConversationByLocationAndContact(contact_id, location_id);
    }
  }, [contact_id, location_id]);

  const handleChangeLocation = (new_location_id: string) => {
    setLocationId(new_location_id);
    getConversationByLocationAndContact(contact_id, new_location_id);
  };

  const [attachments, setAttachments] = useState<{
    attachment_urls: string[];
  }>({
    attachment_urls: [],
  });

  const startNewConversation = () => {
    try {
      if (!locationId) return toast.error('Please select a location');
      if (!message) return toast.error('Please enter a message');

      const messagePayload: NewConversationParamsType['message'] = {
        body: message,
        attachment_urls: attachments.attachment_urls || [],
      };

      if (signature) {
        messagePayload.body += `\n${signature.body}`;
      }

      getOrCreateConversation({
        contact: {
          name: props.contact_name,
          phone: toE164(props.contact_phone),
        },
        location_id: locationId,
        message: messagePayload,
      });
    } catch (error) {
      captureException(error, {
        tags: { action: 'start_conversation_message_drawer', feature: 'message_editor' },
      });
      toast.error('Failed to send message');
    }
  };

  const [scheduleParams, setScheduleParams] = useState({
    day: '',
    month: '',
    year: '',
    hour: '',
    minute: '',
    timezone: '',
  });

  const sendScheduledMessage = () => {
    if (!message && attachments.attachment_urls.length === 0) return;

    scheduleNewConversationMessage({
      contact: {
        name: props.contact_name,
        phone: toE164(props.contact_phone),
      },
      location_id: locationId,
      scheduled_message: {
        params: {
          message: {
            body: message.trim(),
            attachment_urls: attachments.attachment_urls || [],
          },
          user_id: Number(auth?.tokens?.user_id),
          organization_id: String(auth?.tokens?.organization_id),
        },
        schedule_options: scheduleParams,
        target: 'message',
      },
    });

    setMessage('');
    setAttachments({
      attachment_urls: [],
    });
  };

  const conversation_items =
    conversationState.current?.conversationItemsPage?.conversationItems || [];

  const length = conversation_items?.length || 0;

  // ref to keep track of scroll position
  const virtuoso = useRef();

  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const [attachmentLoading, setAttachmentLoading] = useState<boolean>(false);

  return (
    <Flex
      css={{
        flex: 1,
        height: 'calc(100vh - 80px)',
        top: 58,
        bottom: 0,
        position: 'fixed',
        width: '100%',
      }}
    >
      <Flex
        direction="column"
        css={{ width: '100%', position: 'relative', height: '100%' }}
      >
        <ConversationHeaderContainer justify="between" align="center">
          <Flex>
            {current && current.id ? (
              <ConversationHeaderContact
                conversation={current}
                channel={getChannelById(locationId)}
              />
            ) : (
              <ConversationHeaderContact
                conversation={{
                  contact: { name: contact_name, phone: contact_phone, email: '' },
                }}
                channel={getChannelById(locationId)}
              />
            )}
          </Flex>
          <Flex>
            <HStack gap={4}>
              <AssignConversationModal isSmall />
              {current?.id && (
                <IconButton
                  size={2}
                  onClick={() =>
                    window.open(`/inbox/all/${current?.status}/${current?.id}`)
                  }
                >
                  <HiExternalLink size={18} />
                </IconButton>
              )}
              {/* we have to set these props false to prevent navigating to the inbox from the drawer */}
              {current?.id && (
                <ConversationStatus closeAndNavigate={false} openAndNavigate={false} />
              )}
            </HStack>
          </Flex>
        </ConversationHeaderContainer>
        {channels.length > 1 && (
          <ConversationHeaderContainer align="center">
            <Box css={{ width: '100%' }}>
              <SingleSelect
                defaultPlaceholder={
                  getChannelById(locationId)?.name || 'Select a channel'
                }
                isDropdown={true}
                selectItem={locationId}
                setSelectItem={handleChangeLocation}
                options={channels.map((location) => ({
                  type: location?.name || location?.address || '',
                  value: location.id,
                }))}
              />
            </Box>
          </ConversationHeaderContainer>
        )}
        <ConversationItems
          current={current}
          getConversationMessages={getConversationMessages}
          virtuoso={virtuoso}
        />
        <MessageEditorWrapper>
          {current ? (
            <ConversationEditor />
          ) : (
            <MessageEditorV2
              message={message}
              setMessage={setMessage}
              textareaRef={textareaRef}
              attachments={attachments}
              setAttachments={setAttachments}
              showAddTemplate={true}
              showAddAttachment={true}
              showAddReview={true}
              showAddEmoji={true}
              showAddTranslate={true}
              showAddSchedule={true}
              showAddVariable={true}
              showAddSignature={true}
              showCharacterCount={true}
              showSendButton={true}
              showAddNote={true}
              showShortcuts={true}
              setScheduleParams={setScheduleParams}
              scheduleParams={scheduleParams}
              signature={signature}
              setSignature={setSignature}
              contact={{
                name: props.contact_name,
                phone: toE164(props.contact_phone),
              }}
              location={getChannelById(locationId)}
              enableAttachments={true}
              showKeyboardSendShortcut={true}
              scheduleSendAction={sendScheduledMessage}
              sendMessageAction={startNewConversation}
              showAutoComplete={true}
              attachmentLoading={attachmentLoading}
              setAttachmentLoading={setAttachmentLoading}
              isInbox
            />
          )}
        </MessageEditorWrapper>
      </Flex>
    </Flex>
  );
};
