import React from 'react';
import { HiX } from 'react-icons/hi';

import { SideNavigationHeading } from '@/inbox/list/ConversationsFilter';
import {
  PanelContainer,
  PanelContentContainer,
  PanelHeaderContainer,
} from '@/pages/inbox/conversation/panels';
import { Audience } from '@/shared/types/audience';
import {
  AudienceContacts,
  CampaignExcludeContacts,
  PanelType,
} from '@/shared/types/campaigns';
import { CustomScrollbar, Flex, IconButton } from '@/shared/ui';

import { AudiencePanel } from './Audience';
import { ExcludedContactsPanel } from './ExcludedContacts';
import { HelpPanel } from './Help';
import { PreviewMessagePanel } from './PreviewMessage';

type CampaignPanelProps = {
  /* open or closed the panel */
  setShowPanel: (showPanel: boolean) => void;
  /* current view */
  panel: PanelType;
  /* calculating new audience? */
  loadingAudience?: boolean;
  /* included audience contacts */
  audience?: AudienceContacts | Array<null>;
  /* audience object */
  audience_params?: Audience;
  /* excluded contacts */
  excluded_audience?: AudienceContacts | Array<null>;
  /* excluded audience object */
  excluded_audience_params?: CampaignExcludeContacts;
  /* message attachments */
  attachments: Array<any>;
  /* message body */
  body: string;
  /* the message body with pipe fields filled */
  preview: string;
  /* add contact id to preselected exclusion */
  handleExclude?: (id: string) => void;
  /* editor instance */
  editor?: any;
};

export const CampaignPanel = (props: CampaignPanelProps): JSX.Element => {
  const {
    setShowPanel,
    panel,
    audience_params,
    audience,
    excluded_audience,
    excluded_audience_params,
    attachments,
    preview,
    loadingAudience,
    handleExclude,
  } = props;

  const renderPanel = (panelType: string): JSX.Element => {
    switch (panelType) {
      case PanelType.VIEW_AUDIENCE:
        return (
          <AudiencePanel
            loadingAudience={loadingAudience}
            audience={audience}
            audience_params={audience_params}
            handleExclude={handleExclude}
          />
        );
      case PanelType.VIEW_EXCLUDED_AUDIENCE:
        return (
          <ExcludedContactsPanel
            loadingAudience={loadingAudience}
            excluded_audience={excluded_audience}
            excluded_audience_params={excluded_audience_params}
          />
        );
      case PanelType.VIEW_PREVIEW:
        return <PreviewMessagePanel attachment_urls={attachments} body={preview} />;
      case PanelType.VIEW_HELP:
        return <HelpPanel />;
      default:
        return <Flex></Flex>;
    }
  };

  const renderPanelTitle = (panelType: PanelType): string => {
    switch (panelType) {
      case PanelType.VIEW_AUDIENCE:
        return 'Audience';
      case PanelType.VIEW_EXCLUDED_AUDIENCE:
        return 'Excluded Contacts';
      case PanelType.VIEW_PREVIEW:
        return 'Preview Message';
      case PanelType.VIEW_HELP:
        return 'Help';
      default:
        return 'Audience';
    }
  };

  return (
    <PanelContainer direction="column" css={{ height: '100%' }}>
      <PanelHeaderContainer align="center" justify="between">
        <SideNavigationHeading css={{ textTransform: 'capitalize' }}>
          {renderPanelTitle(props.panel)}
        </SideNavigationHeading>
        <IconButton size={2} onClick={() => setShowPanel(false)}>
          <HiX size={16} />
        </IconButton>
      </PanelHeaderContainer>
      <PanelContentContainer css={{ minHeight: '100%', minWidth: '100%' }}>
        <CustomScrollbar>
          <Flex
            align="center"
            justify="center"
            css={{ minHeight: '100%', minWidth: '100%', overflowY: 'scroll', pb: 100 }}
          >
            {renderPanel(panel)}
          </Flex>
        </CustomScrollbar>
      </PanelContentContainer>
    </PanelContainer>
  );
};
