import React, { memo, useCallback } from 'react';
import { HiDotsHorizontal } from 'react-icons/hi';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { ConfirmationDialog } from '@/shared/components/ConfirmationDialog';
import { TeamMember } from '@/shared/types/team';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  IconButton,
} from '@/shared/ui';

type TeamMemberNavigationButtonProps = {
  /* the current team id */
  teamId: string;
  /* the team member item */
  teamMember: TeamMember;
  /* the function to be called when the delete button is clicked */
  onDelete: (id: string) => void;
};

export const TeamMemberNavigationButton = memo(
  ({ teamId, teamMember, onDelete }: TeamMemberNavigationButtonProps) => {
    const history = useHistory();
    const isTeamMemberPage = useRouteMatch('/settings/teams/:team_id/team_members/:id');

    const onEditTeamMember = useCallback(() => {
      history.push(`/settings/teams/${teamId}/team_members/${teamMember.id}`);
    }, [teamId, teamMember.id]);

    const handleDeleteTeamMember = useCallback(() => {
      teamMember?.id && onDelete(teamMember?.id);
      isTeamMemberPage && history.goBack();
    }, [onDelete, teamMember?.id]);

    return (
      <DropdownMenu>
        <DropdownMenuTrigger asChild data-testid="team-dropdown-trigger">
          <IconButton aria-label="open team dropdown" variant="outline" size="2">
            <HiDotsHorizontal size="15px" />
          </IconButton>
        </DropdownMenuTrigger>
        <DropdownMenuContent sideOffset={10}>
          <DropdownMenuItem onClick={onEditTeamMember}>Edit Schedule</DropdownMenuItem>
          <ConfirmationDialog
            title="Delete Team Member"
            description="Are you sure want to delete your team member? This action cannot be undone."
            confirmButtonTitle="Yes, Delete Team Member"
            onConfirm={handleDeleteTeamMember}
          >
            <DropdownMenuItem onClick={(e) => e.preventDefault()}>
              Delete
            </DropdownMenuItem>
          </ConfirmationDialog>
        </DropdownMenuContent>
      </DropdownMenu>
    );
  }
);
