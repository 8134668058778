import { useState } from 'react';
import { Cell, Pie, PieChart, ResponsiveContainer, Sector, Tooltip } from 'recharts';

import { Box, Flex } from '@/shared/ui';

import { ChartTooltipValue, CustomTooltipContentWrapper } from './Chart';
import { COLORS } from './DevicesSection';

type DeviceChartProps = {
  data: any;
};

const CustomChartTooltip = ({ active, payload }: any) => {
  // write a function that formats a value with a comma separator if it's a number in the thousands
  const formatToolTipValue = (tickItem: number) => {
    if (tickItem >= 1000) {
      return tickItem?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
    return tickItem;
  };

  if (active && payload) {
    return (
      <CustomTooltipContentWrapper direction="column" justify="between">
        {payload.map((item: any) => (
          <>
            <Box
              css={{
                pb: 5,
                textTransform: 'capitalize',
              }}
            >
              {item.name}
            </Box>
            <Flex key={item.dataKey} direction="row" align="center">
              <Box>Total clicks:</Box>
              <ChartTooltipValue>
                {formatToolTipValue(Math.round(item.value))}
              </ChartTooltipValue>
            </Flex>
          </>
        ))}
      </CustomTooltipContentWrapper>
    );
  }
  return null;
};

const renderActiveShape = (props: any) => {
  const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill } = props;

  return (
    <g>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius}
        outerRadius={outerRadius + 12}
        fill={fill}
      />
    </g>
  );
};

export const DeviceChart = ({ data }: DeviceChartProps) => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const onMouseEnter = (_: any, index: number) => {
    setActiveIndex(index);
  };

  const onMouseLeave = () => {
    setActiveIndex(null);
  };

  return (
    <ResponsiveContainer width="100%" height="100%">
      <PieChart width={400} height={300}>
        <Pie
          data={data}
          dataKey="count"
          nameKey="key"
          cx="50%"
          cy="50%"
          innerRadius={74}
          outerRadius={120}
          fill="#EA5757"
          paddingAngle={0}
          stroke="none"
          activeIndex={activeIndex || undefined}
          activeShape={renderActiveShape}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          {data?.map((item: any, index: number) => {
            const deviceColor = COLORS.find(
              (c: { device: string; color: string }) => c.device === item.key
            );
            return <Cell key={`cell-${index}`} fill={deviceColor?.color} />;
          })}
        </Pie>
        <Tooltip content={CustomChartTooltip} />
      </PieChart>
    </ResponsiveContainer>
  );
};
