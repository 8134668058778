import { debounce } from 'lodash';
import { useEffect, useState } from 'react';

import * as API from '@/shared/api/contacts/uploads';

import { ValueCombobox } from './Combobox';

type ContactListValuesComboboxProps = {
  selected: { label?: string; value: string | number } | null | undefined;
  onSelect: (option: { label: string; value: string | number }) => void;
  selectLabel?: string;
  searchLabel?: string;
  selectorStyles?: { [key: string]: any };
  isOpen?: boolean;
  disabled?: boolean;
};

export const ContactListValuesCombobox = (
  props: ContactListValuesComboboxProps
): JSX.Element => {
  const { selected, onSelect, selectLabel, searchLabel } = props;

  const [searchValue, setSearchValue] = useState('');
  const [listOptions, setListOptions] = useState<{ label: string; value: string }[]>([]);

  const handleSearchValue = (value: string) => {
    setSearchValue(value);
    debounceSearchTags(value);
  };

  const searchLists = async (value: string) => {
    if (value) {
      const tags = await API.searchUpload(value);
      const options = tags.map((tag: { name: string; id: string }) => ({
        label: tag.name,
        value: tag.id,
      }));
      // merge with existing options
      setListOptions((prevOptions) => {
        const newOptions = options.filter(
          (option: { value: string }) =>
            !prevOptions.some((prevOption) => prevOption.value === option.value)
        );
        return [...prevOptions, ...newOptions];
      });
    }
  };

  const debounceSearchTags = debounce(searchLists, 500);

  const getLists = async () => {
    const lists = await API.getUploads({ offset: 0, limit: 10 });
    const uploads = Array.isArray(lists) ? lists : lists.uploads;
    const options = uploads.map((tag: { name: string; id: string }) => ({
      label: tag.name,
      value: tag.id,
    }));
    setListOptions(options);
  };

  useEffect(() => {
    getLists();
  }, []);

  return (
    <ValueCombobox
      disabled={props.disabled}
      isOpen={props.isOpen}
      selectorStyles={props.selectorStyles}
      options={listOptions}
      selected={{ label: selected?.label || '', value: selected?.value || '' }}
      onSelect={onSelect}
      searchValue={searchValue}
      handleSearchValue={handleSearchValue}
      selectLabel={selectLabel}
      searchLabel={searchLabel}
    />
  );
};
