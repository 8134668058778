import { styled } from '@stitches/react';
import { useKBar } from 'kbar';
import { forwardRef } from 'react';

import { HighlightText } from '@/pages/inbox/list/utils';
import { Box, Flex } from '@/shared/ui';

import { useSearch } from '../SearchContext';
import { SearchResults } from '../utils';

export const SequenceItem = forwardRef(
  (
    {
      action,
      active,
    }: {
      action: SearchResults;
      active: boolean;
    },
    ref: React.Ref<HTMLDivElement>
  ) => {
    const { highlightWords } = useSearch();
    const { query } = useKBar();

    const sequence = action?.sequence;
    if (!sequence) return;

    const sequenceName = sequence?.title ?? '';
    const contactsInProgress = sequence?.contact_tabs?.pending ?? 0;

    return (
      <StyledCampaignItem
        active={active}
        ref={ref}
        onClick={() => {
          action?.perform();
          query.toggle();
        }}
      >
        <Flex direction="column" css={{ width: '100%' }}>
          <NameContainer align="center" css={{ width: '100%' }}>
            <CampaignName align="center">
              <HighlightText
                highlightStyle={{ fontWeight: 500, backgroundColor: '#FFC106' }}
                sentence={sequenceName}
                wordsToHighlight={highlightWords}
                sensitivity={2}
              />
            </CampaignName>

            <CampaignEdited>
              {contactsInProgress} contact{contactsInProgress > 1 ? 's' : ''} in progress
            </CampaignEdited>
          </NameContainer>
          <Flex justify="between" css={{ fontSize: 13 }}>
            <Flex align="center" css={{ width: 'calc(100% - 47px)' }}>
              <Box
                css={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  width: '80%',
                }}
              >
                {sequence.created_by && sequence.created_by.name}
                {sequence.created_by && '- '}
                {sequence.steps && sequence.steps.length} steps
              </Box>
            </Flex>
          </Flex>
        </Flex>
      </StyledCampaignItem>
    );
  }
);

export const StyledCampaignItem = styled('div', {
  padding: '12px 16px',
  background: 'transparent',
  borderLeft: '2px solid transparent',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  cursor: 'pointer',
  '&:hover': {
    background: '$gray1',
    backgroundColor: '$gray3',
  },
  variants: {
    active: {
      true: {
        background: '$gray1',
        borderLeftColor: '$foreground',
        backgroundColor: '$gray3',
      },
    },
  },
});

const CampaignEdited = styled('div', {
  marginLeft: 'auto',
  fontSize: 11,
});

const NameContainer = styled(Flex, {
  gap: 10,
  alignItems: 'center',
});

const CampaignName = styled(Flex, {
  overflow: 'hidden',
  fontWeight: 500,
  fontSize: 13,
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  maxWidth: 400,
});
