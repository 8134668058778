import { Box, Flex, Skeleton, VStack } from '@/shared/ui';

import { StyledDate, StyledMessageItem } from '../items/messages';

export const MessageSkeleton = ({ quantity = 10 }: { quantity?: number }) => {
  return (
    <>
      {[...Array(quantity)].map((_, index) => (
        <StyledMessageItem key={index}>
          <VStack gap={1} css={{ width: '100%' }}>
            <Flex css={{ width: '100%' }} align="center" justify="between">
              <StyledDate>
                <Skeleton key={index} variant="tag" css={{ height: 10, width: 100 }} />
              </StyledDate>
              <Skeleton
                key={index}
                variant="tag"
                css={{ height: 10, width: 60, marginLeft: 'auto' }}
              />
            </Flex>
            <Box css={{ fontSize: 13 }}>
              <Skeleton key={index} variant="tag" css={{ height: 20, width: 300 }} />
            </Box>
          </VStack>
        </StyledMessageItem>
      ))}
    </>
  );
};
