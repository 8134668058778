/* eslint-disable react-hooks/exhaustive-deps */
import { Formik, useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import * as Yup from 'yup';

import { ActionDescription } from '@/pages/automation/ActionDescription';
import { KeywordFormValuesType } from '@/pages/automation/keywords/EditKeywordForm';
import { ActionRenderer } from '@/shared/components/triggers';
import {
  ActionWrapper,
  getActionColor,
  getActionIcon,
  getActionTitle,
} from '@/shared/components/triggers/actions/ActionWrapper';
import { AddAction } from '@/shared/components/triggers/actions/AddAction';
import { LinkClickTriggerType } from '@/shared/components/triggers/AddTrigger';
import { Action } from '@/shared/types/triggers/actions';
import { Button, Flex, HStack, Label, VStack } from '@/shared/ui';

import { AutomationTriggerContainer } from './KeywordAutomations';

type LinkClickAutomationsProps = {
  /* array of link click triggers */
  link_click_triggers: Array<LinkClickTriggerType>;
  /* function to update a link click trigger */
  updateTrigger: any;
  /* function to delete a link click trigger */
  deleteTrigger: (trigger_id: string) => void;
  variant?: 'campaign' | 'template';
};

export const LinkClickAutomations = (props: LinkClickAutomationsProps) => {
  const { link_click_triggers, updateTrigger, deleteTrigger, variant } = props;
  return (
    <VStack gap={4}>
      {link_click_triggers &&
        link_click_triggers?.map(
          (link_click_trigger: LinkClickTriggerType, i: number) => (
            <AutomationTriggerContainer key={i} variant={variant || 'campaign'}>
              <LinkClickTriggerForm
                link_click_trigger={link_click_trigger}
                updateTrigger={updateTrigger}
                deleteTrigger={deleteTrigger}
              />
            </AutomationTriggerContainer>
          )
        )}
    </VStack>
  );
};

type LinkClickTriggerFormProps = {
  link_click_trigger: LinkClickTriggerType;
  updateTrigger: any;
  deleteTrigger: (trigger_id: string) => void;
};

export const LinkClickTriggerForm = (props: LinkClickTriggerFormProps) => {
  const { link_click_trigger, deleteTrigger, updateTrigger } = props;

  const [initialValues, setInitialValues] = useState({
    actions: link_click_trigger?.actions || [],
  });

  useEffect(() => {
    if (link_click_trigger) {
      setInitialValues({
        actions: link_click_trigger?.actions || [],
      });
    }

    return () => {
      setInitialValues({
        actions: [],
      });
    };
  }, [props.link_click_trigger]);

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={Yup.object({
        actions: Yup.array().test(
          'len',
          'Required',
          (arr) => Array.isArray(arr) && arr.length > 0
        ),
      })}
      onSubmit={async (values) => {
        try {
          const params = {
            id: link_click_trigger.id,
            type: link_click_trigger.type,
            actions: values.actions,
          };
          updateTrigger(link_click_trigger.id, params);
        } catch (e) {
          console.log(e);
        }
      }}
    >
      {(formik) => (
        <form onSubmit={formik.handleSubmit}>
          <LinkClickTriggerFormFields
            setInitialValues={setInitialValues}
            link_click_trigger={link_click_trigger}
            deleteTrigger={deleteTrigger}
          />
        </form>
      )}
    </Formik>
  );
};

type LinkClickTriggerFormFieldsProps = {
  setInitialValues: any;
  link_click_trigger: LinkClickTriggerType;
  deleteTrigger: (trigger_id: string) => void;
};

export const LinkClickTriggerFormFields = (props: LinkClickTriggerFormFieldsProps) => {
  const { setInitialValues, link_click_trigger, deleteTrigger } = props;
  const formik = useFormikContext();
  const values = formik.values as KeywordFormValuesType;

  const addAction = (action: Action) => {
    // take the action and merge in a tempId key with a uuid v4 value
    const newAction = {
      ...action,
      tempId: uuidv4(),
    };

    if (link_click_trigger && values.actions) {
      const newActions = [...values.actions, newAction];
      setInitialValues({
        ...values,
        actions: newActions,
      });
    }
  };

  const removeAction = (action: Action) => {
    if (action.id) {
      const newActions = values.actions?.filter((a) => a.id !== action.id);
      setInitialValues({
        ...values,
        actions: newActions,
      });
    } else {
      const newActions = values.actions?.filter((a) => a.tempId !== action.tempId);
      setInitialValues({
        ...values,
        actions: newActions,
      });
    }
  };

  const handleDeleteTrigger = () => {
    if (link_click_trigger.id) {
      deleteTrigger(link_click_trigger.id);
    }
  };

  return (
    <VStack gap={2}>
      <Label>When a contact clicks link in the message then:</Label>
      {values.actions.map((action, index) => (
        <ActionWrapper
          key={index}
          title={`${getActionTitle(action)}` || ''}
          description={ActionDescription(action) || ''}
          removeAction={removeAction as any}
          action={action}
          saveAction={formik.handleSubmit}
          actionColor={`${getActionColor(action)}` || '#2C7675'}
          actionIcon={getActionIcon(action) || ''}
        >
          <ActionRenderer
            key={index}
            index={index}
            action={action}
            removeAction={removeAction}
          />
        </ActionWrapper>
      ))}
      <Flex css={{ mt: 20 }}>
        <HStack>
          <Button type="submit">Save Automation</Button>
          <AddAction addAction={addAction} />
          <Button type="button" variant="red" onClick={() => handleDeleteTrigger()}>
            Delete Automation
          </Button>
        </HStack>
      </Flex>
    </VStack>
  );
};
