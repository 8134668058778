export const WEEK = 'week';
export const MONTH = 'month';
export const YEAR = 'year';
export const DAY = 'day';
// Format of the date 'Aug 4'
export const DATE_MONTH_DAY_FORMAT = 'MMM D';
// Format of the date 'Aug 4 2022'
export const DATE_MONTH_DAY_YEAR_FORMAT = 'MMM D YYYY';

export const granularity_options = [
  {
    type: 'Day',
    value: 'day',
  },
  {
    type: 'Week',
    value: 'week',
  },
  {
    type: 'Month',
    value: 'month',
  },
  {
    type: 'Year',
    value: 'year',
  },
];
