import { ConversationScheduledMessageType } from '@/shared/types/conversations';

import {
  DELETE_SCHEDULED_MESSAGE,
  EDIT_SCHEDULED_MESSAGE,
  GET_PART_OF_SCHEDULED_MESSAGES,
  GET_SCHEDULED_MESSAGES,
  SEND_SCHEDULED_MESSAGE_NOW,
  SET_CURRENT,
  SET_LOADING,
} from './types';

type ScheduledMessagesAction =
  | { type: 'GET_SCHEDULED_MESSAGES'; payload: any }
  | { type: 'GET_PART_OF_SCHEDULED_MESSAGES'; payload: any }
  | { type: 'EDIT_SCHEDULED_MESSAGE'; payload: any }
  | { type: 'SEND_SCHEDULED_MESSAGE_NOW'; payload: any }
  | { type: 'DELETE_SCHEDULED_MESSAGE'; payload: any }
  | { type: 'SET_CURRENT'; payload: any }
  | { type: 'SET_LOADING'; payload: boolean };

const ScheduledMessagesReducer = (state: any, action: ScheduledMessagesAction) => {
  switch (action.type) {
    case GET_SCHEDULED_MESSAGES:
      return {
        ...state,
        scheduledMessages: action.payload,
        loading: false,
      };
    case SEND_SCHEDULED_MESSAGE_NOW:
      return {
        ...state,
        scheduledMessages: state.scheduledMessages.filter(
          (scheduledMessage: ConversationScheduledMessageType) => {
            return scheduledMessage.job_id !== action.payload;
          }
        ),
      };
    case GET_PART_OF_SCHEDULED_MESSAGES:
      return {
        ...state,
        scheduledMessages: action.payload,
        loading: false,
      };
    case EDIT_SCHEDULED_MESSAGE:
      return {
        ...state,
        scheduledMessages: state.scheduledMessages.map(
          (scheduledMessage: ConversationScheduledMessageType) => {
            return scheduledMessage.job_id === action.payload.job_id
              ? action.payload
              : scheduledMessage;
          }
        ),
      };
    case DELETE_SCHEDULED_MESSAGE:
      return {
        ...state,
        scheduledMessages: state.scheduledMessages.filter(
          (scheduledMessage: ConversationScheduledMessageType) => {
            return scheduledMessage.job_id !== action.payload;
          }
        ),
      };
    case SET_CURRENT:
      return {
        ...state,
        current: action.payload,
      };
    case SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
};

export default ScheduledMessagesReducer;
