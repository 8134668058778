/* eslint-disable react-hooks/exhaustive-deps */
import { Formik, useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { HiKey } from 'react-icons/hi';
import { v4 as uuidv4 } from 'uuid';
import * as Yup from 'yup';

import { ActionDescription } from '@/pages/automation/ActionDescription';
import { KeywordDescription } from '@/pages/automation/KeywordDescription';
import { KeywordFormValuesType } from '@/pages/automation/keywords/EditKeywordForm';
import { FormFieldWrapper, RadioInput } from '@/shared/components/forms';
import { KeywordBuilder } from '@/shared/components/forms/KeywordBuilder';
import { ActionRenderer } from '@/shared/components/triggers';
import {
  ActionWrapper,
  getActionColor,
  getActionIcon,
  getActionTitle,
} from '@/shared/components/triggers/actions/ActionWrapper';
import { AddAction } from '@/shared/components/triggers/actions/AddAction';
import { Action } from '@/shared/types/triggers/actions';
import { Box, Button, Flex, HStack, VStack } from '@/shared/ui';
import { styled } from '@/stitches.config';

type KeywordAutomationsProps = {
  keyword_triggers: any;
  updateTrigger: any;
  deleteTrigger: (trigger_id: string) => void;
  variant?: 'campaign' | 'template';
};

export const KeywordAutomations = (props: KeywordAutomationsProps) => {
  const { keyword_triggers, updateTrigger, deleteTrigger, variant } = props;
  return (
    <VStack gap={4}>
      {keyword_triggers &&
        keyword_triggers?.map((keyword_trigger: any, i: number) => (
          <AutomationTriggerContainer key={i} variant={variant || 'campaign'}>
            <KeywordForm
              keyword_trigger={keyword_trigger}
              updateTrigger={updateTrigger}
              deleteTrigger={deleteTrigger}
            />
          </AutomationTriggerContainer>
        ))}
    </VStack>
  );
};

type KeywordTriggerFormProps = {
  keyword_trigger: any;
  updateTrigger: any;
  deleteTrigger: (trigger_id: string) => void;
};

export const KeywordForm = (props: KeywordTriggerFormProps) => {
  const { keyword_trigger, deleteTrigger, updateTrigger } = props;

  const [initialValues, setInitialValues] = useState<KeywordFormValuesType>({
    keywords: keyword_trigger?.keyword?.keywords || [''],
    negate_keywords: keyword_trigger?.keyword?.negate_keywords || [''],
    exact_match: keyword_trigger?.keyword?.exact_match.toString() as string,
    actions: keyword_trigger?.actions || [],
  });

  useEffect(() => {
    if (keyword_trigger) {
      setInitialValues({
        keywords: keyword_trigger?.keyword?.keywords || [''],
        negate_keywords: keyword_trigger?.keyword?.negate_keywords || [''],
        exact_match: keyword_trigger?.keyword?.exact_match.toString() || 'false',
        actions: keyword_trigger?.actions || [],
      });
    }

    return () => {
      setInitialValues({
        keywords: [''],
        negate_keywords: [''],
        exact_match: null,
        actions: [],
      });
    };
  }, [props.keyword_trigger]);

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={Yup.object({
        keywords: Yup.array().test(
          'len',
          'Required',
          (arr) => Array.isArray(arr) && arr.length > 0
        ),
        negate_keywords: Yup.array(),
        actions: Yup.array().test(
          'len',
          'Required',
          (arr) => Array.isArray(arr) && arr.length > 0
        ),
      })}
      onSubmit={async (values) => {
        try {
          const params = {
            id: keyword_trigger.id,
            type: keyword_trigger.type,
            keyword: {
              keywords: values.keywords,
              negate_keywords: values.negate_keywords,
              exact_match: values.exact_match,
            },
            actions: values.actions,
          };

          updateTrigger(keyword_trigger.id, params);
        } catch (e) {
          console.log(e);
        }
      }}
    >
      {(formik) => (
        <form onSubmit={formik.handleSubmit}>
          <KeywordFromFields
            setInitialValues={setInitialValues}
            keyword_trigger={keyword_trigger}
            deleteTrigger={deleteTrigger}
          />
        </form>
      )}
    </Formik>
  );
};

type KeywordTriggerFromFieldsProps = {
  setInitialValues: any;
  keyword_trigger: any;
  deleteTrigger: (trigger_id: string) => void;
};

export const KeywordFromFields = (props: KeywordTriggerFromFieldsProps) => {
  const { setInitialValues, keyword_trigger, deleteTrigger } = props;
  const formik = useFormikContext();
  const values = formik.values as KeywordFormValuesType;

  const addAction = (action: Action) => {
    // take the action and merge in a tempId key with a uuid v4 value
    const newAction: Action = {
      ...action,
      tempId: uuidv4(),
    };

    if (keyword_trigger && values.actions) {
      const newActions = [...values.actions, newAction];
      setInitialValues({
        ...values,
        actions: newActions,
      });
    }
  };

  const removeAction = (action: Action) => {
    if (action.id) {
      const newActions = values.actions?.filter((a) => a.id !== action.id);
      setInitialValues({
        ...values,
        actions: newActions,
      });
    } else {
      const newActions = values.actions?.filter((a) => a.tempId !== action.tempId);
      setInitialValues({
        ...values,
        actions: newActions,
      });
    }
  };

  return (
    <VStack gap={2}>
      <ActionWrapper
        title={'Keyword Trigger'}
        description={<KeywordDescription keywords={values.keywords} />}
        actionColor={'#00A3FF'}
        actionIcon={<HiKey />}
        saveAction={formik.handleSubmit}
      >
        <VStack gap={2}>
          <FormFieldWrapper
            label="Keywords"
            tooltip="Keyword you would like to automate."
            name="keywords"
          >
            <KeywordBuilder
              name="keywords"
              options={
                keyword_trigger?.keyword?.keywords?.map((k: string) => ({
                  value: k,
                  label: k,
                })) || []
              }
            />
          </FormFieldWrapper>
          <FormFieldWrapper
            label="Negate Keywords"
            tooltip="Keyword you would like to automate."
            name="negate_keywords"
          >
            <KeywordBuilder
              name="negate_keywords"
              options={
                keyword_trigger?.keyword?.negate_keywords?.map((k: string) => ({
                  value: k,
                  label: k,
                })) || []
              }
            />
          </FormFieldWrapper>
          {(values.exact_match === 'false' || values.exact_match === 'true') && (
            <FormFieldWrapper
              label="Keyword Match"
              tooltip="Configure when the keyword is going to trigger."
              name="exact_match"
            >
              <RadioInput
                options={[
                  { value: 'false', type: 'Message Contains Keyword' },
                  {
                    value: 'true',
                    type: 'Message Exactly Matches Keyword',
                  },
                ]}
              />
            </FormFieldWrapper>
          )}
        </VStack>
      </ActionWrapper>
      {values.actions.map((action, index) => (
        <ActionWrapper
          key={index}
          title={`${getActionTitle(action)}` || ''}
          description={ActionDescription(action)}
          actionColor={`${getActionColor(action)}` || '#2C7675'}
          actionIcon={getActionIcon(action) || ''}
          removeAction={removeAction as any}
          action={action}
          saveAction={formik.handleSubmit}
        >
          <ActionRenderer
            key={index}
            index={index}
            action={action}
            removeAction={removeAction}
          />
        </ActionWrapper>
      ))}
      <Box>
        <AddAction addAction={addAction} />
      </Box>
      <Flex css={{ mt: 20 }}>
        <HStack>
          <Button type="submit">Save Automation</Button>
          <Button
            variant="red"
            type="button"
            onClick={() => deleteTrigger(keyword_trigger?.id)}
          >
            Delete Automation
          </Button>
        </HStack>
      </Flex>
    </VStack>
  );
};

export const AutomationTriggerContainer = styled(Box, {
  mb: 10,
  backgroundColor: '#fafafa',
  borderRadius: 4,
  variants: {
    variant: {
      campaign: {
        px: 16,
        py: 16,
        pl: 24,
      },
      template: {
        px: 0,
        py: 0,
      },
    },
  },
  defaultVariants: {
    variant: 'campaign',
  },
});
