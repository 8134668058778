/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';

import { usePageView } from '@/shared/hooks';
import { CampaignStatus } from '@/shared/types/campaigns';

import { useCampaignsContext } from './context/CampaignsContext';
import { CampaignsList } from './list';

export const CompletedCampaigns = (): JSX.Element => {
  // Change analytics page
  usePageView();

  const {
    campaignsState: { loading, completeCampaigns },
  } = useCampaignsContext();

  return (
    <CampaignsList
      title="Complete"
      url="completed"
      campaigns={completeCampaigns}
      loading={loading}
      status={CampaignStatus.COMPLETE}
    />
  );
};
