export type SortableItemType = {
  [key: string]: any;
};

/**
 * Sorts an array based on the order of the provided order array and key.
 * @param {Array<SortableItemType>} array - The array to be sorted.
 * @param {Array<any>} order - The array specifying the order of elements.
 * @param {string} key - The key to compare the elements in the array.
 * @returns {Array<SortableItemType>} - The sorted array.
 * @throws {Error} - If input types are invalid.
 *
 * Example usage:
 *
 * const inputArray = [
 *   { id: 1, name: 'A' },
 *   { id: 2, name: 'B' },
 *   { id: 3, name: 'C' },
 * ];
 *
 * const sortOrder = ['C', 'A', 'B'];
 * const sortedArray = mapOrder(inputArray, sortOrder, 'name');
 *
 * // Result: [ { id: 3, name: 'C' }, { id: 1, name: 'A' }, { id: 2, name: 'B' } ]
 *
 */
export function mapOrder(
  array: Array<SortableItemType>,
  order: Array<any>,
  key: string
): Array<SortableItemType> {
  if (!Array.isArray(array) || !Array.isArray(order) || typeof key !== 'string') {
    throw new Error('Invalid input types');
  }

  array.sort(function (a, b) {
    const valueA = a[key];
    const valueB = b[key];

    if (valueA === undefined || valueB === undefined) {
      return valueA === valueB ? 0 : valueA === undefined ? 1 : -1;
    }

    if (valueA === null || valueB === null) {
      return valueA === valueB ? 0 : valueA === null ? 1 : -1;
    }

    const indexA = order.indexOf(valueA);
    const indexB = order.indexOf(valueB);

    if (indexA === -1 || indexB === -1) {
      return indexA === indexB ? 0 : indexA === -1 ? 1 : -1;
    }

    if (indexA > indexB) {
      return 1;
    } else {
      return -1;
    }
  });

  return array;
}
