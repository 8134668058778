/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { HiSearch, HiUsers } from 'react-icons/hi';
import { useMedia } from 'react-use';

import { ContactTagListItem } from '@/pages/contacts/editor/tags/ContactTagListItem';
import { useDisclosure } from '@/shared/hooks';
import { Tag } from '@/shared/types/tags';
import { Team } from '@/shared/types/team';
import {
  Box,
  Button,
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogPortal,
  DialogTrigger,
  Flex,
  HStack,
  Input,
  ScrollArea,
  Text,
  VStack,
} from '@/shared/ui';

import { useTeams } from '../../../settings/organization/teams/context/TeamsContext';
import { TeamMembersAvatars } from '../../../settings/organization/teams/TeamMembersAvatars';
import { useConversation } from '../../context/ConversationContext';

type UnassignedTeamType = {
  name: string;
  id: number;
};

// unassignedUser must be added to list items in order to work with downshift
const unassignedTeam = {
  name: 'Unassigned',
  id: 0,
};

export const AssignTeamConversation = () => {
  const { isOpen, onClose, onOpen } = useDisclosure();

  const { conversationState, handleAssignTeam } = useConversation();
  const { current } = conversationState;

  const {
    teamsState: { allTeams },
  } = useTeams();

  // are teams searched right now
  const [isSearched, setIsSearched] = useState(false);

  // list of searched teams
  const [searchedTeams, setSearchedTeams] = useState<Array<Team>>([]);

  // selected team
  const [selectedTeam, setSelectedTeam] = useState<string | number>(
    current?.assigned_team_id || ''
  );

  // assigned team
  const [assignedTeam, setAssignedTeam] = useState<Team | null>(null);

  const isDesktop = useMedia('(min-width: 1200px)');

  useEffect(() => {
    const assignTeam = current?.assigned_team_id
      ? allTeams.find(
          (team: Team | UnassignedTeamType) => team?.id === current?.assigned_team_id
        )
      : null;

    if (assignTeam) {
      setAssignedTeam(assignTeam);
    }
  }, [allTeams, current?.assigned_team_id]);

  // update teams list whenever a new team is added to organization
  const teamsList = useMemo(() => {
    const filteredTeams = allTeams.filter(
      (team: Team) => team?.team_members && team?.team_members?.length > 0
    );
    return isSearched ? searchedTeams : [unassignedTeam, ...filteredTeams];
  }, [allTeams, searchedTeams, isSearched]);

  const assignConversationTeam = async () => {
    const assignTeam = teamsList.find(
      (team: Team | UnassignedTeamType) => team?.id === selectedTeam
    );
    assignTeam && (await handleAssignTeam(assignTeam as Team));
    onClose();
  };

  // This function is triggered when a user types in the team search bar.
  const onTeamSearch = (value: string) => {
    // If the search bar is not empty
    if (value) {
      // Set the isSearched state to true, indicating that a search is in progress
      setIsSearched(true);

      // Filter the teamsList to only include teams whose name includes the search value
      // The search is case-insensitive as both the team name and search value are converted to lower case
      const searchedTeamsArray =
        teamsList.filter((team: Team | UnassignedTeamType) =>
          team?.name?.toLowerCase()?.includes(value?.toLowerCase())
        ) || [];

      // Update the state with the filtered teams
      setSearchedTeams(searchedTeamsArray as Array<Team>);
    } else {
      // If the search bar is empty, reset the isSearched state to false and clear the searchedTeams array
      setIsSearched(false);
      setSearchedTeams([]);
    }
  };

  const onTeamClick = useCallback((id: string) => {
    setSelectedTeam(id);
  }, []);

  return (
    <Flex
      css={{
        m: 0,
        position: 'relative',
        flexWrap: 'wrap',
      }}
    >
      <>
        <Dialog open={isOpen} onOpenChange={() => !isOpen}>
          <DialogTrigger asChild={true}>
            <HStack onClick={onOpen}>
              {current && current?.assigned_team_id === null && (
                <Button variant={'icon'} size={1} css={{ px: isDesktop ? '$4' : '$2' }}>
                  <Flex css={{ px: 9, mr: 8 }}>
                    <HiUsers size={15} />{' '}
                  </Flex>
                  <Text color="$slate1">Assign Team</Text>
                </Button>
              )}
              {current && !!current?.assigned_team_id && (
                <Button
                  variant={'icon'}
                  size={isDesktop ? 2 : 1}
                  css={{ ml: isDesktop ? 9 : 0, px: '$2' }}
                >
                  {assignedTeam?.team_members?.length ? (
                    <Flex direction="row" align="center" css={{ mr: 8, ml: 4 }}>
                      <TeamMembersAvatars
                        members={assignedTeam?.team_members}
                        limit={3}
                        isPanel
                      />
                    </Flex>
                  ) : (
                    <Flex css={{ px: 8 }}>
                      <HiUsers size={15} style={{ marginRight: '8px' }} />
                    </Flex>
                  )}{' '}
                  <Text
                    color="$slate1"
                    css={{
                      maxWidth: 100,
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                    }}
                  >
                    {assignedTeam?.name}
                  </Text>
                </Button>
              )}
            </HStack>
          </DialogTrigger>
          <DialogPortal>
            <DialogContent
              onEscapeKeyDown={onClose}
              onPointerDownOutside={onClose}
              style={{
                minWidth: isDesktop ? '620px' : '350px',
                padding: 0,
              }}
            >
              <VStack gap={2}>
                {/* tags search bar */}
                <Box css={{ position: 'relative' }}>
                  <Box css={{ position: 'absolute', top: '32%', left: '1.5%' }}>
                    <HiSearch />
                  </Box>
                  <Input
                    css={{
                      minHeight: 45,
                      padding: '10px 10px 10px 30px',
                      borderBottomLeftRadius: 0,
                      borderBottomRightRadius: 0,
                    }}
                    placeholder="Search Teams"
                    onChange={(e) => onTeamSearch(e.target.value)}
                  />
                </Box>
                <ScrollArea
                  variant="combobox"
                  css={{ maxHeight: 250, overflowY: 'auto', marginTop: 0 }}
                >
                  {teamsList.map((team: Team | UnassignedTeamType) => (
                    <ContactTagListItem
                      key={team.id}
                      tag={{ ...team, color: 'black' } as Tag}
                      selectedTags={[selectedTeam] as Array<string>}
                      tagsList={teamsList as Array<Tag>}
                      searchedTags={searchedTeams as Array<Tag>}
                      isSearched={isSearched}
                      onTagClick={onTeamClick}
                    />
                  ))}
                </ScrollArea>
              </VStack>
              <DialogFooter
                justify="end"
                css={{
                  mt: 0,
                  p: 20,
                  borderTop: '1px solid $slate7',
                }}
              >
                <DialogClose asChild>
                  <Button variant="gray" size={2} css={{ mr: '$1' }} onClick={onClose}>
                    Cancel
                  </Button>
                </DialogClose>
                <DialogClose asChild>
                  <Button onClick={assignConversationTeam} size={2}>
                    Assign Team
                  </Button>
                </DialogClose>
              </DialogFooter>
            </DialogContent>
          </DialogPortal>
        </Dialog>
      </>
    </Flex>
  );
};
