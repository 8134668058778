import React from 'react';

import { usePageView } from '@/shared/hooks';
import { PageLayout } from '@/shared/layouts/PageLayout';
import { HStack } from '@/shared/ui';

import { AddApiKeyErrorBoundaries } from '../boundaries/AddApiKeyErrorBoundaries';
import { ApiKeyListErrorBoundaries } from '../boundaries/ApiKeyListErrorBoundaries';
import DeveloperProvider from '../context/DevelopersContext';
import { AddApiKey } from './AddApiKey';
import { ApiKeysList } from './ApiKeysList';

export function ApiKeys() {
  usePageView();

  return (
    <DeveloperProvider>
      <PageLayout
        breadcrumbs={[
          { title: 'Developer', path: '/developer/keys' },
          { title: 'API keys', path: '/developer/keys' },
        ]}
        actions={
          <HStack gap="2">
            <AddApiKeyErrorBoundaries feature="AddApiKey">
              <AddApiKey />
            </AddApiKeyErrorBoundaries>
          </HStack>
        }
      >
        <ApiKeyListErrorBoundaries feature="ApiKeyList">
          <ApiKeysList />
        </ApiKeyListErrorBoundaries>
      </PageLayout>
    </DeveloperProvider>
  );
}
