import * as Sentry from '@sentry/react';
import { H } from 'highlight.run';

import { isDev, isPreview } from '../config';

export const isHighlightEnabled = !isDev && !isPreview;
export const isIntercomEnabled = !isDev && !isPreview;
export const isSegmentEnabled = !isDev && !isPreview;
export const isSentryEnabled = !isDev && !isPreview;

declare global {
  interface Window {
    Intercom: any;
    analytics: any;
  }
}

export const init = () => {
  const environment: string = isDev
    ? 'development'
    : isPreview
      ? 'preview'
      : 'production';

  if (isSentryEnabled) {
    Sentry.init({
      dsn: 'https://f235878fb4a842a8a3191b5fd2ad87a1@o543562.ingest.sentry.io/5695086',
      integrations: [
        new Sentry.BrowserTracing({
          tracingOrigins: ['*'],
          tracePropagationTargets: ['*'],
        }),
      ],
      ignoreErrors: ['ResizeObserver loop limit exceeded'],
      tracesSampleRate: 1.0,
      environment,
    });
  }

  if (isHighlightEnabled) {
    H.init('5ldw5ldo', {
      environment: 'production',
      serviceName: 'whippy-dashboard',
      networkRecording: {
        enabled: true,
        recordHeadersAndBody: true,
      },
      version: localStorage.getItem('last_update') || '',
    });
  }
};

export const identify = (
  id: string,
  email: string,
  user_name?: string,
  current_organization_id?: string,
  current_organization_name?: string,
  organizations?: Array<{ id: string; organization_name: string }>
) => {
  Sentry.setUser({ id, email });

  if (isIntercomEnabled) {
    window.Intercom('boot', {
      app_id: 'j72xvimv',
      email: email,
      user_id: id,
      name: user_name,
      version: localStorage.getItem('last_update') || '',
      company: {
        id: current_organization_id,
        name: current_organization_name,
      },
      companies: organizations?.map(({ id, organization_name }) => ({
        id,
        name: organization_name,
      })),
    });
  }

  if (isHighlightEnabled) {
    H.identify(email, {
      name: user_name || '',
      user_id: id,
    });
  }

  if (isSegmentEnabled) {
    // identify user
    window.analytics.identify(id, {
      email: email,
      name: user_name || '',
      user_id: id,
      current_organization_id: current_organization_id,
      current_organization_name: current_organization_name,
    });
    // identify organization
    window.analytics.group(current_organization_id, {
      name: current_organization_name,
      organization_id: current_organization_id,
      organization_name: current_organization_name,
    });
  }
};

export default {
  init,
  identify,
};
