/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable react-hooks/exhaustive-deps */
import { captureException } from '@sentry/react';
import { debounce } from 'lodash';
import { useCallback, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import { getSequences } from '@/shared/api/sequences';
import { SearchV2Type } from '@/shared/types/conversations';
import { Sequence } from '@/shared/types/sequences';

import { TabOptions, useSearch } from '../SearchContext';
import { MessageSkeleton } from '../skeletons/message';
import { GroupName, KeepResultsFocused, SearchResults } from '../utils';
import { EmptyResults } from './emptyResults';
import { ErrorResults } from './errorResults';

export function RenderSequenceSearch({
  limit = 50,
  showEmptyResults = false,
  showResults = true,
}: {
  limit?: number;
  showEmptyResults?: boolean;
  showResults?: boolean;
}) {
  // Get search state and functions from SearchContext
  const {
    searchInput,
    setSearchInput,
    loadings,
    setLoadings,
    cancelPreviousRequest,
    setControllers,
    searchResults,
    setSearchResults,
    errors,
    setErrors,
    offsets,
    setOffsets,
    setPaginatings,
  } = useSearch();
  const history = useHistory();

  useEffect(() => {
    // Reset search results and trigger search when searchInput changes
    setSearchResults((prev) => ({ ...prev, Sequences: [] }));
    if (searchInput) {
      setLoadings((prev) => ({ ...prev, Sequences: true }));
      debounceSequenceSearch({ inputValue: searchInput });
    }
  }, [searchInput]);

  // Debounce search to reduce API calls during typing
  const debounceSequenceSearch = useCallback(
    debounce((searchV2: SearchV2Type) => {
      searchSequences(searchV2);
    }, 350),
    []
  );

  // Perform the actual search using the provided input value
  const searchSequences = async ({
    inputValue,
    offset = 0,
    shouldAppendResults = false,
  }: {
    inputValue: string;
    offset?: number;
    shouldAppendResults?: boolean;
  }) => {
    try {
      cancelPreviousRequest('Sequences'); // Cancel any ongoing search to prevent race conditions

      const controller = new AbortController(); // For cancelable fetch requests
      setControllers((prev) => ({ ...prev, Sequences: controller }));

      // Call API to search conversations
      const searchResults = await getSequences(
        {
          title: inputValue,
          limit,
          offset,
        },
        controller
      );

      const results = searchResults.data.map((sequence) => {
        const item: SearchResults = {
          id: sequence?.id || '',
          name: inputValue,
          perform: () => handleClick(sequence),
          shortcut: [],
          section: 'Sequence Search',
          subtitle: '',
          keywords: `search sequenceSearch ${inputValue}`,
          sequence: sequence ?? undefined,
        };
        return item;
      });

      // If end reached, prevent further pagination
      if (searchResults.data.length === 0)
        setOffsets((prev) => ({ ...prev, Sequences: -1 }));

      setSearchResults((prev) => ({
        ...prev,
        Sequences: shouldAppendResults ? [...prev.Sequences, ...results] : results,
      }));
      setLoadings((prev) => ({ ...prev, Sequences: false }));
    } catch (error) {
      console.error(error);

      if (error.name === 'AbortError' || error.name === 'CanceledError') {
        return console.log('AbortError: The operation was aborted');
      }

      // Display error toast and reset search state on error
      const errorMessage =
        error?.response?.data?.errors?.[0]?.description ?? 'Failed to search sequences';
      setLoadings((prev) => ({ ...prev, Sequences: false }));
      setSearchResults((prev) => ({ ...prev, Sequences: [] }));
      setErrors((prev) => ({
        ...prev,
        Sequences: { hasError: true, error: errorMessage },
      }));
      captureException(error, {
        tags: {
          area: 'globalSearch',
          feature: 'sequences',
        },
      });
    }
  };

  const handleMoreResults = () => {
    const offset = offsets[TabOptions.Sequences];
    const isLoading = loadings[TabOptions.Sequences];
    if (offset > 0 && !hasNoResults && !isLoading) {
      searchSequences({
        inputValue: searchInput,
        offset,
        shouldAppendResults: true,
      }).finally(() => {
        setPaginatings((prev) => ({ ...prev, Sequences: false }));
      });
    }
  };

  useEffect(() => {
    handleMoreResults();
  }, [offsets[TabOptions.Sequences]]);

  const hasNoResults = useMemo(
    () => searchResults?.Sequences?.length === 0 && searchInput !== '',
    [searchResults?.Sequences?.length]
  );

  // Handle click on a search result item
  const handleClick = (sequence: Sequence | null) => {
    if (sequence) {
      history.push(`/sequences/${sequence.id}/steps`);

      setSearchResults((prev) => ({ ...prev, Sequences: [] }));
      setSearchInput('');
    }
  };

  // Display loading message while searching
  if (loadings.Sequences) {
    return (
      <>
        <GroupName>Searching sequences...</GroupName>
        <MessageSkeleton />
      </>
    );
  }

  // Display error message if search request fails
  if (showResults && errors?.Sequences?.hasError)
    return <ErrorResults errorMessage={errors?.Sequences?.error} />;

  // Display "No results found" message if search input is not empty and no results are found
  if (hasNoResults && !loadings.Sequences && showEmptyResults) return <EmptyResults />;

  // Display search results
  if (!hasNoResults && showResults)
    return <KeepResultsFocused results={searchResults?.Sequences} label="Sequences" />;
}
