import { PermissionActions, PermissionResources } from '@/shared/types/users';

export type PermissionResource = {
  title: string;
  key: PermissionResources;
  items: PermissionAction[];
};

export type PermissionAction = {
  title: string;
  description: string;
  value: PermissionActions;
};

export const permissionResources = [
  {
    title: 'Contacts',
    key: PermissionResources.CONTACTS,
    items: [
      {
        title: 'Create Contact',
        description: 'Allows user to create a new contact within the platform',
        value: PermissionActions.CREATE,
      },
      {
        title: 'Read Contact',
        description: "Allows user to read a contact's personal information",
        value: PermissionActions.READ,
      },
      {
        title: 'Update Contact',
        description: "Allows user to edit and update a contact's personal information",
        value: PermissionActions.UPDATE,
      },
      {
        title: 'Delete Contact',
        description: 'Allows user to delete a contact',
        value: PermissionActions.DELETE,
      },
    ],
  },
  {
    title: 'Developer API Keys',
    key: PermissionResources.API_KEYS,
    items: [
      {
        title: 'Create API Key',
        description: 'Allows user to create a new API key within the platform',
        value: PermissionActions.CREATE,
      },
      {
        title: 'Read API Key',
        description: 'Allows user to read an API key',
        value: PermissionActions.READ,
      },
      {
        title: 'Update API Key',
        description: 'Allows user to edit and update an API key',
        value: PermissionActions.UPDATE,
      },
      {
        title: 'Delete API Key',
        description: 'Allows user to delete an API key',
        value: PermissionActions.DELETE,
      },
    ],
  },
  {
    title: 'Developer Applications',
    key: PermissionResources.APPLICATIONS,
    items: [
      {
        title: 'Create Application',
        description: 'Allows user to create a new application within the platform',
        value: PermissionActions.CREATE,
      },
      {
        title: 'Read Application',
        description: 'Allows user to read an application',
        value: PermissionActions.READ,
      },
      {
        title: 'Update Application',
        description: 'Allows user to edit and update an application',
        value: PermissionActions.UPDATE,
      },
      {
        title: 'Delete Applications',
        description: 'Allows user to delete an application',
        value: PermissionActions.DELETE,
      },
    ],
  },
  {
    title: 'Developer Webhook Endpoints',
    key: PermissionResources.ENDPOINTS,
    items: [
      {
        title: 'Create Webhook Endpoint',
        description: 'Allows user to create a new endpoint within the platform',
        value: PermissionActions.CREATE,
      },
      {
        title: 'Read Webhook Endpoint',
        description: 'Allows user to read an endpoint',
        value: PermissionActions.READ,
      },
      {
        title: 'Update Webhook Endpoint',
        description: 'Allows user to edit and update an endpoint',
        value: PermissionActions.UPDATE,
      },
      {
        title: 'Delete Webhook Endpoint',
        description: 'Allows user to delete an endpoint',
        value: PermissionActions.DELETE,
      },
    ],
  },
];
