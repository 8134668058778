import { useState } from 'react';

export function useProgress(): [
  number,
  React.Dispatch<React.SetStateAction<number>>,
  () => void,
  () => void,
] {
  const [currentStep, setCurrentStep] = useState(0);

  function goForward() {
    setCurrentStep(currentStep + 1);
  }

  function goBack() {
    setCurrentStep(currentStep - 1);
  }

  return [currentStep, setCurrentStep, goForward, goBack];
}
