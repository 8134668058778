import React from 'react';
import { createContext, useContext, useReducer } from 'react';
import { toast } from 'sonner';

import {
  createBrand,
  CreateBrandParams,
  createCampaign,
  CreateCampaignParams,
  getBrands,
  getCampaigns,
} from '@/shared/api/tendlc';
import i18next from '@/shared/utils/translation';

import TenDlcReducer from './TenDlcReducer';
import { CREATE_BRAND, CREATE_CAMPAIGN, GET_BRANDS, GET_CAMPAIGNS } from './types';

export type TenDlcState = {
  brands: Array<any>;
  campaigns: Array<any>;
  loading: boolean;
  current: any | null | undefined;
  error: any;
};

export const initialTenDlcState: TenDlcState = {
  brands: [],
  campaigns: [],
  current: null,
  error: null,
  loading: true,
};

export const TenDlcContext = createContext<{
  tenDlcState: TenDlcState;
  getAllBrands: () => void;
  getAllCampaigns: () => void;
  createOneBrand: (params: CreateBrandParams) => void;
  createOneCampaign: (brandId: string, params: CreateCampaignParams) => void;
}>({
  tenDlcState: initialTenDlcState,
  getAllBrands: () => Promise.resolve(),
  getAllCampaigns: () => Promise.resolve(),
  createOneBrand: () => Promise.resolve(),
  createOneCampaign: () => Promise.resolve(),
});

export const useTenDlc = () => useContext(TenDlcContext);

const TenDlcState = ({ children }: { children: React.ReactNode }) => {
  const [tenDlcState, dispatch] = useReducer(TenDlcReducer, initialTenDlcState);

  const getAllBrands = async () => {
    try {
      const data = await getBrands();

      dispatch({
        type: GET_BRANDS,
        payload: data,
      });
    } catch (err) {
      console.error(err);
    }
  };

  const getAllCampaigns = async () => {
    try {
      const data = await getCampaigns();

      dispatch({
        type: GET_CAMPAIGNS,
        payload: data,
      });
    } catch (err) {
      console.error(err);
    }
  };

  const createOneBrand = async (params: CreateBrandParams) => {
    try {
      const data = await createBrand(params);

      dispatch({
        type: CREATE_BRAND,
        payload: data,
      });

      toast.success(i18next.t('brand_created_success') as string);
    } catch (err) {
      toast.error(i18next.t('brand_created_failure') as string);
      console.error(err);
    }
  };

  const createOneCampaign = async (brandId: string, params: CreateCampaignParams) => {
    try {
      const data = await createCampaign(brandId, params);

      dispatch({
        type: CREATE_CAMPAIGN,
        payload: data,
      });

      toast.success(i18next.t('tendlc_campaign_created_success') as string);
    } catch (err) {
      toast.error(i18next.t('tendlc_campaign_created_failure') as string);
      console.error(err);
    }
  };

  return (
    <TenDlcContext.Provider
      value={{
        tenDlcState,
        getAllBrands,
        getAllCampaigns,
        createOneBrand,
        createOneCampaign,
      }}
    >
      {children}
    </TenDlcContext.Provider>
  );
};

export default TenDlcState;
