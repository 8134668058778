import { Formik, FormikProps } from 'formik';
import React from 'react';
import { CirclePicker } from 'react-color';
import { HiPencil, HiX } from 'react-icons/hi';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';

import { FormFieldWrapper, TextInput } from '@/shared/components/forms';
import { useDisclosure } from '@/shared/hooks';
import {
  Button,
  Dialog,
  DialogClose,
  DialogCloseIcon,
  DialogContent,
  DialogFooter,
  DialogOverlay,
  DialogPortal,
  DialogTitle,
  DialogTrigger,
  IconButton,
  VStack,
} from '@/shared/ui';

import { useUploads } from './context/UploadContext';

export const UpdateUpload = () => {
  const history = useHistory();
  const { updateUpload, uploadsState, deleteUpload } = useUploads();
  const { current } = uploadsState;
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleClose = (formik: FormikProps<any>) => {
    formik.handleReset();
    onClose();
  };

  const handleDelete = (id: string) => {
    if (id) {
      deleteUpload(id);
      onClose();
      history.push('/contacts');
    }
  };

  return (
    <Dialog open={isOpen}>
      <DialogTrigger>
        <IconButton onClick={onOpen}>
          <HiPencil size={15} />
        </IconButton>
      </DialogTrigger>
      <Formik
        enableReinitialize
        initialValues={{
          name: current?.name || '',
          color: current?.color || '',
          id: current?.id,
        }}
        validationSchema={Yup.object({
          name: Yup.string()
            .required('Required')
            .max(29, 'Must be less than 30 characters'),
          color: Yup.string().required('Required'),
        })}
        onSubmit={async (values, actions) => {
          try {
            if (current) {
              updateUpload({
                name: values.name || '',
                color: values.color || '',
                id: current.id,
              });
            }
            actions.resetForm({
              values: {
                name: '',
                color: '',
                id: '',
              },
            });
            onClose();
          } catch (e) {
            console.log(e);
          }
        }}
      >
        {(formik) => (
          <DialogPortal>
            <DialogOverlay />
            <DialogContent
              onEscapeKeyDown={() => handleClose(formik)}
              onPointerDownOutside={() => handleClose(formik)}
            >
              <form onSubmit={formik.handleSubmit}>
                <DialogTitle variant="bold">Update Upload</DialogTitle>
                <VStack>
                  <FormFieldWrapper label="Upload Name" name="name">
                    <TextInput placeholder="Example name" maxLength={29} />
                  </FormFieldWrapper>
                  <FormFieldWrapper label="Upload Color" name="color">
                    <CirclePicker
                      color={formik.values['color']}
                      onChangeComplete={(e) => formik.setFieldValue('color', e.hex)}
                    />
                  </FormFieldWrapper>
                </VStack>
                <DialogFooter justify="end">
                  {current && (
                    <DialogClose asChild>
                      <Button
                        variant="red"
                        css={{ mr: 5 }}
                        type="button"
                        onClick={() => handleDelete(current.id)}
                      >
                        Delete Upload
                      </Button>
                    </DialogClose>
                  )}
                  <DialogClose asChild>
                    <Button type="submit">Update Upload</Button>
                  </DialogClose>
                </DialogFooter>
                <DialogClose asChild>
                  <DialogCloseIcon onClick={() => handleClose(formik)} size="2">
                    <HiX size="15px" />
                  </DialogCloseIcon>
                </DialogClose>
              </form>
            </DialogContent>
          </DialogPortal>
        )}
      </Formik>
    </Dialog>
  );
};
