import { debounce } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { HiExternalLink } from 'react-icons/hi';
import { useHistory } from 'react-router-dom';

import { getAgentVersion } from '@/shared/api/ai/agents';
import { searchCalls } from '@/shared/api/calls';
import { CombinedFilters } from '@/shared/components/filterBuilder/CombinedFilters';
import { default_contact_object } from '@/shared/components/filterBuilder/objects/contact';
import { areFiltersValid } from '@/shared/components/filterBuilder/utils/areValidFilters';
import { cleanFilters } from '@/shared/components/filterBuilder/utils/cleanFilters';
import { PageLayout } from '@/shared/layouts/PageLayout';
import { AgentVersion } from '@/shared/types/ai/agents';
import { Call } from '@/shared/types/calls';
import { FilterType, Sort } from '@/shared/types/filter';
import { Box, Flex, HStack, Link } from '@/shared/ui';

import { TranscriptData } from './AgentCall';
import { CallsTable } from './CallsTable';
import { default_call_object } from './config/filterConfig';
import { sortConfig } from './config/sortConfig';

export const AgentCalls = () => {
  const [calls, setCalls] = useState<{ data: Call[]; total: number }>({
    data: [],
    total: 0,
  });
  const [loading, setLoading] = useState(true);

  const [quickSearchValue, setQuickSearchValue] = useState('');
  const [activeFilters, setActiveFilters] = useState<FilterType[]>([]);
  const [activeSort, setActiveSort] = useState<Sort[]>([
    {
      label: 'Updated At',
      column: 'updated_at',
      resource: 'message_attachment',
      order: 'desc',
      id: 1,
    },
  ]);
  const [offset, setOffset] = useState(0);
  const limit = 10;

  useEffect(() => {
    resetFilters();
  }, []);

  const resetFilters = () => {
    setQuickSearchValue('');
    setActiveFilters([]);
    fetchCalls([], activeSort, limit, offset);
  };

  const fetchCalls = async (
    filter: Array<FilterType>,
    sort: Array<Sort>,
    limit: number,
    offset: number
  ) => {
    try {
      setLoading(true);
      const data = await searchCalls(filter, sort, limit, offset);
      setCalls(data);
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const updateFilters = useCallback((filter: Array<FilterType>) => {
    setActiveFilters(filter);
  }, []);

  const debouncedFetchAgents = useCallback(
    debounce(async (filter: Array<FilterType>) => {
      await fetchCalls(cleanFilters(filter), [], limit, offset);
    }, 500),
    []
  );

  const handleFilterChange = useCallback(
    (filter: Array<FilterType>) => {
      updateFilters(filter);
      if (areFiltersValid(cleanFilters(filter))) {
        debouncedFetchAgents(filter);
      }
    },
    [updateFilters, debouncedFetchAgents]
  );

  const handleSortChange = useCallback((sort: Array<Sort>) => {
    setActiveSort(sort);
    fetchCalls(cleanFilters(activeFilters), sort, limit, offset);
  }, []);

  const handleOffsetChange = useCallback((offset: number) => {
    setOffset(offset);
    fetchCalls(cleanFilters(activeFilters), activeSort, limit, offset);
  }, []);

  const handleQuickSearchChange = useCallback((value: string) => {
    setQuickSearchValue(value);
    const filter = {
      resource: 'contact',
      column: 'name',
      comparison: 'ilike',
      value: `%${value}%`,
    };
    fetchCalls(cleanFilters([filter]), activeSort, limit, offset);
  }, []);

  const history = useHistory();

  const handleCallClick = (id: string) => {
    history.push(`/ai/calls/${id}`);
  };

  return (
    <PageLayout
      breadcrumbs={[
        { title: 'AI', path: '/ai/agents' },
        { title: 'Calls', path: '/ai/calls' },
      ]}
    >
      <Flex direction="column" css={{ flex: '1 1 auto', p: 30 }}>
        <Box css={{ paddingBottom: 24 }}>
          <CombinedFilters
            quickSearchPlaceholder="Search Calls"
            quickSearchValue={quickSearchValue}
            setQuickSearchValue={handleQuickSearchChange}
            defaultObjects={[default_call_object, default_contact_object]}
            customObjects={[]}
            activeFilters={activeFilters}
            setFilters={handleFilterChange}
            sortConfig={sortConfig}
            activeSort={activeSort}
            onSortUpdate={handleSortChange}
          />
        </Box>
        <CallsTable
          data={calls.data}
          loading={loading}
          totalCount={calls.total}
          onCardClick={handleCallClick}
          setOffset={handleOffsetChange}
        />
      </Flex>
    </PageLayout>
  );
};

// Take a transcript url and calculate the duration of the call
// Take a transcript url and calculate the duration of the call
export function CallDuration({ url }: { url: string }) {
  const [duration, setDuration] = useState<number>(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setDuration(calculateDuration(data));
      } catch (err) {
        console.error(err);
      }
    };

    fetchData();
  }, [url]);

  const calculateDuration = (data: TranscriptData) => {
    let minStart = Infinity;
    let maxEnd = -Infinity;

    data.forEach((entry) => {
      if ('words' in entry) {
        // Check if entry has words. Remove tool invocations
        entry.words.forEach((word) => {
          if (word.start < minStart) {
            minStart = word.start;
          }
          if (word.end > maxEnd) {
            maxEnd = word.end;
          }
        });
      }
    });

    return Number((maxEnd - minStart).toFixed(0));
  };

  const formatDuration = (duration: number) => {
    const minutes = Math.floor(duration / 60);
    const seconds = duration % 60;
    return minutes > 0 ? `${minutes} Min ${seconds} Sec` : `${seconds} Sec`;
  };

  return <Box>{duration > 0 ? formatDuration(duration) : 'Unknown'}</Box>;
}

// Takes an agent version id and render a link to the agents page
export function AgentVersionCell({ id }: { id: string }) {
  const [agentVersion, setAgentVersion] = useState<AgentVersion | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await getAgentVersion(id);
        setAgentVersion(response.data);
        setLoading(false);
      } catch (err) {
        console.error(err);
      }
    };

    fetchData();
  }, [id]);

  const history = useHistory();

  return (
    <Box>
      {loading ? (
        'Loading...'
      ) : (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <Link
          variant="contrast"
          href="#"
          onClick={() =>
            history.push(
              `/ai/agents/${agentVersion?.agent.id}/versions/${agentVersion?.id}`
            )
          }
        >
          <HStack>
            <Box>{agentVersion?.name}</Box>
            <HiExternalLink />
          </HStack>
        </Link>
      )}
    </Box>
  );
}
