import { LLM, LLMParams, LLMVersion } from '@/shared/types/ai/llms';
import { FilterItem, Sort } from '@/shared/types/filter';

import { api } from '../../api';

export const searchLLMs = async (
  filter: Array<FilterItem> = [],
  sort: Array<Sort> = [],
  limit = 100,
  offset = 0
): Promise<{ data: LLM[]; total: number }> => {
  const { data } = await api.post('v2/llms/search', {
    filter,
    sort,
    limit,
    offset,
  });
  return data;
};

export const getLLM = async (id: string): Promise<{ data: LLM }> => {
  const { data } = await api.get(`v2/llms/${id}`);
  return data;
};

export const createLLM = async (params: LLMParams): Promise<{ data: LLM }> => {
  const { data } = await api.post('v2/llms', params);
  return data;
};

export const updateLLM = async (
  id: string,
  params: LLMParams
): Promise<{ data: LLM }> => {
  const { data } = await api.put(`v2/llms/${id}`, params);
  return data;
};

export const deleteLLM = async (id: string): Promise<{ data: LLM }> => {
  const { data } = await api.delete(`v2/llms/${id}`);
  return data;
};

export const searchLLMVersions = async (
  filter: Array<FilterItem> = [],
  sort: Array<Sort> = [],
  limit = 100,
  offset = 0
): Promise<{ data: LLMVersion[]; total: number }> => {
  const { data } = await api.post('v2/llms/versions/search', {
    filter,
    sort,
    limit,
    offset,
  });
  return data;
};

export const getLLMVersion = async (id: string): Promise<{ data: LLMVersion }> => {
  const { data } = await api.get(`v2/llms/versions/${id}`);
  return data;
};
