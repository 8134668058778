/*
  GET - get a list of draft messages
*/

import { api } from './api';

/*
  GET - get a list of filtered draft messages
*/

export type GetFilteredDraftMessagesParams = {
  location_ids: Array<string>;
  start_date: string;
  end_date: string;
};

export const getFilteredDraftMessages = (params: GetFilteredDraftMessagesParams) =>
  api
    .get(`/drafts`, {
      params: {
        location_ids: params.location_ids,
        start_date: params.start_date,
        end_date: params.end_date,
      },
    })
    .then((res) => {
      return res.data.data;
    });

/*
  GET - get a list of filtered draft messages for a specific conversation
*/

export const getDraftsMessagesByConversation = (conversationId: string) =>
  api.get(`/drafts/${conversationId}`).then((res) => {
    return res.data;
  });

/*
  PUT - create a draft message
*/

export const createDraftMessage = (
  body: string,
  conversationId: string,
  attachments: Array<{ url: string; content_type?: string | null }>
) =>
  api
    .post(`/drafts`, {
      conversation_id: conversationId,
      body,
      attachments,
    })
    .then((res) => {
      return res.data;
    });

/*
  DELETE - delete a draft message
*/

export const deleteDraftMessage = (id: string) =>
  api.delete(`/drafts/${id}`).then((res) => {
    return res.data.data;
  });
