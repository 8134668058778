/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { Organization } from '@/shared/types';
import { ProgressBar } from '@/shared/ui';
import { isValidUuid } from '@/shared/utils/validations/validations';

import { useAuth } from '../../auth/context/AuthProvider';

type ParamTypes = {
  id: string;
  conversation_id: string;
};

export const ConversationRedirect = (): JSX.Element => {
  const auth = useAuth();
  const history = useHistory();
  const params = useParams<ParamTypes>();

  useEffect(() => {
    (async () => {
      if (isValidUuid(params.id) && shouldChangeOrganization(params.id)) {
        await auth.changeOrganization(params.id).then(() => {
          // reload the page to get the new organization data
          // this is important to prevent the user seeing cached data from the previous organization
          history.go(0);
        });
      } else {
        // we don't need to change the organization, so redirect to the conversation
        history.push(`/inbox/all/open/${params.conversation_id}`);
      }
    })();
  }, []);

  const shouldChangeOrganization = (paramOrganizationId: string) => {
    const orgIds = auth.organizations.map((o: Organization) => o.id);
    return (
      paramOrganizationId !== auth.organizationId && orgIds.includes(paramOrganizationId)
    );
  };

  // increase the progress bar banner by 10% every 100ms and keep it at 100% until the page is loaded
  // return a number between 0 and 100 so that the progress bar can be displayed
  const [progress, setProgress] = React.useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      if (progress < 100) {
        setProgress(progress + 10);
      }
    }, 25);
    return () => clearInterval(interval);
  }, [progress]);

  return <ProgressBar value={progress} variant="primary" />;
};
