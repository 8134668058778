/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { Box } from '@/shared/ui';

import { useSequences } from '../../context/SequenceContext';

interface MatchParams {
  id: string;
}

export const SequenceAnalytics: React.FC<RouteComponentProps<MatchParams>> = ({
  match,
}) => {
  const sequenceContext = useSequences();
  const { sequencesState, getSequence } = sequenceContext;
  const { current } = sequencesState;

  const { id } = match.params;

  useEffect(() => {
    if (!current) {
      getSequence(id);
    }
  }, []);

  return <Box css={{ p: 30 }}>Sequence Analytics</Box>;
};
