import { DefaultObject } from '@/shared/types/data';

import {
  campaign_contact_campaign_id,
  default_campaign_contact_object,
} from './campaign_contact';
import { default_communication_preference_object } from './communication_preference';
import { default_contact_object } from './contact';
import { default_contact_list_object } from './contact_list';
import { default_contact_tag_object } from './contact_tag';
import { default_conversation_object } from './conversation';
import { default_sequence_run_object } from './sequence_run';

export const default_objects: Array<DefaultObject> = [
  default_contact_object,
  default_contact_tag_object,
  default_contact_list_object,
  {
    ...default_campaign_contact_object,
    custom_properties: [
      campaign_contact_campaign_id,
      ...default_campaign_contact_object.custom_properties,
    ],
  },
  default_sequence_run_object,
  default_conversation_object,
  default_communication_preference_object,
];
