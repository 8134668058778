/* eslint-disable react-hooks/exhaustive-deps */
import { useHistory } from 'react-router-dom';

import { renderText } from '@/shared/components/markdown/MarkdownRenderer';
import { Campaign } from '@/shared/types/campaigns';
import { Box, Button, Flex } from '@/shared/ui';

import { useCampaignsContext } from '../../context/CampaignsContext';
import {
  TemplateCard,
  TemplateCardBottom,
  TemplateCardTop,
  TemplateMessage,
  TemplateMessageContainer,
  TemplateTitle,
} from '../styled';

type TemplatePreviewProps = {
  template: Campaign | null;
  isGlobal?: boolean;
};

export const TemplatePreview = (props: TemplatePreviewProps) => {
  const { template, isGlobal } = props;

  const campaigns = useCampaignsContext();
  const { setCurrentTemplate, setCurrentCampaign, duplicateOneCampaign } = campaigns;

  const history = useHistory();

  const handlePreviewTemplate = (template: Campaign) => {
    setCurrentTemplate(template);
    history.push(`/campaigns/templates/browse/${template.id}`);
  };

  const handleUpdateTemplate = (template: Campaign) => {
    setCurrentTemplate(template);
    history.push(`/campaigns/templates/${template.id}`);
  };

  const handleDuplicateTemplate = async (template: Campaign) => {
    if (template && template.id) {
      const campaign = await duplicateOneCampaign(template.id, true);
      if (campaign && campaign.id) {
        setCurrentCampaign(campaign);
        history.push(`/campaigns/${campaign?.id}`);
      }
    }
  };

  return (
    template && (
      <TemplateCard key={template.id}>
        <TemplateCardTop>
          {template && template.body && (
            <TemplateMessageContainer>
              <TemplateMessage>{renderText(template.body)}</TemplateMessage>
            </TemplateMessageContainer>
          )}
        </TemplateCardTop>
        <TemplateCardBottom direction="column">
          <TemplateTitle>{template.title}</TemplateTitle>
          <Flex align="center" css={{ pt: 15 }}>
            <Box css={{ flexGrow: 1, marginRight: '$1' }}>
              <Button
                variant="outline"
                css={{ width: '100%' }}
                onClick={() => handleDuplicateTemplate(template)}
              >
                Use Template
              </Button>
            </Box>
            <Box css={{ flexGrow: 1, marginLeft: '$1' }}>
              {isGlobal ? (
                <Button
                  variant="gray"
                  css={{ width: '100%' }}
                  onClick={() => handlePreviewTemplate(template)}
                >
                  Preview Template
                </Button>
              ) : (
                <Button
                  variant="gray"
                  css={{ width: '100%' }}
                  onClick={() => handleUpdateTemplate(template)}
                >
                  Edit Template
                </Button>
              )}
            </Box>
          </Flex>
        </TemplateCardBottom>
      </TemplateCard>
    )
  );
};
