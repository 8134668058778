import React from 'react';

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
  Box,
  Flex,
} from '@/shared/ui';

import { AccordionValue } from './ContactView';

type ContactAccordionProps = {
  title: string;
  children: React.ReactNode;
  accordionValue: AccordionValue;
  defaultValue?: AccordionValue;
  actionButton?: JSX.Element | null;
};

export const ContactAccordion = ({
  title,
  children,
  accordionValue,
  defaultValue,
  actionButton,
}: ContactAccordionProps) => {
  return (
    <Accordion type="single" collapsible defaultValue={defaultValue}>
      <AccordionItem
        value={accordionValue}
        variant="notes"
        css={{ width: '100%', px: 24 }}
      >
        <Flex justify="between" align="center" css={{ width: '100%', py: 10 }}>
          <Flex css={{ fontSize: 14, fontWeight: 500 }} align="center">
            <Box css={{ mr: 10 }}>{title}</Box>
          </Flex>
          <Flex>
            {actionButton}
            <AccordionTrigger />
          </Flex>
        </Flex>
        <AccordionContent variant="notes" css={{ pb: 24 }}>
          {children}
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
};
