import { CustomObject, DefaultObject } from '@/shared/types/data';
import { FilterType } from '@/shared/types/filter';

import { deepCopyObject } from './cleanFilters';
import { generateAllResourcesAndProperties } from './generateAllResourcesAndProperties';

/**
 * Takes the cleaned filters and returns a new list of FilterType with the relevant
 * information needed to properly render the FilterItems.
 * NOTE: If no defaultObject is passed it will use default_objects and  join_custom_data_object
 *
 * We take the customObjects and the defaultObjects in order fill in the proper type
 * for the FilterType.
 *
 * @param  customObjects - An array of custom objects relevant for custom data for users.
 * @param  cleanedFilters - An array of cleaned filters to apply to the objects.
 * @param  [defaultObjects] - An optional array of default objects to merge with the custom objects.
 * @returns  The function does not return anything; it performs side effects as needed.
 */
export const rebuildFilters = (
  customObjects: Array<CustomObject> | [],
  cleanedFilters: FilterType[],
  defaultObjects?: DefaultObject[]
) => {
  const { resourcePropertiesMapping } = generateAllResourcesAndProperties(
    customObjects,
    defaultObjects
  );

  // Function to remove wildcards and adjust comparison operator back to original
  const removeWildcardsAndAdjustComparison = (comparison: string, value: string) => {
    let operator = comparison;
    let adjustedValue = value.trim();

    switch (comparison) {
      case 'ilike':
        if (value.startsWith('%') && value.endsWith('%')) {
          operator = 'contains';
          adjustedValue = value.slice(1, -1);
        } else if (value.startsWith('%')) {
          operator = 'end';
          adjustedValue = value.slice(1);
        } else if (value.endsWith('%')) {
          operator = 'start';
          adjustedValue = value.slice(0, -1);
        } else {
          operator = '==';
        }
        break;
      case 'not_ilike':
        if (value.startsWith('%') && value.endsWith('%')) {
          operator = 'not_contains';
          adjustedValue = value.slice(1, -1);
        } else if (value.startsWith('%')) {
          operator = 'not_end';
          adjustedValue = value.slice(1);
        } else if (value.endsWith('%')) {
          operator = 'not_start';
          adjustedValue = value.slice(0, -1);
        } else {
          operator = '!=';
        }
        break;
      default:
        // No adjustment needed for other comparison types
        break;
    }

    return { comparison: operator, value: adjustedValue };
  };

  // Recursive function to rebuild each filter object
  const rebuild = (obj: FilterType): FilterType => {
    const copy = deepCopyObject(obj); // Create a deep copy of the object

    // Add a random ID
    copy.id = Math.random().toString(36).substring(2, 9);

    // Add back the type based on the resource and column
    if (copy.resource && copy.column) {
      const resourceProperties = resourcePropertiesMapping[copy.resource];
      const property = resourceProperties?.find((p) => p.key === copy.column);
      if (property) {
        copy.type = property.type;
      }
    }

    // Adjust comparison and value for 'text' type filters
    if (copy.type === 'text') {
      const { comparison, value } = removeWildcardsAndAdjustComparison(
        copy.comparison,
        copy.value
      );
      copy.comparison = comparison;
      copy.value = value;
    }

    // Ensure 'and', 'or', 'on' arrays exist and recursively rebuild them if they do
    ['and', 'or', 'on'].forEach((key) => {
      if (!copy[key]) {
        copy[key] = [];
      } else {
        copy[key] = copy[key].map(rebuild);
      }
    });

    return copy;
  };

  // Apply the rebuilding process to each filter in the array
  return cleanedFilters.map(rebuild);
};
