// The reasoning behind why these functions are created is: in the FE we save the current filters in the following format {tags: [ArrayOfTagIds], sources: [ArrayOfSourceNames]} (this is the "simple format").

// The "complicated format" is the one we send to the BE {type: 'and', filters: [{attribute: 'tag.id', comparison: 'in', type: 'list', value: [ArrayOfTagIds]}, {attribute: 'source', comparison: 'in', type: 'list', value: [ArrayOfSourceNames]}]}.

// So what these functions do is convert one format to the other.

export const simplifyContactFilters = (filtersToBeSimplified: any) => {
  if (!filtersToBeSimplified) {
    return { tags: [], sources: [] };
  }
  if (filtersToBeSimplified.search[0]?.filters.length > 1) {
    if (filtersToBeSimplified.search[0]?.filters[0]?.attribute === 'tag.id') {
      return {
        tags: filtersToBeSimplified.search[0]?.filters[0]?.value,
        sources: filtersToBeSimplified.search[0]?.filters[1]?.value,
      };
    } else {
      return {
        tags: filtersToBeSimplified.search[0]?.filters[1]?.value,
        sources: filtersToBeSimplified.search[0]?.filters[0]?.value,
      };
    }
  } else {
    if (filtersToBeSimplified.search[0]?.filters[0]?.attribute === 'tag.id') {
      return { tags: filtersToBeSimplified.search[0]?.filters[0]?.value, sources: [] };
    } else {
      return { sources: filtersToBeSimplified.search[0]?.filters[0]?.value, tags: [] };
    }
  }
};

export const complicateContactFilters = (filtersToBeComplicated: any) => {
  if (
    filtersToBeComplicated.tags?.length === 0 &&
    filtersToBeComplicated.sources?.length === 0
  ) {
    return { search: [] };
  } else if (
    filtersToBeComplicated.tags?.length > 0 &&
    filtersToBeComplicated.sources?.length === 0
  ) {
    const params = {
      type: 'and',
      filters: [
        {
          attribute: 'tag.id',
          comparison: 'in',
          type: 'list',
          value: filtersToBeComplicated.tags,
        },
      ],
    };

    return { search: [params] };
  } else if (
    filtersToBeComplicated.tags?.length === 0 &&
    filtersToBeComplicated.sources?.length > 0
  ) {
    const params = {
      type: 'and',
      filters: [
        {
          attribute: 'source',
          comparison: 'in',
          type: 'list',
          value: filtersToBeComplicated.sources,
        },
      ],
    };

    return { search: [params] };
  } else {
    const params = {
      type: 'and',
      filters: [
        {
          attribute: 'tag.id',
          comparison: 'in',
          type: 'list',
          value: filtersToBeComplicated.tags,
        },
        {
          attribute: 'source',
          comparison: 'in',
          type: 'list',
          value: filtersToBeComplicated.sources,
        },
      ],
    };

    return { search: [params] };
  }
};

// removes leading and trailing and prevents full whitespace names
export const formatContactName = (contactName: string) => {
  if (!contactName || typeof contactName !== 'string') return '';
  if (contactName.trim().length === 0 && contactName !== '') {
    return '';
  }
  return contactName.trim();
};

export const formatQuickSearchFilter = (value: string, name: string) => {
  return [
    {
      column: name,
      comparison: 'ilike',
      resource: 'contact',
      value,
    },
  ];
};
