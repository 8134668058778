import dayjs from 'dayjs';
import React from 'react';
import { HiOutlineClock, HiPaperAirplane, HiX } from 'react-icons/hi';

import { ConfirmationDialog } from '@/shared/components/ConfirmationDialog';
import { TooltipIconButton } from '@/shared/components/TooltipIconButton';
import { useDisclosure } from '@/shared/hooks';
import { ConversationScheduledMessageType } from '@/shared/types/conversations';
import {
  Box,
  Flex,
  HStack,
  IconButton,
  Tooltip,
  TooltipArrow,
  TooltipContent,
  TooltipTrigger,
} from '@/shared/ui';
import { isValidUuid } from '@/shared/utils/validations/validations';
import { styled } from '@/stitches.config';

import SendNowConfirmation from '../../scheduled/SendNowConfirmation';
import { UpdateScheduledMessageDialog } from '../../scheduled/UpdateScheduledMessageDialog';

const ScheduledMessageText = styled(Box, {
  display: '-webkit-box',
  '-webkit-line-clamp': 1,
  '-webkit-box-orient': 'vertical',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  fontSize: '14px',
  fontWeight: '500',
  maxWidth: 185,
});

const DateText = styled(Box, {
  display: '-webkit-box',
  '-webkit-line-clamp': 1,
  '-webkit-box-orient': 'vertical',
  overflow: 'hidden',
  fontSize: '12px',
});

type ConversationScheduledMessageProps = {
  scheduledMessage: ConversationScheduledMessageType;
  deleteScheduledMessage: (id: number) => void;
  sendScheduledMessageNow: (id: number) => void;
};

export const ConversationScheduledMessage = (
  props: ConversationScheduledMessageProps
) => {
  const { scheduledMessage, deleteScheduledMessage, sendScheduledMessageNow } = props;

  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleDelete = () => {
    deleteScheduledMessage(scheduledMessage.job_id);
  };

  return (
    <Flex align="center" justify="between">
      <HStack css={{ fontSize: 12 }}>
        <HiOutlineClock size={16} />
        <ScheduledMessageText>{scheduledMessage.message.body}</ScheduledMessageText>
        <DateText>
          sending on{' '}
          {dayjs(scheduledMessage.execution_time.substring(0, 19)).format(
            'ddd MMM D, YYYY h:mm a'
          )}{' '}
          {`in ${scheduledMessage.timezone}`}
        </DateText>
      </HStack>
      <HStack>
        <SendNowConfirmation
          id={scheduledMessage.job_id}
          onDelete={sendScheduledMessageNow}
        >
          <Tooltip>
            <TooltipTrigger asChild>
              <IconButton>
                <HiPaperAirplane style={{ transform: 'rotate(90deg)' }} />
              </IconButton>
            </TooltipTrigger>
            <TooltipContent>
              Send Now
              <TooltipArrow />
            </TooltipContent>
          </Tooltip>
        </SendNowConfirmation>
        {isValidUuid(scheduledMessage.conversation_id || '') && (
          <UpdateScheduledMessageDialog
            id={scheduledMessage.job_id}
            location_id={''}
            message={scheduledMessage.message || ''}
            schedule={scheduledMessage.execution_time || ''}
            // fallback to browser timezone if timezone is not set
            timezone={
              scheduledMessage?.timezone ||
              Intl.DateTimeFormat().resolvedOptions().timeZone ||
              ''
            }
            buttonVariant={'ghost'}
            isInbox={true}
            isOpen={isOpen}
            onOpen={onOpen}
            onClose={onClose}
          />
        )}
        <ConfirmationDialog
          title="Delete Scheduled Message"
          description="Are you sure you want to delete your scheduled message?"
          onConfirm={handleDelete}
          confirmButtonTitle="Yes, Delete Message"
          testID="delete-scheduled-message-button"
        >
          <TooltipIconButton
            tooltipArrow
            text="Delete Scheduled Message"
            icon={<HiX />}
          />
        </ConfirmationDialog>
      </HStack>
    </Flex>
  );
};
