import { VStack } from '@/shared/ui';

import TeamsTable from './TeamsTable';

export const TeamsView = () => {
  return (
    <VStack>
      <TeamsTable />
    </VStack>
  );
};
