import * as SliderPrimitive from '@radix-ui/react-slider';
import { styled } from '@stitches/react';

export const Slider = styled(SliderPrimitive.Root, {
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  userSelect: 'none',
  touchAction: 'none',
  width: '100%',

  '&[data-orientation="horizontal"]': {
    height: 20,
  },

  '&[data-orientation="vertical"]': {
    flexDirection: 'column',
    width: 20,
    height: 100,
  },
});

export const SliderTrack = styled(SliderPrimitive.Track, {
  backgroundColor: '$gray5',
  position: 'relative',
  flexGrow: 1,
  borderRadius: '9999px',

  '&[data-orientation="horizontal"]': { height: 3 },
  '&[data-orientation="vertical"]': { width: 3 },
});

export const SliderRange = styled(SliderPrimitive.Range, {
  position: 'absolute',
  backgroundColor: '$primaryColor',
  borderRadius: '9999px',
  height: '100%',
});

export const SliderThumb = styled(SliderPrimitive.Thumb, {
  all: 'unset',
  display: 'block',
  width: 20,
  height: 20,
  backgroundColor: '$primaryColor',
  outline: '2px',
  borderRadius: 10,
  '&:focus': { boxShadow: '0 0 0 5px $black8' },
});
