import React from 'react';

import { VStack } from '@/shared/ui';

import TemplatesTable from './TemplatesTable';

function TemplatesView(): JSX.Element {
  return (
    <VStack>
      <TemplatesTable />
    </VStack>
  );
}

export default TemplatesView;
