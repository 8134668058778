import { ErrorBoundary } from '@sentry/react';
import dayjs from 'dayjs';
import React, { Dispatch } from 'react';

import { AccordionValue, Campaign } from '@/shared/types/campaigns';
import { Avatar, Box, Fieldset, HStack, Input, Label, VStack } from '@/shared/ui';
import { getChannelIds } from '@/shared/utils/campaign';
import { initials } from '@/shared/utils/initials/initials';

import { CampaignAccordion } from '../../create/CampaignAccordion';
import { SelectLocation } from '../../create/SelectLocation';
import { CampaignErrorBoundaryFallback } from './CampaignErrorBoundaryFallback';

type ViewCampaignBasicInfoProps = {
  /* campaign object */
  campaign?: Campaign | null;
  /* current accordion value */
  accordion: AccordionValue;
  /* set accordion value */
  setAccordion: Dispatch<React.SetStateAction<AccordionValue>>;
};

export const ViewCampaignBasicInfo = (props: ViewCampaignBasicInfoProps): JSX.Element => {
  const { campaign, accordion, setAccordion } = props;

  const setLocation = (location: string) => {
    console.log('set location', location);
  };

  return (
    <CampaignAccordion
      index={1}
      title="Campaign Info"
      description="Campaign Name & Channel"
      currentAccordionValue={accordion}
      itemValue={AccordionValue.SELECT_LOCATION}
      setItemValue={setAccordion}
      isValid={true}
      buttonCopy="View"
    >
      <ErrorBoundary
        fallback={<CampaignErrorBoundaryFallback />}
        beforeCapture={(scope) => {
          scope.setTag('Campaign', 'CampaignErrorBoundary.ViewCampaignAudience');
        }}
        showDialog={false}
      >
        <VStack gap="2" data-testid="campaign-info-content">
          <Fieldset>
            <Label>Campaign Name</Label>
            <Box>
              <Input disabled placeholder="Name Campaign" value={campaign?.title || ''} />
            </Box>
          </Fieldset>
          <Fieldset>
            <Label>Sent From</Label>
            <SelectLocation
              disabled
              location={getChannel(campaign)}
              setLocation={setLocation}
            />
          </Fieldset>
          <Fieldset>
            <Label>Created By</Label>
            <HStack css={{ mt: 5 }}>
              <Avatar
                variant="pink"
                size="assign"
                src={campaign?.updated_by?.attachment?.url}
                fallback={initials(
                  campaign?.updated_by?.name || campaign?.created_by?.email
                )}
              />
              <Box css={{ fontSize: 14 }}>
                {campaign?.updated_by?.name || campaign?.updated_by?.email || 'API'}
              </Box>
            </HStack>
          </Fieldset>
          <Fieldset>
            <Label>Created On</Label>
            <Box css={{ fontSize: 14 }}>
              {campaign?.updated_at
                ? dayjs(campaign.updated_at).format('dddd MMM DD, YYYY hh:mm A')
                : '-'}
            </Box>
          </Fieldset>
        </VStack>
      </ErrorBoundary>
    </CampaignAccordion>
  );
};

function getChannel(campaign?: Campaign | null) {
  if (!campaign) return '';
  return getChannelIds(campaign)[0];
}
