import React, { createContext, Dispatch, useContext, useEffect, useReducer } from 'react';
import { toast } from 'sonner';

import { prepareFilters } from '@/pages/data/utils/prepareFilters';
import * as TemplatesAPI from '@/shared/api/message_templates';
import { SearchFilters } from '@/shared/types/contacts';
import {
  CreateMessageTemplateParams,
  Template,
  TemplatesActions,
  TemplatesActionTypes,
  TemplatesState,
} from '@/shared/types/templates';
import i18next from '@/shared/utils/translation';

import TemplatesReducer from './TemplatesReducer';

export const ITEMS_COUNT = 10;

export const initialTemplateState: TemplatesState = {
  allTemplates: [],
  templates: [],
  searchedTemplates: [],
  totalCount: 0,
  loading: true,
  current: null,
  error: null,
  filterParams: {
    offset: 0,
    limit: 10,
    sort: [
      {
        label: 'Updated At',
        column: 'updated_at',
        order: 'desc',
        resource: 'message_template',
        id: null,
      },
    ],
    filter: [],
    searchFilter: [],
  },
};

export const TemplatesContext = createContext<{
  templatesState: TemplatesState;
  createTemplate: (params: CreateMessageTemplateParams) => void;
  editTemplate: (template: Template) => void;
  deleteTemplate: (template: Template) => void;
  setCurrentTemplate: (tag: Template | null | undefined) => void;
  bulkImportMultipleTemplates: (templates: Array<Template>) => void;
  getTemplatesV2: (params: SearchFilters) => Promise<void>;
  updateFilterParams: (params: SearchFilters) => void;
}>({
  templatesState: initialTemplateState,
  createTemplate: () => Promise.resolve({} as Template),
  editTemplate: () => Promise.resolve({} as Template),
  deleteTemplate: () => Promise.resolve({} as Template),
  setCurrentTemplate: () => Promise.resolve({} as Template),
  bulkImportMultipleTemplates: () => Promise.resolve({} as Array<Template>),
  getTemplatesV2: () => Promise.resolve(),
  updateFilterParams: () => Promise.resolve(),
});

export const useTemplates = () => useContext(TemplatesContext);

const TemplateState = ({ children }: { children: React.ReactNode }) => {
  const [templatesState, dispatch]: [TemplatesState, Dispatch<TemplatesActions>] =
    useReducer(TemplatesReducer, initialTemplateState);

  // On mount, get all templates from the API
  // we still use V1 because V2 has a limit of 50 templates
  // so many api calls need to be made in order to be able to allow for
  // fast local search through all templates
  useEffect(() => {
    getTemplates();
  }, []);

  // We use V2 to get templates when the user searches for a template
  // from the settings table where they can apply different complex filters
  useEffect(() => {
    getTemplatesV2(templatesState.filterParams);
  }, [templatesState.filterParams]);

  const getTemplates = async () => {
    try {
      const data = await TemplatesAPI.getMessageTemplatesV1();
      dispatch({
        type: TemplatesActionTypes.GET_ALL_TEMPLATES,
        payload: data,
      });
    } catch (err) {
      console.error(err);
    }
  };

  const createTemplate = async (params: CreateMessageTemplateParams) => {
    try {
      const data = await TemplatesAPI.createMessageTemplate(params);

      dispatch({
        type: TemplatesActionTypes.CREATE_TEMPLATE,
        payload: data,
      });
      if (templatesState.templates.length) {
        getTemplatesV2({ ...templatesState.filterParams });
      }
      toast.success(i18next.t('template_created_success') as string);
    } catch (err) {
      console.error(err);
    }
  };

  const deleteTemplate = async (template: Template) => {
    try {
      await TemplatesAPI.deleteMessageTemplate(template);
      dispatch({
        type: TemplatesActionTypes.DELETE_TEMPLATE,
        payload: template,
      });
      if (templatesState.templates.length) {
        getTemplatesV2({ ...templatesState.filterParams });
      }
      toast.success(i18next.t('template_deleted_success') as string);
    } catch (err) {
      toast.error(i18next.t('template_deleted_failure') as string);
      console.error(err);
    }
  };

  const editTemplate = async (template: Template) => {
    try {
      const data = await TemplatesAPI.editMessageTemplate(template);

      dispatch({
        type: TemplatesActionTypes.EDIT_TEMPLATE,
        payload: data,
      });
      getTemplatesV2({ ...templatesState.filterParams });
      toast.success(i18next.t('template_updated_success') as string);
    } catch (err) {
      toast.error(i18next.t('template_updated_failure') as string);

      console.error(err);
    }
  };

  const setCurrentTemplate = (template: Template | null | undefined) => {
    try {
      dispatch({
        type: TemplatesActionTypes.SET_CURRENT,
        payload: template,
      });
    } catch (err) {
      console.error(err);
    }
  };

  const bulkImportMultipleTemplates = async (templates: Array<Template>) => {
    try {
      const data = await TemplatesAPI.bulkImportTemplates(templates);
      dispatch({
        type: TemplatesActionTypes.BULK_IMPORT_TEMPLATES,
        payload: data,
      });
      if (templatesState.templates.length) {
        getTemplatesV2({ ...templatesState.filterParams });
      }
      toast.success(i18next.t('template_import_success') as string);
    } catch (err) {
      toast.error(i18next.t('template_import_success') as string);

      console.error(err);
    }
  };

  const getTemplatesV2 = async (params: SearchFilters) => {
    try {
      const data = await TemplatesAPI.getMessageTemplates(prepareFilters(params));

      dispatch({
        type: TemplatesActionTypes.GET_TEMPLATES,
        payload: data,
      });
    } catch (err) {
      console.error(err);
    }
  };

  const updateFilterParams = async (params: SearchFilters) => {
    try {
      dispatch({
        type: TemplatesActionTypes.UPDATE_FILTER_PARAMS,
        payload: params,
      });
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <TemplatesContext.Provider
      value={{
        templatesState,
        createTemplate,
        editTemplate,
        deleteTemplate,
        setCurrentTemplate,
        bulkImportMultipleTemplates,
        getTemplatesV2,
        updateFilterParams,
      }}
    >
      {children}
    </TemplatesContext.Provider>
  );
};

export default TemplateState;
