/* eslint-disable react-hooks/exhaustive-deps */
import { useCombobox, UseComboboxStateChangeOptions } from 'downshift';
import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import Highlighter from 'react-highlight-words';
import { HiPencil } from 'react-icons/hi';
import { HiClock } from 'react-icons/hi';
import { useMedia } from 'react-use';

import { AddTemplate } from '@/pages/settings/organization/templates/AddTemplate';
import { useTemplates } from '@/pages/settings/organization/templates/context/TemplatesContext';
import { UpdateTemplate } from '@/pages/settings/organization/templates/UpdateTemplate';
import { Template } from '@/shared/types/templates';
import {
  Box,
  ComboboxItem,
  ComboboxItemText,
  Flex,
  HStack,
  IconButton,
  Input,
  ScrollArea,
  ScrollAreaCorner,
  ScrollAreaScrollbar,
  ScrollAreaThumb,
  ScrollAreaViewport,
  Text,
} from '@/shared/ui';
import { styled } from '@/stitches.config';

type TemplateComboboxProps = {
  anchorDimensions?: {
    height: number;
    width: number;
  };
  updateMessageFromTemplate: (template: Template) => void;
  onClose: () => void;
  // we need this because if an attachment is added to the editor in the template editor
  // and we are in the inbox, the attachment gets added to the inbox editor and not the template editor
  setShowTemplateEditor?: Dispatch<SetStateAction<boolean>>;
  isInbox?: boolean;
  isWhatsAppTemplate?: boolean;
};

const TemplateComboboxItem = styled(ComboboxItem, {
  py: 6,
  mb: '$3',
});

// filter and select message template from list of templates
export const TemplateCombobox = (props: TemplateComboboxProps) => {
  const isLargeScreen = useMedia('(min-width: 1600px)');

  const templatesContext = useTemplates();
  const { templatesState } = templatesContext;
  const { allTemplates } = templatesState;
  const [inputItems, setInputItems] = useState(allTemplates);
  const [searchValue, setSearchValue] = useState('');

  const filteredTemplates = useMemo(
    () =>
      allTemplates.filter((item: Template) =>
        props?.isWhatsAppTemplate ? item.is_whatsapp_template : true
      ),
    [allTemplates, props?.isWhatsAppTemplate]
  );

  useEffect(() => {
    if (searchValue) {
      setInputItems(
        filteredTemplates.filter(
          (item: Template) =>
            item?.title?.toLowerCase().includes(searchValue?.toLowerCase()) ||
            item?.message?.toLowerCase().includes(searchValue?.toLowerCase())
        )
      );
    } else {
      setInputItems(filteredTemplates);
    }
  }, [filteredTemplates]);

  const { getMenuProps, getInputProps, getItemProps, highlightedIndex } = useCombobox({
    items: inputItems,
    itemToString: (item) => item?.title || '',
    stateReducer: (
      state,
      actionAndChanges
    ): Partial<UseComboboxStateChangeOptions<Template>> => {
      const { type, changes } = actionAndChanges;
      // this prevents the menu from being closed when the user selects an item with 'Enter' or mouse
      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
          if (changes.selectedItem) {
            props.updateMessageFromTemplate(changes.selectedItem);
            props.onClose();
          }
          return {
            changes, // default Downshift new state changes on item selection
          };
        case useCombobox.stateChangeTypes.ItemClick:
          if (changes.selectedItem) {
            props.updateMessageFromTemplate(changes.selectedItem);
            props.onClose();
          }
          return {
            changes, // default Downshift new state changes on item selection
          };
        default:
          return changes; // otherwise business as usual
      }
    },
    onInputValueChange: ({ inputValue }) => {
      const inputString = inputValue ? inputValue : '';
      setSearchValue(inputString);
      setInputItems(
        filteredTemplates.filter(
          (item: Template) =>
            item?.title?.toLowerCase().includes(inputString?.toLowerCase()) ||
            item?.message?.toLowerCase().includes(inputString?.toLowerCase())
        )
      );
    },
  });

  const calculateComboboxItemWidth = () => {
    const isSidePanelOpen = Number(props.anchorDimensions?.width) < 900;
    if (isLargeScreen) {
      if (isSidePanelOpen) {
        return Number(props.anchorDimensions?.width) > 650 ? '94%' : '91%';
      }
      return '96%';
    } else {
      return '93%';
    }
  };

  // take the inputItems and order them by the alphabetical order of the title
  const orderedInputItems = inputItems.sort((a: Template, b: Template) =>
    (a?.title || '').localeCompare(b?.title || '')
  );

  return (
    <Box css={{ p: '$4' }}>
      <Box css={{ pb: '$3' }}>
        <Text css={{ fontWeight: 600, fontSize: 15 }}>{'Message Templates'}</Text>
      </Box>
      <Flex css={{ pb: '$4' }}>
        <Input
          {...getInputProps({}, { suppressRefError: true })}
          placeholder="Search message templates..."
          css={{ mr: '$1' }}
        />
        <AddTemplate isInbox={true} setShowTemplateEditor={props.setShowTemplateEditor} />
      </Flex>
      <ScrollArea variant="combobox" {...getMenuProps({}, { suppressRefError: true })}>
        <ScrollAreaViewport variant="combobox" css={{ minHeight: '192px' }}>
          {inputItems.length < 1 && searchValue ? (
            <Flex
              align="center"
              justify="center"
              css={{ height: '100%', fontSize: 14, margin: '85px 0' }}
            >
              No templates match this search
            </Flex>
          ) : (
            orderedInputItems.map((item, index) => (
              <Flex
                key={`${item.id}-${index}`}
                align="center"
                css={{
                  width: props.anchorDimensions
                    ? props.anchorDimensions?.width - 42
                    : undefined,
                  backgroundColor: index === highlightedIndex ? '$slate2' : undefined,
                  borderRadius: index === highlightedIndex ? 4 : undefined,
                }}
                data-testid={`template-item-${item.id}`}
              >
                <TemplateComboboxItem
                  direction="column"
                  css={{
                    height: '100%',
                    width: calculateComboboxItemWidth(),
                    mb: 0,
                    borderRadius: 4,
                  }}
                  {...getItemProps({ item, index })}
                >
                  <Flex align="center" justify="between">
                    <Flex direction="column" css={{ width: '80%' }}>
                      <Box css={{ fontWeight: 600, mb: 3 }}>
                        <HStack>
                          {item?.is_scheduled_template && props?.isInbox && <HiClock />}
                          <Highlighter
                            searchWords={[searchValue]}
                            autoEscape={true}
                            textToHighlight={item.title || ''}
                            highlightStyle={{ backgroundColor: '#FFC106' }}
                          />
                        </HStack>
                      </Box>
                      <ComboboxItemText
                        css={{
                          width: '100%',
                          maxWidth: '300px',
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        <Highlighter
                          searchWords={[searchValue]}
                          autoEscape={true}
                          textToHighlight={
                            item.message || 'This template contains attachments only.'
                          }
                          highlightStyle={{ backgroundColor: '#FFC106' }}
                        />
                      </ComboboxItemText>
                    </Flex>
                  </Flex>
                </TemplateComboboxItem>
                <Flex
                  css={{ pr: 15, borderRadius: 4, width: '10%' }}
                  align="end"
                  direction="column"
                >
                  <UpdateTemplate template={item}>
                    <IconButton
                      variant="outline"
                      size="2"
                      aria-label={`template-${item?.id}-update-button`}
                    >
                      <HiPencil />
                    </IconButton>
                  </UpdateTemplate>
                </Flex>
              </Flex>
            ))
          )}
        </ScrollAreaViewport>
        <ScrollAreaScrollbar orientation="vertical" variant="combobox">
          <ScrollAreaThumb />
        </ScrollAreaScrollbar>
        <ScrollAreaCorner />
      </ScrollArea>
    </Box>
  );
};
