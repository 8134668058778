import { mauve, violet } from '@radix-ui/colors';
import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu';
import { CSS, keyframes, styled, VariantProps } from '@stitches/react';
import React from 'react';

const slideUpAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateY(2px)' },
  '100%': { opacity: 1, transform: 'translateY(0)' },
});

const slideRightAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateX(-2px)' },
  '100%': { opacity: 1, transform: 'translateX(0)' },
});

const slideDownAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateY(-2px)' },
  '100%': { opacity: 1, transform: 'translateY(0)' },
});

const slideLeftAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateX(2px)' },
  '100%': { opacity: 1, transform: 'translateX(0)' },
});

const contentStyles = {
  zIndex: 999999,
  minWidth: 220,
  boxSizing: 'border-box',
  backgroundColor: '$panel',
  border: '1px solid $slate4',
  borderRadius: 6,
  paddingTop: 5,
  paddingBottom: 5,
  paddingLeft: 0,
  paddingRight: 0,
  boxShadow:
    '0px 10px 38px -10px rgba(22, 23, 24, 0), $colors$shadowDark 0px 10px 20px -15px',
  '@media (prefers-reduced-motion: no-preference)': {
    animationDuration: '400ms',
    animationTimingFunction: 'cubic-bezier(0.16, 1, 0.3, 1)',
    willChange: 'transform, opacity',
    '&[data-state="open"]': {
      '&[data-side="top"]': { animationName: slideDownAndFade },
      '&[data-side="right"]': { animationName: slideLeftAndFade },
      '&[data-side="bottom"]': { animationName: slideUpAndFade },
      '&[data-side="left"]': { animationName: slideRightAndFade },
    },
  },

  variants: {
    variant: {
      combobox: {
        minWidth: 'auto',
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
  },
};

const StyledContent = styled(DropdownMenuPrimitive.Content, { ...contentStyles });

type DropdownMenuContentPrimitiveProps = React.ComponentProps<
  typeof DropdownMenuPrimitive.Content
>;
type DropdownMenuContentProps = DropdownMenuContentPrimitiveProps & {
  css?: CSS;
} & VariantProps<typeof StyledContent>;

const Content = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.Content>,
  DropdownMenuContentProps
>(({ children, ...props }, ref) => {
  return (
    <DropdownMenuPrimitive.Portal>
      <StyledContent {...props}>
        <div ref={ref}>{children}</div>
      </StyledContent>
    </DropdownMenuPrimitive.Portal>
  );
});

type DropdownSubMenuContentPrimitiveProps = React.ComponentProps<
  typeof DropdownMenuPrimitive.SubContent
>;
type DropdownSubMenuContentProps = DropdownSubMenuContentPrimitiveProps & { css?: CSS };

const StyledSubContent = styled(DropdownMenuPrimitive.SubContent, { ...contentStyles });

function SubContent(props?: DropdownSubMenuContentProps) {
  return (
    <DropdownMenuPrimitive.Portal>
      <StyledSubContent {...props} />
    </DropdownMenuPrimitive.Portal>
  );
}

const itemStyles = {
  all: 'unset',
  fontSize: 13,
  lineHeight: 1,
  display: 'flex',
  alignItems: 'center',
  minHeight: 25,
  padding: '3px 5px',
  position: 'relative',
  paddingLeft: 20,
  userSelect: 'none',
  cursor: 'pointer',

  '&[data-disabled]': {
    color: mauve.mauve8,
    pointerEvents: 'none',
  },

  '&:focus': {
    backgroundColor: '#E2E8F0',
  },

  '&:hover': {
    backgroundColor: '$slate2',
  },
};

const StyledItem = styled(DropdownMenuPrimitive.Item, { ...itemStyles });

const StyledCheckboxItem = styled(DropdownMenuPrimitive.CheckboxItem, {
  ...itemStyles,
});

const StyledRadioItem = styled(DropdownMenuPrimitive.RadioItem, {
  ...itemStyles,
});

const StyledTriggerItem = styled(DropdownMenuPrimitive.Item, {
  '&[data-state="open"]': {
    backgroundColor: '$panel',
  },
  ...itemStyles,
});

const StyledSubTrigger = styled(DropdownMenuPrimitive.SubTrigger, {
  ...itemStyles,
  '&[data-state="open"]': {
    backgroundColor: '$panel',
  },
});

const StyledLabel = styled(DropdownMenuPrimitive.Label, {
  paddingLeft: 25,
  fontSize: 12,
  lineHeight: '25px',
  color: mauve.mauve11,
});

const StyledSeparator = styled(DropdownMenuPrimitive.Separator, {
  height: 1,
  backgroundColor: violet.violet6,
  margin: 5,
});

const StyledItemIndicator = styled(DropdownMenuPrimitive.ItemIndicator, {
  position: 'absolute',
  left: 0,
  width: 25,
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const StyledArrow = styled(DropdownMenuPrimitive.Arrow, {
  fill: 'white',
});

// Exports
export const DropdownMenu = DropdownMenuPrimitive.Root;
export const DropdownMenuTrigger = DropdownMenuPrimitive.Trigger;
export const DropdownMenuContent = Content;
export const DropdownMenuItem = StyledItem;
export const DropdownMenuCheckboxItem = StyledCheckboxItem;
export const DropdownMenuRadioGroup = DropdownMenuPrimitive.RadioGroup;
export const DropdownMenuRadioItem = StyledRadioItem;
export const DropdownMenuItemIndicator = StyledItemIndicator;
export const DropdownMenuTriggerItem = StyledTriggerItem;
export const DropdownMenuLabel = StyledLabel;
export const DropdownMenuSeparator = StyledSeparator;
export const DropdownMenuArrow = StyledArrow;
export const DropdownSubMenu = DropdownMenuPrimitive.Sub;
export const DropdownSubMenuContent = SubContent;
export const DropdownSubTrigger = StyledSubTrigger;
