import React from 'react';

import { Contact } from '@/shared/types';
import { Box, Button, Flex, HStack, PageTitle, VStack } from '@/shared/ui';
import { styled } from '@/stitches.config';

import { CreateContact } from '../data/contacts/CreateContact';
import { ContactsFilters } from './ContactsFilters';
import { UpdateGroup } from './groups/UpdateGroup';
import { CreateUpload } from './uploads/CreateUpload';
import { UpdateUpload } from './uploads/UpdateUpload';

type ContactPageHeaderProps = {
  /** the name of the contact page e.g group name */
  title: string;
  /** modifies the array of checked contacts */
  setCheckedContacts: (checkedContacts: Array<Contact>) => void;
  /** saves the filters of the current group */
  saveFilters?: (filters: Record<string, any>) => void;
  /**  Is this on a group page? */
  isGroup: boolean;
  /**  Is this on a upload page? */
  isUpload: boolean;
};

export const ContactsPageHeader = (props: ContactPageHeaderProps) => {
  return (
    <ContactPageHeaderContainer>
      <VStack>
        <Flex align="center" justify="between">
          <Flex align="center">
            <PageTitle>{props.title}</PageTitle>
            <Box css={{ ml: '5px' }}>
              {props.isUpload && <UpdateUpload />}
              {props.isGroup && <UpdateGroup />}
            </Box>
          </Flex>
          <HStack gap={1}>
            <CreateUpload>
              <Button variant="gray" type="button">
                Upload Contacts
              </Button>
            </CreateUpload>
            <CreateContact>
              <Button>Create Contact</Button>
            </CreateContact>
          </HStack>
        </Flex>
        {!props.isUpload && (
          <ContactsFilters
            setCheckedContacts={props.setCheckedContacts}
            saveFilters={props.saveFilters}
            isGroup={props.isGroup}
          />
        )}
      </VStack>
    </ContactPageHeaderContainer>
  );
};

export const ContactPageHeaderContainer = styled(Box, {
  alignItems: 'center',
  borderBottom: 'thin solid $gray4',
  height: 60,
  minHeight: 60,
  px: 20,
  paddingTop: 15,
  '@md': {
    height: 140,
    minHeight: 140,
  },
});
