import { ContactPanelHeader } from '@/pages/contacts/editor/v2/ContactPanelHeader';
import { ContactView } from '@/pages/contacts/editor/v2/ContactView';
import { useDisclosure } from '@/shared/hooks';
import { Contact } from '@/shared/types/contacts';
import { Drawer, DrawerContent, DrawerPortal, DrawerTrigger, Flex } from '@/shared/ui';
import { styled } from '@/stitches.config';

export const ContactPanel = ({
  children,
  contact,
  deleteContact,
}: {
  children: React.ReactNode;
  contact: Contact;
  deleteContact: (id: string) => void;
}) => {
  const { isOpen, onClose, onOpen } = useDisclosure();

  const handlePointerDownOutside = (e: Event) => {
    const addressInput = document.querySelector('.pac-container') as HTMLElement;
    if (!addressInput?.contains(e.currentTarget as Node)) {
      onClose();
    }
  };

  return (
    <Drawer open={isOpen}>
      <DrawerTrigger asChild onClick={onOpen}>
        {children}
      </DrawerTrigger>
      <DrawerPortal>
        <DrawerContent
          aria-describedby="Edit Contact"
          css={{
            maxWidth: '420px',
            minWidth: '360px',
            top: 0,
            height: '100%',
            zIndex: 99,
            display: 'flex',
            flexDirection: 'column',
          }}
          onEscapeKeyDown={handlePointerDownOutside}
          onPointerDownOutside={handlePointerDownOutside}
        >
          <ContactPanelHeader
            contact={contact}
            onClose={onClose}
            deleteContact={deleteContact}
          />
          <DrawerContentContainer>
            <ContactView contactId={contact.id} onClose={onClose} />
          </DrawerContentContainer>
        </DrawerContent>
      </DrawerPortal>
    </Drawer>
  );
};

export const DrawerContentContainer = styled(Flex, {
  position: 'relative',
  height: '100%',
  flex: 1,
  overflow: 'auto',
});
