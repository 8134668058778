import * as AvatarPrimitive from '@radix-ui/react-avatar';
import React from 'react';

import { CSS, styled, VariantProps } from '@/stitches.config';

import { Box } from './Box';
import { Status } from './Status';

const StyledAvatar = styled(AvatarPrimitive.Root, {
  alignItems: 'center',
  justifyContent: 'center',
  verticalAlign: 'middle',
  overflow: 'hidden',
  userSelect: 'none',
  boxSizing: 'border-box',
  display: 'flex',
  flexShrink: 0,
  position: 'relative',
  border: 'none',
  fontFamily: 'inherit',
  lineHeight: '1',
  margin: '0',
  outline: 'none',
  padding: '0',
  fontWeight: '700',
  color: 'white',

  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    borderRadius: 'inherit',
    boxShadow: 'inset 0px 0px 1px rgba(0, 0, 0, 0.12)',
  },

  variants: {
    size: {
      '1': {
        width: '$3',
        height: '$3',
      },
      '2': {
        width: '$5',
        height: '$5',
      },
      '3': {
        width: '$6',
        height: '$6',
      },
      '4': {
        width: '$7',
        height: '$7',
      },
      '5': {
        width: '$8',
        height: '$8',
      },
      '6': {
        width: '$9',
        height: '$9',
      },
      '7': {
        width: '$4',
        height: '$4',
      },
      '16': {
        width: 16,
        height: 16,
        fontSize: 8,
      },
      '36': {
        width: 36,
        height: 36,
      },
      '48': {
        width: 48,
        height: 48,
      },
      profile: {
        backgroundColor: '#77787a !important',
        borderRadius: '100px',
        textAlign: 'center',
        color: 'white',
        fontWeight: '700',
        height: '80px',
        width: '80px',
        lineHeight: '80px',
        fontSize: '18px',
      },
      table: {
        width: '$7',
        height: '$7',
      },
      newTable: {
        width: '40px',
        height: '40px',
      },
      seen: {
        background:
          'linear-gradient(90deg, rgba(229,62,62,1) 0%, rgba(245,101,101,1) 35%, rgba(214,158,46,1) 100%)',
        borderRadius: '100px',
        textAlign: 'center',
        color: 'white',
        width: '$4',
        height: '$4',
        lineHeight: '$4',
        fontSize: '8px',
        '&:hover': {
          background:
            'linear-gradient(90deg, rgba(51,130,207,1) 0%, rgba(57,141,215,1) 35%, rgba(65,151,223,1) 100%)',
        },
      },
      assign: {
        borderRadius: '100px',
        textAlign: 'center',
        color: 'white',
        width: '22px',
        height: '22px',
        lineHeight: '$4',
        fontSize: '8px',
      },
      assignPanel: {
        borderRadius: '100px',
        textAlign: 'center',
        color: 'white',
        width: '32px',
        height: '32px',
        lineHeight: '$4',
        fontSize: '12px',
      },
      sender: {
        borderRadius: '100px',
        textAlign: 'center',
        color: 'white',
        width: '28px',
        height: '28px',
        lineHeight: '$4',
        fontSize: '10px',
      },
    },
    variant: {
      hiContrast: {
        backgroundColor: '$hiContrast',
        color: '$loContrast',
      },
      gray: {
        backgroundColor: '#77787a !important',
      },
      tomato: {
        backgroundColor: '$tomato5',
      },
      red: {
        backgroundColor: '$red5',
      },
      crimson: {
        backgroundColor: '$crimson5',
      },
      pink: {
        backgroundColor: '#e57bb3',
      },
      plum: {
        backgroundColor: '$plum5',
      },
      purple: {
        backgroundColor: '#603BF3',
      },
      violet: {
        backgroundColor: '$violet5',
      },
      indigo: {
        backgroundColor: '$indigo5',
      },
      blue: {
        backgroundColor: '$blue5',
      },
      cyan: {
        backgroundColor: '$cyan5',
      },
      teal: {
        backgroundColor: '$teal5',
      },
      green: {
        backgroundColor: '$green5',
      },
      grass: {
        backgroundColor: '$grass5',
      },
      brown: {
        backgroundColor: '$brown5',
      },
      bronze: {
        backgroundColor: '$bronze5',
      },
      gold: {
        backgroundColor: '$gold5',
      },
      sky: {
        backgroundColor: '$sky5',
      },
      mint: {
        backgroundColor: '$mint5',
      },
      lime: {
        backgroundColor: '$lime5',
      },
      yellow: {
        backgroundColor: '$yellow5',
      },
      amber: {
        backgroundColor: '$amber5',
      },
      orange: {
        backgroundColor: '$orange5',
      },
      lightGray: {
        backgroundColor: '$slate8 !important',
      },
      primary: {
        backgroundColor: '$primaryButtonColor',
      },
    },
    shape: {
      square: {
        borderRadius: '$2',
      },
      circle: {
        borderRadius: '50%',
      },
    },
    inactive: {
      true: {
        opacity: '.3',
      },
    },
    interactive: {
      true: {
        '&::after': {
          content: '""',
          position: 'absolute',
          top: '0',
          right: '0',
          bottom: '0',
          left: '0',
          backgroundColor: 'rgba(0,0,0,.08)',
          opacity: '0',
          pointerEvents: 'none',
          transition: 'opacity 25ms linear',
        },
        '@hover': {
          '&:hover': {
            '&::after': {
              opacity: '1',
            },
          },
        },
        '&[data-state="open"]': {
          '&::after': {
            backgroundColor: 'rgba(0,0,0,.12)',
            opacity: '1',
          },
        },
      },
    },
  },
  defaultVariants: {
    size: '2',
    variant: 'gray',
    shape: 'circle',
  },
});

const StyledAvatarImage = styled(AvatarPrimitive.Image, {
  display: 'flex',
  objectFit: 'cover',
  boxSizing: 'border-box',
  height: '100%',
  verticalAlign: 'middle',
  width: '100%',
});

const StyledAvatarFallback = styled(AvatarPrimitive.Fallback, {
  textTransform: 'uppercase',
  variants: {
    size: {
      '1': {
        fontSize: '8px',
        lineHeight: '15px',
      },
      '2': {
        fontSize: '11px',
        lineHeight: '15px',
      },
      '3': {
        fontSize: '12px',
        lineHeight: '15px',
      },
      '4': {
        fontSize: '$7',
      },
      '5': {
        fontSize: '$8',
      },
      '6': {
        fontSize: '$9',
      },
      '7': {
        fontSize: '8px',
        lineHeight: '15px',
      },
      table: {
        fontSize: '$4',
      },
    },
  },
  defaultVariants: {
    size: '1',
  },
});

export const AvatarNestedItem = styled('div', {
  boxShadow: '0 0 0 2px $colors$loContrast',
  borderRadius: '50%',
});

export const AvatarGroup = styled('div', {
  display: 'flex',
  flexDirection: 'row-reverse',
  [`& ${AvatarNestedItem}:nth-child(n+2)`]: {
    marginRight: '-$1',
  },
});

type StatusVariants = React.ComponentProps<typeof Status>;
type StatusColors = Pick<StatusVariants, 'variant'>;

type AvatarCSSProp = { css?: CSS };
type AvatarVariants = Omit<VariantProps<typeof StyledAvatar>, 'size'>;
type AvatarOwnProps = AvatarCSSProp &
  AvatarVariants & {
    alt?: string;
    src?: string;
    fallback?: React.ReactNode;
    status?: StatusColors['variant'];
    size?: any;
  };

type AvatarComponent = AvatarOwnProps;

export const Avatar = ({
  alt,
  src,
  fallback,
  size,
  variant,
  shape,
  css,
  status,
  ...props
}: AvatarComponent) => {
  return (
    <Box
      css={{
        ...(css as any),
        position: 'relative',
        height: 'fit-content',
        width: 'fit-content',
      }}
    >
      <StyledAvatar {...props} size={size} variant={variant} shape={shape}>
        {/* if StyledAvatar fails to be rendered, Fallback will be rendered instead */}
        <StyledAvatarImage alt={alt} src={src} />
        <StyledAvatarFallback size={size}>{fallback}</StyledAvatarFallback>
      </StyledAvatar>
      {status && (
        <Box
          css={{
            position: 'absolute',
            bottom: '0',
            right: '0',
            boxShadow: '0 0 0 3px $colors$loContrast',
            borderRadius: '$round',
            mr: '-3px',
            mb: '-3px',
          }}
        >
          <Status size={size > 2 ? ('2' as any) : ('1' as any)} variant={status} />
        </Box>
      )}
    </Box>
  );
};
