import React from 'react';
import { HiOutlineMenu } from 'react-icons/hi';
import { useMedia } from 'react-use';

import { SettingsNavigation } from '@/shared/components/navigation';
import { useDisclosure } from '@/shared/hooks';
import { LayoutContent } from '@/shared/layouts';
import {
  MobileSettingsContainer,
  SettingsContentContainer,
  SettingsLayoutContainer,
} from '@/shared/layouts/SettingsLayout';
import { Button, Drawer, DrawerContent, DrawerOverlay, DrawerPortal } from '@/shared/ui';

export const LayoutContainer = ({ children }: { children: React.ReactNode }) => {
  const isDesktop = useMedia('(min-width: 912px)');
  const { isOpen, onOpen, onClose } = useDisclosure(false);

  return (
    <SettingsLayoutContainer>
      {isDesktop && <SettingsNavigation />}
      <SettingsContentContainer direction="column">
        {!isDesktop && (
          <MobileSettingsContainer align="center">
            <Button onClick={onOpen} variant="icon">
              <HiOutlineMenu fontSize="1rem" />
            </Button>
            <Drawer open={isOpen}>
              <DrawerPortal>
                <DrawerOverlay />
                <DrawerContent
                  onEscapeKeyDown={onClose}
                  onPointerDownOutside={onClose}
                  css={{ width: 230 }}
                  side="left"
                >
                  <SettingsNavigation />
                </DrawerContent>
              </DrawerPortal>
            </Drawer>
          </MobileSettingsContainer>
        )}
        <LayoutContent>{children}</LayoutContent>
      </SettingsContentContainer>
    </SettingsLayoutContainer>
  );
};
