import React from 'react';

import { Box } from '@/shared/ui';

import { ReportsContentContainer } from '../layout/ReportsContentContainer';

export const LinksReport = () => {
  return (
    <ReportsContentContainer title="Links">
      <Box>Links</Box>
    </ReportsContentContainer>
  );
};
