import * as Tooltip from '@radix-ui/react-tooltip';
import * as d3 from 'd3';
import { useMemo, useState } from 'react';
import { HiMinus, HiPlus } from 'react-icons/hi';
import {
  ComposableMap,
  Geographies,
  Geography,
  Point,
  ZoomableGroup,
} from 'react-simple-maps';

import {
  Box,
  ColorLegend,
  Flex,
  IconButton,
  TooltipContent,
  TooltipTrigger,
} from '@/shared/ui';
import { styled } from '@/stitches.config';

import { hex2rgba } from '../utils';
import * as geoUrl from './geo.json';

type MapChartProps = {
  data: any;
};

type Position = {
  coordinates: Point;
  zoom: number;
};

type CountryItem = { count: number; key: string };

export const MapChart = ({ data }: MapChartProps) => {
  const [tooltipContent, setTooltipContent] = useState<{
    name: string;
    count: number;
  } | null>(null);
  const [position, setPosition] = useState<Position>({ coordinates: [0, 0], zoom: 1 });

  const maxCount = useMemo(() => {
    return data?.by_country.reduce(
      (acc: number, country: CountryItem) => Math.max(acc, country.count),
      0
    );
  }, [data]);

  const handleZoomIn = () => {
    if (position.zoom >= 8) return;
    setPosition((pos) => ({ ...pos, zoom: pos.zoom * 1.5 }));
  };

  const handleZoomOut = () => {
    if (position.zoom <= 1) return;
    setPosition((pos) => ({ ...pos, zoom: pos.zoom / 1.5 }));
  };

  const handleMoveEnd = (position: Position) => {
    setPosition(position);
  };

  const onMouseLeave = () => {
    setTooltipContent(null);
  };

  const geographyStyle = {
    default: {
      outline: 'none',
    },
    hover: {
      fill: '#ccc',
      transition: 'all 250ms',
      outline: 'none',
    },
    pressed: {
      outline: 'none',
    },
  };

  const colorScale = d3
    .scaleLinear<string>()
    .domain([0, maxCount])
    .range(['#F6F6F6', '#3E54CF']);

  return (
    <MapContainer>
      <ComposableMap>
        <ZoomableGroup
          zoom={position.zoom}
          maxZoom={2}
          minZoom={0.5}
          center={position.coordinates}
          onMoveEnd={handleMoveEnd}
        >
          <Geographies geography={geoUrl}>
            {({ geographies }) =>
              geographies.map((geo) => {
                const d = data?.by_country?.find((c: CountryItem) =>
                  c.key.includes(geo.properties.name)
                );
                return (
                  <Tooltip.Provider key={geo.rsmKey}>
                    <Tooltip.Root>
                      <TooltipTrigger asChild>
                        <Geography
                          key={geo.rsmKey}
                          geography={geo}
                          fill={
                            d
                              ? hex2rgba('#3E54CF', (d?.count || 0) / maxCount)
                              : '#F5F4F6'
                          }
                          stroke="#13171E"
                          strokeWidth={0.1}
                          style={geographyStyle}
                          onMouseEnter={() => {
                            setTooltipContent({
                              name: geo.properties.name,
                              count: d?.count || 0,
                            });
                          }}
                          onMouseLeave={onMouseLeave}
                        />
                      </TooltipTrigger>
                      <TooltipContent>
                        {tooltipContent &&
                          `${tooltipContent.name}: ${tooltipContent.count}`}
                      </TooltipContent>
                    </Tooltip.Root>
                  </Tooltip.Provider>
                );
              })
            }
          </Geographies>
        </ZoomableGroup>
      </ComposableMap>
      <ZoomButtons direction={'column'}>
        <IconButton
          size={2}
          variant="outline"
          onClick={handleZoomIn}
          css={{ borderRadius: '4px 4px 0 0' }}
        >
          <HiPlus />
        </IconButton>
        <IconButton
          size={2}
          variant="outline"
          onClick={handleZoomOut}
          css={{ borderRadius: '0 0 4px 4px' }}
        >
          <HiMinus />
        </IconButton>
      </ZoomButtons>
      <Legend justify="center">
        <ColorLegend width={140} height={11} colorScale={colorScale} />
      </Legend>
    </MapContainer>
  );
};

const ZoomButtons = styled(Flex, {
  position: 'absolute',
  right: 20,
  bottom: 20,
  flexDirection: 'column',
});

const MapContainer = styled(Box, {
  position: 'relative',
});

const Legend = styled(Flex, {
  minHeight: 30,
});
