import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import { toast } from 'sonner';
import * as Yup from 'yup';

import { useSequences } from '@/pages/sequences/context/SequenceContext';
import {
  CampaignSourceTuple,
  sequenceBulkActions,
  SequenceSourceTuple,
} from '@/shared/api/sequences';
import { FormFieldWrapper } from '@/shared/components/forms';
import { SingleSelect } from '@/shared/components/SingleSelect';
import { FilterItem } from '@/shared/types/filter';
import { Sequence } from '@/shared/types/sequences';
import { Button, HStack, Label, VStack } from '@/shared/ui';
import {
  Dialog,
  DialogClose,
  DialogFooter,
  DialogOverlay,
  DialogPortal,
  DialogTrigger,
} from '@/shared/ui/Dialog';

import {
  BulkActionDialogContent,
  BulkActionDialogDescription,
  BulkActionDialogTitle,
} from '../Dialog';

type RemoveFromSequenceModalProps = {
  onOpenChange: (open: boolean) => void;
  defaultSequence?: Sequence;
  open: boolean;
  totalContacts: number;
  filter: FilterItem[];
  filterSource?: CampaignSourceTuple | SequenceSourceTuple;
};

export const RemoveFromSequenceModal = ({
  open,
  totalContacts,
  filter,
  filterSource,
  onOpenChange,
}: RemoveFromSequenceModalProps) => {
  const sequenceContext = useSequences();
  const { sequencesState, getSequences } = sequenceContext;
  const { sequences } = sequencesState as { sequences: Sequence[] };
  const [selectedSequenceId, setSelectedSequenceId] = useState<string>('');
  const resetModalState = (): void => {
    onOpenChange(false);
    setSelectedSequenceId('');
  };

  useEffect(() => {
    getSequences();
  }, []);

  const handleSubmit = async (values: { sequenceId: string }, actions: any) => {
    const { sequenceId } = values;
    try {
      await sequenceBulkActions(
        sequenceId,
        'sequences.contacts.remove',
        { filter: filter },
        filterSource
      );
      toast.success('Began processing the removal of contacts from sequence');
      actions.resetForm({
        values: {
          sequenceId: '',
        },
      });
      onOpenChange(false);
    } catch (e) {
      console.error('failed to remove contacts from sequence: ', e);
      toast.error('Failed to remove contacts from sequence');
    }
  };

  return (
    <Dialog open={open} modal={false}>
      <DialogTrigger asChild onClick={resetModalState}></DialogTrigger>
      <DialogPortal>
        <DialogOverlay as="div">
          <Formik
            onSubmit={handleSubmit}
            initialValues={{
              sequenceId: '',
            }}
            validationSchema={Yup.object({
              sequenceId: Yup.string().required('Please select a sequence.').uuid(),
            })}
          >
            {(formik) => (
              <form onSubmit={formik.handleSubmit}>
                <BulkActionDialogContent
                  onPointerDownOutside={resetModalState}
                  onEscapeKeyDown={resetModalState}
                >
                  <BulkActionDialogTitle>
                    Remove Contacts from Sequence
                  </BulkActionDialogTitle>
                  <BulkActionDialogDescription>
                    Select the sequence you want to remove the contact from. (This will
                    mark them as finished within the sequence)
                  </BulkActionDialogDescription>
                  <VStack gap={2}>
                    <FormFieldWrapper name="sequenceId" label="Sequence">
                      <SingleSelect
                        closeOnClick
                        defaultPlaceholder={getSequencePlaceholder(
                          selectedSequenceId,
                          sequences
                        )}
                        options={sequences.map((sequence) => ({
                          type: sequence.title ?? '',
                          value: sequence.id ?? '',
                        }))}
                        setSelectItem={(selectedSequenceId) => {
                          setSelectedSequenceId(selectedSequenceId);
                          formik.setFieldTouched('sequenceId', true);
                          formik.setFieldValue('sequenceId', selectedSequenceId);
                        }}
                        selectItem={selectedSequenceId}
                        isDropdown={true}
                      />
                    </FormFieldWrapper>
                  </VStack>
                  <DialogFooter justify="between" css={{ mt: 0 }}>
                    <HStack
                      gap={1}
                      css={{ width: '100%', justifyContent: 'space-between' }}
                    >
                      <Label css={{ mb: 0 }}>
                        {totalContacts > 0 ? `Total Contacts: ${totalContacts}` : ''}
                      </Label>
                      <HStack>
                        <DialogClose asChild>
                          <Button
                            variant="gray"
                            css={{ mr: '$1' }}
                            onClick={resetModalState}
                          >
                            Cancel
                          </Button>
                        </DialogClose>
                        <DialogClose asChild>
                          <Button type="submit" disabled={!formik.dirty}>
                            Confirm
                          </Button>
                        </DialogClose>
                      </HStack>
                    </HStack>
                  </DialogFooter>
                </BulkActionDialogContent>
              </form>
            )}
          </Formik>
        </DialogOverlay>
      </DialogPortal>
    </Dialog>
  );
};

function getSequencePlaceholder(selectedId: string, sequences: Sequence[]): string {
  const selectedSequence = sequences.find((sequence) => sequence.id == selectedId);
  return selectedSequence?.title ?? 'Select Sequence';
}
