/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import Confetti from 'react-confetti';
import { useWindowSize } from 'react-use';

import { usePageView } from '@/shared/hooks';

import { useCampaignsContext } from './context/CampaignsContext';
import { CampaignsList } from './list';

export const AllCampaigns = (): JSX.Element => {
  // Change analytics page
  usePageView();

  const {
    campaignsState: { campaigns, loading },
    showConfetti,
  } = useCampaignsContext();

  const { width, height } = useWindowSize();

  return (
    <>
      {showConfetti && <Confetti width={width} height={height} />}
      <CampaignsList
        title="SMS"
        url="all"
        campaigns={campaigns.filter((c) => c.status != 'archived')}
        loading={loading}
      />
    </>
  );
};
