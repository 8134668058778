import * as SelectPrimitive from '@radix-ui/react-select';
import React from 'react';
import { HiChevronDown, HiChevronUp } from 'react-icons/hi';

import { ChannelTypes } from '@/shared/types/channels';
import {
  Fieldset,
  Label,
  Select,
  SelectContent,
  SelectGroup,
  SelectIcon,
  SelectItem,
  SelectItemIndicator,
  SelectItemText,
  SelectLabel,
  SelectScrollUpButton,
  SelectTrigger,
  SelectValue,
  SelectViewport,
} from '@/shared/ui';

type SelectTypeProps = {
  /* location channel type */
  type: ChannelTypes;
  /* set location channel type */
  setType: (provider: ChannelTypes) => void;
};

export const SelectType = (props: SelectTypeProps) => {
  const { type, setType } = props;
  return (
    <Fieldset>
      <Label>Channel Type</Label>
      <Select
        defaultValue={ChannelTypes.PHONE}
        value={type as string}
        onValueChange={setType as React.Dispatch<React.SetStateAction<string>>}
      >
        <SelectTrigger aria-label="action-select-trigger">
          <SelectValue />
          <SelectIcon>
            <HiChevronDown />
          </SelectIcon>
        </SelectTrigger>
        <SelectPrimitive.Portal>
          <SelectContent css={{ zIndex: '9999' }}>
            <SelectScrollUpButton>
              <HiChevronUp />
            </SelectScrollUpButton>
            <SelectViewport>
              <SelectGroup>
                <SelectLabel>Channel Types</SelectLabel>
                {TYPES.map((type) => (
                  <SelectItem key={type.value} value={type.value}>
                    <SelectItemIndicator />
                    <SelectItemText>{type.label}</SelectItemText>
                  </SelectItem>
                ))}
              </SelectGroup>
            </SelectViewport>
          </SelectContent>
        </SelectPrimitive.Portal>
      </Select>
    </Fieldset>
  );
};

export const TYPES = [
  { value: ChannelTypes.PHONE, label: 'Phone' },
  { value: ChannelTypes.EMAIL, label: 'Email' },
  { value: ChannelTypes.WHATSAPP, label: 'WhatsApp' },
];
