export const default_signature_object = {
  id: 'unique-id-for-lists',
  label: 'Signature',
  key: 'signature',
  inserted_at: 'current-timestamp',
  updated_at: 'current-timestamp',
  custom_properties: [
    {
      default: '',
      id: 'signature-name',
      label: 'Name',
      type: 'text',
      key: 'name',
      required: true,
      inserted_at: 'current-timestamp',
      updated_at: 'current-timestamp',
      custom_object_id: 'unique-id-for-signature',
    },
    {
      default: '',
      id: 'signature-body',
      label: 'Body',
      type: 'text',
      key: 'body',
      required: true,
      inserted_at: 'current-timestamp',
      updated_at: 'current-timestamp',
      custom_object_id: 'unique-id-for-signature',
    },
    {
      default: 'current-timestamp',
      id: 'signature-inserted_at',
      label: 'Inserted At',
      type: 'date',
      key: 'inserted_at',
      required: true,
      inserted_at: 'current-timestamp',
      updated_at: 'current-timestamp',
      custom_object_id: 'unique-id-for-signature',
    },
    {
      default: 'current-timestamp',
      id: 'signature-updated_at',
      label: 'Updated At',
      type: 'date',
      key: 'updated_at',
      required: true,
      inserted_at: 'current-timestamp',
      updated_at: 'current-timestamp',
      custom_object_id: 'unique-id-for-signature',
    },
  ],
};
