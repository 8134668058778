/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { HiClipboardCopy, HiPencil, HiTrash } from 'react-icons/hi';
import { useParams } from 'react-router-dom';
import { toast } from 'sonner';

import { PageLayout } from '@/shared/layouts/PageLayout';
import { Badge, Box, Flex, HStack, IconButton, Text } from '@/shared/ui';
import { styled } from '@/stitches.config';

import { useIntegrations } from '../context/IntegrationsContext';
import IntegrationConfigurationsState, {
  useIntegrationConfigurations,
} from './context/IntegrationConfigurationContext';
import { IntegrationConfiguration } from './IntegrationConfiguration';

export const IntegrationConfigurations = () => {
  const { integrationsState, getIntegration } = useIntegrations();
  const { current: currentIntegration } = integrationsState;

  const configurationContext = useIntegrationConfigurations();
  const { configurationsState, getConfigurations, deleteConfiguration } =
    configurationContext;
  const { loading, configurations } = configurationsState;

  const params = useParams<{ id: string }>();
  const applicationId = params.id;

  useEffect(() => {
    getIntegration(applicationId);
    getConfigurations(applicationId);
  }, []);

  return (
    <PageLayout
      breadcrumbs={[
        { title: 'Integrations', path: '/integrations' },
        {
          title: `${currentIntegration?.name}`,
          path: `/integrations/${applicationId}`,
        },
        { title: 'Configurations', path: '/integration/configurations' },
      ]}
    >
      <Box
        css={{
          p: 30,
          minHeight: '100vh',
          height: 'fit-content',
          overflow: 'auto',
        }}
      >
        {!loading && configurations.length > 0 && (
          <StyledTable>
            <tbody>
              <tr>
                <th>Forwarding Email</th>
                <th>Status</th>
                <th></th>
              </tr>
              {configurations.map((configuration) => (
                <tr key={configuration.id}>
                  <td>
                    <HStack>
                      <Box>{configuration.client_response?.forward_to ?? ''}</Box>
                      <IconButton
                        onClick={() =>
                          navigator.clipboard
                            .writeText(configuration.client_response?.forward_to ?? '')
                            .then(() => {
                              // Show confirmation message here
                              toast.success('Email Address Copied to clipboard');
                            })
                            .catch(() => {
                              // Handle errors here
                              toast.error('Failed to copy to clipboard');
                            })
                        }
                      >
                        <HiClipboardCopy />
                      </IconButton>
                    </HStack>
                  </td>
                  <td>
                    <Badge variant="green">{configuration.status}</Badge>
                  </td>
                  <td>
                    <Flex justify="end">
                      <IconButton
                        variant="outline"
                        onClick={() =>
                          deleteConfiguration(applicationId, configuration.id)
                        }
                      >
                        <HiTrash />
                      </IconButton>
                      <Box css={{ ml: '10px' }}>
                        <IntegrationConfigurationsState>
                          <IntegrationConfiguration
                            applicationId={applicationId}
                            configurationId={configuration.id}
                          >
                            <IconButton variant="outline">
                              <HiPencil />
                            </IconButton>
                          </IntegrationConfiguration>
                        </IntegrationConfigurationsState>
                      </Box>
                    </Flex>
                  </td>
                </tr>
              ))}
            </tbody>
          </StyledTable>
        )}
        {loading && (
          <StyledTable>
            <tbody>
              <tr>
                <th>Forwarding Email</th>
                <th></th>
              </tr>
              {Array.from({ length: 5 }).map((_, index) => (
                <tr key={index}>
                  <td>
                    <HStack>
                      <Box>loading...</Box>
                    </HStack>
                  </td>
                  <td>
                    <IconButton variant="outline">
                      <HiPencil />
                    </IconButton>
                  </td>
                </tr>
              ))}
            </tbody>
          </StyledTable>
        )}
        <Flex align="center" css={{ height: '100%', width: '100%' }}>
          {!loading && configurations.length == 0 && (
            <EmptyStateContainer align="center" justify="center" direction="column">
              <Text css={{ fontWeight: 600 }}>No Integration Configuration Found</Text>
              <Text css={{ mt: 10 }}>
                Return to the integrations page to add a configuration
              </Text>
            </EmptyStateContainer>
          )}
        </Flex>
      </Box>
    </PageLayout>
  );
};

const EmptyStateContainer = styled(Flex, {
  p: 20,
  height: '100%',
  width: '100%',
});

const StyledTable = styled('table', {
  width: '100%',
  borderCollapse: 'collapse',
  borderSpacing: 0,
  outline: '1px solid lightgrey',
  backgroundColor: 'white',
  borderRadius: '8px',
  'tr > th': {
    textAlign: 'left',
    borderBottom: '1px solid lightgrey',
    padding: '10px',
    fontSize: '14px', // Add this line
    backgroundColor: '$color$slate2',
  },
  'tr > th:first-child': {
    borderTopLeftRadius: '8px',
  },
  'tr > th:last-child': {
    borderTopRightRadius: '8px',
  },
  'tr > td': {
    padding: '10px',
    fontSize: '12px', // Add this line
  },
  'tr:not(:last-child) > td': {
    borderBottom: '1px solid lightgrey',
  },
  'tr > td:last-child': {
    textAlign: 'right',
  },
});
