export const default_contact_message_object = {
  id: 'unique-id-for-contact_message',
  label: 'Contact Message',
  key: 'contact_message',
  inserted_at: 'current-timestamp',
  updated_at: 'current-timestamp',
  custom_properties: [
    {
      default: '',
      id: 'contact_message-response',
      label: 'Response',
      type: 'text',
      key: 'body',
      required: true,
      inserted_at: 'current-timestamp',
      updated_at: 'current-timestamp',
      custom_object_id: 'unique-id-for-contact',
    },
    {
      default: 'current-timestamp',
      id: 'contact_message-responded_at',
      label: 'Responded At',
      type: 'date',
      key: 'inserted_at',
      required: true,
      inserted_at: 'current-timestamp',
      updated_at: 'current-timestamp',
      custom_object_id: 'unique-id-for-contact',
    },
  ],
};
