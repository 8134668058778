import * as SelectPrimitive from '@radix-ui/react-select';
import { Formik } from 'formik';
import { Dispatch, SetStateAction, useState } from 'react';
import {
  HiChevronDown,
  HiChevronUp,
  HiClipboardCopy,
  HiLightningBolt,
  HiTemplate,
  HiX,
} from 'react-icons/hi';

import { CreateCard } from '@/shared/components/CreateCard';
import {
  action_values,
  defaultActions,
} from '@/shared/components/triggers/actions/types';
import { useDisclosure, useProgress } from '@/shared/hooks';
import { Action, ActionTypes } from '@/shared/types/triggers/actions';
import {
  Button,
  Dialog,
  DialogClose,
  DialogCloseIcon,
  DialogContent,
  DialogFooter,
  DialogOverlay,
  DialogPortal,
  DialogTitle,
  DialogTrigger,
  Fieldset,
  Flex,
  Label,
  Select,
  SelectContent,
  SelectGroup,
  SelectIcon,
  SelectItem,
  SelectItemIndicator,
  SelectItemText,
  SelectLabel,
  SelectScrollUpButton,
  SelectTrigger,
  SelectValue,
  SelectViewport,
  Text,
  VStack,
} from '@/shared/ui';

import { ActionRenderer } from './ActionRenderer';

type StepProps = {
  /* The step to display */
  setStep: Dispatch<SetStateAction<number>>;
  /* The action to add when the save button is clicked */
  addAction?: (action: Action) => void;
  /* Callback for when the dialog is closed */
  onClose?: () => void;
};

const SelectCreateType = (props: StepProps) => {
  const { setStep } = props;
  return (
    <>
      <DialogTitle variant="bold">Add Action</DialogTitle>
      <Flex justify="between" align="center" css={{ height: '100%' }}>
        <CreateCard
          title="New Action"
          description="Create a new action from scratch."
          icon={<HiLightningBolt />}
          onClick={() => setStep(1)}
        />
        <CreateCard
          title="Action Template"
          description="Copy an action from our library."
          icon={<HiTemplate />}
          badgeText="Coming Soon"
          badgeColor="pink"
          disabled={true}
        />
        <CreateCard
          title="Clone Action"
          description="Clone one of your existing actions.."
          icon={<HiClipboardCopy />}
          badgeText="Coming Soon"
          badgeColor="pink"
          disabled={true}
        />
      </Flex>
    </>
  );
};

const CreateNewAction = (props: StepProps) => {
  const { setStep, onClose, addAction } = props;

  const [action, setAction] = useState<ActionTypes>(ActionTypes.SEND_MESSAGE);

  const handleAddAction = (a: Action) => {
    if (addAction && onClose) {
      addAction(a);
      onClose();
      setStep(0);
    }
  };

  const defaultAction = (type: ActionTypes) =>
    defaultActions.find((action) => action.type === type);

  return (
    <>
      <DialogTitle variant="bold">Create New Action</DialogTitle>
      <Flex justify="between" align="center" direction="column" css={{ height: '100%' }}>
        <VStack gap="2" css={{ width: '100%' }}>
          <Fieldset>
            <Label>Select Action Type</Label>
            <SelectActionType action_type={action} setAction={setAction} />
          </Fieldset>
          <Formik
            enableReinitialize
            initialValues={{ actions: [defaultAction(action)?.default as Action] }}
            onSubmit={(values) => handleAddAction(values.actions[0])}
          >
            {(formik) => (
              <form onSubmit={formik.handleSubmit}>
                <ActionRenderer
                  action={defaultAction(action)?.default as Action}
                  index={0}
                />
                <DialogFooter justify="between" css={{ mt: 20 }}>
                  <Button type="button" variant="gray" onClick={() => setStep(0)}>
                    Back
                  </Button>
                  <Button type="submit">Create Action</Button>
                </DialogFooter>
              </form>
            )}
          </Formik>
        </VStack>
      </Flex>
    </>
  );
};

type SelectActionTypeProps = {
  /* The action type to display */
  action_type: ActionTypes;
  /* Callback for when the action type is changed */
  setAction: Dispatch<SetStateAction<ActionTypes>> | ((action: ActionTypes) => void);
};

export const SelectActionType = (props: SelectActionTypeProps) => {
  const { action_type, setAction } = props;

  return (
    <Select
      defaultValue={ActionTypes.SEND_MESSAGE}
      value={action_type}
      onValueChange={setAction as Dispatch<SetStateAction<string>>}
    >
      <SelectTrigger aria-label="action-select-trigger">
        <SelectValue />
        <SelectIcon>
          <HiChevronDown />
        </SelectIcon>
      </SelectTrigger>
      <SelectPrimitive.Portal>
        <SelectContent css={{ zIndex: 999999 }}>
          <SelectScrollUpButton>
            <HiChevronUp />
          </SelectScrollUpButton>
          <SelectViewport>
            <SelectGroup>
              <SelectLabel>Action Types</SelectLabel>
              {action_values.map((action) => (
                <SelectItem key={action.value} value={action.value}>
                  <SelectItemIndicator />
                  <SelectItemText>{action.label}</SelectItemText>
                </SelectItem>
              ))}
            </SelectGroup>
          </SelectViewport>
        </SelectContent>
      </SelectPrimitive.Portal>
    </Select>
  );
};

const CreateActionFromTemplate = (props: StepProps) => {
  const { setStep } = props;
  return (
    <>
      <DialogTitle variant="bold">Select Action Template</DialogTitle>
      <Flex justify="between" align="center" css={{ height: '100%' }}>
        <VStack>
          <Text>Test</Text>
        </VStack>
      </Flex>
      <DialogFooter justify="between">
        <Button type="button" variant="gray" onClick={() => setStep(0)}>
          Back
        </Button>
        <DialogClose asChild>
          <Button>Create Action</Button>
        </DialogClose>
      </DialogFooter>
    </>
  );
};

const CloneActionFromTemplate = (props: StepProps) => {
  const { setStep } = props;
  return (
    <>
      <DialogTitle variant="bold">Clone an Action</DialogTitle>
      <Flex justify="between" align="center" css={{ height: '100%' }}>
        <VStack>
          <Text>Action Name</Text>
        </VStack>
      </Flex>
      <DialogFooter justify="between">
        <Button type="button" variant="gray" onClick={() => setStep(0)}>
          Back
        </Button>
        <DialogClose asChild>
          <Button>Add Action</Button>
        </DialogClose>
      </DialogFooter>
    </>
  );
};

type AddActionProps = {
  /* Callback for when an action is added */
  addAction: (action: Action) => void;
};

export const AddAction = (props: AddActionProps) => {
  const { addAction } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [currentStep, setCurrentStep] = useProgress();

  const renderStep = (step: number) => {
    switch (step) {
      case 0:
        return <SelectCreateType setStep={setCurrentStep} />;
      case 1:
        return (
          <CreateNewAction
            setStep={setCurrentStep}
            addAction={addAction}
            onClose={onClose}
          />
        );
      case 2:
        return (
          <CreateActionFromTemplate setStep={setCurrentStep} addAction={addAction} />
        );
      case 3:
        return <CloneActionFromTemplate setStep={setCurrentStep} addAction={addAction} />;
      default:
        return <SelectCreateType setStep={setCurrentStep} />;
    }
  };

  return (
    <Dialog open={isOpen}>
      <DialogTrigger asChild>
        <Button onClick={onOpen} variant="gray">
          Add Action
        </Button>
      </DialogTrigger>
      <DialogPortal>
        <DialogOverlay>
          <DialogContent
            onEscapeKeyDown={onClose}
            onPointerDownOutside={onClose}
            css={{ overflow: 'auto', maxWidth: 800, width: '95%' }}
          >
            {renderStep(currentStep)}
            <DialogCloseIcon onClick={onClose} size="2">
              <HiX size="15px" />
            </DialogCloseIcon>
          </DialogContent>
        </DialogOverlay>
      </DialogPortal>
    </Dialog>
  );
};
