import React, { useState } from 'react';
import { toast } from 'sonner';

import { useDisclosure } from '@/shared/hooks';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogOverlay,
  AlertDialogPortal,
  AlertDialogTitle,
  AlertDialogTrigger,
  Button,
  Flex,
  Input,
  VStack,
} from '@/shared/ui';
import i18next from '@/shared/utils/translation';
import { styled } from '@/stitches.config';

type CampaignConfirmationProps = {
  /** the text to show on the confirmation button */
  buttonCopy: string;
  /** number of contacts to require information for */
  numberOfContacts: number;
  /** function to execute on confirmation */
  onConfirm: () => void;
  /** passed DOM element will be used for the AlertDialogTrigger */
  children: React.ReactNode;
  /** is the campaign scheduled? */
  isScheduled: boolean;
};

export const CampaignConfirmation = (props: CampaignConfirmationProps) => {
  const { children, numberOfContacts, onConfirm, isScheduled } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [inputValue, setInputValue] = useState('');

  const generateDialogTitle = () => {
    const contactText = numberOfContacts === 1 ? 'contact' : 'contacts';

    if (isScheduled) {
      return numberOfContacts
        ? `Schedule to ${numberOfContacts} ${contactText}?`
        : 'Schedule Campaign';
    } else if (!numberOfContacts || numberOfContacts <= 0) {
      return 'Send Campaign';
    } else {
      return `Send to ${numberOfContacts} ${contactText}?`;
    }
  };

  const dialogTitle = generateDialogTitle();

  const onSend = () => {
    if (isValidInput()) {
      // if user confirms close dialog and execute function from props
      onConfirm();
      setInputValue('');
      onClose();
    } else {
      toast.error(i18next.t('number_of_contacts_error') as string);
    }
  };

  // if the number of contacts is 0 the input value should be the word 'confirm'
  // otherwise it should be the number of contacts
  // return true is the input value is valid
  const isValidInput = () => {
    return (
      Number(inputValue) === numberOfContacts ||
      inputValue?.toLocaleLowerCase() === 'confirm'
    );
  };

  return (
    <AlertDialog open={isOpen}>
      <AlertDialogTrigger asChild onClick={onOpen}>
        {children}
      </AlertDialogTrigger>
      <AlertDialogPortal>
        <AlertDialogOverlay />
        <AlertDialogContent onEscapeKeyDown={onClose} css={{ pt: 0 }}>
          <StyledAlertDialogTitle>{dialogTitle}</StyledAlertDialogTitle>
          <AlertDialogDescription css={{ m: '20px 0', lineHeight: 2 }}>
            {`You are about to ${isScheduled ? 'schedule' : 'send'} a campaign${
              numberOfContacts > 0
                ? ` to ${numberOfContacts} contact${numberOfContacts > 1 ? 's' : ''}`
                : ''
            }. Please type CONFIRM to proceed.`}
          </AlertDialogDescription>
          <VStack gap={5}>
            <Input
              size="3"
              placeholder={`${
                numberOfContacts < 1
                  ? 'Type CONFIRM'
                  : `Type ${numberOfContacts} or CONFIRM`
              }`}
              value={inputValue}
              onChange={(e: { target: { value: React.SetStateAction<string> } }) =>
                setInputValue(e.target.value)
              }
            />
            <Flex justify="end">
              <AlertDialogCancel asChild>
                <Button variant="gray" css={{ mr: 10 }} onClick={onClose}>
                  Cancel
                </Button>
              </AlertDialogCancel>
              <AlertDialogAction asChild>
                <Button variant="send" onClick={onSend} disabled={!isValidInput()}>
                  {props.buttonCopy || 'Send Campaign'}
                </Button>
              </AlertDialogAction>
            </Flex>
          </VStack>
        </AlertDialogContent>
      </AlertDialogPortal>
    </AlertDialog>
  );
};

const StyledAlertDialogTitle = styled(AlertDialogTitle, {
  minHeight: 50,
  marginInline: -20,
  mb: 0,
  color: '#00000',
  fontSize: 24,
  fontWeight: 700,
  padding: '20px 0 0px 20px',
  borderTopLeftRadius: '$3',
  borderTopRightRadius: '$3',
});
