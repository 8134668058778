import React from 'react';
import { useState } from 'react';
import Cropper from 'react-easy-crop';
import { useMedia } from 'react-use';

import { Spinner } from '@/shared/components/Icons';
import { Box, Flex, Slider, SliderRange, SliderThumb, SliderTrack } from '@/shared/ui';

const createImage = (url: string) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', (error) => reject(error));
    image.setAttribute('crossOrigin', 'anonymous');
    image.src = url;
  });

export const getCroppedImg = async (
  imageSrc: string,
  crop: { x: number; y: number; width: number; height: number }
) => {
  const image: any = await createImage(imageSrc);
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  /* setting canvas width & height allows us to
  resize from the original image resolution */
  canvas.width = 250;
  canvas.height = 250;

  ctx?.drawImage(
    image,
    crop.x,
    crop.y,
    crop.width,
    crop.height,
    0,
    0,
    canvas.width,
    canvas.height
  );

  return new Promise((resolve) => {
    canvas.toBlob((blob) => {
      resolve(blob);
    }, 'image/jpeg');
  });
};

type ImageCropperProps = {
  imageUrl: string;
  onCropComplete: (
    croppedArea: any,
    croppedAreaPixels: { x: number; y: number; width: number; height: number }
  ) => void;
  timeoutFlag: boolean;
};

const ImageCropper = (props: ImageCropperProps) => {
  const { timeoutFlag, imageUrl, onCropComplete } = props;
  const isDesktop = useMedia('(min-width: 912px)');
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  return (
    <Box css={{ width: '100%' }}>
      <Box>
        <Cropper
          image={imageUrl}
          crop={crop}
          zoom={zoom}
          cropShape="round"
          aspect={1}
          onCropChange={setCrop}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
        />
        {timeoutFlag ? (
          <Flex align="center" justify="center">
            <Spinner size={2} css={{ position: 'absolute', zIndex: 1 }} />
          </Flex>
        ) : null}
      </Box>
      <Box css={{ width: '50%', position: 'relative', bottom: -260, left: 20 }}>
        <Slider
          min={1}
          max={3}
          step={0.1}
          aria-labelledby="zoom"
          defaultValue={[zoom]}
          onValueChange={(zoom: any) => setZoom(zoom)}
          css={{ width: isDesktop ? '200px' : '150px' }}
        >
          <SliderTrack>
            <SliderRange />
          </SliderTrack>
          <SliderThumb />
        </Slider>
      </Box>
    </Box>
  );
};

export default ImageCropper;
