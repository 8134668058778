import * as AspectRatioPrimitive from '@radix-ui/react-aspect-ratio';
import React, { useEffect, useState } from 'react';
import { HiDownload, HiShare } from 'react-icons/hi';
import { useCopyToClipboard } from 'react-use';
import { toast } from 'sonner';

import { HelpCard } from '@/settings/help/HelpCard';
import * as API from '@/shared/api/conversations';
import { getFileType, isValidAttachment } from '@/shared/components/attachments';
import { FileAttachmentIcon, FileType } from '@/shared/components/attachments/File';
import { MediaPreviewContainer } from '@/shared/components/attachments/previewer';
import {
  ConversationAttachmentType,
  ConversationMessageType,
} from '@/shared/types/conversations';
import {
  Box,
  Flex,
  HStack,
  IconButton,
  Skeleton,
  Text,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
  VStack,
} from '@/shared/ui';
import { styled } from '@/stitches.config';

export const AspectRatio = AspectRatioPrimitive;

type AttachmentsPanelProps = {
  conversationId?: string;
};

export const AttachmentsPanel = (props: AttachmentsPanelProps) => {
  const { conversationId } = props;

  const [loading, setLoading] = useState(true);
  const [messages, setMessages] = useState<Array<ConversationMessageType>>([]);

  useEffect(() => {
    if (conversationId) {
      setLoading(true);
      listAttachments(conversationId);
    } else {
      setLoading(false);
    }
  }, [conversationId]);

  const listAttachments = async (id: string) => {
    if (id) {
      const messages_with_attachments = await API.searchConversationAttachments(id);
      setMessages(messages_with_attachments?.messages || []);
      setLoading(false);
    }
  };

  if (loading) {
    return <AttachmentsLoadingState />;
  }

  if (messages.length === 0 && !loading) {
    return <AttachmentsEmptyState />;
  }

  return (
    <Flex css={{ p: 20, height: '100%', width: '100%' }} direction="column">
      <VStack gap={3}>
        {messages
          ?.sort((a, b) => (b || {}).inserted_at.localeCompare((a || {}).inserted_at))
          .map((message, index) => {
            return <AttachmentsList key={index} attachments={message.attachments} />;
          })}
      </VStack>
    </Flex>
  );
};

type AttachmentsListProps = {
  attachments: Array<ConversationAttachmentType> | null[];
};

const AttachmentsList = (props: AttachmentsListProps) => {
  const { attachments } = props;

  // filter attachments we can't display
  const filteredAttachments = attachments.filter(
    (a) => a !== null && isValidAttachment(a.url) === true
  );

  return (
    <>
      {filteredAttachments?.map((attachment, index) => {
        return <AttachmentCard attachment={attachment} key={`attachment-${index}`} />;
      })}
    </>
  );
};

type AttachmentCardProps = {
  attachment: ConversationAttachmentType | null;
};

export const AttachmentCard = (props: AttachmentCardProps) => {
  const { attachment } = props;
  let url = '';
  let file_name = '';

  if (attachment) {
    url = attachment.url;
    const execResult = /[^/]*$/.exec(attachment.url || '');
    file_name = execResult ? execResult[0] : '';
  }

  const [, copyToClipboard] = useCopyToClipboard();

  const handleCopyClick = () => {
    copyToClipboard(url);
    toast.success('Copied to clipboard');
  };

  return (
    <MediaPreviewContainer url={attachment?.url || ''}>
      <AttachmentCardContainer align="center" justify="between">
        <HStack gap={1}>
          <Flex>{renderAttachmentType(attachment?.url || '')}</Flex>
          <Flex direction="column" align="start" css={{ ml: 10 }}>
            <AttachmentFileName>{file_name ? file_name[0] : ''}</AttachmentFileName>
            <Text size={1}>Shared by a user</Text>
          </Flex>
        </HStack>
        <HStack>
          <Tooltip>
            <a href={url} download target="_blank" rel="noreferrer">
              <IconButton size={2}>
                <HiDownload size={18} />
              </IconButton>
            </a>
            <TooltipContent>Download Attachment</TooltipContent>
          </Tooltip>
          <Tooltip>
            <TooltipTrigger asChild>
              <IconButton size={2} onClick={() => handleCopyClick()}>
                <HiShare />
              </IconButton>
            </TooltipTrigger>
            <TooltipContent>Copy Link</TooltipContent>
          </Tooltip>
        </HStack>
      </AttachmentCardContainer>
    </MediaPreviewContainer>
  );
};

const AttachmentsLoadingState = () => {
  return (
    <Flex css={{ width: '100%', p: 20 }} direction="column">
      <VStack gap={3}>
        {Array.from({ length: 10 }, (_: any, k: React.Key | null | undefined) => (
          <AttachmentCardContainer align="center" key={k}>
            <Skeleton css={{ height: 40, width: 40, borderRadius: 4 }} />
            <Flex>
              <VStack></VStack>
            </Flex>
          </AttachmentCardContainer>
        ))}
      </VStack>
    </Flex>
  );
};

const AttachmentsEmptyState = () => {
  return (
    <Flex
      css={{
        p: 20,
        height: '100%',
        width: '100%',
      }}
      direction="column"
    >
      <HelpCard
        title="Send Attachments"
        description="Send media to your contacts."
        loom_id="49580aa26e8348f5baeb838ce016202f"
      />
    </Flex>
  );
};

const AttachmentCardContainer = styled(Flex, {
  fontSize: 13,
  py: 5,
  px: 10,
  width: '100%',
  height: 60,
  borderRadius: 4,
  boxShadow: 'rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;',
  '&:hover': {
    backgroundColor: '$slate2',
  },
  cursor: 'pointer',
});

export const AttachmentFileName = styled(Box, {
  fontSize: 13,
  fontWeight: 600,
  display: '-webkit-box',
  '-webkit-line-clamp': 1,
  '-webkit-box-orient': 'vertical',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: 120,
});

export const Img = styled('img', {
  objectFit: 'cover',
  width: '100%',
  height: '100%',
});

type FileAttachmentProps = {
  url?: string;
  type: FileType;
};

export const FileAttachment = (props: FileAttachmentProps) => {
  const { type } = props;
  return (
    <Flex>
      <Flex
        justify="center"
        align="center"
        css={{
          borderRadius: 6,
          height: 40,
          width: 40,
          backgroundColor: 'rgb(240, 243, 249)',
        }}
      >
        <FileAttachmentIcon type={type} />
      </Flex>
    </Flex>
  );
};

type ImageAttachmentProps = {
  url: string;
};

export const ImageAttachment = (props: ImageAttachmentProps) => {
  const { url } = props;
  return (
    <Box
      css={{
        width: 40,
        height: 40,
        borderRadius: 6,
        overflow: 'hidden',
      }}
    >
      <AspectRatio.Root ratio={1 / 1}>
        <Img src={url || ''} alt="attachment" />
      </AspectRatio.Root>
    </Box>
  );
};

export const renderAttachmentType = (url: string): JSX.Element | null => {
  const fileType = getFileType(url);

  switch (fileType) {
    case FileType.Image:
      return <ImageAttachment url={url} />;
    case FileType.Pdf:
    case FileType.Spreadsheet:
    case FileType.Doc:
    case FileType.Csv:
    case FileType.Presentation:
    case FileType.Zip:
    case FileType.Text:
      return <FileAttachment url={url} type={fileType} />;
    case FileType.Audio:
      return <FileAttachment url={url} type={fileType} />;
    default:
      return (
        <Flex
          align="center"
          justify="center"
          css={{
            width: 40,
            height: 40,
            borderRadius: 6,
            overflow: 'hidden',
          }}
        >
          <HiDownload size={18} />
        </Flex>
      );
  }
};
