/* eslint-disable react-hooks/exhaustive-deps */
import { isObject } from 'lodash';
import React, { useState } from 'react';
import { HiChevronDown, HiChevronRight, HiSearch, HiX } from 'react-icons/hi';

import { useDisclosure } from '@/shared/hooks';
import { RowValueType } from '@/shared/types/data';
import {
  Box,
  Dialog,
  DialogClose,
  DialogCloseIcon,
  DialogContent,
  DialogOverlay,
  DialogPortal,
  DialogTitle,
  DialogTrigger,
  Flex,
  IconButton,
  Input,
} from '@/shared/ui';
import { capitalizeWords } from '@/shared/utils/dromo';
import { styled } from '@/stitches.config';

import { SearchIconContainer } from '../lists';

export const RecordContentDialog = ({
  value,
}: {
  value: Array<RowValueType> | RowValueType;
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [contactValue, setContactValue] = useState('');

  const closeDialog = () => {
    onClose();
  };

  return (
    <>
      <Dialog open={isOpen} modal={false}>
        <DialogTrigger asChild>
          <Box
            data-testid="record-content-dialog-trigger"
            onClick={() => onOpen()}
            css={{
              maxWidth: 150,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              cursor: 'pointer',
            }}
          >
            {JSON.stringify(value)}
          </Box>
        </DialogTrigger>
        <DialogPortal>
          <DialogOverlay as="div" css={{ zIndex: 999999 }}>
            <DialogContent
              css={{
                zIndex: 999999,
                padding: 16,
                transform: 'none',
                top: 150,
                right: 50,
                left: 'auto',
              }}
              onEscapeKeyDown={() => closeDialog()}
              onPointerDownOutside={() => closeDialog()}
            >
              <DialogTitle variant="bold">Cell Details</DialogTitle>
              <Box>
                <Box css={{ position: 'relative', width: '100%', mb: 12 }}>
                  <SearchIconContainer css={{ left: 10 }}>
                    <HiSearch />
                  </SearchIconContainer>
                  <Input
                    data-testid="search-input"
                    type="text"
                    placeholder="Search"
                    value={contactValue}
                    css={{
                      pl: 38,
                      background: '#0144FF0F',
                      border: 'none',
                      boxShadow: 'none!important',
                    }}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setContactValue(e.target.value)
                    }
                  />
                  {contactValue && (
                    <SearchControlsContainer css={{ right: 5 }}>
                      <IconButton onClick={() => setContactValue('')} css={{ mt: 2 }}>
                        <HiX size={15} />
                      </IconButton>
                    </SearchControlsContainer>
                  )}
                </Box>
                <Box
                  css={{
                    maxHeight: 500,
                    overflow: 'auto',
                    border: '1px solid #00003B0D',
                    padding: 12,
                    borderRadius: 4,
                  }}
                >
                  <RowDialogContent
                    searchValue={contactValue}
                    value={value as RowValueType}
                  />
                </Box>
              </Box>
              <DialogClose asChild>
                <DialogCloseIcon
                  css={{ top: 14, right: 14 }}
                  onClick={() => closeDialog()}
                  size="2"
                >
                  <HiX size="15px" />
                </DialogCloseIcon>
              </DialogClose>
            </DialogContent>
          </DialogOverlay>
        </DialogPortal>
      </Dialog>
    </>
  );
};

const RowDialogContent = ({
  value,
  searchValue,
}: {
  value: RowValueType;
  searchValue: string;
}) => {
  const filteredValue =
    searchValue && searchValue.length > 2
      ? Object.keys(value)
          .filter(
            (key) =>
              key.toLowerCase().includes(searchValue.toLowerCase()) ||
              value[key]?.toString().toLowerCase().includes(searchValue.toLowerCase()) ||
              isObject(value[key])
          )
          .reduce((obj, key) => {
            return {
              ...obj,
              [key]: value[key],
            };
          }, {})
      : value;

  return (
    <Box>
      {Object.keys(filteredValue).map((key: string) => (
        <>
          <RowContent key={key} searchValue={searchValue} value={value} valueKey={key} />
        </>
      ))}
    </Box>
  );
};

const RowContent = ({
  value,
  valueKey,
  searchValue,
}: {
  value: RowValueType;
  valueKey: string;
  searchValue: string;
}) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <>
      {!!value[valueKey] && (
        <Box data-testid="row-content" css={{ mb: 8 }}>
          <span style={{ display: 'inline-block' }}>
            <Flex align="center" css={{ mb: 8 }}>
              <span
                style={{
                  fontSize: 14,
                  fontWeight: 770,
                }}
              >
                {capitalizeWords(
                  valueKey.replace(/[A-Z]/g, (letter) => ` ${letter.toLowerCase()}`)
                )}
              </span>
              :
              {isObject(value[valueKey]) && (
                <>
                  {expanded && (
                    <IconButton
                      data-testid="collapse-btn"
                      css={{ color: '$primaryButtonColor', boxShadow: 'none!important' }}
                    >
                      <HiChevronDown onClick={() => setExpanded(false)} />
                    </IconButton>
                  )}
                  {!expanded && (
                    <IconButton
                      data-testid="expand-btn"
                      css={{ color: '$primaryButtonColor', boxShadow: 'none!important' }}
                    >
                      <HiChevronRight onClick={() => setExpanded(true)} />
                    </IconButton>
                  )}
                </>
              )}
            </Flex>
          </span>
          <span style={{ marginLeft: 5 }}>
            {isObject(value[valueKey]) ? (
              <>
                {expanded && (
                  <Box css={{ mt: 5, ml: 10 }}>
                    <RowDialogContent
                      searchValue={searchValue}
                      value={value[valueKey] as unknown as RowValueType}
                    />
                  </Box>
                )}
              </>
            ) : (
              value[valueKey]
            )}
          </span>
        </Box>
      )}
    </>
  );
};

const SearchControlsContainer = styled(Box, {
  position: 'absolute',
  top: '18px',
  transform: 'translateY(-50%)',
  right: '5px',
});
