import React from 'react';
import { HiTrash } from 'react-icons/hi';

import { ConfirmationDialog } from '@/shared/components/ConfirmationDialog';
import { IconButton } from '@/shared/ui';

type DeleteQuestionProps = {
  alertAction: () => void;
  groups?: boolean;
  isWithIcon?: boolean;
};

export function DeleteQuestion(props: DeleteQuestionProps): JSX.Element {
  const handleDelete = () => {
    props.alertAction();
  };

  return (
    <ConfirmationDialog
      title="Delete Automated Question"
      description="Are you sure you want to delete your automated response for this question?"
      onConfirm={handleDelete}
      confirmButtonTitle="Delete Question"
      cancelButtonTitle="Cancel"
      testID="delete-automated-question-button"
    >
      <IconButton variant="outline" size="2" css={{ mr: 5 }}>
        <HiTrash />
      </IconButton>
    </ConfirmationDialog>
  );
}
