/* eslint-disable react-hooks/exhaustive-deps */
import { FormikValues, useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';

import { MessageEditorV2 } from '../../editor/v2';
import { TextInputProps } from '../../forms';

type FormEmailEditorProps = TextInputProps & {
  /* index */
  index: number;
};

export function ActionsEmailEditor(props: FormEmailEditorProps): JSX.Element {
  const formik = useFormikContext<FormikValues>();

  const textareaRef = React.useRef<HTMLTextAreaElement>(null);

  const [message, setMessage] = useState(
    formik.values.actions[props.index]?.params?.body || ''
  );

  useEffect(() => {
    formik.setFieldValue(`actions[${props.index}].params.body` as string, message);
  }, [message]);

  return (
    <MessageEditorV2
      message={message}
      setMessage={setMessage}
      textareaRef={textareaRef}
      showAddTranslate={true}
      showAddEmoji={true}
      placeholder={props.placeholder}
      isForm={true}
    />
  );
}
