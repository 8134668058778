import { api } from './api';

export type CreateBrandParams = {
  company_name: string;
  display_name: string;
  email: string;
  phone: string;
  street: string;
  city: string;
  state: string;
  postal_code: string;
  website: string;
  vertical: string;
  entity_type: string;
  business_registration_number: string;
  business_registration_type: string;
  alt_business_registration_number: string;
  alt_business_registration_type: string | null;
  business_identity: string;
  business_regions_of_operation: string;
  stock_symbol: string;
  stock_exchange: string;
  country: string;
  government_entity: boolean;
  business_type: string;
  ip_address: string;
  representative_job_position: string;
  representative_job_title: string;
  representative_name: string;
};

export type CreateCampaignParams = {
  description: string;
  use_case: string;
  sub_use_cases: Array<string>;
  opt_in_keywords: Array<string>;
  opt_out_keywords: Array<string>;
  help_keywords: Array<string>;
  help_message: string;
  opt_in_message: string;
  opt_out_message: string;
  message_flow: string;
  affiliate_marketing: boolean;
  age_gated: boolean;
  auto_renewal: boolean;
  embedded_link: boolean;
  embedded_phone: boolean;
  number_pool: boolean;
  subscriber_opt_in: boolean;
  subscriber_opt_out: boolean;
  subscriber_help: boolean;
  direct_lending: boolean;
  message_samples: Array<string>;
};

/*
  GET - get brands
*/

export const getBrands = () =>
  api.get('/ten_dlc/brands').then((res) => {
    return res.data.data;
  });

/*
  GET - get brands
*/

export const getCampaigns = () =>
  api.get('/ten_dlc/campaigns').then((res) => {
    return res.data.data;
  });

/*
  POST - create a new brand
*/

export const createBrand = (params: CreateBrandParams) =>
  api.post('/ten_dlc/brands', { brand: params }).then((res) => {
    return res.data;
  });

/*
  POST - create a new campaign
*/

export const createCampaign = (brandId: string, params: CreateCampaignParams) =>
  api.post(`/ten_dlc/brands/${brandId}/campaigns`, { campaign: params }).then((res) => {
    return res.data;
  });
