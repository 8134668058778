import { Action } from '../triggers/actions';

export type Keyword = {
  id?: string;
  /* the organization id */
  organization_id?: string;
  /* list of keywords */
  keywords?: string[];
  /* list of keywords to ignore */
  negate_keywords?: string[];
  /* list of actions to apply when keyword is triggered */
  actions?: Action[];
  /* is the keyword enabled */
  enabled?: boolean | string | null;
  /* does the keyword need to be an exact match */
  exact_match?: boolean;
  /* location_ids for creating a keyword */
  location_ids?: string[];
  /* what locations should the keyword trigger for? */
  locations?:
    | {
        id: string;
        name?: string | null;
        phone: string;
        timezone?: string | null;
      }[]
    | null;
  inserted_at?: string;
  updated_at?: string;
  body?: string | null; // deprecated
};

export enum KeywordActionTypes {
  GET_KEYWORDS = 'GET_KEYWORDS',
  CREATE_KEYWORD = 'CREATE_KEYWORD',
  UPDATE_KEYWORD = 'UPDATE_KEYWORD',
  DELETE_KEYWORD = 'DELETE_KEYWORD',
  SET_LOADING = 'SET_LOADING',
  SET_CURRENT = 'SET_CURRENT',
}

export type KeywordActions =
  | {
      type: KeywordActionTypes.GET_KEYWORDS;
      payload: Keyword[] | null[];
    }
  | {
      type: KeywordActionTypes.CREATE_KEYWORD;
      payload: Keyword | null;
    }
  | {
      type: KeywordActionTypes.UPDATE_KEYWORD;
      payload: Keyword | null;
    }
  | {
      type: KeywordActionTypes.DELETE_KEYWORD;
      payload: string | null;
    }
  | {
      type: KeywordActionTypes.SET_LOADING;
      payload: boolean;
    }
  | {
      type: KeywordActionTypes.SET_CURRENT;
      payload: Keyword | null;
    };
