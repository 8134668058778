import {
  AddToSequenceData,
  CreateLeadData,
  IntegrationConfiguration,
  SendMessageData,
  WhippyAction,
} from '@/shared/types/integrations';

import { AddToSequenceForm } from './components/AddToSequenceForm';
import { CreateLeadForm } from './components/CreateLeadForm';
import { SendMessageForm } from './components/SendMessageForm';

type Props = {
  configuration: IntegrationConfiguration;
  onClose: () => void;
};

export const JobBoardAutomationForm = (props: Props) => {
  const { configuration } = props;
  switch (configuration.data.action) {
    case WhippyAction.ADD_TO_SEQUENCE:
      return (
        <AddToSequenceForm
          data={configuration.data as AddToSequenceData}
          onClose={props.onClose}
        />
      );
    case WhippyAction.SEND_MESSAGE:
      return (
        <SendMessageForm
          data={configuration.data as SendMessageData}
          onClose={props.onClose}
        />
      );
    case WhippyAction.CREATE_LEAD:
      return (
        <CreateLeadForm
          data={configuration.data as CreateLeadData}
          onClose={props.onClose}
        />
      );
  }
};
