import React, { useEffect, useState } from 'react';

import { ContactSource } from '@/pages/contacts/context/constants';
import { useTags } from '@/pages/settings/organization/tags/context/TagsContext';
import { MultiSelect } from '@/shared/components/MultiSelect';
import { Contact } from '@/shared/types';
import { Group } from '@/shared/types/contacts/groups';
import { Tag } from '@/shared/types/tags';
import { Button, Flex, HStack } from '@/shared/ui';

import { useContacts } from './context/ContactContext';
import { AddGroup } from './groups/AddGroup';
import { useGroups } from './groups/context/GroupContext';
import { complicateContactFilters, simplifyContactFilters } from './utils';

export const sources = Object.values(ContactSource);

export const initialFiltersValues = { tags: [], sources: [] };

type ContactsFiltersProps = {
  /** modifies the array of checked contacts */
  setCheckedContacts: (checkedContacts: Array<Contact>) => void;
  /** saves the filters of the current group */
  saveFilters?: (filters: Record<string, any>) => void;
  /** is groups page? */
  isGroup?: boolean;
};

export const ContactsFilters = (props: ContactsFiltersProps): JSX.Element => {
  const tagsContext = useTags();
  const { tagsState } = tagsContext;
  const { allTags } = tagsState;
  const contactContext = useContacts();
  const { filterContacts } = contactContext;
  const [filters, setFilters] = useState(initialFiltersValues);
  const [emptyFilters, setEmptyFilters] = useState(true);

  const { groupsState, createGroup, updateGroup } = useGroups();
  const currentGroup = groupsState.current;

  useEffect(() => {
    if (currentGroup && currentGroup.filters_version === 'v1') {
      setFilters(simplifyContactFilters(currentGroup?.filters) || initialFiltersValues);
    }
  }, [currentGroup]);

  useEffect(() => {
    // do not apply filter changes when we are in groups page
    if (props.isGroup) {
      return;
    }

    if (filters.tags.length === 0 && filters.sources.length === 0) {
      setEmptyFilters(true);
      if (props.saveFilters) {
        props.saveFilters({});
      }
    } else if (filters.tags.length > 0 && filters.sources.length === 0) {
      const params = [
        {
          type: 'and',
          filters: [
            {
              attribute: 'tag.id',
              comparison: 'in',
              type: 'list',
              value: filters.tags,
            },
          ],
        },
      ];

      filterContacts(params);
      setEmptyFilters(false);
      if (props.saveFilters) {
        props.saveFilters({ search: params });
      }
    } else if (filters.tags.length === 0 && filters.sources.length > 0) {
      const params = [
        {
          type: 'and',
          filters: [
            {
              attribute: 'source',
              comparison: 'in',
              type: 'list',
              value: filters.sources,
            },
          ],
        },
      ];

      filterContacts(params);
      setEmptyFilters(false);
      if (props.saveFilters) {
        props.saveFilters({ search: params });
      }
    } else {
      const params = [
        {
          type: 'and',
          filters: [
            {
              attribute: 'tag.id',
              comparison: 'in',
              type: 'list',
              value: filters.tags,
            },
            {
              attribute: 'source',
              comparison: 'in',
              type: 'list',
              value: filters.sources,
            },
          ],
        },
      ];

      filterContacts(params);
      setEmptyFilters(false);
      if (props.saveFilters) {
        props.saveFilters({ search: params });
      }
    }
    props.setCheckedContacts([]);
  }, [filters]);

  const updateFilters = () => {
    const updatedGroup = {
      ...currentGroup,
      filters: complicateContactFilters(filters),
    };
    updateGroup(updatedGroup as Group);
  };

  const clearFilters = () => {
    setFilters({ tags: [], sources: [] });
  };

  return (
    <Flex align="center" css={{ padding: 0, width: '100%' }}>
      {allTags.length > 0 ? (
        <HStack>
          <MultiSelect
            isDropdown={true}
            defaultPlaceholder={'Tags'}
            options={allTags.map((tag: Tag) => ({
              type: tag.name,
              value: tag.id,
            }))}
            defaultSelectedItems={
              currentGroup && currentGroup.filters_version == 'v1'
                ? simplifyContactFilters(currentGroup.filters).tags
                : []
            }
            parentSelectedItems={filters}
            setParentSelectedItems={setFilters}
            clearSelectedItems={emptyFilters}
          />
          <MultiSelect
            isDropdown={true}
            defaultPlaceholder={'Source'}
            options={sources.map((source: string) => ({
              type: source,
              value: source,
            }))}
            defaultSelectedItems={
              currentGroup && currentGroup.filters_version == 'v1'
                ? simplifyContactFilters(currentGroup.filters).sources
                : []
            }
            parentSelectedItems={filters}
            setParentSelectedItems={setFilters}
            clearSelectedItems={emptyFilters}
          />
          {!emptyFilters && (
            <>
              {!currentGroup && (
                <Button variant="red" onClick={clearFilters} css={{ marginRight: '$1' }}>
                  Clear Filters
                </Button>
              )}
              {currentGroup ? (
                <Button variant="gray" onClick={updateFilters}>
                  Save
                </Button>
              ) : (
                <AddGroup filters={filters} createGroup={createGroup} />
              )}
            </>
          )}
        </HStack>
      ) : null}
    </Flex>
  );
};
