/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react-hooks/exhaustive-deps */
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { toast } from 'sonner';

import { useChannels } from '@/pages/settings/organization/channels/context/ChannelContext';
import { useDisclosure } from '@/shared/hooks';
import { Contact } from '@/shared/types';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogOverlay,
  DialogPortal,
  DialogTitle,
  DialogTrigger,
  Flex,
  HStack,
  Skeleton,
  VStack,
} from '@/shared/ui';

import { ChannelsTableErrorBoundaries } from './boundaries/ChannelsTableErrorBoundaries';
import ChannelsTable from './ChannelsTable';
import { useUnsubscribePreference } from './context/UnsubscribePreferenceContext';
import DateSelectionDialog from './DateSelectionDialog';
import PauseConfirmationDialog from './PauseConfirmationDialog';
import UnpauseConfirmationDialog from './UnpauseConfirmationDialog';
import UnsubscribeConfirmationDialog from './UnsubscribeConfirmationDialog';

type UnsubscribePreferenceType = {
  /** contact object **/
  contact: Contact;
  /** child button component **/
  children: React.ReactNode;
};

export const UnsubscribePreference = (props: UnsubscribePreferenceType): JSX.Element => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { channelsState } = useChannels();
  const { allChannels } = channelsState;

  const {
    unsubscribePreferenceState,
    setAllLocationsToShow,
    setIsLoading,
    setSelectedLocations,
    setSelectedActiveLocations,
    setSelectedPausedLocations,
  } = useUnsubscribePreference();

  const {
    isLoading,
    allLocationsToShow,
    activeLocations,
    pausedLocations,
    optedOutLocations,
    selectedLocations,
    selectedActiveLocations,
    selectedPausedLocations,
    pausedPreferences,
  } = unsubscribePreferenceState;

  // 2 types of button show or not
  const [showPauseButton, setShowPauseButton] = useState<boolean>(false);
  const [showUnpauseButton, setShowUnpauseButton] = useState<boolean>(false);

  // 4 dialogs show or not
  const [isUnpauseDialogOpen, setIsUnpauseDialogOpen] = useState(false);
  const [isPauseDialogOpen, setIsPauseDialogOpen] = useState(false);
  const [isDatePickerDialogOpen, setIsDatePickerDialogOpen] = useState(false);
  const [isUnsubscribeConfirmationDialogOpen, setIsUnsubscribeConfirmationDialogOpen] =
    useState(false);

  // is the "Custom" button selected or not
  const [isCustomSelected, setIsCustomSelected] = useState(false);

  // is the date picker open or not
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  // selectedPauseDuration = ['1 week', '1 month', 'Forever', 'Custom']
  const [selectedPauseDuration, setSelectedPauseDuration] = useState<string>('');
  // if selectedPauseDuration === 'Custom', then we need to select a date
  const [selectedCustomizedDate, setSelectedCustomizedDate] = useState<string>(
    dayjs(new Date()).format('MM/DD/YYYY')
  );

  // when selected locations change, update the followings:
  // 1) selected active locations, 2) selected paused locations
  // 3) show pause button or not, 4) show unpause button or not
  useEffect(() => {
    // get the id of the locations that the contact is opted in to
    const activeLocationIds = activeLocations?.map((location) => location.id);
    const pausedLocationIds = pausedLocations?.map((location) => location.id);

    // get the selected locations that the contact is opted in to
    const newSelectedOptedInAndNotPausedLocations = selectedLocations?.filter(
      (location) => activeLocationIds?.includes(location.id)
    );
    const newSelectedPausedLocations = selectedLocations?.filter((location) =>
      pausedLocationIds?.includes(location.id)
    );

    // set the selected active and paused locations
    setSelectedActiveLocations(newSelectedOptedInAndNotPausedLocations ?? []);
    setSelectedPausedLocations(newSelectedPausedLocations ?? []);

    // if there is at least 1 selected location, show the pause button
    setShowPauseButton(
      (newSelectedOptedInAndNotPausedLocations &&
        newSelectedOptedInAndNotPausedLocations.length > 0) ??
        false
    );

    // if there is at least 1 selected location, show the unpause button
    setShowUnpauseButton(
      (newSelectedPausedLocations && newSelectedPausedLocations.length > 0) ?? false
    );
  }, [selectedLocations]);

  // V2 code below
  const handleOpen = async () => {
    // every time we click "Un-subscribe Contact" button, we need to set isLoading to true
    setIsLoading(true);
    setAllLocationsToShow(props.contact.id, allChannels); // a starter to fetch all data
    onOpen();
  };

  // handler when users hit "Unpause" button
  const handleUnpauseClick = () => {
    if ((selectedPausedLocations && selectedPausedLocations.length > 0) ?? false) {
      setIsUnpauseDialogOpen(true);
    } else {
      toast.error('Please select paused locations to unpause.');
    }
  };

  // handler when users hit "Pause" button
  const handlePauseClick = () => {
    if ((selectedActiveLocations && selectedActiveLocations.length > 0) ?? false) {
      setIsPauseDialogOpen(true);
    } else {
      toast.error('Please select active locations to pause.');
    }
  };

  const handleUnsubscribeClick = () => {
    if ((selectedLocations && selectedLocations.length > 0) ?? false) {
      setIsUnsubscribeConfirmationDialogOpen(true);
    } else {
      toast.error('Please select locations to unsubscribe.');
    }
  };

  return (
    <Box css={{ width: '100%' }}>
      <Dialog open={isOpen} modal={false}>
        <DialogTrigger asChild>
          <Box onClick={() => handleOpen()}>{props.children}</Box>
        </DialogTrigger>
        <DialogPortal>
          <DialogOverlay as="div" />
          <DialogContent
            onEscapeKeyDown={onClose}
            css={{
              px: 0,
              width: '500px',
              filter:
                isUnpauseDialogOpen ||
                isPauseDialogOpen ||
                isDatePickerDialogOpen ||
                isUnsubscribeConfirmationDialogOpen
                  ? 'blur(1px)' // blur the background when any dialog is open
                  : 'none',
            }}
          >
            <DialogTitle css={{ px: 20 }}>Un-Subscribe Contact</DialogTitle>
            {isLoading ? (
              <VStack gap="2" css={{ width: '100%', px: 20 }}>
                {Array(6)
                  .fill({})
                  .map((_, index) => (
                    <Skeleton
                      key={index}
                      css={{ width: '100%', height: 20, mt: 10, mb: 10 }}
                    />
                  ))}
              </VStack>
            ) : (
              allLocationsToShow &&
              allLocationsToShow.length > 0 && (
                <ChannelsTableErrorBoundaries feature="locationsTable">
                  <ChannelsTable
                    contact={props.contact}
                    allChannels={allLocationsToShow}
                    optedInAndNotPausedLocations={activeLocations}
                    pausedLocations={pausedLocations}
                    pausedPreferences={pausedPreferences}
                    unsubscribedLocations={optedOutLocations}
                    selectedLocations={selectedLocations}
                    setSelectedLocations={setSelectedLocations}
                    isLoading={isLoading}
                  />
                </ChannelsTableErrorBoundaries>
              )
            )}
            <Flex justify="end" css={{ pt: 20, pr: 20 }}>
              <HStack>
                <Button
                  variant="gray"
                  onClick={onClose}
                  css={{ backgroundColor: '#00003B0D', boxShadow: 'none' }}
                >
                  Cancel
                </Button>
                {showPauseButton && (
                  <Button variant="primary" onClick={handlePauseClick}>
                    Pause
                  </Button>
                )}
                {showUnpauseButton && (
                  <Button
                    variant="gray"
                    onClick={handleUnpauseClick}
                    css={{ backgroundColor: '#00003B0D', boxShadow: 'none' }}
                  >
                    Unpause
                  </Button>
                )}
                <Button
                  variant="primary"
                  onClick={handleUnsubscribeClick}
                  disabled={!selectedLocations?.length}
                  css={{
                    boxShadow: 'none',
                    '&:disabled': {
                      boxShadow: 'none',
                      backgroundColor: '#00003B0D',
                      color: '#00082F46',
                    },
                  }}
                >
                  Un-Subscribe
                </Button>
              </HStack>
            </Flex>
          </DialogContent>
        </DialogPortal>
      </Dialog>

      {/* Separate dialog for un-subscribe */}
      <UnsubscribeConfirmationDialog
        isUnsubscribeConfirmationDialogOpen={isUnsubscribeConfirmationDialogOpen}
        setIsUnsubscribeConfirmationDialogOpen={setIsUnsubscribeConfirmationDialogOpen}
        selectedLocations={selectedLocations ?? []}
        contactId={props.contact.id}
        onClose={onClose}
      />

      {/* Separate dialog for unpause */}
      <UnpauseConfirmationDialog
        isUnpauseDialogOpen={isUnpauseDialogOpen}
        setIsUnpauseDialogOpen={setIsUnpauseDialogOpen}
        selectedPausedLocations={selectedPausedLocations ?? []}
        contactId={props.contact.id}
        onClose={onClose}
      />

      {/* Separate dialog for pause */}
      <PauseConfirmationDialog
        isPauseDialogOpen={isPauseDialogOpen}
        setIsPauseDialogOpen={setIsPauseDialogOpen}
        selectedPauseDuration={selectedPauseDuration}
        selectedCustomizedDate={selectedCustomizedDate}
        isCustomSelected={isCustomSelected}
        selectedActiveLocations={selectedActiveLocations ?? []}
        contactId={props.contact.id}
        onClose={onClose}
        setSelectedPauseDuration={setSelectedPauseDuration}
        setIsCustomSelected={setIsCustomSelected}
        isDatePickerOpen={isDatePickerOpen}
        setIsDatePickerOpen={setIsDatePickerOpen}
        setSelectedCustomizedDate={setSelectedCustomizedDate}
      />

      {/* Separate dialog for customized pause until date */}
      <DateSelectionDialog
        isDatePickerDialogOpen={isDatePickerDialogOpen}
        setIsDatePickerDialogOpen={setIsDatePickerDialogOpen}
        isDatePickerOpen={isDatePickerOpen}
        setIsDatePickerOpen={setIsDatePickerOpen}
        selectedCustomizedDate={selectedCustomizedDate}
        setSelectedCustomizedDate={setSelectedCustomizedDate}
      />
    </Box>
  );
};
