import { styled } from '@stitches/react';

import { useSearch } from '../SearchContext';
import { RenderAllSearch } from './all';
import { RenderCampaignSearch } from './campaigns';
import { RenderContactSearch } from './contacts';
import { RenderMessageSearch } from './messages';
import { RenderSequenceSearch } from './sequences';

export const RenderSearchResults = () => {
  const { selectedTab } = useSearch();

  return (
    <SearchResults>
      {selectedTab === 'All' && <RenderAllSearch />}
      {selectedTab === 'Contacts' && <RenderContactSearch showEmptyResults={true} />}
      {selectedTab === 'Messages' && <RenderMessageSearch showEmptyResults={true} />}
      {selectedTab === 'Campaigns' && <RenderCampaignSearch showEmptyResults={true} />}
      {selectedTab === 'Sequences' && <RenderSequenceSearch showEmptyResults={true} />}
    </SearchResults>
  );
};

const SearchResults = styled('div', {
  // Calculate maxHeight in pixels as a number
  maxHeight: 'calc(100vh - 390px)',
  position: 'relative',
  overflow: 'auto',
});
