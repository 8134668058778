import { useCombobox } from 'downshift';
import React, { useEffect, useState } from 'react';
import { HiCheck, HiOutlineChevronDown } from 'react-icons/hi';
import { useHistory } from 'react-router-dom';

import { useAuth } from '@/auth/context/AuthProvider';
import { Organization } from '@/shared/types';
import { initials } from '@/shared/utils/initials/initials';
import { getAuthTokens } from '@/shared/utils/storage';
import { styled } from '@/stitches.config';

import {
  Avatar,
  Box,
  ComboboxItem,
  ComboboxItemText,
  Divider,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  Flex,
  Input,
  ScrollArea,
  ScrollAreaCorner,
  ScrollAreaScrollbar,
  ScrollAreaThumb,
  ScrollAreaViewport,
} from '../../ui';
import { CreateOrganization } from './CreateOrganization';

const MenuItem = styled(DropdownMenuItem, {
  py: 5,
});

type OrganizationUserMenuProps = {
  isNew?: boolean;
};

function OrganizationUserMenu({ isNew }: OrganizationUserMenuProps) {
  const auth = useAuth();
  const history = useHistory();
  const tokens = getAuthTokens();

  const logout = () => auth.logout().then(() => history.push('/login'));

  const [profilePhotoUrl, setProfilePhotoUrl] = useState('');
  const changeOrganization = (id: string) =>
    auth.changeOrganization(id).then(() => history.go(0));
  const email = tokens?.email;
  const name = tokens?.name;

  // copy organizations array so sorting doesn't mutate it
  const organizationsCopy = auth.organizations.slice();

  const [comboboxOrganizationList, setComboboxOrganizationList]: [Organization[], any] =
    useState(organizationsCopy);

  useEffect(() => {
    if (auth?.tokens?.attachment?.url) {
      setProfilePhotoUrl(auth?.tokens?.attachment?.url);
    } else {
      setProfilePhotoUrl('');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth?.tokens?.attachment]);

  useEffect(() => {
    setComboboxOrganizationList(auth.organizations?.slice());
  }, [auth.organizations]);

  const { getMenuProps, getInputProps, getItemProps, highlightedIndex } = useCombobox({
    items: comboboxOrganizationList,
    stateReducer: (state, actionAndChanges) => {
      const { type, changes } = actionAndChanges;
      // this prevents the menu from being closed when the user selects an item with 'Enter' or mouse
      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
          changeOrganization(changes?.selectedItem?.id || '');
          return {
            ...changes,
            inputValue: '',
          };
        case useCombobox.stateChangeTypes.InputKeyDownEscape:
        case useCombobox.stateChangeTypes.InputBlur:
          return {
            ...changes,
            inputValue: '',
          };
        default:
          return {
            ...changes,
          };
      }
    },
    onInputValueChange: ({ inputValue }) => {
      setComboboxOrganizationList(
        organizationsCopy.filter((organization) =>
          organization!.organization_name
            ?.toLowerCase()
            .includes(inputValue!.toLowerCase())
        )
      );
    },
  });
  const inputProps = getInputProps(
    {
      placeholder: 'Search by organization name',
      onKeyDown(event) {
        event.stopPropagation();
      },
    },
    { suppressRefError: true }
  );

  return (
    <DropdownMenu>
      {isNew ? (
        <DropdownMenuTriggerNew>
          <Avatar
            css={{ ml: '3px' }}
            interactive={true}
            src={profilePhotoUrl}
            fallback={initials(name || email)}
            variant="pink"
            size={7}
          />
          <Box css={{ fontWeight: 500 }}>{name || email}</Box>
          <HiOutlineChevronDown size={15} />
        </DropdownMenuTriggerNew>
      ) : (
        <DropdownMenuTrigger>
          <Avatar
            interactive={true}
            src={profilePhotoUrl}
            fallback={initials(name || email)}
            variant="pink"
          />
        </DropdownMenuTrigger>
      )}
      <DropdownMenuContent sideOffset={10}>
        <Flex direction="column" align="center" justify="center" css={{ mb: 10 }}>
          <Box css={{ pt: 15, pb: 10 }}>
            <Avatar
              interactive={true}
              fallback={initials(name || email)}
              variant="pink"
              src={profilePhotoUrl}
            />
          </Box>
          <Box css={{ pb: 10 }}>{name || email}</Box>
        </Flex>
        {auth.organizations.length >= 4 ? (
          <Box>
            <Input {...inputProps} variant="dialog" />
          </Box>
        ) : (
          <Divider />
        )}
        <ScrollArea variant="combobox" css={{ maxHeight: '40vh' }}>
          <ScrollAreaViewport
            variant="combobox"
            {...getMenuProps({}, { suppressRefError: true })}
          >
            {comboboxOrganizationList
              .sort(
                (a: { organization_name: string }, b: { organization_name: string }) =>
                  a.organization_name.localeCompare(b.organization_name)
              )
              .map((item: Organization, index) => (
                <ComboboxItem
                  align="center"
                  key={item.id}
                  css={{ height: 50, pl: 20 }}
                  selected={highlightedIndex === index}
                  {...getItemProps({ item, index })}
                >
                  <Flex justify="between" align="center">
                    <Flex align="center">
                      <Avatar
                        interactive={true}
                        src={item.attachment?.url}
                        fallback={item.organization_name.charAt(0)}
                        variant="pink"
                      />
                      <ComboboxItemText css={{ width: 225, ml: 10 }}>
                        {item.organization_name}
                      </ComboboxItemText>
                    </Flex>
                    <Box css={{ minWidth: '20px' }}>
                      {auth.organizationId === item.id ? <HiCheck /> : null}
                    </Box>
                  </Flex>
                </ComboboxItem>
              ))}
            {comboboxOrganizationList.length === 0 ? (
              <ComboboxItem
                align="center"
                justify="center"
                disabled
                css={{ height: 50, width: 310 }}
              >
                <ComboboxItemText>No Results Found</ComboboxItemText>
              </ComboboxItem>
            ) : null}
          </ScrollAreaViewport>
          <ScrollAreaScrollbar orientation="vertical" variant="combobox">
            <ScrollAreaThumb />
          </ScrollAreaScrollbar>
          <ScrollAreaCorner />
        </ScrollArea>
        <Divider />
        <MenuItem onSelect={() => history.push('/settings/general')}>
          Organization Settings
        </MenuItem>
        <CreateOrganization />
        <Divider />
        <MenuItem onSelect={logout}>Logout</MenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

export const DropdownMenuTriggerNew = styled(DropdownMenuTrigger, {
  color: 'white',
  display: 'flex',
  alignItems: 'center',
  gap: '6px',
  fontSize: '13px',
});

export default OrganizationUserMenu;
