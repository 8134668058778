import React from 'react';
import { Helmet } from 'react-helmet-async';

import { usePageView } from '@/shared/hooks';
import { SettingsLayout } from '@/shared/layouts';
import { Button } from '@/shared/ui';

import { AddSignature } from './AddSignature';
import SignaturesView from './SignaturesView';

export function Signatures() {
  usePageView();

  return (
    <>
      <Helmet>
        <title>Whippy | Signatures</title>
      </Helmet>
      <SettingsLayout
        background="#fafafa"
        breadcrumbs={[
          { title: 'Settings', path: '/settings/signatures' },
          { title: 'Signatures', path: `/settings/signatures` },
        ]}
        actions={
          <AddSignature>
            <Button onClick={(e) => e.preventDefault()} css={{ minWidth: '120px' }}>
              Add Signature
            </Button>
          </AddSignature>
        }
        width="100%"
      >
        <SignaturesView />
      </SettingsLayout>
    </>
  );
}
