import React, { useEffect, useState } from 'react';
import { HiClipboard } from 'react-icons/hi';

import { fetchOrganizationsInfo } from '@/shared/api/auth';
import { CopyToClipboard } from '@/shared/components/CopyToClipboard';
import { Label } from '@/shared/ui';
import { Box, Flex, VStack } from '@/shared/ui';

const CopyOrganizationId = () => {
  const [id, setId] = useState('Loading...');

  useEffect(() => {
    const fetchAndSetOrganizationId = async () => {
      const { organization } = await fetchOrganizationsInfo();
      setId(organization.id);
    };

    fetchAndSetOrganizationId();
  }, []);

  return (
    <VStack gap="1">
      <Label>Organization Id</Label>
      <Box css={{ color: '#718196', fontSize: 13 }}>
        <CopyToClipboard
          copy={id}
          description="Copy organization id"
          successMessage="Organization id copied to clipboard"
        >
          <Flex align="center">
            <Flex>{id}</Flex>
            <Flex css={{ mx: 4 }}>
              <HiClipboard />
            </Flex>
          </Flex>
        </CopyToClipboard>
      </Box>
    </VStack>
  );
};

export default CopyOrganizationId;
