import { ReportsCollapsible } from '@/shared/components/reports/ReportsCollapsible';
import { Box, Button, VStack } from '@/shared/ui';
import { styled } from '@/stitches.config';

type ErrorStateAnalyticsProps = {
  /** title of the chart */
  title?: string;
  /** the purpose of the chart */
  tooltipContent?: string;
  /** fetching data failed */
  error?: boolean;
  /** retry request */
  errorCallback?: () => void;
};

export const ErrorStateAnalytics = ({
  title,
  tooltipContent,
  error,
  errorCallback,
}: ErrorStateAnalyticsProps) => {
  return (
    <ReportsCollapsible title={title || ''} description={tooltipContent || ''}>
      <VStack gap={2} align="center">
        <ChartErrorTitle>Fail to Load Analytics</ChartErrorTitle>
        <ChartErrorDescription>
          An error occurred while loading this analytics
        </ChartErrorDescription>
        {error && (
          <Box css={{ width: '100%', textAlign: 'center' }}>
            <Button variant="gray" onClick={errorCallback}>
              Try Again
            </Button>
          </Box>
        )}
      </VStack>
    </ReportsCollapsible>
  );
};

const ChartErrorTitle = styled(Box, {
  fontSize: 18,
  fontWeight: 600,
});

const ChartErrorDescription = styled(Box, {
  fontSize: 12,
  color: '$slate11',
});
