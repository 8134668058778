import React from 'react';
import { Helmet } from 'react-helmet-async';

import { usePageView } from '@/shared/hooks';
import { SettingsLayout } from '@/shared/layouts';
import { Flex, Skeleton, Text, VStack } from '@/shared/ui';
import { styled } from '@/stitches.config';

// import { CurrentUsage } from './CurrentUsage';
import { BillingControls } from './BillingControls';
// import { BillingHistory } from './BillingHistory';
// import { CreditsGrants } from './CreditGrants';
import { CurrentPlan } from './CurrentPlan';

export function Billing() {
  usePageView();

  return (
    <>
      <Helmet>
        <title>Whippy | Billing</title>
      </Helmet>
      <SettingsLayout
        breadcrumbs={[
          { title: 'Settings', path: '/settings/billing' },
          { title: 'Billing', path: `/settings/billing` },
        ]}
      >
        <VStack gap="4">
          <CurrentPlan />
          <BillingControls />
          {/* <CurrentUsage /> */}
          {/* <BillingHistory /> */}
          {/* <CreditsGrants /> */}
        </VStack>
      </SettingsLayout>
    </>
  );
}

export const LoadingCard = () => {
  return (
    <BillingCard>
      <VStack gap="2" css={{ width: '100%' }}>
        <Skeleton variant="heading" css={{ width: '50%' }} />
        <Skeleton variant="heading" css={{ width: '100%' }} />
      </VStack>
    </BillingCard>
  );
};

type BillingErrorProps = {
  /* title of the error */
  title: string;
  /* description of the error */
  description: string;
};

export const ErrorCard = (props: BillingErrorProps) => {
  return (
    <BillingCard css={{ background: 'white' }}>
      <VStack gap="2">
        <Text size="3" variant="bold">
          {props.title}
        </Text>
        <Text size="2">{props.description}</Text>
      </VStack>
    </BillingCard>
  );
};

export const BillingCard = styled(Flex, {
  width: '100%',
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: '$gray4',
  borderRadius: '$3',
  padding: '$4',
  gap: '$4',
});
