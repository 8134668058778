import { UserStates } from '@/shared/types/users';

export const default_contact_user_state = {
  id: 'unique-id-for-user-state',
  label: 'User status',
  key: 'user_organization',
  inserted_at: 'current-timestamp',
  updated_at: 'current-timestamp',
  custom_properties: [
    {
      default: '',
      id: 'user_organization-state',
      label: 'Status',
      type: 'combobox',
      key: 'state',
      required: true,
      inserted_at: 'current-timestamp',
      updated_at: 'current-timestamp',
      custom_object_id: 'unique-id-for-contact-tag',
      options: [
        { label: 'Enabled', value: UserStates.ENABLED },
        { label: 'Disabled', value: UserStates.DISABLED },
      ],
    },
  ],
};
