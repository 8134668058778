type UserWebNotificationMetadata = {
  assigned_by_user_name?: string;
  assigned_to_user_name?: string;
  contact_name?: string;
  conversation_id?: string;
  organization_id?: string;
  organization_name?: string;
};

export enum UserWebNotificationTypes {
  ASSIGNED_USER = 'assigned_user',
  ASSIGNED_USER_BULK = 'assigned_user_bulk',
  BILLING_USAGE = 'billing_usage',
  IMPORT_STATUS = 'import_status',
  KEYWORD_TRIGGERED = 'keyword_triggered',
  NEW_LEAD = 'new_lead',
  NEW_MESSAGE = 'new_message',
  NEW_MESSAGE_FOR_ALL_CONVERSATION = 'new_message_for_all_conversation',
  NEW_MESSAGE_FOR_UNASSIGNED_CONVERSATION = 'new_message_for_unassigned_conversation',
  NO_RESPONSE_LONG = 'no_response_long',
  NO_RESPONSE_SHORT = 'no_response_short',
  MENTION = 'mention',
  LINK_CLICK = 'link_click',
  LINK_CLICK_FOR_ALL_MESSAGES = 'link_click_for_all_messages',
  CALL_STARTED = 'call_started',
  CALL_ENDED = 'call_ended',
}

export const UserWebNotificationTitles = {
  [UserWebNotificationTypes.ASSIGNED_USER]: 'New Conversation Assignment',
  [UserWebNotificationTypes.ASSIGNED_USER_BULK]: 'New Conversation Assignment',
  [UserWebNotificationTypes.BILLING_USAGE]: 'Billing Usage',
  [UserWebNotificationTypes.IMPORT_STATUS]: 'Import Status',
  [UserWebNotificationTypes.KEYWORD_TRIGGERED]: 'Keyword Triggered',
  [UserWebNotificationTypes.NEW_LEAD]: 'New Lead',
  [UserWebNotificationTypes.NEW_MESSAGE]: 'New Message',
  [UserWebNotificationTypes.NEW_MESSAGE_FOR_ALL_CONVERSATION]: 'New Message',
  [UserWebNotificationTypes.NEW_MESSAGE_FOR_UNASSIGNED_CONVERSATION]: 'New Message',
  [UserWebNotificationTypes.NO_RESPONSE_LONG]: 'No Response',
  [UserWebNotificationTypes.NO_RESPONSE_SHORT]: 'No Response',
  [UserWebNotificationTypes.MENTION]: 'New Mention',
  [UserWebNotificationTypes.LINK_CLICK]: 'New Link Click',
  [UserWebNotificationTypes.LINK_CLICK_FOR_ALL_MESSAGES]: 'New Link Click',
  [UserWebNotificationTypes.CALL_STARTED]: 'Call in Progress',
  [UserWebNotificationTypes.CALL_ENDED]: 'Call Ended',
};

export type UserWebNotification = {
  id: string;
  message: string;
  metadata: UserWebNotificationMetadata;
  notification_type: UserWebNotificationTypes;
  organization_id: string;
  status: NotificationsStatus;
  updated_at: string;
  user_id: number;
  inserted_at: string;
};

export enum NotificationsStatus {
  READ = 'read',
  UNREAD = 'unread',
  ARCHIVED = 'archived',
}

type UpdateNotificationPayload = {
  bulkAction: NotificationsStatus;
  ids: string[];
};

type UpdateBulkNotificationPayload = {
  bulkAction: NotificationsStatus;
};

export enum UserWebNotificationActionTypes {
  GET_USER_WEB_NOTIFICATIONS = 'GET_USER_WEB_NOTIFICATIONS',
  GET_ARCHIVED_USER_WEB_NOTIFICATIONS = 'GET_ARCHIVED_USER_WEB_NOTIFICATIONS',
  SET_USER_WEB_NOTIFICATIONS_LOADING = 'SET_USER_WEB_NOTIFICATIONS_LOADING',
  NEW_NOTIFICATION = 'NEW_NOTIFICATION',
  UPDATE_NOTIFICATION_STATUS = 'UPDATE_NOTIFICATION_STATUS',
  UPDATE_BULK_NOTIFICATION_STATUS = 'UPDATE_BULK_NOTIFICATION_STATUS',
}

export type UserWebNotificationActions =
  | {
      type: UserWebNotificationActionTypes.GET_USER_WEB_NOTIFICATIONS;
      payload: UserWebNotification[] | null[];
    }
  | {
      type: UserWebNotificationActionTypes.GET_ARCHIVED_USER_WEB_NOTIFICATIONS;
      payload: UserWebNotification[] | null[];
    }
  | {
      type: UserWebNotificationActionTypes.SET_USER_WEB_NOTIFICATIONS_LOADING;
      payload: boolean;
    }
  | {
      type: UserWebNotificationActionTypes.NEW_NOTIFICATION;
      payload: UserWebNotification;
    }
  | {
      type: UserWebNotificationActionTypes.UPDATE_NOTIFICATION_STATUS;
      payload: UpdateNotificationPayload;
    }
  | {
      type: UserWebNotificationActionTypes.UPDATE_BULK_NOTIFICATION_STATUS;
      payload: UpdateBulkNotificationPayload;
    };

export enum USER_WEB_NOTIFICATIONS_FILTERS {
  ALL = 'ALL',
  ARCHIVED = 'ARCHIVED',
}

export enum USER_WEB_NOTIFICATION_TYPES {
  assigned_user = 'New Conversation Assignment',
  assigned_user_bulk = 'New Conversation Assignment',
  billing_usage = 'Billing Usage',
  import_status = 'Import Status',
  keyword_triggered = 'Keyword Triggered',
  new_lead = 'New Lead',
  new_message = 'New Message',
  new_message_for_all_conversation = 'New Message',
  new_message_for_unassigned_conversation = 'New Message',
  no_response_long = 'No Response',
  no_response_short = 'No Response',
  mention = 'New Mention',
  link_click = 'New Link Click',
  link_click_for_all_messages = 'New Link Click',
  call_started = 'Call in Progress',
  call_ended = 'Call Ended',
}

export type GetUserNotificationParams = {
  limit?: number;
  offset?: number;
  status?: Array<NotificationsStatus>;
  organization_ids?: Array<string>;
};
