/* eslint-disable no-unused-vars */
import React from 'react';
import { createContext, useContext, useReducer } from 'react';
import { toast } from 'sonner';

import {
  createAutomatedQuestion,
  deleteAutomatedQuestion,
  disableAutomatedQuestion,
  enableAutomatedQuestion,
  getAutomatedQuestions,
  searchAutomatedQuestions,
  updateAutomatedQuestion,
} from '@/shared/api/automations/questions';
import { Questions, SearchQuestion, TestMessages } from '@/shared/types';
import i18next from '@/shared/utils/translation';

import QuestionsReducer from './QuestionsReducer';
import {
  CREATE_QUESTION,
  DELETE_QUESTION,
  DISABLE_QUESTION,
  EDIT_QUESTION,
  ENABLE_QUESTION,
  GET_QUESTIONS,
  SEARCH_ANSWER,
  SEARCH_QUESTION,
  SET_COUNT,
  SET_CURRENT,
} from './types';

export type QuestionState = {
  questions: Array<Questions>;
  loading: boolean;
  current: Questions | null | undefined;
  testMessages: Array<TestMessages>;
  error: any;
  count: number;
};

export const initialQuestionsState: QuestionState = {
  questions: [],
  testMessages: [],
  loading: true,
  current: null,
  error: null,
  count: 0,
};

export const QuestionsContext = createContext<{
  questionsState: QuestionState;
  getQuestions: (params: any) => void;
  createQuestion: (question: Questions) => void;
  editQuestion: (question: Questions) => void;
  deleteQuestion: (question: Questions) => void;
  enableQuestion: (question: Questions) => void;
  disableQuestion: (question: Questions) => void;
  searchQuestions: (searchQuestion: SearchQuestion) => void;
  setCurrentQuestion: (question: Questions | null | undefined) => void;
}>({
  questionsState: initialQuestionsState,
  getQuestions: () => Promise.resolve(),
  createQuestion: () => Promise.resolve(),
  editQuestion: () => Promise.resolve(),
  deleteQuestion: () => Promise.resolve(),
  enableQuestion: () => Promise.resolve(),
  disableQuestion: () => Promise.resolve(),
  searchQuestions: () => Promise.resolve(),
  setCurrentQuestion: () => Promise.resolve(),
});

export const useQuestions = () => useContext(QuestionsContext);

const QuestionsState = ({ children }: { children: React.ReactNode }) => {
  const [questionsState, dispatch] = useReducer(QuestionsReducer, initialQuestionsState);

  const getQuestions = async (params: any) => {
    try {
      const data = await getAutomatedQuestions(params);

      dispatch({
        type: GET_QUESTIONS,
        payload: data.questions,
      });

      dispatch({
        type: SET_COUNT,
        payload: data.count,
      });
    } catch (err) {
      console.error(err);
    }
  };

  const searchQuestions = async (searchQuestion: SearchQuestion) => {
    dispatch({
      type: SEARCH_QUESTION,
      payload: searchQuestion,
    });

    try {
      const data = await searchAutomatedQuestions(searchQuestion);

      dispatch({
        type: SEARCH_ANSWER,
        payload: data,
      });
    } catch (err) {
      console.error(err);
    }
  };

  const createQuestion = async (question: Questions) => {
    try {
      const data = await createAutomatedQuestion(question);

      dispatch({
        type: CREATE_QUESTION,
        payload: { ...data, enabled: true },
      });

      dispatch({
        type: SET_COUNT,
        payload: questionsState.count + 1,
      });

      toast.success(i18next.t('question_created_success') as string);
    } catch (err) {
      toast.error(i18next.t('question_created_failure') as string);
      console.error(err);

      // rollback state if the request fails
      dispatch({
        type: DELETE_QUESTION,
        payload: question,
      });
    }
  };

  const editQuestion = async (question: Questions) => {
    try {
      const data = await updateAutomatedQuestion(question);

      dispatch({
        type: EDIT_QUESTION,
        payload: data,
      });

      toast.success(i18next.t('question_updated_success') as string);
    } catch (err) {
      toast.error(i18next.t('question_updated_failure') as string);

      console.error(err);
    }
  };

  const deleteQuestion = async (question: Questions) => {
    try {
      await deleteAutomatedQuestion(question);

      dispatch({
        type: DELETE_QUESTION,
        payload: question,
      });

      toast.success(i18next.t('question_deleted_success') as string);
    } catch (err) {
      toast.error(i18next.t('question_deleted_failure') as string);
      console.error(err);
    }
  };

  const enableQuestion = async (question: Questions) => {
    try {
      await enableAutomatedQuestion(question);

      dispatch({
        type: ENABLE_QUESTION,
        payload: question,
      });

      toast.success(i18next.t('question_enabled_success') as string);
    } catch (err) {
      toast.error(i18next.t('question_enabled_failure') as string);

      console.error(err);
    }
  };

  const disableQuestion = async (question: Questions) => {
    try {
      await disableAutomatedQuestion(question);

      dispatch({
        type: DISABLE_QUESTION,
        payload: question,
      });

      toast.success(i18next.t('question_disabled_success') as string);
    } catch (err) {
      toast.error(i18next.t('question_disabled_failure') as string);

      console.error(err);
    }
  };

  const setCurrentQuestion = (question: Questions | null | undefined) => {
    try {
      dispatch({
        type: SET_CURRENT,
        payload: question,
      });
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <QuestionsContext.Provider
      value={{
        questionsState,
        getQuestions,
        createQuestion,
        editQuestion,
        deleteQuestion,
        enableQuestion,
        disableQuestion,
        searchQuestions,
        setCurrentQuestion,
      }}
    >
      {children}
    </QuestionsContext.Provider>
  );
};

export default QuestionsState;
