/* eslint-disable react-hooks/exhaustive-deps */
import { findPhoneNumbersInText } from 'libphonenumber-js';
import React, { useEffect, useState } from 'react';
import { toast } from 'sonner';

import { useDisclosure } from '@/shared/hooks';

import { useUploads } from '../uploads/context/UploadContext';
import { PasteContactsTable } from './PasteContactsTable';

declare global {
  interface WindowEventMap {
    paste: React.ClipboardEvent<HTMLElement>;
  }
}

type PhoneObject = {
  phone: string;
};

export const PasteContacts = ({ onSuccess }: { onSuccess: (id: string) => void }) => {
  const upload = useUploads();
  const { smallUploadContacts } = upload;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [contacts, setContacts] = useState<Array<PhoneObject>>([]);

  const handleOnPaste = (e: React.ClipboardEvent<HTMLElement>) => {
    // edit event
    e.stopPropagation();
    e.preventDefault();

    // get text from paste event
    const items = e.clipboardData?.getData('Text');
    // replace skip lines with commas
    const replace = items?.replace(/\n/g, ',');
    // format phone numbers
    const formatPhoneNumber = (number: string) => {
      return number.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
    };
    // format numbers
    const formattedNumbers = replace.split(',').map(formatPhoneNumber).join(',');

    try {
      // find phone numbers in text
      const numbersObject = findPhoneNumbersInText(formattedNumbers, 'US');
      // filter object for correct values
      const numbers: Array<string> = numbersObject.map((p) => p.number.number);
      const uniqueNumbers = [...new Set(numbers)];
      // transform to array of objects
      const contactObject = uniqueNumbers.map((n) => ({ phone: n }));
      // create array of phone numbers
      setContacts(contactObject);
      // open dialog
      if (contactObject.length > 0) {
        onOpen();
      }
    } catch (error) {
      console.error('Error occurred while parsing phone numbers: ', error);
    }
  };

  useEffect(() => {
    window.addEventListener('paste', handleOnPaste, true);

    return () => {
      window.removeEventListener('paste', handleOnPaste, true);
    };
  }, []);

  const handleOnSetContacts = (contacts: Array<PhoneObject>) => {
    // update state
    setContacts(contacts);

    // bulk create contacts or an upload
    async function bulkCreate() {
      try {
        const data = await smallUploadContacts(contacts);
        if (onSuccess) {
          onSuccess(data.id);
        }
        return data;
      } catch (error) {
        console.error('Error occurred while creating contacts: ', error);
        toast.error('Error occurred while creating contacts');
      }
    }

    // if there are any contacts selected
    // then create them
    if (contacts.length > 0) {
      bulkCreate();
    }

    // close dialog
    onClose();
  };

  return (
    <div onPaste={handleOnPaste}>
      <PasteContactsTable
        onClose={onClose}
        onOpen={onOpen}
        isOpen={isOpen}
        contacts={contacts}
        setContacts={handleOnSetContacts}
      />
    </div>
  );
};
