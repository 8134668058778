export const join_custom_data_object = {
  id: 'unique-id-for-contact_tag',
  label: 'Join Custom Data',
  key: 'join_custom_data',
  inserted_at: 'current-timestamp',
  updated_at: 'current-timestamp',
  custom_properties: [
    {
      default: '',
      id: 'join_custom_data',
      label: 'Key',
      type: 'join',
      key: 'key',
      required: true,
      inserted_at: 'current-timestamp',
      updated_at: 'current-timestamp',
      custom_object_id: 'unique-id-for-campaign_contact',
    },
  ],
};
