import * as DialogPrimitive from '@radix-ui/react-dialog';

import { DialogContent } from '@/shared/ui';
import { styled } from '@/stitches.config';

export const BulkActionDialogDescription = styled(DialogPrimitive.Description, {
  marginBottom: '12px',
  fontSize: '15px',
  color: '$slate11',
});

export const BulkActionDialogTitle = styled(DialogPrimitive.Title, {
  color: 'Black',
  fontSize: '22px',
  fontWeight: 'bold',
  paddingBottom: '4px',
});

export const BulkActionDialogContent = styled(DialogContent, {
  width: 700,
  paddingTop: '16px',
  paddingBottom: '16px',
});
