import React from 'react';

import { isDev } from '../utils/config';

export function usePageView() {
  React.useEffect(() => {
    if (!isDev) {
      window.analytics.page(window.location.pathname);
    }
  }, []);
}
