import React from 'react';
import { HiSearch } from 'react-icons/hi';

import FiltersMenu from '@/shared/components/filters/FiltersMenu';
import { Sort, SortConfig } from '@/shared/types/filter';
import { Box, Input } from '@/shared/ui';
import { styled } from '@/stitches.config';

type QuickSortFilterProps = {
  sortConfig?: SortConfig<any, any>;
  activeSort: Sort[];
  onSortUpdate: (sort: Sort[]) => void;
  children?: React.ReactNode;
};

export const QuickSortFilter: React.FC<QuickSortFilterProps> = ({
  sortConfig,
  activeSort,
  onSortUpdate,
  children,
}) => {
  return (
    <Box>
      {sortConfig && (
        <FiltersMenu
          type="sort"
          config={sortConfig}
          onFilterRowUpdate={onSortUpdate}
          activeFilters={activeSort}
        >
          {children}
        </FiltersMenu>
      )}
    </Box>
  );
};

type QuickSearchFilterProps = {
  quickSearchPlaceholder?: string;
  quickSearchValue?: string;
  setQuickSearchValue?: (value: string) => void;
};

export const QuickSearchFilter: React.FC<QuickSearchFilterProps> = ({
  quickSearchPlaceholder,
  quickSearchValue,
  setQuickSearchValue,
}) => {
  return (
    <Box>
      {quickSearchPlaceholder && setQuickSearchValue && (
        <SearchInputContainer>
          <HiSearch
            style={{
              position: 'absolute',
              top: '9px',
              left: '8px',
            }}
          />
          <Input
            id="table-filters-search"
            data-testid="table-filters-search"
            type="text"
            placeholder={quickSearchPlaceholder}
            value={quickSearchValue}
            onChange={(e) => setQuickSearchValue?.(e.target.value)}
          />
        </SearchInputContainer>
      )}
    </Box>
  );
};

const SearchInputContainer = styled(Box, {
  width: '260px',
  position: 'relative',
  '> input': {
    paddingLeft: '$space$6',
  },
});
