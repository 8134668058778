import React from 'react';
import { Helmet } from 'react-helmet-async';
import { HiGlobeAlt, HiLink } from 'react-icons/hi';
import { useRouteMatch } from 'react-router-dom';
import { useMedia } from 'react-use';

import DomainsProvider from '@/pages/domains/context/DomainsContext';
import LinksProvider from '@/pages/links/context/LinksContext';
import { useSidenavigationSettings } from '@/shared/components/navigation/context/SideNavigationContext';
import {
  SideNavigation,
  sideNavigationGroup,
} from '@/shared/components/navigation/SideNavigation';
import {
  SidebarNavigationContainer,
  SidebarNavigationWrapper,
} from '@/shared/components/navigation/SideNavigationContainer';
import { LayoutContent, MainLayout } from '@/shared/layouts';

export const LinksNavigation = () => {
  const { navigationSettings, toggleExpandedState } = useSidenavigationSettings();
  const isLinks = useRouteMatch('/campaigns/links')?.isExact;
  const isDomains = useRouteMatch('/campaigns/domains')?.isExact;

  const linksSideNavigationTabs: Array<sideNavigationGroup> = [
    {
      sideNavigationGroup: [
        {
          title: 'Short Links',
          image: <HiLink size={18} />,
          isActive: !!isLinks,
          link: '/campaigns/links',
        },
        {
          title: 'Domains',
          image: <HiGlobeAlt size={18} />,
          isActive: !!isDomains,
          link: '/campaigns/domains',
        },
      ],
    },
  ];

  return (
    <SidebarNavigationContainer>
      <SidebarNavigationWrapper>
        <SideNavigation
          expanded={navigationSettings.expanded}
          header="Links"
          sideNavigationTabs={linksSideNavigationTabs}
          toggleExpandedState={toggleExpandedState}
        />
      </SidebarNavigationWrapper>
    </SidebarNavigationContainer>
  );
};

export const LinksLayoutContainer = ({ children }: { children: React.ReactNode }) => {
  const isDesktop = useMedia('(min-width: 912px)');
  const isDomains = useRouteMatch('/campaigns/domains')?.isExact;
  const isDomain = useRouteMatch('/campaigns/domains/:id')?.isExact;
  const isLinks = useRouteMatch('/campaigns/links')?.isExact;
  const isLink = useRouteMatch('/campaigns/links/:id')?.isExact;
  const isLinkAnalytics = useRouteMatch('/campaigns/links/:id/:tab')?.isExact;
  const isLinkCreate = useRouteMatch('/campaigns/links/create')?.isExact;
  const isLinkCreateWithTab = useRouteMatch('/campaigns/links/create/:tab')?.isExact;
  return !isDomains &&
    !isLinks &&
    !isDomain &&
    !isLink &&
    !isLinkCreate &&
    !isLinkAnalytics &&
    !isLinkCreateWithTab ? null : (
    <MainLayout>
      {isDesktop && <LinksNavigation />}
      <LayoutContent>{children}</LayoutContent>
    </MainLayout>
  );
};

export const LinksContainer = ({ children }: { children: React.ReactNode }) => {
  return (
    <DomainsProvider>
      <LinksProvider>
        <Helmet>
          <title>Whippy | Links</title>
        </Helmet>
        <LinksLayoutContainer>{children}</LinksLayoutContainer>
      </LinksProvider>
    </DomainsProvider>
  );
};
