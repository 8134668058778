/* eslint-disable react-hooks/exhaustive-deps */
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';

import { getMessagesOverview } from '@/shared/api/reports';
import { Card } from '@/shared/components/reports/Card';
import { DAY } from '@/shared/components/reports/constants';
import { ReportsTabs } from '@/shared/components/reports/ReportsTabs';
import { Flex, VStack } from '@/shared/ui';
import { styled } from '@/stitches.config';

import { useReport } from '../context/ReportsContext';
import { MessagesOverviewData } from '../messaging/Overview';

export const MessagingOverview = () => {
  const reports = useReport();
  const { dates, initialState } = reports;

  const [data, setData] = useState<MessagesOverviewData>();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    getData();
  }, [dates.startDate, dates.endDate]);

  const getData = async () => {
    setLoading(true);
    setError(false);
    try {
      const response = await getMessagesOverview(
        dates.startDate && dates.endDate
          ? {
              type: 'overview_messages',
              start_date: dayjs(dates.startDate as string).format('YYYY-MM-DD'),
              end_date: dayjs(dates.endDate as string).format('YYYY-MM-DD'),
              granularity: DAY,
            }
          : { ...initialState, type: 'overview_messages' }
      );
      setData(response);
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <VStack gap={2}>
      <ReportsTabs
        values={['overview', 'channel', 'direction']}
        tabTitles={['Messaging Overview', 'SMS vs MMS', 'Outbound vs Inbound']}
      >
        <StyledCardContainer>
          <Card
            title="Total Credits"
            tooltipContent="The total number of Whippy credits sent and received"
            value={data?.total_segments_count || 0}
            previousValue={data?.prev_total_segments_count || 0}
            loading={loading}
            error={error}
            errorCallback={getData}
            hidePercent={false}
          />
          <Card
            title="Total Messages"
            value={data?.total_messages_count || 0}
            tooltipContent="The total number of unique messages sent and received"
            previousValue={data?.prev_total_messages_count || 0}
            loading={loading}
            error={error}
            errorCallback={getData}
            hidePercent={false}
          />
          <Card
            title="Avg. Credits per Message"
            tooltipContent="The average number of credits per message sent and received"
            value={data?.total_avg_segments_per_message || 0}
            previousValue={data?.prev_total_avg_segments_per_message || 0}
            loading={loading}
            error={error}
            errorCallback={getData}
            hidePercent={false}
          />
        </StyledCardContainer>
        <VStack>
          <StyledCardContainer>
            <Card
              title="Total SMS Credits"
              tooltipContent="The total number of SMS credits sent and received"
              value={data?.total_sms_credits || 0}
              previousValue={data?.prev_total_sms_credits || 0}
              loading={loading}
              error={error}
              errorCallback={getData}
              hidePercent={false}
            />
            <Card
              title="Total SMS Messages"
              tooltipContent="The total number of unique SMS messages sent and received"
              value={data?.total_sms_messages_count || 0}
              previousValue={data?.prev_total_sms_messages_count || 0}
              loading={loading}
              error={error}
              errorCallback={getData}
              hidePercent={false}
            />
            <Card
              title="Avg. SMS Credits per Message"
              tooltipContent="The average number of SMS credits per message sent and received"
              value={data?.total_avg_sms_segments_per_message || 0}
              previousValue={data?.prev_total_avg_sms_segments_per_message || 0}
              loading={loading}
              error={error}
              errorCallback={getData}
              hidePercent={false}
            />
          </StyledCardContainer>
          <StyledCardContainer>
            <Card
              title="Total MMS Credits"
              tooltipContent="The total number of MMS credits sent and received"
              value={data?.total_mms_credits || 0}
              previousValue={data?.prev_total_mms_credits || 0}
              loading={loading}
              error={error}
              errorCallback={getData}
              hidePercent={false}
            />
            <Card
              title="Total MMS Messages"
              tooltipContent="The total number of unique MMS messages sent and received"
              value={data?.total_mms_messages_count || 0}
              previousValue={data?.prev_total_mms_messages_count || 0}
              loading={loading}
              error={error}
              errorCallback={getData}
              hidePercent={false}
            />
            <Card
              title="Avg. MMS Credits per Message"
              tooltipContent="The average number of MMS credits per message sent and received"
              value={data?.total_avg_mms_segments_per_message || 0}
              previousValue={data?.prev_total_avg_mms_segments_per_message || 0}
              loading={loading}
              error={error}
              errorCallback={getData}
              hidePercent={false}
            />
          </StyledCardContainer>
        </VStack>
        <VStack>
          <StyledCardContainer>
            <Card
              title="Total Credits"
              tooltipContent="The total number of outbound and inbound credits sent and received"
              value={data?.total_segments_count || 0}
              previousValue={data?.prev_total_segments_count || 0}
              loading={loading}
              error={error}
              errorCallback={getData}
              hidePercent={false}
            />
            <Card
              title="Total Outbound Credits"
              tooltipContent="The total number of credits sent"
              value={data?.total_outbound_credits || 0}
              previousValue={data?.prev_total_outbound_credits || 0}
              loading={loading}
              error={error}
              errorCallback={getData}
              hidePercent={false}
            />
            <Card
              title="Total Inbound Credits"
              tooltipContent="The total number of inbound credits received"
              value={data?.total_inbound_credits || 0}
              previousValue={data?.prev_total_inbound_credits || 0}
              loading={loading}
              error={error}
              errorCallback={getData}
              hidePercent={false}
            />
          </StyledCardContainer>
          <StyledCardContainer>
            <Card
              title="Total SMS Credits"
              tooltipContent="The total number of SMS credits sent and received"
              value={data?.total_sms_credits || 0}
              previousValue={data?.prev_total_sms_credits || 0}
              loading={loading}
              error={error}
              errorCallback={getData}
              hidePercent={false}
            />
            <Card
              title="Total Outbound SMS Credits"
              tooltipContent="The total number of outbound SMS messages sent"
              value={data?.total_outbound_sms_credits || 0}
              previousValue={data?.prev_total_outbound_sms_credits || 0}
              loading={loading}
              error={error}
              errorCallback={getData}
              hidePercent={false}
            />
            <Card
              title="Total Inbound SMS Credits"
              tooltipContent="The total number of inbound SMS messages received"
              value={data?.total_inbound_sms_credits || 0}
              previousValue={data?.prev_total_inbound_sms_credits || 0}
              loading={loading}
              error={error}
              errorCallback={getData}
              hidePercent={false}
            />
          </StyledCardContainer>
          <StyledCardContainer>
            <Card
              title="Total MMS Credits"
              tooltipContent="The total number MMS credits sent and received"
              value={data?.total_mms_credits || 0}
              previousValue={data?.prev_total_mms_credits || 0}
              loading={loading}
              error={error}
              errorCallback={getData}
              hidePercent={false}
            />
            <Card
              title="Total Outbound MMS Credits"
              tooltipContent="The total number of outbound MMS messages sent"
              value={data?.total_outbound_mms_credits || 0}
              previousValue={data?.prev_total_outbound_mms_credits || 0}
              loading={loading}
              error={error}
              errorCallback={getData}
              hidePercent={false}
            />
            <Card
              title="Total Inbound MMS Credits"
              tooltipContent="The total number of inbound MMS messages received"
              value={data?.total_inbound_mms_credits || 0}
              previousValue={data?.prev_total_inbound_mms_credits || 0}
              loading={loading}
              error={error}
              errorCallback={getData}
              hidePercent={false}
            />
          </StyledCardContainer>
        </VStack>
      </ReportsTabs>
    </VStack>
  );
};

const StyledCardContainer = styled(Flex, {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  backgroundColor: 'white',
});
