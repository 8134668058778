import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone'; // Import timezone plugin for Day.js
import utc from 'dayjs/plugin/utc'; // Import UTC plugin for Day.js

dayjs.extend(utc); // Extend Day.js with UTC plugin
dayjs.extend(timezone); // Extend Day.js with timezone plugin

/**
 * Returns the current time in the specified time zone using Day.js.
 * @param timezone The name of the time zone to use.
 * @returns The current time in the specified time zone formatted as "h:mm A".
 */
export function getTimeInTimezone(timezone: string | undefined): string {
  // Create a new Day.js object representing the current date and time
  const currentTime = dayjs();

  // Convert the Day.js object to the desired time zone
  const timezoneTime = currentTime.tz(timezone);

  // Format the time string in the desired format (e.g. "2:30 PM")
  const formattedTime = timezoneTime.format('h:mm A');

  // Return the formatted time string
  return formattedTime;
}

export function isValidFutureTime(
  timeStr: string,
  currentDateStr: string,
  timezone: string
): boolean {
  // make sure the time value is valid
  if (!timeStr) {
    return false;
  }

  // if the time string is invalid, return false
  if (!dayjs(`${currentDateStr} ${timeStr}`, 'M/D/YYYY H:mm A').tz(timezone).isValid()) {
    return false;
  }

  // now in the specified timezone
  const now = dayjs().tz(timezone);

  // time the time, date and time zone and format it like 2023-03-05T23:13:16-05:00
  const time = dayjs(`${currentDateStr} ${timeStr}`, 'M/D/YYYY H:mm A').tz(
    timezone,
    true
  );

  if (time.isBefore(now)) {
    return false;
  }

  return true;
}
