import dayjs from 'dayjs';
import React from 'react';

import { Review } from '@/shared/types';
import { Avatar, Card, Flex, HStack, Text, VStack } from '@/shared/ui';
import { styled } from '@/stitches.config';

import { ReviewFooter, ReviewResponse } from './ReviewFooter';
import { ReviewDate, ReviewerName, ReviewHeader, ReviewStars } from './ReviewHeader';
import { ReviewWriteResponseButton } from './ReviewWriteResponseButton';

type ReviewCardProps = {
  review: Review;
  index: number;
  stars: number;
  showDate?: boolean;
};

export const ReviewCard = (props: ReviewCardProps): JSX.Element => {
  const { review, stars, showDate } = props;

  // get the google location id from the review object, in the name key (url)
  const googleLocationId = review?.name?.split('/')[3];

  return (
    <ReviewContainer>
      <VStack gap={4}>
        {/* Review Header */}
        <VStack gap={4}>
          <ReviewHeader justify="between">
            <Flex align="center">
              <HStack gap={2}>
                <Avatar src={review.reviewer.profilePhotoUrl} />
                <ReviewerName variant="bold" size={3}>
                  {review.reviewer.displayName}
                </ReviewerName>
                <ReviewStars rating={stars} />
              </HStack>
            </Flex>
            {showDate && (
              <ReviewDate>{dayjs(review.createTime).format('MMM DD, YYYY')}</ReviewDate>
            )}
          </ReviewHeader>
          {/* Review Body */}
          <Flex direction="column">
            <Text variant="review" size={2}>
              {review.comment ? review.comment : '(No Comment)'}
            </Text>
          </Flex>
        </VStack>
        {/* Review Footer */}
        {review.reviewId ? (
          review.reviewReply ? (
            <ReviewFooter>
              <ReviewResponse
                comment={review.reviewReply.comment}
                updateTime={review.reviewReply.updateTime}
              />
            </ReviewFooter>
          ) : (
            <ReviewFooter>
              <ReviewWriteResponseButton
                reviewId={review.reviewId}
                googleLocationId={googleLocationId}
                displayName={review.reviewer.displayName}
                starRating={review.starRating}
                comment={review.comment}
              />
            </ReviewFooter>
          )
        ) : null}
      </VStack>
    </ReviewContainer>
  );
};

export const ReviewContainer = styled(Card, {
  p: '$5',
  color: '$gray12',
});
