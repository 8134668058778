import { HiOutlinePhotograph } from 'react-icons/hi';

import {
  FacebookIcon,
  IMessageIcon,
  LinkedinIcon,
  TwitterIcon,
} from '@/shared/components/Icons';
import { CustomCardOptions } from '@/shared/types/links';
import { Box, Divider, Flex, Image, Skeleton, VStack } from '@/shared/ui';
import { styled } from '@/stitches.config';

type PreviewLinkPanelProps = {
  /** Custom Card Options */
  customCard: CustomCardOptions;
  /** domain name */
  domain: string;
};

const socialList = [
  {
    id: 'iMessage',
    icon: <IMessageIcon />,
  },
  {
    id: 'twitter',
    icon: <TwitterIcon />,
  },
  {
    id: 'facebook',
    icon: <FacebookIcon />,
  },
  {
    id: 'linkedin',
    icon: <LinkedinIcon />,
  },
];

export const PreviewLinkPanel = (props: PreviewLinkPanelProps) => {
  const { domain, customCard } = props;

  // custom card image
  const attachment = customCard?.custom_card_image
    ? customCard?.custom_card_image?.url
    : null;

  const renderSocialCard = (item: { id: string; icon: JSX.Element }) => {
    return (
      <Flex
        key={item.id}
        align="center"
        justify="center"
        direction="column"
        css={{ position: 'relative', width: '100%', height: '100%' }}
        data-testid={`${item.id}-social-preview-card`}
      >
        <Flex align="center" css={{ mb: 10, width: '100%' }}>
          <Divider />
          <Box css={{ px: 10 }}>{item.icon}</Box>
          <Divider />
        </Flex>
        <SocialCard css={{ borderRadius: item.id === 'iMessage' ? 20 : 4 }}>
          <PreviewWrapper align="center" justify="center" direction="column">
            {attachment ? (
              <Image src={attachment} alt="image-preview" width="100%" />
            ) : (
              <ImagePreview align="center" gap={1}>
                <HiOutlinePhotograph size={24} color="#7F7F86" />
                <Placeholder>
                  {domain ? 'No image' : 'Enter a link to generate a preview'}
                </Placeholder>
              </ImagePreview>
            )}
            <MessageContainer
              css={
                item.id === 'iMessage' || item.id === 'facebook'
                  ? {
                      backgroundColor: '#E9E9EB',
                      width: '100%',
                      borderBottomLeftRadius: item.id === 'iMessage' ? 20 : 4,
                      borderBottomRightRadius: item.id === 'iMessage' ? 20 : 4,
                    }
                  : {
                      backgroundColor: 'white',
                      borderTop: '1px solid #E8E8E8',
                      width: '100%',
                    }
              }
            >
              {domain ? (
                <>
                  {item.id === 'twitter' || item.id === 'facebook' ? (
                    <>
                      <MessageText>{domain}</MessageText>
                      <MessageTitle>
                        {customCard?.custom_card_title || 'Title'}
                      </MessageTitle>
                      <MessageTitle css={{ color: '#7F7F86' }}>
                        {customCard?.custom_card_description ||
                          (!customCard?.custom_card_title ? 'Description' : '')}
                      </MessageTitle>
                    </>
                  ) : (
                    <>
                      <MessageTitle>
                        {customCard?.custom_card_title || 'Title'}
                      </MessageTitle>
                      <MessageText>{domain}</MessageText>
                    </>
                  )}
                </>
              ) : (
                <>
                  <Skeleton
                    variant="title"
                    css={{ height: 12, margin: '0 0 4px', width: 95 }}
                  />
                  <Skeleton variant="title" css={{ height: 10, width: 40, margin: 0 }} />
                </>
              )}
            </MessageContainer>
          </PreviewWrapper>
        </SocialCard>
      </Flex>
    );
  };

  return (
    <VStack
      css={{
        position: 'relative',
        width: '100%',
        px: 20,
        pt: 20,
        height: '100%',
      }}
    >
      {socialList.map(renderSocialCard)}
    </VStack>
  );
};

const SocialCard = styled(Flex, {
  minWidth: 258,
  width: '100%',
  overflow: 'hidden',
  border: '1px solid #E8E8E8',
});

const PreviewWrapper = styled(Flex, {
  width: '100%',
  borderColor: '#dddee1',
  overflowWrap: 'break-word',
  fontSize: 10,
  fontWeight: '500',
});

const ImagePreview = styled(VStack, {
  width: '100%',
  aspectRatio: 21 / 11,
  backgroundColor: '#F6F6F6',
  justifyContent: 'center',
});

const MessageContainer = styled(Box, {
  backgroundColor: 'rgba(245, 245, 245, 0.5)',
  backdropFilter: 'blur(80px)',
  p: 12,
});

const MessageTitle = styled(Box, {
  color: 'black',
  display: '-webkit-box',
  '-webkit-line-clamp': 2,
  '-webkit-box-orient': 'vertical',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  fontSize: 10,
  fontWeight: '700',
});

const MessageText = styled(Box, {
  color: '#7F7F86',
  display: '-webkit-box',
  '-webkit-line-clamp': 9,
  '-webkit-box-orient': 'vertical',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  fontSize: 8,
});

const Placeholder = styled(Box, {
  color: '#7F7F86',
  fontSize: 12,
});
