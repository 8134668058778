import * as RadioGroupPrimitive from '@radix-ui/react-radio-group';

import { styled } from '@/stitches.config';

import { Flex } from './Flex';

const StyledRadio = styled(RadioGroupPrimitive.Item, {
  all: 'unset',
  backgroundColor: '$panel',
  width: 16,
  height: 16,
  borderRadius: '100%',
  borderWidth: 1,
  cursor: 'pointer',
  boxShadow: 'inset 0 0 0 1px $colors$slate7',
  '&:hover': { backgroundColor: '$slate2' },
});

const StyledIndicator = styled(RadioGroupPrimitive.Indicator, {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
  position: 'relative',
  backgroundColor: '$primaryColor',
  borderRadius: '100%',
  '&::after': {
    content: '""',
    display: 'block',
    width: 6,
    height: 6,
    borderRadius: '50%',
    backgroundColor: '$slate1',
  },
});

export const StyledRadioIndicator = styled(Flex, {
  all: 'unset',
  boxSizing: 'border-box',
  userSelect: 'none',
  '&::before': {
    boxSizing: 'border-box',
  },
  '&::after': {
    boxSizing: 'border-box',
  },
  display: 'flex',
  alignItems: 'center',
  borderRadius: '$2',
  boxShadow: 'inset 0 0 0 1px $colors$slate6',
  p: '$2 !important',
  '@hover': {
    '&:hover': {
      boxShadow: 'inset 0 0 0 1px $colors$slate8',
    },
  },
});

// Exports
export const RadioGroup = RadioGroupPrimitive.Root;
export const RadioGroupRadio = StyledRadio;
export const RadioGroupIndicator = StyledIndicator;
