import { debounce } from 'lodash';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import { searchContacts } from '@/shared/api/contacts/v2';
import {
  ComboboxOption,
  ValueCombobox,
} from '@/shared/components/filterBuilder/values/Combobox';
import { useClickOutside } from '@/shared/hooks/useClickOutside';
import { Contact } from '@/shared/types';
import { ChannelTypes, ProviderTypes } from '@/shared/types/channels';
import { Avatar, Box, Flex, Input } from '@/shared/ui';
import { initials } from '@/shared/utils/initials/initials';
import {
  formatPhoneNumber,
  isValidPhoneNumber,
  phoneFormatting,
  toE164,
} from '@/shared/utils/validations/validations';

import { useAuth } from '../auth/context/AuthProvider';
import { showContactIcon } from '../inbox/list/ConversationPreview';
import { useChannels } from '../settings/organization/channels/context/ChannelContext';
import { useVoIP } from './context/VoIPContext';
import { VoIPDialog } from './VoIPDialog';
import { VoIPSettingsPopup } from './VoIPSettingsPopup';

export const VoIPOutgoingCall = ({ phone }: { phone?: string }) => {
  const voip = useVoIP();
  const auth = useAuth();

  const currentController = useRef<AbortController | null>(null);
  const [contacts, setContact] = useState<Array<Contact>>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState('');
  const [searchContactValue, setSearchContactValue] = useState(phone || '');
  const [selected, setSelected] = useState<ComboboxOption>();
  const [comboboxOpen, setComboboxOpen] = useState(false);
  const [settingsPopupOpen, setSettingsPopupOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  useClickOutside(ref, () => {
    if (!comboboxOpen && !settingsPopupOpen) {
      voip.clearCallStatus();
    }
  });

  const handleComboboxState = (value: boolean) => {
    if (value) {
      setComboboxOpen(value);
    } else {
      setTimeout(() => {
        setComboboxOpen(value);
      }, 300);
    }
  };

  const channelsContext = useChannels();

  const channels: Array<ComboboxOption> = useMemo(() => {
    return (
      channelsContext.channelsState.channels
        .filter(
          (channel) =>
            channel.type === ChannelTypes.PHONE &&
            channel.provider === ProviderTypes.TWILIO &&
            channel.phone
        )
        .map((channel, index) => {
          const option = {
            label: `${channel.name} - ${formatPhoneNumber(channel.phone || '')}`,
            value: channel.phone || '',
          };
          if (index === 0) {
            setSelected(option);
          }
          return option;
        }) || []
    );
  }, [channelsContext.channelsState.channels]);

  const handleSearchContacts = (value: string) => {
    setSearchContactValue(value);
    debounceSearchContacts(value);
  };

  const fetchContacts = async (value: string) => {
    if (currentController.current) {
      currentController.current.abort();
      currentController.current = null;
    }
    const controller = new AbortController();
    currentController.current = controller;
    if (value.length > 2) {
      try {
        setLoading(true);
        const contacts = await searchContacts(
          [
            {
              column: 'phone',
              comparison: 'ilike',
              resource: 'contact',
              value: `%${phoneFormatting(value)}%`,
              or: [
                {
                  column: 'name',
                  comparison: 'ilike',
                  resource: 'contact',
                  value: `%${value}%`,
                },
              ],
            },
          ],
          [],
          50,
          0,
          controller.signal
        );
        setLoading(false);
        setContact(contacts.data);
      } catch (err) {
        console.error(err);
      }
    } else {
      setLoading(false);
      setContact([]);
    }
  };

  const handleCall = (phone: string) => {
    voip.makeOutgoingCall(
      selected?.value.toString() ?? '',
      toE164(phoneFormatting(phone)) ?? '',
      auth?.tokens?.user_id?.toString() ?? ''
    );
  };

  const debounceSearchContacts = debounce(fetchContacts, 1000);

  const inputRef = useRef<HTMLInputElement>(null); // Create a ref for the input

  useEffect(() => {
    if (comboboxOpen && inputRef.current) {
      inputRef.current.focus(); // Focus the input when the combobox is open
    }
  }, [comboboxOpen]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus(); // Focus the input when the component mounts
    }
  }, []); // Empty dependency array to run only on mount

  return (
    <Box ref={ref}>
      <VoIPDialog
        defaultSize={{ width: 500, height: 0 }}
        defaultStyles={{ maxHeight: 500 }}
        header={
          <Flex
            justify="between"
            align="center"
            css={{ p: '16px 16px 8px 16px', cursor: 'pointer' }}
          >
            <Box
              css={{
                fontSize: 20,
                fontWeight: 700,
                color: 'white',
              }}
            >
              Start a call
            </Box>
            <VoIPSettingsPopup
              isOpen={settingsPopupOpen}
              setIsOpen={setSettingsPopupOpen}
            />
          </Flex>
        }
      >
        <Flex
          data-testid="outgoing-call"
          css={{ width: '100%', padding: '0 16px 16px 16px' }}
          direction="column"
          justify="center"
        >
          <Flex>
            <Box css={{ fontSize: 14, color: 'white', marginRight: 8 }}>
              Make a call from
            </Box>
            <ValueCombobox
              isOpen={comboboxOpen}
              setIsOpen={handleComboboxState}
              css={{ width: 254, maxWidth: 254, fontSize: 12, height: 24 }}
              selectorStyles={{
                border: 'none',
                background: '#BDC8FF17',
                color: '#ADB1B8',
              }}
              valueStyles={{
                maxWidth: 180,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
              comboboxStyles={{
                background: '#1B1B1F',
                color: '#EFF5FFB1',
                border: '1px solid #D2DFFF28',
                marginBottom: 4,
              }}
              popoverStyles={{
                background: '#1C2024',
                color: '#EFF5FFB1',
                padding: '12px 8px',
              }}
              comboboxItemStyles={{
                background: '#1C2024',
                color: 'white',
                '&[data-state="checked"]': {
                  background: '#BDC8FF17',
                },
              }}
              comboboxWrapperStyles={{
                padding: '0',
                '& > div': {
                  color: '#EFF5FFB1',
                },
              }}
              options={channels}
              selected={{ label: selected?.label || '', value: selected?.value || '' }}
              onSelect={setSelected}
              searchValue={searchValue}
              handleSearchValue={setSearchValue}
              searchLabel={'Search'}
            />
          </Flex>
          <Input
            ref={inputRef} // Attach the ref to the input
            css={{
              width: '100%',
              marginTop: 18,
              background: 'transparent',
              color: '#fff',
              boxShadow: 'none',
              fontSize: 16,
              padding: 0,
              '&:focus': {
                boxShadow: 'none',
              },
            }}
            placeholder="Enter a name or phone number..."
            value={searchContactValue}
            onChange={(e) => handleSearchContacts(e.target.value)}
          />
          {!contacts.length && !loading && isValidPhoneNumber(searchContactValue) && (
            <>
              <Box css={{ p: 8 }}>Direct number</Box>
              <ContactItem phone={searchContactValue} handleCall={handleCall}>
                <Flex css={{ fontSize: 14, color: 'white' }}>
                  <Box>#</Box>
                  <Box data-testid="user-phone" css={{ ml: 8 }}>
                    <Box>{formatPhoneNumber(searchContactValue) || '-'}</Box>
                  </Box>
                </Flex>
              </ContactItem>
            </>
          )}

          {!!contacts.length && !loading && (
            <>
              <Box css={{ p: 8 }}>Contacts</Box>
              <Box css={{ maxHeight: 300, overflow: 'auto' }}>
                {contacts.map((contact) => (
                  <ContactItem
                    key={contact.id}
                    phone={contact.phone || ''}
                    handleCall={handleCall}
                  >
                    <Flex align="center" css={{ flex: 1, fontSize: 14, color: 'white' }}>
                      <Avatar
                        data-testid="user-avatar"
                        size="2"
                        variant="lightGray"
                        src={
                          showContactIcon(contact?.name || '')
                            ? `${window.location.origin}/outline.svg`
                            : ''
                        }
                        alt={contact?.name || 'No name'}
                        fallback={initials(contact?.name || '')}
                      />
                      <Box
                        data-testid="user-phone"
                        css={{
                          flex: 1,
                          ml: 8,
                          maxWidth: 185,
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {contact.name}
                      </Box>
                    </Flex>
                  </ContactItem>
                ))}
              </Box>
            </>
          )}
        </Flex>
      </VoIPDialog>
    </Box>
  );
};

export const ContactItem = ({
  phone,
  handleCall,
  children,
}: {
  phone: string;
  handleCall: (phone: string) => void;
  children: React.ReactNode;
}) => {
  return (
    <Flex
      data-testid="call-contact-item"
      onClick={() => handleCall(phone)}
      justify="between"
      align="center"
      css={{
        px: 12,
        py: 8,
        whiteSpace: 'nowrap',
        cursor: 'pointer',
        '&:hover': {
          background: '#2A2B33',
        },
      }}
    >
      {children}
      <Box css={{ fontSize: 14, color: 'white', whiteSpace: 'nowrap' }}>
        {formatPhoneNumber(phone) || '-'}
      </Box>
    </Flex>
  );
};
