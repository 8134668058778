import React from 'react';
import { HiX } from 'react-icons/hi';

import { DropdownOption } from '@/shared/types/filter';
import { Sort } from '@/shared/types/filter';
import { Box, Flex } from '@/shared/ui';
import { styled } from '@/stitches.config';

import FiltersMenuDropdown from './FiltersMenuDropdown';

type FiltersMenuFilterRowProps = {
  // The sort row.
  row: Sort;
  // Callback function when you click the remove button.
  onRemove: () => void;
  // Callback function when you update the sort direction.
  onUpdateSort: (row: Sort) => void;
};

const sortDirectionOptions = [
  {
    label: 'Ascending',
    value: 'asc',
  },
  {
    label: 'Descending',
    value: 'desc',
  },
];

/**
 * The Sort Row component which is used to manage the sort order of a table.
 * Sort Rows can be added and removed from the Filters Menu component.
 * The sort direction can be updated by selecting the relevant option from the dropdown.
 *
 * @param row - The sort row with id, label, value, order & resource values.
 * @param onRemove - A callback function which is called when you click the remove button.
 * @param onUpdateSort - A callback function which is called when you update the sort direction.
 */

const FiltersMenuSortRow: React.FC<FiltersMenuFilterRowProps> = ({
  row,
  onRemove,
  onUpdateSort,
}) => {
  const mapCurrentSort = (row: Sort) => {
    return row.order === 'asc' ? sortDirectionOptions[0] : sortDirectionOptions[1];
  };

  const handleSortSelection = (sortDirection: DropdownOption) => {
    if (sortDirection.value === 'asc' || sortDirection.value === 'desc') {
      onUpdateSort({
        ...row,
        order: sortDirection.value,
      });
    }
  };

  return (
    <SortRowContainer align="center" data-testid="filters-menu-sort-row">
      <SortRowDetails align="center">
        <Box css={{ width: '100%', p: '7px 14px', borderRight: '1px solid $slate7' }}>
          {row.label}
        </Box>
        <Box css={{ width: 200 }}>
          <FiltersMenuDropdown
            options={sortDirectionOptions}
            selected={mapCurrentSort(row)}
            onSelect={handleSortSelection}
          />
        </Box>
      </SortRowDetails>
      <RemoveButton onClick={onRemove} data-testid="filters-menu-remove-sort-button">
        <HiX size={16} />
      </RemoveButton>
    </SortRowContainer>
  );
};

export default FiltersMenuSortRow;

const SortRowContainer = styled(Flex, {
  marginBottom: '$space$2',
  backgroundColor: 'white',
});

const SortRowDetails = styled(Flex, {
  width: '100%',
  marginRight: '$space$1',
  border: '1px solid $slate7',
  borderRadius: '4px',
});

const RemoveButton = styled(Box, {
  cursor: 'pointer',
  borderRadius: '4px',
  padding: '$space$2',
  '&:hover': {
    backgroundColor: '$slate3',
  },
});
