import { styled } from '@stitches/react';
import { ActionImpl, useMatches } from 'kbar';
import { useCallback, useEffect, useMemo, useRef } from 'react';

import { Campaign } from '../types/campaigns';
import { ConversationSearchTypeV2 } from '../types/conversations';
import { Sequence } from '../types/sequences';
import { toE164 } from '../utils/validations/validations';
import { ActionItem } from './items/actions';
import { CampaignItem } from './items/campaigns';
import { ContactItem } from './items/contacts';
import { MessageItem } from './items/messages';
import { SequenceItem } from './items/sequences';
import { TabOptions, useSearch } from './SearchContext';

export type SearchResults = {
  id: string;
  name: string;
  perform: () => void;
  shortcut: string[];
  section: string;
  subtitle: string;
  keywords: string;
  conversation?: ConversationSearchTypeV2;
  campaign?: Campaign;
  sequence?: Sequence;
};

export const GroupName = styled('div', {
  padding: '8px 16px',
  fontSize: '10px',
  opacity: 0.5,
  textTransform: 'uppercase',
});

export const KbdStyled = styled('kbd', {
  padding: '4px 4px',
  backgroundColor: '$gray1',
  borderRadius: 3,
  borderColor: '#E5E5E5',
  borderWidth: 1,
  marginRight: 4,
  color: '$gray11',
  userSelect: 'none',
  boxSizing: 'border-box',
  display: 'inline-flex',
  alignItems: 'center',
});

// Removes all non-digit characters from a phone number string
const stripNumber = (input: string) => {
  return input?.replace(/\D/g, '');
};

// Formats a phone number to E.164 standard, adding a '+1' prefix if needed
export const extractNumber = (input: string) => {
  const formattedValue = input.trim();
  let formattedPhone = toE164(formattedValue) || '';

  if (stripNumber(formattedPhone).length < 10) {
    const prefix = formattedPhone.startsWith('+1') ? '+' : '+1';
    formattedPhone = `${prefix}${stripNumber(formattedValue)}`;
  }

  return formattedPhone;
};

// Extracts country code, area code, and number parts from a phone number string
// Returns an array of strings even if the number is incomplete
export const extractNumberParts = (input: string) => {
  try {
    const regex = /^(\+\d{1,3})?\s*(\(\d{3}\)|\d{3})\s*-?\s*(\d{3})?\s*-?\s*(\d{1,4})?$/;
    const match = input.match(regex);

    const countryCode = match?.[1] || '';
    const areaCode = match?.[2] || '';
    const firstPart = match?.[3] || '';
    const secondPart = match?.[4] || '';

    return [countryCode, areaCode, firstPart, secondPart];
  } catch (e) {
    console.error(e);
    return ['', '', '', ''];
  }
};

// Extracts username, domain, and TLD parts from an email address string
export const extractEmailParts = (input: string) => {
  const email = input?.split('@')[0] ?? '';
  const end = input?.split('@')[1] ?? '';
  const [domain, tld] = end?.split('.') ?? ['', ''];
  return [email, end, domain, tld, input];
};

/**
 * Renders a group of search results and keeps the active item focused
 * @param results - The results to render
 * @param label - The label to render
 * @returns
 */
export const KeepResultsFocused = ({
  results,
  label,
}: {
  results: SearchResults[];
  label: string;
}) => {
  const itemRefs = useRef<HTMLDivElement[]>([]);
  const {
    activeItemIndex,
    selectedTab,
    setActiveItemIndex,
    offsets,
    setOffsets,
    loadings,
    searchResults,
    setPaginatings,
    paginatings,
  } = useSearch();
  const { results: kbarActions, rootActionId } = useMatches();

  // Memoizing the results length to check if there are any results
  const hasResults = useMemo(() => results?.length > 0, [results]);
  const kbarActionsLength = kbarActions?.length > 0 ? kbarActions.length - 1 : 0;

  const sentinelRef = useRef<HTMLDivElement | null>(null);

  // Callback function to handle loading more results
  const handleLoadMore = useCallback(() => {
    // Checking if the selected tab is currently loading or paginating
    const tabLoading = loadings[selectedTab];
    const tabPaginating = paginatings[selectedTab];
    const tabOffset = offsets[selectedTab];

    // Checking if already paginating or loading, if true return
    if (tabLoading || tabPaginating || tabOffset === -1) {
      return;
    }

    // Getting the current offset for the selected tab
    const selectedTabOffset = offsets[selectedTab];

    // Increasing offset by 50 for pagination
    const newOffset = selectedTabOffset + 50;

    // Setting paginating and offset state for selected tab
    setPaginatings((prev) => ({ ...prev, [selectedTab]: true }));
    setOffsets((prev) => ({ ...prev, [selectedTab]: newOffset }));
  }, [offsets, paginatings, selectedTab, setOffsets, setPaginatings, loadings]);

  // Updating itemRefs length to match current results length
  useEffect(() => {
    itemRefs.current = itemRefs.current.slice(0, results?.length);
  }, [results?.length]);

  // Scrolling the active item into view when it changes
  useEffect(() => {
    const currentElement = itemRefs.current[activeItemIndex];
    if (currentElement) {
      currentElement.scrollIntoView({
        behavior: 'auto',
        block: 'nearest',
        inline: 'start',
      });
    }
  }, [activeItemIndex, selectedTab]);

  // Setting up IntersectionObserver to load more results when sentinel comes into view
  useEffect(() => {
    const sentinel = sentinelRef.current;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (
            entry.isIntersecting &&
            !loadings[selectedTab] &&
            searchResults[selectedTab]?.length > 0
          ) {
            handleLoadMore();
          }
        });
      },
      { root: null, rootMargin: '0px', threshold: 1.0 }
    );

    if (sentinel) {
      observer.observe(sentinel);
    }

    // Cleaning up observer on unmount
    return () => {
      if (sentinel) {
        observer.unobserve(sentinel);
      }
    };
  }, [sentinelRef, loadings, searchResults, selectedTab, handleLoadMore]);

  // Checking if activeItemIndex is the last item in results and loading more if necessary
  useEffect(() => {
    const finalItemIndex = results ? results?.length - 1 : 0;
    const isLoading = loadings[selectedTab];

    if (activeItemIndex === finalItemIndex && !isLoading) {
      const tabHasResults = searchResults[selectedTab]?.length > 0;
      if (selectedTab !== TabOptions.All && !loadings[selectedTab] && tabHasResults) {
        handleLoadMore();
      }
    }
  }, [
    results,
    activeItemIndex,
    setActiveItemIndex,
    handleLoadMore,
    loadings,
    selectedTab,
    offsets,
    searchResults,
  ]);

  // TODO: Virtualize this results for performance and efficiency
  return (
    <div id={label} key={`${label}-${selectedTab}`}>
      {kbarActionsLength > 0 && <GroupName>Shortcuts</GroupName>}
      {kbarActionsLength > 0 &&
        kbarActions.slice(1).map((item, index) => {
          const isActive = index === activeItemIndex;
          if (typeof item === 'string') {
            return <GroupName key={index}>{item}</GroupName>;
          }
          return (
            <div ref={(el) => el && (itemRefs.current[index] = el)} key={index}>
              <ActionItem
                active={isActive}
                action={item as ActionImpl}
                currentRootActionId={rootActionId}
              />
            </div>
          );
        })}
      {hasResults && <GroupName>{label}</GroupName>}
      {hasResults &&
        results.map((item, index) => {
          const isActive = index + kbarActionsLength === activeItemIndex;
          return (
            <>
              <div
                ref={(el) => el && (itemRefs.current[index + kbarActionsLength] = el)}
                key={index + kbarActionsLength}
              >
                {/* Render the appropriate item component based on the item's keywords */}
                {item.keywords.includes('messageSearch') ? (
                  <MessageItem action={item} active={isActive} />
                ) : item.keywords.includes('contactSearch') ? (
                  <ContactItem action={item} active={isActive} />
                ) : item.keywords.includes('campaignSearch') ? (
                  <CampaignItem action={item} active={isActive} />
                ) : item.keywords.includes('sequenceSearch') ? (
                  <SequenceItem action={item} active={isActive} />
                ) : (
                  <GroupName>{item.name}</GroupName>
                )}
              </div>
            </>
          );
        })}
      <div id="sentinel" ref={sentinelRef}></div>
    </div>
  );
};

/**
 * Renders all search results in a single group and keeps the active item focused
 * Used in All tab
 * @param results - The results to render grouped by type
 * @param label - The label to render
 * @returns
 */
export const KeepAllResultsFocused = ({
  results,
  label,
}: {
  results: { [key: string]: SearchResults[] };
  label: string;
}) => {
  const itemRefs = useRef<HTMLDivElement[]>([]);
  const { activeItemIndex, selectedTab, setSelectedTab, loadings, searchInput } =
    useSearch();
  const { results: kbarActions, rootActionId } = useMatches();

  // Ensure all results have finished loading before showing them
  // This prevents results flickering as they are added
  const hasFinishedLoading = useMemo(() => {
    return Object.values(loadings).every((loading) => !loading);
  }, [loadings]);

  // Flatten the results object into a single array
  const flattenedResults = useMemo(() => {
    return Object.values(results).flat();
  }, [results]);

  const hasSearchInput = searchInput !== '';

  // Scroll the active item into view when it changes
  useEffect(() => {
    const currentElement = itemRefs.current[activeItemIndex];
    if (currentElement) {
      currentElement.scrollIntoView({
        behavior: 'instant',
        block: 'nearest',
        inline: 'start',
      });
    }
  }, [activeItemIndex, selectedTab]);

  // NOTE: KBar actions count the section as an item, so we need to subtract 1 from the length
  // If we were to have multiple sections, we would need to subtract the number of titles
  // This is defined in useSearchActions in SearchContext.tsx
  // In this case, the 1 section is called Navigation
  const kbarActionsLength = kbarActions?.length > 0 ? kbarActions.length - 1 : 0;

  return (
    <div id={label}>
      {kbarActionsLength > 0 && <GroupName>Shortcuts</GroupName>}
      {kbarActions.slice(1).map((item, index) => {
        const isActive = index === activeItemIndex;
        return (
          <div ref={(el) => el && (itemRefs.current[index] = el)} key={index}>
            <ActionItem
              active={isActive}
              action={item as ActionImpl}
              currentRootActionId={rootActionId}
            />
          </div>
        );
      })}
      {hasSearchInput &&
        hasFinishedLoading &&
        Object.entries(results).map(([resultType, resultItems]) => (
          <div key={resultType}>
            {resultItems.length > 0 && (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <GroupName>{resultType}</GroupName>
                <ShowMoreButton
                  onClick={() => {
                    const tabId = resultType.replace(/\b\w/g, (char) =>
                      char.toUpperCase()
                    );
                    const tab = TabOptions[tabId as keyof typeof TabOptions];
                    setSelectedTab(tab);
                  }}
                >
                  Show more
                </ShowMoreButton>
              </div>
            )}
            {hasSearchInput &&
              hasFinishedLoading &&
              resultItems.map((item) => {
                const flattenedIndex = flattenedResults.indexOf(item) + kbarActionsLength;
                const isActive = flattenedIndex === activeItemIndex;
                return (
                  <div
                    ref={(el) => el && (itemRefs.current[flattenedIndex] = el)}
                    key={item.id || flattenedIndex}
                  >
                    {/* Render the appropriate item component based on the item's keywords */}
                    {item.keywords.includes('messageSearch') ? (
                      <MessageItem action={item} active={isActive} />
                    ) : item.keywords.includes('contactSearch') ? (
                      <ContactItem action={item} active={isActive} />
                    ) : item.keywords.includes('campaignSearch') ? (
                      <CampaignItem action={item} active={isActive} />
                    ) : item.keywords.includes('sequenceSearch') ? (
                      <SequenceItem action={item} active={isActive} />
                    ) : null}
                  </div>
                );
              })}
          </div>
        ))}
    </div>
  );
};

const ShowMoreButton = styled('button', {
  backgroundColor: 'transparent',
  border: 'none',
  padding: '4px 10px',
  cursor: 'pointer',
  fontSize: '12px',
  color: 'var(--colors-primaryColor)',
  textDecoration: 'none',
  transition: 'all 0.2s ease-in-out',
});
