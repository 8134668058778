import React, { useEffect } from 'react';
import { createContext, useContext, useReducer } from 'react';
import { toast } from 'sonner';

import * as API from '@/shared/api/contacts/groups';
import { SearchFilters } from '@/shared/types/contacts';
import { Group, GroupsActionTypes, GroupsState } from '@/shared/types/contacts/groups';
import i18next from '@/shared/utils/translation';

import GroupsReducer from './GroupReducer';

export const ITEMS_COUNT = 10;

export const initialGroupsState: GroupsState = {
  groups: [],
  recentGroups: [],
  searchedGroups: [],
  totalCount: 0,
  current: null,
  error: null,
  loading: true,
  filterParams: {
    offset: 0,
    limit: ITEMS_COUNT,
    sort: [],
    filter: [],
    searchFilter: [],
  },
};

export const GroupsContext = createContext<{
  groupsState: GroupsState;
  getGroups: (
    params: { offset: number; limit: number },
    updateRecentGroups?: boolean
  ) => Promise<void>;
  getGroup: (id: string) => Promise<Group | null>;
  searchGroups: (name: string) => Promise<Array<Group> | Array<null>>;
  createGroup: (
    groupName: string,
    groupColor: string,
    filters: Record<string, any>,
    filters_version: 'v1' | 'v2'
  ) => Promise<Group>;
  updateGroup: (group: Partial<Group>) => Promise<Group>;
  deleteGroup: (groupId: string) => Promise<void>;
  setCurrentGroup: (group: Group | null) => void;
  getGroupsV2: (params: SearchFilters, signal?: any) => Promise<void>;
  updateFilterParams: (params: SearchFilters) => void;
}>({
  groupsState: initialGroupsState,
  getGroups: () => Promise.resolve(),
  getGroup: () => Promise.resolve({} as Group),
  searchGroups: () => Promise.resolve([]),
  createGroup: () => Promise.resolve({} as Group),
  updateGroup: () => Promise.resolve({} as Group),
  deleteGroup: () => Promise.resolve(),
  setCurrentGroup: () => Promise.resolve(),
  getGroupsV2: () => Promise.resolve(),
  updateFilterParams: () => Promise.resolve(),
});

export const useGroups = () => useContext(GroupsContext);

const GroupState = ({ children }: { children: React.ReactNode }) => {
  const [groupsState, dispatch] = useReducer(GroupsReducer, initialGroupsState);

  useEffect(() => {
    getGroupsV2(groupsState.filterParams);
  }, [groupsState.filterParams]);

  const getGroups = async (
    params: { offset: number; limit: number },
    updateRecentGroups?: boolean
  ) => {
    try {
      const data = await API.getGroups(params);

      dispatch({
        type: GroupsActionTypes.GET_GROUPS,
        payload: data,
      });

      if (updateRecentGroups) {
        dispatch({
          type: GroupsActionTypes.GET_RECENT_GROUPS,
          payload: data,
        });
      }
    } catch (err) {
      console.error(err);
    }
  };

  const getGroupsV2 = async (params: SearchFilters, signal?: any) => {
    try {
      const data = await API.getGroupsV2(
        {
          filter: [...params.filter, ...params.searchFilter],
          sort: params.sort,
          limit: ITEMS_COUNT,
          offset: params.offset || 0,
        },
        signal
      );

      dispatch({
        type: GroupsActionTypes.GET_GROUPS_V2,
        payload: data,
      });
    } catch (err) {
      console.error(err);
    }
  };

  const updateFilterParams = async (params: SearchFilters) => {
    try {
      dispatch({
        type: GroupsActionTypes.UPDATE_FILTER_PARAMS,
        payload: params,
      });
    } catch (err) {
      console.error(err);
    }
  };

  const getGroup = async (id: string): Promise<Group | null> => {
    try {
      const data = await API.getGroup(id);

      dispatch({
        type: GroupsActionTypes.GET_GROUP,
        payload: data,
      });

      return data as Group;
    } catch (err) {
      console.error(err);
      return Promise.reject(err);
    }
  };

  const searchGroups = async (name: string): Promise<Array<Group> | Array<null>> => {
    try {
      const data = await API.searchGroup(name);
      dispatch({
        type: GroupsActionTypes.SEARCH_GROUP,
        payload: data,
      });

      return data;
    } catch (err) {
      console.error(err);
      return Promise.reject(err);
    }
  };

  const createGroup = async (
    groupName: string,
    groupColor: string,
    filters: Record<string, any>,
    filters_version: 'v1' | 'v2'
  ): Promise<Group> => {
    try {
      const data = await API.createGroup(groupName, groupColor, filters, filters_version);

      dispatch({
        type: GroupsActionTypes.CREATE_GROUP,
        payload: data,
      });

      toast.success(i18next.t('group_created_success') as string);

      return data;
    } catch (err) {
      console.error(err);
      toast.error(i18next.t('group_created_failure') as string);
      return Promise.reject(err);
    }
  };

  const updateGroup = async (group: Partial<Group>): Promise<Group> => {
    try {
      const data = await API.updateGroup(group);

      dispatch({
        type: GroupsActionTypes.UPDATE_GROUP,
        payload: data,
      });

      toast.success(i18next.t('group_updated_success') as string);

      return data;
    } catch (err) {
      console.error(err);
      toast.error(i18next.t('group_updated_failure') as string);
      return Promise.reject(err);
    }
  };

  const deleteGroup = async (groupId: string) => {
    try {
      await API.deleteGroup(groupId);

      dispatch({
        type: GroupsActionTypes.DELETE_GROUP,
        payload: groupId,
      });

      toast.success(i18next.t('group_deleted_success') as string);
      getGroupsV2(groupsState.filterParams);
    } catch (err) {
      console.error(err);
      toast.error(i18next.t('group_deleted_failure') as string);
    }
  };

  const setCurrentGroup = (group: Group | null) => {
    try {
      dispatch({
        type: GroupsActionTypes.SET_CURRENT,
        payload: group,
      });
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <GroupsContext.Provider
      value={{
        groupsState,
        getGroups,
        getGroup,
        searchGroups,
        createGroup,
        updateGroup,
        deleteGroup,
        setCurrentGroup,
        getGroupsV2,
        updateFilterParams,
      }}
    >
      {children}
    </GroupsContext.Provider>
  );
};

export default GroupState;
