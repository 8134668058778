import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { getCall } from '@/shared/api/calls';
import { Call } from '@/shared/types/calls';
import { Box, Flex } from '@/shared/ui';

import {
  AudioControl,
  CallAnalysis,
  Transcript,
  TranscriptData,
} from '../../calls/AgentCall';

export const AgentVersionCall = () => {
  const { call_id } = useParams<{ call_id: string }>();
  const [call, setCall] = useState<Call | null>(null);
  const [data, setData] = useState<TranscriptData | null>(null);
  const [url, setUrl] = useState<string | null>(null);

  useEffect(() => {
    fetchCall();
  }, [call_id]);

  const fetchCall = async () => {
    try {
      const call = await getCall(call_id);
      setCall(call.data);
      // filter the call.data.attachment for url ending with .mp3
      const transcript = call.data.attachments.filter((a) =>
        a.url.endsWith('/transcript.json')
      );
      setUrl(transcript[0].url);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (!url) return;
    const fetchData = async () => {
      try {
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setData(data);
      } catch (err) {
        console.error(err);
      }
    };

    fetchData();
  }, [url]);

  // Filter the attachments objects for content_type audio/x-wav
  const audioAttachments = call?.attachments?.filter(
    (attachment) => attachment?.content_type === 'audio/x-wav'
  );

  const callAnalysisAttachments = call?.attachments?.filter((attachment) =>
    attachment?.url.endsWith('/analysis.json')
  );

  return (
    <Flex
      css={{
        backgroundColor: 'white',
        height: '100%',
        width: '100%',
        position: 'relative',
      }}
      direction="row"
    >
      <Flex direction="column" css={{ flex: 1, overflow: 'hidden' }}>
        <Box css={{ overflowY: 'auto', paddingBottom: '125px', height: '100%' }}>
          <Transcript data={data || []} contact={call?.contact} />
        </Box>
        <Flex
          css={{
            height: 125,
            maxHeight: 125,
            borderTopWidth: 1,
            borderTopColor: '$slate3',
            p: 15,
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <AudioControl controls src={audioAttachments?.[0]?.url ?? ''}>
            <track kind="captions" />
            Your browser does not support the audio element.
          </AudioControl>
        </Flex>
      </Flex>
      <Flex
        direction="column"
        css={{
          overflowY: 'auto',
          borderLeftWidth: 1,
          borderLeftColor: '$slate3',
          width: '400px',
          p: 15,
        }}
      >
        <Box css={{ fontWeight: 'bold', pb: 20 }}>Call Analysis</Box>
        <CallAnalysis url={callAnalysisAttachments?.[0]?.url ?? ''} />
      </Flex>
    </Flex>
  );
};
