import { ErrorBoundary } from '@sentry/react';
import React, { Dispatch, SetStateAction } from 'react';

import { StyledMessageContainer } from '@/pages/inbox/conversation/items';
import { ConversationEmail } from '@/pages/inbox/conversation/items/email/ConversationEmail';
import { OutboundSMS } from '@/pages/inbox/conversation/items/sms/OutboundSMS';
import { useChannels } from '@/pages/settings/organization/channels/context/ChannelContext';
import { AccordionValue, Campaign } from '@/shared/types/campaigns';
import { Channel } from '@/shared/types/channels';
import {
  ConversationAttachmentType,
  ConversationContactType,
  ConversationItemModeTypes,
  ConversationItemSourceTypes,
  ConversationItemTypesType,
  ConversationMessageType,
} from '@/shared/types/conversations';
import { VStack } from '@/shared/ui';

import { CampaignAccordion } from '../../create/CampaignAccordion';
import { CampaignErrorBoundaryFallback } from './CampaignErrorBoundaryFallback';

type ViewCampaignMessageProps = {
  /* campaign object */
  campaign: Campaign;
  /* current accordion value */
  accordion: AccordionValue;
  /* set accordion value */
  setAccordion: Dispatch<SetStateAction<AccordionValue>>;
  /* show "Expand" instead of "View" button */
  showExpand?: boolean;
  /* hide index and green box */
  hideIndex?: boolean;
};

export const ViewCampaignMessage = (props: ViewCampaignMessageProps): JSX.Element => {
  const { campaign, accordion, setAccordion, showExpand, hideIndex } = props;
  const channel = useChannels();
  const { channels } = channel.channelsState;

  // reduce campaigns.attachments into an array of attachments objects with a url key
  const attachments = campaign?.attachment_urls?.reduce(
    (acc: ConversationAttachmentType[], attachment: string) => {
      acc.push({
        url: attachment,
        content_type: '',
        id: '',
      });
      return acc;
    },
    [] as ConversationAttachmentType[]
  );

  return (
    <CampaignAccordion
      index={3}
      title="Message"
      description="Campaign Message Body & Attachments"
      currentAccordionValue={accordion}
      itemValue={AccordionValue.CREATE_MESSAGE}
      setItemValue={setAccordion}
      isValid={true}
      buttonCopy={showExpand === true ? 'Expand' : 'View'}
      hideIndex={hideIndex}
    >
      <ErrorBoundary
        fallback={<CampaignErrorBoundaryFallback />}
        beforeCapture={(scope) => {
          scope.setTag('Campaign', 'CampaignErrorBoundary.ViewCampaignMessage');
        }}
        showDialog={false}
      >
        <VStack gap="2" data-testid="campaign-message-content">
          <StyledMessageContainer direction="OUTBOUND">
            {campaign.type === 'email' ? (
              <ConversationEmail
                message={
                  {
                    message_status: 'delivered',
                    body: campaign?.body || '',
                    attachments: attachments || [],
                    type: ConversationItemTypesType.SMS,
                    mode: ConversationItemModeTypes.CAMPAIGN,
                    user_id: campaign?.updated_by?.id,
                  } as unknown as ConversationMessageType
                }
                date={campaign.updated_at}
                user_email={campaign?.updated_by?.email || ''}
                contact={{ email: 'test@email.com' } as ConversationContactType}
                source_type={ConversationItemSourceTypes.OUTBOUND}
                channel={
                  channels.find(
                    (channel) => channel.id === campaign.channel_id
                  ) as Channel
                }
              />
            ) : (
              <OutboundSMS
                message={
                  {
                    message_status: 'delivered',
                    body: campaign?.body || '',
                    attachments: attachments || [],
                    type: ConversationItemTypesType.SMS,
                    mode: ConversationItemModeTypes.CAMPAIGN,
                  } as unknown as ConversationMessageType
                }
                date={campaign.updated_at}
                user_email={campaign?.updated_by?.email || ''}
              />
            )}
          </StyledMessageContainer>
        </VStack>
      </ErrorBoundary>
    </CampaignAccordion>
  );
};
