/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';

import { ActionDescription } from '@/pages/automation/ActionDescription';
import { ActionRenderer } from '@/shared/components/triggers';
import {
  getActionColor,
  getActionIcon,
  getActionTitle,
} from '@/shared/components/triggers/actions/ActionWrapper';
import { LinkClickTriggerType } from '@/shared/components/triggers/AddTrigger';
import { Label, VStack } from '@/shared/ui';

import { PreviewActionWrapper } from './PreviewActionWrapper';

type LinkClickAutomationsPreviewProps = {
  /* array of link click triggers */
  link_click_triggers: Array<LinkClickTriggerType>;
};

export const LinkClickAutomationsPreview = (props: LinkClickAutomationsPreviewProps) => {
  const { link_click_triggers } = props;

  // render each trigger in this template
  return link_click_triggers.map(
    (link_click_trigger: LinkClickTriggerType, i: number) => (
      <LinkClickTriggerWrapper key={i} link_click_trigger={link_click_trigger} />
    )
  );
};

export const LinkClickTriggerWrapper = (props: any) => {
  const { link_click_trigger } = props;

  return (
    <VStack gap={2} style={{ paddingTop: '10px' }}>
      <Label>When a contact clicks link in the message then:</Label>
      {link_click_trigger.actions.map((action: any, index: number) => (
        <PreviewActionWrapper
          key={index}
          title={`${getActionTitle(action)}` || ''}
          description={ActionDescription(action)}
          actionColor={`${getActionColor(action)}` || '#2C7675'}
          actionIcon={getActionIcon(action) || ''}
          action={action}
        >
          <ActionRenderer key={index} index={index} action={action} />
        </PreviewActionWrapper>
      ))}
    </VStack>
  );
};
