import {
  GetUserNotificationParams,
  NotificationsStatus,
} from '@/shared/types/notifications';

import { api } from './api';

/*
  GET - the user's organization notifications
*/

export const fetchNotifications = () =>
  api.get('/notifications').then((res) => {
    return res.data.data;
  });

/*
  POST - toggle on a user's organization notification
*/

export const createNotification = (notificationParams: any) =>
  api.post('/notifications', { notification: notificationParams }).then((res) => {
    return res.data.data;
  });

/*
  DELETE - toggle off a user's organization notification
*/

export const deleteNotification = (id: string) =>
  api.delete(`/notifications/${id}`).then((res) => {
    return res.data.data;
  });

/*

GET - List of user web notifications

{
  "data": [
    {
      "id": "e1c896be-8e03-4560-be68-55ec9da9490a",
      "inserted_at": "2023-06-22T07:42:52",
      "message": "You were mentioned in a conversation with John",
      "metadata": {
        "contact_name": "John",
        "conversation_id": "1a63c78f-4901-4c2f-ba96-6ae485fb09cc"
      },
      "notification_type": "mention",
      "organization_id": "c9540b61-87e2-4435-9178-116ea7299d81",
      "status": "unread",
      "updated_at": "2023-06-22T07:42:52",
      "user_id": 418349
    }
  ]
}

*/

// the problem with this endpoint is that if the user is in many organizations
// the the url will be too long and the request will fail
export const getUserWebNotifications = (params: GetUserNotificationParams) =>
  api.get(`/notifications/user_web_notifications`, { params }).then((res) => {
    return res.data.data;
  });

export const searchUserWebNotifications = (params: GetUserNotificationParams) =>
  api
    .post(`/notifications/user_web_notifications`, {
      limit: params.limit,
      offset: params.offset,
      organization_ids: params.organization_ids,
      status: params.status,
    })
    .then((res) => {
      return res.data.data;
    });

export const bulkMarkNotifications = (
  bulkAction: NotificationsStatus,
  resource: object
) =>
  api
    .post('v2/bulk_actions', {
      bulk_action: `notifications.mark.${bulkAction}`,
      resource: resource,
    })
    .then((res) => {
      return res.data.data;
    });
