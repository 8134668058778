import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import './datepicker.css';

import moment, { Moment } from 'moment';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { DateRangePicker } from 'react-dates';

type DatepickerProps = {
  /** setter for start and end date from parent */
  setParentDates: Dispatch<SetStateAction<any>>;
  /** minimum start date in YYYY-MM-DD format */
  minDate?: string;
  minDaysRange?: number;
  initialDates?: {
    startDate: Moment;
    endDate: Moment;
  };
};

export const Datepicker = (props: DatepickerProps) => {
  const { setParentDates, minDate, initialDates, minDaysRange = 1 } = props;
  const [focusedInput, setFocusedInput] = useState<any>();
  const [dates, setDates] = useState({
    startDate: initialDates?.startDate || moment().add(-30, 'day'),
    endDate: initialDates?.endDate || moment(),
  });

  return (
    <DateRangePicker
      startDate={dates.startDate}
      startDateId="start-date"
      endDate={dates.endDate}
      endDateId="end-date"
      onDatesChange={({ startDate, endDate }: any) => {
        setDates({ startDate, endDate });
        setParentDates({ startDate, endDate });
      }}
      minimumNights={minDaysRange}
      focusedInput={focusedInput}
      onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
      // don't allow selected dates before Aug 1st if none are passed in props
      isOutsideRange={(day) => day.isBefore(minDate || '2022-08-01')}
      displayFormat="MMM D, YYYY"
    />
  );
};
