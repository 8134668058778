import { DomainSetupState, DomainStatusType } from '@/shared/types/domains';

export const getBadgeVariant = (status?: DomainStatusType) => {
  switch (status) {
    case DomainStatusType.PENDING:
      return 'yellow';
    case DomainStatusType.ACTIVE:
      return 'green';
    case DomainStatusType.CANCELLED:
      return 'red';
    case DomainStatusType.EXPIRED:
      return 'blue';
    case DomainStatusType.DELETED:
    default:
      return 'gray';
  }
};

export const getStep = (setupState?: DomainSetupState) => {
  switch (setupState) {
    case DomainSetupState.AWAITING_SETUP:
      return {
        value: 1,
        label: 'Setup scheduled...',
      };
    case DomainSetupState.CHECKING_PRICE:
    case DomainSetupState.CHECKED_PRICE:
    case DomainSetupState.CHECKING_RATE_LIMIT:
    case DomainSetupState.CHECKED_RATE_LIMIT:
      return {
        value: 2,
        label: 'Checking domain purchase is allowed...',
      };
    case DomainSetupState.PURCHASING_DOMAIN:
    case DomainSetupState.PURCHASED_DOMAIN:
    case DomainSetupState.PURCHASING_DOMAIN_PENDING:
      return {
        value: 3,
        label: 'Purchasing domain...',
      };
    case DomainSetupState.SETTING_UP_DOMAIN_FOR_EMAILS:
      return {
        value: 3,
        label: 'Setting up domain for emails',
      };
    case DomainSetupState.SET_UP_DOMAIN_FOR_EMAILS:
      return {
        value: 4,
        label: 'Set up domain for emails',
      };
    case DomainSetupState.ADDING_PROJECT_DOMAIN:
    case DomainSetupState.ADDED_PROJECT_DOMAIN:
      return {
        value: 4,
        label: 'Adding domain to project...',
      };
    case DomainSetupState.CHECKING_VERIFIED:
    case DomainSetupState.CHECKED_VERIFIED:
    case DomainSetupState.CHECKING_MISCONFIGURED:
    case DomainSetupState.CHECKED_MISCONFIGURED:
      return {
        value: 5,
        label: 'Checking domain configuration...',
      };
    case DomainSetupState.CHECKED_EMAILS_MISCONFIGURED:
    case DomainSetupState.CHECKING_EMAILS_MISCONFIGURED:
      return {
        value: 5,
        label: 'Checking email configuration...',
      };
    case DomainSetupState.UPDATING_SUBDOMAINS_APEX_DOMAIN:
    case DomainSetupState.UPDATED_SUBDOMAINS_APEX_DOMAIN:
      return {
        value: 6,
        label: 'Updating subdomain records...',
      };
    case DomainSetupState.COMPLETED_SETUP:
      return {
        value: 7,
        label: 'Setup completed...',
      };
    default:
      return null;
  }
};

export const getProgressVariant = (status?: DomainStatusType) => {
  switch (status) {
    case DomainStatusType.PENDING:
    case DomainStatusType.ACTIVE:
      return '#16AF8A';
    case DomainStatusType.CANCELLED:
      return '#EA5757';
    default:
      return 'gray';
  }
};
