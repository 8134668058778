import * as AlertDialogPrimitive from '@radix-ui/react-alert-dialog';
import React from 'react';

import { keyframes, styled } from '@/stitches.config';

import { overlayStyles } from './Overlay';
import { panelStyles } from './Panel';

type AlertDialogProps = React.ComponentProps<typeof AlertDialogPrimitive.Root> & {
  children: React.ReactNode;
  leastDestructiveRef?: any;
};

const fadeIn = keyframes({
  from: { opacity: '0' },
  to: { opacity: '1' },
});

const fadeOut = keyframes({
  from: { opacity: '1' },
  to: { opacity: '0' },
});

const StyledOverlay = styled(AlertDialogPrimitive.Overlay, {
  ...overlayStyles,
  position: 'fixed',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,

  '&[data-state="open"]': {
    animation: `${fadeIn} 205ms cubic-bezier(0.22, 1, 0.36, 1)`,
  },

  '&[data-state="closed"]': {
    animation: `${fadeOut} 205ms cubic-bezier(0.22, 1, 0.36, 1)`,
  },
});

export function AlertDialog({ children, ...props }: AlertDialogProps) {
  return <AlertDialogPrimitive.Root {...props}>{children}</AlertDialogPrimitive.Root>;
}

const StyledContent = styled(AlertDialogPrimitive.Content, {
  ...panelStyles,
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  maxHeight: '85vh',
  padding: '$4',
  marginTop: '-5vh',
  zIndex: 99999,
  '@md': {
    minWidth: 380,
    width: '500px',
  },
  '&:focus': {
    outline: 'none',
  },
});

const StyledTitle = styled(AlertDialogPrimitive.Title, {
  fontWeight: 'bold',
  marginBottom: '10px',
  fontSize: '16px',
});

const StyledDescription = styled(AlertDialogPrimitive.Description, {
  fontSize: '14px',
  marginBottom: '30px',
});

export const AlertDialogContent = StyledContent;
export const AlertDialogTrigger = AlertDialogPrimitive.Trigger;
export const AlertDialogTitle = StyledTitle;
export const AlertDialogDescription = StyledDescription;
export const AlertDialogAction = AlertDialogPrimitive.Action;
export const AlertDialogCancel = AlertDialogPrimitive.Cancel;
export const AlertDialogPortal = AlertDialogPrimitive.Portal;
export const AlertDialogOverlay = StyledOverlay;
