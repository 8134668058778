import React, { Dispatch, memo, useMemo } from 'react';
import { HiX } from 'react-icons/hi';

import { SideNavigationHeading } from '@/inbox/list/ConversationsFilter';
import {
  PanelContainer,
  PanelContentContainer,
  PanelHeaderContainer,
} from '@/pages/inbox/conversation/panels';
import { PanelType } from '@/shared/types/links';
import { CustomCardOptions } from '@/shared/types/links';
import { CustomScrollbar, Flex, IconButton } from '@/shared/ui';

import { cleanUrl } from '../utils';
import { PreviewLinkPanel } from './PreviewLink';

type PanelProps = {
  /* open or closed the panel */
  setShowPanel: Dispatch<React.SetStateAction<boolean>>;
  /* current view */
  panel: PanelType;
  /* customCard options */
  customCard: CustomCardOptions;
  /* destination url */
  url: string;
};

const LinkPanel = (props: PanelProps): JSX.Element => {
  const { setShowPanel, panel, customCard, url } = props;

  const domainName = useMemo(() => {
    try {
      const newUrl = url && new URL(cleanUrl(url));
      return newUrl ? (newUrl as URL).hostname : '';
    } catch (e) {
      return '';
    }
  }, [url]);

  const renderPanel = (panelType: string): JSX.Element => {
    switch (panelType) {
      case PanelType.VIEW_PREVIEW:
        return <PreviewLinkPanel customCard={customCard} domain={domainName} />;
      default:
        return <Flex></Flex>;
    }
  };

  return (
    <PanelContainer direction="column" css={{ height: '100%' }}>
      <PanelHeaderContainer align="center" justify="between">
        <SideNavigationHeading css={{ textTransform: 'capitalize' }}>
          Preview
        </SideNavigationHeading>
        <IconButton size={2} onClick={() => setShowPanel(false)}>
          <HiX size={16} />
        </IconButton>
      </PanelHeaderContainer>
      <PanelContentContainer css={{ minHeight: 'calc(100% - 64px)', minWidth: '100%' }}>
        <CustomScrollbar>
          <Flex
            align="center"
            justify="center"
            css={{ minHeight: '100%', minWidth: '100%', overflowY: 'scroll', pb: 30 }}
          >
            {renderPanel(panel)}
          </Flex>
        </CustomScrollbar>
      </PanelContentContainer>
    </PanelContainer>
  );
};

export default memo(LinkPanel);
