import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'sonner';

import { usePageView } from '@/shared/hooks';
import { Button, Fieldset, Flex, Input, Label, VStack } from '@/shared/ui';
import i18next from '@/shared/utils/translation';

import { useAuth } from './context/AuthProvider';
import { AuthContainer, AuthPageTitle, AuthPageWrapper } from './Login';

/** This is for error message. */
type Message = {
  message: string;
};

/**
 * Accepts user's email to start WorkOS SSO progress.
 * When users are successfully authenticated using their Identity Provider(ex: Google or Microsoft),
 * They will be redirect to the inbox page.
 * But If users aren't authenticated properly because of any reason,
 * They will be redirected to this page and show error message.
 */

export const SSO = ({ message }: Message) => {
  usePageView();
  const auth = useAuth();
  const history = useHistory();
  const [email, setEmail] = useState('');

  const handleChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  useEffect(() => {
    /* In SSO Login page,  This component renders without message property in /sso 
       But when user fails to login, BE sends error message and render this component
       with error message.
       So we need to check if this component is with error or without error
    */
    if (message != null && message != '' && message != undefined) {
      /* If message contains `WorkOS` letters, it is error related to WorkOs */
      if (message.includes('WorkOS')) {
        toast.error(i18next.t('workos_error') as string);
      }

      /* Error message is hardcoded in BE */
      if (message.includes('No User')) {
        toast.error(i18next.t('sso_no_user_found') as string);
      }

      /* Error message is hardcoded in BE */
      if (message.includes('No Permission for Org')) {
        toast.error(i18next.t('sso_no_auth_for_organization') as string);
      }
    }
  }, [message]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      await auth.sso({ email });
    } catch (e) {
      const statusCode = e.response?.data?.error?.status;

      if (statusCode === 404) {
        message = e.response?.data?.error.message;
        // We can't find a user in our system.
        if (message.includes('no_user_found')) {
          toast.error(i18next.t('sso_no_user_found') as string);
        }
        // We found a user in our system but user doesn't have the
        // workos_organization_id
        if (message.includes('no_workos_organization_id_found')) {
          toast.error(i18next.t('sso_no_organization_id_found') as string);
        }
      }

      if (statusCode === 403) {
        // redirect to change organization page and pass tokens
        history.push('/organizations', {
          email: email,
          tokens: e.response.data.tokens,
        });
      }

      if (e.code === 'ERR_NETWORK') {
        toast.error(i18next.t('network_error'));
      }

      if (statusCode === 500) {
        toast.error(i18next.t('server_error'));
      }
    }
  };

  return (
    <>
      <Helmet>
        <title>Whippy | Login with SSO</title>
      </Helmet>
      <AuthPageWrapper align="center" justify="center">
        <AuthContainer>
          <AuthPageTitle css={{ fontSize: 18, pb: 15 }}>Sign in With SSO</AuthPageTitle>
          <form onSubmit={handleSubmit}>
            <VStack gap={5} css={{ pb: 15 }}>
              <Fieldset>
                <Label htmlFor="email">Email</Label>
                <Input
                  id="email"
                  type="email"
                  autoComplete="username"
                  value={email}
                  onChange={handleChangeEmail}
                  required={true}
                />
              </Fieldset>
              <Button type="submit" css={{ minWidth: '100%' }}>
                Login to Whippy
              </Button>
              <Flex justify="center">
                <Link to="/login">Return to login?</Link>
              </Flex>
            </VStack>
          </form>
        </AuthContainer>
      </AuthPageWrapper>
    </>
  );
};
