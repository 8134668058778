import * as ReactNavigationMenu from '@radix-ui/react-navigation-menu';
import { keyframes, styled } from '@stitches/react';

const linkStyles = {
  padding: '12px 16px',
  outline: 'none',
  userSelect: 'none',
  fontSize: '13px',
  color: '#D7DBDF',
  fontWeight: 600,
  lineHeight: '20px',
  '&:hover': {
    backgroundColor: '#13171E',
  },
  '&[data-active]': {
    backgroundColor: '#13171E',
  },
};

const scaleIn = keyframes({
  '0%': {
    opacity: 0,
    transform: 'rotateX(-30deg) scale(0.9)',
  },
  '100%': {
    opacity: 1,
    transform: 'rotateX(0deg) scale(1)',
  },
});

const scaleOut = keyframes({
  '0%': {
    opacity: 1,
    transform: 'rotateX(0deg) scale(1)',
  },
  '100%': {
    opacity: 0,
    transform: 'rotateX(-10deg) scale(0.95)',
  },
});

const enterFromLeft = keyframes({
  '0%': {
    opacity: 0,
    transform: 'translateX(-200px)',
  },
  '100%': {
    opacity: 1,
    transform: 'translateX(0)',
  },
});

const enterFromRight = keyframes({
  '0%': {
    opacity: 0,
    transform: 'translateX(200px)',
  },
  '100%': {
    opacity: 1,
    transform: 'translateX(0)',
  },
});

const exitToRight = keyframes({
  '0%': {
    opacity: 1,
    transform: 'translateX(0)',
  },
  '100%': {
    opacity: 0,
    transform: 'translateX(200px)',
  },
});

const exitToLeft = keyframes({
  '0%': {
    opacity: 1,
    transform: 'translateX(0)',
  },
  '100%': {
    opacity: 0,
    transform: 'translateX(-200px)',
  },
});

const StyledNavigationMenu = styled(ReactNavigationMenu.Root, {
  fill: 'white',
  position: 'relative',
  display: 'flex',
  width: '100vw',
  backgroundColor: '#1E212D',
});

export const StyledNavigationMenuList = styled(ReactNavigationMenu.List, {
  display: 'flex',
  backgroundColor: '#1E212D',
  listStyle: 'none',
  paddingRight: '0',
  margin: 0,
});

const StyledNavigationMenuLink = styled(ReactNavigationMenu.Link, {
  ...linkStyles,
  display: 'block',
  textDecoration: 'none',
});

const StyledNavigationMenuTrigger = styled(ReactNavigationMenu.Trigger, {
  ...linkStyles,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '5px',
  '&[data-state="open"]': {
    backgroundColor: '#13171E',
  },
});

const StyledNavigationMenuContent = styled(ReactNavigationMenu.Content, {
  zIndex: 100,
  position: 'absolute',
  backgroundColor: '#13171E',
  top: '100%',
  width: 'auto',
  animationDuration: '150ms',
  animationTimingFunction: 'ease',
  '&[data-motion="to-start"]': {
    animationName: exitToLeft,
  },
  '&[data-motion="to-end"]': {
    animationName: exitToRight,
  },
  '&[data-motion="from-start"]': {
    animationName: enterFromLeft,
  },
  '&[data-motion="from-end"]': {
    animationName: enterFromRight,
  },
});

export const StyledNavigationMenuViewport = styled(ReactNavigationMenu.Viewport, {
  position: 'relative',
  transformOrigin: 'top center',
  width: '100%',
  backgroundColor: '#13171E',
  overflow: 'hidden',
  boxShadow:
    'hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px',
  height: 'var(--radix-navigation-menu-viewport-height)',

  '@media only screen and (min-width: 600px)': {
    width: 'var(--radix-navigation-menu-viewport-width)',
  },
  '@media (prefers-reduced-motion: no-preference)': {
    transition: 'width, height, 300ms ease',
    '&[data-state="open"]': { animation: `${scaleIn} 100ms ease` },
    '&[data-state="closed"]': { animation: `${scaleOut} 100ms ease` },
  },
});

// Exports
export const NavigationMenuItem = ReactNavigationMenu.Item;
export const NavigationMenu = StyledNavigationMenu;
export const NavigationMenuList = StyledNavigationMenuList;
export const NavigationMenuLink = StyledNavigationMenuLink;
export const NavigationMenuTrigger = StyledNavigationMenuTrigger;
export const NavigationMenuContent = StyledNavigationMenuContent;
