/* eslint-disable @typescript-eslint/no-unused-vars */
import { debounce } from 'lodash';
import React, { useState } from 'react';
import { CirclePicker } from 'react-color';
import { useCopyToClipboard } from 'react-use';

import { fetchOrganizationsInfo } from '@/shared/api/auth';
import { updateWebchat } from '@/shared/api/webchat';
import {
  Box,
  Button,
  Divider,
  Fieldset,
  Flex,
  Input,
  Label,
  RadioGroup,
  RadioGroupIndicator,
  RadioGroupRadio as Radio,
  StyledRadioIndicator,
  Text,
  TextArea,
  VStack,
} from '@/shared/ui';

import WebchatImage from './WebchatImage';
import WebchatLocations from './WebchatLocations';

// Pass in snippet HTML to allow copy to clipboard.
function CodeBlock(value: any) {
  const code = value['value'];
  const [state, copyToClipboard] = useCopyToClipboard();
  const [copied, setCopied] = useState(false);

  const handleClick = (code: any) => {
    setCopied(true);
    copyToClipboard(code);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  return (
    <Flex>
      <Input value={code} placeholder="Welcome" css={{ mr: '4px' }} />
      <Button onClick={() => handleClick(code)} data-intercom-target="copy-code-button">
        {copied ? '🎉' : 'Copy'}
      </Button>
    </Flex>
  );
}

type Props = Record<string, unknown>;
type State = {
  organizationId: string;
  color: string;
  greeting?: string;
  position: string;
  show_when: string;
};

class WebchatView extends React.Component<Props, State> {
  state: State = {
    organizationId: '',
    color: '#294ccb;',
    greeting: '',
    position: '',
    show_when: 'always',
  };

  // GET organization widget details on mount
  async componentDidMount() {
    const { organization } = await fetchOrganizationsInfo();
    const { id: organizationId, widget_settings: webchatSettings } = organization;

    if (webchatSettings && webchatSettings.id) {
      const { color, greeting, position, show_when } = webchatSettings;

      this.setState({
        organizationId,
        greeting,
        color: color || this.state.color,
        position,
        show_when,
      });
    } else {
      this.setState({ organizationId });
    }
  }

  // Optimistically update webchat settings
  debouncedUpdateWidgetSettings = debounce(() => this.updateWidgetSettings(), 400);

  // Set webchat greeting from text area
  handleChangeGreeting = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({ greeting: e.target.value }, this.debouncedUpdateWidgetSettings);
  };

  // Set webchat color from color picker
  handleChangeColor = (color: any) => {
    this.setState({ color: color.hex }, this.debouncedUpdateWidgetSettings);
  };

  // Set webchat color from color picker
  handleChangePosition = (position: string) => {
    this.setState({ position: position }, this.debouncedUpdateWidgetSettings);
  };

  // Set webchat color from color picker
  handleChangeShowWhen = (show: string) => {
    this.setState({ show_when: show }, this.debouncedUpdateWidgetSettings);
  };

  // PUT new webchat color and greeting
  updateWidgetSettings = async () => {
    const { color, greeting, position, show_when } = this.state;

    updateWebchat({
      color,
      greeting,
      position,
      show_when,
    });
  };

  // Generate the JS snippet to load the webchat on websites
  generateHtmlCode = () => {
    const { organizationId } = this.state;

    return `<script>window.Whippy = { config: { organizationId: "${organizationId}" },};</script>
    <script type="text/javascript" async defer src="https://web.whippy.co/whippy.js"></script>`.trim();
  };

  render() {
    const { greeting, position, show_when } = this.state;

    return (
      <Box css={{ maxWidth: '600px' }}>
        <VStack gap={7}>
          <Fieldset>
            <VStack gap={4}>
              <Box>
                <Label>Image</Label>
                <Text>Set a custom webchat picture</Text>
              </Box>
              <Divider />
              <WebchatImage />
            </VStack>
          </Fieldset>
          <Fieldset>
            <VStack gap={4}>
              <Box>
                <Label>Greeting</Label>
                <Text>
                  The greeting is what customers will see when they visit your website
                </Text>
              </Box>
              <Divider />
              <Box>
                <TextArea
                  name="greeting"
                  data-intercom-target="webchat-greeting-editor"
                  value={greeting}
                  onChange={this.handleChangeGreeting}
                  onBlur={this.updateWidgetSettings}
                  maxLength={117}
                />
                <Flex justify="end" css={{ width: '100%' }}>
                  <Text align="right">
                    {greeting?.length ? greeting?.length : 0}
                    /117
                  </Text>
                </Flex>
              </Box>
            </VStack>
          </Fieldset>
          <Fieldset>
            <VStack gap={4}>
              <Box>
                <Label>Bubble Color</Label>
                <Text>Choose a color to match your brand</Text>
              </Box>
              <Divider />
              <CirclePicker
                data-intercom-target="webchat-color-picker"
                color={this.state.color}
                onChangeComplete={this.handleChangeColor}
              />
            </VStack>
          </Fieldset>
          <Fieldset>
            <VStack gap={4}>
              <Box>
                <Label>Channels</Label>
                <Text>Select which channels you want your customers to see</Text>
              </Box>
              <Divider />
              <Box>
                <WebchatLocations />
              </Box>
            </VStack>
          </Fieldset>
          <Fieldset>
            <VStack gap={4}>
              <Box>
                <Label>Webchat Snippet</Label>
                <Text>
                  Copy this snippet and add to your website or send to your web developer
                </Text>
              </Box>
              <Divider />
              <CodeBlock value={this.generateHtmlCode()} />
            </VStack>
          </Fieldset>
          <Fieldset>
            <VStack gap={4}>
              <Box>
                <Label>Webchat Postilion</Label>
                <Text>Choose what side to show the webchat on your website</Text>
              </Box>
              <Divider />
              <RadioGroup
                value={position || 'right'}
                onValueChange={this.handleChangePosition}
              >
                <VStack gap={2}>
                  <StyledRadioIndicator css={{ background: 'white' }} align="center">
                    <Radio value="right">
                      <RadioGroupIndicator />
                    </Radio>
                    <Label htmlFor="r1" css={{ mb: 0, ml: '10px' }}>
                      Right Corner
                    </Label>
                  </StyledRadioIndicator>
                  <StyledRadioIndicator css={{ background: 'white' }} align="center">
                    <Radio value="left">
                      <RadioGroupIndicator />
                    </Radio>
                    <Label htmlFor="r1" css={{ mb: 0, ml: '10px' }}>
                      Left Corner
                    </Label>
                  </StyledRadioIndicator>
                </VStack>
              </RadioGroup>
            </VStack>
          </Fieldset>
          <Fieldset>
            <VStack gap={4}>
              <Box>
                <Label>Webchat Display</Label>
                <Text>Choose when to show the webchat on your website</Text>
              </Box>
              <Divider />
              <RadioGroup
                value={show_when || 'always'}
                onValueChange={this.handleChangeShowWhen}
              >
                <VStack gap={2}>
                  <StyledRadioIndicator css={{ background: 'white' }} align="center">
                    <Radio value="always">
                      <RadioGroupIndicator />
                    </Radio>
                    <Label htmlFor="r1" css={{ mb: 0, ml: '10px' }}>
                      Always show webchat
                    </Label>
                  </StyledRadioIndicator>
                  <StyledRadioIndicator css={{ background: 'white' }} align="center">
                    <Radio value="open">
                      <RadioGroupIndicator />
                    </Radio>
                    <Label htmlFor="r1" css={{ mb: 0, ml: '10px' }}>
                      Only show a location when open
                    </Label>
                  </StyledRadioIndicator>
                  <StyledRadioIndicator css={{ background: 'white' }} align="center">
                    <Radio value="closed">
                      <RadioGroupIndicator />
                    </Radio>
                    <Label htmlFor="r1" css={{ mb: 0, ml: '10px' }}>
                      Only show a location when closed
                    </Label>
                  </StyledRadioIndicator>
                </VStack>
              </RadioGroup>
            </VStack>
          </Fieldset>
        </VStack>
      </Box>
    );
  }
}

export default WebchatView;
