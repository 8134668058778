import { ErrorBoundary } from '@sentry/react';
import i18next from 'i18next';
import React from 'react';
import { HiStatusOffline } from 'react-icons/hi';
import { toast } from 'sonner';

import { TooltipButton } from '@/pages/campaigns/v2/analytics/CampaignActions';
import { Button } from '@/shared/ui';

// Fallback Component to be rendered in case of an error
const AddTriggerFallBack = () => (
  <TooltipButton text="Add Trigger">
    <Button variant="red" css={{ width: '100%' }}>
      <HiStatusOffline />
      Add Trigger Unavailable
    </Button>
  </TooltipButton>
);

type AddTriggerErrorBoundariesProps = {
  feature: string;
  children: React.ReactNode;
};

export const AddTriggerErrorBoundaries: React.FC<AddTriggerErrorBoundariesProps> = ({
  feature,
  children,
}) => {
  // need to have a feature signal
  if (!feature || feature.length < 1) return null;
  // need to have children to render
  if (!children) return null;

  const localizationError = `${feature}_error`;
  const errorTag = `addTrigger.${feature}`;

  return (
    <ErrorBoundary
      fallback={<AddTriggerFallBack />}
      beforeCapture={(scope) => {
        toast.error(
          (i18next.t(localizationError) as string) || 'An error occurred in add trigger.'
        );
        scope.setTag('ErrorLocation', errorTag);
      }}
      showDialog={false}
    >
      {children}
    </ErrorBoundary>
  );
};
