import i18next from 'i18next';
import { isBoolean } from 'lodash';
import React, { useEffect } from 'react';
import { createContext, useContext, useReducer } from 'react';
import { toast } from 'sonner';

import { prepareFilters } from '@/pages/data/utils/prepareFilters';
import { DomainParams, domains, SetupType } from '@/shared/api/links/domains';
import { SearchFilters } from '@/shared/types/contacts';
import { Availability, Domain } from '@/shared/types/domains';

import { DomainsReducer } from './DomainsReducer';
import { DomainsActionType, DomainsState } from './types';

export const initialDomainsState: DomainsState = {
  domains: [],
  activeDomains: [],
  current: null,
  totalCount: 0,
  loading: true,
  loadingDomain: true,
  error: null,
  filterParams: {
    offset: 0,
    limit: 10,
    sort: [
      {
        label: 'Updated At',
        column: 'updated_at',
        order: 'desc',
        resource: 'domain',
        id: null,
      },
    ],
    filter: [],
    searchFilter: [],
  },
};

export const ITEMS_COUNT = 10;

export const DomainsContext = createContext<{
  domainsState: DomainsState;
  getDomains: (params: SearchFilters) => Promise<void>;
  getActiveDomains: () => void;
  getDomain: (id: string, showLoading?: boolean) => void;
  deleteDomain: (id: string) => void;
  addDomain: (params: DomainParams) => Promise<void | Domain>;
  buyDomain: (params: DomainParams) => Promise<void | Domain>;
  updateDomain: (params: Partial<DomainParams>) => Promise<void | Domain>;
  getDomainAvailability: (name: string) => Promise<void | Availability>;
  updateFilterParams: (params: SearchFilters) => void;
}>({
  domainsState: initialDomainsState,
  getDomains: () => Promise.resolve(),
  getActiveDomains: () => Promise.resolve(),
  getDomain: () => Promise.resolve(),
  deleteDomain: () => Promise.resolve(),
  addDomain: () => Promise.resolve(),
  buyDomain: () => Promise.resolve(),
  updateDomain: () => Promise.resolve(),
  getDomainAvailability: () => Promise.resolve(),
  updateFilterParams: () => Promise.resolve(),
});

export const useDomainsContext = () => useContext(DomainsContext);

const DomainsProvider = ({ children }: { children: React.ReactNode }) => {
  const [domainsState, dispatch] = useReducer(DomainsReducer, initialDomainsState);

  useEffect(() => {
    getActiveDomains();
  }, []);

  useEffect(() => {
    getDomains(domainsState.filterParams);
  }, [domainsState.filterParams]);

  const getDomains = async (params: SearchFilters) => {
    try {
      const data = await domains.searchDomains(prepareFilters(params));

      dispatch({
        type: DomainsActionType.GET_DOMAINS,
        payload: {
          data: data?.data,
          total: data?.total,
        },
      });
    } catch (err) {
      if (err?.response) {
        dispatch({
          type: DomainsActionType.SET_ERROR,
          payload: err.response.msg,
        });
      }
    }
  };

  const getActiveDomains = async () => {
    try {
      const searchParams = {
        filter: [
          {
            column: 'status',
            comparison: '==',
            resource: 'domain',
            value: 'active',
          },
        ],
      };
      const { data } = await domains.searchDomains(searchParams);

      dispatch({
        type: DomainsActionType.GET_ACTIVE_DOMAINS,
        payload: data,
      });
    } catch (err) {
      if (err?.response) {
        dispatch({
          type: DomainsActionType.SET_ERROR,
          payload: err.response.msg,
        });
      }
    }
  };

  const getDomain = async (id: string, showLoading?: boolean) => {
    if (!isBoolean(showLoading) || showLoading)
      dispatch({
        type: DomainsActionType.SET_LOADING_DOMAIN,
        payload: true,
      });
    try {
      const { data } = await domains.getDomain(id);

      dispatch({
        type: DomainsActionType.GET_DOMAIN,
        payload: data,
      });
    } catch (err) {
      if (err?.response) {
        dispatch({
          type: DomainsActionType.SET_ERROR,
          payload: err.response.msg,
        });
      }
    } finally {
      dispatch({
        type: DomainsActionType.SET_LOADING_DOMAIN,
        payload: false,
      });
    }
  };

  const deleteDomain = async (id: string) => {
    try {
      await domains.deleteDomain(id);

      dispatch({
        type: DomainsActionType.DELETE_DOMAIN,
        payload: id,
      });
      getDomains({ ...domainsState.filterParams });
      toast.success(i18next.t('domain_delete_success') as string);
    } catch (err) {
      if (err.response?.data) {
        toast.error(err.response?.data?.errors[0]?.description);
        dispatch({
          type: DomainsActionType.SET_ERROR,
          payload: err.response?.data?.errors?.[0]?.description,
        });
      } else {
        toast.error(i18next.t('domain_delete_failure') as string);
      }
      throw err;
    }
  };

  const addDomain = async (params: DomainParams) => {
    try {
      const { data } = await domains.createDomain({
        ...params,
        setup_type: SetupType.ADD,
        emails_provider: 'mailgun',
      });

      dispatch({
        type: DomainsActionType.ADD_DOMAIN,
        payload: data,
      });

      if (domainsState.domains.length) {
        getDomains({ ...domainsState.filterParams });
      }
      toast.success(i18next.t('domain_add_success') as string);
      return data;
    } catch (err) {
      if (err.response?.data) {
        dispatch({
          type: DomainsActionType.SET_ERROR,
          payload: err.response?.data?.errors?.[0]?.description,
        });
      } else {
        toast.error(i18next.t('domain_add_failure') as string);
      }
      throw err;
    }
  };

  const updateDomain = async (params: Partial<DomainParams>) => {
    try {
      const { data } = await domains.updateDomain({
        ...params,
        emails_provider: 'mailgun',
      });

      dispatch({
        type: DomainsActionType.UPDATE_DOMAIN,
        payload: data,
      });

      getDomains({ ...domainsState.filterParams });
      toast.success(i18next.t('domain_update_success') as string);
      return data;
    } catch (err) {
      if (err.response?.data) {
        dispatch({
          type: DomainsActionType.SET_ERROR,
          payload: err.response?.data?.errors?.[0]?.description,
        });
      } else {
        toast.error(i18next.t('domain_update_failure') as string);
      }
      throw err;
    }
  };

  const buyDomain = async (params: DomainParams) => {
    try {
      const { data } = await domains.createDomain({
        ...params,
        setup_type: SetupType.BUY,
        emails_provider: 'mailgun',
      });

      dispatch({
        type: DomainsActionType.BUY_DOMAIN,
        payload: data,
      });

      if (domainsState.domains.length) {
        getDomains({ ...domainsState.filterParams });
      }
      toast.success(i18next.t('domain_buy_success') as string);
      return data;
    } catch (err) {
      if (err?.response?.data) {
        dispatch({
          type: DomainsActionType.SET_ERROR,
          payload: err.response?.data?.errors?.[0]?.description,
        });
      } else {
        toast.error(i18next.t('domain_buy_failure') as string);
      }
      throw err;
    }
  };

  const getDomainAvailability = async (name: string) => {
    try {
      const data = await domains.getDomainAvailability(name);

      return data;
    } catch (err) {
      if (err?.response?.data) {
        dispatch({
          type: DomainsActionType.SET_ERROR,
          payload: err.response?.data?.errors?.[0]?.description,
        });
      }
      throw err;
    }
  };

  const updateFilterParams = async (params: SearchFilters) => {
    try {
      dispatch({
        type: DomainsActionType.UPDATE_FILTER_PARAMS,
        payload: params,
      });
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <DomainsContext.Provider
      value={{
        domainsState,
        getDomains,
        getActiveDomains,
        getDomain,
        deleteDomain,
        addDomain,
        buyDomain,
        getDomainAvailability,
        updateFilterParams,
        updateDomain,
      }}
    >
      {children}
    </DomainsContext.Provider>
  );
};

export default DomainsProvider;
