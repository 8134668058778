/* eslint-disable react-hooks/exhaustive-deps */
import { useCombobox } from 'downshift';
import React, { useEffect, useRef, useState } from 'react';
import { HiChevronDown } from 'react-icons/hi';

import { CSS } from '@/stitches.config';

import { useDisclosure } from '../hooks';
import {
  Box,
  Checkbox,
  ComboboxItem,
  ComboboxItemText,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
  HStack,
  Input,
  ScrollArea,
  ScrollAreaCorner,
  ScrollAreaScrollbar,
  ScrollAreaThumb,
  ScrollAreaViewport,
} from '../ui';
import { ConditionalComponent } from './forms/MultiSelectCombobox';

export type SelectOption = {
  /** name of option */
  type: string;
  /** id of option */
  value: string;
};

type SingleSelectProps = {
  /** The selected value */
  selectItem: string;
  /** The select function option */
  setSelectItem: (item: string) => void;
  /** list of options in the [{type: string, value: string}] format */
  options: Array<SelectOption>;
  /** list of options that you want to be selected by default [value1: string, value2: string] format */
  defaultSelectedItem?: string;
  /** placeholder for the input field */
  defaultPlaceholder: string;
  /** will the component be a dropdown */
  isDropdown: boolean;
  /** if this is true all items will be unselected */
  clearSelectedItem?: boolean;
  /** if this is true close the dropdown on click*/
  closeOnClick?: boolean;
  /** if this is true the input height is reduced*/
  isSmall?: boolean;
  /** if this is true the input height is increased*/
  isLarge?: boolean;
  /** if this is true the input is disabled*/
  disabled?: boolean;
  /** true if on user page */
  isUserPage?: boolean;
  /** data-testid for testing */
  dataTestId?: string;
  /** input styles */
  inputStyles?: CSS;
};

export const SINGLE_SELECT_OPTIONS = {
  SELECT_ALL: 'SELECT_ALL',
};

export const SingleSelect = (props: SingleSelectProps): JSX.Element => {
  const { selectItem, setSelectItem, inputStyles } = props;
  //if users page -> dropdown with select all
  const [inputItems, setInputItems] = useState(
    props.isUserPage
      ? [
          { type: 'Select All', value: SINGLE_SELECT_OPTIONS.SELECT_ALL },
          ...props.options,
        ]
      : props.options
  );

  const inputRef = useRef() as React.MutableRefObject<HTMLInputElement>;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [anchorWidth, setAnchorWidth] = useState(0);
  const [placeholder, setPlaceholder] = useState(props.defaultPlaceholder || '');

  const updateAnchorWidth = () => {
    setAnchorWidth(inputRef.current.offsetWidth);
  };

  const handleClick = () => {
    if (props.disabled) {
      return;
    }
    if (!isOpen) {
      onOpen();
      updateAnchorWidth();
      setPlaceholder('');
    } else {
      setPlaceholder(props.defaultPlaceholder);
      onClose();
    }
  };

  const handlePointerDownOutside = (e: Event) => {
    if (e.currentTarget !== inputRef.current) {
      setPlaceholder(props.defaultPlaceholder);
      onClose();
    }
  };

  useEffect(() => {
    setPlaceholder(props.defaultPlaceholder || '');
  }, [selectItem, props.defaultPlaceholder]);

  useEffect(() => {
    if (props.clearSelectedItem) {
      setSelectItem(props.defaultSelectedItem || '');
    }
  }, [props.clearSelectedItem]);

  const { getMenuProps, getInputProps, getItemProps, highlightedIndex } = useCombobox({
    itemToString: (item) => (item ? item.type : ''),
    items: inputItems,
    onSelectedItemChange: ({ selectedItem }) => {
      if (!selectedItem) return;
      const index = selectItem?.indexOf(selectedItem?.value);
      if (index >= 0) {
        props.closeOnClick ? onClose() : null;
        // refactor with the whole singleSelect
        if (props.isUserPage) {
          return setSelectItem(selectedItem?.value);
        }
        return setSelectItem('');
      } else {
        props.closeOnClick ? onClose() : null;
        return setSelectItem(selectedItem?.value);
      }
    },
    selectedItem: null,
    stateReducer: (state, actionAndChanges) => {
      const { type, changes } = actionAndChanges;
      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
        case useCombobox.stateChangeTypes.InputKeyDownEscape:
        case useCombobox.stateChangeTypes.InputBlur:
          return {
            ...changes,
            inputValue: '',
          };
        default:
          return {
            ...changes,
          };
      }
    },
    onInputValueChange: ({ inputValue }) => {
      if (inputValue) {
        const filteredOptions = props.options.filter((item) =>
          item.type.toLowerCase().includes(inputValue.toLowerCase())
        );
        setInputItems(filteredOptions);
      } else {
        setInputItems(props.options);
      }
    },
  });

  return (
    <ConditionalComponent
      condition={!!props.isDropdown}
      wrapper={{
        base: (children) => (children ? children : null),
        custom: (children) => (
          <DropdownMenu open={isOpen} onOpenChange={() => !isOpen} modal={false}>
            {children}
          </DropdownMenu>
        ),
      }}
    >
      <>
        <Box>
          <ConditionalComponent
            condition={!!props.isDropdown}
            wrapper={{
              base: (children) => (
                <Box
                  css={{
                    position: 'relative',
                  }}
                >
                  {children}
                </Box>
              ),
              custom: (children) => (
                <DropdownMenuTrigger asChild={true} onClick={handleClick}>
                  <Box css={{ position: 'relative' }}>
                    <HiChevronDown
                      style={{
                        position: 'absolute',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        right: '10px',
                        pointerEvents: 'none',
                        fontSize: '16px',
                      }}
                    />
                    {children}
                  </Box>
                </DropdownMenuTrigger>
              ),
            }}
          >
            <ConditionalComponent
              condition={false}
              wrapper={{
                base: () => (
                  <Input
                    data-testid={props.dataTestId || 'single-select'}
                    {...getInputProps(
                      {
                        placeholder: placeholder,
                        autoComplete: 'chrome-off',
                        type: 'text',
                        ref: inputRef,
                      },
                      { suppressRefError: true }
                    )}
                    disabled={!!props.disabled}
                    variant={props.isDropdown ? 'selectDropdown' : null}
                    css={{
                      textTransform: 'capitalize',
                      maxHeight: props.isSmall ? '27px' : props.isLarge ? '36px' : '32px',
                      ...(inputStyles || {}),
                    }}
                  />
                ),
                custom: () => null,
              }}
            />
          </ConditionalComponent>
        </Box>
        <Box {...getMenuProps({}, { suppressRefError: true })}>
          <ConditionalComponent
            condition={!!props.isDropdown}
            wrapper={{
              base: (children) => (children ? children : null),
              custom: (children) => (
                <DropdownMenuContent
                  side="bottom"
                  sideOffset={5}
                  variant="combobox"
                  onPointerDownOutside={(e) => handlePointerDownOutside(e)}
                  onFocus={() => inputRef.current.focus()}
                >
                  {children}
                </DropdownMenuContent>
              ),
            }}
          >
            <ConditionalComponent
              condition={false}
              wrapper={{
                base: (children) => (
                  <ScrollArea variant="combobox">{children}</ScrollArea>
                ),
                custom: () => null,
              }}
            >
              <>
                <ScrollAreaViewport variant="combobox">
                  {inputItems.length > 0 ? (
                    inputItems.map((item, index) => (
                      <Box
                        key={`${item.value}${index}`}
                        {...getItemProps({ item, index })}
                        css={{ width: props.isDropdown ? anchorWidth : 'auto' }}
                      >
                        <ConditionalComponent
                          condition={false}
                          wrapper={{
                            base: () => (
                              <ComboboxItem
                                selected={highlightedIndex === index}
                                css={{
                                  borderBottom: !item.value
                                    ? '1px solid $slate7'
                                    : 'auto',
                                }}
                              >
                                <HStack gap={2} css={{ overflow: 'hidden' }}>
                                  <Checkbox
                                    checked={
                                      selectItem?.includes(item?.value) ||
                                      selectItem?.length === props?.options?.length
                                    }
                                    css={{ pointerEvents: 'none', flex: 'none' }}
                                  />

                                  <ComboboxItemText css={{ textTransform: 'capitalize' }}>
                                    {item.type}
                                  </ComboboxItemText>
                                </HStack>
                              </ComboboxItem>
                            ),
                            custom: () => null,
                          }}
                        />
                      </Box>
                    ))
                  ) : (
                    <Box css={{ width: props.isDropdown ? anchorWidth : 'auto' }}>
                      <ConditionalComponent
                        condition={false}
                        wrapper={{
                          base: () => (
                            <ComboboxItem
                              disabled="true"
                              justify="center"
                              align="center"
                              css={{ pointerEvent: 'none' }}
                            >
                              <ComboboxItemText>No Results Found</ComboboxItemText>
                            </ComboboxItem>
                          ),
                          custom: () => null,
                        }}
                      />
                    </Box>
                  )}
                </ScrollAreaViewport>
                <ScrollAreaScrollbar orientation="vertical" variant="combobox">
                  <ScrollAreaThumb />
                </ScrollAreaScrollbar>
                <ScrollAreaCorner />
              </>
            </ConditionalComponent>
          </ConditionalComponent>
        </Box>
      </>
    </ConditionalComponent>
  );
};
