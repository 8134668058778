import React, { useEffect, useState } from 'react';
import { HiCog, HiDuplicate } from 'react-icons/hi';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';

import {
  pre_selected_audience_initial_state,
  PreSelectedAudience,
} from '@/pages/campaigns/quick';
import { prepareFilters } from '@/pages/data/utils/prepareFilters';
import * as SequencesAPI from '@/shared/api/sequences';
import { duplicateSequenceTemplate } from '@/shared/api/sequences/templates';
import { ToolTipIconButton } from '@/shared/components/attachments/previewer';
import { DownloadContacts } from '@/shared/components/DownloadContacts';
import { useDisclosure } from '@/shared/hooks';
import { PageLayout } from '@/shared/layouts/PageLayout';
import { Sequence, SequenceStatus, SequenceStepContact } from '@/shared/types/sequences';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogOverlay,
  AlertDialogPortal,
  AlertDialogTitle,
  AlertDialogTrigger,
  Box,
  Button,
  Flex,
  HStack,
} from '@/shared/ui';
import { styled } from '@/stitches.config';

import { useSequences } from '../context/SequenceContext';
import { SequenceAudience } from './audience/AddAudienceToSequence';
import { SequenceEditor } from './SequenceEditor';

type SequenceContainerProps = {
  children: React.ReactNode;
};

// Number of contacts to fetch at a time
const DATA_LIMIT = 50;

export const SequenceContainer = ({ children }: SequenceContainerProps) => {
  const sequenceContext = useSequences();
  const { sequencesState, getSequence, setCurrentSequence } = sequenceContext;
  const { current } = sequencesState;

  // get the sequence id from the url
  const { id } = useParams<{ id: string }>();
  const match = useRouteMatch<{ id: string; tab: string }>('/sequences/:id/:tab');
  const tab = match?.params?.tab as string;
  // capitalize the first letter of the tab
  const tabTitle = tab ? tab.charAt(0).toUpperCase() + tab.slice(1) : '';

  useEffect(() => {
    // if the current sequence is not set in state, get it from the api
    getSequence(id);

    // on unmount, set the current sequence to null
    return () => {
      setCurrentSequence(null);
    };
  }, []);

  return (
    <SequencePageLayout current={current} tabTitle={tabTitle} tabUrl={tab}>
      <Box css={{ height: '100%' }}>{children}</Box>
    </SequencePageLayout>
  );
};

// This is the wrapper for a sequence page,
// it generated the breadcrumbs from the current sequence
const SequencePageLayout = ({
  children,
  current,
  tabTitle,
  tabUrl,
}: {
  children: React.ReactNode;
  current?: Sequence | null;
  tabTitle?: string;
  tabUrl?: string;
}) => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();

  const sequences = useSequences();
  const { duplicateSequence } = sequences;

  const [downloading, setDownloading] = useState(false);

  const [preSelectedAudience, setPreSelectedAudience] = useState<PreSelectedAudience>(
    pre_selected_audience_initial_state
  );

  const getSequenceResponses = async (isDownloadAll: boolean) => {
    let allContacts:
      | any[]
      | ((
          prevState: {
            name: string;
            email: string;
            phone: string;
          }[]
        ) => {
          name: string;
          email: string;
          phone: string;
        }[]) = [];
    if (current?.id) {
      let offset = 0;
      let keepLoading = true;

      // Define default params for download all contacts option
      const defaultParams = {
        filter: [],
        sort: [
          {
            column: 'inserted_at',
            order: 'desc',
            resource: 'step_contact',
          },
        ],
        limit: DATA_LIMIT,
      };

      // Determine whether to default params or filtered params
      const params = isDownloadAll
        ? defaultParams
        : prepareFilters(sequences.sequencesState.sequenceResponsesParams);

      // Also sort the steps by their position and include the position in the step title
      const sortedSteps = current?.steps?.sort(
        (a, b) => Number(a?.position) - Number(b?.position)
      );

      const stepTitleMap = current?.steps?.reduce(
        (map: { [key: string]: string }, step) => {
          step && step.id
            ? (map[step?.id] = `Step ${Number(step?.position) + 1} - ${step.title}`)
            : null;
          return map;
        },
        {}
      );

      while (keepLoading) {
        const responses = await SequencesAPI.getSequenceResponses(
          current.id,
          {
            ...params,
            limit: DATA_LIMIT,
            offset,
          },
          null
        );

        // Format the contacts data for export
        const formattedExportContacts = responses.data.map(
          (contact: {
            contact: { name: string; email: string; phone: string };
            step_contacts: SequenceStepContact[];
          }) => {
            // initial contact data
            const contactData: {
              [key: string]: string | boolean | SequenceStatus | Date;
            } = {
              name: contact.contact.name,
              email: contact.contact.email,
              phone: contact.contact.phone,
            };

            // Sort step_contacts based on the order of sortedSteps
            const sortedStepContacts = contact.step_contacts.sort((a, b) => {
              const stepAPosition = sortedSteps?.findIndex(
                (step) => step.id === a.step_id
              );
              const stepBPosition = sortedSteps?.findIndex(
                (step) => step.id === b.step_id
              );
              return Number(stepAPosition) - Number(stepBPosition);
            });

            // Initialize an array to store all the inserted_at times
            const insertedAtTimes: Date[] = [];

            sortedStepContacts.forEach((step) => {
              const stepTitle = stepTitleMap && stepTitleMap[step?.step_id];
              if (stepTitle) {
                contactData[stepTitle] = step.contact_messages[0]?.body || 'N/A'; // Default to 'N/A' if no message
              }

              // If the contact_message has an inserted_at value, add it to the array
              if (step.contact_messages[0]?.inserted_at) {
                insertedAtTimes.push(new Date(step.contact_messages[0].inserted_at));
              }
            });

            sortedStepContacts.forEach((step) => {
              // If the contact_message has a conversation_id value, add the conversation URL to contactData
              if (step.contact_messages[0]?.conversation_id) {
                contactData['Conversation URL'] =
                  `https://app.whippy.co/inbox/all/open/${step?.contact_messages[0]?.conversation_id}`;
              }
            });

            // After the loop, sort the insertedAtTimes array
            insertedAtTimes.sort((a, b) => a.getTime() - b.getTime());

            // Add the start_time and finished_at to contactData
            if (insertedAtTimes.length > 0) {
              contactData['start_time'] = insertedAtTimes[0].toISOString();
              contactData['finished_at'] =
                insertedAtTimes[insertedAtTimes.length - 1].toISOString();
              contactData['clicked'] = contact.step_contacts.some(
                (step) => step.clicked_link
              );
            }

            return contactData;
          }
        );

        // Add the formatted contacts to the allContacts array
        allContacts = [...allContacts, ...formattedExportContacts];

        // If all data has been fetched, stop the loop
        if (allContacts.length >= responses.total) {
          keepLoading = false;
        }

        offset += DATA_LIMIT;
      }
    }
    return allContacts;
  };

  const getSequenceContacts = async (isDownloadAll: boolean) => {
    let allContacts:
      | any[]
      | ((
          prevState: {
            name: string;
            email: string;
            phone: string;
            clicked: boolean;
            unsubscribed: boolean;
            status: string;
          }[]
        ) => {
          name: string;
          email: string;
          phone: string;
          clicked: boolean;
          unsubscribed: boolean;
          status: string;
        }[]) = [];

    if (current?.id) {
      let offset = 0;
      let keepLoading = true;

      // Define default params for download all contacts option
      const defaultParams = {
        extended_filter: [
          {
            type: 'tab',
            value: 'total',
          },
        ],
        filter: [],
        sort: [
          {
            column: 'inserted_at',
            order: 'desc',
            resource: 'step_contact',
          },
        ],
      };

      // Determine whether to default params or filtered params
      const params = isDownloadAll
        ? defaultParams
        : prepareFilters(sequences.sequencesState.sequenceContactsParams);

      while (keepLoading) {
        const contacts = await SequencesAPI.getSequenceContacts(
          current.id,
          {
            ...params,
            offset,
            limit: DATA_LIMIT,
          },
          null
        );

        // Format the contacts data for export
        const formattedExportContacts = contacts.data.map((contact) => {
          return {
            name: contact.contact.name,
            email: contact.contact.email,
            phone: contact.contact.phone,
            response: contact.contact_messages[0]?.body,
            conversation_url: `https://app.whippy.co/inbox/all/open/${contact.contact_messages[0]?.conversation_id}`,
            status: contact.status,
            clicked: contact.clicked_link,
            unsubscribed: contact.unsubscribed,
            inserted_at: contact.inserted_at,
            updated_at: contact.updated_at,
          };
        });

        // Add the formatted contacts to the allContacts array
        allContacts = [...allContacts, ...formattedExportContacts];

        // If all data has been fetched, stop the loop
        if (allContacts.length >= contacts.total) {
          keepLoading = false;
        }

        // Increment the offset
        offset += DATA_LIMIT;
      }
    }
    return allContacts;
  };

  const getContacts = (isDownloadAll: boolean) => {
    if (history.location.pathname === `/sequences/${id}/responses`) {
      return getSequenceResponses(isDownloadAll);
    } else {
      return getSequenceContacts(isDownloadAll);
    }
  };

  const handleDuplicate = async () => {
    if (current?.id) {
      const sequence = await duplicateSequence(current.id);
      if (sequence) {
        history.push(`/sequences`);
      }
    }
  };

  const handleDuplicateAsTemplate = async () => {
    if (current?.id) {
      const sequence = await duplicateSequenceTemplate(current.id);
      if (sequence) {
        history.push(`/sequences/templates/${sequence.id}/steps`);
      }
    }
  };

  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <PageLayout
      breadcrumbs={[
        { title: 'Sequences', path: '/sequences' },
        {
          title: current?.title || '',
          path: `/sequences/${current?.id}/steps`,
        },
        {
          title: tabTitle || '',
          path: `/sequences/${current?.id}/${tabUrl || 'steps'}`,
        },
      ]}
      actions={
        <HStack gap={2} css={{ mt: 3, ml: 10 }}>
          {downloading && <Box>Downloading Data...</Box>}
          {current?.id && (
            <DownloadContacts
              getData={getContacts}
              setDownloading={setDownloading}
              fileName="contacts"
              title="Download CSV"
            />
          )}
          <AlertDialog open={isOpen}>
            <AlertDialogTrigger asChild>
              <ToolTipIconButton
                variant="outline"
                description="Duplicate Sequence"
                icon={<HiDuplicate />}
                onClick={onOpen}
                dataTestID="duplicate-sequence-button"
              />
            </AlertDialogTrigger>
            <AlertDialogPortal>
              <AlertDialogOverlay>
                <AlertDialogContent onEscapeKeyDown={onClose}>
                  <AlertDialogTitle>Duplicate Sequence</AlertDialogTitle>
                  <AlertDialogDescription>
                    Are you sure you want to duplicate this sequence?
                  </AlertDialogDescription>
                  <Flex justify="end">
                    <AlertDialogCancel asChild>
                      <Button variant="gray" css={{ mr: 10 }} onClick={onClose}>
                        No
                      </Button>
                    </AlertDialogCancel>
                    <AlertDialogAction asChild>
                      <Button
                        variant="gray"
                        onClick={handleDuplicateAsTemplate}
                        css={{ mr: 10 }}
                      >
                        Yes, Duplicate as Template
                      </Button>
                    </AlertDialogAction>
                    <AlertDialogAction asChild>
                      <Button variant="primary" onClick={handleDuplicate}>
                        Yes, Duplicate
                      </Button>
                    </AlertDialogAction>
                  </Flex>
                </AlertDialogContent>
              </AlertDialogOverlay>
            </AlertDialogPortal>
          </AlertDialog>
          {current?.id && (
            <SequenceEditor sequence={current}>
              <ToolTipIconButton
                variant="outline"
                description="Edit Sequence Settings"
                icon={<HiCog />}
              />
            </SequenceEditor>
          )}
          <SequenceAudience
            sequence={current}
            preSelectedAudience={preSelectedAudience}
            setPreSelectedAudience={setPreSelectedAudience}
          />
        </HStack>
      }
    >
      <TabsContainer align="center">
        <HStack>
          <SequenceSubNavigationItem
            selected={history.location.pathname.includes('steps')}
            onClick={() => history.push(`/sequences/${id}/steps`)}
          >
            Steps
          </SequenceSubNavigationItem>
          <SequenceSubNavigationItem
            selected={history.location.pathname === `/sequences/${id}/contacts`}
            onClick={() => history.push(`/sequences/${id}/contacts`)}
          >
            Contacts
          </SequenceSubNavigationItem>
          <SequenceSubNavigationItem
            selected={history.location.pathname === `/sequences/${id}/responses`}
            onClick={() => history.push(`/sequences/${id}/responses`)}
          >
            Responses
          </SequenceSubNavigationItem>
        </HStack>
      </TabsContainer>
      <StepsContainer direction="column">{children}</StepsContainer>
    </PageLayout>
  );
};

export const SequenceSubNavigationItem = styled(Flex, {
  px: 10,
  py: 8,
  borderRadius: 4,
  fontWeight: 500,
  alignContent: 'center',
  cursor: 'pointer',
  fontSize: 13,
  position: 'relative',
  '&:hover': {
    backgroundColor: '$slate3',
  },
  variants: {
    selected: {
      true: {
        backgroundColor: '$slate3',
      },
    },
  },
});

export const TabsContainer = styled(Flex, {
  width: '100%',
  height: 60,
  px: 20,
  borderBottom: 'thin solid $gray4',
  backgroundColor: 'white',
});

export const StepsContainer = styled(Flex, {
  position: 'relative',
  height: '100%',
  flex: 1,
  overflow: 'auto',
  backgroundColor: '#fafafa',
});
