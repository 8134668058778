import { Campaign } from '@/shared/types/campaigns';

import { api } from '../api';

export type GetCampaignTemplateParams = {
  /* the industry filter of the template */
  industry?: string;
  /* the access level of the template */
  access_level?: string;
  /* the title of the template */
  title?: string;
  /* the user id of the template */
  user_id?: string;
  /* request offset */
  offset?: number;
  /* request limit */
  limit?: number;
};

export const getCampaignTemplates = (
  params: GetCampaignTemplateParams
): Promise<{ campaigns: Array<Campaign> | Array<null>; total: number }> =>
  api.get('/campaigns/templates', { params }).then((res) => {
    return res.data.data as { campaigns: Array<Campaign> | Array<null>; total: number };
  });

export const getCampaignTemplate = (id: string): Promise<Campaign | null> =>
  api.get(`/campaigns/templates/${id}`).then((res) => {
    return res.data.data as Campaign | null;
  });

export const createCampaignTemplate = (data: Campaign): Promise<Campaign | null> =>
  api.post('/campaigns/templates', { campaign: data }).then((res) => {
    return res.data.data as Campaign | null;
  });

export const updateCampaignTemplate = (
  id: string,
  data: Campaign
): Promise<Campaign | null> =>
  api.put(`/campaigns/templates/${id}`, { campaign: data }).then((res) => {
    return res.data.data as Campaign | null;
  });

export const deleteCampaignTemplate = (id: string): Promise<Campaign | null> =>
  api.delete(`/campaigns/templates/${id}`).then((res) => {
    return res.data.data as Campaign | null;
  });

export const duplicateCampaignTemplate = (id: string): Promise<Campaign | null> =>
  api
    .post(`/campaigns/templates/duplicate`, {
      campaign_template_id: id,
    })
    .then((res) => {
      return res.data.data as Campaign | null;
    });
