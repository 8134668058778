/* eslint-disable react-hooks/exhaustive-deps */
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';

import { MessageEditorV2 } from '@/shared/components/editor/v2';
import { Attachments } from '@/shared/components/editor/v2/constants';
import { Signature } from '@/shared/types';
import { AccordionValue } from '@/shared/types/campaigns';
import { Box, Button, Fieldset, HStack, Label, VStack } from '@/shared/ui';

import { CampaignAccordion } from './CampaignAccordion';

type CampaignMessageEditorProps = {
  /* message */
  message: string;
  /* set message */
  setMessage: Dispatch<SetStateAction<string>>;
  /* message attachments */
  attachments: Attachments;
  /* set message attachments */
  // setAttachments: Dispatch<SetStateAction<{ attachment_urls?: string[] }>>;
  setAttachments: Dispatch<SetStateAction<Attachments>>;
  // setAttachments: (attachments: { attachment_urls: string[] }) => void;
  /* current accordion value */
  accordion: AccordionValue;
  /* set current accordion value */
  setAccordion: Dispatch<SetStateAction<AccordionValue>>;
  /* set current slate message editor */
  setCurrentEditor?: (editor: any) => void;
  /* handle save step */
  handleSave: (accordion: AccordionValue) => void;
  /* is this component used under GlobalTemplatePreview */
  showSaveButton?: boolean;
  /* channel type */
  channel_type?: 'phone' | 'email' | 'whatsapp';
};

export const CampaignMessageEditor = (props: CampaignMessageEditorProps): JSX.Element => {
  const {
    message,
    setMessage,
    attachments,
    setAttachments,
    accordion,
    setAccordion,
    handleSave,
    showSaveButton = true,
    channel_type = 'phone',
  } = props;

  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [signature, setSignature] = useState<Signature | null>(null);

  // State for attachment loading
  const [attachmentLoading, setAttachmentLoading] = useState<boolean>(false);

  // Used to add signature to message when selected
  // As long as the signature has not already been added
  useEffect(() => {
    if (signature && !message.includes(signature.body)) {
      setMessage(`${message}\n${signature.body}`);
      setSignature(null);
    }
  }, [signature]);

  return (
    <CampaignAccordion
      // if this component used under GlobalTemplatePreview, showSaveButton === false, then the index should be 2
      index={!showSaveButton ? 2 : 3}
      title="Message"
      description="Campaign Message Body & Attachments"
      currentAccordionValue={accordion}
      itemValue={AccordionValue.CREATE_MESSAGE}
      setItemValue={setAccordion}
      isValid={message.length > 1 || attachments?.attachment_urls?.length > 0}
      // if showSaveButton === false, then the buttonCopy should be 'Expand', otherwise, it should be 'Edit'
      buttonCopy={!showSaveButton ? 'Expand' : 'Edit'}
    >
      <VStack gap="2">
        <Fieldset>
          <Label>Edit Message</Label>
          <Box>
            <MessageEditorV2
              textareaRef={textareaRef}
              message={message}
              setMessage={setMessage}
              attachments={attachments}
              setAttachments={setAttachments}
              enableAttachments={true}
              attachmentLoading={attachmentLoading}
              setAttachmentLoading={setAttachmentLoading}
              isForm={true}
              signature={signature}
              setSignature={setSignature}
              showAddTemplate={true}
              showAddSignature={true}
              showAddAttachment={true}
              showAddVariable={true}
              showAddEmoji={true}
              showCharacterCount={true}
              showAddTranslate={true}
              sendMessageFromTranslationDialog={false}
              channel_type={channel_type}
            />
          </Box>
        </Fieldset>
        <HStack>
          {(showSaveButton === true || showSaveButton === undefined) && (
            <Button
              onClick={() => handleSave(AccordionValue.SELECT_SCHEDULE)}
              disabled={message.length < 1 && attachments?.attachment_urls?.length < 1}
            >
              Save
            </Button>
          )}

          <Button
            variant="gray"
            ghost={true}
            onClick={() => setAccordion(AccordionValue.DEFAULT_VALUE)}
          >
            Cancel
          </Button>
        </HStack>
      </VStack>
    </CampaignAccordion>
  );
};
