import { useFlags } from 'launchdarkly-react-client-sdk';
import React, { ComponentType, useEffect, useState } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';

type ProtectedRouteProps = RouteProps & {
  component: ComponentType<any>;
  flagKey: string;
  fallbackRoute: string;
};

/**
 * For development purposes only - A component which can allows developers to
 * continuously integrate unfinished work into production without fear of users
 * accessing it. The feature flag provided should be serve false to all users by default
 * and true for whomever is developing / testing the feature.
 *
 * @param path - The route path which renders this component.
 * @param component - The component which should render when accessing this route.
 * @param flagKey - The feature flag key which needs to be active to access this route.
 * @param fallbackRoute - The fallback route which the user is redirected to without the relevant feature flag.
 */

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  component: Component,
  flagKey,
  fallbackRoute,
  ...rest
}) => {
  const flags = useFlags();
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    // When accessing this route directly via it's URL, flags are not
    // correctly set yet. This short delay gives the flags a chance to
    // update and then renders the component or the fallback. With this
    // implementation, this component is only suitable for development purposes.
    setTimeout(() => setIsReady(true), 1000);
  }, []);

  if (!isReady) return;

  return (
    <Route
      {...rest}
      render={(props) =>
        flags[flagKey] ? <Component {...props} /> : <Redirect to={fallbackRoute} />
      }
    />
  );
};

export default ProtectedRoute;
