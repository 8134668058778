/* eslint-disable prettier/prettier */
import React from 'react';
import { useHistory } from 'react-router-dom';

import { Integration } from '@/shared/types/integrations';
import { Badge, Box, Flex } from '@/shared/ui';
import { styled } from '@/stitches.config';

import { useIntegrations } from './context/IntegrationsContext';

export const IntegrationCard = (props: { integration: Integration }) => {
  const { integration } = props;

  const integrationContext = useIntegrations();
  const { setCurrentIntegration } = integrationContext;

  const history = useHistory();

  const onIntegrationClick = () => {
    setCurrentIntegration(integration);
    history.push(`/integrations/${integration?.id}`);
  };

  // get the current.integration_attachments and map over them to display the images
  // order by the order key and remove the objects if is_logo is true
  const images = integration?.application_attachments?.sort((a, b) => a.order - b.order);
  const logo = images?.filter((image) => image.is_logo === true)[0];

  return (
    <IntegrationCardContainer
      key={integration.id}
      onClick={onIntegrationClick}
      direction="column"
      data-testid="integration-card"
    >
      <Flex justify="between">
        <IntegrationLogo src={logo?.url || ''} />
        <Badge
          css={{
            textTransform: 'capitalize',
          }}
        >
          {integration.categories[0]}
        </Badge>
      </Flex>
      <Flex direction="column" css={{ mt: 16 }}>
        <IntegrationTitle data-testid="integration-card-title">
          {integration.name}
        </IntegrationTitle>
        <IntegrationDescription data-testid="integration-card-description">
          {integration.description}
        </IntegrationDescription>
      </Flex>
    </IntegrationCardContainer>
  );
};

const IntegrationCardContainer = styled(Flex, {
  borderRadius: '8px',
  px: '25px',
  py: '20px',
  border: '1px solid #eaeaea',
  backgroundColor: 'white',
  height: '175px',
  '&:hover': {
    cursor: 'pointer',
    borderRadius: '8px',
    border: '1px solid var(--primary-100, #3E54CF)',
    background: 'var(--dark-white, #FFF)',
    boxShadow: '0px 4px 32px 0px rgba(133, 133, 133, 0.20)',
  },
});

const IntegrationLogo = styled('img', {
  width: '48px',
  height: '48px',
});

const IntegrationTitle = styled(Box, {
  color: 'var(--current-neutral-100, #11181C)',
  fontSize: '18px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '150%',
});

const IntegrationDescription = styled(Box, {
  color: 'var(--dark-gray-1, #7F7F86)',
  fontSize: '13px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '150%',
  letterSpacing: '-0.13px',
  justifyContent: 'center',
  display: '-webkit-box',
  '-webkit-line-clamp': 2,
  '-webkit-box-orient': 'vertical',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  textAlign: 'left',
  marginTop: '4px',
});
