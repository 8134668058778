import { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';

import { usePageView } from '@/shared/hooks';
import { PageLayout } from '@/shared/layouts/PageLayout';
import { Box, Flex, HStack } from '@/shared/ui';
import { styled } from '@/stitches.config';

import { initialSocialCard, useLinks } from '../context/LinksContext';

type LinkContainerProps = {
  children: React.ReactNode;
  actions?: React.ReactNode;
  sidebar?: React.ReactNode;
};

export const LinkContainer = ({ children, actions, sidebar }: LinkContainerProps) => {
  // get the link id and tab from the url
  const { id } = useParams<{ id: string }>();
  const match = useRouteMatch<{ id: string; tab: string }>('/campaigns/links/:id/:tab');
  const tab = match?.params?.tab as string;
  // capitalize the first letter of the tab
  const tabTitle = tab ? tab.charAt(0).toUpperCase() + tab.slice(1) : '';

  // product analytics tracking
  usePageView();

  // link state
  const [loading, setLoading] = useState(true);

  const {
    getLink,
    linksState: { current },
    setShowPanel,
    setOgData,
  } = useLinks();

  useEffect(() => {
    async function fetchLink() {
      getLink(id);
      setLoading(false);
    }
    // if no link is set, or the current link is not correct
    if (!current || current.id !== id) {
      fetchLink();
    } else {
      setLoading(false);
    }
  }, [current, id]);

  useEffect(() => {
    return () => {
      setShowPanel(false);
      setOgData(initialSocialCard);
    };
  }, []);

  if (loading) {
    return <Box css={{ p: 20, overflow: 'auto' }}></Box>;
  }

  return (
    <LinkPageLayout tabTitle={tabTitle} tabUrl={tab} actions={actions} sidebar={sidebar}>
      <Box css={{ height: '100%' }}>{children}</Box>
    </LinkPageLayout>
  );
};

export const LinkPageLayout = ({
  children,
  tabUrl,
  actions,
  sidebar,
}: {
  children: React.ReactNode;
  actions?: React.ReactNode;
  sidebar?: React.ReactNode;
  tabTitle?: string;
  tabUrl?: string;
}) => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();

  // links state
  const {
    linksState: { current },
    setCurrentLink,
  } = useLinks();

  const handleLinkTab = useCallback(
    (tab: string) => () => {
      // set the current link in the context
      setCurrentLink(current);
      // go to tab
      history.push(`/campaigns/links/${id}/${tab}`);
    },
    [current]
  );

  return (
    <Flex css={{ minHeight: '100%' }}>
      <PageLayout
        border={false}
        breadcrumbs={[
          { title: 'Links', path: '/campaigns/links' },
          { title: 'Short Links', path: '/campaigns/links' },
          {
            title: current ? `${current?.shareable_link?.short_url}` : '',
            path: `/campaigns/links/${id}/link`,
          },
        ]}
        actions={actions}
      >
        <TabsContainer align="center">
          <HStack>
            <LinkSubNavigationItem
              selected={tabUrl?.includes('link')}
              onClick={handleLinkTab('link')}
            >
              Edit Link
            </LinkSubNavigationItem>
            <LinkSubNavigationItem
              selected={tabUrl?.includes('analytics')}
              onClick={handleLinkTab('analytics')}
            >
              Analytics
            </LinkSubNavigationItem>
          </HStack>
        </TabsContainer>
        <ContentContainer direction="column">{children}</ContentContainer>
      </PageLayout>
      {sidebar}
    </Flex>
  );
};

export const LinkSubNavigationItem = styled(Flex, {
  px: 10,
  py: 8,
  borderRadius: 4,
  fontWeight: 500,
  alignContent: 'center',
  cursor: 'pointer',
  fontSize: 13,
  position: 'relative',
  '&:hover': {
    backgroundColor: '$slate3',
  },
  variants: {
    selected: {
      true: {
        backgroundColor: '$slate3',
      },
    },
  },
});

export const TabsContainer = styled(Flex, {
  width: '100%',
  height: 60,
  px: 20,
  borderBottom: 'thin solid $gray4',
  backgroundColor: 'white',
});

export const ContentContainer = styled(Flex, {
  position: 'relative',
  height: '100%',
  flex: 1,
  overflow: 'auto',
  backgroundColor: '#fafafa',
});
