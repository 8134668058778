import dayjs from 'dayjs';
import React, { useMemo } from 'react';
import { HiPhoneIncoming, HiPhoneOutgoing } from 'react-icons/hi';

import { Call } from '@/shared/types/calls';
import { Avatar, Box, Button, Flex, HStack, Text } from '@/shared/ui';
import { initials } from '@/shared/utils/initials/initials';
import { formatPhoneNumber } from '@/shared/utils/validations/validations';
import { Table, TableColumn } from '@/shared/v2/components/table/Table';

import { AgentVersionCell, CallDuration } from './';

interface CallsTableProps {
  data: Call[];
  loading: boolean;
  totalCount: number;
  onCardClick: (id: string) => void;
  setOffset: (offset: number) => void;
}

export const CallsTable: React.FC<CallsTableProps> = ({
  data,
  loading,
  totalCount,
  onCardClick,
  setOffset,
}) => {
  const columns: Array<TableColumn<Call>> = useMemo(
    () => [
      {
        Header: 'Date & Time',
        colWidth: '',
        accessor: 'call.time',
        Cell: (props: { row: { original: Call } }) => (
          <Flex direction="column" css={{ minWidth: 140 }}>
            <Box css={{ fontSize: 13 }}>
              {dayjs(props.row.original.updated_at).format('MMM D YYYY')}
            </Box>
            <Box css={{ fontSize: 10 }}>
              {dayjs(props.row.original.updated_at).format('h:mm A')}
            </Box>
          </Flex>
        ),
      },
      {
        Header: 'Contact',
        colWidth: '100%',
        accessor: 'contact.name',
        Cell: (props: { row: { original: Call } }) => (
          <Flex direction="column" css={{ minWidth: 200 }}>
            <HStack>
              <Avatar size="3" fallback={initials(props.row.original.contact.name)} />
              <Flex direction="column">
                <Box css={{ fontSize: 13 }}>{props.row.original.contact.name}</Box>
                <Box css={{ fontSize: 10 }}>
                  {formatPhoneNumber(props.row.original?.contact?.phone || '')}
                </Box>
              </Flex>
            </HStack>
          </Flex>
        ),
      },
      {
        Header: 'Agent Version',
        colWidth: '100%',
        accessor: 'call.agent_version_id',
        Cell: (props: { row: { original: Call } }) => (
          <Flex direction="column" css={{ minWidth: 160 }}>
            <AgentVersionCell id={props.row.original?.attachments[0]?.agent_version_id} />
          </Flex>
        ),
      },
      {
        Header: 'Call Duration',
        colWidth: '100%',
        accessor: 'call.duration',
        Cell: (props: { row: { original: Call } }) => (
          <Flex direction="column" css={{ minWidth: 110 }}>
            <CallDuration
              url={
                props.row.original?.attachments?.filter((attachment) =>
                  attachment?.url?.endsWith('/transcript.json')
                )[0]?.url
              }
            />
          </Flex>
        ),
      },
      {
        Header: 'Call Direction',
        colWidth: '100%',
        accessor: 'call.updated_at',
        Cell: (props: { row: { original: Call } }) => (
          <Flex direction="column" css={{ minWidth: 135 }}>
            <HStack align="center">
              {props.row.original.source_type === 'INBOUND' ? (
                <HiPhoneIncoming />
              ) : (
                <HiPhoneOutgoing />
              )}
              <Text>{props.row.original?.source_type}</Text>
            </HStack>
          </Flex>
        ),
      },
      {
        Header: 'Actions',
        colWidth: '100%',
        accessor: 'call.actions',
        Cell: (props: { row: { original: Call } }) => (
          <Flex direction="column" css={{ minWidth: 110 }}>
            <HStack>
              <Button variant="gray" onClick={() => onCardClick(props.row.original?.id)}>
                View Call
              </Button>
            </HStack>
          </Flex>
        ),
      },
    ],
    []
  );

  return (
    <Table
      data={data}
      columns={columns}
      caption="Calls Table"
      emptyTitle="No Calls Found"
      totalCount={totalCount}
      pageSize={10}
      isLoading={loading}
      setOffset={setOffset}
    />
  );
};
