import { ErrorBoundary } from '@highlight-run/react';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import * as React from 'react';
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter as Router } from 'react-router-dom';

import App from './app/App';
import { AuthProvider } from './pages/auth/context/AuthProvider';
import ChannelState from './pages/settings/organization/channels/context/ChannelContext';
import analytics from './shared/utils/setup/analytics';
import { globalStyles } from './stitches.config';

// Initialize the analytics & error tracking
analytics.init();

// Initialize global styles
globalStyles();

// Wrap the App component with LDProvider
// Using async provider means that if LD is offline
// Then the app will not load
const AppWithLDProvider = withLDProvider({
  clientSideID: '6328992e9a86761162a7bdc0',
})(App);

// Upgrading to react 18 causes an issue with the AuthProvider
// Which is not a functional component
// eslint-disable-next-line react/no-deprecated
ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <HelmetProvider>
        <Router>
          <AuthProvider>
            <ChannelState>
              <AppWithLDProvider />
            </ChannelState>
          </AuthProvider>
        </Router>
      </HelmetProvider>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root')
);
