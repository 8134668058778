/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';

import { getUserPreferences, updateUserPreferences } from '@/shared/api/preferences';

import { updatePreferencesObject } from './updatePreferencesObject';

// user preferences type
export type UserPreferences = {
  id: string;
  user_id: number;
  general?: {
    theme?: string;
    language?: string;
  };
  inbox?: {
    enter_to_send?: boolean;
    preferred_location_id?: string;
  };
};

// fallback preferences in case the API fails
export const initialPreferencesState = {
  id: '',
  user_id: 0,
  general: {
    theme: 'light',
    language: 'en',
  },
  inbox: {
    enter_to_send: true,
    preferred_location_id: '',
  },
};

// create a react context for user preferences, with object for general and inbox
export const PreferencesContext = React.createContext<{
  preferences: UserPreferences;
  loading: boolean;
  error: boolean;
  updatePreferences: (new_preferences: Partial<UserPreferences>) => Promise<void>;
}>({
  preferences: initialPreferencesState,
  loading: true,
  error: false,
  updatePreferences: () => Promise.resolve(),
});

export const useUserPreferences = () => useContext(PreferencesContext);

// create a react context for user preferences
const UserPreferencesState = ({ children }: { children: React.ReactNode }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [preferences, setPreferences] = useState<UserPreferences>(
    initialPreferencesState
  );

  // fetch the user preferences from the API on mount
  useEffect(() => {
    getPreferences();
  }, []);

  // function that adds the preferences object to local storage
  const savePreferences = (new_preferences: Partial<UserPreferences>) => {
    // get the current preferences from local storage if they exist
    const current_preferences = JSON.parse(
      localStorage.getItem('user-preferences') || '{}'
    );

    // update the current preferences with the new preferences
    const updated_preferences = updatePreferencesObject(
      current_preferences,
      new_preferences
    );

    // save the updated preferences to local storage
    localStorage.setItem('user-preferences', JSON.stringify(updated_preferences));

    // update the preferences in state
    setPreferences(updated_preferences);
  };

  const getPreferences = async () => {
    try {
      // get the loading preferences true
      setLoading(true);
      // get the preferences from the API
      const data = await getUserPreferences();
      // if the data is not null, set the preferences
      if (data !== null) {
        savePreferences(data);
      }
    } catch (err) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  // create a function to update the user preferences
  // this function should accept an object and merge it with the existing preferences
  const updatePreferences = async (new_preferences: Partial<UserPreferences>) => {
    try {
      // merge the new preferences with the existing preferences
      const updated_preferences = updatePreferencesObject(preferences, new_preferences);
      // console.log('updated_preferences:', updated_preferences);
      // update the preferences in the database
      const data = await updateUserPreferences({
        user_preferences: updated_preferences,
      });

      if (data !== null) {
        // save the updated preferences to local storage and state
        savePreferences(data);
      }
    } catch (err) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  // return the preferences and the update function
  return (
    <PreferencesContext.Provider
      value={{ preferences, loading, error, updatePreferences }}
    >
      {children}
    </PreferencesContext.Provider>
  );
};

export default UserPreferencesState;
