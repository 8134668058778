/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';

import { SequenceStep } from '@/shared/types/sequences';
import { Box, Button, Flex, Text, VStack } from '@/shared/ui';

import { useSequences } from '../../context/SequenceContext';
import { StepCard, StepLoadingCard } from './StepCard';
import { UpdateStep } from './UpdateStep';

type SequenceStepsPropsType = {
  disableEdit?: boolean;
};

export const SequenceSteps = (props: SequenceStepsPropsType) => {
  const { disableEdit } = props;
  const sequenceContext = useSequences();
  const { sequencesState, getAndSetSequenceSteps } = sequenceContext;
  const { currentSequenceSteps, current } = sequencesState;

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getSequences = async (id: string) => {
      try {
        await getAndSetSequenceSteps(id);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
    if (current && current.id) {
      getSequences(current.id);
    }
  }, [current]);

  if (loading) {
    return (
      <Box css={{ p: 30 }}>
        <VStack gap={4} align="center" css={{ width: '100%' }}>
          {[...Array(3)].map((_, i) => (
            <Box key={i} css={{ width: '100%', mb: 20 }}>
              <StepLoadingCard />
            </Box>
          ))}
          {!disableEdit && (
            <Box css={{ width: '100%' }}>
              <UpdateStep>
                <Button variant="gray">Add New Step</Button>
              </UpdateStep>
            </Box>
          )}
        </VStack>
      </Box>
    );
  }

  if (currentSequenceSteps.length < 1 && !loading) {
    return (
      <Flex align="center" justify="center" css={{ height: '100%' }}>
        <VStack align="center" gap="4">
          <Text variant="bold" size={4} css={{ fontWeight: 600 }}>
            No Sequence Steps
          </Text>
          <Text variant="gray">Add the first step in your sequence to get started.</Text>
          {!disableEdit && (
            <UpdateStep>
              <Button>Add Step</Button>
            </UpdateStep>
          )}
        </VStack>
      </Flex>
    );
  }

  return (
    <Box css={{ p: 30 }}>
      <VStack gap={4} align="center" css={{ width: '100%' }}>
        {currentSequenceSteps &&
          currentSequenceSteps.length > 0 &&
          currentSequenceSteps.map((step: SequenceStep | null, index: number) => (
            <Box key={index} css={{ width: '100%' }}>
              <StepCard
                sequence={current}
                sequence_id={current?.id || ''}
                step={step}
                disableEdit={disableEdit}
              />
            </Box>
          ))}
        {!disableEdit && (
          <Box css={{ width: '100%' }}>
            <UpdateStep>
              <Button>Add New Step</Button>
            </UpdateStep>
          </Box>
        )}
      </VStack>
    </Box>
  );
};
