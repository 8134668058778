export type ApplicationAttachment = {
  /* the order of the attachment on the integration page */
  order: number;
  /* the url of the attachment */
  url: string;
  /* if the attachment is a logo? */
  is_logo: boolean;
};

export type Integration = {
  id: string;
  /* the name of the integration */
  name: string;
  /* the unique key of the integration */
  key: IntegrationApplicationKeys;
  /* the integration description */
  description?: string;
  /* the integration categories */
  categories: string[];
  /* the integration overview text */
  rich_body_text: string;
  /* array of objects */
  application_attachments: ApplicationAttachment[];
  /* the developer of the integration */
  developer: string | null;
  /* the integration website */
  website: string | null;
  /* the integration pricing model */
  pricing: string | null;
};

export type IntegrationConfiguration = {
  /* the id of the integration configuration */
  id: string;
  /* the status of the integration */
  status: string;
  /* date attached to the integration */
  data: JobBoardAutomationData;
  client_response: any;
  type: string;
  /* the id of the organization the integration is attached to */
  organization_id: string;
  /* the id of the integration */
  application_id: string;
  /* the integration  */
  application: Integration;
  /* the id of the user who created the integration */
  created_by_id: string;
  /* the id of the user who last updated the integration */
  updated_by_id: string;
  /* the id of the user who deleted the integration */
  deleted_by_id: string;
  /* the date the integration was inserted */
  inserted_at: string;
  /* the date the integration was last updated */
  updated_at: string;
  /* the date the integration was deleted */
  deleted_at: string;
};

// Reducer action types
export enum IntegrationActionTypes {
  SET_CURRENT = 'SET_CURRENT',
  GET_INTEGRATIONS = 'GET_INTEGRATIONS',
  GET_INTEGRATION = 'GET_INTEGRATION',
  SET_INTEGRATIONS = 'SET_INTEGRATIONS',
  FILTER_INTEGRATIONS = 'FILTER_INTEGRATIONS',
  SET_CONFIGURATIONS = 'SET_CONFIGURATIONS',
  SET_INTEGRATION_RESPONSES_LOADING = 'SET_INTEGRATION_RESPONSES_LOADING',
}

export type IntegrationActions =
  | { type: IntegrationActionTypes.SET_CURRENT; payload: Integration | null }
  | { type: IntegrationActionTypes.GET_INTEGRATION; payload: Integration | null }
  | { type: IntegrationActionTypes.GET_INTEGRATIONS; payload: Integration[] }
  | { type: IntegrationActionTypes.SET_INTEGRATIONS; payload: Integration[] }
  | { type: IntegrationActionTypes.SET_INTEGRATION_RESPONSES_LOADING; payload: boolean }
  | { type: IntegrationActionTypes.FILTER_INTEGRATIONS; payload: Integration[] };

export type QueryItem = {
  column: string;
  comparison: string;
  resource: string;
  value: string | number | boolean | string[] | number[] | null;
  or?: QueryItem[];
};

export type QueryParams = {
  filter: QueryItem[];
};

export enum ConfigurationActionTypes {
  GET_CONFIGURATIONS = 'GET_CONFIGURATIONS',
  GET_CONFIGURATIONS_BY_INTEGRATION = 'GET_CONFIGURATIONS_BY_INTEGRATION',
  SET_CURRENT_CONFIGURATION = 'SET_CURRENT_CONFIGURATION',
  CREATE_CONFIGURATION = 'CREATE_CONFIGURATION',
  DELETE_CONFIGURATION = 'DELETE_CONFIGURATION',
  GET_CONFIGURATION = 'GET_CONFIGURATION',
  UPDATE_CONFIGURATION = 'UPDATE_CONFIGURATION',
}

export type ConfigurationActions =
  | {
      type: ConfigurationActionTypes.SET_CURRENT_CONFIGURATION;
      payload: IntegrationConfiguration | null;
    }
  | {
      type: ConfigurationActionTypes.GET_CONFIGURATIONS_BY_INTEGRATION;
      payload: IntegrationConfiguration[];
    }
  | {
      type: ConfigurationActionTypes.GET_CONFIGURATION;
      payload: IntegrationConfiguration;
    }
  | {
      type: ConfigurationActionTypes.CREATE_CONFIGURATION;
      payload: IntegrationConfiguration | null;
    }
  | {
      type: ConfigurationActionTypes.GET_CONFIGURATIONS;
      payload: IntegrationConfiguration[];
    }
  | {
      type: ConfigurationActionTypes.DELETE_CONFIGURATION;
      payload: string;
    };

export enum WhippyAction {
  SEND_MESSAGE = 'send_message',
  CREATE_LEAD = 'create_lead',
  ADD_TO_SEQUENCE = 'add_to_sequence',
}

export type SendMessageActionParams = {
  from_number: string;
  outbound_body: string;
  delay_in_seconds: number;
  outbound_attachment_url?: string;
};

export type CreateLeadActionParams = {
  from_number: string;
  outbound_attachment_url?: string;
  outbound_body: string;
  delay_in_seconds: number;
  channel_id: string;
};

export type AddToSequenceActionParams = {
  sequence_id: string;
  from_number: string;
};

/*
These represent the valid integration keys for a job board automation
*/
export enum JobBoardAutomationKeys {
  INDEED = 'indeed',
  PANDO = 'pando',
  JOB_ADDER = 'job_adder',
  ZIP_RECRUITER = 'zip_recruiter',
  CAREER_BUILDER = 'career_builder',
  JAZZ_HR = 'jazz_hr',
  ACQUIRE_ROI = 'acquire_roi',
  ZOOM_VOICEMAIL = 'zoom_voicemail',
  DATA_FRENZY = 'data_frenzy',
  JOB_SPARX = 'job_sparx',
}

// all the possible integration keys
export enum IntegrationApplicationKeys {
  INDEED = 'job_board_automation_indeed',
  PANDO = 'job_board_automation_pando',
  JOB_ADDER = 'job_board_automation_job_adder',
  ZIP_RECRUITER = 'job_board_automation_zip_recruiter',
  CAREER_BUILDER = 'job_board_automation_career_builder',
  JAZZ_HR = 'job_board_automation_jazz_hr',
  ACQUIRE_ROI = 'job_board_automation_acquire_roi',
  ZOOM_VOICEMAIL = 'job_board_automation_zoom_voicemail',
  DATA_FRENZY = 'job_board_automation_data_frenzy',
  JOB_SPARX = 'job_board_automation_job_sparx',
  EDGE = 'edge',
  CHROME = 'chrome',
  BULLHORN = 'bullhorn',
  BLUESKY = 'bluesky',
  AIRTABLE = 'airtable',
  RETOOL = 'retool',
  ZAPIER = 'zapier',
  HUBSPOT = 'hubspot',
}

export type JobBoardAutomationData = {
  action: WhippyAction;
  action_params:
    | AddToSequenceActionParams
    | CreateLeadActionParams
    | SendMessageActionParams;
  api_key: string;
  forwarding_email: string;
  job_board: string;
  organization_id: string;
};

export type AddToSequenceData = {
  action: WhippyAction;
  action_params: AddToSequenceActionParams;
  api_key: string;
  forwarding_email: string;
  job_board: string;
  organization_id: string;
};

export type SendMessageData = {
  action: WhippyAction;
  action_params: SendMessageActionParams;
  api_key: string;
  forwarding_email: string;
  job_board: string;
  organization_id: string;
};

export type CreateLeadData = {
  action: WhippyAction;
  action_params: CreateLeadActionParams;
  api_key: string;
  forwarding_email: string;
  job_board: string;
  organization_id: string;
};

export function isAddToSequenceParams(
  actionParams: any
): actionParams is AddToSequenceActionParams {
  return actionParams && 'sequence_id' in actionParams;
}

export function isSendToMessageParams(
  actionParams: any
): actionParams is SendMessageActionParams {
  return (
    actionParams && 'outbound_body' in actionParams && !('channel_id' in actionParams)
  );
}

export function isCreateLeadParams(
  actionParams: any
): actionParams is CreateLeadActionParams {
  return actionParams && 'outbound_body' in actionParams && 'channel_id' in actionParams;
}
