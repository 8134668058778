import { ActionProps, ActionTypes } from '@/shared/types/triggers/actions';

import { AddTagsAction } from './types/AddTagsAction';
import { AddToSequenceAction } from './types/AddToSequenceAction';
import { AssignUserAction } from './types/AssignUserAction';
import { MoveToStepAction } from './types/MoveToStepAction';
import { NotifyUserAction } from './types/NotifyUsersActions';
import { OptInAction } from './types/OptInAction';
import { OptOutAction } from './types/OptOutAction';
import { RemoveFromSequenceAction } from './types/RemoveFromSequenceAction';
import { RemoveTagsAction } from './types/RemoveTagsAction';
import { SendEmailAction } from './types/SendEmailAction';
import { SendMessageAction } from './types/SendMessageAction';
import { UnassignUserAction } from './types/UnassignUserAction';
import { UpdateConversationStatusAction } from './types/UpdateConversationStatusAction';

export const ActionRenderer = (props: ActionProps) => {
  const { action } = props;

  const renderAction = (type: string) => {
    switch (type) {
      case ActionTypes.SEND_MESSAGE:
        return <SendMessageAction {...props} />;
      case ActionTypes.ADD_TAGS:
        return <AddTagsAction {...props} />;
      case ActionTypes.REMOVE_TAGS:
        return <RemoveTagsAction {...props} />;
      case ActionTypes.OPT_IN:
        return <OptInAction />;
      case ActionTypes.OPT_OUT:
        return <OptOutAction />;
      case ActionTypes.ASSIGN_USER:
        return <AssignUserAction {...props} />;
      case ActionTypes.UNASSIGN_USER:
        return <UnassignUserAction />;
      case ActionTypes.NOTIFY_USERS:
        return <NotifyUserAction {...props} />;
      case ActionTypes.ADD_TO_SEQUENCE:
        return <AddToSequenceAction {...props} />;
      case ActionTypes.REMOVE_FROM_SEQUENCE:
        return <RemoveFromSequenceAction {...props} />;
      case ActionTypes.UPDATE_CONVERSATION_STATUS:
        return <UpdateConversationStatusAction {...props} />;
      case ActionTypes.SEND_EMAIL:
        return <SendEmailAction {...props} />;
      case ActionTypes.MOVE_TO_STEP:
        return <MoveToStepAction {...props} />;
      default:
        return null;
    }
  };

  return <>{renderAction(action.type)} </>;
};
