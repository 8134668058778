/* eslint-disable react-hooks/exhaustive-deps */
import dayjs from 'dayjs';
import { debounce } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { HiChat, HiCheck, HiExclamationCircle, HiPencilAlt } from 'react-icons/hi';
import { Column as ReactTableColumn } from 'react-table';
import { VirtuosoHandle } from 'react-virtuoso';
import { toast } from 'sonner';

import { ContactPanel } from '@/pages/data/contacts/ContactPanel';
import { useData } from '@/pages/data/context/DataContext';
import { ContactActionsMenu } from '@/pages/data/utils/ContactsTable';
import {
  handleFilterChange,
  handleQuickSearch,
  handleSortUpdate,
} from '@/pages/data/utils/filterActions';
import { ConversationDrawer } from '@/pages/inbox/drawer';
import { useChannels } from '@/pages/settings/organization/channels/context/ChannelContext';
import { useUsers } from '@/pages/settings/organization/users/context/UserContext';
import * as SequencesAPI from '@/shared/api/sequences';
import { ToolTipIconButton } from '@/shared/components/attachments/previewer';
import { CombinedFilters } from '@/shared/components/filterBuilder/CombinedFilters';
import { default_contact_object } from '@/shared/components/filterBuilder/objects/contact';
import {
  step_contact_object,
  step_position_filter,
} from '@/shared/components/filterBuilder/objects/step_contact';
import { BulkTagModal } from '@/shared/components/filters/bulkAction/modals/BulkTagModal';
import { UnsubscribeContactsFromCampaignModal } from '@/shared/components/filters/bulkAction/modals/UnsubscribeContactsFromCampaignModal';
import { BulkAction, FilteredTable } from '@/shared/components/filters/FiltersTable';
import { FilterTabs } from '@/shared/components/filters/FilterTabs';
import { prepareFilter } from '@/shared/components/filters/utils';
import { AddToSequenceDialog } from '@/shared/components/modals/AddToSequenceDialog/AddToSequenceDialog';
import { TooltipButton } from '@/shared/components/TooltipButton';
import { SearchFilters } from '@/shared/types/contacts';
import { DefaultObject } from '@/shared/types/data';
import {
  FilterItem,
  FilterType,
  Sort,
  TabFilter,
  TabValueMapping,
} from '@/shared/types/filter';
import {
  SequenceBulkActionFilter,
  SequenceContactStatusType,
  SequenceStepContact,
} from '@/shared/types/sequences';
import { TagBulkActionType } from '@/shared/types/tags';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogOverlay,
  AlertDialogPortal,
  AlertDialogTitle,
  AlertDialogTrigger,
  Badge,
  Box,
  Button,
  Flex,
  HStack,
  IconButton,
} from '@/shared/ui';
import { IndeterminateCheckbox } from '@/shared/ui/IndeterminateCheckbox';
import { formatPhoneNumber } from '@/shared/utils/validations/validations';
import { styled } from '@/stitches.config';

import {
  initialSequencesState,
  ITEMS_COUNT,
  useSequences,
} from '../../context/SequenceContext';
import { sequenceContactsSortConfig } from './filterConfig';

const ignoredMessages = [
  'STOP',
  'Stop',
  'stop',
  'REMOVE',
  'HELP',
  'Help',
  'help',
  'UNSUBSCRIBE',
  'Unsubscribe',
  'unsubscribe',
  'END',
  'End',
  'end',
];
const getBadgeColor = (status: SequenceContactStatusType) => {
  switch (status) {
    case SequenceContactStatusType.PENDING:
      return 'blue';
    case SequenceContactStatusType.COMPLETE:
      return 'green';
    case SequenceContactStatusType.ACTIVE:
      return 'purple';
    case SequenceContactStatusType.REMOVED:
      return 'orange';
    case SequenceContactStatusType.INACTIVE:
      return 'yellow';
    default:
      return 'gray';
  }
};

const formatDate = (value: string) => {
  return dayjs(value).format('ddd D MMM hh:mm A');
};

const showRemoveContact = (status: SequenceContactStatusType) => {
  if (
    status === SequenceContactStatusType.ACTIVE ||
    status === SequenceContactStatusType.PENDING
  ) {
    return true;
  } else {
    return false;
  }
};

const noContactsFound = (
  <Box css={{ textAlign: 'center' }}>
    <Box>No contacts found</Box>
  </Box>
);

export const sequenceContactsTabFilters = [
  {
    key: 'total',
    label: 'All Contacts',
    value: {
      filter: [
        {
          resource: 'sequence_run',
          column: 'id',
          comparison: '!=',
          value: 'null',
        },
      ],
    },
  },
  {
    key: 'pending',
    label: 'Pending Start',
    value: {
      filter: [
        {
          column: 'status',
          comparison: '==',
          resource: 'step_contact',
          value: 'pending',
        },
      ],
    },
  },
  {
    key: 'active',
    label: 'Active Step',
    value: {
      filter: [
        {
          column: 'status',
          comparison: '==',
          resource: 'step_contact',
          value: 'active',
        },
      ],
    },
  },
  {
    key: 'finished',
    label: 'Finished',
    value: {
      filter: [
        {
          column: 'status',
          comparison: '==',
          resource: 'sequence_run',
          value: 'finished',
        },
      ],
    },
  },
  {
    key: 'responded',
    label: 'Responded',
    value: {
      filter: [
        {
          column: 'body',
          comparison: 'not_in',
          resource: 'contact_message',
          value: ignoredMessages,
        },
      ],
    },
  },
  {
    key: 'unresponded',
    label: 'Un-responded',
    value: {
      filter: [
        {
          column: 'body',
          comparison: 'in',
          resource: 'contact_message',
          value: ignoredMessages,
          or: [
            {
              resource: 'contact_message',
              column: 'id',
              comparison: '==',
              value: null,
            },
          ],
        },
        {
          column: 'delivery_status',
          comparison: 'in',
          resource: 'step_message',
          value: ['delivered', 'queued', 'dlr_timeout', 'delivery_unconfirmed'],
        },
      ],
    },
  },
  {
    key: 'clicked',
    label: 'Link Clicked',
    value: {
      filter: [
        {
          column: 'clicked_link',
          comparison: '==',
          resource: 'step_contact',
          value: true,
        },
      ],
    },
  },
  {
    key: 'unsubscribed',
    label: 'Un-subscribed',
    value: {
      filter: [
        {
          column: 'unsubscribed',
          comparison: '==',
          resource: 'step_contact',
          value: true,
        },
      ],
    },
  },
  {
    key: 'failed',
    label: 'Not Delivered',
    value: {
      filter: [
        {
          resource: 'step_message',
          column: 'delivery_status',
          comparison: 'in',
          value: ['failed', 'delivery_failed', 'sending_failed'],
        },
      ],
    },
  },
];

const defaultSort: Array<Sort> = [
  {
    label: 'Step Created',
    column: 'inserted_at',
    order: 'desc',
    resource: 'step_contact',
    id: null,
  },
];

export const SequenceContacts = () => {
  const [quickSearchValue, setQuickSearchValue] = useState('');
  const [selectedStepContactIds, setSelectedStepContactIds] = useState([] as string[]);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [isAddToSequenceModalOpen, setAddToSequenceModalOpen] = useState(false);
  const [isBulkTagModalOpen, setBulkTagModalOpen] = useState(false);
  const [isUnsubscribeContactsModalOpen, setUnsubscribeContactsModalOpen] =
    useState(false);
  const [tagModalAction, setTagModalAction] = useState<TagBulkActionType>('unassign');
  const [totalContactsSelected, setTotalContactsSelected] = useState(0);
  const [selectedTabFilter, setSelectedTabFilter] = useState<TabFilter>(
    sequenceContactsTabFilters[0]
  );
  const [activeFilters, setActiveFilters] = useState<FilterType[]>([]);

  const sequenceContext = useSequences();

  const { sequencesState, removeContactsFromSequence, updateSequenceContactsParams } =
    sequenceContext;

  const { current, loadingContacts, sequenceContactsParams } = sequencesState;

  const usersContext = useUsers();
  const { users } = usersContext.userState;

  const locations = useChannels();
  const { getChannelById, channelsState } = locations;

  const contacts = useData();
  const { blockContact, deleteContact } = contacts;

  const tableRef = useRef<VirtuosoHandle>(null);

  const contactTabs = current?.contact_tabs || {
    active: 0,
    clicked: 0,
    finished: 0,
    pending: 0,
    responded: 0,
    total: 0,
    unresponded: 0,
    unsubscribed: 0,
    failed: 0,
  };

  const tabValueMappings: TabValueMapping = {
    active: () => contactTabs.active,
    clicked: () => contactTabs.clicked,
    finished: () => contactTabs.finished,
    pending: () => contactTabs.pending,
    responded: () => contactTabs.responded,
    total: () => contactTabs.total,
    unresponded: () => contactTabs.unresponded,
    unsubscribed: () => contactTabs.unsubscribed,
    failed: () => null,
    active_rate: () =>
      contactTabs.total ? Math.round((contactTabs.active / contactTabs.total) * 100) : 0,
    clicked_rate: () =>
      contactTabs.total ? Math.round((contactTabs.clicked / contactTabs.total) * 100) : 0,
    finished_rate: () =>
      contactTabs.total
        ? Math.round((contactTabs.finished / contactTabs.total) * 100)
        : 0,
    pending_rate: () =>
      contactTabs.total ? Math.round((contactTabs.pending / contactTabs.total) * 100) : 0,
    responded_rate: () =>
      contactTabs.total
        ? Math.round((contactTabs.responded / contactTabs.total) * 100)
        : 0,
    unresponded_rate: () =>
      contactTabs.total
        ? Math.round((contactTabs.unresponded / contactTabs.total) * 100)
        : 0,
    unsubscribed_rate: () =>
      contactTabs.total
        ? Math.round((contactTabs.unsubscribed / contactTabs.total) * 100)
        : 0,
  };

  // get the step position based on the step id
  const getStepPosition = (step_id: string | undefined) => {
    if (current && current.steps) {
      const step = current.steps.find((step) => step.id === step_id);
      if (step && step.position) {
        return step.position + 1;
      } else {
        return 1;
      }
    } else {
      return null;
    }
  };

  const handleOnEndReached = () => {
    if (
      sequencesState.sequenceContacts.length < sequencesState.totalSequenceContacts &&
      !loadingContacts
    ) {
      updateSequenceContactsParams({
        ...sequenceContactsParams,
        offset: sequenceContactsParams.offset + ITEMS_COUNT,
      });
    }
  };

  const data = useMemo(
    () => sequencesState.sequenceContacts,
    [sequencesState.sequenceContacts]
  ) as SequenceStepContact[] | [];

  const stepOptions = useMemo(
    () =>
      current &&
      current?.steps &&
      current?.steps.map((step) => {
        return {
          label: `Step ${getStepPosition(step.id)}`,
          value: step.id ?? '',
        };
      }),
    [current?.steps]
  );

  const defaultObject: Array<DefaultObject> = [
    default_contact_object,
    {
      ...step_contact_object,
      custom_properties: stepOptions?.length
        ? [
            ...step_contact_object.custom_properties,
            { ...step_position_filter, options: stepOptions },
          ]
        : step_contact_object.custom_properties,
    },
  ];

  const bulkActions: BulkAction[] = [
    {
      label: 'Add to Sequence',
      action: 'addToSequenceV2',
      title: 'Add to Sequence',
      getConfirmationMessage: (selected: number) =>
        `Are you sure you want to add **${selected} contact(s)** to an existing sequence?`,
      onManualSelectedSubmit: (selectedSequenceRunIds: string[]) => {
        setAddToSequenceModalOpen(true);
        setSelectedStepContactIds(selectedSequenceRunIds);
        setIsAllSelected(false);
        setTotalContactsSelected(selectedSequenceRunIds.length);
      },
      onAllSelectedSubmit: () => {
        setAddToSequenceModalOpen(true);
        setIsAllSelected(true);
        setTotalContactsSelected(sequencesState.totalSequenceContacts);
      },
    },
    {
      action: 'sequences.contacts.remove',
      label: 'Remove from Sequence',
      title: 'Remove from Sequence',
      type: 'destructive',
      getConfirmationMessage: (selected: number) =>
        `Are you sure you want to remove **${selected} contact(s)**?`,
      onManualSelectedSubmit: async (stepContactIds: string[]) => {
        if (current && current.id) {
          const filter = combineFilter(stepContactIds, sequenceContactsParams, false);

          try {
            await SequencesAPI.sequenceBulkActions(
              current.id,
              'sequences.contacts.remove',
              filter,
              ['sequence', current.id]
            );
            toast.success('Contact(s) removed from sequence.');
          } catch (err) {
            console.error(err);
            toast.error('Something went wrong.');
          }
        }
      },
      onAllSelectedSubmit: async () => {
        if (current && current.id) {
          const filter = combineFilter([], sequenceContactsParams, true);

          try {
            await SequencesAPI.sequenceBulkActions(
              current.id,
              'sequences.contacts.remove',
              filter,
              ['sequence', current.id]
            );
            toast.success('Contact(s) removed from sequence.');
          } catch (err) {
            console.error(err);
            toast.error('Something went wrong.');
          }
        }
      },
    },
    {
      label: 'Assign Tags',
      action: 'assignTagsV2',
      title: 'Assign Tags',
      getConfirmationMessage: (selected: number) => {
        setTagModalAction('assign');
        return `Are you sure you want to assign tags from **${selected} contact(s)**`;
      },
      onManualSelectedSubmit: (selectedContactIds: string[]) => {
        setSelectedStepContactIds(selectedContactIds);
        setBulkTagModalOpen(true);
        setIsAllSelected(false);
        setTotalContactsSelected(selectedContactIds.length);
      },
      onAllSelectedSubmit: () => {
        setBulkTagModalOpen(true);
        setIsAllSelected(true);
        setTotalContactsSelected(sequencesState.totalSequenceContacts);
      },
    },
    {
      label: 'Overwrite Tags',
      action: 'overwriteTagsV2',
      title: 'Overwrite Tags',
      type: 'destructive',
      getConfirmationMessage: (selected: number) => {
        setTagModalAction('assign.overwrite');
        return `Are you sure you want to overwrite tags from **${selected} contact(s)**`;
      },
      onManualSelectedSubmit: (selectedContactIds: string[]) => {
        setSelectedStepContactIds(selectedContactIds);
        setBulkTagModalOpen(true);
        setIsAllSelected(false);
        setTotalContactsSelected(selectedContactIds.length);
      },
      onAllSelectedSubmit: () => {
        setBulkTagModalOpen(true);
        setIsAllSelected(true);
        setTotalContactsSelected(sequencesState.totalSequenceContacts);
      },
    },
    {
      label: 'Un-assign Tags',
      action: 'unassignTagV2',
      title: 'Un-assign Tags',
      type: 'destructive',
      getConfirmationMessage: (selected: number) => {
        setTagModalAction('unassign');
        return `Are you sure you want to unassign tags from **${selected} contact(s)**`;
      },
      onManualSelectedSubmit: (selectedContactIds: string[]) => {
        setSelectedStepContactIds(selectedContactIds);
        setBulkTagModalOpen(true);
        setIsAllSelected(false);
        setTotalContactsSelected(selectedContactIds.length);
      },
      onAllSelectedSubmit: () => {
        setBulkTagModalOpen(true);
        setIsAllSelected(true);
        setTotalContactsSelected(sequencesState.totalSequenceContacts);
      },
    },
    {
      action: 'unsubscribeFromCampaignV2',
      label: 'Un-subscribe',
      title: 'Un-subscribe Contacts from Campaign',
      type: 'destructive',
      getConfirmationMessage: (selected: number) =>
        `Are you sure you want to unsubscribe **${selected} contact(s)** from an existing Campaign?`,
      onAllSelectedSubmit: () => {
        setUnsubscribeContactsModalOpen(true);
        setIsAllSelected(true);
        setTotalContactsSelected(sequencesState.totalSequenceContacts);
      },
      onManualSelectedSubmit(selectedContactIds: string[]) {
        setUnsubscribeContactsModalOpen(true);
        setIsAllSelected(false);
        setSelectedStepContactIds(selectedContactIds);
        setTotalContactsSelected(selectedContactIds.length);
      },
    },
  ];

  const previewColumn =
    selectedTabFilter.key === 'failed'
      ? {
          Header: 'Preview',
          accessor: '',
          colWidth: 250,
          Cell: () => (
            <Box css={{ color: 'red' }}>
              <HStack>
                <Box css={{ width: 14, mt: 1 }}>
                  <HiExclamationCircle />
                </Box>
                <Box>{'Message failed to send'}</Box>
              </HStack>
            </Box>
          ),
        }
      : {
          Header: 'Preview',
          accessor: 'contact_messages[0].body',
          colWidth: 250,
          Cell: (row: { value: string }) => (
            <Cell align="center" css={{ width: 230 }}>
              {row.value || '-'}
            </Cell>
          ),
        };

  const columns: ReactTableColumn[] = useMemo(
    () => [
      {
        id: 'selection',
        Header: ({ getToggleAllRowsSelectedProps }) => (
          <Flex align="center" justify="start">
            {/* Checkbox for selecting all rows */}
            <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            <span style={{ marginLeft: '8px' }}>Name</span>
          </Flex>
        ),
        accessor: (row: any) => ({
          contact: row.contact,
          name: row.contact.name,
          id: row.contact.id,
          phone: row.contact.phone,
          location_id: row.location_id,
          status: row.status,
        }),
        colWidth: 360,
        colMinWidth: 360,
        Cell: ({ row, value }: any) => (
          <Flex align="center" justify="between">
            <Flex align="center">
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
              <Box css={{ ...ellipsis, width: 150, ml: 8 }}>{value?.name || '-'}</Box>
            </Flex>
            <HStack css={{ minWidth: 115, justifyContent: 'flex-end' }}>
              {showRemoveContact(value?.status) ? (
                <AlertDialog>
                  <TooltipButton text="Remove Contact">
                    <AlertDialogTrigger>
                      <IconButton size="2">
                        <HiCheck />
                      </IconButton>
                    </AlertDialogTrigger>
                  </TooltipButton>
                  <AlertDialogPortal>
                    <AlertDialogOverlay>
                      <AlertDialogContent>
                        <AlertDialogTitle>Remove Contact</AlertDialogTitle>
                        <AlertDialogDescription>
                          Are you sure you want to remove this contact from the sequence?
                        </AlertDialogDescription>
                        <Flex justify="end">
                          <HStack>
                            <AlertDialogCancel>
                              <Button variant="gray">Cancel</Button>
                            </AlertDialogCancel>
                            <AlertDialogAction
                              onClick={() => {
                                removeContactsFromSequence(
                                  [value.contact?.id] || [],
                                  current?.id || ''
                                );
                              }}
                            >
                              <Button variant="red">Remove Contact</Button>
                            </AlertDialogAction>
                          </HStack>
                        </Flex>
                      </AlertDialogContent>
                    </AlertDialogOverlay>
                  </AlertDialogPortal>
                </AlertDialog>
              ) : null}
              {value.location_id && (
                <ConversationDrawer
                  contact_id={value.id || ''}
                  contact_name={value.name || ''}
                  contact_phone={value.phone || ''}
                  location_id={value.location_id || ''}
                >
                  <IconButton size="2">
                    <HiChat />
                  </IconButton>
                </ConversationDrawer>
              )}
              <ContactPanel contact={value.contact} deleteContact={deleteContact}>
                <ToolTipIconButton
                  description="Edit Contact"
                  icon={<HiPencilAlt />}
                  size={1}
                />
              </ContactPanel>
              <Box>
                <ContactActionsMenu
                  contact={value.contact}
                  isBlocked={value.contact?.blocked}
                  blockContact={blockContact}
                  deleteContact={deleteContact}
                />
              </Box>
            </HStack>
          </Flex>
        ),
      },
      {
        Header: 'Phone',
        accessor: 'contact.phone',
        colWidth: 150,
        Cell: (row: { value: string }) => <Cell>{formatPhoneNumber(row.value)}</Cell>,
      },
      {
        Header: 'Step',
        accessor: 'step_id',
        colWidth: 80,
        Cell: (row: { value: string }) => (
          <Cell align="center" css={{ width: 60 }}>
            <Badge>{`Step ${getStepPosition(row.value)}`}</Badge>
          </Cell>
        ),
      },
      {
        Header: 'Status',
        accessor: 'status',
        colWidth: 100,
        Cell: ({ value }: any) => (
          <Cell align="center" css={{ width: 80 }}>
            <Badge variant={getBadgeColor(value)} css={{ textTransform: 'capitalize' }}>
              {value || '-'}
            </Badge>
          </Cell>
        ),
      },
      previewColumn,
      {
        Header: 'Added By',
        accessor: 'added_by_id',
        colWidth: 120,
        Cell: ({ value }: any) => (
          <Cell align="center" css={{ width: 100 }}>
            {users.find((user) => user.id === Number(value))?.name || '-'}
          </Cell>
        ),
      },
      {
        Header: 'Sent From',
        accessor: 'location_id',
        colWidth: 150,

        Cell: ({ value }: any) => {
          return <Cell>{getChannelById(value)?.name || ''}</Cell>;
        },
      },
      {
        Header: 'Scheduled At',
        accessor: 'scheduled_at',
        colWidth: 160,
        Cell: (row: { value: string }) => (
          <Cell css={{ width: 140 }}>
            {row.value != null ? formatDate(row.value) : '-'}
          </Cell>
        ),
      },
      {
        Header: 'Step Created',
        accessor: 'inserted_at',
        colWidth: 160,
        Cell: (row: { value: string }) => (
          <Cell css={{ width: 140 }}>{formatDate(row.value)}</Cell>
        ),
      },
    ],
    [data]
  );

  const debouncedUpdate = useCallback(
    debounce((props: SearchFilters) => {
      updateSequenceContactsParams(props);
    }, 100),
    []
  );

  useEffect(() => {
    resetFilters();
  }, [current?.id]);

  const resetFilters = () => {
    setQuickSearchValue('');
    setActiveFilters([]);
    updateSequenceContactsParams({
      ...initialSequencesState.sequenceContactsParams,
      sort: defaultSort,
    });
  };

  return (
    <Box css={{ backgroundColor: 'white', height: '100%', overflowY: 'hidden' }}>
      <FilterTabs
        tabs={sequenceContactsTabFilters}
        activeTab={selectedTabFilter}
        onTabChange={(value) => {
          setSelectedTabFilter(value);
          debouncedUpdate({
            ...sequenceContactsParams,
            defaultFilters: value.value.filter,
            offset: 0,
          });
        }}
        tabValueMappings={tabValueMappings}
      />
      <Box css={{ padding: 24 }}>
        <CombinedFilters
          quickSearchPlaceholder="Search Contacts"
          quickSearchValue={quickSearchValue}
          setQuickSearchValue={(value: string) => {
            setQuickSearchValue(value);
            handleQuickSearch(debouncedUpdate, sequenceContactsParams, value);
          }}
          defaultObjects={defaultObject}
          customObjects={[]}
          activeFilters={activeFilters}
          setFilters={(value: Array<FilterType>) => {
            setActiveFilters(value);
            handleFilterChange(debouncedUpdate, sequenceContactsParams, value);
          }}
          sortConfig={sequenceContactsSortConfig}
          activeSort={sequenceContactsParams.sort}
          onSortUpdate={(value: Array<Sort>) =>
            handleSortUpdate(debouncedUpdate, sequenceContactsParams, value)
          }
        />
      </Box>
      <FilteredTable
        isLoading={loadingContacts}
        columns={columns}
        data={data}
        totalCount={sequencesState.totalSequenceContacts}
        onEndReached={() => handleOnEndReached()}
        bulkActions={bulkActions}
        emptyStateElement={noContactsFound}
        tableRef={tableRef}
        heightOffset={325}
      />
      {current && current.id && (
        <>
          <AddToSequenceDialog
            isOpen={isAddToSequenceModalOpen}
            showTimeZonePicker={false}
            sequence={current}
            locations={channelsState.channels}
            totalContacts={totalContactsSelected}
            setIsOpen={setAddToSequenceModalOpen}
            filterSource={['sequence', current.id]}
            filter={combineFilter(
              selectedStepContactIds,
              sequenceContactsParams,
              isAllSelected
            )}
          />
          <BulkTagModal
            action={tagModalAction}
            filterSource={['sequence', current.id]}
            open={isBulkTagModalOpen}
            totalContacts={totalContactsSelected}
            onOpenChange={setBulkTagModalOpen}
            filter={
              combineFilter(selectedStepContactIds, sequenceContactsParams, isAllSelected)
                .filter as FilterItem[]
            }
          />
          <UnsubscribeContactsFromCampaignModal
            onOpenChange={setUnsubscribeContactsModalOpen}
            open={isUnsubscribeContactsModalOpen}
            totalContacts={totalContactsSelected}
            filterSource={['sequence', current.id]}
            filter={
              combineFilter(selectedStepContactIds, sequenceContactsParams, isAllSelected)
                .filter as FilterItem[]
            }
          />
        </>
      )}
    </Box>
  );
};

function combineFilter(
  contactIds: string[],
  searchParam: SearchFilters,
  isAllSelected: boolean
): SequenceBulkActionFilter {
  const filter = prepareFilter(searchParam, contactIds, isAllSelected, 'step_contact');
  return { filter: filter };
}

const ellipsis = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

const Cell = styled(Flex, {
  width: 120,
  p: 0,
  m: 0,
  alignItems: 'center',
  justifyContent: 'center',
  display: '-webkit-box',
  '-webkit-line-clamp': 1,
  '-webkit-box-orient': 'vertical',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  textAlign: 'left',
  borderBottom: '1px solid $gray300',
});
