import { weekDays } from '@/shared/components/openingHours/constants';
import { Button, Checkbox, Text } from '@/shared/ui';

export const WeekDaySelector = (props: {
  targetDays: Array<string>;
  sourceDay: string;
  handleCheckboxClick: (weekday: string, isChecked: boolean) => void;
  onApply: () => void;
}) => {
  const { targetDays, sourceDay, handleCheckboxClick, onApply } = props;

  // Inline styles for the label containing the checkbox and day text
  const labelStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '10px 0',
  };

  return (
    <>
      <Text>COPY TIMES TO...</Text>
      {weekDays.map((weekday: string, index: number) => (
        <label key={index} style={labelStyles}>
          <Text>{weekday}</Text>
          <Checkbox
            checked={targetDays.includes(weekday) || weekday === sourceDay}
            onCheckedChange={(isChecked: boolean) =>
              handleCheckboxClick(weekday, isChecked)
            }
            disabled={weekday === sourceDay}
            data-testid={`copyDaysPopoverCheckbox-${weekday}`}
          />
        </label>
      ))}
      <Button onClick={() => onApply()} data-testid="applyButton">
        Apply
      </Button>
    </>
  );
};
