import React, { useEffect, useMemo, useState } from 'react';
import { HiDotsVertical, HiX } from 'react-icons/hi';
import { toast } from 'sonner';

import { useAuth } from '@/pages/auth/context/AuthProvider';
import {
  createProviderAccount,
  getProviderAccounts,
  updateProviderAccount,
} from '@/shared/api/channels/accounts';
import { useDisclosure } from '@/shared/hooks';
import { SettingsLayout } from '@/shared/layouts';
import {
  CreateProviderAccountParams,
  ProviderAccountItem,
} from '@/shared/types/channels/accounts';
import {
  Box,
  Button,
  Dialog,
  DialogCloseIcon,
  DialogContent,
  DialogOverlay,
  DialogPortal,
  DialogTitle,
  DialogTrigger,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
  Fieldset,
  Flex,
  HStack,
  IconButton,
  Input,
  Label,
} from '@/shared/ui';
import { Table, TableColumn } from '@/shared/v2/components/table/Table';

import { CustomDropdownMenuItem } from '../users/UsersTable';

export const ProvidersAccounts = () => {
  const [providers, setProviders] = useState<ProviderAccountItem[] | null>(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const authContext = useAuth();
  const { organizationId } = authContext;

  useEffect(() => {
    // Define the parameters for the API request
    const params = {
      filter: [
        {
          column: 'id',
          comparison: '==',
          resource: 'organization',
          value: organizationId,
        },
      ],
      sort: [],
      limit: 1000,
      offset: 0,
    };

    // Fetch providers data
    setIsLoading(true);
    getProviderAccounts(params)
      .then((data) => setProviders(data))
      .catch((err) => setError(err.message))
      .finally(() => setIsLoading(false));
  }, [organizationId]);

  const columns: Array<TableColumn<ProviderAccountItem>> = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        width: '100%',
        colWidth: '100%',
        Cell: ({ row }) => <span>{row.original.name}</span>,
      },
      {
        Header: '',
        accessor: 'provider',
        width: '100px',
        Cell: ({ row }) => (
          <DropdownMenu modal={false}>
            <DropdownMenuTrigger asChild>
              <IconButton variant="outline" size="2">
                <HiDotsVertical />
              </IconButton>
            </DropdownMenuTrigger>
            <DropdownMenuContent
              hideWhenDetached
              css={{
                padding: 8,
                width: 150,
                minWidth: 150,
                boxShadow: '0px 12px 60px 0px #0000000E',
              }}
              align="start"
              side="left"
              sideOffset={5}
            >
              <UpdateProviderAccountForm account={row.original}>
                <CustomDropdownMenuItem onClick={(e) => e.preventDefault()}>
                  <Box css={{ fontWeight: 400, fontSize: 14 }}>Update Account</Box>
                </CustomDropdownMenuItem>
              </UpdateProviderAccountForm>
            </DropdownMenuContent>
          </DropdownMenu>
        ),
      },
    ],
    []
  );

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <SettingsLayout
      background="#fafafa"
      padding="0"
      width="100%"
      breadcrumbs={[
        { title: 'Settings', path: '/settings/accounts' },
        { title: 'Provider Accounts', path: `/settings/accounts` },
      ]}
      actions={<CreateProviderAccountForm />}
    >
      <Flex css={{ p: 30 }} direction="column">
        <Table
          data={providers || []}
          columns={columns}
          caption="Providers Table"
          emptyTitle="No Provider Accounts Match Search"
          totalCount={providers?.length || 0}
          pageSize={10}
          setOffset={(offset) => {
            console.log('offset', offset);
          }}
          isLoading={isLoading}
        />
      </Flex>
    </SettingsLayout>
  );
};

export const CreateProviderAccountForm = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [formData, setFormData] = useState<CreateProviderAccountParams>({
    name: '',
    provider: 'twilio',
    setup_type: 'automated',
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await createProviderAccount(formData);
      toast.success('Account created successfully');
      onClose();
    } catch (error) {
      console.error('Error creating account:', error);
      toast.error('Error creating account');
    }
  };

  return (
    <Dialog open={isOpen}>
      <DialogTrigger asChild onClick={() => onOpen()}>
        <Button>Add Account</Button>
      </DialogTrigger>
      <DialogPortal>
        <DialogOverlay as="div" />
        <DialogContent>
          <DialogTitle>Create Provider Account</DialogTitle>
          <DialogCloseIcon onClick={() => onClose()} size="2">
            <HiX size="15px" />
          </DialogCloseIcon>
          <form onSubmit={handleSubmit}>
            <Fieldset>
              <Label htmlFor="name">Name</Label>
              <Input
                type="text"
                placeholder="Enter Account Name"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </Fieldset>
            <Flex justify="end">
              <HStack>
                <Button variant="gray" type="button" onClick={() => onClose()}>
                  Cancel
                </Button>
                <Button type="submit">Create Account</Button>
              </HStack>
            </Flex>
          </form>
        </DialogContent>
      </DialogPortal>
    </Dialog>
  );
};

const UpdateProviderAccountForm = ({
  account,
  children,
}: {
  account: ProviderAccountItem;
  children: React.ReactNode;
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [formData, setFormData] = useState<ProviderAccountItem>({
    ...account,
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleNestedChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    parentKey: string
  ) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [parentKey as keyof ProviderAccountItem]: {
        ...(prevData[parentKey as keyof ProviderAccountItem] as any),
        [name]: value,
      },
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await updateProviderAccount(account.id, formData);
      toast.success('Account updated successfully');
      onClose();
    } catch (error) {
      console.error('Error updating account:', error);
      toast.error('Error updating account');
    }
  };

  return (
    <Dialog open={isOpen}>
      <DialogTrigger asChild onClick={() => onOpen()}>
        {children}
      </DialogTrigger>
      <DialogPortal>
        <DialogOverlay as="div" />
        <DialogContent>
          <DialogTitle>Update Account</DialogTitle>
          <DialogCloseIcon onClick={() => onClose()} size="2">
            <HiX size="15px" />
          </DialogCloseIcon>
          <form onSubmit={handleSubmit}>
            <Fieldset>
              <Label htmlFor="name">Name</Label>
              <Input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </Fieldset>
            <Fieldset>
              <Label htmlFor="auth_token">Auth Token</Label>
              <Input
                type="text"
                id="auth_token"
                name="auth_token"
                disabled={formData.setup_type === 'automated'}
                value={formData.auth.auth_token}
                onChange={(e) => handleNestedChange(e, 'auth')}
                required
              />
            </Fieldset>
            <Fieldset>
              <Label htmlFor="api_key">API Key</Label>
              <Input
                type="text"
                id="api_key"
                name="api_key"
                disabled={formData.setup_type === 'automated'}
                value={formData.provider_external_data.api_key}
                onChange={(e) => handleNestedChange(e, 'provider_external_data')}
                required
              />
            </Fieldset>
            <Fieldset>
              <Label htmlFor="api_secret">API Secret</Label>
              <Input
                type="text"
                id="api_secret"
                name="api_secret"
                disabled={formData.setup_type === 'automated'}
                value={formData.provider_external_data.api_secret}
                onChange={(e) => handleNestedChange(e, 'provider_external_data')}
                required
              />
            </Fieldset>
            <Fieldset>
              <Label htmlFor="voice_app_sid">Voice App SID</Label>
              <Input
                type="text"
                id="voice_app_sid"
                name="voice_app_sid"
                disabled={formData.setup_type === 'automated'}
                value={formData.provider_external_data.voice_app_sid}
                onChange={(e) => handleNestedChange(e, 'provider_external_data')}
                required
              />
            </Fieldset>
            <Fieldset>
              <Label htmlFor="ios_push_credential">iOS Push Credential</Label>
              <Input
                type="text"
                id="ios_push_credential"
                name="ios_push_credential"
                disabled={formData.setup_type === 'automated'}
                value={formData.provider_external_data.ios_push_credential}
                onChange={(e) => handleNestedChange(e, 'provider_external_data')}
                required
              />
            </Fieldset>
            <Fieldset>
              <Label htmlFor="android_push_credential">Android Push Credential:</Label>
              <Input
                type="text"
                id="android_push_credential"
                name="android_push_credential"
                disabled={formData.setup_type === 'automated'}
                value={formData.provider_external_data.android_push_credential}
                onChange={(e) => handleNestedChange(e, 'provider_external_data')}
                required
              />
            </Fieldset>
            <Flex justify="end">
              <HStack>
                <Button variant="gray" type="button" onClick={() => onClose()}>
                  Cancel
                </Button>
                <Button type="submit">Update Account</Button>
              </HStack>
            </Flex>
          </form>
        </DialogContent>
      </DialogPortal>
    </Dialog>
  );
};
