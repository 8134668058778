import React from 'react';
import { Helmet } from 'react-helmet-async';

import { usePageView } from '@/shared/hooks';
import { SettingsLayout } from '@/shared/layouts';

import NotificationState from './context/NotificationContext';
import NotificationsView from './NotificationsView';

export const Notifications = (): JSX.Element => {
  usePageView();

  return (
    <>
      <Helmet>
        <title>Whippy | Notifications</title>
      </Helmet>
      <NotificationState>
        <SettingsLayout
          breadcrumbs={[
            { title: 'Settings', path: '/settings/notifications' },
            { title: 'Notifications', path: `/settings/notifications` },
          ]}
        >
          <NotificationsView />
        </SettingsLayout>
      </NotificationState>
    </>
  );
};
