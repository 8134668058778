import React, { Dispatch, memo } from 'react';
import { HiX } from 'react-icons/hi';

import { SideNavigationHeading } from '@/inbox/list/ConversationsFilter';
import {
  PanelContainer,
  PanelContentContainer,
  PanelHeaderContainer,
} from '@/pages/inbox/conversation/panels';
import { AgentEditorPanelType } from '@/shared/types/ai/agents';
import { Flex, IconButton } from '@/shared/ui';

import { useLLMAgent } from '../../context/AgentsContext';
import { TestAgentPanel } from './TestAgentPanel';

type AgentEditorPanelProps = {
  panel: AgentEditorPanelType;
  setShowPanel: Dispatch<React.SetStateAction<boolean>>;
};

const AgentEditorPanel = (props: AgentEditorPanelProps): JSX.Element => {
  const { panel, setShowPanel } = props;

  const agents = useLLMAgent();
  const { currentLLM } = agents.state;

  const renderPanelContent = (panel: AgentEditorPanelType): JSX.Element => {
    switch (panel) {
      case AgentEditorPanelType.CHAT:
        return <TestAgentPanel prompt={currentLLM?.current_version?.prompt || ''} />;
      case AgentEditorPanelType.SUGGESTIONS:
        return <Flex>Suggestions</Flex>;
      case AgentEditorPanelType.HELP:
        return <Flex>Help</Flex>;
    }
  };

  return (
    <PanelContainer
      direction="column"
      css={{ height: '100%', borderRight: '1px solid #dddee1' }}
    >
      <PanelHeaderContainer align="center" justify="between">
        <SideNavigationHeading css={{ textTransform: 'capitalize' }}>
          Agent Editor
        </SideNavigationHeading>
        <IconButton size={2} onClick={() => setShowPanel(false)}>
          <HiX size={16} />
        </IconButton>
      </PanelHeaderContainer>
      <PanelContentContainer
        css={{ minHeight: 'calc(100% - 64px)', minWidth: '100%', width: 400 }}
      >
        <Flex
          align="center"
          justify="center"
          css={{
            minHeight: '100%',
            minWidth: '100%',
          }}
        >
          {renderPanelContent(panel)}
        </Flex>
      </PanelContentContainer>
    </PanelContainer>
  );
};

export default memo(AgentEditorPanel);
