import { styled } from '@stitches/react';
import { useRef, useState } from 'react';

import { CustomScrollbar, Drawer, DrawerContent, DrawerPortal } from '@/shared/ui';

import { SideBar } from './SideBar';

export type sideNavigationGroup = {
  title?: string;
  sideNavigationGroup: Array<sideNavigationTabType>;
};

export type sideNavigationTabType = {
  title: string;
  image: JSX.Element;
  action?: () => void;
  link?: string;
  isActive: boolean;
  hidden?: boolean;
  href?: string;
  target?: string;
};

export type sideNavigationType = {
  header: string | JSX.Element;
  expanded: boolean;
  hideDivider?: boolean;
  sideNavigationTabs: Array<sideNavigationGroup>;
  toggleExpandedState: () => void;
  children?: JSX.Element;
};

export const SideToggleButton = styled('button', {
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
  borderRadius: '4px',
  width: '35px',
  height: '35px',
  justifyContent: 'center',
  marginBottom: '20px',
  marginTop: '15px',
});

export function SideNavigation(props: sideNavigationType) {
  const timerId = useRef<NodeJS.Timeout>();
  const [showDrawer, setShowDrawer] = useState(false);

  const setShowDrawerState = (value: boolean) => {
    if (timerId.current) {
      clearTimeout(timerId.current);
    }
    timerId.current = setTimeout(() => {
      setShowDrawer(value);
      value && (document.body.style.pointerEvents = 'auto');
    }, 100);
  };

  return (
    <div data-testid="side-navigation-bar">
      <SideBar {...props} setShowDrawer={setShowDrawerState} showToggleButton />
      <Drawer open={showDrawer && !props.expanded}>
        <DrawerPortal>
          <DrawerContent
            onClick={() => setShowDrawer(false)}
            onMouseLeave={() => setShowDrawerState(false)}
            onMouseEnter={() => setShowDrawerState(true)}
            onOpenAutoFocus={(e) => e.preventDefault()}
            onEscapeKeyDown={() => setShowDrawerState(false)}
            onPointerDownOutside={() => setShowDrawerState(false)}
            side="left"
            css={{ width: 230, overflowY: 'scroll', top: 130 }}
          >
            <CustomScrollbar scrollAreaWidth={24}>
              <SideBar {...props} expanded={true} />
            </CustomScrollbar>
          </DrawerContent>
        </DrawerPortal>
      </Drawer>
    </div>
  );
}
