import React from 'react';
import ReactPlayer from 'react-player';

import { Flex } from '@/shared/ui';

type VideoViewerProps = {
  url: string;
};
const VideoViewer = (props: VideoViewerProps) => {
  return (
    <Flex
      align="center"
      justify="center"
      css={{
        width: '100%',
        height: '100%',
      }}
    >
      <ReactPlayer
        url={props.url}
        playing={true}
        width="30%"
        height="50%"
        controls={true}
      />
    </Flex>
  );
};

export default VideoViewer;
