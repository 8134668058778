import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Loading } from '@/shared/components/Loading';

import { useAuth } from './context/AuthProvider';
import { SSO } from './SSO';

type ParamTypes = {
  data: string;
};

/**
 * Handles Login with SSO authentication success.
 * When user is successfully authenticated by SSO, server sends
 * user object and token information
 */

export const SSORedirect = () => {
  const auth = useAuth();
  const data = useParams<ParamTypes>();
  const [authenticated, setAuthenticated] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    /** Data is base64 encoded, so decode the data and covert to string */
    const convertedData = atob(data.data);
    /** Then convert string of data to JSON object */
    const json = JSON.parse(convertedData);
    if (login_success(json)) {
      /** Map user object and token value */
      auth.handleAuthSuccess(json);
    }
  }, [auth, data.data]);

  const login_success = (data: {
    access_token: string | null;
    message: string | null;
  }) => {
    if (data.access_token != null) {
      setAuthenticated(true);
      setLoading(false);
      return true;
    } else {
      setAuthenticated(false);
      setErrorMessage(data?.message || 'Error');
      setLoading(false);
      return false;
    }
  };

  return loading ? <Loading /> : authenticated ? null : <SSO message={errorMessage} />;
};
