/* eslint-disable react-hooks/exhaustive-deps */
import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import { toast } from 'sonner';
import * as Yup from 'yup';

import { useDeveloperContext } from '@/pages/developer/context/DevelopersContext';
import * as Util from '@/pages/integrations/utils';
import { useChannels } from '@/pages/settings/organization/channels/context/ChannelContext';
import * as API from '@/shared/api/integrations';
import * as SequencesAPI from '@/shared/api/sequences';
import { FormFieldWrapper, SelectCombobox, TextInput } from '@/shared/components/forms';
import { AddToSequenceData, isAddToSequenceParams } from '@/shared/types/integrations';
import { Sequence } from '@/shared/types/sequences';
import { Button, Flex, HStack } from '@/shared/ui';

import { useIntegrationConfigurations } from '../../context/IntegrationConfigurationContext';

type FormValues = {
  from_number: string;
  sequence_id: string;
  forwarding_email: string;
  api_key: string;
};

export const AddToSequenceForm = (props: {
  data: AddToSequenceData;
  onClose: () => void;
}) => {
  const { channelsState } = useChannels();
  const { channels } = channelsState;
  const { developerState, getApiKeys } = useDeveloperContext();
  const { apiKeys } = developerState;
  const [sequences, setSequences] = useState<Sequence[]>([]);

  const { configurationsState, setCurrentConfiguration } = useIntegrationConfigurations();
  const { current } = configurationsState;

  useEffect(() => {
    const fetchSequences = async () => {
      const data = await SequencesAPI.getSequences();
      // this is here for typescript reasons since data could be a null[]
      if (data.data) {
        const nonNullData = data.data as Sequence[];
        setSequences(nonNullData);
      }
    };
    fetchSequences().finally(getApiKeys);
  }, []);

  const apiKeyOptions =
    apiKeys && apiKeys.length > 0
      ? apiKeys.map((apiKey) => ({
          // display the name
          type: apiKey?.name as string,
          // capture the key as the value
          value: apiKey?.key as string,
        }))
      : [];

  const sequenceOptions =
    sequences && sequences.length > 0
      ? sequences.map((sequence: Sequence) => ({
          value: sequence?.id as string,
          type: sequence?.title as string,
        }))
      : [];

  // mapping the locations into the data structure that is needed for combobox
  const phoneNumberOptions =
    channels && channels.length > 0
      ? channels.map((channel) => ({
          value: channel.phone as string,
          type: channel.phone as string,
        }))
      : [];

  const schema = Yup.object({
    from_number: Yup.string().required('Required'),
    sequence_id: Yup.string().uuid().required('Required'),
    // required fields for all Job Board Automations
    forwarding_email: Yup.string().email().required('Required'),
    api_key: Yup.string().uuid().required('Required'),
  });

  if (!isAddToSequenceParams(props.data.action_params)) {
    return <div></div>;
  }

  const initialFormValues = {
    from_number: props.data.action_params.from_number,
    sequence_id: props.data.action_params.sequence_id,
    // required fields for all Job Board Automations
    forwarding_email: props.data.forwarding_email,
    api_key: props.data.api_key,
  } as FormValues;

  return (
    <Formik
      initialValues={initialFormValues}
      validationSchema={schema}
      onSubmit={(values) => {
        const updatedConfig = Util.updatedConfiguration(current, values);
        if (!updatedConfig) return;
        API.updateConfiguration(updatedConfig)
          .then(() => {
            toast.success('Configuration Updated');
            setCurrentConfiguration(updatedConfig);
          })
          .catch(() => {
            toast.error('Error updating configuration');
          })
          .finally(() => {
            props.onClose();
          });
      }}
    >
      {(formik) => (
        <form onSubmit={formik.handleSubmit}>
          <Flex justify="between">
            <FormFieldWrapper
              name="from_number"
              tooltip="The number within your organization that you want the message to originate from"
              label="From Number"
            >
              <SelectCombobox
                key={`${phoneNumberOptions.length}+${formik.values.from_number}`}
                placeholder={formik.values.from_number}
                isDropdown={true}
                options={phoneNumberOptions}
                closeOnClick={true}
              />
            </FormFieldWrapper>
          </Flex>
          <Flex justify="between">
            <FormFieldWrapper
              name="forwarding_email"
              tooltip="The email to forward the applicants to"
              label="Forwarding Email"
            >
              <TextInput name="forwarding_email" type="email" />
            </FormFieldWrapper>
          </Flex>
          <Flex justify="between">
            <FormFieldWrapper
              name="sequence_id"
              tooltip="The the sequence you would like to add the applicants to"
              label="Sequence"
            >
              <SelectCombobox
                key={`${sequenceOptions.length}+${formik.values.sequence_id}`}
                placeholder={formik.values.sequence_id}
                isDropdown={true}
                options={sequenceOptions}
                closeOnClick={true}
              />
            </FormFieldWrapper>
          </Flex>
          <Flex justify="between">
            <FormFieldWrapper
              name="api_key"
              tooltip="The api key to use."
              label="API Key"
            >
              <SelectCombobox
                key={`${apiKeyOptions.length}+${formik.values.api_key}`}
                placeholder={formik.values.api_key}
                isDropdown={true}
                options={apiKeyOptions}
                closeOnClick={true}
              />
            </FormFieldWrapper>
          </Flex>
          <Flex justify="end">
            <HStack>
              <Button type="submit" disabled={!formik.dirty}>
                Submit Changes
              </Button>
            </HStack>
          </Flex>
        </form>
      )}
    </Formik>
  );
};
