import React, { Dispatch, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { HiExclamationCircle } from 'react-icons/hi';
import { useParams } from 'react-router-dom';

import { ConfirmationDialog } from '@/shared/components/ConfirmationDialog';
import { SingleSelect } from '@/shared/components/SingleSelect';
import { Domain } from '@/shared/types/domains';
import { AccordionValue } from '@/shared/types/links';
import { Box, Button, Fieldset, Flex, HStack, Input, Label, VStack } from '@/shared/ui';
import { styled } from '@/stitches.config';

import { useLinks } from '../context/LinksContext';
import { isValidUrl } from '../utils';
import { LinkAccordion } from './LinkAccordion';

type LinkInfoProps = {
  /* accordion value */
  accordion: AccordionValue;
  /* accordion setter */
  setAccordion: Dispatch<React.SetStateAction<AccordionValue>>;
  /* link */
  url: string;
  /* link setter */
  setUrl: Dispatch<React.SetStateAction<string>>;
  /* active domains list */
  domains: Domain[];
  /* short key */
  shortKey: string;
  /* short key setter */
  setShortKey: Dispatch<React.SetStateAction<string>>;
  /* domain id */
  domainId: string;
  /* domain id setter */
  setDomainId: Dispatch<React.SetStateAction<string>>;
  /* handle save */
  handleSave: (accordion: AccordionValue) => void;
  /* is link settings form submitting? */
  isSubmitting: boolean;
  /* show save button or not */
  showSaveButton?: boolean;
  /* hide index and green box */
  hideIndex?: boolean;
};

const LinkInfo = (props: LinkInfoProps): JSX.Element => {
  const {
    accordion,
    setAccordion,
    url,
    domains,
    setUrl,
    shortKey,
    setShortKey,
    handleSave,
    showSaveButton = true,
    domainId,
    setDomainId,
    hideIndex,
    isSubmitting,
  } = props;

  const {
    linksState: { error, current },
  } = useLinks();
  const params = useParams<{ id: string }>();

  const [errorUrl, setErrorUrl] = useState('');
  const [lockShortLink, setLockShortLink] = useState<boolean>(!!params?.id);

  const getDomainById = (id: string) =>
    domains.find((domain: Domain) => domain.id === id);

  const handleChangeDomain = useCallback(async (newDomainId: string) => {
    setDomainId(newDomainId);
  }, []);

  const handleGenerateShortLink = useCallback(() => {
    const uniqueId = Math.random().toString(36).slice(2, 9);
    setShortKey(uniqueId);
  }, []);

  const handleBlur = useCallback((e: React.FocusEvent<HTMLInputElement>) => {
    if (!isValidUrl(e?.target?.value) && !!e?.target?.value) {
      setErrorUrl('Url is not valid');
    } else {
      setErrorUrl('');
    }
  }, []);

  const handleLinkSave = useCallback(() => {
    handleSave(AccordionValue.SETTINGS);
  }, [handleSave]);

  useEffect(() => {
    if (params?.id && accordion === AccordionValue.SETTINGS) {
      setLockShortLink(true);
    }
  }, [params?.id, accordion]);

  const isErrorVisible = useMemo(() => {
    return error?.includes('URL') || error?.includes('Short Key');
  }, [error]);

  return (
    <LinkAccordion
      index={1}
      hideIndex={hideIndex}
      title="Link"
      description="Add destination and short link"
      currentAccordionValue={accordion}
      itemValue={AccordionValue.LINK}
      setItemValue={setAccordion}
      isValid={url.length > 1 && isValidUrl(url) && !!shortKey}
      buttonCopy={!showSaveButton ? 'Expand' : 'Edit'}
    >
      <VStack gap="2">
        <Fieldset>
          <Label>Destination URL</Label>
          <Box>
            <Input
              placeholder="https://app.whippy.co/campaigns"
              value={url}
              onChange={(e) => setUrl(e.target.value)}
              onBlur={handleBlur}
              css={errorUrl && url.length > 1 ? { border: '1px solid #E53E3E' } : {}}
              disabled={!!params?.id}
            />
          </Box>
          {errorUrl && url.length > 1 && (
            <HStack css={{ mt: 11 }}>
              <HiExclamationCircle color="#E53E3E" />
              <Box css={{ color: '#E53E3E', fontSize: 12 }}>{errorUrl}</Box>
            </HStack>
          )}
        </Fieldset>
        <Fieldset css={{ mt: 0 }}>
          <Flex justify="between" align="end">
            <Label>Short Link</Label>
            {lockShortLink ? (
              <ConfirmationDialog
                title="Warning"
                description="Editing an existing short link will result in broken links and reset its analytics. Are you sure you want to continue?"
                cancelButtonTitle="Cancel"
                confirmButtonTitle="Ok"
                confirmButtonVariant="primary"
                onConfirm={() => setLockShortLink(false)}
                testID="unlock-short-key"
              >
                <GenerateButton
                  onClick={(e) => e.preventDefault()}
                  variant="primary"
                  ghost
                  css={{ p: 0, boxShadow: 'none' }}
                >
                  Unlock
                </GenerateButton>
              </ConfirmationDialog>
            ) : (
              <GenerateButton
                variant="primary"
                ghost
                css={{ p: 0, boxShadow: 'none' }}
                onClick={handleGenerateShortLink}
              >
                Generate
              </GenerateButton>
            )}
          </Flex>
          <HStack>
            <Box css={{ width: 350 }}>
              <SingleSelect
                defaultPlaceholder={
                  getDomainById(domainId || '')?.name || 'Select a domain'
                }
                selectItem={domainId || ''}
                setSelectItem={handleChangeDomain}
                options={domains.map((domain: Domain) => ({
                  type: domain?.name || '',
                  value: domain.id,
                }))}
                inputStyles={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                disabled={lockShortLink}
                dataTestId="domains-select"
                isDropdown
                isLarge
              />
            </Box>
            <Input
              placeholder="Uh4hgG"
              value={shortKey}
              onChange={(e) => setShortKey(e.target.value)}
              css={{ ml: -1, borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
              disabled={lockShortLink}
            />
          </HStack>
          {error && isErrorVisible && (
            <HStack css={{ mt: 11 }}>
              <HiExclamationCircle color="#E53E3E" />
              <Box css={{ color: '#E53E3E', fontSize: 12 }}>{error}</Box>
            </HStack>
          )}
        </Fieldset>
        <HStack>
          {showSaveButton && (
            <Button
              onClick={handleLinkSave}
              disabled={
                isSubmitting ||
                url.length < 2 ||
                !shortKey ||
                lockShortLink ||
                (current?.shareable_link?.short_key === shortKey &&
                  (current?.domain?.id || '') === domainId)
              }
            >
              Save
            </Button>
          )}
          <Button
            variant="gray"
            ghost={true}
            onClick={() => setAccordion(AccordionValue.DEFAULT_VALUE)}
          >
            Cancel
          </Button>
        </HStack>
      </VStack>
    </LinkAccordion>
  );
};

const GenerateButton = styled(Button, {
  fontWeight: 600,
  fontSize: 14,
  '&:hover': {
    backgroundColor: 'transparent !important',
    color: '$hiContrast',
  },
});

export default memo(LinkInfo);
