import { FormikValues, useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import Select from 'react-select';

type SelectableProps = {
  name: string;
  options: { value: string; label: string }[];
  selected: string[];
};

// use creatable select to allow users to add new industries
export function Selectable(props: SelectableProps): JSX.Element {
  const prepareSelectedValues = (
    options: { value: string; label: string }[],
    selected: string[]
  ) => {
    return options.filter((o) => selected.includes(o.value));
  };

  const formik = useFormikContext<FormikValues>();
  const [selectableValue, setSelectableValue] = useState(() =>
    prepareSelectedValues(props.options, props.selected)
  );

  useEffect(() => {
    setSelectableValue(prepareSelectedValues(props.options, props.selected));
  }, [props.selected, props.options]);

  const handleChange = (newValue: any) => {
    const selectedValues = newValue ? newValue.map((v: any) => v.value) : [];
    formik.setFieldValue(props.name, selectedValues);
    setSelectableValue(newValue);
  };

  return (
    <Select
      isMulti
      options={props.options}
      value={selectableValue}
      onChange={handleChange}
    />
  );
}
