/* eslint-disable react-hooks/exhaustive-deps */
import dayjs from 'dayjs';
import { HiMinus, HiPlus } from 'react-icons/hi';
import { useMedia } from 'react-use';

import { TimePicker } from '@/shared/components/timepicker/TimePicker';
import { TimezonePicker } from '@/shared/components/timezonepicker/TimezonePicker';
import { Box, ControlGroup, HStack, IconButton, Input } from '@/shared/ui';

/**
 *
 * Schedule Template
 *
 */
type ScheduleTemplateProps = {
  /** is the template scheduled? */
  isScheduled: boolean;
  /** number of days in the future to send the message */
  days: number;
  /** set number of days in the future to send the message */
  setDays: (days: number) => void;
  /** the time the template is scheduled */
  time: string;
  /** set the time the template is scheduled */
  setTime: (time: string) => void;
  /** the timezone the template is scheduled */
  timezone: string;
  /** set the timezone the template is scheduled */
  setTimezone: (timezone: string) => void;
};

export const ScheduleTemplate = ({
  days,
  isScheduled,
  setDays,
  time,
  setTime,
  timezone,
  setTimezone,
}: ScheduleTemplateProps): JSX.Element => {
  const isMobile = useMedia('(max-width: 700px)');

  return (
    <HStack
      gap={2}
      data-testid="schedule-fields"
      css={{ flexWrap: isMobile ? 'wrap' : 'nowrap' }}
    >
      <Box css={{ fontSize: 13, minWidth: 10 }}>In</Box>
      <HStack>
        <ControlGroup>
          <IconButton
            size={2}
            css={{ backgroundColor: 'white' }}
            onClick={(e) => {
              e.preventDefault();
              days > 0 ? setDays(days - 1) : setDays(days);
            }}
          >
            <HiMinus />
          </IconButton>
          <Input
            type="number"
            placeholder="0"
            value={days}
            onChange={(e) => setDays(Number(e.target.value))}
            css={{ textAlign: 'center', width: 45 }}
          />
          <IconButton
            size={2}
            css={{ backgroundColor: 'white' }}
            onClick={(e) => {
              e.preventDefault();
              setDays(days + 1);
            }}
          >
            <HiPlus />
          </IconButton>
        </ControlGroup>
        <Box css={{ fontSize: 13 }}>days</Box>
      </HStack>
      <Box css={{ fontSize: 13 }}>at</Box>
      <TimePicker
        setTime={setTime}
        time={time}
        currentDate={dayjs().add(days, 'day').format('MM/DD/YYYY')}
        timezone={timezone || Intl.DateTimeFormat().resolvedOptions().timeZone}
        disabled={isScheduled ? false : true}
        variant="medium"
      />
      <Box css={{ fontSize: 13 }}>in</Box>
      <Box css={{ minWidth: 210, mt: isMobile ? '5px' : 0 }}>
        <TimezonePicker
          timezone={timezone}
          setTimezone={setTimezone}
          isSmall={false}
          disabled={isScheduled ? false : true}
        />
      </Box>
    </HStack>
  );
};
