import {
  IntegrationApplicationKeys,
  IntegrationConfiguration,
} from '@/shared/types/integrations';

import { JobBoardAutomationForm } from './forms/JobBoardAutomationForm';

type ConfigurationFormProps = {
  configuration: IntegrationConfiguration | null;
  onClose: () => void;
};

export const IntegrationConfigurationForm = (props: ConfigurationFormProps) => {
  const { configuration } = props;

  if (configuration == null) {
    return <div>no configuration</div>;
  }

  switch (configuration.application.key) {
    case IntegrationApplicationKeys.INDEED:
    case IntegrationApplicationKeys.PANDO:
    case IntegrationApplicationKeys.DATA_FRENZY:
    case IntegrationApplicationKeys.ACQUIRE_ROI:
    case IntegrationApplicationKeys.CAREER_BUILDER:
    case IntegrationApplicationKeys.JOB_ADDER:
    case IntegrationApplicationKeys.JOB_SPARX:
    case IntegrationApplicationKeys.ZIP_RECRUITER:
    case IntegrationApplicationKeys.JAZZ_HR:
    case IntegrationApplicationKeys.ZOOM_VOICEMAIL:
      return (
        <JobBoardAutomationForm configuration={configuration} onClose={props.onClose} />
      );
  }
};
