import { Box, Flex, Skeleton, VStack } from '@/shared/ui';

import { StyledCampaignItem } from '../items/campaigns';

export const CampaignSkeleton = ({ quantity = 10 }: { quantity?: number }) => {
  return (
    <>
      {[...Array(quantity)].map((_, index) => (
        <StyledCampaignItem key={index}>
          <VStack gap={1} css={{ width: '100%' }}>
            <Flex css={{ width: '100%' }} align="center" justify="between">
              <Skeleton key={index} variant="tag" css={{ height: 10, width: 100 }} />
              <Skeleton key={index} variant="tag" css={{ height: 10, width: 60 }} />
              <Skeleton
                key={index}
                variant="tag"
                css={{ height: 10, width: 100, marginLeft: 'auto' }}
              />
            </Flex>
            <Box css={{ fontSize: 13 }}>
              <Skeleton key={index} variant="tag" css={{ height: 20, width: 400 }} />
            </Box>
          </VStack>
        </StyledCampaignItem>
      ))}
    </>
  );
};
