export const default_contact_list_object = {
  id: 'unique-id-for-contact-list',
  label: 'Contact List',
  key: 'list',
  inserted_at: 'current-timestamp',
  updated_at: 'current-timestamp',
  custom_properties: [
    {
      default: '',
      id: 'contact-list',
      label: 'List',
      type: 'list',
      key: 'id',
      required: true,
      inserted_at: 'current-timestamp',
      updated_at: 'current-timestamp',
      custom_object_id: 'unique-id-for-contact-list',
    },
  ],
};
