/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';

import { useDisclosure } from '@/shared/hooks';
import { Button, Input, Popover, PopoverContent, PopoverTrigger } from '@/shared/ui';

import { useContacts } from '../context/ContactContext';

type ContactDynamicInputProps = {
  /** initial value of the input */
  initialValue: string;
  /** function to be executed on save */
  onSave: (newValue: string) => void;
  /** string to show when there is no value */
  fallbackString: string;
};

export const ContactDynamicInput = (props: ContactDynamicInputProps) => {
  const contactContext = useContacts();
  const { contactState } = contactContext;
  const { error } = contactState;

  const { isOpen, onOpen, onClose } = useDisclosure();
  // show this under the input when the popover is open so position doesn't shift
  const placeHolderValue = '*'.repeat(props.initialValue.length);
  // is the dynamic input used for contact name
  const isContactName = props.fallbackString === 'Add a name';

  const [inputValue, setInputValue] = useState(props.initialValue);

  const saveValueAndCloseDialog = () => {
    // prevent extra requests when the value has not changed
    if (inputValue !== props.initialValue) {
      props.onSave(inputValue);
    }
    onClose();
  };

  // sets the old value if for example an invalid phone has been entered
  useEffect(() => {
    setInputValue(props.initialValue);
  }, [error]);

  return (
    <Popover open={isOpen}>
      <PopoverTrigger asChild>
        {isContactName ? (
          <Button
            variant="transparentWhite"
            css={{
              color: isOpen ? '#FFFFFF' : inputValue ? '#000000' : '#879095',
              minWidth: '170px',
              maxWidth: '280px',
              fontSize: '24px',
              fontWeight: 500,
              justifyContent: 'center',
              p: 0,
              mt: '15px',
            }}
            onClick={onOpen}
          >
            {isOpen
              ? placeHolderValue.slice(0, 20)
              : inputValue.slice(0, 20) || props.fallbackString}
          </Button>
        ) : (
          <Button
            variant="transparentWhite"
            css={{
              color: isOpen ? '#FFFFFF' : inputValue ? '#000000' : '#879095',
              minWidth: '200px',
              maxWidth: '220px',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              pl: 22,
              fontWeight: 400,
              justifyContent: 'flex-start',
            }}
            onClick={onOpen}
          >
            {isOpen
              ? placeHolderValue.slice(0, 25)
              : inputValue.slice(0, 25) || props.fallbackString}
          </Button>
        )}
      </PopoverTrigger>
      <PopoverContent
        align={'center'}
        side="right"
        onMouseOut={() => saveValueAndCloseDialog()}
        sideOffset={isContactName ? -236 : -188}
      >
        {isContactName ? (
          <Input
            css={{
              fontSize: '24px',
              minWidth: '200px',
              maxWidth: '280px',
              textAlign: 'center',
              minHeight: '36px',
              fontWeight: 500,
            }}
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
          />
        ) : (
          <Input
            css={{ minWidth: '220px' }}
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
          />
        )}
      </PopoverContent>
    </Popover>
  );
};
