import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { HiEye, HiEyeOff } from 'react-icons/hi';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'sonner';

import * as API from '@/shared/api/auth';
import { usePageView } from '@/shared/hooks';
import { Button, ControlGroup, Fieldset, Flex, Input, Label, VStack } from '@/shared/ui';
import i18next from '@/shared/utils/translation';

import { AuthContainer, AuthPageTitle, AuthPageWrapper } from './Login';

export const ResetPassword = () => {
  usePageView();
  const history = useHistory();
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const token = params.get('token');
  const [show, setShow] = useState(Boolean);

  const handleChangePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const handleChangePasswordConfirmation = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPasswordConfirmation(e.target.value);
  };

  const getValidationError = () => {
    if (!password) {
      return toast.error(i18next.t('password_required') as string);
    } else if (password.length < 8) {
      return toast.error(i18next.t('password_too_short') as string);
    } else if (password !== passwordConfirmation) {
      return toast.error(i18next.t('password_mismatch') as string);
    } else {
      return null;
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const error = getValidationError();

    if (error) {
      return;
    }

    API.attemptPasswordReset(token, {
      password,
      passwordConfirmation,
    })
      .then(() => history.push('/login'))
      .catch((err) => {
        console.log(err);
        if (err.code === 'ERR_NETWORK') {
          toast.error(i18next.t('network_error'));
        }
        if (err.response?.data?.error?.status === 500) {
          toast.error(i18next.t('server_error'));
        }
      });
  };

  return (
    <>
      <Helmet>
        <title>Whippy | Reset Password</title>
      </Helmet>
      <AuthPageWrapper align="center" justify="center">
        <AuthContainer>
          <AuthPageTitle
            css={{ fontSize: 18, pb: 15 }}
            data-testid="reset-password-title"
          >
            Reset Password
          </AuthPageTitle>
          <form onSubmit={handleSubmit}>
            <VStack gap={5}>
              <Fieldset>
                <Label htmlFor="password">New Password</Label>
                <ControlGroup>
                  <Input
                    id="password"
                    data-testid="password"
                    type={show ? 'text' : 'password'}
                    autoComplete="current-password"
                    value={password}
                    onChange={handleChangePassword}
                    required={true}
                  />
                  <Button
                    type="button"
                    variant="gray"
                    onClick={() => setShow(!show)}
                    aria-label="Show password"
                  >
                    {show ? <HiEye /> : <HiEyeOff />}
                  </Button>
                </ControlGroup>
              </Fieldset>
              <Fieldset>
                <Label htmlFor="password">Confirm New Password</Label>
                <ControlGroup>
                  <Input
                    id="password"
                    data-testid="confirm-password"
                    type={show ? 'text' : 'password'}
                    autoComplete="current-password"
                    value={passwordConfirmation}
                    onChange={handleChangePasswordConfirmation}
                    required={true}
                  />
                  <Button
                    type="button"
                    variant="gray"
                    onClick={() => setShow(!show)}
                    aria-label="Show password"
                  >
                    {show ? <HiEye /> : <HiEyeOff />}
                  </Button>
                </ControlGroup>
              </Fieldset>
              <Button type="submit" css={{ width: '100%' }}>
                Reset Password
              </Button>
              <Flex justify="center">
                <Link to="/login">Login to your account</Link>
              </Flex>
            </VStack>
          </form>
        </AuthContainer>
      </AuthPageWrapper>
    </>
  );
};
