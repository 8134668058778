import React from 'react';
import { HiKey, HiQuestionMarkCircle, HiTemplate } from 'react-icons/hi';
import { useLocation } from 'react-router-dom';
import { useMedia } from 'react-use';

import { useSidenavigationSettings } from '@/shared/components/navigation/context/SideNavigationContext';
import {
  SideNavigation,
  sideNavigationGroup,
} from '@/shared/components/navigation/SideNavigation';
import {
  SidebarNavigationContainer,
  SidebarNavigationWrapper,
} from '@/shared/components/navigation/SideNavigationContainer';
import { LayoutContent, MainLayout } from '@/shared/layouts';

import KeywordsState from '../keywords/context/KeywordsContext';
import AutomationTemplatesState from '../templates/context/AutomationsTemplatesContext';

export const AutomationsNavigation = () => {
  const location = useLocation();
  const { navigationSettings, toggleExpandedState } = useSidenavigationSettings();

  const isQuestions = location.pathname.includes('/automations/questions');
  const isKeywords = location.pathname.includes('/automations/keywords');
  const isTemplates = location.pathname.includes('/automations/templates');

  const automationsSideNavigationTabs: Array<sideNavigationGroup> = [
    {
      sideNavigationGroup: [
        {
          title: 'Keywords',
          image: <HiKey size={18} />,
          isActive: isKeywords,
          link: '/automations/keywords',
        },
        {
          title: 'Question Bot',
          image: <HiQuestionMarkCircle size={18} />,
          isActive: isQuestions,
          link: '/automations/questions',
        },
      ],
    },
    {
      sideNavigationGroup: [
        {
          title: 'Templates',
          image: <HiTemplate size={18} />,
          isActive: isTemplates,
          link: '/automations/templates',
        },
      ],
    },
  ];

  return (
    <SidebarNavigationContainer>
      <SidebarNavigationWrapper>
        <SideNavigation
          expanded={navigationSettings.expanded}
          header="Automations"
          sideNavigationTabs={automationsSideNavigationTabs}
          toggleExpandedState={toggleExpandedState}
        />
      </SidebarNavigationWrapper>
    </SidebarNavigationContainer>
  );
};

export const AutomationsContainer = ({ children }: { children: React.ReactNode }) => {
  const isDesktop = useMedia('(min-width: 912px)');

  return (
    <MainLayout>
      {isDesktop && <AutomationsNavigation />}
      <KeywordsState>
        <AutomationTemplatesState>
          <LayoutContent css={{ width: isDesktop ? 'calc(100% - 180px)' : '100%' }}>
            {children}
          </LayoutContent>
        </AutomationTemplatesState>
      </KeywordsState>
    </MainLayout>
  );
};
