export const default_sequence_run_object = {
  id: 'unique-id-for-sequence-run',
  label: 'Sequence Run',
  key: 'sequence_run_id',
  inserted_at: 'current-timestamp',
  updated_at: 'current-timestamp',
  custom_properties: [
    {
      default: '',
      id: 'sequence_run',
      label: 'Sequence',
      type: 'sequence',
      key: 'sequence_id',
      required: true,
      inserted_at: 'current-timestamp',
      updated_at: 'current-timestamp',
      custom_object_id: 'unique-id-for-sequence_run_sequence',
    },
  ],
};
