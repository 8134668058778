/* eslint-disable react-hooks/exhaustive-deps */
import * as Accordion from '@radix-ui/react-accordion';
import { Highlight, themes } from 'prism-react-renderer';
import React, { useEffect, useState } from 'react';
import { HiChevronDown, HiOutlineClipboardCopy, HiTrash } from 'react-icons/hi';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { toast } from 'sonner';

import { usePageView } from '@/shared/hooks';
import { PageLayout } from '@/shared/layouts/PageLayout';
import { DeveloperEndpoint, webhook_events } from '@/shared/types/developers';
import {
  Box,
  Button,
  Checkbox,
  Divider,
  Fieldset,
  Flex,
  HStack,
  IconButton,
  Input,
  Label,
  Text,
  VStack,
} from '@/shared/ui';

import DeveloperProvider, { useDeveloperContext } from '../context/DevelopersContext';
import { goCodeBlock, jsCodeBlock, pythonCodeBlock } from './code';

export function Endpoint() {
  usePageView();

  const developers = useDeveloperContext();
  const {
    getDeveloperEndpoints,
    developerState,
    createDeveloperEndpoint,
    updateDeveloperEndpoint,
    deleteDeveloperEndpoint,
    cleanDeveloperEndpointsState,
  } = developers;
  const { developerEndpoints } = developerState;

  const params = useParams<{ id: string }>();

  const [developerEndpoint, setDeveloperEndpoint] = useState<DeveloperEndpoint>({
    id: '',
    url: '',
    method: 'POST',
    event_types: [],
    active: true,
    rate_limit: 100,
  });

  // reset state on unmount
  useEffect(() => {
    return () => {
      cleanDeveloperEndpointsState();
      setDeveloperEndpoint({
        id: '',
        url: '',
        method: 'POST',
        event_types: [],
        active: true,
        rate_limit: 100,
      });
    };
  }, []);

  // get the developer endpoint on mount or when the id changes
  useEffect(() => {
    getDeveloperEndpoints(params.id);
  }, [params.id]);

  // set the developer endpoint when the developer endpoints are fetched
  useEffect(() => {
    if (developerEndpoints.length > 0 && developerEndpoints[0] !== null) {
      setDeveloperEndpoint(developerEndpoints[0]);
    }
  }, [developerEndpoints]);

  // get the total number of events
  const totalEvents = webhook_events.reduce((sum, event) => sum + event.events.length, 0);
  // check if all events are checked
  const allEventsChecked = developerEndpoint?.event_types?.length === totalEvents;

  // update the selected events
  const updateSelectedEvents = (events: Array<string>) => {
    setDeveloperEndpoint({
      ...developerEndpoint,
      event_types: events,
    });
  };

  // select all events
  const selectAllEvents = () => {
    if (allEventsChecked) {
      setDeveloperEndpoint({ ...developerEndpoint, event_types: [] });
    } else {
      setDeveloperEndpoint({
        ...developerEndpoint,
        event_types: webhook_events
          .map((event) => event.events.map((e) => e.event))
          .flat(),
      });
    }
  };

  // check if url is valid, must be https
  const isValidEndpoint = (url: string): boolean => {
    try {
      const parsedUrl = new URL(url);
      return parsedUrl.protocol === 'https:';
    } catch (error) {
      return false;
    }
  };

  const validateDeveloperEndpoint = (endpoint: DeveloperEndpoint): string | boolean => {
    // check if url is valid and event types are selected
    // return different error messages for each
    if (!isValidEndpoint(endpoint.url)) {
      return 'Please enter a valid URL';
    }

    // check if event types are selected
    if (endpoint.event_types.length === 0) {
      return 'Please select at least one event type';
    }

    return true;
  };

  // handle saving the endpoint
  const handleSaveEndpoint = async () => {
    const validation = validateDeveloperEndpoint(developerEndpoint || {});
    if (validation !== true) {
      alert(validation);
      return;
    }

    // if the endpoint has an id, update it
    if (developerEndpoint?.id) {
      updateDeveloperEndpoint(developerEndpoint);
      toast.success('Endpoint Saved');
    } else {
      // otherwise create it
      createDeveloperEndpoint({
        developer_application_id: params.id,
        ...developerEndpoint,
      });
      toast.success('Endpoint Saved');
    }
  };

  const history = useHistory();

  const handleDeleteEndpoint = async () => {
    if (developerEndpoint?.id) {
      deleteDeveloperEndpoint(developerEndpoint);
      history.push(`/developer/webhooks`);
    }
  };

  const [selectedCodeBlock, setSelectedCodeBlock] = useState('javascript');

  const handleCodeBlockChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedCodeBlock(event.target.value);
  };

  return (
    <DeveloperProvider>
      <PageLayout
        breadcrumbs={[
          { title: 'Developer', path: '/developer/keys' },
          { title: 'Webhooks', path: '/developer/webhooks' },
          {
            title: `${developerEndpoint.id ? 'Update Endpoint' : 'Create Endpoint'}`,
            path: '/developer/webhooks',
          },
        ]}
        actions={
          <HStack gap="2">
            {developerEndpoint?.id && (
              <IconButton
                size="2"
                variant="outline"
                onClick={() => {
                  handleDeleteEndpoint();
                }}
              >
                <HiTrash />
              </IconButton>
            )}
            <Button variant="primary" onClick={handleSaveEndpoint}>
              Save Endpoint
            </Button>
          </HStack>
        }
      >
        <Flex
          css={{
            width: '100%',
            minHeight: '100%',
            backgroundColor: 'white',
          }}
        >
          <Flex
            direction="column"
            css={{
              width: '50%',
              p: 30,
              minHeight: '100%',
              borderRight: '1px solid #E5E5E5',
              overflowY: 'scroll',
            }}
          >
            <Fieldset>
              <Label>URL</Label>
              <Input
                placeholder="URL"
                value={developerEndpoint?.url || ''}
                onChange={(e) => {
                  setDeveloperEndpoint({ ...developerEndpoint, url: e.target.value });
                }}
              />
            </Fieldset>
            <VStack gap="3" css={{ mt: 10 }}>
              <HStack>
                <Checkbox
                  checked={developerEndpoint?.event_types?.length === totalEvents}
                  onCheckedChange={() => selectAllEvents()}
                />
                <Text>Select All Events</Text>
              </HStack>
              <EventsAccordion
                selectedEvents={developerEndpoint?.event_types}
                updateSelectedEvents={updateSelectedEvents}
              />
            </VStack>
          </Flex>
          <Flex
            css={{
              width: '50%',
              height: '100%',
              overflowY: 'auto !important',
              backgroundColor: '#f6f8fa',
              position: 'relative',
            }}
          >
            <Box
              css={{
                position: 'fixed',
                top: '160px',
                right: '30px',
              }}
            >
              <select
                value={selectedCodeBlock}
                onChange={handleCodeBlockChange}
                style={{
                  width: '200px',
                  borderColor: '#E5E5E5',
                  borderWidth: '1px',
                  fontSize: '14px',
                  borderRadius: '5px',
                  padding: '5px',
                }}
              >
                {codeBlockValues.map((codeBlock) => (
                  <option key={codeBlock.title} value={codeBlock.title}>
                    {codeBlock.title}
                  </option>
                ))}
              </select>
            </Box>
            <Code
              selectedCodeBlock={codeBlockValues.find(
                (c) => c.title === selectedCodeBlock
              )}
            />
            <Button
              variant="gray"
              css={{
                position: 'fixed',
                bottom: '10px',
                right: '30px',
              }}
              onClick={() => {
                navigator.clipboard.writeText(jsCodeBlock);
                toast.success('Code copied to clipboard');
              }}
            >
              <HiOutlineClipboardCopy />
              Copy Code
            </Button>
          </Flex>
        </Flex>
      </PageLayout>
    </DeveloperProvider>
  );
}

type EventsAccordionProps = {
  /* the currently selected events */
  selectedEvents?: Array<string>;
  /* update the selected events */
  updateSelectedEvents?: (events: Array<string>) => void;
};

// Render the accordion for each event resource
const EventsAccordion = (props: EventsAccordionProps) => {
  return (
    <Accordion.Root type="single" defaultValue="" collapsible>
      {webhook_events.map((event) => (
        <Accordion.Item value={event.resource} key={event.resource}>
          <AccordionTrigger>
            <Flex direction="column" align="start">
              <Text size="2" css={{ mb: 2, fontWeight: 600 }}>
                {event.title}
              </Text>
              <Text>{event.description}</Text>
            </Flex>
          </AccordionTrigger>
          <Divider css={{ my: 15 }} />
          <AccordionContent>
            {event.events.map((e, i) => (
              <Box
                key={i}
                css={{
                  // padding y but not last item
                  py: 5,
                  ...(i === event.events.length - 1 && { pb: 0 }),
                }}
              >
                <Flex align="start" justify="start">
                  <Checkbox
                    checked={props?.selectedEvents?.includes(e.event)}
                    onCheckedChange={(checked: boolean) => {
                      if (checked) {
                        props?.updateSelectedEvents?.([
                          ...(props?.selectedEvents || []),
                          e.event,
                        ]);
                      } else {
                        props?.updateSelectedEvents?.(
                          (props?.selectedEvents || []).filter(
                            (event) => event !== e.event
                          )
                        );
                      }
                    }}
                    css={{ mt: 5 }}
                  />
                  <VStack gap="1" css={{ ml: 10 }}>
                    <Text variant="bold">{e.event}</Text>
                    <Text>{e.description}</Text>
                  </VStack>
                </Flex>
              </Box>
            ))}
          </AccordionContent>
        </Accordion.Item>
      ))}
    </Accordion.Root>
  );
};

// Render the accordion trigger for each event resource
const AccordionTrigger = React.forwardRef<
  HTMLButtonElement,
  React.PropsWithChildren<object>
>((props, forwardedRef) => {
  const { children, ...otherProps } = props;
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Box>
      <Accordion.Header>
        <Accordion.Trigger
          {...otherProps}
          ref={forwardedRef}
          style={{ width: '100%' }}
          onClick={() => setIsOpen(!isOpen)}
        >
          <Box css={{ minWidth: '100%' }}>
            <Flex css={{ width: '100%' }} align="center" justify="between">
              {children}
              <HiChevronDown
                size={18}
                style={{
                  transform: isOpen ? 'rotate(0deg)' : 'rotate(180deg)',
                  transition: 'transform 0.2s ease-in-out',
                }}
              />
            </Flex>
          </Box>
        </Accordion.Trigger>
      </Accordion.Header>
    </Box>
  );
});

// Render the accordion content
// Which contains the checkbox for each event resource
const AccordionContent = React.forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<object>
>(({ children, ...props }, forwardedRef) => (
  <Accordion.Content {...props} ref={forwardedRef} style={{ marginBottom: 15 }}>
    <div>{children}</div>
  </Accordion.Content>
));

type CodeProps = {
  /* the currently selected code block */
  selectedCodeBlock?: {
    title: string;
    value: string;
  };
};

// Render the example code block
export const Code = (props: CodeProps) => {
  const selectedCodeBlock = props?.selectedCodeBlock?.title || 'Node.js';
  const codeBlock =
    codeBlockValues.find((codeBlock) => codeBlock.title === selectedCodeBlock)?.value ||
    '';
  return (
    <Highlight theme={themes.github} code={codeBlock} language="js">
      {({ style, tokens, getLineProps, getTokenProps }) => (
        <pre style={style}>
          {tokens.map((line, i) => (
            <div key={i} {...getLineProps({ line })}>
              <span
                style={{
                  paddingLeft: '10px',
                  fontFamily: 'monospace',
                  display: 'inline-block',
                  width: '30px', // adjust this value based on your needs
                }}
              >
                {i + 1}
              </span>
              {line.map((token, key) => (
                <span key={key} {...getTokenProps({ token })} />
              ))}
            </div>
          ))}
        </pre>
      )}
    </Highlight>
  );
};

const codeBlockValues = [
  {
    title: 'Node.js',
    value: jsCodeBlock,
  },
  {
    title: 'Go',
    value: goCodeBlock,
  },
  {
    title: 'Python',
    value: pythonCodeBlock,
  },
];
