import { useFlags } from 'launchdarkly-react-client-sdk';
import React from 'react';
import { HiSearch, HiSpeakerphone, HiTemplate } from 'react-icons/hi';
import { useRouteMatch } from 'react-router-dom';
import { useMedia } from 'react-use';

import { useSidenavigationSettings } from '@/shared/components/navigation/context/SideNavigationContext';
import {
  SideNavigation,
  sideNavigationGroup,
} from '@/shared/components/navigation/SideNavigation';
import {
  SidebarNavigationContainer,
  SidebarNavigationWrapper,
} from '@/shared/components/navigation/SideNavigationContainer';
import { LayoutContent, MainLayout } from '@/shared/layouts';

import SequenceState from '../context/SequenceContext';

export function SequenceNavigation() {
  const isSequences = useRouteMatch('/sequences')?.isExact;
  const isGlobalTemplates = useRouteMatch('/sequences/templates/browse')?.isExact;
  const isMyTemplates = useRouteMatch('/sequences/templates')?.isExact;

  const { browseSequenceTemplates } = useFlags();
  const { navigationSettings, toggleExpandedState } = useSidenavigationSettings();

  const sequenceSideNavigationTabs: Array<sideNavigationGroup> = [
    {
      sideNavigationGroup: [
        {
          title: 'All Sequences',
          image: <HiSpeakerphone size={18} />,
          isActive: !!isSequences,
          link: '/sequences',
        },
      ],
    },
    {
      sideNavigationGroup: [
        {
          title: 'My Templates',
          image: <HiTemplate size={18} />,
          isActive: !!isMyTemplates,
          link: '/sequences/templates',
        },
        {
          hidden: !browseSequenceTemplates,
          title: 'Browse Templates',
          image: <HiSearch size={18} />,
          isActive: !!isGlobalTemplates,
          link: '/sequences/templates/browse',
        },
      ],
    },
  ];

  return (
    <SidebarNavigationContainer>
      <SidebarNavigationWrapper>
        <SideNavigation
          expanded={navigationSettings.expanded}
          header={<SequenceNavigationHeader />}
          sideNavigationTabs={sequenceSideNavigationTabs}
          toggleExpandedState={toggleExpandedState}
        />
      </SidebarNavigationWrapper>
    </SidebarNavigationContainer>
  );
}

function SequenceNavigationHeader() {
  return (
    <>
      <div>Sequences</div>
    </>
  );
}

export const SequencesContainer = ({ children }: { children: React.ReactNode }) => {
  const isDesktop = useMedia('(min-width: 912px)');
  return (
    <SequenceState>
      <MainLayout>
        {isDesktop && <SequenceNavigation />}
        <LayoutContent>{children}</LayoutContent>
      </MainLayout>
    </SequenceState>
  );
};
