import React from 'react';
import { Helmet } from 'react-helmet-async';

import { usePageView } from '@/shared/hooks';
import { SettingsLayout } from '@/shared/layouts';

import WebchatView from './WebchatView';

export function Webchat() {
  usePageView();

  return (
    <>
      <Helmet>
        <title>Whippy | Webchat</title>
      </Helmet>
      <SettingsLayout
        breadcrumbs={[
          { title: 'Settings', path: '/settings/webchat' },
          { title: 'Webchat', path: `/settings/webchat` },
        ]}
      >
        <WebchatView />
      </SettingsLayout>
    </>
  );
}
