/* eslint-disable react-hooks/exhaustive-deps */
import { ErrorBoundary } from '@sentry/react';
import React, { useEffect, useState } from 'react';
import { HiMinus, HiPlus } from 'react-icons/hi';

import { Audience } from '@/shared/types/audience';
import { AccordionValue, Campaign } from '@/shared/types/campaigns';
import {
  Box,
  ControlGroup,
  Fieldset,
  Flex,
  HStack,
  IconButton,
  Input,
  Label,
  VStack,
} from '@/shared/ui';
import { Switch, SwitchThumb } from '@/shared/ui/Switch';

import { AudienceSelection, AudienceState } from '../../audience/AudienceSelection';
import { Description } from '../../create';
import { CampaignAccordion } from '../../create/CampaignAccordion';
import { pre_selected_audience_initial_state } from '../../quick';
import { CampaignErrorBoundaryFallback } from './CampaignErrorBoundaryFallback';

type ViewCampaignAudienceProps = {
  /* campaign object */
  current?: Campaign;
  /* accordion state */
  accordion: AccordionValue;
  /* accordion setter */
  setAccordion: React.Dispatch<React.SetStateAction<AccordionValue>>;
};

export const ViewCampaignAudience = (props: ViewCampaignAudienceProps): JSX.Element => {
  const { current, accordion, setAccordion } = props;

  // contacts to message audience
  const [includeAudience, setIncludeAudience] = useState<AudienceState>({
    contacts: [],
    uploads: [],
    groups: [],
    tags: [],
    manuallyAddedContacts: [],
    wholeCampaignTab: {},
  });

  // contacts to exclude audience
  const [excludeAudience, setExcludeAudience] = useState<AudienceState>({
    contacts: [],
    uploads: [],
    groups: [],
    tags: [],
    manuallyAddedContacts: [],
    wholeCampaignTab: {},
  });

  // should we exclude contacts with open conversation from the campaign?
  const excludeContactsWithOpenConversation =
    current?.excluded_contacts?.exclude_contacts_with_open_conversations || false;

  // should we exclude contacts who got a campaign recently?
  const excludeContactsWithOtherCampaigns =
    current &&
    current.excluded_contacts?.time_since_last_campaign &&
    current.excluded_contacts.time_since_last_campaign > 0
      ? true
      : false;

  // how many days if excludeContactsWithOtherCampaigns is true
  const [excludeCampaignSince, setExcludeCampaignSince] = useState<number>(
    current?.excluded_contacts?.time_since_last_campaign || 0
  );

  // preselected audience from saved campaign object
  const [includePreSelectAudience, setIncludePreSelectAudience] = useState<Audience>(
    current?.audience || pre_selected_audience_initial_state || {}
  );

  // preselected excluded audience from saved campaign object
  const [excludePreSelectAudience, setExcludePreSelectAudience] = useState(
    current?.excluded_contacts
      ? current?.excluded_contacts
      : pre_selected_audience_initial_state
  );

  useEffect(() => {
    // merge pre_selected_audience_initial_state with current.audience
    if (current?.audience) {
      setIncludePreSelectAudience({
        ...pre_selected_audience_initial_state,
        ...current.audience,
      });
    }
  }, []);

  useEffect(() => {
    // merge pre_selected_audience_initial_state with current.excluded_contacts
    if (current?.excluded_contacts) {
      setExcludePreSelectAudience({
        ...pre_selected_audience_initial_state,
        ...current.excluded_contacts,
      });
    }
  }, []);

  // returns true if any of the excludeAudience keys have a value
  const hasExcludeAudience = () => {
    const audience = excludeAudience;
    return Object.keys(audience).some(
      (key) => (key !== 'location' && audience[key as keyof typeof audience]) || false
    );
  };

  return (
    <CampaignAccordion
      index={2}
      title="Audience"
      description="Audience who received this campaign"
      currentAccordionValue={accordion}
      itemValue={AccordionValue.SELECT_AUDIENCE}
      isError={false}
      setItemValue={setAccordion}
      buttonCopy="View"
      isValid={true}
    >
      <ErrorBoundary
        fallback={<CampaignErrorBoundaryFallback />}
        beforeCapture={(scope) => {
          scope.setTag('Campaign', 'CampaignErrorBoundary.ViewCampaignAudience');
        }}
        showDialog={false}
      >
        <VStack gap="2" data-testid="campaign-audience-content">
          <Fieldset>
            <Label>Selected Audience</Label>
            <Box css={{ p: 0, m: 0 }}>
              <AudienceSelection
                locationId={current?.audience?.location || ''}
                selectedAudience={includeAudience}
                setSelectedAudience={setIncludeAudience}
                isInbox={false}
                isContactsPage={false}
                isGroupsPage={false}
                isUploadsPage={false}
                isCampaignsEditPage={false}
                isCampaignsPage={false}
                preSelectedAudience={includePreSelectAudience}
                setPreSelectedAudience={setIncludePreSelectAudience}
                clearStatesOnUnmount={false}
                disabled={true}
              />
            </Box>
          </Fieldset>
          {current?.excluded_contacts && hasExcludeAudience() && (
            <Fieldset>
              <Label>Excluded Audience</Label>
              <Box>
                <AudienceSelection
                  locationId={current?.audience?.location || ''}
                  selectedAudience={excludeAudience}
                  setSelectedAudience={setExcludeAudience}
                  isInbox={false}
                  isContactsPage={false}
                  isGroupsPage={false}
                  isUploadsPage={false}
                  isCampaignsEditPage={false}
                  isCampaignsPage={false}
                  preSelectedAudience={excludePreSelectAudience}
                  setPreSelectedAudience={setExcludePreSelectAudience}
                  clearStatesOnUnmount={false}
                  disabled={true}
                />
              </Box>
            </Fieldset>
          )}
          {excludeContactsWithOpenConversation && (
            <Fieldset>
              <Flex align="center" justify="between">
                <Flex direction="column">
                  <Label>
                    Exclude Contacts with Open Conversations in any Channel (regardless of
                    access)
                  </Label>
                  <Description>
                    Exclude contacts that have an open conversation with any phone number
                    in your organization regardless of your access.
                  </Description>
                </Flex>
                <Box css={{ mt: 5 }}>
                  <Switch
                    checked={excludeContactsWithOpenConversation}
                    onCheckedChange={() => console.log('checked')}
                    disabled={true}
                  >
                    <SwitchThumb />
                  </Switch>
                </Box>
              </Flex>
            </Fieldset>
          )}
          {excludeContactsWithOtherCampaigns && (
            <Fieldset>
              <Flex align="center" justify="between">
                <Flex direction="column">
                  <Label>Exclude Contacts who recently received another Campaign</Label>
                  <Description>
                    Excludes contacts from this campaign that have recently received
                    another campaign.
                  </Description>
                </Flex>
              </Flex>
              {excludeContactsWithOtherCampaigns && (
                <Box
                  css={{
                    mt: 10,
                    p: 16,
                    backgroundColor: '#F6F6F6',
                    borderRadius: 4,
                  }}
                >
                  <HStack>
                    <ControlGroup>
                      <IconButton
                        size={2}
                        disabled={true}
                        css={{ backgroundColor: 'white' }}
                        onClick={() =>
                          excludeCampaignSince > 0
                            ? setExcludeCampaignSince(excludeCampaignSince - 1)
                            : setExcludeCampaignSince(excludeCampaignSince)
                        }
                      >
                        <HiMinus />
                      </IconButton>
                      <Input
                        type="number"
                        disabled={true}
                        value={excludeCampaignSince}
                        onChange={(e) =>
                          setExcludeCampaignSince(
                            Math.max(Math.round(Number(e.target.value)), 0)
                          )
                        }
                        css={{ textAlign: 'center', width: 50 }}
                      />
                      <IconButton
                        size={2}
                        disabled={true}
                        css={{ backgroundColor: 'white' }}
                        onClick={() => setExcludeCampaignSince(excludeCampaignSince + 1)}
                      >
                        <HiPlus />
                      </IconButton>
                    </ControlGroup>
                    <Box css={{ fontSize: 13 }}>days</Box>
                  </HStack>
                </Box>
              )}
            </Fieldset>
          )}
        </VStack>
      </ErrorBoundary>
    </CampaignAccordion>
  );
};
