import dayjs from 'dayjs';

import { ColumnType, FilterConfig, Resource, SortConfig } from '@/shared/types/filter';

export const contactsFiltersConfig: FilterConfig<
  Resource.Contact,
  | ColumnType.Name
  | ColumnType.Email
  | ColumnType.Phone
  | ColumnType.Source
  | ColumnType.Blocked
  | ColumnType.SMSOptIn
  | ColumnType.EmailOptIn
  | ColumnType.OptOutOfAll
  | ColumnType.InsertedAt
  | ColumnType.UpdatedAt
> = {
  label: 'Filter',
  columnOptions: [
    {
      label: 'Contact Name',
      column: ColumnType.Name,
      resource: Resource.Contact,
      operator: null,
      type: 'text',
      value: '',
    },
    {
      label: 'Contact Email',
      column: ColumnType.Email,
      resource: Resource.Contact,
      operator: null,
      type: 'text',
      value: '',
    },
    {
      label: 'Contact Phone',
      column: ColumnType.Phone,
      resource: Resource.Contact,
      operator: null,
      type: 'text',
      value: '',
    },
    {
      label: 'Contact Source',
      column: ColumnType.Source,
      resource: Resource.Contact,
      operator: null,
      type: 'text',
      value: '',
    },
    {
      label: 'Inserted At',
      column: ColumnType.InsertedAt,
      resource: Resource.Contact,
      operator: '<=',
      type: 'date',
      value: dayjs(),
    },
    {
      label: 'Updated At',
      column: ColumnType.UpdatedAt,
      resource: Resource.Contact,
      operator: '<=',
      type: 'date',
      value: dayjs(),
    },
  ],
};

export const sortConfig: SortConfig<
  Resource.Contact,
  | ColumnType.UpdatedAt
  | ColumnType.InsertedAt
  | ColumnType.Name
  | ColumnType.Email
  | ColumnType.Phone
> = {
  label: 'Sort',
  columnOptions: [
    {
      label: 'Updated At',
      column: ColumnType.UpdatedAt,
      resource: Resource.Contact,
      order: 'desc',
    },
    {
      label: 'Inserted At',
      column: ColumnType.InsertedAt,
      resource: Resource.Contact,
      order: 'desc',
    },
    {
      label: 'Name',
      column: ColumnType.Name,
      resource: Resource.Contact,
      order: 'desc',
    },
    {
      label: 'Email',
      column: ColumnType.Email,
      resource: Resource.Contact,
      order: 'desc',
    },
    {
      label: 'Phone',
      column: ColumnType.Phone,
      resource: Resource.Contact,
      order: 'desc',
    },
  ],
};
