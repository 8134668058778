import { debounce } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { searchAgentsVersions } from '@/shared/api/ai/agents';
import { CombinedFilters } from '@/shared/components/filterBuilder/CombinedFilters';
import { areFiltersValid } from '@/shared/components/filterBuilder/utils/areValidFilters';
import { cleanFilters } from '@/shared/components/filterBuilder/utils/cleanFilters';
import { AgentVersion } from '@/shared/types/ai/agents';
import { FilterType, Sort } from '@/shared/types/filter';
import { Box, Flex } from '@/shared/ui';

import { AgentsTable } from '../AgentsTable';
import { created_by, default_agents_versions_object } from './config/filterConfig';
import { sortConfig } from './config/sortConfig';

export const AgentVersions = () => {
  const [versions, setVersions] = useState<AgentVersion[]>([]);
  const [loading, setLoading] = useState(true);
  const [totalCount, setTotalCount] = useState(0);
  const [offset, setOffset] = useState(0);
  const [quickSearchValue, setQuickSearchValue] = useState('');
  const [activeFilters, setActiveFilters] = useState<FilterType[]>([]);
  const [activeSort, setActiveSort] = useState<Sort[]>([
    {
      label: 'Created At',
      column: 'inserted_at',
      resource: 'agent_version',
      order: 'desc',
      id: 1,
    },
  ]);
  const limit = 10;

  const { id } = useParams<{ id: string }>();
  const history = useHistory();

  const fetchVersions = useCallback(
    async (filters: FilterType[], sort: Sort[]) => {
      setLoading(true);
      const response = await searchAgentsVersions(
        [
          {
            resource: 'agent',
            column: 'id',
            comparison: '==',
            value: id,
          },
          ...filters,
        ],
        sort,
        limit,
        offset
      );
      setVersions(response.data);
      setTotalCount(response.total);
      setLoading(false);
    },
    [id, offset]
  );

  useEffect(() => {
    fetchVersions(cleanFilters(activeFilters), activeSort);
  }, [fetchVersions]);

  const handleVersionClick = (versionId: string) => {
    history.push(`/ai/agents/${id}/versions/${versionId}`);
  };

  const updateFilters = useCallback((filter: Array<FilterType>) => {
    setActiveFilters(filter);
  }, []);

  const debouncedFetchVersions = useCallback(
    debounce(async (filter: Array<FilterType>) => {
      await fetchVersions(cleanFilters(filter), activeSort);
    }, 500),
    [activeSort]
  );

  const handleFilterChange = useCallback(
    (filter: Array<FilterType>) => {
      updateFilters(filter);
      if (areFiltersValid(cleanFilters(filter))) {
        debouncedFetchVersions(filter);
      }
    },
    [updateFilters, debouncedFetchVersions]
  );

  const handleSortChange = useCallback(
    (sort: Array<Sort>) => {
      setActiveSort(sort);
      fetchVersions(cleanFilters(activeFilters), sort);
    },
    [activeFilters]
  );

  const handleQuickSearchChange = useCallback(
    (value: string) => {
      setQuickSearchValue(value);
      const filter = {
        resource: 'agent_version',
        column: 'name',
        comparison: 'ilike',
        value: `%${value}%`,
      };
      fetchVersions(cleanFilters([filter]), activeSort);
    },
    [activeSort]
  );

  return (
    <Flex direction="column" css={{ flex: '1 1 auto', p: 30 }}>
      <Box css={{ paddingBottom: '$space$3' }}>
        <CombinedFilters
          quickSearchPlaceholder="Search Versions"
          quickSearchValue={quickSearchValue}
          setQuickSearchValue={handleQuickSearchChange}
          defaultObjects={[default_agents_versions_object, created_by]}
          customObjects={[]}
          activeFilters={activeFilters}
          setFilters={handleFilterChange}
          sortConfig={sortConfig}
          activeSort={activeSort}
          onSortUpdate={handleSortChange}
        />
      </Box>
      <AgentsTable
        data={versions}
        loading={loading}
        totalCount={totalCount}
        onCardClick={handleVersionClick}
        setOffset={setOffset}
        isVersionTable={true}
      />
    </Flex>
  );
};
