/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import spacetime from 'spacetime';
import soft from 'timezone-soft';

import { Input } from '@/shared/ui';
import { timezones, tzValueAndLabel } from '@/shared/utils/timezones';

import { SingleSelect } from '../SingleSelect';

interface TimezonePickerProps {
  /** The timezone to display as selected */
  timezone: string;
  /** The callback to call when the timezone is changed */
  setTimezone: (timezone: string) => void;
  /** if this is true the input height is reduced*/
  isSmall?: boolean;
  /** if this is true the input is disabled*/
  disabled?: boolean;
}

interface ITimezoneOption {
  value: string;
  label: string;
  abbrev?: string;
  altName?: string;
  offset: number;
}

export type ITimezone = ITimezoneOption | string;

type Abbr = {
  name: string;
  abbr?: string;
};

export const TimezonePicker = (props: TimezonePickerProps) => {
  const labelStyle = 'original';
  const getOptions = React.useMemo(() => {
    return Object.entries(timezones)
      .reduce<ITimezoneOption[]>((selectOptions, zone) => {
        const now = spacetime.now(zone[0]);
        const tz = now.timezone();
        const tzStrings = soft(zone[0]);

        const daylight: Abbr = tzStrings[0].daylight;
        const standard: Abbr = tzStrings[0].standard;

        let label = '';
        const abbr = now.isDST() ? daylight?.abbr : standard?.abbr;
        const altName = now.isDST()
          ? tzStrings[0].daylight?.name
          : tzStrings[0].standard?.name;

        const min = tz.current.offset * 60;
        const hr = `${(min / 60) ^ 0}:` + (min % 60 === 0 ? '00' : Math.abs(min % 60));
        const prefix = `(GMT${hr.includes('-') ? hr : `+${hr}`}) ${zone[1]}`;
        // removing switch, label is const and always enter the first case
        label = prefix;

        selectOptions.push({
          value: tz.name,
          label: label,
          offset: tz.current.offset,
          abbrev: abbr,
          altName: altName,
        });

        return selectOptions;
      }, [])
      .sort((a: ITimezoneOption, b: ITimezoneOption) => a.offset - b.offset);
  }, [labelStyle, timezones]);

  const findFuzzyTz = (zone: string): ITimezoneOption => {
    let currentTime = spacetime.now('GMT');
    try {
      currentTime = spacetime.now(zone);
    } catch (err) {
      return err;
    }
    return getOptions
      .filter(
        (tz: ITimezoneOption) => tz.offset === currentTime.timezone().current.offset
      )
      .map((tz: ITimezoneOption) => {
        let score = 0;
        if (
          currentTime.timezones[tz.value.toLowerCase()] &&
          !!currentTime.timezones[tz.value.toLowerCase()].dst ===
            currentTime.timezone().hasDst
        ) {
          if (
            tz.value
              .toLowerCase()
              .indexOf(currentTime.tz.substring(currentTime.tz.indexOf('/') + 1)) !== -1
          ) {
            score += 8;
          }
          if (
            tz.label
              .toLowerCase()
              .indexOf(currentTime.tz.substring(currentTime.tz.indexOf('/') + 1)) !== -1
          ) {
            score += 4;
          }
          if (
            tz.value
              .toLowerCase()
              .indexOf(currentTime.tz.substring(0, currentTime.tz.indexOf('/')))
          ) {
            score += 2;
          }
          score += 1;
        } else if (tz.value === 'GMT') {
          score += 1;
        }
        return { tz, score };
      })
      .sort((a, b) => b.score - a.score)
      .map(({ tz }) => tz)[0];
  };

  const parseTimezone = (zone: ITimezone) => {
    if (typeof zone === 'object' && zone.value && zone.label) return zone.value;
    if (typeof zone === 'string') {
      return (
        getOptions.find((tz) => tz.value === zone)?.value ||
        (zone.indexOf('/') !== -1 && findFuzzyTz(zone)?.value)
      );
    } else if (zone?.value && !zone.label) {
      return getOptions.find((tz) => tz.value === zone.value)?.value;
    }
  };

  // find the timezone label from the timezone prop which is the value
  const findTimezoneLabel = (timezoneValue: string) => {
    return (
      getOptions.find((tz) => tz.value === timezoneValue)?.label ||
      tzValueAndLabel.find((tz) => tz.value === timezoneValue)?.label
    );
  };

  const getTimezoneLabelValue = (timezone: string, disabled?: boolean) => {
    const now = spacetime.now(timezone);
    const tz = now.timezone();
    const min = tz.current.offset * 60;
    const hr = `${(min / 60) ^ 0}:` + (min % 60 === 0 ? '00' : Math.abs(min % 60));
    const label = timezone
      ? `(GMT${hr.includes('-') ? hr : `+${hr}`}) ${timezone}`
      : undefined;
    const parsedTimezone = parseTimezone(timezone);

    return disabled ? label : findTimezoneLabel(parsedTimezone || '') || '';
  };

  return !props.disabled ? (
    <SingleSelect
      dataTestId="timezone-picker"
      selectItem={parseTimezone(props.timezone) || ''}
      setSelectItem={(e) => props.setTimezone(e)}
      options={getOptions.map((timezone: ITimezoneOption) => ({
        type: `${timezone.label}`,
        value: `${timezone.value}`,
      }))}
      defaultPlaceholder={
        findTimezoneLabel(props.timezone) ||
        getTimezoneLabelValue(props.timezone, false) ||
        'Select Timezone'
      }
      isDropdown={true}
      closeOnClick={true}
      isSmall={!!props.isSmall}
      disabled={!!props.disabled}
    />
  ) : (
    <Input
      data-testId="timezone-picker"
      disabled={!!props.disabled}
      placeholder={
        findTimezoneLabel(props.timezone) ||
        getTimezoneLabelValue(props.timezone, true) ||
        props.timezone ||
        'Select Timezone'
      }
    />
  );
};
