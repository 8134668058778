/* eslint-disable react-hooks/exhaustive-deps */
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';

import { getContactsOverview } from '@/shared/api/reports';
import { Card } from '@/shared/components/reports/Card';
import { Chart } from '@/shared/components/reports/Chart';
import { DAY } from '@/shared/components/reports/constants';
import { Grid, VStack } from '@/shared/ui';

import { useReport } from '../context/ReportsContext';

export type ContactsOverviewGroupedResult = {
  /** newly subscribed count */
  newly_subscribed_count: number;
  /** newly unsubscribed count */
  newly_unsubscribed_count: number;
  /** period to display -> day, week, month, year */
  period: string;
  /** end of the period */
  period_end: string;
  /** start of the period */
  period_start: string;
};

export type ContactOverviewData = {
  /** data to display in chart */
  grouped_result: ContactsOverviewGroupedResult[];
  /** newly subscribed count */
  newly_subscribed_count: number;
  /** newly subscribed count in the previous time period */
  prev_subscribed_count: number;
  /** newly unsubscribed count */
  newly_unsubscribed_count: number;
  /** newly unsubscribed count in the previous time period */
  prev_unsubscribed_count: number;
  /** total subscribed count */
  total_subscribed_count: number;
  /** total unsubscribed count */
  total_unsubscribed_count: number;
  /** response key */
  resource: 'contact';
};

export const ContactsOverview = () => {
  const reports = useReport();
  const { dates, initialState, selectedLocations } = reports;

  const [data, setData] = useState<ContactOverviewData>();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    if (selectedLocations.locations.length > 0) {
      getData();
    }
  }, [dates.startDate, dates.endDate]);

  const getData = async () => {
    setLoading(true);
    setError(false);
    try {
      const response = await getContactsOverview(
        dates.startDate && dates.endDate
          ? {
              type: 'overview_contacts',
              start_date: dayjs(dates.startDate as string).format('YYYY-MM-DD'),
              end_date: dayjs(dates.endDate as string).format('YYYY-MM-DD'),
              granularity: DAY,
              location_ids: selectedLocations.locations,
            }
          : { ...initialState, type: 'overview_contacts' }
      );
      setData(response);
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const chart_config = [
    {
      dataKey: 'newly_subscribed_count',
      fill: '#3E54CF',
      name: 'Newly Subscribed Contacts',
    },
  ];

  return (
    <VStack gap={2}>
      <Grid columns="3" gap="4">
        <Card
          title="Total Subscribed Contacts"
          value={data?.total_subscribed_count || 0}
          tooltipContent="The total number of subscribed contacts"
          previousValue={0}
          loading={loading}
          error={error}
          errorCallback={getData}
          hidePercent={true}
        />
        <Card
          title="New Subscribed Contacts"
          value={data?.newly_subscribed_count || 0}
          tooltipContent="The total number of new subscribed contacts"
          previousValue={data?.prev_subscribed_count || 0}
          loading={loading}
          error={error}
          errorCallback={getData}
        />
        <Card
          title="New Un-subscribed Contacts"
          value={data?.total_unsubscribed_count || 0}
          tooltipContent="The total number of un-subscribed contacts"
          previousValue={data?.prev_unsubscribed_count || 0}
          loading={loading}
          error={error}
          errorCallback={getData}
        />
      </Grid>
      <Chart
        title="Subscribed Contacts"
        chartConfig={chart_config}
        data={data || []}
        loading={loading}
        error={error}
        errorCallback={getData}
      />
    </VStack>
  );
};
