import dayjs from 'dayjs';
import {
  DateFilterItem,
  DropdownFilterItem,
  Filter,
  FilterItem,
} from 'src/shared/types/filter';

import { prepareFilters } from '@/pages/data/utils/prepareFilters';
import { SearchFilters } from '@/shared/types/contacts';

/**
  Utility functions for formatting filters for the backend API:
  localhost:4000/docs/private/v2#/Sequence/searchStepContacts
  localhost:4000/docs/private/v2#/Campaign/searchCampaignContacts
 */

export const formatQuickSearchFilter = (value: string) => {
  return value.length
    ? [
        {
          column: 'name',
          comparison: 'ilike',
          resource: 'contact',
          value: `%${value}%`,
          or: [
            {
              column: 'phone',
              resource: 'contact',
              comparison: 'ilike',
              value: `%${value}%`,
            },
          ],
        },
      ]
    : [];
};

export const formatTextFilter = (filter: Filter): FilterItem => {
  const { column, operator, resource, value } = filter;

  const wildcardValue =
    operator === 'start'
      ? `${value}%`
      : operator === 'end'
        ? `%${value}`
        : operator === 'exact'
          ? `${value}`
          : `%${value}%`;

  return {
    column,
    comparison: 'ilike',
    resource,
    value: wildcardValue,
  };
};

export const formatDateFilter = (filter: Filter): DateFilterItem => {
  const { column, operator, resource, value } = filter;

  return {
    column,
    comparison: operator,
    resource,
    value: dayjs(value).format('YYYY-MM-DD'),
    cast: {
      from: 'iso_date',
      to: operator === '<=' ? 'datetime_start' : 'datetime_end',
    },
  };
};

export const formatDropdownFilter = (filter: Filter): DropdownFilterItem => {
  const { column, resource, value } = filter;

  return {
    column,
    comparison: '==',
    resource,
    value,
  };
};

export const formatFilters = (filters: Filter[]) => {
  return filters.map((filter: any) => {
    switch (filter.type) {
      case 'text':
        return formatTextFilter(filter);
      case 'date':
        return formatDateFilter(filter);
      case 'dropdown':
        return formatDropdownFilter(filter);
      default:
        return null;
    }
  });
};

/**
 * This function will conditionally determine the filter to send in connection to the selected contacts.
 * @param searchParam - The SearchParam of the current screen.
 * @param selectedIds - The list of selected contact ids, step contact ids, sequence run ids, or campaign contact ids that were manually selected.
 * @param isAllSelected - A boolean representing whether or not the user selected the "Select All" option.
 * @param idType - In some cases the list of ids we select can be something other than the id of a contact.
 * it could be a contact variant like step contact or campaign contact or it could be a sequence run.
 */
export const prepareFilter = (
  searchParam: SearchFilters,
  selectedIds: string[],
  isAllSelected: boolean,
  idType: 'contact' | 'step_contact' | 'campaign_contact' | 'sequence_run'
): FilterItem[] => {
  const resource = idType;
  const selectedContactsFilterParam: FilterItem = {
    resource,
    column: 'id',
    comparison: 'in',
    value: selectedIds,
  };

  const { filter } = prepareFilters(searchParam);
  if (!isAllSelected && selectedIds.length > 0) {
    return [selectedContactsFilterParam];
  }
  return filter;
};
