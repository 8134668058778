import { CustomObject, DefaultObject } from '@/shared/types/data';

import { ResourcePropertiesMapping } from '../FilterBuilder';
import { default_objects } from '../objects';
import { join_custom_data_object } from '../objects/join_custom_data';

export const generateAllResourcesAndProperties = (
  customObjects: CustomObject[],
  defaultObjects?: DefaultObject[]
) => {
  const allObjects = [
    ...(defaultObjects ??
      ([...default_objects, join_custom_data_object] as Array<DefaultObject>)),
    ...customObjects,
  ];

  // Dynamically generate resources from allObjects
  const resources = allObjects.map((obj) => ({ key: obj.key, label: obj.label }));

  // Generate resourcePropertiesMapping from customObjects
  const resourcePropertiesMapping = allObjects.reduce<ResourcePropertiesMapping>(
    (acc, obj) => {
      acc[obj.key] = obj.custom_properties.map(({ key, type, label, options }) => ({
        key,
        type,
        label,
        options,
      }));
      return acc;
    },
    {}
  );

  // Generate resourcePropertiesMapping from customObjects
  const resourcesAndPropertiesMapping = [...(defaultObjects ?? default_objects)].reduce<
    Array<any>
  >((acc, obj) => {
    obj.custom_properties.forEach((property) => {
      acc.push({
        value: property.id,
        column: property.key,
        resource: obj.key,
        type: property.type,
        label: `${obj.label} ${property.label}`,
        options: property.options,
      });
    });
    return acc;
  }, []);

  // Optionally, define properties for 'join_custom_data' if needed
  // For example, if 'join_custom_data' should have specific properties, add them here
  // resourcePropertiesMapping['join_custom_data'] = [{ key: 'customKey', type: 'text' }];
  return { resources, resourcePropertiesMapping, resourcesAndPropertiesMapping };
};
