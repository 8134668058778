import { Integration as IntegrationPage } from './Integration';
import { IntegrationProvider } from './IntegrationProvider';
import { Integrations as IntegrationsList } from './Integrations';

export const Integrations = () => {
  return (
    <IntegrationProvider>
      <IntegrationsList />
    </IntegrationProvider>
  );
};

export const Integration = () => {
  return (
    <IntegrationProvider>
      <IntegrationPage />
    </IntegrationProvider>
  );
};
