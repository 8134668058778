import { ErrorBoundary } from '@sentry/react';
import i18next from 'i18next';
import React from 'react';
import { HiStatusOffline } from 'react-icons/hi';
import { toast } from 'sonner';

import { Text } from '@/shared/ui';
import { styled } from '@/stitches.config';

// Fallback Component to be rendered in case of an error
const AsIsTemplatesDisplayFallBack = () => (
  <StyledAsIsAutomationTemplatesContainer>
    <HiStatusOffline color="red" />
    <Text style={{ paddingLeft: 5 }}> As Is Templates Unavailable</Text>
  </StyledAsIsAutomationTemplatesContainer>
);

type AsIsTemplatesDisplayErrorBoundariesProps = {
  feature: string;
  children: React.ReactNode;
};

export const AsIsTemplatesDisplayErrorBoundaries: React.FC<
  AsIsTemplatesDisplayErrorBoundariesProps
> = ({ feature, children }) => {
  // need to have a feature signal
  if (!feature || feature.length < 1) return null;
  // need to have children to render
  if (!children) return null;

  const localizationError = `${feature}_error`;
  const errorTag = `asIsTemplateDisplay.${feature}`;

  return (
    <ErrorBoundary
      fallback={<AsIsTemplatesDisplayFallBack />}
      beforeCapture={(scope) => {
        toast.error(
          (i18next.t(localizationError) as string) ||
            'An error occurred in As-Is Templates Display.'
        );
        scope.setTag('ErrorLocation', errorTag);
      }}
      showDialog={false}
    >
      {children}
    </ErrorBoundary>
  );
};

const StyledAsIsAutomationTemplatesContainer = styled('div', {
  backgroundColor: '#fafafa',
  padding: '16px',
  borderRadius: '5px', // optional, for a slight rounded corner effect
  alignItems: 'center',
  display: 'flex',
});
