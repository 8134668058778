import React from 'react';
import { createContext, useContext, useReducer } from 'react';

import * as API from '@/shared/api/notifications';
import { NewNotification, Notification } from '@/shared/types';

import NotificationReducer from './NotificationReducer';
import { ADD_NOTIFICATION, DELETE_NOTIFICATION, GET_NOTIFICATIONS } from './types';

export type NotificationsState = {
  notifications: Array<Notification>;
  loading: boolean;
};

export const initialNotificationsState: NotificationsState = {
  notifications: [],
  loading: true,
};

export const NotificationsContext = createContext<{
  notificationState: NotificationsState;
  addNotification: (notification: NewNotification) => Promise<void>;
  deleteNotification: (notification: Notification) => Promise<void>;
  fetchNotifications: () => Promise<void>;
}>({
  notificationState: initialNotificationsState,
  addNotification: () => Promise.resolve(),
  deleteNotification: () => Promise.resolve(),
  fetchNotifications: () => Promise.resolve(),
});

export const useNotifications = () => useContext(NotificationsContext);

const NotificationState = ({ children }: { children: React.ReactNode }) => {
  const [notificationState, dispatch] = useReducer(
    NotificationReducer,
    initialNotificationsState
  );

  // Function to get all notification subscriptions of the current user
  const fetchNotifications = async () => {
    try {
      const data = await API.fetchNotifications();

      dispatch({
        type: GET_NOTIFICATIONS,
        payload: data,
      });
    } catch (err) {
      console.log(err);
    }
  };

  // Function to add a notification subscription
  const addNotification = async (notification: NewNotification) => {
    try {
      const data = await API.createNotification(notification);

      dispatch({
        type: ADD_NOTIFICATION,
        payload: data,
      });
    } catch (err) {
      console.log(err);
    }
  };

  // Function to delete a notification subscription
  const deleteNotification = async (notification: Notification) => {
    try {
      await API.deleteNotification(notification.id);

      dispatch({
        type: DELETE_NOTIFICATION,
        payload: notification,
      });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <NotificationsContext.Provider
      value={{
        notificationState,
        addNotification,
        deleteNotification,
        fetchNotifications,
      }}
    >
      {children}
    </NotificationsContext.Provider>
  );
};

export default NotificationState;
