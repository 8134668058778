import DromoUploader, { IDeveloperField, IDeveloperSettings } from 'dromo-uploader-react';
import React from 'react';

import { useAuth } from '@/auth/context/AuthProvider';
import { useUploads } from '@/contacts/uploads/context/UploadContext';
import { formatContactName } from '@/pages/contacts/utils';
import { DROMO_API_KEY } from '@/shared/utils/config';
import {
  dromo_contact_fields,
  dromo_contact_row_hooks,
  dromo_contact_step_hooks,
  dromo_settings_large,
} from '@/shared/utils/dromo';

export const BulkOptOutContacts = ({ children }: { children: React.ReactNode }) => {
  const auth = useAuth();
  const uploads = useUploads();

  const settings = {
    version: 'v2',
    importIdentifier: 'Opt out Contacts',
    ...dromo_settings_large,
  };

  const user = {
    id: `${auth?.tokens?.user_id}`,
    name: auth?.tokens?.name || '',
    email: auth?.tokens?.email || '',
    companyId: auth.organizationId || '',
    companyName: auth.organizationName || '',
  };

  const stepHooks = dromo_contact_step_hooks;

  const rowHooks = dromo_contact_row_hooks;

  const onResults = async (data: any) => {
    // filter or format names that are just spaces
    const dataWithFormattedNames = data.map((contact: any) => {
      return {
        ...contact,
        name: formatContactName(contact.name),
      };
    });

    // upload contacts
    // use uploads.bulkContactsOptOut(dataWithFormattedNames);
    // if you want to opt out contacts directly (not via an Import)
    await uploads.bulkContactsOptOutImport(dataWithFormattedNames);
  };

  return (
    <DromoUploader
      licenseKey={DROMO_API_KEY}
      settings={settings as IDeveloperSettings}
      fields={dromo_contact_fields as IDeveloperField[]}
      user={user}
      rowHooks={rowHooks}
      stepHooks={stepHooks}
      onResults={(data) => onResults(data)}
    >
      {children}
    </DromoUploader>
  );
};
