/* eslint-disable react-hooks/exhaustive-deps */
import { captureException } from '@sentry/react';
import { styled } from '@stitches/react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useEffect, useMemo, useRef, useState } from 'react';
import { HiOutlineClock } from 'react-icons/hi';
import { toast } from 'sonner';

import { TypingContainer } from '@/shared/components/editor/Styles';
import { MessageEditorV2 } from '@/shared/components/editor/v2';
import { Attachments } from '@/shared/components/editor/v2/constants';
import { useFocusedContext } from '@/shared/components/editor/v2/context/FocusedContext';
import { EditorErrorBoundary } from '@/shared/components/editor/v2/ErrorBoundaries';
import { ScheduleParams } from '@/shared/components/editor/v2/ScheduleMessage';
import {
  DraftMessage,
  isNumericMention,
  mentionNameToId,
  numericMentions,
} from '@/shared/components/editor/v2/utils';
import { Signature } from '@/shared/types';
import {
  ConversationEmailMetadataType,
  ConversationMessageType,
  ConversationPanelTypes,
  ConversationScheduledMessageType,
} from '@/shared/types/conversations';
import { User } from '@/shared/types/users';
import { Box, Button, Flex, HStack, Text } from '@/shared/ui';

import { useAuth } from '../../../auth/context/AuthProvider';
import { useSocket } from '../../../auth/context/socket/SocketProvider';
import { useChannels } from '../../../settings/organization/channels/context/ChannelContext';
import { useSignatures } from '../../../settings/organization/signatures/context/SignaturesContext';
import { useUsers } from '../../../settings/organization/users/context/UserContext';
import { useUserPreferences } from '../../../settings/user/preferences/context/PreferencesContext';
import { useConversation } from '../../context/ConversationContext';
import { ConversationScheduledMessage } from './ConversationScheduledMessage';

export const ConversationEditor = () => {
  // conversation context
  const {
    conversationState,
    addMessage,
    addNote,
    toggleForwardState,
    addTypingUser,
    removeTypingUser,
    deleteScheduledMessage,
    sendScheduledMessageNow,
    setConversationPanel,
    toggleConversationPanel,
  } = useConversation();
  // Forwarding state from conversation context can be set from another component that uses the Conversation State
  // If there are Forwarding Params set, they will be rendered below; this can be done using toggleForwardState
  const { isForwarded, forwardingParams, current } = conversationState;
  // Setting the initial message state. If a message is being forwarded, use the forwarded message, else start with an empty string
  const [message, setMessage] = useState(isForwarded ? forwardingParams?.message : '');
  // Setting the initial attachments state. If a message is being forwarded, use the forwarded attachments, else start with an empty array
  const [attachments, setAttachments] = useState<Attachments>(
    isForwarded
      ? { attachment_urls: forwardingParams?.attachment_urls }
      : { attachment_urls: [] }
  );
  // State to track if attachments are being loaded
  const [attachmentLoading, setAttachmentLoading] = useState<boolean>(false);
  // Setting the initial signature state. If a message is being forwarded, use the forwarded signature, else start with null
  const [signature, setSignature] = useState<Signature | null>(
    isForwarded ? forwardingParams?.signature || null : null
  );
  const [conversationId, setConversationId] = useState<string | null>(null);

  // Auth Context
  const authContext = useAuth();
  const { tokens } = authContext;
  const userId = tokens?.user_id || 0;

  // Channels Context
  const { getChannelById } = useChannels();

  // Users Context
  const { getUsers, userState } = useUsers();
  const { users } = userState;

  useMemo(() => {
    // Load in users for user tagging for @mentions
    // Only load if users are not already loaded
    if (!users.length) {
      getUsers({});
    }
  }, []);

  // Socket Context
  const { socket } = useSocket();

  // User Preferences Context
  const { preferences } = useUserPreferences();

  // Focused Context
  const { isNote } = useFocusedContext();

  // Signature Context
  const signaturesContext = useSignatures();
  const { signaturesState } = signaturesContext;
  const { defaultUserSignatureId, allSignatures } = signaturesState;

  const { conversationDraftMessagesFlag } = useFlags();

  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout>();

  useEffect(() => {
    if (current && current.id !== conversationId) {
      // If buttonAction is present, do not auto focus the textarea as we are using the message editor in a different context
      if (textareaRef?.current) textareaRef?.current.focus();

      // If message is forwarded, don't load draft as the message should come from the forwarded message and forwarded attachments
      // By resetting the forward state here, we ensure the state is only used once in this conversation and not reused in other conversations eg in contacts drawer
      if (isForwarded)
        return toggleForwardState(false, {
          message: '',
          attachment_urls: [],
          signature: null,
        });

      if (conversationDraftMessagesFlag) {
        // Load conversation (message and attachments) from draft
        // Get Draft must be called before saveDraft to ensure the draft is not overwritten with empty state
        const draft: DraftMessage = getDraft();
        setMessage(draft.text);
        if (!setAttachments) return;
        setAttachments({ attachment_urls: draft.attachmentUrls });
      } else {
        // Reset message and attachments state when the conversation changes
        setMessage('');
        if (setAttachments) setAttachments({ attachment_urls: [] });
      }

      // Reset default user signature when conversation changes
      getAndSetUserDefaultSignature();

      // Update conversationId state
      setConversationId(current?.id);
    }
  }, [current?.id]);

  useEffect(() => {
    // Check if other users typing & add to typing list if typing
    handleTypingPresence(message);
    // If draft disabled for instance of Message Editor or feature flag is disabled, do not save draft
    if (!conversationDraftMessagesFlag) return;
    // Save draft on every change, also this must be below getDraft to ensure the draft is not overwritten on initial load
    saveDraft();
  }, [message, attachments]);

  const getAndSetUserDefaultSignature = () => {
    if (defaultUserSignatureId) {
      const defaultSignature = allSignatures.find((signature: Signature | null) => {
        return signature?.id == defaultUserSignatureId;
      });
      if (setSignature && defaultSignature) {
        setSignature(defaultSignature);
      }
    } else {
      if (setSignature) setSignature(null);
    }
  };

  const getDraft = (): DraftMessage => {
    const draftData = localStorage.getItem(`conversations/${current?.id}`);
    if (draftData) {
      const draft: DraftMessage = JSON.parse(draftData);
      if (draft.userId === userId) return draft;
    }

    return {
      conversationId: current?.id || '',
      text: '',
      attachmentUrls: [],
      isDraft: false,
      userId,
    };
  };

  const saveDraft = (): void => {
    const draft: DraftMessage = {
      conversationId: current?.id || '',
      text: message,
      attachmentUrls: attachments?.attachment_urls || [],
      isDraft: true,
      userId,
      createdAt: new Date().toISOString(),
    };
    localStorage.setItem(`conversations/${current?.id}`, JSON.stringify(draft));
  };

  // The type of location dictates the type of message that can be sent
  const conversation_location = getChannelById(current?.location_id || '');
  const conversation_location_type = conversation_location?.type;

  // Manipulate messages to get the last email thread id for email conversations
  // Filter out only the message types
  const messagesOnly: ConversationMessageType[] =
    current?.conversationItemsPage.conversationItems?.filter(
      (item): item is ConversationMessageType => !('event' in item)
    ) || [];

  // sort messages by inserted_at date, so we can get the last email thread id
  const sorted_messages = messagesOnly?.sort((a, b) => {
    return new Date(b?.inserted_at).getTime() - new Date(a?.inserted_at).getTime();
  });

  // We need this to keep email threads together
  const last_email_thread_id = sorted_messages[0]?.provider_id || '';
  const subject = sorted_messages[0]?.email_metadata?.subject || '';

  type SmsMessagePayload = {
    body: string;
    attachment_urls: string[];
    translated_body: string;
  };

  type EmailMessagePayload = {
    body: string;
    attachment_urls: string[];
    translated_body: string;
    email_metadata: Partial<ConversationEmailMetadataType>;
  };

  const handleSend = (buttonPressed = false) => {
    try {
      const isFocusedOrButtonPressed =
        textareaRef.current?.contains(document.activeElement) || buttonPressed;

      // If the user is not focused on the textarea and
      // the message body or undefined, or if there are no attachments
      // then do not send the message and show a toast error
      if (
        (message == undefined || message === '') &&
        attachments?.attachment_urls &&
        attachments?.attachment_urls?.length < 1 &&
        isFocusedOrButtonPressed
      ) {
        return toast.error('Message cannot be empty');
      }

      let payload: SmsMessagePayload = {
        body: message,
        attachment_urls: attachments?.attachment_urls || [],
        translated_body: '',
      };

      if (signature) {
        const updatedMessage = `${payload.body}\n\n${signature.body}`;
        payload = {
          ...payload,
          body: updatedMessage,
        };
      }

      if (isNote) {
        sendNote(payload);
      } else if (conversation_location_type === 'phone') {
        sendMessage(payload);
      } else if (conversation_location_type === 'email') {
        const emailPayload: EmailMessagePayload = {
          ...payload,
          email_metadata: {
            subject: subject,
            in_reply_to: last_email_thread_id,
          },
        };
        sendMessage(emailPayload);
      } else {
        // fallback to sending as SMS in case of unknown location type
        sendMessage(payload);
      }

      // Focus textarea after sending message/note
      textareaRef.current?.focus();
      // Reset message
      toggleForwardState(false, { message: '', attachment_urls: [], signature: null });
      setMessage('');
      if (setAttachments) setAttachments({ attachment_urls: [] });
    } catch (error) {
      captureException(error, {
        tags: {
          action: 'send_message',
          conversationId: current?.id,
          feature: 'message_editor',
        },
      });
      console.log(error);
      toast.error(`Could not send message - ${error}`);
    }
  };

  const sendNote = (notePayload: SmsMessagePayload) => {
    // Pull the @mentions and convert to @id and send as note
    const userMentions = users.map((c: User) => `@${c.name}`);
    // Join mention patterns
    const mentionRegexPattern = [...userMentions, ...numericMentions].join('|');
    const mentionRegex = new RegExp(mentionRegexPattern, 'g');

    const mentions = notePayload.body.match(mentionRegex);
    if (mentions) {
      mentions.forEach((mention) => {
        let id;
        // Extract ID from mention (@name or @{{id}})
        if (isNumericMention.test(mention)) id = mention.slice(3, -2);
        else id = mentionNameToId(users, mention.slice(1));
        notePayload.body = notePayload.body.replace(mention, `@{{${id}}}`);
      });
    }
    // Send note via Web Socket
    addNote(notePayload);
  };

  const sendMessage = (messagePayload: SmsMessagePayload) => {
    // Send message via Web Socket
    addMessage(messagePayload);
  };

  // filter out scheduled messages that are not for this conversation
  // only keep one if there are multiple with the same job_id
  const scheduledMessages = current?.scheduled_messages
    ?.filter((scheduled_message: ConversationScheduledMessageType) => {
      return scheduled_message.conversation_id === current.id;
    })
    .reduce(
      (
        acc: Array<ConversationScheduledMessageType>,
        scheduled_message: ConversationScheduledMessageType
      ) => {
        if (acc.find((m) => m.job_id === scheduled_message.job_id)) {
          return acc;
        }
        return [...acc, scheduled_message];
      },
      []
    );

  // Presence indication
  const handleTypingPresence = (messageBody: string) => {
    const username =
      authContext?.tokens?.name || authContext?.tokens?.email || 'Another user';
    // clearing the current timeout every time the message changes
    clearTimeout(Number(typingTimeout));

    // removing the user from the typing list as soon as the input is empty
    // instead of waiting for the setTimeout function to execute
    if (messageBody === '') {
      return removeTypingUser();
    }

    if (current) {
      if (!current.typing?.includes(username)) {
        // only adding the user to the typing list
        // if they are not already in it
        addTypingUser();
      }

      let timeoutID: null | ReturnType<typeof setTimeout> = null;

      // resetting the timeout every time the message changes
      timeoutID = setTimeout(() => {
        removeTypingUser();
      }, 1000);

      setTypingTimeout(timeoutID);
    }
  };

  // Presence indication
  const typingUsers = () => {
    // removing the current user's name from the typing list
    const currentUserName = authContext?.tokens?.name || authContext?.tokens?.email || '';
    const typingUsersList = current?.typing?.filter((n: string) => n !== currentUserName);

    const format = (names: Array<string>) => {
      return names.reduce(
        (a, b, i, array) => a + (i < array.length - 1 ? ', ' : ' and ') + b
      );
    };

    // composing sentence based on the filtered list length
    switch (typingUsersList?.length) {
      case undefined:
        return null;
      case 0:
        return null;
      case 1:
        return `${typingUsersList[0]} is typing...`;
      case 2:
      case 3:
        return `${format(typingUsersList)} are typing...`;
      default:
        return 'Several people are typing...';
    }
  };

  const [scheduleParams, setScheduleParams] = useState<ScheduleParams>({
    day: '',
    month: '',
    year: '',
    hour: '',
    minute: '',
    timezone: '',
  });

  const handleOpenScheduledPanel = () => {
    toggleConversationPanel(true);
    setConversationPanel(ConversationPanelTypes.SCHEDULED);
  };

  return (
    <>
      {scheduledMessages?.length ? (
        <EditorErrorBoundary feature={'show_scheduled_messages_error'}>
          <ScheduledBottomPanel>
            {scheduledMessages?.length === 1 ? (
              scheduledMessages.map(
                (scheduled_message: ConversationScheduledMessageType, i: number) => {
                  return (
                    <Box css={{ py: 10 }} key={i}>
                      <ConversationScheduledMessage
                        key={i}
                        scheduledMessage={scheduled_message}
                        deleteScheduledMessage={deleteScheduledMessage}
                        sendScheduledMessageNow={sendScheduledMessageNow}
                      />
                    </Box>
                  );
                }
              )
            ) : (
              <HStack>
                <HiOutlineClock size={16} />
                <ScheduledText>{`You have ${scheduledMessages?.length} scheduled messages.`}</ScheduledText>
                <LinkButton
                  variant="primary"
                  ghost
                  css={{ border: 'none', boxShadow: 'none' }}
                  onClick={handleOpenScheduledPanel}
                >
                  See All
                </LinkButton>
              </HStack>
            )}
          </ScheduledBottomPanel>
        </EditorErrorBoundary>
      ) : null}
      <Box css={{ zIndex: 10 }}>
        <MessageEditorV2
          // Message related props
          message={message}
          setMessage={setMessage}
          sendMessageAction={handleSend}
          textareaRef={textareaRef}
          socketConnected={socket?.connectionState() === 'open'}
          // Attachment related props
          attachments={attachments}
          setAttachments={setAttachments}
          attachmentLoading={attachmentLoading}
          setAttachmentLoading={setAttachmentLoading}
          enableAttachments={true}
          showAddAttachment={true}
          // Signature related props
          signature={signature}
          setSignature={setSignature}
          showAddSignature={true}
          // UI feature toggles
          showAddEmoji={true}
          showAddNote={true}
          showAddSchedule={true}
          showAddTemplate={true}
          showSendButton={true}
          showKeyboardSendShortcut={true}
          showShortcuts={true}
          showAddVariable={true}
          showAddTranslate={true}
          showAddReview={true}
          showCharacterCount={true}
          showAutoComplete={true}
          showSentenceGenerator={true}
          // Contact and location related props
          contact={{ name: current?.contact?.name || '' }}
          location={getChannelById(current?.location_id || '')}
          // Schedule Message props
          scheduledMessages={scheduledMessages}
          scheduleParams={scheduleParams}
          setScheduleParams={setScheduleParams}
          enableEnterToSend={true}
          channel_type={conversation_location_type}
          isInbox
        />
      </Box>
      <EditorErrorBoundary feature={'keyboard_shortcuts'}>
        <Flex justify="between" align="center" css={{ height: 40 }}>
          <Box>
            {socket?.connectionState() !== 'open' ? (
              <Text css={{ fontWeight: 500, color: '$gray11' }}>{'Connecting...'}</Text>
            ) : (
              typingUsers() && <TypingContainer>{typingUsers()}</TypingContainer>
            )}
          </Box>
          <Box>
            {preferences &&
            preferences.inbox &&
            preferences.inbox.enter_to_send === false ? (
              <Text css={{ fontWeight: 500, color: '$gray11' }}>
                {'Shift + Enter to Send'}
              </Text>
            ) : (
              <Text css={{ fontWeight: 500, color: '$gray11' }}>
                {'Shift + Enter to Skip a Line'}
              </Text>
            )}
          </Box>
        </Flex>
      </EditorErrorBoundary>
    </>
  );
};

const ScheduledBottomPanel = styled(Box, {
  padding: '8px 20px',
  backgroundColor: '#F6F6F6',
  borderTopLeftRadius: 8,
  borderTopRightRadius: 8,
  marginBottom: -5,
  zIndex: 1,
});

const ScheduledText = styled(Box, {
  fontSize: '13px',
  fontWeight: '500',
});

const LinkButton = styled(Button, {
  border: 'none',
  boxShadow: 'none',
  paddingLeft: '8px !important',
  paddingRight: '8px !important',
  '&:hover': {
    backgroundColor: 'none !important',
    boxShadow: 'none !important',
    textDecoration: 'underline',
  },
});
