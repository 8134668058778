import React, { MutableRefObject } from 'react';

import { Flex, Skeleton } from '@/shared/ui';

import {
  TemplateCardContainer,
  TemplateCardContainerBottom,
  TemplateTitle,
} from './styled';

type TemplateLoadingCardProps = {
  observerTarget?: null | MutableRefObject<null>;
};

const TemplateLoadingCard = ({ observerTarget = null }: TemplateLoadingCardProps) => {
  return (
    <TemplateCardContainer ref={observerTarget}>
      <Flex direction="column" css={{ minWidth: '100%', overflow: 'hidden' }}>
        <Skeleton css={{ height: 290, width: '100%', p: 0, m: 0 }} />
        <TemplateCardContainerBottom direction="column">
          <TemplateTitle>
            <Skeleton css={{ width: 50, height: 10, my: 7, ml: 15 }} />
          </TemplateTitle>
          <Flex css={{ alignItems: 'center', justifyContent: 'center', px: 10 }}>
            <Flex css={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
              <Skeleton variant="button" css={{ width: '95%', height: 30 }} />
            </Flex>
            <Flex css={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
              <Skeleton variant="button" css={{ width: '95%', height: 30 }} />
            </Flex>
          </Flex>
        </TemplateCardContainerBottom>
      </Flex>
    </TemplateCardContainer>
  );
};

export default TemplateLoadingCard;
