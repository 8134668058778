import { SearchFilters } from '@/shared/types/contacts';
import { FilterData } from '@/shared/types/filter';

export const prepareFilters = (params: SearchFilters): FilterData => {
  const filters = [...params.filter, ...params.searchFilter];
  if (params.defaultFilter) {
    filters.push(params.defaultFilter);
  }
  return {
    filter: params.defaultFilters ? [...filters, ...params.defaultFilters] : filters,
    extended_filter: params.extended_filter,
    sort: params.sort,
    limit: params.limit,
    offset: params.offset || 0,
  };
};
