/** ArrowIcon */
export function ArrowIcon(props: { direction: 'up' | 'right' | 'down' | 'left' }) {
  const pathMap = {
    up: [<path key="0" d="m5 12 7-7 7 7" />, <path key="1" d="M12 19V5" />],
    right: [<path key="0" d="M5 12h14" />, <path key="1" d="m12 5 7 7-7 7" />],
    down: [<path key="0" d="M12 5v14" />, <path key="1" d="m19 12-7 7-7-7" />],
    left: [<path key="0" d="m12 19-7-7 7-7" />, <path key="1" d="M19 12H5" />],
  };
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      {pathMap[props.direction]}
    </svg>
  );
}
