import React from 'react';

import { Contact } from '@/shared/types';
import { AudienceContacts, CampaignExcludeContacts } from '@/shared/types/campaigns';
import { Box, Flex, Input } from '@/shared/ui';

import {
  AudienceEmptyState,
  AudienceLoadingState,
  ContactCard,
  ContactsCount,
} from './Audience';

type ExcludedAudiencePanelProps = {
  /* calculating audience? */
  loadingAudience?: boolean;
  /** array of contacts object that will not get the campaign */
  excluded_audience?: AudienceContacts | Array<null>;
  /** excluded audience definition object */
  excluded_audience_params?: CampaignExcludeContacts | null;
};

type ExcludedContact = {
  /** contact object */
  contact: Contact;
  /** array of reasons why a contact wont be messages */
  criteria: Array<string>;
};

export const ExcludedContactsPanel = (props: ExcludedAudiencePanelProps) => {
  const { excluded_audience, loadingAudience } = props;

  // reduce object to array of contacts
  const contacts =
    (Object.values(excluded_audience || {}) as unknown as Array<ExcludedContact>) || [];

  // input search field value and filter contacts
  const [search, setSearch] = React.useState('');

  // filter by name or phone number
  // sort by name alphabetically
  const filteredContacts = contacts
    .filter((contact) => {
      return (
        (contact?.contact?.name || '').toLowerCase().includes(search.toLowerCase()) ||
        (contact?.contact?.phone || '').toLowerCase().includes(search.toLowerCase())
      );
    })
    .sort((a, b) => {
      return (a.contact?.name || '').localeCompare(b.contact?.name || '');
    })
    .map((contact) => {
      return {
        ...contact.contact,
        criteria: contact.criteria,
      };
    });

  if (loadingAudience) {
    return <AudienceLoadingState />;
  }

  if (contacts.length === 0 && !loadingAudience) {
    return (
      <AudienceEmptyState
        title={'Exclude Contacts'}
        description="Select who to exclude from your campaign"
        loom_id="40308ba501a04ce987b39f8fb2bd8691"
      />
    );
  }

  return (
    <Flex css={{ p: 20, width: '100%' }} direction="column">
      <ContactsCount>Total Contacts: {contacts.length}</ContactsCount>
      <Box css={{ width: '100%', pb: 20 }}>
        <Input
          placeholder="Filter contacts"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </Box>
      <Box>
        {filteredContacts.map((contact) => (
          <ContactCard
            key={contact.id}
            id={contact.id}
            name={contact.name}
            phone={contact?.phone || ''}
            exclusion_criteria={contact.criteria}
          />
        ))}
      </Box>
    </Flex>
  );
};
