import React from 'react';

import { Box } from '@/shared/ui';

import { ReportsContentContainer } from '../layout/ReportsContentContainer';

export const TemplatesReport = () => {
  return (
    <ReportsContentContainer title="Templates">
      <Box>Templates</Box>
    </ReportsContentContainer>
  );
};
