import { Questions } from '@/shared/types';
import { removeDuplicates } from '@/shared/utils/removeDuplicates';

import {
  CREATE_QUESTION,
  DELETE_QUESTION,
  DISABLE_QUESTION,
  EDIT_QUESTION,
  ENABLE_QUESTION,
  GET_QUESTIONS,
  SEARCH_ANSWER,
  SEARCH_QUESTION,
  SET_COUNT,
  SET_CURRENT,
  SET_LOADING,
} from './types';

type QuestionsAction =
  | { type: 'GET_QUESTIONS'; payload: any }
  | { type: 'SEARCH_QUESTION'; payload: any }
  | { type: 'SEARCH_ANSWER'; payload: any }
  | { type: 'CREATE_QUESTION'; payload: any }
  | { type: 'EDIT_QUESTION'; payload: any }
  | { type: 'DELETE_QUESTION'; payload: any }
  | { type: 'ENABLE_QUESTION'; payload: any }
  | { type: 'DISABLE_QUESTION'; payload: any }
  | { type: 'SET_CURRENT'; payload: any }
  | { type: 'SET_COUNT'; payload: any }
  | { type: 'SET_LOADING'; payload: boolean };

const QuestionsReducer = (state: any, action: QuestionsAction) => {
  switch (action.type) {
    case GET_QUESTIONS:
      return {
        ...state,
        questions: removeDuplicates([...state.questions, ...action.payload]),
        loading: false,
      };
    case SET_COUNT:
      return {
        ...state,
        count: action.payload,
      };
    case SEARCH_QUESTION:
      return {
        ...state,
        testMessages: [
          ...state.testMessages,
          { text: action.payload.text, direction: 'inbound' },
        ],
      };
    case SEARCH_ANSWER:
      return {
        ...state,
        testMessages: [
          ...state.testMessages,
          { text: action.payload.answer, direction: 'outbound' },
        ],
      };
    case CREATE_QUESTION:
      return {
        ...state,
        questions: [...state.questions, { ...action.payload, enabled: true }],
        loading: false,
      };
    case EDIT_QUESTION:
      return {
        ...state,
        questions: state.questions.map((question: Questions) => {
          return question.id === action.payload.id ? action.payload : question;
        }),
      };
    case DELETE_QUESTION:
      return {
        ...state,
        questions: state.questions.filter(
          (question: Questions) => question.id !== action.payload.id
        ),
        loading: false,
      };
    case ENABLE_QUESTION:
      return {
        ...state,
        questions: state.questions.map((question: Questions) => {
          return question.id === action.payload.id
            ? { ...action.payload, enabled: true }
            : question;
        }),
      };
    case DISABLE_QUESTION:
      return {
        ...state,
        questions: state.questions.map((question: Questions) => {
          return question.id === action.payload.id
            ? { ...action.payload, enabled: false }
            : question;
        }),
      };
    case SET_CURRENT:
      return {
        ...state,
        current: action.payload,
      };
    case SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
};

export default QuestionsReducer;
