import { api } from '../api';

export const createCampaignTemplateAssociation = (
  campaignId: string,
  templateId: string
): Promise<any> =>
  api
    .post(`/campaigns/${campaignId}/campaigns_automation_templates`, {
      automation_template_id: templateId,
    })
    .then((res) => {
      // only return the association id
      return res.data.id;
    });

export const updateCampaignTemplateAssociation = (
  campaignId: string,
  templateId: string,
  associationId: string
): Promise<any> =>
  api
    .patch(`/campaigns/${campaignId}/campaigns_automation_templates/${associationId}`, {
      automation_template_id: templateId,
    })
    .then((res) => {
      return res.data;
    });

export const deleteCampaignTemplateAssociation = (
  campaignId: string,
  associationId: string
): Promise<any> =>
  api
    .delete(`/campaigns/${campaignId}/campaigns_automation_templates/${associationId}`)
    .then((res) => {
      return res.data;
    });
