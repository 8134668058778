import { IntegrationConfiguration } from '@/shared/types/integrations';

/*
This is a helper function to take in the current configuration
as well as possibly updated values and create a new configuration from it.
 */
export function updatedConfiguration(
  currentConfig: IntegrationConfiguration | null,
  updatedValues: {
    from_number: string;
    delay_in_seconds?: number;
    outbound_body?: string;
    outbound_attachment_url?: string;
    channel_id?: string;
    sequence_id?: string;
    // required fields
    forwarding_email: string;
    api_key: string;
  }
): IntegrationConfiguration | null {
  if (!currentConfig) return null;

  const { api_key, forwarding_email, ...updatedActionParams } = updatedValues;
  const requiredParams = {
    api_key: api_key,
    forwarding_email: forwarding_email,
  };

  return {
    ...currentConfig,
    data: {
      ...currentConfig.data,
      ...requiredParams,
      action_params: updatedActionParams,
    },
  } as IntegrationConfiguration;
}

export function deSnakeCase(item: unknown): string {
  if (item == '') return 'Empty';
  if (typeof item == 'boolean') return item.toString();
  if (typeof item === 'string') return item.replace(/_/g, ' ');
  return '';
}
