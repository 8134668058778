import React, { useEffect, useRef, useState } from 'react';
import { HiPaperAirplane, HiRefresh } from 'react-icons/hi';
import { HiArrowDown } from 'react-icons/hi2';

import { StyledMessage } from '@/pages/inbox/conversation/items';
import { createChatCompletion, Message } from '@/shared/api/ai';
import { renderText } from '@/shared/components/markdown/MarkdownRenderer';
import { Box, Flex, HStack, IconButton, Input, VStack } from '@/shared/ui';

interface ChatComponentProps {
  prompt: string;
}

export const TestAgentPanel: React.FC<ChatComponentProps> = ({ prompt }) => {
  const [messages, setMessages] = useState<Message[]>([]);
  const [userInput, setUserInput] = useState('');
  const messagesEndRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (prompt) {
      setMessages([{ role: 'system', content: prompt }]);
    }
  }, [prompt]);

  const handleSendMessage = async () => {
    const newMessage = { role: 'user', content: userInput };
    const updatedMessages = [...messages, newMessage];
    setMessages(updatedMessages);
    const response = await createChatCompletion({
      model: 'gpt-4o-mini',
      options: {
        frequency_penalty: 0,
        max_tokens: 150,
        n: 1,
        presence_penalty: 0,
        temperature: 0.5,
        top_p: 1,
      },
      messages: updatedMessages,
    });
    setMessages([...updatedMessages, { role: 'assistant', content: response.response }]);
    setUserInput('');
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  return (
    <Flex
      direction="column"
      css={{
        width: '100%',
        height: '100%',
        minHeight: '100%',
        backgroundColor: 'white',
        borderColor: 'rgba(232, 232, 232, 1)',
        border: 1,
        flexGrow: 1,
      }}
    >
      <VStack
        css={{
          overflowY: 'auto',
          width: '100%',
          px: 30,
          flexGrow: 1,
        }}
      >
        {messages.filter((msg) => msg.role !== 'system').length === 0 && (
          <Flex
            css={{
              flexGrow: 1,
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <VStack align="center">
              <Box>Enter a message to test your agent</Box>
              <Box>
                <HiArrowDown />
              </Box>
            </VStack>
          </Flex>
        )}
        {messages.map(
          (msg, index) =>
            msg.role !== 'system' && (
              <Flex
                key={index}
                align="center"
                justify={msg.role === 'user' ? 'end' : 'start'}
                css={{
                  margin: '20px 0',
                }}
              >
                <StyledMessage
                  direction={msg.role === 'user' ? 'outbound_sms' : 'inbound_sms'}
                >
                  {renderText(msg.content)}
                </StyledMessage>
              </Flex>
            )
        )}
        <div ref={messagesEndRef} />
      </VStack>
      <HStack
        css={{
          padding: '10px',
          borderTop: '1px solid rgba(232, 232, 232, 1)',
        }}
      >
        <IconButton
          onClick={() => setMessages([{ role: 'system', content: prompt }])}
          variant="outline"
          size="2"
        >
          <HiRefresh />
        </IconButton>
        <Input
          placeholder="Type your message..."
          value={userInput}
          onChange={(e) => setUserInput(e.target.value)}
          onKeyPress={(e) => e.key === 'Enter' && handleSendMessage()}
        />
        <IconButton onClick={handleSendMessage} variant="send">
          <HiPaperAirplane style={{ transform: 'rotate(90deg)' }} />
        </IconButton>
      </HStack>
    </Flex>
  );
};
