import { HiQuestionMarkCircle } from 'react-icons/hi';

import {
  Box,
  Button,
  Flex,
  Skeleton,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
  VStack,
} from '@/shared/ui';
import { styled } from '@/stitches.config';

type CardProps = {
  /** title of the card */
  title: string;
  /** current value for given period */
  value: number;
  /** previous value for given period */
  previousValue: number;
  /** description of the card */
  description?: string;
  /** tooltip card information */
  tooltipContent?: string;
  /** fetching data failed */
  error?: boolean;
  /** retry request */
  errorCallback?: () => void;
  /** currently fetching */
  loading?: boolean;
  /** only for test purpose remove after */
  hidePercent?: boolean;
  /** show card outline */
  outlined?: boolean;
};

export const Card = (props: CardProps) => {
  const {
    title,
    value,
    previousValue,
    description,
    tooltipContent,
    error,
    errorCallback,
    loading,
    hidePercent,
    outlined,
  } = props;

  // calculated percentage for card to display
  const trend =
    previousValue != 0 ? ((value - previousValue) / previousValue) * 100 : 100;

  // load skeleton while waiting the dynamic data
  if (loading) {
    return (
      <CardContainer direction="column" outline={outlined || false}>
        <VStack gap="2">
          <Skeleton variant="heading" css={{ width: '50%' }} />
          <Skeleton variant="heading" />
        </VStack>
      </CardContainer>
    );
  }

  // error state
  if (error && !loading) {
    return (
      <CardContainer
        direction="column"
        align="center"
        justify="center"
        outline={outlined || false}
      >
        <VStack gap="2" css={{ textAlign: 'center' }} align="center">
          <CardTitle>Fail to Load Report</CardTitle>
          <CardDescription>An error occurred while loading this report</CardDescription>
          {errorCallback && (
            <Box css={{ width: 100 }}>
              <Button variant="gray" onClick={() => errorCallback()}>
                Try Again
              </Button>
            </Box>
          )}
        </VStack>
      </CardContainer>
    );
  }

  // if percent has more digits after comma fix to two numbers
  // if percent is whole number remove the last two 00 from the percent
  const fixedValue = value?.toFixed(2).replace(/\.00$/, '');

  // add comma to thousands separator
  function numberWithCommas(x: string) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
  //fix trend value to second number after decimal
  const fixTrendValue = (value: number) => {
    return `${Math.abs(value).toFixed(2)}%`;
  };

  const getTrendElements = () => {
    // true when value is greater than previous value
    if ((previousValue === 0 || previousValue) && value > 0 && trend > 0) {
      return {
        arrowVariation: 'up',
        trendVariation: 'green',
        trendValue: fixTrendValue(trend),
      };
      //true if previous value is greater than value
    } else if (previousValue > value) {
      return {
        arrowVariation: 'down',
        trendVariation: 'red',
        trendValue: fixTrendValue(trend),
      };
      // true if previous value and value are equal
    } else if (previousValue === value) {
      return {
        arrowVariation: 'hidden',
        trendVariation: 'gray',
        trendValue: '0%',
      };
      //true if value or previous value are incorrect
    } else {
      return {
        arrowVariation: 'hidden',
        trendVariation: 'gray',
        trendValue: '-',
      };
    }
  };

  const RenderTrendElements = () => {
    const trendContent = getTrendElements();
    return (
      <>
        <Arrow variant={trendContent.arrowVariation as any} />
        <Trend variant={trendContent.trendVariation as any}>
          {trendContent.trendValue}
        </Trend>
      </>
    );
  };

  return (
    <CardContainer direction="column" outline={outlined || false}>
      <VStack gap={2}>
        <Flex direction="row">
          <CardTitle>{title}</CardTitle>
          <Tooltip>
            <TooltipTrigger>{tooltipContent && <HiQuestionMarkCircle />}</TooltipTrigger>
            <TooltipContent>
              <Box
                css={{
                  width: 270,
                  p: 5,
                  borderRadius: 4,
                  color: '#FFFFFF',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  fontSize: 14,
                  lineHeight: '20px',
                }}
              >
                {tooltipContent}
              </Box>
            </TooltipContent>
          </Tooltip>
        </Flex>
        <Flex direction="row" align="center" justify="start" gap={1}>
          <CardData>{fixedValue ? numberWithCommas(fixedValue) : '-'}</CardData>
          {/* remove hidePercent when tested */}
          {!hidePercent ? RenderTrendElements() : null}
          {/* remove hidePercent when tested */}
          {!hidePercent && <CardDescription>from previous date range</CardDescription>}
        </Flex>
        <CardDescription>{description}</CardDescription>
      </VStack>
    </CardContainer>
  );
};

const CardContainer = styled(Flex, {
  mb: 0,
  backgroundColor: 'white',
  borderRadius: '$3',
  width: '100%',
  px: 12,

  variants: {
    outline: {
      true: {
        border: '1px solid $slate5',
        height: '132px',
        p: 24,
      },
    },
  },
});

const CardTitle = styled(Box, {
  fontSize: 18,
  fontWeight: 600,
  mr: 10,
});

const CardData = styled(Box, {
  mr: 7,
  fontSize: 30,
  fontWeight: 600,
});

const CardDescription = styled(Box, {
  pr: 10,
  fontSize: 12,
  color: '$slate11',
});

const Arrow = styled(Box, {
  width: 0,
  height: 0,

  variants: {
    variant: {
      up: {
        borderLeft: '5px solid transparent',
        borderRight: '5px solid transparent',
        borderBottom: '5px solid green',
      },
      down: {
        borderLeft: '5px solid transparent',
        borderRight: '5px solid transparent',
        borderTop: '5px solid red',
      },
      hidden: {
        display: 'none',
      },
    },
  },
});

const Trend = styled(Box, {
  color: 'green',
  fontSize: 14,
  fontWeight: 600,

  variants: {
    variant: {
      green: {
        color: 'green',
      },
      red: {
        color: 'red',
      },
      gray: {
        color: 'gray',
      },
    },
  },
});
