import React from 'react';
import { HiStar } from 'react-icons/hi';

import { Flex, Text } from '@/shared/ui';
import { styled } from '@/stitches.config';

type RatingProps = {
  rating: number;
};

export const ReviewHeader = ({ children }: any) => {
  return <Flex justify="between">{children}</Flex>;
};

export const ReviewerDateContainer = styled(Flex, {
  display: 'none',

  '@lg': {
    display: 'flex',
  },
});

export const ReviewDate = ({ children }: any) => {
  return (
    <ReviewerDateContainer align="center">
      <Text variant="gray" size={2} css={{ fontWeight: '500' }}>
        {children}
      </Text>
    </ReviewerDateContainer>
  );
};

export const ReviewerName = styled(Text, {
  mx: '$2',
  textTransform: 'capitalize',
});

export const ReviewStars = (props: RatingProps) => {
  return (
    <Flex>
      <HiStar color={props.rating >= 1 ? '#FFBF00' : 'lightgrey'} />
      <HiStar color={props.rating >= 2 ? '#FFBF00' : 'lightgrey'} />
      <HiStar color={props.rating >= 3 ? '#FFBF00' : 'lightgrey'} />
      <HiStar color={props.rating >= 4 ? '#FFBF00' : 'lightgrey'} />
      <HiStar color={props.rating === 5 ? '#FFBF00' : 'lightgrey'} />
    </Flex>
  );
};
