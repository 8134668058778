import { DeveloperActions, DeveloperActionTypes } from '@/shared/types/developers';

import { DeveloperState } from './DevelopersContext';

export const DeveloperReducer: React.Reducer<DeveloperState, DeveloperActions> = (
  state,
  action
) => {
  switch (action.type) {
    case DeveloperActionTypes.SEARCH_API_KEYS:
      return {
        ...state,
        apiKeys: action.payload.data,
        total: action.payload.total,
        loading: false,
      };
    case DeveloperActionTypes.GET_API_KEYS:
      return {
        ...state,
        apiKeys: action.payload,
        loading: false,
      };
    case DeveloperActionTypes.CREATE_API_KEY:
      return {
        ...state,
        apiKeys: [...state.apiKeys, action.payload],
      };
    case DeveloperActionTypes.ENABLE_API_KEY:
      return {
        ...state,
        apiKeys: state.apiKeys.map((apiKey) => {
          // If the apiKey is null, just return null
          if (apiKey === null) return null;

          return apiKey.id === action.payload?.id
            ? { ...apiKey, active: true, updated_by: action.payload?.updated_by }
            : apiKey;
        }),
      };
    case DeveloperActionTypes.DISABLE_API_KEY:
      return {
        ...state,
        apiKeys: state.apiKeys.map((apiKey) => {
          // If the apiKey is null, just return null
          if (apiKey === null) return null;

          return apiKey.id === action.payload?.id
            ? { ...apiKey, active: false, updated_by: action.payload?.updated_by }
            : apiKey;
        }),
      };
    case DeveloperActionTypes.DELETE_API_KEY:
      return {
        ...state,
        apiKeys: state.apiKeys.filter((apiKey) => apiKey?.id !== action.payload),
      };
    case DeveloperActionTypes.RENAME_API_KEY:
      return {
        ...state,
        apiKeys: state.apiKeys.map((apiKey) => {
          // If the apiKey is null, just return null
          if (apiKey === null) return null;
          if (apiKey?.id === action.payload?.id) {
            return {
              ...apiKey,
              name: action.payload?.name,
              updated_by: action.payload?.updated_by,
            };
          } else {
            return apiKey;
          }
        }),
      };
    case DeveloperActionTypes.SEARCH_DEVELOPER_APPLICATIONS:
      return {
        ...state,
        developerApplications: action.payload.data,
        developerApplicationsTotal: action.payload.total,
        loading: false,
      };
    case DeveloperActionTypes.GET_DEVELOPER_APPLICATIONS:
      return {
        ...state,
        developerApplications: action.payload,
        loading: false,
      };
    case DeveloperActionTypes.CREATE_DEVELOPER_APPLICATION:
      return {
        ...state,
        developerApplications: [...state.developerApplications, action.payload],
      };
    case DeveloperActionTypes.UPDATE_DEVELOPER_APPLICATION:
      return {
        ...state,
        developerApplications: state.developerApplications.map((application) => {
          // If the application is null, just return null
          if (application === null) return null;

          return application.id === action.payload?.id ? action.payload : application;
        }),
      };
    case DeveloperActionTypes.GET_DEVELOPER_ENDPOINTS:
      return {
        ...state,
        developerEndpoints: action.payload,
        loading: false,
      };
    case DeveloperActionTypes.CREATE_DEVELOPER_ENDPOINT:
      return {
        ...state,
        developerEndpoints: [action.payload],
      };
    case DeveloperActionTypes.UPDATE_DEVELOPER_ENDPOINT:
      return {
        ...state,
        developerEndpoints: state.developerEndpoints.map((endpoint) => {
          // If the endpoint is null, just return null
          if (endpoint === null) return null;

          return endpoint.id === action.payload?.id ? action.payload : endpoint;
        }),
      };
    case DeveloperActionTypes.DELETE_DEVELOPER_ENDPOINT:
      return {
        ...state,
        developerEndpoints: state.developerEndpoints.filter(
          (endpoint) => endpoint?.id !== action.payload
        ),
      };
    case DeveloperActionTypes.CLEAN_DEVELOPER_ENDPOINTS_STATE:
      return {
        ...state,
        developerEndpoints: [],
      };
    case DeveloperActionTypes.SET_CURRENT:
      return {
        ...state,
        currentApiKey: action.payload,
      };
    case DeveloperActionTypes.SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
};
