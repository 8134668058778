// register if the user is typing
export const onUserTyping = (
  /* the body of the message */
  body: string,
  /* the name of the user */
  name: string,
  /* the email of the user */
  email: string,
  current: any,
  /* remove typing indicator */
  remove: () => void,
  /* add typing indicator */
  add: () => void,
  timeout: any,
  setTypingTimeout: (x: NodeJS.Timeout | null) => void
) => {
  // user's name or use email as fallback
  const user = name || email;
  // clearing the current timeout every time the message changes
  clearTimeout(Number(timeout));

  // removing the user from the typing list as soon as the input is empty
  // instead of waiting for the setTimeout function to execute
  if (body === '') {
    return remove();
  }

  if (current) {
    if (!current.typing?.includes(user)) {
      // only adding the user to the typing list
      // if they are not already in it
      add();
    }

    let timeoutID: null | ReturnType<typeof setTimeout> = null;

    // resetting the timeout every time the message changes
    timeoutID = setTimeout(() => {
      remove();
    }, 1000);

    setTypingTimeout(timeoutID);
  }
};

// rendering typing users string
export const renderTypingUsers = (name: string, email: string, typing: Array<any>) => {
  // removing the current user's name from the typing list
  const user = name || email;
  const typingUsers = typing.filter((name: string) => name !== user);

  // composing sentence based on the filtered list length
  switch (typingUsers?.length) {
    case undefined:
      return null;
    case 0:
      return null;
    case 1:
      return `${typingUsers[0]} is typing...`;
    case 2:
    case 3:
      return `${formatTypingUsers(typingUsers)} are typing...`;
    default:
      return 'Several people are typing...';
  }
};

// format the tying users string
export const formatTypingUsers = (names: Array<any>) => {
  return names.reduce(
    (a, b, i, array) => a + (i < array.length - 1 ? ', ' : ' and ') + b
  );
};

export const languages = [
  { label: 'Afrikaans', value: 'af' },
  { label: 'Albanian', value: 'sq' },
  { label: 'Amharic', value: 'am' },
  { label: 'Arabic', value: 'ar' },
  { label: 'Armenian', value: 'hy' },
  { label: 'Azerbaijani', value: 'az' },
  { label: 'Basque', value: 'eu' },
  { label: 'Belarusian', value: 'be' },
  { label: 'Bengali', value: 'bn' },
  { label: 'Bosnian', value: 'bs' },
  { label: 'Bulgarian', value: 'bg' },
  { label: 'Catalan', value: 'ca' },
  { label: 'Cebuano', value: 'ceb' },
  { label: 'Chinese (Simplified)', value: 'zh-CN' },
  { label: 'Chinese (Traditional)', value: 'zh-TW' },
  { label: 'Corsican', value: 'co' },
  { label: 'Croatian', value: 'hr' },
  { label: 'Czech', value: 'cs' },
  { label: 'Danish', value: 'da' },
  { label: 'Dutch', value: 'nl' },
  { label: 'English', value: 'en' },
  { label: 'Esperanto', value: 'eo' },
  { label: 'Estonian', value: 'et' },
  { label: 'Finnish', value: 'fi' },
  { label: 'French', value: 'fr' },
  { label: 'Frisian', value: 'fy' },
  { label: 'Galician', value: 'gl' },
  { label: 'Georgian', value: 'ka' },
  { label: 'German', value: 'de' },
  { label: 'Greek', value: 'el' },
  { label: 'Gujarati', value: 'gu' },
  { label: 'Haitian Creole', value: 'ht' },
  { label: 'Hausa', value: 'ha' },
  { label: 'Hawaiian', value: 'haw' },
  { label: 'Hebrew', value: 'he' },
  { label: 'Hindi', value: 'hi' },
  { label: 'Hmong', value: 'hmn' },
  { label: 'Hungarian', value: 'hu' },
  { label: 'Icelandic', value: 'is' },
  { label: 'Igbo', value: 'ig' },
  { label: 'Indonesian', value: 'id' },
  { label: 'Irish', value: 'ga' },
  { label: 'Italian', value: 'it' },
  { label: 'Japanese', value: 'ja' },
  { label: 'Javanese', value: 'jv' },
  { label: 'Kannada', value: 'kn' },
  { label: 'Kazakh', value: 'kk' },
  { label: 'Khmer', value: 'km' },
  { label: 'Kinyarwanda', value: 'rw' },
  { label: 'Korean', value: 'ko' },
  { label: 'Kurdish', value: 'ku' },
  { label: 'Kyrgyz', value: 'ky' },
  { label: 'Lao', value: 'lo' },
  { label: 'Latin', value: 'la' },
  { label: 'Latvian', value: 'lv' },
  { label: 'Lithuanian', value: 'lt' },
  { label: 'Luxembourgish', value: 'lb' },
  { label: 'Macedonian', value: 'mk' },
  { label: 'Malagasy', value: 'mg' },
  { label: 'Malay', value: 'ms' },
  { label: 'Malayalam', value: 'ml' },
  { label: 'Maltese', value: 'mt' },
  { label: 'Maori', value: 'mi' },
  { label: 'Marathi', value: 'mr' },
  { label: 'Mongolian', value: 'mn' },
  { label: 'Myanmar (Burmese)', value: 'my' },
  { label: 'Nepali', value: 'ne' },
  { label: 'Norwegian', value: 'no' },
  { label: 'Nyanja (Chichewa)', value: 'ny' },
  { label: 'Odia (Oriya)', value: 'or' },
  { label: 'Pashto', value: 'ps' },
  { label: 'Persian', value: 'fa' },
  { label: 'Polish', value: 'pl' },
  { label: 'Portuguese (Portugal, Brazil)', value: 'pt' },
  { label: 'Punjabi', value: 'pa' },
  { label: 'Romanian', value: 'ro' },
  { label: 'Russian', value: 'ru' },
  { label: 'Samoan', value: 'sm' },
  { label: 'Scots Gaelic', value: 'gd' },
  { label: 'Serbian', value: 'sr' },
  { label: 'Sesotho', value: 'st' },
  { label: 'Shona', value: 'sn' },
  { label: 'Sindhi', value: 'sd' },
  { label: 'Sinhala (Sinhalese)', value: 'si' },
  { label: 'Slovak', value: 'sk' },
  { label: 'Slovenian', value: 'sl' },
  { label: 'Somali', value: 'so' },
  { label: 'Spanish', value: 'es' },
  { label: 'Sundanese', value: 'su' },
  { label: 'Swahili', value: 'sw' },
  { label: 'Swedish', value: 'sv' },
  { label: 'Tagalog (Filipino)	', value: 'tl' },
  { label: 'Tajik', value: 'tg' },
  { label: 'Tamil', value: 'ta' },
  { label: 'Tatar', value: 'tt' },
  { label: 'Telugu', value: 'te' },
  { label: 'Thai', value: 'th' },
  { label: 'Turkish', value: 'tr' },
  { label: 'Turkmen', value: 'tk' },
  { label: 'Ukrainian', value: 'uk' },
  { label: 'Urdu', value: 'ur' },
  { label: 'Uyghur', value: 'ug' },
  { label: 'Uzbek', value: 'uz' },
  { label: 'Vietnamese', value: 'vi' },
  { label: 'Welsh', value: 'cy' },
  { label: 'Xhosa', value: 'xh' },
  { label: 'Yiddish', value: 'yi' },
  { label: 'Yoruba', value: 'yo' },
  { label: 'Zulu', value: 'zu' },
];

// pass dates int MM/DD/YYYY format
export const isDateInThePast = (selectedDate: string, todaysDate: string) => {
  const selectedDateArray = selectedDate.split('/').map((item) => Number(item));
  const todaysDateArray = todaysDate.split('/').map((item) => Number(item));

  const selectedMonth = selectedDateArray[0];
  const selectedDay = selectedDateArray[1];
  const selectedYear = selectedDateArray[2];

  const todaysMonth = todaysDateArray[0];
  const todaysDay = todaysDateArray[1];
  const todaysYear = todaysDateArray[2];

  if (selectedMonth < todaysMonth && selectedYear === todaysYear) {
    return true;
  }

  if (
    selectedDay < todaysDay &&
    selectedMonth === todaysMonth &&
    selectedYear === todaysYear
  ) {
    return true;
  }

  if (selectedYear < todaysYear) {
    return true;
  }

  return false;
};

// pass time in H:mm format (24h)
export const isTimeInThePast = (selectedTime: string, todaysTime: string) => {
  const selectedTimeArray = selectedTime.split(':').map((item) => Number(item));
  const todaysTimeArray = todaysTime.split(':').map((item) => Number(item));

  const selectedHour = selectedTimeArray[0];
  const selectedMinutes = selectedTimeArray[1];

  const todaysHour = todaysTimeArray[0];
  const todaysMinutes = todaysTimeArray[1];

  if (selectedHour < todaysHour) {
    return true;
  }

  if (selectedHour === todaysHour && selectedMinutes < todaysMinutes) {
    return true;
  }

  return false;
};
