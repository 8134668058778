import React from 'react';

import { Flex, Box } from '@/shared/ui';

type AudioViewerProps = {
  src: string;
};

export const AudioViewer: React.FC<AudioViewerProps> = ({ src }) => {
  return (
    <Flex css={{ width: '100%', height: '100%' }} align="center" justify="center">
      <Box css={{ zIndex: 9999999 }}>
        <audio src={src} controls />
      </Box>
    </Flex>
  );
};
