import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { useTags } from '@/pages/settings/organization/tags/context/TagsContext';
import { ContactTagItem } from '@/shared/types';
import { Box, Flex, Skeleton } from '@/shared/ui';

import { useContacts } from '../../context/ContactContext';
import { AddSingleContactTags } from './AddSingleContactTags';
import { ContactTag } from './ContactTag';

export const ContactTags = () => {
  // contacts context
  const {
    contactState: { current, loadingContact },
  } = useContacts();
  // tags context
  const {
    tagsState: { allTags },
  } = useTags();

  const history = useHistory();

  // render tag item function
  const renderItem = useCallback(
    (contact_tag: ContactTagItem, index: number) => {
      if (contact_tag.tag) {
        // only standard tags are displayed
        if (contact_tag.tag.type !== 'standard') {
          return null;
        }
        return (
          <ContactTag
            key={contact_tag.tag.id}
            tagText={contact_tag.tag.name}
            iconColor={contact_tag.tag.color}
            isClosable={true}
            tagId={contact_tag.id}
            contactId={current?.id}
            contact_tag={contact_tag}
            index={index}
          />
        );
      }
      return null;
    },
    [current?.id]
  );

  // redirects to tags page if there are no tags created
  const redirectToTagsPage = () => {
    history.replace({ pathname: '/settings/tags' });
  };

  return (
    <Flex css={{ flexWrap: 'wrap' }}>
      {current?.id && (
        <>
          {allTags.length === 0 ? (
            <Box onClick={redirectToTagsPage}>
              <ContactTag isClosable={false} tagId="1" contactId="" index={0} />
            </Box>
          ) : (
            <AddSingleContactTags key={current?.id} contactId={current?.id || ''}>
              <ContactTag
                isClosable={false}
                tagId=""
                contactId=""
                index={current?.contact_tags?.length === 0 ? 0 : 1}
              />
            </AddSingleContactTags>
          )}
        </>
      )}
      {loadingContact ? (
        <>
          {Array.from({ length: 5 }, (_: any, k: React.Key | null | undefined) => (
            <Skeleton key={k} variant="tag" css={{ height: 30, width: 95 }} />
          ))}
        </>
      ) : (
        <>
          {current?.contact_tags && current?.contact_tags?.length
            ? current?.contact_tags?.map(renderItem)
            : null}
        </>
      )}
    </Flex>
  );
};
