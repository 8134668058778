import { memo, useCallback } from 'react';
import { HiDotsHorizontal } from 'react-icons/hi';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { ConfirmationDialog } from '@/shared/components/ConfirmationDialog';
import { Link } from '@/shared/types/links';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  IconButton,
} from '@/shared/ui';

import { useLinks } from '../context/LinksContext';

type LinkNavigationButtonProps = {
  link: Link;
};

export const LinkNavigationButton = memo(({ link }: LinkNavigationButtonProps) => {
  const { deleteLink, setCurrentLink } = useLinks();
  const history = useHistory();
  const isLinkPage = useRouteMatch('/campaigns/links/:id');

  const goToLink = useCallback(() => {
    // set the current link in the context
    setCurrentLink(link);
    // navigate to the link steps page
    history.push(`/campaigns/links/${link.id}/link`);
  }, [link]);

  const handleConfirm = useCallback(() => {
    // delete link
    link?.id && deleteLink(link.id);
    // got to links list
    isLinkPage && history.push(`/campaigns/links`);
  }, [deleteLink, link?.id]);

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild data-testid="link-dropdown-trigger">
        <IconButton aria-label="open domain dropdown" variant="outline" size="2">
          <HiDotsHorizontal size="15px" />
        </IconButton>
      </DropdownMenuTrigger>
      <DropdownMenuContent sideOffset={10}>
        <DropdownMenuItem onClick={goToLink}>Edit</DropdownMenuItem>
        <ConfirmationDialog
          title="Delete Link"
          description="Are you sure want to delete this link? This action cannot be undone."
          onConfirm={handleConfirm}
          confirmButtonTitle="Yes, Delete Link"
        >
          <DropdownMenuItem onClick={(e) => e.preventDefault()}>Delete</DropdownMenuItem>
        </ConfirmationDialog>
      </DropdownMenuContent>
    </DropdownMenu>
  );
});
