import React from 'react';

import { VStack } from '@/shared/ui';

import SignaturesTable from './SignaturesTable';

const SignaturesView = () => {
  return (
    <VStack>
      <SignaturesTable />
    </VStack>
  );
};

export default SignaturesView;
