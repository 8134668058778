/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ChangeEvent } from 'react';
import { HiOutlinePaperClip } from 'react-icons/hi';

import { Button, IconButton } from '../../ui';

export const AttachmentIconButton = React.forwardRef(
  (props: any, ref: React.Ref<any>): JSX.Element => {
    return (
      <IconButton
        ref={ref}
        as={'label'}
        htmlFor={props?.source || 'input'}
        size={props.buttonSize || 2}
        css={{ cursor: 'pointer' }}
      >
        <HiOutlinePaperClip
          size={props?.iconsize}
          title="Add Attachment"
          style={{ transform: 'rotate(45deg) scaleY(-1)' }}
        />
        <input
          value=""
          id={props?.source || 'input'}
          type="file"
          accept=".jpg, .jpeg, .png, .gif, .pdf, .doc, .csv, text/plain, text/vcard, video/3gpp, video/mp4, video/x-m4v, video/*, .txt"
          multiple
          hidden
          {...props}
        />
      </IconButton>
    );
  }
);

interface AttachmentButtonProps {
  size?: number;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  iconsize?: number;
}

export const AttachmentButton = (props: AttachmentButtonProps): JSX.Element => {
  return (
    <Button as={'label'} htmlFor="input" size={2} variant="gray" ghost={true}>
      <HiOutlinePaperClip
        title="Add Attachment"
        style={{ transform: 'rotate(45deg) scaleY(-1)' }}
        size={props?.size}
      />
      Attachments
      <input
        value=""
        id="input"
        type="file"
        accept=".jpg, .jpeg, .png, .gif, .pdf, .doc, .csv, text/plain, text/vcard, video/3gpp, video/mp4, video/x-m4v, video/*"
        multiple
        hidden
        {...props}
      />
    </Button>
  );
};
