import { ColumnType, Resource, SortConfig } from '@/shared/types/filter';

export const sortConfig: SortConfig<
  Resource.CustomProperty,
  ColumnType.Label | ColumnType.InsertedAt | ColumnType.UpdatedAt | ColumnType.Type
> = {
  label: 'Sort',
  columnOptions: [
    {
      label: 'Name',
      resource: Resource.CustomProperty,
      column: ColumnType.Label,
      order: 'asc',
    },
    {
      label: 'Type',
      resource: Resource.CustomProperty,
      column: ColumnType.Type,
      order: 'asc',
    },
    {
      label: 'Created At',
      resource: Resource.CustomProperty,
      column: ColumnType.InsertedAt,
      order: 'asc',
    },
    {
      label: 'Updated At',
      resource: Resource.CustomProperty,
      column: ColumnType.UpdatedAt,
      order: 'asc',
    },
  ],
};
