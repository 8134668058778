import {
  Group,
  GroupsAction,
  GroupsActionTypes,
  GroupsState,
} from '@/shared/types/contacts/groups';
import { removeDuplicates } from '@/shared/utils/removeDuplicates';

const GroupsReducer = (state: GroupsState, action: GroupsAction) => {
  switch (action.type) {
    case GroupsActionTypes.GET_GROUPS:
      return {
        ...state,
        groups: removeDuplicates(action.payload.groups),
        totalCount: action.payload.total,
        loading: false,
      };
    case GroupsActionTypes.GET_RECENT_GROUPS:
      return {
        ...state,
        recentGroups: removeDuplicates(action.payload.groups),
      };
    case GroupsActionTypes.GET_GROUPS_V2:
      return {
        ...state,
        groups: removeDuplicates(action.payload.data),
        totalCount: action.payload.total,
        loading: false,
      };
    case GroupsActionTypes.UPDATE_FILTER_PARAMS:
      return {
        ...state,
        filterParams: action.payload,
        loading: true,
      };
    case GroupsActionTypes.GET_GROUP:
      return {
        ...state,
        current: action.payload,
        loading: false,
      };
    case GroupsActionTypes.SEARCH_GROUP:
      return {
        ...state,
        searchedGroups: action.payload,
        loading: false,
      };
    case GroupsActionTypes.CREATE_GROUP:
      return {
        ...state,
        groups: action.payload.name ? [action.payload, ...state.groups] : state.groups,
        recentGroups: action.payload.name
          ? [action.payload, ...state.recentGroups]
          : state.recentGroups,
        totalCount: state.totalCount + 1,
      };
    case GroupsActionTypes.UPDATE_GROUP:
      return {
        ...state,
        groups: state.groups.map((group: Group) => {
          return group.id === action.payload.id ? action.payload : group;
        }),
        recentGroups: state.recentGroups.map((group: Group) => {
          return group.id === action.payload.id ? action.payload : group;
        }),
        current: action.payload,
      };
    case GroupsActionTypes.DELETE_GROUP:
      return {
        ...state,
        groups: state.groups.filter((group: Group) => {
          return group.id !== action.payload;
        }),
        recentGroups: state.recentGroups.filter((group: Group) => {
          return group.id !== action.payload;
        }),
        current: null,
        totalCount: state.totalCount - 1,
      };
    case GroupsActionTypes.SET_CURRENT:
      return {
        ...state,
        current: action.payload,
      };
    case GroupsActionTypes.SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
};

export default GroupsReducer;
