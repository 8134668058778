import React, { createContext, useContext, useReducer } from 'react';

import * as DeveloperApi from '@/shared/api/developers';
import {
  ApiKey,
  ApiKeyResponse,
  CreateDeveloperApplication,
  CreateDeveloperEndpoint,
  DeveloperActionTypes,
  DeveloperApplication,
  DeveloperApplicationResponse,
  DeveloperEndpoint,
} from '@/shared/types/developers';
import { FilterItem, Sort } from '@/shared/types/filter';

import { DeveloperReducer } from './DevelopersReducer';

export type DeveloperState = {
  apiKeys: (ApiKey | null)[];
  total: number;
  currentApiKey: ApiKey | null;
  developerApplications: (DeveloperApplication | null)[];
  developerApplicationsTotal: number;
  developerEndpoints: (DeveloperEndpoint | null)[];
  loading: boolean;
  error: any;
};

export const initialDeveloperState: DeveloperState = {
  apiKeys: [],
  total: 0,
  currentApiKey: null,
  developerApplications: [],
  developerApplicationsTotal: 0,
  developerEndpoints: [],
  loading: true,
  error: null,
};

export const DeveloperContext = createContext<{
  developerState: DeveloperState;
  searchApiKeys: (
    filter: Array<FilterItem>,
    sort: Array<Sort>,
    limit: number,
    offset: number
  ) => Promise<ApiKeyResponse>;
  getApiKeys: () => void;
  createApiKey: (name: string) => void;
  enableApiKey: (id: string) => void;
  disableApiKey: (id: string) => void;
  deleteApiKey: (id: string) => void;
  renameApiKey: (id: string, name: string) => void;
  setCurrentApiKey: (apiKey: ApiKey | null) => void;
  getDeveloperApplications: () => void;
  createDeveloperApplication: (
    application: CreateDeveloperApplication
  ) => Promise<DeveloperApplication>;
  updateDeveloperApplication: (application: DeveloperApplication) => void;
  searchDeveloperApplications: (
    filter: Array<FilterItem>,
    sort: Array<Sort>,
    limit: number,
    offset: number
  ) => Promise<DeveloperApplicationResponse>;
  getDeveloperEndpoints: (application_id: string) => void;
  createDeveloperEndpoint: (endpoint: CreateDeveloperEndpoint) => void;
  updateDeveloperEndpoint: (endpoint: DeveloperEndpoint) => void;
  deleteDeveloperEndpoint: (endpoint: DeveloperEndpoint) => void;
  cleanDeveloperEndpointsState: () => void;
}>({
  developerState: initialDeveloperState,
  searchApiKeys: async () => Promise.resolve({} as ApiKeyResponse),
  getApiKeys: () => ({}) as ApiKey[],
  createApiKey: () => ({}) as ApiKey[],
  enableApiKey: () => ({}) as ApiKey[],
  disableApiKey: () => ({}) as ApiKey[],
  deleteApiKey: () => ({}) as ApiKey[],
  renameApiKey: () => ({}) as ApiKey[],
  setCurrentApiKey: () => ({}) as ApiKey[],
  searchDeveloperApplications: async () =>
    Promise.resolve({} as DeveloperApplicationResponse),
  getDeveloperApplications: () => ({}) as DeveloperApplication[],
  createDeveloperApplication: () => Promise.resolve({} as DeveloperApplication),
  updateDeveloperApplication: () => ({}) as DeveloperApplication[],
  getDeveloperEndpoints: () => ({}) as DeveloperEndpoint[],
  createDeveloperEndpoint: () => ({}) as DeveloperEndpoint[],
  updateDeveloperEndpoint: () => ({}) as DeveloperEndpoint[],
  deleteDeveloperEndpoint: () => ({}) as DeveloperEndpoint[],
  cleanDeveloperEndpointsState: () => Promise.resolve(),
});

export const useDeveloperContext = () => useContext(DeveloperContext);

const DeveloperProvider = ({ children }: { children: React.ReactNode }) => {
  const [developerState, dispatch] = useReducer(DeveloperReducer, initialDeveloperState);

  const searchApiKeys = async (
    filter: Array<FilterItem>,
    sort: Array<Sort>,
    limit = 100,
    offset = 0
  ): Promise<ApiKeyResponse> => {
    try {
      const api_keys = await DeveloperApi.searchApiKeys(filter, sort, limit, offset);

      dispatch({
        type: DeveloperActionTypes.SEARCH_API_KEYS,
        payload: api_keys,
      });

      return api_keys;
    } catch (err) {
      console.error(err);
      return Promise.reject(err);
    }
  };

  const getApiKeys = async () => {
    try {
      const data = await DeveloperApi.getApiKeys();

      dispatch({
        type: DeveloperActionTypes.GET_API_KEYS,
        payload: data,
      });
    } catch (err) {
      console.error(err);
    }
  };

  const createApiKey = async (name: string) => {
    try {
      const data = await DeveloperApi.createApiKey(name);

      dispatch({
        type: DeveloperActionTypes.CREATE_API_KEY,
        payload: data,
      });
    } catch (err) {
      console.error(err);
    }
  };

  const enableApiKey = async (id: string) => {
    try {
      const api_key = await DeveloperApi.enableApiKey(id);

      dispatch({
        type: DeveloperActionTypes.ENABLE_API_KEY,
        payload: api_key,
      });
    } catch (err) {
      console.error(err);
    }
  };

  const disableApiKey = async (id: string) => {
    try {
      const api_key = await DeveloperApi.disableApiKey(id);

      dispatch({
        type: DeveloperActionTypes.DISABLE_API_KEY,
        payload: api_key,
      });
    } catch (err) {
      console.error(err);
    }
  };

  const deleteApiKey = async (id: string) => {
    try {
      await DeveloperApi.deleteApiKey(id);

      dispatch({
        type: DeveloperActionTypes.DELETE_API_KEY,
        payload: id,
      });
    } catch (err) {
      console.error(err);
    }
  };

  const renameApiKey = async (id: string, name: string) => {
    try {
      const data = await DeveloperApi.renameApiKey(id, name);
      dispatch({
        type: DeveloperActionTypes.RENAME_API_KEY,
        payload: data,
      });
    } catch (err) {
      console.error(err);
    }
  };

  const setCurrentApiKey = (apiKey: ApiKey | null | undefined) => {
    try {
      dispatch({
        type: DeveloperActionTypes.SET_CURRENT,
        payload: apiKey ? apiKey : null,
      });
    } catch (err) {
      console.error(err);
    }
  };

  const searchDeveloperApplications = async (
    filter: Array<FilterItem>,
    sort: Array<Sort>,
    limit = 100,
    offset = 0
  ): Promise<DeveloperApplicationResponse> => {
    try {
      const applications = await DeveloperApi.searchDeveloperApplications(
        filter,
        sort,
        limit,
        offset
      );

      dispatch({
        type: DeveloperActionTypes.SEARCH_DEVELOPER_APPLICATIONS,
        payload: applications,
      });

      return applications;
    } catch (err) {
      console.error(err);
      return Promise.reject(err);
    }
  };

  const getDeveloperApplications = async () => {
    try {
      const data = await DeveloperApi.getDeveloperApplications();

      dispatch({
        type: DeveloperActionTypes.GET_DEVELOPER_APPLICATIONS,
        payload: data,
      });

      return data;
    } catch (err) {
      console.error(err);
    }
  };

  const createDeveloperApplication = async (
    application: CreateDeveloperApplication
  ): Promise<DeveloperApplication> => {
    try {
      const data = await DeveloperApi.createDeveloperApplication(application);

      dispatch({
        type: DeveloperActionTypes.CREATE_DEVELOPER_APPLICATION,
        payload: data,
      });

      return data;
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  };

  const updateDeveloperApplication = async (application: DeveloperApplication) => {
    try {
      const data = await DeveloperApi.updateDeveloperApplication(application);

      dispatch({
        type: DeveloperActionTypes.UPDATE_DEVELOPER_APPLICATION,
        payload: data,
      });

      return data;
    } catch (err) {
      console.error(err);
    }
  };

  const getDeveloperEndpoints = async (application_id: string) => {
    try {
      const data = await DeveloperApi.getDeveloperEndpoints(application_id);

      dispatch({
        type: DeveloperActionTypes.GET_DEVELOPER_ENDPOINTS,
        payload: data,
      });

      return data;
    } catch (err) {
      console.error(err);
    }
  };

  const cleanDeveloperEndpointsState = () => {
    dispatch({
      type: DeveloperActionTypes.CLEAN_DEVELOPER_ENDPOINTS_STATE,
    });
  };

  const createDeveloperEndpoint = async (endpoint: CreateDeveloperEndpoint) => {
    try {
      const data = await DeveloperApi.createDeveloperEndpoint(endpoint);

      dispatch({
        type: DeveloperActionTypes.CREATE_DEVELOPER_ENDPOINT,
        payload: data,
      });

      return data;
    } catch (err) {
      console.error(err);
    }
  };

  const updateDeveloperEndpoint = async (endpoint: DeveloperEndpoint) => {
    try {
      const data = await DeveloperApi.updateDeveloperEndpoint(endpoint);

      dispatch({
        type: DeveloperActionTypes.UPDATE_DEVELOPER_ENDPOINT,
        payload: data,
      });

      return data;
    } catch (err) {
      console.error(err);
    }
  };

  const deleteDeveloperEndpoint = async (endpoint: DeveloperEndpoint) => {
    try {
      const data = await DeveloperApi.deleteDeveloperEndpoint(endpoint.id);

      dispatch({
        type: DeveloperActionTypes.DELETE_DEVELOPER_ENDPOINT,
        payload: data,
      });

      return data;
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <DeveloperContext.Provider
      value={{
        developerState,
        searchApiKeys,
        getApiKeys,
        createApiKey,
        enableApiKey,
        disableApiKey,
        deleteApiKey,
        renameApiKey,
        setCurrentApiKey,
        searchDeveloperApplications,
        getDeveloperApplications,
        createDeveloperApplication,
        updateDeveloperApplication,
        getDeveloperEndpoints,
        createDeveloperEndpoint,
        updateDeveloperEndpoint,
        deleteDeveloperEndpoint,
        cleanDeveloperEndpointsState,
      }}
    >
      {children}
    </DeveloperContext.Provider>
  );
};

export default DeveloperProvider;
