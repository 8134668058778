import React from 'react';
import { toast } from 'sonner';

import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogPortal,
  AlertDialogTitle,
  Button,
  HStack,
  Text,
} from '@/shared/ui';

type DeleteConfirmationDialogProps = {
  /* is the dialog open? */
  isDisableConfirmationDialogOpen: boolean;
  /* set the dialog open state */
  setIsDisableConfirmationDialogOpen: (isOpen: boolean) => void;
  /* the focused api key */
  currentApiKeyId: string | null;
  /* the focused api key status */
  currentApiKeyStatus: boolean | null;
  /* api function to disable the api key */
  disableApiKey: (id: string) => void;
  /* api function to enable the api key */
  enableApiKey: (id: string) => void;
};

const DisableConfirmationDialog = (props: DeleteConfirmationDialogProps): JSX.Element => {
  const {
    isDisableConfirmationDialogOpen,
    setIsDisableConfirmationDialogOpen,
    currentApiKeyId,
    currentApiKeyStatus,
    disableApiKey,
    enableApiKey,
  } = props;

  const handleDisableConfirm = async () => {
    if (currentApiKeyStatus) {
      try {
        disableApiKey(currentApiKeyId || '');
        toast.success('API Key disabled');
      } catch (error) {
        toast.error('Failed to disable API Key');
      }
    } else {
      try {
        enableApiKey(currentApiKeyId || '');
        toast.success('API Key enabled');
      } catch (error) {
        toast.error('Failed to enable API Key');
      }
    }
    setIsDisableConfirmationDialogOpen(false);
  };

  return (
    <AlertDialog open={isDisableConfirmationDialogOpen}>
      <AlertDialogPortal>
        <AlertDialogOverlay />
        <AlertDialogContent
          onEscapeKeyDown={() => setIsDisableConfirmationDialogOpen(false)}
        >
          <AlertDialogTitle>
            {currentApiKeyStatus ? 'Disable' : 'Enable'} API Key
          </AlertDialogTitle>
          <Text>
            Are you sure you want to {currentApiKeyStatus ? 'disable' : 'enable'} this API
            Key?
          </Text>
          <HStack css={{ marginTop: 20, justifyContent: 'flex-end' }}>
            <Button
              variant={'gray'}
              onClick={() => setIsDisableConfirmationDialogOpen(false)}
            >
              Cancel
            </Button>
            <Button variant={'red'} onClick={handleDisableConfirm}>
              Confirm
            </Button>
          </HStack>
        </AlertDialogContent>
      </AlertDialogPortal>
    </AlertDialog>
  );
};

export default DisableConfirmationDialog;
