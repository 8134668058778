/* eslint-disable @typescript-eslint/no-unnecessary-type-constraint */
import { FormikValues, useFormikContext } from 'formik';
import _ from 'lodash';
import { useState } from 'react';

import { ValueCombobox } from '@/shared/components/filterBuilder/values/Combobox';

import { SelectComboboxItemIndicator } from './SelectComboboxItemIndicator';

export type ComboboxOption = { label: string; value: string | number };

export type SelectComboboxFieldProps = {
  options: Array<ComboboxOption>;
  selectLabel?: string;
  searchLabel?: string;
  selectorStyles?: { [key: string]: any };
  valueStyles?: { [key: string]: any };
  disabled?: boolean;
  name?: string;
  error?: boolean;
  withSearch?: boolean;
};

export const SelectComboboxField = ({
  options,
  searchLabel,
  selectLabel,
  name,
  selectorStyles,
  valueStyles,
  withSearch,
}: SelectComboboxFieldProps) => {
  const formik = useFormikContext<FormikValues>();

  const value = _.get(formik.values, name as string);
  const selectedValue = options?.find((item) => item.value === value);
  const [selected, setSelected] = useState<ComboboxOption | undefined>(selectedValue);

  const onSelect = (selectedValue: ComboboxOption) => {
    setSelected(selectedValue);
    formik.setFieldTouched(name as string, true);
    formik.setFieldValue(name as string, selectedValue.value);
  };

  return (
    <div>
      <ValueCombobox
        selectorStyles={{
          padding: '6px 8px',
          'span + span': {
            translate: '0 !important',
          },
          '&[data-state="open"]': {
            border: '1px solid $primaryColor',
          },
          ...selectorStyles,
        }}
        valueStyles={
          selected
            ? {
                color: '#1C2024',
                fontSize: '12px',
                fontWeight: '500',
                padding: '1px 8px',
                ...valueStyles,
              }
            : {
                color: '#00041D74',
              }
        }
        options={options}
        selected={{ label: selected?.label || '', value: selected?.value || '' }}
        onSelect={onSelect}
        selectLabel={selectLabel}
        searchLabel={searchLabel}
        SelectItemIndicatorComponent={SelectComboboxItemIndicator}
        withSearch={withSearch}
        renderOptionsIfOpen
      />
    </div>
  );
};
