/* eslint-disable react-hooks/exhaustive-deps */
import { capitalize } from 'lodash';
import React, { useEffect, useState } from 'react';
import { HiMicrophone } from 'react-icons/hi';
import { useHistory, useParams } from 'react-router-dom';
import { RetellWebClient } from 'retell-client-js-sdk';

import { Box, Button, Flex } from '@/shared/ui';

export const WebCallInProgress = () => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();

  const [isCallActive, setIsCallActive] = useState(false);
  const [transcript, setTranscript] = useState<{ content: string; role: string }[]>([]);
  const retellWebClient = new RetellWebClient();

  const startInterviewCall = async () => {
    try {
      await retellWebClient.startCall({
        accessToken: id,
        sampleRate: 24000,
        captureDeviceId: 'default',
        emitRawAudioSamples: false,
      });
      setIsCallActive(true);
    } catch (error) {
      console.error('Failed to start call:', error);
    }
  };

  useEffect(() => {
    startInterviewCall();
  }, []);

  retellWebClient.on('call_started', () => {
    console.log('Call started');
  });

  retellWebClient.on('call_ended', () => {
    console.log('Call ended');
    setIsCallActive(false);
  });

  retellWebClient.on('agent_start_talking', () => {
    console.log('Agent started talking');
  });

  retellWebClient.on('agent_stop_talking', () => {
    console.log('Agent stopped talking');
  });

  retellWebClient.on('update', (update) => {
    console.log('Transcript update:', update.transcript);
    setTranscript(update.transcript);
  });

  retellWebClient.on('error', (error) => {
    console.error('An error occurred:', error);
    retellWebClient.stopCall();
  });

  // Adjusted endCall function without await
  const endCall = () => {
    if (!isCallActive) {
      console.log('Call is not active, cannot end call.');
      return;
    }

    try {
      console.log('Attempting to end call');
      retellWebClient.stopCall();
      console.log('Call stop requested');
      setIsCallActive(false);
      history.push(`/call/end`);
    } catch (error) {
      console.error('Failed to stop call:', error);
    }
  };

  // End the call on unmount
  useEffect(() => {
    return () => {
      if (isCallActive) {
        endCall();
      }
    };
  }, [isCallActive]);

  return (
    <Box css={{ padding: 20 }}>
      <Flex
        direction="row"
        justify="between"
        css={{
          '@media (max-width: 768px)': {
            flexDirection: 'column',
          },
        }}
      >
        <Flex
          direction="column"
          css={{
            width: '100%',
            p: 20,
            border: '1px solid #e0e0e0',
            borderRadius: 10,
            mr: 5,
            '@media (max-width: 768px)': {
              marginRight: 0,
              marginBottom: 20,
            },
          }}
        >
          <Box>
            <Box css={{ fontSize: 20, fontWeight: 600 }}>Welcome to your call!</Box>
          </Box>
          <Flex
            css={{
              width: '100%',
              height: '200px',
              backgroundColor: '#f0f0f0',
              border: '1px dashed #ccc',
              my: 10,
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <HiMicrophone size={40} />
          </Flex>
          <Button onClick={endCall} variant="red">
            End Call
          </Button>
        </Flex>
        <Flex
          css={{
            width: '100%',
            p: 20,
            border: '1px solid #e0e0e0',
            borderRadius: 10,
            ml: 5,
            '@media (max-width: 768px)': {
              marginLeft: 0,
            },
          }}
          direction="column"
        >
          <Box css={{ fontSize: 20, fontWeight: 600 }}>Live Transcript</Box>
          <Box>
            <Box>
              {transcript.map((entry, index) => (
                <Box key={index} css={{ my: 5 }}>
                  <strong>{capitalize(entry.role)}:</strong> {entry.content}
                </Box>
              ))}
            </Box>
          </Box>
          {!transcript.length && (
            <Box css={{ fontSize: 14, color: '#808080' }}>
              Call transcript will appear here when the call starts
            </Box>
          )}
        </Flex>
      </Flex>
    </Box>
  );
};
