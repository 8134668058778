import { ErrorBoundary } from '@sentry/react';
import React from 'react';
import { HiStatusOffline } from 'react-icons/hi';

import { PageLayout } from '@/shared/layouts/PageLayout';
import { Box, Text } from '@/shared/ui';

// Fallback Component to be rendered in case of an error
const CampaignGlobalTemplatesFallback = ({ feature }: { feature?: string }) => (
  <PageLayout>
    <Box
      css={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
      }}
    >
      <HiStatusOffline color="red" />
      <Text css={{ marginLeft: 10 }}>
        {feature === 'CampaignGlobalTemplates'
          ? 'Global Templates Unavailable'
          : 'Campaign Templates Unavailable'}
      </Text>
    </Box>
  </PageLayout>
);

type ErrorBoundaryProps = {
  feature: string;
  children: React.ReactNode;
};

export const CampaignGlobalTemplatesErrorBoundary: React.FC<ErrorBoundaryProps> = ({
  feature,
  children,
}) => {
  // need to have a feature signal
  if (!feature || feature.length < 1) return null;
  // need to have children to render
  if (!children) return null;

  const errorTag = `CampaignGlobalTemplatesErrorBoundary.${feature}`;

  return (
    <ErrorBoundary
      fallback={<CampaignGlobalTemplatesFallback feature={feature} />}
      beforeCapture={(scope) => {
        scope.setTag('ErrorLocation', errorTag);
      }}
      showDialog={false}
    >
      {children}
    </ErrorBoundary>
  );
};
