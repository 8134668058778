export const jsCodeBlock = `
    // server.js
    //
    // Use this sample code to handle webhook events in your integration.
    //
    // 1) Paste this code into a new file (server.js)
    //
    // 2) Install dependencies
    //   npm install express
    //
    // 3) Run the server on http://localhost:4242
    //   node server.js

    const express = require('express');
    const app = express();

    // Replace 'your_secret_key' with your actual secret key
    const webhookSecret = "your_secret_key";

    app.post('/webhook', express.json(), (request, response) => {
        // Verifying the signature of the webhook event is highly recommended for security
        // This is a placeholder for the actual verification logic
        const signature = request.headers['your-signature-header'];

        if (!verifySignature(request.body, signature, webhookSecret)) {
            response.status(401).send('Invalid signature');
            return;
        }

        const event = request.body; // Assuming the event payload is sent in the body of the request

        // Handle the event
        switch (event.event) {
            case 'message.created':
                // Define and call a function to handle the event message.created
                handleMessageCreated(event);
                break;
                // ... handle other event types
            default:
                console.log("Unhandled event type");
        }

        // Return a 200 response to acknowledge receipt of the event
        response.send('Event received');
    });

    // Placeholder function for signature verification (to be implemented)
    function verifySignature(payload, signature, secret) {
        // Your signature verification logic goes here
        return true; // This should return a boolean value after verification
    }

    // Placeholder function to handle message.created event (to be implemented)
    function handleMessageCreated(event) {
        // Your logic for handling the message.created event goes here
        console.log('Handled message.created event', event);
    }

    app.listen(4242, () => console.log('Running on port 4242'));    // server.js
    //
    // Use this sample code to handle webhook events in your integration.
    //
    // 1) Paste this code into a new file (server.js)
    //
    // 2) Install dependencies
    //   npm install express
    //
    // 3) Run the server on http://localhost:4242
    //   node server.js

    const express = require('express');
    const app = express();

    // Replace 'your_secret_key' with your actual secret key
    const webhookSecret = "your_secret_key";

    app.post('/webhook', express.json(), (request, response) => {
        // Verifying the signature of the webhook event is highly recommended for security
        // This is a placeholder for the actual verification logic
        const signature = request.headers['your-signature-header'];

        if (!verifySignature(request.body, signature, webhookSecret)) {
            response.status(401).send('Invalid signature');
            return;
        }

        const event = request.body; // Assuming the event payload is sent in the body of the request

        // Handle the event
        switch (event.event) {
            case 'message.created':
                // Define and call a function to handle the event message.created
                handleMessageCreated(event);
                break;
                // ... handle other event types
            default:
                console.log("Unhandled event type");
        }

        // Return a 200 response to acknowledge receipt of the event
        response.send('Event received');
    });

    // Placeholder function for signature verification (to be implemented)
    function verifySignature(payload, signature, secret) {
        // Your signature verification logic goes here
        return true; // This should return a boolean value after verification
    }

    // Placeholder function to handle message.created event (to be implemented)
    function handleMessageCreated(event) {
        // Your logic for handling the message.created event goes here
        console.log('Handled message.created event', event);
    }

    app.listen(4242, () => console.log('Running on port 4242'));
`;

export const pythonCodeBlock = `
    # server.py
    #
    # Use this sample code to handle webhook events in your integration.
    #
    # 1) Paste this code into a new file (server.py)
    #
    # 2) Install dependencies
    #    pip install Flask
    #
    # 3) Run the server on http://localhost:4242
    #    flask run --port 4242

    from flask import Flask, request, abort

    app = Flask(__name__)

    # Replace 'your_secret_key' with your actual secret key
    webhook_secret = "your_secret_key"

    @app.route('/webhook', methods=['POST'])
    def webhook():
        # Verifying the signature of the webhook event is highly recommended for security
        # This is a placeholder for the actual verification logic
        signature = request.headers.get('Your-Signature-Header')

        if not verify_signature(request.data, signature, webhook_secret):
            abort(401)  # Unauthorized

        event = request.json

        # Handle the event
        if event['event'] == 'message.created':
            handle_message_created(event)
            # ... handle other event types
        else:
            print(f"Unhandled event type {event['event']}")

        # Return a 200 response to acknowledge receipt of the event
        return 'Event received', 200

    # Placeholder function for signature verification (to be implemented)
    def verify_signature(payload, signature, secret):
        # Your signature verification logic goes here
        return True  # This should return a boolean value after verification

    # Placeholder function to handle message.created event (to be implemented)
    def handle_message_created(event):
        # Your logic for handling the message.created event goes here
        print('Handled message.created event', event)

    if __name__ == '__main__':
        app.run(port=4242)
`;

export const goCodeBlock = `
    // main.go
    //
    // Use this sample code to handle webhook events in your integration.
    //
    // 1) Paste this code into a new file (main.go)
    //
    // 2) Install dependencies
    //    No external dependencies required
    //
    // 3) Run the server on http://localhost:4242
    //    go run main.go

    package main

    import (
        "fmt"
        "io/ioutil"
        "log"
        "net/http"
    )

    const webhookSecret string = "your_secret_key"

    func verifySignature(payload []byte, signature, secret string) bool {
        // Your signature verification logic goes here
        return true // This should return a boolean value after verification
    }

    func handleWebhook(w http.ResponseWriter, r *http.Request) {
        if r.Method != http.MethodPost {
            http.Error(w, "Method not allowed", http.StatusMethodNotAllowed)
            return
        }

        signature := r.Header.Get("Your-Signature-Header")

        body, err := ioutil.ReadAll(r.Body)
        if err != nil {
            http.Error(w, "Can't read body", http.StatusBadRequest)
            return
        }
        defer r.Body.Close()

        if !verifySignature(body, signature, webhookSecret) {
            http.Error(w, "Invalid signature", http.StatusUnauthorized)
            return
        }

        // Assuming the event type is part of the body payload as a simple JSON structure
        var data map[string]interface{}
        err = json.Unmarshal(body, &data)
        if err != nil {
            http.Error(w, "Can't unmarshal JSON", http.StatusBadRequest)
            return
        }

        eventType, ok := data["event"].(string)
        if !ok {
            http.Error(w, "Invalid data", http.StatusBadRequest)
            return
        }

        // Handle the event
        switch eventType {
        case "message.created":
            // Handle message.created event
            fmt.Println("Handled message.created event")
            // ... handle other event types
        default:
            fmt.Printf("Unhandled event type: %s\n", eventType)
        }

        // Return a 200 response to acknowledge receipt of the event
        w.Write([]byte("Event received"))
    }

    func main() {
        http.HandleFunc("/webhook", handleWebhook)

        log.Println("Server started on port 4242")
        log.Fatal(http.ListenAndServe(":4242", nil))
    }
`;
