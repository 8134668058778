import dayjs from 'dayjs';

import { ColumnType, FilterConfig, Resource, SortConfig } from '@/shared/types/filter';

export const sequenceResponsesFiltersConfig: FilterConfig<
  Resource.Contact | Resource.StepContact | Resource.ContactMessage,
  | ColumnType.Name
  | ColumnType.Email
  | ColumnType.Phone
  | ColumnType.InsertedAt
  | ColumnType.UpdatedAt
  | ColumnType.Body
> = {
  label: 'Filter',
  columnOptions: [
    {
      label: 'Contact Name',
      column: ColumnType.Name,
      resource: Resource.Contact,
      operator: null,
      type: 'text',
      value: '',
    },
    {
      label: 'Contact Email',
      column: ColumnType.Email,
      resource: Resource.Contact,
      operator: null,
      type: 'text',
      value: '',
    },
    {
      label: 'Contact Phone',
      column: ColumnType.Phone,
      resource: Resource.Contact,
      operator: null,
      type: 'text',
      value: '',
    },
    {
      label: 'Start Time',
      column: ColumnType.InsertedAt,
      resource: Resource.StepContact,
      operator: '<=',
      type: 'date',
      value: dayjs(),
    },
  ],
};

export const sequenceResponsesSortConfig: SortConfig<
  Resource.Contact | Resource.StepContact | Resource.ContactMessage,
  | ColumnType.Name
  | ColumnType.Email
  | ColumnType.Phone
  | ColumnType.InsertedAt
  | ColumnType.UpdatedAt
> = {
  label: 'Sort',
  columnOptions: [
    {
      label: 'Contact Name',
      column: ColumnType.Name,
      resource: Resource.Contact,
      order: 'asc',
    },
    {
      label: 'Contact Phone',
      column: ColumnType.Phone,
      resource: Resource.Contact,
      order: 'asc',
    },
    {
      label: 'Contact Email',
      column: ColumnType.Email,
      resource: Resource.Contact,
      order: 'asc',
    },
    {
      label: 'Start Time',
      column: ColumnType.InsertedAt,
      resource: Resource.StepContact,
      order: 'asc',
    },
  ],
};
