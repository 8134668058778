/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { Channel } from 'phoenix';
import React, {
  createContext,
  Dispatch,
  useContext,
  useEffect,
  useReducer,
  useState,
} from 'react';
import { toast } from 'sonner';

import { useAuth } from '@/pages/auth/context/AuthProvider';
import { useSocket } from '@/pages/auth/context/socket/SocketProvider';
import { useConversation } from '@/pages/inbox/context/ConversationContext';
import * as UserWebNotificationsAPI from '@/shared/api/notifications';
import { useAudio } from '@/shared/hooks';
import { ConversationType } from '@/shared/types/conversations';
import {
  NotificationsStatus,
  USER_WEB_NOTIFICATIONS_FILTERS,
  UserWebNotification,
  UserWebNotificationActions,
  UserWebNotificationActionTypes,
} from '@/shared/types/notifications';
import { Box } from '@/shared/ui';
import { isValidUuid } from '@/shared/utils/validations/validations';

import { AttachmentPreview } from '../NotificationsCard';
import { generateNotificationTitle } from '../utils';
import { UserWebNotificationsReducer } from './UserWebNotificationsReducer';

export const USER_WEB_NOTIFICATIONS_LIMIT = 50;

type UserWebNotificationsContextProps = {
  userWebNotificationsState: UserWebNotificationsState;
  isNotificationsDrawerOpen: boolean;
  hasUnreadNotifications: boolean;
  openNotificationsDrawer: () => void;
  closeNotificationsDrawer: () => void;
  getUserWebNotifications: (
    actionType: UserWebNotificationActionTypes,
    status: NotificationsStatus[],
    offset: number
  ) => Promise<void>;
  bulkMarkNotifications: (
    bulkAction: NotificationsStatus,
    ids: string[],
    event: React.SyntheticEvent
  ) => Promise<void>;
  bulkMarkAllNotifications: (
    bulkAction: NotificationsStatus,
    resource: object,
    event: React.SyntheticEvent
  ) => Promise<void>;
};

export type UserWebNotificationsState = {
  /* all read & unread notifications in state */
  notifications: UserWebNotification[];
  /* all archived notifications in state */
  archivedNotifications: UserWebNotification[];
  /* loading state */
  loading: boolean;
  /* selected tab */
  selectedTab: USER_WEB_NOTIFICATIONS_FILTERS;
};

export const initialUserWebNotificationsState: UserWebNotificationsState = {
  notifications: [],
  archivedNotifications: [],
  loading: true,
  selectedTab: USER_WEB_NOTIFICATIONS_FILTERS.ALL,
};

export const UserWebNotificationsContext =
  createContext<UserWebNotificationsContextProps>({
    userWebNotificationsState: initialUserWebNotificationsState,
    isNotificationsDrawerOpen: false,
    hasUnreadNotifications: false,
    openNotificationsDrawer: () => undefined,
    closeNotificationsDrawer: () => undefined,
    getUserWebNotifications: async () => undefined,
    bulkMarkNotifications: async () => undefined,
    bulkMarkAllNotifications: async () => undefined,
  });

export const useUserWebNotifications = () => useContext(UserWebNotificationsContext);

const UserWebNotificationsProvider = ({ children }: { children: React.ReactNode }) => {
  const [isNotificationsDrawerOpen, setIsNotificationsDrawerOpen] = useState(false);
  const [hasUnreadNotifications, setHasUnreadNotifications] = useState(false);
  const [userWebNotificationsChannel, setUserWebNotificationsChannel] =
    useState<Channel | null>(null);
  const openNotificationsDrawer = () => setIsNotificationsDrawerOpen(true);
  const closeNotificationsDrawer = () => setIsNotificationsDrawerOpen(false);

  const [userWebNotificationsState, dispatch]: [
    UserWebNotificationsState,
    Dispatch<UserWebNotificationActions>,
  ] = useReducer(UserWebNotificationsReducer, initialUserWebNotificationsState);

  const { organizations, tokens } = useAuth();
  const { socket, joinChannel, handleConnect } = useSocket();

  // get all of the user's organization ids
  const organizationIds = organizations?.map((item) => item.id);

  const setLoadingState = (loading: boolean) => {
    dispatch({
      type: UserWebNotificationActionTypes.SET_USER_WEB_NOTIFICATIONS_LOADING,
      payload: loading,
    });
  };

  const getUserWebNotifications = async (
    actionType: UserWebNotificationActionTypes,
    status: NotificationsStatus[],
    offset: number
  ) => {
    const params = {
      limit: USER_WEB_NOTIFICATIONS_LIMIT,
      offset: offset || 0,
      organization_ids: organizationIds,
      status,
    };

    setLoadingState(true);

    try {
      const data = await UserWebNotificationsAPI.searchUserWebNotifications(params);

      dispatch({
        type: actionType,
        payload: data,
      });

      checkForUnreadNotifications();

      return data;
    } catch (err) {
      console.log(err);

      setLoadingState(false);
      return Promise.reject(err);
    }
  };

  const bulkMarkNotifications = async (
    bulkAction: NotificationsStatus,
    ids: string[],
    event: React.SyntheticEvent
  ) => {
    event.stopPropagation();
    try {
      const data = await UserWebNotificationsAPI.bulkMarkNotifications(bulkAction, {
        ids,
      });

      dispatch({
        type: UserWebNotificationActionTypes.UPDATE_NOTIFICATION_STATUS,
        payload: {
          bulkAction,
          ids,
        },
      });

      return data;
    } catch (err) {
      console.log(err);

      return Promise.reject(err);
    }
  };

  const bulkMarkAllNotifications = async (
    bulkAction: NotificationsStatus,
    resource: object,
    event: React.SyntheticEvent
  ) => {
    event.stopPropagation();
    try {
      const data = await UserWebNotificationsAPI.bulkMarkNotifications(
        bulkAction,
        resource
      );

      dispatch({
        type: UserWebNotificationActionTypes.UPDATE_BULK_NOTIFICATION_STATUS,
        payload: {
          bulkAction,
        },
      });

      return data;
    } catch (err) {
      console.log(err);

      return Promise.reject(err);
    }
  };

  const checkForUnreadNotifications = () => {
    const hasUnread = userWebNotificationsState.notifications.some(
      (notification) => notification.status === NotificationsStatus.UNREAD
    );
    setHasUnreadNotifications(hasUnread);
  };

  // Fetch notifications on initial render
  useEffect(() => {
    getUserWebNotifications(
      UserWebNotificationActionTypes.GET_USER_WEB_NOTIFICATIONS,
      [NotificationsStatus.READ, NotificationsStatus.UNREAD],
      0
    );
  }, []);

  // Fetch notifications on initial render if notifications drawer is open
  useEffect(() => {
    if (isNotificationsDrawerOpen) {
      getUserWebNotifications(
        UserWebNotificationActionTypes.GET_USER_WEB_NOTIFICATIONS,
        [NotificationsStatus.READ, NotificationsStatus.UNREAD],
        0
      );
    }
  }, [isNotificationsDrawerOpen]);

  // Set hasUnreadNotifications value
  // Check if there are any unread notifications in state.
  useEffect(() => {
    checkForUnreadNotifications();
  }, [userWebNotificationsState?.notifications]);

  useEffect(() => {
    if (!socket) {
      handleConnect();
    }
  }, []);

  // connect to user web notifications websocket channel
  useEffect(() => {
    const channel = joinChannel(`notification:${tokens?.user_id}`);
    setUserWebNotificationsChannel(channel);
  }, [tokens?.user_id, socket]);

  // listen for new notification web socket events
  useEffect(() => {
    if (!userWebNotificationsChannel) return;

    userWebNotificationsChannel.on(
      UserWebNotificationActionTypes.NEW_NOTIFICATION,
      (payload: UserWebNotification) => {
        dispatch({
          type: UserWebNotificationActionTypes.NEW_NOTIFICATION,
          payload,
        });

        toast.message(generateNotificationTitle(payload), {
          description: payload.message || 'No preview available',
        });

        handleNotificationSound(payload);
      }
    );
  }, [userWebNotificationsChannel]);

  const toggleAudio = useAudio(`${window.location.origin}/notification.mp3`);

  const conversations = useConversation();
  const { getConversationById, conversationState } = conversations;

  const handleNotificationSound = async (payload: UserWebNotification) => {
    // if the payload metadata.conversation_id is null then return
    if (!payload.metadata.conversation_id) return;

    // see if the conversation is in the conversations state
    const inState = await isConversationInState(payload.metadata.conversation_id);

    // if the conversation is in state then get the location_id from the conversation
    // then check if that uuid is in the url params
    const channel = window.location.pathname.split('/')[2];

    const locationFilterMatch: boolean = inState?.location_id === channel;

    // play the sound when the locationFilterApplied is true or when the channel is not a valid uuid
    const playSound = locationFilterMatch || !isValidUuid(channel);

    if (playSound) {
      toggleAudio();
    }
  };

  const isConversationInState = async (conversation_id: string) => {
    const inState = conversationState?.conversations?.find(
      (c: ConversationType) => c.id === conversation_id
    );

    if (!inState) {
      const inState = await getConversationById(conversation_id);

      return inState;
    }

    return inState;
  };

  return (
    <UserWebNotificationsContext.Provider
      value={{
        userWebNotificationsState,
        hasUnreadNotifications,
        isNotificationsDrawerOpen,
        openNotificationsDrawer,
        closeNotificationsDrawer,
        getUserWebNotifications,
        bulkMarkNotifications,
        bulkMarkAllNotifications,
      }}
    >
      {children}
    </UserWebNotificationsContext.Provider>
  );
};

export default UserWebNotificationsProvider;
