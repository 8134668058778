import React from 'react';
import { HiShare } from 'react-icons/hi';
import { useCopyToClipboard } from 'react-use';
import { toast } from 'sonner';

import { useDisclosure } from '@/shared/hooks';
import {
  Dialog,
  DialogContent,
  DialogOverlay,
  DialogPortal,
  DialogTrigger,
  Flex,
  IconButton,
  Text,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
  VStack,
} from '@/shared/ui';

type HelpCardProps = {
  title: string;
  description: string;
  loom_id: string;
};

export const HelpCard = (props: HelpCardProps) => {
  const { title, description, loom_id } = props;
  const { isOpen, onClose, onOpen } = useDisclosure();

  const [, copyToClipboard] = useCopyToClipboard();

  const handleCopyClick = () => {
    copyToClipboard(`https://www.loom.com/share/${loom_id}`);
    toast.success('Copied to clipboard');
  };

  return (
    <Dialog open={isOpen}>
      <Flex justify="start" align="start">
        <VStack gap={2}>
          <Flex
            align="center"
            justify="between"
            css={{ width: '100%', textAlign: 'left', pb: 5 }}
          >
            <VStack gap={1}>
              <Text variant="bold" size={3}>
                {title}
              </Text>
              <Text>{description}</Text>
            </VStack>
            <Tooltip>
              <TooltipTrigger asChild>
                <IconButton size={2} onClick={handleCopyClick}>
                  <HiShare />
                </IconButton>
              </TooltipTrigger>
              <TooltipContent>Share Video</TooltipContent>
            </Tooltip>
          </Flex>
          <DialogTrigger>
            <Flex
              onClick={onOpen}
              css={{
                borderRadius: 4,
                maxHeight: '180px',
                minHeight: '180px',
                boxShadow:
                  'rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;',
              }}
            >
              <img
                alt="Loom"
                style={{ borderRadius: 4, cursor: 'pointer', objectFit: 'cover' }}
                src={`https://cdn.loom.com/sessions/thumbnails/${loom_id}-with-play.gif`}
              />
            </Flex>
          </DialogTrigger>
        </VStack>
      </Flex>
      <DialogPortal>
        {/* DialogOverlay needs to be of the same level as DialogContent */}
        <DialogOverlay />
        <DialogContent
          onEscapeKeyDown={() => onClose()}
          onPointerDownOutside={() => onClose()}
          css={{ p: 0, width: '90vh' }}
        >
          <div
            style={{
              position: 'relative',
              paddingBottom: '64.63195691202873%',
              height: 0,
            }}
          >
            <iframe
              title="Help"
              src={`https://www.loom.com/embed/${loom_id}`}
              frameBorder="0"
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
              }}
            />
          </div>
        </DialogContent>
      </DialogPortal>
    </Dialog>
  );
};
