import { Tag, TagActions, TagActionType } from '@/shared/types/tags';

import { TagsState } from './TagsContext';

const TagsReducer: React.Reducer<TagsState, TagActions> = (
  state: TagsState,
  action: TagActions
) => {
  switch (action.type) {
    case TagActionType.GET_ALL_TAGS:
      return {
        ...state,
        allTags: action.payload,
        loading: false,
      } as TagsState;
    case TagActionType.GET_TAGS:
      return {
        ...state,
        tags: action.payload.data,
        totalCount: action.payload.total,
        loading: false,
      } as TagsState;
    case TagActionType.SEARCH_TAG:
      return {
        ...state,
        searchedTags: action.payload,
        loading: false,
      } as TagsState;
    case TagActionType.CREATE_TAG:
      return {
        ...state,
        allTags: action.payload.name ? [...state.allTags, action.payload] : state.allTags,
        tags: action.payload.name ? [...state.tags, action.payload] : state.tags,
        totalCount: state.totalCount + 1,
      } as TagsState;
    case TagActionType.EDIT_TAG:
      return {
        ...state,
        allTags: state.allTags.map((tag: Tag) => {
          return tag.id === action.payload.id ? action.payload : tag;
        }),
        tags: state.tags.map((tag: Tag) => {
          return tag.id === action.payload.id ? action.payload : tag;
        }),
      } as TagsState;
    case TagActionType.DELETE_TAG:
      return {
        ...state,
        allTags: state.allTags.filter((tag: Tag) => {
          return tag !== action.payload;
        }),
        tags: state.tags.filter((tag: Tag) => {
          return tag !== action.payload;
        }),
        totalCount: state.totalCount - 1,
      } as TagsState;
    case TagActionType.SET_CURRENT:
      return {
        ...state,
        current: action.payload,
      } as TagsState;
    case TagActionType.SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      } as TagsState;
    case TagActionType.UPDATE_FILTER_PARAMS:
      return {
        ...state,
        filterParams: action.payload,
        loading: true,
      };
    default:
      return state as TagsState;
  }
};

export default TagsReducer;
