import {
  NotificationsStatus,
  UserWebNotification,
  UserWebNotificationActions,
  UserWebNotificationActionTypes,
} from '@/shared/types/notifications';

import { UserWebNotificationsState } from './UserWebNotificationsContext';

export const UserWebNotificationsReducer = (
  state: UserWebNotificationsState,
  action: UserWebNotificationActions
) => {
  switch (action.type) {
    case UserWebNotificationActionTypes.GET_USER_WEB_NOTIFICATIONS: {
      const filteredNotifications = removeDuplicateNotifications([
        ...state.notifications,
        ...action.payload,
      ]);

      return {
        ...state,
        notifications: filteredNotifications,
        loading: false,
      } as UserWebNotificationsState;
    }
    case UserWebNotificationActionTypes.GET_ARCHIVED_USER_WEB_NOTIFICATIONS: {
      const filteredNotifications = removeDuplicateNotifications([
        ...state.archivedNotifications,
        ...action.payload,
      ]);

      return {
        ...state,
        archivedNotifications: filteredNotifications,
        loading: false,
      } as UserWebNotificationsState;
    }
    case UserWebNotificationActionTypes.SET_USER_WEB_NOTIFICATIONS_LOADING:
      return {
        ...state,
        loading: action.payload,
      } as UserWebNotificationsState;
    case UserWebNotificationActionTypes.NEW_NOTIFICATION:
      return {
        ...state,
        notifications: [action.payload, ...state.notifications],
      } as UserWebNotificationsState;
    case UserWebNotificationActionTypes.UPDATE_NOTIFICATION_STATUS: {
      // if the payload.bulkAction is 'unread' then we need to update the
      // notification status's to 'unread' and add them to the notifications array if they doesn't exist
      // for the payload.ids
      if (action.payload.bulkAction === NotificationsStatus.UNREAD) {
        const updatedNotifications = state.notifications.map((notification) => {
          if (action.payload.ids.includes(notification.id)) {
            return {
              ...notification,
              status: action.payload.bulkAction,
            };
          }
          return notification;
        });
        return {
          ...state,
          notifications: updatedNotifications,
        } as UserWebNotificationsState;
      } else if (action.payload.bulkAction === NotificationsStatus.ARCHIVED) {
        const updatedNotifications = state.notifications.filter(
          (notification) => !action.payload.ids.includes(notification.id)
        );
        return {
          ...state,
          notifications: updatedNotifications,
        } as UserWebNotificationsState;
      } else if (action.payload.bulkAction === NotificationsStatus.READ) {
        const updatedNotifications = state.notifications.map((notification) => {
          if (action.payload.ids.includes(notification.id)) {
            return {
              ...notification,
              status: action.payload.bulkAction,
            };
          }
          return notification;
        });
        return {
          ...state,
          notifications: updatedNotifications,
        } as UserWebNotificationsState;
      }
      return {
        ...state,
      } as UserWebNotificationsState;
    }
    case UserWebNotificationActionTypes.UPDATE_BULK_NOTIFICATION_STATUS:
      {
        if (action.payload.bulkAction === NotificationsStatus.READ) {
          const updatedNotifications = state.notifications.map((notification) => {
            // Only mark those notifications which has status of read or unread, lets not mark archived notifications.
            if (
              [NotificationsStatus.READ, NotificationsStatus.UNREAD].includes(
                notification.status
              )
            ) {
              return {
                ...notification,
                status: NotificationsStatus.READ,
              };
            }
            return notification;
          });
          return {
            ...state,
            notifications: updatedNotifications,
          } as UserWebNotificationsState;
        } else if (action.payload.bulkAction === NotificationsStatus.ARCHIVED) {
          const updatedNotifications = state.notifications.map((notification) => {
            return {
              ...notification,
              status: NotificationsStatus.ARCHIVED,
            };
          });
          return {
            ...state,
            notifications: updatedNotifications,
          } as UserWebNotificationsState;
        }
      }
      return {
        ...state,
      } as UserWebNotificationsState;
  }
};

const removeDuplicateNotifications = (data: (UserWebNotification | null)[]) => {
  if (!data) return [];
  const idSet = new Set();
  return data.filter((notification: UserWebNotification | null) => {
    if (!notification) return false;
    if (!idSet.has(notification.id)) {
      idSet.add(notification.id);
      return true;
    }
    return false;
  });
};
