import { styled } from '@/stitches.config';

import { Box } from './Box';
import { Flex } from './Flex';

export const ComboboxItem = styled(Flex, {
  fontSize: 13,
  width: '100%',
  cursor: 'pointer',
  minHeight: 32,
  px: '$2',
  border: 'none',
  '&:hover': {
    backgroundColor: '$slate2',
  },
  variants: {
    selected: {
      true: {
        backgroundColor: '$slate2',
        borderRadius: 4,
      },
    },
    disabled: {
      true: {
        '&:hover': {
          cursor: 'unset',
          backgroundColor: 'transparent',
        },
      },
    },
  },
});

export const ComboboxItemText = styled(Box, {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});
