import { Formik } from 'formik';
import React from 'react';
import { HiX } from 'react-icons/hi';
import * as Yup from 'yup';

import { useChannels } from '@/pages/settings/organization/channels/context/ChannelContext';
import {
  FormFieldWrapper,
  helpers,
  MultiSelectCombobox,
  TextInput,
} from '@/shared/components/forms';
import { FormMessageEditorV2 } from '@/shared/components/forms/FormMessageEditorV2';
import { useDisclosure } from '@/shared/hooks';
import {
  Button,
  Dialog,
  DialogClose,
  DialogCloseIcon,
  DialogContent,
  DialogFooter,
  DialogOverlay,
  DialogPortal,
  DialogTitle,
  DialogTrigger,
  VStack,
} from '@/shared/ui';

import { useQuestions } from './context/QuestionsContext';

export function AddQuestion(): JSX.Element {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const automationsContext = useQuestions();
  const { createQuestion } = automationsContext;

  const location = useChannels();
  const { channels } = location.channelsState;

  return (
    <Dialog open={isOpen}>
      <DialogTrigger asChild>
        <Button onClick={onOpen}>Create Question</Button>
      </DialogTrigger>
      <DialogPortal>
        <DialogOverlay>
          <DialogContent
            onEscapeKeyDown={onClose}
            onPointerDownOutside={onClose}
            style={{ overflow: 'auto' }}
          >
            <DialogTitle variant="bold">Add a New Question</DialogTitle>
            <Formik
              initialValues={{
                question: '',
                message: {
                  body: '',
                  attachment_urls: [],
                },
                locations: channels.map((location) => location.id),
              }}
              validationSchema={Yup.object({
                question: Yup.string().required('Required'),
                message: Yup.object({
                  body: Yup.string().required('Required'),
                  attachment_urls: Yup.array(),
                }),
                locations: Yup.array().test(
                  'len',
                  'Required',
                  (arr) => Array.isArray(arr) && arr.length > 0
                ),
              })}
              onSubmit={async (values) => {
                const params = {
                  questions: [{ question: values.question }],
                  answer: values.message.body,
                  locations: values.locations,
                  tolerance: 0.1,
                  attachments: values.message.attachment_urls,
                };
                try {
                  createQuestion(params);
                  onClose();
                } catch (e) {
                  console.log(e);
                }
              }}
            >
              {(formik) => (
                <form onSubmit={formik.handleSubmit}>
                  <VStack gap={2}>
                    <FormFieldWrapper
                      label="Question"
                      tooltip="FAQ you would like to automate."
                      name="question"
                    >
                      <TextInput placeholder="E.g. When are you open until?" />
                    </FormFieldWrapper>
                    <FormFieldWrapper
                      label="Message"
                      tooltip="Answer shown when customers ask the question above."
                      name="message"
                    >
                      <FormMessageEditorV2
                        field="message"
                        placeholder="E.g. We are open from..."
                        showAddAttachment={true}
                        enableAttachments={true}
                      />
                    </FormFieldWrapper>
                    {channels.length > 1 ? (
                      <FormFieldWrapper
                        label="Channels"
                        tooltip="Channels you would like to automate this FAQ."
                        name="locations"
                      >
                        <MultiSelectCombobox
                          placeholder={helpers.displaySelectedItems}
                          selectAll={true}
                          isDropdown={true}
                          options={channels.map((location) => ({
                            type: location.name as string,
                            value: location.id as string,
                          }))}
                        />
                      </FormFieldWrapper>
                    ) : null}
                  </VStack>
                  <DialogFooter justify="end">
                    <DialogClose asChild>
                      <Button variant="gray" css={{ mr: '$1' }} onClick={onClose}>
                        Cancel
                      </Button>
                    </DialogClose>
                    <DialogClose asChild>
                      <Button type="submit">Save Automated Response</Button>
                    </DialogClose>
                  </DialogFooter>
                </form>
              )}
            </Formik>
            <DialogCloseIcon onClick={onClose} size="2">
              <HiX size="15px" />
            </DialogCloseIcon>
          </DialogContent>
        </DialogOverlay>
      </DialogPortal>
    </Dialog>
  );
}

export default AddQuestion;
