import { Dispatch, SetStateAction } from 'react';

import { SingleSelect } from '@/shared/components/SingleSelect';
import { Box, Flex } from '@/shared/ui';

import { getLocationName } from './utils';

type LocationMenuProps = {
  locations: any[];
  locationId: string | null;
  setLocationId: Dispatch<SetStateAction<string>>;
};

export const LocationMenu = (props: LocationMenuProps): JSX.Element => {
  const { locations, locationId, setLocationId } = props;
  return (
    <Flex css={{ flexGrow: 1 }} align="center">
      {locations.length < 2 && locations.length > 0 && (
        <Box css={{ fontSize: 15, fontWeight: 500 }}>
          New Message from: {locations[0]?.name}
        </Box>
      )}
      {locations.length > 1 && (
        <Box css={{ width: 300 }}>
          <SingleSelect
            defaultPlaceholder={
              getLocationName(locationId || '', locations) || 'Select a channel'
            }
            isDropdown={true}
            selectItem={locationId || ''}
            setSelectItem={(location: string) => {
              if (!location) return;
              setLocationId(location);
            }}
            options={locations.map((location) => ({
              type: location.name || location.address || '',
              value: location.id,
            }))}
          />
        </Box>
      )}
    </Flex>
  );
};
