import { createContext, useContext, useReducer } from 'react';

import * as API from '@/shared/api/integrations';
import {
  Integration,
  IntegrationActionTypes,
  QueryParams,
} from '@/shared/types/integrations';

import { IntegrationReducer } from './IntegrationsReducer';

export type IntegrationsState = {
  integrations: Array<Integration> | [];
  current: Integration | null;
  loading: boolean;
};

export const initialIntegrationsState: IntegrationsState = {
  integrations: [],
  current: null,
  loading: true,
};

export const IntegrationContext = createContext<{
  integrationsState: IntegrationsState;
  getIntegration: (id: string) => void;
  getIntegrations: () => Promise<Integration[]>;
  filterIntegrations: (params: any) => void;
  setCurrentIntegration: (integration: Integration | null) => void;
  setIntegrations: (integrations: Integration[]) => void;
}>({
  integrationsState: initialIntegrationsState,
  getIntegration: () => Promise.resolve(),
  getIntegrations: () => Promise.resolve([]),
  filterIntegrations: () => Promise.resolve([]),
  setCurrentIntegration: () => Promise.resolve(),
  setIntegrations: () => Promise.resolve(),
});

export const useIntegrations = () => useContext(IntegrationContext);

const IntegrationState = ({ children }: { children: React.ReactNode }) => {
  const [integrationsState, dispatch] = useReducer(
    IntegrationReducer,
    initialIntegrationsState
  );

  const setCurrentIntegration = (integration: Integration | null) => {
    dispatch({
      type: IntegrationActionTypes.SET_CURRENT,
      payload: integration,
    });
  };

  const getIntegration = async (id: string) => {
    try {
      setLoadingResponses(true);
      const integration = await API.getIntegration(id);
      dispatch({
        type: IntegrationActionTypes.GET_INTEGRATION,
        payload: integration,
      });
      return integration;
    } catch (err) {
      console.error(err);
      dispatch({
        type: IntegrationActionTypes.GET_INTEGRATION,
        payload: null,
      });
      return null;
    }
  };

  const getIntegrations = async () => {
    try {
      setLoadingResponses(true);
      const data: any = await API.getIntegrations();
      dispatch({
        type: IntegrationActionTypes.GET_INTEGRATIONS,
        payload: data,
      });
      return data;
    } catch (err) {
      console.error(err);
      return Promise.reject(err);
    }
  };

  const setIntegrations = async (data: Integration[]) => {
    try {
      dispatch({
        type: IntegrationActionTypes.SET_INTEGRATIONS,
        payload: data,
      });
      return data;
    } catch (err) {
      console.error(err);
      return Promise.reject(err);
    }
  };

  const filterIntegrations = async (params: QueryParams) => {
    try {
      setLoadingResponses(true);
      const data = await API.filterIntegrations(params);
      dispatch({
        type: IntegrationActionTypes.FILTER_INTEGRATIONS,
        payload: data,
      });
    } catch (err) {
      console.error(err);
      return Promise.reject(err);
    }
  };

  const setLoadingResponses = (loading: boolean) => {
    dispatch({
      type: IntegrationActionTypes.SET_INTEGRATION_RESPONSES_LOADING,
      payload: loading,
    });
  };

  return (
    <IntegrationContext.Provider
      value={{
        integrationsState,
        getIntegration,
        setCurrentIntegration,
        setIntegrations,
        getIntegrations,
        filterIntegrations,
      }}
    >
      {children}
    </IntegrationContext.Provider>
  );
};

export default IntegrationState;
