import 'react-dayjs-picker/dist/index.css';

import React from 'react';

import { NewContactParams } from '@/shared/api/contacts/v2';
import { Flex } from '@/shared/ui';
import { styled } from '@/stitches.config';

import { ContactForm } from './ContactForm';

type CreateContactProps = {
  /* initial state of name field */
  name?: string;
  /* initial state of phone field */
  phone?: string;
  /* initial state of email field */
  email?: string;
  /* add contact functionality */
  handleCreateContact?: (params: NewContactParams) => void;
  /* close add contact panel */
  onClose?: () => void;
};

export const CreateContact = (props: CreateContactProps) => {
  return (
    <ContactEditorLayout>
      <ContactForm {...props} />
    </ContactEditorLayout>
  );
};

export const ContactEditorLayout = ({ children }: { children?: React.ReactNode }) => {
  return (
    <ContactPanelLayout
      data-testid="ContactEditorLayout"
      direction="column"
      css={{ width: '100%', minWidth: '100%' }}
    >
      {children}
    </ContactPanelLayout>
  );
};

export const ContactPanelLayout = styled(Flex, {
  flexDirection: 'column',
  flexGrow: 1,
  flexShrink: 1,
  flexBasis: 'auto',
  minHeight: '100%',
  height: '100%',
  maxWidth: '100%',
  overflowY: 'auto',
  overflowX: 'hidden',
  '@lg': {
    width: '420px',
    maxWidth: '420px',
    display: 'initial',
  },
});
