import React, { useMemo, useState } from 'react';

import { ConversationScheduledMessageType } from '@/shared/types/conversations';
import { Flex, VStack } from '@/shared/ui';
import { styled } from '@/stitches.config';

import { useConversation } from '../../context/ConversationContext';
import { ConversationScheduledItem } from './ConversationScheduledItem';

export const ScheduledPanel = () => {
  const [hoverItemId, setHoverItemId] = useState<number | null>(null);

  const {
    conversationState: { current },
    deleteScheduledMessage,
    sendScheduledMessageNow,
  } = useConversation();

  // filter out scheduled messages that are not for this conversation
  // only keep one if there are multiple with the same job_id
  const scheduledMessages = useMemo(
    () =>
      current?.scheduled_messages
        ?.filter((scheduled_message: ConversationScheduledMessageType) => {
          return scheduled_message.conversation_id === current.id;
        })
        .reduce(
          (
            acc: Array<ConversationScheduledMessageType>,
            scheduled_message: ConversationScheduledMessageType
          ) => {
            if (acc.find((m) => m.job_id === scheduled_message.job_id)) {
              return acc;
            }
            return [...acc, scheduled_message];
          },
          []
        ),
    [current?.scheduled_messages]
  );

  const mouseEventHandler = (id: number | null) => {
    setHoverItemId(id);
  };

  return (
    <StyledScheduledContainer direction="column">
      <VStack gap={1}>
        {scheduledMessages?.map(
          (scheduled_message: ConversationScheduledMessageType, i: number) => {
            return (
              <ConversationScheduledItem
                key={i}
                scheduledMessage={scheduled_message}
                deleteScheduledMessage={deleteScheduledMessage}
                sendScheduledMessageNow={sendScheduledMessageNow}
                mouseEventHandler={mouseEventHandler}
                hoverItemId={hoverItemId}
              />
            );
          }
        )}
      </VStack>
    </StyledScheduledContainer>
  );
};

const StyledScheduledContainer = styled(Flex, {
  py: 20,
  height: '100%',
  width: '100%',
});
