/* eslint-disable react-hooks/exhaustive-deps */
import { Formik } from 'formik';
import { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { HiX } from 'react-icons/hi';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';

import { useAuth } from '@/pages/auth/context/AuthProvider';
import { FormFieldWrapper, TextInput } from '@/shared/components/forms';
import { FormSelect } from '@/shared/components/forms/Select';
import { Table } from '@/shared/components/Table';
import { useDisclosure, usePageView } from '@/shared/hooks';
import { PageLayout } from '@/shared/layouts/PageLayout';
import { TemplateAccessLevel, TemplateUsage } from '@/shared/types/automations';
import {
  Box,
  Button,
  Dialog,
  DialogClose,
  DialogCloseIcon,
  DialogContent,
  DialogFooter,
  DialogOverlay,
  DialogPortal,
  DialogTitle,
  DialogTrigger,
  Flex,
  HStack,
  Skeleton,
  Td,
  Tr,
  VStack,
} from '@/shared/ui';

import { useAutomationTemplates } from './context/AutomationsTemplatesContext';

const LIMIT = 20;

export const AutomationTemplates = () => {
  usePageView();

  return (
    <>
      <Helmet>
        <title>Whippy | Automation Template</title>
      </Helmet>
      <PageLayout
        breadcrumbs={[
          { title: 'Automations', path: '/automations/keywords' },
          { title: 'Templates', path: '/automations/templates' },
        ]}
        actions={
          <HStack gap="2">
            <AddAutomationTemplate />
          </HStack>
        }
      >
        <Box css={{ p: 30 }}>
          <AutomationTemplatesTable />
        </Box>
      </PageLayout>
    </>
  );
};

export const AutomationTemplatesTable = () => {
  const automationTemplatesContext = useAutomationTemplates();
  const {
    getAutomationTemplates,
    automationTemplatesState,
    setCurrentAutomationTemplate,
  } = automationTemplatesContext;
  const { templates, loading, total } = automationTemplatesState;

  const [limit, setLimit] = useState(LIMIT);
  const [offset, setOffset] = useState(0);

  const history = useHistory();

  const auth = useAuth();

  useEffect(() => {
    getAutomationTemplates({
      limit,
      offset,
      archived: false,
      organization_id: auth?.organizationId, // assure the access level of the user
    });

    setCurrentAutomationTemplate(null);
  }, [offset]);

  useEffect(() => {
    // on unmount set offset to 0
    return () => {
      setOffset(0);
    };
  }, []);

  const data = useMemo(
    () => (loading ? Array(5).fill({}) : templates),
    [loading, templates]
  );

  const columns = useMemo(
    () =>
      loading
        ? [
            {
              Header: 'Templates',
              colWidth: '100%',
              Cell: (
                <>
                  <Skeleton variant="subheading" css={{ maxWidth: '50%', m: 0 }} />
                  <Skeleton variant="subheading" css={{ maxWidth: '80%', mb: 0 }} />
                </>
              ),
            },
            {
              Header: (): null => null,
              disableSortBy: true,
              id: 'expander',
              Cell: <Skeleton variant="icon" />,
            },
          ]
        : [
            {
              Header: 'Templates',
              colWidth: '100%',
              Cell: ({ row }: any) => {
                return <Box>{row?.original?.title}</Box>;
              },
            },
            {
              Header: '-',
              Cell: ({ row }: any) => {
                return (
                  <Box css={{ width: 120 }}>
                    <Button
                      variant="gray"
                      onClick={() =>
                        history.push(`/automations/templates/${row?.original?.id}`)
                      }
                    >
                      Edit Template
                    </Button>
                  </Box>
                );
              },
            },
          ],
    [loading]
  );

  return (
    <Table
      data={data}
      columns={columns}
      limit={LIMIT}
      offset={offset}
      setLimit={setLimit}
      setOffset={setOffset}
      loading={loading}
      totalCount={total}
      empty={
        <Tr>
          <Td
            colSpan={columns.length}
            css={{ textAlign: 'center', backgroundColor: 'white' }}
          >
            <Flex css={{ width: '100%' }} direction="column">
              <VStack gap={3}>
                <Box>Create your first automation template.</Box>
                <Box>
                  <AddAutomationTemplate />
                </Box>
              </VStack>
            </Flex>
          </Td>
        </Tr>
      }
    />
  );
};

export const AddAutomationTemplate = () => {
  const automationTemplatesContext = useAutomationTemplates();
  const { createAutomationTemplate } = automationTemplatesContext;

  const history = useHistory();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleCreateAutomationTemplate = async (values: {
    title: string;
    access_level: TemplateAccessLevel;
    usage: TemplateUsage;
  }) => {
    const template = await createAutomationTemplate({
      title: values.title,
      access_level: values.access_level,
      usage: values.usage,
    });

    if (template && template.id) {
      history.push(`/automations/templates/${template.id}`);
      onClose();
    }
  };

  return (
    <Dialog open={isOpen}>
      <DialogTrigger asChild onClick={() => onOpen()}>
        <Button variant="primary">Create Template</Button>
      </DialogTrigger>
      <DialogPortal>
        <DialogOverlay />
        <DialogContent
          onEscapeKeyDown={() => onClose()}
          onPointerDownOutside={() => onClose()}
          css={{ overflow: 'auto' }}
        >
          <DialogTitle variant="bold">Create Template</DialogTitle>
          <Formik
            enableReinitialize
            initialValues={{
              title: '',
              access_level: TemplateAccessLevel.ORGANIZATION,
              usage: TemplateUsage.AS_IS,
            }}
            validationSchema={Yup.object({
              title: Yup.string().required('Required'),
              access_level: Yup.string().required('Required'),
              usage: Yup.string().required('Required'),
            })}
            onSubmit={async (values) => {
              await handleCreateAutomationTemplate(values);
            }}
          >
            {(formik) => (
              <form onSubmit={formik.handleSubmit}>
                <FormFieldWrapper
                  label="Title"
                  tooltip="The title of the template."
                  name="title"
                >
                  <TextInput />
                </FormFieldWrapper>
                <FormFieldWrapper
                  label="Access Level"
                  tooltip="The access level of the template."
                  name="access_level"
                >
                  <FormSelect
                    placeholder="Access Levels"
                    options={[
                      {
                        label: 'Organization',
                        value: TemplateAccessLevel.ORGANIZATION,
                      },
                      {
                        label: 'User',
                        value: TemplateAccessLevel.USER,
                      },
                    ]}
                  />
                </FormFieldWrapper>
                <FormFieldWrapper
                  label="Usage"
                  tooltip="The usage of the template."
                  name="usage"
                >
                  <FormSelect
                    placeholder="Usage"
                    options={[
                      {
                        label: 'As Is',
                        value: TemplateUsage.AS_IS,
                      },
                      {
                        label: 'Duplicate',
                        value: TemplateUsage.DUPLICATE,
                      },
                    ]}
                  />
                </FormFieldWrapper>
                <DialogFooter justify="end">
                  <DialogClose asChild>
                    <Button type="submit">Create Template</Button>
                  </DialogClose>
                </DialogFooter>
              </form>
            )}
          </Formik>
          <DialogCloseIcon onClick={() => onClose()} size="2">
            <HiX size="15px" />
          </DialogCloseIcon>
        </DialogContent>
      </DialogPortal>
    </Dialog>
  );
};
