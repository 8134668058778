/* eslint-disable react-hooks/exhaustive-deps */
import React, { Dispatch, SetStateAction } from 'react';

import { Channel, ChannelTypes } from '@/shared/types/channels';
import { Box, Flex, RadioGroup, RadioGroupIndicator, RadioGroupRadio } from '@/shared/ui';

import { renderChannelIcon } from '../utils/ChannelIcons';

export const channel_type_config = [
  {
    value: ChannelTypes.PHONE,
    icon: renderChannelIcon(ChannelTypes.PHONE),
    label: 'Phone',
    description: 'Phone channels are used for Voice, SMS and MMS communication.',
  },
  {
    value: ChannelTypes.EMAIL,
    icon: renderChannelIcon(ChannelTypes.EMAIL),
    label: 'Email',
    description: 'Email channels are used for email communication.',
  },
  {
    value: ChannelTypes.WHATSAPP,
    icon: renderChannelIcon(ChannelTypes.WHATSAPP),
    label: 'WhatsApp',
    description: 'WhatsApp channels are used for WhatsApp Business messaging.',
  },
];

export const SelectChannelType = ({
  channel,
  setChannel,
}: {
  channel: Partial<Channel>;
  setChannel: Dispatch<SetStateAction<Partial<Channel>>>;
}) => {
  const handleTypeChange = (value: string) => {
    setChannel((prevChannel) => ({
      ...prevChannel,
      type: value as ChannelTypes,
    }));
  };

  return (
    <Flex direction="column" css={{ width: '100%', marginBottom: '20px' }}>
      <RadioGroup value={channel.type} onValueChange={handleTypeChange}>
        <Flex direction="column" css={{ gap: '20px' }}>
          {channel_type_config.map((type) => (
            <Flex key={type.value} direction="column" css={{ gap: '5px' }}>
              <Flex align="center" css={{ gap: '10px' }}>
                <RadioGroupRadio value={type.value}>
                  <RadioGroupIndicator />
                </RadioGroupRadio>
                {type.icon && (
                  <Box css={{ width: '15px', height: '15px' }}>{type.icon}</Box>
                )}
                <Box css={{ fontSize: '14px', fontWeight: '500' }}>{type.label}</Box>
              </Flex>
              <Box css={{ fontSize: '12px', color: '#666' }}>{type.description}</Box>
            </Flex>
          ))}
        </Flex>
      </RadioGroup>
    </Flex>
  );
};
