export const TIME_STAMP = 'h:mm a';
export const DAY_STAMP = 'MMM Do';
export const DATE_TIME_STAMP = 'MMM D hh:mm a';
export const DATE_TIME_HOURLY = 'h:mm A';
export const DATE_TIME_STAMP_YEAR = 'MMM D, YYYY • h:mm A';
export const MONTH_STAMP = 'MMM D';
export const HOUR_STAMP = 'HH:mm';
export const MONTH_DAY = 'MMM DD';
export const MONTH_DAY_YEAR = 'MMM DD, YYYY';
export const DAY_OF_WEEK_STAMP = 'dddd, MMM D';
