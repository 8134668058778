export const inbox_help = [
  {
    title: 'Inbox',
    description: 'Become an Inbox power user. Save time and speed up your workflow',
    videos: [
      {
        title: 'Start a New Conversation',
        description: 'Learn how to start a new conversation.',
        loom_id: '5f215fa2338b4054a9209ed9d46a8403',
      },
      {
        title: 'Schedule a Message',
        description: 'Schedule a message to send later.',
        loom_id: 'a47057d1b41e497aa0e04c6cc3dcb6a5',
      },
      {
        title: 'Search & Filter',
        description: 'Track down the right conversations.',
        loom_id: '457d460cf9be4ccfa8b2c5e2b32101d0',
      },
      {
        title: 'Send Attachments',
        description: 'Send media to your contacts.',
        loom_id: '49580aa26e8348f5baeb838ce016202f',
      },
      {
        title: 'Create & Use Templates',
        description: 'Save frequent replies for faster responses.',
        loom_id: '9de66882679247c2a1206c052c12ba97',
      },
    ],
  },
];

export const campaigns_help = [
  {
    title: 'Campaigns',
    description:
      'Broadcast a messages to your subscribers & measure results with advanced analytics',
    videos: [
      {
        title: 'Create a Campaign',
        description: 'Broadcast a Message to your subscribers.',
        loom_id: '42fc70e6d0264ae9a8ff69087dd85031',
      },
      {
        title: 'View Campaign Analytics',
        description: 'View campaign analytics & create follow ups.',
        loom_id: '99d4712dbc944797949a256c760bad31',
      },
      {
        title: 'Create Quick Campaign',
        description: 'Quickly create a campaign from any page.',
        loom_id: '37045bd699ff44cfa320d7477a9ea97b',
      },
      {
        title: 'Merge Area Code & Prefix',
        description: 'Merge fields during upload (search wizard).',
        loom_id: '9e708e62a6d7424e922fd6909f507082',
      },
    ],
  },
];
