/* eslint-disable react-hooks/exhaustive-deps */
import { CheckedState } from '@radix-ui/react-checkbox';
import { debounce } from 'lodash';
import React, { useEffect, useState } from 'react';
import { HiSearch } from 'react-icons/hi';
import { useMedia } from 'react-use';

import * as API from '@/shared/api/contacts/v1';
import { Contact } from '@/shared/types';
import { Box, Checkbox, Flex, Input } from '@/shared/ui';
import { phoneFormatting, phoneRegex } from '@/shared/utils/validations/validations';
import { styled } from '@/stitches.config';

import { ContactRowContainer } from './ContactRow';
import { useContacts } from './context/ContactContext';

type ContactSearchProps = {
  /** array of contacts to search */
  contacts: Array<Contact>;
  /** an array of rendered contacts */
  renderedContacts: Array<Contact>;
  /** an array of checked contacts */
  checkedContacts: Array<Contact>;
  /** is this a grouped contacts page? */
  isGroupsPage: boolean | undefined;
  /** is there input in search bar or are filters applied? */
  searchOrFiltersApplied: boolean;
  /** are all contacts checked? */
  allContactsChecked: boolean;
  /** are contacts from search result checked? */
  searchResultChecked: boolean;
  /** is this a uploaded contacts page? */
  isUpload: boolean;
  /** contacts page filters */
  filters: any;
  /** call function to check a contact */
  checkContact: (contacts: Contact[]) => void;
  /** call function to search contacts  */
  searchContacts: (contacts: Array<Contact>) => void;
  /** call function to check search result contacts */
  setSearchResultChecked: (a: boolean) => void;
  /** call function to check all contacts */
  setAllContactsChecked: (b: boolean) => void;
  /** updates checked contacts array */
  setCheckedContacts: (a: Array<Contact>) => void;
  /** search value */
  searchValue: string;
  /** updates search value */
  setSearchValue: (a: string) => void;
};

export const ContactsSearch = (props: ContactSearchProps): JSX.Element => {
  const isDesktop = useMedia('(min-width: 912px)');
  const contacts = useContacts();
  const { setCurrent, setIsSearched, contactState } = contacts;
  const { currentUploadId, isFiltered } = contactState;
  const [state, setState] = useState(false);

  // take the search input and filter the contacts accordingly
  const searchInputChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.toLowerCase();

    props.setSearchValue(value);

    const isContactName = phoneRegex.containsAlphabet.test(value);
    const searchParamsList = isFiltered ? props.filters['search'] : [];
    const uploadId = props.isUpload ? currentUploadId : '';

    // if value is more than 1 chars
    if (value.length > 1) {
      setState(false);

      if (isContactName) {
        // if value is name
        const searchedContactsByName = await API.searchContacts(
          value,
          '',
          uploadId as string,
          searchParamsList
        );
        setIsSearched(true);
        props.searchContacts(searchedContactsByName);
      } else {
        //format phone number
        const formattedValue = phoneFormatting(value);

        // if value is phone
        const searchedContactsByPhone = await API.searchContacts(
          '',
          formattedValue,
          uploadId as string,
          searchParamsList
        );
        setIsSearched(true);
        props.searchContacts(searchedContactsByPhone);
      }
    }

    if (value === '') {
      setState(false);
      setIsSearched(false);
      props.searchContacts(props.contacts);
    }
  };

  // check all the contacts in the list;
  // updates the state with the new checked value
  const onContactCheckAll = (checked: CheckedState) => {
    setState(checked as boolean);

    if (checked) {
      props.checkContact(props.renderedContacts);
      setCurrent(null);
    } else {
      props.checkContact([]);
      setCurrent(null);
    }
  };

  // tracks changes in the state and
  // updates the flags used to check/uncheck contacts
  useEffect(() => {
    if (state) {
      if (props.searchOrFiltersApplied) {
        props.setSearchResultChecked(state);
        props.setAllContactsChecked(!state);
      } else {
        props.setSearchResultChecked(!state);
        props.setAllContactsChecked(state);
      }
    } else {
      props.setAllContactsChecked(false);
      props.setSearchResultChecked(false);
    }
  }, [state]);

  // tracks changes in the lists of checked and rendered contacts
  // and sets state accordingly
  useEffect(() => {
    let edgeCase = false;
    if (props.checkedContacts.length > 0 && props.renderedContacts.length > 0) {
      edgeCase = props.checkedContacts
        .map((contact: Contact) => contact.id)
        .includes(props.renderedContacts[0].id); // this fixes an edge case
    }

    if (
      props.checkedContacts.length > 0 &&
      props.checkedContacts.length === props.renderedContacts.length &&
      edgeCase
    ) {
      setState(true);
    } else {
      if (!props.allContactsChecked) {
        setState(false);
      }
    }
  }, [props.checkedContacts, props.renderedContacts, props.searchOrFiltersApplied]);

  useEffect(() => {
    return () => {
      props.setSearchValue('');
      setIsSearched(false);
    };
  }, []);

  return (
    <ContactRowContainer align="center" justify="start" variant="search" css={{ py: 25 }}>
      {isDesktop && (
        <Flex align="center" css={{ pr: 10 }}>
          <Checkbox onCheckedChange={onContactCheckAll} checked={state} />
        </Flex>
      )}
      <HiSearch />
      <Box css={{ width: '100%' }}>
        <ContactSearchBar
          placeholder="Search Contacts"
          variant="ghost"
          onChange={debounce(searchInputChange, 250)}
        />
      </Box>
    </ContactRowContainer>
  );
};

const ContactSearchBar = styled(Input, {
  width: '100%',
  minWidth: '100%',
  minHeight: '100%',
  ml: '10px',
});
