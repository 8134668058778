import { api } from '../api';

type ChatCompletionParams = {
  model: string;
  options: {
    frequency_penalty: number;
    max_tokens: number;
    n: number;
    presence_penalty: number;
    temperature: number;
    top_p: number;
  };
  messages: Message[];
};

export type Message = {
  role: string;
  content: string;
};

type ChatCompletionResponse = {
  response: string;
  usage: {
    completion_tokens: number;
    prompt_tokens: number;
    total_tokens: number;
  };
};

export const createChatCompletion = async (
  params: ChatCompletionParams,
  controller?: AbortController
): Promise<ChatCompletionResponse> => {
  if (controller && controller.signal) {
    return api
      .post<ChatCompletionResponse>('/chat', params, {
        signal: controller?.signal,
      })
      .then((response) => response.data);
  } else {
    return api
      .post<ChatCompletionResponse>('/chat', params)
      .then((response) => response.data);
  }
};
