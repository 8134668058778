import React, { useState } from 'react';
import { Virtuoso } from 'react-virtuoso';

import {
  ConversationFilterTypes,
  ConversationStatusTypes,
  ConversationType,
  SearchType,
} from '@/shared/types/conversations';

import { ConversationPreview } from './ConversationPreview';
import { SEARCH_CONVERSATIONS_LIMIT } from './utils';

export type VirtualisedConversationsListProps = {
  /** The list of conversations to display */
  conversations: Array<ConversationType>;
  /** The length of the conversations */
  length: number;
  /** The offset for the conversations request */
  offset: number;
  /** The function to call when the user scrolls to the bottom of the list */
  setOffset: (a: number) => void;
  /** ConversationType URL params */
  slug: Record<string, string>;
  /** ConversationType filter all | me | unassigned | location_id */
  filter: string;
  /** ConversationType tab */
  tab: string;
  /** The search type used to filter the conversations */
  searchType?: SearchType;
};

// only render the conversation previews in the viewport
export function VirtualisedConversationsList(
  props: VirtualisedConversationsListProps
): JSX.Element {
  const { conversations, setOffset, slug, filter, tab, searchType = 'none' } = props;

  // create a unique key for each conversation preview by tab
  function generateKey(index: number, conversation: ConversationType) {
    return `${index}-${conversation.id}-${tab}`;
  }

  // fallback ensure there are no duplicates in the list
  // probably a bug in state that we can fix to prevent needing this
  const uniqueConversations = Array.from(
    new Set(conversations?.map((c: ConversationType) => c.id))
  ).map((id) => {
    return conversations?.find((c: ConversationType) => c.id === id);
  });

  // update the offset when the user scrolls to the bottom of the list
  const handleEndReached = () => {
    setOffset(props.offset + SEARCH_CONVERSATIONS_LIMIT);
  };

  const [checkboxHoverId, setCheckboxHoverId] = useState<string | null>(null);

  const mouseEventHandler = (id: string | null) => {
    setCheckboxHoverId(id);
  };

  return (
    <Virtuoso
      data={uniqueConversations as ConversationType[]}
      atBottomStateChange={(atBottom) => {
        if (atBottom) {
          handleEndReached();
        }
      }}
      totalCount={uniqueConversations.length}
      computeItemKey={generateKey}
      itemContent={(index: number, conversation: ConversationType) => (
        <ConversationPreview
          key={`${index}-${conversation?.id}-${tab}`}
          checkboxHoverId={checkboxHoverId}
          mouseEventHandler={mouseEventHandler}
          conversation={conversation}
          isActive={slug.id === conversation.id ? true : false}
          tab={conversation.status || ConversationStatusTypes.OPEN}
          filter={filter || ConversationFilterTypes.ALL}
          searchType={searchType}
        />
      )}
    />
  );
}
