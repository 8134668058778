import {
  CreateProviderAccountParams,
  ProviderAccountItem,
  ProviderAccountsApiResponse,
} from '@/shared/types/channels/accounts';

import { api } from '../api';

export const getProviderAccounts = (params: {
  filter: Array<{
    column: string;
    comparison: string;
    resource: string;
    value: string;
  }>;
  sort: Array<{
    column: string;
    order: string;
    resource: string;
  }>;
  limit: number;
  offset: number;
}) =>
  api
    .post<ProviderAccountsApiResponse>('/v2/providers/accounts/search', { ...params })
    .then((res) => res.data.data);

export const getProviderAccount = (id: string) =>
  api.get<ProviderAccountItem>(`/v2/providers/accounts/${id}`).then((res) => res.data);

export const createProviderAccount = (params: CreateProviderAccountParams) =>
  api
    .post<ProviderAccountItem>('/v2/providers/accounts', {
      provider_account: { ...params },
    })
    .then((res) => res.data);

export const updateProviderAccount = (id: string, params: ProviderAccountItem) =>
  api
    .put<ProviderAccountItem>(`/v2/providers/accounts/${id}`, {
      provider_account: { ...params },
    })
    .then((res) => res.data);
