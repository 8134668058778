import { Call } from '@twilio/voice-sdk';
import { Formik, FormikProps } from 'formik';
import React, { useCallback } from 'react';

import { FormFieldWrapper, TextInput } from '@/shared/components/forms';
import { Box, Flex } from '@/shared/ui';
import { styled } from '@/stitches.config';

export const VoIPKeypad = ({ call }: { call?: Call | null }) => {
  // keys array for keypad
  const keys = [
    {
      value: '1',
    },
    {
      value: '2',
      label: 'ABC',
    },
    {
      value: '3',
      label: 'DEF',
    },
    {
      value: '4',
      label: 'GHI',
    },
    {
      value: '5',
      label: 'JKL',
    },
    {
      value: '6',
      label: 'MNO',
    },
    {
      value: '7',
      label: 'PQRS', // spell-checker:disable-line
    },
    {
      value: '8',
      label: 'TUV',
    },
    {
      value: '9',
      label: 'WXYZ', // spell-checker:disable-line
    },
    {
      value: '*',
    },
    {
      value: '0',
      label: '+',
    },
    {
      value: '#',
    },
  ];

  /**
   * This function handle key press and updates the input digits value,
   * then send DTMF digits to the twilio.
   *
   * @param keyValue - The value of key.
   * @param formik - The formik object to get and set input value.
   */
  const handleKeyPress = useCallback(
    (keyValue: string, formik: FormikProps<{ digits: string }>) => () => {
      const newValue = formik.getFieldProps('digits').value + keyValue;
      // set new input value
      formik.setFieldValue('digits', newValue);
      // send DTMF digits to twilio
      call?.sendDigits(newValue);
    },
    [call]
  );

  return (
    <Box css={{ flex: 1, paddingTop: 112 }}>
      <Formik
        enableReinitialize
        initialValues={{
          digits: '',
        }}
        onSubmit={async (values) => {
          console.log(values);
        }}
      >
        {(formik) => (
          <form onSubmit={formik.handleSubmit}>
            <FormFieldWrapper name="digits">
              <TextInput
                css={{
                  background: 'transparent',
                  color: '#fff',
                  boxShadow: 'none',
                  textAlign: 'center',
                  fontSize: 28,
                  fontWeight: '500',
                  '&:focus': {
                    boxShadow: 'none',
                  },
                }}
              />
            </FormFieldWrapper>
            <Box css={{ px: 40, py: 32 }}>
              <Flex align="center" wrap="wrap" justify="center">
                {keys.map((key) => (
                  <KeypadButton
                    key={key.value}
                    onClick={handleKeyPress(key.value, formik)}
                  >
                    <Box>{key.value}</Box>
                    <Box css={{ fontSize: 12, color: '#ADB1B8' }}>{key.label}</Box>
                  </KeypadButton>
                ))}
              </Flex>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

export const KeypadButton = styled('div', {
  cursor: 'pointer',
  display: 'flex',
  flexDirection: 'column',
  width: 56,
  height: 56,
  background: '#BDC8FF17',
  borderRadius: '50%',
  margin: 12,
  alignItems: 'center',
  fontSize: 20,
  lineHeight: '20px',
  fontWeight: 700,
  color: '#fff',
  padding: 8,
  '&:hover': {
    background: '#C7D6FE20',
  },
});
