import React from 'react';

import { Flex } from '@/shared/ui';

type PdfViewerProps = {
  src: string;
};

export const PdfViewer = (props: PdfViewerProps) => {
  const { src } = props;
  return (
    <Flex
      align="center"
      justify="center"
      css={{
        width: '100%',
        height: '100%',
      }}
    >
      <iframe
        title="Pdf Attachment"
        src={src}
        frameBorder="0"
        style={{ width: '100%', height: '100%' }}
      />
    </Flex>
  );
};
