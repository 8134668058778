import React from 'react';
import {
  HiArchive,
  HiCheckCircle,
  HiDotsHorizontal,
  HiOutlinePaperClip,
} from 'react-icons/hi';
import { useHistory } from 'react-router-dom';

import { useAuth } from '@/auth/context/AuthProvider';
import { useUserWebNotifications } from '@/shared/components/notifications/context/UserWebNotificationsContext';
import { generateNotificationTitle } from '@/shared/components/notifications/utils';
import { NotificationsStatus, UserWebNotification } from '@/shared/types/notifications';
import {
  Box,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  Flex,
  IconButton,
} from '@/shared/ui';
import { CSS, styled } from '@/stitches.config';

type StyledBoxProps = {
  status: NotificationsStatus;
} & CSS;

const NotificationsCard = ({ notification }: { notification: UserWebNotification }) => {
  const { bulkMarkNotifications, closeNotificationsDrawer } = useUserWebNotifications();
  const auth = useAuth();
  const history = useHistory();

  const isClickable = notification.metadata.conversation_id;

  const handleNotificationClick = (event: React.SyntheticEvent) => {
    if (!notification.metadata.conversation_id) return;

    if (notification.organization_id === auth.organizationId) {
      history.push(`/inbox/all/open/${notification.metadata.conversation_id}`);
    } else {
      history.push(
        `/organizations/${notification.organization_id}/inbox/${notification.metadata.conversation_id}`
      );
    }

    closeNotificationsDrawer();

    if (notification.status === NotificationsStatus.UNREAD) {
      bulkMarkNotifications(NotificationsStatus.READ, [notification.id], event);
    }
  };

  return (
    <NotificationCard
      key={notification.id}
      style={!isClickable ? { cursor: 'default' } : {}}
      status={notification.status}
      onClick={handleNotificationClick}
      data-testid="notification-card"
    >
      <Flex justify="between" align="start">
        {notification.status === NotificationsStatus.UNREAD && <UnreadDot />}
        <Box>
          <NotificationTitle status={notification.status as any}>
            {generateNotificationTitle(notification)}
          </NotificationTitle>
          <Flex align="center">
            <Box css={{ mt: 4, fontSize: 12, fontWeight: 500, color: '#687076', mr: 6 }}>
              {getTimeSent(notification.inserted_at)}
            </Box>
            <Dot />
            <Box css={{ mt: 7, fontSize: 12, fontWeight: 500, color: '#687076', ml: 6 }}>
              {notification.metadata.organization_name}
            </Box>
          </Flex>
          {/* if the message is null or undefined its a message with no body and just an attachment, so we render an attachment icon */}
          <NotificationMessage>
            {notification.message ? (
              <Box>
                <Box>{notification.message}</Box>
              </Box>
            ) : (
              <AttachmentPreview />
            )}
          </NotificationMessage>
        </Box>
        {notification.status !== NotificationsStatus.ARCHIVED && (
          <Flex css={{ pl: '10px' }}>
            <DropdownMenu>
              <DropdownMenuTrigger asChild data-testid="notification-menu-button">
                <IconButton variant="outline" size="2">
                  <HiDotsHorizontal />
                </IconButton>
              </DropdownMenuTrigger>
              <DropdownMenuContent
                sideOffset={4}
                align="end"
                onClick={(event) => event.stopPropagation()}
              >
                <DropdownMenuItem
                  onClick={(event) =>
                    bulkMarkNotifications(
                      NotificationsStatus.ARCHIVED,
                      [notification.id],
                      event
                    )
                  }
                >
                  <Flex>
                    <HiArchive />
                    <Box css={{ pl: 6 }}>Archive</Box>
                  </Flex>
                </DropdownMenuItem>

                {notification.status === NotificationsStatus.UNREAD && (
                  <DropdownMenuItem
                    onClick={(event) =>
                      bulkMarkNotifications(
                        NotificationsStatus.READ,
                        [notification.id],
                        event
                      )
                    }
                  >
                    <Flex>
                      <HiCheckCircle />
                      <Box css={{ pl: 6 }}>Mark as read</Box>
                    </Flex>
                  </DropdownMenuItem>
                )}

                {notification.status === NotificationsStatus.READ && (
                  <DropdownMenuItem
                    onClick={(event) =>
                      bulkMarkNotifications(
                        NotificationsStatus.UNREAD,
                        [notification.id],
                        event
                      )
                    }
                  >
                    <Flex>
                      <HiCheckCircle />
                      <Box css={{ pl: 6 }}>Mark as unread</Box>
                    </Flex>
                  </DropdownMenuItem>
                )}
              </DropdownMenuContent>
            </DropdownMenu>
          </Flex>
        )}
      </Flex>
    </NotificationCard>
  );
};

export default NotificationsCard;

const getTimeSent = (dateString: string) => {
  // Date string from the backend are returned in UTC
  // Adding 'Z' to the end of the time string so that the Date object interprets it as UTC
  const utcDateString = dateString.endsWith('Z') ? dateString : dateString + 'Z';
  const date = new Date(utcDateString);
  const now = new Date();

  // Calculate the between the notification time & now (in milliseconds)
  const millisecondsDiff = now.getTime() - date.getTime();

  // Calculate the difference in seconds, minutes, and hours
  const secondsDiff = Math.floor(millisecondsDiff / 1000);
  const minutesDiff = Math.floor(secondsDiff / 60);
  const hoursDiff = Math.floor(minutesDiff / 60);

  // Notifications are delayed by 1 minute so we can check if a conversation has been read before
  // the notification arrives, therefore we set the 'Just Now' message to show at anything < 2 mins
  if (minutesDiff < 2) return 'Just Now';
  // If it was more than a minute but less than an hour ago
  else if (minutesDiff < 60) return `${minutesDiff} minutes ago`;
  // If it was more than an hour but less than a day ago
  else if (hoursDiff < 24) return `${hoursDiff} hour${hoursDiff > 1 ? 's' : ''} ago`;
  // If it was more than a day ago
  else {
    const day = date.getUTCDate();
    const month = date.toLocaleString('default', { month: 'long' });
    return `${day} ${month}`;
  }
};

// When the message is null or undefined its a message with no body and just an attachment
export const AttachmentPreview = () => {
  return (
    <Flex
      align="center"
      css={{ alignItems: 'center', mt: 4, fontSize: 14, lineHeight: '20px' }}
    >
      <HiOutlinePaperClip
        size={12}
        style={{
          marginRight: '2px',
          transform: 'rotate(90deg) scaleY(-1)',
        }}
      />{' '}
      Attachment
    </Flex>
  );
};

const NotificationCard = styled('div', {
  position: 'relative',
  p: '15px 25px',
  borderBottom: '1px solid #EDEDED',
  cursor: 'pointer',
  variants: {
    status: {
      [NotificationsStatus.UNREAD]: {
        backgroundColor: '#FAFAFA',
        '&:hover': {
          backgroundColor: '#F2F2F2',
        },
      },
      [NotificationsStatus.READ]: {
        backgroundColor: 'white',
        '&:hover': {
          backgroundColor: '#F2F2F2',
        },
      },
      [NotificationsStatus.ARCHIVED]: {
        backgroundColor: 'white',
        '&:hover': {
          backgroundColor: '#F2F2F2',
        },
      },
    },
  },
}) as React.FC<StyledBoxProps>;

const NotificationTitle = styled('h6', {
  margin: 0,
  fontSize: 14,
  pb: 1,
  fontWeight: 500,
  variants: {
    status: {
      [NotificationsStatus.UNREAD]: {
        fontWeight: 700,
      },
    },
  },
});

const NotificationMessage = styled('div', {
  mt: 4,
  fontSize: 14,
  lineHeight: '20px',
});

const Dot = styled(Box, {
  display: 'inline-block',
  width: '5px',
  height: '5px',
  borderRadius: '50%',
  backgroundColor: '#687076',
  mt: 5,
});

const UnreadDot = styled('span', {
  position: 'absolute',
  top: 19,
  left: 8,
  width: '13px',
  height: '13px',
  borderRadius: '50%',
  backgroundColor: '$primaryColor',
  border: '2px solid white',
});
