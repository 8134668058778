import React, { memo, SetStateAction, useCallback, useMemo, useState } from 'react';
import { HiOutlineChevronDown as ChevronDownIcon } from 'react-icons/hi';
import { useParams } from 'react-router-dom';

import { MultiSelect } from '@/shared/components/MultiSelect';
import { TeamMember as TeamMemberType } from '@/shared/types/team';
import { User } from '@/shared/types/users';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
  Button,
  Divider,
  Fieldset,
  Flex,
  Label,
  Text,
  VStack,
} from '@/shared/ui';

import { useUsers } from '../../users/context/UserContext';
import { useTeams } from '../context/TeamsContext';
import { TeamMemberItem } from './TeamMemberItem';

const LIMIT = 10;

type TeamMembersProps = {
  selectedUsers: { users: Array<number> };
  setSelectedUsers: (value: SetStateAction<{ users: never[] }>) => void;
};

export const TeamMembers = memo(
  ({ selectedUsers, setSelectedUsers }: TeamMembersProps) => {
    const {
      userState: { users },
    } = useUsers();

    const {
      deleteTeamMember,
      teamsState: { current },
    } = useTeams();

    const params = useParams<{ id: string }>();

    const [offset, setOffset] = useState<number>(0);

    // remove the selected team member from the team
    const onItemDelete = useCallback(
      async (itemId: string) => {
        if (itemId) {
          current?.id && (await deleteTeamMember(current?.id, itemId));
        }
      },
      [current?.id, deleteTeamMember]
    );

    // team members list
    const members = useMemo(() => {
      return current?.team_members
        ?.slice(0, offset + LIMIT)
        ?.map((item: TeamMemberType) => {
          const user = users?.find((user: User) => user.id === item.user_id);
          return {
            ...item,
            user_id: user?.id || 0,
            attachment: user?.attachment,
            name: user?.name || user?.email || '',
          };
        });
    }, [current?.team_members, users, offset]);

    // is Show More button visible or not
    const isShowMoreVisible = useMemo(() => {
      return offset + LIMIT < (current?.team_members?.length || 0);
    }, [current?.team_members, offset]);

    // handle show more members
    const handleShowMoreTeamMembers = useCallback(() => {
      setOffset((prevOffset) => prevOffset + LIMIT);
    }, []);

    // filtered users
    const filteredItems = useMemo(() => {
      const userList = (users as User[])?.filter((user) => {
        return !current?.team_members?.find((m) => m.user_id === user.id);
      });
      return userList?.map((user: User) => ({
        type: user?.name || user?.email || '',
        value: user.id,
      }));
    }, [users, current?.team_members]);

    return (
      <Accordion type="single" collapsible defaultValue="team-members">
        <AccordionItem value="team-members" variant="neumorphic">
          <Flex justify="between" align="center">
            <VStack gap={2}>
              <Text css={{ fontWeight: 600 }}>Members</Text>
              <Text>Add or remove member of this team</Text>
            </VStack>
            <AccordionTrigger data-testid="team-members-open-trigger" />
          </Flex>
          <AccordionContent variant="neumorphic">
            <Divider css={{ mt: -20, mb: 20 }} />
            <Fieldset css={{ marginBottom: 0 }}>
              <Label>Team Members</Label>
              <MultiSelect
                defaultPlaceholder="Search name or email"
                defaultSelectedItems={[]}
                isDropdown={true}
                options={filteredItems}
                parentSelectedItems={selectedUsers.users}
                setParentSelectedItems={setSelectedUsers}
                isCampaigns={true}
                clearSelectedItems={!selectedUsers.users.length}
              />
            </Fieldset>
            <Flex direction="column">
              {!!members &&
              current?.id &&
              current?.id === params?.id &&
              members?.length > 0 ? (
                members?.map((user: TeamMemberType) => (
                  <TeamMemberItem
                    key={user.id}
                    teamId={current?.id as string}
                    item={user}
                    onItemDelete={onItemDelete}
                  />
                ))
              ) : (
                <VStack css={{ mt: 20 }}>
                  <Text>No member</Text>
                </VStack>
              )}
            </Flex>
            {isShowMoreVisible && (
              <Flex direction="column">
                <Button
                  onClick={handleShowMoreTeamMembers}
                  variant="gray"
                  ghost={true}
                  css={{ mt: '$4' }}
                  size={1}
                >
                  <Text css={{ mr: 4 }}>Show more</Text>
                  <ChevronDownIcon />
                </Button>
              </Flex>
            )}
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    );
  }
);
