/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';

import { Box, Checkbox, HStack } from '@/shared/ui/index';
import i18next from '@/shared/utils/translation';
import { styled } from '@/stitches.config';

type NotificationPreferenceItemProps = {
  item: string;
  onToggle: () => void;
  checked: boolean;
};

const NotificationPreferenceItemRow = (props: NotificationPreferenceItemProps) => {
  const { item, onToggle, checked } = props;
  const [isChecked, setIsChecked] = useState<boolean>(checked);

  useEffect(() => {
    if (checked !== isChecked) {
      setIsChecked(checked);
    }
  }, [checked]);

  const translate = (key: string): string => {
    return i18next.t(key);
  };

  return (
    <HStack css={{ my: 12 }} gap={3}>
      <Checkbox
        css={{
          minWidth: '15px',
        }}
        checked={isChecked}
        onCheckedChange={() => {
          setIsChecked((prev) => !prev);
          onToggle();
        }}
      />
      <NotificationsRowContainer>{translate(item)}</NotificationsRowContainer>
    </HStack>
  );
};

const NotificationsRowContainer = styled(Box, {
  width: '390px',
  fontSize: '13px',
});

export default NotificationPreferenceItemRow;
