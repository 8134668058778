import dayjs from 'dayjs';
import React, { useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { HiChevronRight, HiDownload } from 'react-icons/hi';

import {
  Box,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  DropdownSubMenu,
  DropdownSubMenuContent,
  DropdownSubTrigger,
  Flex,
} from '@/shared/ui';

import { searchContacts } from '../api/contacts/v2';
import { useDisclosure } from '../hooks';
import { Contact } from '../types/contacts';
import { FilterItem, Sort } from '../types/filter';
import { ToolTipIconButton } from './attachments/previewer';

export const DownloadContacts = ({
  getData,
  setDownloading,
  fileName,
  isSubmenu,
  title,
}: {
  fileName: string;
  getData: (isDownloadAll: boolean) => Promise<object[]>;
  setDownloading: React.Dispatch<React.SetStateAction<boolean>>;
  isSubmenu?: boolean;
  title: string;
}): JSX.Element => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [downloadData, setDownloadData] = useState<object[]>([]);
  const ref = useRef<{
    link: HTMLAnchorElement | null;
  }>();

  const handleDownloadContacts = async (isDownloadAll: boolean) => {
    setDownloading(true);
    // Update the state variable with all contacts
    onClose();
    const data = await getData(isDownloadAll);
    setDownloadData(data);

    // Set downloading to false
    setDownloading(false);
    ref.current && ref.current.link ? ref.current.link.click() : null;
  };

  return (
    <>
      {isSubmenu ? (
        <DropdownSubMenu
          open={isOpen}
          onOpenChange={(value) => (value ? onOpen() : onClose())}
        >
          <DropdownSubTrigger
            css={{ paddingLeft: 12, fontSize: 14 }}
            asChild
            data-testid="download-option"
          >
            <Box>
              <Flex justify="between" align="center" css={{ width: '100%' }}>
                <Box>{title}</Box>
                <Box>
                  <HiChevronRight />
                </Box>
              </Flex>
              <CSVLink
                data={downloadData}
                ref={ref as any}
                filename={`${fileName}.csv`}
              />
            </Box>
          </DropdownSubTrigger>
          <DropdownSubMenuContent sideOffset={8}>
            <DropdownMenuItem
              onClick={(e) => {
                e.preventDefault();
                handleDownloadContacts(true);
              }}
            >
              Download All Contacts
            </DropdownMenuItem>
            <DropdownMenuItem
              onClick={(e) => {
                e.preventDefault();
                handleDownloadContacts(false);
              }}
            >
              Download Filtered Contacts
            </DropdownMenuItem>
          </DropdownSubMenuContent>
        </DropdownSubMenu>
      ) : (
        <DropdownMenu>
          <DropdownMenuTrigger asChild data-testid="notification-menu-button">
            <Box>
              <ToolTipIconButton
                variant="outline"
                description={title}
                icon={<HiDownload />}
              />
              <CSVLink
                data={downloadData}
                ref={ref as any}
                filename={`${fileName}.csv`}
              />
            </Box>
          </DropdownMenuTrigger>
          <DropdownMenuContent sideOffset={4} align="end">
            <DropdownMenuItem onClick={() => handleDownloadContacts(true)}>
              Download All Contacts
            </DropdownMenuItem>
            <DropdownMenuItem onClick={() => handleDownloadContacts(false)}>
              Download Filtered Contacts
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      )}
    </>
  );
};

export const getContactList = async (
  filter: FilterItem[],
  sort: Sort[],
  limit: number
) => {
  let offset = 0;
  let keepLoading = true;
  let allContacts: object[] = [];

  while (keepLoading) {
    const contacts = await searchContacts(filter, sort, limit, offset);

    const formattedExportContacts = contacts.data.map((contact: Contact) => {
      return {
        name: contact.name || '',
        phone: contact.phone || '',
        email: contact.email || '',
        source: contact.source || '',
        address: contact.address?.address_line_one,
        birth_date:
          contact.birth_date &&
          contact.birth_date.day &&
          contact.birth_date.month &&
          contact.birth_date.year
            ? dayjs(
                `${contact.birth_date.year}-${contact.birth_date.month}-${contact.birth_date.day}`
              ).format('MMM D, YYYY')
            : contact.birth_date && contact.birth_date.month && contact.birth_date.day
              ? dayjs(
                  `2000-${contact.birth_date.month}-${contact.birth_date.day}`
                ).format('MMM D') // Year is arbitrary, used for formatting purposes
              : '',
        external_id: contact.external_id,
        blocked: contact.blocked ? 'True' : 'False',
        inserted_at: dayjs(contact.inserted_at).format('MMM D[,] YYYY[,] h:mmA'),
        updated_at: dayjs(contact.updated_at).format('MMM D[,] YYYY[,] h:mmA'),
      };
    });

    // Add the formatted contacts to the allContacts array
    allContacts = [...allContacts, ...formattedExportContacts];

    // If all data has been fetched, stop the loop
    if (allContacts.length >= contacts.total) {
      keepLoading = false;
    }

    // Increment the offset
    offset += limit;
  }

  return allContacts;
};
