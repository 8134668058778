import { useCallback, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useRouteMatch } from 'react-router-dom';
import { useMedia } from 'react-use';
import { toast } from 'sonner';

import { OpeningHours } from '@/shared/components/openingHours';
import {
  formatHour,
  transformOpeningHours,
} from '@/shared/components/openingHours/utils';
import { usePageView } from '@/shared/hooks';
import { SettingsLayout } from '@/shared/layouts';
import { OpeningHour } from '@/shared/types';
import { TeamMember as TeamMemberType } from '@/shared/types/team';
import { WorkSchedule } from '@/shared/types/team/schedule';
import { User } from '@/shared/types/users';
import { sortWeekDays } from '@/shared/utils/sortWeekDays/sortWeekDays';
import i18next from '@/shared/utils/translation';

import { useUsers } from '../../users/context/UserContext';
import { TeamsErrorBoundary } from '../';
import { initialWorkSchedules, weekDays } from '../constants';
import { useTeams } from '../context/TeamsContext';
import { ContentWrapper, PageContainer } from '../team';
export const TeamMember = () => {
  usePageView();
  const isDesktop = useMedia('(min-width: 912px)');

  const {
    userState: { users },
  } = useUsers();

  const {
    teamsState: { current, currentTeamMember },
    getTeam,
    getTeamMember,
    createTeamMemberWorkSchedules,
    deleteTeamMemberWorkSchedules,
    updateTeamMemberWorkSchedules,
    clearCurrentTeamMember,
  } = useTeams();

  const match = useRouteMatch<{ teamId: string; id: string }>(
    '/settings/teams/:teamId/team_members/:id'
  );

  const [radioValue, setRadioValue] = useState('default');

  const [currentWorkSchedules, setCurrentWorkSchedules] = useState<Array<WorkSchedule>>(
    []
  );

  // Applies the opening hours to the selected days
  const onApply = async (openingHour: OpeningHour, targetDays: string[]) => {
    // If there is no team, return
    if (!current || !current.id || !currentTeamMember) {
      return;
    }

    // We need to go over the weekdays array and check if the day is in the target days array
    // If it is, to find work schedule from existing work schedules and to update with openingHour
    // If not, we need to create a new work schedule with from openingHour
    const updatingWorkSchedules = weekDays.map((weekday: string) => {
      if (targetDays.includes(weekday)) {
        const existingWorkSchedules = currentTeamMember?.work_schedules || [];
        const workSchedule = existingWorkSchedules.find(
          (workSchedule: WorkSchedule) => workSchedule.weekday === weekday
        );

        // If the opening hour which we are copying form exists,
        // either update the target work schedule or create one
        if (openingHour.id) {
          // If we have a work schedule, we need to update it
          if (workSchedule) {
            return updateTeamMemberWorkSchedules(
              current?.id as string,
              currentTeamMember?.id as string,
              workSchedule.id as string,
              {
                starts_at: formatHour(openingHour?.opens_at),
                ends_at: formatHour(openingHour?.closes_at),
                timezone: openingHour?.timezone,
              },
              true
            );
          } else {
            // If we don't have a work schedule, we need to create a new one
            return createTeamMemberWorkSchedules(
              current?.id as string,
              currentTeamMember?.id as string,
              {
                weekday: weekday,
                starts_at: openingHour?.opens_at,
                ends_at: openingHour?.closes_at,
                timezone: openingHour?.timezone,
              }
            );
          }
        } else {
          // If the day we are copying from doesn't exist we delete the targeted work schedule
          if (workSchedule) {
            return deleteTeamMemberWorkSchedules(
              current?.id as string,
              currentTeamMember?.id as string,
              workSchedule?.id as string,
              true
            );
          }
        }
      }
    });

    // We need to wait for all the work schedules to be updated/created
    try {
      current?.id && (await Promise.all(updatingWorkSchedules));
    } catch (error) {
      console.error('Error update team work schedule:', error);
    }
  };

  // Clear local state on unmount
  useEffect(() => {
    return () => {
      setRadioValue('default');
      setCurrentWorkSchedules([]);
      clearCurrentTeamMember();
    };
  }, []);

  // On mount get the id from the url and fetch the team if the teamId does not match the current Team
  useEffect(() => {
    if (match?.params?.teamId && current?.id !== match?.params?.teamId) {
      getTeam(match?.params?.teamId);
    }
  }, [match?.params?.teamId, current?.id]);
  // And fetch the team member if the id does not match the current Team Member
  useEffect(() => {
    if (match?.params?.id && currentTeamMember?.id !== match?.params?.id) {
      getTeamMember(match?.params?.teamId, match?.params?.id);
    }
  }, [match?.params, currentTeamMember?.id]);

  // Set work schedules
  useEffect(() => {
    const days = initialWorkSchedules.map((day: WorkSchedule) => {
      const workDay = currentTeamMember?.work_schedules?.find(
        (d) => d.weekday === day.weekday
      );
      return {
        ...day,
        ...workDay,
        ...{ state: workDay ? 'open' : 'closed' },
      };
    });
    setCurrentWorkSchedules(sortWeekDays(days));
    currentTeamMember?.work_schedules?.length
      ? setRadioValue('custom')
      : setRadioValue('default');
  }, [JSON.stringify(currentTeamMember?.work_schedules)]);

  // Update the existing work schedules with timezone
  const updateTimezone = async (timezoneName: string) => {
    const existingWorkSchedules = currentTeamMember?.work_schedules || [];
    const updatingTeamMemberWorkSchedules = existingWorkSchedules.map(
      (workSchedule: WorkSchedule, index: number) =>
        updateTeamMemberWorkSchedules(
          current?.id as string,
          currentTeamMember?.id as string,
          workSchedule.id as string,
          {
            timezone: timezoneName,
          },
          index === 0
        )
    );
    try {
      current?.id &&
        currentTeamMember?.id &&
        (await Promise.all(updatingTeamMemberWorkSchedules));
    } catch (error) {
      console.error('Error update team work schedule:', error);
    }
  };

  // Delete the existing work schedules when check use default schedule
  const deletingTeamMemberWorkSchedules = async () => {
    const existingWorkSchedules = currentTeamMember?.work_schedules || [];
    const deletingWorkSchedules = existingWorkSchedules.map(
      (workSchedule: WorkSchedule, index: number) =>
        deleteTeamMemberWorkSchedules(
          current?.id as string,
          currentTeamMember?.id as string,
          workSchedule.id as string,
          index === 0
        )
    );
    try {
      current?.id && currentTeamMember?.id && (await Promise.all(deletingWorkSchedules));
    } catch (error) {
      console.error('Error use default team work schedule:', error);
    }
  };

  // Handle the chosen radio button between default and custom work schedules
  const handleRadioChange = useCallback(
    (value: 'default' | 'custom') => {
      if (value === 'default') {
        // HERE DELETES
        deletingTeamMemberWorkSchedules();
      }
      setRadioValue(value);
    },
    [currentTeamMember?.work_schedules]
  );

  // Handle change work schedules
  const onChange = async (openingHour: any) => {
    if (radioValue === 'default') return;

    if (
      (openingHour?.opens_at === null || openingHour?.closes_at === null) &&
      openingHour?.state === 'open'
    ) {
      toast.error(i18next.t('working_hours_not_set') as string);
      return;
    }

    if (current && current?.id && currentTeamMember?.id) {
      if (openingHour?.isSwitch) {
        if (openingHour?.id) {
          await deleteTeamMemberWorkSchedules(
            current?.id,
            currentTeamMember?.id as string,
            openingHour?.id,
            true
          );
        } else {
          await createTeamMemberWorkSchedules(current.id, currentTeamMember?.id, {
            weekday: openingHour.weekday,
            starts_at: openingHour?.opens_at,
            ends_at: openingHour?.closes_at,
            timezone: openingHour?.timezone,
          });
        }
      } else {
        openingHour?.id &&
          (await updateTeamMemberWorkSchedules(
            current?.id,
            currentTeamMember?.id as string,
            openingHour?.id,
            {
              weekday: openingHour.weekday,
              starts_at: formatHour(openingHour?.opens_at),
              ends_at: formatHour(openingHour?.closes_at),
              timezone: openingHour?.timezone,
            },
            true
          ));
      }
    }
  };

  // Get user data for team member
  const userData = useMemo(() => {
    return (currentTeamMember?.user_id &&
      users?.find((m: User) => m.id === currentTeamMember?.user_id)) as User;
  }, [currentTeamMember?.user_id, users]);

  // Get default work schedules
  const defaultWorkSchedules = useMemo(() => {
    return initialWorkSchedules.map((day: WorkSchedule) => {
      const workDay = current?.work_schedules?.find((d) => d.weekday === day.weekday);
      return {
        ...day,
        ...workDay,
        ...{ state: workDay ? 'open' : 'closed' },
      };
    });
  }, [current?.work_schedules]);

  // Transform the types of TeamMember work schedule from 'WorkSchedule' to 'OpeningHour'
  const transformTypes = (teamMember: TeamMemberType) => {
    if (!teamMember || !teamMember.work_schedules) {
      return;
    }
    const workSchedules =
      radioValue === 'default' ? defaultWorkSchedules : currentWorkSchedules;

    const openingHours = workSchedules.map((workSchedule: WorkSchedule) =>
      transformOpeningHours(workSchedule)
    );
    return { ...teamMember, openingHours };
  };

  return (
    <>
      <Helmet>
        <title>Whippy | Team</title>
      </Helmet>
      <SettingsLayout
        breadcrumbs={[
          { title: 'Teams', path: '/settings/teams' },
          { title: current?.name || '', path: `/settings/teams/${current?.id}` },
          {
            title: userData?.name || userData?.email || '',
            path: `/settings/teams/${current?.id}/${currentTeamMember?.id}`,
          },
        ]}
      >
        <TeamsErrorBoundary>
          <PageContainer
            align="start"
            justify="center"
            css={{ padding: isDesktop ? '30px' : '40px 20px' }}
          >
            <ContentWrapper gap={6}>
              {currentTeamMember &&
                currentTeamMember?.id &&
                currentTeamMember?.id === match?.params?.id && (
                  <OpeningHours
                    onChange={onChange}
                    onApply={onApply}
                    timezone={currentTeamMember?.work_schedules?.[0]?.timezone}
                    updateTimezone={updateTimezone}
                    current={transformTypes(currentTeamMember)}
                    radioSectionRendered={true}
                    radioValue={radioValue}
                    handleRadioChange={handleRadioChange}
                  />
                )}
            </ContentWrapper>
          </PageContainer>
        </TeamsErrorBoundary>
      </SettingsLayout>
    </>
  );
};
