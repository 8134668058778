import { createContext, Dispatch, useContext, useReducer } from 'react';

import { getContactCommunicationPreference } from '@/shared/api/contacts/v1';
import { Preference } from '@/shared/types';
import { Channel } from '@/shared/types/channels';
import { UnsubscribePreferenceActionTypes } from '@/shared/types/contacts';

import { UnsubscribePreferenceActions } from './UnsubscribePreferenceReducer';
import { UnsubscribePreferenceReducer } from './UnsubscribePreferenceReducer';

export type UnsubscribePreferenceState = {
  /** is the component loading or not **/
  isLoading?: boolean;
  /** all locations to show (aka all locations under this user) **/
  allLocationsToShow?: Array<Channel>;
  /** active location **/
  activeLocations?: Array<Channel>;
  /** paused locations **/
  pausedLocations?: Array<Channel>;
  /** opted out locations **/
  optedOutLocations?: Array<Channel>;
  /** selected locations **/
  selectedLocations?: Array<Channel>;
  /** selected active locations **/
  selectedActiveLocations?: Array<Channel>;
  /** selected paused locations **/
  selectedPausedLocations?: Array<Channel>;
  /** confirmed paused duration **/
  confirmedPausedDuration?: string;
  /** paused preferences (the preference objects for paused locations) **/
  pausedPreferences?: Array<Preference>;
};

export const initialUnsubscribePreferenceState: UnsubscribePreferenceState = {
  isLoading: true,
  allLocationsToShow: [], // nothing is shown at first
  activeLocations: [],
  pausedLocations: [],
  optedOutLocations: [],
  selectedLocations: [], // nothing is selected at first
  selectedActiveLocations: [],
  selectedPausedLocations: [],
  confirmedPausedDuration: '',
  pausedPreferences: [],
};

// props needed to create a context
type UnsubscribePreferenceContextProps = {
  /** current state */
  unsubscribePreferenceState: UnsubscribePreferenceState;
  /** set is loading for current state */
  setIsLoading: (isLoading: boolean) => void;
  /** set all locations to show for current state */
  setAllLocationsToShow: (contactId: string, allChannels: Array<Channel>) => void;
  /** set selected locations for current state */
  setConfirmedPausedDuration: (duration: string) => void;
  /** set selected locations for current state */
  setSelectedLocations: (locations: Array<Channel>) => void;
  /** set selected active locations for current state */
  setSelectedActiveLocations: (locations: Array<Channel>) => void;
  /** set selected paused locations for current state */
  setSelectedPausedLocations: (locations: Array<Channel>) => void;
};

// initialize context
export const UnsubscribePreferenceContext =
  createContext<UnsubscribePreferenceContextProps>({
    unsubscribePreferenceState: initialUnsubscribePreferenceState,
    setIsLoading: () => Promise.resolve(),
    setAllLocationsToShow: () => Promise.resolve(),
    setConfirmedPausedDuration: () => Promise.resolve(),
    setSelectedLocations: () => Promise.resolve(),
    setSelectedActiveLocations: () => Promise.resolve(),
    setSelectedPausedLocations: () => Promise.resolve(),
  });

export const useUnsubscribePreference = () => useContext(UnsubscribePreferenceContext);

const UnsubscribePreferenceState = ({ children }: { children: React.ReactNode }) => {
  const [unsubscribePreferenceState, dispatch]: [
    UnsubscribePreferenceState,
    Dispatch<UnsubscribePreferenceActions>,
  ] = useReducer(UnsubscribePreferenceReducer, initialUnsubscribePreferenceState);

  const setIsLoading = (isLoading: boolean) => {
    try {
      dispatch({
        type: UnsubscribePreferenceActionTypes.SET_IS_LOADING,
        payload: isLoading,
      });
    } catch (err) {
      console.error(err);
    }
  };

  // set 1）all locations to show，
  // 2）opted in locations
  // 3) paused locations
  // 4）opted out (unsubscribed)locations
  const setAllLocationsToShow = async (
    contactId: string,
    allChannels: Array<Channel>
  ) => {
    try {
      // get all preferences under this contact
      const preferences = await getContactCommunicationPreference(contactId);

      // get 3 types of preferences
      const pausedPreferences = preferences.filter(
        (p: Preference) => p.opt_in === false && p.paused_until !== null
      );
      const optedOutPreferences = preferences.filter(
        (p: Preference) => p.opt_in === false && p.paused_until === null
      );

      // get location Ids of 3 types of preferences
      const pausedLocationIds = pausedPreferences.map((p: Preference) => p.location_id);
      const optedOutLocationIds = optedOutPreferences.map(
        (p: Preference) => p.location_id
      );

      // get location object of 3 types of preferences
      const pausedLocations = allChannels.filter((location: Channel) =>
        pausedLocationIds.includes(location.id)
      );
      const optedOutLocations = allChannels.filter((location: Channel) =>
        optedOutLocationIds.includes(location.id)
      );

      // if a location doesn't have preference, it's also active
      const activeLocations = allChannels.filter(
        (location: Channel) =>
          !pausedLocationIds.includes(location.id) &&
          !optedOutLocationIds.includes(location.id)
      );

      dispatch({
        type: UnsubscribePreferenceActionTypes.SET_ALL_LOCATIONS_TO_SHOW,
        payload: allChannels,
      });

      dispatch({
        type: UnsubscribePreferenceActionTypes.SET_ACTIVE_LOCATIONS,
        payload: activeLocations,
      });

      dispatch({
        type: UnsubscribePreferenceActionTypes.SET_PAUSED_LOCATIONS,
        payload: pausedLocations,
      });

      dispatch({
        type: UnsubscribePreferenceActionTypes.SET_OPTED_OUT_LOCATIONS,
        payload: optedOutLocations,
      });

      dispatch({
        type: UnsubscribePreferenceActionTypes.SET_SELECTED_LOCATIONS,
        payload: [],
      });

      dispatch({
        type: UnsubscribePreferenceActionTypes.SET_PAUSED_PREFERENCES,
        payload: pausedPreferences,
      });

      dispatch({
        type: UnsubscribePreferenceActionTypes.SET_IS_LOADING,
        payload: false,
      });
    } catch (err) {
      console.error(err);
    }
  };

  const setSelectedLocations = (locations: Array<Channel>) => {
    try {
      dispatch({
        type: UnsubscribePreferenceActionTypes.SET_SELECTED_LOCATIONS,
        payload: locations,
      });
    } catch (err) {
      console.error(err);
    }
  };

  const setSelectedActiveLocations = (locations: Array<Channel>) => {
    try {
      dispatch({
        type: UnsubscribePreferenceActionTypes.SET_SELECTED_ACTIVE_LOCATIONS,
        payload: locations,
      });
    } catch (err) {
      console.error(err);
    }
  };

  const setSelectedPausedLocations = (locations: Array<Channel>) => {
    try {
      dispatch({
        type: UnsubscribePreferenceActionTypes.SET_SELECTED_PAUSED_LOCATIONS,
        payload: locations,
      });
    } catch (err) {
      console.error(err);
    }
  };

  const setConfirmedPausedDuration = (duration: string) => {
    try {
      dispatch({
        type: UnsubscribePreferenceActionTypes.SET_CONFIRMED_PAUSE_DURATION,
        payload: duration,
      });
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <UnsubscribePreferenceContext.Provider
      value={{
        unsubscribePreferenceState,
        setIsLoading,
        setAllLocationsToShow,
        setConfirmedPausedDuration,
        setSelectedLocations,
        setSelectedActiveLocations,
        setSelectedPausedLocations,
      }}
    >
      {children}
    </UnsubscribePreferenceContext.Provider>
  );
};

export default UnsubscribePreferenceState;
