import { ColumnType, Resource, SortConfig } from '@/shared/types/filter';

export const sortConfig: SortConfig<
  Resource.List | Resource.ContactMessage,
  ColumnType.Name | ColumnType.InsertedAt | ColumnType.UpdatedAt
> = {
  label: 'Sort',
  columnOptions: [
    {
      label: 'Name',
      resource: Resource.List,
      column: ColumnType.Name,
      order: 'asc',
    },
    {
      label: 'Created At',
      resource: Resource.List,
      column: ColumnType.InsertedAt,
      order: 'asc',
    },
    {
      label: 'Updated At',
      resource: Resource.List,
      column: ColumnType.UpdatedAt,
      order: 'asc',
    },
  ],
};
