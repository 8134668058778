import { FormikValues, useFormikContext } from 'formik';
import { useRef, useState } from 'react';

import { useChannels } from '@/pages/settings/organization/channels/context/ChannelContext';
import { MessageEditorV2 } from '@/shared/components/editor/v2';
import { TextInputProps } from '@/shared/components/forms';

export function SearchEditor(
  props: TextInputProps & { selectedLocation: string | null }
): JSX.Element {
  const formik = useFormikContext<FormikValues>();

  const [message, setMessage] = useState('');

  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const handleSubmit = () => {
    formik.setFieldValue(props.name as string, message).then(() => {
      formik.submitForm();
    });
    setMessage('');
  };

  const { getChannelById } = useChannels();

  return (
    <MessageEditorV2
      message={message}
      setMessage={setMessage}
      textareaRef={textareaRef}
      sendMessageAction={handleSubmit}
      showSendButton={true}
      showAddEmoji={true}
      showAddTemplate={true}
      showCharacterCount={true}
      enableAttachments={false}
      placeholder={'Enter a message'}
      location={getChannelById(props.selectedLocation || '')}
    />
  );
}
