/* eslint-disable react/jsx-key */
/* eslint-disable react-hooks/exhaustive-deps */
import { useMemo } from 'react';
import {
  HiChevronDown,
  HiChevronLeft,
  HiChevronRight,
  HiChevronUp,
  HiQuestionMarkCircle,
  HiSelector,
} from 'react-icons/hi';
import { usePagination, useRowSelect, useSortBy, useTable } from 'react-table';

import { useUsers } from '@/pages/settings/organization/users/context/UserContext';
import { User } from '@/shared/types/users';
import {
  Avatar,
  Box,
  Button,
  Flex,
  HStack,
  IconButton,
  Label,
  Skeleton,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
  Tr,
  VStack,
} from '@/shared/ui';
import { initials } from '@/shared/utils/initials/initials';
import { styled } from '@/stitches.config';

type ConfigItem = {
  /** key to match accessor in the column */
  key: string;
  /** header of the column */
  header: string;
  /** tooltip content */
  tooltip?: string;
};

export type ReportsTableProps = {
  /** data to display */
  data: any;
  /** config file to know on which column what tooltip content and header to display */
  config: Array<ConfigItem>;
  /** is table sortable */
  sortable?: boolean;
  /** table title */
  tableTitle?: string;
  /** is dating loading */
  loading: boolean;
  /** is response failed */
  error: boolean;
  /** retry request */
  errorCallback?: () => void;
};

export const ReportsTable = (props: ReportsTableProps) => {
  const { data, config, sortable, loading, error, errorCallback } = props;

  const generateColumns = (data: any) => {
    if (data) {
      return config?.map((configItem) => ({
        Header: configItem.header,
        accessor: configItem.key,
        tooltip: configItem.tooltip,
      }));
    } else {
      return [];
    }
  };

  // Users
  const { userState } = useUsers();
  const { users } = userState;

  // if the data object has a user_id key, then we need to find the user and put it in the user key
  const findUserAndPutUser = (data: any) => {
    const allUsers = [...users];
    data?.map((result: any) => {
      const user = allUsers?.find((user: User) => user?.id === result?.user_id);
      result.user = user;
    });

    return data;
  };

  const columns = useMemo(() => generateColumns(findUserAndPutUser(data)), [data, users]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data,
    },
    useSortBy,
    usePagination,
    useRowSelect
  );

  // loading state
  if (loading) {
    return (
      <Table>
        <Thead>
          <Tr>
            {config.map((column) => (
              <Th key={column.header}>{column.header}</Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {Array.from({ length: 2 }, (_, i) => (
            <Tr key={i}>
              {config.map((column) => (
                <Td key={column.header}>
                  <Skeleton variant="heading" />
                </Td>
              ))}
            </Tr>
          ))}
        </Tbody>
      </Table>
    );
  }

  // error state
  if (error && !loading) {
    return (
      <VStack gap={2} align="center" css={{ mb: 25 }}>
        <TableTitle>Fail to Load Report</TableTitle>
        <TableErrorDescription>
          An error occurred while loading this report.
        </TableErrorDescription>
        {error && (
          <Box css={{ width: '100%', textAlign: 'center' }}>
            <Button variant="gray" onClick={errorCallback}>
              Try Again
            </Button>
          </Box>
        )}
      </VStack>
    );
  }

  return (
    <Box>
      <Table {...getTableProps()}>
        <Thead css={{ borderBottom: '1px solid lightgrey' }}>
          {headerGroups.map((headerGroup, index) => (
            <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column: any) => (
                <Th
                  key={column.Header}
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                >
                  <Flex align="center">
                    <Box>{column.render('Header')}</Box>
                    {column.tooltip && (
                      <Tooltip>
                        <TooltipTrigger asChild>
                          <IconButton>
                            <HiQuestionMarkCircle />
                          </IconButton>
                        </TooltipTrigger>
                        <TooltipContent side="top">
                          <Box
                            css={{
                              width: 270,
                              p: 5,
                              borderRadius: 4,
                              color: '#FFFFFF',
                              fontStyle: 'normal',
                              fontWeight: 400,
                              fontSize: 14,
                              lineHeight: '20px',
                            }}
                          >
                            {column.tooltip}
                          </Box>
                        </TooltipContent>
                      </Tooltip>
                    )}
                    {sortable ? (
                      column.isSorted ? (
                        column.isSortedDesc ? (
                          <HiChevronDown
                            style={{
                              opacity: '0.6',
                              marginLeft: 5,
                            }}
                          />
                        ) : (
                          <HiChevronUp
                            style={{
                              opacity: '0.6',
                              marginLeft: 5,
                            }}
                          />
                        )
                      ) : // This prevents rendering arrows on empty headers
                      column.Header === '' || column.Header === ' ' ? (
                        ''
                      ) : (
                        <HiSelector
                          style={{
                            opacity: '0.6',
                            marginLeft: 5,
                          }}
                        />
                      )
                    ) : null}
                  </Flex>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {page.length > 0 ? (
            page.map((row) => {
              prepareRow(row);
              return (
                <Tr
                  {...row.getRowProps()}
                  key={row.id}
                  css={{
                    '&:last-child': { borderBottom: 'none' },
                    width: '100%',
                  }}
                >
                  {row.cells.map((cell) => {
                    return (
                      <Td
                        {...cell.getCellProps()}
                        css={{
                          width: '100%',
                        }}
                      >
                        {typeof cell.value !== 'object' ? (
                          <Flex
                            align="center"
                            justify="end"
                            css={{ width: '100%' }}
                            gap={1}
                          >
                            {formatIfIsNumber(cell.value)}
                          </Flex>
                        ) : (
                          <HStack gap={1} key={cell.value?.email}>
                            <Avatar
                              src={cell.value?.attachment?.url || ''}
                              variant="pink"
                              fallback={initials(
                                cell.value?.name || cell.value?.email || ''
                              )}
                            />
                            <Box css={{ whiteSpace: 'nowrap' }}>
                              {cell.value?.name || cell.value?.email || ''}
                            </Box>
                          </HStack>
                        )}
                      </Td>
                    );
                  })}
                </Tr>
              );
            })
          ) : (
            <Tr>
              <Td colSpan={columns.length} css={{ textAlign: 'center' }}>
                <VStack gap={2}>
                  <Box css={{ p: 20, pt: 30 }}>
                    <TableTitle>No data to display yet. Try again later</TableTitle>
                  </Box>
                </VStack>
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>
      {pageOptions.length > 1 && (
        <HStack gap={3} css={{ justifyContent: 'center' }}>
          <IconButton
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
            variant="ghost"
          >
            <HiChevronLeft />
          </IconButton>
          <Flex>
            <Text>Page&nbsp;</Text>
            <Text variant="semibold">
              {pageIndex + 1} of {pageOptions.length}
            </Text>
          </Flex>
          <IconButton onClick={() => nextPage()} disabled={!canNextPage} variant="ghost">
            <HiChevronRight />
          </IconButton>
        </HStack>
      )}
    </Box>
  );
};

const TableTitle = styled(Label, {
  fontSize: 18,
  fontWeight: 600,
});

const TableErrorDescription = styled(Box, {
  pr: 10,
  fontSize: 12,
  color: '$slate11',
});

// take a value that is either a number or a string representing a number and return a number
// if the value is not a number, return 0, if it is a number, return the number rounded to the nearest integer
// add commas to the number if it is an integer and return it as a string
const formatIfIsNumber = (value: number | string) => {
  if (typeof value === 'number') {
    // round to nearest 2 decimal places
    value = Math.round(value * 100) / 100;
    return value.toLocaleString();
  }
  if (typeof value === 'string') {
    let number = parseInt(value);
    // if the value is a number, round to nearest integer and add comma
    if (Number.isInteger(number)) {
      number = Math.round(number * 100) / 100;
      return number.toLocaleString();
    }
  }
  return '0';
};
