/* eslint-disable @typescript-eslint/no-empty-function */
import React from 'react';

import { ReplyTriggerType } from '@/shared/components/triggers/AddTrigger';
import { LinkClickTriggerType } from '@/shared/components/triggers/AddTrigger';
import { KeywordTriggerType } from '@/shared/components/triggers/AddTrigger';
import { KeywordAutomationsPreview } from '@/shared/components/triggers/previews/KeywordAutomationsPreview';
import { LinkClickAutomationsPreview } from '@/shared/components/triggers/previews/LinkClickAutomationsPreview';
import { ReplyAutomationsPreview } from '@/shared/components/triggers/previews/ReplyAutomationsPreview';
import { Campaign } from '@/shared/types/campaigns';
import { TriggerTypes } from '@/shared/types/triggers';
import { Box } from '@/shared/ui';
import { styled } from '@/stitches.config';

type CampaignGlobalTemplateTriggersPreviewPropsType = {
  /* The template to view */
  campaign: Campaign | null;
};

export const CampaignGlobalTemplateTriggersPreview = (
  props: CampaignGlobalTemplateTriggersPreviewPropsType
) => {
  const { campaign } = props;

  const triggers = campaign?.triggers || [];

  const keyWordTriggers =
    (triggers?.filter(
      (trigger: { type: string }) =>
        trigger.type === TriggerTypes.KEYWORD || trigger.type === 'reply_with_keyword'
    ) as Array<KeywordTriggerType>) || [];

  const replyTriggers =
    (triggers?.filter(
      (trigger: { type: string }) => trigger.type === TriggerTypes.REPLY
    ) as Array<ReplyTriggerType>) || [];

  const linkClickTriggers =
    (triggers?.filter(
      (trigger: { type: string }) => trigger.type === TriggerTypes.LINK_CLICK
    ) as Array<LinkClickTriggerType>) || [];

  return (
    <ScrollableContainer>
      <Box>
        <KeywordAutomationsPreview keyword_triggers={keyWordTriggers} />
      </Box>
      <Box>
        <ReplyAutomationsPreview reply_triggers={replyTriggers} />
      </Box>
      <Box>
        <LinkClickAutomationsPreview link_click_triggers={linkClickTriggers} />
      </Box>
    </ScrollableContainer>
  );
};

const ScrollableContainer = styled('div', {
  maxHeight: '300px',
  overflowY: 'auto',
  paddingRight: '10px', // to prevent the scrollbar from touching the content
});
