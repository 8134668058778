import { ColumnType, Resource, SortConfig } from '@/shared/types/filter';

export const sortConfig: SortConfig<
  Resource.Template,
  ColumnType.UpdatedAt | ColumnType.InsertedAt | ColumnType.Title
> = {
  label: 'Sort',
  columnOptions: [
    {
      label: 'Updated At',
      column: ColumnType.UpdatedAt,
      resource: Resource.Template,
      order: 'desc',
    },
    {
      label: 'Created At',
      column: ColumnType.InsertedAt,
      resource: Resource.Template,
      order: 'desc',
    },
    {
      label: 'Title',
      column: ColumnType.Title,
      resource: Resource.Template,
      order: 'desc',
    },
  ],
};
