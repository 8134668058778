import { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';

import { Box, Flex } from '@/shared/ui';

type ZoomableImageProps = {
  url: string;
};

/*
 * A component that displays an image and allows it to be zoomed in/out
 * by clicking on it.
 */
export const ZoomableImageComponent: React.FC<ZoomableImageProps> = (
  props: ZoomableImageProps
) => {
  const { url } = props;
  // State to track whether the image is zoomed in
  const [isZoomed, setIsZoomed] = useState(false);

  // Ref to the image element
  const imageRef = useRef<HTMLImageElement>(null);

  // Handler for click events on the image
  const handleImageClick = (e: React.MouseEvent) => {
    e.stopPropagation(); // Prevent the event from bubbling up to the document
    setIsZoomed(!isZoomed); // Toggle the zoomed state
  };

  // Add/remove a click event listener on the document when the image is zoomed in/out
  useEffect(() => {
    const handleDocumentClick = () => {
      setIsZoomed(false); // Un zoom the image when a click event is detected on the document
    };

    if (isZoomed) {
      document.addEventListener('click', handleDocumentClick);
    }

    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, [isZoomed]);

  // The zoomed image and overlay
  const zoomedImage = (
    <>
      <div
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          zIndex: 999998,
        }}
      />
      <img
        src={url}
        alt="integration"
        style={{
          position: 'fixed',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%) scale(2)',
          maxWidth: '35%',
          maxHeight: '35%',
          zIndex: 999999,
          cursor: 'zoom-out',
        }}
      />
    </>
  );

  return (
    <Box
      css={{
        width: '580px',
        height: '420px',
        borderRadius: '6px',
        position: 'relative',
      }}
    >
      <Flex align="center" justify="center" css={{ width: '100%', height: '100%' }}>
        <button onClick={handleImageClick}>
          <img
            ref={imageRef}
            src={url}
            alt="integration"
            style={{
              maxWidth: '100%',
              maxHeight: '100%',
              borderRadius: '6px',
              cursor: 'pointer',
              transition: 'transform 0.3s',
              zIndex: isZoomed ? 999999 : 1,
            }}
          />
        </button>
      </Flex>
      {isZoomed && ReactDOM.createPortal(zoomedImage, document.body)}
    </Box>
  );
};
