export const cleanUrl = (url: string) => {
  let cleanUrl = url.trim();

  if (!cleanUrl.startsWith('http://') && !cleanUrl.startsWith('https://')) {
    cleanUrl = `https://${cleanUrl}`;
  }

  return cleanUrl;
};

export const isValidUrl = (url: string) => {
  try {
    new URL(cleanUrl(url));
    return true;
  } catch (e) {
    return false;
  }
};

/**
 * Converts hex color to rgba format
 * @param hex - color to convert
 * @param alpha - opacity parameter between 0-1
 * @returns rgba color
 *
 * Example usage:
 * hex2rgba('#f80', 0.5)
 * Result: rgba(255, 136, 0, 0.5)
 * hex2rgba('#0088ff')
 * Result: rgba(0, 136, 255, 1)
 */
export const hex2rgba = (hex: string, alpha = 1) => {
  const isValidHex = (hex: string) => /^#([A-Fa-f0-9]{3,4}){1,2}$/.test(hex);
  const getChunksFromString = (st: string, chunkSize: number) =>
    st.match(new RegExp(`.{${chunkSize}}`, 'g'));
  const convertHexUnitTo256 = (hexStr: string) =>
    parseInt(hexStr.repeat(2 / hexStr.length), 16);
  const getAlphaFloat = (a: number, alpha: number) => {
    if (typeof a !== 'undefined') {
      return a / 255;
    }
    if (typeof alpha != 'number' || alpha < 0 || alpha > 1) {
      return 1;
    }
    return alpha;
  };

  if (!isValidHex(hex)) {
    return hex;
  }
  const chunkSize = Math.floor((hex.length - 1) / 3);
  const hexArr = getChunksFromString(hex.slice(1), chunkSize);
  const [r, g, b, a] = (hexArr || []).map(convertHexUnitTo256);
  return `rgba(${r}, ${g}, ${b}, ${getAlphaFloat(a, alpha)})`;
};
