import { useFlags } from 'launchdarkly-react-client-sdk';
import React from 'react';
import { HiChat, HiDotsHorizontal, HiPhoneOutgoing, HiPlus, HiX } from 'react-icons/hi';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { toast } from 'sonner';

import { MatchParams } from '@/inbox/context/ConversationContext';
import { ConversationDrawer } from '@/inbox/drawer';
import { useAuth } from '@/pages/auth/context/AuthProvider';
import { AddContactToSequence } from '@/pages/sequences/sequence/audience/AddContactToSequence';
import { useChannels } from '@/pages/settings/organization/channels/context/ChannelContext';
import { CustomDropdownMenuItemWarning } from '@/pages/settings/organization/users/UsersTable';
import { useVoIP } from '@/pages/voip/context/VoIPContext';
import { blockContact } from '@/shared/api/contacts/v1';
import { ConfirmationDialog } from '@/shared/components/ConfirmationDialog';
import { Contact } from '@/shared/types';
import {
  ConversationFilterTypes,
  ConversationStatusTypes,
} from '@/shared/types/conversations';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
  Flex,
  HStack,
  IconButton,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
  VStack,
} from '@/shared/ui';
import i18next from '@/shared/utils/translation';
import { formatPhoneNumber } from '@/shared/utils/validations/validations';
import { styled } from '@/stitches.config';

import { useContacts } from '../../context/ContactContext';
import { ArchiveContact } from '../ArchiveContact';
import { UnsubscribePreferenceErrorBoundary } from '../preferences/boundaries/UnsubscribePreferenceErrorBoundaries';
import { UnsubscribePreference } from '../preferences/UnsubscribePreference';

type ContactPanelProps = {
  deleteContact?: (id: string) => void;
  onClose?: () => void;
  contact?: Contact;
  isInbox?: boolean;
};

export const ContactPanelHeader = ({
  onClose,
  isInbox,
  contact,
  deleteContact,
}: ContactPanelProps) => {
  const history = useHistory();
  const match = useRouteMatch<MatchParams>('/inbox/:filter/:tab');
  const filter = match?.params.filter || ConversationFilterTypes.ALL;
  const tab = match?.params.tab || ConversationStatusTypes.OPEN;

  const voip = useVoIP();
  const { enableVoip } = useFlags();
  const {
    channelsState: { channels },
  } = useChannels();

  const { isAdmin } = useAuth();

  const {
    contactState: { current },
    archiveContact,
    clearCurrent,
    setCurrent,
  } = useContacts();

  const onBlockContact = async (isBlocked: boolean) => {
    try {
      // make a request to block the contact
      current?.id && (await blockContact(current?.id, !isBlocked));
      /*
        After hitting "block" button, current contact's "blocked" property get reversed,
        but the "current" object (of type contact) of this page is not updated yet.
        Therefore, we need to copy a current object and reverse its "blocked" property
        and setCurrent into the copy to trigger a page refresh
      */
      const updatedContact = { ...current, blocked: !isBlocked };
      setCurrent(updatedContact as Contact);

      toast.success(i18next.t('contact_blocked_success') as string);
    } catch (err) {
      console.log(err);
      toast.error(i18next.t('contact_blocked_failure') as string);
    }
  };

  // Archive contact function
  const onArchive = () => {
    contact?.id &&
      (deleteContact ? deleteContact?.(contact?.id) : archiveContact(contact?.id));
    clearCurrent();
    // next conversation when you delete one
    if (isInbox) {
      history.push(`/inbox/${filter}/${tab}`);
    }
    onClose?.();
  };

  const contactData = current || contact;

  return (
    <PanelHeaderContainer>
      <Flex align="center" justify="between">
        <Heading
          css={{ textTransform: 'capitalize', fontWeight: isInbox ? '600' : '800' }}
        >
          {contactData?.name ||
            formatPhoneNumber(contactData?.phone || undefined) ||
            contactData?.email}
        </Heading>
        <HStack>
          <DropdownMenu>
            <DropdownMenuTrigger>
              <IconButton size={2} data-testid="dots-button">
                <HiDotsHorizontal size={16} />
              </IconButton>
            </DropdownMenuTrigger>
            <DropdownMenuContent
              hideWhenDetached
              css={{
                padding: 8,
                width: 185,
                minWidth: 185,
                boxShadow: '0px 12px 60px 0px #0000000E',
              }}
              align="start"
              side="left"
              sideOffset={5}
            >
              {contact && (
                <UnsubscribePreferenceErrorBoundary feature="UnsubscribePreference">
                  <UnsubscribePreference contact={contact}>
                    <CustomDropdownMenuItemWarning
                      data-testid="delete-contact-option"
                      onClick={(e) => e.preventDefault()}
                    >
                      Un-subscribe Contact
                    </CustomDropdownMenuItemWarning>
                  </UnsubscribePreference>
                </UnsubscribePreferenceErrorBoundary>
              )}
              {current && (
                <ConfirmationDialog
                  title={current?.blocked ? 'Un-block Contact' : 'Block Contact'}
                  description={
                    current?.blocked
                      ? 'Un-blocking a contact will set their conversations to a closed state. Messages you missed while the contact was blocked are still preserved.'
                      : 'Blocking a contact means you will no longer see their messages in your inbox. You can unblock a contact at any time.'
                  }
                  onConfirm={() => onBlockContact(!!current?.blocked)}
                  confirmButtonTitle={current?.blocked ? 'Yes, Un-block' : 'Yes, Block'}
                  testID="block-contact-button"
                >
                  <CustomDropdownMenuItemWarning
                    data-testid="delete-contact-option"
                    onClick={(e) => e.preventDefault()}
                  >
                    {current?.blocked ? 'Un-block Contact' : 'Block Contact'}
                  </CustomDropdownMenuItemWarning>
                </ConfirmationDialog>
              )}
              {isAdmin && (
                <ArchiveContact alertAction={onArchive}>
                  <CustomDropdownMenuItemWarning
                    data-testid="delete-contact-option"
                    onClick={(e) => e.preventDefault()}
                  >
                    Delete Contact
                  </CustomDropdownMenuItemWarning>
                </ArchiveContact>
              )}
            </DropdownMenuContent>
          </DropdownMenu>
          {!isInbox && (
            <IconButton size={2} onClick={onClose}>
              <HiX size={16} />
            </IconButton>
          )}
        </HStack>
      </Flex>
      <HStack gap={1} css={{ mt: 4 }}>
        {contact?.phone && !isInbox && (
          <ConversationDrawer
            contact_id={contact.id}
            location_id={channels[0].id}
            contact_name={contact?.name || ''}
            contact_phone={contact?.phone}
          >
            <ContactButton
              icon={<HiChat size={18} color="#60646C" />}
              text={'Open Conversation'}
            />
          </ConversationDrawer>
        )}
        {enableVoip && (
          <ContactButton
            icon={<HiPhoneOutgoing size={18} color="#60646C" />}
            text={'Make a Call'}
            onClick={() => setTimeout(() => voip.newCall(), 0)}
          />
        )}
        <AddContactToSequence contact_ids={[contact?.id || '']}>
          <ContactButton
            icon={<HiPlus size={18} color="#60646C" />}
            text={'Add to Sequence'}
          />
        </AddContactToSequence>
      </HStack>
    </PanelHeaderContainer>
  );
};

type ContactButtonProps = {
  icon?: React.ReactElement;
  text?: string;
  backgroundColor?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

export const ContactButton = (props: ContactButtonProps) => {
  const sanitizedText = props?.text?.toLowerCase()?.replace(/\s/g, '');
  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <IconButton
          onClick={props.onClick}
          size={2}
          variant="ghost"
          css={{ width: 32, height: 32, color: '#60646C' }}
          data-testid={`contact-button-${sanitizedText}`}
        >
          {props.icon}
        </IconButton>
      </TooltipTrigger>
      <TooltipContent>{props.text}</TooltipContent>
    </Tooltip>
  );
};

export const PanelHeaderContainer = styled(VStack, {
  minHeight: 64,
  position: 'relative',
  height: 101,
  width: '100%',
  flexShrink: 0,
  py: 13,
  px: 24,
  borderBottom: '1px solid $colors$slate6',
});

export const Heading = styled(Flex, {
  flex: 'initial 0 initial',
  fontSize: 17,
  fontWeight: 800,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});
