import dayjs from 'dayjs';
import React from 'react';

import { StyledMessage, StyledMessageFooter } from '@/pages/inbox/conversation/items';
import { styled } from '@/stitches.config';

import { Box, Flex, VStack } from '../ui';
import { TIME_STAMP } from '../utils/timestamps';
import { renderText } from './markdown/MarkdownRenderer';

type DemoMessagesProps = {
  /* The message that the customer sent */
  inboundMessage: string;
  /* The message that the bot sent */
  outboundMessage: string;
  /* Whether the message is part of a campaign */
  isCampaign?: boolean;
};

export const DemoMessages = (props: DemoMessagesProps): JSX.Element => {
  return (
    <>
      {props.isCampaign ? (
        <StyledMessageOuterContainer align="center" justify="center">
          <StyledMessageInnerContainer>
            {props.inboundMessage && (
              <Flex direction="column">
                <DemoMessage direction="inbound_sms" css={{ marginRight: 'auto' }}>
                  {renderText(props.inboundMessage)}
                </DemoMessage>
                <StyledMessageFooter justify="start">
                  <Flex align="center">
                    <Box css={{ marginRight: 5 }}>{dayjs().format(TIME_STAMP)}</Box>
                    <Box css={{ fontWeight: 500 }}>Customer</Box>
                  </Flex>
                </StyledMessageFooter>
              </Flex>
            )}
            <Flex direction="column" css={{ mt: props.inboundMessage ? '0' : '50px' }}>
              <DemoMessage
                direction="outbound_sms"
                css={{
                  marginLeft: 'auto',
                }}
              >
                {renderText(props.outboundMessage)}
              </DemoMessage>
              <StyledMessageFooter justify="end">
                <Flex align="center">
                  <Box css={{ fontWeight: 500, marginRight: 5 }}>Automated by Whippy</Box>
                  <Box>{dayjs().format(TIME_STAMP)}</Box>
                </Flex>
              </StyledMessageFooter>
            </Flex>
          </StyledMessageInnerContainer>
        </StyledMessageOuterContainer>
      ) : (
        <>
          <Flex direction="column">
            <DemoMessage direction="inbound_sms" css={{ marginRight: 'auto' }}>
              {renderText(props.inboundMessage)}
            </DemoMessage>
            <StyledMessageFooter justify="start">
              <Flex align="center">
                <Box css={{ marginRight: 5 }}>{dayjs().format(TIME_STAMP)}</Box>
                <Box css={{ fontWeight: 500 }}>Customer</Box>
              </Flex>
            </StyledMessageFooter>
          </Flex>
          <Flex direction="column" css={{ marginTop: 'auto' }}>
            <DemoMessage
              direction="outbound_sms"
              css={{
                marginLeft: 'auto',
              }}
            >
              {renderText(props.outboundMessage)}
            </DemoMessage>
            <StyledMessageFooter justify="end">
              <Flex align="center">
                <Box css={{ fontWeight: 500, marginRight: 5 }}>Automated by Whippy</Box>
                <Box>{dayjs().format(TIME_STAMP)}</Box>
              </Flex>
            </StyledMessageFooter>
          </Flex>
        </>
      )}
    </>
  );
};

export const StyledMessageOuterContainer = styled(Flex, {
  width: '100%',
  borderRadius: '6px',
  border: '1.5px solid $slate5',
});

export const StyledMessageInnerContainer = styled(VStack, {
  width: '100%',
  overflow: 'auto',
  padding: '15px',
  height: '100%',
});

const DemoMessage = styled(StyledMessage, {
  fontSize: 13,
  py: 15,
  px: 20,
  maxWidth: '65%',
  lineHeight: 1.5,
});
