import { useState } from 'react';
import { Virtuoso } from 'react-virtuoso';

import { HelpCard } from '@/pages/settings/help/HelpCard';
import { Contact } from '@/shared/types/contacts';
import { Avatar, Badge, Box, Button, Flex, Input } from '@/shared/ui';
import { initials } from '@/shared/utils/initials/initials';
import { formatPhoneNumber } from '@/shared/utils/validations/validations';
import { styled } from '@/stitches.config';

type AudiencePanelProps = {
  /** we should only show the contacts if the audience is valid */
  isValid: boolean;
  /** array of contacts that will get the campaign */
  contacts: Contact[];
  /** function to remove a contact from the audience */
  handleExclude?: (contact: Contact) => void;
};

export const V2AudiencePanel = ({
  contacts,
  handleExclude,
  isValid,
}: AudiencePanelProps) => {
  // input search field value and filter contacts
  const [search, setSearch] = useState('');

  const filteredContacts = contacts
    .filter((contact) => {
      return (
        (contact?.name || '').toLowerCase().includes(search.toLowerCase()) ||
        (contact?.phone || '').toLowerCase().includes(search.toLowerCase()) ||
        (contact?.email || '').toLowerCase().includes(search.toLowerCase())
      );
    })
    .sort((a, b) => {
      return (a.name || '').localeCompare(b.name || '');
    })
    .map((contact) => {
      const formattedNumber = formatPhoneNumber(contact.phone ?? '');
      return {
        ...contact,
        initials: initials(contact.name),
        // ensure that the number is a string or a null to stay
        // consistent with the phone type
        phone: formattedNumber ?? null,
      };
    });

  // for when we're showing the included contacts
  // if we have no contacts or if the audience is not valid
  if (
    (!handleExclude && filteredContacts.length === 0 && search == '') ||
    (!handleExclude && isValid === false)
  ) {
    return (
      <AudienceEmptyState
        data-testid="exclude_loom_video"
        title="Exclude Contacts"
        description="Select who to exclude from your campaign"
        loom_id="40308ba501a04ce987b39f8fb2bd8691"
      />
    );
  }

  // for when we're showing the excluding contacts
  // if we have no contacts or if the audience is not valid
  if (
    (handleExclude && filteredContacts.length === 0 && search == '') ||
    (handleExclude && isValid === false)
  ) {
    return (
      <AudienceEmptyState
        data-testid="include_loom_video"
        title="Create Audience"
        description="Select who to send your campaign to"
        loom_id="40308ba501a04ce987b39f8fb2bd8691"
      />
    );
  }

  // temporarily hide the Contact List to preserve the Virtuoso state
  // instead of conditionally render
  return (
    <Flex css={{ width: '100%' }}>
      <Flex css={{ p: 20, minWidth: '100%' }} direction="column">
        <>
          <ContactsCount>Total Contacts: {filteredContacts.length}</ContactsCount>
          <Box css={{ width: '100%', pb: 20 }}>
            <Input
              placeholder="Filter contacts"
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
              }}
            />
          </Box>
          <Virtuoso
            style={{ height: '80%' }}
            data={filteredContacts}
            itemContent={(_index, contact) => {
              return (
                <ContactCard
                  key={contact.id}
                  id={contact.id}
                  name={getDisplayName(contact)}
                  phone={getContactInfo(contact)}
                  handleExclude={handleExclude}
                />
              );
            }}
          />
        </>
      </Flex>
    </Flex>
  );
};

type ContactCardProps = {
  /** Contact ID */
  id: string;
  /** Contact name */
  name: string | null;
  /** Contact phone number */
  phone: string;
  /** Array of reasons why a contact is excluded */
  exclusion_criteria?: Array<string>;
  /** Function to remove a contact from the audience */
  handleExclude?: (contact: Contact) => void;
};

export const ContactCard = (props: ContactCardProps) => {
  const { id, name, phone, exclusion_criteria, handleExclude } = props;

  return (
    <Flex css={{ width: '100%', py: 20 }} justify="between">
      <Flex align="center">
        <Box>
          <Avatar fallback={initials(name)} variant="gray" />
        </Box>
        <Flex direction="column" css={{ ml: 12 }}>
          <ContactName>{name || '-'}</ContactName>
          <ContactPhone>{formatPhoneNumber(phone)}</ContactPhone>
        </Flex>
      </Flex>
      {!exclusion_criteria && handleExclude && (
        <Box>
          <Button
            variant="gray"
            onClick={() =>
              handleExclude({
                id: id,
                name: name,
                phone: phone,
              } as Contact)
            }
          >
            Exclude
          </Button>
        </Box>
      )}
      {exclusion_criteria && exclusion_criteria.length > 0 && (
        <Box>
          <Badge css={{ mr: 2 }}>{exclusion_criteria[0]}</Badge>
        </Box>
      )}
    </Flex>
  );
};

type AudienceEmptyStateProps = {
  title: string;
  description: string;
  loom_id: string;
};

export const AudienceEmptyState = (props: AudienceEmptyStateProps) => {
  const { title, description, loom_id } = props;
  return (
    <Flex
      css={{
        p: 20,
        height: '100%',
        width: '100%',
      }}
      direction="column"
    >
      <HelpCard title={title} description={description} loom_id={loom_id} />
    </Flex>
  );
};

export const ContactsCount = styled(Box, {
  fontSize: 16,
  fontWeight: 500,
  pb: 20,
});

export const ContactPhone = styled(Box, {
  color: '#7F7F86',
  fontSize: 14,
});

export const ContactName = styled(Box, {
  color: 'rgba(43, 42, 52, 1)',
  fontSize: 14,
  fontWeight: 500,
  display: '-webkit-box',
  '-webkit-line-clamp': 1,
  '-webkit-box-orient': 'vertical',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  textAlign: 'left',
});

function getDisplayName(contact: Contact) {
  if (contact.name) {
    return contact.name;
  } else if (contact.phone) {
    return contact.phone;
  } else if (contact.email) {
    return contact.email;
  }
  return '-';
}

function getContactInfo(contact: Contact) {
  if (contact.phone) {
    return contact.phone;
  } else if (contact.email) {
    return contact.email;
  }
  return '-';
}
