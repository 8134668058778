import React from 'react';
import { toast } from 'sonner';
import { HiDownload, HiShare, HiX } from 'react-icons/hi';
import { useCopyToClipboard, useMedia } from 'react-use';

import { useDisclosure } from '@/shared/hooks';
import {
  Box,
  Dialog,
  DialogContent,
  DialogOverlay,
  DialogPortal,
  DialogTrigger,
  Flex,
  HStack,
  IconButton,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
  VStack,
} from '@/shared/ui';
import { styled } from '@/stitches.config';

import { DocViewer } from './DocViewer';
import { ImageViewer } from './ImageViewer';
import { PdfViewer } from './PdfViewer';
import { PrintImage } from './PrintImage';
import VideoViewer from './VideoViewer';
import { AudioViewer } from './AudioViewer';
import { getFileType } from '..';
import { FileType } from '../File';

type MediaPreviewProps = {
  url: string;
};

export const MediaPreview: React.FC<MediaPreviewProps> = function (
  props: MediaPreviewProps
) {
  const { url } = props;
  const fileType = getFileType(url);

  switch (fileType) {
    case FileType.Image:
      return <ImageViewer src={url} />;
    case FileType.Video:
      return <VideoViewer url={url} />;
    case FileType.Spreadsheet:
    case FileType.Doc:
    case FileType.Csv:
    case FileType.Presentation:
      return <DocViewer src={url} />;
    case FileType.Pdf:
    case FileType.Text:
      return <PdfViewer src={url} />;
    case FileType.Audio:
      return <AudioViewer src={url} />;
    default:
      return (
        <Flex
          css={{
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
          }}
        >
          <VStack align="center">
            <Box>Attachment preview not supported.</Box>
            <HStack>
              Press the <HiDownload style={{ margin: '10px' }} /> button to download the
              attachment
            </HStack>
          </VStack>
        </Flex>
      );
  }
};

type MediaPreviewContainerProps = {
  url: string;
  children: React.ReactNode;
};

export const MediaPreviewContainer = (props: MediaPreviewContainerProps) => {
  const { url, children } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [, copyToClipboard] = useCopyToClipboard();

  const isDesktop = useMedia('(min-width: 912px)');

  const handleCopyClick = () => {
    copyToClipboard(url);
    toast.success('Copied to clipboard');
  };

  const handleOpenClick = () => {
    if (isDesktop) {
      onOpen();
    }
  };

  const isImage = url.match(/.(jpg|jpeg|png|gif|heic)$/i);

  const handleDownloadClick = () => {
    // copyToClipboard(url) need to be replaced
    copyToClipboard(url);
    toast.success('Downloading');
  };

  return (
    <Dialog open={isOpen}>
      <DialogTrigger asChild>
        <Flex onClick={() => handleOpenClick()} css={{ width: '100% !important' }}>
          {children}
        </Flex>
      </DialogTrigger>
      <DialogPortal>
        {/* set zIndex: 1000 because attachment overlay needs to be at the top of ConversationContainer -> DrawerPortal */}
        {/* now, DrawerPortal has no zIndex setting */}
        <DialogOverlay style={{ zIndex: 1000 }}>
          <StyledMediaPreviewContainer
            onEscapeKeyDown={() => onClose()}
            onPointerDownOutside={() => onClose()}
          >
            <MediaPreview url={url} />
            <HStack gap="3" css={{ position: 'absolute', top: '$3', right: '$4' }}>
              <a href={url} download target="_blank" rel="noreferrer">
                <ToolTipIconButton
                  icon={<HiDownload size={18} />}
                  onClick={() => handleDownloadClick()}
                  description="Download Attachment"
                />
              </a>
              {isImage && <PrintImage src={url} />}
              <ToolTipIconButton
                icon={<HiShare />}
                onClick={() => handleCopyClick()}
                description="Share Attachment"
              />
              <ToolTipIconButton
                icon={<HiX size={18} />}
                onClick={() => onClose()}
                description="Close"
              />
            </HStack>
          </StyledMediaPreviewContainer>
        </DialogOverlay>
      </DialogPortal>
    </Dialog>
  );
};

export const downloadAttachment = async (url: string) => {
  try {
    toast.success('Downloading...');
    const response = await fetch(url);
    if (!response.ok) return console.error("Couldn't download the file");
    const blob = await response.blob();
    const link = document.createElement('a');
    // prevent open new tab
    link.target = '_blank';
    link.href = URL.createObjectURL(blob);
    link.setAttribute('download', ''); // you can also give a specific name like 'download.png'
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    console.error('There was a problem with the download:', error);
  }
};

const StyledMediaPreviewContainer = styled(DialogContent, {
  px: 0,
  py: 60,
  position: 'absolute',
  top: '50%',
  left: '50%',
  mx: 'auto',
  mt: '0px',
  mb: '-30px',
  width: 'calc(100vw - 60px)',
  height: 'calc(100vh - 60px)',
  background: 'rgba(255, 255, 255, 0.85)',
  backgroundSize: 'cover',
});

type ToolTipIconButtonProps = {
  /*
   * Icon to display
   */
  icon: JSX.Element;
  /*
   * Description to display in the tooltip
   */
  description: string;
  /*
   * Function to call when the button is clicked
   */
  onClick?: () => void;
  /*
   * Size of the button
   */
  size?: number;
  /*
   * Variant of the button
   */
  variant?: string;
  /*
   * If true, the button will be disabled
   */
  disabled?: boolean;
  /*
   * optional type of button
   */
  type?: 'button' | 'submit' | 'reset';
  /*
   * testID for component
   */
  dataTestID?: string;
};

export const ToolTipIconButton = React.forwardRef(
  (props: ToolTipIconButtonProps, ref: React.Ref<any>) => {
    const { icon, onClick, description, size, variant, dataTestID } = props;
    return (
      <Tooltip>
        <TooltipTrigger asChild>
          <IconButton
            ref={ref}
            size={size || 2}
            onClick={onClick}
            // @ts-ignore
            variant={variant || 'ghost'}
            disabled={props.disabled ? props.disabled : false}
            type={props.type ? props.type : 'button'}
            data-testid={dataTestID}
          >
            {icon}
          </IconButton>
        </TooltipTrigger>
        <TooltipContent sideOffset={5}>{description}</TooltipContent>
      </Tooltip>
    );
  }
);
