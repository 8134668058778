import {
  Template,
  TemplatesActions,
  TemplatesActionTypes,
  TemplatesState,
} from '@/shared/types/templates';
import { removeDuplicates } from '@/shared/utils/removeDuplicates';

export const TemplatesReducer: React.Reducer<TemplatesState, TemplatesActions> = (
  state: TemplatesState,
  action: TemplatesActions
) => {
  switch (action.type) {
    case TemplatesActionTypes.GET_ALL_TEMPLATES:
      return {
        ...state,
        allTemplates: removeDuplicates([...state.allTemplates, ...action.payload.data]),
        totalCount: action.payload.total,
      } as TemplatesState;
    case TemplatesActionTypes.CREATE_TEMPLATE:
      return {
        ...state,
        allTemplates: [...state.allTemplates, action.payload],
        templates: [action.payload, ...state.templates],
        totalCount: state.totalCount + 1,
        loading: false,
      } as TemplatesState;
    case TemplatesActionTypes.EDIT_TEMPLATE:
      return {
        ...state,
        allTemplates: state.allTemplates.map((template: Template) => {
          return template.id === action.payload.id ? action.payload : template;
        }),
        templates: state.templates.map((template: Template) => {
          return template.id === action.payload.id ? action.payload : template;
        }),
      } as TemplatesState;
    case TemplatesActionTypes.DELETE_TEMPLATE:
      return {
        ...state,
        allTemplates: state.allTemplates.filter((template: Template) => {
          return template.id !== action.payload.id;
        }),
        templates: state.templates.filter((template: Template) => {
          return template.id !== action.payload.id;
        }),
        totalCount: state.totalCount - 1,
      } as TemplatesState;
    case TemplatesActionTypes.BULK_IMPORT_TEMPLATES:
      return {
        ...state,
        allTemplates: [...state.allTemplates, ...action.payload],
        totalCount: state.totalCount + action.payload.length,
      } as TemplatesState;
    case TemplatesActionTypes.SET_CURRENT:
      return {
        ...state,
        current: action.payload,
      } as TemplatesState;
    case TemplatesActionTypes.SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      } as TemplatesState;
    case TemplatesActionTypes.GET_TEMPLATES:
      return {
        ...state,
        templates: action.payload.data,
        totalCount: action.payload.total,
        loading: false,
      };
    case TemplatesActionTypes.UPDATE_FILTER_PARAMS:
      return {
        ...state,
        filterParams: action.payload,
        loading: true,
      };
    default:
      return state as TemplatesState;
  }
};

export default TemplatesReducer;
