/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable react-hooks/exhaustive-deps */
import { captureException } from '@sentry/react';
import { debounce } from 'lodash';
import { useCallback, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import { getCampaigns } from '@/shared/api/campaigns';
import { Campaign } from '@/shared/types/campaigns';
import { SearchV2Type } from '@/shared/types/conversations';

import { TabOptions, useSearch } from '../SearchContext';
import { CampaignSkeleton } from '../skeletons/campaigns';
import { GroupName, KeepResultsFocused, SearchResults } from '../utils';
import { EmptyResults } from './emptyResults';
import { ErrorResults } from './errorResults';

export function RenderCampaignSearch({
  limit = 50,
  showEmptyResults = false,
  showResults = true,
}: {
  limit?: number;
  showEmptyResults?: boolean;
  showResults?: boolean;
}) {
  const {
    searchInput,
    loadings,
    setLoadings,
    setControllers,
    cancelPreviousRequest,
    searchResults,
    setSearchResults,
    errors,
    setErrors,
    offsets,
    setOffsets,
    setPaginatings,
  } = useSearch();
  const history = useHistory();

  useEffect(() => {
    // Reset search results and trigger search when searchInput changes
    setSearchResults((prev) => ({ ...prev, Campaigns: [] }));
    if (searchInput) {
      setLoadings((prev) => ({ ...prev, Campaigns: true }));
      debounceCampaignSearch({ inputValue: searchInput });
    }
  }, [searchInput]);

  // Debounce search to reduce API calls during typing
  const debounceCampaignSearch = useCallback(
    debounce((searchV2: SearchV2Type) => {
      searchCampaigns(searchV2);
    }, 350),
    []
  );

  const handleClick = (campaign: Campaign) => {
    // Navigate to campaign details page on click
    history.push(`/campaigns/${campaign.id}`);
  };

  const searchCampaigns = async ({
    inputValue,
    offset = 0,
    shouldAppendResults = false,
  }: {
    inputValue: string;
    offset?: number;
    shouldAppendResults?: boolean;
  }) => {
    try {
      cancelPreviousRequest('Campaigns'); // Cancel any ongoing search to prevent race conditions

      const controller = new AbortController(); // For cancelable fetch requests
      setControllers((prev) => ({ ...prev, Campaigns: controller }));

      // Perform search
      const campaignResults = await getCampaigns(
        {
          title: inputValue,
          limit,
          offset,
          status: '',
        },
        controller
      );

      const results: SearchResults[] = campaignResults.data.campaigns.map((campaign) => {
        const item: SearchResults = {
          id: campaign.id || '',
          name: inputValue,
          perform: () => handleClick(campaign),
          shortcut: [],
          section: 'Campaign Search',
          subtitle: '',
          keywords: `search campaignSearch ${inputValue}`,
          campaign: campaign,
        };
        return item;
      });

      // If end reached, prevent further pagination
      if (results.length === 0) setOffsets((prev) => ({ ...prev, Campaigns: -1 }));

      setLoadings((prev) => ({ ...prev, Campaigns: false }));
      setSearchResults((prev) => ({
        ...prev,
        Campaigns: shouldAppendResults ? [...prev.Campaigns, ...results] : results,
      }));
    } catch (error) {
      console.error(error);

      if (error.name === 'AbortError' || error.name === 'CanceledError') {
        return console.log('AbortError: The operation was aborted');
      }

      // Display error toast and reset search state on error
      const errorMessage =
        error?.response?.data?.errors?.[0]?.description ?? 'Failed to search campaigns';
      setLoadings((prev) => ({ ...prev, Campaigns: false }));
      setSearchResults((prev) => ({ ...prev, Campaigns: [] }));
      setErrors((prev) => ({
        ...prev,
        Campaigns: { hasError: true, error: errorMessage },
      }));
      captureException(error, {
        tags: {
          area: 'globalSearch',
          feature: 'campaigns',
        },
      });
    }
  };

  const handleMoreResults = () => {
    const offset = offsets[TabOptions.Campaigns];
    const isLoading = loadings[TabOptions.Campaigns];
    if (offset > 0 && !hasNoResults && !isLoading) {
      searchCampaigns({
        inputValue: searchInput,
        offset,
        shouldAppendResults: true,
      }).finally(() => {
        setPaginatings((prev) => ({ ...prev, Campaigns: false }));
      });
    }
  };

  useEffect(() => {
    handleMoreResults();
  }, [offsets[TabOptions.Campaigns]]);

  const hasNoResults = useMemo(
    () => searchResults?.Campaigns?.length === 0 && searchInput !== '',
    [searchResults]
  );

  // Display loading message while searching
  if (loadings.Campaigns) {
    return (
      <>
        <GroupName>Searching campaigns...</GroupName>
        <CampaignSkeleton />
      </>
    );
  }

  // Display error message if search request fails
  if (showResults && errors?.Campaigns?.hasError)
    return <ErrorResults errorMessage={errors?.Campaigns?.error} />;

  // Display "No results found" message if search input is not empty and no results are found
  if (hasNoResults && !loadings.Campaigns && showEmptyResults) {
    return <EmptyResults />;
  }

  // Display search results
  if (!hasNoResults && showResults)
    return <KeepResultsFocused results={searchResults?.Campaigns} label="Campaigns" />;
}
