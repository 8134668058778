import { useState } from 'react';

type Setter = (event: React.FormEvent | string) => void;

export default function useInputState(
  initialValue: string,
  formatter: (s: string) => string
): [string, Setter] {
  const [state, setState] = useState(initialValue || '');

  const onChange = (event: React.FormEvent | string) => {
    if (event === undefined) return;
    let value: string = (() => {
      if (typeof event === 'string') return event;
      else return (event?.target as HTMLInputElement).value;
    })();
    if (formatter) {
      value = formatter(value);
    }
    setState(value);
  };

  return [state, onChange];
}
