import { api } from './api';

export type AttachmentParams = {
  filename: string;
};

/*
  POST - create presigned url for S3 client upload
*/

export const createPresignedUrl = (attachmentParams: AttachmentParams) =>
  api
    .post('/attachments', {
      attachment: attachmentParams,
    })
    .then((res) => {
      return res.data.data;
    });

/*
  DELETE - delete attachment from S3
*/

export const deleteAttachment = (attachmentParams: AttachmentParams) => {
  const encodedFilename = encodeURIComponent(attachmentParams.filename);

  api.delete(`/attachments/${encodedFilename}`).then((res) => {
    return res.data.data;
  });
};
