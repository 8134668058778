import {
  AddToSequenceActionParams,
  CreateLeadActionParams,
  Integration,
  IntegrationConfiguration,
  JobBoardAutomationKeys,
  QueryParams,
  SendMessageActionParams,
  WhippyAction,
} from '@/shared/types/integrations';

import { api } from '../api';

type CreateConfigurationParams = {
  type: string;
  application_id: string;
  data: {
    organization_id: string;
    forwarding_email: string;
    job_board: string;
    api_key: string;
    action: string;
    action_params:
      | AddToSequenceActionParams
      | CreateLeadActionParams
      | SendMessageActionParams;
  };
};

export const getIntegration = async (id: string) => {
  const integration = await api.get<{ data: Integration }>(`/v2/integrations/${id}`);
  return integration.data.data;
};

export const getIntegrations = async () => {
  const integrations = await api.post<{ data: Integration[] }>(`/v2/integrations/`);
  return integrations.data.data;
};

export async function filterIntegrations(params: QueryParams): Promise<Integration[]> {
  const integrations = await api.post<{ data: Integration[] }>(
    `/v2/integrations/`,
    params
  );
  return integrations.data.data;
}

export async function getConfigurations(id: string): Promise<IntegrationConfiguration[]> {
  const configurations = await api.post<{ data: IntegrationConfiguration[] }>(
    `/v2/integrations/${id}/configurations`
  );
  return configurations.data.data;
}

export async function getConfiguration(
  applicationId: string,
  id: string
): Promise<IntegrationConfiguration> {
  const configuration = await api.get<{ data: IntegrationConfiguration }>(
    `/v2/integrations/${applicationId}/configurations/${id}`
  );
  return configuration.data.data;
}

export async function updateConfiguration(
  config: IntegrationConfiguration
): Promise<IntegrationConfiguration> {
  const response = await api.put<{ data: IntegrationConfiguration }>(
    `/v2/integrations/${config.application_id}/configurations/${config.id}`,
    {
      configuration: config,
    }
  );
  return response.data.data;
}

export async function deleteConfiguration(
  applicationId: string,
  id: string
): Promise<void> {
  await api.delete(`/v2/integrations/${applicationId}/configurations/${id}`);
}

export const createJobBoardConfiguration = async (
  apiKey: string,
  jobBoard: JobBoardAutomationKeys,
  applicationId: string,
  organizationId: string,
  configurationParams: AddToSequenceActionParams &
    SendMessageActionParams &
    CreateLeadActionParams & {
      action_type: string;
      api_key: string;
      forwarding_email: string;
    }
): Promise<IntegrationConfiguration> => {
  const actionParams = createActionParams(
    configurationParams.action_type as WhippyAction,
    configurationParams
  );
  const params: CreateConfigurationParams = {
    type: 'job_board',
    application_id: applicationId,
    data: {
      organization_id: organizationId,
      forwarding_email: configurationParams.forwarding_email,
      job_board: jobBoard,
      api_key: apiKey,
      action: configurationParams.action_type,
      action_params: actionParams,
    },
  };
  const configuration = await api.post<{ data: IntegrationConfiguration }>(
    `/v2/integrations/${applicationId}/configurations/create`,
    { configuration: params }
  );
  return configuration.data.data;
};

function createActionParams(
  type: WhippyAction,
  configurationParams: AddToSequenceActionParams &
    SendMessageActionParams &
    CreateLeadActionParams & { action_type: string; api_key: string }
): AddToSequenceActionParams | SendMessageActionParams | CreateLeadActionParams {
  switch (type) {
    case WhippyAction.ADD_TO_SEQUENCE:
      return {
        from_number: configurationParams.from_number,
        sequence_id: configurationParams.sequence_id,
      };
    case WhippyAction.CREATE_LEAD: {
      const actionParams: CreateLeadActionParams = {
        outbound_body: configurationParams.outbound_body,
        delay_in_seconds: configurationParams.delay_in_seconds,
        channel_id: configurationParams.channel_id,
        from_number: configurationParams.from_number,
      };
      // only add the attachment url if one exists
      if (configurationParams.outbound_attachment_url) {
        actionParams.outbound_attachment_url =
          configurationParams.outbound_attachment_url;
      }
      return actionParams;
    }
    case WhippyAction.SEND_MESSAGE: {
      const actionParams: SendMessageActionParams = {
        outbound_body: configurationParams.outbound_body,
        delay_in_seconds: configurationParams.delay_in_seconds,
        from_number: configurationParams.from_number,
      };
      // only add the attachment url if one exists
      if (configurationParams.outbound_attachment_url) {
        actionParams.outbound_attachment_url =
          configurationParams.outbound_attachment_url;
      }
      return actionParams;
    }
  }
}
