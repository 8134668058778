import * as SelectPrimitive from '@radix-ui/react-select';
import { Formik } from 'formik';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { HiChevronDown, HiChevronUp, HiX } from 'react-icons/hi';
import { useMedia } from 'react-use';
import { toast } from 'sonner';
import * as Yup from 'yup';

import { useAuth } from '@/pages/auth/context/AuthProvider';
import {
  FormFieldWrapper,
  FormMessageEditorV2,
  TextInput,
} from '@/shared/components/forms';
import { MultiSelect } from '@/shared/components/MultiSelect';
import { useDisclosure } from '@/shared/hooks';
import { TemplateContentType } from '@/shared/types/templates';
import {
  Box,
  Button,
  Dialog,
  DialogClose,
  DialogCloseIcon,
  DialogContent,
  DialogFooter,
  DialogOverlay,
  DialogPortal,
  DialogTitle,
  DialogTrigger,
  Fieldset,
  Flex,
  Label,
  Select,
  SelectContent,
  SelectGroup,
  SelectIcon,
  SelectItem,
  SelectItemIndicator,
  SelectItemText,
  SelectLabel,
  SelectScrollUpButton,
  SelectTrigger,
  SelectValue,
  SelectViewport,
} from '@/shared/ui';
import { Switch, SwitchThumb } from '@/shared/ui/Switch';

import { useChannels } from '../channels/context/ChannelContext';
import { messageTemplatePermissions } from './constants';
import { useTemplates } from './context/TemplatesContext';
import { ScheduleTemplate } from './ScheduleTemplate';

type AddTemplateProps = {
  isInbox?: boolean;
  /* is the template editor open  in the inbox */
  setShowTemplateEditor?: Dispatch<SetStateAction<boolean>>;
};

const initialScheduledTemplateParams = {
  days_in_the_future: 0,
  hour: 0,
  minute: 0,
  timezone: '',
};

export const AddTemplate = (props: AddTemplateProps): JSX.Element => {
  const { isInbox, setShowTemplateEditor } = props;
  const templatesContext = useTemplates();
  const { createTemplate } = templatesContext;
  const { isOpen, onOpen, onClose } = useDisclosure();

  const authContext = useAuth();
  const { isAdmin } = authContext;

  const locationContext = useChannels();
  const { channels, allChannels } = locationContext.channelsState;
  const [permissionValue, setPermissionValue] = useState('organization');
  const [selectedLocations, setSelectedLocations] = useState({ locations: [] });
  const [isReviewRequest, setIsReviewRequest] = useState(false);
  const [isReviewResponse, setIsReviewResponse] = useState(false);
  const [isScheduledTemplate, setIsScheduledTemplate] = useState(false);
  const [scheduledTemplateParams, setScheduledTemplateParams] = useState(
    initialScheduledTemplateParams
  );
  const [isWhatsAppTemplate, setIsWhatsAppTemplate] = useState(false);

  // set the date from the schedule params if it exists
  // otherwise set the date to the current date
  const [days, setDays] = useState<number>(0);

  // set the time from the schedule params if it exists
  // if it doesn't exist, set the time to the current time
  const [time, setTime] = useState('');

  // set the timezone from the schedule params if it exists
  // or use the default browser timezone
  const [time_zone, setTimezone] = useState(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );

  // disable submit btn if location permission is selected without picking location/s
  const shouldDisableSubmitBtn =
    permissionValue === 'location' && selectedLocations.locations.length < 1;

  const isDesktop = useMedia('(min-width: 620px)');

  const handleOpen = () => {
    onOpen();
    // we need this because if an attachment is added to the editor in the template editor
    // and we are in the inbox, the attachment gets added to the inbox editor and not the template editor
    setShowTemplateEditor ? setShowTemplateEditor(true) : null;
  };

  const handleClose = () => {
    setPermissionValue('organization');
    setShowTemplateEditor ? setShowTemplateEditor(false) : null;
    onClose();
  };

  useEffect(() => {
    if (time) {
      let hour = time?.split(':')[0] || '';
      const minute = time?.split(':')[1]?.split(' ')[0] || '';
      const ampm = time?.split(' ')[1] || '';

      if (ampm === 'PM') {
        if (hour === '12') {
          hour = '12';
        } else {
          hour = (Number(hour) + 12).toString();
        }
      } else {
        if (hour === '12') {
          hour = '00';
        }
      }

      setScheduledTemplateParams({
        days_in_the_future: days,
        hour: Number(hour),
        minute: Number(minute),
        timezone: time_zone,
      });
    }
  }, [time, days, time_zone]);

  return (
    <Dialog open={isOpen} onOpenChange={() => !isOpen}>
      <DialogTrigger asChild>
        <Button onClick={handleOpen} data-intercom-target="add-template-button">
          Add Template
        </Button>
      </DialogTrigger>
      <DialogPortal>
        <DialogOverlay css={{ zIndex: isInbox ? 9999 : undefined }}>
          <DialogContent
            onClick={(e: React.MouseEvent) => e.stopPropagation()}
            onPointerDownOutside={handleClose}
            onEscapeKeyDown={handleClose}
            css={{ zIndex: isInbox ? 999998 : 99998, width: isDesktop ? 620 : '95%' }}
          >
            <DialogTitle variant="bold">Create a Template</DialogTitle>
            <Formik
              initialValues={{
                title: '',
                message: { body: '', attachment_urls: [] },
              }}
              validationSchema={Yup.object({
                title: Yup.string().test(
                  'len',
                  'Must be at least 3 characters',
                  (val) => val !== undefined && val.length > 2
                ),
              })}
              onSubmit={async (values: {
                title: string;
                message: {
                  body: string;
                  attachment_urls: string[];
                };
              }) => {
                const params = {
                  title: values.title,
                  message: values.message.body,
                  attachments: values.message.attachment_urls,
                  access_level: permissionValue,
                  content_type: isReviewRequest
                    ? TemplateContentType.REVIEW_REQUEST
                    : isReviewResponse
                      ? TemplateContentType.REVIEW_RESPONSE
                      : TemplateContentType.GENERAL,
                  is_scheduled_template: isScheduledTemplate,
                  scheduled_template_params: isScheduledTemplate
                    ? scheduledTemplateParams
                    : {},
                  is_whatsapp_template: isWhatsAppTemplate,
                };
                if (permissionValue === 'location') {
                  Object.assign(params, {
                    location_ids: selectedLocations.locations,
                  });
                }
                try {
                  if (params.message.length < 1 && params.attachments.length < 1) {
                    return toast.error(
                      `You must add at least one attachment or a message`
                    );
                  }
                  createTemplate(params);

                  handleClose();
                } catch (e) {
                  console.log(e);
                }
              }}
            >
              {(formik) => (
                <form onSubmit={formik.handleSubmit}>
                  <FormFieldWrapper
                    label="Template Name"
                    tooltip="The name of your template."
                    name="title"
                  >
                    <TextInput placeholder="Example: New Customer Question" />
                  </FormFieldWrapper>
                  <FormFieldWrapper
                    label="Template Message"
                    tooltip="The message of your template."
                    name="message"
                  >
                    <FormMessageEditorV2
                      field="message"
                      placeholder="E.g. We are open from..."
                      showAddAttachment={true}
                      enableAttachments={true}
                      showCharacterCount={true}
                      showAddEmoji={true}
                      showAddReview={true}
                      showAddVariable={true}
                    />
                  </FormFieldWrapper>
                  <FormFieldWrapper label="Permission" name="permissions">
                    <Select
                      value={permissionValue}
                      onValueChange={(e) => setPermissionValue(e)}
                    >
                      <SelectTrigger aria-label="option-select-trigger">
                        <SelectValue />
                        <SelectIcon>
                          <HiChevronDown />
                        </SelectIcon>
                      </SelectTrigger>
                      <SelectPrimitive.Portal>
                        <SelectContent css={{ zIndex: 9999999 }}>
                          <SelectScrollUpButton>
                            <HiChevronUp />
                          </SelectScrollUpButton>
                          <SelectViewport>
                            <SelectGroup>
                              <SelectLabel>Permission</SelectLabel>
                              {messageTemplatePermissions.map((option) => (
                                <SelectItem key={option.value} value={option.value}>
                                  <SelectItemIndicator />
                                  <SelectItemText>{option.label}</SelectItemText>
                                </SelectItem>
                              ))}
                            </SelectGroup>
                          </SelectViewport>
                        </SelectContent>
                      </SelectPrimitive.Portal>
                    </Select>
                  </FormFieldWrapper>
                  {permissionValue === 'location' && (
                    <FormFieldWrapper label="Channels" name="locations">
                      {channels.length > 0 && (
                        <MultiSelect
                          defaultPlaceholder="Channels"
                          defaultSelectedItems={[]}
                          isDropdown={true}
                          options={
                            isAdmin
                              ? allChannels.map((channel: any) => ({
                                  type: channel?.name || '',
                                  value: channel.id,
                                }))
                              : channels.map((channel: any) => ({
                                  type: channel?.name || '',
                                  value: channel.id,
                                }))
                          }
                          parentSelectedItems={selectedLocations}
                          setParentSelectedItems={setSelectedLocations}
                          isCampaigns={true}
                        />
                      )}
                    </FormFieldWrapper>
                  )}
                  <Fieldset>
                    <Flex justify="between" align="center">
                      <Label css={{ marginBottom: 0 }}>
                        Set Template as Review Request
                      </Label>
                      <Switch
                        checked={isReviewRequest}
                        onCheckedChange={(e) => {
                          setIsReviewRequest(e);
                          !!e && setIsReviewResponse(!e);
                        }}
                        aria-label="review-request-template-switch-button"
                      >
                        <SwitchThumb />
                      </Switch>
                    </Flex>
                  </Fieldset>
                  <Fieldset>
                    <Flex justify="between" align="center">
                      <Label css={{ marginBottom: 0 }}>
                        Set Template as Review Response
                      </Label>
                      <Switch
                        checked={isReviewResponse}
                        onCheckedChange={(e) => {
                          setIsReviewResponse(e);
                          !!e && setIsReviewRequest(!e);
                        }}
                        aria-label="review-response-template-switch-button"
                      >
                        <SwitchThumb />
                      </Switch>
                    </Flex>
                  </Fieldset>
                  <Fieldset>
                    <Flex justify="between" align="center">
                      <Label css={{ marginBottom: 0 }}>WhatsApp Template</Label>
                      <Switch
                        checked={isWhatsAppTemplate}
                        onCheckedChange={setIsWhatsAppTemplate}
                        aria-label="whatsapp-template-switch-button"
                      >
                        <SwitchThumb />
                      </Switch>
                    </Flex>
                  </Fieldset>
                  <Fieldset>
                    <Flex justify="between" align="center">
                      <Label css={{ marginBottom: 0 }}>Schedule Template</Label>
                      <Switch
                        checked={isScheduledTemplate}
                        onCheckedChange={setIsScheduledTemplate}
                        aria-label="schedule-template-switch-button"
                      >
                        <SwitchThumb />
                      </Switch>
                    </Flex>
                  </Fieldset>
                  {isScheduledTemplate && (
                    <Box
                      css={{
                        mt: 10,
                        p: 16,
                        backgroundColor: '#F6F6F6',
                        borderRadius: 4,
                      }}
                    >
                      <Box css={{ p: 0, m: 0 }}>
                        <ScheduleTemplate
                          isScheduled={isScheduledTemplate}
                          days={days}
                          setDays={setDays}
                          time={time}
                          setTime={setTime}
                          timezone={time_zone}
                          setTimezone={setTimezone}
                        />
                      </Box>
                    </Box>
                  )}
                  <DialogFooter justify="end" css={{ mt: 15 }}>
                    <DialogClose asChild>
                      <Button variant="gray" css={{ mr: '$1' }} onClick={handleClose}>
                        Cancel
                      </Button>
                    </DialogClose>
                    <DialogClose asChild>
                      <Button type="submit" disabled={shouldDisableSubmitBtn}>
                        Save Template
                      </Button>
                    </DialogClose>
                  </DialogFooter>
                </form>
              )}
            </Formik>
            <DialogClose asChild>
              <DialogCloseIcon onClick={handleClose} size="2">
                <HiX />
              </DialogCloseIcon>
            </DialogClose>
          </DialogContent>
        </DialogOverlay>
      </DialogPortal>
    </Dialog>
  );
};
