/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';

import { usePageView } from '@/shared/hooks';
import { CampaignStatus } from '@/shared/types/campaigns';

import { useCampaignsContext } from './context/CampaignsContext';
import { sortScheduledCampaignsBySendDate } from './context/utils';
import { CampaignsList } from './list';

export const ScheduledCampaigns = (): JSX.Element => {
  // Change analytics page
  usePageView();

  const campaign = useCampaignsContext();
  const {
    campaignsState: { loading, scheduledCampaigns },
  } = campaign;

  return (
    <CampaignsList
      title="Scheduled"
      url="scheduled"
      campaigns={sortScheduledCampaignsBySendDate(scheduledCampaigns)}
      loading={loading}
      status={CampaignStatus.SCHEDULED}
    />
  );
};
