/* eslint-disable react-hooks/exhaustive-deps */
import { debounce } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';

import { useResponsiveColumns } from '@/shared/hooks';

import { useCampaignsContext } from '../context/CampaignsContext';

const LIMIT = 24;

type FetchCampaignTemplatesParams = {
  offset: number;
  /* maximum number of items to include in a query result */
  limit: number;
  /* search for campaign templates by title using an input string. */
  title?: string;
  /* filter campaign templates by industry */
  industry?: string;
  /* filter campaign templates by access_level */
  access_level?: string;
};

export type CurrentTemplatesFilters = {
  /* filter parameter by title */
  title: string;
  /* filter parameter by industry */
  industry?: string[];
};

export const useFilterCampaignTemplates = (isGlobal?: boolean) => {
  const campaign = useCampaignsContext();
  const { getCampaignTemplates, campaignsState } = campaign;
  const { templates, totalCampaignTemplates, loadingTemplates } = campaignsState;
  const [isLoadingNext, setIsLoadingNext] = useState(false);
  const [filters, setFilters] = useState<CurrentTemplatesFilters>({
    title: '',
  });

  const columns = useResponsiveColumns();

  // Increment the "page" number every time the user reaches the end of the list,
  // triggering the loading of the next "page" of data.
  const [offsetCoefficient, setOffsetCoefficient] = useState(0);
  const campaignTemplatesRef = useRef<HTMLDivElement | null>(null);

  // compute how many loading cards to show when fetching for next list of templates
  // example - if last row has only 1 in a 4 column templates, this will return 3
  const getNumberOfBottomLoadingCards = columns - (templates.length % columns);

  // checks if all templates are already fetched
  const isAllTemplatesFetched = templates.length >= totalCampaignTemplates;

  // set new filters (title) on change
  const changeFilters = (newFilters: CurrentTemplatesFilters) => {
    setOffsetCoefficient(0);
    setFilters(newFilters);
  };

  // Debounced fetching campaign templates list
  const debouncedFetchCampaignTemplates = useCallback(
    debounce(async (params: FetchCampaignTemplatesParams) => {
      await getCampaignTemplates(params);
    }, 200),
    []
  );

  // fetch next set of campaign templates to add the the list
  const fetchNextCampaignTemplates = async (params: FetchCampaignTemplatesParams) => {
    setIsLoadingNext(true);
    await getCampaignTemplates(params);
    setIsLoadingNext(false);
  };

  const increaseOffsetCoefficient = () => {
    setOffsetCoefficient((prevCoefficient) => prevCoefficient + LIMIT);
  };

  useEffect(() => {
    // initial fetch of templates
    debouncedFetchCampaignTemplates({
      offset: 0,
      limit: LIMIT,
    });
  }, []);

  // listeners to fetch campaign templates
  useEffect(() => {
    // fetch campaign templates by title
    setOffsetCoefficient(0);
    debouncedFetchCampaignTemplates({
      offset: 0,
      limit: LIMIT,
      title: filters.title,
      industry: filters.industry?.[0],
      access_level: isGlobal ? 'global' : undefined,
    });
  }, [filters.title, filters?.industry, isGlobal]);

  // infinite scroll listener
  useEffect(() => {
    if (
      // is not fetching templates
      !loadingTemplates &&
      // is not fetching new set of templates
      !isLoadingNext &&
      // had not fetched all templates yet
      !isAllTemplatesFetched &&
      // Don't update on the first part of items
      offsetCoefficient !== 0
    ) {
      fetchNextCampaignTemplates({
        offset: offsetCoefficient,
        limit: LIMIT,
        title: filters.title,
        industry: filters.industry?.[0],
        access_level: isGlobal ? 'global' : undefined,
      });
    }
  }, [offsetCoefficient, isAllTemplatesFetched, loadingTemplates, isGlobal]);

  return {
    filters,
    changeFilters,
    offsetCoefficient,
    increaseOffsetCoefficient,
    campaignTemplatesRef,
    totalCampaignTemplates,
    isLoadingNext,
    getNumberOfBottomLoadingCards,
    isAllTemplatesFetched,
    fetchNextCampaignTemplates,
  };
};
