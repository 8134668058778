/* eslint-disable react-hooks/exhaustive-deps */
import * as AccordionPrimitive from '@radix-ui/react-accordion';
import React, { useEffect, useState } from 'react';

import { Box, Flex } from '@/shared/ui';
import { Switch, SwitchThumb } from '@/shared/ui/Switch';
import { CSS, styled } from '@/stitches.config';

import { NotificationPreferencesType } from './context/types';

type NotificationAccordionProps = {
  /* accordion item title */
  title: string;
  /* the accordion value */
  itemValue: NotificationPreferencesType;
  /* set the accordion value */
  setAccordions: () => void;
  /* handles the api call on switch press */
  handleToggleAllNotificationType: (checked: boolean) => void;
  /* custom per item accordion content */
  children: React.ReactNode;
  /* checks if preference currently has a subscription*/
  hasSubscription: boolean;
};

export const NotificationAccordion = (props: NotificationAccordionProps) => {
  const {
    title,
    handleToggleAllNotificationType,
    children,
    setAccordions,
    itemValue,
    hasSubscription,
  } = props;

  const [isSwitchedOn, setIsSwitchedOn] = useState<boolean>(false);

  useEffect(() => {
    if (hasSubscription !== isSwitchedOn) {
      setIsSwitchedOn(hasSubscription);
    }
  }, [hasSubscription]);

  return (
    <StyledItem value={itemValue} defaultValue={itemValue}>
      <Box>
        <StyledHeader>
          <StyledTrigger onClick={() => setAccordions()}>
            <Flex css={{ py: 16 }} align="center">
              <Flex direction="column">
                <Box
                  css={{
                    fontSize: 16,
                    fontWeight: 600,
                  }}
                >
                  {title}
                </Box>
              </Flex>
            </Flex>
          </StyledTrigger>
          <Box>
            <Switch
              checked={isSwitchedOn}
              onCheckedChange={(checked: boolean) => {
                handleToggleAllNotificationType(checked);
                setIsSwitchedOn(checked);
              }}
              css={{ mr: 10, p: 1, ml: 10 }}
            >
              <SwitchThumb />
            </Switch>
          </Box>
        </StyledHeader>
      </Box>
      <StyledContent>{children}</StyledContent>
    </StyledItem>
  );
};

type AccordionPrimitiveProps = React.ComponentProps<typeof AccordionPrimitive.Root>;
type AccordionProps = AccordionPrimitiveProps & { css?: CSS };

export const Accordion = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Root>,
  AccordionProps
>(({ children, ...props }, forwardedRef) => (
  <AccordionPrimitive.Root ref={forwardedRef} {...props}>
    {children}
  </AccordionPrimitive.Root>
));

const StyledItem = styled(AccordionPrimitive.Item, {
  borderRadius: '6px',
  backgroundColor: 'white',
  border: '1px solid #EDEDED',
});

const StyledHeader = styled(AccordionPrimitive.Header, {
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  backgroundColor: '#F8F9FA',
  borderTopLeftRadius: '5px',
  borderTopRightRadius: '5px',

  '&[data-state="closed"]': {
    borderBottomLeftRadius: '5px !important',
    borderBottomRightRadius: '5px !important',
  },
});

const StyledTrigger = styled(AccordionPrimitive.Trigger, {
  all: 'unset',
  boxSizing: 'border-box',
  userSelect: 'none',
  '&::before': {
    boxSizing: 'border-box',
  },
  '&::after': {
    boxSizing: 'border-box',
  },

  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  px: '24px',
  color: '$hiContrast',
  width: '100%',

  cursor: 'pointer',
});

const StyledContent = styled(AccordionPrimitive.Content, {
  p: 24,
});
