import { Formik } from 'formik';
import React, { useCallback } from 'react';
import { HiX } from 'react-icons/hi';
import { toast } from 'sonner';

import { useDisclosure } from '@/shared/hooks';
import { DomainSetupType } from '@/shared/types/domains';
import {
  Box,
  Button,
  Dialog,
  DialogClose,
  DialogCloseIcon,
  DialogContent,
  DialogFooter,
  DialogOverlay,
  DialogPortal,
  DialogTitle,
  DialogTrigger,
} from '@/shared/ui';

import { useDomainsContext } from '../context/DomainsContext';
import { DomainSetup } from './DomainSetup';

export const EditDomain = () => {
  const {
    updateDomain,
    domainsState: { current },
  } = useDomainsContext();
  const { isOpen, onOpen, onClose } = useDisclosure();

  // handle update domain
  const handleSubmit = useCallback(
    async (values: { links: boolean; emails: boolean }) => {
      try {
        const params = {
          setup_for: [] as Array<string>,
        };
        if (values.links) {
          params.setup_for.push(DomainSetupType.LINKS);
        }

        if (values.emails) {
          params.setup_for.push(DomainSetupType.EMAILS);
        }
        await updateDomain({ ...params, id: current?.id ?? '' });
        onClose();
      } catch (e) {
        toast.error(e?.response?.data?.errors?.[0]?.description as string);
      }
    },
    [current]
  );

  return (
    <Dialog open={isOpen} onOpenChange={() => !isOpen}>
      <DialogTrigger asChild>
        <Button variant="gray" onClick={onOpen} data-intercom-target="edit-domain-button">
          Edit Domain
        </Button>
      </DialogTrigger>
      <DialogPortal>
        <DialogOverlay as="div">
          <DialogContent onEscapeKeyDown={onClose} onPointerDownOutside={onClose}>
            <DialogTitle variant="bold" css={{ marginBottom: 20 }}>
              Edit Domain
            </DialogTitle>
            <Formik
              initialValues={{
                links: current?.setup_for.includes(DomainSetupType.LINKS) ?? false,
                emails: current?.setup_for.includes(DomainSetupType.EMAILS) ?? false,
              }}
              onSubmit={handleSubmit}
            >
              {(formik) => (
                <form onSubmit={formik.handleSubmit} data-testid="edit-domain-form">
                  <DomainSetup />
                  <Box css={{ pt: 15 }} />
                  <DialogFooter justify="end" css={{ mt: 15 }}>
                    <DialogClose asChild>
                      <Button variant="gray" css={{ mr: '$1' }} onClick={onClose}>
                        Cancel
                      </Button>
                    </DialogClose>
                    <DialogClose asChild>
                      <Button type="submit">Confirm</Button>
                    </DialogClose>
                  </DialogFooter>
                </form>
              )}
            </Formik>
            <DialogClose asChild>
              <DialogCloseIcon onClick={onClose} size="2">
                <HiX />
              </DialogCloseIcon>
            </DialogClose>
          </DialogContent>
        </DialogOverlay>
      </DialogPortal>
    </Dialog>
  );
};
