/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';

import { useTags } from '@/pages/settings/organization/tags/context/TagsContext';
import { Tag } from '@/shared/types/tags';

import { FormFieldWrapper, helpers } from '../../../forms';
import { MultiSelectCombobox } from '../../../forms';
import { ActionProps } from '.';

export const AddTagsAction = (props: ActionProps) => {
  const { index } = props;

  const tags = useTags();
  const { tagsState, getTags } = tags;
  const { allTags } = tagsState;

  useEffect(() => {
    getTags();
  }, []);

  return (
    <FormFieldWrapper
      label="Add Tags"
      tooltip="Add tags to the contact when this action is executed"
      name={`actions[${index}].params.tag_ids`}
    >
      <MultiSelectCombobox
        placeholder={helpers.displaySelectedItems}
        selectAll={false}
        isDropdown={true}
        name="tags"
        options={allTags.map((tag: Tag) => ({
          type: tag.name,
          value: tag.id,
        }))}
      />
    </FormFieldWrapper>
  );
};
