import { Contact } from '@/shared/types/contacts';

export function applySort(
  contacts: Contact[],
  sortField: string,
  sortOrder: 'asc' | 'desc'
): Contact[] {
  return contacts.sort((a, b) => {
    let fieldA, fieldB;

    switch (sortField) {
      case 'inserted_at':
      case 'updated_at':
        fieldA = new Date(a[sortField]);
        fieldB = new Date(b[sortField]);
        break;
      case 'name':
      case 'email':
      case 'phone':
        fieldA = a[sortField]?.toLowerCase() ?? '';
        fieldB = b[sortField]?.toLowerCase() ?? '';
        break;
      default: // Default to 'updated_at' desc if sortField is unrecognized
        fieldA = new Date(a['updated_at']);
        fieldB = new Date(b['updated_at']);
        sortOrder = 'desc';
        break;
    }

    if (sortOrder === 'asc') {
      return fieldA < fieldB ? -1 : fieldA > fieldB ? 1 : 0;
    } else {
      // 'desc'
      return fieldA > fieldB ? -1 : fieldA < fieldB ? 1 : 0;
    }
  });
}
