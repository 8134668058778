import { Formik } from 'formik';
import React, { memo, useCallback } from 'react';
import { HiPencil, HiX } from 'react-icons/hi';
import * as Yup from 'yup';

import { FormFieldWrapper, TextInput } from '@/shared/components/forms';
import { useDisclosure } from '@/shared/hooks';
import {
  Button,
  Dialog,
  DialogClose,
  DialogCloseIcon,
  DialogContent,
  DialogFooter,
  DialogOverlay,
  DialogPortal,
  DialogTitle,
  DialogTrigger,
  IconButton,
} from '@/shared/ui';
import { generateKey } from '@/shared/utils/generateKey/generateKey';

import { CustomObject, UpdateCustomObjectParams } from '../../../../../shared/types/data';

type UpdateObjectProps = {
  /** current data object */
  data: CustomObject;
  /** function triggered on submit form */
  onUpdate: (object_id: string, params: UpdateCustomObjectParams) => Promise<void>;
};

export const UpdateObject = memo(({ data, onUpdate }: UpdateObjectProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  // handle update custom object
  const handleSubmit = useCallback(
    async (
      values: {
        label: string;
      },
      actions: any
    ) => {
      try {
        const params = {
          key: generateKey(values.label),
          label: values.label,
        };
        await onUpdate(data.id, params);
        actions.resetForm({
          values: {
            label: '',
          },
        });
        onClose();
      } catch (e) {
        console.log(e);
      }
    },
    []
  );

  return (
    <Dialog open={isOpen} onOpenChange={() => !isOpen}>
      <DialogTrigger asChild>
        <IconButton
          size={2}
          variant="outline"
          onClick={onOpen}
          data-testid="update-data-object-trigger"
          data-intercom-target="update-data-object-button"
          css={{ mx: 10 }}
        >
          <HiPencil />
        </IconButton>
      </DialogTrigger>
      <DialogPortal>
        <DialogOverlay as="div">
          <DialogContent onEscapeKeyDown={onClose} onPointerDownOutside={onClose}>
            <DialogTitle variant="bold">Edit Object</DialogTitle>
            <Formik
              initialValues={{
                label: data.label,
              }}
              validationSchema={Yup.object({
                label: Yup.string().test(
                  'len',
                  'Must be at least 3 characters',
                  (val) => val !== undefined && val.length > 2
                ),
              })}
              onSubmit={handleSubmit}
            >
              {(formik) => (
                <form
                  onSubmit={formik.handleSubmit}
                  data-testid="update-custom-object-form"
                >
                  <FormFieldWrapper label="Object Name" name="label">
                    <TextInput placeholder="Enter name" />
                  </FormFieldWrapper>
                  <DialogFooter justify="end" css={{ mt: 15 }}>
                    <DialogClose asChild>
                      <Button variant="gray" css={{ mr: '$1' }} onClick={onClose}>
                        Cancel
                      </Button>
                    </DialogClose>
                    <DialogClose asChild>
                      <Button type="submit" disabled={!formik.isValid}>
                        Save
                      </Button>
                    </DialogClose>
                  </DialogFooter>
                </form>
              )}
            </Formik>
            <DialogClose asChild>
              <DialogCloseIcon onClick={onClose} size="2">
                <HiX />
              </DialogCloseIcon>
            </DialogClose>
          </DialogContent>
        </DialogOverlay>
      </DialogPortal>
    </Dialog>
  );
});
