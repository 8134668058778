import { Review } from '@/shared/types';

import {
  CLEAR_REVIEW_RESPONSE,
  GET_CONSENT_URL,
  GET_REVIEWS,
  SET_FILTER,
  SET_LOADING,
  SET_REVIEW_COMMENT,
} from './types';

type ReviewsAction =
  | { type: 'GET_REVIEWS'; payload: any }
  | { type: 'CLEAR_REVIEW_RESPONSE'; payload: any }
  | { type: 'SET_REVIEW_COMMENT'; payload: any }
  | { type: 'GET_CONSENT_URL'; payload: any }
  | { type: 'SET_LOADING'; payload: boolean }
  | { type: 'SET_FILTER'; payload: string };

const ReviewReducer = (state: any, action: ReviewsAction) => {
  switch (action.type) {
    case GET_REVIEWS:
      return {
        ...state,
        reviews: action.payload,
        loading: false,
      };
    case CLEAR_REVIEW_RESPONSE:
      return {
        ...state,
        reviews: state.reviews.map((review: Review) => {
          return review.reviewId === action.payload
            ? { ...review, ...{ reviewReply: null } }
            : review;
        }),
      };
    case SET_REVIEW_COMMENT:
      return {
        ...state,
        reviews: state.reviews.map((review: Review) => {
          return review.reviewId === action.payload.reviewId
            ? { ...review, ...action.payload }
            : review;
        }),
      };
    case GET_CONSENT_URL:
      return {
        ...state,
        consentUrl: action.payload,
      };
    case SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case SET_FILTER:
      return {
        ...state,
        filter: action.payload,
      };
    default:
      return state;
  }
};

export default ReviewReducer;
