import { Channel } from '@/shared/types/channels';

export const getDefaultChannel = (
  channel?: string,
  channels?: Array<Channel>,
  preferredLocation?: string
): string => {
  if (channel) {
    return channel;
  } else if (channels?.length === 1) {
    return channels[0].id;
  } else if (preferredLocation && isChannelsInList(preferredLocation, channels)) {
    return preferredLocation;
  } else {
    return '';
  }
};

const isChannelsInList = (id: string, channels?: Array<Channel>) => {
  return !!channels?.some((channel: Channel) => channel.id === id);
};
