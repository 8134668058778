import * as SelectPrimitive from '@radix-ui/react-select';
import React, { memo } from 'react';
import { HiChevronDown, HiChevronUp } from 'react-icons/hi';
import { toast } from 'sonner';

import {
  Box,
  Fieldset,
  Label,
  Select,
  SelectContent,
  SelectGroup,
  SelectIcon,
  SelectItem,
  SelectItemIndicator,
  SelectItemText,
  SelectScrollUpButton,
  SelectTrigger,
  SelectValue,
  SelectViewport,
  Text,
  VStack,
} from '@/shared/ui';
import i18next from '@/shared/utils/translation';

import { useSettings } from './context/SettingsContext';
import { OptOutOptions } from './context/types';

type CommunicationPreferencesProps = {
  settings: {
    contact_opt_out_default: OptOutOptions;
  };
};

export const CommunicationPreferences = memo(
  ({ settings }: CommunicationPreferencesProps) => {
    const { updateSettings } = useSettings();

    const communication_preferences_options = [
      { value: OptOutOptions.LOCATION, label: 'Channel' },
      { value: OptOutOptions.CHANNEL_TYPE, label: 'Channel Type' },
      { value: OptOutOptions.ALL, label: 'All' },
    ];

    const handlePreferenceUpdate = async (value: OptOutOptions) => {
      try {
        const data = await updateSettings({
          settings: {
            contact_opt_out_default: value,
          },
        });
        if (data) {
          toast.success(i18next.t('organization_opt_out_preferences_success') as string);
        }
      } catch (error) {
        toast.error(i18next.t('organization_opt_out_preferences_failure') as string);
      }
    };

    return (
      <VStack
        css={{
          mt: '$6',
        }}
      >
        <Fieldset>
          <VStack gap={3}>
            <Box>
              <Label>Communication Preferences</Label>
              <Text>
                Choose if a contact should be opted out of just the location, all
                locations with a give channel type, or all communication, when they
                message STOP.
              </Text>
            </Box>
            <Select
              defaultValue={settings?.contact_opt_out_default || OptOutOptions.LOCATION}
              value={settings?.contact_opt_out_default || OptOutOptions.LOCATION}
              onValueChange={(value: OptOutOptions) => handlePreferenceUpdate(value)}
            >
              <SelectTrigger
                aria-label="action-select-trigger"
                css={{ minWidth: '250px' }}
              >
                <SelectValue />
                <SelectIcon>
                  <HiChevronDown />
                </SelectIcon>
              </SelectTrigger>
              <SelectPrimitive.Portal>
                <SelectContent>
                  <SelectScrollUpButton>
                    <HiChevronUp />
                  </SelectScrollUpButton>
                  <SelectViewport>
                    <SelectGroup>
                      {communication_preferences_options.map((action) => (
                        <SelectItem key={action.value} value={action.value}>
                          <SelectItemIndicator />
                          <SelectItemText>{action.label}</SelectItemText>
                        </SelectItem>
                      ))}
                    </SelectGroup>
                  </SelectViewport>
                </SelectContent>
              </SelectPrimitive.Portal>
            </Select>
          </VStack>
        </Fieldset>
      </VStack>
    );
  }
);
