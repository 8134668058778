import { useCallback } from 'react';
import { HiClipboardList, HiTag, HiX } from 'react-icons/hi';

import { ConfirmationDialog } from '@/shared/components/ConfirmationDialog';
import { ContactTagType } from '@/shared/types';
import { Box, Flex, HStack, IconButton } from '@/shared/ui';
import { styled } from '@/stitches.config';

type ContactTagProps = {
  text: string;
  id: string;
  color?: string;
  contactId?: string;
  onDelete?: (id: string) => void;
  type?: ContactTagType;
};

export const ContactTag = ({
  text,
  id,
  color,
  contactId,
  onDelete,
  type = ContactTagType.STANDARD,
}: ContactTagProps) => {
  const removeTagFromContact = useCallback(() => {
    if (contactId && id) {
      onDelete?.(id);
    }
  }, [contactId, id]);

  return (
    <ContactTagContainer css={{ height: 20, backgroundColor: `${color}2F` }}>
      <HStack gap={1}>
        {type === ContactTagType.UPLOAD ? (
          <HiClipboardList
            color={color === 'white' || color === '#fff' ? '$blackA' : ''}
          />
        ) : (
          <HiTag color={color === 'white' || color === '#fff' ? '$blackA' : ''} />
        )}
        <Box
          css={{
            whiteSpace: 'nowrap',
            cursor: 'default',
            userSelect: 'none',
            color: color === 'white' || color === '#fff' ? '$blackA' : '',
            lineHeight: '20px',
          }}
        >
          {text}
        </Box>
        {id && (
          <ConfirmationDialog
            title={`Delete ${type === ContactTagType.STANDARD ? 'Contact Tag' : 'Contact List'}`}
            description={
              type === ContactTagType.STANDARD
                ? `Are you sure to delete ${
                    text || ''
                  } from contact? You can retag any time.`
                : `Are you sure to delete contact from ${
                    text || ''
                  } list? You can add contact to a ${text || ''} list any time.`
            }
            confirmButtonTitle="Yes, Delete"
            onConfirm={removeTagFromContact}
            testID={`delete-button-tag-${id}`}
          >
            <IconButton size={0}>
              <HiX
                size={12}
                color={color === 'white' || color === '#fff' ? '$blackA' : ''}
              />
            </IconButton>
          </ConfirmationDialog>
        )}
      </HStack>
    </ContactTagContainer>
  );
};

export const ContactTagContainer = styled(Flex, {
  maxWidth: '100%',
  width: 'max-content',
  fontSize: '12px',
  fontWeight: 'medium',
  borderRadius: 3,
  py: 2,
  px: 8,
  my: 4,
  mr: 8,
  ml: 0,
  backgroundColor: '$panel',
  cursor: 'pointer',
  boxShadow: 'inset 0 0 0 1px $colors$gray4',
  color: '$hiContrast',
  '@hover': {
    '&:hover': {
      boxShadow: 'inset 0 0 0 1px $colors$gray4',
    },
  },
});
