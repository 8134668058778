import React from 'react';

import {
  ConfigurationActions,
  ConfigurationActionTypes,
} from '@/shared/types/integrations';

import { ConfigurationsState } from './IntegrationConfigurationContext';

export const ConfigurationReducer: React.Reducer<
  ConfigurationsState,
  ConfigurationActions
> = (state: ConfigurationsState, action: ConfigurationActions) => {
  switch (action.type) {
    case ConfigurationActionTypes.GET_CONFIGURATIONS:
      return {
        ...state,
        configurations: action.payload,
        loading: false,
      } as ConfigurationsState;

    case ConfigurationActionTypes.CREATE_CONFIGURATION:
      return {
        ...state,
        // append latest configuration to the list
        configurations: [...state.configurations, action.payload],
        loading: false,
      } as ConfigurationsState;
    case ConfigurationActionTypes.SET_CURRENT_CONFIGURATION:
      return {
        ...state,
        current: action.payload,
        loading: false,
      } as ConfigurationsState;
    case ConfigurationActionTypes.GET_CONFIGURATION:
      return {
        ...state,
        current: action.payload,
        loading: false,
      } as ConfigurationsState;
    // Place holder
    case ConfigurationActionTypes.GET_CONFIGURATIONS_BY_INTEGRATION:
      return {
        ...state,
        configurations: [...state.configurations, action.payload],
        loading: false,
      } as ConfigurationsState;
    case ConfigurationActionTypes.DELETE_CONFIGURATION:
      return {
        ...state,
        // remove the configuration from the list
        configurations: state.configurations.filter(
          (config) => config.id != action.payload
        ),
        loading: false,
      } as ConfigurationsState;
  }
};
