import React, { createContext, useContext, useEffect, useState } from 'react';

import {
  getSideNavigationSettings,
  setSideNavigationSettings,
} from '@/shared/utils/storage';

export type NavigationSettingsType = {
  width: number;
  expanded: boolean;
  name?: string;
};

type SideNavigationContextProps = {
  navigationSettings: NavigationSettingsType;
  setNavigationSettings: (value: any) => void;
  toggleExpandedState: () => void;
};

export const sideNavigationInitialValue = { width: 230, expanded: true };

export const SideNavigationContext = createContext<SideNavigationContextProps>({
  navigationSettings: sideNavigationInitialValue,
  setNavigationSettings: () => undefined,
  toggleExpandedState: () => undefined,
});

export const SideNavigationContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [navigationSettings, setNavigationSettings] = useState(
    sideNavigationInitialValue
  );

  // Local Storage: setting & getting data
  useEffect(() => {
    const navigationSettings = getSideNavigationSettings();

    if (navigationSettings) {
      setNavigationSettings(navigationSettings);
    }
  }, []);

  useEffect(() => {
    setSideNavigationSettings(navigationSettings);
  }, [navigationSettings]);

  const toggleExpandedState = () => {
    setNavigationSettings(
      navigationSettings.expanded
        ? { ...sideNavigationInitialValue, expanded: false }
        : sideNavigationInitialValue
    );
  };

  return (
    <SideNavigationContext.Provider
      value={{ navigationSettings, setNavigationSettings, toggleExpandedState }}
    >
      {children}
    </SideNavigationContext.Provider>
  );
};

export const useSidenavigationSettings = () => useContext(SideNavigationContext);
