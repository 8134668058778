import { Channel, MetaOnboardingParams } from '@/shared/types/channels';
import { FilterData } from '@/shared/types/filter';

import { api } from '../api';

export type ChannelParams = {
  id?: string;
  name?: string;
  address?: string;
  widget_setting_id?: string | null;
  phone?: string;
  state?: string;
  review_link?: string;
  google_place_id?: string | null;
  automatic_response_open?: string | null;
  automatic_response_closed?: string | null;
};

/*
  GET - the organizations's channels
*/

export const fetchChannels = (queryParams?: string) =>
  api.get(`/locations?${queryParams || ''}`).then((res) => {
    return res.data.data;
  });
/*
  PUT - disable a channel in an organization
*/

export const disableChannel = (channelParams: ChannelParams) =>
  api.put(`/locations/disable/${channelParams.id}`).then((res) => {
    return res.data.data;
  });

/*
  V2 API Calls 
*/

export const searchChannels = (params: FilterData) =>
  api.post(`/v2/channels/search`, { ...params }).then((res) => {
    return res.data;
  });

export const getChannel = (id: string) =>
  api.get(`/v2/channels/${id}`).then((res) => {
    return res.data.data;
  });

export const createChannel = (
  params: Partial<Channel>,
  meta_onboarding_params?: MetaOnboardingParams
) =>
  api.post(`/v2/channels`, { channel: params, meta_onboarding_params }).then((res) => {
    return res.data.data;
  });

export const updateChannel = (params: Partial<Channel>) =>
  api.put(`/v2/channels/${params.id}`, { channel: params }).then((res) => {
    return res.data.data;
  });
