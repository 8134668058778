/* eslint-disable react-hooks/exhaustive-deps */
import * as TabsPrimitive from '@radix-ui/react-tabs';
import React, { useEffect, useState } from 'react';

import { useCampaignsContext } from '@/campaigns/context/CampaignsContext';
import { CampaignAnalyticsTab } from '@/campaigns/context/types';
import { Flex } from '@/shared/ui';
import { styled } from '@/stitches.config';

import { CampaignContacts } from './CampaignContacts';
import { CampaignOverview } from './overview';

export const CampaignAnalyticsTabs = () => {
  const campaigns = useCampaignsContext();
  const {
    campaignsState,
    getCampaignContacts,
    setSelectedContacts,
    setAllSelected,
    setCurrentTab,
    currentTab,
    setCurrentCampaign,
  } = campaigns;
  const {
    unfulfilledContacts,
    unsubscribedContacts,
    unrespondedContacts,
    respondedContacts,
    clickedContacts,
    notClickedContacts,
    deliveredContacts,
    notDeliveredContacts,
    current,
  } = campaignsState;

  const campaignsTabs = [
    {
      value: CampaignAnalyticsTab.OVERVIEW,
    },
    {
      value: CampaignAnalyticsTab.DELIVERED,
      contacts: deliveredContacts,
    },
    {
      value: CampaignAnalyticsTab.NOT_DELIVERED,
      contacts: notDeliveredContacts,
    },
    {
      value: CampaignAnalyticsTab.RESPONDED,
      contacts: respondedContacts,
    },
    {
      value: CampaignAnalyticsTab.UNRESPONDED,
      contacts: unrespondedContacts,
    },
    {
      value: CampaignAnalyticsTab.CLICKS,
      contacts: clickedContacts,
    },
    {
      value: CampaignAnalyticsTab.NOT_CLICKED,
      contacts: notClickedContacts,
    },
    {
      value: CampaignAnalyticsTab.UNSUBSCRIBED,
      contacts: unsubscribedContacts,
    },
    {
      value: CampaignAnalyticsTab.UNFULFILLED,
      contacts: unfulfilledContacts,
    },
  ];

  const [offset, setOffset] = useState(0);

  // when the current tab changes set selected contacts null
  // un-select select all
  useEffect(() => {
    setSelectedContacts([]);
    setAllSelected(false);
  }, [currentTab]);

  // when the offset changes then potentially request more contacts
  useEffect(() => {
    if (current && current.id && offset > 0) {
      getCampaignContacts(current?.id, currentTab, offset, 50);
    }
  }, [offset]);

  const renderCampaignTab = () => {
    return campaignsTabs.map((tab) => {
      return (
        <CampaignAnalyticsTabContent value={tab.value} key={tab.value}>
          {tab.value === CampaignAnalyticsTab.OVERVIEW ? (
            <CampaignOverview
              campaign={current}
              setCurrentCampaign={setCurrentCampaign}
            />
          ) : (
            <>
              {tab?.contacts && (
                <CampaignContacts
                  campaign_contacts={tab?.contacts}
                  setOffset={setOffset}
                />
              )}
            </>
          )}
        </CampaignAnalyticsTabContent>
      );
    });
  };

  return (
    <TabsPrimitive.Root
      defaultValue={CampaignAnalyticsTab.OVERVIEW}
      onValueChange={(value: string) => {
        setCurrentTab(value as CampaignAnalyticsTab);
      }}
    >
      <CampaignAnalyticsTabsList />
      {renderCampaignTab()}
    </TabsPrimitive.Root>
  );
};

type CampaignContactsTabTriggerProps = {
  value: string;
  title: string;
  result: number | string;
};

const CampaignContactsTabTrigger = (props: CampaignContactsTabTriggerProps) => {
  const { value, title, result } = props;
  return (
    <CampaignContactsTabsTriggerContainer value={value}>
      <Flex direction="column" justify="start">
        <Flex css={{ mb: 5, fontWeight: 800 }}>{result}</Flex>
        <Flex css={{ fontWeight: 600 }}>{title}</Flex>
      </Flex>
    </CampaignContactsTabsTriggerContainer>
  );
};

const CampaignAnalyticsTabsList = () => {
  const campaigns = useCampaignsContext();
  const { campaignsState } = campaigns;
  const { current } = campaignsState;
  const analytics = current?.analytics
    ? current?.analytics
    : {
        delivery_rate: 0,
        responses: 0,
        sent_messages: 0,
        failed_messages: 0,
        slated_messages: 0,
        response_rate: 0,
        link_clicks_rate: 0,
        unsubscribe_rate: 0,
        unfulfilled_rate: 0,
      };

  const response_rate = () => {
    if (analytics.responses === 0) {
      return 0;
    }
    return Math.round((analytics.responses / analytics.sent_messages) * 100).toFixed(0);
  };

  const unresponse_rate = () => {
    if (analytics.responses === 0) {
      return 100;
    }
    return Math.round(
      ((analytics.sent_messages - analytics.responses) / analytics.sent_messages) * 100
    ).toFixed(0);
  };

  const unfulfilledTabTitle = () => {
    if (current?.status === 'complete') {
      return 'Cancelled';
    }
    return 'Pending';
  };

  // returns 100% if the value is greater than 100, and 0 if the value is less than 0
  // this prevent random values from being displayed if the backend returns a value greater than 100
  const sanitize = (value: string | number) => {
    if (typeof value === 'string') {
      const num = parseInt(value);
      if (num > 100) {
        return 100;
      }
      if (num < 0) {
        return 0;
      }
      return num;
    }
    if (typeof value === 'number') {
      if (value > 100) {
        return 100;
      }
      if (value < 0) {
        return 0;
      }
      return value;
    }
  };

  return (
    <CampaignContactsTagsList loop={true}>
      <CampaignContactsTabTrigger
        value={CampaignAnalyticsTab.OVERVIEW}
        title="Overview"
        result="SMS"
      />
      <CampaignContactsTabTrigger
        value={CampaignAnalyticsTab.DELIVERED}
        title="Delivered"
        result={`${analytics?.delivery_rate.toFixed(0)}%`}
      />
      {analytics?.unfulfilled_rate > 0 && (
        <CampaignContactsTabTrigger
          value={CampaignAnalyticsTab.UNFULFILLED}
          title={unfulfilledTabTitle()}
          result={`${analytics?.unfulfilled_rate.toFixed(0)}%`}
        />
      )}
      <CampaignContactsTabTrigger
        value={CampaignAnalyticsTab.NOT_DELIVERED}
        title="Not delivered"
        result={`${(100 - analytics.delivery_rate).toFixed(0)}%`}
      />
      <CampaignContactsTabTrigger
        value={CampaignAnalyticsTab.RESPONDED}
        title="Responded"
        result={`${response_rate()}%`}
      />
      <CampaignContactsTabTrigger
        value={CampaignAnalyticsTab.UNRESPONDED}
        title="Unresponded"
        result={`${unresponse_rate()}%`}
      />
      {analytics.link_clicks_rate > 0 && (
        <CampaignContactsTabTrigger
          value={CampaignAnalyticsTab.CLICKS}
          title="Link Clicks"
          result={`${sanitize(analytics.link_clicks_rate.toFixed(0))}%`}
        />
      )}
      {analytics.link_clicks_rate > 0 && (
        <CampaignContactsTabTrigger
          value={CampaignAnalyticsTab.NOT_CLICKED}
          title="Not clicked"
          result={`${sanitize((100 - analytics.link_clicks_rate).toFixed(0))}%`}
        />
      )}
      {analytics.unsubscribe_rate > 0 && (
        <CampaignContactsTabTrigger
          value={CampaignAnalyticsTab.UNSUBSCRIBED}
          title="Unsubscribed"
          result={`${sanitize(analytics.unsubscribe_rate.toFixed(0))}%`}
        />
      )}
    </CampaignContactsTagsList>
  );
};

const CampaignContactsTagsList = styled(TabsPrimitive.List, {
  flexShrink: 0,
  display: 'flex',
  height: 90,
  justifyContent: 'start',
  borderBottom: '1px solid rgb(226, 232, 240)',
});

const CampaignContactsTabsTriggerContainer = styled(TabsPrimitive.Trigger, {
  flexGrow: 1,
  paddingLeft: 30,
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  fontSize: '13px',
  fontWeight: 700,
  zIndex: 2,
  color: '$gray12',
  borderBottom: '1px solid #EDEDED',
  '&:focus': { outline: 'none' },
  '&[data-state="active"]': {
    borderBottomWidth: '1.5px',
    borderBottomColor: '$primaryColor',
    color: '$primaryColor',
  },
});

const CampaignAnalyticsTabContent = styled(TabsPrimitive.Content, {
  flexGrow: 1,
  height: '83vh',
  overflowY: 'auto',
  '@lg': {
    height: 'inherit',
  },
});
