import { useEffect, useRef } from 'react';
import { toast } from 'sonner';

import { CountdownToast } from '../CountdownToast';

// This hook is used to manage the display of a toast notification during reconnection attempts.
export const useReconnectToast = (
  reconnectAttempts: number,
  reconnectingIn: number | undefined
) => {
  // useRef is used to store the current toast ID for later reference.
  const toastIdRef = useRef<string | number | null>(null);

  // useEffect is used to perform side effects in our functional component.
  // In this case, it's used to manage the creation and dismissal of the toast notification.
  useEffect(() => {
    // createToast is a helper function that manages the creation and dismissal of the toast.
    const createToast = () => {
      // If there have been more than 2 reconnect attempts and there is no current toast,
      // create a new error toast with a countdown.
      if (reconnectAttempts > 2 && toastIdRef.current === null) {
        toastIdRef.current = toast.error(
          <CountdownToast
            initialCount={Math.floor((reconnectingIn || 0) / 1000)}
            onEnd={() => {
              // When the countdown ends, dismiss the toast and reset the toast ID reference.
              if (toastIdRef.current !== null) {
                toast.dismiss(toastIdRef.current);
                toastIdRef.current = null;
              }
            }}
          />,
          {
            duration: reconnectingIn,
          }
        );
      } else if (reconnectAttempts <= 2 && toastIdRef.current !== null) {
        // If there have been 2 or fewer reconnect attempts and there is a current toast,
        // dismiss the toast and reset the toast ID reference.
        toast.dismiss(toastIdRef.current);
        toastIdRef.current = null;
      }
    };

    // Set a timeout to create the toast immediately (after 0 milliseconds).
    const timeoutId = setTimeout(createToast, 0);

    // Return a cleanup function that clears the timeout.
    // This function will be called when the component unmounts or before the next time the effect runs.
    return () => clearTimeout(timeoutId);
  }, [reconnectAttempts, reconnectingIn]); // The effect depends on reconnectAttempts and reconnectingIn. It runs whenever any of these values change.
};
