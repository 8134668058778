import React from 'react';

import { CircleIcon } from '@/shared/components/Icons';
import { Tag } from '@/shared/types/tags';
import { Box, Checkbox, Flex, HStack } from '@/shared/ui';
import { styled } from '@/stitches.config';

type ContactTagListItemProps = {
  /** tag object */
  tag: Tag;
  /** selected tag ids */
  selectedTags: Array<string>;
  /** array of searched tag objects */
  searchedTags: Array<Tag>;
  /** array of tag objects */
  tagsList: Array<Tag>;
  /** is the tags component searched? */
  isSearched: boolean;
  /** execute when a tag is clicked */
  onTagClick?: (id: string) => void;
  /** is the tag component with dot */
  noDot?: boolean;
};

export const ContactTagListItem = (props: ContactTagListItemProps) => {
  const { tag, selectedTags, tagsList, searchedTags, isSearched, onTagClick, noDot } =
    props;

  const generateCheckedStatus = (
    isSearched: boolean,
    selectedTags: string[],
    tag: Tag,
    tagsList: Tag[]
  ) => {
    // if the user is using the search bar
    if (isSearched) {
      // if the tag is selected already, or the user typed something in the search bar and then hits "select all"
      return selectedTags.includes(tag.id) || selectedTags.length === searchedTags.length;
    } else {
      // if the tag is selected already, or the user hits "select all"
      return selectedTags.includes(tag.id) || selectedTags.length === tagsList.length;
    }
  };

  return (
    <ListItemContainer
      onClick={() => onTagClick?.(tag.id)}
      data-testid={`tag-item-${tag.id}`}
    >
      <HStack gap={2} css={{ overflow: 'hidden' }}>
        <Checkbox
          checked={generateCheckedStatus(isSearched, selectedTags, tag, tagsList)}
          css={{ minWidth: 15 }}
        />
        {!noDot && <CircleIcon color={tag.color} />}
        <Box>{tag.name}</Box>
      </HStack>
    </ListItemContainer>
  );
};

const ListItemContainer = styled(Flex, {
  fontSize: 13,
  width: '100%',
  cursor: 'pointer',
  minHeight: 52,
  px: '$2',
  py: '$2',
  border: 'none',
  '&:hover': {
    backgroundColor: '$slate2',
  },
});
