import { api } from '@/shared/api/api';
import {
  AutomationTemplate,
  GetAutomationTemplateParams,
} from '@/shared/types/automations';
import { Trigger } from '@/shared/types/triggers';

export const getAutomationTemplates = (
  params: GetAutomationTemplateParams
): Promise<{ data: Array<AutomationTemplate>; total: number }> =>
  api.get('/automation_templates', { params }).then((res) => {
    return res.data as { data: Array<AutomationTemplate>; total: number };
  });

export const getAutomationTemplate = (id: string): Promise<AutomationTemplate> =>
  api.get(`/automation_templates/${id}`).then((res) => {
    return res.data.data as AutomationTemplate;
  });

export const createAutomationTemplate = (
  template: AutomationTemplate
): Promise<AutomationTemplate> =>
  api.post('/automation_templates', { automation_template: template }).then((res) => {
    return res.data.data as AutomationTemplate;
  });

export const updateAutomationTemplate = (
  template: AutomationTemplate
): Promise<AutomationTemplate> =>
  api
    .put(`/automation_templates/${template.id}`, { automation_template: template })
    .then((res) => {
      return res.data.data as AutomationTemplate;
    });

export const deleteAutomationTemplate = (id: string): Promise<AutomationTemplate> =>
  api.delete(`/automation_templates/${id}`);

export const duplicateAutomationTemplate = (id: string): Promise<AutomationTemplate> =>
  api.post(`/automation_templates/${id}/duplicate`).then((res) => {
    return res.data.data as AutomationTemplate;
  });

export const duplicateAutomationTemplateInCampaign = (
  id: string,
  campaignId: string
): Promise<AutomationTemplate> =>
  api
    .post(`/automation_templates/${id}/duplicate_automations_in_campaign`, {
      campaign_id: campaignId,
    })
    .then((res) => {
      return res.data.data as AutomationTemplate;
    });

export const createAutomationTemplateTrigger = (
  automation_id: string,
  trigger: Trigger
): Promise<Trigger> =>
  api
    .post(`/automation_templates/${automation_id}/automation_template_trigger/trigger`, {
      trigger_param: trigger,
    })
    .then((res) => {
      return res.data as Trigger;
    });

export const updateAutomationTemplateTrigger = (
  automation_id: string,
  trigger: Trigger
): Promise<Trigger> =>
  api
    .put(
      `/automation_templates/${automation_id}/automation_template_trigger/trigger/${trigger.id}`,
      {
        trigger_param: trigger,
      }
    )
    .then((res) => {
      return res.data as Trigger;
    });

export const deleteAutomationTemplateTrigger = (
  automation_id: string,
  trigger_id: string
): Promise<Trigger> =>
  api.delete(
    `/automation_templates/${automation_id}/automation_template_trigger/trigger/${trigger_id}`
  );
