/* eslint-disable react-hooks/exhaustive-deps */
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';

import { getMessagesOverview } from '@/shared/api/reports';
import { Chart } from '@/shared/components/reports/Chart';
import { DAY } from '@/shared/components/reports/constants';
import { VStack } from '@/shared/ui';

import { useReport } from '../context/ReportsContext';

export type MessagesOverviewData = {
  /** data to display in chart */
  grouped_result: MessagesOverviewGroupedResult[];

  total_segments_count: number;
  prev_total_segments_count: number;

  total_messages_count: number;
  prev_total_messages_count: number;

  total_avg_segments_per_message: number;
  prev_total_avg_segments_per_message: number;

  total_sms_credits: number;
  prev_total_sms_credits: number;

  total_sms_messages_count: number;
  prev_total_sms_messages_count: number;

  total_avg_sms_segments_per_message: number;
  prev_total_avg_sms_segments_per_message: number;

  total_mms_credits: number;
  prev_total_mms_credits: number;

  total_mms_messages_count: number;
  prev_total_mms_messages_count: number;

  total_avg_mms_segments_per_message: number;
  prev_total_avg_mms_segments_per_message: number;

  total_outbound_credits: number;
  prev_total_outbound_credits: number;

  total_inbound_credits: number;
  prev_total_inbound_credits: number;

  total_outbound_sms_credits: number;
  prev_total_outbound_sms_credits: number;

  total_inbound_sms_credits: number;
  prev_total_inbound_sms_credits: number;

  total_outbound_mms_credits: number;
  prev_total_outbound_mms_credits: number;

  total_inbound_mms_credits: number;
  prev_total_inbound_mms_credits: number;

  /** response key */
  resource: string;
};

export type MessagesOverviewGroupedResult = {
  /** average segments per message */
  avg_segments_per_message: number;
  /** message count */
  messages_count: number;
  /** segments count per period */
  segments_count: number;
  /** period to display -> day, week, month, year */
  period: string;
  /** end of the period */
  period_end: string;
  /** start of the period */
  period_start: string;
};

export const Overview = () => {
  const reports = useReport();
  const { dates, initialState } = reports;

  const [data, setData] = useState<MessagesOverviewData>();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    getData();
  }, [dates.startDate, dates.endDate]);

  const getData = async () => {
    setLoading(true);
    setError(false);
    try {
      const response = await getMessagesOverview(
        dates.startDate && dates.endDate
          ? {
              type: 'overview_messages',
              start_date: dayjs(dates.startDate as string).format('YYYY-MM-DD'),
              end_date: dayjs(dates.endDate as string).format('YYYY-MM-DD'),
              granularity: DAY,
            }
          : { ...initialState, type: 'overview_messages' }
      );
      setData(response);
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const chart_config = [
    {
      dataKey: 'segments_count',
      fill: '#3E54CF',
      name: 'Total Credits',
    },
  ];

  const headers = [
    { label: 'Total Credits', key: 'segments_count' },
    { label: 'Total Messages', key: 'messages_count' },
    { label: 'Avg. Credits per Message', key: 'avg_segments_per_message' },
    { label: 'Period', key: 'period' },
  ];

  // Prepare data for CSV export from grouped result
  const csvData =
    data?.grouped_result.map((item) => ({
      ...item,
      'Total Credits': item.segments_count,
      'Total Messages': item.messages_count,
      'Avg. Credits per Message': item.avg_segments_per_message,
      Period: item.period,
    })) || [];

  return (
    <VStack gap={2}>
      <Chart
        title="Total Credits by Day"
        chartConfig={chart_config}
        data={data || []}
        loading={loading}
        error={error}
        errorCallback={getData}
        headers={headers}
        csvData={csvData}
        filename="total_credits_by_day"
      />
    </VStack>
  );
};
