import { Link } from '@/shared/types/links';

// Reducer action types
export enum LinksActionTypes {
  GET_LINKS = 'GET_LINKS',
  GET_LINK = 'GET_LINK',
  GET_CURRENT_LINK_ANALYTICS = 'GET_CURRENT_LINK_ANALYTICS',
  SET_CURRENT_LINK_ANALYTICS = 'SET_CURRENT_LINK_ANALYTICS',
  CREATE_LINK = 'CREATE_LINK',
  UPDATE_LINK = 'UPDATE_LINK',
  DELETE_LINK = 'DELETE_LINK',
  SET_LOADING = 'SET_LOADING',
  SET_CURRENT = 'SET_CURRENT',
  SET_ERROR = 'SET_ERROR',
}

export type LinksActions =
  | {
      type: LinksActionTypes.GET_LINKS;
      payload: { links: Array<Link> | Array<null>; offset: number; total: number };
    }
  | { type: LinksActionTypes.GET_LINK; payload: Link }
  | { type: LinksActionTypes.UPDATE_LINK; payload: Link }
  | { type: LinksActionTypes.CREATE_LINK; payload: Link }
  | { type: LinksActionTypes.SET_CURRENT; payload: Link | null }
  | { type: LinksActionTypes.GET_CURRENT_LINK_ANALYTICS; payload: any | null }
  | { type: LinksActionTypes.SET_CURRENT_LINK_ANALYTICS; payload: any | null }
  | { type: LinksActionTypes.DELETE_LINK; payload: string }
  | { type: LinksActionTypes.SET_ERROR; payload: string | null }
  | { type: LinksActionTypes.SET_LOADING; payload: boolean };

export enum AnalyticsTableType {
  DEVICE = 'DEVICE',
  COUNTRY = 'COUNTRY',
  CITY = 'CITY',
}
