export const weekDays = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
];

// Initial work schedules for a new opening hours
export const initialWorkSchedules = [
  {
    closes_at: '23:59',
    opens_at: '00:00',
    weekday: 'Monday',
    state: 'closed',
  },
  {
    closes_at: '23:59',
    opens_at: '00:00',
    weekday: 'Tuesday',
    state: 'closed',
  },
  {
    closes_at: '23:59',
    opens_at: '00:00',
    weekday: 'Wednesday',
    state: 'closed',
  },
  {
    closes_at: '23:59',
    opens_at: '00:00',
    weekday: 'Thursday',
    state: 'closed',
  },
  {
    closes_at: '23:59',
    opens_at: '00:00',
    weekday: 'Friday',
    state: 'closed',
  },
  {
    closes_at: '23:59',
    opens_at: '00:00',
    weekday: 'Saturday',
    state: 'closed',
  },
  {
    closes_at: '23:59',
    opens_at: '00:00',
    weekday: 'Sunday',
    state: 'closed',
  },
];
