import { FormikValues, useFormikContext } from 'formik';
import React, { useState } from 'react';

import { Box, Flex, Slider, SliderRange, SliderThumb, SliderTrack } from '@/shared/ui';

import { SliderInputProps } from './types';

/** A slider input that can be used in conjunction with Formik, must be placed within a FormFieldWrapper */
export function SliderInput(props: SliderInputProps): JSX.Element {
  const formik = useFormikContext<FormikValues>();
  const [value, setValue] = useState(formik.values[props.name as string]);

  const handleValueChange = (value: number[]) => {
    formik.setFieldValue(props.name as string, value);
    setValue(value);
  };

  return (
    <Flex align="center" css={{ margin: '10px 0' }}>
      <Slider
        defaultValue={[formik.values[props.name as string]] as number[]}
        min={0.1}
        max={1}
        step={0.01}
        aria-label={props.ariaLabel}
        onValueChange={(value: number[]) => handleValueChange(value)}
      >
        <SliderTrack>
          <SliderRange />
        </SliderTrack>
        <SliderThumb />
      </Slider>
      <Box css={{ marginLeft: 10 }}>{value}</Box>
    </Flex>
  );
}
