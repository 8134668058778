/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-empty-function */
import dayjs from 'dayjs';
import { useFlags } from 'launchdarkly-react-client-sdk';
import {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { useConversation } from '@/pages/inbox/context/ConversationContext';
import { createChatCompletion } from '@/shared/api/ai';
import {
  ConversationItemSourceTypes,
  ConversationItemType,
  ConversationMessageType,
} from '@/shared/types/conversations';

import { ONE_MINUTE, SuggestedItem } from '../utils';

type MessageHistoryItem = {
  direction: 'inbound' | 'outbound';
  type: 'message' | 'attachment';
  message: string;
  time: string;
};

type AIContextType = {
  isPromptLoading: boolean;
  setIsPromptLoading: Dispatch<SetStateAction<boolean>>;
  promptMessage: string;
  setPromptMessage: Dispatch<SetStateAction<string>>;
  messageHistory: MessageHistoryItem[];
  setMessageHistory: Dispatch<SetStateAction<MessageHistoryItem[]>>;
  completionEnabled: boolean;
  setCompletionEnabled: Dispatch<SetStateAction<boolean>>;
  getThreeCompletionOptions: (
    userPrompt: string,
    suggestionsToGenerate?: number
  ) => Promise<void>;
  threeCompletionOptions: SuggestedItem[];
};

const defaultContext: AIContextType = {
  isPromptLoading: false,
  setIsPromptLoading: () => {},
  promptMessage: '',
  setPromptMessage: () => {},
  messageHistory: [],
  setMessageHistory: () => {},
  completionEnabled: false,
  setCompletionEnabled: () => {},
  getThreeCompletionOptions: () => Promise.resolve(),
  threeCompletionOptions: [],
};

type AutocompleteConfiguration = {
  variant: string;
  model: string;
  frequency_penalty: number;
  max_tokens: number;
  n: number;
  presence_penalty: number;
  temperature: number;
  top_p: number;
};

export const AIContext = createContext(defaultContext);

export const useAIContext = () => useContext(AIContext);

export const AIProvider = ({ children }: { children: React.ReactNode }) => {
  const [isPromptLoading, setIsPromptLoading] = useState(false);
  const [promptMessage, setPromptMessage] = useState('');
  const [messageHistory, setMessageHistory] = useState<MessageHistoryItem[]>([]);
  const [completionEnabled, setCompletionEnabled] = useState(true);
  const { autocompleteConfiguration, aiSentenceGenerator } = useFlags();
  const [threeCompletionOptions, setThreeCompletionOptions] = useState<SuggestedItem[]>(
    []
  );

  // Used to track when the conversation changes
  const [previousConversationId, setPreviousConversationId] = useState<string | null>();

  const conversations = useConversation();
  const { current } = conversations.conversationState;

  useEffect(() => {
    // When the conversation items change, update the conversation context
    // This is passed into the autocomplete feature for context
    if (!current) return;

    // Reset the prompt message when the conversation changes
    if (current.id != previousConversationId) {
      setPromptMessage('');
      setPreviousConversationId(current.id);
    }

    // Create an array of MessageHistoryItem objects by filtering out any items that are not messages
    const conversationItems = current?.conversationItemsPage.conversationItems
      ? ([...(current?.conversationItemsPage?.conversationItems || [])].filter(
          (item: ConversationItemType) => 'body' in item
        ) as ConversationMessageType[])
      : ([] as ConversationMessageType[]);

    const newMessageHistory: MessageHistoryItem[] = conversationItems
      .filter(
        (item: ConversationMessageType) =>
          item?.source_type !== ConversationItemSourceTypes.NOTE
      )
      .reverse()
      .map((item: ConversationMessageType) => {
        // Get the direction of the message
        const direction =
          item?.source_type === ConversationItemSourceTypes.OUTBOUND
            ? 'outbound'
            : 'inbound';
        // Check if the message has an attachment
        const hasAttachment = item.attachments && item?.attachments?.length > 0;
        // Get the message body and remove any new lines from it
        let message = item?.body?.replace(/\n/g, '') || '';
        if (hasAttachment) {
          message += ` [Attachment ${direction === 'outbound' ? 'Sent' : 'Received'}]`;
        }
        // Format the time
        const time = dayjs(item?.inserted_at).format('MMM D, HH:mm');

        return {
          direction,
          type: 'message',
          message,
          time,
        };
      });

    setMessageHistory(newMessageHistory);

    // If last conversation was outbound and recent, do not generate new AI completion
    const lastConversation = conversationItems[0] || {};
    const lastConversationInsertedAt = lastConversation?.inserted_at || '';
    if (!lastConversation || !lastConversationInsertedAt) return;
    const sentAt = new Date(lastConversationInsertedAt);
    const sentLessThanTenMinutes = new Date().getTime() - sentAt.getTime() > ONE_MINUTE;
    if (
      lastConversation.source_type == ConversationItemSourceTypes.OUTBOUND &&
      sentLessThanTenMinutes
    )
      return setPromptMessage('');
  }, [current?.id, current?.conversationItemsPage.conversationItems]);

  // Generates AI config based on Feature Flag autocompleteConfiguration
  const generateConfig = (): AutocompleteConfiguration => {
    const defaultConfig: AutocompleteConfiguration = {
      variant: 'DEFAULT',
      model: 'gpt-3.5-turbo',
      frequency_penalty: 1,
      max_tokens: 50,
      n: 1,
      presence_penalty: 0,
      temperature: 0.7,
      top_p: 0.5,
    };

    if (!autocompleteConfiguration) return defaultConfig;

    return {
      ...defaultConfig,
      ...autocompleteConfiguration,
    };
  };

  const getPrompt = () => {
    const dateAndTime = new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'short',
      day: '2-digit',
      hour12: false,
      hour: 'numeric',
      minute: 'numeric',
      timeZoneName: 'long',
    })
      .formatToParts(new Date())
      .map(({ type, value }) => {
        switch (type) {
          case 'fractionalSecond':
            return ''; // Remove milliseconds
          default:
            return value;
        }
      })
      .join('');
    const day = dayjs(new Date()).format('dddd');

    return `You are an SMS message writing assistant for a support agent. Today is ${day}, date and time: ${dateAndTime}, use time and message history as context.
    Your suggestions should be short and action based. Full sentences only, minimum 30 words, maximum 60 words.
    You are writing a message to a contact. You can use {{first_name}} to include the contacts name in the response if you need to.`;
  };

  // Function to get three completion options for the AI
  // It makes three (by default) separate requests to the API
  const getThreeCompletionOptions: (
    userPrompt: string,
    suggestionsToGenerate?: number
  ) => Promise<void> = useMemo(
    () =>
      async (userPrompt: string, suggestionsToGenerate = 3) => {
        // Check if AI Sentence Generator is enabled
        if (!aiSentenceGenerator) return;

        // Set loading state to true
        setIsPromptLoading(true);
        // Generate the prompt for the AI
        const prompt = getPrompt();
        // Configuration for the AI model
        const config = {
          ...generateConfig(),
          model: 'gpt-3.5-turbo', // Override with GPT3.5 turbo
        };

        // Filter out any messages that are empty and sort them by date
        const orderedMessages = messageHistory
          .filter((msg) => msg.message != '')
          .sort((a, b) => new Date(a.time).getTime() - new Date(b.time).getTime())
          .reverse();

        // Get the last ten messages
        const lastTenMessages = orderedMessages.slice(-10);

        // Array to store the requests - used to track loading state
        const requests = [];

        // Reset the completion options
        setThreeCompletionOptions([]);

        // Loop to generate three (by default) completion options
        for (let i = 0; i < suggestionsToGenerate; i++) {
          // Fetch request to the API
          const request = createChatCompletion({
            model: config.model,
            options: {
              max_tokens: 400,
              frequency_penalty: config.frequency_penalty,
              presence_penalty: config.presence_penalty,
              temperature: parseFloat((Math.random() * (2.0 - 0.5) + 0.5).toFixed(1)), // vary temperature randomly between 0.5 and 1.5, rounded to 1 decimal place
              top_p: config.top_p,
              n: config.n,
            },
            messages: [
              {
                role: 'system',
                content: `${prompt} Random Seed: ${Math.random()
                  .toString(36)
                  .slice(2)}${Math.random().toString(36).slice(2)}.`,
              },
              ...lastTenMessages.map(({ message, direction, time }) => {
                return direction == 'inbound'
                  ? {
                      role: 'user',
                      content: `[received at ${time}] ${message}`,
                    }
                  : {
                      role: 'assistant',
                      content: `[sent at ${time}] ${message}`,
                    };
              }),
              // If there is a user prompt, add it to the messages
              ...(userPrompt
                ? [
                    {
                      role: 'system',
                      content: `IMPORTANT: You must tell them that ${userPrompt}`,
                    },
                  ]
                : []),
            ],
          }).then(async (response) => {
            // Parse the response
            const data = await response.response;

            // Remove timestamp from aiResponse if present
            const aiResponse: string = data?.replace(/\[.*?\]/, '').trim();

            if (!aiResponse) return;

            // Create a suggested item
            const suggestedItem: SuggestedItem = {
              id: `${i}-${aiResponse}`,
              message: aiResponse,
            };

            // Add the suggested item to the completion options
            setThreeCompletionOptions((prevOptions) => [...prevOptions, suggestedItem]);
          });

          // Add the request to the requests array
          requests.push(request);
        }

        // Manage loading state: Wait for all requests to complete
        await Promise.all(requests).then(() => {
          // Set loading state to false
          setIsPromptLoading(false);
        });
      },
    [messageHistory]
  );

  return (
    <AIContext.Provider
      value={{
        isPromptLoading,
        setIsPromptLoading,
        promptMessage,
        setPromptMessage,
        messageHistory,
        setMessageHistory,
        completionEnabled,
        setCompletionEnabled,
        getThreeCompletionOptions,
        threeCompletionOptions,
      }}
    >
      {children}
    </AIContext.Provider>
  );
};
