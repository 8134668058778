export const default_application_object = {
  id: 'unique-id-for-applications',
  label: 'Applications',
  key: 'application',
  inserted_at: 'current-timestamp',
  updated_at: 'current-timestamp',
  custom_properties: [
    {
      default: '',
      id: 'application-created-at',
      label: 'Created At',
      type: 'date',
      key: 'inserted_at',
      required: true,
      inserted_at: 'current-timestamp',
      updated_at: 'current-timestamp',
      custom_object_id: 'unique-id-for-applications',
    },
    {
      default: '',
      id: 'application-updated-at',
      label: 'Updated At',
      type: 'date',
      key: 'updated_at',
      required: true,
      inserted_at: 'current-timestamp',
      updated_at: 'current-timestamp',
      custom_object_id: 'unique-id-for-applications',
    },
    {
      default: '',
      id: 'application-active',
      label: 'Active',
      type: 'boolean',
      key: 'active',
      required: true,
      inserted_at: 'current-timestamp',
      updated_at: 'current-timestamp',
      custom_object_id: 'unique-id-for-applications',
    },
  ],
};
