import React, { useEffect } from 'react';
import { createContext, Dispatch, useContext, useReducer } from 'react';
import { toast } from 'sonner';

import { prepareFilters } from '@/pages/data/utils/prepareFilters';
import * as TagsAPI from '@/shared/api/tags';
import { SearchFilters } from '@/shared/types/contacts';
import { Tag, TagActions, TagActionType } from '@/shared/types/tags';

import TagsReducer from './TagsReducer';

export type TagsState = {
  /* all tags in state */
  allTags: Array<Tag>;
  tags: Array<Tag>;
  /* total count of tags */
  totalCount: number;
  /* loading state */
  loading: boolean;
  /* current tag */
  current: Tag | null | undefined;
  filterParams: SearchFilters;
};

export const ITEMS_COUNT = 10;

export const initialTagsState: TagsState = {
  allTags: [],
  tags: [],
  totalCount: 0,
  current: null,
  loading: true,
  filterParams: {
    offset: 0,
    limit: ITEMS_COUNT,
    sort: [
      {
        label: 'Updated At',
        column: 'updated_at',
        order: 'desc',
        resource: 'tag',
        id: null,
      },
    ],
    filter: [],
    searchFilter: [],
  },
};

/** Tags Context Refactoring */
export const TagsContext = createContext<{
  tagsState: TagsState;
  getTag: (id: string) => Promise<Tag>;
  getTags: () => void;
  searchTags: (name: string) => void;
  createTag: (tagName: string, tagColor: string, tagType: string) => Promise<void | Tag>;
  editTag: (tag: Tag) => void;
  deleteTag: (tag: Tag) => void;
  setCurrentTag: (tag: Tag | null | undefined) => void;
  getTagsV2: (params: SearchFilters) => Promise<void>;
  updateFilterParams: (params: SearchFilters) => void;
}>({
  tagsState: initialTagsState,
  getTag: () => Promise.resolve({} as Tag),
  getTags: () => Promise.resolve(),
  searchTags: () => Promise.resolve(),
  createTag: () => Promise.resolve(),
  editTag: () => Promise.resolve(),
  deleteTag: () => Promise.resolve(),
  setCurrentTag: () => Promise.resolve(),
  getTagsV2: () => Promise.resolve(),
  updateFilterParams: () => Promise.resolve(),
});

export const useTags = () => useContext(TagsContext);

const TagState = ({ children }: { children: React.ReactNode }) => {
  const [tagsState, dispatch]: [TagsState, Dispatch<TagActions>] = useReducer(
    TagsReducer,
    initialTagsState
  );

  useEffect(() => {
    getTags();
  }, []);

  useEffect(() => {
    getTagsV2(tagsState.filterParams);
  }, [tagsState.filterParams]);

  const getTag = async (id: string) => {
    try {
      const data = await TagsAPI.getTag(id);

      dispatch({
        type: TagActionType.GET_ALL_TAGS,
        payload: [data],
      });

      return data;
    } catch (err) {
      console.error(err);
    }
  };

  const getTags = async () => {
    try {
      const data = await TagsAPI.getTags();
      dispatch({
        type: TagActionType.GET_ALL_TAGS,
        payload: data,
      });
    } catch (err) {
      console.error(err);
    }
  };

  const getTagsV2 = async (params: SearchFilters) => {
    try {
      const data = await TagsAPI.getTagsV2(prepareFilters(params));

      dispatch({
        type: TagActionType.GET_TAGS,
        payload: data,
      });
    } catch (err) {
      console.error(err);
    }
  };

  const updateFilterParams = async (params: SearchFilters) => {
    try {
      dispatch({
        type: TagActionType.UPDATE_FILTER_PARAMS,
        payload: params,
      });
    } catch (err) {
      console.error(err);
    }
  };

  const searchTags = async (name: string) => {
    try {
      const data = await TagsAPI.searchTag(name);

      dispatch({
        type: TagActionType.SEARCH_TAG,
        payload: data,
      });
      return data;
    } catch (err) {
      console.error(err);
    }
  };

  const createTag = async (tagName: string, tagColor: string, tagType: string) => {
    try {
      const data = await TagsAPI.createTag(tagName, tagColor, tagType);

      dispatch({
        type: TagActionType.CREATE_TAG,
        payload: data,
      });
      if (tagsState.tags.length) {
        getTagsV2({ ...tagsState.filterParams });
      }
      toast.success('Tag created');
      return data;
    } catch (err) {
      toast.error('Tag too long');
      console.error(err);
    }
  };

  const deleteTag = async (tag: Tag) => {
    try {
      await TagsAPI.deleteTag(tag);

      dispatch({
        type: TagActionType.DELETE_TAG,
        payload: tag,
      });
      getTagsV2({ ...tagsState.filterParams });
      toast.success('Tag deleted');
    } catch (err) {
      toast.error('Tag delete failed');
      console.error(err);
    }
  };

  const editTag = async (tag: Tag) => {
    try {
      await TagsAPI.editTag(tag);

      dispatch({
        type: TagActionType.EDIT_TAG,
        payload: tag,
      });
      getTagsV2({ ...tagsState.filterParams });
      toast.success('Tag updated');
    } catch (err) {
      toast.error('Tag update failed');

      console.error(err);
    }
  };

  const setCurrentTag = (tag: Tag | null | undefined) => {
    try {
      dispatch({
        type: TagActionType.SET_CURRENT,
        payload: tag,
      });
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <TagsContext.Provider
      value={{
        tagsState,
        getTag,
        getTags,
        searchTags,
        createTag,
        editTag,
        deleteTag,
        setCurrentTag,
        getTagsV2,
        updateFilterParams,
      }}
    >
      {children}
    </TagsContext.Provider>
  );
};

export default TagState;
