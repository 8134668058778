import React from 'react';
import { HiTrash } from 'react-icons/hi';

import { useDisclosure } from '@/shared/hooks';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogOverlay,
  AlertDialogPortal,
  AlertDialogTitle,
  AlertDialogTrigger,
  Button,
  Flex,
  IconButton,
  Tooltip,
  TooltipArrow,
  TooltipContent,
  TooltipTrigger,
} from '@/shared/ui';

type DraftMessageAlertDialogProps = {
  alertAction: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

export function DeleteDraftMessage(props: DraftMessageAlertDialogProps): JSX.Element {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleClose = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    props.alertAction(event);
    onClose();
  };

  return (
    <AlertDialog open={isOpen}>
      <Tooltip>
        <TooltipTrigger asChild>
          <AlertDialogTrigger asChild>
            <IconButton size={2} onClick={() => onOpen()} variant="outline">
              <HiTrash />
            </IconButton>
          </AlertDialogTrigger>
        </TooltipTrigger>
        <TooltipContent>
          Delete Draft Message
          <TooltipArrow />
        </TooltipContent>
      </Tooltip>
      <AlertDialogPortal>
        <AlertDialogOverlay />
        <AlertDialogContent onEscapeKeyDown={onClose}>
          <AlertDialogTitle>Delete Draft Message</AlertDialogTitle>
          <AlertDialogDescription>
            Are you sure you want to delete your draft message?
          </AlertDialogDescription>
          <Flex justify="end">
            <AlertDialogCancel asChild>
              <Button variant="gray" css={{ mr: '10px' }} onClick={onClose}>
                Cancel
              </Button>
            </AlertDialogCancel>
            <AlertDialogAction asChild>
              <Button
                variant="red"
                onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
                  handleClose(event)
                }
              >
                Yes, Delete Message
              </Button>
            </AlertDialogAction>
          </Flex>
        </AlertDialogContent>
      </AlertDialogPortal>
    </AlertDialog>
  );
}
