import React from 'react';
import { createContext, useContext, useReducer } from 'react';
import { toast } from 'sonner';

import { getGoogleConsent, getGoogleReviews, replyToReview } from '@/shared/api/reviews';
import { Review } from '@/shared/types';
import i18next from '@/shared/utils/translation';

import ReviewReducer from './ReviewReducer';
import {
  CLEAR_REVIEW_RESPONSE,
  GET_CONSENT_URL,
  GET_REVIEWS,
  SET_FILTER,
  SET_LOADING,
  SET_REVIEW_COMMENT,
} from './types';

export type CreateResponse = {
  reviewId: string;
  message: string;
  google_location_id: string;
};

export type ReviewsState = {
  reviews: Array<Review>;
  consentUrl: string;
  loading: boolean;
  error: any;
  filter: string;
};

export const initialReviewsState: ReviewsState = {
  reviews: [],
  consentUrl: '',
  error: null,
  loading: true,
  filter: 'all',
};

export const ReviewContext = createContext<{
  reviewState: ReviewsState;
  getReviews: (locationIds: Array<string>) => void;
  createResponse: (params: CreateResponse) => void;
  getConsentUrl: () => void;
  setFilter: (filter: string) => void;
}>({
  reviewState: initialReviewsState,
  getReviews: () => Promise.resolve(),
  createResponse: () => Promise.resolve(),
  getConsentUrl: () => Promise.resolve(),
  setFilter: () => Promise.resolve(),
});

export const useReviews = () => useContext(ReviewContext);

const ReviewState = ({ children }: { children: React.ReactNode }) => {
  const [reviewState, dispatch] = useReducer(ReviewReducer, initialReviewsState);

  const getReviews = async (locationIds: Array<string>) => {
    try {
      const data = await getGoogleReviews(locationIds);

      const reviews = data.reduce((reviews: any, reviewObject: any) => {
        return [...reviews, ...reviewObject.reviews];
      }, []);

      const sortedReviews = reviews.sort(
        (a: { createTime: string }, b: { createTime: string }) =>
          b.createTime.localeCompare(a.createTime)
      );

      dispatch({
        type: GET_REVIEWS,
        payload: sortedReviews,
      });
    } catch (err) {
      dispatch({
        type: SET_LOADING,
        payload: false,
      });

      console.error(err);
    }
  };

  const getConsentUrl = async () => {
    try {
      const data = await getGoogleConsent();

      dispatch({
        type: GET_CONSENT_URL,
        payload: data,
      });
    } catch (err) {
      console.error(err);
    }
  };

  const createResponse = async (params: CreateResponse) => {
    const { reviewId, google_location_id, message } = params;

    try {
      dispatch({
        type: SET_REVIEW_COMMENT,
        payload: {
          reviewId: reviewId,
          reviewReply: { comment: message, updateTime: new Date().toString() },
        },
      });

      await replyToReview({ reviewId, message, google_location_id });

      toast.success(i18next.t('review_response_success') as string);
    } catch (err) {
      dispatch({
        type: CLEAR_REVIEW_RESPONSE,
        payload: reviewId,
      });

      toast.error(i18next.t('review_response_failure') as string);

      console.error(err);
    }
  };

  const setFilter = (filter: string) => {
    dispatch({
      type: SET_FILTER,
      payload: filter,
    });
  };

  return (
    <ReviewContext.Provider
      value={{
        reviewState,
        getReviews,
        createResponse,
        getConsentUrl,
        setFilter,
      }}
    >
      {children}
    </ReviewContext.Provider>
  );
};

export default ReviewState;
