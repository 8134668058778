/* eslint-disable prettier/prettier */
/* eslint-disable react-hooks/exhaustive-deps */
import { debounce } from 'lodash';
import { useCallback, useState } from 'react';

import { MultiSelect } from '@/shared/components/MultiSelect';
import { QueryItem } from '@/shared/types/integrations';
import { Box, Flex, Input } from '@/shared/ui';

import { useIntegrations } from './context/IntegrationsContext';

type IntegrationFilters = {
  /* array of location ids */
  categories: string[];
  /* array of user ids */
  users: number[];
};

type QueryState = { title: string; filters: IntegrationFilters };

export const IntegrationsFilter = () => {
  const categories = [
    {
      value: 'staffing',
      type: 'Staffing',
    },
    {
      value: 'marketing',
      type: 'Marketing',
    },
    {
      value: 'sales',
      type: 'Sales',
    },
    {
      value: 'crm',
      type: 'CRM',
    },
    {
      value: 'ats',
      type: 'ATS',
    },
  ];

  const integrationContext = useIntegrations();
  const { filterIntegrations } = integrationContext;
  const [query, setQuery] = useState<QueryState>({
    title: '',
    filters: { categories: [], users: [] },
  });

  const filterIntegrationsDebounced = useCallback(
    debounce((query: QueryState) => {
      const params = {
        // note that we are technically filtering off users
        filter: handle_filters_query(query.title, query.filters.users),
      };
      filterIntegrations(params);
    }, 500), // 500ms debounce time
    []
  );

  return (
    <Flex align="center" css={{ padding: 0, minWidth: '100%', pb: 30 }} justify="between">
      <Box>
        <Input
          value={query.title}
          placeholder="Search Integrations"
          onChange={(e) => {
            const newQuery = { ...query, title: e.target.value };
            setQuery(newQuery);
            filterIntegrationsDebounced(newQuery);
          }}
          css={{ width: '409px' }}
        />
      </Box>
      <Box css={{ minWidth: 409 }}>
        <MultiSelect
          isDropdown={true}
          defaultPlaceholder="Categories"
          defaultSelectedItems={[]}
          setParentSelectedItems={(filters: IntegrationFilters) => {
            const newQuery = { ...query, filters };
            setQuery(newQuery);
            filterIntegrationsDebounced(newQuery);
          }}
          options={categories.map((category) => ({
            type: category.type,
            value: category.value,
          }))}
        />
      </Box>
    </Flex>
  );
};

/*
 * This function will either build off the search phrase the user entered or based off the
 * categories selected. If the user entered no input but has selected one or more categories,
 * then only filter based off categories.
 * However, if the user entered a search name and some categories then we will search based off the
 * search name and to use OR logic on the selected categories.
 */
function handle_filters_query(searchQuery: string, categories: any[]): QueryItem[] {
  const searchFilter = {
    column: 'name',
    comparison: 'ilike',
    resource: 'application',
    value: '%' + searchQuery + '%',
  };

  if (categories.length == 0) {
    return [searchFilter];
  }

  const orFilter: QueryItem[] = categories.slice(1).map((category) => {
    return {
      column: 'categories',
      comparison: 'contains',
      resource: 'application',
      value: [category],
    };
  });

  let categoriesQuery: QueryItem = {
    column: 'categories',
    comparison: 'contains',
    resource: 'application',
    value: [categories[0]],
  };

  if (orFilter.length > 0) {
    categoriesQuery = {
      ...categoriesQuery,
      or: orFilter,
    };
  }

  if (searchQuery) {
    return [searchFilter, categoriesQuery];
  } else {
    // If the search query is empty then only filter based off categories.
    // Treat all the categories as conditional ors
    return [categoriesQuery];
  }
}
