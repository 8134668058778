import React from 'react';
import { HiAdjustments } from 'react-icons/hi';

import { Tag } from '@/shared/types/tags';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  HStack,
} from '@/shared/ui';
import { styled } from '@/stitches.config';

import { AddUploadToAudience } from '../quick/paste/AddUploadToAudience';

type AudienceFilterDropdownProps = {
  /** show what are we searching for in audience contacts/tags/groups/uploads  */
  audienceSearch: string;
  /** sets what are we searching for in audience contacts/tags/groups/uploads */
  setAudienceSearch: React.Dispatch<React.SetStateAction<string>>;
  /** if this is true show the contacts search option */
  showContacts: boolean;
  /** true if we are on the campaigns page */
  isCampaignsPage: boolean;
  /** true if we are on the contacts page */
  isContactsPage: boolean;
  /** true if we are on the groups page */
  isGroupsPage: boolean;
  /** true if we are on the uploads page */
  isUploadsPage: boolean;
  /** true if we are on the inbox page */
  isInbox: boolean;
  /** adds the pasted upload to audience */
  addPastedDataToAudience: (upload: Tag) => void;
  /** sets the quick dialog state to true/false */
  setQuickCampaignDialogState: any;
};

export const AudienceFilterDropdown = (props: AudienceFilterDropdownProps) => {
  const { audienceSearch, setAudienceSearch, showContacts, isContactsPage } = props;

  if (isContactsPage && !showContacts) {
    return null;
  }

  return (
    <HStack gap={3} align="center" css={{ mr: 10 }}>
      <AddUploadToAudience
        addPastedDataToAudience={props.addPastedDataToAudience}
        setQuickCampaignDialogState={props.setQuickCampaignDialogState}
      />
      <DropdownMenu>
        <DropdownMenuTrigger data-testid="audience-filter-menu">
          <HiAdjustments />
        </DropdownMenuTrigger>
        <DropdownMenuContent sideOffset={2}>
          <StyledDropdownMenuItem
            selected={audienceSearch === 'contacts'}
            onClick={() => setAudienceSearch('contacts')}
          >
            <span>Contacts</span>
          </StyledDropdownMenuItem>
          <StyledDropdownMenuItem
            selected={audienceSearch === 'uploads'}
            onClick={() => setAudienceSearch('uploads')}
          >
            <span>Uploads</span>
          </StyledDropdownMenuItem>
          <StyledDropdownMenuItem
            selected={audienceSearch === 'tags'}
            onClick={() => setAudienceSearch('tags')}
          >
            <span>Tags</span>
          </StyledDropdownMenuItem>
          <StyledDropdownMenuItem
            selected={audienceSearch === 'groups'}
            onClick={() => setAudienceSearch('groups')}
          >
            <span>Groups</span>
          </StyledDropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </HStack>
  );
};

const StyledDropdownMenuItem = styled(DropdownMenuItem, {
  backgroundColor: '#FFFFFF',
  variants: {
    selected: {
      true: {
        backgroundColor: '$slate4',
      },
    },
  },
});
