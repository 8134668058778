import React from 'react';
import { useParams } from 'react-router-dom';

import { Flex, Text } from '@/shared/ui';

export const AgentVersion = () => {
  const { id, version_id } = useParams<{ id: string; version_id: string }>();

  console.log(id, version_id);
  return (
    <Flex direction="column" css={{ height: '100%', overflow: 'hidden', p: 30 }}>
      <Text>Coming soon</Text>
    </Flex>
  );
};
