/* eslint-disable react-hooks/exhaustive-deps */
import * as TabsPrimitive from '@radix-ui/react-tabs';
import React, { useCallback, useState } from 'react';

import {
  USER_WEB_NOTIFICATIONS_LIMIT,
  useUserWebNotifications,
} from '@/shared/components/notifications/context/UserWebNotificationsContext';
import {
  USER_WEB_NOTIFICATIONS_FILTERS,
  UserWebNotification,
} from '@/shared/types/notifications';
import {
  NotificationsStatus,
  UserWebNotificationActionTypes,
} from '@/shared/types/notifications';
import { Drawer, DrawerContent, DrawerOverlay, DrawerPortal, Flex } from '@/shared/ui';
import { styled } from '@/stitches.config';

import NotificationsBulkActions from './NotificationsBulkActions';
import NotificationsDrawerHeader from './NotificationsDrawerHeader';
import NotificationsList from './NotificationsList';

export const NotificationsDrawer = () => {
  const {
    userWebNotificationsState,
    isNotificationsDrawerOpen,
    closeNotificationsDrawer,
    getUserWebNotifications,
  } = useUserWebNotifications();
  const { notifications, archivedNotifications } = userWebNotificationsState;

  const [selectedTab, setSelectedTab] = useState<string>(
    USER_WEB_NOTIFICATIONS_FILTERS.ALL
  );

  const [offset, setOffset] = useState(0);

  const handleTabChange = (value: string) => {
    setSelectedTab(value as string);
    setOffset(0);
    if (value === USER_WEB_NOTIFICATIONS_FILTERS.ALL) {
      getUserWebNotifications(
        UserWebNotificationActionTypes.GET_USER_WEB_NOTIFICATIONS,
        [NotificationsStatus.UNREAD, NotificationsStatus.READ],
        0
      );
    } else if (value === USER_WEB_NOTIFICATIONS_FILTERS.ARCHIVED) {
      getUserWebNotifications(
        UserWebNotificationActionTypes.GET_ARCHIVED_USER_WEB_NOTIFICATIONS,
        [NotificationsStatus.ARCHIVED],
        0
      );
    }
  };

  const endReached = useCallback(() => {
    // when the end of the list is reached, load more notifications (limit 10)
    // we need to check the state for notification or archivedNotifications to determine which action to call
    // if the selected tab is all, we need to call the get user web notifications action
    // if the selected tab is archived, we need to call the get archived user web notifications action
    if (selectedTab === USER_WEB_NOTIFICATIONS_FILTERS.ALL) {
      if (notifications.length < offset + USER_WEB_NOTIFICATIONS_LIMIT) {
        return;
      }
      setOffset(offset + USER_WEB_NOTIFICATIONS_LIMIT);
      getUserWebNotifications(
        UserWebNotificationActionTypes.GET_USER_WEB_NOTIFICATIONS,
        [NotificationsStatus.UNREAD, NotificationsStatus.READ],
        offset + 10
      );
    } else if (selectedTab === USER_WEB_NOTIFICATIONS_FILTERS.ARCHIVED) {
      if (archivedNotifications.length < offset + USER_WEB_NOTIFICATIONS_LIMIT) {
        return;
      }
      setOffset(offset + USER_WEB_NOTIFICATIONS_LIMIT);
      getUserWebNotifications(
        UserWebNotificationActionTypes.GET_ARCHIVED_USER_WEB_NOTIFICATIONS,
        [NotificationsStatus.ARCHIVED],
        offset + USER_WEB_NOTIFICATIONS_LIMIT
      );
    }
  }, [selectedTab, offset, notifications, archivedNotifications]);

  // remove any archived notifications from the notifications array and sort by created_at
  const sortedNotifications = notifications
    .filter(
      (notification: UserWebNotification) =>
        notification.status !== NotificationsStatus.ARCHIVED
    )
    .sort((a, b) => {
      return new Date(b.inserted_at).getTime() - new Date(a.inserted_at).getTime();
    });

  // remove any non-archived notifications from the archivedNotifications array and sort by created_at
  const sortedArchivedNotifications = archivedNotifications
    .filter(
      (notification: UserWebNotification) =>
        notification.status === NotificationsStatus.ARCHIVED
    )
    .sort((a, b) => {
      return new Date(b.inserted_at).getTime() - new Date(a.inserted_at).getTime();
    });

  return (
    <Drawer open={isNotificationsDrawerOpen}>
      <DrawerPortal>
        <DrawerOverlay />
        <DrawerContent
          onEscapeKeyDown={closeNotificationsDrawer}
          onPointerDownOutside={closeNotificationsDrawer}
          css={{ width: '35%', minWidth: '360px' }}
        >
          <NotificationsDrawerHeader />
          <NotificationsDrawerTabs
            defaultValue={USER_WEB_NOTIFICATIONS_FILTERS.ALL}
            value={selectedTab}
            onValueChange={handleTabChange}
          >
            <Flex
              justify="between"
              align="center"
              css={{
                paddingRight: 25,
                borderBottom: '1px solid $gray4',
              }}
            >
              <NotificationsDrawerTabsList>
                <NotificationsDrawerTabsTrigger
                  value={USER_WEB_NOTIFICATIONS_FILTERS.ALL}
                >
                  All
                </NotificationsDrawerTabsTrigger>
                <NotificationsDrawerTabsTrigger
                  value={USER_WEB_NOTIFICATIONS_FILTERS.ARCHIVED}
                >
                  Archived
                </NotificationsDrawerTabsTrigger>
              </NotificationsDrawerTabsList>

              <NotificationsBulkActions />
            </Flex>
            <NotificationsDrawerTabsContent value={USER_WEB_NOTIFICATIONS_FILTERS.ALL}>
              <NotificationsList
                notifications={sortedNotifications}
                onEndReached={endReached}
                emptyMessage="No Notifications"
                isLoading={userWebNotificationsState.loading}
              />
            </NotificationsDrawerTabsContent>
            <NotificationsDrawerTabsContent
              value={USER_WEB_NOTIFICATIONS_FILTERS.ARCHIVED}
            >
              <NotificationsList
                notifications={sortedArchivedNotifications}
                onEndReached={endReached}
                emptyMessage="No Archived Notifications"
                isLoading={userWebNotificationsState.loading}
              />
            </NotificationsDrawerTabsContent>
          </NotificationsDrawerTabs>
        </DrawerContent>
      </DrawerPortal>
    </Drawer>
  );
};

const NotificationsDrawerTabs = styled(TabsPrimitive.Root, {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: '100%',
  borderBottom: '1px solid $gray4',
});

const NotificationsDrawerTabsList = styled(TabsPrimitive.List, {
  display: 'flex',
  height: '48px',
  width: 'fit-content',
  mb: -1,
});

const NotificationsDrawerTabsTrigger = styled(TabsPrimitive.Trigger, {
  flex: 1,
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  p: '0 25px',
  fontSize: '14px',
  fontWeight: 600,
  zIndex: 2,
  borderBottom: '2px solid transparent',
  '&:focus': { outline: 'none' },
  '&[data-state="active"]': {
    borderBottomWidth: '2px',
    borderBottomColor: '$primaryColor',
  },
});

const NotificationsDrawerTabsContent = styled(TabsPrimitive.Content, {
  overflowY: 'auto',
  height: 'calc(100% - 106px)',
});
