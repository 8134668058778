import { useState } from 'react';
import { HiArrowRight } from 'react-icons/hi';
import { useMedia } from 'react-use';

import { Flex } from '@/shared/ui';
import { formatPhoneNumber } from '@/shared/utils/validations/validations';

import { ContactItemContainer, ContactName, ContactPhone } from './utils';

type ContactItemProps = {
  id: string;
  name: string | null;
  phone: string | null;
  loadConversationOnClick: () => void;
};

export const ContactItem = (props: ContactItemProps) => {
  const [isShown, setIsShown] = useState(false);
  const isDesktop = useMedia('(min-width: 912px)');

  return (
    <ContactItemContainer
      key={props.id}
      align="center"
      onClick={props.loadConversationOnClick}
      onMouseEnter={() => setIsShown(true)}
      onMouseLeave={() => setIsShown(false)}
    >
      <Flex
        align={!isDesktop ? 'start' : 'center'}
        css={{
          flexGrow: 1,
        }}
        direction={!isDesktop ? 'column' : 'row'}
      >
        <ContactName>{props.name}</ContactName>
        <ContactPhone css={{ marginLeft: isDesktop ? '8px' : '0px' }}>
          {formatPhoneNumber(props.phone || '')}
        </ContactPhone>
      </Flex>
      {isShown && <HiArrowRight size="20px" />}
    </ContactItemContainer>
  );
};
