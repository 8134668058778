import React from 'react';
import { useEffect, useRef, useState } from 'react';
import { HiChevronDown, HiChevronUp } from 'react-icons/hi';

import { DropdownOption } from '@/shared/types/filter';
import { Box, Flex } from '@/shared/ui';
import { styled } from '@/stitches.config';

type FiltersMenuDropdownProps = {
  /** The list of options that appear in the dropdown menu. */
  options: DropdownOption[];
  /** The currently selected option from the dropdown. */
  selected: DropdownOption | null | undefined;
  /** Callback function when you select an option from the dropdown. */
  onSelect: (option: DropdownOption) => void;
  /** Optional label/placeholder */
  label?: string;
};

/**
 * Dropdown component for the Filters Menu (used throughout the filters feature).
 * Filter Rows can be added and removed from the Filters Menu component.
 * The comparison value can be updated by selecting the relevant option from the dropdown.
 * The row type will dictate whether a text input, datepicker or dropdown is rendered for
 * selecting / updating a filter value.
 *
 * @param options - The list of options that appear in the dropdown menu.
 * @param selected - The current selected value.
 * @param onSelect - A callback function which is called when an option is selected from the dropdown.
 */

const FiltersMenuDropdown: React.FC<FiltersMenuDropdownProps> = ({
  options,
  selected,
  onSelect,
  label,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const dropdownMenuRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const clickOutside = (event: MouseEvent) => {
      if (!dropdownRef.current?.contains(event?.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', clickOutside);

    return () => {
      document.removeEventListener('mousedown', clickOutside);
    };
  }, []);

  useEffect(() => {
    if (isOpen && dropdownMenuRef.current) {
      // Dynamically adjust the width based on content
      const maxWidth = Math.max(
        ...Array.from(dropdownMenuRef.current.children).map((child) => child.scrollWidth),
        dropdownRef.current?.scrollWidth || 0
      );
      dropdownMenuRef.current.style.width = `${maxWidth}px`;
    }
  }, [isOpen, options]);

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <DropdownContainer
        onClick={() => setIsOpen((prev) => !prev)}
        ref={dropdownRef}
        align="center"
        justify="between"
        data-testid="filters-menu-dropdown-container"
      >
        <Flex align="center" justify="between" css={{ minWidth: '100%' }}>
          <DropdownLabel css={{ pr: 6 }}>
            {selected?.label || label || 'Select a value'}
          </DropdownLabel>
          {isOpen ? <HiChevronUp size={18} /> : <HiChevronDown size={18} />}
        </Flex>
        {isOpen && (
          <DropdownMenu data-testid="filters-menu-dropdown-options" ref={dropdownMenuRef}>
            {options.map((option, index) => (
              <DropdownMenuOption key={index} onClick={() => onSelect(option)}>
                {option.label}
              </DropdownMenuOption>
            ))}
          </DropdownMenu>
        )}
      </DropdownContainer>
    </div>
  );
};

export default FiltersMenuDropdown;

const DropdownContainer = styled(Flex, {
  position: 'relative',
  width: '100%',
  minWidth: '100%',
  height: '100%',
  padding: '$space$1 $space$3',
  fontSize: '$text$3',
  cursor: 'pointer',
});

const DropdownLabel = styled(Box, {
  fontSize: '14px',
  minWidth: 'max-content', // Ensure the minimum width is based on content size
  whiteSpace: 'nowrap', // Prevent text from wrapping to ensure it stays in one line
  overflow: 'hidden', // Hide overflowed text
  textOverflow: 'ellipsis', // Show ellipsis for overflowed text
});

const DropdownMenu = styled(Box, {
  position: 'absolute',
  top: '36px',
  left: 0,
  zIndex: 5,
  width: '100%',
  minWidth: 'max-content',
  padding: '$space$2 0',
  backgroundColor: 'white',
  border: '1px solid $slate7',
  borderRadius: '4px',
  whiteSpace: 'nowrap',
});

const DropdownMenuOption = styled(Box, {
  cursor: 'pointer',
  padding: '$space$2 $space$3',
  fontSize: '14px',
  '&:hover': {
    backgroundColor: '$slate2',
  },
});
