import { WorkSchedule } from '@/shared/types/team/schedule';

const order: Record<string, number> = {
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
  Saturday: 6,
  Sunday: 7,
};

// sorting work schedules by week days
export const sortWeekDays = (openingHours: Array<WorkSchedule>) => {
  return openingHours?.sort((a: WorkSchedule, b: WorkSchedule) => {
    return order[a.weekday] - order[b.weekday];
  });
};
