/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const RedirectToMobileApp = () => {
  const { search } = useLocation();

  useEffect(() => {
    window.location.replace(`whippymobile://${search}`);

    const timer = setTimeout(function () {
      window.location.replace('/');
    }, 2000);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return null;
};
