/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';

import { useGroups } from '@/contacts/groups/context/GroupContext';
import { Flex } from '@/shared/ui';

import { GROUPS } from '../AudienceSelection';
import { AudienceTag } from '.';

type GroupsTagsProps = {
  /** the array of group ids from the campaigns audience */
  group_ids: string[];
  /** function to remove the tag from the audience */
  onItemDelete: (type: string, id?: string) => void;
  /** if removing the tag is disabled */
  disabled?: boolean;
};

export const GroupTags = (props: GroupsTagsProps) => {
  const group_ids = props.group_ids;
  const groups = useGroups();
  const { groupsState, getGroup } = groups;
  const { groups: groupsList } = groupsState;

  return (
    <Flex wrap="wrap">
      {group_ids &&
        group_ids?.map((group_id) => {
          return (
            <AudienceTag
              key={group_id}
              id={group_id}
              list={groupsList}
              getObject={getGroup}
              onItemDelete={props.onItemDelete}
              itemType={GROUPS}
              disabled={props.disabled}
            />
          );
        })}
    </Flex>
  );
};
