/* eslint-disable @typescript-eslint/ban-ts-comment */
import { FormikErrors, FormikValues, useFormikContext } from 'formik';
import React from 'react';
import { HiOutlineInformationCircle } from 'react-icons/hi';

import { useDisclosure } from '@/shared/hooks';
import {
  Fieldset,
  Flex,
  Label,
  Text,
  Tooltip,
  TooltipArrow,
  TooltipContent,
  TooltipTrigger,
} from '@/shared/ui';

import { FormFieldWrapperProps } from './types';

/**
  This component wraps the form field passed as a child with a `Fieldset`,
  Label`, and `Tooltip`. It also makes it possible to render whatever Yup error
  messages are declared in Formik's validationSchema
*/

export function CheckboxFormFieldWrapper(props: FormFieldWrapperProps): JSX.Element {
  const formik = useFormikContext<FormikValues>();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const formikTouched: FormikValues = formik.touched;
  const formikErrors: FormikValues = formik.errors;

  return (
    <Fieldset css={{ height: '100%' }}>
      <Flex gap={1} align="center">
        {React.isValidElement(props.children)
          ? React.cloneElement(props.children as React.ReactElement<any>, {
              name: props.name,
            })
          : null}
        {props.label ? (
          <Label css={{ m: 0 }} htmlFor={props.name}>
            {props.label}
          </Label>
        ) : null}
        {props.tooltip ? (
          <Tooltip open={isOpen}>
            <TooltipTrigger onMouseEnter={onOpen} onMouseLeave={onClose} type="button">
              <HiOutlineInformationCircle size="13px" />
            </TooltipTrigger>
            <TooltipContent side="top" sideOffset={10}>
              <TooltipArrow />
              {props.tooltip}
            </TooltipContent>
          </Tooltip>
        ) : null}
        {props.name !== 'message' &&
        formik.touched[props.name] &&
        formik.errors[props.name] ? (
          <Text variant="error">{formik.errors[props.name] as FormikErrors<string>}</Text>
        ) : null}
        {props.name === 'message' &&
        formikTouched?.message?.body &&
        formikErrors?.message?.body ? (
          <Text variant="error">{formikErrors?.message?.body}</Text>
        ) : null}
        {/* Clone child component and provide it with a name prop */}
      </Flex>
    </Fieldset>
  );
}
