/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { HiEye, HiInformationCircle, HiMinus, HiSearch } from 'react-icons/hi';
import { toast } from 'sonner';

import { useAuth } from '@/pages/auth/context/AuthProvider';
import { getAutomationTemplates } from '@/shared/api/automations/templates';
import { ViewAutomationTemplateDialog } from '@/shared/components/triggers/ViewAutomationTemplateDialog';
import { AutomationTemplate, TemplateUsage } from '@/shared/types/automations/index';
import {
  Box,
  Button,
  Checkbox,
  DialogTitle,
  Flex,
  HStack,
  IconButton,
  Input,
  Skeleton,
  Text,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
  VStack,
} from '@/shared/ui';
import { styled } from '@/stitches.config';

import { BackButton } from './AddTrigger';

type SelectAutomationTemplatesProps = {
  /* The action to perform when the save button is clicked */
  onSubmit: (values: Array<AutomationTemplate>) => void;
  /* The existing automation templates */
  existingAllAutomationTemplates: Array<AutomationTemplate>;
  /* The action to perform when the dialog is closed */
  handleClose: () => void;
  onBack: () => void;
};

const LoadingState = () => {
  return (
    <VStack gap="2" css={{ width: '100%', px: 0 }}>
      {Array(7)
        .fill({})
        .map((_, index) => (
          <Skeleton key={index} css={{ width: '100%', height: 20, mt: 10, mb: 10 }} />
        ))}
    </VStack>
  );
};

export const SelectAutomationTemplates = (props: SelectAutomationTemplatesProps) => {
  const { onSubmit, existingAllAutomationTemplates, handleClose, onBack } = props;
  const auth = useAuth();

  const [currentTemplate, setCurrentTemplate] = useState<AutomationTemplate | null>(null);

  // The following state is for the list of all automation templates this user has access to
  const [allAutomationTemplates, setAllAutomationTemplates] = useState<
    AutomationTemplate[]
  >([]);
  const [selectedAutomationTemplates, setSelectedAutomationTemplates] = useState<
    AutomationTemplate[]
  >([]);
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState('');
  // The following Dialog refers to the ViewAutomationTemplateDialog
  const [isDialogVisible, setIsDialogVisible] = useState(false);

  const fetchAllValidAutomationTemplates = async () => {
    try {
      setLoading(true);
      const { data } = await getAutomationTemplates({
        archived: false,
        organization_id: auth?.organizationId, // assure the access level of the user
      });
      setAllAutomationTemplates(data);
    } catch (error) {
      toast.error('failed to fetch automation templates');
    } finally {
      setLoading(false);
    }
  };

  // When mounted, fetch all the automation templates this user has access to
  useEffect(() => {
    fetchAllValidAutomationTemplates();
  }, []);

  const handleInputValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const filteredAutomationTemplates = allAutomationTemplates.filter(
    (template: AutomationTemplate) =>
      template.title?.toLowerCase().includes(inputValue.toLowerCase())
  );

  const handleCheckboxChange = (template: AutomationTemplate) => {
    const prev = selectedAutomationTemplates || [];

    const templateExists = prev.some((t: AutomationTemplate) => t.id === template.id);

    if (templateExists) {
      setSelectedAutomationTemplates(
        prev.filter((t: AutomationTemplate) => t.id !== template.id)
      );
    } else {
      setSelectedAutomationTemplates([...prev, template]);
    }
  };

  const toggleDialog = (template?: AutomationTemplate) => {
    setCurrentTemplate(template || null);
    setIsDialogVisible(true);
  };

  const handleAddTemplates = () => {
    onSubmit(selectedAutomationTemplates);
  };

  const isTemplateExisting = (templateId: string) => {
    if (!existingAllAutomationTemplates) {
      return false;
    }
    return existingAllAutomationTemplates.some((template) => template.id === templateId);
  };

  return (
    <div>
      {isDialogVisible && (
        <ViewAutomationTemplateDialog
          template={currentTemplate}
          setIsDialogVisible={setIsDialogVisible}
          isTemplateSelectionDialog={true}
        />
      )}
      <SelectAutomationTemplateDialogContainer blur={isDialogVisible ? 'true' : 'false'}>
        <DialogTitle variant="bold">
          <>
            <BackButton onClick={() => onBack()} />
            Select Automation Templates
          </>
        </DialogTitle>
        {loading ? (
          <LoadingState />
        ) : (
          <div>
            {/* An intermediate container for borderline control */}
            <div style={{ paddingLeft: 0, paddingRight: 0 }}>
              {/* Container for search input */}
              <SearchInputContainer>
                <Flex align="center" justify="center" css={{ pl: 10 }}>
                  <HiSearch />
                </Flex>
                <SearchInput
                  placeholder="Search Template Name"
                  value={inputValue}
                  onChange={handleInputValueChange}
                />
              </SearchInputContainer>
            </div>

            {inputValue === '' && filteredAutomationTemplates.length === 0 ? (
              <EmptyStateContainer>
                <Text>You do not have any automation template. Please create one.</Text>
              </EmptyStateContainer>
            ) : inputValue !== '' && filteredAutomationTemplates.length === 0 ? (
              <NoFoundStateContainer>
                <Text>No automation template found.</Text>
              </NoFoundStateContainer>
            ) : (
              <ScrollableContainer>
                {filteredAutomationTemplates.map(
                  (template: AutomationTemplate) =>
                    template.triggers &&
                    template.triggers.length > 0 && (
                      <HStack
                        key={template.id}
                        style={{ paddingTop: 2, paddingRight: 2 }}
                      >
                        <TemplateRowContainer
                          key={template.id}
                          onClick={(e) => {
                            handleCheckboxChange(template);
                          }}
                        >
                          <Flex
                            align="center"
                            css={{ justifyContent: 'space-between', width: '100%' }}
                            style={{ width: '100%' }}
                          >
                            <Flex align="center">
                              <Flex align="center" css={{ pr: 10 }}>
                                {template.id && isTemplateExisting(template.id) ? (
                                  <HiMinus />
                                ) : (
                                  <Checkbox
                                    checked={selectedAutomationTemplates?.some(
                                      (t) => t.id === template.id
                                    )}
                                  />
                                )}
                              </Flex>
                              <Flex align="center">{template.title}</Flex>
                              <Flex align="center">
                                <IndicatorText>
                                  {template.usage === 'as-is'
                                    ? 'linked'
                                    : template.usage === 'duplicate'
                                      ? 'duplicate'
                                      : 'N/A'}
                                </IndicatorText>
                              </Flex>
                            </Flex>
                            {/* Preview template button */}
                            <Tooltip>
                              <TooltipTrigger asChild>
                                <IconButton
                                  variant="outline"
                                  size={1}
                                  onClick={(e) => {
                                    toggleDialog(template);
                                  }}
                                >
                                  <HiEye style={{ zIndex: 10 }} />
                                  {/* zIndex is to make sure the icon is on top of the row container */}
                                </IconButton>
                              </TooltipTrigger>
                              <TooltipContent>
                                <ViewAutomationTemplateTooltipContent />
                              </TooltipContent>
                            </Tooltip>
                          </Flex>
                        </TemplateRowContainer>
                      </HStack>
                    )
                )}
              </ScrollableContainer>
            )}
          </div>
        )}
        <Flex justify="end" css={{ pt: 20 }}>
          <HStack>
            <Button variant="gray" onClick={handleClose} style={{ marginRight: 3 }}>
              Close
            </Button>
            <Button variant="primary" onClick={handleAddTemplates}>
              Select
            </Button>
          </HStack>
        </Flex>
      </SelectAutomationTemplateDialogContainer>
    </div>
  );
};

const SelectAutomationTemplateDialogContainer = styled('div', {
  backgroundColor: '$loContrast',
  variants: {
    blur: {
      true: {
        filter: 'blur(1px)', // Increase or decrease for desired blur intensity
      },
      false: {},
    },
  },
});

const ScrollableContainer = styled('div', {
  maxHeight: '300px',
  overflowY: 'auto',
});

const TemplateRowContainer = styled(Flex, {
  width: '100%',
  position: 'relative',
  height: '35px',
  pl: 2,
  fontWeight: 400,
  fontSize: 13,
  alignItems: 'center',
  align: 'center',
  justify: 'between',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: '$slate2',
  },
});

const SearchInputContainer = styled(Flex, {
  position: 'relative',
  height: 38,
  border: '1px solid $gray4',
  borderRadius: '4px',
  marginBottom: '10px', // to have some space between the search input and the table
});

const SearchInput = styled(Input, {
  borderRadius: 1,
  boxShadow: 'none',
  padding: '0px 0px 0px 0px',
  height: '30px',
  '&:focus': {
    backgroundColor: '$loContrast',
    boxShadow: 'none',
  },
});

const IndicatorText = styled('div', {
  color: 'gray',
  position: 'absolute',
  right: '0px',
  top: '50%',
  transform: 'translateY(-50%)',
  pr: 32,
  display: 'flex', // Ensure its contents align horizontally
  alignItems: 'center', // Center align vertically
});

// Styles for Empty state
const EmptyStateContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '20px',
  textAlign: 'center',
  color: '$gray5',
});

// Styles for No Found state
const NoFoundStateContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '20px',
  textAlign: 'center',
  color: '$gray5',
});

const ViewAutomationTemplateTooltipContent = () => {
  return <div>Preview Template</div>;
};
