import * as TabsPrimitive from '@radix-ui/react-tabs';
import { styled } from '@stitches/react';

const StyledTabs = styled(TabsPrimitive.Root, {
  display: 'flex',
  flexDirection: 'column',
});

const StyledList = styled(TabsPrimitive.List, {
  flexShrink: 0,
  display: 'flex',
  width: 'max-content',
  borderBottom: '1px solid #02023414',
  marginBottom: '16px',
});

const StyledTrigger = styled(TabsPrimitive.Trigger, {
  all: 'unset',
  fontFamily: 'inherit',
  backgroundColor: 'transparent',
  height: 40,
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: 14,
  fontWeight: 500,
  lineHeight: 1,
  padding: '0 16px',
  color: '#0007139F',
  userSelect: 'none',
  '&:hover': { cursor: 'pointer' },
  '&[data-state="active"]': {
    color: '#1C2024',
    boxShadow: 'inset 0 -1px 0 0 #3A5CCC, 0 1.3px 0 0 #3A5CCC',
  },
});

const StyledContent = styled(TabsPrimitive.Content, {
  flexGrow: 1,
  borderBottomLeftRadius: 6,
  borderBottomRightRadius: 6,
  outline: 'none',
  display: 'flex',
  flexDirection: 'column',
});

// Exports
export const Tabs = StyledTabs;
export const TabsList = StyledList;
export const TabsTrigger = StyledTrigger;
export const TabsContent = StyledContent;
