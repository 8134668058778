import dayjs from 'dayjs';
import { FormikValues, useFormikContext } from 'formik';
import { get } from 'lodash';
import { useMemo, useState } from 'react';
import { DatePicker } from 'react-dayjs-picker';
import { HiCalendar, HiX } from 'react-icons/hi';

import { TextInputProps } from '@/shared/components/forms/types';
import { Box, IconButton, Tooltip, TooltipContent, TooltipTrigger } from '@/shared/ui';

export const DatePickerInput = (props: TextInputProps) => {
  const formik = useFormikContext<FormikValues>();

  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

  const value = useMemo(() => get(formik.values, props.name as string), [formik]);

  const handleSelectDate = (e: any) => {
    const newDate = dayjs(e.$d).format('MM/DD/YYYY');
    formik.setFieldTouched(props.name as string, true);
    formik.setFieldValue(props.name as string, newDate);
  };

  const handleClearDate = () => {
    formik.setFieldTouched(props.name as string, true);
    formik.setFieldValue(props.name as string, null);
  };

  const getDatePickerStyles = () => ({
    padding: '2px 10px',
    width: '100%',
    height: '35px',
    border: '1px solid #D7DBDF',
    borderRadius: '4px',
    fontSize: '13px',
    cursor: 'pointer',
    textAlign: 'left',
    pointerEvents: 'all',
    backgroundColor: 'transparent',
    color: '#000000',
    outlineColor: '#3E54CF',
  });

  const datePickerColors = {
    highlighted: 'hsl(0 0% 90.9%)',
    disabled: 'hsl(0 0% 78.0%)',
    default: '',
    active: '',
  };

  return (
    <Box css={{ position: 'relative' }}>
      {value && (
        <Tooltip>
          <TooltipTrigger asChild>
            <IconButton
              onClick={handleClearDate}
              size={2}
              variant="ghost"
              style={{
                position: 'absolute',
                top: '50%',
                transform: 'translateY(-50%)',
                right: '30px',
                fontSize: '16px',
                width: 24,
                height: 24,
                color: '#60646C',
              }}
              data-testid={`clear-birthdate-button`}
            >
              <HiX size={16} color="#1C2024" />
            </IconButton>
          </TooltipTrigger>
          <TooltipContent>Clear Birth Date</TooltipContent>
        </Tooltip>
      )}
      <HiCalendar
        color="#60646C"
        style={{
          position: 'absolute',
          top: '50%',
          transform: 'translateY(-50%)',
          right: '10px',
          pointerEvents: 'none',
          fontSize: '16px',
        }}
      />
      <DatePicker
        isOpen={isDatePickerOpen}
        setIsOpen={setIsDatePickerOpen}
        zIndex={9999990}
        date={value ? dayjs(value) : undefined}
        markToday={true}
        format="Do MMMM YYYY"
        disableBeforeToday={false}
        inputStyle={getDatePickerStyles() as React.CSSProperties | undefined}
        colors={datePickerColors}
        onSelect={handleSelectDate}
        popoverPositions={['bottom']}
        closeOnSelect={true}
        style={{ pointerEvents: 'auto' }}
        placeholder={props.placeholder}
      />
    </Box>
  );
};
