import { styled } from '@/stitches.config';

import { Button } from './Button';
import { Flex } from './Flex';
import { IconButton } from './IconButton';
import { Input } from './Input';
import { Select } from './Select';

export const ControlGroup = styled('div', {
  display: 'flex',

  // Make sure ControlGroup and its children don't affect normal stacking order
  position: 'relative',
  zIndex: 0,

  [`& ${Button}`]: {
    borderRadius: 0,
    boxShadow:
      'inset 0 1px $colors$slate7, inset -1px 0 $colors$slate7, inset 0 -1px $colors$slate7',
    '&:hover': {
      boxShadow:
        '-1px 0 $colors$slate8, inset 0 1px $colors$slate8, inset -1px 0 $colors$slate8, inset 0 -1px $colors$slate8',
    },
    '&:focus': {
      zIndex: 1,
      boxShadow: 'inset 0 0 0 1px $colors$slate8, 0 0 0 1px $colors$slate8',
    },
    '&:first-child': {
      borderTopLeftRadius: '$1',
      borderBottomLeftRadius: '$1',
      boxShadow: 'inset 0 0 0 1px $colors$slate7',
      '&:hover': {
        boxShadow: 'inset 0 0 0 1px $colors$slate8',
      },
      '&:focus': {
        boxShadow: 'inset 0 0 0 1px $colors$slate8, 0 0 0 1px $colors$slate8',
      },
    },
    '&:last-child': {
      borderTopRightRadius: '$1',
      borderBottomRightRadius: '$1',
      boxShadow:
        'inset 0 1px $colors$slate7, inset -1px 0 $colors$slate7, inset 0 -1px $colors$slate7',
      '&:focus': {
        boxShadow: 'inset 0 0 0 1px $colors$slate8, 0 0 0 1px $colors$slate8',
      },
    },
  },
  [`& ${Input}`]: {
    borderRadius: 0,
    boxShadow:
      'inset 0 1px $colors$slate7, inset -1px 0 $colors$slate7, inset 0 -1px $colors$slate7',
    '&:focus': {
      zIndex: 1,
      boxShadow:
        'inset 0px 0px 0px 1px $colors$primaryColor, 0px 0px 0px 1px $colors$primaryColor',
    },
    '&:first-child': {
      borderTopLeftRadius: '$1',
      borderBottomLeftRadius: '$1',
      boxShadow: 'inset 0 0 0 1px $colors$slate7',
      '&:focus': {
        boxShadow:
          'inset 0px 0px 0px 1px $colors$primaryColor, 0px 0px 0px 1px $colors$primaryColor',
      },
    },
    '&:last-child': {
      borderTopRightRadius: '$1',
      borderBottomRightRadius: '$1',
      boxShadow:
        'inset 0 1px $colors$slate7, inset -1px 0 $colors$slate7, inset 0 -1px $colors$slate7',
      '&:focus': {
        boxShadow:
          'inset 0px 0px 0px 1px $colors$primaryColor, 0px 0px 0px 1px $colors$primaryColor',
      },
    },
  },
  [`& ${Select}`]: {
    borderRadius: 0,
    boxShadow:
      'inset 0 1px $colors$slate7, inset -1px 0 $colors$slate7, inset 0 -1px $colors$slate7',
    '&:focus-within': {
      boxShadow:
        'inset 0px 0px 0px 1px $colors$primaryColor, 0px 0px 0px 1px $colors$primaryColor',
    },
    '&:first-child': {
      borderTopLeftRadius: '$1',
      borderBottomLeftRadius: '$1',
      boxShadow: 'inset 0 0 0 1px $colors$slate7',
      '&:focus-within': {
        boxShadow:
          'inset 0px 0px 0px 1px $colors$primaryColor, 0px 0px 0px 1px $colors$primaryColor',
      },
    },
    '&:last-child': {
      borderTopRightRadius: '$1',
      borderBottomRightRadius: '$1',
      boxShadow:
        'inset 0 1px $colors$slate7, inset -1px 0 $colors$slate7, inset 0 -1px $colors$slate7',
      '&:focus-within': {
        boxShadow:
          'inset 0px 0px 0px 1px $colors$primaryColor, 0px 0px 0px 1px $colors$primaryColor',
      },
    },
  },
  [`& ${Flex}`]: {
    borderRadius: 0,
    px: '$2',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow:
      'inset 0 1px $colors$slate7, inset -1px 0 $colors$slate7, inset 0 -1px $colors$slate7',
    '&:focus-within': {
      boxShadow:
        'inset 0px 0px 0px 1px $colors$primaryColor, 0px 0px 0px 1px $colors$primaryColor',
    },
    '&:first-child': {
      borderTopLeftRadius: '$1',
      borderBottomLeftRadius: '$1',
      boxShadow: 'inset 0 0 0 1px $colors$slate7',
      '&:focus-within': {
        boxShadow:
          'inset 0px 0px 0px 1px $colors$primaryColor, 0px 0px 0px 1px $colors$primaryColor',
      },
    },
    '&:last-child': {
      borderTopRightRadius: '$1',
      borderBottomRightRadius: '$1',
      boxShadow:
        'inset 0 1px $colors$slate7, inset -1px 0 $colors$slate7, inset 0 -1px $colors$slate7',
      '&:focus-within': {
        boxShadow:
          'inset 0px 0px 0px 1px $colors$primaryColor, 0px 0px 0px 1px $colors$primaryColor',
      },
    },
  },
  [`& ${IconButton}`]: {
    borderRadius: 0,
    boxShadow:
      'inset 0 1px $colors$slate7, inset -1px 0 $colors$slate7, inset 0 -1px $colors$slate7',
    '&:hover': {
      boxShadow:
        '-1px 0 $colors$slate8, inset 0 1px $colors$slate8, inset -1px 0 $colors$slate8, inset 0 -1px $colors$slate8',
    },
    '&:focus': {
      zIndex: 1,
      boxShadow: 'inset 0 0 0 1px $colors$slate8, 0 0 0 1px $colors$slate8',
    },
    '&:first-child': {
      borderTopLeftRadius: '$1',
      borderBottomLeftRadius: '$1',
      boxShadow: 'inset 0 0 0 1px $colors$slate7',
      '&:hover': {
        boxShadow: 'inset 0 0 0 1px $colors$slate8',
      },
      '&:focus': {
        boxShadow: 'inset 0 0 0 1px $colors$slate8, 0 0 0 1px $colors$slate8',
      },
    },
    '&:last-child': {
      borderTopRightRadius: '$1',
      borderBottomRightRadius: '$1',
      boxShadow:
        'inset 0 1px $colors$slate7, inset -1px 0 $colors$slate7, inset 0 -1px $colors$slate7',
      '&:focus': {
        boxShadow: 'inset 0 0 0 1px $colors$slate8, 0 0 0 1px $colors$slate8',
      },
    },
  },
  [`& svg`]: {
    '&:hover': {
      boxShadow:
        '-1px 0 $colors$slate8, inset 0 1px $colors$slate8, inset -1px 0 $colors$slate8, inset 0 -1px $colors$slate8',
    },
    '&:focus': {
      zIndex: 1,
    },
    '&:first-child': {},
    '&:last-child': {},
  },
});
