import { IoList, IoMap, IoText, IoToggleSharp } from 'react-icons/io5';
import { MdCalendarToday, MdNumbers } from 'react-icons/md';
import { TbDecimal } from 'react-icons/tb';

import { PropertyType, ReferenceTypes } from '../../../../shared/types/data';

export const propertyType = [
  { label: 'Text', value: PropertyType.TEXT, icon: <IoText /> },
  { label: 'Number', value: PropertyType.NUMBER, icon: <MdNumbers size={16} /> },
  { label: 'Float', value: PropertyType.FLOAT, icon: <TbDecimal size={24} /> },
  { label: 'Boolean', value: PropertyType.BOOLEAN, icon: <IoToggleSharp size={14} /> },
  { label: 'Date', value: PropertyType.DATE, icon: <MdCalendarToday /> },
  { label: 'List', value: PropertyType.LIST, icon: <IoList /> },
  { label: 'Map', value: PropertyType.MAP, icon: <IoMap /> },
];

export const referenceTypes = [
  { label: 'Many to One', value: ReferenceTypes.MANY_TO_ONE },
  { label: 'Many to Many', value: ReferenceTypes.MANY_TO_MANY },
  { label: 'One to One', value: ReferenceTypes.ONE_TO_ONE },
  { label: 'One to Many', value: ReferenceTypes.ONE_TO_MANY },
];
