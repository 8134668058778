/* eslint-disable react-hooks/exhaustive-deps */
import { debounce } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useAuth } from '@/pages/auth/context/AuthProvider';
import { conversationSearch } from '@/shared/api/conversations';
import {
  ConversationsSearchV2ApiResponse,
  SearchV2Type,
  WhippyQueryLanguage,
  WhippyQueryLanguageFilterSchema,
} from '@/shared/types/conversations';

import { useConversation } from '../context/ConversationContext';
import { mergeConversations } from '../context/ConversationReducer';
import { ConversationsTabsContent } from '.';
import {
  EmptyConversationPreview,
  LoadingConversationPreview,
} from './ConversationPreview';
import { generateSearchClauses, SEARCH_CONVERSATIONS_LIMIT } from './utils';
import { VirtualisedConversationsList } from './VirtualisedConversationsList';

export const FilteredAll = ({ filter, tab }: { filter: string; tab: string }) => {
  const { searchV2 } = useConversation();

  const auth = useAuth();
  const [searchedResults, setSearchedResults] =
    useState<ConversationsSearchV2ApiResponse>({
      data: [],
      total: 0,
    });
  const [isLoading, setIsLoading] = useState(false);
  const [offset, setOffset] = useState(0);

  // Searches conversations based on input value, filter, and pagination offset
  const search = async (searchV2: SearchV2Type) => {
    const userId = auth?.tokens?.user_id ?? null;
    const additionalFilters = generateSearchClauses(userId, filter);

    // Define search filter schema (contacts and message)
    const contactFilter: WhippyQueryLanguageFilterSchema = {
      resource: 'contact',
      column: 'name',
      comparison: 'ilike',
      value: `${searchV2.inputValue}%`,
      or: [
        {
          resource: 'contact',
          column: 'email',
          comparison: 'ilike',
          value: `${searchV2.inputValue}%`,
          or: [
            {
              resource: 'contact',
              column: 'phone',
              comparison: 'ilike',
              value: `${searchV2.inputValue}%`,
              or: [
                {
                  resource: 'message',
                  column: 'body',
                  comparison: 'ilike',
                  value: `%${searchV2.inputValue}%`,
                },
              ],
            },
          ],
        },
      ],
      ...(additionalFilters.length > 0 ? { and: [...additionalFilters] } : {}),
    };

    // Prepare payload for search
    const searchPayload: WhippyQueryLanguage = {
      filter: [contactFilter],
      limit: SEARCH_CONVERSATIONS_LIMIT,
      offset,
    };

    // Perform search and update state
    const conversationSearchResults = await conversationSearch(searchPayload);
    setSearchedResults((previousSearchResults) => {
      const merged = mergeConversations(
        previousSearchResults.data,
        conversationSearchResults.data
      );

      return {
        data: merged,
        total: previousSearchResults.total + conversationSearchResults.total,
      } as ConversationsSearchV2ApiResponse;
    });
    setIsLoading(false);
  };

  // Debounce search to reduce API calls during typing
  const debounceConversationSearch = useCallback(
    debounce((searchV2: SearchV2Type) => {
      search(searchV2);
    }, 350),
    [auth?.tokens?.user_id, filter, offset]
  );

  // Trigger search on input change or filter/tab change
  useEffect(() => {
    if (searchV2.inputValue) {
      setIsLoading(true);
      setSearchedResults({
        data: [],
        total: 0,
      });
      setOffset(0);
      debounceConversationSearch(searchV2);
    } else {
      setIsLoading(false);
    }
  }, [searchV2.inputValue, filter]);

  // Trigger search on pagination offset change (without debouncing & side effects)
  useEffect(() => {
    if (
      searchV2.inputValue &&
      searchedResults.data.length === SEARCH_CONVERSATIONS_LIMIT * (offset + 1)
    ) {
      if (offset === 0) return;
      search(searchV2);
    }
  }, [offset]);

  // get the conversation id from the url
  const conversation_id = useParams<{ id?: string }>();

  return (
    <ConversationsTabsContent value="filtered:all">
      {isLoading && <LoadingConversationPreview />}
      {!isLoading && searchedResults.total > 0 && (
        <VirtualisedConversationsList
          conversations={searchedResults.data}
          length={searchedResults.total}
          setOffset={setOffset}
          offset={offset}
          slug={conversation_id}
          filter={filter}
          tab={tab}
        />
      )}
      {!isLoading && searchedResults.total === 0 && <EmptyConversationPreview />}
    </ConversationsTabsContent>
  );
};
