import { Formik } from 'formik';
import React from 'react';
import * as Yup from 'yup';

import { FormFieldWrapper, TextInput } from '@/shared/components/forms';
import { useDisclosure } from '@/shared/hooks';
import { Group } from '@/shared/types/contacts/groups';
import { Tag } from '@/shared/types/tags';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogPortal,
  AlertDialogTitle,
  AlertDialogTrigger,
  Box,
  Button,
  Flex,
  VStack,
} from '@/shared/ui';
import { styled } from '@/stitches.config';

export const RenameDialog = ({
  title,
  description,
  value,
  action,
}: {
  title: string;
  description: string;
  value: Group | Tag;
  action: (group: Partial<Group | Tag>) => Promise<Group | Tag>;
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <AlertDialog open={isOpen}>
      <AlertDialogTrigger asChild>
        <TriggerButton onClick={onOpen} data-testid="rename-option">
          {title}
        </TriggerButton>
      </AlertDialogTrigger>
      <AlertDialogPortal>
        <AlertDialogOverlay css={{ zIndex: 9999999999 }}>
          <AlertDialogContent onEscapeKeyDown={onClose}>
            <AlertDialogTitle css={{ fontSize: '20px' }}>{description}</AlertDialogTitle>
            <Formik
              initialValues={{
                name: value.name,
              }}
              validationSchema={Yup.object({
                name: Yup.string().required('Name is required'),
              })}
              onSubmit={async (values) => {
                const params = {
                  ...value,
                  name: values.name,
                };
                try {
                  action(params);
                  onClose();
                } catch (e) {
                  console.log(e);
                }
              }}
            >
              {(formik) => (
                <form onSubmit={formik.handleSubmit}>
                  <VStack gap={0} css={{ marginTop: '16px' }}>
                    <Box css={{ position: 'relative' }}>
                      <FormFieldWrapper name="name">
                        <TextInput
                          css={{ height: '32px', fontSize: 14, fontWeight: 400 }}
                          placeholder="Enter name"
                        />
                      </FormFieldWrapper>
                    </Box>
                  </VStack>
                  <Flex justify="end">
                    <AlertDialogCancel asChild>
                      <Button variant="grayBackground" css={{ mr: 10 }} onClick={onClose}>
                        Cancel
                      </Button>
                    </AlertDialogCancel>
                    <AlertDialogAction asChild>
                      <Button
                        variant="primary"
                        type="submit"
                        data-testid="confirm-rename"
                      >
                        Confirm
                      </Button>
                    </AlertDialogAction>
                  </Flex>
                </form>
              )}
            </Formik>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialogPortal>
    </AlertDialog>
  );
};

const TriggerButton = styled('button', {
  paddingLeft: 12,
  width: '100%',
  height: '31px',
  borderRadius: '4px',
  fontSize: 14,
  color: '#1C2024',
  textAlign: 'left',
  backgroundColor: 'transparent',
  '&:hover': {
    backgroundColor: '#3A5CCC',
    color: '#FFF',
  },
});
