import React, { useState } from 'react';

import { useChannels } from '@/pages/settings/organization/channels/context/ChannelContext';
import { SingleSelect } from '@/shared/components/SingleSelect';
import { TimezonePicker } from '@/shared/components/timezonepicker/TimezonePicker';
import { Channel } from '@/shared/types/channels';
import { Label } from '@/shared/ui';

type Props = {
  handleChangeLocation: (location: Channel) => void;
  showTimeZonePicker: boolean;
  selected?: Channel | null;
};

export const LocationDropdown = ({
  handleChangeLocation,
  showTimeZonePicker,
  selected: selectedLocation,
}: Props) => {
  const { channelsState } = useChannels();
  const { channels } = channelsState;
  const [timezone, setTimezone] = useState<string>(
    selectedLocation?.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone || ''
  );

  const getLocationName = (
    currentLocation?: Channel | null
  ): string | null | undefined => {
    if (!currentLocation) return null;
    return currentLocation.name;
  };

  return (
    <>
      {channels.length > 0 && <Label>From:</Label>}
      {channels.length > 0 && (
        <SingleSelect
          defaultPlaceholder={getLocationName(selectedLocation) ?? 'Select a channel'}
          isDropdown={true}
          selectItem={selectedLocation?.id ?? ''}
          setSelectItem={(locationId: string) => {
            const location = channels.find((location) => location.id === locationId);
            if (location) handleChangeLocation(location);
          }}
          options={channels.map((location) => ({
            type: location.name || location.address || '',
            value: location?.id,
          }))}
        />
      )}
      {!selectedLocation?.timezone && showTimeZonePicker && (
        <>
          <Label>Update Channel Timezone:</Label>
          <TimezonePicker timezone={timezone} setTimezone={setTimezone} />
        </>
      )}
    </>
  );
};
