/**
 * This function checks if two arrays are equal by comparing their lengths and elements.
 * It returns true if both arrays have the same length and all elements of the first array are present in the second array.
 * Otherwise, it returns false.
 *
 * @param a - The first array to compare.
 * @param b - The second array to compare.
 * @returns A boolean indicating whether the two arrays are equal.
 */
export const equals = (a: any[], b: any[]): boolean =>
  a.length === b.length && a.every((v: any) => b.indexOf(v) > -1);
