import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useMedia } from 'react-use';

import { usePageView } from '@/shared/hooks';
import { SettingsLayout } from '@/shared/layouts';
import { Button, Flex } from '@/shared/ui';

import { AddTemplate } from './AddTemplate';
import TemplatesBulkImport from './TemplatesBulkImport';
import TemplatesView from './TemplatesView';

export function Templates(): JSX.Element {
  usePageView();
  const isDesktop = useMedia('(min-width: 912px)');

  return (
    <>
      <Helmet>
        <title>Whippy | Message Templates</title>
      </Helmet>
      <SettingsLayout
        background="#fafafa"
        width="100%"
        breadcrumbs={[
          { title: 'Settings', path: '/settings/templates' },
          { title: 'Message Templates', path: `/settings/templates` },
        ]}
        actions={
          <Flex gap={2}>
            {isDesktop && (
              <TemplatesBulkImport>
                <Button variant="gray" type="button">
                  Import Templates
                </Button>
              </TemplatesBulkImport>
            )}
            <AddTemplate />
          </Flex>
        }
      >
        <TemplatesView />
      </SettingsLayout>
    </>
  );
}
