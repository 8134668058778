import { memo } from 'react';
import { HiChat, HiLightBulb, HiQuestionMarkCircle } from 'react-icons/hi';

import {
  Toolbar,
  ToolbarButton,
} from '@/pages/inbox/conversation/panels/ConversationToolbar';
import { AgentEditorPanelType } from '@/shared/types/ai/agents';
import { VStack } from '@/shared/ui';

type AgentEditorToolbarProps = {
  /* open or closed the panel */
  open: boolean;
  /* handle agent editor side panel toolbar click */
  handlePanelClick: (panel: AgentEditorPanelType) => void;
};

const AgentEditorToolbar = (props: AgentEditorToolbarProps) => {
  const { open, handlePanelClick } = props;

  return (
    <Toolbar open={open}>
      <VStack gap={4}>
        <ToolbarButton
          description="Test Agent"
          onClick={() => handlePanelClick(AgentEditorPanelType.CHAT)}
          testID="show-preview-button"
        >
          <HiChat size={18} />
        </ToolbarButton>
        <ToolbarButton
          description="Test Agent"
          onClick={() => handlePanelClick(AgentEditorPanelType.SUGGESTIONS)}
          testID="show-preview-button"
        >
          <HiLightBulb size={18} />
        </ToolbarButton>
        <ToolbarButton
          description="Help"
          onClick={() => handlePanelClick(AgentEditorPanelType.HELP)}
          testID="agent-settings-button"
        >
          <HiQuestionMarkCircle size={18} />
        </ToolbarButton>
      </VStack>
    </Toolbar>
  );
};

export default memo(AgentEditorToolbar);
