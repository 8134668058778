/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable react-hooks/exhaustive-deps */
// @refresh reset
import 'reactflow/dist/style.css';

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ReactFlow, {
  Background,
  Controls,
  MarkerType,
  MiniMap,
  ReactFlowProvider,
  useReactFlow,
} from 'reactflow';

import * as SequencesAPI from '@/shared/api/sequences';
import { SequenceStep, SequenceStepTrigger } from '@/shared/types/sequences';

const proOptions = {
  account: 'paid-pro',
  hideAttribution: true,
};

const defaultEdgeOptions = {
  type: 'smoothstep',
  markerEnd: { type: MarkerType.ArrowClosed },
  pathOptions: { offset: 5 },
};

export const SequenceFlow = () => {
  const { id } = useParams<{ id: string }>();

  const [steps, setSteps] = useState<SequenceStep[]>([]);

  useEffect(() => {
    const fetchSequenceSteps = async () => {
      if (id) {
        // get the sequence steps based on the selected sequence id
        const data = await SequencesAPI.getSequenceSteps(id);
        // set the sequence steps
        setSteps(data);
      }
    };

    fetchSequenceSteps();
  }, [id]);

  const [triggers, setTriggers] = useState<SequenceStepTrigger[]>([]);

  useEffect(() => {
    steps.forEach((step) => {
      if (!step?.id) return;
      SequencesAPI.getSequenceStepTriggers(id, step?.id).then((res) => {
        // merge in the step.id as soon as you fetch the triggers
        const triggersWithStepId = res.map((trigger) => ({
          ...trigger,
          step_id: step.id,
        }));

        // set and remove duplicates by id and merge in the step.id
        setTriggers((prev) => [
          ...prev,
          ...triggersWithStepId.filter(
            (trigger) => !prev.find((t) => t.id === trigger.id)
          ),
        ]);
      });
    });
  }, [steps]);

  console.log('STEPS', JSON.stringify(steps));
  console.log('TRIGGERS', JSON.stringify(triggers));

  return (
    <>
      {triggers.length > 0 && steps.length > 0 && (
        <ReactFlowProvider>
          <Flow steps={steps} triggers={triggers} />
        </ReactFlowProvider>
      )}
    </>
  );
};

type FlowProps = {
  steps: SequenceStep[];
  triggers: SequenceStepTrigger[];
};

type Node = {
  id: string;
  type: string;
  data: { label: string };
  position: { x: number; y: number };
};

type Edge = {
  id: string;
  source: string;
  target: string;
};

export const Flow = (props: FlowProps) => {
  const { steps, triggers } = props;

  const { fitView } = useReactFlow();

  const [nodes, setNodes] = useState<Node[]>([]);
  const [edges, setEdges] = useState<Edge[]>([]);

  useEffect(() => {
    // Convert steps to nodes
    const stepNodes = steps.map((step, index) => ({
      id: step.id || '',
      type: 'default',
      data: { label: `Step: ${step.title}` },
      // position: { x: 0, y: 0 },
      position: { x: index * 200, y: 100 },
    }));

    // Convert triggers to nodes
    const triggerNodes = triggers.map((trigger, index) => ({
      id: trigger.id || '',
      type: 'default',
      data: { label: `Trigger: ${trigger.trigger.id}` },
      // position: { x: 0, y: 0 },
      position: { x: index * 200, y: 300 },
    }));

    const nodes = [...stepNodes, ...triggerNodes];

    // Create edges
    const edges = triggers.flatMap((trigger) => {
      const originStepId: string = trigger?.step_id || '';
      // @ts-ignore
      const targetStepId: string = trigger?.trigger?.actions[0]?.params?.step_id || '';

      return [
        {
          id: `e${originStepId}-${trigger.id}` || '',
          source: originStepId || '',
          target: trigger.id || '',
          animated: true,
        },
        {
          id: `e${trigger.id}-${targetStepId}` || '',
          source: trigger.id || '',
          target: targetStepId || '',
          animated: true,
        },
      ].filter((edge) => edge.source !== edge.target); // Filter out edges that would create a cycle
    });

    setNodes(nodes);
    setEdges(edges);
  }, [steps, triggers]);

  console.log('NODES', JSON.stringify(nodes));
  console.log('EDGES', JSON.stringify(edges));

  useEffect(() => {
    fitView({ duration: 400 });
  }, [nodes, fitView]);

  return (
    <div style={{ width: '100vw', height: '100vh' }}>
      {nodes.length > 0 && edges.length > 0 && (
        <ReactFlow
          nodes={nodes}
          edges={edges}
          fitView
          minZoom={0.2}
          nodesDraggable={false}
          nodesConnectable={false}
          zoomOnDoubleClick={false}
          proOptions={proOptions}
          defaultEdgeOptions={defaultEdgeOptions}
        >
          <Controls />
          <MiniMap />
          <Background gap={12} size={1} />
        </ReactFlow>
      )}
    </div>
  );
};
