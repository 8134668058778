import React, { useEffect } from 'react';
import { createContext, useContext, useReducer } from 'react';
import { toast } from 'sonner';

import { useAuth } from '@/pages/auth/context/AuthProvider';
import { prepareFilters } from '@/pages/data/utils/prepareFilters';
import * as SignaturesAPI from '@/shared/api/signatures';
import { fetchUser } from '@/shared/api/users';
import { Signature } from '@/shared/types';
import { SearchFilters } from '@/shared/types/contacts';
import {
  CreateSignatureParams,
  SignatureActionTypes,
  UpdateSignatureParams,
} from '@/shared/types/signatures';
import { User } from '@/shared/types/users';

import SignaturesReducer from './SignaturesReducer';

export const ITEMS_COUNT = 10;

export type SignaturesState = {
  /* all signatures in state */
  allSignatures: Array<Signature>;
  /* filtered signatures in state */
  signatures: Array<Signature>;
  /* default user signature id */
  defaultUserSignatureId: string | null | undefined;
  /* total count of signatures */
  totalCount: number;
  /* loading state */
  loading: boolean;
  /* current signature state */
  current: Signature | null | undefined;
  /* error state */
  error: any;
  filterParams: SearchFilters;
};

export const initialSignaturesState: SignaturesState = {
  allSignatures: [],
  signatures: [],
  totalCount: 0,
  current: null,
  error: null,
  loading: true,
  defaultUserSignatureId: null,
  filterParams: {
    offset: 0,
    limit: ITEMS_COUNT,
    sort: [
      {
        label: 'Updated At',
        column: 'updated_at',
        order: 'desc',
        resource: 'signature',
        id: null,
      },
    ],
    filter: [],
    searchFilter: [],
  },
};

export const SignaturesContext = createContext<{
  signaturesState: SignaturesState;
  getSignature: (id: string) => Promise<Signature | null | undefined>;
  getSignatures: () => void;
  createSignature: (params: CreateSignatureParams) => void;
  editSignature: (params: UpdateSignatureParams) => void;
  deleteSignature: (signatureId: string) => void;
  setCurrentSignature: (signature: Signature | null | undefined) => void;
  getDefaultSignature: () => void;
  updateDefaultSignature: (signature: Signature | User | null) => void;
  getSignaturesV2: (params: SearchFilters) => Promise<void>;
  updateFilterParams: (params: SearchFilters) => void;
}>({
  signaturesState: initialSignaturesState,
  getSignature: () => Promise.resolve({} as Signature),
  getSignatures: () => Promise.resolve([] as Array<Signature>),
  createSignature: () => Promise.resolve({} as Signature),
  editSignature: () => Promise.resolve({} as Signature),
  deleteSignature: () => Promise.resolve({} as Signature),
  setCurrentSignature: () => Promise.resolve(),
  getDefaultSignature: () => Promise.resolve({} as Signature),
  updateDefaultSignature: () => Promise.resolve({} as Signature),
  getSignaturesV2: () => Promise.resolve(),
  updateFilterParams: () => Promise.resolve(),
});

export const useSignatures = () => useContext(SignaturesContext);

const SignatureState = ({ children }: { children: React.ReactNode }) => {
  const [signaturesState, dispatch] = useReducer(
    SignaturesReducer,
    initialSignaturesState
  );

  const authContext = useAuth();
  const { tokens } = authContext;

  // On mount get all signatures
  useEffect(() => {
    if (
      signaturesState.allSignatures.length > 0 ||
      window.location.pathname === '/signatures'
    ) {
      return;
    }
    getSignatures();
  }, []);

  // On mount get default user signature
  useEffect(() => {
    if (signaturesState.defaultUserSignatureId) {
      return;
    }
    getDefaultSignature();
  }, []);

  useEffect(() => {
    getSignaturesV2(signaturesState.filterParams);
  }, [signaturesState.filterParams]);

  const getSignature = async (id: string) => {
    try {
      const data = await SignaturesAPI.getSignature(id);

      dispatch({
        type: SignatureActionTypes.GET_ALL_SIGNATURES,
        payload: [data],
      });

      return data;
    } catch (err) {
      console.error(err);
    }
  };

  const getSignatures = async () => {
    try {
      const data = await SignaturesAPI.getSignatures();

      dispatch({
        type: SignatureActionTypes.GET_ALL_SIGNATURES,
        payload: data,
      });
    } catch (err) {
      console.error(err);
    }
  };

  const getSignaturesV2 = async (params: SearchFilters) => {
    try {
      const data = await SignaturesAPI.getSignaturesV2(prepareFilters(params));

      dispatch({
        type: SignatureActionTypes.GET_SIGNATURES,
        payload: data,
      });
    } catch (err) {
      console.error(err);
    }
  };

  const updateFilterParams = async (params: SearchFilters) => {
    try {
      dispatch({
        type: SignatureActionTypes.UPDATE_FILTER_PARAMS,
        payload: params,
      });
    } catch (err) {
      console.error(err);
    }
  };

  const createSignature = async (params: CreateSignatureParams) => {
    try {
      const data = await SignaturesAPI.createSignature(params);

      dispatch({
        type: SignatureActionTypes.CREATE_SIGNATURE,
        payload: data,
      });
      if (signaturesState.signatures.length) {
        getSignaturesV2({ ...signaturesState.filterParams });
      }
      toast.success('Signature created');
    } catch (err) {
      toast.error('Signature too long');
      console.error(err);
    }
  };

  const editSignature = async (params: UpdateSignatureParams) => {
    try {
      const data = await SignaturesAPI.editSignature(params);

      dispatch({
        type: SignatureActionTypes.EDIT_SIGNATURE,
        payload: data,
      });
      getSignaturesV2({ ...signaturesState.filterParams });
      toast.success('Signature updated');
    } catch (err) {
      toast.error('Signature update failed');

      console.error(err);
    }
  };

  const deleteSignature = async (signatureId: string) => {
    try {
      await SignaturesAPI.deleteSignature(signatureId);
      dispatch({
        type: SignatureActionTypes.DELETE_SIGNATURE,
        payload: signatureId,
      });
      getSignaturesV2({ ...signaturesState.filterParams });
      toast.success('Signature deleted');
    } catch (err) {
      toast.error('Signature delete failed');

      console.error(err);
    }
  };

  const setCurrentSignature = (signature: Signature | null | undefined) => {
    try {
      dispatch({
        type: SignatureActionTypes.SET_CURRENT,
        payload: signature,
      });
    } catch (err) {
      console.error(err);
    }
  };

  const getDefaultSignature = async () => {
    if (tokens && tokens?.user_id) {
      const data = await fetchUser(tokens?.user_id);

      dispatch({
        type: SignatureActionTypes.SET_DEFAULT_USER_SIGNATURE,
        payload: data,
      });
    }
  };

  const updateDefaultSignature = (signature: Signature | User | null) => {
    try {
      dispatch({
        type: SignatureActionTypes.SET_DEFAULT_USER_SIGNATURE,
        payload: signature,
      });
      toast.success('Default Signature updated');
    } catch (err) {
      toast.error('Default Signature update failed');
      console.error(err);
    }
  };

  return (
    <SignaturesContext.Provider
      value={{
        signaturesState,
        getSignature,
        getSignatures,
        createSignature,
        editSignature,
        deleteSignature,
        setCurrentSignature,
        getDefaultSignature,
        updateDefaultSignature,
        getSignaturesV2,
        updateFilterParams,
      }}
    >
      {children}
    </SignaturesContext.Provider>
  );
};

export default SignatureState;
