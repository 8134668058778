import { CSS, styled } from '@/stitches.config';

export const overlayStyles: CSS = {
  backgroundColor: 'rgba(0, 0, 0, .65)',
  margin: '0',
};

export const Overlay = styled('div', {
  ...overlayStyles,
  variants: {
    variant: {
      full: {
        position: 'fixed',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        marginLeft: 0,
        zIndex: 2,
      },
      scroll: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: 'grid',
        placeItems: 'center',
        overflowY: 'auto',
      },
    },
  },
});
