import React, { forwardRef, useEffect, useRef } from 'react';
import { HiCheck, HiMinusSm } from 'react-icons/hi';

import { styled } from '@/stitches.config';

interface IndeterminateCheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
  indeterminate?: boolean;
}

export const IndeterminateCheckbox = forwardRef<
  HTMLInputElement,
  IndeterminateCheckboxProps
>(({ indeterminate, ...rest }, ref) => {
  const defaultRef = useRef<HTMLInputElement>(null);
  // Use the passed ref if it exists; otherwise, use the default ref
  const resolvedRef = ref ?? defaultRef;

  useEffect(() => {
    // Type guard to check if resolvedRef is a RefObject
    if (resolvedRef && typeof resolvedRef !== 'function' && resolvedRef.current) {
      resolvedRef.current.indeterminate = !!indeterminate;
    }
  }, [resolvedRef, indeterminate]);

  return (
    <>
      <CustomCheckbox checked={rest.checked}>
        {rest.checked ? (
          <HiCheck style={{ position: 'absolute', color: 'white' }} size={12} />
        ) : null}
        {indeterminate ? (
          <HiMinusSm style={{ position: 'absolute', color: '#1d2124' }} size={12} />
        ) : null}
        <HiddenCheckbox type="checkbox" ref={resolvedRef} {...rest} />
      </CustomCheckbox>
    </>
  );
});

const CustomCheckbox = styled('label', {
  height: 14,
  width: 14,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  border: '1px solid $slate7',
  borderRadius: 4,
  variants: {
    checked: {
      true: {
        borderColor: '#3f54cf',
        backgroundColor: '#3f54cf',
      },
    },
  },
});

const HiddenCheckbox = styled('input', {
  opacity: 0,
});
