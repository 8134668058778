export const default_communication_preference_object = {
  id: 'unique-id-for-comm-preference',
  label: 'Communication Preference',
  key: 'communication_preference',
  inserted_at: 'current-timestamp',
  updated_at: 'current-timestamp',
  custom_properties: [
    {
      default: '',
      id: 'communication_preference-channel',
      label: 'Channel',
      type: 'channel',
      key: 'location_id',
      required: true,
      inserted_at: 'current-timestamp',
      updated_at: 'current-timestamp',
      custom_object_id: 'unique-id-for-conversation',
    },
    {
      default: '',
      id: 'communication_preference-opt_in',
      label: 'Opt In',
      type: 'boolean',
      key: 'opt_in',
      required: true,
      inserted_at: 'current-timestamp',
      updated_at: 'current-timestamp',
      custom_object_id: 'unique-id-for-conversation',
    },
    {
      default: '',
      id: 'communication_preference-last-campaign',
      label: 'Last Campaign',
      type: 'campaign',
      key: 'last_campaign_id',
      required: true,
      inserted_at: 'current-timestamp',
      updated_at: 'current-timestamp',
      custom_object_id: 'unique-id-for-contact',
    },
    {
      default: '',
      id: 'communication_preference-opt-out-date',
      label: 'Opt Out Date',
      type: 'date',
      key: 'opt_out_date',
      required: true,
      inserted_at: 'current-timestamp',
      updated_at: 'current-timestamp',
      custom_object_id: 'unique-id-for-contact',
    },
    {
      default: '',
      id: 'communication_preference-paused_until',
      label: 'Paused Until',
      type: 'date',
      key: 'paused_until',
      required: true,
      inserted_at: 'current-timestamp',
      updated_at: 'current-timestamp',
      custom_object_id: 'unique-id-for-contact',
    },
    {
      default: '',
      id: 'communication_preference-last-campaign-date',
      label: 'Last Campaign Date',
      type: 'date',
      key: 'last_campaign_date',
      required: true,
      inserted_at: 'current-timestamp',
      updated_at: 'current-timestamp',
      custom_object_id: 'unique-id-for-contact',
    },
    {
      default: 'current-timestamp',
      id: 'communication_preference-inserted_at',
      label: 'Inserted At',
      type: 'date',
      key: 'inserted_at',
      required: true,
      inserted_at: 'current-timestamp',
      updated_at: 'current-timestamp',
      custom_object_id: 'unique-id-for-contact',
    },
    {
      default: 'current-timestamp',
      id: 'communication_preference-updated_at',
      label: 'Updated At',
      type: 'date',
      key: 'updated_at',
      required: true,
      inserted_at: 'current-timestamp',
      updated_at: 'current-timestamp',
      custom_object_id: 'unique-id-for-contact',
    },
  ],
};
