import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';

import {
  CheckboxFormFieldWrapper,
  CheckboxInput,
  FormFieldWrapper,
  helpers,
  MultiSelectCombobox,
  SelectCombobox,
  TextAreaInput,
  TextInput,
} from '@/shared/components/forms';
import { SingleSelect } from '@/shared/components/SingleSelect';
import { Box, Button, Flex, HStack, Input, VStack } from '@/shared/ui';

import { useTenDlc } from '../context/TenDlcContext';
import { TrustLayout } from '../TrustLayout';
import { helpKeywords, listOfUseCases, optinKeywords, optOutKeywords } from './lists';

export const CreateCampaignForm = () => {
  const history = useHistory();

  const tenDlcContext = useTenDlc();
  const { tenDlcState, createOneCampaign, getAllBrands } = tenDlcContext;
  const { brands } = tenDlcState;

  const [messageSamples, setMessageSamples] = useState<string[]>(['', '', '']);
  const [brandId, setBrandId] = useState(brands.length < 1 ? '' : brands[0].id);

  const getBrandDisplayName = (id: string) =>
    brands.find((brand: { id: string }) => brand.id === id)?.display_name;

  const onMessageSampleChange = (messageSampleNumber: number, value: string) => {
    const arr = messageSamples.slice();
    arr[messageSampleNumber] = value;
    setMessageSamples(arr);
  };

  const isButtonDisabled = () => {
    if (!brandId) {
      return true;
    }
    if (
      messageSamples[0] === '' ||
      messageSamples[1] === '' ||
      messageSamples[2] === ''
    ) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (brands.length < 1) {
      getAllBrands();
    }
  }, []);

  return (
    <TrustLayout>
      <Flex align="center" css={{ marginBottom: 30 }}>
        <Box css={{ fontWeight: 600, fontSize: 16, ml: 8 }}>Create 10DLC Campaign</Box>
      </Flex>
      <Formik
        enableReinitialize
        initialValues={{
          description: '',
          use_case: 'MIXED',
          sub_use_cases: [
            'CUSTOMER_CARE',
            'MARKETING',
            'DELIVERY_NOTIFICATION',
            'ACCOUNT_NOTIFICATION',
          ],
          opt_in_keywords: optinKeywords.map((key) => key.value),
          opt_out_keywords: optOutKeywords.map((key) => key.value),
          help_keywords: helpKeywords.map((key) => key.value),
          help_message: '',
          opt_in_message: '',
          opt_out_message: '',
          message_flow: '',
          affiliate_marketing: false,
          age_gated: false,
          auto_renewal: false,
          embedded_link: true,
          embedded_phone: false,
          number_pool: false,
          subscriber_opt_in: true,
          subscriber_opt_out: true,
          subscriber_help: true,
          direct_lending: false,
        }}
        validationSchema={Yup.object({
          description: Yup.string().required('Required'),
          use_case: Yup.string().required('Required'),
          sub_use_cases: Yup.array().required('Required'),
          opt_in_keywords: Yup.array().required('Required'),
          opt_out_keywords: Yup.array().required('Required'),
          help_keywords: Yup.array().required('Required'),
          help_message: Yup.string().required('Required'),
          opt_in_message: Yup.string().required('Required'),
          opt_out_message: Yup.string().required('Required'),
          message_flow: Yup.string().required('Required'),
          affiliate_marketing: Yup.boolean(),
          age_gated: Yup.boolean(),
          auto_renewal: Yup.boolean(),
          embedded_link: Yup.boolean(),
          embedded_phone: Yup.boolean(),
          number_pool: Yup.boolean(),
          subscriber_opt_in: Yup.boolean(),
          subscriber_opt_out: Yup.boolean(),
          subscriber_help: Yup.boolean(),
          direct_lending: Yup.boolean(),
        })}
        onSubmit={async (values) => {
          try {
            createOneCampaign(brandId, { ...values, message_samples: messageSamples });
            history.push('/settings/onboarding');
          } catch (e) {
            console.log(e);
          }
        }}
      >
        {(formik) => (
          <form onSubmit={formik.handleSubmit}>
            <VStack gap={2}>
              <FormFieldWrapper label="Brand" tooltip="Brand" name="brand_id">
                {brands.length > 0 && (
                  <SingleSelect
                    selectItem={brandId}
                    setSelectItem={setBrandId}
                    closeOnClick={true}
                    options={brands.map((brand) => ({
                      type: brand.display_name as string,
                      value: brand.id as string,
                    }))}
                    defaultPlaceholder={getBrandDisplayName(brandId) || 'Select a brand'}
                    isDropdown={true}
                  />
                )}
              </FormFieldWrapper>
              <FormFieldWrapper
                label="Description"
                tooltip="Describe your campaign."
                name="description"
              >
                <TextInput placeholder="Marketing campaign" />
              </FormFieldWrapper>
              <HStack gap={3}>
                <FormFieldWrapper
                  label="Use Case"
                  tooltip="The use case of the campaign."
                  name="use_case"
                >
                  <SelectCombobox
                    placeholder={helpers.displaySelectedItem}
                    isDropdown={true}
                    options={listOfUseCases}
                  />
                </FormFieldWrapper>
                <FormFieldWrapper
                  label="Sub Use Cases"
                  tooltip="A collection of sub use cases that are relevant for the campaign."
                  name="sub_use_cases"
                >
                  <MultiSelectCombobox
                    placeholder={helpers.displaySelectedItems}
                    isDropdown={true}
                    options={listOfUseCases}
                  />
                </FormFieldWrapper>
              </HStack>
              <HStack gap={3}>
                <FormFieldWrapper
                  label="Opt-in Keywords"
                  tooltip="A collection of keywords that will be used to opt in subscribers to a campaign."
                  name="opt_in_keywords"
                >
                  <MultiSelectCombobox
                    placeholder={helpers.displaySelectedItems}
                    isDropdown={true}
                    options={optinKeywords}
                  />
                </FormFieldWrapper>
                <FormFieldWrapper
                  label="Opt-out Keywords"
                  tooltip="A collection of keywords that will be used to opt out subscribers from a campaign"
                  name="opt_out_keywords"
                >
                  <MultiSelectCombobox
                    placeholder={helpers.displaySelectedItems}
                    isDropdown={true}
                    options={optOutKeywords}
                  />
                </FormFieldWrapper>
              </HStack>
              <FormFieldWrapper
                label="Help Keywords"
                tooltip="A collection of keywords to which the campaign will respond to with more information."
                name="help_keywords"
              >
                <MultiSelectCombobox
                  placeholder={helpers.displaySelectedItems}
                  isDropdown={true}
                  options={helpKeywords}
                />
              </FormFieldWrapper>
              <FormFieldWrapper
                label="Help Message"
                tooltip="Help message of the campaign."
                name="help_message"
              >
                <TextAreaInput placeholder="Тhe Campaign is regarding x, y and z. To unsubscribe send STOP." />
              </FormFieldWrapper>
              <FormFieldWrapper
                label="Opt-in Message"
                tooltip="Subscriber opt-in message"
                name="opt_in_message"
              >
                <TextAreaInput placeholder="You have subscribed to receiving campaign messages." />
              </FormFieldWrapper>
              <FormFieldWrapper
                label="Opt-out Message"
                tooltip="Subscriber opt-out message"
                name="opt_out_message"
              >
                <TextAreaInput placeholder="You have unsubscribed from receiving campaign messages." />
              </FormFieldWrapper>
              <FormFieldWrapper
                label="Message Flow"
                tooltip="Message flow description."
                name="message_flow"
              >
                <TextInput placeholder="message_flow" />
              </FormFieldWrapper>
              <FormFieldWrapper
                label="Message Sample 1"
                tooltip="Message Sample 1"
                name=""
              >
                <Input
                  placeholder="Hi, thank you for messaging. A member of our team will contact you shortly."
                  value={messageSamples[0]}
                  onChange={(e) => onMessageSampleChange(0, e.target.value)}
                />
              </FormFieldWrapper>
              <FormFieldWrapper
                label="Message Sample 2"
                tooltip="Message Sample 2"
                name=""
              >
                <Input
                  placeholder="Hi, reply with your name to confirm your appointment."
                  value={messageSamples[1]}
                  onChange={(e) => onMessageSampleChange(1, e.target.value)}
                />
              </FormFieldWrapper>
              <FormFieldWrapper
                label="Message Sample 3"
                tooltip="Message Sample 3"
                name=""
              >
                <Input
                  placeholder="Hi we have jobs that might interest you. Reply with interested to find out more."
                  value={messageSamples[2]}
                  onChange={(e) => onMessageSampleChange(2, e.target.value)}
                />
              </FormFieldWrapper>
              <Flex>
                <CheckboxFormFieldWrapper
                  label="Affiliate Marketing"
                  tooltip="Is the message content controlled by affiliate marketing other than the brand?"
                  name="affiliate_marketing"
                >
                  <CheckboxInput />
                </CheckboxFormFieldWrapper>
                <CheckboxFormFieldWrapper
                  label="Age Gated"
                  tooltip="is there age gated message content in the campaign?"
                  name="age_gated"
                >
                  <CheckboxInput />
                </CheckboxFormFieldWrapper>
                <CheckboxFormFieldWrapper
                  label="Auto Renewal"
                  tooltip="Should the campaign be renewed automatically at the end of the billing cycle?"
                  name="auto_renewal"
                >
                  <CheckboxInput />
                </CheckboxFormFieldWrapper>
              </Flex>
              <Flex>
                <CheckboxFormFieldWrapper
                  label="Embedded Link"
                  tooltip="Does the campaign message contain a URL?"
                  name="embedded_link"
                >
                  <CheckboxInput />
                </CheckboxFormFieldWrapper>
                <CheckboxFormFieldWrapper
                  label="Embedded Phone"
                  tooltip="Does the campaign message contain a phone number?"
                  name="embedded_phone"
                >
                  <CheckboxInput />
                </CheckboxFormFieldWrapper>
                <CheckboxFormFieldWrapper
                  label="Number Pool"
                  tooltip="Does campaign utilize pool of phone numbers?"
                  name="number_pool"
                >
                  <CheckboxInput />
                </CheckboxFormFieldWrapper>
              </Flex>
              <Flex>
                <CheckboxFormFieldWrapper
                  label="Subscriber Opt-in"
                  tooltip="Does the campaign require subscriber to opt-in before SMS is sent to subscriber?"
                  name="subscriber_opt_in"
                >
                  <CheckboxInput />
                </CheckboxFormFieldWrapper>
                <CheckboxFormFieldWrapper
                  label="Subscriber Opt-out"
                  tooltip="Does the campaign support subscriber opt-out keyword(s)?"
                  name="subscriber_opt_out"
                >
                  <CheckboxInput />
                </CheckboxFormFieldWrapper>
                <CheckboxFormFieldWrapper
                  label="Subscriber Help"
                  tooltip="Does campaign responds to help keyword(s)?"
                  name="subscriber_help"
                >
                  <CheckboxInput />
                </CheckboxFormFieldWrapper>
              </Flex>
              <CheckboxFormFieldWrapper
                label="Direct Lending"
                tooltip="Is it direct lending or loan arrangement?"
                name="direct_lending"
              >
                <CheckboxInput />
              </CheckboxFormFieldWrapper>
              <Box>
                <Button type="submit" disabled={isButtonDisabled()}>
                  Create 10DLC Campaign
                </Button>
              </Box>
            </VStack>
          </form>
        )}
      </Formik>
    </TrustLayout>
  );
};
