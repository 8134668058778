/* eslint-disable react-hooks/exhaustive-deps */
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { HiX } from 'react-icons/hi';
import { useHistory } from 'react-router-dom';
import { VirtuosoHandle } from 'react-virtuoso';

import { QuickCampaignDialog } from '@/campaigns/v2/quick';
import { createQuickCampaign } from '@/pages/campaigns/v2/quick/api';
import { useGroups } from '@/pages/contacts/groups/context/GroupContext';
import { prepareFilters } from '@/pages/data/utils/prepareFilters';
import { useChannels } from '@/pages/settings/organization/channels/context/ChannelContext';
import {
  initialCustomDataState,
  useCustomData,
} from '@/pages/settings/organization/data/context/CustomDataContext';
import { useSettings } from '@/pages/settings/organization/general/context/SettingsContext';
import { OptOutOptions } from '@/pages/settings/organization/general/context/types';
import { DownloadContacts, getContactList } from '@/shared/components/DownloadContacts';
import { Attachments } from '@/shared/components/editor/v2/constants';
import { CombinedFilters } from '@/shared/components/filterBuilder/CombinedFilters';
import { BulkTagModal } from '@/shared/components/filters/bulkAction/modals/BulkTagModal';
import { RemoveFromSequenceModal } from '@/shared/components/filters/bulkAction/modals/RemoveFromSequenceModal';
import { UnsubscribeContactsFromCampaignModal } from '@/shared/components/filters/bulkAction/modals/UnsubscribeContactsFromCampaignModal';
import { BulkAction, FilteredTable } from '@/shared/components/filters/FiltersTable';
import { prepareFilter } from '@/shared/components/filters/utils';
import { AddToSequenceDialog } from '@/shared/components/modals/AddToSequenceDialog/AddToSequenceDialog';
import { useDisclosure } from '@/shared/hooks';
import { PageLayout } from '@/shared/layouts/PageLayout';
import { ScheduleOptions } from '@/shared/types/campaigns';
import { Channel } from '@/shared/types/channels';
import { SearchFilters } from '@/shared/types/contacts';
import { FilterParams, FilterType, Sort } from '@/shared/types/filter';
import { SequenceBulkActionFilter } from '@/shared/types/sequences';
import { TagBulkActionType } from '@/shared/types/tags';
import {
  Box,
  Button,
  Dialog,
  DialogCloseIcon,
  DialogContent,
  DialogFooter,
  DialogOverlay,
  DialogPortal,
  DialogTitle,
  DialogTrigger,
  Fieldset,
  Flex,
  Input,
  Label,
} from '@/shared/ui';
import { TableActionMenu } from '@/shared/v2/components/table/TableActionMenu';

import { cleanFilters } from '../../../shared/components/filterBuilder/utils/cleanFilters';
import { initialState, useData } from '../context/DataContext';
import { applySort } from '../utils/applySort';
import { ContactActions } from '../utils/ContactActions';
import { ContactsTableColumns } from '../utils/ContactsTable';
import {
  handleFilterChange,
  handleInfiniteScroll,
  handleQuickSearch,
  handleSortChange,
} from '../utils/filterActions';
import { sortConfig } from '../utils/filterConfig';

export const defaultSort: Array<Sort> = [
  {
    label: 'Updated At',
    column: 'updated_at',
    order: 'desc',
    resource: 'contact',
    id: null,
  },
];

export const ITEMS_COUNT = 100;

export const Contacts = (): JSX.Element => {
  const contacts = useData();
  const [downloading, setDownloading] = useState(false);

  const { updateContactFilters } = contacts;
  const { settingsState } = useSettings();
  const { settings } = settingsState;

  const data = useMemo(() => {
    const sortField = contacts.dataState.contactsFilters.sort[0]?.column || 'updated_at';
    const sortOrder = contacts.dataState.contactsFilters.sort[0]?.order || 'desc';
    return applySort(contacts.dataState.contacts, sortField, sortOrder);
  }, [contacts.dataState.contacts]);

  const [quickSearchValue, setQuickSearchValue] = useState<string>('');
  const [activeFilters, setActiveFilters] = useState<FilterType[]>([]);

  // the state needed for Quick Campaign
  const location = useChannels();
  const { channelsState } = location;
  const { channels } = channelsState;
  const [currentLocation, setCurrentLocation] = useState<Channel | null>(null);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [selectedContactIds, setSelectedContactIds] = useState([] as string[]);
  const [totalContactsSelected, setTotalContactsSelected] = useState(0);
  const [isQuickCampaignOpen, setQuickCampaignOpen] = useState(false);
  const [isAddToSequenceModalOpen, setAddToSequenceModalOpen] = useState(false);
  const [isBulkTagModalOpen, setBulkTagModalOpen] = useState(false);
  const [isRemoveFromSequenceModalOpen, setRemoveFromSequenceModalOpen] = useState(false);
  const [isUnsubscribeContactsModalOpen, setUnsubscribeContactsModalOpen] =
    useState(false);
  const [tagModalAction, setTagModalAction] = useState<TagBulkActionType>('unassign');

  const custom = useCustomData();
  const { getCustomObjects, customDataState } = custom;
  const { customObjects } = customDataState;

  useEffect(() => {
    const fetchCustomObjects = async () => {
      await getCustomObjects({
        ...initialCustomDataState.filterParams,
        limit: ITEMS_COUNT,
        offset: 0,
      });
    };

    if (customObjects.length === 0) fetchCustomObjects();

    resetFilters();
  }, []);

  const resetFilters = () => {
    setQuickSearchValue('');
    setActiveFilters([]);
    updateContactFilters({
      ...initialState.contactsFilters,
      sort: defaultSort,
    });
  };

  const getContacts = async (isDownloadAll: boolean) => {
    const defaultParams = {
      filter: [],
      sort: defaultSort,
      offset: 0,
    };

    const params = isDownloadAll
      ? defaultParams
      : prepareFilters(contacts.dataState.contactsFilters);

    return getContactList(params.filter, params.sort, ITEMS_COUNT);
  };

  // Wrap fetchContacts in a useCallback to ensure the debounced function doesn't get recreated on every render
  const debouncedUpdate = useCallback(
    debounce((props: SearchFilters) => updateContactFilters(props), 1000),
    []
  );

  function handleConfirm(
    message: string,
    attachments: Attachments,
    location: Channel,
    scheduleOptions: ScheduleOptions | null
  ) {
    const filter = combineFilter(
      selectedContactIds,
      contacts.dataState.contactsFilters,
      isAllSelected
    );
    createQuickCampaign({
      message,
      attachments,
      location,
      scheduleOptions,
      filter,
    });
    setQuickCampaignOpen(false);
  }

  const tableRef = useRef<VirtuosoHandle>(null);
  const columns = ContactsTableColumns();
  const bulkActions: BulkAction[] = [
    {
      label: 'Add to Sequence',
      action: 'addToSequenceV2',
      title: 'Add to Sequence',
      getConfirmationMessage: (selected: number) =>
        `Are you sure you want to add **${selected} contact(s)** to an existing sequence?`,
      onManualSelectedSubmit: (selectedContactIds: string[]) => {
        setAddToSequenceModalOpen(true);
        setSelectedContactIds(selectedContactIds);
        setIsAllSelected(false);
        setTotalContactsSelected(selectedContactIds.length);
      },
      onAllSelectedSubmit: () => {
        setAddToSequenceModalOpen(true);
        setIsAllSelected(true);
        setTotalContactsSelected(contacts.dataState.totalCount);
      },
    },
    {
      action: 'createQuickCampaignV2',
      label: 'Create Campaign',
      title: 'Create Quick Campaign',
      getConfirmationMessage: (selected: number) =>
        `Are you sure you want to add **${selected} contact(s)** to a Quick Campaign?`,
      onAllSelectedSubmit: () => {
        setQuickCampaignOpen(true);
        setSelectedContactIds(selectedContactIds);
        setIsAllSelected(isAllSelected);
        setTotalContactsSelected(contacts.dataState.totalCount);
      },
      onManualSelectedSubmit(selectedContactIds: string[]) {
        setQuickCampaignOpen(true);
        setSelectedContactIds(selectedContactIds);
        setTotalContactsSelected(selectedContactIds.length);
      },
    },
    {
      label: 'Assign Tags',
      action: 'assignTagsV2',
      title: 'Assign Tags',
      getConfirmationMessage: (selected: number) => {
        setTagModalAction('assign');
        return `Are you sure you want to assign tags from **${selected} contact(s)**`;
      },
      onManualSelectedSubmit: (selectedContactIds: string[]) => {
        setBulkTagModalOpen(true);
        setSelectedContactIds(selectedContactIds);
        setIsAllSelected(false);
        setTotalContactsSelected(selectedContactIds.length);
      },
      onAllSelectedSubmit: () => {
        setBulkTagModalOpen(true);
        setIsAllSelected(true);
        setTotalContactsSelected(contacts.dataState.totalCount);
      },
    },
    {
      label: 'Overwrite Tags',
      action: 'overwriteTagsV2',
      title: 'Overwrite Tags',
      type: 'destructive',
      getConfirmationMessage: (selected: number) => {
        setTagModalAction('assign.overwrite');
        return `Are you sure you want to overwrite tags from **${selected} contact(s)**`;
      },
      onManualSelectedSubmit: (selectedContactIds: string[]) => {
        setBulkTagModalOpen(true);
        setSelectedContactIds(selectedContactIds);
        setIsAllSelected(false);
        setTotalContactsSelected(selectedContactIds.length);
      },
      onAllSelectedSubmit: () => {
        setBulkTagModalOpen(true);
        setIsAllSelected(true);
        setTotalContactsSelected(contacts.dataState.totalCount);
      },
    },
    {
      label: 'Un-assign Tags',
      action: 'unassignTagV2',
      title: 'Un-assign Tags',
      type: 'destructive',
      getConfirmationMessage: (selected: number) => {
        setTagModalAction('unassign');
        return `Are you sure you want to unassign tags from **${selected} contact(s)**`;
      },
      onManualSelectedSubmit: (selectedContactIds: string[]) => {
        setBulkTagModalOpen(true);
        setSelectedContactIds(selectedContactIds);
        setIsAllSelected(false);
        setTotalContactsSelected(selectedContactIds.length);
      },
      onAllSelectedSubmit: () => {
        setBulkTagModalOpen(true);
        setIsAllSelected(true);
        setTotalContactsSelected(contacts.dataState.totalCount);
      },
    },
    {
      label: 'Remove from Sequence',
      action: 'removeFromSequenceV2',
      title: 'Remove from Sequence',
      type: 'destructive',
      getConfirmationMessage: (selected: number) =>
        `Are you sure you want to remove **${selected} contact(s)** from an existing sequence`,
      onManualSelectedSubmit: (selectedContactIds: string[]) => {
        setRemoveFromSequenceModalOpen(true);
        setSelectedContactIds(selectedContactIds);
        setIsAllSelected(false);
        setTotalContactsSelected(selectedContactIds.length);
      },
      onAllSelectedSubmit: () => {
        setRemoveFromSequenceModalOpen(true);
        setIsAllSelected(true);
        setTotalContactsSelected(contacts.dataState.totalCount);
      },
    },
    {
      action: 'unsubscribeFromCampaignV2',
      label: 'Un-subscribe',
      title: 'Un-subscribe Contacts from Campaign',
      type: 'destructive',
      getConfirmationMessage: (selected: number) =>
        `Are you sure you want to unsubscribe **${selected} contact(s)** from an existing Campaign?`,
      onAllSelectedSubmit: () => {
        if (settings.contact_opt_out_default == OptOutOptions.LOCATION)
          setUnsubscribeContactsModalOpen(true);
        setIsAllSelected(true);
        setTotalContactsSelected(contacts.dataState.totalCount);
      },
      onManualSelectedSubmit(selectedContactIds: string[]) {
        if (settings.contact_opt_out_default == OptOutOptions.LOCATION)
          setUnsubscribeContactsModalOpen(true);
        setIsAllSelected(false);
        setSelectedContactIds(selectedContactIds);
        setTotalContactsSelected(selectedContactIds.length);
      },
    },
  ];

  const groups = useGroups();
  const { createGroup } = groups;

  const [segmentName, setSegmentName] = useState<string>('');

  const { isOpen, onOpen, onClose } = useDisclosure();
  const history = useHistory();

  const handleSaveSegment = async () => {
    try {
      const segment = await createGroup(
        segmentName,
        '#3333',
        { filter: cleanFilters(activeFilters) },
        'v2'
      );

      setSegmentName('');
      onClose();

      if (segment) {
        // Redirect to the segment page
        history.push(`/data/segments/${segment.id}`);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Helmet>
        <title>Whippy | All Contacts</title>
      </Helmet>
      <PageLayout
        breadcrumbs={[
          { title: 'Data', path: '/data/contacts' },
          { title: 'All Contacts', path: '/data/contacts' },
        ]}
        actions={
          <Flex align="center" gap={2}>
            <ContactActions>
              <div style={{ width: 35 }}>
                <TableActionMenu buttonSize={35} width={200}>
                  <div>
                    <DownloadContacts
                      isSubmenu={true}
                      getData={getContacts}
                      setDownloading={setDownloading}
                      fileName="contacts"
                      title={downloading ? 'Downloading Data...' : 'Download CSV'}
                    />
                    <></>
                  </div>
                </TableActionMenu>
              </div>
            </ContactActions>
          </Flex>
        }
      >
        <Box css={{ backgroundColor: 'white', height: '100%', overflowY: 'hidden' }}>
          <Box css={{ padding: 24 }}>
            <CombinedFilters
              quickSearchPlaceholder="Search Contacts"
              quickSearchValue={quickSearchValue}
              setQuickSearchValue={(value: string) => {
                setQuickSearchValue(value);
                handleQuickSearch(
                  debouncedUpdate,
                  contacts.dataState.contactsFilters,
                  value
                );
              }}
              customObjects={customObjects}
              activeFilters={activeFilters}
              setFilters={(value: Array<FilterType>) => {
                setActiveFilters(value);
                handleFilterChange(
                  debouncedUpdate,
                  contacts.dataState.contactsFilters,
                  value
                );
              }}
              sortConfig={sortConfig}
              activeSort={contacts.dataState.contactsFilters.sort}
              onSortUpdate={(value: Array<Sort>) =>
                handleSortChange(
                  updateContactFilters,
                  contacts.dataState.contactsFilters,
                  value
                )
              }
            >
              <Dialog open={isOpen}>
                <DialogTrigger asChild>
                  <Button
                    variant="gray"
                    size="2"
                    onClick={onOpen}
                    disabled={contacts.dataState.contactsFilters.filter.length === 0}
                  >
                    Save Segment
                  </Button>
                </DialogTrigger>
                <DialogPortal>
                  <DialogOverlay />
                  <DialogContent css={{ overflow: 'auto' }}>
                    <DialogTitle variant="bold">Create New Segment</DialogTitle>
                    <Fieldset>
                      <Label>Segment Name</Label>
                      <Input
                        placeholder="Enter segment name"
                        minLength={1}
                        value={segmentName}
                        onChange={(e: {
                          target: { value: React.SetStateAction<string> };
                        }) => setSegmentName(e.target.value)}
                      />
                    </Fieldset>
                    <DialogFooter>
                      <Flex css={{ width: '100%' }} justify="end">
                        <Button variant="gray" css={{ mr: 5 }} onClick={onClose}>
                          Cancel
                        </Button>
                        <Button
                          size="2"
                          onClick={handleSaveSegment}
                          disabled={
                            contacts.dataState.contactsFilters.filter.length === 0 ||
                            segmentName.length < 2
                          }
                        >
                          Save Segment
                        </Button>
                      </Flex>
                    </DialogFooter>
                    <DialogCloseIcon size="2" onClick={onClose}>
                      <HiX size="15px" />
                    </DialogCloseIcon>
                  </DialogContent>
                </DialogPortal>
              </Dialog>
            </CombinedFilters>
          </Box>
          <Box>
            <FilteredTable
              columns={columns}
              data={data}
              bulkActions={bulkActions}
              isLoading={contacts.dataState.loading}
              totalCount={contacts.dataState.totalCount}
              onEndReached={() =>
                handleInfiniteScroll(
                  updateContactFilters,
                  contacts.dataState.contactsFilters,
                  data,
                  contacts.dataState.totalCount,
                  contacts.dataState.loadingMore
                )
              }
              emptyStateElement={<Box>No records found</Box>}
              tableRef={tableRef}
              showTotalCount
            />
          </Box>
        </Box>
      </PageLayout>
      {isQuickCampaignOpen && (
        <QuickCampaignDialog
          currentLocation={currentLocation}
          setLocation={setCurrentLocation}
          locations={channels}
          isOpen={isQuickCampaignOpen}
          showTimeZonePicker={false}
          setIsOpen={setQuickCampaignOpen}
          handleConfirm={handleConfirm}
          totalContacts={totalContactsSelected}
        />
      )}
      <AddToSequenceDialog
        isOpen={isAddToSequenceModalOpen}
        showTimeZonePicker={false}
        locations={channels}
        totalContacts={totalContactsSelected}
        setIsOpen={setAddToSequenceModalOpen}
        filter={
          combineFilter(
            selectedContactIds,
            contacts.dataState.contactsFilters,
            isAllSelected
          ) as SequenceBulkActionFilter
        }
      />
      <BulkTagModal
        action={tagModalAction}
        open={isBulkTagModalOpen}
        totalContacts={totalContactsSelected}
        onOpenChange={setBulkTagModalOpen}
        filter={
          combineFilter(
            selectedContactIds,
            contacts.dataState.contactsFilters,
            isAllSelected
          ).filter ?? []
        }
      />
      <RemoveFromSequenceModal
        open={isRemoveFromSequenceModalOpen}
        totalContacts={totalContactsSelected}
        onOpenChange={setRemoveFromSequenceModalOpen}
        filter={
          combineFilter(
            selectedContactIds,
            contacts.dataState.contactsFilters,
            isAllSelected
          ).filter ?? []
        }
      />
      <UnsubscribeContactsFromCampaignModal
        onOpenChange={setUnsubscribeContactsModalOpen}
        open={isUnsubscribeContactsModalOpen}
        totalContacts={totalContactsSelected}
        filter={
          combineFilter(
            selectedContactIds,
            contacts.dataState.contactsFilters,
            isAllSelected
          ).filter ?? []
        }
      />
    </>
  );
};

function combineFilter(
  selectedContactIds: string[],
  searchParam: SearchFilters,
  isAllSelected: boolean
): FilterParams {
  const filter = prepareFilter(searchParam, selectedContactIds, isAllSelected, 'contact');
  return { filter: filter };
}
