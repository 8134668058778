export const listOfUseCases = [
  {
    type: 'Two-Factor authentication',
    value: '2FA',
  },
  {
    type: 'Account Notification',
    value: 'ACCOUNT_NOTIFICATION',
  },
  {
    type: 'Agents and Franchises',
    value: 'AGENTS_FRANCHISES',
  },
  {
    type: 'Charity',
    value: 'CHARITY',
  },
  {
    type: 'Proxy',
    value: 'PROXY',
  },
  {
    type: 'Customer Care',
    value: 'CUSTOMER_CARE',
  },
  {
    type: 'Delivery Notification',
    value: 'DELIVERY_NOTIFICATION',
  },
  {
    type: 'Emergency',
    value: 'EMERGENCY',
  },
  {
    type: 'Fraud alert',
    value: 'FRAUD_ALERT',
  },
  {
    type: 'Higher Education',
    value: 'HIGHER_EDUCATION',
  },
  {
    type: 'K-12 Education',
    value: 'K12_EDUCATION',
  },
  {
    type: 'Low Volume Mixed',
    value: 'LOW_VOLUME',
  },
  {
    type: 'Marketing',
    value: 'MARKETING',
  },
  {
    type: 'Mixed',
    value: 'MIXED',
  },
  {
    type: 'Political',
    value: 'POLITICAL',
  },
  {
    type: 'Polling and voting',
    value: 'POLLING_VOTING',
  },
  {
    type: 'Public Service Announcement',
    value: 'PUBLIC_SERVICE_ANNOUNCEMENT',
  },
  {
    type: 'Security Alert',
    value: 'SECURITY_ALERT',
  },
  {
    type: 'Social',
    value: 'SOCIAL',
  },
  {
    type: 'Sweepstake',
    value: 'SWEEPSTAKE',
  },
];

export const optinKeywords = [
  {
    type: 'START',
    value: 'START',
  },
  {
    type: 'BEGIN',
    value: 'BEGIN',
  },
];

export const optOutKeywords = [
  {
    type: 'STOP',
    value: 'STOP',
  },
  {
    type: 'STOPALL',
    value: 'STOPALL',
  },
  {
    type: 'UNSUBSCRIBE',
    value: 'UNSUBSCRIBE',
  },
  {
    type: 'CANCEL',
    value: 'CANCEL',
  },
  {
    type: 'END',
    value: 'END',
  },
  {
    type: 'QUIT',
    value: 'QUIT',
  },
];

export const helpKeywords = [
  {
    type: 'HELP',
    value: 'HELP',
  },
  {
    type: 'INFO',
    value: 'INFO',
  },
];
