import { AsYouType } from 'libphonenumber-js';

import useInputState from './useInputState';

export type Setter = (event: React.FormEvent | string) => void;

export function usePhoneNumberInputState(initialValue: string): [string, Setter] {
  const [input, setInput] = useInputState(initialValue, (value) => {
    if (input.endsWith(')') && value.length === input.length - 1) {
      value = value.slice(0, -1);
    }
    return new AsYouType('US').input(value) || value;
  });
  return [input, setInput];
}
