import { AsYouType } from 'libphonenumber-js';
import React from 'react';
import { ChangeEvent, useState } from 'react';
import { HiX } from 'react-icons/hi';

import { useAuth } from '@/auth/context/AuthProvider';
import { useDisclosure } from '@/shared/hooks';
import { Channel, ChannelTypes, ProviderTypes } from '@/shared/types/channels';
import {
  Button,
  DialogClose,
  DialogCloseIcon,
  DialogFooter,
  DialogTitle,
  DialogTrigger,
  Drawer,
  DrawerContent,
  Fieldset,
  Flex,
  HStack,
  Input,
  Label,
  RadioGroup,
  RadioGroupIndicator,
  RadioGroupRadio as Radio,
  VStack,
} from '@/shared/ui';
import { toE164 } from '@/shared/utils/validations/validations';

import { AddressInput } from './channel/AddressInput';
import { SelectProvider } from './channel/SelectProvider';
import { SelectType } from './channel/SelectType';
import { useChannels } from './context/ChannelContext';

type PointerDownOutsideEvent = CustomEvent<{
  originalEvent: PointerEvent;
}>;

export const AddChannelV1 = () => {
  const auth = useAuth();
  const widget_id = auth?.organizationInfo?.organization?.widget_settings?.id;

  const { addChannel } = useChannels();
  const [channel, setChannel] = useState<Partial<Channel>>({
    name: '',
    phone: '',
    address: '',
    google_place_id: '',
    type: ChannelTypes.PHONE,
    widget_setting_id: widget_id,
    provider: null,
    provider_auth: null,
  });
  const { name, phone, google_place_id } = channel;

  const { isOpen, onOpen, onClose } = useDisclosure();

  // auth type set state default or custom
  const [providerAuthType, setProviderAuthType] = useState('default');

  // set auth provider telnyx, twilio, vonage, bandwidth, mailgun
  const [provider, setProvider] = useState<ProviderTypes>(ProviderTypes.TELNYX);

  /* telnyx */

  // set Telnyx API key
  const [telnyxApiKey, setTelnyxApiKey] = useState('');
  const [telnyxPublicKey, setTelnyxPublicKey] = useState('');

  /* Sinch */

  // set Sinch APP ID
  const [sinchAppId, setSinchAppId] = useState('');
  // set Sinch PROJECT ID
  const [sinchProjectId, setSinchProjectId] = useState('');
  // set Sinch REGION
  const [sinchRegion, setSinchRegion] = useState('');
  // set Sinch USERNAME
  const [sinchUsername, setSinchUsername] = useState('');
  // set Sinch PASSWORD
  const [sinchPassword, setSinchPassword] = useState('');
  // set Sinch WEBHOOK SECRET
  const [sinchWebhookSecret, setSinchWebhookSecret] = useState('');

  /* twilio */

  // set Twilio Account SID
  const [twilioAccountSID, setTwilioAccountSID] = useState('');
  // set Twilio Auth Token
  const [twilioAuthToken, setTwilioAuthToken] = useState('');

  /* vonage */

  // set Vonage API key
  const [vonageApplicationId, setVonageApplicationId] = useState('');
  // set Vonage API secret
  const [vonagePrivateKey, setVonagePrivateKey] = useState('');

  /* Bandwidth */

  // set Bandwidth Username
  const [bandwidthUsername, setBandwidthUsername] = useState('');
  const [bandwidthUserPassword, setBandwidthUserPassword] = useState('');
  const [bandwidthAccountId, setBandwidthAccountId] = useState('');
  const [bandwidthApplicationId, setBandwidthApplicationId] = useState('');
  const [bandwidthSubAccountNumber, setBandwidthSubAccountNumber] = useState('');
  const [bandwidthLocationNumber, setBandwidthLocationNumber] = useState('');
  const [bandwidthCallbackID, setBandwidthCallbackID] = useState('');
  const [bandwidthCallbackPassword, setBandwidthCallbackPassword] = useState('');

  /* Mailgun */

  // set Mailgun Base Domain
  const [mailgunBaseDomain, setMailgunBaseDomain] = useState('');
  // set Mailgun API key
  const [mailgunApiKey, setMailgunApiKey] = useState('');
  // set Mailgun Webhook Signing Key
  const [mailgunWebhookSigningKey, setMailgunWebhookSigningKey] = useState('');

  // set channel type sms, email
  const [type, setType] = useState<ChannelTypes>(ChannelTypes.PHONE);

  const isCustomProvider = providerAuthType === 'custom';

  const onChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setChannel((channel) => ({
      ...channel,
      ...{ [e.target.name]: e.target.value },
    }));
  };

  const handleChangePhone = (e: ChangeEvent<HTMLInputElement>) => {
    if (
      channel.phone?.endsWith(')') &&
      e.target.value.length === channel.phone?.length - 1
    ) {
      e.target.value = e.target.value.slice(0, -1);
    }
    setChannel((channel) => ({
      ...channel,
      ...{ [e.target.name]: new AsYouType('US').input(e.target.value) },
    }));
  };

  const getCustomAuthObject = (provider: ProviderTypes) => {
    switch (provider) {
      case ProviderTypes.TWILIO:
        return {
          account_sid: twilioAccountSID,
          auth_token: twilioAuthToken,
        };
      case ProviderTypes.VONAGE:
        return {
          application_id: vonageApplicationId,
          private_key: vonagePrivateKey,
        };
      case ProviderTypes.TELNYX:
        return {
          api_key: telnyxApiKey,
          public_key: telnyxPublicKey,
        };
      case ProviderTypes.SINCH:
        return {
          app_id: sinchAppId,
          project_id: sinchProjectId,
          region: sinchRegion,
          username: sinchUsername,
          password: sinchPassword,
          webhook_secret: sinchWebhookSecret,
        };
      case ProviderTypes.BANDWIDTH:
        return {
          username: bandwidthUsername,
          user_password: bandwidthUserPassword,
          account_id: bandwidthAccountId,
          application_id: bandwidthApplicationId,
          sub_account_number: bandwidthSubAccountNumber,
          location_number: bandwidthLocationNumber,
          callback_id: bandwidthCallbackID,
          callback_password: bandwidthCallbackPassword,
        };
      case ProviderTypes.MAILGUN:
        return {
          base_domain: mailgunBaseDomain,
          api_key: mailgunApiKey,
          webhook_signing_key: mailgunWebhookSigningKey,
        };
      default:
        return {
          api_key: telnyxApiKey,
          public_key: telnyxPublicKey,
        };
    }
  };

  const onSubmit = () => {
    if (isCustomProvider) {
      addChannel({
        ...channel,
        provider: provider,
        provider_auth: getCustomAuthObject(provider),
        type: type,
        phone: toE164(channel.phone),
      });
    } else {
      addChannel({
        ...channel,
        provider: provider,
        type: type,
        phone: toE164(channel.phone),
      });
    }

    setChannel({
      name: '',
      phone: '',
      address: '',
      google_place_id: '',
      widget_setting_id: widget_id,
    });

    onClose();
  };

  // recursive function to handle pointer events on google iframe
  const handlePointerDownOutside = (e: PointerDownOutsideEvent) => {
    const isPointerOnIframe = (
      target: Node & ParentNode & { classList?: DOMTokenList }
    ) => {
      if (target.classList?.contains('pac-container')) {
        return true;
      }
      if (target.parentNode) {
        if (isPointerOnIframe(target.parentNode)) {
          return true;
        } else {
          return false;
        }
      }
    };

    if (
      !isPointerOnIframe(e.target as Node & ParentNode & { classList?: DOMTokenList })
    ) {
      onClose();
    }
  };

  return (
    <Drawer open={isOpen}>
      <DialogTrigger asChild>
        <Button onClick={onOpen} variant="outline">
          Add Channel (old)
        </Button>
      </DialogTrigger>
      <DrawerContent
        onEscapeKeyDown={onClose}
        onPointerDownOutside={(e: PointerDownOutsideEvent) => handlePointerDownOutside(e)}
        css={{ height: '100%' }}
      >
        <Flex
          direction="column"
          justify="between"
          css={{ height: '100%', overflow: 'auto', py: 30, px: 30 }}
        >
          <Flex direction="column" justify="between">
            <DialogTitle>New Channel</DialogTitle>
            <VStack gap={2}>
              <Fieldset>
                <Label>Name</Label>
                <Input
                  placeholder="Northridge"
                  name="name"
                  value={name}
                  onChange={(
                    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                  ) => onChange(e)}
                />
              </Fieldset>
              <Fieldset>
                <Label>Business Address</Label>
                <AddressInput
                  channel={channel}
                  setChannel={setChannel}
                  onChange={onChange}
                />
              </Fieldset>
              <Fieldset>
                <Label>Google Place ID</Label>
                <Input
                  placeholder="GhIJQWDl0CIeQUARxks3icF8U8A"
                  name="google_place_id"
                  value={google_place_id || ''}
                  onChange={(
                    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                  ) => onChange(e)}
                />
              </Fieldset>
              <Fieldset>
                <Label>Phone Number</Label>
                <Input
                  placeholder="(000) 000-0000"
                  name="phone"
                  value={phone}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleChangePhone(e)
                  }
                />
              </Fieldset>
              <SelectType type={type} setType={setType} />
              <SelectProvider provider={provider} setProvider={setProvider} />
              <Fieldset>
                <Label>Select Authentication Type</Label>
                <RadioGroup value={providerAuthType} onValueChange={setProviderAuthType}>
                  <Flex align="center" css={{ mt: 10 }}>
                    <Flex justify="center" align="center" css={{ mr: 10 }}>
                      <Radio value="default">
                        <RadioGroupIndicator />
                      </Radio>
                      <Label css={{ ml: 5, mb: 0 }}>Managed by Whippy</Label>
                    </Flex>
                    <HStack align="center">
                      <Radio value="custom">
                        <RadioGroupIndicator />
                      </Radio>
                      <Label css={{ ml: 5, mb: 0 }}>Custom</Label>
                    </HStack>
                  </Flex>
                </RadioGroup>
              </Fieldset>
              {isCustomProvider && provider === ProviderTypes.TELNYX && (
                <Fieldset>
                  <Label>Telnyx API Key</Label>
                  <Input
                    placeholder="Enter Telnyx API Key"
                    name="api_key"
                    value={telnyxApiKey}
                    onChange={(e: { target: { value: React.SetStateAction<string> } }) =>
                      setTelnyxApiKey(e.target.value)
                    }
                  />
                </Fieldset>
              )}
              {isCustomProvider && provider === ProviderTypes.TELNYX && (
                <Fieldset>
                  <Label>Telnyx Public Key</Label>
                  <Input
                    placeholder="Enter Telnyx Public Key"
                    name="public_key"
                    value={telnyxPublicKey}
                    onChange={(e: { target: { value: React.SetStateAction<string> } }) =>
                      setTelnyxPublicKey(e.target.value)
                    }
                  />
                </Fieldset>
              )}
              {isCustomProvider && provider === ProviderTypes.SINCH && (
                <Fieldset>
                  <Label htmlFor="app_id_sinch">Sinch App Id</Label>
                  <Input
                    id="app_id_sinch"
                    placeholder="Enter Sinch App Id"
                    name="app_id"
                    value={sinchAppId}
                    onChange={(e) => setSinchAppId(e.target.value)}
                  />
                </Fieldset>
              )}
              {isCustomProvider && provider === ProviderTypes.SINCH && (
                <Fieldset>
                  <Label htmlFor="project_id_sinch">Sinch Project Id</Label>
                  <Input
                    id="project_id_sinch"
                    placeholder="Enter Sinch Project Id"
                    name="project_id"
                    value={sinchProjectId}
                    onChange={(e) => setSinchProjectId(e.target.value)}
                  />
                </Fieldset>
              )}
              {isCustomProvider && provider === ProviderTypes.SINCH && (
                <Fieldset>
                  <Label htmlFor="region_sinch">Sinch Region</Label>
                  <Input
                    id="region_sinch"
                    placeholder="Enter Sinch Region"
                    name="region"
                    value={sinchRegion}
                    onChange={(e) => setSinchRegion(e.target.value)}
                  />
                </Fieldset>
              )}
              {isCustomProvider && provider === ProviderTypes.SINCH && (
                <Fieldset>
                  <Label htmlFor="username_sinch">Sinch Username</Label>
                  <Input
                    id="username_sinch"
                    placeholder="Enter Sinch Username"
                    name="username"
                    value={sinchUsername}
                    onChange={(e) => setSinchUsername(e.target.value)}
                  />
                </Fieldset>
              )}
              {isCustomProvider && provider === ProviderTypes.SINCH && (
                <Fieldset>
                  <Label htmlFor="password_sinch">Sinch Password</Label>
                  <Input
                    id="password_sinch"
                    placeholder="Enter Sinch Password"
                    name="password"
                    value={sinchPassword}
                    onChange={(e) => setSinchPassword(e.target.value)}
                  />
                </Fieldset>
              )}
              {isCustomProvider && provider === ProviderTypes.SINCH && (
                <Fieldset>
                  <Label htmlFor="webhook_secret_sinch">Sinch Webhook Secret</Label>
                  <Input
                    id="webhook_secret_sinch"
                    placeholder="Enter Sinch Webhook Secret"
                    name="webhook_secret"
                    value={sinchWebhookSecret}
                    onChange={(e) => setSinchWebhookSecret(e.target.value)}
                  />
                </Fieldset>
              )}
              {isCustomProvider && provider === ProviderTypes.TWILIO && (
                <Fieldset>
                  <Label>Twilio Account SID</Label>
                  <Input
                    placeholder="Enter Twilio Account SID"
                    name="account_sid"
                    value={twilioAccountSID}
                    onChange={(e: { target: { value: React.SetStateAction<string> } }) =>
                      setTwilioAccountSID(e.target.value)
                    }
                  />
                </Fieldset>
              )}
              {isCustomProvider && provider === ProviderTypes.TWILIO && (
                <Fieldset>
                  <Label>Twilio Auth Token</Label>
                  <Input
                    placeholder="Enter Twilio Auth Token"
                    name="auth?.tokens"
                    value={twilioAuthToken}
                    onChange={(e: { target: { value: React.SetStateAction<string> } }) =>
                      setTwilioAuthToken(e.target.value)
                    }
                  />
                </Fieldset>
              )}
              {isCustomProvider && provider === ProviderTypes.VONAGE && (
                <Fieldset>
                  <Label>Vonage Application ID</Label>
                  <Input
                    placeholder="Enter Vonage Application ID"
                    name="api_key"
                    value={vonageApplicationId}
                    onChange={(e: { target: { value: React.SetStateAction<string> } }) =>
                      setVonageApplicationId(e.target.value)
                    }
                  />
                </Fieldset>
              )}
              {isCustomProvider && provider === ProviderTypes.VONAGE && (
                <Fieldset>
                  <Label>Vonage Private Key</Label>
                  <Input
                    placeholder="Enter Vonage Private Key"
                    name="api_secret"
                    value={vonagePrivateKey}
                    onChange={(e: { target: { value: React.SetStateAction<string> } }) =>
                      setVonagePrivateKey(e.target.value)
                    }
                  />
                </Fieldset>
              )}
              {isCustomProvider && provider === ProviderTypes.BANDWIDTH && (
                <Fieldset>
                  <Label>Bandwidth Username</Label>
                  <Input
                    placeholder="Enter Bandwidth Username"
                    name="username"
                    value={bandwidthUsername}
                    onChange={(e: { target: { value: React.SetStateAction<string> } }) =>
                      setBandwidthUsername(e.target.value)
                    }
                  />
                </Fieldset>
              )}
              {isCustomProvider && provider === ProviderTypes.BANDWIDTH && (
                <Fieldset>
                  <Label>Bandwidth Password</Label>
                  <Input
                    placeholder="Enter Bandwidth Password"
                    name="user_password"
                    value={bandwidthUserPassword}
                    onChange={(e: { target: { value: React.SetStateAction<string> } }) =>
                      setBandwidthUserPassword(e.target.value)
                    }
                  />
                </Fieldset>
              )}
              {isCustomProvider && provider === ProviderTypes.BANDWIDTH && (
                <Fieldset>
                  <Label>Bandwidth Account Id</Label>
                  <Input
                    placeholder="Enter Bandwidth Account Id"
                    name="account_id"
                    value={bandwidthAccountId}
                    onChange={(e: { target: { value: React.SetStateAction<string> } }) =>
                      setBandwidthAccountId(e.target.value)
                    }
                  />
                </Fieldset>
              )}
              {isCustomProvider && provider === ProviderTypes.BANDWIDTH && (
                <Fieldset>
                  <Label>Bandwidth Application Id</Label>
                  <Input
                    placeholder="Enter Bandwidth Application Id"
                    name="application_id"
                    value={bandwidthApplicationId}
                    onChange={(e: { target: { value: React.SetStateAction<string> } }) =>
                      setBandwidthApplicationId(e.target.value)
                    }
                  />
                </Fieldset>
              )}
              {isCustomProvider && provider === ProviderTypes.BANDWIDTH && (
                <Fieldset>
                  <Label>Bandwidth Sub-Account Number</Label>
                  <Input
                    placeholder="Enter Bandwidth Sub-Account Number"
                    name="sub_account_number"
                    value={bandwidthSubAccountNumber}
                    onChange={(e: { target: { value: React.SetStateAction<string> } }) =>
                      setBandwidthSubAccountNumber(e.target.value)
                    }
                  />
                </Fieldset>
              )}
              {isCustomProvider && provider === ProviderTypes.BANDWIDTH && (
                <Fieldset>
                  <Label>Bandwidth Channel Number</Label>
                  <Input
                    placeholder="Enter Bandwidth Channel Number"
                    name="location_number"
                    value={bandwidthLocationNumber}
                    onChange={(e: { target: { value: React.SetStateAction<string> } }) =>
                      setBandwidthLocationNumber(e.target.value)
                    }
                  />
                </Fieldset>
              )}
              {isCustomProvider && provider === ProviderTypes.BANDWIDTH && (
                <Fieldset>
                  <Label>Bandwidth Callback ID</Label>
                  <Input
                    placeholder="Enter Bandwidth Callback ID"
                    name="callback_id"
                    value={bandwidthCallbackID}
                    onChange={(e: { target: { value: React.SetStateAction<string> } }) =>
                      setBandwidthCallbackID(e.target.value)
                    }
                  />
                </Fieldset>
              )}
              {isCustomProvider && provider === ProviderTypes.BANDWIDTH && (
                <Fieldset>
                  <Label>Bandwidth Callback Password</Label>
                  <Input
                    placeholder="Enter Bandwidth Callback Password"
                    name="callback_password"
                    value={bandwidthCallbackPassword}
                    onChange={(e: { target: { value: React.SetStateAction<string> } }) =>
                      setBandwidthCallbackPassword(e.target.value)
                    }
                  />
                </Fieldset>
              )}
              {isCustomProvider && provider === ProviderTypes.MAILGUN && (
                <Fieldset>
                  <Label>Mailgun Base Domain</Label>
                  <Input
                    placeholder="Enter Mailgun Base Domain"
                    name="base_domain"
                    value={mailgunBaseDomain}
                    onChange={(e: { target: { value: React.SetStateAction<string> } }) =>
                      setMailgunBaseDomain(e.target.value)
                    }
                  />
                </Fieldset>
              )}
              {isCustomProvider && provider === ProviderTypes.MAILGUN && (
                <Fieldset>
                  <Label>Mailgun API Key</Label>
                  <Input
                    placeholder="Enter Mailgun API Key"
                    name="api_key"
                    value={mailgunApiKey}
                    onChange={(e: { target: { value: React.SetStateAction<string> } }) =>
                      setMailgunApiKey(e.target.value)
                    }
                  />
                </Fieldset>
              )}
              {isCustomProvider && provider === ProviderTypes.MAILGUN && (
                <Fieldset>
                  <Label>Mailgun Webhook Signing Key</Label>
                  <Input
                    placeholder="Enter Mailgun Webhook Signing Key"
                    name="webhook_signing_key"
                    value={mailgunWebhookSigningKey}
                    onChange={(e: { target: { value: React.SetStateAction<string> } }) =>
                      setMailgunWebhookSigningKey(e.target.value)
                    }
                  />
                </Fieldset>
              )}
            </VStack>
          </Flex>
          <DialogFooter justify="end">
            <DialogClose asChild>
              <Button variant="gray" css={{ mr: '$1' }} onClick={onClose}>
                Cancel
              </Button>
            </DialogClose>
            <DialogClose asChild>
              <Button onClick={onSubmit}>Save</Button>
            </DialogClose>
          </DialogFooter>
        </Flex>
        <DialogCloseIcon onClick={onClose}>
          <HiX size="15px" />
        </DialogCloseIcon>
      </DrawerContent>
    </Drawer>
  );
};
