import { debounce, DebouncedFunc } from 'lodash';
import { useCallback, useEffect, useState } from 'react';

import { handleFilterChange, handleSortChange } from '@/pages/data/utils/filterActions';
import { CombinedFilters } from '@/shared/components/filterBuilder/CombinedFilters';
import { default_custom_data_object } from '@/shared/components/filterBuilder/objects/custom_data';
import { SearchFilters } from '@/shared/types/contacts';
import { FilterType, Sort } from '@/shared/types/filter';
import { Box, Flex } from '@/shared/ui';

import { initialCustomDataState, useCustomData } from './context/CustomDataContext';
import DataTable from './DataTable';
import { sortConfig } from './filterConfig';

const defaultSort: Array<Sort> = [
  {
    label: 'Created At',
    resource: 'custom_object',
    column: 'inserted_at',
    order: 'desc',
    id: null,
  },
];

export const DataView = () => {
  const [searchValue, setSearchValue] = useState('');
  const [activeFilters, setActiveFilters] = useState<FilterType[]>([]);

  const {
    updateFilterParams,
    customDataState: { filterParams },
  } = useCustomData();

  useEffect(() => {
    updateFilterParams({
      ...initialCustomDataState.filterParams,
      sort: defaultSort,
    });
  }, []);

  const debouncedUpdate = useCallback(
    debounce((props: SearchFilters) => {
      updateFilterParams(props);
    }, 1000),
    []
  );

  const handleQuickSearch = (
    debouncedUpdate: DebouncedFunc<(props: SearchFilters) => void>,
    filters: SearchFilters,
    value: string
  ) => {
    const formattedValue = value.trim();

    const quickFilters = [
      {
        column: 'label',
        comparison: 'ilike',
        resource: 'custom_object',
        value: `%${formattedValue}%`,
      },
    ];
    debouncedUpdate({
      ...filters,
      searchFilter: quickFilters,
      offset: 0,
    });
  };

  return (
    <Flex direction="column" css={{ flex: '1 1 auto' }}>
      <Box css={{ paddingBottom: 24 }}>
        <CombinedFilters
          quickSearchPlaceholder="Search Custom Objects"
          quickSearchValue={searchValue}
          setQuickSearchValue={(value) => {
            setSearchValue(value);
            handleQuickSearch(debouncedUpdate, filterParams, value);
          }}
          defaultObjects={[default_custom_data_object]}
          customObjects={[]}
          activeFilters={activeFilters}
          setFilters={(value) => {
            setActiveFilters(value);
            handleFilterChange(debouncedUpdate, filterParams, value);
          }}
          sortConfig={sortConfig}
          activeSort={filterParams.sort}
          onSortUpdate={(value) =>
            handleSortChange(updateFilterParams, filterParams, value)
          }
        />
      </Box>
      <DataTable />
    </Flex>
  );
};
