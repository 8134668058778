export const default_users_object = {
  id: 'unique-id-for-contact',
  label: 'Users',
  key: 'user',
  inserted_at: 'current-timestamp',
  updated_at: 'current-timestamp',
  custom_properties: [
    {
      default: '',
      id: 'user-name',
      label: 'Name',
      type: 'text',
      key: 'name',
      required: true,
      inserted_at: 'current-timestamp',
      updated_at: 'current-timestamp',
      custom_object_id: 'unique-id-for-segments',
    },
    {
      default: '',
      id: 'user-email',
      label: 'Email',
      type: 'text',
      key: 'email',
      required: true,
      inserted_at: 'current-timestamp',
      updated_at: 'current-timestamp',
      custom_object_id: 'unique-id-for-segments',
    },
  ],
};
