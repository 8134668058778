import { ContactParams, UploadsType } from '../../../types/contacts/uploads';
import { Tag } from '../../../types/tags';
import { api } from '../../api';

export const getUploads = (params?: {
  offset: number;
  limit: number;
}): Promise<UploadsType | Array<Tag>> =>
  api.get(`/contacts/uploads`, { params }).then((res) => {
    return res.data.data;
  });

export const getUploadsV2 = (
  params?: any
): Promise<{ data: Array<Tag>; total: number }> =>
  api.post(`/v2/contacts/lists/search`, { ...params }).then((res) => {
    return res.data.data;
  });

export const searchUpload = (name: string): Promise<Array<Tag>> =>
  api.post(`/contacts/uploads/search`, { name }).then((res) => {
    return res.data.data;
  });

export const getUpload = (id: string): Promise<Tag | null> =>
  api.get<{ data: Tag }>(`/contacts/uploads/${id}`).then((res) => {
    return res.data.data;
  });

export const updateUpload = (upload: Partial<Tag>): Promise<Tag> =>
  api.put(`/contacts/uploads/${upload.id}`, { tag: { ...upload } }).then((res) => {
    return res.data.data;
  });

export const deleteUpload = (uploadId: string): Promise<null> =>
  api.delete(`/contacts/uploads/${uploadId}`).then((res) => {
    return res.data.data;
  });

export const importContacts = (
  name: string,
  color: string,
  contactsParams: Array<ContactParams>,
  locations: Array<string>
): Promise<Tag> =>
  api
    .post('/contacts/uploads', {
      name: name,
      color: color,
      contacts: contactsParams,
      location_ids: locations,
    })
    .then((res) => {
      return res.data.data;
    });

export const smallImportContacts = (
  name: string,
  color: string,
  contactsParams: Array<ContactParams>,
  locations: Array<string>
): Promise<Tag> =>
  api
    .post('/contacts/uploads/small', {
      name: name,
      color: color,
      contacts: contactsParams,
      location_ids: locations,
    })
    .then((res) => {
      return res.data.data;
    });

/*
  POST - bulk opt in contact via Import
*/

export const bulkOptInImport = (
  name: string,
  color: string,
  contacts: Array<ContactParams>,
  locations: Array<string>,
  target?: 'opt_in' | 'bulk_opt_in'
): Promise<Tag> => {
  const formData = new FormData();
  const blob = new Blob([JSON.stringify(contacts, null, 2)], {
    type: 'application/json',
  });

  formData.append('name', name);
  formData.append('color', color);
  formData.append('location_ids', JSON.stringify(locations));
  formData.append('target', target || 'opt_in');
  formData.append('upload', blob);

  return api
    .post(`/contacts/uploads`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then((res) => {
      return res.data.data;
    });
};

/*
  POST - bulk opt out contact via Import
*/

export const bulkOptOutImport = (contacts: Array<ContactParams>): Promise<null> => {
  const formData = new FormData();
  const blob = new Blob([JSON.stringify(contacts, null, 2)], {
    type: 'application/json',
  });
  formData.append('upload', blob);

  return api
    .post(`/contacts/optouts`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then((res) => {
      return res.data.data;
    });
};
