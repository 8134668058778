import { TooltipArrow, TooltipTrigger } from '@radix-ui/react-tooltip';
import { ErrorBoundary } from '@sentry/react';
import i18next from 'i18next';
import { HiStatusOffline } from 'react-icons/hi';
import { toast } from 'sonner';

import { IconButton, Tooltip, TooltipContent } from '@/shared/ui';

import { ICON_SIZE } from '.';

// This is a fallback component for the Message Toolbar
// If any of the features become unavailable or throw an error, this fallback
// component will be rendered. This decouples each small feature from the entire
// Toolbar which means only the affected features will become unavailable.
const ToolbarErrorFallback = () => {
  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <IconButton size={2} type="button">
          <HiStatusOffline size={ICON_SIZE} color="red" title="Feature not available" />
        </IconButton>
      </TooltipTrigger>
      <TooltipContent side="top">
        {'Feature not available'}
        <TooltipArrow />
      </TooltipContent>
    </Tooltip>
  );
};

// The Toolbar Error Boundary is a wrapper for the Message Toolbar
// It handles the fallback component and sending an error to Sentry
type ErrorBoundaryProps = {
  feature: string;
  children: React.ReactNode;
};
export const ToolbarErrorBoundary: React.FC<ErrorBoundaryProps> = ({
  feature,
  children,
}) => {
  if (!feature || feature.length < 1) return null;
  if (!children) return null;

  const localizationError = `${feature}_error`;
  const errorTag = `messageToolbar.${feature}`;
  return (
    <ErrorBoundary
      fallback={<ToolbarErrorFallback />}
      beforeCapture={(scope) => {
        toast.error((i18next.t(localizationError) as string) || 'Feature not available.');
        scope.setTag('ErrorLocation', errorTag);
      }}
      showDialog={false}
    >
      {children}
    </ErrorBoundary>
  );
};

export const EditorErrorBoundary: React.FC<ErrorBoundaryProps> = ({
  feature,
  children,
}) => {
  if (!feature || feature.length < 1) return null;
  if (!children) return null;

  const localizationError = `${feature}_error`;
  const errorTag = `messageEditor.${feature}`;
  return (
    <ErrorBoundary
      beforeCapture={(scope) => {
        toast.error((i18next.t(localizationError) as string) || 'Feature not available.');
        scope.setTag('ErrorLocation', errorTag);
      }}
      showDialog={false}
    >
      {children}
    </ErrorBoundary>
  );
};
