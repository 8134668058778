import { User } from '@sentry/react';

import { Organization } from '../..';

/**
 * Enum for providers of LLMs.
 */
export enum Provider {
  Retell = 'retell',
}

/**
 * Enum for models supported by LLMs.
 */
export enum Model {
  Gpt4o = 'gpt-4o',
  Gpt4oMini = 'gpt-4o-mini',
  Claude35Sonnet = 'claude-3.5-sonnet',
  Claude3Haiku = 'claude-3-haiku',
}

/**
 * Enum for the status of an LLM.
 */
enum Status {
  Active = 'active',
  Archived = 'archived',
  Deleted = 'deleted',
}

/**
 * Interface for settings specific to each provider.
 */
interface ProviderSettings {
  /** Optional initial message for the provider. */
  begin_message?: string;
  /** Optional WebSocket URL for the provider. */
  websocket_url?: string;
}

/**
 * Type definition for a Large Language Model (LLM).
 */
export type LLM = {
  /** Unique identifier for the LLM. */
  id: string;
  /** Current status of the LLM. */
  status: Status;
  /** ID of the current version of the LLM. */
  current_version_id: string;
  /** Current active version of the LLM. */
  current_version: LLMVersion;
  /** List of all versions of the LLM. */
  versions: LLMVersion[];
  /** ID of the organization owning the LLM. */
  organization_id: string;
  /** Organization owning the LLM. */
  organization: Organization;
  /** ID of the user who created the LLM. */
  created_by_id: number;
  /** User who created the LLM. */
  created_by: User;
  /** ID of the user who last updated the LLM. */
  updated_by_id: number;
  /** User who last updated the LLM. */
  updated_by: User;
  /** ID of the user who deleted the LLM, if applicable. */
  deleted_by_id: number | null;
  /** User who deleted the LLM, if applicable. */
  deleted_by: User | null;
  /** Timestamp when the LLM was created. */
  inserted_at: Date;
  /** Timestamp when the LLM was last updated. */
  updated_at: Date;
  /** Timestamp when the LLM was deleted, if applicable. */
  deleted_at: Date | null;
};

/**
 * Type definition for a version of a Large Language Model (LLM).
 */
export type LLMVersion = {
  /** Unique identifier for the version. */
  id: string;
  /** Optional description of the version. */
  description?: string;
  /** Optional model used in this version. */
  model?: Model;
  /** Prompt or input used for this version. */
  prompt: string;
  /** Provider of the LLM service. */
  provider: Provider;
  /** Optional specific identifier for the provider. */
  provider_id?: string;
  /** Optional settings specific to the provider. */
  provider_settings?: ProviderSettings;
  /** ID of the LLM this version belongs to. */
  llm_id: string;
  /** Reference to the LLM this version belongs to. */
  llm: LLM;
  /** ID of the organization owning this version. */
  organization_id: string;
  /** Organization owning this version. */
  organization: Organization;
  /** ID of the user who created this version. */
  created_by_id: number;
  /** User who created this version. */
  created_by: User;
  /** Timestamp when this version was created. */
  inserted_at: Date;
};

export type LLMParams = {
  llm: {
    description: string;
    model: Model;
    prompt: string;
    provider: Provider;
    provider_settings: ProviderSettings;
  };
};
