import { styled } from '@/stitches.config';

export const Table = styled('table', {
  boxSizing: 'border-box',
  fontSize: '14px',
  color: '#1C2024',
  width: '100%',
});

export const Thead = styled('thead', {
  boxSizing: 'border-box',
});

export const Tbody = styled('tbody', {
  boxSizing: 'border-box',
});

export const Tfooter = styled('tfoot', {
  boxSizing: 'border-box',
});

export const Th = styled('th', {
  boxSizing: 'border-box',
  position: 'relative',
  whiteSpace: 'nowrap',
  padding: '14px 16px',
  color: '#1C2024',
  fontSize: '14px',
  fontWeight: 500,
  textAlign: 'left',

  variants: {
    ellipsis: {
      true: {
        textOverflow: 'ellipsis',
        overflowX: 'hidden',

        '& *': {
          textOverflow: 'ellipsis',
          overflowX: 'hidden',
        },
      },
    },
  },
});

export const Tr = styled('tr', {
  boxSizing: 'border-box',
  borderBottom: '1px solid #02023414',
  variants: {
    variant: {
      header: {
        background: 'linear-gradient(0deg, rgba(5, 5, 88, 0.024), rgba(5, 5, 88, 0.024))',
      },
      expanded: {
        borderBottom: 'none',
      },
    },
  },
});

export const Td = styled('td', {
  boxSizing: 'border-box',
  padding: '12px 16px !important',

  variants: {
    ellipsis: {
      true: {
        textOverflow: 'ellipsis',
        overflowX: 'hidden',

        '& *': {
          textOverflow: 'ellipsis',
          overflowX: 'hidden',
        },
      },
    },
    variant: {
      search: {
        padding: '0 !important',
      },
    },
  },
});

export const Caption = styled('caption', {
  border: 0,
  padding: 0,
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(100%)',
  height: 1,
  width: 1,
  margin: -1,
  overflow: 'hidden',
  position: 'absolute',
  appearance: 'none',
  whiteSpace: 'nowrap',
  wordWrap: 'normal',
});
