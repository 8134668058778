import React from 'react';

import { usePageView } from '@/shared/hooks';
import { PageLayout } from '@/shared/layouts/PageLayout';
import { Box, HStack } from '@/shared/ui';

import DeveloperProvider from '../context/DevelopersContext';

export function Events() {
  usePageView();

  return (
    <DeveloperProvider>
      <PageLayout
        breadcrumbs={[
          { title: 'Developer', path: '/developer/keys' },
          { title: 'Events', path: '/developer/events' },
        ]}
        actions={<HStack gap="2"></HStack>}
      >
        <Box />
      </PageLayout>
    </DeveloperProvider>
  );
}
