export function parseName(input: string) {
  const fullName = input.trim() || '';
  const result = { firstName: '', lastName: '' };

  if (fullName.length > 0) {
    const generateNameTokens = () => {
      let tokens = fullName.split(' ');
      if (tokens.length > 1) {
        tokens = [tokens[0], tokens.slice(1).join(' ')];
      }
      return tokens;
    };

    result.firstName = generateNameTokens()[0] || '';
    result.lastName = generateNameTokens()[1] || '';
  }

  return result;
}

export const generateNameTokens = (fullName: string) => {
  let tokens = fullName.split(' ');
  if (tokens.length > 1) {
    tokens = [tokens[0], tokens.slice(1).join(' ')];
  }
  return tokens;
};
