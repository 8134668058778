import { User } from '../types/users';
import { initials } from '../utils/initials/initials';
import { Avatar } from './Avatar';
import { Box } from './Box';
import { HStack } from './Stack';
import { Tooltip, TooltipArrow, TooltipContent, TooltipTrigger } from './Tooltip';

export const TooltipAvatar = ({ user }: { user: Partial<User> }) => (
  <HStack gap={2} align="center" css={{ justifyContent: 'center' }}>
    <Tooltip>
      <TooltipTrigger>
        <Avatar
          variant="pink"
          size="newTable"
          src={user.attachment?.url}
          fallback={initials(user.name || user.email)}
        />
      </TooltipTrigger>
      <TooltipContent>
        <Box css={{ textAlign: 'center', fontSize: 12 }}>
          <Box css={{ mb: 8 }}>{user.name}</Box>
          <Box>{user.email}</Box>
        </Box>
        <TooltipArrow />
      </TooltipContent>
    </Tooltip>
  </HStack>
);
