/* eslint-disable react-hooks/exhaustive-deps */
import { Formik } from 'formik';
import i18next from 'i18next';
import { useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import { HiCog, HiTrash, HiX } from 'react-icons/hi';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'sonner';
import * as Yup from 'yup';

import {
  createAutomationTemplateTrigger,
  deleteAutomationTemplateTrigger,
  updateAutomationTemplateTrigger,
} from '@/shared/api/automations/templates';
import { ConfirmationDialog } from '@/shared/components/ConfirmationDialog';
import { FormFieldWrapper, TextInput } from '@/shared/components/forms';
import { FormSelect } from '@/shared/components/forms/Select';
import {
  AddTrigger,
  LinkClickTriggerType,
  ReplyTriggerType,
} from '@/shared/components/triggers/AddTrigger';
import { KeywordAutomations } from '@/shared/components/triggers/KeywordAutomations';
import { LinkClickAutomations } from '@/shared/components/triggers/LinkClickAutomations';
import { ReplyAutomations } from '@/shared/components/triggers/ReplyAutomations';
import { useDisclosure, usePageView } from '@/shared/hooks';
import { PageLayout } from '@/shared/layouts/PageLayout';
import { TemplateAccessLevel, TemplateUsage } from '@/shared/types/automations';
import { Trigger, TriggerTypes } from '@/shared/types/triggers';
import {
  Box,
  Button,
  Dialog,
  DialogClose,
  DialogCloseIcon,
  DialogContent,
  DialogFooter,
  DialogOverlay,
  DialogPortal,
  DialogTitle,
  DialogTrigger,
  HStack,
  IconButton,
  VStack,
} from '@/shared/ui';
import { isValidUuid } from '@/shared/utils/validations/validations';

import { useAutomationTemplates } from './context/AutomationsTemplatesContext';

export const AutomationTemplate = () => {
  usePageView();

  const history = useHistory();
  const automationTemplatesContext = useAutomationTemplates();
  const {
    getAutomationTemplate,
    automationTemplatesState,
    setCurrentAutomationTemplate,
    deleteAutomationTemplate,
  } = automationTemplatesContext;
  const { current } = automationTemplatesState;

  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    if (!current && isValidUuid(id)) {
      getAutomationTemplate(id);
    }

    // on unmount set current to null
    return () => {
      setCurrentAutomationTemplate(null);
    };
  }, []);

  const createTrigger = async (trigger: Trigger) => {
    if (current && current?.id) {
      await createAutomationTemplateTrigger(current?.id, trigger)
        .then((res) => {
          if (current && current?.triggers) {
            setCurrentAutomationTemplate({
              ...current,
              triggers: [...(current?.triggers || {}), res],
            });
            toast.success(
              i18next.t('automation_template_trigger_created_success') as string
            );
          }
        })
        .catch((err) => {
          toast.error(i18next.t('automation_template_trigger_created_failure') as string);
          console.error(err);
        });
    }
  };

  const updateTrigger = async (trigger_id: string, trigger_param: Trigger) => {
    if (current && current?.id && trigger_param.id) {
      await updateAutomationTemplateTrigger(current?.id, trigger_param)
        .then((res) => {
          if (current && current?.triggers) {
            const updatedTriggers = current?.triggers?.map((trigger) => {
              if (trigger.id === trigger_id) {
                return res;
              }
              return trigger;
            });
            setCurrentAutomationTemplate({
              ...current,
              triggers: updatedTriggers,
            });
            toast.success(
              i18next.t('automation_template_trigger_updated_success') as string
            );
          }
        })
        .catch((err) => {
          toast.error(i18next.t('automation_template_trigger_updated_failure') as string);
          console.error(err);
        });
    }
  };

  const deleteTrigger = async (trigger_id: string) => {
    if (current && current?.id) {
      await deleteAutomationTemplateTrigger(current?.id, trigger_id)
        .then(() => {
          if (current && current?.triggers) {
            const updatedTriggers = current?.triggers?.filter(
              (trigger) => trigger.id !== trigger_id
            );
            setCurrentAutomationTemplate({
              ...current,
              triggers: updatedTriggers,
            });
            toast.success(
              i18next.t('automation_template_trigger_deleted_success') as string
            );
          }
        })
        .catch((err) => {
          toast.error(i18next.t('automation_template_trigger_deleted_failure') as string);
          console.error(err);
        });
    }
  };

  // add trigger button ref
  const addTriggerRef = useRef<HTMLButtonElement>(null);

  // on mount, if current.triggers is length 0, then show the add trigger modal
  // we do this because we want to show the add trigger modal when there are no triggers
  // we accomplish this by programmatically clicking the add trigger button
  useEffect(() => {
    // if current is not null and current.triggers is length 0
    if (current && current?.triggers && current?.triggers?.length === 0) {
      // if the add trigger button exists, click it
      if (addTriggerRef.current) {
        addTriggerRef.current.click();
      }
    }
  }, [current]);

  const handleDelete = () => {
    if (current && current.id) {
      deleteAutomationTemplate(current?.id);
      history.push('/automations/templates');
    }
  };

  return (
    <>
      <Helmet>
        <title>Whippy | Automation Template</title>
      </Helmet>
      <PageLayout
        breadcrumbs={[
          { title: 'Automations', path: '/automations/keywords' },
          { title: 'Templates', path: '/automations/templates' },
        ]}
        actions={
          <HStack gap="2">
            <Box>
              <ConfirmationDialog
                title="Delete Automation Template"
                description="Are you sure you want to delete your automation template? This action cannot be undone."
                onConfirm={handleDelete}
                confirmButtonTitle="Yes, Delete Domain"
                testID="delete-template-button"
              >
                <IconButton size={2} variant="outline">
                  <HiTrash />
                </IconButton>
              </ConfirmationDialog>
            </Box>
            <Box>
              <UpdateAutomationTemplate />
            </Box>
            <Box>
              <AddTrigger ref={addTriggerRef} addTrigger={createTrigger} />
            </Box>
          </HStack>
        }
      >
        <Box css={{ p: 30 }}>
          <VStack>
            <Box>
              <KeywordAutomations
                keyword_triggers={
                  current?.triggers?.filter(
                    (trigger: { type: string }) =>
                      trigger.type === TriggerTypes.KEYWORD ||
                      trigger.type === 'reply_with_keyword'
                  ) || []
                }
                updateTrigger={updateTrigger}
                variant="template"
                deleteTrigger={deleteTrigger}
              />
            </Box>
            <Box>
              <ReplyAutomations
                reply_triggers={
                  current?.triggers?.filter(
                    (trigger: { type: string }) => trigger.type === TriggerTypes.REPLY
                  ) as Array<ReplyTriggerType>
                }
                updateTrigger={updateTrigger}
                variant="template"
                deleteTrigger={deleteTrigger}
              />
            </Box>
            <Box>
              <LinkClickAutomations
                link_click_triggers={
                  current?.triggers?.filter(
                    (trigger: { type: string }) =>
                      trigger.type === TriggerTypes.LINK_CLICK
                  ) as Array<LinkClickTriggerType>
                }
                updateTrigger={updateTrigger}
                variant="template"
                deleteTrigger={deleteTrigger}
              />
            </Box>
          </VStack>
        </Box>
      </PageLayout>
    </>
  );
};

export const UpdateAutomationTemplate = () => {
  const automationTemplatesContext = useAutomationTemplates();
  const { updateAutomationTemplate, setCurrentAutomationTemplate } =
    automationTemplatesContext;
  const { current } = automationTemplatesContext.automationTemplatesState;

  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleCreateAutomationTemplate = async (values: {
    title: string;
    access_level: TemplateAccessLevel;
    usage: TemplateUsage;
  }) => {
    const template = await updateAutomationTemplate({
      ...current,
      ...{
        title: values.title,
        access_level: values.access_level,
        usage: values.usage,
      },
    });

    if (template && template.id) {
      onClose();
      setCurrentAutomationTemplate(template);
    }
  };

  return (
    <Dialog open={isOpen}>
      <DialogTrigger asChild onClick={() => onOpen()}>
        <IconButton variant="outline" size="2" aria-label="Create Template">
          <HiCog />
        </IconButton>
      </DialogTrigger>
      <DialogPortal>
        <DialogOverlay />
        <DialogContent
          onEscapeKeyDown={() => onClose()}
          onPointerDownOutside={() => onClose()}
          css={{ overflow: 'auto' }}
        >
          <DialogTitle variant="bold">Update Template 123123</DialogTitle>
          <Formik
            enableReinitialize
            initialValues={{
              title: current?.title || '',
              access_level: current?.access_level || TemplateAccessLevel.ORGANIZATION,
              usage: current?.usage || TemplateUsage.AS_IS,
            }}
            validationSchema={Yup.object({
              title: Yup.string().required('Required'),
              access_level: Yup.string().required('Required'),
              usage: Yup.string().required('Required'),
            })}
            onSubmit={async (values) => {
              await handleCreateAutomationTemplate(values);
            }}
          >
            {(formik) => (
              <form onSubmit={formik.handleSubmit}>
                <FormFieldWrapper
                  label="Title"
                  tooltip="The title of the template."
                  name="title"
                >
                  <TextInput />
                </FormFieldWrapper>
                <FormFieldWrapper
                  label="Access Level"
                  tooltip="The access level of the template."
                  name="access_level"
                >
                  <FormSelect
                    placeholder="Access Levels"
                    options={[
                      {
                        label: 'Organization',
                        value: TemplateAccessLevel.ORGANIZATION,
                      },
                      {
                        label: 'User',
                        value: TemplateAccessLevel.USER,
                      },
                    ]}
                  />
                </FormFieldWrapper>
                <FormFieldWrapper
                  label="Usage"
                  tooltip="The usage of the template."
                  name="usage"
                >
                  <FormSelect
                    placeholder="Usage"
                    options={[
                      {
                        label: 'As Is',
                        value: TemplateUsage.AS_IS,
                      },
                      {
                        label: 'Duplicate',
                        value: TemplateUsage.DUPLICATE,
                      },
                    ]}
                  />
                </FormFieldWrapper>
                <DialogFooter justify="end">
                  <DialogClose asChild>
                    <Button type="submit">Update Template</Button>
                  </DialogClose>
                </DialogFooter>
              </form>
            )}
          </Formik>
          <DialogCloseIcon onClick={() => onClose()} size="2">
            <HiX size="15px" />
          </DialogCloseIcon>
        </DialogContent>
      </DialogPortal>
    </Dialog>
  );
};
