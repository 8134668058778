/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { HiChevronDown, HiChevronRight } from 'react-icons/hi';
import { Link, useHistory, useLocation } from 'react-router-dom';

import {
  initialCustomDataState,
  useCustomData,
} from '@/pages/settings/organization/data/context/CustomDataContext';
import { CircleIcon } from '@/shared/components/Icons';
import {
  SideNavigationHeading,
  SideNavigationItem,
} from '@/shared/components/navigation/SideNavigationItem';
import { Group } from '@/shared/types/contacts/groups';
import { Tag } from '@/shared/types/tags';
import {
  Box,
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
  Flex,
  IconButton,
  Text,
  VStack,
} from '@/shared/ui';

import { useContacts } from '../context/ContactContext';
import { useGroups } from '../groups/context/GroupContext';
import { useUploads } from '../uploads/context/UploadContext';

export function ContactSideNavigation(): JSX.Element {
  const { uploadsState, getUploads, setCurrentUpload } = useUploads();
  const { uploads } = uploadsState;
  const previewUploads = uploads.slice(0, 5);

  const groupContext = useGroups();
  const { getGroups, setCurrentGroup, groupsState } = groupContext;

  const contactContext = useContacts();
  const { setAllContactsChecked } = contactContext;

  const { groups } = groupsState;
  // filter out groups where group.filterVersion = v2
  const previewGroups = groups
    .filter((group) => group.filters_version === 'v1')
    .slice(0, 5);

  const history = useHistory();
  const location = useLocation();

  const custom = useCustomData();
  const { customDataState, getCustomObjects } = custom;
  const { customObjects } = customDataState;

  useEffect(() => {
    getGroups({ offset: 0, limit: 20 });
    getUploads({ offset: 0, limit: 20 });
  }, []);

  // Fetch custom objects if not already fetched
  useEffect(() => {
    if (customObjects.length === 0) {
      getCustomObjects({ ...initialCustomDataState.filterParams, offset: 0, limit: 5 });
    }
  }, []);

  useEffect(() => {
    if (history.location.pathname.includes('/contacts/uploads/')) {
      const uploadId = history.location.pathname.split('/')[3];
      const uploadRendered = uploads.filter((upload: Tag) => upload.id === uploadId)[0];
      setCurrentUpload(uploadRendered);
    }

    if (history.location.pathname.includes('/contacts/groups/')) {
      const groupId = history.location.pathname.split('/')[3];
      const groupRendered = groups.filter((group: Group) => group.id === groupId)[0];
      setCurrentGroup(groupRendered);
    }
  }, [history.location.pathname, uploads, groups]);

  const onNavigateToContacts = () => {
    setAllContactsChecked(false);
    setCurrentUpload(null);
    setCurrentGroup(null);
  };

  const onNavigateToGroup = (group: Group) => {
    setCurrentUpload(null);
    setCurrentGroup(group);
    setAllContactsChecked(false);
  };

  const onNavigateToUpload = (upload: Tag) => {
    setCurrentUpload(upload);
    setCurrentGroup(null);
    setAllContactsChecked(false);
  };

  const onNavigateToObject = () => {
    setCurrentGroup(null);
    setAllContactsChecked(false);
  };

  return (
    <Box css={{ pl: 8, pr: 8 }}>
      <Link to="/contacts">
        <Box onClick={() => onNavigateToContacts()} css={{ mb: 3 }}>
          <SideNavigationItem active={location.pathname === '/contacts'}>
            <CircleIcon color={'#6C6F75'} />
            <Text>All Contacts</Text>
          </SideNavigationItem>
        </Box>
      </Link>
      {customObjects.length > 0 && (
        <ContactCollapsibleNavigation title="Objects">
          <>
            {customObjects.map((object) => {
              return (
                <Link to={`/contacts/objects/${object.id}`} key={`sidebar-${object.id}`}>
                  <Box onClick={() => onNavigateToObject()} css={{ mb: 3 }}>
                    <SideNavigationItem
                      active={object.id === location.pathname.split('/')[3]}
                    >
                      <CircleIcon css={{ mt: 2 }} color="#333333" />
                      <Text variant="sidenavigation">{object.label}</Text>
                    </SideNavigationItem>
                  </Box>
                </Link>
              );
            })}
          </>
        </ContactCollapsibleNavigation>
      )}
      {groups.length > 0 && (
        <ContactCollapsibleNavigation title="Groups">
          <>
            <Link to="/contacts/groups">
              <Box onClick={() => onNavigateToContacts()} css={{ mb: 3 }}>
                <SideNavigationItem active={location.pathname === '/contacts/groups'}>
                  <CircleIcon color={'#6C6F75'} />
                  <Text>All Groups</Text>
                </SideNavigationItem>
              </Box>
            </Link>
            {previewGroups.map((group: Group) => {
              return (
                <Link to={`/contacts/groups/${group.id}`} key={`sidebar-${group.id}`}>
                  <Box onClick={() => onNavigateToGroup(group)} css={{ mb: 3 }}>
                    <SideNavigationItem
                      active={group.id === location.pathname.split('/')[3]}
                    >
                      <CircleIcon css={{ mt: 2 }} color={group.color} />
                      <Text variant="sidenavigation">{group.name}</Text>
                    </SideNavigationItem>
                  </Box>
                </Link>
              );
            })}
          </>
        </ContactCollapsibleNavigation>
      )}
      {uploads.length > 0 && (
        <ContactCollapsibleNavigation title="Uploads">
          <>
            <Link to="/contacts/uploads">
              <Box onClick={() => onNavigateToContacts()} css={{ mb: 3 }}>
                <SideNavigationItem active={location.pathname === '/contacts/uploads'}>
                  <CircleIcon color={'#6C6F75'} />
                  <Text>All Uploads</Text>
                </SideNavigationItem>
              </Box>
            </Link>
            {previewUploads.map((upload: Tag) => {
              return (
                <Link to={`/contacts/uploads/${upload.id}`} key={`sidebar-${upload.id}`}>
                  <Box onClick={() => onNavigateToUpload(upload)} css={{ mb: 3 }}>
                    <SideNavigationItem
                      active={upload.id === location.pathname.split('/')[3]}
                    >
                      <CircleIcon css={{ mt: 2 }} color={upload.color} />
                      <Text variant="sidenavigation">{upload.name}</Text>
                    </SideNavigationItem>
                  </Box>
                </Link>
              );
            })}
          </>
        </ContactCollapsibleNavigation>
      )}
    </Box>
  );
}

type ContactCollapsibleNavigationProps = {
  children: JSX.Element;
  title: string;
};

export const ContactCollapsibleNavigation = (
  props: ContactCollapsibleNavigationProps
) => {
  const [open, setOpen] = useState(true);

  return (
    <Collapsible open={open} onOpenChange={setOpen}>
      <CollapsibleTrigger asChild>
        <Flex align="center">
          <IconButton>
            {open ? <HiChevronDown size={19} /> : <HiChevronRight size={19} />}
          </IconButton>
          <SideNavigationHeading
            align="center"
            size={1}
            css={{ cursor: 'pointer', mb: 2, mr: 5 }}
          >
            {props.title}
          </SideNavigationHeading>
        </Flex>
      </CollapsibleTrigger>
      <CollapsibleContent>
        <VStack gap={1}>{props.children}</VStack>
      </CollapsibleContent>
    </Collapsible>
  );
};
