import React, { useState } from 'react';
import { HiX } from 'react-icons/hi';
import { toast } from 'sonner';

import {
  Button,
  Dialog,
  DialogCloseIcon,
  DialogContent,
  DialogOverlay,
  DialogPortal,
  DialogTitle,
  HStack,
  Input,
  Text,
} from '@/shared/ui';

type RenameConfirmationDialogProps = {
  /* is the dialog open? */
  isRenameConfirmationDialogOpen: boolean;
  /* set the dialog open state */
  setIsRenameConfirmationDialogOpen: (isOpen: boolean) => void;
  /* the focused api's previous name to fill in place holder*/
  oldName: string | null;
  /* the focused api key id */
  currentApiKeyId: string | null;
  /* api function to rename the api key */
  renameApiKey: (id: string, name: string) => void;
};

const RenameConfirmationDialog = (props: RenameConfirmationDialogProps): JSX.Element => {
  const {
    isRenameConfirmationDialogOpen,
    setIsRenameConfirmationDialogOpen,
    oldName,
    renameApiKey,
    currentApiKeyId,
  } = props;
  const MAX_LENGTH = 30; // Maximum allowed length for rename
  const [newName, setNewName] = useState(oldName);
  const [nameError, setNameError] = useState<string | null>(null); // To store validation error

  const handleRenameConfirm = async (newName: string) => {
    if (validateNewName(newName)) {
      // Only continue if name is valid
      try {
        currentApiKeyId && renameApiKey(currentApiKeyId, newName);
        toast.success('API Key renamed successfully');
      } catch (error) {
        toast.error('API Key rename error');
      }
      setIsRenameConfirmationDialogOpen(false);
    }
  };

  const handleChangeNewName = (e: any) => {
    setNewName(e.target.value);
    setNameError(null); // Reset error when user types
  };

  const validateNewName = (name: string): boolean => {
    if (!name.trim()) {
      setNameError("Name can't be empty");
      return false;
    }
    if (name === oldName) {
      setNameError('New name should be different from the old name');
      return false;
    }
    if (name.length > MAX_LENGTH) {
      setNameError(`Name can't be longer than ${MAX_LENGTH} characters`);
      return false;
    }
    return true;
  };

  return (
    <Dialog open={isRenameConfirmationDialogOpen}>
      <DialogPortal>
        <DialogOverlay />
        <DialogContent onEscapeKeyDown={() => setIsRenameConfirmationDialogOpen(false)}>
          <DialogTitle variant="bold">Rename API Key</DialogTitle>
          <DialogCloseIcon
            onClick={() => setIsRenameConfirmationDialogOpen(false)}
            size="2"
          >
            <HiX size="15px" />
          </DialogCloseIcon>
          <Text>Please input a new name for the API Key.</Text>
          <HStack css={{ marginTop: 20, width: '100%' }}>
            <Input
              placeholder={'Type the new name here'}
              value={newName || ''}
              onChange={handleChangeNewName}
            />
          </HStack>
          {/* Display validation error */}
          <HStack css={{ marginTop: 10, width: '100%' }}>
            {nameError && <Text css={{ color: 'red' }}>{nameError}</Text>}
          </HStack>
          <HStack css={{ marginTop: 20, justifyContent: 'flex-end' }}>
            <Button
              variant={'gray'}
              onClick={() => setIsRenameConfirmationDialogOpen(false)}
            >
              Cancel
            </Button>
            <Button
              variant={'primary'}
              onClick={() => handleRenameConfirm(newName || '')}
            >
              Save
            </Button>
          </HStack>
        </DialogContent>
      </DialogPortal>
    </Dialog>
  );
};

export default RenameConfirmationDialog;
