import { Flex } from '../../../../ui';

export const UnassignUserAction = () => {
  return (
    <Flex css={{ fontSize: 14 }}>
      When a contact sends a message with this keyword, any currently assigned users will
      be unassigned.
    </Flex>
  );
};
