import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { Button, Card, Flex, Text, VStack } from '@/shared/ui';

export const WebCallStart = () => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();

  const startCall = () => {
    history.push(`/call/${id}/in-progress`);
  };

  return (
    <Flex direction="column" css={{ p: 20 }} align="center">
      <Text align="center" css={{ fontSize: 25, fontWeight: 600, mb: 15 }}>
        It&apos;s Time For Your Call!
      </Text>
      <Text align="center" css={{ fontSize: 16, mb: 30 }}>
        Click Start to begin your call.
      </Text>

      <Flex justify="center" css={{ my: 30 }} align="center">
        <Button
          size={3}
          onClick={startCall}
          color="primary"
          css={{ fontSize: 14, minWidth: '300px' }}
        >
          🏁 Start the Call
        </Button>
      </Flex>

      <Text align="center" css={{ fontSize: 20, fontWeight: 600, my: 30 }}>
        Quick tips for your call
      </Text>

      <Flex justify="center" align="center" css={{ width: '100%' }} direction="column">
        <VStack gap={2} align="center">
          {tips.map((tip, index) => (
            <TipCard key={index} tip={tip} />
          ))}
        </VStack>
      </Flex>
    </Flex>
  );
};

const TipCard = ({
  tip,
}: {
  tip: { icon: string; title: string; description: string };
}) => {
  return (
    <Card css={{ width: '80%', p: 20 }}>
      <Flex align="center">
        <Text css={{ fontSize: 22, fontWeight: 500, mr: 10 }}>{tip.icon}</Text>
        <Text css={{ fontSize: 22, fontWeight: 500 }}>{tip.title}</Text>
      </Flex>
      <Text css={{ fontSize: 18, lineHeight: 1.5, mt: 10 }}>{tip.description}</Text>
    </Card>
  );
};

const tips = [
  {
    icon: '🕒',
    title: '1. Take Your Time!',
    description:
      'You have all the time you need to highlight your skills and experiences. The interviewer is patient and eager to learn more about you.',
  },
  {
    icon: '🗣️',
    title: '2. Speak Naturally',
    description:
      'Speak naturally and don&apos;t worry about sounding perfect. The interviewer is looking for a real conversation, not a rehearsed speech.',
  },
  {
    icon: '💪',
    title: '3. Be Confident',
    description:
      'Be confident in your abilities and experiences. The interviewer is looking for someone who is passionate and excited about the opportunity.',
  },
  {
    icon: '🌐',
    title: '4. Connectivity Basics',
    description:
      'Make sure you have a stable internet connection and a quiet environment. You don&apos;t want any background noise or interruptions during your call.',
  },
];
