import React, { memo } from 'react';
import { HiClock } from 'react-icons/hi';

import { TeamMember } from '@/shared/types/team';
import { Avatar, Box, Flex, VStack } from '@/shared/ui';
import { initials } from '@/shared/utils/initials/initials';
import { styled } from '@/stitches.config';

import { TeamMemberNavigationButton } from './TeamMemberNavigationButton';

type TeamMemberItemProps = {
  /* the current team id */
  teamId: string;
  /* the team member item */
  item: TeamMember;
  /* the function to be called when the delete button is clicked */
  onItemDelete: (id: string) => void;
};

export const TeamMemberItem = memo((props: TeamMemberItemProps) => {
  const { teamId, item, onItemDelete } = props;
  return (
    <VStack css={{ mt: 12 }}>
      <Flex align="center" justify="between">
        <Flex align="center">
          <Avatar
            variant="pink"
            size="2"
            src={item?.attachment?.url}
            fallback={initials(item.name)}
          />
          <TagTextWrapper>{item.name}</TagTextWrapper>
          {!!item.work_schedules.length && (
            <HiClock size={14} style={{ color: '#3E54CF' }} />
          )}
        </Flex>
        <TeamMemberNavigationButton
          teamId={teamId}
          teamMember={item}
          onDelete={onItemDelete}
        />
      </Flex>
    </VStack>
  );
});

const TagTextWrapper = styled(Box, {
  whiteSpace: 'nowrap',
  cursor: 'default',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: '190px',
  fontSize: 13,
  marginLeft: '8px',
  marginRight: '6px',
});
