import { Avatar, Box } from '@/shared/ui';
import { initials } from '@/shared/utils/initials/initials';

import { showContactIcon } from '../../list/ConversationPreview';

type ContactIconProps = {
  contact_name: string;
  margin?: string | number;
};

export const ContactIcon = (props: ContactIconProps) => {
  const { contact_name, margin = 8 } = props;

  return (
    <Box css={{ mr: margin }} data-testid="contact-icon">
      <Avatar
        size="sender"
        variant="lightGray"
        src={
          showContactIcon(contact_name || '')
            ? `${window.location.origin}/outline.svg`
            : ''
        }
        alt={contact_name || 'No name'}
        fallback={initials(contact_name || '')}
      />
    </Box>
  );
};
