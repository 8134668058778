import React from 'react';
import { ActionType } from 'react-table';

import { Box, Flex, VStack } from '@/shared/ui';

import { action_values } from '../actions/types';

type PreviewActionWrapperProps = {
  /** action title */
  title: string;
  /** action description */
  description: string | React.ReactNode;
  /** action child component */
  children?: React.ReactNode;
  /** action object */
  action?: ActionType;
  /** remove action function */
  removeAction?: (action: ActionType) => void;
  /** save the current action */
  saveAction?: () => void;
  /** action icon */
  actionIcon: React.ReactNode;
  /** action color */
  actionColor: string;
};

export const PreviewActionWrapper = (props: PreviewActionWrapperProps) => {
  const { title, description, actionIcon, actionColor } = props;

  return (
    <Flex>
      <Flex direction="column" align="center" css={{ mr: 10 }}>
        <Flex
          align="center"
          justify="center"
          css={{
            backgroundColor: actionColor || '#DFE3EB',
            color: 'white',
            borderRadius: '50%',
            width: 28,
            height: 28,
            mb: 10,
          }}
        >
          {actionIcon}
        </Flex>
        <Box
          css={{
            borderLeft: '1px solid #DFE3EB',
            height: 60,
          }}
        />
      </Flex>
      <Flex direction="column" css={{ width: '100%', maxWidth: 'calc(100% - 40px)' }}>
        <Box css={{ mb: 5 }}>
          <Box
            css={{
              fontSize: 14,
              fontWeight: 500,
            }}
          >
            {title}
          </Box>
        </Box>
        <Flex direction="row" css={{ width: '100%' }}>
          <Flex
            css={{
              borderWidth: 1,
              borderColor: '$slate6',
              borderRadius: 8,
              backgroundColor: 'white',
              p: 15,
              width: '100%',
            }}
            align="center"
            justify="between"
          >
            <VStack gap={2}>
              <Box css={{ fontWeight: 500, fontSize: 14, color: '#2B2A34' }}>
                {description}
              </Box>
            </VStack>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export const getActionTitle = (x: ActionType) => {
  const action = action_values.find((a) => a.value === x?.type);
  return action?.title;
};

export const getActionDescription = (x: ActionType) => {
  const action = action_values.find((a) => a.value === x?.type);
  return action?.description;
};

export const getActionColor = (x?: ActionType) => {
  const action = action_values.find((a) => a.value === x?.type);
  return action?.color;
};

export const getActionIcon = (x?: ActionType) => {
  const action = action_values.find((a) => a.value === x?.type);
  return action?.icon;
};
