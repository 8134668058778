import { blackA, mauve } from '@radix-ui/colors';
import * as ScrollAreaPrimitive from '@radix-ui/react-scroll-area';
import { CSS, styled } from '@stitches/react';
import React from 'react';

const SCROLLBAR_SIZE = 10;

const StyledScrollArea = styled(ScrollAreaPrimitive.Root, {
  width: 200,
  height: 192,
  borderRadius: 4,
  overflow: 'hidden',
  boxShadow: `0 2px 10px ${blackA.blackA7}`,

  variants: {
    variant: {
      combobox: {
        width: '100%',
        height: 'auto',
        maxHeight: 192,
        boxShadow: 'none',
        borderRadius: 0,
        overflowX: 'hidden',
      },
      timepicker: {
        width: 200,
        height: 225,
        borderRadius: 4,
        overflow: 'hidden',
        boxShadow: `0 2px 10px ${blackA.blackA7}`,
      },
    },
  },
});

const StyledViewport = styled(ScrollAreaPrimitive.Viewport, {
  width: '100%',
  height: '100%',
  borderRadius: 'inherit',

  variants: {
    variant: {
      combobox: {
        maxHeight: 'inherit',
        width: 'inherit',
      },
    },
  },
});

const StyledScrollbar = styled(ScrollAreaPrimitive.Scrollbar, {
  display: 'flex',
  // ensures no selection
  userSelect: 'none',
  // disable browser handling of all panning and zooming gestures on touch devices
  touchAction: 'none',
  padding: 2,
  background: blackA.blackA6,
  transition: 'background 160ms ease-out',
  '&:hover': { background: blackA.blackA8 },
  '&[data-orientation="vertical"]': { width: SCROLLBAR_SIZE },
  '&[data-orientation="horizontal"]': {
    flexDirection: 'column',
    height: SCROLLBAR_SIZE,
  },

  variants: {
    variant: {
      combobox: {
        background: 'transparent',
      },
    },
  },
});

const StyledThumb = styled(ScrollAreaPrimitive.Thumb, {
  flex: 1,
  background: mauve.mauve10,
  borderRadius: SCROLLBAR_SIZE,
  // increase target size for touch devices https://www.w3.org/WAI/WCAG21/Understanding/target-size.html
  position: 'relative',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    height: '100%',
    minWidth: 44,
    minHeight: 44,
  },
});

const StyledCorner = styled(ScrollAreaPrimitive.Corner, {
  background: blackA.blackA8,
});

// Exports
export const ScrollArea = StyledScrollArea;
export const ScrollAreaViewport = StyledViewport;
export const ScrollAreaScrollbar = StyledScrollbar;
export const ScrollAreaThumb = StyledThumb;
export const ScrollAreaCorner = StyledCorner;

const CustomScrollArea = styled(ScrollAreaPrimitive.Root, {
  width: '100%',
  height: '100%',
  borderRadius: 4,
  overflow: 'hidden',
});

type ScrollAreaViewportPrimitiveProps = React.ComponentProps<
  typeof ScrollAreaPrimitive.Viewport
>;
type ScrollAreaViewportProps = ScrollAreaViewportPrimitiveProps & { css?: CSS } & {
  scrollAreaWidth?: number;
};

export const CustomScrollbar = React.forwardRef<
  React.ElementRef<typeof StyledViewport>,
  ScrollAreaViewportProps
>(({ children, scrollAreaWidth, ...props }, ref) => {
  return (
    <CustomScrollArea>
      <ScrollAreaViewport ref={ref} {...props}>
        {children}
      </ScrollAreaViewport>
      <ScrollAreaScrollbar orientation="vertical" variant="combobox">
        <ScrollAreaThumb css={scrollAreaWidth ? { minWidth: scrollAreaWidth } : {}} />
      </ScrollAreaScrollbar>
      <ScrollAreaCorner />
    </CustomScrollArea>
  );
});
