/* eslint-disable react-hooks/exhaustive-deps */
import { useCombobox } from 'downshift';
import { FormikValues, useFormikContext } from 'formik';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { HiChevronDown } from 'react-icons/hi';
import { useLocation } from 'react-router-dom';

import { sources } from '@/contacts/ContactsFilters';
import { useGroups } from '@/contacts/groups/context/GroupContext';
import { simplifyContactFilters } from '@/contacts/utils';
import { useDisclosure } from '@/shared/hooks';
import {
  Box,
  Checkbox,
  ComboboxItem,
  ComboboxItemText,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
  HStack,
  Input,
  ScrollArea,
  ScrollAreaCorner,
  ScrollAreaScrollbar,
  ScrollAreaThumb,
  ScrollAreaViewport,
} from '@/shared/ui';

import { MultiSelectComboboxProps } from './types';

// If props.condition is set to true, renders the custom component set via
// props.wrapper.custom. otherwise return the base component set via props.wrapper.base.

export function ConditionalComponent({
  condition,
  wrapper,
  children,
}: {
  condition: boolean;
  wrapper: {
    base: (children?: JSX.Element) => JSX.Element | null;
    custom: (children?: JSX.Element) => JSX.Element | null;
  };
  children?: JSX.Element;
}): JSX.Element | null {
  return condition ? wrapper.custom(children) : wrapper.base(children);
}

// A multi-select combobox that can be used in conjunction with Formik
// must be placed within a FormFieldWrapper

export function MultiSelectCombobox(props: MultiSelectComboboxProps): JSX.Element {
  const formik = useFormikContext<FormikValues>();

  const value = _.get(formik.values, props.name as string);

  useEffect(() => {
    if (value) {
      setSelectedItems(value);
    }
  }, [value]);

  const enhancedOptions = [{ type: 'Select All', value: null }, ...props.options];

  const [inputItems, setInputItems] = useState(
    props.selectAll ? enhancedOptions : props.options
  );

  const [selectedItems, setSelectedItems] = useState(value || []);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [anchorWidth, setAnchorWidth] = useState(0);

  const [placeholder, setPlaceholder] = useState('');

  const inputRef = useRef() as React.MutableRefObject<HTMLInputElement>;

  const { groupsState } = useGroups();
  const currentGroup = groupsState.current;

  const location = useLocation();
  const isContacts = location.pathname.startsWith('/contacts');
  const isInbox = location.pathname.startsWith('/inbox');

  useEffect(() => {
    if (props.parentFilters) {
      const isFilteredBySource =
        props.options.map((item: any) => item.value)[0] === sources[0];
      if (isFilteredBySource) {
        selectedItems.length === 0
          ? // optional chaining for setParentFilters because could be undefined in types
            props.setParentFilters?.({ ...props.parentFilters, sources: [] })
          : props.setParentFilters?.({ ...props.parentFilters, sources: selectedItems });
      } else {
        selectedItems.length === 0
          ? props.setParentFilters?.({ ...props.parentFilters, tags: [] })
          : props.setParentFilters?.({ ...props.parentFilters, tags: selectedItems });
      }
    }
  }, [selectedItems]);

  useEffect(() => {
    if (props.emptyFilters) {
      setSelectedItems([]);
    }
  }, [props.emptyFilters]);

  useEffect(() => {
    // updates the input items when props.options is dynamically changed
    if (inputItems.length !== enhancedOptions.length) {
      props.name === 'tags' && (isContacts || isInbox)
        ? null
        : setInputItems(enhancedOptions);
    }
  }, [enhancedOptions]);

  useEffect(() => {
    if (currentGroup && currentGroup.filters_version === 'v1') {
      const isFilteredBySource =
        props.options.map((item: any) => item.value)[0] === sources[0];
      if (isFilteredBySource) {
        setSelectedItems(simplifyContactFilters(currentGroup.filters).sources);
      } else {
        props.name === 'tags' && isContacts
          ? null
          : setSelectedItems(simplifyContactFilters(currentGroup.filters).tags);
      }
    }
  }, [currentGroup]);

  const { getMenuProps, getInputProps, getItemProps, highlightedIndex } = useCombobox({
    items: inputItems,
    onSelectedItemChange: ({ selectedItem }) => {
      if (!selectedItem?.value) {
        if (selectedItems.length === props.options.length) {
          return setSelectedItems([]);
        } else {
          return setSelectedItems(props.options.map((item) => item.value));
        }
      }
      const index = selectedItems.indexOf(selectedItem?.value);
      if (index >= 0) {
        return setSelectedItems(
          selectedItems.filter((item: string) => item !== selectedItem?.value)
        );
      } else {
        return setSelectedItems([...selectedItems, selectedItem?.value]);
      }
    },
    selectedItem: null,
    stateReducer: (state, actionAndChanges) => {
      const { type, changes } = actionAndChanges;
      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
        case useCombobox.stateChangeTypes.InputKeyDownEscape:
        case useCombobox.stateChangeTypes.InputBlur:
          return {
            ...changes,
            inputValue: '',
          };
        default:
          return {
            ...changes,
          };
      }
    },
    onInputValueChange: ({ inputValue }) => {
      if (inputValue) {
        const filteredOptions = props.options.filter((item) =>
          item.type.toLowerCase().startsWith(inputValue.toLowerCase())
        );
        setInputItems(filteredOptions);
      } else {
        if (props.selectAll) {
          setInputItems(enhancedOptions);
        } else {
          setInputItems(props.options);
        }
      }
    },
  });

  useEffect(() => {
    formik.setFieldValue(props.name as string, selectedItems);
  }, [props.name, selectedItems, formik.setFieldValue]);

  const updateAnchorWidth = () => {
    setAnchorWidth(inputRef.current.offsetWidth);
  };

  useEffect(() => {
    if (typeof props.placeholder === 'function') {
      props.placeholder({ formik, props, setPlaceholder });
    } else if (typeof props.placeholder === 'string') {
      setPlaceholder(props.placeholder);
    }
    window.addEventListener('resize', updateAnchorWidth);
    return () => {
      window.removeEventListener('resize', updateAnchorWidth);
    };
  }, [value, selectedItems]);

  const handleClick = () => {
    !props.disabled ? setPlaceholder('') : null;
    if (!isOpen) {
      onOpen();
      updateAnchorWidth();
    }
  };

  const handlePointerDownOutside = (e: Event) => {
    if (e.currentTarget !== inputRef.current) {
      formik.setFieldTouched(props.name as string);
      if (typeof props.placeholder === 'function') {
        props.placeholder({ formik, props, setPlaceholder });
      } else if (typeof props.placeholder === 'string') {
        setPlaceholder(props.placeholder);
      }
      onClose();
    }
  };

  return (
    <ConditionalComponent
      condition={!!props.isDropdown}
      wrapper={{
        base: (children) => (children ? children : null),
        custom: (children) => (
          <DropdownMenu open={isOpen} onOpenChange={() => !isOpen} modal={false}>
            {children}
          </DropdownMenu>
        ),
      }}
    >
      <>
        <Box>
          <ConditionalComponent
            condition={!!props.isDropdown}
            wrapper={{
              base: (children) => (
                <Box
                  css={{
                    position: 'relative',
                  }}
                >
                  {children}
                </Box>
              ),
              custom: (children) => (
                <DropdownMenuTrigger asChild={true} onClick={handleClick}>
                  <Box css={{ position: 'relative' }}>
                    <HiChevronDown
                      style={{
                        position: 'absolute',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        right: '10px',
                        pointerEvents: 'none',
                        fontSize: '16px',
                      }}
                    />
                    {children}
                  </Box>
                </DropdownMenuTrigger>
              ),
            }}
          >
            <ConditionalComponent
              condition={!!props.FieldComponent}
              wrapper={{
                base: () => (
                  <Input
                    {...getInputProps(
                      {
                        placeholder: placeholder,
                        autoComplete: 'chrome-off',
                        type: 'text',
                        ref: inputRef,
                        name: props.name,
                      },
                      { suppressRefError: true }
                    )}
                    data-intercom-target={props.intercomId}
                    aria-label={props.ariaLabel}
                    variant={props.isDropdown ? 'selectDropdown' : null}
                    css={{
                      textTransform: 'capitalize',
                      pointerEvents: props.disabled ? 'none' : undefined,
                    }}
                    disabled={!!props.disabled}
                  />
                ),
                custom: () =>
                  props.FieldComponent
                    ? props.FieldComponent({
                        props,
                        placeholder,
                        getInputProps,
                        inputRef,
                      })
                    : null,
              }}
            />
          </ConditionalComponent>
        </Box>
        <Box {...getMenuProps({}, { suppressRefError: true })}>
          <ConditionalComponent
            condition={!!props.isDropdown}
            wrapper={{
              base: (children) => (children ? children : null),
              custom: (children) => (
                <DropdownMenuContent
                  side="bottom"
                  sideOffset={5}
                  variant="combobox"
                  onPointerDownOutside={(e: Event) => handlePointerDownOutside(e)}
                  onFocus={() => inputRef.current.focus()}
                >
                  {children}
                </DropdownMenuContent>
              ),
            }}
          >
            <ConditionalComponent
              condition={!!props.ScrollAreaComponent}
              wrapper={{
                base: (children) => (
                  <ScrollArea variant="combobox">{children}</ScrollArea>
                ),
                custom: (children) =>
                  props.ScrollAreaComponent
                    ? props.ScrollAreaComponent({
                        children,
                      })
                    : null,
              }}
            >
              <>
                {!props.disabled && (
                  <ScrollAreaViewport variant="combobox">
                    {inputItems.length ? (
                      inputItems.map((item, index) => (
                        <Box
                          key={`${item.value}${index}`}
                          {...getItemProps({ item, index })}
                          css={{ width: props.isDropdown ? anchorWidth : 'auto' }}
                        >
                          <ConditionalComponent
                            condition={!!props.OptionComponent}
                            wrapper={{
                              base: () => (
                                <ComboboxItem
                                  selected={highlightedIndex === index}
                                  css={{
                                    borderBottom: !item.value
                                      ? '1px solid $slate7'
                                      : 'auto',
                                  }}
                                >
                                  <HStack gap={2} css={{ overflow: 'hidden' }}>
                                    <Checkbox
                                      checked={
                                        selectedItems.includes(item.value) ||
                                        selectedItems.length === props.options.length
                                      }
                                      css={{ pointerEvents: 'none' }}
                                    />

                                    <ComboboxItemText
                                      css={{
                                        textTransform: props.capitalize
                                          ? 'capitalize'
                                          : '',
                                      }}
                                    >
                                      {item.type}
                                    </ComboboxItemText>
                                  </HStack>
                                </ComboboxItem>
                              ),
                              custom: () =>
                                props.OptionComponent
                                  ? props.OptionComponent({
                                      selected: highlightedIndex === index,
                                      item,
                                      checked:
                                        selectedItems.includes(item.value) ||
                                        selectedItems.length === props.options.length,
                                    })
                                  : null,
                            }}
                          />
                        </Box>
                      ))
                    ) : (
                      <Box css={{ width: props.isDropdown ? anchorWidth : 'auto' }}>
                        <ConditionalComponent
                          condition={!!props.OptionNullComponent}
                          wrapper={{
                            base: () => (
                              <ComboboxItem
                                disabled="true"
                                justify="center"
                                align="center"
                                css={{ pointerEvent: 'none' }}
                              >
                                <ComboboxItemText>No Results Found</ComboboxItemText>
                              </ComboboxItem>
                            ),
                            custom: () =>
                              props.OptionNullComponent
                                ? props.OptionNullComponent()
                                : null,
                          }}
                        />
                      </Box>
                    )}
                  </ScrollAreaViewport>
                )}
                <ScrollAreaScrollbar orientation="vertical" variant="combobox">
                  <ScrollAreaThumb />
                </ScrollAreaScrollbar>
                <ScrollAreaCorner />
              </>
            </ConditionalComponent>
          </ConditionalComponent>
        </Box>
      </>
    </ConditionalComponent>
  );
}
