/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';

import { Box } from '@/shared/ui';
import { styled } from '@/stitches.config';

import { useFocusedContext } from './context/FocusedContext';

const StyledTab = styled('a', {
  flex: 1,
  textAlign: 'center',
  width: 'calc(50% - 10px)', // offset the width by the marginRight
  marginRight: '$space$2',
  color: '$colors$slate11',
  borderColor: 'transparent',
  whiteSpace: 'nowrap',
  borderBottomWidth: 2,
  py: '$space$1',
  padding: '$space$1',
  px: '$space$0',
  fontSize: '$fontSizes$2',
  fontWeight: 500,
  variants: {
    current: {
      true: {
        color: '$colors$black11',
        borderColor: '$colors$black11',
      },
    },
  },
  '&:hover': {
    cursor: 'pointer',
    color: '$colors$slate12',
  },
});

const StyledTabs = styled('div', {
  width: '100%',
  display: 'flex',
});

type MessageTopbarProps = {
  textareaRef: React.RefObject<HTMLTextAreaElement>;
};

export const MessageTopbar = (props: MessageTopbarProps) => {
  const { isNote, setIsNote } = useFocusedContext();
  const { textareaRef } = props;
  const tabs = ['Reply', 'Note'];

  const [currentTab, setCurrentTab] = useState(isNote ? 'Note' : tabs[0]);

  const onTabClick = (tab: string) => {
    setCurrentTab(tab);

    // Focus on the textarea when switching tabs
    textareaRef?.current?.focus();

    if (tab === 'Note') {
      setIsNote(true);
    } else {
      setIsNote(false);
    }
  };

  // Monitor the isNote state and set the current tab accordingly
  useEffect(() => {
    setCurrentTab(isNote ? 'Note' : tabs[0]);
  }, [isNote]);

  return (
    <Box
      css={{
        display: 'flex',
        flexDirection: 'row',
        alignContent: 'flex-end',
        justifyContent: 'space-between',
        px: '12px',
        py: '12px',
        br: '$radii$2',
      }}
    >
      <Box>
        <StyledTabs aria-label="Tabs">
          {tabs.map((tab) => (
            <StyledTab
              css={{ fontSize: 14, paddingTop: 0 }}
              key={tab}
              current={tab == currentTab}
              aria-current={tab == currentTab ? 'page' : undefined}
              onClick={() => onTabClick(tab)}
            >
              {tab}
            </StyledTab>
          ))}
        </StyledTabs>
      </Box>
    </Box>
  );
};
