import { SequenceStepScheduleOptions } from '@/shared/types/sequences';

export const renderDelayText = (scheduleOptions: SequenceStepScheduleOptions) => {
  const { days, hours, minutes } = scheduleOptions;

  const daysNumber = days ? Number(days) : 0;
  const hoursNumber = hours ? Number(hours) : 0;
  const minutesNumber = minutes ? Number(minutes) : 0;

  const daysText =
    daysNumber > 1 ? `${daysNumber} days` : daysNumber === 1 ? '1 day' : null;
  const hoursText =
    hoursNumber > 1 ? `${hoursNumber} hours` : hoursNumber === 1 ? '1 hour' : null;
  const minutesText =
    minutesNumber > 1
      ? `${minutesNumber} minutes`
      : minutesNumber === 1
        ? '1 minute'
        : null;

  if (daysNumber === 0 && hoursNumber === 0 && minutesNumber === 0) {
    return 'Send Immediately';
  }

  if (daysText && !hoursText && !minutesText) {
    return 'Wait ' + daysText;
  }

  if (!daysText && hoursText && !minutesText) {
    return 'Wait ' + hoursText;
  }

  if (!daysText && !hoursText && minutesText) {
    return 'Wait ' + minutesText;
  }

  if (daysText && hoursText && !minutesText) {
    return `Wait ${daysText}, ${hoursText}`;
  }

  if (daysText && !hoursText && minutesText) {
    return `Wait ${daysText}, ${minutesText}`;
  }

  if (!daysText && hoursText && minutesText) {
    return `Wait ${hoursText}, ${minutesText}`;
  }

  if (daysText && hoursText && minutesText) {
    return `Wait ${daysText}, ${hoursText}, ${minutesText}`;
  }
};
