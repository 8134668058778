import { FormikValues, useFormikContext } from 'formik';
import { get } from 'lodash';
import { useEffect, useMemo } from 'react';

import { TextInput } from '@/shared/components/forms';
import { TextInputProps } from '@/shared/components/forms/types';

type TextInputWithValidationProps = TextInputProps & {
  onValidateOnChange?: (name: string, value: string) => void;
};
export const TextInputWithValidation = (props: TextInputWithValidationProps) => {
  const { onValidateOnChange } = props;
  const formik = useFormikContext<FormikValues>();

  const value = useMemo(() => get(formik.values, props.name as string), [formik]);

  useEffect(() => {
    if (props?.name && value && onValidateOnChange) {
      onValidateOnChange(props?.name || '', value);
    }
  }, [onValidateOnChange, value]);

  return <TextInput {...props} />;
};
