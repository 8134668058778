import React, { useState } from 'react';

import { findOrganizationId, getAccessToken } from '../api/api';
import { Box, Button } from '../ui';

export const EventStreamDemo = () => {
  const [response, setResponse] = useState('');

  const generateResponse = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    setResponse('');

    const prompt = {
      model: 'gpt-4',
      messages: [
        { role: 'system', content: 'You are a helpful assistant.' },
        { role: 'user', content: 'Hello, I need help with something.' },
      ],
    };

    const response = await fetch('http://localhost:4000/api/chat/stream', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        organization_id: findOrganizationId(),
        authorization: getAccessToken(),
      },
      body: JSON.stringify(prompt),
    });

    if (!response.ok) {
      throw new Error(response.statusText);
    }

    const data = response.body;
    if (!data) {
      return;
    }

    const reader = data.getReader();
    const decoder = new TextDecoder();
    let done = false;

    while (!done) {
      const { value, done: doneReading } = await reader.read();
      done = doneReading;
      const chunk = decoder.decode(value);

      // Extract the message string
      const match = chunk.match(/data: ({"message": ".*?"})/);

      if (match) {
        const { message } = JSON.parse(match[1]);
        // Handle null message values
        const text = message === null ? ' ' : message;
        setResponse((prev) => prev + text);
      } else {
        setResponse((prev) => prev + '\n');
      }
    }
  };

  return (
    <Box css={{ p: 20 }}>
      <Button variant="gray" onClick={generateResponse}>
        Generate Response
      </Button>
      <Box css={{ pt: 20 }}>Response</Box>
      <Box css={{ py: 20, whiteSpace: 'pre-line' }}>{response}</Box>
    </Box>
  );
};
