import { createContext, ReactNode, useContext, useEffect, useReducer } from 'react';
import { toast } from 'sonner';

import { prepareFilters } from '@/pages/data/utils/prepareFilters';
import * as API from '@/shared/api/data';
import { SearchFilters } from '@/shared/types/contacts';
import i18next from '@/shared/utils/translation';

import {
  CustomDataActionsType,
  CustomDataState,
  CustomObject,
  CustomObjectParams,
  CustomPropertyParams,
  CustomRecordParams,
  UpdateCustomObjectParams,
} from '../../../../../shared/types/data';
import CustomDataReducer from './CustomDataReducer';

export const PAGE_SIZE = 10;

export const initialCustomDataState: CustomDataState = {
  customObjects: [],
  current: null,
  properties: [],
  error: null,
  loading: true,
  loadingProperties: true,
  totalCount: 0,
  propertiesTotalCount: 0,
  filterParams: {
    offset: 0,
    limit: PAGE_SIZE,
    sort: [],
    filter: [],
    searchFilter: [],
  },
  customPropertiesFilterParams: {
    offset: 0,
    limit: PAGE_SIZE,
    sort: [],
    filter: [],
    searchFilter: [],
  },
};

export const CustomDataContext = createContext<{
  customDataState: CustomDataState;
  getCustomObjects: (
    params: SearchFilters
  ) => Promise<{ data: CustomObject[] | null[]; total: number }>;
  createCustomObject: (params: CustomObjectParams) => Promise<void>;
  getCustomObject: (id: string) => Promise<CustomObject | null>;
  updateCustomObject: (id: string, params: UpdateCustomObjectParams) => Promise<void>;
  deleteCustomObject: (id: string) => Promise<void>;
  getCustomProperties: (id: string, params: SearchFilters) => Promise<void>;
  createCustomProperty: (id: string, params: CustomPropertyParams) => Promise<void>;
  updateCustomProperty: (
    id: string,
    propertyId: string,
    params: CustomPropertyParams
  ) => Promise<void>;
  deleteCustomProperty: (id: string, propertyId: string) => Promise<void>;
  createCustomRecord: (id: string, params: CustomRecordParams) => Promise<void>;
  setCurrent: (customObject: CustomObject | null) => void;
  clearCurrent: () => void;
  clearProperties: () => void;
  updateFilterParams: (params: SearchFilters) => void;
  updateCustomPropertiesFilterParams: (params: SearchFilters) => void;
}>({
  customDataState: initialCustomDataState,
  getCustomObjects: () => Promise.resolve({} as { data: CustomObject[]; total: number }),
  getCustomObject: () => Promise.resolve({} as CustomObject),
  createCustomObject: () => Promise.resolve(),
  updateCustomObject: () => Promise.resolve(),
  deleteCustomObject: () => Promise.resolve(),
  getCustomProperties: () => Promise.resolve(),
  createCustomProperty: () => Promise.resolve(),
  updateCustomProperty: () => Promise.resolve(),
  deleteCustomProperty: () => Promise.resolve(),
  createCustomRecord: () => Promise.resolve(),
  setCurrent: () => Promise.resolve(),
  clearCurrent: () => Promise.resolve(),
  clearProperties: () => Promise.resolve(),
  updateFilterParams: () => Promise.resolve(),
  updateCustomPropertiesFilterParams: () => Promise.resolve(),
});

export const useCustomData = () => useContext(CustomDataContext);

const CustomDataProvider = ({ children }: { children: ReactNode }) => {
  const [customDataState, dispatch] = useReducer(
    CustomDataReducer,
    initialCustomDataState
  );

  useEffect(() => {
    getCustomObjects(customDataState.filterParams);
  }, [customDataState.filterParams]);

  useEffect(() => {
    customDataState.current?.id &&
      getCustomProperties(
        customDataState.current.id,
        customDataState.customPropertiesFilterParams
      );
  }, [customDataState.customPropertiesFilterParams]);

  const getCustomObjects = async (
    params: SearchFilters
  ): Promise<{ data: CustomObject[]; total: number }> => {
    try {
      const filters = prepareFilters(params);
      const data = await API.getCustomObjects(filters);

      dispatch({
        type: CustomDataActionsType.GET_CUSTOM_OBJECTS,
        payload: { ...data },
      });

      return data;
    } catch (error) {
      dispatch({
        type: CustomDataActionsType.SET_ERROR,
        payload: error,
      });

      return Promise.reject(error);
    }
  };

  const getCustomObject = async (id: string): Promise<CustomObject | null> => {
    try {
      const filter = [
        {
          column: 'id',
          comparison: '==',
          resource: 'custom_object',
          value: `${id}`,
        },
      ];
      const { data } = await API.getCustomObjects({
        ...initialCustomDataState.filterParams,
        filter,
      });

      dispatch({
        type: CustomDataActionsType.GET_CUSTOM_OBJECT,
        payload: data[0],
      });

      return data[0];
    } catch (error) {
      dispatch({ type: CustomDataActionsType.SET_ERROR, payload: error });
      console.error(error);
      return Promise.reject(error);
    }
  };

  const getCustomProperties = async (id: string, params: SearchFilters) => {
    try {
      const filters = prepareFilters(params);
      const data = await API.getCustomProperties(id, filters);

      if (data) {
        dispatch({
          type: CustomDataActionsType.GET_CUSTOM_OBJECT_PROPERTIES,
          payload: data,
        });
        return data;
      }
    } catch (error) {
      dispatch({
        type: CustomDataActionsType.SET_ERROR,
        payload: error,
      });
    }
  };

  const createCustomObject = async (params: CustomObjectParams) => {
    try {
      const { data } = await API.createCustomObject(params);

      dispatch({
        type: CustomDataActionsType.ADD_CUSTOM_OBJECT,
        payload: data,
      });
      getCustomObjects(customDataState.filterParams);
      toast.success(i18next.t('custom_object_create_success') as string);
    } catch (error) {
      dispatch({ type: CustomDataActionsType.SET_ERROR, payload: error });
      if (error?.response?.data?.errors?.[0]?.description) {
        toast.error(error?.response?.data?.errors?.[0]?.description);
      }
    }
  };

  const updateCustomObject = async (id: string, params: UpdateCustomObjectParams) => {
    try {
      const { data } = await API.updateCustomObject(id, params);

      if (data) {
        dispatch({
          type: CustomDataActionsType.UPDATE_CUSTOM_OBJECT,
          payload: data,
        });
        getCustomObjects(customDataState.filterParams);
        toast.success(i18next.t('custom_object_update_success') as string);
      }
    } catch (err) {
      if (err?.response) {
        dispatch({
          type: CustomDataActionsType.SET_ERROR,
          payload: err.response.msg,
        });
      }
      getCustomObjects(customDataState.filterParams);
      toast.error(i18next.t('custom_object_update_failure') as string);
    }
  };

  const deleteCustomObject = async (id: string) => {
    try {
      const data = await API.deleteCustomObject(id);

      if (data) {
        dispatch({
          type: CustomDataActionsType.DELETE_CUSTOM_OBJECT,
          payload: id,
        });
        getCustomObjects(customDataState.filterParams);
        toast.success(i18next.t('custom_object_delete_success') as string);
      }
    } catch (error) {
      dispatch({ type: CustomDataActionsType.SET_ERROR, payload: error });
      toast.error(i18next.t('custom_object_delete_failure') as string);
    }
  };

  const createCustomProperty = async (
    customObjectId: string,
    params: CustomPropertyParams
  ) => {
    try {
      const { data } = await API.createCustomObjectProperty(customObjectId, params);
      if (data) {
        dispatch({
          type: CustomDataActionsType.ADD_CUSTOM_OBJECT_PROPERTY,
          payload: data,
        });
        toast.success(i18next.t('custom_property_create_success') as string);
        getCustomProperties(customObjectId, customDataState.customPropertiesFilterParams);
        return data;
      }
    } catch (error) {
      dispatch({ type: CustomDataActionsType.SET_ERROR, payload: error });
      toast.error(i18next.t('custom_property_create_failure') as string);
    }
  };

  const updateCustomProperty = async (
    customObjectId: string,
    customPropertyId: string,
    params: CustomPropertyParams
  ) => {
    try {
      const { data } = await API.updateCustomProperty(
        customObjectId,
        customPropertyId,
        params
      );
      if (data) {
        dispatch({
          type: CustomDataActionsType.UPDATE_CUSTOM_OBJECT_PROPERTY,
          payload: data,
        });
        toast.success(i18next.t('custom_property_update_success') as string);
        getCustomProperties(customObjectId, customDataState.customPropertiesFilterParams);
        return data;
      }
    } catch (error) {
      dispatch({ type: CustomDataActionsType.SET_ERROR, payload: error });
      toast.error(i18next.t('custom_property_update_failure') as string);
    }
  };

  const deleteCustomProperty = async (id: string, propertyId: string) => {
    try {
      const data = await API.deleteCustomProperty(id, propertyId);

      if (data) {
        dispatch({
          type: CustomDataActionsType.DELETE_CUSTOM_OBJECT_PROPERTY,
          payload: propertyId,
        });
        toast.success(i18next.t('custom_property_delete_success') as string);
        getCustomProperties(id, customDataState.customPropertiesFilterParams);
      }
    } catch (error) {
      dispatch({ type: CustomDataActionsType.SET_ERROR, payload: error });
      toast.error(i18next.t('custom_property_delete_failure') as string);
    }
  };

  const createCustomRecord = async (
    customObjectId: string,
    params: CustomRecordParams
  ) => {
    try {
      const { data } = await API.createCustomObjectRecord(customObjectId, params);
      if (data) {
        dispatch({
          type: CustomDataActionsType.ADD_CUSTOM_OBJECT_RECORDS,
          payload: data,
        });
        getCustomProperties(customObjectId, customDataState.customPropertiesFilterParams);
        return data;
      }
    } catch (error) {
      dispatch({ type: CustomDataActionsType.SET_ERROR, payload: error });
    }
  };

  const setCurrent = (data: CustomObject | null) => {
    dispatch({ type: CustomDataActionsType.SET_CURRENT, payload: data });
  };

  const clearCurrent = () => {
    dispatch({ type: CustomDataActionsType.CLEAR_CURRENT });
  };

  const clearProperties = () => {
    dispatch({ type: CustomDataActionsType.CLEAR_PROPERTIES });
  };

  const updateFilterParams = async (params: SearchFilters) => {
    try {
      dispatch({
        type: CustomDataActionsType.UPDATE_FILTER_PARAMS,
        payload: params,
      });
    } catch (err) {
      console.error(err);
    }
  };

  const updateCustomPropertiesFilterParams = async (params: SearchFilters) => {
    try {
      dispatch({
        type: CustomDataActionsType.UPDATE_CUSTOM_OBJECTS_FILTER_PARAMS,
        payload: params,
      });
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <CustomDataContext.Provider
      value={{
        customDataState,
        getCustomObjects,
        createCustomObject,
        getCustomObject,
        updateCustomObject,
        deleteCustomObject,
        createCustomProperty,
        updateCustomProperty,
        getCustomProperties,
        deleteCustomProperty,
        createCustomRecord,
        setCurrent,
        clearCurrent,
        clearProperties,
        updateFilterParams,
        updateCustomPropertiesFilterParams,
      }}
    >
      {children}
    </CustomDataContext.Provider>
  );
};

export default CustomDataProvider;
