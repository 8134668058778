import dayjs from 'dayjs';
import React, { useMemo } from 'react';
import { HiOutlineClock, HiPaperAirplane, HiX } from 'react-icons/hi';

import { useUsers } from '@/pages/settings/organization/users/context/UserContext';
import { ConfirmationDialog } from '@/shared/components/ConfirmationDialog';
import { TooltipIconButton } from '@/shared/components/TooltipIconButton';
import { useDisclosure } from '@/shared/hooks';
import { ConversationScheduledMessageType } from '@/shared/types/conversations';
import { User } from '@/shared/types/users';
import {
  Avatar,
  Box,
  Flex,
  HStack,
  IconButton,
  Tooltip,
  TooltipArrow,
  TooltipContent,
  TooltipTrigger,
  VStack,
} from '@/shared/ui';
import { initials } from '@/shared/utils/initials/initials';
import { isValidUuid } from '@/shared/utils/validations/validations';
import { styled } from '@/stitches.config';

import SendNowConfirmation from '../../scheduled/SendNowConfirmation';
import { UpdateScheduledMessageDialog } from '../../scheduled/UpdateScheduledMessageDialog';

type ConversationScheduledItemProps = {
  scheduledMessage: ConversationScheduledMessageType;
  deleteScheduledMessage: (id: number) => void;
  sendScheduledMessageNow: (id: number) => void;
  mouseEventHandler: (id: number | null) => void;
  hoverItemId?: number | null;
};

export const ConversationScheduledItem = (props: ConversationScheduledItemProps) => {
  const {
    scheduledMessage,
    deleteScheduledMessage,
    sendScheduledMessageNow,
    mouseEventHandler,
    hoverItemId,
  } = props;

  const {
    userState: { users },
  } = useUsers();

  const user = useMemo(() => {
    return users.filter((user: User) => user.id === scheduledMessage?.user_id)?.[0];
  }, [scheduledMessage?.user_id, users]);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleDelete = () => {
    deleteScheduledMessage(scheduledMessage.job_id);
  };

  const onMouseEnter = () => {
    mouseEventHandler(scheduledMessage?.job_id);
  };

  const onMouseLeave = () => {
    if (scheduledMessage.job_id === hoverItemId && !isOpen) {
      mouseEventHandler(null);
    }
  };

  return (
    <Flex
      align="center"
      justify="between"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      css={{
        backgroundColor:
          scheduledMessage.job_id === hoverItemId ? '#F6F6F6' : 'transparent',
        px: 20,
        py: 10,
        position: 'relative',
      }}
    >
      <VStack gap="1" css={{ width: '100%' }}>
        <Flex justify="between" css={{ width: '100%' }}>
          <HStack>
            <Box css={{ mr: 2 }}>
              <Avatar
                src={user?.attachment?.url}
                fallback={initials(user?.name || user?.email)}
                variant="pink"
                size={2}
              />
            </Box>
            <ScheduleTitleText>{user?.name || user?.email}</ScheduleTitleText>
          </HStack>
          <HStack>
            <HiOutlineClock />
            <DateText>
              {dayjs(scheduledMessage.execution_time.substring(0, 19)).format(
                'ddd MMM D, YYYY h:mm a'
              )}
            </DateText>
          </HStack>
        </Flex>
        <ScheduledMessageText>{scheduledMessage.message.body}</ScheduledMessageText>
      </VStack>
      {scheduledMessage.job_id === hoverItemId && (
        <ActionsWrapper>
          <HStack>
            <SendNowConfirmation
              id={scheduledMessage.job_id}
              onDelete={sendScheduledMessageNow}
            >
              <Tooltip>
                <TooltipTrigger asChild>
                  <IconButton>
                    <HiPaperAirplane style={{ transform: 'rotate(90deg)' }} />
                  </IconButton>
                </TooltipTrigger>
                <TooltipContent>
                  Send Now
                  <TooltipArrow />
                </TooltipContent>
              </Tooltip>
            </SendNowConfirmation>
            {isValidUuid(scheduledMessage.conversation_id || '') && (
              <UpdateScheduledMessageDialog
                id={scheduledMessage.job_id}
                location_id={''}
                message={scheduledMessage.message || ''}
                schedule={scheduledMessage.execution_time || ''}
                // fallback to browser timezone if timezone is not set
                timezone={
                  scheduledMessage?.timezone ||
                  Intl.DateTimeFormat().resolvedOptions().timeZone ||
                  ''
                }
                buttonVariant={'ghost'}
                isInbox={true}
                isOpen={isOpen}
                onOpen={onOpen}
                onClose={onClose}
              />
            )}
            <ConfirmationDialog
              title="Delete Scheduled Message"
              description="Are you sure you want to delete your scheduled message?"
              onConfirm={handleDelete}
              confirmButtonTitle="Yes, Delete Message"
              testID="delete-scheduled-message-button"
            >
              <TooltipIconButton
                tooltipArrow
                text="Delete Scheduled Message"
                icon={<HiX />}
              />
            </ConfirmationDialog>
          </HStack>
        </ActionsWrapper>
      )}
    </Flex>
  );
};

const ScheduledMessageText = styled(Box, {
  display: '-webkit-box',
  '-webkit-line-clamp': 1,
  '-webkit-box-orient': 'vertical',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  fontSize: '12px',
  fontWeight: '400',
  maxWidth: 450,
});

const ScheduleTitleText = styled(Box, {
  display: '-webkit-box',
  '-webkit-line-clamp': 1,
  '-webkit-box-orient': 'vertical',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  fontSize: '13px',
  fontWeight: '500',
  maxWidth: 100,
});

const DateText = styled(Box, {
  fontSize: '12px',
});

const ActionsWrapper = styled(Box, {
  padding: '8.5px',
  borderRadius: 6,
  border: '1px solid #E8E8E8',
  position: 'absolute',
  top: 10,
  right: 20,
  backgroundColor: 'white',
});
