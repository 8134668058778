import { NotificationsState } from './NotificationContext';
import { ADD_NOTIFICATION, DELETE_NOTIFICATION, GET_NOTIFICATIONS } from './types';

type NotificationsAction =
  | { type: 'GET_NOTIFICATIONS'; payload: any }
  | { type: 'ADD_NOTIFICATION'; payload: any }
  | { type: 'DELETE_NOTIFICATION'; payload: any };

const NotificationReducer = (state: NotificationsState, action: NotificationsAction) => {
  switch (action.type) {
    case GET_NOTIFICATIONS:
      return {
        ...state,
        notifications: action.payload,
        loading: false,
      };
    case ADD_NOTIFICATION:
      return {
        ...state,
        notifications: [action.payload, ...state.notifications],
        loading: false,
      };
    case DELETE_NOTIFICATION:
      return {
        ...state,
        notifications: state.notifications.filter(
          (notification) => notification.id !== action.payload.id
        ),
        loading: false,
      };
    default:
      return state;
  }
};

export default NotificationReducer;
