import dayjs from 'dayjs';
import React from 'react';

import {
  Button,
  Dialog,
  DialogContent,
  DialogPortal,
  DialogTitle,
  Flex,
  HStack,
  Text,
  VStack,
} from '@/shared/ui';

import { DatePickerErrorBoundary } from './boundaries/DatePickerErrorBoundaries';
import CustomizedDatePicker from './CustomizedDatePicker';

type DateSelectionDialogPropsType = {
  isDatePickerDialogOpen: boolean;
  setIsDatePickerDialogOpen: (value: boolean) => void;
  isDatePickerOpen: boolean;
  setIsDatePickerOpen: (value: boolean) => void;
  selectedCustomizedDate: string;
  setSelectedCustomizedDate: (value: string) => void;
};

const DateSelectionDialog = (props: DateSelectionDialogPropsType): JSX.Element => {
  const {
    isDatePickerDialogOpen,
    setIsDatePickerDialogOpen,
    isDatePickerOpen,
    setIsDatePickerOpen,
    selectedCustomizedDate,
    setSelectedCustomizedDate,
  } = props;

  const [newSelectedCustomizedDate, setNewSelectedCustomizedDate] =
    React.useState<string>(selectedCustomizedDate);

  const handleSelectDate = (e: any) => {
    const newSelectedCustomizedDate = dayjs(e.$d).format('MM/DD/YYYY');
    setNewSelectedCustomizedDate(newSelectedCustomizedDate);
  };

  const handleSaveDate = () => {
    setSelectedCustomizedDate(newSelectedCustomizedDate);
    setIsDatePickerDialogOpen(false);
  };

  return (
    <Dialog open={isDatePickerDialogOpen} modal={false}>
      <DialogPortal>
        <DialogContent
          onEscapeKeyDown={() => setIsDatePickerDialogOpen(false)}
          css={{ zIndex: 100001, width: '450px' }}
        >
          <DialogTitle>Date Selection</DialogTitle>
          <Text>Choose a date to temporarily suspend the selected active channels.</Text>
          <VStack gap={1} css={{ pt: 20 }}>
            <DatePickerErrorBoundary feature="datePicker">
              <CustomizedDatePicker
                isDatePickerOpen={isDatePickerOpen}
                setIsDatePickerOpen={setIsDatePickerOpen}
                newSelectedCustomizedDate={newSelectedCustomizedDate}
                handleSelectDate={handleSelectDate}
              />
            </DatePickerErrorBoundary>
          </VStack>
          <Flex justify="end" css={{ pt: 20 }}>
            <HStack>
              <Button variant="primary" onClick={handleSaveDate}>
                Save
              </Button>
              <Button variant="gray" onClick={() => setIsDatePickerDialogOpen(false)}>
                Cancel
              </Button>
            </HStack>
          </Flex>
        </DialogContent>
      </DialogPortal>
    </Dialog>
  );
};

export default DateSelectionDialog;
