/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-empty-function */
import { mauve } from '@radix-ui/colors';
import { Item } from '@radix-ui/react-dropdown-menu';
import dayjs from 'dayjs';
import { useMemo } from 'react';
import { HiClipboard } from 'react-icons/hi';

import { useAuth } from '@/auth/context/AuthProvider';
import { ConfirmationDialog } from '@/shared/components/ConfirmationDialog';
import { CopyToClipboard } from '@/shared/components/CopyToClipboard';
import { Invite } from '@/shared/types';
import { Channel } from '@/shared/types/channels';
import { ChannelsStates } from '@/shared/types/channels';
import { UserRoles } from '@/shared/types/users';
import { Box, Flex, HStack, VStack } from '@/shared/ui';
import { ComboboxMultiselect } from '@/shared/v2/components/comboboxMultiselect/ComboboxMultiselect';
import { ComboboxMultiselectItem } from '@/shared/v2/components/comboboxMultiselect/ComboboxMultiselectItem';
import { ComboboxMultiselectTrigger } from '@/shared/v2/components/comboboxMultiselect/ComboboxMultiselectTrigger';
import { Table, TableColumn } from '@/shared/v2/components/table/Table';
import { TableActionMenu } from '@/shared/v2/components/table/TableActionMenu';
import { styled } from '@/stitches.config';

import { useChannels } from '../channels/context/ChannelContext';
import { AddUser } from './AddUser';
import { PAGE_SIZE, useUsers } from './context/UserContext';

export const InvitesTable = () => {
  const { userState, deleteInvite, addUsers, updateInvitesFilterParams } = useUsers();
  const { invites, loading, invitesFilterParams, totalCount } = userState;

  const location = useChannels();
  const { allChannels } = location.channelsState;
  const locations = allChannels;

  const auth = useAuth();
  const currentUserRole = auth?.tokens?.role;

  const columns: Array<TableColumn<Invite>> = useMemo(
    () => [
      {
        Header: 'Email',
        colWidth: '50%',
        accessor: 'name',
        Cell: (props: { row: { original: Invite } }) => (
          <HStack gap={2} align="center">
            <VStack gap="1">
              <Box css={{ color: '#718196', fontSize: 13 }}>
                <CopyToClipboard
                  copy={props.row.original.email}
                  description="Copy user email"
                  successMessage="Email copied to clipboard"
                >
                  <Flex align="center">
                    <Flex>{props.row.original.email}</Flex>
                    <Flex css={{ mx: 4 }}>
                      <HiClipboard />
                    </Flex>
                  </Flex>
                </CopyToClipboard>
              </Box>
            </VStack>
          </HStack>
        ),
      },
      {
        Header: 'Channel Access',
        accessor: 'locations',
        Cell: (props: { row: { original: Invite } }) => {
          const invite = props.row.original;
          const initialValues = invite.locations?.map((item: Channel) => item?.id);
          return (
            <Box css={{ width: 300 }}>
              <ComboboxMultiselect
                options={
                  locations
                    .filter((location) => location?.state === ChannelsStates.ENABLED)
                    .map((item) => ({ value: item.id, label: item.name as string })) || []
                }
                selected={initialValues || []}
                onSelect={() => {}}
                Trigger={ComboboxMultiselectTrigger}
                Option={ComboboxMultiselectItem}
                disabled={true}
                width={300}
                selectAll
              />
            </Box>
          );
        },
      },
      {
        Header: 'Invited At',
        colWidth: '50%',
        accessor: 'inserted_at',
        Cell: (props: { row: { original: Invite } }) => {
          const invite = props.row.original;
          return (
            <Flex>{dayjs(invite.inserted_at).format('MMM D[,] YYYY[,] h:mmA')}</Flex>
          );
        },
      },
      {
        Header: 'Invite Expired',
        accessor: 'expired',
        Cell: (props: { row: { original: Invite } }) => {
          const invite = props.row.original;
          return <Flex>{invite.expired ? 'True' : 'False'}</Flex>;
        },
      },
      {
        Header: 'Actions',
        accessor: 'id',
        Cell: (props: { row: { original: Invite } }) => {
          const invite = props.row.original;
          return (
            <TableActionMenu disabled={currentUserRole !== UserRoles.ADMIN}>
              <>
                <ConfirmationDialog
                  width="432px"
                  title="Resend invite?"
                  description={
                    <ConfirmationDialogDescription
                      value={invite.email}
                      description="will receive new invite."
                    />
                  }
                  onConfirm={() =>
                    addUsers({
                      email: invite.email,
                      locations: invite.locations?.map((location) => location.id) ?? [],
                    })
                  }
                  confirmButtonTitle="Confirm"
                  cancelButtonTitle="Cancel"
                  confirmButtonVariant="primary"
                  cancelButtonVariant="grayBackground"
                >
                  <CustomDropdownMenuItem
                    data-testid="resend-invite-option"
                    onClick={(e) => e.preventDefault()}
                  >
                    Resend invite
                  </CustomDropdownMenuItem>
                </ConfirmationDialog>
                <ConfirmationDialog
                  width="432px"
                  title="Cancel invite?"
                  description={
                    <ConfirmationDialogDescription
                      value={invite.email}
                      description="invite will be canceled."
                    />
                  }
                  onConfirm={() => deleteInvite(invite.id.toString(), invite.email)}
                  confirmButtonTitle="Confirm"
                  cancelButtonTitle="Cancel"
                  confirmButtonVariant="redBackground"
                  cancelButtonVariant="grayBackground"
                >
                  <CustomDropdownMenuItemWarning
                    data-testid="cancel-invite-option"
                    onClick={(e) => e.preventDefault()}
                  >
                    Cancel invite
                  </CustomDropdownMenuItemWarning>
                </ConfirmationDialog>
              </>
            </TableActionMenu>
          );
        },
      },
    ],
    [invites]
  );

  return (
    <Table
      data={invites}
      columns={columns}
      caption="Users Table"
      totalCount={totalCount}
      setOffset={(offset) => {
        updateInvitesFilterParams({
          ...invitesFilterParams,
          offset,
        });
      }}
      pageSize={PAGE_SIZE}
      emptyTitle="No Users Match Search"
      isLoading={loading}
      colHeight={43}
      empty={
        <>
          <Box
            css={{
              width: 250,
              fontSize: 16,
              marginTop: 8,
              marginBottom: 16,
              fontWeight: 400,
            }}
          >
            Change your search parameters or invite a new user
          </Box>
          <Box>{currentUserRole === UserRoles.ADMIN ? <AddUser /> : null}</Box>
        </>
      }
    />
  );
};

export const CustomDropdownMenuItem = styled(Item, {
  all: 'unset',
  lineHeight: 1,
  display: 'flex',
  alignItems: 'center',
  minHeight: 25,
  padding: '3px 12px',
  position: 'relative',
  userSelect: 'none',
  cursor: 'pointer',
  borderRadius: '4px',
  fontSize: 14,
  color: '#1C2024',
  '&:hover': {
    backgroundColor: '#3A5CCC',
    color: '#FFF',
  },
  '&[data-disabled]': {
    color: mauve.mauve8,
    pointerEvents: 'none',
  },
});

export const CustomDropdownMenuItemWarning = styled(Item, {
  all: 'unset',
  lineHeight: 1,
  display: 'flex',
  alignItems: 'center',
  minHeight: 25,
  padding: '3px 12px',
  position: 'relative',
  userSelect: 'none',
  cursor: 'pointer',
  borderRadius: '4px',
  fontSize: 14,
  color: '#D93D42',
  '&:hover': {
    backgroundColor: '#D93D42',
    color: '#FFF',
  },
});

export const ConfirmationDialogDescription = ({
  value,
  description,
}: {
  value: string;
  description: string;
}) => {
  return (
    <div>
      <span style={{ fontWeight: 700 }}>{value}</span> {description}
    </div>
  );
};
