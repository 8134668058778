import { useState } from 'react';

import { Box, Button, Flex, TextArea } from '@/shared/ui';

export const WebCallEnd = () => {
  const [feedback, setFeedback] = useState('');
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = () => {
    console.log(feedback);
    setSubmitted(true);
  };

  return (
    <Flex css={{ p: 30 }} direction="column" align="center">
      <Box css={{ marginTop: '20px', textAlign: 'center' }}>
        <Box css={{ fontSize: 20, fontWeight: 600 }}>
          Call Ended. Thank you for you time.
        </Box>
        {!submitted ? (
          <>
            <Box css={{ fontSize: 16, fontWeight: 500, my: 20 }}>
              We value your feedback
            </Box>
            <TextArea
              placeholder="Please leave your feedback here..."
              style={{ width: '100%', height: '100px', marginTop: '10px' }}
              value={feedback}
              onChange={(e) => setFeedback(e.target.value)}
            />
            <Button css={{ mt: 10 }} onClick={handleSubmit}>
              Submit Feedback
            </Button>
          </>
        ) : (
          <div>Thank you, we&apos;ll be in contact soon.</div>
        )}
      </Box>
    </Flex>
  );
};
