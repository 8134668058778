import React, { useMemo } from 'react';

import { User } from '@/shared/types/users';
import { Avatar, Box } from '@/shared/ui';
import { initials } from '@/shared/utils/initials/initials';
import { styled } from '@/stitches.config';

import { useUsers } from '../users/context/UserContext';

type TeamMemberPreviewProps = {
  /** id of user to get user's data to show avatar ot initials */
  user_id: number;
  /** index of team members list */
  index: number;
  /** total count of team members list */
  total: number;
};

export const TeamMemberPreview = ({ user_id, index, total }: TeamMemberPreviewProps) => {
  const {
    userState: { users },
  } = useUsers();

  const user = useMemo(() => {
    return users.filter((user: User) => user.id === user_id)[0];
  }, [user_id, users]);

  return (
    <StyledTeamMember style={{ zIndex: total - index }}>
      <StylesAvatar
        css={{ border: '2px solid $slate1' }}
        variant="purple"
        size="assignPanel"
        src={user?.attachment?.url}
        fallback={initials(user?.name || user?.email)}
      />
    </StyledTeamMember>
  );
};

const StyledTeamMember = styled(Box, {
  ml: '-4px',
  position: 'relative',
});

const StylesAvatar = styled(Avatar, {
  border: '2px solid $slate1',
});
