import * as API from '@/shared/api/attachments';

import { FileUpload } from '../editor/v2/constants';

/**
 * Sends a request to the backend to generate a presigned url
 * for each of the files passed as a parameter.
 * It then sends a PUT request to that presigned url.
 * Uploads files to S3 using presigned urls
 * @param files - Array of files to upload
 * @returns Array of FileUpload objects
 */

export const directUpload = (files: Array<File>): Promise<FileUpload[]> => {
  // Return an array of promises by iterating through the files and uploading them
  return Promise.all(
    files.map((file: File) => {
      const params = {
        filename: file.name.replace(/\s/g, '_'),
      };

      return new Promise((resolve, reject) => {
        API.createPresignedUrl(params)
          .then((data) => {
            // uploading the object to s3 and appending the
            // public url to the current message
            fetch(data.presigned_url, { method: 'PUT', body: file })
              .then((response) => {
                if (response.status === 200) {
                  resolve(data);
                } else {
                  reject();
                }
              })
              .catch(() => reject());
          })
          .catch(() => reject());
      });
    })
  ) as Promise<FileUpload[]>;
};

/**
 * Legacy version of directUpload function to be deprecated and replaced by directUpload
 * * Sends a request to the backend to generate a presigned url
 * for each of the files passed as a parameter.
 * It then sends a PUT request to that presigned url
 * and invokes the callback passed as a second parameter.
 * Uploads files to S3 using presigned urls
 * @param files - Array of files to upload
 * @param successCallback - Callback called on successful upload of file
 * @param errorCallback - Callback called on error during upload
 */

export const directUploadLegacy = (
  files: Array<File>,
  successCallback: (data: {
    public_url: string;
    private_url: string;
    file_extension: string;
  }) => void,
  errorCallback: () => void
) => {
  // iterating through the files and uploading them
  files.map(async (file: File) => {
    const params = {
      filename: file.name.replace(/\s/g, '_'),
    };

    try {
      // loading the presigned url for the given file
      const data = await API.createPresignedUrl(params);

      // uploading the object to s3 and appending the
      // public url to the current message
      fetch(data.presigned_url, { method: 'PUT', body: file })
        .then((response) => {
          if (response.status === 200) {
            successCallback(data);
          } else {
            errorCallback();
          }
        })
        .catch(() => errorCallback());
    } catch (error) {
      errorCallback();
    }
  });
};

// sends a request to the backend to delete attachment from s3
export const deleteAttachment = async (public_url: string) => {
  const filename = public_url.split('organization_')[1];

  const params = {
    filename: `organization_${filename}`,
  };

  API.deleteAttachment(params);
};

export const deleteUnsentAttachments = (attachments: { attachment_urls: string[] }) => {
  attachments.attachment_urls.map((url: string) => deleteAttachment(url));
};

/**
 * Handle data transfer into blob.
 *
 * Get the a file image as blob, used for pasting an image from clipboard.
 * This function takes a DataTransfer object and returns the first blob it finds.
 * It is used in the onDrop event handler in the FileUpload component.
 *
 * @param {DataTransfer} dataTransfer
 * @returns {Blob | null}
 */

export const handleTransformDataTransferIntoBlob = (dataTransfer: DataTransfer) => {
  const [firstItem, secondItem] = dataTransfer.items;

  const firstBlob = firstItem ? firstItem.getAsFile() : null;

  const secondBlob = secondItem ? secondItem.getAsFile() : null;

  return firstBlob || secondBlob;
};

/**
 * Returns the file name of the pasted image. If there is no file name, give it a default name.
 * @param {string} fileType The type of the file, e.g. image/png.
 * @param {string} fileName The name of the file, e.g. my-image.png.
 * @returns {string} The name of the file, e.g. my-image.png.
 */

export const returnFileNameBasedOnFileType = (
  fileType: string,
  fileName?: string
): string => {
  // if there is a file name, use it, remove the current extension
  // and add the new one
  if (fileName) {
    // Split the fileName into two parts: the file name and the file extension
    const currentFileName = fileName.split('.')[0];
    const currentFileExtension = fileName.split('.')[1];

    // Get the file extension for the desired file type
    const fileExtension = returnFileExtension(fileType, currentFileExtension);

    // Return the new file name with the new file extension
    return `${currentFileName}.${fileExtension}`;
  } else {
    switch (fileType) {
      case 'image/png':
        return 'pasted-image.png';
      case 'image/gif':
        return 'pasted-image.gif';
      case 'image/jpeg':
        return 'pasted-image.jpeg';
      default:
        return 'pasted-image.jpeg';
    }
  }
};

// get the file extension based on the file type
export const returnFileExtension = (fileType: string, currentFileExtension?: string) => {
  switch (fileType) {
    case 'image/png':
      return 'png';
    case 'image/gif':
      return 'gif';
    case 'image/jpeg':
      return 'jpeg';
    // audio
    case 'audio/mpeg':
      return 'mp3';
    case 'audio/ogg':
      return 'ogg';
    case 'audio/wav':
      return 'wav';
    // video
    case 'video/mp4':
      return 'mp4';
    case 'video/ogg':
      return 'ogg';
    case 'video/webm':
      return 'webm';
    // pdf
    case 'application/pdf':
      return 'pdf';
    // doc
    case 'application/msword':
      return 'doc';
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      return 'docx';
    // xls
    case 'application/vnd.ms-excel':
      return 'xls';
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      return 'xlsx';
    // ppt
    case 'application/vnd.ms-powerpoint':
    default:
      return currentFileExtension || 'jpeg';
  }
};

/**
 * This function will return the file type based on the header of the file.
 * It will also return the blob type if the header does not match a file type.
 * @param header - The header of the file
 * @param blob - The original blob
 * @returns {string} - The file type
 */

export const returnFileType = (header: string, blob: File): string => {
  switch (header) {
    case '89504e47':
      return 'image/png';
    case '47494638':
      return 'image/gif';
    case 'ffd8ffe0':
    case 'ffd8ffe1':
    case 'ffd8ffe2':
    case 'ffd8ffe3':
    case 'ffd8ffe8':
      return 'image/jpeg';
    case '25504446':
      return 'application/pdf';
    default:
      return blob.type || 'image/jpeg';
  }
};

/**
 * Reads the header of a file and returns it as a string.
 * @param file The file to read.
 * @returns The header of the file as a string.
 */
export const readFileHeader = async (file: File) => {
  // 1. Create a new promise for reading the file
  return new Promise((resolve, reject) => {
    // 2. Create a new file reader
    const fileReader = new FileReader();

    // 3. Register the onload event
    fileReader.onload = () => {
      // 4. Create a new array of 4 bytes from the file header
      const arr = new Uint8Array(fileReader.result as ArrayBuffer).subarray(0, 4);

      // 5. Create a new string from the 4 bytes
      let header = '';
      for (let i = 0; i < arr.length; i++) {
        header += arr[i].toString(16);
      }

      // 6. Resolve the promise with the file header
      resolve(header);
    };

    // 7. Register the onerror event
    fileReader.onerror = () => {
      reject('Error reading file header.');
    };

    // 8. Read the first 4 bytes of the file
    fileReader.readAsArrayBuffer(file.slice(0, 4));
  }) as Promise<string>;
};
