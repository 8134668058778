import { toast } from 'sonner';

import { Flex, Tooltip, TooltipContent, TooltipTrigger } from '@/shared/ui';

type CopyToClipboardProps = {
  copy: string;
  description: string;
  successMessage: string;
  children?: JSX.Element | string;
};

export const CopyToClipboard = ({
  copy,
  description,
  successMessage,
  children,
}: CopyToClipboardProps) => (
  <div>
    <Tooltip>
      <TooltipTrigger>
        <Flex
          align="center"
          onClick={() => {
            navigator.clipboard.writeText(copy);
            toast.success(successMessage);
          }}
        >
          {children}
        </Flex>
      </TooltipTrigger>
      <TooltipContent>{description}</TooltipContent>
    </Tooltip>
  </div>
);
