import { UserPreferences } from './PreferencesContext';

// write a function that can take any key value pair or object and update the preferences, without overwriting the entire object
// this function should return a new object of Type UserPreferences
export const updatePreferencesObject = (
  preferences: UserPreferences,
  new_preferences: Partial<UserPreferences>
): UserPreferences => {
  return {
    ...preferences,
    ...new_preferences,
  };
};
