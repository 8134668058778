import React from 'react';
import { DatePicker } from 'react-dayjs-picker';
import { HiCalendar } from 'react-icons/hi';

import { Box } from '@/shared/ui';

type CustomizedDatePickerProps = {
  isDatePickerOpen: boolean;
  setIsDatePickerOpen: (value: boolean) => void;
  newSelectedCustomizedDate: string;
  handleSelectDate: (e: any) => void;
};

const CustomizedDatePicker: React.FC<CustomizedDatePickerProps> = (
  props: CustomizedDatePickerProps
) => {
  const {
    isDatePickerOpen,
    setIsDatePickerOpen,
    newSelectedCustomizedDate,
    handleSelectDate,
  } = props;

  const getDatePickerStyles = () => ({
    padding: '2px 10px',
    width: '100%',
    height: '35px',
    border: '1px solid #D7DBDF',
    borderRadius: '4px',
    fontSize: '13px',
    cursor: 'pointer',
    textAlign: 'left',
    pointerEvents: 'all',
    backgroundColor: 'transparent',
    color: '#000000',
    outlineColor: '#3E54CF',
    boxShadow: '0px 1.5px 2px 0px #05055806 inset, 0px 1.5px 2px 0px #00000006 inset',
  });

  const datePickerColors = {
    highlighted: 'hsl(0 0% 90.9%)',
    disabled: 'hsl(0 0% 78.0%)',
    default: '',
    active: '',
  };

  return (
    <Box css={{ position: 'relative' }}>
      <HiCalendar
        color="#60646C"
        style={{
          position: 'absolute',
          top: '50%',
          transform: 'translateY(-50%)',
          right: '10px',
          pointerEvents: 'none',
          fontSize: '16px',
        }}
      />
      <DatePicker
        isOpen={isDatePickerOpen}
        setIsOpen={setIsDatePickerOpen}
        zIndex={200000}
        value={`Pause Until ${newSelectedCustomizedDate}`}
        markToday={true}
        format="MMM-dd-yyyy"
        disableBeforeToday={true}
        inputStyle={getDatePickerStyles() as React.CSSProperties | undefined}
        colors={datePickerColors}
        onSelect={handleSelectDate}
        popoverPositions={['bottom']}
        closeOnSelect={true}
        style={{ pointerEvents: 'auto' }}
      />
    </Box>
  );
};

export default CustomizedDatePicker;
