import React from 'react';

import { useCampaignsContext } from '@/campaigns/context/CampaignsContext';
import { CampaignOverview as Overview } from '@/pages/campaigns/analytics/overview';

export const CampaignOverview = () => {
  const campaigns = useCampaignsContext();
  const { campaignsState, setCurrentCampaign } = campaigns;

  const { current } = campaignsState;

  return <Overview campaign={current} setCurrentCampaign={setCurrentCampaign} />;
};
