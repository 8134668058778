import React from 'react';
import { HiPaperAirplane } from 'react-icons/hi';

import { useDisclosure } from '@/shared/hooks';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogOverlay,
  AlertDialogPortal,
  AlertDialogTitle,
  AlertDialogTrigger,
  Button,
  Flex,
} from '@/shared/ui';

type SendNowConfirmationProps = {
  id: number;
  children?: React.ReactNode;
  onDelete: (id: number) => void;
};

const SendNowConfirmation = (props: SendNowConfirmationProps) => {
  const { id, children, onDelete } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();

  const onSend = () => {
    onDelete(id);
    onClose();
  };

  return (
    <AlertDialog open={isOpen}>
      <AlertDialogTrigger onClick={onOpen}>{children}</AlertDialogTrigger>
      <AlertDialogPortal>
        <AlertDialogOverlay css={{ pointerEvents: 'auto', zIndex: 5 }}>
          <AlertDialogContent onEscapeKeyDown={onClose}>
            <AlertDialogTitle>Send Scheduled Message Now</AlertDialogTitle>
            <AlertDialogDescription>
              Are you sure you want to send this message now ?
            </AlertDialogDescription>
            <Flex justify="end">
              <AlertDialogCancel asChild>
                <Button variant="gray" css={{ mr: 10 }} onClick={onClose}>
                  No
                </Button>
              </AlertDialogCancel>
              <AlertDialogAction asChild>
                <Button variant="send" onClick={onSend}>
                  Yes, Send Now
                  <HiPaperAirplane style={{ transform: 'rotate(90deg)' }} />
                </Button>
              </AlertDialogAction>
            </Flex>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialogPortal>
    </AlertDialog>
  );
};

export default SendNowConfirmation;
