/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'sonner';

import * as API from '@/shared/api/auth';
import { DotsTyping } from '@/shared/components/Icons';
import { usePageView } from '@/shared/hooks';
import { Box, Button, Flex } from '@/shared/ui';
import i18next from '@/shared/utils/translation';
import { isValidUuid } from '@/shared/utils/validations/validations';
import { styled } from '@/stitches.config';

import { useAuth } from './context/AuthProvider';

type ParamTypes = {
  id: string;
};

export const Join = () => {
  usePageView();

  // auth context
  const auth = useAuth();

  // react router
  const history = useHistory();
  const invite = useParams<ParamTypes>();

  // invite state
  const [loading, setLoading] = useState(true);
  const [inviteToken, setInviteToken] = useState('');
  const [invitedBy, setInvitedBy] = useState('');
  const [isAlreadyAccepted, setAccepted] = useState(false);

  useEffect(() => {
    if (isValidUuid(invite.id)) {
      setInviteToken(invite.id);
    } else {
      history.push('/');
    }
  }, []);

  if (inviteToken) {
    API.getInviteInfo(inviteToken).then((data) => {
      if (!data.valid_token) {
        return history.push('/login');
      }
      if (!auth?.tokens) {
        return handleRedirect(data.user_exist);
      } else if (auth?.tokens?.email !== data.email) {
        return auth.logout().then(() => handleRedirect(data.user_exist));
      }

      setInvitedBy(data.invited_by);
      setAccepted(data.accepted);
      setLoading(false);
    });
  }

  const handleRedirect = (userExist: boolean) => {
    return userExist
      ? history.push(`/login/${inviteToken}`)
      : history.push(`/register/${inviteToken}`);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (isAlreadyAccepted) {
      toast.error(i18next.t('invite_already_accepted') as string);
    } else {
      try {
        await auth.acceptInvite({ token: inviteToken });
        history.push('/');
      } catch (e) {
        if (e.code === 'ERR_NETWORK') {
          toast.error(i18next.t('network_error'));
        }
        if (e.response?.data?.error?.status === 500) {
          toast.error(i18next.t('server_error'));
        } else {
          toast.error(i18next.t('invite_error'));
        }
      }
    }
  };

  return (
    <>
      <Helmet>
        <title>Whippy | Join Organization</title>
      </Helmet>
      <Flex align="center" justify="center" css={{ height: '100vh' }}>
        <Box css={{ width: 450, height: 500, p: 50 }}>
          {loading ? (
            <DotsTyping />
          ) : (
            <form onSubmit={handleSubmit}>
              <Box css={{ mb: 20 }}>
                <OnboardingHeading>
                  {auth?.tokens && `Logged in as ${auth?.tokens?.email}`}
                </OnboardingHeading>
              </Box>
              <OnboardingDescription css={{ marginBottom: 70 }}>
                You were invited to join the {invitedBy} workspace.
              </OnboardingDescription>
              <Box>
                <Button type="submit" css={{ width: '100%' }}>
                  Accept Invite
                </Button>
              </Box>
            </form>
          )}
        </Box>
      </Flex>
    </>
  );
};

export const OnboardingHeading = styled('h1', {
  fontWeight: '700',
  fontSize: '28px !important',
  minWidth: '100%',
});

export const OnboardingDescription = styled('p', {
  lineHeight: 1.5,
  fontSize: '16px',
  minWidth: '100%',
});
