/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo } from 'react';

import { useSequences } from '@/pages/sequences/context/SequenceContext';
import { Sequence } from '@/shared/types/sequences';

import { FormFieldWrapper, helpers } from '../../../forms';
import { SelectCombobox } from '../../../forms';
import { ActionProps } from '.';

export const RemoveFromSequenceAction = (props: ActionProps) => {
  const { index } = props;

  const sequence = useSequences();
  const { sequencesState, getSequences, getSequenceTemplates } = sequence;
  const { allSequences, allSequenceTemplates: allTemplates } = sequencesState;

  useEffect(() => {
    // if the sequences are not loaded, load them
    if (!allSequences || allSequences.length === 0) {
      getSequences();
    }
  }, []);

  useEffect(() => {
    if (!allTemplates || allTemplates.length === 0) {
      getSequenceTemplates();
    }
  }, []);

  // combine sequences and templates
  const options = useMemo(() => {
    const allSequencesAndTemplates = [...allSequences, ...allTemplates];
    return allSequencesAndTemplates && allSequencesAndTemplates.length > 0
      ? allSequencesAndTemplates
          // filter out null values
          .filter((sequence): sequence is Sequence => sequence !== null)
          // filter out duplicate sequences by id
          .filter(
            (sequence, index, self) =>
              index === self.findIndex((t) => t.id === sequence.id)
          )
          // map the sequences to the options and add the template tag to the title
          .map((sequence: Sequence) => ({
            value: `${sequence?.id || ''}` as string,
            type:
              sequence?.status === 'template'
                ? `${sequence?.title} [TEMPLATE]`
                : `${sequence?.title}`,
          }))
      : [];
  }, [allSequences, allTemplates]);

  return (
    <FormFieldWrapper
      label="Remove from Sequence"
      tooltip="Select the sequence to remove the contact from"
      name={`actions[${index}].params.sequence_id`}
    >
      <SelectCombobox
        placeholder={helpers.displaySelectedItem}
        isDropdown={true}
        options={options}
      />
    </FormFieldWrapper>
  );
};
