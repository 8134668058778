import React from 'react';

import { CallStatusTypes } from '@/shared/types/voip';

import { useVoIP } from './context/VoIPContext';
import { VoIPCall } from './VoIPCall';
import { VoIPIncomingCall } from './VoIPIncomingCall';
import { VoIPOutgoingCall } from './VoIPOutgoingCall';

export const VoIP = () => {
  const voip = useVoIP();
  const { incomingCall, outgoingCall, callStatus } = voip.voipState;

  return (
    <>
      {!!incomingCall && callStatus !== CallStatusTypes.INCOMING_ACCEPTED && (
        <VoIPIncomingCall />
      )}
      {(outgoingCall ||
        (incomingCall && callStatus === CallStatusTypes.INCOMING_ACCEPTED)) && (
        <VoIPCall data={incomingCall || outgoingCall} />
      )}
      {callStatus === CallStatusTypes.NEW_OUTGOING && <VoIPOutgoingCall />}
    </>
  );
};
