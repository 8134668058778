import { Flex, Text } from '@/shared/ui';
import { styled } from '@/stitches.config';

export const SearchedListEmptyState = () => {
  return (
    <EmptyStateContainer align="center" justify="center" direction="column">
      <Text css={{ fontWeight: 600 }}>No Links Matches Your Search</Text>
      <Text css={{ mt: 10 }}>Please try a different key word</Text>
    </EmptyStateContainer>
  );
};

export const ListEmptyState = () => {
  return (
    <EmptyStateContainer align="center" justify="center" direction="column">
      <Text css={{ fontWeight: 600 }}>No Links</Text>
      <Text css={{ mt: 10 }}>{`Click "Create Link" to get started`}</Text>
    </EmptyStateContainer>
  );
};

const EmptyStateContainer = styled(Flex, {
  p: 20,
  height: '100%',
  width: '100%',
});
