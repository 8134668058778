import { Formik } from 'formik';
import { useEffect } from 'react';
import { toast } from 'sonner';
import * as Yup from 'yup';

import { useTags } from '@/pages/settings/organization/tags/context/TagsContext';
import { bulkUpdateTags } from '@/shared/api/contacts/v2';
import { CampaignSourceTuple, SequenceSourceTuple } from '@/shared/api/sequences';
import { FormFieldWrapper } from '@/shared/components/forms';
import { FilterItem } from '@/shared/types/filter';
import { Tag, TagBulkActionType } from '@/shared/types/tags';
import {
  Button,
  Dialog,
  DialogClose,
  DialogFooter,
  DialogOverlay,
  DialogPortal,
  DialogTrigger,
  HStack,
  Label,
  VStack,
} from '@/shared/ui';
import { ComboboxMultiselectItem } from '@/shared/v2/components/comboboxMultiselect/ComboboxMultiselectItem';
import { ComboboxMultiselectTrigger } from '@/shared/v2/components/comboboxMultiselect/ComboboxMultiselectTrigger';
import { ComboboxMultiselectInput } from '@/shared/v2/components/forms/comboboxMultiselect/ComboboxMultiselectInput';

import {
  BulkActionDialogContent,
  BulkActionDialogDescription,
  BulkActionDialogTitle,
} from '../Dialog';

type Props = {
  onOpenChange: (open: boolean) => void;
  filterSource?: CampaignSourceTuple | SequenceSourceTuple;
  open: boolean;
  totalContacts: number;
  filter: FilterItem[];
  action: TagBulkActionType;
};

export const BulkTagModal = ({
  open,
  filterSource,
  totalContacts,
  filter,
  action,
  onOpenChange,
}: Props) => {
  const { tagsState, getTags } = useTags();
  const { allTags } = tagsState;

  const resetModalState = (): void => {
    onOpenChange(false);
  };

  useEffect(() => {
    getTags();
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleSubmit = async (values: { tagIds: string[] }, _actions: any) => {
    const { tagIds } = values;
    toast.info('Making a request to ' + action + ' from contacts');
    bulkUpdateTags(action, tagIds, filter, filterSource)
      // eslint-disable-next-line
      .then((_data) => {
        toast.success(capitalizeAction(action) + ' tags from contacts now in progress');
      })
      // eslint-disable-next-line
      .catch((error) => {
        console.error(`Failed to ${action} tags with error: `, error);
        toast.error(`Failed to ${action} tags from contacts`);
      });
    resetModalState();
  };

  return (
    <Dialog open={open} modal={true}>
      <DialogTrigger asChild onClick={resetModalState}></DialogTrigger>
      <DialogPortal>
        <DialogOverlay as="div">
          <Formik
            onSubmit={handleSubmit}
            initialValues={{
              tagIds: [],
            }}
            validationSchema={Yup.object({
              tagIds: Yup.array().min(1, 'Please provide at least one tag'),
            })}
          >
            {(formik) => (
              <form onSubmit={formik.handleSubmit}>
                <BulkActionDialogContent
                  onPointerDownOutside={resetModalState}
                  onEscapeKeyDown={resetModalState}
                >
                  <BulkActionDialogTitle>
                    {' '}
                    {generateModalTitle(action)}{' '}
                  </BulkActionDialogTitle>
                  <BulkActionDialogDescription>
                    {capitalizeAction(action)} the following tags from the chosen
                    contacts.
                  </BulkActionDialogDescription>
                  <VStack gap={2}>
                    <FormFieldWrapper name="tagIds" label="Tag(s)">
                      <ComboboxMultiselectInput
                        options={allTags.map((tag: Tag) => ({
                          label: tag.name,
                          value: tag.id,
                        }))}
                        searchLabel="Search"
                        selectLabel="Select Tag(s)"
                        Trigger={ComboboxMultiselectTrigger}
                        Option={ComboboxMultiselectItem}
                        selectAll
                      />
                    </FormFieldWrapper>
                  </VStack>
                  <DialogFooter justify="between" css={{ mb: 8, mt: 8 }}>
                    <HStack
                      gap={1}
                      css={{ width: '100%', justifyContent: 'space-between' }}
                    >
                      <Label css={{ mb: 0 }}>
                        {totalContacts > 0 ? `Total Contacts: ${totalContacts}` : ''}
                      </Label>
                      <HStack>
                        <DialogClose asChild>
                          <Button
                            variant="gray"
                            css={{ mr: '$1' }}
                            onClick={resetModalState}
                          >
                            Cancel
                          </Button>
                        </DialogClose>
                        <DialogClose asChild>
                          {/* 
                          the submit button should also be disabled when no tags are selected 
                          in the beginning since we start with an empty list of tag ids
                          but we require at least one to be selected.
                          */}
                          <Button
                            type="submit"
                            disabled={!formik.isValid || !formik.dirty}
                          >
                            Confirm
                          </Button>
                        </DialogClose>
                      </HStack>
                    </HStack>
                  </DialogFooter>
                </BulkActionDialogContent>
              </form>
            )}
          </Formik>
        </DialogOverlay>
      </DialogPortal>
    </Dialog>
  );
};

function generateModalTitle(action: TagBulkActionType) {
  if (action == 'unassign') {
    return 'Un-assign Tags to Contacts';
  }
  if (action == 'assign') {
    return 'Assign Tags to Contacts';
  }

  if (action == 'assign.overwrite') {
    return 'Overwrite Tags to Contacts';
  }
  return '';
}

/**
 * This function will capitalize the action but also make it into a more human readable form if necessary.
 * For example, instead of 'assign.overwrite' we just want to display Overwrite to the user.
 */
export function capitalizeAction(action: TagBulkActionType) {
  if (action == 'unassign') {
    return 'Un-assign';
  }
  if (action == 'assign') {
    return 'Assign';
  }
  if (action == 'assign.overwrite') {
    return 'Overwrite';
  }
  return '';
}
