/* eslint-disable react-hooks/exhaustive-deps */
import {
  useCombobox,
  UseComboboxGetItemPropsOptions,
  UseComboboxStateChangeOptions,
} from 'downshift';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import Highlighter from 'react-highlight-words';
import { useMedia } from 'react-use';

import {
  Badge,
  Box,
  Button,
  ComboboxItem,
  CustomScrollbar,
  Flex,
  Input,
  ScrollArea,
  ScrollAreaCorner,
  ScrollAreaScrollbar,
  ScrollAreaThumb,
  ScrollAreaViewport,
  Skeleton,
  Text,
} from '@/shared/ui';
import { styled } from '@/stitches.config';

import { Spinner } from '../../Icons';
import { useAIContext } from './context/AIContext';
import { pipeRegex, SuggestedItem } from './utils';

type AIBoxProps = {
  anchorDimensions?: {
    height: number;
    width: number;
  };
  message: string;
  setMessage: Dispatch<SetStateAction<string>>;
  onClose: () => void;
  onOpen: () => void;
  // we need this because if an attachment is added to the editor in the template editor
  // and we are in the inbox, the attachment gets added to the inbox editor and not the template editor
  setShowTemplateEditor?: Dispatch<SetStateAction<boolean>>;
  onAIOptionClick: (option: SuggestedItem) => void;
};

const AIComboboxItem = styled(ComboboxItem, {
  py: 6,
  mb: '$3',
});

// filter and select message template from list of templates
export const AIBox = (props: AIBoxProps) => {
  const [searchValue, setSearchValue] = useState('');
  const [suggestedItems, setSuggestedItems] = useState<SuggestedItem[]>([]);
  const [isCustomSuggestionLoading, setIsCustomSuggestionLoading] = useState(false);

  const { aiSentenceGenerator } = useFlags();
  const { getThreeCompletionOptions, threeCompletionOptions, isPromptLoading } =
    useAIContext();

  // isCustomSuggestionLoading is set to true when the user clicks on the generate button
  // Whenever a request completes (isPromptLoading is false), we reset isCustomSuggestionLoading to false
  // This stops the spinner from showing when the user clicks on the generate button
  useEffect(() => {
    if (!isPromptLoading) setIsCustomSuggestionLoading(false);
  }, [isPromptLoading]);

  // Get options when the AIBox is opened
  useEffect(() => {
    getOptions();
  }, [props.onOpen]);

  const getOptions = async () => {
    await getThreeCompletionOptions(searchValue != '' ? searchValue : '');
  };

  useEffect(() => {
    // Updating the suggested items when the threeCompletionOptions change allows for backwards compatibility
    // Previous implementation, setSuggestedItems was set after making a request to the backend in getOptions()
    // Now the state of threeCompletionOptions is updated inside the useAIContext hook
    // This value is updated several times (there are 3 HTTP requests made)
    // If we decide to stay with this implementation, we can remove this useEffect and refactor in the future
    if (threeCompletionOptions.length > 0) {
      setSuggestedItems(threeCompletionOptions);
    }
  }, [threeCompletionOptions]);

  // Array of placeholders to display in the input
  const guideExamplePlaceholders = [
    'can you start a shift tomorrow at 9am?',
    'payments go out each friday',
    'can you send me a picture of your ID?',
  ];

  // State variables for the index and text of the placeholder
  const [placeholderTextIndex, setPlaceholderTextIndex] = useState(0);
  const [placeholderText, setPlaceholderText] = useState('');

  // Effect to handle the typing animation of the placeholder text
  useEffect(() => {
    let textIndex = 0;
    const interval = setInterval(() => {
      const currentPlaceholder = guideExamplePlaceholders[placeholderTextIndex];
      // Update the placeholder text to give a typing effect
      setPlaceholderText(currentPlaceholder.slice(0, textIndex) + `...`);
      textIndex++;
      if (textIndex > currentPlaceholder.length) {
        // Once the entire placeholder is typed out, clear the interval
        setPlaceholderText(currentPlaceholder.slice(0, textIndex));
        clearInterval(interval);
        setTimeout(() => {
          // After a delay, reset the text index and move to the next placeholder
          textIndex = 0;
          setPlaceholderTextIndex(
            (prevIndex) => (prevIndex + 1) % guideExamplePlaceholders.length
          );
        }, 1200);
      }
    }, 40); // Change character every 40 milliseconds
    // Cleanup function to clear the interval when the component unmounts
    return () => clearInterval(interval);
  }, [placeholderTextIndex]);

  const {
    getMenuProps,
    getInputProps,
    getItemProps,
    highlightedIndex = 0,
  } = useCombobox({
    items: suggestedItems || [],
    itemToString: (item) => item?.message || '',
    stateReducer: (
      state,
      actionAndChanges
    ): Partial<UseComboboxStateChangeOptions<SuggestedItem>> => {
      const { type, changes } = actionAndChanges;
      // this prevents the menu from being closed when the user selects an item with 'Enter' or mouse
      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
          if (changes.selectedItem) {
            props.onAIOptionClick(changes.selectedItem);
            props.onClose();
          }
          return {
            changes, // default Downshift new state changes on item selection
          };
        case useCombobox.stateChangeTypes.ItemClick:
          if (changes.selectedItem) {
            props.onAIOptionClick(changes.selectedItem);
            props.onClose();
          }
          return {
            changes, // default Downshift new state changes on item selection
          };
        default:
          return changes; // otherwise business as usual
      }
    },
    onInputValueChange: ({ inputValue }) => {
      const inputString = inputValue ? inputValue : '';
      setSearchValue(inputString);
    },
  });

  if (!aiSentenceGenerator) return null;

  return (
    <Box css={{ p: '$4' }}>
      <Flex css={{ pb: '$3', alignItems: 'center' }}>
        <Text css={{ fontWeight: 600, fontSize: 15, marginRight: 10 }}>
          {'Whippy AI'}
        </Text>
        <Badge>Experimental</Badge>
      </Flex>
      <Flex css={{ position: 'relative', alignItems: 'center', mb: '$4' }}>
        <Input
          {...getInputProps(
            {
              onKeyDown: ({ key }) => {
                if (key === 'Enter') {
                  setIsCustomSuggestionLoading(true);
                  getOptions();
                }
              },
            },
            { suppressRefError: true }
          )}
          placeholder={`Guide Whippy AI - ${placeholderText}`}
          css={{ mr: '$1', flexGrow: 1 }}
        />
        <Button
          data-intercom-target="add-template-button"
          onClick={() => {
            setIsCustomSuggestionLoading(true);
            getOptions();
          }}
        >
          {isCustomSuggestionLoading ? <Spinner /> : 'Generate'}
        </Button>
      </Flex>
      <ScrollArea variant="combobox" {...getMenuProps({}, { suppressRefError: true })}>
        <AIBoxOptions
          getItemProps={getItemProps}
          highlightedIndex={highlightedIndex}
          isPromptLoading={isPromptLoading}
          suggestedItems={suggestedItems}
          props={props}
        />
        <ScrollAreaScrollbar orientation="vertical" variant="combobox">
          <ScrollAreaThumb />
        </ScrollAreaScrollbar>
        <ScrollAreaCorner />
      </ScrollArea>
    </Box>
  );
};

const AIBoxOptions = ({
  isPromptLoading,
  suggestedItems,
  highlightedIndex,
  props,
  getItemProps,
}: {
  isPromptLoading: boolean;
  suggestedItems: SuggestedItem[];
  highlightedIndex: number;
  props: AIBoxProps;
  getItemProps: (options: UseComboboxGetItemPropsOptions<SuggestedItem>) => any;
}) => {
  const isLargeScreen = useMedia('(min-width: 1600px)');

  const calculateComboboxItemWidth = () => {
    const isSidePanelOpen = Number(props.anchorDimensions?.width) < 900;
    if (isLargeScreen) {
      if (isSidePanelOpen) {
        return Number(props.anchorDimensions?.width) > 650 ? '94%' : '91%';
      }
      return '96%';
    } else {
      return '93%';
    }
  };

  if (isPromptLoading)
    return (
      <CustomScrollbar>
        {Array.from({ length: 3 }, (_, k) => (
          <Box key={k} css={{ px: 8, pb: 4 }}>
            <Flex css={{ width: '90%', height: 40 }} align="center">
              <Flex css={{ width: '90%', ml: 4 }} direction="column" justify="center">
                <Skeleton
                  variant="heading"
                  css={{ width: '100%', height: 10, m: 0, mb: 2 }}
                />
                <Skeleton
                  variant="heading"
                  css={{ width: '75%', height: 10, m: 0, mt: 2 }}
                />
              </Flex>
            </Flex>
          </Box>
        ))}
      </CustomScrollbar>
    );

  if (suggestedItems?.length < 1) {
    return (
      <ScrollAreaViewport
        variant="combobox"
        css={{
          minHeight: '92px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Flex align="center" justify="center" css={{ fontSize: 14 }}>
          No AI Suggestions
        </Flex>
      </ScrollAreaViewport>
    );
  }

  if (suggestedItems) {
    return (
      <ScrollAreaViewport variant="combobox">
        {suggestedItems?.length > 0 &&
          suggestedItems?.map((item, index) => {
            const isHighlighted =
              highlightedIndex !== undefined && index === highlightedIndex;
            return (
              <Flex
                key={item.id}
                align="center"
                css={{
                  mb: 3,
                  paddingLeft: 'var(--space-2)',
                  paddingRight: 'var(--space-2)',
                  width: props.anchorDimensions
                    ? props.anchorDimensions?.width - 42
                    : undefined,
                  backgroundColor: isHighlighted ? '$slate3' : undefined,
                  borderRadius: isHighlighted ? 4 : undefined,
                  '&:hover': {
                    backgroundColor: '$slate3',
                  },
                }}
                onClick={() => {
                  props.onAIOptionClick(item);
                  props.onClose();
                }}
              >
                <AIComboboxItem
                  direction="column"
                  css={{
                    height: '100%',
                    width: calculateComboboxItemWidth(),
                    mb: 0,
                    borderRadius: 4,
                    paddingLeft: 0,
                    paddingRight: 0,
                    '&:hover': {
                      backgroundColor: 'transparent',
                    },
                  }}
                  {...getItemProps({ item, index })}
                >
                  <Flex align="center" justify="between">
                    <Flex direction="column">
                      <Box
                        css={{
                          width: '100%',
                        }}
                      >
                        <Highlighter
                          searchWords={item.message.match(pipeRegex) || []}
                          autoEscape={true}
                          textToHighlight={item.message}
                          highlightStyle={{
                            background: '#EAF5F9',
                            color: '#4276AA',
                            borderRadius: '3px',
                          }}
                        />
                      </Box>
                    </Flex>
                  </Flex>
                </AIComboboxItem>
              </Flex>
            );
          })}
      </ScrollAreaViewport>
    );
  }
};
