import { Group, GroupsType, GroupsTypeV2 } from '../../../types/contacts/groups';
import { api } from '../../api';

/*
  GET - get specific batch of groups
*/

export const getGroups = (params: {
  offset: number;
  limit: number;
}): Promise<GroupsType> =>
  api
    .get(`/contacts/dynamic_groups?offset=${params.offset}&limit=${params.limit}`)
    .then((res) => {
      return res.data.data;
    });

/*
  GET - get a specific dynamic contact group
*/

export const getGroup = (id: string): Promise<Group | null> =>
  api.get(`/contacts/dynamic_groups/${id}`).then((res) => {
    return res.data.data;
  });

export const getGroupsV2 = (params: any, signal: any): Promise<GroupsTypeV2> =>
  api.post(`/v2/contacts/segments/search`, { ...params }, { signal }).then((res) => {
    return res.data;
  });

/*
  POST - create a dynamic contact group
*/

export const createGroup = (
  groupName: string,
  groupColor: string,
  filters: object,
  filters_version: string
): Promise<Group> =>
  api
    .post(`/contacts/dynamic_groups`, {
      dynamic_group: {
        name: groupName,
        color: groupColor,
        filters,
        filters_version: filters_version,
      },
    })
    .then((res) => {
      return res.data.data;
    });

/*
  POST - search a dynamic group
*/

export const searchGroup = (name: string): Promise<Array<Group>> =>
  api.post(`/contacts/dynamic_groups/search`, { name }).then((res) => {
    return res.data.data;
  });

/*
  PUT - update a dynamic contact group
*/

export const updateGroup = (group: Partial<Group>): Promise<Group> =>
  api
    .put(`/contacts/dynamic_groups/${group.id}`, { dynamic_group: { ...group } })
    .then((res) => {
      return res.data.data;
    });

/*
  DELETE - delete a dynamic contact group
*/

export const deleteGroup = (groupId: string): Promise<null> =>
  api.delete(`/contacts/dynamic_groups/${groupId}`).then((res) => {
    return res.data.data;
  });
