import { ColumnType, Resource, SortConfig } from '@/shared/types/filter';

export const campaignContactsSortConfig: SortConfig<
  Resource.Contact | Resource.ContactMessage,
  | ColumnType.Name
  | ColumnType.Email
  | ColumnType.Phone
  | ColumnType.InsertedAt
  | ColumnType.UpdatedAt
> = {
  label: 'Sort',
  columnOptions: [
    {
      label: 'Contact Name',
      resource: Resource.Contact,
      column: ColumnType.Name,
      order: 'asc',
    },
    {
      label: 'Contact Phone',
      resource: Resource.Contact,
      column: ColumnType.Phone,
      order: 'asc',
    },
    {
      label: 'Responded At',
      resource: Resource.ContactMessage,
      column: ColumnType.InsertedAt,
      order: 'asc',
    },
  ],
};
