import { useState } from 'react';
import { HiChevronDown, HiChevronUp } from 'react-icons/hi';
import { useMedia } from 'react-use';

import {
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  Flex,
  Label,
} from '@/shared/ui';
import { styled } from '@/stitches.config';

import { BulkAction } from '../FiltersTable';

type BulkActionDropdownProps = {
  bulkActions: BulkAction[];
  handleClick: (bulkAction: BulkAction) => void;
};

export const BulkActionDropdown = ({
  handleClick,
  bulkActions,
}: BulkActionDropdownProps) => {
  // if we have no bulk actions don't display a dropdown
  const isLarge = useMedia('(min-width: 1200px)');
  const [isChevronDown, setChevronDown] = useState(false);
  const [open, setOpen] = useState(false);
  if (bulkActions.length == 0) return <></>;

  return (
    <DropdownMenu
      onOpenChange={(isOpen) => {
        setChevronDown(false);
        setOpen(isOpen);
      }}
      open={open}
    >
      <DropdownMenuTrigger asChild>
        <Button
          onMouseOver={() => setChevronDown(false)}
          onMouseOut={() => setChevronDown(true)}
          size="2"
          css={{
            textAlign: 'start',
            color: '#00259ECB',
            paddingRight: '8px',
            paddingLeft: '8px',
            borderRadius: '4px',
            background: 'white',
            backgroundColor: isChevronDown ? '#0144FF0F' : 'white',
            boxShadow: 'none',
            '&:focus': {
              boxShadow: 'none',
            },
            '&:hover': {
              backgroundColor: '#0144FF0F',
              boxShadow: 'none',
            },
            '&:active': {
              backgroundColor: '#0144FF0F',
              boxShadow: 'none',
            },
          }}
        >
          <Flex align="center" css={{ gap: '8px', cursor: 'pointer' }}>
            <Label css={{ padding: '0px', margin: '0px', cursor: 'pointer' }}>More</Label>
            {isChevronDown ? <HiChevronDown /> : <HiChevronUp />}
          </Flex>
        </Button>
      </DropdownMenuTrigger>
      {/* the alignOffset is using magic numbers to align the end of the panel to the dropdown */}
      <StyledDropdownMenuContent
        side="top"
        sideOffset={10}
        alignOffset={isLarge ? -15 : -15}
        align="end"
        css={{
          maxWidth: 'fit-content',
        }}
      >
        <Flex direction="column" css={{ paddingLeft: '8px', paddingRight: '8px' }}>
          {bulkActions?.map((bulkAction) => {
            return (
              <DropdownMenuItem
                key={bulkAction.action}
                onClick={() => handleClick(bulkAction)}
                asChild
                style={{
                  textAlign: 'start',
                }}
              >
                <Button
                  variant="transparentWhite"
                  size="2"
                  css={{
                    // display needed since the Button is inline-flex
                    display: 'block',
                    boxShadow: 'none',
                    cursor: 'pointer',
                    backgroundColor: 'transparent',
                    textAlign: 'start',
                    paddingLeft: '12px',
                    paddingRight: '12px',
                    fontSize: 14,
                    color: bulkAction.type == 'destructive' ? 'Red' : '#1C2024',
                    '&:focus': {
                      color: '#FFF',
                      boxShadow: 'none',
                      backgroundColor:
                        bulkAction.type == 'destructive' ? 'rgb(217, 61, 66)' : '#3A5CCC',
                    },
                    '&:hover': {
                      color: '#FFF',
                      boxShadow: 'none',
                      backgroundColor:
                        bulkAction.type == 'destructive' ? 'rgb(217, 61, 66)' : '#3A5CCC',
                    },
                  }}
                >
                  {' '}
                  {bulkAction.label}
                </Button>
              </DropdownMenuItem>
            );
          })}
        </Flex>
      </StyledDropdownMenuContent>
    </DropdownMenu>
  );
};

const StyledDropdownMenuContent = styled(DropdownMenuContent, {
  cursor: 'pointer',
  color: 'white',
});
