import * as SelectPrimitive from '@radix-ui/react-select';
import { FormikValues, useFormikContext } from 'formik';
import { get, set } from 'lodash';
import React from 'react';
import { HiChevronDown, HiChevronUp } from 'react-icons/hi';

import {
  Select,
  SelectContent,
  SelectGroup,
  SelectIcon,
  SelectItem,
  SelectItemIndicator,
  SelectItemText,
  SelectLabel,
  SelectScrollUpButton,
  SelectTrigger,
  SelectValue,
  SelectViewport,
} from '@/shared/ui';

import { SelectProps } from './types';

/** A radio input that can be used in conjunction with Formik, must be placed within a FormFieldWrapper */
export function FormSelect(props: SelectProps): JSX.Element {
  const formik = useFormikContext<FormikValues>();

  return (
    <Select
      defaultValue={get(formik.initialValues, props?.name ?? '')}
      value={get(formik.values, props.name ?? '')}
      onValueChange={(value: any) => {
        const updatedValues = set({ ...formik.values }, props.name ?? '', value);
        formik.setValues(updatedValues);
      }}
    >
      <SelectTrigger aria-label="option-select-trigger">
        <SelectValue />
        <SelectIcon>
          <HiChevronDown />
        </SelectIcon>
      </SelectTrigger>
      <SelectPrimitive.Portal>
        <SelectContent css={{ zIndex: 9999999 }}>
          <SelectScrollUpButton>
            <HiChevronUp />
          </SelectScrollUpButton>
          <SelectViewport>
            <SelectGroup>
              <SelectLabel>{props.placeholder}</SelectLabel>
              {props.options.map((option) => (
                <SelectItem key={option.value} value={option.value}>
                  <SelectItemIndicator />
                  <SelectItemText>{option.label}</SelectItemText>
                </SelectItem>
              ))}
            </SelectGroup>
          </SelectViewport>
        </SelectContent>
      </SelectPrimitive.Portal>
    </Select>
  );
}
