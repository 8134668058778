import { PageLayout } from '@/shared/layouts/PageLayout';

import { AddDomain } from './AddDomain';
import { BuyDomain } from './BuyDomain';
import { DomainsTable } from './DomainsTable';

export const DomainsList = () => {
  return (
    <PageLayout
      background="#fafafa"
      breadcrumbs={[
        { title: 'Links', path: '/campaigns/domains' },
        { title: 'Domains', path: '/campaigns/domains' },
      ]}
      actions={
        <>
          <BuyDomain key="buy" />
          <AddDomain key="add" />
        </>
      }
    >
      <DomainsTable />
    </PageLayout>
  );
};
