/* eslint-disable react-hooks/exhaustive-deps */
import { Formik } from 'formik';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { toast } from 'sonner';
import * as Yup from 'yup';

import { useReviews } from '@/reviews/context/ReviewContext';
import { FormFieldWrapper, TextInput } from '@/shared/components/forms';
import { Selectable } from '@/shared/components/forms/selectableInput';
import { IndustryOptions } from '@/shared/components/select/data';
import { Box, Button, Fieldset, Label, Text, VStack } from '@/shared/ui';
import i18next from '@/shared/utils/translation';

import { useSettings } from './context/SettingsContext';
import OrganizationLogo from './OrganizationLogo';

type ConsentUrlProps = {
  consentUrl: string;
};

export const ReviewConsentField = (props: ConsentUrlProps): JSX.Element => {
  const onClickHandler = () => {
    window.location.href = props.consentUrl;
  };
  return <Button onClick={onClickHandler}>Connect GMB</Button>;
};

type GeneralViewProps = {
  organizationName: string;
  organizationAttachment: string;
  selectedIndustries: string[];
  getOrganizationInfo: () => void;
};

const GeneralView = memo((props: GeneralViewProps): JSX.Element => {
  const {
    organizationName,
    organizationAttachment,
    getOrganizationInfo,
    selectedIndustries,
  } = props;
  const { updateSettings } = useSettings();

  const reviewContext = useReviews();
  const { getConsentUrl, reviewState } = reviewContext;
  const { consentUrl } = reviewState;

  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    getConsentUrl();
  }, []);

  const onSubmit = useCallback(
    async (values: { organization_name: string; industry: string[] }) => {
      setIsSubmitting(true);
      try {
        const data = await updateSettings({
          organization_name: values.organization_name,
          industry: values.industry,
        });
        if (data) {
          toast.success(i18next.t('organization_update_success') as string);
        }
      } catch (e) {
        toast.error(i18next.t('organization_update_failure') as string);
        return getOrganizationInfo();
      } finally {
        setIsSubmitting(false);
      }
    },
    []
  );

  return (
    <Box>
      <VStack gap={6}>
        <VStack gap={2}>
          <Fieldset>
            <Label>Organization Logo</Label>
            <OrganizationLogo organizationAttachment={organizationAttachment} />
          </Fieldset>
          <Formik
            enableReinitialize
            initialValues={{
              organization_name: organizationName || '',
              industry: selectedIndustries || [],
            }}
            validationSchema={Yup.object({
              organization_name: Yup.string()
                .required('Required')
                .min(2, 'Must be at least 2 characters'),
            })}
            onSubmit={onSubmit}
          >
            {(formik) => (
              <form onSubmit={formik.handleSubmit}>
                <VStack gap={2}>
                  <FormFieldWrapper label="Organization Name" name="organization_name">
                    <TextInput />
                  </FormFieldWrapper>

                  <FormFieldWrapper
                    label="Industry"
                    tooltip="Choose your industries"
                    name="industry"
                  >
                    <Selectable
                      name="industry"
                      options={[...IndustryOptions]} // Convert readonly array to mutable array
                      selected={formik.values.industry}
                    />
                  </FormFieldWrapper>
                  <Box>
                    <Button type="submit" disabled={isSubmitting}>
                      {isSubmitting ? 'Updating...' : 'Update'}
                    </Button>
                  </Box>
                </VStack>
              </form>
            )}
          </Formik>
        </VStack>
        <VStack gap={2}>
          <Fieldset>
            <Label>Connect to Google My Business</Label>
            <Text>Automatically respond, track and analyze reviews in Whippy.</Text>
          </Fieldset>
          <Box>
            <ReviewConsentField consentUrl={consentUrl} />
          </Box>
        </VStack>
      </VStack>
    </Box>
  );
});

export default GeneralView;
