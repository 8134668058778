import { OpeningHours } from '@/shared/components/openingHours';
import { transformOpeningHours } from '@/shared/components/openingHours/utils';
import { formatHour } from '@/shared/components/openingHours/utils';
import { OpeningHour } from '@/shared/types';
import { Team } from '@/shared/types/team';
import { WorkSchedule } from '@/shared/types/team/schedule';

import { weekDays } from '../constants';
import { useTeams } from '../context/TeamsContext';
export const BusinessHours = () => {
  const {
    teamsState: { current },
    createWorkSchedules,
    deleteWorkSchedules,
    updateWorkSchedules,
  } = useTeams();

  // Handle change work schedules
  const onChange = async (openingHour: {
    id?: string;
    opens_at: string;
    closes_at: string;
    weekday: string;
    state: string;
    isSwitch?: boolean;
    timezone?: string; //
  }) => {
    if (!openingHour.isSwitch && openingHour?.id) {
      if (!current?.id) return;
      await updateWorkSchedules(
        current?.id,
        openingHour?.id,
        {
          weekday: openingHour.weekday,
          starts_at: formatHour(openingHour?.opens_at),
          ends_at: formatHour(openingHour?.closes_at),
          timezone: openingHour.timezone,
        },
        true
      );
      return;
    }

    if (openingHour?.id && current?.id) {
      await deleteWorkSchedules(current.id, openingHour.id);
    } else if (current?.id) {
      await createWorkSchedules(current?.id, {
        weekday: openingHour.weekday,
        starts_at: openingHour?.opens_at,
        ends_at: openingHour?.closes_at,
        timezone: openingHour?.timezone,
      });
    }
  };

  // Applies the opening hours to the selected days
  const onApply = async (openingHour: OpeningHour, targetDays: string[]) => {
    // If there is no team, return
    if (!current || !current.id) {
      return;
    }

    // We need to go over the weekdays array and check if the day is in the target days array
    // If it is, to find work schedule from existing work schedules and to update with openingHour
    // If not, we need to create a new work schedule with from openingHour
    const updatingWorkSchedules = weekDays.map((weekday: string) => {
      if (targetDays.includes(weekday)) {
        const existingWorkSchedules = current?.work_schedules || [];
        const workSchedule = existingWorkSchedules.find(
          (workSchedule: WorkSchedule) => workSchedule.weekday === weekday
        );

        // If the opening hour which we are copying form exists,
        // either update the target work schedule or create one
        if (openingHour.id) {
          // If we have a target work schedule, we need to update it
          if (workSchedule) {
            return updateWorkSchedules(current?.id as string, workSchedule.id as string, {
              starts_at: openingHour.opens_at,
              ends_at: openingHour.closes_at,
              timezone: openingHour.timezone,
            });
          } else {
            // If we don't have a work schedule, we need to create a new one
            return createWorkSchedules(current.id as string, {
              weekday: weekday,
              starts_at: openingHour?.opens_at,
              ends_at: openingHour?.closes_at,
              timezone: openingHour.timezone,
            });
          }
        } else {
          // If the opening hour which we are copying from doesn't exist, we delete the target work schedule
          if (workSchedule) {
            return deleteWorkSchedules(current?.id as string, workSchedule.id as string);
          }
        }
      }
    });

    // We need to wait for all the work schedules to be updated/created
    try {
      current?.id && (await Promise.all(updatingWorkSchedules));
    } catch (error) {
      console.error('Error update team work schedule:', error);
    }
  };

  // Transform the types of TeamMember work schedule from 'WorkSchedule' to 'OpeningHour'
  const transformTypes = (team: Team) => {
    if (!team || !team.work_schedules) {
      return;
    }
    const openingHours = team?.work_schedules.map((workSchedule: WorkSchedule) =>
      transformOpeningHours(workSchedule)
    );
    return { ...team, openingHours };
  };

  // Update the existing work schedules with timezone
  const updateTimezone = async (timezoneName: string) => {
    const existingWorkSchedules = current?.work_schedules || [];
    const updatingWorkSchedules = existingWorkSchedules.map(
      (workSchedule: WorkSchedule, index: number) =>
        updateWorkSchedules(
          current?.id as string,
          workSchedule.id as string,
          {
            timezone: timezoneName,
          },
          index === 0
        )
    );
    try {
      current?.id && (await Promise.all(updatingWorkSchedules));
    } catch (error) {
      console.error('Error update team work schedule:', error);
    }
  };

  return (
    <>
      {!current ? null : (
        <OpeningHours
          onChange={onChange}
          onApply={onApply}
          timezone={current?.timezone || current?.work_schedules?.[0]?.timezone}
          updateTimezone={updateTimezone}
          current={transformTypes(current)}
        />
      )}
    </>
  );
};
