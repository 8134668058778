/* eslint-disable react/display-name */
import * as CheckboxPrimitive from '@radix-ui/react-checkbox';
import React from 'react';
import { HiCheck as CheckIcon, HiMinus } from 'react-icons/hi';

import { CSS, styled, VariantProps } from '@/stitches.config';

const StyledCheckbox = styled(CheckboxPrimitive.Root, {
  all: 'unset',
  boxSizing: 'border-box',
  userSelect: 'none',
  '&::before': {
    boxSizing: 'border-box',
  },
  '&::after': {
    boxSizing: 'border-box',
  },

  alignItems: 'center',
  appearance: 'none',
  display: 'inline-flex',
  justifyContent: 'center',
  lineHeight: '1',
  margin: '0',
  outline: 'none',
  padding: '2px',
  WebkitTapHighlightColor: 'rgba(0,0,0,0)',

  color: '$hiContrast',
  overflow: 'hidden',
  '@hover': {
    '&:hover': {
      boxShadow: 'inset 0 0 0 1px $colors$slate8',
    },
  },
  '&:focus': {
    outline: 'none',
    borderColor: '$red7',
    boxShadow: 'inset 0 0 0 1px $colors$blue9, 0 0 0 1px $colors$blue9',
  },
  '&[data-state="unchecked"]': {
    bc: '$lowContrast',
    bs: 'inset 0 0 0 1px $colors$slate7 !important',
  },

  variants: {
    color: {
      primary: {
        '&[data-state="checked"]': {
          bc: '$primaryColor',
          color: 'white',
          '@hover': {
            '&:hover': {
              boxShadow: 'inset 0 0 0 1px $colors$blue9',
            },
          },
          '&:active': {
            backgroundColor: '$blue3',
            boxShadow: 'inset 0 0 0 1px $colors$blue10',
          },
          '&:focus': {
            boxShadow: 'inset 0 0 0 1px $colors$blue8, 0 0 0 1px $colors$blue8',
          },
        },
      },
      green: {
        '&[data-state="checked"]': {
          bc: '#28D07F',
          color: 'white',
          '@hover': {
            '&:hover': {
              boxShadow: 'inset 0 0 0 1px $colors$green9',
            },
          },
          '&:active': {
            backgroundColor: '$green3',
            boxShadow: 'inset 0 0 0 1px $colors$green10',
          },
          '&:focus': {
            boxShadow: 'inset 0 0 0 1px $colors$green8, 0 0 0 1px $colors$green8',
          },
        },
      },
    },
    size: {
      '1': {
        width: '$3',
        height: '$3',
        borderRadius: '$1',
      },
      '2': {
        width: '18px',
        height: '18px',
        borderRadius: '$1',
      },
      '3': {
        width: '$5',
        height: '$5',
        borderRadius: '$2',
      },
    },
  },
  defaultVariants: {
    size: '1',
    color: 'primary',
  },
});

const StyledIndicator = styled(CheckboxPrimitive.Indicator, {
  alignItems: 'center',
  display: 'flex',
  height: '100%',
  justifyContent: 'center',
  width: '100%',
});

type CheckboxPrimitiveProps = React.ComponentProps<typeof CheckboxPrimitive.Root>;
type CheckboxVariants = VariantProps<typeof StyledCheckbox>;
//any -> there is some mixin between stitches types and react-table types
//onCheckedChange doesn't accept function as argument, it accept only boolean or 'indeterminate'
type CheckboxProps = CheckboxPrimitiveProps & CheckboxVariants & { css?: CSS } & any;

export const Checkbox = React.forwardRef<
  React.ElementRef<typeof StyledCheckbox>,
  CheckboxProps
>((props, forwardedRef) => (
  <StyledCheckbox {...props} ref={forwardedRef}>
    <StyledIndicator>
      {props.indeterminate && props.indeterminate === true ? <HiMinus /> : <CheckIcon />}
    </StyledIndicator>
  </StyledCheckbox>
));
