import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';

import ReviewState from '@/reviews/context/ReviewContext';
import { usePageView } from '@/shared/hooks';
import { SettingsLayout } from '@/shared/layouts';

import { CommunicationPreferences } from './CommunicationPreferences';
import { useSettings } from './context/SettingsContext';
import CopyOrganizationId from './CopyOrganizationId';
import GeneralView from './GeneralView';
import { LinkTracking } from './LinkTracking';

export function General() {
  usePageView();

  const {
    settingsState: {
      organizationName,
      organizationAttachment,
      settings,
      selectedIndustries,
    },
    getOrganizationInfo,
  } = useSettings();

  useEffect(() => {
    getOrganizationInfo();
  }, []);

  return (
    <>
      <Helmet>
        <title>Whippy | Organization</title>
      </Helmet>
      <SettingsLayout
        breadcrumbs={[
          { title: 'Settings', path: '/settings/general' },
          { title: 'Organization', path: `/settings/general` },
        ]}
      >
        <ReviewState>
          <GeneralView
            organizationName={organizationName}
            selectedIndustries={selectedIndustries}
            organizationAttachment={organizationAttachment}
            getOrganizationInfo={getOrganizationInfo}
          />
          <CommunicationPreferences settings={settings} />
          <LinkTracking linkTracking={settings.link_tracking} />
          <CopyOrganizationId />
        </ReviewState>
      </SettingsLayout>
    </>
  );
}
