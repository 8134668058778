/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { toast } from 'sonner';

import { User } from '@/shared/types/users';
import { Box } from '@/shared/ui';
import i18next from '@/shared/utils/translation';
import { ComboboxMultiselect } from '@/shared/v2/components/comboboxMultiselect/ComboboxMultiselect';
import { ComboboxMultiselectItem } from '@/shared/v2/components/comboboxMultiselect/ComboboxMultiselectItem';
import { ComboboxMultiselectTrigger } from '@/shared/v2/components/comboboxMultiselect/ComboboxMultiselectTrigger';

import { useUsers } from './context/UserContext';

type SelectedItem = {
  /** The type of the selected item */
  type: string | undefined;
  /** The label of the selected item */
  value: string;
};

type UpdateUserProps = {
  /** The list of items for multiselect */
  list: SelectedItem[];
  /** The initial state for the multi select */
  initialValues: string[];
  /** If the user is not an admin, they cant change this */
  disabled: boolean;
  /** The user to update */
  user: User;
  /** Is the single select clickable? */
  isClickable: boolean;
};

export const UpdateUser = ({
  list,
  initialValues,
  disabled,
  user,
  isClickable,
}: UpdateUserProps) => {
  const [selectedItems, setSelectedItems] = useState<string[]>([...initialValues]);
  const [placeholderText, setPlaceholderText] = useState('');
  const { updateUser } = useUsers();

  const updatePlaceholderText = () => {
    const firstSelectedOption = list.find(
      (item: SelectedItem) => item.value === selectedItems[0]
    );
    if (selectedItems.length === 1) {
      return firstSelectedOption?.type || '';
    } else if (selectedItems?.length > 1) {
      return `${firstSelectedOption?.type} & ${selectedItems?.length - 1} more` || '';
    } else {
      return '';
    }
  };

  useEffect(() => {
    setPlaceholderText(updatePlaceholderText());
  }, [selectedItems]);

  const setSelectedValues = (locations: Array<string>) => {
    if (locations.length > 0) {
      updateUser({
        ...user,
        locations: [...locations],
      });
      setSelectedItems(locations);
    } else {
      toast.error(i18next.t('user_update_unselect_error') as string);
    }
  };

  return (
    <Box
      css={{
        display: 'inline-block',
        pointerEvents: isClickable ? 'auto' : 'none',
        cursor: isClickable ? 'pointer' : 'default',
        width: 300,
      }}
    >
      <Box>
        <ComboboxMultiselect
          options={list.map((location: SelectedItem) => ({
            label: location.type as string,
            value: location?.value,
          }))}
          selected={selectedItems}
          onSelect={setSelectedValues}
          searchLabel="Search"
          selectLabel={placeholderText}
          Trigger={ComboboxMultiselectTrigger}
          Option={ComboboxMultiselectItem}
          disabled={disabled}
          width={300}
          selectAll
        />
      </Box>
    </Box>
  );
};
