export const default_conversation_object = {
  id: 'unique-id-for-conversation',
  label: 'Conversation',
  key: 'conversation',
  inserted_at: 'current-timestamp',
  updated_at: 'current-timestamp',
  custom_properties: [
    {
      default: '',
      id: 'conversation-status',
      label: 'Status',
      type: 'conversation_status',
      key: 'status',
      required: true,
      inserted_at: 'current-timestamp',
      updated_at: 'current-timestamp',
      custom_object_id: 'unique-id-for-conversation',
    },
    {
      default: '',
      id: 'conversation-last_assigned_user',
      label: 'Assigned User',
      type: 'user',
      key: 'assigned_user_id',
      required: true,
      inserted_at: 'current-timestamp',
      updated_at: 'current-timestamp',
      custom_object_id: 'unique-id-for-conversation',
    },
    {
      default: '',
      id: 'conversation-channel',
      label: 'Channel',
      type: 'channel',
      key: 'location_id',
      required: true,
      inserted_at: 'current-timestamp',
      updated_at: 'current-timestamp',
      custom_object_id: 'unique-id-for-conversation',
    },
    {
      default: 'current-timestamp',
      id: 'conversation-last_message_at',
      label: 'Last Message At',
      type: 'date',
      key: 'last_message_timestamp',
      required: true,
      inserted_at: 'current-timestamp',
      updated_at: 'current-timestamp',
      custom_object_id: 'unique-id-for-conversation',
    },
    {
      default: 'current-timestamp',
      id: 'conversation-inserted_at',
      label: 'Inserted At',
      type: 'date',
      key: 'inserted_at',
      required: true,
      inserted_at: 'current-timestamp',
      updated_at: 'current-timestamp',
      custom_object_id: 'unique-id-for-conversation',
    },
    {
      default: 'current-timestamp',
      id: 'conversation-updated_at',
      label: 'Updated At',
      type: 'date',
      key: 'updated_at',
      required: true,
      inserted_at: 'current-timestamp',
      updated_at: 'current-timestamp',
      custom_object_id: 'unique-id-for-conversation',
    },
  ],
};
