import { Attachment } from '..';
import { Domain } from '../domains';

export type Link = {
  created_by: {
    attachment: Attachment;
    email: string;
    id: number;
    name: string;
  };
  custom_card_description?: string | null;
  custom_card_image?: Attachment | null;
  custom_card_title?: string | null;
  deleted_at: string;
  deleted_by: string;
  description: string;
  domain: Domain;
  id: string;
  inserted_at: string;
  organization: {
    attachment: Attachment;
    id: string;
    organization_name: string;
  };
  shareable_link: {
    id: string;
    link_setting_id: string;
    short_key: string;
    short_url: string;
    url: string;
  };
  status: string;
  updated_at: string;
  updated_by: {
    attachment: Attachment;
    email: string;
    id: number;
    name: string;
  };
  url: string;
  use_custom_card: boolean;
  utm_campaign: string | null;
  utm_content: string | null;
  utm_medium: string | null;
  utm_source: string | null;
  utm_term: string | null;
};

// link accordion types
export enum AccordionValue {
  LINK = 'link',
  SETTINGS = 'settings',
  DEFAULT_VALUE = '',
}

// link panel types
export enum PanelType {
  VIEW_PREVIEW = 'view_preview',
}

export type CustomCardOptions = {
  custom_card_description: string | null;
  custom_card_image: Attachment | null;
  custom_card_title: string | null;
};

export type UtmBuilderOptions = {
  utm_campaign: string | null;
  utm_content: string | null;
  utm_medium: string | null;
  utm_source: string | null;
  utm_term: string | null;
};
