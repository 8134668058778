import React, { useEffect, useState } from 'react';

import { ChannelTypes, ProviderTypes } from '@/shared/types/channels';
import { Button, Fieldset, Input, Label, VStack } from '@/shared/ui'; // Adjust imports based on your UI library

import { useChannels } from '../context/ChannelContext'; // Adjust the import path as needed

declare global {
  interface Window {
    fbAsyncInit: () => void;
    FB: fb.FacebookStatic;
  }
}

interface MessageEventData {
  data: {
    phone_number_id: string;
    waba_id: string;
  };
  type: string;
  event: string;
  version: number;
}

interface CustomLoginOptions extends fb.LoginOptions {
  config_id?: string;
  response_type?: string;
  override_default_response_type?: boolean;
  extras?: {
    setup: Record<string, unknown>;
    featureType: string;
    sessionInfoVersion: string;
  };
}

interface CustomAuthResponse extends fb.AuthResponse {
  code?: string;
}

const CONFIG_ID = '538275532388831';
const APP_ID = '448689774328699';

export const WhatsAppSignup: React.FC = () => {
  const { addChannel } = useChannels(); // Use the new function from context

  const [isSdkLoaded, setIsSdkLoaded] = useState<boolean>(false);

  const [code, setCode] = useState<string | null>(null);
  const [phoneNumberId, setPhoneNumberId] = useState<string | null>(null);
  const [wabaId, setWabaId] = useState<string | null>(null);
  const [name, setName] = useState<string>('');
  const [businessAddress, setBusinessAddress] = useState<string>('');
  const [phonePin, setPhonePin] = useState<string>('');

  useEffect(() => {
    const loadFacebookSDK = () => {
      if (document.getElementById('facebook-jssdk')) {
        // SDK script already exists
        return;
      }

      const script = document.createElement('script');
      script.id = 'facebook-jssdk';
      script.src = 'https://connect.facebook.net/en_US/sdk.js';
      script.async = true;
      script.defer = true;
      script.crossOrigin = 'anonymous';

      document.body.appendChild(script);
    };

    window.fbAsyncInit = function () {
      window.FB.init({
        appId: APP_ID,
        autoLogAppEvents: true,
        xfbml: true,
        version: 'v21.0',
      });

      setIsSdkLoaded(true);
    };

    loadFacebookSDK();

    // Message event listener
    const messageListener = (event: MessageEvent) => {
      if (
        event.origin !== 'https://www.facebook.com' &&
        event.origin !== 'https://web.facebook.com'
      )
        return;

      try {
        const data: MessageEventData = JSON.parse(event.data);
        if (data.type === 'WA_EMBEDDED_SIGNUP' && data.event === 'FINISH') {
          const { phone_number_id, waba_id } = data.data;
          setPhoneNumberId(phone_number_id);
          setWabaId(waba_id);
        }
      } catch (error) {
        console.error('Error parsing message event data:', error);
      }
    };

    window.addEventListener('message', messageListener);

    return () => {
      window.removeEventListener('message', messageListener);
    };
  }, []);

  const fbLoginCallback = (response: fb.StatusResponse) => {
    if (response.authResponse) {
      const authResponse = response.authResponse as CustomAuthResponse;
      const code = authResponse.code;

      if (code) {
        setCode(code);
      }
    }
  };

  // Launch method and callback registration
  const launchWhatsAppSignup = () => {
    if (window.FB) {
      const loginOptions: CustomLoginOptions = {
        config_id: CONFIG_ID,
        response_type: 'code',
        override_default_response_type: true,
        extras: {
          setup: {},
          featureType: '',
          sessionInfoVersion: '3',
        },
      };

      window.FB.login(fbLoginCallback, loginOptions);
    } else {
      console.error('Facebook SDK not loaded yet.');
    }
  };

  // Send data to the server when all data is available
  useEffect(() => {
    if (code && phoneNumberId && wabaId) {
      const channel_params = {
        name,
        address: businessAddress,
        provider: ProviderTypes.META,
        type: ChannelTypes.WHATSAPP,
      };

      const meta_onboarding_params = {
        code,
        phone_pin: phonePin,
        phone_number_id: phoneNumberId,
        waba_id: wabaId,
      };

      addChannel(channel_params, meta_onboarding_params);

      // Reset state after submission
      setName('');
      setBusinessAddress('');
      setPhonePin('');
      setCode(null);
      setPhoneNumberId(null);
      setWabaId(null);
    }
  }, [code, phoneNumberId, wabaId]);

  return (
    <div>
      <VStack gap={2}>
        <Fieldset>
          <Label>Name</Label>
          <Input
            placeholder="Business Name"
            value={name}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
          />
        </Fieldset>
        <Fieldset>
          <Label>Business Address</Label>
          <Input
            placeholder="123 Main St, City, State"
            value={businessAddress}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setBusinessAddress(e.target.value)
            }
          />
        </Fieldset>
        <Fieldset>
          <Label>Phone Number Pin</Label>
          <Input
            placeholder="6 digit pin for two step verification, if the phone was already registered within whatsapp you must send the same pin"
            value={phonePin}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setPhonePin(e.target.value)
            }
          />
        </Fieldset>
      </VStack>
      <Button
        onClick={launchWhatsAppSignup}
        disabled={!isSdkLoaded}
        style={{
          backgroundColor: '#1877f2',
          border: 0,
          borderRadius: '4px',
          color: '#fff',
          cursor: 'pointer',
          fontFamily: 'Helvetica, Arial, sans-serif',
          fontSize: '16px',
          fontWeight: 'bold',
          height: '40px',
          padding: '0 24px',
          marginTop: '16px',
        }}
      >
        Login with Facebook
      </Button>
    </div>
  );
};
