import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { toast } from 'sonner';

import * as API from '@/shared/api/auth';
import { usePageView } from '@/shared/hooks';
import { Button, Fieldset, Flex, Input, Label, VStack } from '@/shared/ui';
import i18next from '@/shared/utils/translation';
import { isValidEmail } from '@/shared/utils/validations/validations';

import { AuthContainer, AuthPageTitle, AuthPageWrapper } from './Login';

export const ForgotPassword = () => {
  usePageView();
  const [email, setEmail] = useState('');

  const handleChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!isValidEmail(email)) {
      return toast.error(i18next.t('enter_valid_email') as string);
    }

    try {
      await API.sendPasswordResetEmail(email);
      toast.success(i18next.t('password_reset_email_sent') as string);
    } catch (e) {
      console.log(e);
      if (e.code === 'ERR_NETWORK') {
        toast.error(i18next.t('network_error'));
      }
      if (e.response?.data?.error?.status === 500) {
        toast.error(i18next.t('server_error'));
      }
    }
  };

  return (
    <>
      <Helmet>
        <title>Whippy | Forgot Password</title>
      </Helmet>
      <AuthPageWrapper align="center" justify="center">
        <AuthContainer>
          <AuthPageTitle css={{ fontSize: 18, pb: 15 }}>Reset password</AuthPageTitle>
          <form onSubmit={handleSubmit}>
            <VStack gap={5}>
              <Fieldset>
                <Label htmlFor="email">Email</Label>
                <Input
                  id="email"
                  type="email"
                  value={email}
                  onChange={handleChangeEmail}
                  required={true}
                />
              </Fieldset>
              <Button type="submit" css={{ width: '100%' }}>
                Send Email
              </Button>
              <Flex justify="center">
                <Link to="/login">Return to login?</Link>
              </Flex>
            </VStack>
          </form>
        </AuthContainer>
      </AuthPageWrapper>
    </>
  );
};
