import { Channel } from '../channels';
import { SearchFilters } from '../contacts';
import { User, UserAttachment } from '../users';
import { WorkSchedule } from './schedule';

export type Team = {
  id?: string;
  assignment_method?: string;
  inserted_at?: string;
  updated_at?: string;
  locations?: Array<Channel>;
  location_ids?: Array<string>;
  name?: string;
  team_members?: Array<TeamMember>;
  work_schedules?: Array<WorkSchedule>;
  timezone?: string;
  created_by?: Partial<User>;
  updated_by?: Partial<User>;
};

export type TeamMember = {
  id: string;
  inserted_at: string;
  team_id: string;
  updated_at: string;
  user_organization_id: number;
  work_schedules: Array<WorkSchedule>;
  user_id: number;
  name?: string;
  attachment?: UserAttachment | null;
};

export type TeamsState = {
  allTeams: Array<Team>;
  teams: Array<Team>;
  team_members: Array<TeamMember>;
  totalCount: number;
  error: any;
  loading: boolean;
  current: Team | null;
  currentTeamMember: TeamMember | null;
  filterParams: SearchFilters;
};

export enum TeamsActionType {
  GET_TEAMS = 'GET_TEAMS',
  GET_TEAM = 'GET_TEAM',
  CREATE_TEAM = 'CREATE_TEAM',
  SET_CURRENT = 'SET_CURRENT',
  CLEAR_CURRENT = 'CLEAR_CURRENT',
  CLEAR_CURRENT_TEAM_MEMBER = 'CLEAR_CURRENT_TEAM_MEMBER',
  UPDATE_TEAM = 'UPDATE_TEAM',
  DELETE_TEAM = 'DELETE_TEAM',
  SET_LOADING = 'SET_LOADING',
  SET_ERROR = 'SET_ERROR',
  GET_TEAM_MEMBERS = 'GET_TEAM_MEMBERS',
  GET_TEAM_MEMBER = 'GET_TEAM_MEMBER',
  CREATE_TEAM_MEMBER = 'CREATE_TEAM_MEMBER',
  DELETE_TEAM_MEMBER = 'DELETE_TEAM_MEMBER',
  GET_WORK_SCHEDULES = 'GET_WORK_SCHEDULES',
  CREATE_WORK_SCHEDULES = 'CREATE_WORK_SCHEDULES',
  UPDATE_WORK_SCHEDULES = 'UPDATE_WORK_SCHEDULES',
  DELETE_WORK_SCHEDULES = 'DELETE_WORK_SCHEDULES',
  CREATE_TEAM_MEMBER_WORK_SCHEDULES = 'CREATE_TEAM_MEMBER_WORK_SCHEDULES',
  UPDATE_TEAM_MEMBER_WORK_SCHEDULES = 'UPDATE_TEAM_MEMBER_WORK_SCHEDULES',
  DELETE_TEAM_MEMBER_WORK_SCHEDULES = 'DELETE_TEAM_MEMBER_WORK_SCHEDULES',
  UPDATE_FILTER_PARAMS = 'UPDATE_FILTER_PARAMS',
  GET_ALL_TEAMS = 'GET_ALL_TEAMS',
}

export type TeamsAction =
  | {
      type: TeamsActionType.GET_TEAMS;
      payload: { data: Array<Team>; total: number };
    }
  | { type: TeamsActionType.GET_TEAM; payload: Team }
  | { type: TeamsActionType.CREATE_TEAM; payload: Team }
  | { type: TeamsActionType.UPDATE_TEAM; payload: Team }
  | { type: TeamsActionType.SET_CURRENT; payload: Team | null }
  | { type: TeamsActionType.CLEAR_CURRENT }
  | { type: TeamsActionType.CLEAR_CURRENT_TEAM_MEMBER }
  | { type: TeamsActionType.DELETE_TEAM; payload: string }
  | { type: TeamsActionType.GET_TEAM_MEMBERS; payload: Array<TeamMember> }
  | { type: TeamsActionType.GET_TEAM_MEMBER; payload: TeamMember }
  | { type: TeamsActionType.CREATE_TEAM_MEMBER; payload: TeamMember }
  | { type: TeamsActionType.DELETE_TEAM_MEMBER; payload: string }
  | { type: TeamsActionType.GET_WORK_SCHEDULES; payload: Array<WorkSchedule> }
  | { type: TeamsActionType.CREATE_WORK_SCHEDULES; payload: WorkSchedule }
  | { type: TeamsActionType.UPDATE_WORK_SCHEDULES; payload: WorkSchedule }
  | { type: TeamsActionType.DELETE_WORK_SCHEDULES; payload: string }
  | { type: TeamsActionType.CREATE_TEAM_MEMBER_WORK_SCHEDULES; payload: WorkSchedule }
  | { type: TeamsActionType.UPDATE_TEAM_MEMBER_WORK_SCHEDULES; payload: WorkSchedule }
  | { type: TeamsActionType.DELETE_TEAM_MEMBER_WORK_SCHEDULES; payload: string }
  | { type: TeamsActionType.SET_ERROR; payload: any }
  | { type: TeamsActionType.UPDATE_FILTER_PARAMS; payload: SearchFilters }
  | {
      type: TeamsActionType.GET_ALL_TEAMS;
      payload: { data: Array<Team>; total: number };
    };
