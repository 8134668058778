import dayjs, { Dayjs } from 'dayjs';

import { ContactStateType } from '../contacts';
import { SequenceContactStatusType } from '../sequences';

/**
 * This is an attempt to come up with a flexible type system for the filtering and sorting.
 *
 * We should be able to re use this in both the filtering components, contexts and api calls etc.
 *
 * The way I thought about this was:
 *
 * 1. There are a finite number of resources that can be filtered and sorted.
 * 2. Each resource has a finite number of columns that can be filtered and sorted and are scoped to that resource.
 * 3. Each column has a finite number of types that can be filtered and sorted.
 * 4. Each type has a finite number of value types that can be filtered and sorted.
 * 5. Each value type has a finite number of operators that can be filtered and sorted.
 *
 * So we can create a type for each of these and then use them to create a type for the filter and sort configs.
 */

/**
 * Enum representing the different resources that can be filtered and sorted.
 * Each resource corresponds to a different type of data in the system.
 */
export enum Resource {
  /**
   * Represents a contact in the Whippy.
   */
  Contact = 'contact',
  /**
   * Represents a step contact in the system. A step contact is a contact that
   * has been added to a step of a sequence.
   */
  StepContact = 'step_contact',
  /**
   * Represents a contact message in the system. A contact message could be any
   * form of communication or message that is sent to or received from a contact.
   */
  ContactMessage = 'contact_message',
  /**
   * Represents a campaign contact in the system. A campaign contact is a contact that
   * has been added to a campaign.
   */
  CampaignContact = 'campaign_contact',
  /**
   * Represents a custom field in the system. A custom field is a field that can be added
   */
  CustomObjectRecord = 'custom_object_record',
  Segment = 'segment',
  User = 'user',
  UserOrganization = 'user_organization',
  List = 'list',
  Invite = 'invite',
  Template = 'message_template',
  CustomData = 'custom_object',
  CustomProperty = 'custom_property',
  Agent = 'agent',
  AgentVersion = 'agent_version',
  Call = 'call',
  Tag = 'tag',
  Signature = 'signature',
  Location = 'location',
  Team = 'team',
  Domain = 'domain',
  ApiKey = 'api_key',
  Application = 'application',
}

/**
 * Enum representing the different types of columns that can be filtered and sorted.
 * This is a supers set of all the columns that can be filtered and sorted for all resources.
 * When you add a new resource you should add the columns that can be filtered and sorted for that resource to this enum.
 **/
export enum ColumnType {
  Name = 'name',
  Email = 'email',
  Phone = 'phone',
  Source = 'source',
  State = 'state',
  Blocked = 'blocked',
  SMSOptIn = 'opt_in_sms',
  EmailOptIn = 'opt_in_sms',
  OptOutOfAll = 'opt_out_of_all',
  InsertedAt = 'inserted_at',
  UpdatedAt = 'updated_at',
  CreatedAt = 'created_at',
  Status = 'status',
  StepId = 'step_id',
  Body = 'body',
  ClickedLink = 'clicked_link',
  Unsubscribed = 'unsubscribed',
  Title = 'title',
  Label = 'label',
  Type = 'type',
  Role = 'role',
  Active = 'active',
}

/**
 * Type representing a mapping between resources and their corresponding columns.
 * When you add a new resource you should add the columns that can be filtered and sorted for that resource to this type.
 **/
export type ColumnMap = {
  [Resource.Contact]:
    | ColumnType.Name
    | ColumnType.Email
    | ColumnType.Phone
    | ColumnType.Source
    | ColumnType.Blocked
    | ColumnType.SMSOptIn
    | ColumnType.EmailOptIn
    | ColumnType.OptOutOfAll
    | ColumnType.UpdatedAt
    | ColumnType.InsertedAt;
  [Resource.StepContact]: ColumnType.InsertedAt | ColumnType.Status | ColumnType.StepId;
  [Resource.ContactMessage]:
    | ColumnType.InsertedAt
    | ColumnType.UpdatedAt
    | ColumnType.Body;
  [Resource.CampaignContact]: ColumnType.ClickedLink | ColumnType.Unsubscribed;
  [Resource.CustomObjectRecord]: ColumnType.InsertedAt | ColumnType.UpdatedAt;
  [Resource.Segment]: ColumnType.Name | ColumnType.InsertedAt | ColumnType.UpdatedAt;
  [Resource.User]: ColumnType.Name | ColumnType.Email | ColumnType.Role;
  [Resource.UserOrganization]: ColumnType.Name | ColumnType.Email | ColumnType.Role;
  [Resource.List]: ColumnType.Name | ColumnType.InsertedAt | ColumnType.UpdatedAt;
  [Resource.Invite]: ColumnType.Email | ColumnType.InsertedAt;
  [Resource.Template]: ColumnType.Title | ColumnType.InsertedAt | ColumnType.UpdatedAt;
  [Resource.CustomData]: ColumnType.Label | ColumnType.InsertedAt | ColumnType.UpdatedAt;
  [Resource.CustomProperty]:
    | ColumnType.Label
    | ColumnType.InsertedAt
    | ColumnType.UpdatedAt
    | ColumnType.Type;
  [Resource.Agent]: ColumnType.UpdatedAt;
  [Resource.AgentVersion]: ColumnType.InsertedAt | ColumnType.UpdatedAt;
  [Resource.Call]: ColumnType.UpdatedAt;
  [Resource.Tag]: ColumnType.Name | ColumnType.InsertedAt | ColumnType.UpdatedAt;
  [Resource.Signature]:
    | ColumnType.Body
    | ColumnType.Name
    | ColumnType.InsertedAt
    | ColumnType.UpdatedAt;
  [Resource.Location]: ColumnType.Name | ColumnType.InsertedAt | ColumnType.UpdatedAt;
  [Resource.Team]: ColumnType.Name | ColumnType.InsertedAt | ColumnType.UpdatedAt;
  [Resource.Team]: ColumnType.Name | ColumnType.InsertedAt | ColumnType.UpdatedAt;
  [Resource.Domain]: ColumnType.Name | ColumnType.InsertedAt | ColumnType.UpdatedAt;
  [Resource.Team]: ColumnType.Name | ColumnType.InsertedAt | ColumnType.UpdatedAt;
  [Resource.ApiKey]:
    | ColumnType.Name
    | ColumnType.Active
    | ColumnType.InsertedAt
    | ColumnType.UpdatedAt;
  [Resource.Application]:
    | ColumnType.Name
    | ColumnType.InsertedAt
    | ColumnType.UpdatedAt
    | ColumnType.Active;
};

/**
 * Type representing a mapping between column types and their corresponding operators.
 * Columns can only have certain operators depending on their type. Every time you add a new column
 * you should add the operators that can be applied to that column to this type.
 **/
type OperatorTypeMap = {
  [ColumnType.Name]: 'contains' | 'exact';
  [ColumnType.Email]: 'contains' | 'exact';
  [ColumnType.Phone]: 'contains' | 'exact';
  [ColumnType.Source]: 'contains' | 'exact';
  [ColumnType.State]: 'exact';
  [ColumnType.Blocked]: 'exact';
  [ColumnType.SMSOptIn]: 'exact';
  [ColumnType.EmailOptIn]: 'exact';
  [ColumnType.OptOutOfAll]: 'exact';
  [ColumnType.InsertedAt]: '<=' | '>=';
  [ColumnType.Status]: 'exact';
  [ColumnType.StepId]: 'exact';
  [ColumnType.UpdatedAt]: '<=' | '>=';
  [ColumnType.Body]: 'contains' | 'exact';
  [ColumnType.ClickedLink]: 'exact';
  [ColumnType.Unsubscribed]: 'exact';
  [ColumnType.Title]: 'contains' | 'exact';
  [ColumnType.Label]: 'contains' | 'exact';
  [ColumnType.Type]: 'contains' | 'exact';
  [ColumnType.Role]: 'exact';
  [ColumnType.Active]: 'exact';
};

/**
 * Type representing a mapping between column types and their corresponding value types.
 * Depending on the type you can apply different operators, every time you add a new column
 * you should add the value types that can be applied to that column to this type.
 **/
type ValueTypeMap = {
  [ColumnType.Name]: string;
  [ColumnType.Email]: string;
  [ColumnType.Phone]: string;
  [ColumnType.Status]: SequenceContactStatusType;
  [ColumnType.Source]: string;
  [ColumnType.State]: ContactStateType;
  [ColumnType.Blocked]: boolean;
  [ColumnType.SMSOptIn]: boolean;
  [ColumnType.EmailOptIn]: boolean;
  [ColumnType.OptOutOfAll]: boolean;
  [ColumnType.StepId]: string;
  [ColumnType.InsertedAt]: dayjs.Dayjs;
  [ColumnType.UpdatedAt]: dayjs.Dayjs;
  [ColumnType.Body]: string;
  [ColumnType.ClickedLink]: boolean;
  [ColumnType.Unsubscribed]: boolean;
  [ColumnType.Title]: string;
  [ColumnType.Label]: string;
  [ColumnType.Type]: string;
  [ColumnType.Role]: string;
  [ColumnType.Active]: boolean;
};

/**
 * Type representing an option for a column in a filter configuration.
 * For example if you were filtering contacts by name you would have a column option for name.
 * And if you tried to apply an operator that was not valid for the column type you would get a type error.
 */
type ColumnOption<R extends Resource, C extends ColumnMap[R]> = {
  label: string;
  column: C;
  resource: R;
  operator: OperatorTypeMap[C] | null;
  type: ValueTypeMap[C];
  value: ValueTypeMap[C] | null;
};

/**
 * Type representing a filter configuration for a resource.
 **/
export type FilterConfig<R extends Resource, C extends ColumnMap[R]> = {
  label: string;
  columnOptions: ColumnOption<R, C>[];
};

/** Type representing the order of sorting **/
export type OrderType = 'asc' | 'desc';

export enum OperationTypes {
  Equal = '==',
  NotEqual = '!=',
  LessThan = '<',
  GreaterThan = '>',
  LessThanOrEqual = '<=',
  GreaterThanOrEqual = '>=',
  ILike = 'ilike',
  Like = 'like',
  NotILike = 'not_ilike',
  NotLike = 'not_like',
  In = 'in',
  NotIn = 'not_in',
  Contains = 'contains',
  NotContains = 'not_contains',
  IsContained = 'is_contained',
}

/**
 * Type representing a mapping between resources and their corresponding columns for sorting.
 * When you add a new resource you should add the columns that can be sorted for that resource to this type.
 **/
export type SortColumnMap = {
  [Resource.Contact]:
    | ColumnType.Name
    | ColumnType.Email
    | ColumnType.Phone
    | ColumnType.UpdatedAt
    | ColumnType.InsertedAt;
  [Resource.StepContact]: ColumnType.InsertedAt | ColumnType.Status | ColumnType.StepId;
  [Resource.ContactMessage]: ColumnType.InsertedAt | ColumnType.UpdatedAt;
  [Resource.CampaignContact]: ColumnType.ClickedLink | ColumnType.Unsubscribed;
  [Resource.CustomObjectRecord]: ColumnType.InsertedAt | ColumnType.UpdatedAt;
  [Resource.Segment]: ColumnType.Name | ColumnType.InsertedAt | ColumnType.UpdatedAt;
  [Resource.User]: ColumnType.Name | ColumnType.Email | ColumnType.Role;
  [Resource.UserOrganization]: ColumnType.Name | ColumnType.Email | ColumnType.Role;
  [Resource.List]: ColumnType.Name | ColumnType.InsertedAt | ColumnType.UpdatedAt;
  [Resource.Invite]: ColumnType.Email | ColumnType.InsertedAt;
  [Resource.Template]: ColumnType.Title | ColumnType.InsertedAt | ColumnType.UpdatedAt;
  [Resource.CustomData]: ColumnType.Label | ColumnType.InsertedAt | ColumnType.UpdatedAt;
  [Resource.CustomProperty]:
    | ColumnType.Label
    | ColumnType.InsertedAt
    | ColumnType.UpdatedAt
    | ColumnType.Type;
  [Resource.Agent]: ColumnType.UpdatedAt;
  [Resource.AgentVersion]: ColumnType.InsertedAt | ColumnType.UpdatedAt;
  [Resource.Call]: ColumnType.UpdatedAt;
  [Resource.Tag]: ColumnType.Name | ColumnType.InsertedAt | ColumnType.UpdatedAt;
  [Resource.Signature]:
    | ColumnType.Body
    | ColumnType.Name
    | ColumnType.InsertedAt
    | ColumnType.UpdatedAt;
  [Resource.Location]: ColumnType.Name | ColumnType.InsertedAt | ColumnType.UpdatedAt;
  [Resource.Team]: ColumnType.Name | ColumnType.InsertedAt | ColumnType.UpdatedAt;
  [Resource.Team]: ColumnType.Name | ColumnType.InsertedAt | ColumnType.UpdatedAt;
  [Resource.Domain]: ColumnType.Name | ColumnType.InsertedAt | ColumnType.UpdatedAt;
  [Resource.Team]: ColumnType.Name | ColumnType.InsertedAt | ColumnType.UpdatedAt;
  [Resource.ApiKey]:
    | ColumnType.Name
    | ColumnType.InsertedAt
    | ColumnType.UpdatedAt
    | ColumnType.Active;
  [Resource.Application]:
    | ColumnType.Name
    | ColumnType.InsertedAt
    | ColumnType.UpdatedAt
    | ColumnType.Active;
};

export type SortColumnOption<R extends Resource, C extends SortColumnMap[R]> = {
  label: string;
  column: C;
  order: OrderType;
  resource: R;
};

export type SortConfig<R extends Resource, C extends SortColumnMap[R]> = {
  label: string;
  columnOptions: SortColumnOption<R, C>[];
};

export type Filter = {
  label: string;
  column: string;
  resource: string;
  operator: string | null;
  type: string;
  value: string | Dayjs | null;
  comparison?: string;
  id: number | null;
  subOptions?: Filter[];
};

export type Sort = {
  label: string;
  column: string;
  order: OrderType;
  resource: string;
  id: number | null;
  subOptions?: Sort[];
};

export type TabValueMapping = {
  [key: string]: () => number | null;
};

export type DataRow = {
  contact: {
    name: string;
    phone: string;
  };
  contact_messages: {
    body: string;
  }[];
  campaign_messages: {
    error: string;
  }[];
};

export type FilterItem = {
  resource: string;
  column: string;
  comparison: string | null;
  value: string[] | string | boolean | number | null | undefined;
  or?: FilterItem[];
  and?: FilterItem[];
};

export type DateFilterItem = FilterItem & {
  cast: {
    from: string;
    to: string;
  };
};

export type DropdownFilterItem = {
  resource: string;
  column: string;
  comparison: string | null;
  value: string | Dayjs | null;
};

export type ExtendedFilterItem = {
  type: string;
  value: string | boolean | number;
};

export type SortItem = {
  resource: string;
  column: string;
  order: string;
};

type TabFilterValue = {
  filter: FilterItem[];
  extended_filter?: ExtendedFilterItem[];
};

export type TabFilter = {
  label: string;
  key: string;
  value: TabFilterValue;
};

export type DropdownOption = {
  label: string;
  value?: string | OrderType | null;
  operator?: string | null;
};

export type FilterParams = {
  filter?: FilterItem[];
  sort?: SortItem[];
  limit?: number;
  offset?: number;
};

export type FilterTypeInputType =
  | 'number'
  | 'boolean'
  | 'campaign'
  | 'sequence'
  | 'tag'
  | 'list'
  | 'channel'
  | 'team'
  | 'user'
  | 'conversation_status'
  | 'date'
  | 'language'
  | 'combobox'
  | 'text'
  | 'birth-date';
/**
 * This type is used to represent the frontend friendly version of FilterItem.
 *
 * The 'or' and 'and' fields represents the same logic as the 'or' and 'and' fields
 * found in the FilterItem however this version uses FilterType instead of FilterItem.
 *
 * The 'type' field represents relevant information needed to smartly display information for the Input that the
 * advanced filter uses. It is built depending on the resource and the column of the FilterItem.
 * This is useful in two ways.
 * 1. We need to display a type specific input like date or number.
 *     - If the type is something like 'date' then we'd need to display a date picker.
 * 2. We need additional information in order to determine what data to fetch
 *     - If we have a campaign, then we need to make the input a dropdown where the
 *         user can select a valid campaign.
 *
 */
export type FilterType = {
  id: string;
  resource: string;
  column: string;
  comparison: string;
  value: string | number | null;
  and: FilterType[];
  or: FilterType[];
  on: JoinType[];
  type: FilterTypeInputType;
  cast?: { from: string; to: string } | null;
};

export type JoinType = {
  parent: string;
  parent_column: string;
  child: string;
  child_column: string;
};

export type FilterData = {
  filter: Array<FilterItem>;
  extended_filter?: Array<ExtendedFilterItem>;
  sort: Array<Sort>;
  limit: number;
  offset: number;
};
