import React from 'react';
import { HiX } from 'react-icons/hi';

import { ContactTagLayout } from '@/pages/contacts/editor/tags/ContactTag';
import { CircleIcon } from '@/shared/components/Icons';
import { Box, HStack, IconButton } from '@/shared/ui';
import { formatPhoneNumber } from '@/shared/utils/validations/validations';
import { styled } from '@/stitches.config';

type AudienceItemProps = {
  /* the audience item to be displayed */
  item: { id: string; name?: string; phone?: string };
  /* the function to be called when the delete button is clicked */
  onItemDelete: () => void;
  /* the color of the circle icon */
  color: string;
  /* is the item disabled */
  disabled?: boolean;
};

export const AudienceItem = (props: AudienceItemProps) => {
  const { item, onItemDelete, color, disabled } = props;
  return (
    <ContactTagLayout css={{ height: 30, ml: 0 }}>
      <HStack gap={1}>
        <CircleIcon color={color} />
        <TagTextWrapper css={item?.name === 'Failed' ? { color } : {}}>
          {item?.name || formatPhoneNumber(item?.phone)}
        </TagTextWrapper>
        {!disabled && (
          <IconButton size={0} onClick={onItemDelete}>
            <HiX size={15} />
          </IconButton>
        )}
      </HStack>
    </ContactTagLayout>
  );
};

const TagTextWrapper = styled(Box, {
  whiteSpace: 'nowrap',
  cursor: 'default',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: '170px',
});
