import { Box, Text } from '@/shared/ui';

// Fallback Component to be rendered in case of an error
export const CampaignErrorBoundaryFallback = () => (
  <Box
    data-testid="campaign-error-boundary-fallback"
    css={{
      height: '100%',
      textAlign: 'center',
    }}
  >
    <Text>Oops! Something went wrong.</Text>
    <Text css={{ fontSize: 12, fontWeight: 400, color: '#7F7F86' }}>
      Please refresh the page and try again. If the issue persists please contact support.
    </Text>
  </Box>
);
