import * as SelectPrimitive from '@radix-ui/react-select';
import { Formik } from 'formik';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { HiChevronDown, HiChevronUp, HiCog, HiX } from 'react-icons/hi';
import * as Yup from 'yup';

import { UpdateTeamParams } from '@/shared/api/teams/types';
import { FormFieldWrapper, TextInput } from '@/shared/components/forms';
import { MultiSelect } from '@/shared/components/MultiSelect';
import { useDisclosure } from '@/shared/hooks';
import { Team } from '@/shared/types/team';
import {
  Button,
  Dialog,
  DialogClose,
  DialogCloseIcon,
  DialogContent,
  DialogFooter,
  DialogOverlay,
  DialogPortal,
  DialogTitle,
  DialogTrigger,
  IconButton,
  Select,
  SelectContent,
  SelectGroup,
  SelectIcon,
  SelectItem,
  SelectItemIndicator,
  SelectItemText,
  SelectLabel,
  SelectScrollUpButton,
  SelectTrigger,
  SelectValue,
  SelectViewport,
} from '@/shared/ui';

import { useChannels } from '../../channels/context/ChannelContext';
import { assignmentMethods } from '../constants';

type UpdateTeamProps = {
  /** current team data */
  team: Team;
  /** function triggered on submit form */
  onUpdate: (team_id: string, params: UpdateTeamParams) => Promise<void>;
};

export const UpdateTeam = memo(({ team, onUpdate }: UpdateTeamProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const {
    channelsState: { channels },
  } = useChannels();
  const [selectedLocations, setSelectedLocations] = useState({
    locations: (team?.location_ids as Array<string>) || [],
  });
  const [assignmentMethod, setAssignmentMethod] = useState(
    team?.assignment_method || assignmentMethods[0].value
  );

  // disable submit btn if location permission is selected without picking location/s
  const shouldDisableSubmitBtn = selectedLocations.locations.length < 1;

  // handle update team
  const handleSubmit = useCallback(
    async (
      values: {
        name: string;
        assignment_method: string;
        locations: Array<string>;
      },
      actions: any
    ) => {
      try {
        const params = {
          name: values.name,
          assignment_method: assignmentMethod,
          locations: selectedLocations.locations,
        } as UpdateTeamParams;
        team?.id && (await onUpdate(team?.id, params));
        actions.resetForm({
          values: {
            name: '',
            assignment_method: assignmentMethods[0].value,
            locations: [],
          },
        });
        onClose();
      } catch (e) {
        console.log(e);
      }
    },
    [assignmentMethod, selectedLocations.locations, team?.id]
  );

  // set initial assignment method
  useEffect(() => {
    team?.assignment_method && setAssignmentMethod(team?.assignment_method);
  }, [team?.assignment_method]);

  // set initial locations
  useEffect(() => {
    team?.location_ids?.length &&
      setSelectedLocations({ locations: team?.location_ids as Array<string> });
  }, [team?.location_ids]);

  return (
    <Dialog open={isOpen} onOpenChange={() => !isOpen}>
      <DialogTrigger asChild>
        <IconButton
          size={2}
          variant="outline"
          onClick={onOpen}
          data-testid="update-team-trigger"
          data-intercom-target="update-team-button"
          css={{ ml: 10 }}
        >
          <HiCog />
        </IconButton>
      </DialogTrigger>
      <DialogPortal>
        <DialogOverlay as="div">
          <DialogContent onEscapeKeyDown={onClose} onPointerDownOutside={onClose}>
            <DialogTitle variant="bold">Update Team</DialogTitle>
            <Formik
              initialValues={{
                name: team?.name || '',
                assignment_method: team?.assignment_method || '',
                locations: selectedLocations.locations || [],
              }}
              validationSchema={Yup.object({
                name: Yup.string().test(
                  'len',
                  'Must be at least 3 characters',
                  (val) => val !== undefined && val.length > 2
                ),
              })}
              onSubmit={handleSubmit}
            >
              {(formik) => (
                <form onSubmit={formik.handleSubmit} data-testid="update-team-form">
                  <FormFieldWrapper label="Team Name" name="name">
                    <TextInput placeholder="Example: Sales" />
                  </FormFieldWrapper>
                  <FormFieldWrapper label="Assignment method" name="assignment_method">
                    <Select
                      value={assignmentMethod}
                      onValueChange={(e: string) => setAssignmentMethod(e)}
                    >
                      <SelectTrigger aria-label="assignment-option-select-trigger">
                        <SelectValue />
                        <SelectIcon>
                          <HiChevronDown />
                        </SelectIcon>
                      </SelectTrigger>
                      <SelectPrimitive.Portal>
                        <SelectContent css={{ zIndex: 9999999 }}>
                          <SelectScrollUpButton>
                            <HiChevronUp />
                          </SelectScrollUpButton>
                          <SelectViewport>
                            <SelectGroup>
                              <SelectLabel>Assignment method</SelectLabel>
                              {assignmentMethods.map((option) => (
                                <SelectItem key={option.value} value={option.value}>
                                  <SelectItemIndicator />
                                  <SelectItemText>{option.label}</SelectItemText>
                                </SelectItem>
                              ))}
                            </SelectGroup>
                          </SelectViewport>
                        </SelectContent>
                      </SelectPrimitive.Portal>
                    </Select>
                  </FormFieldWrapper>
                  <FormFieldWrapper label="Channels" name="locations">
                    {channels.length > 0 && (
                      <MultiSelect
                        defaultPlaceholder="Channels"
                        defaultSelectedItems={selectedLocations.locations || []}
                        isDropdown={true}
                        options={channels.map((channel: any) => ({
                          type: channel?.name || '',
                          value: channel.id,
                        }))}
                        parentSelectedItems={selectedLocations}
                        setParentSelectedItems={setSelectedLocations}
                        isCampaigns={true}
                      />
                    )}
                  </FormFieldWrapper>
                  <DialogFooter justify="end" css={{ mt: 15 }}>
                    <DialogClose asChild>
                      <Button variant="gray" css={{ mr: '$1' }} onClick={onClose}>
                        Cancel
                      </Button>
                    </DialogClose>
                    <DialogClose asChild>
                      <Button
                        type="submit"
                        disabled={shouldDisableSubmitBtn || !formik.isValid}
                      >
                        Save Team
                      </Button>
                    </DialogClose>
                  </DialogFooter>
                </form>
              )}
            </Formik>
            <DialogClose asChild>
              <DialogCloseIcon onClick={onClose} size="2">
                <HiX />
              </DialogCloseIcon>
            </DialogClose>
          </DialogContent>
        </DialogOverlay>
      </DialogPortal>
    </Dialog>
  );
});
