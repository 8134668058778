/* eslint-disable react-hooks/exhaustive-deps */
import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import { HiX } from 'react-icons/hi';
import * as Yup from 'yup';

import { useAuth } from '@/pages/auth/context/AuthProvider';
import { useDeveloperContext } from '@/pages/developer/context/DevelopersContext';
import { useChannels } from '@/pages/settings/organization/channels/context/ChannelContext';
import { FormFieldWrapper } from '@/shared/components/forms';
import { FormSelect } from '@/shared/components/forms/Select';
import { useDisclosure } from '@/shared/hooks';
import { IntegrationConfiguration } from '@/shared/types/integrations';
import {
  Box,
  Button,
  Dialog,
  DialogCloseIcon,
  DialogContent,
  DialogFooter,
  DialogOverlay,
  DialogPortal,
  DialogTitle,
  DialogTrigger,
  HStack,
} from '@/shared/ui';
import { SYNC_BASE_URL } from '@/shared/utils/config';

import { useIntegrations } from '../context/IntegrationsContext';

type ConfigurationEditorProps = {
  configuration?: IntegrationConfiguration;
  applicationId: string;
  children: React.ReactNode;
};

export const HubspotConfigurationEditor = (props: ConfigurationEditorProps) => {
  const { children } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { integrationsState } = useIntegrations();
  const { current: currentIntegration } = integrationsState;
  const { organizationId } = useAuth();
  const { channelsState } = useChannels();
  const { channels } = channelsState;
  const { developerState, getApiKeys } = useDeveloperContext();
  const { apiKeys } = developerState;

  const [step, setStep] = useState(1);
  // const history = useHistory();

  useEffect(() => getApiKeys(), []);

  if (currentIntegration == null) {
    return <div>Failed to load integration</div>;
  }

  // mapping apiKeys into the data structure that is needed for combobox
  const apiKeyOptions =
    apiKeys && apiKeys.length > 0
      ? apiKeys.map((apiKey) => ({
          // display the name
          label: `${apiKey?.name || 'No API Name'}`,
          // capture the key as the value
          value: apiKey?.key as string,
        }))
      : [];

  // mapping channelIds into the data structure that is needed for combobox
  const channelOptions =
    channels && channels.length > 0
      ? channels.map((channel) => ({
          // display the name
          label: channel.name as string,
          // capture the key as the value
          value: channel.id as string,
        }))
      : [];

  type FormValues = {
    api_key: string;
    channel_id: string;
  };

  // This is a superset of AddToSequenceParams
  // SendMessageActionParams and CreateLeadActionParams
  const formInitialValue: FormValues = {
    api_key: '',
    channel_id: '',
  };

  const determineValidation = () => {
    return Yup.object({
      api_key: Yup.string().required('Required'),
      channel_id: Yup.string().required('Required'),
    });
  };

  // close the dialog and reset the step
  const onDialogClose = () => {
    onClose();
  };

  return (
    <Dialog open={isOpen} modal={true}>
      <DialogTrigger onClick={onOpen}>{children}</DialogTrigger>
      <DialogPortal>
        <DialogOverlay as="div" />
        <DialogContent
          onEscapeKeyDown={onDialogClose}
          onPointerDownOutside={onDialogClose}
        >
          <DialogTitle variant="bold">Connect to Hubspot</DialogTitle>
          <Box>
            <Formik
              initialValues={formInitialValue}
              validationSchema={determineValidation()}
              onSubmit={async (values: FormValues) => {
                onClose();
                setStep(0);
                const payload = btoa(
                  JSON.stringify({ organization_id: organizationId, ...values })
                );
                location.href = `${SYNC_BASE_URL}//v1/hubspot/auth/connect/${payload}`;
              }}
            >
              {(formik) => (
                <form onSubmit={formik.handleSubmit}>
                  {step === 1 && (
                    <Box>
                      <FormFieldWrapper
                        name="api_key"
                        tooltip="The API key of Whippy to connect with hubspot"
                        label="API Key"
                      >
                        <FormSelect placeholder="API Key" options={apiKeyOptions} />
                      </FormFieldWrapper>
                    </Box>
                  )}
                  {step === 2 && (
                    <Box>
                      <FormFieldWrapper
                        name="channel_id"
                        tooltip="The Channel to send SMS from hubspot through"
                        label="Whippy SMS Channel"
                      >
                        <FormSelect
                          placeholder="Whippy SMS Channel"
                          options={channelOptions}
                        />
                      </FormFieldWrapper>
                    </Box>
                  )}

                  <DialogFooter justify="end">
                    <HStack>
                      <Button variant="gray" onClick={onClose} type="button">
                        Cancel
                      </Button>
                      {step === 1 && (
                        <Button
                          onClick={() => {
                            setStep(step + 1);
                          }}
                          type="button"
                          disabled={formik.values.api_key === ''}
                        >
                          Next Step
                        </Button>
                      )}
                      {step === 2 && (
                        <>
                          <Button variant="gray" onClick={() => setStep(1)} type="button">
                            Back
                          </Button>
                          <Button type="submit">Submit</Button>
                        </>
                      )}
                    </HStack>
                  </DialogFooter>
                </form>
              )}
            </Formik>
          </Box>
          <DialogCloseIcon onClick={() => onClose()} size="2">
            <HiX size="15px" />
          </DialogCloseIcon>
        </DialogContent>
      </DialogPortal>
    </Dialog>
  );
};
