import React from 'react';

import { VStack } from '@/shared/ui';

import TagsTable from './TagsTable';

const TagsView = () => {
  return (
    <VStack>
      <TagsTable />
    </VStack>
  );
};

export default TagsView;
