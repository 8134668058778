import React from 'react';

import { styled } from '@/stitches.config';

import { Box, Flex, Text, VStack } from '.';

type EmptyStateProps = {
  title?: string;
  description?: string | React.ReactNode;
  buttonComponent?: JSX.Element | React.ReactNode;
  icon?: React.ReactNode;
};

export const EmptyStateContainer = styled(Flex, {
  zIndex: '1000',
  px: '44px',
  py: '44px',
  alignItems: 'left',
  borderRadius: '4px',
  justifyContent: 'space-evenly',
  width: '360px',
  backgroundColor: '$panel',

  '@md': {
    width: '480px',
    boxShadow: 'rgb(0 0 0 / 7%) 0px 4px 44px',
  },
});

export const EmptyState = (props: EmptyStateProps) => {
  return (
    <EmptyStateContainer direction="column" justify="between">
      <VStack gap={2}>
        <Box css={{ width: '30px', fontSize: 13, mb: 5 }}>{props.icon}</Box>
        <Text css={{ fontWeight: '600', fontSize: '20px' }}>{props.title}</Text>
        <Text>{props.description}</Text>
        <Box css={{ width: '100%' }}>{props.buttonComponent}</Box>
      </VStack>
    </EmptyStateContainer>
  );
};
