/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useState } from 'react';
import { HiChat, HiPencilAlt, HiX } from 'react-icons/hi';

import { ToolTipIconButton } from '@/shared/components/attachments/previewer';
import { ConfirmationDialog } from '@/shared/components/ConfirmationDialog';
import { TooltipIconButton } from '@/shared/components/TooltipIconButton';
import { AddTrigger } from '@/shared/components/triggers/AddTrigger';
import {
  Sequence,
  SequenceAccessLevel,
  SequenceStep,
  SequenceStepScheduleOptions,
  SequenceStepTrigger,
} from '@/shared/types/sequences';
import { Trigger } from '@/shared/types/triggers';
import { Box, Flex, HStack, Skeleton, VStack } from '@/shared/ui';
import { styled } from '@/stitches.config';

import * as SequenceAPI from '../../../../shared/api/sequences';
import { useSequences } from '../../context/SequenceContext';
import { StepTriggers, StepTriggersPreview } from './StepTriggers';
import { UpdateStep } from './UpdateStep';

type StepCardProps = {
  /* sequence uuid */
  sequence_id: string;
  /* sequence object */
  sequence?: Sequence | null;
  /* sequence step object */
  step: SequenceStep | null;
  /* is under Global Templates Preview */
  disableEdit?: boolean;
};

export const StepCard = (props: StepCardProps) => {
  const { step, sequence_id, disableEdit } = props;
  const sequences = useSequences();
  const { sequencesState, deleteSequenceStep } = sequences;
  const { current } = sequencesState;

  // is the step scheduled?
  const isScheduled =
    step?.schedule_options.days !== '0' ||
    step?.schedule_options.hours !== '0' ||
    step?.schedule_options.minutes !== '0';

  // add 1 to the position of the step
  const position = step ? step.position! + 1 : 1;

  // sequence step triggers and actions
  const [triggers, setTriggers] = useState<Array<SequenceStepTrigger>>([]);

  // create a sequence step trigger
  const createTrigger = async (trigger: Trigger) => {
    if (step && step?.id) {
      try {
        const res = await SequenceAPI.createSequenceStepTrigger(
          sequence_id,
          step?.id,
          trigger
        );
        if (res) {
          setTriggers([...triggers, res]);
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  // is the sequence access_level global?
  // if so, show the triggers preview, so the user can see what the triggers will look like
  // but not be able to edit them
  const isGlobal = props.sequence?.access_level === SequenceAccessLevel.GLOBAL;

  const handleDelete = () => {
    if (current && current.id) {
      deleteSequenceStep(current.id, step?.id ?? '');
    }
  };

  return (
    <Flex css={{ width: '100%' }}>
      <Flex direction="column" css={{ width: '100%' }}>
        <VStack gap="2">
          <Flex css={{ fontWeight: 500, fontSize: 16, mb: 10 }}>
            <Box css={{ mr: 5 }}>
              Step {position} {step?.title ? `(${step.title})` : null} - Send{' '}
              {isScheduled && 'After'}
            </Box>
            <Flex align="center" css={{ color: '#7F7F86' }}>
              <Box>
                {step && isScheduled
                  ? formattedScheduleTime(step.schedule_options)
                  : 'Immediately'}
              </Box>
            </Flex>
          </Flex>
          <StepBoxContainer align="center" justify="between">
            <Flex gap={2}>
              <StepBody>{step && step.body}</StepBody>
            </Flex>
            {!disableEdit && (
              <HStack gap="2" align="center">
                <ConfirmationDialog
                  title="Delete Sequence Step"
                  description="You can only delete steps when there are no active contacts in the sequence. Are you sure you want to delete this sequence step?"
                  onConfirm={handleDelete}
                  confirmButtonTitle="Yes, Delete Sequence Step"
                  testID="delete-sequence-step-button"
                >
                  <TooltipIconButton
                    size={2}
                    text="Delete Step"
                    icon={<HiX size="22px" style={{ color: '#7F7F86' }} />}
                  />
                </ConfirmationDialog>
                <UpdateStep step={step}>
                  <ToolTipIconButton
                    description="Edit Step"
                    size={2}
                    icon={<HiPencilAlt size="22px" style={{ color: '#7F7F86' }} />}
                  />
                </UpdateStep>
              </HStack>
            )}
          </StepBoxContainer>
          {!disableEdit && (
            <Box>
              <AddTrigger
                addTrigger={createTrigger}
                disabled={false}
                variant="gray"
                sequenceType={current?.type}
                isSequence
              />
            </Box>
          )}
          <Box>
            {sequence_id && step && step.id && !isGlobal && (
              <StepTriggers
                sequence_id={sequence_id}
                step_id={step.id}
                triggers={triggers || []}
                setTriggers={setTriggers}
              />
            )}
            {isGlobal && sequence_id && step && step.id && (
              <StepTriggersPreview
                sequence_id={sequence_id}
                step_id={step.id}
                triggers={triggers || []}
                setTriggers={setTriggers}
              />
            )}
          </Box>
        </VStack>
      </Flex>
    </Flex>
  );
};

export const StepLoadingCard = () => {
  return (
    <Flex css={{ width: '100%' }}>
      <Flex direction="column" css={{ width: '100%' }}>
        <Flex css={{ fontWeight: 500, fontSize: 16, mb: 10 }}>
          <Skeleton css={{ width: 100, height: 20, mt: 0, mb: 0 }} />
        </Flex>
        <StepBoxContainer align="center" justify="between">
          <Flex gap={2}>
            <Skeleton css={{ width: 200, height: 20, mt: 0, mb: 0 }} />
          </Flex>
          <HStack gap="2" align="center">
            <Skeleton
              css={{
                width: 30,
                height: 30,
                mt: 0,
                mb: 0,
              }}
            />
            <Skeleton
              css={{
                width: 30,
                height: 30,
                mt: 0,
                mb: 0,
              }}
            />
          </HStack>
        </StepBoxContainer>
      </Flex>
    </Flex>
  );
};

export const SequenceChatIcon = () => {
  return (
    <ChatIconContainer align="center" justify="center" css={{ mr: 10 }}>
      <HiChat style={{ color: '#FFFFFF' }} />
      <VerticalLine />
    </ChatIconContainer>
  );
};

// show the time offset from the previous step
const formattedScheduleTime = (schedule: SequenceStepScheduleOptions) => {
  // Get the days, hours, and minutes from the step object
  const days = schedule.days;
  const hours = schedule.hours;
  const minutes = schedule.minutes;

  // Format the time to show the days, hours, and minutes
  const daysString = days === '0' || days === '' ? '' : `${days} day`;
  const hoursString = hours === '0' || hours === '' ? '' : `${hours} hour`;
  const minutesString = minutes === '0' || minutes === '' ? '' : `${minutes}m`;

  const mergedStrings = [daysString, hoursString, minutesString].join(' ');

  return mergedStrings;
};

const StepBoxContainer = styled(Flex, {
  backgroundColor: '#FFFFFF',
  border: '1px solid #DFE3EB',
  borderRadius: 8,
  boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.06)',
  padding: '20px 24px',
});

const StepBody = styled(Box, {
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  maxWidth: '500px',
});

const ChatIconContainer = styled(Flex, {
  backgroundColor: '#6A74CF',
  borderRadius: '50%',
  width: 28,
  height: 28,
});

const VerticalLine = styled(Box, {
  position: 'absolute',
  borderLeft: '1px solid #DFE3EB',
  height: 90,
  mt: 130,
});
