import React from 'react';
import { Helmet } from 'react-helmet-async';

import { usePageView } from '@/shared/hooks';
import { SettingsLayout } from '@/shared/layouts';
import { Button } from '@/shared/ui';

import { AddTag } from './AddTag';
import TagsView from './TagsView';

export function Tags() {
  usePageView();

  return (
    <>
      <Helmet>
        <title>Whippy | Tags</title>
      </Helmet>
      <SettingsLayout
        background="#fafafa"
        breadcrumbs={[
          { title: 'Settings', path: '/settings/tags' },
          { title: 'Tags', path: `/settings/tags` },
        ]}
        actions={
          <AddTag>
            <Button onClick={(e) => e.preventDefault()}>Add Tag</Button>
          </AddTag>
        }
        width="100%"
      >
        <TagsView />
      </SettingsLayout>
    </>
  );
}
