/* eslint-disable prettier/prettier */
/* eslint-disable react-hooks/exhaustive-deps */
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import React, { useEffect, useState } from 'react';
import {
  HiArrowDown,
  HiCheckCircle,
  HiOutlineCheckCircle,
  HiOutlineExclamationCircle,
} from 'react-icons/hi';
import { Virtuoso, VirtuosoHandle } from 'react-virtuoso';

import { useAuth } from '@/auth/context/AuthProvider';
import { useChannels } from '@/pages/settings/organization/channels/context/ChannelContext';
import { ConversationCall } from '@/pages/voip/ConversationCall';
import { Channel, ChannelTypes } from '@/shared/types/channels';
import {
  ConversationItemSourceTypes,
  ConversationItemType,
  ConversationItemTypesType,
  ConversationMessageType,
  ConversationReadByUserType,
  ConversationType,
  ConversationWithStateType,
  EventType,
} from '@/shared/types/conversations';
import { User } from '@/shared/types/users';
import {
  Avatar,
  Box,
  Flex,
  IconButton,
  Tooltip,
  TooltipArrow,
  TooltipContent,
  TooltipTrigger,
} from '@/shared/ui';
import { initials } from '@/shared/utils/initials/initials';
import {
  DAY_OF_WEEK_STAMP,
  DAY_STAMP,
  MONTH_DAY_YEAR,
  TIME_STAMP,
} from '@/shared/utils/timestamps';
import i18next from '@/shared/utils/translation';
import { formatPhoneNumber } from '@/shared/utils/validations/validations';
import { styled } from '@/stitches.config';

import { ConversationEvent } from './ConversationEvent';
import { ConversationNote } from './ConversationNote';
import { ConversationEmail } from './email/ConversationEmail';
import { MessageToolbar } from './MessageToolbar';
import { InboundSMS } from './sms/InboundSMS';
import { OutboundSMS } from './sms/OutboundSMS';

type ConversationItemsProps = {
  /** the currently selected conversation  */
  current: ConversationWithStateType | null;
  /** function to get more of messages in the conversation */
  getConversationMessages: (
    id: string,
    offset: number,
    limit: number,
    loadBefore?: boolean
  ) => ConversationMessageType[] | void;
  /** react ref for the virtualised list of messages */
  virtuoso: React.MutableRefObject<VirtuosoHandle | undefined | null>;
};

const PAGE_SIZE = 50;

// This function is where we will iterate through a list of messages
// in the conversation and render them based on their direction (OUTBOUND, or INBOUND)
export function ConversationItems(props: ConversationItemsProps): JSX.Element {
  const { tokens } = useAuth();
  const channel = useChannels();
  const { channels } = channel.channelsState;
  const currentUserId = tokens?.user_id;

  const { current, getConversationMessages, virtuoso } = props;

  const [showScrollToBottom, setShowScrollToBottom] = useState(true);
  const [loading, setLoading] = useState(false);
  const [virtuosoInitiated, setVirtuosoInitiated] = useState(false);
  const [newMessagesCount, setNewMessagesCount] = useState(0);
  const [highlightedItemId, setHighlightedItemId] = useState<string | null>(null);

  useEffect(() => {
    if (current && virtuoso && virtuoso.current) {
      // scroll to searched message
      if (current?.searchMessageId) {
        setHighlightedItemId(current.searchMessageId);
        const index = current.conversationItemsPage.conversationItems.findIndex(
          (c) => c.id === current.searchMessageId
        );
        setVirtuosoInitiated(true);
        virtuoso.current.scrollToIndex({
          index: index,
        });
        setTimeout(() => {
          setHighlightedItemId(null);
        }, 2000);
      } else {
        // scroll to bottom on new conversation
        setNewMessagesCount(0);
        const conversationItemsLength =
          current?.conversationItemsPage?.conversationItems.length;

        virtuoso.current.scrollToIndex({
          index: conversationItemsLength + 1,
        });
      }
    }
  }, [current?.id, current?.isInitialConversation, current?.searchMessageId]);

  useEffect(() => {
    if (current && current?.newMessagesCount) {
      const lastMessage =
        current.conversationItemsPage?.conversationItems[
          current?.conversationItemsPage?.conversationItems.length - 1
        ];

      if (
        ('user_id' in lastMessage && lastMessage.user_id === currentUserId) ||
        !showScrollToBottom
      ) {
        // scroll to bottom on new message if new message from current user or scroll position was at bottom
        setTimeout(() => {
          // Set scroll to bottom
          // Using setTimeout to ensure scroll position will update after render new items.
          // Without this delay, in some cases scroll will not work correctly
          scrollToBottom();
        }, 0);
      } else {
        // add scroll to bottom button with messages count
        setNewMessagesCount(newMessagesCount + current.newMessagesCount);
      }
    }
  }, [
    current?.newMessagesCount,
    current?.conversationItemsPage?.conversationItems.length,
  ]);

  const scrollToBottom = async () => {
    if (current && virtuoso && virtuoso.current) {
      // load previous messages and scroll to bottom if bottomOffset > 0
      if (current.bottomOffset && current.bottomOffset > 0) {
        const data = await getMessages(current, 0, current.bottomOffset, true);
        setNewMessagesCount(0);
        virtuoso.current.scrollToIndex({
          index: data.length + current.conversationItemsPage.conversationItems.length + 1,
        });
      } else {
        // scroll to bottom
        setNewMessagesCount(0);
        virtuoso.current.scrollToIndex({
          index: current?.conversationItemsPage.conversationItems.length + 1,
        });
      }
    }
  };

  const calculateOffset = (
    current: ConversationWithStateType,
    loadBefore?: boolean
  ): number => {
    if (loadBefore) {
      const bottomOffset = (current.bottomOffset ?? 0) - PAGE_SIZE;
      return bottomOffset > 0 ? bottomOffset : 0;
    } else {
      return current.topOffset ?? 0;
    }
  };

  const handleLoadMore = async (loadBefore?: boolean) => {
    // load more messages if current conversation selected amd messages list not empty
    if (current && current.id && current.conversationItemsPage.conversationItems.length) {
      setLoading(true);
      // calculate offset for load more messages
      const offset = calculateOffset(current, loadBefore);
      // load more messages
      const data = await getMessages(
        current,
        offset,
        loadBefore && offset === 0 ? (current.bottomOffset ?? 0) : PAGE_SIZE,
        loadBefore
      );
      setLoading(false);

      // stay at the last message index if more messages are loaded and not displaying search result
      if (virtuoso && virtuoso.current && data && data.length && !highlightedItemId) {
        virtuoso.current.scrollToIndex({
          index: loadBefore
            ? current.conversationItemsPage.conversationItems.length - 1
            : data.length - 1,
        });
      }
    }
  };

  const getMessages = async (
    current: ConversationWithStateType,
    offset: number,
    limit: number,
    loadBefore?: boolean
  ) => {
    const data = (await getConversationMessages(
      current?.id || '',
      offset,
      limit,
      loadBefore
    )) as ConversationMessageType[];
    return data;
  };

  // filter conversation items by inserted_at date
  const sortedConversationItems = current?.conversationItemsPage?.conversationItems?.sort(
    (a, b) => new Date(a.inserted_at).getTime() - new Date(b.inserted_at).getTime()
  );

  const [hoverItemId, setHoverItemId] = useState<string | null>(null);

  const mouseEventHandler = (id: string | null) => {
    setHoverItemId(id);
  };

  return (
    <StyledMessagesWrapper data-testid="conversation-items">
      {current && !!sortedConversationItems?.length && (
        <Virtuoso
          atBottomStateChange={(atBottom) => {
            setNewMessagesCount(0);
            setShowScrollToBottom(!atBottom);
            atBottom && current.bottomOffset && !loading && handleLoadMore(true);
          }}
          endReached={() => setVirtuosoInitiated(true)}
          defaultItemHeight={80}
          atTopStateChange={(atTop) => {
            atTop &&
              current.topOffset &&
              current.topOffset > 9 &&
              current?.conversationItemsPage?.conversationItems.length >
                current.topOffset - PAGE_SIZE &&
              !loading &&
              virtuosoInitiated &&
              !current.isInitialConversation &&
              handleLoadMore();
          }}
          ref={virtuoso as React.MutableRefObject<VirtuosoHandle>}
          data={sortedConversationItems}
          totalCount={sortedConversationItems?.length}
          components={{
            Item,
          }}
          itemContent={(index: number, item: ConversationItemType) => (
            <ConversationItem
              highlightedItemId={highlightedItemId}
              hoverItemId={hoverItemId}
              mouseEventHandler={mouseEventHandler}
              key={index}
              item={item}
              current={current}
              channel={
                channels.find((channel) => channel.id === current.location_id) as Channel
              }
              index={index}
            />
          )}
        />
      )}
      {showScrollToBottom && !!newMessagesCount && (
        <ScrollButtonContainer>
          <IconButton
            data-testid="new-messages-button"
            onClick={scrollToBottom}
            size={2}
            variant="outline"
            css={{ borderRadius: '50%' }}
          >
            <HiArrowDown />
          </IconButton>
          <NewMessagesMarker data-testid="new-messages-marker">
            {newMessagesCount}
          </NewMessagesMarker>
        </ScrollButtonContainer>
      )}
    </StyledMessagesWrapper>
  );
}

const ScrollButtonContainer = styled('div', {
  position: 'absolute',
  zIndex: 10,
  bottom: 0,
  left: 'calc(50% - 17px)',
});

const NewMessagesMarker = styled('div', {
  position: 'absolute',
  top: '-7px',
  right: 0,
  background: 'red',
  width: 15,
  height: 15,
  borderRadius: '50%',
  fontSize: 12,
  color: '#fff',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const Item = styled(Box, {
  '&:last-child': {
    pb: 30,
  },
  '&:first-child': {
    pt: 20,
  },
});

type ConversationItemProps = {
  /** the currently selected conversation  */
  current: ConversationType | null;
  /** the conversation item to be rendered */
  item: ConversationItemType;
  /** the index of the message in the conversation */
  index: number;
  hoverItemId: string | null;
  highlightedItemId: string | null;
  mouseEventHandler: (id: string | null) => void;
  channel: Channel;
};

const ConversationItem = (props: ConversationItemProps) => {
  const auth = useAuth();
  const {
    item,
    current,
    index,
    hoverItemId,
    highlightedItemId,
    mouseEventHandler,
    channel,
  } = props;

  const renderUser = (message: ConversationItemType) => {
    const readBy = current && current.readByUsers ? current.readByUsers : [];

    // Define the type for usersById
    const usersById: { [key: string]: ConversationReadByUserType } = readBy.reduce(
      (acc, id) => {
        return { ...acc, [id.user.id]: id };
      },
      {}
    );

    // Check if message is of type ConversationMessageType before accessing user_id
    if ('user_id' in message && message.user_id) {
      return (
        usersById[message.user_id]?.user?.name ||
        usersById[message.user_id]?.user?.email ||
        ''
      );
    }
  };

  const renderUserRead = (
    message: ConversationItemType,
    nextMessage: ConversationItemType | undefined
  ) => {
    const userReadMessage = current?.readByUsers
      ? current.readByUsers.map(
          (read: ConversationReadByUserType) =>
            message?.inserted_at.slice(0, -8) <= read.most_recent_read &&
            (nextMessage?.inserted_at === undefined ||
              read.most_recent_read < nextMessage?.inserted_at?.slice(0, -8))
        )
      : [];

    const renderUserReadIcon = userReadMessage?.map((x: boolean, index: number) => {
      return x === true &&
        current?.readByUsers[index].user.email !== auth?.tokens?.email ? (
        <Tooltip key={current?.readByUsers[index].user.id}>
          <TooltipTrigger>
            <Flex
              justify="start"
              css={{
                pb: 8,
                pr: 2,
              }}
            >
              <Avatar
                variant="pink"
                size="assign"
                css={{
                  ':hover': {
                    backgroundColor: '#e57bb3',
                  },
                }}
                src={current?.readByUsers[index].user.attachment?.url}
                fallback={initials(
                  current?.readByUsers[index].user.name ||
                    current?.readByUsers[index].user.email
                )}
              />
            </Flex>
          </TooltipTrigger>
          <TooltipContent>
            {`Read by ${
              current?.readByUsers[index].user.name ||
              current?.readByUsers[index].user.email
            }`}
            <TooltipArrow offset={3} />
          </TooltipContent>
        </Tooltip>
      ) : null;
    });

    return renderUserReadIcon;
  };

  const formatDate = (date: string) => {
    if (!date) {
      return null;
    }
    const stamp =
      dayjs(date).year() === new Date().getFullYear()
        ? DAY_OF_WEEK_STAMP
        : MONTH_DAY_YEAR;
    const argDate = `${dayjs(date).format(stamp)}`;

    return argDate;
  };

  const checkIfDateHasToBeRendered = (
    previousMessage: ConversationItemType,
    message: ConversationItemType
  ) => {
    const previousFormattedDate = formatDate(previousMessage?.inserted_at || '');
    const currentFormattedDate = formatDate(message?.inserted_at || '');

    if (!previousFormattedDate) {
      return currentFormattedDate;
    }

    if (previousFormattedDate !== currentFormattedDate) {
      return currentFormattedDate;
    }
  };

  const conversationItemContainerPops = {
    item,
    date: current
      ? checkIfDateHasToBeRendered(
          current?.conversationItemsPage.conversationItems[index - 1] || {},
          item
        )
      : '',
    hoverItemId,
    highlightedItemId,
    mouseEventHandler,
  };

  // take the event.metadata.contact_name and replace it with the contact name
  // from the current conversation.contact.name, this is so that the most up to date contact name is
  // displayed in the event, since we save the contact name in the event metadata when the event is created
  const mergeCurrentAndNewEvent = (event: EventType) => {
    if (event.type !== 'link_clicked') {
      return {
        ...event,
        metadata: {
          ...event?.metadata,
          contact_name: current?.contact?.name || event?.metadata?.contact_name || '',
        },
      };
    } else {
      return event;
    }
  };

  if ('event' in item) {
    return (
      <ConversationItemContainer key={item.event.id} {...conversationItemContainerPops}>
        <ConversationEvent
          event={mergeCurrentAndNewEvent(item.event)}
          date={item.updated_at || item.inserted_at || ''}
          index={index}
        />
      </ConversationItemContainer>
    );
  } else if ('type' in item && item.type === 'call') {
    return (
      <ConversationItemContainer key={item.id} {...conversationItemContainerPops}>
        <ConversationCall
          message={item}
          date={item.updated_at || item.inserted_at || ''}
          contact_name={
            current?.contact.name ||
            formatPhoneNumber(current?.contact?.phone || '') ||
            ''
          }
          user_email={renderUser(item) || ''}
        />
        <Flex
          css={{ mt: 4 }}
          justify={
            item?.source_type === ConversationItemSourceTypes.INBOUND ? 'start' : 'end'
          }
        >
          {renderUserRead(
            item,
            current?.conversationItemsPage.conversationItems[index + 1]
          )}
        </Flex>
      </ConversationItemContainer>
    );
  } else if ('body' in item && item?.source_type === ConversationItemSourceTypes.NOTE) {
    return (
      <ConversationItemContainer key={item.id} {...conversationItemContainerPops}>
        <ConversationNote
          message={item}
          date={item.updated_at || item.inserted_at || ''}
          user_email={renderUser(item)}
        />
        <Flex justify="end">
          {renderUserRead(
            item,
            current?.conversationItemsPage.conversationItems[index + 1]
          )}
        </Flex>
      </ConversationItemContainer>
    );
  } else if (
    'body' in item &&
    item?.source_type === ConversationItemSourceTypes.OUTBOUND &&
    (current?.channel_type === ChannelTypes.PHONE ||
      current?.channel_type === ChannelTypes.WHATSAPP ||
      current?.channel_type === null)
  ) {
    return (
      <ConversationItemContainer key={item.id} {...conversationItemContainerPops}>
        <OutboundSMS
          message={item}
          date={item.inserted_at || item.updated_at || ''}
          user_email={renderUser(item)}
        />
        <Flex justify="end">
          {renderUserRead(
            item,
            current?.conversationItemsPage.conversationItems[index + 1]
          )}
        </Flex>
      </ConversationItemContainer>
    );
  } else if (
    'body' in item &&
    item?.source_type === ConversationItemSourceTypes.INBOUND &&
    (current?.channel_type === ChannelTypes.PHONE ||
      current?.channel_type === ChannelTypes.WHATSAPP ||
      current?.channel_type === null)
  ) {
    return (
      <ConversationItemContainer key={item.id} {...conversationItemContainerPops}>
        <InboundSMS
          message={item}
          date={item.updated_at || item.inserted_at || ''}
          contact_name={
            current?.contact.name ||
            formatPhoneNumber(current?.contact?.phone || '') ||
            ''
          }
        />
        <Flex justify="start">
          {renderUserRead(
            item,
            current?.conversationItemsPage.conversationItems[index + 1]
          )}
        </Flex>
      </ConversationItemContainer>
    );
  } else if ('body' in item && current?.channel_type === ChannelTypes.EMAIL) {
    return (
      <ConversationItemContainer key={item.id} {...conversationItemContainerPops}>
        <ConversationEmail
          message={item}
          date={item.updated_at || item.inserted_at || ''}
          user_email={renderUser(item)}
          contact={current?.contact}
          source_type={item?.source_type}
          channel={channel}
        />
        <Flex justify="end">
          {renderUserRead(
            item,
            current?.conversationItemsPage.conversationItems[index + 1]
          )}
        </Flex>
      </ConversationItemContainer>
    );
  }
};

dayjs.extend(advancedFormat);

const getTodaysDate = () => {
  const todaysDate = dayjs().format(DAY_STAMP);
  return todaysDate;
};

// Outer div container for each message responsible for positioning
// This will eventually contain read receipts, dates etc not just the message
type ConversationItemContainerProps = {
  /* Date to be displayed */
  date: string | undefined | null;
  /* Message object to be displayed */
  item: ConversationItemType;
  /* Children to be rendered */
  children: React.ReactNode;
  hoverItemId: string | null;
  highlightedItemId: string | null;
  mouseEventHandler: (id: string | null) => void;
};

const ConversationItemContainer = (props: ConversationItemContainerProps) => {
  const { date, children, item, hoverItemId, highlightedItemId, mouseEventHandler } =
    props;

  return (
    <>
      {date && (
        <StyledDateContainer
          css={{
            px: 30,
            alignItems: 'center',
            alignContent: 'center',
            justifyContent: 'center',
          }}
        >
          <Box css={{ width: '100%', height: 1, backgroundColor: '#EDEDED' }} />
          <Box css={{ whiteSpace: 'nowrap', px: 12 }}>
            {date === getTodaysDate() ? 'Today' : `${date}`}
          </Box>
          <Box css={{ width: '100%', height: 1, backgroundColor: '#EDEDED' }} />
        </StyledDateContainer>
      )}
      <MessageToolbar
        hoverItemId={hoverItemId}
        mouseEventHandler={mouseEventHandler}
        message={item as ConversationMessageType}
      >
        <Box
          css={{
            width: '100% !important',
            minWidth: '100% !important',
            '@md': {
              ':hover': {
                backgroundColor:
                  'source_type' in item &&
                  item.type !== ConversationItemTypesType.CALL &&
                  item.type !== ConversationItemTypesType.EMAIL
                    ? '#F9F9FB'
                    : '',
              },
            },
          }}
        >
          <StyledMessageContainer
            highlighted={item.id === highlightedItemId}
            direction={item && 'source_type' in item ? item.source_type : undefined}
          >
            {children}
          </StyledMessageContainer>
        </Box>
      </MessageToolbar>
    </>
  );
};

export type MessageStatusProps = {
  /* the status of the message e.g. failed/delivered? */
  status?: string | null;
  /* the error message if the message failed e.g. tmobile_10dlc_sending_limit */
  error?: string | null;
  /* the date the message was sent */
  date?: string | undefined | null;
  /* the email of the user who sent the message */
  user?: string;
  /* the type of message e.g. imported */
  message_type?: string;
};

export const MessageError = (props: MessageStatusProps) => {
  const { status, error, date, user, message_type } = props;

  return (
    <StyledErrorContainer align="end">
      <Box css={{ pr: 5 }}>
        {!user && message_type === 'imported'
          ? 'Imported'
          : user || 'Automated by Whippy'}{' '}
        {dayjs(date).format(TIME_STAMP)}
        <Box css={{ pr: 5 }} />
      </Box>
      <StatusIcon status={status} error={error} />
    </StyledErrorContainer>
  );
};

export const MessageSuccess = (props: MessageStatusProps) => {
  const { status, error, date, user, message_type } = props;

  return (
    <Flex align="center">
      <Box css={{ mr: 5, fontWeight: 500 }}>
        {!user && message_type === 'imported'
          ? 'Imported'
          : user || 'Automated by Whippy'}
      </Box>
      <Box>{dayjs(date).format(TIME_STAMP)}</Box>
      <Box css={{ mr: 5 }}></Box>
      {/* Notes do not have a status, so we don't show the status icon */}
      {status && <StatusIcon status={status} error={error} />}
    </Flex>
  );
};

/**
 *
 * OUTBOUND MESSAGE STATUS
 *
 */

type StatusIconProps = {
  /* the status of the message e.g. failed/delivered? */
  status?: string | undefined | null;
  /* the error message if the message failed e.g. tmobile_10dlc_sending_limit */
  error?: string | undefined | null;
};

export const StatusIcon = (props: StatusIconProps) => {
  if (props.status === 'sent') return <HiOutlineCheckCircle size="14px" color="grey" />;

  if (props.error || props.status === 'failed' || props.status === 'sending_failed') {
    const errorMsg = i18next.t(props.error || '') || 'Message Failed to Send';
    return (
      <Flex align="center">
        <Box css={{ color: '$red10', pr: 5 }}>{errorMsg}</Box>
        <HiOutlineExclamationCircle size="14px" color="#dc3d43" />
      </Flex>
    );
  }

  if (props.status === 'failed')
    return <HiOutlineExclamationCircle size="14px" color="#C53030" />;

  return <HiCheckCircle size="14px" color="grey" />;
};

export const StyledMessage = styled(Box, {
  textAlign: 'left',
  lineHeight: 1.4,
  overflowWrap: 'break-word',
  wordBreak: 'break-word',
  fontSize: 14,
  fontWeight: 500,
  px: 24,
  py: 10,
  variants: {
    visibility: {
      outbound_hidden: {
        color: 'transparent',
        textShadow: '0 0 10px #fff',
      },
      inbound_hidden: {
        color: 'transparent',
        textShadow: '0 0 10px #000',
      },
      outbound_visible: {},
      inbound_visible: {},
      outbound_removed: {},
      inbound_removed: {},
    },
    direction: {
      inbound_sms: {
        borderRadius: 30,
        textAlign: 'left',
        borderBottomLeftRadius: '6px',
        backgroundColor: '#F2F2F5 !important',
        position: 'relative',
        display: 'inline-block',
        maxWidth: '80%',
        '@lg': {
          maxWidth: '54%',
        },
      },
      outbound_sms: {
        borderRadius: 30,
        textAlign: 'left',
        backgroundColor: '$primaryButtonColor !important',
        color: '#ffffff',
        borderBottomRightRadius: '6px',
        position: 'relative',
        display: 'inline-block',
        maxWidth: '80%',
        '@lg': {
          maxWidth: '54%',
        },
      },
      note: {
        borderRadius: 30,
        textAlign: 'left',
        backgroundColor: '#FFECB7 !important',
        borderBottomRightRadius: '6px',
        position: 'relative',
        display: 'inline-block',
        maxWidth: '80%',
        '@lg': {
          maxWidth: '54%',
        },
      },
      outbound_sms_translation: {
        borderRadius: 30,
        textAlign: 'left',
        backgroundColor: '$slate5 !important',
        borderTopRightRadius: '6px',
        maxWidth: '80%',
        '@lg': {
          maxWidth: '54%',
        },
      },
      inbound_sms_translation: {
        borderRadius: 30,
        textAlign: 'left',
        backgroundColor: '$slate5 !important',
        borderTopLeftRadius: '6px',
        maxWidth: '80%',
        '@lg': {
          maxWidth: '54%',
        },
      },
      inbound_email: {
        width: 'calc(100% - 75px)',
        borderRadius: 24,
        backgroundColor: 'white !important',
        border: '1px solid #0000301B',
        padding: 0,
      },
      outbound_email: {
        width: '100%',
        borderRadius: 24,
        backgroundColor: 'white !important',
        border: '1px solid #0000301B',
        padding: 0,
      },
      outbound_email_translation: {
        width: 'calc(100% - 39px)',
        borderRadius: 8,
        textAlign: 'left',
        backgroundColor: '$slate5 !important',
        borderTopRightRadius: '6px',
      },
      inbound_email_translation: {
        width: 'calc(100% - 39px)',
        borderRadius: 8,
        textAlign: 'left',
        backgroundColor: '$slate5 !important',
        borderTopLeftRadius: '6px',
      },
    },
  },
});

export const StyledDateContainer = styled(Flex, {
  flexDirection: 'column',
  fontSize: 12,
  textAlign: 'center',
  color: '$gray11',
  py: 15,
  fontWeight: 500,
});

export const StyledMessageContainer = styled(Box, {
  width: '100%',
  position: 'relative',
  fontSize: 12,
  pt: 8,
  pb: 12,
  px: 24,
  zIndex: 1,
  variants: {
    highlighted: {
      true: {
        border: '2px $primaryColor solid',
      },
    },
    direction: {
      INBOUND: {
        textAlign: 'left',
      },
      OUTBOUND: {
        textAlign: 'right',
      },
      NOTE: {
        textAlign: 'right',
      },
    },
  },
});

export const StyledMessageFooter = styled(Flex, {
  fontSize: '12px',
  color: '$slate11',
});

export const StyledAttachmentContainer = styled(Flex, {
  borderBottomLeftRadius: '3px',
  width: '100%',
});

export const StyledErrorContainer = styled(Flex, {
  flexDirection: 'column',
  '@xl': { flexDirection: 'row' },
});

export const StyledMessagesWrapper = styled(Box, {
  position: 'relative',
  height: '100%',
  flex: 1,
  overflow: 'auto',
});

export const StyledEventContainer = styled(Box, {
  width: '100%',
  position: 'relative',
  fontSize: 11,
  textAlign: 'center',
  paddingBottom: 16,
  color: '$gray11',
  fontWeight: 500,
});

export const StyledEvent = styled(Box, {
  borderRadius: 26,
  pt: 5,
  pb: 5,
  lineHeight: 1.5,
  maxWidth: '80%',
  '@lg': {
    maxWidth: '60%',
  },
  display: 'inline-block',
  overflowWrap: 'break-word',
  wordBreak: 'break-word',
});

// write a function that takes a message body (string) and an array of user object ([{id: 1, name: 'John Doe', email: 'john@doe'}]) and
// returns a replaces @{{user_id}} with the user name or email and make the user name or email have bold mark up
export const replaceUserIdsWithNames = (messageBody: string, users: User[]) => {
  const userIds = messageBody.match(/@{{\d+}}/g);
  const globalMentions = messageBody.match(/@(all|here)/g);
  if (!userIds && !globalMentions) return messageBody;
  let newMessageBody = messageBody;

  Array.isArray(userIds) &&
    userIds.forEach((userId) => {
      const user = users.find(
        (user) => user.id === parseInt(userId.replace(/@{{|}}/g, ''))
      );
      if (user) {
        newMessageBody = newMessageBody.replace(
          userId,
          // space is added so if another bold text is directly after this one, they will both highlight
          `**@${user?.name || user?.email}** `
        );
      }
    });

  Array.isArray(globalMentions) &&
    globalMentions.forEach((mention: string) => {
      newMessageBody = newMessageBody.replace(
        new RegExp(mention, 'g'),
        // space is added so if another bold text is directly after this one, they will both highlight
        `**${mention}** `
      );
    });

  return newMessageBody.trim();
};
