/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';

import { useContacts } from '@/contacts/context/ContactContext';
import { Flex } from '@/shared/ui';

import { CONTACTS } from '../AudienceSelection';
import { AudienceTag } from '.';

type ContactTagsProps = {
  /** array of contact ids from the audience */
  contact_ids: string[];
  /** function to remove the tag from the audience */
  onItemDelete: (type: string, id?: string) => void;
  /** if removing the tag is disabled */
  disabled?: boolean;
};

export const ContactTags = (props: ContactTagsProps) => {
  const contact_ids = props.contact_ids;
  const contacts = useContacts();
  const { contactState, getContact } = contacts;
  const { contacts: contactsList } = contactState;

  return (
    <Flex wrap="wrap">
      {contact_ids &&
        contact_ids?.map((contact_id) => {
          return (
            <AudienceTag
              key={contact_id}
              id={contact_id}
              getObject={getContact}
              list={contactsList}
              itemType={CONTACTS}
              onItemDelete={props.onItemDelete}
              disabled={props.disabled}
            />
          );
        })}
    </Flex>
  );
};
