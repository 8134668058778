/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';

import { Contact } from '@/shared/types';
import { Campaign } from '@/shared/types/campaigns';
import { Channel } from '@/shared/types/channels';
import { Group } from '@/shared/types/contacts/groups';
import { Tag } from '@/shared/types/tags';
import { Skeleton } from '@/shared/ui';

import { AudienceItem } from '../AudienceItem';

type AudienceTagsProps = {
  /** the current state for any tag type e.g. Groups */
  list: AudienceList;
  /** unique id for any audience tag object */
  id: string;
  /** API request function to get the tag object by id if its not in state */
  getObject: AudienceGetObject;
  /** function to remove the tag from the audience */
  onItemDelete: (type: string, id?: string) => void;
  /** campaign results type e.g. responded */
  itemType?: string;
  /** if removing the tag is disabled */
  disabled?: boolean;
};

export type AudienceGetObject = (
  id: string
) => Promise<Contact | Channel | Group | Tag | Campaign | null>;
export type AudienceList = Contact[] | Channel[] | Group[] | Tag[] | Campaign[];

export const AudienceTag = (props: AudienceTagsProps) => {
  const { list, getObject, onItemDelete, itemType } = props;

  const [loading, setLoading] = React.useState(true);
  const [object, setObject] = React.useState<
    Contact | Channel | Group | Tag | Campaign | null
  >(null);

  const getAudienceTagValues = async (id: string) => {
    try {
      const contact = await getObject(id);
      setObject(contact);
      setLoading(false);
    } catch (error) {
      setObject({ name: 'Failed', id, color: 'red' });
      setLoading(false);
    }
  };

  useEffect(() => {
    async function fetchData() {
      await getAudienceTagValues(props.id);
    }

    const audience_tag_data = list.find((c) => c.id === props.id);

    if (audience_tag_data) {
      setObject(audience_tag_data);
      setLoading(false);
    } else {
      fetchData();
    }
  }, [props.id]);

  const getCircleColor = () => {
    switch (itemType) {
      case 'groups':
        return 'blue';
      case 'tags':
        return 'green';
      case 'uploads':
        return 'red';
      default:
        return 'black';
    }
  };

  return (
    <>
      {loading ? (
        <Skeleton variant="tag" />
      ) : (
        <AudienceItem
          key={`${object?.id}`}
          item={object as { id: string; name?: string; phone?: string }}
          onItemDelete={() => onItemDelete(itemType || 'contacts', object?.id)}
          color={(object as Tag)?.color || getCircleColor()}
          disabled={props.disabled}
        />
      )}
    </>
  );
};
