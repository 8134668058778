import { api } from '@/shared/api/api';
import { GetSequencesParams, Sequence } from '@/shared/types/sequences';

export const getSequencesTemplates = (params?: GetSequencesParams) =>
  api.get(`/sequences/templates`, { params }).then((res) => {
    return res.data as { data: Array<Sequence> | Array<null>; total: number };
  });

export const getSequencesTemplate = (id: string): Promise<Sequence | null> =>
  api.get(`/sequences/templates/${id}`).then((res) => {
    return res.data.data as Sequence | null;
  });

export const createSequenceTemplate = (params: Sequence): Promise<Sequence> =>
  api.post(`/sequences/templates`, { sequence: params }).then((res) => {
    return res.data.data as Sequence;
  });

export const updateSequenceTemplate = (params: Sequence): Promise<Sequence> =>
  api.put(`/sequences/templates/${params.id}`, { sequence: params }).then((res) => {
    return res.data.data as Sequence;
  });

export const deleteSequenceTemplate = (id: string): Promise<Sequence> =>
  api.delete(`/sequences/templates/${id}`).then((res) => {
    return res.data.data as Sequence;
  });

export const duplicateSequenceTemplate = (id: string): Promise<Sequence> =>
  api.post(`/sequences/templates/${id}/duplicate`).then((res) => {
    return res.data.data as Sequence;
  });
