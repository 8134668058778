import React from 'react';

import { styled } from '@/stitches.config';

import { Box, Button, Flex, HStack, Skeleton } from '../../ui';
import { Spinner } from '../Icons';
import { Attachment } from '.';

export type AttachmentStackType = {
  /* list of attachments urls */
  attachments: Array<string>;
  /* handler to delete attachment */
  deleteHandler: (public_url: string) => void;
  /* loading state */
  loading?: boolean;
};

// list of attachments to preview before sending
export const AttachmentsStack = (props: AttachmentStackType) => {
  return (
    <HStack css={{ alignItems: 'baseline', ml: 16 }}>
      {props.loading && (
        <AspectRatioContainer>
          <Flex css={{ position: 'relative', width: 90 }}>
            {/* styling this btn with inline css, because for some reason if it is in styled component it breaks in preview/prod */}
            <Button
              css={{
                position: 'absolute',
                alignContent: 'center',
                borderRadius: '$round !important',
                pointerEvents: 'none',
                top: 0,
                padding: '0px !important',
                right: 0,
                width: 27,
                zIndex: 100000,
                height: 27,
                maxHeight: 27,
                '& svg': {
                  marginRight: '0px !important',
                },
              }}
            >
              <Spinner size={1} color="primary" />
            </Button>
            <Skeleton css={{ width: 90, height: 90, borderRadius: 11, m: 0 }} />
          </Flex>
        </AspectRatioContainer>
      )}
      {props.attachments.map((url: string, index: number) => {
        return (
          <AspectRatioContainer key={index}>
            <Box css={{ position: 'absolute', p: 0, width: 90 }}>
              <Attachment src={url} deleteHandler={props.deleteHandler} preview={true} />
            </Box>
          </AspectRatioContainer>
        );
      })}
    </HStack>
  );
};

// message editor attachment container
export const AspectRatioContainer = styled(Box, {
  width: 90,
  height: 90,
  maxHeight: 90,
  marginTop: 15,
  alignItems: 'end',
  borderWidth: 1,
  borderColor: '$gray5',
  borderRadius: 12,
  zIndex: 1,
});
