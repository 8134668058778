/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { HiOutlineMenu } from 'react-icons/hi';
import { useMedia } from 'react-use';

import { useDisclosure } from '@/shared/hooks';
import { styled } from '@/stitches.config';

import { SettingsNavigation } from '../components/navigation';
import {
  Box,
  Button,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  DrawerPortal,
  Flex,
} from '../ui';
import { BreadCrumb, PageLayout } from './PageLayout';

type SettingsLayoutProps = {
  width?: string;
  breadcrumbs?: BreadCrumb[];
  children: React.ReactNode;
  actions?: React.ReactNode;
  background?: string;
  padding?: string;
  footer?: React.ReactNode;
};

export const SettingsLayoutContainer = ({ children }: { children: React.ReactNode }) => {
  return (
    <Flex
      css={{
        width: '100%',
        height: '100%',
        minHeight: '100%',
        alignItems: 'stretch',
        position: 'fixed',
        '@lg': {
          height: 'calc(100vh - 104px)',
          minHeight: 'calc(100vh - 104px)',
        },
        '@media (min-width: 1083px)': {
          height: 'calc(100vh - 85px)',
          minHeight: 'calc(100vh - 85px)',
        },
        '@media (min-width: 1140px)': {
          height: 'calc(100vh - 80px)',
          minHeight: 'calc(100vh - 80px)',
        },
      }}
    >
      {children}
    </Flex>
  );
};

export function SettingsLayout({
  width,
  breadcrumbs,
  actions,
  background,
  padding,
  children,
  footer,
}: SettingsLayoutProps): JSX.Element {
  const isDesktop = useMedia('(min-width: 912px)');
  const { isOpen, onOpen, onClose } = useDisclosure(false);
  const isUsersPage = location.pathname.startsWith('/settings/users');
  const layoutWidth = width ?? (isDesktop ? '600px' : '90vw');
  return (
    <SettingsLayoutContainer>
      {isDesktop && <SettingsNavigation />}
      <SettingsContentContainer
        css={{ overflow: 'hidden' }}
        data-testid="settings-content-container"
        direction="column"
      >
        {!isDesktop && (
          <MobileSettingsContainer align="center">
            <Button onClick={onOpen} variant="icon">
              <HiOutlineMenu fontSize="1rem" />
            </Button>
            <Drawer open={isOpen}>
              <DrawerPortal>
                <DrawerOverlay />
                <DrawerContent
                  onEscapeKeyDown={onClose}
                  onPointerDownOutside={onClose}
                  css={{ width: 230 }}
                  side="left"
                >
                  <SettingsNavigation />
                </DrawerContent>
              </DrawerPortal>
            </Drawer>
          </MobileSettingsContainer>
        )}
        <SettingsContent
          css={{
            maxWidth: isDesktop ? 'auto' : '100vw',
            background: background ?? 'white',
            '@lg': {
              minHeight: `calc(100vh - ${isUsersPage ? '104px' : '80px'})`,
            },
            '@media (min-width: 1083px)': {
              minHeight: `calc(100vh - ${isUsersPage ? '85px' : '80px'})`,
            },
          }}
          direction="column"
        >
          <SettingsPageContainer>
            <PageLayout
              background={background ?? 'white'}
              breadcrumbs={breadcrumbs}
              actions={actions}
              footer={footer}
            >
              <Flex css={{ flexGrow: 1 }} justify="center">
                <Box
                  data-testid="settings-layout-content"
                  style={{
                    width: layoutWidth,
                  }}
                  css={{
                    maxHeight: `calc(100vh - ${footer ? '192px' : '128px'})`,
                    '@lg': {
                      maxHeight: `calc(100vh - ${footer ? '232px' : '168px'})`,
                    },
                    '@media (min-width: 1083px)': {
                      maxHeight: `calc(100vh - ${footer ? '213px' : '149px'})`,
                    },
                    '@media (min-width: 1140px)': {
                      maxHeight: `calc(100vh - ${footer ? '208px' : '144px'})`,
                    },
                  }}
                >
                  <Flex
                    direction="column"
                    css={{
                      minHeight: '100%',
                      padding: padding ?? (isDesktop ? '24px' : '40px 20px'),
                    }}
                  >
                    {children}
                  </Flex>
                </Box>
              </Flex>
            </PageLayout>
          </SettingsPageContainer>
        </SettingsContent>
      </SettingsContentContainer>
    </SettingsLayoutContainer>
  );
}

export const SettingsContentContainer = styled(Flex, {
  position: 'relative',
  align: 'stretch',
  justifyItems: 'stretch',
  flexFrow: 1,
  flexShrink: 'initial',
  flexBasis: 'initial',
  width: '100%',
  height: '100%',
  minHeight: '100%',
});

export const MobileSettingsContainer = styled(Flex, {
  height: '64px',
  maxWidth: '100vw',
  flexShrink: 0,
  justify: 'space-between',
  wrap: 'wrap',
  borderBottom: 'thin solid $gray4',
  px: '$3',
  '@lg': {
    display: 'none',
  },
  '@xl': {
    px: '$10',
  },
});

export const SettingsContent = styled(Flex, {
  flexGrow: 1,
  flexBasis: 'auto',
  flexShrink: 1,
  overflow: 'auto',
  width: '100%',
  height: '100%',
  minHeight: 'calc(100vh - 80px)',
  '@lg': {
    minHeight: 'calc(100vh - 104px)',
  },
  '@media (min-width: 1083px)': {
    minHeight: 'calc(100vh - 85px)',
  },
  '@media (min-width: 1140px)': {
    minHeight: 'calc(100vh - 80px)',
  },
});

export const SettingsPageContainer = styled(Box, {
  alignContent: 'stretch',
  width: '100%',
  flexGrow: 1,
});
