import React from 'react';

import { Flex } from '@/shared/ui';

type DocViewerProps = {
  src: string;
};

export const DocViewer = (props: DocViewerProps) => {
  const { src } = props;
  return (
    <Flex
      align="center"
      justify="center"
      css={{
        width: '100%',
        height: '100%',
      }}
    >
      <iframe
        title="Document"
        src={`https://view.officeapps.live.com/op/embed.aspx?src=${src}`}
        frameBorder="0"
        style={{
          width: '100%',
          height: '100%',
        }}
      />
    </Flex>
  );
};
