export const formatValue = (comparison: string, value: string) => {
  let wildcardValue = `${value}`;
  if (value === null) {
    wildcardValue = '';
  } else {
    if (['start', 'not_start'].includes(comparison)) {
      wildcardValue = `${value}%`;
    } else if (['end', 'not_end'].includes(comparison)) {
      wildcardValue = `%${value}`;
    } else if (['contains', 'not_contains'].includes(comparison)) {
      wildcardValue = `%${value}%`;
    }
  }

  let formattedComparison = comparison;
  if (['contains', 'start', 'end'].includes(comparison)) {
    formattedComparison = 'ilike';
  } else if (['not_contains', 'not_start', 'not_end'].includes(comparison)) {
    formattedComparison = 'not_ilike';
  } else {
    formattedComparison = comparison;
  }

  return {
    comparison: formattedComparison,
    value: wildcardValue,
  };
};
