/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';

import { VStack } from '@/shared/ui';

// import { ContactsOverview } from './ContactsOverview';
import { ReportsContentContainer } from '../layout/ReportsContentContainer';
// import { CampaignsOverview } from './CampaignsOverview';
import { MessagingOverview } from './MessagingOverview';

export const OverviewReport = () => {
  return (
    <ReportsContentContainer title="Overview">
      <VStack gap={2}>
        <MessagingOverview />
        {/* <CampaignsOverview /> */}
        {/* <ContactsOverview /> */}
      </VStack>
    </ReportsContentContainer>
  );
};
