/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useCallback, useState } from 'react';
import { toast } from 'sonner';

import { DomainSelect } from '@/shared/components/domainSelect/DomainSelect';
import { OrganizationLinkTrackingSettings } from '@/shared/types';
import {
  Box,
  Fieldset,
  Flex,
  Label,
  Switch,
  SwitchThumb,
  Text,
  VStack,
} from '@/shared/ui';
import i18next from '@/shared/utils/translation';

import { useSettings } from './context/SettingsContext';

type LinkTrackingProps = {
  linkTracking: OrganizationLinkTrackingSettings;
};

export const LinkTracking = memo(({ linkTracking }: LinkTrackingProps) => {
  const { updateSettings } = useSettings();

  const [domainId, setDomainId] = useState('');

  const handleLinkTrackingUpdate = useCallback(
    async (value: boolean) => {
      try {
        await updateSettings({
          settings: {
            link_tracking: {
              ...linkTracking,
              enabled: value,
            },
          },
        });
        toast.success(
          i18next.t('organization_link_tracking_settings_update_success') as string
        );
      } catch (err) {
        toast.error(
          i18next.t('organization_link_tracking_settings_update_failure') as string
        );
      }
    },
    [linkTracking]
  );

  const handleLinkTrackingMetadataUpdate = useCallback(
    async (value: boolean) => {
      try {
        await updateSettings({
          settings: {
            link_tracking: {
              ...linkTracking,
              track_click_metadata: value,
            },
          },
        });
        toast.success(
          i18next.t('organization_link_tracking_settings_update_success') as string
        );
      } catch (err) {
        toast.error(
          i18next.t('organization_link_tracking_settings_update_failure') as string
        );
      }
    },
    [linkTracking]
  );

  const handleChangeDomain = useCallback(
    async (newDomainId: string) => {
      setDomainId(newDomainId);
      try {
        await updateSettings({
          settings: {
            link_tracking: {
              ...linkTracking,
              default_domain_id: newDomainId || null,
            },
          },
        });
        toast.success(
          i18next.t('organization_link_tracking_settings_update_success') as string
        );
      } catch (err) {
        toast.error(
          i18next.t('organization_link_tracking_settings_update_failure') as string
        );
      }
    },
    [linkTracking]
  );

  return (
    <VStack
      css={{
        mt: '$6',
      }}
    >
      <Fieldset>
        <VStack gap={3}>
          <Box>
            <Label>Organization Link Tracking Settings</Label>
            <Text>
              You can disable link tracking for your entire organization. Please note that
              links will no longer be shortened by Whippy. Using long links or third-party
              URL shorteners increase the risk that your messages will be flagged for
              SPAM.
            </Text>
          </Box>

          <Flex align="center" justify="between">
            <Label>Enable Link Tracking for the entire organization</Label>
            <Switch
              checked={linkTracking?.enabled}
              onCheckedChange={handleLinkTrackingUpdate}
            >
              <SwitchThumb />
            </Switch>
          </Flex>
          <Flex align="center" justify="between">
            <Label>Enable Link Tracking Metadata</Label>
            <Switch
              checked={linkTracking?.track_click_metadata}
              onCheckedChange={handleLinkTrackingMetadataUpdate}
            >
              <SwitchThumb />
            </Switch>
          </Flex>
          <DomainSelect
            value={domainId || linkTracking?.default_domain_id || ''}
            onChange={handleChangeDomain}
          />
        </VStack>
      </Fieldset>
    </VStack>
  );
});
