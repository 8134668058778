import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import { styled } from '@/stitches.config';

import { AspectRatio, Box, Skeleton } from '../../ui';

type AttachmentProps = {
  src?: string;
  preview?: boolean;
};

export const AspectRatioContainer = styled(Box, {
  width: 'inherit',
  borderRadius: 12,
  minHeight: 90,
  overflow: 'hidden',
  alignItems: 'end',
  maxHeight: 254,
});

export function ImageAttachment(props: AttachmentProps): JSX.Element {
  return props.preview ? (
    <AspectRatioContainer>
      <AspectRatio ratio={5 / 3}>
        <LazyLoadImage
          src={props.src}
          placeholder={<Skeleton variant="fill" />}
          style={{
            objectFit: 'cover',
            borderRadius: 12,
            minHeight: 90,
            maxWidth: 90,
          }}
          visibleByDefault={true}
        />
      </AspectRatio>
    </AspectRatioContainer>
  ) : (
    <AspectRatioContainer>
      <LazyLoadImage
        src={props.src}
        placeholder={<Skeleton variant="fill" />}
        width={300}
        height={300}
        style={{
          objectFit: 'cover',
          borderRadius: 12,
          minHeight: 90,
        }}
        visibleByDefault={true}
      />
    </AspectRatioContainer>
  );
}
