import { debounce } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { CombinedFilters } from '@/shared/components/filterBuilder/CombinedFilters';
import { areFiltersValid } from '@/shared/components/filterBuilder/utils/areValidFilters';
import { cleanFilters } from '@/shared/components/filterBuilder/utils/cleanFilters';
import { PageLayout } from '@/shared/layouts/PageLayout';
import { FilterType, Sort } from '@/shared/types/filter';
import { Box, Flex } from '@/shared/ui';

import { useLLMAgent } from '../context/AgentsContext';
import { AgentsTable } from '../editor/AgentsTable';
import { CreateAgent } from '../editor/CreateAgent';
import {
  created_by,
  current_agent_version_object,
  default_agents_object,
  updated_by,
} from './config/filterConfig';
import { sortConfig } from './config/sortConfig';

export const Agents = () => {
  const [quickSearchValue, setQuickSearchValue] = useState('');
  const [activeFilters, setActiveFilters] = useState<FilterType[]>([]);
  const [activeSort, setActiveSort] = useState<Sort[]>([
    {
      label: 'Updated At',
      column: 'updated_at',
      resource: 'agent',
      order: 'desc',
      id: 1,
    },
  ]);
  const [offset, setOffset] = useState(0);
  const limit = 10;

  // Default filter for agents that are not archived
  const default_status_filter = {
    resource: 'agent',
    column: 'status',
    comparison: '!=',
    value: 'archived',
  };

  const agentContext = useLLMAgent();
  const { fetchAgents, state } = agentContext;
  const { agents, loading } = state;

  useEffect(() => {
    fetchAgents(mergeDefaultStatusFilter(cleanFilters(activeFilters)), [], limit, offset);
  }, []);

  const history = useHistory();

  const onCardClick = (id: string) => {
    history.push(`/ai/agents/${id}/editor`);
  };

  const updateFilters = useCallback((filter: Array<FilterType>) => {
    setActiveFilters(filter);
  }, []);

  const mergeDefaultStatusFilter = useCallback(
    (filter: Array<FilterType>) => {
      const quickFilter = quickSearchValue
        ? {
            resource: 'current_version',
            column: 'name',
            comparison: 'ilike',
            value: `%${quickSearchValue}%`,
          }
        : null;

      return quickFilter
        ? [...filter, default_status_filter, quickFilter]
        : [...filter, default_status_filter];
    },
    [quickSearchValue]
  );

  const debouncedFetchAgents = useCallback(
    debounce(async (filter: Array<FilterType>) => {
      await fetchAgents(
        mergeDefaultStatusFilter(cleanFilters(filter)),
        [],
        limit,
        offset
      );
    }, 500),
    []
  );

  const handleFilterChange = useCallback(
    (filter: Array<FilterType>) => {
      updateFilters(filter);
      if (areFiltersValid(cleanFilters(filter))) {
        debouncedFetchAgents(filter);
      }
    },
    [updateFilters, debouncedFetchAgents]
  );

  const handleSortChange = useCallback((sort: Array<Sort>) => {
    setActiveSort(sort);
    fetchAgents(
      mergeDefaultStatusFilter(cleanFilters(activeFilters)),
      sort,
      limit,
      offset
    );
  }, []);

  const handleOffsetChange = useCallback((offset: number) => {
    setOffset(offset);
    fetchAgents(
      mergeDefaultStatusFilter(cleanFilters(activeFilters)),
      activeSort,
      limit,
      offset
    );
  }, []);

  const handleQuickSearchChange = useCallback((value: string) => {
    setQuickSearchValue(value);
    const filter = {
      resource: 'current_version',
      column: 'name',
      comparison: 'ilike',
      value: `%${value}%`,
    };
    fetchAgents(
      mergeDefaultStatusFilter(cleanFilters([filter])),
      activeSort,
      limit,
      offset
    );
  }, []);

  return (
    <PageLayout
      breadcrumbs={[
        { title: 'AI', path: '/ai/agents' },
        { title: 'Agents', path: '/ai/agents' },
      ]}
      actions={<CreateAgent />}
    >
      <Flex direction="column" css={{ flex: '1 1 auto', p: 30 }}>
        <Box css={{ paddingBottom: 24 }}>
          <CombinedFilters
            quickSearchPlaceholder="Search Agents"
            quickSearchValue={quickSearchValue}
            setQuickSearchValue={handleQuickSearchChange}
            defaultObjects={[
              default_agents_object,
              current_agent_version_object,
              updated_by,
              created_by,
            ]}
            customObjects={[]}
            activeFilters={activeFilters}
            setFilters={handleFilterChange}
            sortConfig={sortConfig}
            activeSort={activeSort}
            onSortUpdate={handleSortChange}
          />
        </Box>
        <AgentsTable
          data={agents.data}
          loading={loading}
          totalCount={agents.total}
          onCardClick={onCardClick}
          setOffset={handleOffsetChange}
        />
      </Flex>
    </PageLayout>
  );
};
