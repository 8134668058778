/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useHistory } from 'react-router-dom';
import { toast } from 'sonner';

import * as API from '@/shared/api/auth';
import { Organization } from '@/shared/types';
import { Box, Button, Flex, Link, Text, VStack } from '@/shared/ui';
import { setAuthTokens } from '@/shared/utils/storage';
import i18next from '@/shared/utils/translation';

import { useAuth } from './context/AuthProvider';
import { AuthContainer, AuthPageTitle, AuthPageWrapper } from './Login';

type OrganizationViewProps = {
  tokens: { access_token: string; renew_token: string };
  email: string;
};

export const Organizations = (props: OrganizationViewProps) => {
  const auth = useAuth();
  const [organizations, setOrganizations] = useState<[]>();
  const history = useHistory();

  // using the email and the access token from the props to
  // make a request and load and set the organizations of the user
  useEffect(() => {
    const queryParams = new URLSearchParams({ email: props.email }).toString();

    API.listOrganizations(queryParams, props.tokens.access_token).then((data) =>
      setOrganizations(data)
    );
  }, []);

  // on submit the organization of the user is changed with the selected one,
  // the tokens from the props are set (in local storage) as auth tokens and
  // a request is made to renew the tokens of the user which
  // populates the state with auth tokens and sets user as authenticated.
  const handleSubmit = async (
    e: React.MouseEvent<HTMLElement>,
    organizationId: string
  ) => {
    e.preventDefault();

    try {
      await API.changeOrganization(organizationId, props.tokens.access_token)
        .then(() => setAuthTokens(props.tokens))
        .then(() => auth.refresh());
    } catch (e) {
      toast.error(i18next.t('change_organization_error') as string);
    }
  };

  return (
    <>
      <Helmet>
        <title>Whippy | Organizations</title>
      </Helmet>
      <AuthPageWrapper align="center" justify="center">
        <AuthContainer>
          <AuthPageTitle css={{ fontSize: 18, pb: 15 }}>
            Switch Organizations
          </AuthPageTitle>
          {organizations?.length === 0 ? (
            <VStack gap={7} css={{ textAlign: 'center' }}>
              <Text size={3}>
                It seems like you are not a user of other organizations.
              </Text>
              <Text size={3}>Create your own!</Text>
              <Button onClick={() => history.push('/register')}>Create Account</Button>
            </VStack>
          ) : (
            <VStack gap={7}>
              <Box css={{ height: 200, overflowX: 'hidden', overflowY: 'auto' }}>
                {organizations?.map((org: Organization) => (
                  <Box
                    key={org.id}
                    css={{
                      width: 300,
                      pt: 15,
                      pb: 15,
                      cursor: 'pointer',
                      textAlign: 'center',
                    }}
                    onClick={(e: React.MouseEvent<HTMLElement>) =>
                      handleSubmit(e, org.id)
                    }
                  >
                    {org.organization_name}
                  </Box>
                ))}
              </Box>
              <Flex justify="center">
                <Link href="/register">Create an account?</Link>
              </Flex>
            </VStack>
          )}
        </AuthContainer>
      </AuthPageWrapper>
    </>
  );
};
