/* eslint-disable react-hooks/exhaustive-deps */
import EmojiPicker from 'emoji-picker-react';
import React, { ChangeEvent, Dispatch, SetStateAction, useRef } from 'react';
import { HiOutlineEmojiHappy } from 'react-icons/hi';

import { Channel } from '@/shared/types/channels';
import {
  Box,
  Fieldset,
  Flex,
  IconButton,
  Input,
  Label,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/shared/ui';

import { AddressInput } from '../channel/AddressInput';

export const SelectChannelDetails = ({
  channel,
  setChannel,
  onChange,
}: {
  channel: Partial<Channel>;
  setChannel: Dispatch<SetStateAction<Partial<Channel>>>;
  onChange: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}) => {
  const emojiButton = useRef<HTMLButtonElement>(null);

  return (
    <Flex css={{ width: '100%' }} direction="column">
      <Fieldset>
        <Label>Channel Name</Label>
        <Input
          placeholder="Channel Name"
          value={channel.name || ''}
          onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
            setChannel((prevChannel) => ({
              ...prevChannel,
              name: e.target.value,
            }))
          }
        />
      </Fieldset>
      <Fieldset>
        <Label>Emoji</Label>
        <Popover>
          <PopoverTrigger asChild>
            <IconButton ref={emojiButton} size={2} type="button" variant="outline">
              {channel.emoji ? (
                <Box>{channel.emoji}</Box>
              ) : (
                <HiOutlineEmojiHappy title="Emoji Picker" type="button" size={16} />
              )}
            </IconButton>
          </PopoverTrigger>
          <PopoverContent align="start" side="top" sideOffset={4}>
            <EmojiPicker
              onEmojiClick={(emoji) =>
                setChannel((prevChannel) => ({
                  ...prevChannel,
                  emoji: emoji.emoji,
                }))
              }
              height={300}
              previewConfig={{
                showPreview: false,
              }}
              autoFocusSearch={true}
            />
          </PopoverContent>
        </Popover>
      </Fieldset>
      <Fieldset>
        <Label>Description</Label>
        <Input
          placeholder="Description"
          value={channel.description || ''}
          onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
            setChannel((prevChannel) => ({
              ...prevChannel,
              description: e.target.value,
            }))
          }
        />
      </Fieldset>
      <Fieldset>
        <Label>Business Address</Label>
        <AddressInput
          channel={channel}
          setChannel={setChannel}
          onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
            onChange(e)
          }
        />
      </Fieldset>
      <Fieldset>
        <Label>Google Place ID</Label>
        <Input
          placeholder="GhIJQWDl0CIeQUARxks3icF8U8A"
          name="google_place_id"
          value={channel.google_place_id || ''}
          onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
            onChange(e)
          }
        />
      </Fieldset>
    </Flex>
  );
};
