export const getOpeningHours = () => {
  // Create an empty array to hold the formatted opening hours.
  const hours = [];

  // Loop through each hour of the day.
  for (let i = 0; i <= 23; i++) {
    // Calculate the hour in 12-hour format (e.g. 1 instead of 13).
    const hour = i % 12 || 12;
    // Determine whether the time is in the AM or PM.
    const amPm = i < 12 ? 'AM' : 'PM';

    // Loop through each minute of the hour (either 00 or 30).
    for (const minute of ['00', '30']) {
      // Create a string in the format "h:mm AM/PM".
      const formattedTime = `${hour}:${minute} ${amPm}`;
      if (formattedTime === '12:30 AM') {
        continue;
      }

      // Add the formatted time to the array of opening hours.
      hours.push(formattedTime);
    }
  }

  // Return the array of opening hours.
  return hours;
};
