import { Questions, SearchQuestion } from '../../types';
import { api } from '../api';

export const getAutomatedQuestions = (params: any) =>
  api.get(`/questions?${params}`).then((res) => {
    return res.data.data;
  });

export const searchAutomatedQuestions = (searchQuestion: SearchQuestion) =>
  api.post(`/questions/search`, { ...searchQuestion }).then((res) => {
    return res.data.data;
  });

export const createAutomatedQuestion = (questions: Questions) =>
  api.post(`/questions`, { ...questions }).then((res) => {
    return res.data.data;
  });

export const updateAutomatedQuestion = (questions: Questions) =>
  api.put(`/questions/${questions.id}`, { question: { ...questions } }).then((res) => {
    return res.data.data;
  });

export const deleteAutomatedQuestion = (questions: Questions) =>
  api.delete(`/questions/${questions.id}`).then((res) => {
    return res.data.data;
  });

export const enableAutomatedQuestion = (questions: Questions) =>
  api
    .put(`/questions/${questions.id}/enable`, { question: { enabled: true } })
    .then((res) => {
      return res.data.data;
    });

export const disableAutomatedQuestion = (questions: Questions) =>
  api
    .put(`/questions/${questions.id}/enable`, { question: { enabled: false } })
    .then((res) => {
      return res.data.data;
    });
