import { Box, Flex } from '@/shared/ui';
import { styled } from '@/stitches.config';

export const getLocationName = (id: string, locations: any[]) =>
  locations.find((location) => location.id === id)?.name;

export const ContactItemContainer = styled(Flex, {
  pt: 19,
  pb: 19,
  paddingRight: 30,
  textAlign: 'left',
  cursor: 'pointer',
  width: '100%',
  px: 15,
  '@lg': {
    px: 30,
  },
  '&:hover': {
    backgroundColor: '$slate2',
  },
  variants: {
    button: {
      true: {
        backgroundColor: '$slate2',
      },
    },
  },
});

export const ContactName = styled(Box, {
  fontSize: 16,
  fontWeight: 600,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

export const ContactPhone = styled(Box, {
  fontSize: 16,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});
