import { Tag } from '../../tags';
import { SearchFilters } from '..';

export type ContactParams = {
  /*
   * Email address of the contact. It is optional and can be null.
   */
  email?: string | null;
  /*
   * Full name of the contact. It is optional and can be null.
   */
  name?: string | null;
  /*
   * Phone number of the contact. It is optional and can be null.
   */
  phone?: string | null;
  /*
   * First name of the contact. It is optional and can be null.
   */
  first_name?: string | null;
  /*
   * Last name of the contact. It is optional and can be null.
   */
  last_name?: string | null;
};

export type UploadsType = {
  uploads: Array<Tag>;
  total: number;
};

export enum UploadsActionTypes {
  GET_UPLOADS = 'GET_UPLOADS',
  GET_ALL_UPLOADS = 'GET_ALL_UPLOADS',
  GET_RECENT_UPLOADS = 'GET_RECENT_UPLOADS',
  GET_UPLOAD = 'GET_UPLOAD',
  SEARCH_UPLOAD = 'SEARCH_UPLOAD',
  CREATE_UPLOAD = 'CREATE_UPLOAD',
  EDIT_UPLOAD = 'EDIT_UPLOAD',
  DELETE_UPLOAD = 'DELETE_UPLOAD',
  SET_CURRENT = 'SET_CURRENT',
  SET_LOADING = 'SET_LOADING',
  UPLOAD_CONTACTS = 'UPLOAD_CONTACTS',
  GET_UPLOADS_V2 = 'GET_UPLOADS_V2',
  UPDATE_FILTER_PARAMS = 'UPDATE_FILTER_PARAMS',
}

export type UploadsAction =
  | {
      type: UploadsActionTypes.GET_UPLOADS;
      payload: { uploads: Array<Tag>; total?: number } | Array<Tag>;
    }
  | {
      type: UploadsActionTypes.GET_ALL_UPLOADS;
      payload: { uploads: Array<Tag>; total?: number } | Array<Tag>;
    }
  | {
      type: UploadsActionTypes.GET_RECENT_UPLOADS;
      payload: { uploads: Array<Tag>; total?: number } | Array<Tag>;
    }
  | { type: UploadsActionTypes.GET_UPLOAD; payload: Tag | null }
  | { type: UploadsActionTypes.GET_UPLOAD; payload: Tag | null }
  | { type: UploadsActionTypes.SEARCH_UPLOAD; payload: Array<Tag> }
  | { type: UploadsActionTypes.UPLOAD_CONTACTS; payload: Tag }
  | { type: UploadsActionTypes.CREATE_UPLOAD; payload: Tag }
  | { type: UploadsActionTypes.EDIT_UPLOAD; payload: Tag }
  | { type: UploadsActionTypes.DELETE_UPLOAD; payload: string }
  | { type: UploadsActionTypes.SET_CURRENT; payload: Tag | null }
  | { type: UploadsActionTypes.SET_LOADING; payload: boolean }
  | {
      type: UploadsActionTypes.GET_UPLOADS_V2;
      payload: { data: Array<Tag>; total: number };
    }
  | { type: UploadsActionTypes.UPDATE_FILTER_PARAMS; payload: SearchFilters };
