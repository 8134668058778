import { useState } from 'react';

import { FilterType } from '@/shared/types/filter';
import { Box, Flex } from '@/shared/ui';

import { SearchInput } from '../FilterBuilder';
import { relativeDateOptions } from '../utils/relativeDateOptions';
import { ValueCombobox } from './Combobox';

type DateValueProps = {
  isQuickFilter?: boolean;
  onChange: (field: keyof FilterType, value: FilterType[keyof FilterType]) => void;
  value: string | number | null;
  selectorStyles?: { [key: string]: any };
};

export const DateValue = (props: DateValueProps): JSX.Element => {
  const { isQuickFilter, onChange, value, selectorStyles } = props;

  const [isCustomDate, setIsCustomDate] = useState(
    !value || !relativeDateOptions.find((op) => op.value === value)
  );

  const handleSelect = (value: string | number) => {
    if (value === relativeDateOptions[relativeDateOptions.length - 1].value) {
      setIsCustomDate(true);
      onChange('value', null);
      onChange('cast', null);
    } else {
      setIsCustomDate(false);
      onChange('value', value);
      onChange('cast', { from: 'relative_datetime', to: 'datetime_start' });
    }
  };

  const getSelectedValue = () => {
    const option = {
      label: relativeDateOptions.find((op) => op.value === value)?.label as string,
      value: value ?? '',
    };
    return !option.label || isCustomDate
      ? relativeDateOptions[relativeDateOptions.length - 1]
      : option;
  };

  return (
    <Flex>
      <ValueCombobox
        selectorStyles={selectorStyles}
        selectLabel="Select a value"
        options={relativeDateOptions}
        selected={getSelectedValue()}
        onSelect={(option) => handleSelect(option.value)}
      />
      {isCustomDate && (
        <Box css={{ ml: isQuickFilter ? 0 : 8 }}>
          <SearchInput
            isQuickFilter={isQuickFilter}
            value={value ?? ''}
            inputType="date"
            onChange={(value) => onChange('value', value)}
          />
        </Box>
      )}
    </Flex>
  );
};
