import React from 'react';
import { HiX } from 'react-icons/hi';
import { toast } from 'sonner';

import { useDisclosure } from '@/shared/hooks';
import {
  Button,
  Dialog,
  DialogCloseIcon,
  DialogContent,
  DialogOverlay,
  DialogPortal,
  DialogTitle,
  DialogTrigger,
  Flex,
  Input,
  Label,
  VStack,
} from '@/shared/ui';

import { useDeveloperContext } from '../context/DevelopersContext';

export function AddApiKey(): JSX.Element {
  const apiKeysContext = useDeveloperContext();
  const { createApiKey } = apiKeysContext;
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [apiKeyName, setApiKeyName] = React.useState('');

  const handleClose = async () => {
    try {
      createApiKey(apiKeyName);
      toast.success('API Key created successfully');
    } catch (error) {
      toast.error('Error in creating API Key');
    }
    onClose();
  };

  return (
    <Dialog open={isOpen}>
      <DialogTrigger asChild>
        <Button onClick={onOpen} variant="primary">
          Add API Key
        </Button>
      </DialogTrigger>
      <DialogPortal>
        <DialogOverlay>
          <DialogContent onEscapeKeyDown={onClose}>
            <DialogTitle variant="bold">Add API Key</DialogTitle>
            <DialogCloseIcon onClick={onClose} size="2">
              <HiX size="15px" />
            </DialogCloseIcon>
            <VStack gap="1" css={{ mb: 20 }}>
              <Label htmlFor="apiKeyName">API Key Name</Label>
              <Input
                placeholder="API Key Name"
                onChange={(e: { target: { value: React.SetStateAction<string> } }) =>
                  setApiKeyName(e.target.value)
                }
                value={apiKeyName}
              />
            </VStack>
            <Flex justify="end">
              <Button variant="gray" css={{ mr: 10 }} onClick={onClose}>
                Cancel
              </Button>
              <Button onClick={() => handleClose()} disabled={apiKeyName.length < 1}>
                Yes, Create Key
              </Button>
            </Flex>
          </DialogContent>
        </DialogOverlay>
      </DialogPortal>
    </Dialog>
  );
}
