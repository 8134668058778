import { api } from './api';

type TranslateMessageParams = {
  source_language: string;
  target_language: string;
  text: string;
  conversation_id?: string;
};

export interface TranslateMessageResponse {
  translated_text: string;
}

export const translateMessage = (
  params: TranslateMessageParams
): Promise<TranslateMessageResponse> =>
  api.post(`/messages/translate`, { ...params }).then((res) => {
    return res.data;
  });
