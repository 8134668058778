import { Formik, FormikProps } from 'formik';
import React from 'react';
import { CirclePicker } from 'react-color';
import { HiX } from 'react-icons/hi';
import * as Yup from 'yup';

import { FormFieldWrapper, TextInput } from '@/shared/components/forms';
import { useDisclosure } from '@/shared/hooks';
import { Tag } from '@/shared/types/tags';
import {
  Box,
  Button,
  Dialog,
  DialogClose,
  DialogCloseIcon,
  DialogContent,
  DialogFooter,
  DialogOverlay,
  DialogPortal,
  DialogTitle,
  DialogTrigger,
  VStack,
} from '@/shared/ui';

import { useTags } from './context/TagsContext';

type AddTagProps = {
  tag?: Tag;
  children: React.ReactNode;
};

export function AddTag(props: AddTagProps): JSX.Element {
  const tagsContext = useTags();
  const { createTag, editTag } = tagsContext;
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleClose = (formik: FormikProps<any>) => {
    formik.handleReset();
    onClose();
  };

  return (
    <Dialog open={isOpen}>
      <Box>
        <DialogTrigger asChild onClick={() => onOpen()}>
          {props.children}
        </DialogTrigger>
      </Box>
      <Formik
        initialValues={{
          name: props.tag?.name ?? '',
          color: props.tag?.color ?? '',
        }}
        validationSchema={Yup.object({
          name: Yup.string().required('Required').max(29, 'Max 29 characters'),
          color: Yup.string().required('Required'),
        })}
        onSubmit={async (values, actions) => {
          try {
            if (props.tag) {
              editTag({ ...props.tag, name: values.name, color: values.color });
            } else {
              createTag(values.name, values.color, 'standard');
            }
            actions.resetForm({
              values: {
                name: '',
                color: '',
              },
            });
            onClose();
          } catch (e) {
            console.log(e);
          }
        }}
      >
        {(formik) => (
          <DialogPortal>
            <DialogOverlay />
            <DialogContent
              onEscapeKeyDown={() => handleClose(formik)}
              onPointerDownOutside={() => handleClose(formik)}
              css={{ overflow: 'hidden' }}
            >
              <form onSubmit={formik.handleSubmit}>
                <DialogTitle variant="bold" css={{ marginBottom: 8 }}>
                  {props.tag ? 'Update Tag' : 'Add New Tag'}
                </DialogTitle>
                <Box css={{ fontSize: 14, marginBottom: 16 }}>
                  {props.tag
                    ? 'You can update Tag name and color.'
                    : 'You can add a new Tag by entering a Tag name and choosing a Tag color.'}
                </Box>
                <VStack gap={2}>
                  <FormFieldWrapper label="Tag Name" name="name">
                    <TextInput placeholder="Enter Tag name" maxLength={29} />
                  </FormFieldWrapper>
                  <FormFieldWrapper label="Tag Color" name="color">
                    <Box
                      css={{
                        boxShadow: 'inset 0 0 0 1px $colors$slate7',
                        padding: 13,
                        borderRadius: '$1',
                      }}
                    >
                      <CirclePicker
                        width="100%"
                        data-intercom-target="tag-color-picker"
                        color={formik.values['color']}
                        onChangeComplete={(e) => formik.setFieldValue('color', e.hex)}
                      />
                    </Box>
                  </FormFieldWrapper>
                </VStack>
                <DialogFooter justify="end" css={{ mt: 10 }}>
                  <DialogClose asChild>
                    <Button
                      variant="grayBackground"
                      css={{ mr: 8 }}
                      onClick={() => handleClose(formik)}
                    >
                      Cancel
                    </Button>
                  </DialogClose>
                  <Button type="submit">{props.tag ? 'Update Tag' : 'Save Tag'}</Button>
                </DialogFooter>
                <DialogCloseIcon onClick={() => handleClose(formik)} size="2">
                  <HiX size="15px" />
                </DialogCloseIcon>
              </form>
            </DialogContent>
          </DialogPortal>
        )}
      </Formik>
    </Dialog>
  );
}
