import * as TooltipPrimitive from '@radix-ui/react-tooltip';
import { CSS, keyframes, styled } from '@stitches/react';
import React from 'react';

const slideUpAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateY(2px)' },
  '100%': { opacity: 1, transform: 'translateY(0)' },
});

const slideRightAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateX(-2px)' },
  '100%': { opacity: 1, transform: 'translateX(0)' },
});

const slideDownAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateY(-2px)' },
  '100%': { opacity: 1, transform: 'translateY(0)' },
});

const slideLeftAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateX(2px)' },
  '100%': { opacity: 1, transform: 'translateX(0)' },
});

const StyledContent = styled(TooltipPrimitive.Content, {
  borderRadius: 4,
  padding: '10px 10px',
  marginTop: '10px',
  fontSize: 13,
  lineHeight: 1,
  zIndex: 9999999999,
  color: '$slate1',
  backgroundColor: '$gray12',
  boxShadow:
    'hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px',
  '@media (prefers-reduced-motion: no-preference)': {
    animationDuration: '10ms',
    animationTimingFunction: 'cubic-bezier(0.16, 1, 0.3, 1)',
    willChange: 'transform, opacity',
    '&[data-state="delayed-open"]': {
      '&[data-side="top"]': { animationName: slideDownAndFade },
      '&[data-side="right"]': { animationName: slideLeftAndFade },
      '&[data-side="bottom"]': { animationName: slideUpAndFade },
      '&[data-side="left"]': { animationName: slideRightAndFade },
    },
  },
});

export const TooltipArrow = styled(TooltipPrimitive.Arrow, {
  fill: '$gray12',
});

type TooltipRootProps = React.ComponentProps<typeof TooltipPrimitive.Root>;
type TooltipProps = TooltipRootProps & { css?: CSS };

export const Tooltip = React.forwardRef<
  React.ElementRef<typeof TooltipPrimitive.Root>,
  TooltipProps
>(({ children, ...props }, ref) => {
  return (
    <TooltipProvider delayDuration={0}>
      <TooltipPrimitive.Root {...props}>
        <div ref={ref}>{children}</div>
      </TooltipPrimitive.Root>
    </TooltipProvider>
  );
});

type TooltipContentPrimitiveProps = React.ComponentProps<typeof TooltipPrimitive.Content>;
type TooltipContentProps = TooltipContentPrimitiveProps & { css?: CSS };

function Content({
  children,
  css,
  ...props
}: {
  children: React.ReactNode;
  props?: TooltipContentProps;
  side?: 'bottom' | 'left' | 'right' | 'top' | undefined;
  sideOffset?: number;
  asChild?: boolean;
  css?: CSS;
}) {
  return (
    <TooltipPrimitive.Portal>
      <StyledContent css={css} {...props}>
        {children}
      </StyledContent>
    </TooltipPrimitive.Portal>
  );
}

// Exports
// export const Tooltip = TooltipPrimitive.Root;
export const TooltipProvider = TooltipPrimitive.Provider;
export const TooltipTrigger = TooltipPrimitive.Trigger;
export const TooltipContent = Content;
