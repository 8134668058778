import React from 'react';

import {
  ContactSourceColor,
  ContactSourceCopy,
} from '@/pages/contacts/context/constants';
import { CircleIcon } from '@/shared/components/Icons';
import { Box, Flex, HStack } from '@/shared/ui';
import { styled } from '@/stitches.config';

import { useContacts } from '../context/ContactContext';

export const ContactSource = (): JSX.Element => {
  const { contactState } = useContacts();
  const { current } = contactState;
  const contactSource = current?.source;

  return (
    <Box css={{ ml: 20 }}>
      {contactSource != null ? (
        <ContactSourceTag
          iconColor={ContactSourceColor[contactSource]}
          tagText={ContactSourceCopy[contactSource] || current?.source || ''}
        />
      ) : (
        <UnknownSource>Unknown</UnknownSource>
      )}
    </Box>
  );
};

type ContactSourceTagProps = {
  /** tag icon color */
  iconColor?: string;
  /** tag name */
  tagText?: string;
};

export function ContactSourceTag(props: ContactSourceTagProps) {
  return (
    <ContactSourceLayout>
      <HStack gap={1}>
        <CircleIcon color={props.iconColor || '#2A2D8A'} />
        <SourceText>{props.tagText}</SourceText>
      </HStack>
    </ContactSourceLayout>
  );
}

const SourceText = styled(Box, {
  textTransform: 'capitalize',
  maxWidth: 150,
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
});

const ContactSourceLayout = styled(Flex, {
  maxWidth: '220px',
  width: 'max-content',
  fontSize: '12px',
  fontWeight: 'medium',
  borderRadius: '100px',
  p: '3px 4px',
  pr: '7px',
  pl: '7px',
  m: '3px',
  backgroundColor: '$panel',
  border: '1px solid',
  borderColor: '$gray4',
});

const UnknownSource = styled(Box, {
  color: '#879095',
  p: '3.5px 0px',
  pl: 2,
});
