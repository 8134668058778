import * as SeparatorPrimitive from '@radix-ui/react-separator';
import * as ToolbarPrimitive from '@radix-ui/react-toolbar';
import TextareaAutosize from 'react-textarea-autosize';

import { Box, Flex } from '@/shared/ui';
import { styled } from '@/stitches.config';

// outer most styles wraps editor and typing container
export const MessageEditorWrapper = styled(Box, {
  width: '100%',
  display: 'initial',
  position: 'relative',
  maxHeight: 'none',
  px: '25px',
  paddingTop: '5px',
});

// wraps just the message editor component
export const MessageEditorContainer = styled(Flex, {
  width: '100%',
  height: 'inherit',
  position: 'relative',
  wordBreak: 'break-word',
  borderBottom: '0px',
  boxShadow: 'inset 0 0 0 1.5px $colors$slate5',
  borderRadius: '$2',
  variants: {
    focus: {
      true: {
        boxShadow:
          'rgba(62, 84, 207, 0.7) 0px 0px 0px 1.5px inset, rgba(62, 84, 207, 0.1) 0px 0px 0px 4px;',
      },
    },
  },
});

// message editor buttons container
export const MessageEditorToolbarContainer = styled(ToolbarPrimitive.Root, {
  display: 'flex',
  flexDirection: 'row',
  alignContent: 'flex-end',
  justifyContent: 'space-between',
  padding: '8px 12px',
  borderRadius: '8px',
  paddingTop: 10,
});

// typing indicator container
export const TypingContainer = styled(Box, {
  height: 30,
  fontSize: 13,
  paddingTop: 5,
});

// pipe span styles
export const StyledPipe = styled('span', {
  padding: '3px 3px 2px',
  lineHeight: '21px',
  mx: '2px',
  verticalAlign: 'baseline',
  display: 'inline-block',
  borderRadius: '4px',
  fontSize: '0.9em',
  backgroundColor: '#5653DF',
  color: 'white',
});

// styles inherited by Slate editor
export const SlateContainer = styled(Box, {
  width: '100%',
  height: '100%',
  lineHeight: '21px',
  textAlign: 'left',
  fontSize: '15px',
  overflowY: 'auto',
  overflowX: 'hidden',
  cursor: 'text',
  borderRadius: '10px',
  border: 'none',
  paddingTop: 10,
  px: 15,
  resize: 'none',
  '&:focus': {
    outline: 'none',
  },
  '@md': {
    paddingTop: 16,
  },
  '@lg': {
    paddingTop: 15,
    minHeight: '50px',
  },
});

// separator between buttons and character count
export const StyledSeparator = styled(SeparatorPrimitive.Root, {
  backgroundColor: '$gray6',
  '&[data-orientation=horizontal]': { height: 1, width: '100%' },
  '&[data-orientation=vertical]': { height: '50%', width: 1 },
});

export const MobileMessageEditorContainer = styled(Flex, {
  width: '100%',
  position: 'relative',
  borderTop: '2px solid $gray4',
  align: 'end',
  paddingTop: '20px',
  overflowY: 'scroll',
  height: 160,
  px: 10,
  borderTopRightRadius: 15,
  borderTopLeftRadius: 15,
});

export const MobileIconsContainer = styled(Flex, {
  height: 65,
  fontSize: 13,
  paddingBottom: 40,
});

export const StyledTextArea = styled(TextareaAutosize, {
  width: '100%',
  lineHeight: '21px',
  fontSize: '15px',
  overflow: 'auto',
  cursor: 'text',
  borderRadius: '10px',
  border: 'none',
  paddingTop: 10,
  px: 5,
  resize: 'none',
  '&:focus': {
    outline: 'none',
  },
});
