import { Trigger } from '../triggers';

export type GetAutomationTemplateParams = {
  title?: string;
  /* template usage */
  usage?: TemplateUsage;
  /* who can use this template */
  access_level?: TemplateAccessLevel;
  /* is this template archived */
  archived?: boolean;
  /* max number of records to return */
  limit?: number;
  /* number of records to skip */
  offset?: number;
  /* the organization id */
  organization_id?: string;
};

export type AutomationTemplate = {
  id?: string | null;
  /* organization id for template */
  organization_id?: string | null;
  /* template name/description */
  title?: string | null;
  /* who can use this template */
  access_level?: TemplateAccessLevel | null;
  /* is this template archived */
  archived?: boolean | null;
  /* template usage */
  usage?: TemplateUsage | null;
  /* template triggers objects */
  triggers?: Trigger[] | null;
  /* who created the template? - could be by us so could be null */
  created_by?: string | null;
  /* who updated the template? - could be by us so could be null */
  updated_by?: string | null;
  /* when was the template created? */
  inserted_at?: string | null;
  /* when was the template updated? */
  updated_at?: string | null;
};

export enum TemplateAccessLevel {
  ORGANIZATION = 'organization',
  LOCATION = 'location',
  USER = 'user',
}

export enum TemplateUsage {
  AS_IS = 'as-is',
  DUPLICATE = 'duplicate',
}

export enum AutomationTemplatesActionTypes {
  GET_AUTOMATION_TEMPLATES = 'GET_AUTOMATION_TEMPLATES',
  GET_AUTOMATION_TEMPLATE = 'GET_AUTOMATION_TEMPLATE',
  CREATE_AUTOMATION_TEMPLATE = 'CREATE_AUTOMATION_TEMPLATE',
  UPDATE_AUTOMATION_TEMPLATE = 'UPDATE_AUTOMATION_TEMPLATE',
  DELETE_AUTOMATION_TEMPLATE = 'DELETE_AUTOMATION_TEMPLATE',
  SET_CURRENT_AUTOMATION_TEMPLATE = 'SET_CURRENT_AUTOMATION_TEMPLATE',
}

export type AutomationTemplatesActions =
  | {
      type: AutomationTemplatesActionTypes.GET_AUTOMATION_TEMPLATES;
      payload: { data: AutomationTemplate[] | null[]; total: number; offset: number };
    }
  | {
      type: AutomationTemplatesActionTypes.GET_AUTOMATION_TEMPLATE;
      payload: AutomationTemplate | null;
    }
  | {
      type: AutomationTemplatesActionTypes.CREATE_AUTOMATION_TEMPLATE;
      payload: AutomationTemplate | null;
    }
  | {
      type: AutomationTemplatesActionTypes.UPDATE_AUTOMATION_TEMPLATE;
      payload: AutomationTemplate;
    }
  | {
      type: AutomationTemplatesActionTypes.DELETE_AUTOMATION_TEMPLATE;
      payload: string;
    }
  | {
      type: AutomationTemplatesActionTypes.SET_CURRENT_AUTOMATION_TEMPLATE;
      payload: AutomationTemplate | null;
    };
