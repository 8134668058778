import React from 'react';
import { HiPencilAlt, HiTrash } from 'react-icons/hi';
import { ActionType } from 'react-table';

import { useDisclosure } from '@/shared/hooks';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogOverlay,
  AlertDialogPortal,
  AlertDialogTitle,
  AlertDialogTrigger,
  Box,
  Button,
  Flex,
  HStack,
  IconButton,
  VStack,
} from '@/shared/ui';

import { ActionDialog } from './ActionDialog';
import { action_values } from './types';

type ActionWrapperProps = {
  /** action title */
  title: string;
  /** action description */
  description: string | React.ReactNode;
  /** action child component */
  children: React.ReactNode;
  /** action object */
  action?: ActionType;
  /** remove action function */
  removeAction?: (action: ActionType) => void;
  /** save the current action */
  saveAction?: () => void;
  /** action icon */
  actionIcon: React.ReactNode;
  /** action color */
  actionColor: string;
};

export const ActionWrapper = (props: ActionWrapperProps) => {
  const { children, title, description, action, removeAction, actionIcon, actionColor } =
    props;

  const {
    isOpen: isAlertOpen,
    onOpen: onAlertOpen,
    onClose: onAlertClose,
  } = useDisclosure();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleClose = () => {
    if (removeAction && action) {
      removeAction(action);
    }
    onAlertClose();
  };

  return (
    <Flex>
      <Flex direction="column" align="center" css={{ mr: 10 }}>
        <Flex
          align="center"
          justify="center"
          css={{
            backgroundColor: actionColor || '#DFE3EB',
            color: 'white',
            borderRadius: '50%',
            width: 28,
            height: 28,
            mb: 10,
          }}
        >
          {actionIcon}
        </Flex>
        <Box
          css={{
            borderLeft: '1px solid #DFE3EB',
            height: 'calc(100% - 40px)',
          }}
        />
      </Flex>
      <Flex direction="column" css={{ width: '100%', maxWidth: 'calc(100% - 40px)' }}>
        <Box css={{ mb: 5 }}>
          <Box
            css={{
              fontSize: 14,
              fontWeight: 500,
            }}
          >
            {title}
          </Box>
        </Box>
        <Flex direction="row" css={{ width: '100%' }}>
          <Flex
            css={{
              borderWidth: 1,
              borderColor: '$slate6',
              borderRadius: 8,
              backgroundColor: 'white',
              p: 15,
              width: '100%',
            }}
            align="center"
            justify="between"
          >
            <VStack gap={2} css={{ width: 'calc(100% - 85px)' }}>
              <Box
                css={{
                  fontWeight: 500,
                  fontSize: 14,
                  color: '#2B2A34',
                  textOverflow: 'ellipsis',
                  width: '100%',
                  overflow: 'hidden',
                }}
              >
                {description}
              </Box>
            </VStack>
            <HStack>
              {removeAction && action && (
                <AlertDialog open={isAlertOpen}>
                  <AlertDialogTrigger asChild>
                    <IconButton
                      size="2"
                      type="button"
                      onClick={onAlertOpen}
                      variant="outline"
                    >
                      <HiTrash />
                    </IconButton>
                  </AlertDialogTrigger>
                  <AlertDialogPortal>
                    <AlertDialogOverlay>
                      <AlertDialogContent onEscapeKeyDown={onAlertClose}>
                        <AlertDialogTitle>Remove Action</AlertDialogTitle>
                        <AlertDialogDescription>
                          Are you sure you want to remove this action?
                        </AlertDialogDescription>
                        <Flex justify="end">
                          <AlertDialogCancel asChild>
                            <Button
                              variant="gray"
                              type="button"
                              css={{ mr: 10 }}
                              onClick={onAlertClose}
                            >
                              No
                            </Button>
                          </AlertDialogCancel>
                          <AlertDialogAction asChild>
                            <Button
                              variant="red"
                              type="button"
                              onClick={() => handleClose()}
                            >
                              Yes, Remove Action
                            </Button>
                          </AlertDialogAction>
                        </Flex>
                      </AlertDialogContent>
                    </AlertDialogOverlay>
                  </AlertDialogPortal>
                </AlertDialog>
              )}
              <ActionDialog
                trigger={
                  <IconButton size="2" type="button" variant="outline">
                    <HiPencilAlt />
                  </IconButton>
                }
                saveAction={props.saveAction}
                isOpen={isOpen}
                onClose={onClose}
                onOpen={onOpen}
              >
                {children}
              </ActionDialog>
            </HStack>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export const getActionTitle = (x: ActionType) => {
  const action = action_values.find((a) => a.value === x?.type);
  return action?.title;
};

export const getActionDescription = (x: ActionType) => {
  const action = action_values.find((a) => a.value === x?.type);
  return action?.description;
};

export const getActionColor = (x?: ActionType) => {
  const action = action_values.find((a) => a.value === x?.type);
  return action?.color;
};

export const getActionIcon = (x?: ActionType) => {
  const action = action_values.find((a) => a.value === x?.type);
  return action?.icon;
};
