import React from 'react';

import { useChannels } from '@/pages/settings/organization/channels/context/ChannelContext';
import { Campaign } from '@/shared/types/campaigns';
import { Channel } from '@/shared/types/channels';
import { Box, Flex } from '@/shared/ui';
import { getChannelIds, hasChannels } from '@/shared/utils/campaign';

type CampaignCardLocationProps = {
  campaign: Campaign;
};

const CampaignCardLocation = (props: CampaignCardLocationProps) => {
  const { campaign } = props;

  const locationsContext = useChannels();
  const { channelsState } = locationsContext;
  const { channels } = channelsState;

  const locationsText = (locationIds: Array<string>) => {
    if (locationIds) {
      const locationsNames = channels
        .map((location: Channel) =>
          locationIds.includes(location.id) ? location.name : null
        )
        .filter((location) => location !== null);

      if (locationsNames.length === 1) {
        return locationsNames[0] || '';
      } else if (locationsNames.length > 1) {
        return `${locationsNames[0]} & ${locationsNames?.length - 1} more` || '';
      } else {
        return '';
      }
    }
  };

  return (
    <Flex direction="column">
      <Flex css={{ fontSize: 12, fontColor: '#7F7F86' }}>Sent From</Flex>
      <Box
        css={{
          width: 80,
          fontSize: 14,
          fontColor: '#2B2A34',
          mt: 8,
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {hasChannels(campaign) ? locationsText(getChannelIds(campaign)) : '-'}
      </Box>
    </Flex>
  );
};

export default CampaignCardLocation;
