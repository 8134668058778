import { api } from './api';

export const getGoogleConsent = () =>
  api.get(`/google/consent`).then((res) => {
    return res.data.data.url;
  });

export const getGoogleReviews = (locationIds: Array<string>) =>
  api
    .post(`/google/reviews`, {
      location_ids: locationIds,
    })
    .then((res) => {
      return res.data.data;
    });

export const replyToReview = ({ reviewId, message, google_location_id }: any) =>
  api
    .post(`/google/reviews/${reviewId}/responses`, {
      body: message,
      google_location_id,
    })
    .then((res) => {
      return res.data.data;
    });

export type GenerateResponseParams = {
  name: string;
  stars: string;
  review: string | undefined | null;
  reviewId: string;
};

export const generateReviewResponse = (
  generateReviewResponseParams: GenerateResponseParams
) =>
  api
    .post(`/generate/review_response`, { review: generateReviewResponseParams })
    .then((res) => {
      return res.data.data;
    });
