import { Channel } from './channels';
import { ConversationMessageType } from './conversations';
import { Tag } from './tags';
import { User } from './users';

export type Organization = {
  /* organization uuid */
  id: string;
  /* organization name */
  organization_name: string;
  /* organization users with access */
  users?: Array<User>;
  /* organization webchat settings */
  widget_settings?: {
    /* organization webchat settings uuid */
    id?: string | null;
    /* organization webchat color */
    color?: string | null;
    /* organization webchat greeting */
    greeting?: string | null;
    /* organization webchat position - left or right of page */
    position?: string | null;
    /* show webchat when business is open, closed, always */
    show_when?: 'open' | 'closed' | 'always' | null;
    /* the profile picture/logo of the webchat */
    attachment?: {
      /* webchat attachment uuid */
      id?: string | null;
      /* webchat attachment url */
      url?: string | null;
      /* webchat attachment content type */
      content_type?: string | null;
    } | null;
  };
  /* organization gmb access */
  google_access_token?: string | null;
  /* organization profile picture attachment */
  attachment?: { url: string; content_type: string } | null;
  /* organization billing plan - possible needs to be deprecated? */
  billing_plan?: string;
  /* organization settings */
  settings?: {
    /** how should we opt out contacts  */
    contact_opt_out_default?: 'location' | 'channel_type' | 'all' | null;
    /* organization link tracking settings */
    link_tracking: OrganizationLinkTrackingSettings;
    /* max number of segments per sms message */
    max_number_of_segments?: number | null;
    /* can use emojis in sms messages */
    sms_emoji_enabled?: boolean | null;
  };
  /** website url collected during onboarding */
  website_url?: string | null;
};

export type OrganizationLinkTrackingSettings = {
  enabled: boolean;
  default_domain_id: string | null;
  track_click_metadata: boolean;
};

export type Organizations = {
  organizations: Array<Organization>;
};

export type NoteType = {
  id: string;
  body: string;
  updated_at: string;
  inserted_at: string;
  user: User;
};

export type Contact = {
  id: string;
  email: string | null;
  name: string | null;
  phone: string | null;
  birth_date?: { year: number; month: number; day: number } | null;
  language?: string | null;
  source?: string | null;
  notes?: Array<NoteType> | null;
  contact_events?: Array<ContactEvent> | null;
  contact_tags?: Array<ContactTagItem> | null;
  contact_lists?: Array<ContactTagItem> | null;
  blocked?: boolean;
  opt_in_sms?: boolean;
  opt_in_email?: boolean;
  opt_out_of_all?: boolean;
  address?: ContactAddress | null;
  default_channel_id?: string | null;
  external_id?: string | null;
  communication_preferences?: CommunicationPreference[] | null;
};

export type ContactAddress = {
  address_line_one: string | null;
  address_line_two: string | null;
  city: string | null;
  state: string | null;
  post_code: string | null;
  country: string | null;
};

export type NewContact = {
  email?: string;
  name?: string;
  phone?: string;
  source?: string;
  locations?: Array<string>;
  default_channel_id?: string | null;
  external_id?: string | null;
  address?: ContactAddress | null;
  language?: string | null;
  blocked?: boolean | null;
};

export type ContactEvent = {
  id: string;
  event_id: string;
  contact_id: string;
  event: Event;
  inserted_at: string;
};

export type CommunicationPreference = {
  id: string;
  channel_id: string;
  contact_id: string;
  created_at: string;
  last_campaign_date?: string | null;
  opt_in: boolean;
  opt_in_date: string | null;
  opt_out_date: string | null;
  paused_until?: string | null;
  updated_at?: string | null;
};

export type Contacts = {
  contacts: Array<Contact>;
};

export type DeliveryOptions = {
  /* max messages per period */
  messages_per_period: number;
  /* period in seconds */
  period: number;
};

export type TimeZone = {
  name: string;
  offset: string;
};

export type OpeningHour = {
  id?: string;
  opens_at: string;
  closes_at: string;
  weekday: string;
  state: string;
  isSwitch?: boolean;
  timezone?: string;
};

export type ProviderTypes =
  | 'telnyx'
  | 'twilio'
  | 'vonage'
  | 'bandwidth'
  | 'mailgun'
  | 'sinch'
  | null;

export type ProviderAuthParams =
  | TelnyxAuthParams
  | TwilioAuthParams
  | VonageAuthParams
  | BandwidthAuthParams
  | SinchAuthParams
  | MailgunAuthParams;

export type SinchAuthParams = {
  app_id: string;
  project_id: string;
  region: string;
  username: string;
  password: string;
  webhook_secret: string;
};

export type TelnyxAuthParams = {
  api_key: string;
  public_key: string;
};

export type TwilioAuthParams = {
  account_sid: string;
  auth_token: string;
};

export type VonageAuthParams = {
  application_id: string;
  private_key: string;
};

export type BandwidthAuthParams = {
  username: string;
  user_password: string;
  account_id: string;
  application_id: string;
  sub_account_number: string;
  location_number: string;
  callback_id: string;
  callback_password: string;
};

export type MailgunAuthParams = {
  base_domain: string;
  api_key: string;
  webhook_signing_key: string;
};

export type Channels = {
  locations: Array<Channel>;
};

export type ChannelTypes = 'phone' | 'email' | 'whatsapp' | null;

export type Attachment = {
  url: string;
  content_type: string;
};

export type Invite = {
  id: string;
  email: string;
  inserted_at: string;
  locations?: Array<Channel>;
  expired?: boolean;
};

export type Notification = {
  id: string;
  type: string;
  delivery_method: string;
};

export type NewNotification = {
  type: string;
  delivery_method: string;
};

export type Reviewer = {
  displayName: string;
  profilePhotoUrl: string;
};

export type ReviewReply = {
  comment: string;
  updateTime: string;
};

export type Review = {
  comment?: string;
  createTime: string;
  name: string;
  reviewId: string;
  reviewer: Reviewer;
  starRating: string;
  updateTime: string;
  address?: string | null;
  locationId: string;
  reviewReply: ReviewReply;
};

export type ContactTagItem = {
  type?: ContactTagType | null;
  contact_id: string;
  id: string;
  inserted_at: string;
  tag: Tag;
  tag_id: string;
  updated_at: string;
};

export enum ContactTagType {
  STANDARD = 'standard',
  UPLOAD = 'upload',
}

export type Note = {
  contact_id?: string;
  body?: string;
  id?: string;
  inserted_at: string;
  updated_at: string;
  user: User;
};

export type AddNote = {
  contact_id?: string;
  body?: string;
};

export type AddTemplate = {
  title?: string | null;
  message?: string | null;
};

export type Pipe = {
  name: string;
  flag: string;
};

export type Question = {
  question?: string;
};

export type SearchQuestion = {
  text: string;
  location_id: string;
};

export type TestMessages = {
  text: string;
  direction: 'inbound' | 'outbound';
};

export type Questions = {
  answer: string;
  id?: string;
  locations: Array<string>;
  organization_id?: string;
  questions: Array<Question>;
  tolerance?: number;
  attachments?: Array<Attachment>;
};

export type Preference = {
  id: string;
  location_id: string;
  contact_id: string;
  opt_in: boolean;
  opt_in_date: string | null;
  opt_out_date: string | null;
  last_campaign_date: string | null;
  paused_until?: string | null;
};

export type CampaignContact = {
  id: string;
  contact: Contact;
  campaign_message: ConversationMessageType;
  contact_messages: Array<ConversationMessageType>;
  delivered: boolean;
  responded: boolean;
  unresponded: boolean;
  unsubscribed: boolean;
  clicked_link: boolean;
};

export type ICustomTimezone = {
  [key: string]: string;
};

export type Signature = {
  /** signature id */
  id: string;
  /** signature name */
  name: string;
  /** signature body */
  body: string;
  /** signature access level */
  access_level: string;
  /** signature locations */
  locations?: Array<Channel>;
  /** signature created by */
  created_by: Partial<User>;
  /** signature updated by */
  updated_by: Partial<User>;
  /** signature crated at */
  inserted_at: string;
  /** signature updated at */
  updated_at: string;
  /** signature organization id */
  organization_id: string;
  /** id of default signature */
  default_signature_id: string | null;
};

export type DraftMessage = {
  id: string;
  user_id: number;
  conversation_id?: string;
  body: string;
  location: string;
  attachments: Array<any>;
  updated_at: string;
  inserted_at: string;
  contact: {
    id: string;
    name: string | null;
    phone: string;
    email: string | null;
  };
};

export type Brand = {
  brand_id: string;
  provider_brand_id: string;
  provider: string;
  status: string;
  identity_status: string;
  tax_exempt_status: string;
  russel_3000: string;
  csp_id: string;
  error: string;
  score: string;
  is_main: string;
};

export type FilterParams = {
  column: string;
  comparison: string;
  resource: string;
  value: string;
  cast?: {
    from: string;
    to: string;
  };
};
