import { ErrorBoundary } from '@sentry/react';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { DomainSelect } from '@/shared/components/domainSelect/DomainSelect';
import { SingleSelect } from '@/shared/components/SingleSelect';
import {
  AccordionValue,
  BatchDelayTimeUnit,
  CampaignSettings,
} from '@/shared/types/campaigns';
import { Box, Button, Fieldset, Flex, HStack, Input, Label, VStack } from '@/shared/ui';
import { Switch, SwitchThumb } from '@/shared/ui/Switch';

import { CampaignErrorBoundaryFallback } from '../analytics/overview/CampaignErrorBoundaryFallback';
import { Description } from '.';
import { CampaignAccordion } from './CampaignAccordion';
import { SelectSchedule } from './SelectSchedule';

type SchedulePropsWhenPickerShown = {
  /* is campaign scheduled? */
  isScheduled: boolean;
  /* set is campaign scheduled */
  setIsScheduled?: (value: boolean) => void;
  /* scheduled date */
  date: string;
  /* set scheduled date */
  setDate?: (value: string) => void;
  /* scheduled time */
  time: string;
  /* set scheduled time */
  setTime?: (value: string) => void;
  /* scheduled timezone */
  time_zone: string;
  /* set scheduled timezone */
  setTimezone?: (value: string) => void;
  /* is the campaign schedule valid? */
  isValidSchedule: boolean;
};

type SchedulePropsWhenPickerNotShown = Partial<SchedulePropsWhenPickerShown>;

type BaseProps = {
  /* current accordion value */
  accordion: AccordionValue;
  /* set current accordion value */
  setAccordion: Dispatch<SetStateAction<AccordionValue>>;
  /* show the schedule picker? default is true */
  showSchedulePicker?: boolean;
  /* handle save step */
  handleSave?: (accordion: AccordionValue) => void;
  /* enable batch send */
  enableBatchSend: boolean;
  /* set enable batch send */
  setEnableBatchSend?: (value: boolean) => void;
  /* number of messages to send in each batch */
  batchSize: number | null;
  /* set batch size */
  setBatchSize?: (value: number | null) => void;
  /* batch delay - in seconds */
  batchDelay: number | null;
  /* set batch delay */
  setBatchDelay?: (value: number | null) => void;
  /* batch sending delay time unit, default is minutes */
  batchDelayTimeUnit: BatchDelayTimeUnit;
  /* set time unit for batch sending delay */
  setBatchDelayTimeUnit?: (value: BatchDelayTimeUnit) => void;
  /* show the campaign settings */
  enableCampaignSettings: boolean;
  /* set enable campaign settings */
  setEnableCampaignSettings?: (value: boolean) => void;
  /* campaign settings */
  campaignSettings: CampaignSettings;
  /* set campaign settings */
  setCampaignSettings?: (value: CampaignSettings) => void;
  /* show the campaign link tracking settings */
  enableLinkTrackingSettings: boolean;
  /* set enable campaign link tracking settings */
  setEnableLinkTrackingSettings?: (value: boolean) => void;
  /* show Save button or not */
  showSaveButton?: boolean;
  /* disable edit */
  disableEdit?: boolean;
  /* hide index and green box */
  hideIndex?: boolean;
};

type CampaignScheduleProps = BaseProps &
  (BaseProps['showSchedulePicker'] extends true
    ? SchedulePropsWhenPickerShown
    : SchedulePropsWhenPickerNotShown);

export const CampaignSchedule = (props: CampaignScheduleProps): JSX.Element => {
  const {
    accordion,
    setAccordion,
    isScheduled,
    setIsScheduled,
    date,
    setDate,
    time,
    setTime,
    time_zone,
    setTimezone,
    isValidSchedule,
    handleSave,
    enableBatchSend,
    setEnableBatchSend,
    batchSize,
    setBatchSize,
    batchDelay,
    setBatchDelay,
    batchDelayTimeUnit,
    setBatchDelayTimeUnit,
    enableCampaignSettings,
    setEnableCampaignSettings,
    campaignSettings,
    setCampaignSettings,
    enableLinkTrackingSettings,
    setEnableLinkTrackingSettings,
    showSchedulePicker = true,
    showSaveButton = true,
    disableEdit = false,
    hideIndex,
  } = props;

  const [domainId, setDomainId] = useState('');

  // when handleToggleCampaignSettings is called, we want to reset the campaign settings to have enabled = false
  const handleToggleCampaignSettings = () => {
    if (setEnableCampaignSettings && setCampaignSettings) {
      setEnableCampaignSettings(!enableCampaignSettings);
      setCampaignSettings({
        ...campaignSettings,
        support_sms_quiet_hours: {
          enabled: false,
          use_contact_timezone: false,
        },
        skip_sending_on_weekend: {
          enabled: false,
          use_contact_timezone: false,
        },
        only_send_during_business_hours: {
          enabled: false,
          use_contact_timezone: false,
        },
      });
    }
  };

  const handleToggleLinkTrackingSettings = () => {
    if (setEnableLinkTrackingSettings && setCampaignSettings) {
      setEnableLinkTrackingSettings(!enableLinkTrackingSettings);
      setDomainId('');
      setCampaignSettings({
        ...campaignSettings,
        link_tracking: {
          disabled: false,
          domain_id: null,
        },
      });
    }
  };

  const handleChangeDomain = (newDomainId: string) => {
    setDomainId(newDomainId);
    setCampaignSettings &&
      setCampaignSettings({
        ...campaignSettings,
        link_tracking: {
          disabled: !!campaignSettings?.link_tracking?.disabled,
          domain_id: newDomainId || null,
        },
      });
  };

  const handleChangeBatchDelayTimeUnit = (value: string) => {
    if (!value) return;
    setBatchDelayTimeUnit?.(value as BatchDelayTimeUnit);
  };

  useEffect(() => {
    if (batchDelay && batchDelay > 1 && batchDelayTimeUnit === BatchDelayTimeUnit.DAYS) {
      setBatchDelay?.(1);
    }
  }, [batchDelayTimeUnit, batchDelay]);

  return (
    <CampaignAccordion
      // if this component used under GlobalTemplatePreview, showSaveButton === false, then the index should be 3
      index={!showSaveButton ? 3 : 4}
      title="Schedule & Settings"
      description="Schedule your campaign to be sent at a specific time"
      currentAccordionValue={accordion}
      itemValue={AccordionValue.SELECT_SCHEDULE}
      setItemValue={setAccordion}
      isValid={
        (isScheduled && isValidSchedule && time_zone !== '') ||
        (enableBatchSend && batchSize !== null && batchDelay !== null) ||
        enableCampaignSettings
      }
      buttonCopy={!showSaveButton ? 'Expand' : disableEdit ? 'View' : 'Edit'}
      hideIndex={hideIndex}
    >
      <ErrorBoundary
        fallback={<CampaignErrorBoundaryFallback />}
        beforeCapture={(scope) => {
          scope.setTag('Campaign', 'CampaignErrorBoundary.ViewCampaignAudience');
        }}
        showDialog={false}
      >
        <VStack gap="2" data-testid="campaign-schedule-content">
          {showSchedulePicker && (
            <Fieldset data-testid="schedule-message-field">
              <Flex align="center" justify="between">
                <Flex direction="column">
                  <Label>Schedule Message (optional)</Label>
                  <Description>
                    Scheduled messages will be sent at the time and in the timezone you
                    specify.
                  </Description>
                </Flex>
                <Box css={{ mt: 5 }}>
                  {/* if disableEdit is true, then we want to disable the switch */}
                  <Switch
                    disabled={disableEdit}
                    checked={isScheduled}
                    onCheckedChange={() => setIsScheduled?.(!props.isScheduled)}
                  >
                    <SwitchThumb />
                  </Switch>
                </Box>
              </Flex>
              {isScheduled && (
                <Box
                  css={{
                    mt: 10,
                    p: 16,
                    backgroundColor: '#F6F6F6',
                    borderRadius: 4,
                  }}
                >
                  <Box css={{ p: 0, m: 0 }}>
                    <SelectSchedule
                      isScheduled={isScheduled && !disableEdit}
                      setIsScheduled={setIsScheduled}
                      date={date as string}
                      setDate={setDate as (value: string) => void}
                      time={time as string}
                      setTime={setTime as (value: string) => void}
                      timezone={time_zone as string}
                      setTimezone={setTimezone as (value: string) => void}
                    />
                  </Box>
                </Box>
              )}
            </Fieldset>
          )}
          <Fieldset data-testid="batch-settings-field">
            <Flex align="center" justify="between">
              <Flex direction="column">
                <Label>Send Messages in Batches (optional)</Label>
                <Description>
                  Send messages in batches and to prevent over sending. Pause the campaign
                  when you need to.
                </Description>
              </Flex>
              <Box css={{ mt: 5 }}>
                {/* if disableEdit is true, then we want to disable the switch */}
                {!disableEdit && setEnableBatchSend ? (
                  <Switch
                    checked={enableBatchSend}
                    onCheckedChange={() => setEnableBatchSend(!enableBatchSend)}
                  >
                    <SwitchThumb />
                  </Switch>
                ) : (
                  <Switch checked={enableBatchSend}>
                    <SwitchThumb />
                  </Switch>
                )}
              </Box>
            </Flex>
            {enableBatchSend && (
              <Box
                css={{
                  mt: 10,
                  p: 16,
                  backgroundColor: '#F6F6F6',
                  borderRadius: 4,
                }}
              >
                <HStack gap={2}>
                  <Box css={{ fontSize: 13 }}>Send</Box>
                  <Input
                    disabled={!setBatchSize}
                    type="number"
                    min={1}
                    value={batchSize as number}
                    onChange={(e) =>
                      setBatchSize &&
                      setBatchSize(
                        Number(e.target.value) > 0 ? Number(e.target.value) : null
                      )
                    }
                    css={{ textAlign: 'center', width: 50 }}
                    data-testid="batch-size-input"
                  />
                  <Box css={{ fontSize: 13 }}>messages every</Box>
                  <Input
                    disabled={
                      !setBatchDelay || batchDelayTimeUnit === BatchDelayTimeUnit.DAYS
                    }
                    type="number"
                    min={1}
                    value={batchDelay as number}
                    onChange={(e) =>
                      setBatchDelay &&
                      setBatchDelay(
                        Number(e.target.value) > 0 ? Number(e.target.value) : null
                      )
                    }
                    css={{ textAlign: 'center', width: 50 }}
                    data-testid="batch-delay-input"
                  />
                  <Box css={{ fontSize: 13 }}>
                    <SingleSelect
                      disabled={!setBatchDelayTimeUnit}
                      defaultPlaceholder={
                        batchDelayTimeUnit === BatchDelayTimeUnit.DAYS
                          ? 'day'
                          : batchDelayTimeUnit
                      }
                      selectItem={batchDelayTimeUnit}
                      setSelectItem={handleChangeBatchDelayTimeUnit}
                      options={Object.values(BatchDelayTimeUnit).map(
                        (item: BatchDelayTimeUnit) =>
                          item === BatchDelayTimeUnit.DAYS
                            ? {
                                type: 'day',
                                value: item,
                              }
                            : {
                                type: item,
                                value: item,
                              }
                      )}
                      isDropdown
                    />
                  </Box>
                </HStack>
              </Box>
            )}
          </Fieldset>
          <Fieldset data-testid="campaign-settings-field">
            <Flex align="center" justify="between">
              <Flex direction="column">
                <Label>Campaign Settings (optional)</Label>
                <Description>
                  Control when your campaign is sending messages to comply with
                  regulations.
                </Description>
              </Flex>
              <Box css={{ mt: 5 }}>
                {/* if disableEdit is true, then we want to disable the switch */}
                {disableEdit ? (
                  <Switch checked={enableCampaignSettings}>
                    <SwitchThumb />
                  </Switch>
                ) : (
                  <Switch
                    checked={enableCampaignSettings}
                    onCheckedChange={handleToggleCampaignSettings}
                  >
                    <SwitchThumb />
                  </Switch>
                )}
              </Box>
            </Flex>
            {enableCampaignSettings && (
              <Box
                css={{
                  mt: 10,
                  p: 16,
                  backgroundColor: '#F6F6F6',
                  borderRadius: 4,
                }}
              >
                <VStack gap={3}>
                  <Flex align="center" justify="between">
                    <Box css={{ fontSize: 13 }}>
                      Pause sending during SMS Quiet Hours (Before 8 a.m. & after 9 p.m.)
                    </Box>
                    {/* if disableEdit is true, then we want to disable the switch */}
                    {!disableEdit && setCampaignSettings ? (
                      <Switch
                        checked={
                          campaignSettings?.support_sms_quiet_hours.enabled || false
                        }
                        onCheckedChange={() => {
                          setCampaignSettings({
                            ...campaignSettings,
                            support_sms_quiet_hours: {
                              enabled: !campaignSettings?.support_sms_quiet_hours.enabled,
                              use_contact_timezone: false,
                            },
                          });
                        }}
                        data-testid="sms_quiet_hours-settings"
                      >
                        <SwitchThumb />
                      </Switch>
                    ) : (
                      <Switch
                        checked={
                          campaignSettings?.support_sms_quiet_hours.enabled || false
                        }
                      >
                        <SwitchThumb />
                      </Switch>
                    )}
                  </Flex>
                  <Flex align="center" justify="between">
                    <Box css={{ fontSize: 13 }}>Pause Sending on Weekends</Box>
                    {!disableEdit && setCampaignSettings ? (
                      <Switch
                        checked={
                          campaignSettings.skip_sending_on_weekend.enabled || false
                        }
                        onCheckedChange={() => {
                          setCampaignSettings({
                            ...campaignSettings,
                            skip_sending_on_weekend: {
                              enabled: !campaignSettings.skip_sending_on_weekend.enabled,
                              use_contact_timezone: false,
                            },
                          });
                        }}
                        data-testid="skip_sending_on_weekend-settings"
                      >
                        <SwitchThumb />
                      </Switch>
                    ) : (
                      <Switch
                        checked={
                          campaignSettings.skip_sending_on_weekend.enabled || false
                        }
                      >
                        <SwitchThumb />
                      </Switch>
                    )}
                  </Flex>
                  <Flex align="center" justify="between">
                    <Box css={{ fontSize: 13 }}>
                      Pause Sending outside of Business Hours
                    </Box>
                    {!disableEdit && setCampaignSettings ? (
                      <Switch
                        checked={
                          campaignSettings.only_send_during_business_hours.enabled ||
                          false
                        }
                        onCheckedChange={() => {
                          setCampaignSettings({
                            ...campaignSettings,
                            only_send_during_business_hours: {
                              enabled:
                                !campaignSettings.only_send_during_business_hours.enabled,
                              use_contact_timezone: false,
                            },
                          });
                        }}
                        data-testid="only_business_hours-settings"
                      >
                        <SwitchThumb />
                      </Switch>
                    ) : (
                      <Switch
                        checked={
                          campaignSettings.only_send_during_business_hours.enabled ||
                          false
                        }
                      >
                        <SwitchThumb />
                      </Switch>
                    )}
                  </Flex>
                </VStack>
              </Box>
            )}
          </Fieldset>
          <Fieldset data-testid="campaign-settings-field">
            <Flex align="center" justify="between">
              <Flex direction="column">
                <Label>Campaign Link Tracking Settings (optional)</Label>
                <Description>
                  You can disable link tracking for this campaign only. Links will no
                  longer be shortened by Whippy. Using long links or third-party URL
                  shorteners increase the risk that your messages will be flagged for SPAM
                </Description>
              </Flex>
              <Box css={{ mt: 5 }}>
                {/* if disableEdit is true, then we want to disable the switch */}
                {disableEdit ? (
                  <Switch checked={enableLinkTrackingSettings}>
                    <SwitchThumb />
                  </Switch>
                ) : (
                  <Switch
                    checked={enableLinkTrackingSettings}
                    onCheckedChange={handleToggleLinkTrackingSettings}
                  >
                    <SwitchThumb />
                  </Switch>
                )}
              </Box>
            </Flex>
            {enableLinkTrackingSettings && (
              <Box
                css={{
                  mt: 10,
                  p: 16,
                  backgroundColor: '#F6F6F6',
                  borderRadius: 4,
                }}
              >
                <VStack gap={3}>
                  <Flex align="center" justify="between">
                    <Box css={{ fontSize: 13, fontWeight: '500' }}>Disable</Box>
                    {/* if disableEdit is true, then we want to disable the switch */}
                    {!disableEdit && setCampaignSettings ? (
                      <Switch
                        checked={campaignSettings?.link_tracking?.disabled || false}
                        onCheckedChange={() => {
                          setCampaignSettings({
                            ...campaignSettings,
                            link_tracking: {
                              ...campaignSettings?.link_tracking,
                              disabled: !campaignSettings?.link_tracking?.disabled,
                            },
                          });
                        }}
                        data-testid="campaign-link-tracking-settings-disabled-switch"
                      >
                        <SwitchThumb />
                      </Switch>
                    ) : (
                      <Switch
                        checked={campaignSettings?.link_tracking?.disabled || false}
                      >
                        <SwitchThumb />
                      </Switch>
                    )}
                  </Flex>
                  <DomainSelect
                    value={domainId || campaignSettings?.link_tracking?.domain_id || ''}
                    onChange={handleChangeDomain}
                    disabled={disableEdit}
                  />
                </VStack>
              </Box>
            )}
          </Fieldset>
          <HStack>
            {showSaveButton && !!handleSave && (
              <Button onClick={() => handleSave(AccordionValue.CREATE_AUTOMATION)}>
                Save
              </Button>
            )}
            {!disableEdit && (
              <Button
                variant="gray"
                ghost={true}
                onClick={() => setAccordion(AccordionValue.CREATE_AUTOMATION)}
              >
                Cancel
              </Button>
            )}
          </HStack>
        </VStack>
      </ErrorBoundary>
    </CampaignAccordion>
  );
};
