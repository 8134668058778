import dayjs from 'dayjs';
import React from 'react';
import { CSVLink } from 'react-csv';
import { HiChevronRight, HiDotsHorizontal } from 'react-icons/hi';
import { useHistory, useLocation } from 'react-router-dom';

import { useCampaignsContext } from '@/campaigns/context/CampaignsContext';
import { ConfirmationDialog } from '@/shared/components/ConfirmationDialog';
import { Campaign, CampaignStatus } from '@/shared/types/campaigns';
import {
  Box,
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  DropdownSubMenu,
  DropdownSubMenuContent,
  DropdownSubTrigger,
  Flex,
  IconButton,
  Tooltip,
  TooltipTrigger,
} from '@/shared/ui';

type CampaignNavigationButtonProps = {
  campaign: Campaign;
  onEditClick: () => void;
  onArchiveClick: () => void;
  onShowClick: () => void;
};

const CampaignNavigationButton = (props: CampaignNavigationButtonProps): JSX.Element => {
  const campaigns = useCampaignsContext();
  const { deleteOneCampaign, duplicateOneCampaign } = campaigns;
  const { id, status, title, body, analytics, inserted_at } = props.campaign;
  const { onEditClick, onArchiveClick, onShowClick } = props;
  const { pathname } = useLocation();

  const history = useHistory();

  const onDuplicateClick = async (include?: boolean) => {
    if (id) {
      const data = await duplicateOneCampaign(id, include);
      data &&
        history.push(pathname.includes('email') ? '/campaigns/email' : '/campaigns');
    }
  };

  const handleClose = () => {
    if (id) {
      deleteOneCampaign(id);
    }
  };

  return (
    <Box>
      <Flex justify="end">
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <IconButton aria-label="open dropdown" variant="outline" size="2">
              <HiDotsHorizontal size="15px" />
            </IconButton>
          </DropdownMenuTrigger>
          <DropdownMenuContent sideOffset={10}>
            {status === CampaignStatus.DRAFT && (
              <ConfirmationDialog
                title="Delete Campaign"
                description="Are you sure you want to delete this campaign?"
                onConfirm={handleClose}
                confirmButtonTitle="Yes, Delete Campaign"
                testID="delete-campaign-button"
              >
                <DropdownMenuItem onClick={(e) => e.preventDefault()}>
                  Delete Draft
                </DropdownMenuItem>
              </ConfirmationDialog>
            )}
            {status === CampaignStatus.COMPLETE && (
              <CSVLink
                filename={`${title}.csv`}
                data={[
                  {
                    title,
                    message: body,
                    date: dayjs(inserted_at).format('MMM DD, YYYY'),
                    ...analytics,
                  },
                ]}
              >
                <Tooltip>
                  <TooltipTrigger asChild>
                    <DropdownMenuItem>Export Campaign to CSV</DropdownMenuItem>
                  </TooltipTrigger>
                </Tooltip>
              </CSVLink>
            )}
            <DropdownSubMenu>
              <DropdownSubTrigger>
                <Flex justify="between" align="center" css={{ width: '100%' }}>
                  <Box>Duplicate Campaign</Box>
                  <Box>
                    <HiChevronRight />
                  </Box>
                </Flex>
              </DropdownSubTrigger>
              <DropdownSubMenuContent>
                <DropdownMenuItem onClick={() => onDuplicateClick(false)}>
                  Duplicate Message
                </DropdownMenuItem>
                <DropdownMenuItem onClick={() => onDuplicateClick(true)}>
                  Duplicate Message & Audience
                </DropdownMenuItem>
              </DropdownSubMenuContent>
            </DropdownSubMenu>
            {(status === CampaignStatus.COMPLETE || status === CampaignStatus.FAILED) && (
              <DropdownMenuItem onClick={onArchiveClick}>
                Archive Campaign
              </DropdownMenuItem>
            )}
          </DropdownMenuContent>
        </DropdownMenu>
        <Box css={{ width: 120, alignItems: 'end', ml: 5 }}>
          {!(
            status === CampaignStatus.COMPLETE ||
            status === CampaignStatus.IN_PROGRESS ||
            status == CampaignStatus.PAUSED
          ) ? (
            status === CampaignStatus.SCHEDULED ? (
              <Button variant="gray" css={{ width: '100%' }}>
                Edit Draft
              </Button>
            ) : (
              <Button variant="gray" onClick={onEditClick} css={{ width: '100%' }}>
                Edit Draft
              </Button>
            )
          ) : (
            <Button variant="gray" onClick={onShowClick} css={{ width: '100%' }}>
              View Results
            </Button>
          )}
        </Box>
      </Flex>
    </Box>
  );
};

export default CampaignNavigationButton;
