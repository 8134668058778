import { Link } from '@/shared/types/links';
import { removeDuplicates } from '@/shared/utils/removeDuplicates';

import { LinksActions, LinksActionTypes } from './types';

export const LinksReducer = (state: any, action: LinksActions) => {
  switch (action.type) {
    case LinksActionTypes.GET_LINKS:
      return {
        ...state,
        links:
          action.payload.offset === 0
            ? action.payload.links
            : removeDuplicates([...state.links, ...action.payload.links]),
        loading: false,
        totalCount: action.payload.total,
      };
    case LinksActionTypes.GET_LINK:
      return {
        ...state,
        current: action.payload,
      };
    case LinksActionTypes.DELETE_LINK:
      return {
        ...state,
        links: state.links.filter((link: Link) => {
          return link.id !== action.payload;
        }),
        totalCount: state.totalCount - 1,
      };
    case LinksActionTypes.CREATE_LINK:
      return {
        ...state,
        links: [action.payload, ...state.links],
        current: action.payload,
        error: null,
      };
    case LinksActionTypes.UPDATE_LINK:
      return {
        ...state,
        current: action.payload,
        links: state.links.map((link: Link) =>
          link.id === action.payload.id ? action.payload : link
        ),
        error: null,
      };
    case LinksActionTypes.SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case LinksActionTypes.SET_CURRENT:
      return {
        ...state,
        current: action.payload,
      };
    case LinksActionTypes.GET_CURRENT_LINK_ANALYTICS:
      return {
        ...state,
        currentLinkAnalytics: action.payload,
      };
    case LinksActionTypes.SET_CURRENT_LINK_ANALYTICS:
      return {
        ...state,
        currentLinkAnalytics: action.payload,
      };
    case LinksActionTypes.SET_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};
