/* eslint-disable react-hooks/exhaustive-deps */
import { debounce } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { HiTrash } from 'react-icons/hi';
import { useHistory } from 'react-router-dom';

import { ConfirmationDialog } from '@/shared/components/ConfirmationDialog';
import { Table } from '@/shared/components/Table';
import { Keyword } from '@/shared/types/keywords';
import {
  Box,
  Button,
  Flex,
  HStack,
  IconButton,
  Skeleton,
  Switch,
  SwitchThumb,
  Td,
  Tr,
  VStack,
} from '@/shared/ui';
import { styled } from '@/stitches.config';

import { useKeywords } from './context/KeywordsContext';
import { KeywordForm } from './CreateKeywordForm';
import { KeywordDialog } from './KeywordDialog';

export const KeywordsTable = () => {
  const keywordsContext = useKeywords();
  const {
    keywordsState,
    updateKeyword,
    getKeywords,
    deleteKeyword,
    searchKeywords,
    setLoading,
  } = keywordsContext;
  const history = useHistory();

  const { loading, keywords } = keywordsState;
  // limit is the max number of keywords to fetch
  const limit = 50;
  // offset is the number of keywords to skip
  const [offset, setOffset] = useState(0);
  const [searchValue, setSearchValue] = useState('');

  // get all keywords on mount
  useEffect(() => {
    getKeywords({ offset, limit });
    // state.keywords is now populated with non-duplicate keywords
  }, [offset]);

  // debounce search and wrap in useCallback
  // need to use useCallback to memoize the function so that it doesn't change on every render
  const debounceFn = React.useCallback(
    debounce((value) => {
      searchKeywords(value);
    }, 250),
    []
  );

  const handleSearch = (value: string) => {
    setLoading(true); // immediately set loading to true when there's any change in the search input
    setSearchValue(value);
    debounceFn(value); // call debounceFn() to search for keywords. debounceFn() will hold until 250ms inactivity
  };

  // filter keywords based on search value, if the search value is '' (empty string), then return all keywords
  // lower case the keywords array and negates array to make the search case insensitive
  const filteredKeywords =
    searchValue === ''
      ? keywords
      : keywords.filter(
          (keyword) =>
            (keyword.keywords &&
              keyword.keywords.some((kw) =>
                kw.toLowerCase().includes(searchValue.toLowerCase())
              )) ||
            keyword?.negate_keywords?.some((kw) =>
              kw.toLowerCase().includes(searchValue.toLowerCase())
            )
        );

  // when we call searchKeywords(), the keywords state is updated with the newly added keywords
  // then, searchedData (to display) is updated with the new keywords, too
  const data = useMemo(
    () => (loading ? Array(5).fill({}) : filteredKeywords),
    [loading, filteredKeywords]
  );

  const isEnabled = (props: { row: { original: Keyword } }) => {
    const rowStatus = props.row.original.enabled;

    if (rowStatus) {
      return true;
    } else {
      return false;
    }
  };

  const toggleKeyword = (props: { row: { original: Keyword } }) => {
    const id = props.row.original.id;
    const enabled = props.row.original.enabled;

    if (!id) {
      return;
    }

    if (enabled) {
      updateKeyword(id, { enabled: false });
    } else {
      updateKeyword(id, { enabled: true });
    }
  };

  const handleDelete = (props: { row: { original: Keyword } }) => {
    const id = props.row.original.id;

    if (!id) {
      return;
    }

    deleteKeyword(id);
  };

  const columns = useMemo(
    () =>
      loading
        ? [
            {
              Header: 'Keywords',
              colWidth: '100%',
              Cell: (
                <>
                  <Skeleton variant="subheading" css={{ maxWidth: '50%', m: 0 }} />
                  <Skeleton variant="subheading" css={{ maxWidth: '80%', mb: 0 }} />
                </>
              ),
            },
            {
              Header: (): null => null,
              disableSortBy: true,
              id: 'expander',
              Cell: <Skeleton variant="icon" />,
            },
          ]
        : [
            {
              Header: 'Keywords',
              colWidth: '100%',
              Cell: (props: { row: { original: Keyword } }) => (
                <Flex direction="column">
                  <KeywordTitle>
                    {props.row.original?.keywords &&
                    props.row.original?.keywords.length > 0
                      ? props.row.original?.keywords[0]
                      : ''}
                  </KeywordTitle>
                </Flex>
              ),
            },
            {
              Header: 'Enabled',
              disableSortBy: true,
              id: 'status',
              Cell: (props: { row: { original: Keyword } }) => (
                <Switch checked={isEnabled(props)} onClick={() => toggleKeyword(props)}>
                  <SwitchThumb />
                </Switch>
              ),
            },
            {
              Header: (): null => null,
              disableSortBy: true,
              id: 'expander',
              Cell: (props: { row: { original: Keyword } }) => (
                <Flex css={{ width: 150 }}>
                  <HStack>
                    <ConfirmationDialog
                      title="Delete Keyword"
                      description="Are you sure you want to delete this keyword?"
                      confirmButtonTitle="Yes, Delete Keyword"
                      onConfirm={() => handleDelete(props)}
                    >
                      <IconButton size={2} variant="outline">
                        <HiTrash />
                      </IconButton>
                    </ConfirmationDialog>
                    <Button
                      variant="gray"
                      onClick={() =>
                        history.push(`/automations/keywords/${props.row.original.id}`)
                      }
                    >
                      Edit Keyword
                    </Button>
                  </HStack>
                </Flex>
              ),
            },
          ],
    [loading, filteredKeywords]
  );

  return (
    <Table
      data={data}
      columns={columns}
      sortable={true}
      caption="Keywords Table"
      searchFilter={true}
      searchValue={searchValue}
      setSearchValue={handleSearch}
      limit={limit}
      offset={offset}
      setOffset={setOffset}
      totalCount={keywordsState.totalCount}
      empty={
        <Tr css={{ backgroundColor: 'white' }}>
          <Td colSpan={columns.length} css={{ textAlign: 'center' }}>
            <Flex css={{ width: '100%' }} direction="column">
              <VStack gap={3}>
                <Box>
                  Create your first keyword. Trigger automations from keywords or phrases.
                </Box>
                <Box>
                  <KeywordDialog trigger={<Button>Create Keyword</Button>}>
                    <KeywordForm />
                  </KeywordDialog>
                </Box>
              </VStack>
            </Flex>
          </Td>
        </Tr>
      }
    />
  );
};

const KeywordTitle = styled(Box, {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  maxWidth: 250,
  textOverflow: 'ellipsis',
});
