/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';

import { ActionDescription } from '@/pages/automation/ActionDescription';
import { ActionRenderer } from '@/shared/components/triggers';
import {
  getActionColor,
  getActionIcon,
  getActionTitle,
} from '@/shared/components/triggers/actions/ActionWrapper';
import { ReplyTriggerType } from '@/shared/components/triggers/AddTrigger';
import { Label, VStack } from '@/shared/ui';

import { PreviewActionWrapper } from './PreviewActionWrapper';

type ReplyAutomationsPreviewProps = {
  /* array of reply triggers */
  reply_triggers: Array<ReplyTriggerType>;
};

export const ReplyAutomationsPreview = (props: ReplyAutomationsPreviewProps) => {
  const { reply_triggers } = props;
  // render each trigger in this template
  return reply_triggers.map((reply_trigger: any, i: number) => (
    <ReplyTriggerWrapper key={i} reply_trigger={reply_trigger} />
  ));
};

export const ReplyTriggerWrapper = (props: any) => {
  const { reply_trigger } = props;
  return (
    <VStack gap={2} style={{ paddingTop: '10px' }}>
      <Label>When a contact response with any message then:</Label>
      {reply_trigger.actions.map((action: any, index: number) => (
        <PreviewActionWrapper
          key={index}
          title={`${getActionTitle(action)}` || ''}
          description={ActionDescription(action)}
          actionColor={`${getActionColor(action)}` || '#2C7675'}
          actionIcon={getActionIcon(action) || ''}
          action={action}
        >
          <ActionRenderer key={index} index={index} action={action} />
        </PreviewActionWrapper>
      ))}
    </VStack>
  );
};
