import React, { MutableRefObject, useEffect, useMemo, useRef, useState } from 'react';

import { useResizeObserver } from '@/shared/hooks/useResizeObserver';
import { Badge, HStack } from '@/shared/ui';
import { styled } from '@/stitches.config';

// generate description from keywords
export const KeywordDescription = ({ keywords = [] }: { keywords?: string[] }) => {
  const keywordsRow = useRef<HTMLDivElement>(null);
  const itemsRef = useRef<HTMLSpanElement[]>([]);
  const [keywordsList, setKeywordsList] = useState(keywords);
  const [maxWidth, setMaxWidth] = useState(500);

  useResizeObserver(
    (data: ResizeObserverEntry[]) => setMaxWidth(data[0].contentRect.width),
    keywordsRow as MutableRefObject<HTMLDivElement>
  );

  useEffect(() => {
    const containerWidth = maxWidth - 40;
    if (itemsRef?.current?.length === keywords?.length) {
      const kList = keywords?.reduce((list: string[], item: string, index: number) => {
        const newWidth = list?.reduce((acc, el, i) => {
          return acc + (itemsRef?.current?.[i] as HTMLSpanElement)?.offsetWidth + 5;
        }, 0);
        if (
          itemsRef?.current?.[index] &&
          (itemsRef?.current?.[index]?.offsetWidth + newWidth < containerWidth ||
            keywords.length === 1)
        ) {
          list.push(item);
        }
        return list;
      }, []);
      setKeywordsList(kList);
    }
  }, [keywords, itemsRef?.current, maxWidth]);

  const listDiff = useMemo(() => {
    return keywords?.length - (keywordsList?.length || 0);
  }, [keywordsList, keywords]);

  if (keywords && keywords.length > 0) {
    return (
      <RowContainer align="center" ref={keywordsRow}>
        <HiddenRow align="center">
          {keywords.map((keyword, i) => (
            <Badge
              key={i}
              size="2"
              ref={(el: HTMLSpanElement) => (itemsRef.current[i] = el)}
            >
              {keyword}
            </Badge>
          ))}
        </HiddenRow>
        <HStack align="center">
          {keywordsList?.map((keyword, i) => (
            <Badge
              key={i}
              size="2"
              css={{
                maxWidth,
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                justifyContent: 'flex-start',
                display: keywordsList?.length === 1 ? 'inline-block' : 'inline-flex',
                py: keywordsList?.length === 1 ? '$1' : 0,
              }}
            >
              {keyword}
            </Badge>
          ))}
        </HStack>
        {listDiff > 0 && <Badge size="2">{`+${listDiff}`}</Badge>}
      </RowContainer>
    );
  }
  return null;
};

const RowContainer = styled(HStack, {
  width: '100%',
  position: 'relative',
  overflow: 'hidden',
});

const HiddenRow = styled(HStack, {
  opacity: 0,
  position: 'absolute',
  pointerEvents: 'none',
});
