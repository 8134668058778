import { Campaign } from '@/shared/types/campaigns';

/*
 * This will determine what the channel(s) or location(s) of a campaign is by checking
 * if the channel is determined by the audience field or the channel_id.
 * If the campaign has no associated channel then we return empty array
 */
export function getChannelIds(campaign: Campaign): string[] {
  if (campaign.audience) {
    if (campaign.audience.location) return [campaign.audience.location];
    if (campaign.audience.location_ids) return campaign.audience.location_ids;
  }
  return campaign.channel_id ? [campaign.channel_id] : [];
}

export function hasChannels(campaign: Campaign | null): boolean {
  if (!campaign) return false;
  if (campaign.audience) {
    if (campaign.audience.location) return true;
    if (campaign.audience.location_ids) return true;
  }
  return campaign.channel_id ? true : false;
}
