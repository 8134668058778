export const stateList = [
  { value: 'AK', type: 'AK' },
  { value: 'AL', type: 'AL' },
  { value: 'AR', type: 'AR' },
  { value: 'AZ', type: 'AZ' },
  { value: 'CA', type: 'CA' },
  { value: 'CO', type: 'CO' },
  { value: 'CT', type: 'CT' },
  { value: 'DC', type: 'DC' },
  { value: 'DE', type: 'DE' },
  { value: 'FL', type: 'FL' },
  { value: 'GA', type: 'GA' },
  { value: 'HI', type: 'HI' },
  { value: 'IA', type: 'IA' },
  { value: 'ID', type: 'ID' },
  { value: 'IL', type: 'IL' },
  { value: 'IN', type: 'IN' },
  { value: 'KS', type: 'KS' },
  { value: 'KY', type: 'KY' },
  { value: 'LA', type: 'LA' },
  { value: 'MA', type: 'MA' },
  { value: 'MD', type: 'MD' },
  { value: 'ME', type: 'ME' },
  { value: 'MI', type: 'MI' },
  { value: 'MN', type: 'MN' },
  { value: 'MO', type: 'MO' },
  { value: 'MS', type: 'MS' },
  { value: 'MT', type: 'MT' },
  { value: 'NC', type: 'NC' },
  { value: 'ND', type: 'ND' },
  { value: 'NE', type: 'NE' },
  { value: 'NH', type: 'NH' },
  { value: 'NJ', type: 'NJ' },
  { value: 'NM', type: 'NM' },
  { value: 'NV', type: 'NV' },
  { value: 'NY', type: 'NY' },
  { value: 'OH', type: 'OH' },
  { value: 'OK', type: 'OK' },
  { value: 'OR', type: 'OR' },
  { value: 'PA', type: 'PA' },
  { value: 'PR', type: 'PR' },
  { value: 'RI', type: 'RI' },
  { value: 'SC', type: 'SC' },
  { value: 'SD', type: 'SD' },
  { value: 'TN', type: 'TN' },
  { value: 'TX', type: 'TX' },
  { value: 'UT', type: 'UT' },
  { value: 'VA', type: 'VA' },
  { value: 'VT', type: 'VT' },
  { value: 'WA', type: 'WA' },
  { value: 'WV', type: 'WV' },
  { value: 'WI', type: 'WI' },
  { value: 'WY', type: 'WY' },
];

export const verticalList = [
  { value: 'AGRICULTURE', type: 'Agriculture' },
  { value: 'COMMUNICATION', type: 'Communication' },
  { value: 'CONSTRUCTION', type: 'Construction' },
  { value: 'EDUCATION', type: 'Education' },
  { value: 'ENERGY', type: 'Energy' },
  { value: 'ENTERTAINMENT', type: 'Entertainment' },
  { value: 'FINANCIAL', type: 'Financial' },
  { value: 'GAMBLING', type: 'Gambling' },
  { value: 'GOVERNMENT', type: 'Government' },
  { value: 'HEALTHCARE', type: 'Healthcare' },
  { value: 'HOSPITALITY', type: 'Hospitality' },
  { value: 'INSURANCE', type: 'Insurance' },
  { value: 'MANUFACTURING', type: 'Manufacturing' },
  { value: 'NGO', type: 'NGO' },
  { value: 'REAL_ESTATE', type: 'Real Estate' },
  { value: 'RETAIL', type: 'Retail' },
  { value: 'TECHNOLOGY', type: 'Technology' },
];

export const businessTypes = [
  { value: 'SOLE_PROPRIETORSHIP', type: 'Sole Proprietorship' },
  { value: 'PARTNERSHIP', type: 'Partnership' },
  { value: 'LIMITED_LIABILITY_CORPORATION', type: 'Limited Liability Corporation' },
  { value: 'CO_OPERATIVE', type: 'Co-operative' },
  { value: 'NON_PROFIT_CORPORATION', type: 'Non-profit Corporation' },
  { value: 'CORPORATION', type: 'Corporation' },
];

export const representativeJobPositions = [
  { value: 'DIRECTOR', type: 'Director' },
  { value: 'GM', type: 'GM' },
  { value: 'VP', type: 'VP' },
  { value: 'CEO', type: 'CEO' },
  { value: 'CFO', type: 'CFO' },
  { value: 'GENERAL_COUNSEL', type: 'General Counsel' },
  { value: 'OTHER', type: 'Other' },
];

export const businessRegistrationTypes = [
  { value: 'EIN', type: 'EIN' },
  { value: 'DUNS', type: 'DUNS' },
  { value: 'LEI', type: 'LEI' },
  { value: 'GIIN', type: 'GIIN' },
];

export const altBusinessRegistrationTypes = [
  { value: 'NONE', type: 'None' },
  { value: 'EIN', type: 'EIN' },
  { value: 'DUNS', type: 'DUNS' },
  { value: 'LEI', type: 'LEI' },
  { value: 'GIIN', type: 'GIIN' },
];

export const businessIdentities = [
  {
    type: 'Reseller',
    value: 'RESELLER',
  },
  {
    type: 'Direct Customer',
    value: 'DIRECT_CUSTOMER',
  },
];

export const businessRegionsOfOperation = [
  {
    type: 'USA & Canada',
    value: 'USA_AND_CANADA',
  },
  {
    type: 'Latin America',
    value: 'LATIN_AMERICA',
  },
  {
    type: 'Europe',
    value: 'EUROPE',
  },
  {
    type: 'Asia',
    value: 'ASIA',
  },
  {
    type: 'Africa',
    value: 'AFRICA',
  },
];
