import dayjs from 'dayjs';
import React, { MouseEvent, useCallback } from 'react';
import { HiChartBar, HiDuplicate } from 'react-icons/hi';
import { useHistory } from 'react-router-dom';
import { useMedia } from 'react-use';
import { toast } from 'sonner';

import { ToolTipIconButton } from '@/shared/components/attachments/previewer';
import { Link } from '@/shared/types/links';
import {
  Box,
  Button,
  Flex,
  HStack,
  IconButton,
  Skeleton,
  Text,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '@/shared/ui';
import { MONTH_DAY_YEAR } from '@/shared/utils/timestamps';
import i18next from '@/shared/utils/translation';
import { styled } from '@/stitches.config';

import { useLinks } from '../context/LinksContext';
import { LinkNavigationButton } from './LinkNavigationButton';

export const LinkCard = ({ link }: { link: Link }) => {
  const { setCurrentLink } = useLinks();
  const history = useHistory();

  const isSmallScreen = !useMedia('(min-width: 1024px)');
  const isMobile = !useMedia('(min-width: 700px)');

  const onEditClick = useCallback(() => {
    // set the current link in the context
    setCurrentLink(link);
    // navigate to the link steps page
    history.push(`/campaigns/links/${link?.id}/link`);
  }, [link]);

  const onViewAnalytics = useCallback(async () => {
    // set the current link in the context
    setCurrentLink(link);

    // navigate to the link steps page
    history.push(`/campaigns/links/${link?.id}/analytics`);
  }, [link]);

  const handleCopyShortLink = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation();
      e.preventDefault();
      navigator.clipboard.writeText(link?.shareable_link?.short_url || '');
      toast.success(i18next.t('link_url_copied') as string);
    },
    [link?.shareable_link?.short_url]
  );

  return (
    <LinkCardContainer
      align={isMobile ? 'start' : 'center'}
      justify="between"
      direction={isMobile ? 'column' : 'row'}
      css={{ py: isMobile ? 10 : 0 }}
    >
      <Flex
        align="start"
        onClick={onEditClick}
        css={{ minWidth: '400px', width: '100%', height: '100%', flex: 1.5 }}
        direction="column"
      >
        <Flex gap={1} align="center">
          <LinkTitle>{link?.shareable_link?.short_url}</LinkTitle>
          <Tooltip>
            <TooltipTrigger asChild>
              <IconButton onClick={handleCopyShortLink}>
                <HiDuplicate size={15} />
              </IconButton>
            </TooltipTrigger>
            <TooltipContent>
              <Box>Copy Short Link</Box>
            </TooltipContent>
          </Tooltip>
        </Flex>
        <LinkDescription>{link?.url}</LinkDescription>
      </Flex>
      <Flex
        justify={isMobile ? 'between' : 'end'}
        css={{ width: '100%', height: '100%', flex: 1, mt: isMobile ? 5 : 0 }}
      >
        <Flex align="center">
          <Box>
            <TextLabel>Created</TextLabel>
            <ColumnValue>{dayjs(link?.inserted_at).format(MONTH_DAY_YEAR)}</ColumnValue>
          </Box>
        </Flex>
        <Flex css={{ pl: 40 }}>
          <HStack gap="2">
            <LinkNavigationButton link={link} />
            {isSmallScreen ? (
              <ToolTipIconButton
                variant="outline"
                size={2}
                icon={<HiChartBar />}
                description="View Analytics"
                onClick={onViewAnalytics}
              />
            ) : (
              <Button
                variant="gray"
                data-testid={`link-${link?.id}-button`}
                onClick={onViewAnalytics}
              >
                View Analytics
              </Button>
            )}
          </HStack>
        </Flex>
      </Flex>
    </LinkCardContainer>
  );
};

export const LinkLoadingCard: React.FC = () => {
  const isSmallScreen = !useMedia('(min-width: 968px)');
  const isMobile = !useMedia('(min-width: 700px)');

  return (
    <LinkCardContainer
      align={isMobile ? 'start' : 'center'}
      justify="between"
      direction={isMobile ? 'column' : 'row'}
      css={{ py: isMobile ? 10 : 0 }}
    >
      <Flex align="center">
        <Box>
          <Skeleton variant="title" css={{ height: 15, margin: '5px 0', width: 200 }} />
        </Box>
      </Flex>
      <Flex
        justify={isMobile ? 'between' : 'end'}
        css={{ width: '100%', height: '100%', mt: isMobile ? 5 : 0 }}
      >
        <Flex align="center">
          <Box>
            <BoldStats>
              <Skeleton css={{ height: 36, width: 36, m: '5px 0 0 0' }} />
            </BoldStats>
          </Box>
        </Flex>
        <Flex css={{ pl: 20 }}>
          <HStack>
            <Box>
              <BoldStats>
                <Skeleton css={{ height: 36, width: 36, m: '5px 0 0 0' }} />
              </BoldStats>
            </Box>
            <Skeleton
              variant="button"
              css={{ height: 36, width: isSmallScreen ? 36 : 122, m: 0, ml: 6 }}
            />
          </HStack>
        </Flex>
      </Flex>
    </LinkCardContainer>
  );
};

// Link Card Styled Components
const LinkCardContainer = styled(Flex, {
  px: '24px',
  backgroundColor: '#FFFFFF',
  minHeight: 74,
  border: '1px solid #E8E8E8',
  borderRadius: 12,
  position: 'relative',
  '&:hover': {
    border: '1px solid #3E54CF',
    filter: 'drop-shadow(0px 4px 32px rgba(62, 84, 207, 0.08))',
    cursor: 'pointer',
  },
});

const LinkTitle = styled(Box, {
  fontSize: 16,
  fontWeight: 500,
  lineHeight: '19px',
  pb: 5,
});

const LinkDescription = styled(Box, {
  color: '#7F7F86',
  fontSize: 14,
  lineHeight: '17px',
});

const BoldStats = styled(Box, {
  fontSize: 16,
  fontWeight: 600,
  lineHeight: '19px',
  pb: 5,
});

const ColumnValue = styled(Box, {
  fontSize: 14,
  fontWeight: 500,
});

const TextLabel = styled(Text, {
  color: '#7F7F86',
  fontSize: 12,
  lineHeight: '15px',
  fontWeight: 500,
});
