import React, { useEffect, useRef, useState } from 'react';

import { fetchOrganizationsInfo } from '@/shared/api/auth';
import { updateWebchat } from '@/shared/api/webchat';
import { CreateCropImage } from '@/shared/components/CreateCropImage';
import { useDisclosure } from '@/shared/hooks';

export type AttachmentType = {
  url: string;
  presigned_url?: string;
};

type Blob = {
  size: number;
  type: string;
  arrayBuffer(): Promise<ArrayBuffer>;
  slice: (start?: number, end?: number, contentType?: string) => Blob;
  stream: any;
  text: any;
};

const WebchatImage = (): JSX.Element => {
  const placeholderImg = 'https://dummyimage.com/300/ddd.jpg';
  const [attachment, setAttachment] = useState<AttachmentType>({
    url: placeholderImg,
    presigned_url: '',
  });
  const [currentAttachment, setCurrent] = useState<AttachmentType>({
    url: '',
    presigned_url: '',
  });
  const [croppedBlob, setCropped] = useState<Blob | null>(null);
  const [timeoutFlag, setTimeoutFlag] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  // get webchat setting and set attachment url
  useEffect(() => {
    async function fetchWebchatSetting() {
      const {
        organization: { widget_settings: webchatSetting },
      } = await fetchOrganizationsInfo();

      if (webchatSetting?.attachment) {
        setAttachment(webchatSetting.attachment);
      }
    }

    fetchWebchatSetting();
  }, []);

  const sleep = (ms: number) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  // overwrites the original s3 attachment with the cropped one
  // updates the widget with the new attachment
  const handleUpdate = () => {
    if (!currentAttachment.presigned_url) return;
    fetch(currentAttachment.presigned_url, {
      method: 'PUT',
      body: croppedBlob as BodyInit,
    })
      .then(async () => {
        setTimeoutFlag(true);
        await sleep(4000).then(() => setTimeoutFlag(false));
        setAttachment(currentAttachment);
        updateWebchat({
          attachments: [{ url: currentAttachment.url, content_type: croppedBlob?.type }],
        });
        onClose();
      })
      .catch((err) => console.error(err));
  };

  /* Attachment Deletion Functionality */
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const onAlertClose = () => setIsAlertOpen(false);
  const cancelRef = useRef();

  const handleDelete = () => {
    updateWebchat({ attachments: [] });
    setAttachment({ url: placeholderImg });
    onAlertClose();
  };

  return (
    <CreateCropImage
      handleDelete={handleDelete}
      handleUpdate={handleUpdate}
      isAlertOpen={isAlertOpen}
      setIsAlertOpen={setIsAlertOpen}
      onAlertClose={onAlertClose}
      cancelRef={cancelRef}
      attachment={attachment}
      currentAttachment={currentAttachment}
      setCurrent={setCurrent}
      onOpen={onOpen}
      onClose={onClose}
      isOpen={isOpen}
      timeoutFlag={timeoutFlag}
      setCropped={setCropped}
    />
  );
};

export default WebchatImage;
