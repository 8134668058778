import { api } from '../api';

type PhoneCapabilities = {
  MMS: boolean;
  SMS: boolean;
  voice: boolean;
};

export type PhoneData = {
  address_requirements: string;
  beta: boolean;
  capabilities: PhoneCapabilities;
  friendly_name: string;
  iso_country: string;
  lata: string | null;
  latitude: string | null;
  locality: string;
  longitude: string | null;
  phone_number: string;
  postal_code: string | null;
  rate_center: string | null;
  region: string;
};

type PhoneResponse = {
  data: PhoneData[];
};

type GetPhoneNumbersParams = {
  area_code?: string;
  in_locality?: boolean;
  toll_free?: boolean;
};

export const getPhoneNumbers = (
  provider_account_id: string,
  params: GetPhoneNumbersParams
) =>
  api
    .post<PhoneResponse>(
      `/v2/providers/accounts/${provider_account_id}/phones/available`,
      { ...params }
    )
    .then((res) => res.data);

export const purchasePhoneNumber = (provider_account_id: string, phone: string) =>
  api
    .post<{ data: PhoneData }>(`/v2/providers/accounts/${provider_account_id}/phones`, {
      provider_phone: {
        setup_type: 'buy',
        phone: phone,
      },
    })
    .then((res) => res.data);
