import React from 'react';
import { HiCog, HiInbox, HiMenu, HiSpeakerphone, HiUsers } from 'react-icons/hi';
import { Link, useLocation, useRouteMatch } from 'react-router-dom';

import { Box } from '@/shared/ui';

import { Text, VStack } from '../../ui';
import { SideNavigationItem } from './SideNavigationItem';

function Navigation(): JSX.Element {
  const location = useLocation();
  const isConversation = useRouteMatch('/inbox/:id([a-z0-9-]{36})')?.isExact;
  const isInbox = useRouteMatch('/inbox')?.isExact;
  const isContacts = location.pathname.startsWith('/contacts');
  const isCampaigns = location.pathname.startsWith('/campaigns');
  const isSequences = location.pathname.startsWith('/sequences');
  const isSettings = location.pathname.startsWith('/settings');

  return (
    <Box css={{ px: 8 }}>
      <VStack gap={1}>
        <Link to="/inbox/all/open">
          <SideNavigationItem active={isInbox || isConversation}>
            <HiInbox />
            <Text>Inbox</Text>
          </SideNavigationItem>
        </Link>
        <Link to="/contacts">
          <SideNavigationItem active={isContacts}>
            <HiUsers />
            <Text>Contacts</Text>
          </SideNavigationItem>
        </Link>
        <Link to="/campaigns">
          <SideNavigationItem active={isCampaigns}>
            <HiSpeakerphone />
            <Text>Campaigns</Text>
          </SideNavigationItem>
        </Link>
        <Link to="/sequences">
          <SideNavigationItem active={isSequences}>
            <HiMenu />
            <Text>Sequences</Text>
          </SideNavigationItem>
        </Link>
        <Link to="/settings/profile" data-intercom-target="settings-navigation">
          <SideNavigationItem active={isSettings}>
            <HiCog />
            <Text>Settings</Text>
          </SideNavigationItem>
        </Link>
      </VStack>
    </Box>
  );
}

export default Navigation;
