import { useState } from 'react';
import { HiOutlineCloudUpload } from 'react-icons/hi';

import { Spinner } from '@/shared/components/Icons';
import { Box, Button, Flex, Image, Label, VStack } from '@/shared/ui';
import { styled } from '@/stitches.config';

type UploadImageProps = {
  onUpload?: (file: File) => void;
  loading?: boolean;
  width?: number;
  height?: number;
  url?: string;
  onRemove?: () => void;
};

export const UploadImage = ({ onUpload, loading, url, onRemove }: UploadImageProps) => {
  const [dragActive, setDragActive] = useState(false);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = event.target.files;
    if (selectedFiles && selectedFiles.length > 0) {
      const newFiles = Array.from(selectedFiles);
      onUpload?.(newFiles[0]);
    }
  };
  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event?.preventDefault();
    const droppedFiles = event?.dataTransfer?.files;
    if (droppedFiles.length > 0) {
      const newFiles = Array.from(droppedFiles);
      onUpload?.(newFiles[0]);
    }
    setDragActive(false);
  };

  const handleDrag = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  return url && !loading ? (
    <ImageContainer align="center">
      <Image
        css={{
          maxHeight: 148,
          maxWidth: 'calc(100% - 155px)',
          borderRadius: 4,
          border: '1px solid #D7DBDF',
        }}
        src={url}
      />
      <VStack gap={1} css={{ ml: 15 }}>
        <Button as={Label} htmlFor="input" variant="gray" css={{ mb: 1 }}>
          Upload Image
          <input
            id="input"
            type="file"
            accept=".jpg, .jpeg, .png"
            hidden
            onChange={handleFileChange}
            data-testid="upload-image-button"
          />
        </Button>
        <Button onClick={onRemove} variant="gray" ghost>
          Remove Image
        </Button>
      </VStack>
    </ImageContainer>
  ) : (
    <UploadContainer
      onDrop={handleDrop}
      onDragEnter={handleDrag}
      onDragOver={handleDrag}
      onDragLeave={handleDrag}
      className={dragActive ? 'drag-active' : ''}
      align="center"
      justify="center"
      data-testid="drag-image-field"
    >
      {loading ? (
        <Spinner size={2} />
      ) : (
        <Label htmlFor="upload" css={{ width: '100%' }}>
          <input
            type="file"
            id="upload"
            onChange={handleFileChange}
            accept=".jpg, .jpeg, .png"
            data-testid="upload-image-field"
            hidden
          />
          <Flex
            align="center"
            justify="center"
            direction="column"
            css={{ p: 42, pointerEvents: 'none' }}
          >
            <HiOutlineCloudUpload size={24} color="#7F7F86" />
            <TextLine>Drag and drop or click to upload.</TextLine>
            <TextLine>Recommended: 1200 x 630 pixels (max 5MB)</TextLine>
          </Flex>
        </Label>
      )}
    </UploadContainer>
  );
};

const ImageContainer = styled(Flex, {
  minHeight: 148,
});

const UploadContainer = styled(Flex, {
  border: '1px solid #D7DBDF',
  borderRadius: '$1',
  backgroundColor: 'white',
  minHeight: 148,
  '&.drag-active': {
    boxShadow: 'inset 0px 0px 0px 2px $colors$primaryColor',
  },
});

const TextLine = styled(Box, {
  fontSize: 12,
  lineHeight: 1.5,
  fontWeight: '600',
  color: '#7F7F86',
  pt: 2,
});
