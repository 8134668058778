export const default_invites_object = {
  id: 'unique-id-for-invites',
  label: 'Invites',
  key: 'invite',
  inserted_at: 'current-timestamp',
  updated_at: 'current-timestamp',
  custom_properties: [
    {
      default: '',
      id: 'invite-email',
      label: 'Email',
      type: 'text',
      key: 'email',
      required: true,
      inserted_at: 'current-timestamp',
      updated_at: 'current-timestamp',
      custom_object_id: 'unique-id-for-invites',
    },
  ],
};
