/* eslint-disable react-hooks/exhaustive-deps */
import { FormikValues, useFormikContext } from 'formik';
import _ from 'lodash';
import { SetStateAction, useEffect, useRef, useState } from 'react';

// MessageEditorV2 and its props are imported from the same place
import { MessageEditorV2, MessageEditorV2Props } from '../editor/v2';
import { Attachments } from '../editor/v2/constants';

// This file is a wrapper on MessageEditorV2 that handles formik usage
// FormMessageEditorProps are a subset of MessageEditorV2Props with some additional properties
type FormMessageEditorProps = Omit<
  MessageEditorV2Props,
  // The following props are omitted as they are not required in FormMessageEditorProps
  | 'message'
  | 'setMessage'
  | 'attachments'
  | 'setAttachments'
  | 'attachmentLoading'
  | 'setAttachmentLoading'
  | 'textareaRef'
> & {
  // Field is the path to the field in formik values
  field: string;
};

// FormMessageEditorV2 is a wrapper on MessageEditorV2 that handles formik usage
export function FormMessageEditorV2(props: FormMessageEditorProps): JSX.Element {
  // Using formik context for form handling
  const formikContext = useFormikContext<FormikValues>();

  // Defining paths for body and attachments fields
  const bodyFieldPath = `${props.field}.body`;
  const attachmentsFieldPath = `${props.field}.attachment_urls`;

  // Extracting values from formik context
  const formikValues = formikContext.values;

  // Extracting body and attachments field values using lodash get
  const bodyFieldValue = _.get(formikValues, bodyFieldPath);
  const rawAttachmentsFieldValue = _.get(formikValues, attachmentsFieldPath);
  const attachmentsFieldValue = Array.isArray(rawAttachmentsFieldValue)
    ? rawAttachmentsFieldValue.map((attachment: any) =>
        typeof attachment === 'string' ? attachment : attachment.url
      )
    : [];

  // State for message and attachments
  const [message, setMessage] = useState(bodyFieldValue || '');

  const [attachments, setAttachments] = useState<Attachments>({
    attachment_urls: attachmentsFieldValue || formikValues[attachmentsFieldPath] || [],
  });

  // State for attachment loading
  const [attachmentLoading, setAttachmentLoading] = useState<boolean>(false);

  // Function to handle message update
  const handleMessageUpdate = (updatedMessage: SetStateAction<string>) => {
    setMessage(updatedMessage);
    formikContext.setFieldValue(bodyFieldPath, updatedMessage);
  };

  // Effect to handle attachments update
  useEffect(() => {
    formikContext.setFieldValue(attachmentsFieldPath, attachments.attachment_urls);
  }, [attachments]);

  // Reference for textarea
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  // Rendering MessageEditorV2 with necessary props
  return (
    <MessageEditorV2
      {...props}
      textareaRef={textareaRef}
      isForm={true}
      message={message}
      setMessage={handleMessageUpdate}
      attachments={attachments}
      setAttachments={setAttachments}
      attachmentLoading={attachmentLoading}
      setAttachmentLoading={setAttachmentLoading}
    />
  );
}
