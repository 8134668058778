/* eslint-disable react-hooks/exhaustive-deps */
import { captureException } from '@sentry/react';
import { debounce } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useAuth } from '@/pages/auth/context/AuthProvider';
import { contactSearch } from '@/shared/api/conversations';
import {
  ConversationSortTypes,
  ConversationsSearchV2ApiResponse,
  SearchV2Type,
  WhippyQueryLanguage,
  WhippyQueryLanguageFilterSchema,
} from '@/shared/types/conversations';
import { toE164 } from '@/shared/utils/validations/validations';

import { useConversation } from '../context/ConversationContext';
import { mergeConversations } from '../context/ConversationReducer';
import { ConversationsTabsContent } from './';
import {
  EmptyConversationPreview,
  LoadingConversationPreview,
} from './ConversationPreview';
import { ErrorConversation } from './ErrorConversation';
import { generateSearchClauses, SEARCH_CONVERSATIONS_LIMIT } from './utils';
import { VirtualisedConversationsList } from './VirtualisedConversationsList';

export const FilteredContacts = ({ filter, tab }: { filter: string; tab: string }) => {
  const { searchV2 } = useConversation();

  const auth = useAuth();
  const [searchedContacts, setSearchedContacts] =
    useState<ConversationsSearchV2ApiResponse>({
      data: [],
      total: 0,
    });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState({ hasError: false, error: '' });
  const [offset, setOffset] = useState(0);

  // Searches conversations based on input value, filter, and pagination offset
  const searchContacts = async (searchV2: SearchV2Type, offset: number) => {
    try {
      const userId = auth?.tokens?.user_id ?? null;
      const additionalFilters = generateSearchClauses(userId, filter);

      const formattedValue = searchV2.inputValue.trim();
      const formattedPhone = toE164(formattedValue);

      const storedSort = localStorage.getItem(
        'conversations-search-sort'
      ) as ConversationSortTypes;

      // Define contact search filter schema
      const contactFilter: WhippyQueryLanguageFilterSchema = {
        resource: 'contact',
        column: 'name',
        comparison: 'ilike',
        value: `%${searchV2.inputValue.replace(' ', '%')}%`,
        or: [
          {
            resource: 'contact',
            column: 'email',
            comparison: 'ilike',
            value: `${searchV2.inputValue}%`,
            or: [
              {
                resource: 'contact',
                column: 'phone',
                comparison: 'ilike',
                value: `%${formattedPhone}%`,
              },
            ],
          },
        ],
        ...(additionalFilters.length > 0 ? { and: [...additionalFilters] } : {}),
      };

      // Prepare payload for contact search
      const contactPayload: WhippyQueryLanguage = {
        filter: [contactFilter],
        limit: SEARCH_CONVERSATIONS_LIMIT,
        offset,
        sort: [
          {
            resource: 'conversation',
            column: 'last_message_timestamp',
            order: storedSort === ConversationSortTypes.OLDEST ? 'asc' : 'desc',
          },
        ],
      };

      // Perform contact search and update state
      const contactsResults = await contactSearch(contactPayload);
      setSearchedContacts((previousContacts) => {
        const merged = mergeConversations(previousContacts.data, contactsResults.data);

        return {
          data: merged,
          total: previousContacts.total + contactsResults.total,
        } as ConversationsSearchV2ApiResponse;
      });
      setIsLoading(false);
    } catch (error) {
      const errorMessage =
        error?.response?.data?.errors?.[0]?.description ?? 'Failed to search contacts';
      setIsLoading(false);
      setError({ hasError: true, error: errorMessage });
      captureException(error, {
        tags: {
          feature: 'inboxSearch',
          subFeature: 'contacts',
        },
      });
    }
  };

  // Debounce search to reduce API calls during typing
  const debounceConversationSearch = useCallback(
    debounce((searchV2: SearchV2Type, offset: number) => {
      searchContacts(searchV2, offset);
    }, 350),
    [auth?.tokens?.user_id, filter, offset]
  );

  // Trigger search on input change or filter/tab change
  useEffect(() => {
    if (searchV2.inputValue) {
      setIsLoading(true);
      setSearchedContacts({
        data: [],
        total: 0,
      });
      setOffset(0);
      debounceConversationSearch(searchV2, 0);
    } else {
      setIsLoading(false);
    }
  }, [searchV2.inputValue, filter, searchV2.sort]);

  // Trigger search on pagination offset change (without debouncing & side effects)
  useEffect(() => {
    if (
      searchV2.inputValue &&
      searchedContacts.data.length === SEARCH_CONVERSATIONS_LIMIT * (offset + 1)
    ) {
      if (offset === 0) return;
      searchContacts(searchV2, offset);
    }
  }, [offset]);

  // Flatten the contacts array, include organization_id and conversation_id, remove duplicates, and slice the first 5 contacts
  // get the conversation id from the url
  const conversation_id = useParams<{ id?: string }>();

  return (
    <ConversationsTabsContent value="filtered:contacts">
      {isLoading && <LoadingConversationPreview />}
      {error.hasError && <ErrorConversation errorMessage={error.error} />}
      {!isLoading && searchedContacts.total > 0 && (
        <VirtualisedConversationsList
          conversations={searchedContacts.data}
          length={searchedContacts.total}
          setOffset={setOffset}
          offset={offset}
          slug={conversation_id}
          filter={filter}
          tab={tab}
          searchType="contacts"
        />
      )}
      {!isLoading && searchedContacts.total === 0 && <EmptyConversationPreview />}
    </ConversationsTabsContent>
  );
};
