/* eslint-disable @typescript-eslint/no-non-null-assertion */
import dayjs from 'dayjs';
import { useCombobox, UseComboboxStateChangeOptions } from 'downshift';
import React, { useState } from 'react';
import { useMedia } from 'react-use';
import { toast } from 'sonner';

import {
  Box,
  ComboboxItem,
  ComboboxItemText,
  Flex,
  Input,
  ScrollArea,
  ScrollAreaCorner,
  ScrollAreaScrollbar,
  ScrollAreaThumb,
  ScrollAreaViewport,
  Text,
} from '@/shared/ui';
import { styled } from '@/stitches.config';

import { parseName } from './pipefilling';
import { PipeType } from './types';
import { pipes } from './v2/constants';

// parse template and fill pipe fields
export const fillPipeFields = (
  template: string,
  contactName: string,
  locationName: string,
  locationAddress: string,
  googlePlaceId: string | null | undefined,
  organizationName: string,
  conversationId?: string | null,
  locationId?: string | null
) => {
  const pipes_name = pipes.map((pipe: PipeType) => pipe.children);
  const reg = new RegExp('(' + pipes_name.join('|') + ')', 'gi');
  const matches = template?.match(reg) || [];

  for (let index = 0; index < matches.length; index++) {
    const partToReplace = matches[index];
    const name = partToReplace;

    switch (name) {
      case '{{full_name}}':
        template = template?.replace(
          partToReplace,
          contactName || (conversationId ? '' : '{{full_name}}')
        );
        break;
      case '{{first_name}}':
        template = template?.replace(
          partToReplace,
          parseName(contactName).firstName || (conversationId ? '' : '{{first_name}}')
        );
        break;
      case '{{last_name}}':
        template = template?.replace(
          partToReplace,
          parseName(contactName).lastName || (conversationId ? '' : '{{last_name}}')
        );
        break;
      case '{{location_name}}':
        template = template?.replace(
          partToReplace,
          locationName || (conversationId ? '' : '{{location_name}}')
        );
        break;
      case '{{location_address}}':
        template = template?.replace(
          partToReplace,
          locationAddress || (conversationId ? '' : '{{location_address}}')
        );
        break;
      case '{{organization_name}}':
        template = template?.replace(
          partToReplace,
          organizationName || (conversationId ? '' : '{{organization_name}}')
        );
        break;
      case '{{review_link}}':
        if (!googlePlaceId) {
          template = template?.replace(partToReplace, '[No Google Place ID]');
          toast.error(
            `No Google Place ID found for ${locationName}. Add Google Place ID in Settings.`,
            {
              action: {
                label: 'Settings',
                onClick: () => {
                  window.location.href = `/settings/channels/${
                    locationId || ''
                  }?open=locationDetails`;
                },
              },
            }
          );
          break;
        }

        template = template?.replace(
          partToReplace,
          googlePlaceId
            ? `http://search.google.com/local/writereview?placeid=${googlePlaceId}`
            : ''
        );
        break;
      case '{{today}}':
        template = template?.replace(
          partToReplace,
          conversationId ? dayjs().format('MM/DD/YYYY') : '{{today}}'
        );
        break;
      case '{{tomorrow}}':
        template = template?.replace(
          partToReplace,
          conversationId ? dayjs().add(1, 'day').format('MM/DD/YYYY') : '{{tomorrow}}'
        );
        break;
      default:
        break;
    }
  }

  return template;
};

type PipeComboboxProps = {
  anchorDimensions: {
    height: number;
    width: number;
  };
  onClose: () => void;
  addPipeField: (template?: string | null | undefined | any) => void;
};

const PipeComboboxItem = styled(ComboboxItem, {
  borderRadius: 4,
  py: '6px',
  mb: '$2',
});

// pipes selection component with filtering
export const PipeCombobox = (props: PipeComboboxProps) => {
  const [inputItems, setInputItems] = useState(pipes);
  const isDesktop = useMedia('(min-width: 912px)');

  const { getMenuProps, getInputProps, highlightedIndex, getItemProps } = useCombobox({
    items: inputItems,
    stateReducer: (
      state,
      actionAndChanges
    ): Partial<UseComboboxStateChangeOptions<PipeType>> => {
      const { type, changes } = actionAndChanges;
      // this prevents the menu from being closed when the user selects an item with 'Enter' or mouse
      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
          props.addPipeField(changes.selectedItem);
          props.onClose();
          return {
            changes, // default Downshift new state changes on item selection.
          };
        case useCombobox.stateChangeTypes.ItemClick:
          props.addPipeField(changes.selectedItem);
          props.onClose();
          return {
            changes, // default Downshift new state changes on item selection.
          };
        default:
          return changes; // otherwise business as usual.
      }
    },
    onInputValueChange: ({ inputValue }) => {
      setInputItems(
        pipes.filter(
          (item) =>
            item!.character!.toLowerCase().includes(inputValue!.toLowerCase()) ||
            item.description.toLowerCase().includes(inputValue!.toLowerCase())
        )
      );
    },
  });

  return (
    <Box css={{ padding: '$4', zIndex: 9999999999 }}>
      <Box css={{ paddingBottom: '$3' }}>
        <Text css={{ fontWeight: 600, fontSize: 15 }}>{'Message Variables'}</Text>
      </Box>
      <Flex css={{ paddingBottom: '$4' }}>
        <Input
          {...getInputProps({}, { suppressRefError: true })}
          placeholder="Search message variables..."
        />
      </Flex>
      <ScrollArea variant="combobox" {...getMenuProps({}, { suppressRefError: true })}>
        <ScrollAreaViewport variant="combobox">
          {inputItems.map((item, index) => (
            <PipeComboboxItem
              key={item.children}
              direction="column"
              selected={highlightedIndex === index}
              css={{
                height: '100%',
                width: isDesktop
                  ? props.anchorDimensions.width - 42
                  : props.anchorDimensions.width - 52,
              }}
              {...getItemProps({ item, index })}
            >
              <ComboboxItemText css={{ fontWeight: 600, marginBottom: 3 }}>
                {item.character}
              </ComboboxItemText>
              <ComboboxItemText>{item.description}</ComboboxItemText>
            </PipeComboboxItem>
          ))}
        </ScrollAreaViewport>
        <ScrollAreaScrollbar orientation="vertical" variant="combobox">
          <ScrollAreaThumb />
        </ScrollAreaScrollbar>
        <ScrollAreaCorner />
      </ScrollArea>
    </Box>
  );
};
