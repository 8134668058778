/* eslint-disable prefer-const */
/* eslint-disable react-hooks/exhaustive-deps */
import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';
import { HiChevronDown } from 'react-icons/hi';
import { useClickAway } from 'react-use';

import { styled } from '@/stitches.config';

import {
  Box,
  Flex,
  Input,
  ScrollArea,
  ScrollAreaCorner,
  ScrollAreaScrollbar,
  ScrollAreaThumb,
  ScrollAreaViewport,
} from '../../ui';
import { getHours } from './utils/getHours';
import { isValidFutureTime } from './utils/getTimeInTimezone';

type TimePickerProps = {
  /* set the time to the parent component */
  setTime: (time: string) => void;
  /* get the time from the parent component */
  time: string;
  /* current date */
  currentDate: string;
  /* timezone */
  timezone: string | undefined;
  /* disabled */
  disabled?: boolean;
  /* variant */
  variant?: 'large' | 'medium';
};

export const TimePicker = (props: TimePickerProps) => {
  const { currentDate, timezone, setTime, time, disabled, variant } = props;

  const [open, setOpen] = useState(false);

  // the time value prop might be invalid, so we need to check if it's valid
  // it also might no longer be a valid time in the future, so we need to check that too
  const isPropTimeValid = (time: string, timezone?: string) => {
    if (!isValidTime(time)) {
      return false;
    } else if (!isValidFutureTime(time, currentDate, timezone || '')) {
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    // if the current time is no longer valid, when the date or timezone changes, then set the time to the first valid time
    // if the current time is still valid, then keep the current time
    if (!isPropTimeValid(time, timezone)) {
      setTime(getHours(currentDate, timezone)[0]);
    }
  }, [timezone, currentDate, time]);

  const toggleSelect = () => {
    setOpen(!open);
  };

  const onSelect = (time: string) => {
    // if is valid time, and is a valid future time, then set the time
    if (isValidTime(time) && isValidFutureTime(time, currentDate, timezone || '')) {
      setTime(time);
    }
    setOpen(false);
  };

  const ref = useRef(null);

  useClickAway(ref, () => {
    toggleSelect();
  });

  return (
    <>
      <Box css={{ position: 'relative' }}>
        <Box css={{ position: 'relative' }}>
          <HiChevronDown
            style={{
              position: 'absolute',
              top: '50%',
              transform: 'translateY(-50%)',
              right: '10px',
              pointerEvents: 'none',
              fontSize: '16px',
            }}
          />
          <TimePickerInput
            value={dayjs(`${currentDate} ${time}`, 'M/D/YYYY H:mm').format('h:mm A')}
            // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
            onChange={(_e) => {}}
            onClick={toggleSelect}
            disabled={disabled}
            css={{ borderRadius: '7px', minWidth: 100 }}
            variant={variant}
            data-testid="timepicker-input"
          />
        </Box>
        {open && (
          <TimePickerScrollArea css={{ mt: 5 }} ref={ref} variant={variant}>
            <ScrollAreaViewport css={{ backgroundColor: 'white' }}>
              <Box css={{ p: '5px 0' }}>
                {getHours(currentDate, timezone).map((time: string, index: number) => (
                  <TimeItem
                    key={`time-${index}`}
                    onClick={() => onSelect(time)}
                    variant={variant}
                  >
                    {time}
                  </TimeItem>
                ))}
              </Box>
            </ScrollAreaViewport>
            <ScrollAreaScrollbar orientation="vertical">
              <ScrollAreaThumb />
            </ScrollAreaScrollbar>
            <ScrollAreaScrollbar orientation="horizontal">
              <ScrollAreaThumb />
            </ScrollAreaScrollbar>
            <ScrollAreaCorner />
          </TimePickerScrollArea>
        )}
      </Box>
    </>
  );
};

function isValidTime(timeStr: string): boolean {
  // Check if the time string is in the correct format
  if (!timeStr) {
    return false;
  }

  // Split the time string into hours and minutes
  const [hoursStr, minutesStr] = timeStr.split(':');
  const hours = parseInt(hoursStr);
  const minutes = parseInt(minutesStr);

  // Check if the hours and minutes are in the valid range
  const isValidHours = hours >= 0 && hours <= 23;
  const isValidMinutes = minutes >= 0 && minutes <= 59;

  // Return whether the time is valid or not
  return isValidHours && isValidMinutes;
}

export const TimeItem = styled(Flex, {
  cursor: 'pointer',
  width: '90px',
  fontSize: 14,
  alignContent: 'center',
  padding: '4px 5px',
  '&:hover': {
    backgroundColor: '$gray3',
  },
  variants: {
    variant: {
      medium: {},
      large: {
        maxWidth: '440px',
        width: '440px',
      },
    },
  },
});

export const TimePickerScrollArea = styled(ScrollArea, {
  maxWidth: '100px',
  position: 'absolute !important',
  zIndex: 10000,
  marginTop: '-260px', // this makes the scroll area appear above the input,
  variants: {
    variant: {
      medium: {},
      large: {
        maxWidth: '450px',
        width: '450px',
      },
    },
  },
});

export const TimePickerInput = styled(Input, {
  maxWidth: '100px',
  cursor: 'pointer',
  maxHeight: '27px',
  fontSize: 14,
  borderRadius: '4px !important',
  variants: {
    variant: {
      medium: {
        height: 32,
        maxHeight: 32,
      },
      large: {
        height: 35,
        maxHeight: 35,
        maxWidth: '450px',
        width: '550px',
        pl: 10,
        fontSize: '14px',
      },
    },
  },
});
