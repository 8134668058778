import React from 'react';

import { Box, Flex } from '@/shared/ui';

export const EmptyTable = ({
  title,
  children,
  height,
}: {
  title: string;
  children?: JSX.Element;
  height?: number;
}) => {
  return (
    <Flex
      data-testid="empty-data-placeholder"
      justify="center"
      direction="column"
      align="center"
      css={{
        height: height ?? 'calc(100vh - 230px)',
        maxHeight: 'calc(100vh - 230px)',
        paddingTop: '40px',
        paddingBottom: '40px',
        background: 'white',
        borderRadius: '8px',
        fontSize: 16,
        fontWeight: 700,
        textAlign: 'center',
        borderBottom: '1px solid rgb(226, 232, 240)',
      }}
    >
      <Box>{title}</Box>
      {children}
    </Flex>
  );
};
