import { api } from './api';

type ReportParams = {
  type: string;
  start_date: string;
  end_date: string;
  granularity: string;
  location_ids?: string[];
};

export const getMessagesOverview = (params: ReportParams) =>
  api.post('/reports/messages', { messages: params }).then((res) => {
    return res.data.data;
  });

export const getContactsOverview = (params: ReportParams) =>
  api.post('/reports/contacts', { contacts: params }).then((res) => {
    return res.data.data;
  });

export const getCampaignsOverview = (params: ReportParams) =>
  api.post('/reports/campaigns', { campaigns: params }).then((res) => {
    return res.data.data;
  });
