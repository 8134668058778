import { api } from './api';

/*
  POST - create a new organization
*/

export const createOrganization = (params: Record<string, any>) =>
  api.post('/organizations', { organization: params }).then((res) => {
    return res.data;
  });

/*
  PUT - update the organization
*/

export const updateOrganization = async (
  organizationId: string,
  settings: Record<string, any>
) => {
  const { data } = await api.put(`/v2/organizations/${organizationId}`, {
    organization: settings,
  });
  return data;
};
