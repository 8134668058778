import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { HiX } from 'react-icons/hi';

import { useConversation } from '@/pages/inbox/context/ConversationContext';
import { useUsers } from '@/pages/settings/organization/users/context/UserContext';
import { Attachment, isValidAttachment } from '@/shared/components/attachments';
import { renderText } from '@/shared/components/markdown/MarkdownRenderer';
import { useDisclosure } from '@/shared/hooks';
import { Channel } from '@/shared/types/channels';
import {
  ConversationAttachmentType,
  ConversationContactType,
  ConversationItemModeTypes,
  ConversationItemSourceTypes,
  ConversationItemVisibilityStatusTypes,
  ConversationMessageType,
} from '@/shared/types/conversations';
import {
  Box,
  Button,
  Dialog,
  DialogCloseIcon,
  DialogContent,
  DialogOverlay,
  DialogPortal,
  DialogTitle,
  DialogTrigger,
  Divider,
  Flex,
} from '@/shared/ui';
import { TIME_STAMP } from '@/shared/utils/timestamps';

import {
  MessageError,
  MessageSuccess,
  StyledAttachmentContainer,
  StyledMessage,
  StyledMessageFooter,
} from '..';
import { ContactIcon } from '../ContactIcon';
import { SenderIcon } from '../SenderIcon';

type OutboundEmailProps = {
  message: ConversationMessageType;
  /* the date the message was sent */
  date?: string | undefined | null;
  /* the email of the user who sent the message */
  user_email?: string;
  contact?: ConversationContactType;
  source_type: ConversationItemSourceTypes;
  channel: Channel;
};

// Combined message container and message divs for outbound messages
export const ConversationEmail = (props: OutboundEmailProps) => {
  const { message, date, user_email, contact, source_type, channel } = props;
  const { body, delivery_status, error, type, visibility_status, translated_body, mode } =
    message;
  const {
    conversationState: { isExpanded },
  } = useConversation();
  const [expanded, setExpanded] = useState(true);

  useEffect(() => {
    setExpanded(isExpanded);
  }, [isExpanded]);

  const { userState } = useUsers();
  const { users } = userState;

  const user = users.find((u) => u.id === message.user_id);

  // filter attachments with .sml extension
  const filteredAttachments = message.attachments.filter(
    (a) => a !== null && isValidAttachment(a.url)
  );

  // default to `visible` when the visibility is not set
  const visibility = visibility_status ?? ConversationItemVisibilityStatusTypes.VISIBLE;

  return (
    <Box>
      {/* Show `Message Removed` bubble when the visibility is `removed` */}
      {visibility === ConversationItemVisibilityStatusTypes.REMOVED ? (
        <StyledMessage direction="outbound_email">
          {renderText('This message has been deleted...')}
        </StyledMessage>
      ) : null}

      {(body !== null || filteredAttachments.length > 0) && (
        <Box>
          {body !== null ? (
            <Flex
              justify={
                source_type === ConversationItemSourceTypes.INBOUND ? 'start' : 'end'
              }
              align="end"
            >
              <StyledMessage
                visibility={`outbound_${visibility}`}
                direction="outbound_email"
              >
                <Flex
                  onClick={() => setExpanded(!expanded)}
                  align="center"
                  justify="between"
                  css={{
                    p: 16,
                    cursor: body.startsWith('<!DOCTYPE HTML') ? 'default' : 'pointer',
                  }}
                >
                  <Flex>
                    {source_type === ConversationItemSourceTypes.INBOUND ? (
                      <ContactIcon
                        margin={0}
                        contact_name={contact?.name || contact?.email || ''}
                      />
                    ) : (
                      <SenderIcon margin={0} message={message} />
                    )}
                    <Box css={{ ml: 12 }}>
                      <Box
                        css={{
                          fontSize: '12px',
                          color: '#1C2024',
                          fontWeight: 500,
                          marginBottom: 4,
                        }}
                      >
                        {source_type === ConversationItemSourceTypes.INBOUND ? (
                          <>{`${contact?.name} <${contact?.email}>`}</>
                        ) : (
                          <>{`${user?.name} <${user?.email}>`}</>
                        )}
                      </Box>
                      <Box
                        css={{
                          fontSize: 12,
                          fontWeight: 500,
                          color: '#60646C',
                        }}
                      >
                        <Box css={{ marginBottom: 4 }}>
                          To:{' '}
                          {source_type === ConversationItemSourceTypes.INBOUND
                            ? `${channel.id}@whippymail.com`
                            : contact?.email}
                        </Box>
                        <Box>
                          <span style={{ color: '#1C2024' }}>Subject: </span>
                          {message?.email_metadata?.subject}
                        </Box>
                      </Box>
                    </Box>
                  </Flex>
                  {body.startsWith('<!DOCTYPE HTML') && (
                    <MessagePreviewDialog message={body} />
                  )}
                </Flex>
                {!body.startsWith('<!DOCTYPE HTML') && expanded && (
                  <>
                    <Divider css={{ my: 0 }} />
                    <Box css={{ p: 16 }}>{renderText(body)}</Box>
                  </>
                )}
              </StyledMessage>
            </Flex>
          ) : null}
          <Box>
            {translated_body && translated_body !== null ? (
              <Flex justify="end" align="end" css={{ mt: 5, mr: 37 }}>
                <StyledMessage
                  visibility={`outbound_${visibility}`}
                  direction="outbound_email_translation"
                >
                  {renderText(`Original: ${translated_body}`)}
                </StyledMessage>
              </Flex>
            ) : null}
          </Box>
          <Box>
            {filteredAttachments.length > 0 ? (
              visibility === ConversationItemVisibilityStatusTypes.HIDDEN ? (
                'Attachments have been hidden'
              ) : (
                <Box css={{ mr: 35 }}>
                  {filteredAttachments.map((a: ConversationAttachmentType | null) => {
                    if (a === null) return null;
                    return (
                      <StyledAttachmentContainer align="end" justify="end" key={a.url}>
                        <Flex css={{ width: '300px !important' }} justify="end">
                          <Attachment src={a.url} />
                        </Flex>
                      </StyledAttachmentContainer>
                    );
                  })}
                </Box>
              )
            ) : null}
          </Box>
          {source_type === ConversationItemSourceTypes.INBOUND ? (
            <StyledMessageFooter align="start" justify="start">
              <Flex align="center">
                <Box css={{ mr: 5 }}>{dayjs(date).format(TIME_STAMP)}</Box>
                <Box css={{ fontWeight: 500 }}>
                  {!contact?.name &&
                  !contact?.email &&
                  mode === ConversationItemModeTypes.IMPORTED
                    ? 'Imported'
                    : contact?.name || contact?.email}
                </Box>
              </Flex>
            </StyledMessageFooter>
          ) : (
            <StyledMessageFooter align="end" justify="end" css={{ alignItems: 'center' }}>
              {error && (
                <MessageError
                  status={delivery_status}
                  error={error}
                  date={date}
                  user={user_email}
                  message_type={type}
                />
              )}
              {!error && (
                <MessageSuccess
                  status={status}
                  error={error}
                  date={date}
                  user={user_email}
                  message_type={type}
                />
              )}
            </StyledMessageFooter>
          )}
        </Box>
      )}
    </Box>
  );
};

function MessagePreviewDialog(props: { message: string }): JSX.Element {
  const { message } = props;

  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleCloseDialog = () => {
    if (onClose) {
      onClose();
    }
  };

  const handleOpen = () => {
    if (onOpen) {
      onOpen();
    }
  };

  return (
    <Dialog open={isOpen}>
      <DialogTrigger asChild onClick={() => handleOpen()}>
        <Button
          variant="gray"
          css={{ borderRadius: 24, color: '#60646C', fontSize: 12, height: 24 }}
        >
          Open Email
        </Button>
      </DialogTrigger>
      <DialogPortal>
        <DialogOverlay />
        <DialogContent
          css={{ p: 0, width: '60%', overflow: 'auto', maxHeight: 600 }}
          onEscapeKeyDown={() => handleCloseDialog()}
          onPointerDownOutside={() => handleCloseDialog()}
        >
          <DialogTitle
            css={{
              padding: '16px 20px',
              m: 0,
              color: '#60646C',
              fontSize: 12,
              fontWeight: 500,
            }}
            variant="bold"
          >
            View message
          </DialogTitle>
          <Divider css={{ m: 0 }} />
          <div dangerouslySetInnerHTML={{ __html: message }} />

          <DialogCloseIcon
            css={{ top: 8, right: 8 }}
            onClick={() => handleCloseDialog()}
            size="2"
          >
            <HiX size="15px" />
          </DialogCloseIcon>
        </DialogContent>
      </DialogPortal>
    </Dialog>
  );
}
