/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import { HiPrinter } from 'react-icons/hi';
import ReactToPrint from 'react-to-print';

import { ToolTipIconButton } from '.';
import { ImageViewer } from './ImageViewer';

type PrintImageProps = {
  src: string;
};

export const PrintImage = (props: PrintImageProps) => {
  const componentRef = React.useRef(null);

  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);

  const reactToPrintTrigger = React.useCallback(() => {
    return (
      <ToolTipIconButton
        icon={<HiPrinter size={18} />}
        onClick={() => reactToPrintContent()}
        description="Print Attachment"
      />
    );
  }, []);

  return (
    <div>
      <ReactToPrint
        content={reactToPrintContent}
        documentTitle="AwesomeFileName"
        removeAfterPrint
        trigger={reactToPrintTrigger}
      />

      <div style={{ display: 'none' }}>
        <div
          ref={componentRef}
          style={{
            height: '100vh',
            width: '100vw',
            display: 'flex',
            alignContent: 'center',
            justifyContent: 'center',
          }}
        >
          <style type="text/css" media="print">
            {
              '\
   @page { size: landscape; }\
'
            }
          </style>
          <ImageViewer src={props.src} />
        </div>
      </div>
    </div>
  );
};
