import React, { MutableRefObject } from 'react';

import { CampaignCardContainer } from '@/campaigns/list/styled';
import { Flex, Skeleton } from '@/shared/ui';

type CampaignLoadingCardProps = {
  observerTarget?: null | MutableRefObject<null>;
};
const CampaignLoadingCard = ({ observerTarget = null }: CampaignLoadingCardProps) => {
  return (
    <CampaignCardContainer align="center" justify="between" ref={observerTarget}>
      <Flex direction="column" css={{ pr: 44, minWidth: '100%' }}>
        <Skeleton variant="subheading" css={{ maxWidth: '50%', m: 0 }} />
        <Skeleton variant="subheading" css={{ maxWidth: '80%', mb: 0 }} />
      </Flex>
      <Flex />
    </CampaignCardContainer>
  );
};
export default CampaignLoadingCard;
