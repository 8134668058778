export const convertTime12to24 = (time12h: string) => {
  // Define a regular expression to match the 12-hour time format (e.g. "1:30 PM").
  const timeRegex = /^(0?[1-9]|1[0-2]):[0-5][0-9] (AM|PM)$/i;

  // If the input string doesn't match the expected format, log an error and throw an exception.
  if (!timeRegex.test(time12h)) {
    throw new Error('Invalid time format');
  }

  // Split the time string into its hour/minute and AM/PM components.
  const [time, modifier] = time12h.split(' ');

  // Split the hour/minute component into its hour and minute values.
  const [hourValue, minutes] = time.split(':');

  // Initialize the "hours" variable to the hour value.
  let hours = hourValue;

  // If the time is 12:xx PM, change the hour to 00.
  if (hours === '12' && modifier === 'PM') {
    hours = '00';
  }

  // If the time is in the PM, add 12 to the hour value (except for 12 PM).
  if (modifier === 'PM') {
    hours = (parseInt(hours, 10) + 12).toString();
  }

  // Return the time in 24-hour format.
  return `${hours}:${minutes}`;
};
