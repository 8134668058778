/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo } from 'react';
import { HiOutlineUser, HiOutlineUserGroup } from 'react-icons/hi';

import { useTeams } from '@/pages/settings/organization/teams/context/TeamsContext';
import { Team } from '@/shared/types/team';
import { Box, Fieldset, Flex, Label, VStack } from '@/shared/ui';
import { styled } from '@/stitches.config';

import { AssignConversationModal } from '../assign/AssignConversationModal';
import { AssignTeamConversation } from '../assign/AssignTeamConversationTeam';

export const ConversationDetailsPanel = () => {
  const {
    getTeams,
    teamsState: { allTeams },
  } = useTeams();

  useEffect(() => {
    allTeams.length === 0 && getTeams();
  }, [JSON.stringify(allTeams)]);

  const IsTeamsEnabled = useMemo(() => {
    const teamsList = allTeams?.filter(
      (team: Team) => team?.team_members && team?.team_members?.length > 0
    );
    return teamsList.length > 0;
  }, [allTeams]);

  return (
    <Flex css={{ p: 24, width: '100%' }} direction="column" justify="between">
      <VStack css={{ width: '100%' }} gap="3">
        <Fieldset css={{ mb: 0 }}>
          <Flex direction="row" align="center" css={{ minHeight: 35 }}>
            <Flex direction="row" align="center">
              <HiOutlineUser size={14} />
              <StyledLabel>Assignee</StyledLabel>
            </Flex>
            <Box css={{ alignItems: 'center' }}>
              <AssignConversationModal isPanel />
            </Box>
          </Flex>
        </Fieldset>
        {IsTeamsEnabled && (
          <Fieldset css={{ mb: 0 }}>
            <Flex direction="row" align="center" css={{ minHeight: 35 }}>
              <Flex direction="row" align="center">
                <HiOutlineUserGroup size={14} />
                <StyledLabel>Team</StyledLabel>
              </Flex>
              <Box css={{ alignItems: 'center' }}>
                <AssignTeamConversation />
              </Box>
            </Flex>
          </Fieldset>
        )}
      </VStack>
    </Flex>
  );
};

export const StyledLabel = styled(Label, {
  minWidth: '57px',
  mb: '0 !important',
  mr: 5,
  ml: 4,
});
