/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-key */
import dayjs from 'dayjs';
import React, { useEffect } from 'react';
import { CSVLink } from 'react-csv';
import {
  HiChevronDown,
  HiChevronLeft,
  HiChevronRight,
  HiChevronUp,
  HiDownload,
  HiSearch,
  HiSelector,
} from 'react-icons/hi';
import {
  useExpanded,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table';
import { useMedia } from 'react-use';

import { Campaign } from '../types/campaigns';
import {
  Box,
  Button,
  Caption,
  Checkbox,
  Flex,
  HStack,
  IconButton,
  Input,
  Table as StyledTable,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  TooltipArrow,
  TooltipContent,
  TooltipTrigger,
  Tr,
} from '../ui';

export const GlobalFilter = ({ searchValue, setSearchValue }: any) => {
  const onValueChange = (value: string) => {
    setSearchValue(value);
  };

  return (
    <Box css={{ position: 'relative' }}>
      <HiSearch
        style={{
          position: 'absolute',
          top: '50%',
          transform: 'translateY(-50%)',
          left: '24px',
          pointerEvents: 'none',
        }}
      />
      <Input
        placeholder="Search..."
        css={{
          borderRadius: 0,
          boxShadow: 'none',
          padding: '24px 24px 24px 48px',
        }}
        value={searchValue}
        onChange={(e: { target: { value: string } }) => onValueChange(e.target.value)}
      />
    </Box>
  );
};

export const Table = ({
  columns,
  data,
  caption,
  empty,
  overflow,
  fixed,
  searchFilter,
  sortable,
  selectable,
  renderRowSubComponent,
  searchValue,
  setSearchValue,
  totalCount, // show the number of all pages (including ones that are not loaded)
  limit,
  offset,
  setOffset,
  enableExport,
  isSearched,
}: any) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    // setPageSize,
    visibleColumns,
    setGlobalFilter,
    state: { pageIndex, pageSize, globalFilter },
  } = useTable(
    {
      columns,
      data,
      disableSortBy: !sortable,
      autoResetPage: false,
    },
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
    (hooks) => {
      if (selectable) {
        hooks.visibleColumns.push((columns) => [
          // Create a column for selection
          {
            id: 'selection',
            Header: ({ getToggleAllRowsSelectedProps, toggleAllRowsSelected }) => (
              <div>
                <Checkbox
                  {...getToggleAllRowsSelectedProps()}
                  onCheckedChange={toggleAllRowsSelected}
                />
              </div>
            ),
            disableSortBy: true,
            Cell: ({ row }) => (
              <div>
                <Checkbox
                  {...row.getToggleRowSelectedProps()}
                  onCheckedChange={row.toggleRowSelected}
                />
              </div>
            ),
          },
          ...columns,
        ]);
      }
    }
  );
  const isDesktop = useMedia('(min-width: 912px)');

  const handleNextPage = () => {
    nextPage();
  };

  //   const onGoToPageClick = (value: string) => {
  //     const page = value ? Number(value) - 1 : 0;
  //     if (page <= pageOptions.length) {
  //       gotoPage(page);
  //     } else {
  //         //TODO
  //     }
  //   };

  useEffect(() => {
    const currentPage = pageIndex + 1;
    const numberOfLoadedItems = (currentPage + 1) * 10;
    const pagesToMakeReqOn = limit / 10 - 1;

    if (currentPage % (limit / 10) === pagesToMakeReqOn && numberOfLoadedItems > offset) {
      setOffset(numberOfLoadedItems);
    }
  }, [pageIndex]);

  // fixes bug where if there are e.g 11 elements and the last one is deleted, table remains on the second page and no data is shown
  useEffect(() => {
    if (data.length % 10 === 0 && pageIndex >= Math.floor(data.length / 10)) {
      gotoPage(pageIndex - 1);
    }
  }, [data]);

  const [pageReference, setPageReference] = React.useState(0);

  useEffect(() => {
    if (!globalFilter && searchValue) {
      if (pageIndex !== 0) {
        setPageReference(pageIndex);
        gotoPage(0);
      }
    } else if (!globalFilter && !isSearched) {
      gotoPage(pageReference);
    } else {
      if (pageIndex !== 0) {
        setPageReference(pageIndex);
        gotoPage(0);
      }
    }
  }, [globalFilter, isSearched]);

  const handleSearchValue = (value: string) => {
    setSearchValue(value);
    setGlobalFilter(value);
  };

  return (
    <Box
      css={{
        overflowX: isDesktop
          ? overflow?.desktop
            ? 'scroll'
            : 'auto'
          : overflow?.mobile
            ? 'scroll'
            : 'auto',
        width: isDesktop ? overflow?.desktop || '100%' : overflow?.mobile || '100%',
        border: '1px solid rgb(226, 232, 240)',
        borderRadius: 8,
      }}
    >
      <StyledTable {...getTableProps()} css={{ tableLayout: fixed ? 'fixed' : 'auto' }}>
        {caption ? <Caption>{caption}</Caption> : null}
        <Thead>
          {headerGroups.map((headerGroup) => (
            <Tr {...headerGroup.getHeaderGroupProps()} variant="header">
              {headerGroup.headers.map((column) => (
                <Th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  css={{
                    // Adjust width of a column if colWidth is set in header cell template
                    width: column.colWidth || 'auto',
                    maxWidth: column.colWidth || 'auto',
                  }}
                  // Turn overflow into ellipsis if ellipsis is set to true in header cell template
                  ellipsis={column.ellipsis}
                >
                  <HStack align="center" gap={2}>
                    {column.render('Header')}
                    {sortable ? (
                      column.Header === '' ||
                      column.Header === ' ' ||
                      column.disableSortBy ? null : column.isSorted ? (
                        column.isSortedDesc ? (
                          <HiChevronDown
                            style={{
                              opacity: '0.6',
                              marginLeft: 5,
                            }}
                          />
                        ) : (
                          <HiChevronUp
                            style={{
                              opacity: '0.6',
                              marginLeft: 5,
                            }}
                          />
                        )
                      ) : // This prevents rendering arrows on empty headers
                      column.Header === '' || column.Header === ' ' ? (
                        ''
                      ) : (
                        <HiSelector
                          style={{
                            opacity: '0.6',
                            marginLeft: 5,
                          }}
                        />
                      )
                    ) : null}
                  </HStack>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {searchFilter ? (
            <Tr>
              <Td colSpan={columns.length} variant="search">
                <GlobalFilter
                  searchValue={searchValue}
                  setSearchValue={handleSearchValue}
                />
              </Td>
            </Tr>
          ) : null}
          {/* Add page navigation to table if suitable */}
          {page.length > 0
            ? page.map((row) => {
                prepareRow(row);
                return (
                  <>
                    <Tr
                      {...row.getRowProps()}
                      variant={row.isExpanded ? 'expanded' : undefined}
                    >
                      {row.cells.map((cell) => {
                        return (
                          <Td
                            {...cell.getCellProps()}
                            // Adjust width of a column if colWidth is set in row cell template
                            css={{
                              width: cell.column.colWidth || 'auto',
                              maxWidth: cell.column.colWidth || 'auto',
                              padding: '16px 20px',
                              backgroundColor: 'white',
                            }}
                            // Turn overflow into ellipsis if ellipsis is set to true in row cell template
                            ellipsis={cell.column.ellipsis}
                          >
                            {cell.render('Cell')}
                          </Td>
                        );
                      })}
                    </Tr>
                    {row.isExpanded ? (
                      <Tr css={{ backgroundColor: 'white' }}>
                        <Td colSpan={visibleColumns.length}>
                          {renderRowSubComponent({ row })}
                        </Td>
                      </Tr>
                    ) : null}
                  </>
                );
              })
            : empty || null}
        </Tbody>
      </StyledTable>
      {rows.length > pageSize || rows.length > 10 ? (
        <Flex justify="center" css={{ padding: '$2', position: 'relative' }}>
          {/* Change rows per page */}
          {/* <Flex justify="center" align="center" css={{ mr: '10px', maxWidth: '100px' }}>
            <SingleSelect
              selectItem={`Show ${pageSize}`}
              setSelectItem={(e) => setPageSize(Number(e))}
              options={['10', '20', '30', '40', '50'].map((number: string) => ({
                type: `Show ${number}`,
                value: number,
              }))}
              defaultPlaceholder="Show 10"
              isDropdown={true}
              closeOnClick={true}
            />
          </Flex> */}
          <HStack gap={4}>
            <Button
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
              variant="gray"
            >
              <HiChevronLeft style={{ margin: 0 }} />
            </Button>
            <Flex>
              <Text>Page&nbsp;</Text>
              <Text variant="semibold">
                {pageIndex + 1} of{' '}
                {pageIndex + 1 > pageOptions.length
                  ? ((Math.ceil(totalCount / 10) && previousPage()) as React.ReactNode)
                  : Math.ceil(totalCount / 10)}
              </Text>
            </Flex>
            <Button
              onClick={() => handleNextPage()}
              disabled={!canNextPage}
              variant="gray"
            >
              <HiChevronRight style={{ margin: 0 }} />
            </Button>
          </HStack>
          {!!enableExport && (
            <CSVLink
              filename={`campaigns.csv`}
              data={data.map((campaign: Campaign) => {
                return {
                  title: campaign.title,
                  message: campaign.body,
                  date: dayjs(campaign.inserted_at).format('MMM DD, YYYY'),
                  ...campaign.analytics,
                };
              })}
            >
              <Tooltip>
                <TooltipTrigger asChild>
                  <IconButton
                    size={2}
                    css={{
                      position: 'absolute',
                      right: '15px',
                    }}
                  >
                    <HiDownload style={{ fontSize: '17px' }} />
                  </IconButton>
                </TooltipTrigger>
                <TooltipContent>
                  Export Campaigns to CSV
                  <TooltipArrow />
                </TooltipContent>
              </Tooltip>
            </CSVLink>
          )}
          {/* <Flex justify="center" align="center" css={{ ml: 10 }}>
            <Text>Go to page:</Text>
            <Input
              type="number"
              defaultValue={pageIndex + 1}
              onChange={(e) => onGoToPageClick(e.target.value)}
              css={{ maxWidth: '40px', ml: 5 }}
            />
          </Flex> */}
        </Flex>
      ) : null}
    </Box>
  );
};
