/* eslint-disable @typescript-eslint/no-unused-vars */
import { ErrorBoundary } from '@sentry/react';
import i18next from 'i18next';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { toast } from 'sonner';

import {
  createCampaignTrigger,
  deleteCampaignTrigger,
  updateCampaignTrigger,
} from '@/shared/api/campaigns';
import {
  AddTrigger,
  LinkClickTriggerType,
  ReplyTriggerType,
} from '@/shared/components/triggers/AddTrigger';
import { KeywordAutomations } from '@/shared/components/triggers/KeywordAutomations';
import { LinkClickAutomations } from '@/shared/components/triggers/LinkClickAutomations';
import { ReplyAutomations } from '@/shared/components/triggers/ReplyAutomations';
import { AsIsAutomationTemplates } from '@/shared/components/triggers/templates/AsIsAutomationTemplates';
import { AutomationTemplate } from '@/shared/types/automations';
import { AccordionValue, Campaign } from '@/shared/types/campaigns';
import { Trigger, TriggerTypes } from '@/shared/types/triggers';
import { Box, Button, HStack, Skeleton, VStack } from '@/shared/ui';

import { CampaignErrorBoundaryFallback } from '../analytics/overview/CampaignErrorBoundaryFallback';
import { AddTriggerErrorBoundaries } from './boundaries/AddTriggerErrorBoundaries';
import { AsIsTemplatesDisplayErrorBoundaries } from './boundaries/AsIsTemplatesDisplayErrorBoundaries';

type CampaignAutomationsProps = {
  /* The current campaign */
  current: Campaign | null;
  /* Update the current campaign */
  setCurrentCampaign: (campaign: Campaign | null) => void;
  /* The current accordion value */
  accordion: AccordionValue;
  /* Set the current accordion value */
  setAccordion: Dispatch<SetStateAction<AccordionValue>>;
  /* All the existing as-is automation templates */
  existingAllAutomationTemplates?: Array<AutomationTemplate>;
  /* Set all the existing as-is automation templates */
  setExistingAllAutomationTemplates?: React.Dispatch<
    React.SetStateAction<AutomationTemplate[]>
  >;
  /* Update the current campaign */
  updateCurrentCampaign?: () => Promise<void>;
  /* is this component used under GlobalTemplatePreview */
  showSaveButton?: boolean;
};

export const CampaignAutomations = (props: CampaignAutomationsProps) => {
  const {
    current,
    setCurrentCampaign,
    setAccordion,
    existingAllAutomationTemplates,
    setExistingAllAutomationTemplates,
    updateCurrentCampaign,
    showSaveButton,
  } = props;

  // if the number of existingAllAutomationTemplates is the same as the number of current.associated_automation_templates,
  // then we know that all the templates are finished loading
  const isLoadingTemplates =
    (current &&
      current.associated_automation_templates &&
      current.associated_automation_templates.length > 0 &&
      current.associated_automation_templates.length !==
        existingAllAutomationTemplates?.length) ||
    false;

  // separate automationTemplate.usage = "as-is" or "duplicate"
  const existingAsIsAutomationTemplates = existingAllAutomationTemplates
    ? existingAllAutomationTemplates
    : [];

  const createTrigger = async (trigger: Trigger) => {
    if (current && current?.id) {
      await createCampaignTrigger(current?.id, trigger)
        .then((res) => {
          if (current && current?.triggers) {
            setCurrentCampaign({
              ...current,
              triggers: [...(current?.triggers || {}), res],
            });
          }
          updateCurrentCampaign && updateCurrentCampaign();
        })
        .catch((err) => {
          toast.error(i18next.t('campaign_trigger_created_failure') as string);
        });
    }
  };

  // for duplicate templates, we only add one trigger at a time, without updating the current campaign.triggers
  // this is because we want to add all the triggers to current state at once, not asynchronously
  const addSingleTriggerOfDuplicateTemplates = async (trigger: Trigger) => {
    if (current && current?.id) {
      await createCampaignTrigger(current?.id, trigger)
        .then((res) => {
          if (current && current?.triggers) {
            setCurrentCampaign({
              ...current,
              triggers: [...(current?.triggers || {}), res],
            });
            updateCurrentCampaign && updateCurrentCampaign(); // after adding a single trigger, we need to update the current campaign
          }
        })
        .catch((err) => {
          toast.error(i18next.t('campaign_trigger_created_failure') as string);
        });
    }
  };

  const updateTrigger = async (trigger_id: string, trigger_param: Trigger) => {
    if (current && current?.id && trigger_param.id) {
      await updateCampaignTrigger(current?.id, trigger_id, trigger_param)
        .then(() => {
          // after we update the trigger, we need to update the current campaign.triggers
          setCurrentCampaign({
            ...current,
            triggers: current?.triggers?.map((t: Trigger) =>
              t.id === trigger_param.id ? trigger_param : t
            ),
          });
          updateCurrentCampaign && updateCurrentCampaign();
        })
        .catch((err) => {
          toast.error(i18next.t('campaign_trigger_updated_failure') as string);
          console.error(err);
        });
    }
  };

  const deleteTrigger = async (id: string) => {
    if (current && current?.id) {
      await deleteCampaignTrigger(current?.id, id)
        .then(() => {
          // after we delete the trigger, we need to update the current campaign.triggers
          if (current && current?.id) {
            setCurrentCampaign({
              ...current,
              triggers: current?.triggers?.filter((t: Trigger) => t.id !== id),
            });
            updateCurrentCampaign && updateCurrentCampaign();
          }
        })
        .catch((err) => {
          toast.error(i18next.t('campaign_trigger_deleted_failure') as string);
          console.error(err);
        });
    }
  };

  return (
    <ErrorBoundary
      fallback={<CampaignErrorBoundaryFallback />}
      beforeCapture={(scope) => {
        scope.setTag('Campaign', 'CampaignErrorBoundary.CampaignAutomations');
      }}
      showDialog={false}
    >
      <Box>
        {/* A section for As-Is Automation Templates under this Campaign*/}
        <Box>
          {current &&
            current.associated_automation_templates &&
            current.associated_automation_templates.length > 0 && (
              <AsIsTemplatesDisplayErrorBoundaries feature="asIsTemplates">
                <AsIsAutomationTemplates
                  templates={existingAsIsAutomationTemplates}
                  campaignId={current?.id || ''}
                  associations={current?.associated_automation_templates || []}
                  currentCampaign={current}
                  setCurrentCampaign={setCurrentCampaign}
                  updateCurrentCampaign={updateCurrentCampaign}
                  isLoading={isLoadingTemplates}
                />
              </AsIsTemplatesDisplayErrorBoundaries>
            )}
        </Box>

        {/* A section for all other triggers */}
        <Box>
          <KeywordAutomations
            keyword_triggers={
              current?.triggers?.filter(
                (trigger: { type: string }) =>
                  trigger.type === TriggerTypes.KEYWORD ||
                  trigger.type === 'reply_with_keyword'
              ) || []
            }
            updateTrigger={updateTrigger}
            deleteTrigger={deleteTrigger}
          />
        </Box>
        <Box>
          <ReplyAutomations
            reply_triggers={
              current?.triggers?.filter(
                (trigger: { type: string }) => trigger.type === TriggerTypes.REPLY
              ) as Array<ReplyTriggerType>
            }
            updateTrigger={updateTrigger}
            deleteTrigger={deleteTrigger}
          />
        </Box>
        <Box>
          <LinkClickAutomations
            link_click_triggers={
              current?.triggers?.filter(
                (trigger: { type: string }) => trigger.type === TriggerTypes.LINK_CLICK
              ) as Array<LinkClickTriggerType>
            }
            updateTrigger={updateTrigger}
            deleteTrigger={deleteTrigger}
          />
        </Box>

        <VStack gap="2">
          <HStack>
            {(showSaveButton === undefined || showSaveButton === true) && (
              <AddTriggerErrorBoundaries feature="addTrigger">
                <AddTrigger
                  addTrigger={createTrigger}
                  disabled={!current?.id ? true : false}
                  campaignId={current?.id}
                  existingAllAutomationTemplates={existingAllAutomationTemplates ?? []}
                  setExistingAllAutomationTemplates={setExistingAllAutomationTemplates}
                  currentCampaign={current}
                  setCurrentCampaign={setCurrentCampaign}
                  isUnderCampaignsPage={true}
                  addSingleTriggerOfDuplicateTemplates={
                    addSingleTriggerOfDuplicateTemplates
                  }
                  updateCurrentCampaign={updateCurrentCampaign}
                />
              </AddTriggerErrorBoundaries>
            )}

            <Button
              variant="gray"
              ghost={true}
              onClick={() => setAccordion(AccordionValue.DEFAULT_VALUE)}
            >
              Cancel
            </Button>
          </HStack>
        </VStack>
      </Box>
    </ErrorBoundary>
  );
};
