import React, { useRef, useState } from 'react';
import { HiInformationCircle, HiQuestionMarkCircle } from 'react-icons/hi';
import { useMedia } from 'react-use';

import { CustomScrollbar, Text } from '@/shared/ui';
import { isDev, isPreview } from '@/shared/utils/config';
import {
  getSideNavigationSettings,
  setSideNavigationSettings,
} from '@/shared/utils/storage';
import { styled } from '@/stitches.config';

import { Box, Flex } from '../../ui';
import {
  NavigationSettingsType,
  sideNavigationInitialValue,
  useSidenavigationSettings,
} from './context/SideNavigationContext';
import { SideNavigationItem } from './SideNavigationItem';

export type sidebarNavigationContainerType = {
  children: React.ReactNode;
  maxWidth?: number;
  minWidth?: number;
  defaultWidth?: number;
  disableCollapse?: boolean;
  dragDirection?: 'left' | 'right';
  name?: string;
};

export const SidebarContainer = styled('div', {
  position: 'relative',
  display: 'flex',
  flexGrow: 0,
  flexShrink: 0,
  flexDirection: 'row',
  borderRight: 'thin solid $gray4',
});

export const SettingsNavigationFlex = styled(Flex, {
  positive: 'relative',
  flexShrink: 0,
  height: '100%',
  width: '100%',
  bc: '$loContrast',
  zIndex: 2,
});

export const SidebarResizer = styled('div', {
  position: 'absolute',
  top: 0,
  bottom: 0,
  flexGrow: 0,
  flexShrink: 0,
  justifySelf: 'flex-end',
  cursor: 'col-resize',
  resize: 'horizontal',
  width: '6px',
  zIndex: 10,
  '&:hover': {
    background: '#efefef',
  },
});

const calculateWidth = (
  width: number,
  maxWidth: number,
  minWidth: number,
  disableCollapse: boolean
) => {
  if (width > maxWidth) {
    return maxWidth;
  } else {
    return width <= minWidth && disableCollapse ? minWidth : width;
  }
};

export const SidebarNavigationContainer = ({
  maxWidth = 350,
  minWidth = 150,
  disableCollapse,
  defaultWidth,
  dragDirection = 'left',
  children,
  name,
}: sidebarNavigationContainerType) => {
  let settings: NavigationSettingsType;
  let setSettings: (val: any) => void;
  const { navigationSettings, setNavigationSettings } = useSidenavigationSettings();
  const defaultSettings =
    getSideNavigationSettings(name) ??
    (defaultWidth
      ? {
          ...sideNavigationInitialValue,
          width: calculateWidth(defaultWidth, maxWidth, minWidth, !!disableCollapse),
        }
      : sideNavigationInitialValue);

  React.useEffect(() => {
    const settings = getSideNavigationSettings(name);
    if (!settings) {
      setSideNavigationSettings({
        ...navigationSettings,
        name,
        width: defaultWidth
          ? calculateWidth(defaultWidth, maxWidth, minWidth, !!disableCollapse)
          : navigationSettings.width,
      });
    }
  }, []);

  const [navigationSettingsState, setNavigationSettingsState] =
    useState<NavigationSettingsType>(defaultSettings);

  if (name) {
    settings = navigationSettingsState;
    setSettings = setNavigationSettingsState;
  } else {
    settings = navigationSettings;
    setSettings = setNavigationSettings;
  }

  const [collapsedWidth, setCollapsedWidth] = useState(54);
  const isDesktop = useMedia('(min-width: 912px)');
  const sidebarRef = useRef<HTMLDivElement>(null);
  const [isResizing, setIsResizing] = useState(false);

  React.useEffect(() => {
    if (!settings.expanded) {
      const defaultHeight = 54;
      const containerHeight = sidebarRef.current?.offsetHeight;
      const contentHeight =
        sidebarRef.current?.querySelector('#sidebar-container')?.clientHeight;
      const isScrollAvailable = !!(
        containerHeight &&
        contentHeight &&
        contentHeight - containerHeight > 0
      );
      setCollapsedWidth(isScrollAvailable ? defaultHeight + 16 : defaultHeight);
    }
  }, [sidebarRef, settings.expanded]);

  const startResizing = React.useCallback(() => {
    document.body.style.userSelect = 'none';
    setIsResizing(true);
  }, []);

  const stopResizing = React.useCallback(() => {
    document.body.style.userSelect = 'auto';
    setIsResizing(false);
  }, []);

  const resize = React.useCallback(
    (mouseMoveEvent: MouseEvent) => {
      if (isResizing && sidebarRef.current) {
        const sidebarWidth =
          dragDirection === 'left'
            ? mouseMoveEvent.clientX - sidebarRef.current.getBoundingClientRect().left
            : sidebarRef.current.getBoundingClientRect().right - mouseMoveEvent.clientX;

        const newNavigationSetting = {
          width: calculateWidth(sidebarWidth, maxWidth, minWidth, !!disableCollapse),
          expanded: disableCollapse ? true : sidebarWidth > minWidth,
        };
        setSettings(newNavigationSetting);
        if (name) {
          setSideNavigationSettings({ ...newNavigationSetting, name });
        }
      }
    },
    [isResizing]
  );

  React.useEffect(() => {
    window.addEventListener('mousemove', resize);
    window.addEventListener('mouseup', stopResizing);
    return () => {
      window.removeEventListener('mousemove', resize);
      window.removeEventListener('mouseup', stopResizing);
    };
  }, [resize, stopResizing]);

  return (
    <SidebarContainer
      data-testid="sidebar-container"
      ref={sidebarRef}
      style={{
        width: settings.expanded ? settings.width : collapsedWidth,
        minWidth: settings.expanded ? minWidth : collapsedWidth,
      }}
    >
      <SettingsNavigationFlex css={{ flex: 1 }} direction="column" justify="between">
        {children}
        {!disableCollapse && settings.expanded && <HelpButton />}
      </SettingsNavigationFlex>
      {isDesktop && (
        <SidebarResizer
          css={dragDirection === 'left' ? { right: 0 } : { left: 0 }}
          onMouseDown={startResizing}
        />
      )}
    </SidebarContainer>
  );
};

export const SidebarNavigationWrapper = ({ children }: { children: React.ReactNode }) => {
  return (
    <CustomScrollbar scrollAreaWidth={24}>
      <Box id="sidebar-container">
        <Flex
          direction="column"
          css={{ overflowY: 'auto', flex: '1 initial initial', mt: 4 }}
        >
          {children}
        </Flex>
      </Box>
    </CustomScrollbar>
  );
};

export function HelpButton(): JSX.Element {
  return (
    <Box css={{ p: 8, mb: 20 }} data-testid="help-button">
      {isDev && (
        <SideNavigationItem>
          <HiInformationCircle color="red" />
          <Text css={{ color: 'red' }}>Local Environment</Text>
        </SideNavigationItem>
      )}
      {isPreview && (
        <SideNavigationItem>
          <HiInformationCircle color="blue" />
          <Text css={{ color: 'blue' }}>Preview Environment</Text>
        </SideNavigationItem>
      )}
      <Box css={{ pb: 0, mt: 10 }} id="my_custom_link">
        <SideNavigationItem>
          <HiQuestionMarkCircle size={18} />
          <Text>{'Help & Feedback'}</Text>
        </SideNavigationItem>
      </Box>
    </Box>
  );
}
