import React, { memo } from 'react';
import { HiTrash } from 'react-icons/hi';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { ConfirmationDialog } from '@/shared/components/ConfirmationDialog';
import { IconButton } from '@/shared/ui';

type DeleteObjectProps = {
  id: string;
  onDelete: (id: string) => void;
};

export const DeleteObject = memo(({ onDelete, id }: DeleteObjectProps) => {
  const isCustomObjectData = useRouteMatch('/settings/data/:id');
  const history = useHistory();

  const handleDelete = () => {
    if (id) {
      onDelete(id);
      isCustomObjectData && history.push(`/settings/data`);
    }
  };

  return (
    <ConfirmationDialog
      title="Delete Object"
      description="Are you sure you want to delete this custom object?"
      onConfirm={handleDelete}
      confirmButtonTitle="Yes, Delete Object"
      testID="delete-object-button"
    >
      <IconButton
        aria-label="delete data object"
        variant="outline"
        size="2"
        data-testid="delete-object-button"
      >
        <HiTrash size="15px" />
      </IconButton>
    </ConfirmationDialog>
  );
});
