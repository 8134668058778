import React from 'react';

import { useChannels } from '@/pages/settings/organization/channels/context/ChannelContext';
import { MultiSelect } from '@/shared/components/MultiSelect';
import { Channel } from '@/shared/types/channels';
import { Box } from '@/shared/ui';

type SelectPermissionLocationProps = {
  /** set the location the campaign is scheduled */
  setPermissionLocations: (location: string[]) => void;
};

export const SelectPermissionLocations = (
  props: SelectPermissionLocationProps
): JSX.Element => {
  const { setPermissionLocations } = props;

  const channels = useChannels();
  const { channelsState } = channels;

  return (
    <Box>
      {channelsState.channels.length > 0 && (
        <MultiSelect
          defaultPlaceholder="Channels"
          defaultSelectedItems={[]}
          isDropdown={true}
          options={channelsState.channels.map((location: Channel) => ({
            type: location?.name || '',
            value: location.id,
          }))}
          parentSelectedItems={(data: { channels: string[] }) =>
            setPermissionLocations(data.channels)
          }
          setParentSelectedItems={(data: { channels: string[] }) =>
            setPermissionLocations(data.channels)
          }
          isCampaigns={true}
        />
      )}
    </Box>
  );
};
