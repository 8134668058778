import { TokensType } from '@/pages/auth/context/AuthProvider';

import { NavigationSettingsType } from '../components/navigation/context/SideNavigationContext';

const PREFIX = '__WHIPPY__';

export const get = (key: string) => {
  const result = localStorage.getItem(`${PREFIX}${key}`);

  if (!result) {
    return null;
  }

  try {
    return JSON.parse(result);
  } catch (e) {
    return result;
  }
};

export const set = (key: string, value: any) => {
  localStorage.setItem(`${PREFIX}${key}`, JSON.stringify(value));
};

const remove = (key: string) => {
  localStorage.removeItem(`${PREFIX}${key}`);
};

const getView = (key: string) => {
  const viewSettings = localStorage.getItem(`${PREFIX}${key}`);
  if (!viewSettings) {
    set(key, {
      showPreview: false,
      showUpdateDate: false,
      showCreateDate: true,
      showSource: true,
    });

    return {
      showPreview: false,
      showUpdateDate: false,
      showCreateDate: true,
      showSource: true,
    };
  }

  try {
    return JSON.parse(viewSettings);
  } catch (e) {
    return viewSettings;
  }
};

const addToLocalStorageObject = function (name: string, key: string, value: boolean) {
  // Get the existing data
  let existing: any = localStorage.getItem(`${PREFIX}${name}`);

  // If no existing data, create an array
  // Otherwise, convert the localStorage string to an array
  existing = existing ? JSON.parse(existing) : {};

  // Add new data to localStorage Array
  existing[key] = value || '';

  // Save back to localStorage
  localStorage.setItem(`${PREFIX}${name}`, JSON.stringify(existing));
};

/*

  Manipulate auth tokens in local storage

*/

export const getAuthTokens = () => get('__AUTH_TOKENS__');

export const setAuthTokens = (tokens: TokensType) => set('__AUTH_TOKENS__', tokens);

export const removeAuthTokens = () => remove('__AUTH_TOKENS__');

/*

  Manipulate view settings in local storage

*/

export const getViewSettings = () => getView('__VIEW_SETTINGS__');

export const setViewSettings = (key: string, value: any) =>
  addToLocalStorageObject('__VIEW_SETTINGS__', key, value);

export const removeViewSettings = () => remove('__VIEW_SETTINGS__');

/*

  Functions to get, set and remove data
  in browser's session storage

*/

const getSession = (key: string) => {
  const result = sessionStorage.getItem(`${PREFIX}${key}`);

  if (!result) {
    return null;
  }

  try {
    return JSON.parse(result);
  } catch (e) {
    return result;
  }
};

const setSession = (key: string, value: any) => {
  sessionStorage.setItem(`${PREFIX}${key}`, value);
};

const removeSession = (key: string) => {
  sessionStorage.removeItem(`${PREFIX}${key}`);
};

/*

  Manipulate organizationId in session storage

*/
export const getOrganizationId = () => getSession('__ORGANIZATION_ID__');

export const setOrganizationId = (organizationId: string) =>
  setSession('__ORGANIZATION_ID__', organizationId);

export const removeOrganizationId = () => removeSession('__ORGANIZATION_ID__');

/*

  Manipulate side navigation settings in local storage

*/

export const getSideNavigationSettings = (name?: string) =>
  get(`__SIDE_NAVIGATION_${name ? name : 'DEFAULT'}_SETTINGS__`);

export const setSideNavigationSettings = (settings: NavigationSettingsType) =>
  set(
    `__SIDE_NAVIGATION_${settings.name ? settings.name : 'DEFAULT'}_SETTINGS__`,
    settings
  );

export const removeSideNavigationSettings = (name?: string) =>
  remove(`__SIDE_NAVIGATION_${name ? name : 'DEFAULT'}_SETTINGS__`);
