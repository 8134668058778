import React from 'react';
import { HiCloudUpload } from 'react-icons/hi';

import { usePageView } from '@/shared/hooks';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
  Button,
  Divider,
  Flex,
  Text,
  VStack,
} from '@/shared/ui';

import { OnboardingLayout } from '.';
import { BulkImportConversation } from './BulkImportConversation';
import { BulkOptInDialog } from './BulkOptInDialog';
import { BulkOptOutContacts } from './BulkOptOutContacts';

export const OnboardingSettings = () => {
  usePageView();

  return (
    <OnboardingLayout>
      <VStack gap={6}>
        <Accordion css={{ background: 'white' }} type="single" collapsible>
          <AccordionItem value="basic-information" variant="neumorphic">
            <Flex justify="between" align="center">
              <VStack gap={2} css={{ lineHeight: 1.5 }}>
                <Text css={{ fontWeight: 600 }}>Bulk Opt-In Contacts</Text>
                <Text>
                  {'Bulk upload contacts you wish to opt-in for bulk messages.'}
                </Text>
              </VStack>
              <AccordionTrigger />
            </Flex>
            <AccordionContent variant="neumorphic">
              <Divider css={{ mt: -20, mb: 20 }} />
              <BulkOptInDialog>
                <Button variant="gray" size={4} as="div" css={{ width: '250px' }}>
                  <HiCloudUpload size={16} /> Click to Upload a File
                </Button>
              </BulkOptInDialog>
            </AccordionContent>
          </AccordionItem>
        </Accordion>
        <Accordion css={{ background: 'white' }} type="single" collapsible>
          <AccordionItem value="basic-information" variant="neumorphic">
            <Flex justify="between" align="center">
              <VStack gap={2} css={{ lineHeight: 1.5 }}>
                <Text css={{ fontWeight: 600 }}>Bulk Opt-Out Contacts</Text>
                <Text>
                  {'Bulk upload contacts you wish to opt out of bulk messages.'}
                </Text>
              </VStack>
              <AccordionTrigger />
            </Flex>
            <AccordionContent variant="neumorphic">
              <Divider css={{ mt: -20, mb: 20 }} />
              <BulkOptOutContacts>
                <Button variant="gray" size={4} as="div" css={{ width: '250px' }}>
                  <HiCloudUpload size={16} /> Click to Upload a File
                </Button>
              </BulkOptOutContacts>
            </AccordionContent>
          </AccordionItem>
        </Accordion>
        <Accordion css={{ background: 'white' }} type="single" collapsible>
          <AccordionItem value="basic-information" variant="neumorphic">
            <Flex justify="between" align="center">
              <VStack gap={2} css={{ lineHeight: 1.5 }}>
                <Text css={{ fontWeight: 600 }}>Bulk Import Conversations</Text>
                <Text>
                  Bulk Import Conversations from another messaging system e.g. ZipWhip
                </Text>
              </VStack>
              <AccordionTrigger />
            </Flex>
            <AccordionContent variant="neumorphic">
              <Divider css={{ mt: -20, mb: 20 }} />
              <BulkImportConversation>
                <Button variant="gray" size={4} as="div" css={{ width: '250px' }}>
                  <HiCloudUpload size={16} /> Click to Upload a File
                </Button>
              </BulkImportConversation>
            </AccordionContent>
          </AccordionItem>
        </Accordion>
      </VStack>
    </OnboardingLayout>
  );
};
