import { APIProvider, Map, Marker } from '@vis.gl/react-google-maps';
import React, { Component, ReactNode } from 'react';
import { HiEye, HiX } from 'react-icons/hi';

import { useDisclosure } from '@/shared/hooks';
import { LinkClickEventType } from '@/shared/types/conversations';
import {
  Box,
  Dialog,
  DialogCloseIcon,
  DialogContent,
  DialogOverlay,
  DialogPortal,
  DialogTitle,
  DialogTrigger,
  Flex,
  IconButton,
  Text,
} from '@/shared/ui';

type LinkClickDialogProps = {
  event: LinkClickEventType;
};

const LinkClickDialog = ({ event }: LinkClickDialogProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Dialog open={isOpen}>
      <DialogTrigger asChild onClick={() => onOpen()}>
        <IconButton size="1">
          <HiEye size={10} />
        </IconButton>
      </DialogTrigger>
      <DialogPortal>
        <DialogOverlay as="div" />
        <DialogContent
          onEscapeKeyDown={onClose}
          css={{
            px: 0,
            width: '80vw',
            height: '80vh',
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            overflow: 'hidden',
            boxSizing: 'border-box',
          }}
        >
          <DialogTitle variant="bold" css={{ px: 20 }}>
            Link Click Event
          </DialogTitle>
          <DialogCloseIcon onClick={() => onClose()} size="2">
            <HiX size="15px" />
          </DialogCloseIcon>
          <Flex css={{ height: '100%' }}>
            <Flex css={{ width: '70%' }}>
              <LinkClickMap event={event} />
            </Flex>
            <Flex css={{ width: '30%', px: 20 }} direction="column">
              <ErrorBoundary>
                <MetadataDisplay event={event} />
              </ErrorBoundary>
            </Flex>
          </Flex>
        </DialogContent>
      </DialogPortal>
    </Dialog>
  );
};

const MAP_KEY = 'AIzaSyDvZkCD8dE8AHfGya8Z_l8coxbhcsKiDvM';

const LinkClickMap = ({ event }: { event: LinkClickEventType }) => {
  const position = {
    lat: event?.metadata?.link_tracking?.geolocation?.lat,
    lng: event?.metadata?.link_tracking?.geolocation?.lon,
  };

  return (
    <APIProvider apiKey={MAP_KEY} libraries={['marker']}>
      <Map
        style={{ width: '100%', height: '100%' }}
        defaultCenter={position}
        defaultZoom={15}
        gestureHandling={'greedy'}
        disableDefaultUI={true}
      >
        <Marker
          position={position}
          clickable={true}
          onClick={() => alert('Marker was clicked!')}
          title={'Link Click Position'}
        />
      </Map>
    </APIProvider>
  );
};

type MetadataDisplayProps = {
  event: LinkClickEventType;
};

const MetadataDisplay = ({ event }: MetadataDisplayProps) => {
  return (
    <Box
      css={{
        borderRadius: '8px',
        padding: '16px',
        paddingRight: '0px',
        backgroundColor: '#fff',
        height: 'calc(100% - 20px)',
        overflowY: 'auto',
      }}
    >
      <Flex css={{ marginBottom: '20px', flexDirection: 'column' }}>
        <Text
          variant="bold"
          css={{
            fontSize: '14px',
            color: '#444',
            marginBottom: '10px',
          }}
        >
          Geolocation:
        </Text>
        {event?.metadata?.link_tracking?.geolocation &&
          Object.entries(event?.metadata?.link_tracking?.geolocation).map(
            ([geoKey, geoValue]) => (
              <Text
                key={geoKey}
                css={{
                  marginBottom: '10px',
                  fontSize: '14px',
                  color: '#555',
                }}
              >
                <span style={{ fontWeight: 'bold' }}>
                  {geoKey
                    .replace(/_/g, ' ')
                    .replace(/\b\w/g, (char) => char.toUpperCase())}
                  :
                </span>{' '}
                {String(geoValue)}
              </Text>
            )
          )}
      </Flex>
      <Box css={{ marginBottom: '16px' }}>
        <Text
          variant="bold"
          css={{
            fontSize: '14px',
            color: '#444',
            marginBottom: '10px',
          }}
        >
          User Agent:
        </Text>
        {event?.metadata?.link_tracking?.user_agent &&
          Object.entries(event?.metadata?.link_tracking?.user_agent).map(
            ([uaKey, uaValue]) => {
              if (typeof uaValue === 'object' && uaValue !== null) {
                return (
                  <Box key={uaKey} css={{ marginBottom: '10px' }}>
                    <Text
                      variant="bold"
                      css={{
                        fontSize: '14px',
                        color: '#555',
                        marginBottom: '5px',
                      }}
                    >
                      {uaKey
                        .replace(/_/g, ' ')
                        .replace(/\b\w/g, (char) => char.toUpperCase())
                        .replace('Os', 'OS')}
                      :
                    </Text>
                    {Object.entries(uaValue).map(([innerKey, innerValue]) => (
                      <Text
                        key={innerKey}
                        css={{
                          marginBottom: '5px',
                          fontSize: '14px',
                          color: '#666',
                        }}
                      >
                        <span style={{ fontWeight: 'bold' }}>
                          {innerKey
                            .replace(/_/g, ' ')
                            .replace(/\b\w/g, (char) => char.toUpperCase())
                            .replace('Os', 'OS')}
                          :
                        </span>{' '}
                        {String(innerValue)}
                      </Text>
                    ))}
                  </Box>
                );
              }
              return (
                <Text
                  key={uaKey}
                  css={{
                    marginBottom: '10px',
                    fontSize: '14px',
                    color: '#555',
                  }}
                >
                  <span style={{ fontWeight: 'bold' }}>
                    {uaKey
                      .replace(/_/g, ' ')
                      .replace(/\b\w/g, (char) => char.toUpperCase())
                      .replace('Os', 'OS')}
                    :
                  </span>{' '}
                  {String(uaValue)}
                </Text>
              );
            }
          )}
      </Box>
    </Box>
  );
};

type ErrorBoundaryProps = {
  children: ReactNode;
};

type ErrorBoundaryState = {
  hasError: boolean;
};

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    console.error('ErrorBoundary caught an error', error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <h1>Something went wrong.</h1>;
    }

    return this.props.children;
  }
}

export default LinkClickDialog;
