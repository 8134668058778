import React from 'react';

import { Tooltip, TooltipContent, TooltipTrigger } from '@/shared/ui';
import { CSS } from '@/stitches.config';

type TooltipButtonProps = {
  /* The button component */
  children: React.ReactNode;
  /* The text to display in the tooltip */
  text: string;
  side?: 'bottom' | 'left' | 'right' | 'top' | undefined;
  sideOffset?: number;
  css?: CSS;
};

export const TooltipButton = (props: TooltipButtonProps) => {
  const { children, text, side, sideOffset, css } = props;
  return (
    <Tooltip>
      <TooltipTrigger asChild>{children}</TooltipTrigger>
      <TooltipContent side={side} sideOffset={sideOffset} css={css}>
        {text}
      </TooltipContent>
    </Tooltip>
  );
};
