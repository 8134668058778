import { Formik, FormikProps } from 'formik';
import React from 'react';
import { CirclePicker } from 'react-color';
import { HiPencil, HiX } from 'react-icons/hi';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';

import { FormFieldWrapper, TextInput } from '@/shared/components/forms';
import { useDisclosure } from '@/shared/hooks';
import {
  Button,
  Dialog,
  DialogClose,
  DialogCloseIcon,
  DialogContent,
  DialogFooter,
  DialogOverlay,
  DialogPortal,
  DialogTitle,
  DialogTrigger,
  IconButton,
  VStack,
} from '@/shared/ui';

import { useGroups } from './context/GroupContext';

export const UpdateGroup = (): JSX.Element => {
  const { updateGroup, deleteGroup, groupsState } = useGroups();
  const current = groupsState.current;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const history = useHistory();

  const handleClose = (formik: FormikProps<any>) => {
    formik.handleReset();
    onClose();
  };

  const onDelete = (id: string) => {
    if (!id) return;
    deleteGroup(id);
    history.push('/contacts');
  };

  return (
    <Dialog open={isOpen}>
      <DialogTrigger asChild>
        <IconButton onClick={onOpen}>
          <HiPencil size={15} />
        </IconButton>
      </DialogTrigger>
      <Formik
        enableReinitialize
        initialValues={{
          name: current?.name,
          color: current?.color,
          id: current?.id,
          filters: current?.filters,
          filters_version: current?.filters_version || 'v1',
        }}
        validationSchema={Yup.object({
          name: Yup.string().required('Required'),
          color: Yup.string().required('Required'),
        })}
        onSubmit={async (values, actions) => {
          try {
            updateGroup({
              name: values?.name || '',
              color: values?.color || '',
              id: current?.id,
              filters_version: current?.filters_version || 'v1',
              filters: current?.filters || { search: [] },
            });
            actions.resetForm({
              values: {
                name: '',
                color: '',
                id: '',
                filters_version: 'v1',
                filters: { search: [] },
              },
            });
            onClose();
          } catch (e) {
            console.log(e);
          }
        }}
      >
        {(formik) => (
          <DialogPortal>
            <DialogOverlay />
            <DialogContent
              onEscapeKeyDown={() => handleClose(formik)}
              onPointerDownOutside={() => handleClose(formik)}
              css={{ overflow: 'hidden' }}
            >
              <form onSubmit={formik.handleSubmit}>
                <DialogTitle variant="bold">Update Group</DialogTitle>
                <VStack gap={2}>
                  <FormFieldWrapper label="Group Name" name="name">
                    <TextInput placeholder="Example name" />
                  </FormFieldWrapper>
                  <FormFieldWrapper label="Group Color" name="color">
                    <CirclePicker
                      color={formik.values['color']}
                      onChangeComplete={(e: { hex: any }) =>
                        formik.setFieldValue('color', e.hex)
                      }
                    />
                  </FormFieldWrapper>
                </VStack>
                <DialogFooter justify="end">
                  <DialogClose asChild>
                    <Button
                      variant="red"
                      css={{ marginRight: '5px' }}
                      type="button"
                      onClick={() => onDelete(current?.id || '')}
                    >
                      Delete Group
                    </Button>
                  </DialogClose>
                  <DialogClose asChild>
                    <Button type="submit">Update Group</Button>
                  </DialogClose>
                </DialogFooter>
                <DialogClose asChild>
                  <DialogCloseIcon onClick={() => handleClose(formik)} size="2">
                    <HiX size="15px" />
                  </DialogCloseIcon>
                </DialogClose>
              </form>
            </DialogContent>
          </DialogPortal>
        )}
      </Formik>
    </Dialog>
  );
};
