/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import i18next from 'i18next';
import { useState } from 'react';
import { HiEye, HiInformationCircle, HiLink, HiPlus, HiTrash } from 'react-icons/hi';
import { toast } from 'sonner';

import { deleteCampaignTemplateAssociation } from '@/shared/api/campaigns/campaign_automation_template';
import { ViewAutomationTemplateDialog } from '@/shared/components/triggers/ViewAutomationTemplateDialog';
import { AutomationTemplate } from '@/shared/types/automations';
import { Campaign } from '@/shared/types/campaigns';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogOverlay,
  AlertDialogPortal,
  AlertDialogTitle,
  Button,
  Flex,
  HStack,
  IconButton,
  Label,
  Skeleton,
  Text,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '@/shared/ui';
import { styled } from '@/stitches.config';

type AsIsAutomationTemplatesProps = {
  /* The current templates */
  templates: Array<AutomationTemplate>;
  /* The current campaign id */
  campaignId: string;
  /* The current associations */
  associations: Array<{
    automation_template_id: string;
    campaign_automation_template_id: string;
  }>;
  /* The current campaign */
  currentCampaign: Campaign | null;
  /* Update the current campaign */
  setCurrentCampaign: (campaign: Campaign | null) => void;
  /* Are we still loading the as-is templates? */
  isLoading: boolean;
  /* Update the current campaign */
  updateCurrentCampaign?: () => Promise<void>;
};

export const AsIsAutomationTemplates = (props: AsIsAutomationTemplatesProps) => {
  const {
    templates,
    campaignId,
    associations,
    currentCampaign,
    setCurrentCampaign,
    isLoading,
    updateCurrentCampaign,
  } = props;

  const [isDialogVisible, setIsDialogVisible] = useState(false);
  const [currentTemplate, setCurrentTemplate] = useState<AutomationTemplate | null>(null);

  // render each trigger in this template
  return (
    <>
      {isDialogVisible && (
        <ViewAutomationTemplateDialog
          template={currentTemplate}
          setIsDialogVisible={setIsDialogVisible}
        />
      )}
      <StyledAsIsAutomationTemplatesContainer>
        {isLoading ? (
          <LoadingContainer />
        ) : (
          <div>
            <HStack>
              <Text style={{ fontSize: 14, fontWeight: 500 }}>Linked Templates</Text>
              <Tooltip>
                <TooltipTrigger style={{ paddingTop: 7 }}>
                  <HiInformationCircle color={'gray'} />
                </TooltipTrigger>
                <TooltipContent>
                  You can modify under Automation Templates page
                </TooltipContent>
              </Tooltip>
            </HStack>
            {templates.map((template: AutomationTemplate) => (
              <AsIsTemplateWrapper
                key={template.id || template.title}
                template={template}
                setCurrentTemplate={setCurrentTemplate}
                campaignId={campaignId}
                associationId={
                  associations.find(
                    (association) => association.automation_template_id === template.id
                  )?.campaign_automation_template_id || ''
                }
                currentCampaign={currentCampaign}
                setCurrentCampaign={setCurrentCampaign}
                setIsDialogVisible={setIsDialogVisible}
                updateCurrentCampaign={updateCurrentCampaign}
              />
            ))}
          </div>
        )}
      </StyledAsIsAutomationTemplatesContainer>
    </>
  );
};

type AsIsTemplateWrapperProps = {
  /* The current template */
  template: AutomationTemplate;
  /* setCurrentTemplate */
  setCurrentTemplate: (template: AutomationTemplate | null) => void;
  /* The current campaign id */
  campaignId: string;
  /* The current association id */
  associationId: string;
  /* The current campaign */
  currentCampaign: Campaign | null;
  /* Update the current campaign */
  setCurrentCampaign: (campaign: Campaign | null) => void;
  /* The action to perform when the dialog is closed */
  setIsDialogVisible: (isDialogVisible: boolean) => void;
  /* Update the current campaign */
  updateCurrentCampaign?: () => Promise<void>;
};

export const AsIsTemplateWrapper = (props: AsIsTemplateWrapperProps) => {
  const {
    template,
    setCurrentTemplate,
    campaignId,
    associationId,
    currentCampaign,
    setCurrentCampaign,
    setIsDialogVisible,
    updateCurrentCampaign,
  } = props;

  const [isRemoveConfirmationDialogOpen, setIsRemoveConfirmationDialogOpen] =
    useState(false);

  const toggleDialog = (template?: AutomationTemplate) => {
    setCurrentTemplate(template || null);
    setIsDialogVisible(true);
  };

  const handleRemoveTemplate = async () => {
    if (template.id) {
      await deleteCampaignTemplateAssociation(campaignId, associationId)
        .then(() => {
          toast.success('Template removed successfully');
          if (currentCampaign && currentCampaign?.id) {
            setCurrentCampaign({
              ...currentCampaign,
              associated_automation_templates:
                currentCampaign?.associated_automation_templates?.filter(
                  (association) =>
                    association.campaign_automation_template_id !== associationId
                ),
            });
            updateCurrentCampaign && updateCurrentCampaign();
          }
        })
        .catch((error) => {
          toast.error('Failed to remove template');
        });
    }
    setIsRemoveConfirmationDialogOpen(false);
  };

  return (
    <CustomizedFlex align="center" justify="between">
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Label css={{ marginLeft: 10, marginTop: 0, marginBottom: 0 }}>
          {template.title}
        </Label>
      </div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {/* Trash bin button to remove the template */}
        <Tooltip>
          <TooltipTrigger asChild>
            <IconButton
              size={2}
              variant="outline"
              onClick={() => setIsRemoveConfirmationDialogOpen(true)}
            >
              <HiTrash />
            </IconButton>
          </TooltipTrigger>
          <StyledTooltipContent>Remove Template</StyledTooltipContent>
        </Tooltip>
        {/* Link button directing to the template page */}
        <Tooltip>
          <TooltipTrigger asChild>
            <IconButton size={2} variant="outline" css={{ ml: 6 }}>
              <a
                href={`/automations/templates/${template.id}`}
                target="_blank"
                rel="noopener noreferrer" // this is for security reasons
              >
                <HiLink />
              </a>
            </IconButton>
          </TooltipTrigger>
          <StyledTooltipContent>Go To Template</StyledTooltipContent>
        </Tooltip>
        {/* Eye button to preview the template in a dialog*/}
        <Tooltip>
          <TooltipTrigger asChild>
            <IconButton
              size={2}
              variant="outline"
              css={{ ml: 6 }}
              onClick={() => toggleDialog(template)}
            >
              <HiEye />
            </IconButton>
          </TooltipTrigger>
          <StyledTooltipContent>Preview Template</StyledTooltipContent>
        </Tooltip>
        {/* Remove template confirmation */}
        <AlertDialog open={isRemoveConfirmationDialogOpen}>
          <AlertDialogPortal>
            <AlertDialogOverlay />
            <AlertDialogContent>
              <AlertDialogTitle>Remove Template</AlertDialogTitle>
              <AlertDialogDescription>
                Are you sure you want to remove this template ?
              </AlertDialogDescription>
              <Flex justify="end">
                <AlertDialogCancel asChild>
                  <Button
                    variant="gray"
                    css={{ mr: 10 }}
                    onClick={() => setIsRemoveConfirmationDialogOpen(false)}
                  >
                    No
                  </Button>
                </AlertDialogCancel>
                <AlertDialogAction asChild>
                  <Button variant="red" onClick={handleRemoveTemplate}>
                    Yes, Remove Template
                  </Button>
                </AlertDialogAction>
              </Flex>
            </AlertDialogContent>
          </AlertDialogPortal>
        </AlertDialog>
      </div>
    </CustomizedFlex>
  );
};

const LoadingContainer = () => {
  return (
    <div>
      <Skeleton css={{ width: 100, height: 20 }} />
      <Skeleton css={{ height: 40 }} />
      <Skeleton css={{ height: 40 }} />
    </div>
  );
};

const CustomizedFlex = styled(Flex, {
  backgroundColor: 'white',
  borderRadius: '5px',
  border: '1px solid #e2e8f0',
  height: '48px',
  width: '100%',
  paddingLeft: '10px',
  paddingRight: '10px',
  marginTop: '8px',
  marginBottom: '8px',
});

const StyledAsIsAutomationTemplatesContainer = styled('div', {
  backgroundColor: '#fafafa',
  padding: '16px',
  marginBottom: '30px',
  borderRadius: '5px', // optional, for a slight rounded corner effect
});

const StyledTooltipContent = styled(TooltipContent, {
  marginBottom: '8px', // to avoid touching the button
});
