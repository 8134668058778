import * as AccordionPrimitive from '@radix-ui/react-accordion';
import React from 'react';

import { PermissionResources } from '@/shared/types/users';
import { AccordionTrigger, Box, Checkbox, Flex } from '@/shared/ui';
import { CSS, styled } from '@/stitches.config';

type PermissionAccordionProps = {
  /* accordion item title */
  title: string;
  /* the accordion value */
  itemValue: PermissionResources;
  /* set the accordion value */
  setAccordions: () => void;
  /* handles the checkbox change all */
  handleToggleAll: (checked: boolean) => void;
  /* custom per item accordion content */
  children: React.ReactNode;
  /* checks if All checkbox is checked */
  isAllChecked: boolean;
};

export const PermissionAccordion = (props: PermissionAccordionProps) => {
  const { title, handleToggleAll, children, setAccordions, itemValue, isAllChecked } =
    props;

  return (
    <StyledItem value={itemValue} defaultValue={itemValue}>
      <StyledHeader>
        <StyledTrigger onClick={setAccordions}>
          <Flex align="center">
            <Box
              css={{
                fontSize: 16,
                lineHeight: '24px',
                fontWeight: 500,
                mr: 6,
                textTransform: 'capitalize',
              }}
            >
              {title}
            </Box>
            <AccordionTrigger />
          </Flex>
        </StyledTrigger>
        <Checkbox
          css={{
            minWidth: '15px',
          }}
          checked={isAllChecked}
          onCheckedChange={handleToggleAll}
          aria-label={`${itemValue}-permissions-select-all-checkbox`}
        />
      </StyledHeader>
      <StyledContent>{children}</StyledContent>
    </StyledItem>
  );
};

type AccordionPrimitiveProps = React.ComponentProps<typeof AccordionPrimitive.Root>;
type AccordionProps = AccordionPrimitiveProps & { css?: CSS };

export const Accordion = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Root>,
  AccordionProps
>(({ children, ...props }, forwardedRef) => (
  <AccordionPrimitive.Root ref={forwardedRef} {...props}>
    {children}
  </AccordionPrimitive.Root>
));

const StyledItem = styled(AccordionPrimitive.Item, {
  backgroundColor: 'white',
});

const StyledHeader = styled(AccordionPrimitive.Header, {
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  backgroundColor: '#F8F9FA',
  px: 24,
  py: 6,
});

const StyledTrigger = styled(AccordionPrimitive.Trigger, {
  all: 'unset',
  boxSizing: 'border-box',
  userSelect: 'none',
  '&::before': {
    boxSizing: 'border-box',
  },
  '&::after': {
    boxSizing: 'border-box',
  },

  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  color: '$hiContrast',
  width: '100%',

  cursor: 'pointer',
});

const StyledContent = styled(AccordionPrimitive.Content, {
  p: 0,
});
