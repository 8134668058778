import { useCallback, useEffect, useMemo, useState } from 'react';
import { HiDuplicate } from 'react-icons/hi';
import { useHistory } from 'react-router-dom';
import { useMedia } from 'react-use';
import { toast } from 'sonner';

import { useDomainsContext } from '@/pages/domains/context/DomainsContext';
import { LinkParams } from '@/shared/api/links';
import { ToolTipIconButton } from '@/shared/components/attachments/previewer';
import { SidebarNavigationContainer } from '@/shared/components/navigation/SideNavigationContainer';
import { Domain } from '@/shared/types/domains';
import {
  AccordionValue,
  CustomCardOptions,
  PanelType,
  UtmBuilderOptions,
} from '@/shared/types/links';
import { Button, Drawer, DrawerContent, DrawerPortal, Flex, VStack } from '@/shared/ui';
import { API_BASE_URL, isDev, isPreview } from '@/shared/utils/config';
import i18next from '@/shared/utils/translation';

import { initialSocialCard, useLinks } from '../context/LinksContext';
import { cleanUrl, isValidUrl } from '../utils';
import { Accordion } from './LinkAccordion';
import LinkInfo from './LinkInfo';
import LinkPanel from './LinkPanel';
import LinkSettings from './LinkSettings';
import LinkToolbar from './LinkToolbar';

type LinkEditorProps = {
  containerComponent: React.ElementType<any>;
};

export const LinkEditor = ({
  containerComponent: WrapperComponent,
}: LinkEditorProps): JSX.Element => {
  const history = useHistory();

  // links state
  const {
    linksState: { current },
    createLink,
    updateLink,
    clearError,
    showPanel,
    setShowPanel,
    ogData,
    setOgData,
  } = useLinks();

  // domains state
  const {
    domainsState: { activeDomains },
    getActiveDomains,
  } = useDomainsContext();

  // set accordion state
  const [accordion, setAccordion] = useState<AccordionValue>(AccordionValue.LINK);

  // handle link side panel state
  // const [showPanel, setShowPanel] = useState(false);
  const [panel, setPanel] = useState<PanelType>(PanelType.VIEW_PREVIEW);

  // open or close the link side panel
  const handlePanelClick = (panelType: PanelType): void => {
    if (panelType === panel) {
      setShowPanel(!showPanel);
    } else {
      setPanel(panelType);
      setShowPanel(true);
    }
  };

  // is this a big screen?
  const isLargeDesktop = useMedia('(min-width: 1350px)');

  // link local state
  const [url, setUrl] = useState(current?.url || '');
  const [shortKey, setShortKey] = useState(current?.shareable_link?.short_key || '');
  const [domainId, setDomainId] = useState(current?.domain?.id || '');
  const [description, setDescription] = useState(current?.description || '');
  const [customCard, setCustomCard] = useState<CustomCardOptions>({
    custom_card_title: current?.custom_card_title || null,
    custom_card_description: current?.custom_card_description || null,
    custom_card_image: current?.custom_card_image || null,
  });
  const [utmBuilder, setUtmBuilder] = useState<UtmBuilderOptions>({
    utm_campaign: current?.utm_campaign || null,
    utm_content: current?.utm_content || null,
    utm_medium: current?.utm_medium || null,
    utm_source: current?.utm_source || null,
    utm_term: current?.utm_term || null,
  });
  const [showCustomCard, setShowCustomCard] = useState(current?.use_custom_card || false);
  const [showUtmBuilder, setShowUtmBuilder] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  // save the current link, either create or update
  const handleSave = async (accordion: AccordionValue) => {
    setIsSubmitting(true);
    const link_params = {
      url,
      description,
      short_key: shortKey,
      domain_id: domainId,
      use_custom_card: showCustomCard,
      ...(showCustomCard ? customCard : {}),
      ...(showUtmBuilder ? utmBuilder : {}),
    } as LinkParams;

    if (current && current?.id) {
      await updateLink(current?.id, link_params);
      setAccordion(accordion);
    } else {
      const link = await createLink(link_params);
      // if the link settings has and id then redirect to the link settings page
      if (link && link.id) {
        history.push(`/campaigns/links/${link.id}/link`);
      }
    }
    setIsSubmitting(false);
  };

  // handle create link and open settings section
  const handleCreateLink = () => {
    handleSave(AccordionValue.SETTINGS);
  };

  useEffect(() => {
    if (!activeDomains.length) {
      getActiveDomains();
    }
  }, []);

  // update accordion state when basic link details are set, otherwise clear state
  useEffect(() => {
    if (current?.url) {
      setAccordion(AccordionValue.SETTINGS);
      if (current?.use_custom_card) {
        setCustomCard({
          custom_card_title: current?.custom_card_title || null,
          custom_card_description: current?.custom_card_description || null,
          custom_card_image: current?.custom_card_image || null,
        });
      }
    } else {
      setShowPanel(false);
      setOgData(initialSocialCard);
    }
  }, [current?.url, current?.use_custom_card]);

  // fetch the url to get metadata for social media cards
  const fetchMeta = async () => {
    try {
      const response = await fetch(`https://www.whippy.ai/api/og?url=${cleanUrl(url)}`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const metadata = await response.text();
      if (metadata) {
        const data = JSON.parse(metadata);
        setOgData({
          custom_card_title: data?.itemTitle || null,
          custom_card_description: data?.itemDescription || null,
          custom_card_image: data?.itemImage
            ? {
                url: data?.itemImage,
                content_type: 'image/jpeg',
              }
            : null,
        });
      }
    } catch (error) {
      console.error('Error fetching HTML:', error);
    }
  };

  useEffect(() => {
    if (isValidUrl(url)) {
      fetchMeta();
    } else {
      setOgData(initialSocialCard);
    }
    clearError();
  }, [url]);

  useEffect(() => {
    if (current?.custom_card_title || accordion === AccordionValue.SETTINGS) {
      setShowCustomCard(true);
      !current?.custom_card_title && setCustomCard(ogData);
    }
  }, [accordion, current?.custom_card_title, ogData]);

  useEffect(() => {
    if (
      accordion !== AccordionValue.SETTINGS &&
      !customCard?.custom_card_title &&
      !customCard?.custom_card_image
    ) {
      setShowCustomCard(false);
    }
  }, [accordion, customCard?.custom_card_title]);

  useEffect(() => {
    if (
      current?.utm_campaign ||
      current?.utm_content ||
      current?.utm_medium ||
      current?.utm_source ||
      current?.utm_term ||
      accordion === AccordionValue.SETTINGS
    ) {
      setShowUtmBuilder(true);
    }
  }, [
    current?.utm_campaign,
    current?.utm_content,
    current?.utm_medium,
    current?.utm_source,
    current?.utm_term,
    accordion,
  ]);

  const isSettingsDirty =
    ((!!customCard?.custom_card_title || !!current?.custom_card_title) &&
      current?.custom_card_title !== customCard?.custom_card_title) ||
    ((!!customCard?.custom_card_description || !!current?.custom_card_description) &&
      current?.custom_card_description !== customCard?.custom_card_description) ||
    ((!!customCard?.custom_card_image?.url || !!current?.custom_card_image?.url) &&
      current?.custom_card_image?.url !== customCard?.custom_card_image?.url) ||
    ((!!description || !!current?.description) && current?.description !== description) ||
    ((!!current?.utm_campaign || !!utmBuilder?.utm_campaign) &&
      current?.utm_campaign !== utmBuilder?.utm_campaign) ||
    ((!!current?.utm_content || !!utmBuilder?.utm_content) &&
      current?.utm_content !== utmBuilder?.utm_content) ||
    ((!!current?.utm_medium || !!utmBuilder?.utm_medium) &&
      current?.utm_medium !== utmBuilder?.utm_medium) ||
    ((!!current?.utm_source || !!utmBuilder?.utm_source) &&
      current?.utm_source !== utmBuilder?.utm_source) ||
    ((!!current?.utm_term || !!utmBuilder?.utm_term) &&
      current?.utm_term !== utmBuilder?.utm_term);

  const isSaveValid =
    !isSubmitting &&
    !!current?.id &&
    (((!!domainId || !!current?.domain?.id) &&
      (current?.domain?.id || '') !== domainId) ||
      current?.shareable_link?.short_key !== shortKey ||
      isSettingsDirty);

  const domainName = useMemo(() => {
    const selectedDomain = activeDomains.find((domain: Domain) => domain.id === domainId);
    const defaultDomain = isDev || isPreview ? API_BASE_URL : 'm.whippy.co';
    return url ? selectedDomain?.name || defaultDomain : '';
  }, [domainId, activeDomains, url]);

  const handleCopyShortLink = useCallback(() => {
    navigator.clipboard.writeText(current?.shareable_link?.short_url || '');
    toast.success(i18next.t('link_url_copied') as string);
  }, [current?.shareable_link?.short_url]);

  return (
    <WrapperComponent
      actions={
        <Flex gap={2}>
          <ToolTipIconButton
            variant="outline"
            description="Copy Short Link"
            icon={<HiDuplicate />}
            onClick={handleCopyShortLink}
            dataTestID="copy-short-link-button"
          />
          <Button
            variant="send"
            disabled={
              current?.url ? !isSaveValid : url.length < 2 || !shortKey || isSubmitting
            }
            onClick={handleCreateLink}
            data-testid="save-link-button"
          >
            {current?.url ? 'Save' : 'Create'}
          </Button>
        </Flex>
      }
      sidebar={
        <Flex css={{ height: '100%' }}>
          {showPanel && isLargeDesktop && (
            <SidebarNavigationContainer
              defaultWidth={360}
              minWidth={300}
              maxWidth={500}
              dragDirection="right"
              name="CREATE_LINK"
              disableCollapse
            >
              <LinkPanel
                setShowPanel={setShowPanel}
                panel={panel}
                customCard={{ ...ogData, ...(showCustomCard ? customCard : {}) }}
                url={domainName}
              />
            </SidebarNavigationContainer>
          )}
          {!isLargeDesktop && (
            <Drawer open={showPanel}>
              <DrawerPortal>
                <DrawerContent
                  side="right"
                  css={{ width: 360, overflowY: 'scroll' }}
                  onEscapeKeyDown={() => setShowPanel(false)}
                  onPointerDownOutside={() => setShowPanel(false)}
                >
                  <LinkPanel
                    setShowPanel={setShowPanel}
                    panel={panel}
                    customCard={{ ...ogData, ...(showCustomCard ? customCard : {}) }}
                    url={domainName}
                  />
                </DrawerContent>
              </DrawerPortal>
            </Drawer>
          )}
          <LinkToolbar open={showPanel} handlePanelClick={handlePanelClick} />
        </Flex>
      }
    >
      <Flex direction="column" css={{ pb: 10 }}>
        <Accordion type="single" defaultValue={AccordionValue.LINK} value={accordion}>
          <VStack css={{ p: 20 }} gap="2">
            <LinkInfo
              url={url}
              setUrl={setUrl}
              shortKey={shortKey}
              setShortKey={setShortKey}
              accordion={accordion}
              setAccordion={setAccordion}
              handleSave={handleSave}
              domainId={domainId}
              setDomainId={setDomainId}
              domains={activeDomains}
              isSubmitting={isSubmitting}
            />
            <LinkSettings
              description={description}
              setDescription={setDescription}
              customCard={customCard}
              setCustomCard={setCustomCard}
              utmBuilder={utmBuilder}
              setUtmBuilder={setUtmBuilder}
              accordion={accordion}
              setAccordion={setAccordion}
              handleSave={handleSave}
              showCustomCard={showCustomCard}
              showUtmBuilder={showUtmBuilder}
              setShowCustomCard={setShowCustomCard}
              setShowUtmBuilder={setShowUtmBuilder}
              isDirty={current?.id ? isSettingsDirty : true}
              isSubmitting={isSubmitting}
            />
          </VStack>
        </Accordion>
      </Flex>
    </WrapperComponent>
  );
};
