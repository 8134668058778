import { styled } from '@/stitches.config';

export const Center = styled('div', {
  boxSizing: 'border-box',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'absolute',

  variants: {
    axis: {
      horizontal: {
        left: '50%',
        transform: 'translateX(-50%)',
      },
      vertical: {
        top: '50%',
        transform: 'translateY(-50%)',
      },
      both: {
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
      },
    },
  },

  defaultVariants: {
    axis: 'both',
  },
});
