/* eslint-disable react-hooks/exhaustive-deps */
import { Formik, useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { HiX } from 'react-icons/hi';
import { v4 as uuidv4 } from 'uuid';
import * as Yup from 'yup';

import { ActionDescription } from '@/pages/automation/ActionDescription';
import { FormFieldWrapper, TextInput } from '@/shared/components/forms';
import { ActionRenderer } from '@/shared/components/triggers';
import {
  ActionWrapper,
  getActionColor,
  getActionIcon,
  getActionTitle,
} from '@/shared/components/triggers/actions/ActionWrapper';
import { AddAction } from '@/shared/components/triggers/actions/AddAction';
import { Action } from '@/shared/types/triggers/actions';
import { Box, Button, Flex, HStack, VStack } from '@/shared/ui';

import { AutomationTriggerContainer } from './KeywordAutomations';

type NoReplyAutomationsProps = {
  no_reply_triggers: any;
  updateTrigger: any;
  deleteTrigger: (trigger_id: string) => void;
  variant?: 'campaign' | 'template';
};

export const NoReplyAutomations = (props: NoReplyAutomationsProps) => {
  const { no_reply_triggers, updateTrigger, deleteTrigger, variant } = props;
  return (
    <VStack gap={4}>
      {no_reply_triggers &&
        no_reply_triggers?.map((no_reply_trigger: any, i: number) => (
          <AutomationTriggerContainer key={i} variant={variant || 'campaign'}>
            <NoReplyForm
              no_reply_trigger={no_reply_trigger}
              updateTrigger={updateTrigger}
              deleteTrigger={deleteTrigger}
            />
          </AutomationTriggerContainer>
        ))}
    </VStack>
  );
};

type NoReplyTriggerFormProps = {
  no_reply_trigger: any;
  updateTrigger: any;
  deleteTrigger: (trigger_id: string) => void;
};

export const NoReplyForm = (props: NoReplyTriggerFormProps) => {
  const { no_reply_trigger, deleteTrigger, updateTrigger } = props;

  const [initialValues, setInitialValues] = useState({
    schedule_options: no_reply_trigger?.schedule_options || [],
    actions: no_reply_trigger?.actions || [],
  });

  useEffect(() => {
    if (no_reply_trigger) {
      setInitialValues({
        schedule_options: no_reply_trigger?.schedule_options || [],
        actions: no_reply_trigger?.actions || [],
      });
    }

    return () => {
      setInitialValues({
        schedule_options: {},
        actions: [],
      });
    };
  }, [props.no_reply_trigger]);

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={Yup.object({
        schedule_options: Yup.object({
          days: Yup.string().required('Required'),
          hours: Yup.string().required('Required'),
          minutes: Yup.string().required('Required'),
        }),
        actions: Yup.array().test(
          'len',
          'Required',
          (arr) => Array.isArray(arr) && arr.length > 0
        ),
      })}
      onSubmit={async (values) => {
        try {
          const params = {
            id: no_reply_trigger.id,
            type: no_reply_trigger.type,
            schedule_options: values.schedule_options,
            actions: values.actions,
          };

          updateTrigger(no_reply_trigger.id, params);
        } catch (e) {
          console.log(e);
        }
      }}
    >
      {(formik) => (
        <form onSubmit={formik.handleSubmit}>
          <NoReplyFromFields
            setInitialValues={setInitialValues}
            no_reply_trigger={no_reply_trigger}
            deleteTrigger={deleteTrigger}
          />
        </form>
      )}
    </Formik>
  );
};

type NoReplyTriggerFromFieldsProps = {
  setInitialValues: any;
  no_reply_trigger: any;
  deleteTrigger: (trigger_id: string) => void;
};

export const NoReplyFromFields = (props: NoReplyTriggerFromFieldsProps) => {
  const { setInitialValues, no_reply_trigger, deleteTrigger } = props;
  const formik = useFormikContext();
  const values = formik.values as {
    schedule_options: {
      days: string;
      hours: string;
      minutes: string;
    };
    actions: Action[];
  };

  const addAction = (action: Action) => {
    // take the action and merge in a tempId key with a uuid v4 value
    const newAction: Action = {
      ...action,
      tempId: uuidv4(),
    };

    if (no_reply_trigger && values.actions) {
      const newActions = [...values.actions, newAction];
      setInitialValues({
        ...values,
        actions: newActions,
      });
    }
  };

  const removeAction = (action: Action) => {
    if (action.id) {
      const newActions = values.actions?.filter((a) => a.id !== action.id);
      setInitialValues({
        ...values,
        actions: newActions,
      });
    } else {
      const newActions = values.actions?.filter((a) => a.tempId !== action.tempId);
      setInitialValues({
        ...values,
        actions: newActions,
      });
    }
  };

  return (
    <VStack gap={2}>
      <ActionWrapper
        title={'No Reply Trigger'}
        description="Trigger an automation when a contact does not reply to a message."
        actionColor={'#2C7675'}
        actionIcon={<HiX />}
        saveAction={formik.handleSubmit}
      >
        <VStack gap={2}>
          <FormFieldWrapper
            label="Days"
            tooltip="Number of days to wait for no reply"
            name="schedule_options.days"
          >
            <TextInput />
          </FormFieldWrapper>
          <FormFieldWrapper
            label="Hours"
            tooltip="Number of hours to wait for no reply"
            name="schedule_options.hours"
          >
            <TextInput />
          </FormFieldWrapper>
          <FormFieldWrapper
            label="Minutes"
            tooltip="Number of minutes to wait for no reply"
            name="schedule_options.minutes"
          >
            <TextInput />
          </FormFieldWrapper>
        </VStack>
      </ActionWrapper>
      {values.actions.map((action, index) => (
        <ActionWrapper
          key={index}
          title={`${getActionTitle(action)}` || ''}
          description={ActionDescription(action)}
          actionColor={`${getActionColor(action)}` || '#2C7675'}
          actionIcon={getActionIcon(action) || ''}
          removeAction={removeAction as any}
          action={action}
          saveAction={formik.handleSubmit}
        >
          <ActionRenderer
            key={index}
            index={index}
            action={action}
            removeAction={removeAction}
          />
        </ActionWrapper>
      ))}
      <Box>
        <AddAction addAction={addAction} />
      </Box>
      <Flex css={{ mt: 20 }}>
        <HStack>
          <Button type="submit">Save Automation</Button>
          <Button
            variant="red"
            type="button"
            onClick={() => deleteTrigger(no_reply_trigger?.id)}
          >
            Delete Automation
          </Button>
        </HStack>
      </Flex>
    </VStack>
  );
};
