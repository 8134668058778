import React from 'react';

import { Channel, ChannelActions, ChannelActionTypes } from '@/shared/types/channels';

import { ChannelsState } from './ChannelContext';

export const ChannelReducer: React.Reducer<ChannelsState, ChannelActions> = (
  state: ChannelsState,
  action: ChannelActions
) => {
  switch (action.type) {
    case ChannelActionTypes.GET_CHANNELS:
      return {
        ...state,
        channels: action.payload,
        loading: false,
      } as ChannelsState;
    case ChannelActionTypes.GET_ALL_CHANNELS:
      return {
        ...state,
        allChannels: action.payload,
        loading: false,
      } as ChannelsState;
    case ChannelActionTypes.ADD_CHANNEL:
      return {
        ...state,
        channels: [action.payload, ...state.channels],
        allChannels: [action.payload, ...state.allChannels],
        filteredChannels: [action.payload, ...state.filteredChannels],
        loading: false,
      } as ChannelsState;
    case ChannelActionTypes.UPDATE_CHANNEL:
      return {
        ...state,
        channels: state.channels.map((channel: Channel) =>
          channel.id === action.payload.id ? action.payload : channel
        ),
        allChannels: state.allChannels.map((channel) =>
          channel.id === action.payload.id ? action.payload : channel
        ),
        loading: false,
      } as ChannelsState;
    case ChannelActionTypes.DISABLE_CHANNEL:
      return {
        ...state,
        channels: state.channels.map((channel: Channel) =>
          channel.id === action.payload.id ? action.payload : channel
        ),
        allChannels: state.allChannels.map((channel) =>
          channel.id === action.payload.id ? action.payload : channel
        ),
        filteredChannels: state.filteredChannels.map((channel: Channel) =>
          channel.id === action.payload.id ? action.payload : channel
        ),
        loading: false,
      } as ChannelsState;
    case ChannelActionTypes.ENABLE_CHANNEL:
      return {
        ...state,
        channels: state.channels.map((channel: Channel) =>
          channel.id === action.payload.id ? action.payload : channel
        ),
        allChannels: state.allChannels.map((channel) =>
          channel.id === action.payload.id ? action.payload : channel
        ),
        filteredChannels: state.filteredChannels.map((channel: Channel) =>
          channel.id === action.payload.id ? action.payload : channel
        ),
        loading: false,
      } as ChannelsState;
    case ChannelActionTypes.ARCHIVE_CHANNEL:
      return {
        ...state,
        channels: state.channels.filter(
          (channel: Channel) => channel.id !== action.payload.id
        ),
        allChannels: state.allChannels.filter(
          (channel: Channel) => channel.id !== action.payload.id
        ),
        filteredChannels: state.filteredChannels.filter(
          (channel: Channel) => channel.id !== action.payload.id
        ),
        loading: false,
      } as ChannelsState;
    case ChannelActionTypes.CLEAR_CHANNELS:
      return {
        ...state,
        channels: [],
        allChannels: [],
        filtered: null,
        error: null,
        current: null,
      } as ChannelsState;
    case ChannelActionTypes.SET_CURRENT:
      return {
        ...state,
        current: action.payload,
      } as ChannelsState;
    case ChannelActionTypes.CLEAR_CURRENT:
      return {
        ...state,
        current: null,
      } as ChannelsState;
    case ChannelActionTypes.UPDATE_FILTER_PARAMS:
      return {
        ...state,
        filterParams: { ...action.payload },
        loading: true,
      };
    case ChannelActionTypes.SEARCH_CHANNELS:
      return {
        ...state,
        filteredChannels: action.payload.data,
        totalCount: action.payload.total,
        loading: false,
      };
    default:
      return state;
  }
};

export default ChannelReducer;
