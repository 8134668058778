import React from 'react';

import { CSS, styled } from '@/stitches.config';

import { Badge, Box, Flex, VStack } from '../ui';

type CreateCardProps = {
  /** icon component */
  icon: React.ReactElement;
  /** card title copy */
  title: string;
  /** card description copy */
  description: string;
  /** card click handler */
  onClick?: () => void;
  /** badge text */
  badgeText?: string;
  /** badge color */
  badgeColor?: Parameters<typeof Badge>[0]['variant'];
  /** disabled state */
  disabled?: boolean;
  /** card styles */
  cardStyles?: CSS;
};

export const CreateCard = (props: CreateCardProps) => {
  const {
    title,
    description,
    onClick,
    icon,
    badgeText,
    badgeColor,
    disabled,
    cardStyles,
  } = props;
  return (
    <CreateCardContainer
      align="center"
      justify="center"
      onClick={onClick}
      disabled={disabled}
      css={cardStyles}
    >
      <VStack align="center" gap={3}>
        {badgeText && badgeColor ? <Badge variant={badgeColor}>{badgeText}</Badge> : null}
        {icon}
        <CreateCardTitle>{title}</CreateCardTitle>
        <CreateCardDescription>{description}</CreateCardDescription>
      </VStack>
    </CreateCardContainer>
  );
};

const CreateCardContainer = styled(Flex, {
  height: 200,
  width: 250,
  borderRadius: 5,
  mx: 10,
  boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.3)',
  cursor: 'pointer',
  p: 20,
  '&:hover': {
    boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.4)',
  },
  variants: {
    disabled: {
      true: {
        backgroundColor: '$slate1',
        cursor: 'not-allowed',
      },
    },
  },
});

const CreateCardTitle = styled(Box, {
  fontSize: 16,
  fontWeight: 500,
  textAlign: 'center',
});

const CreateCardDescription = styled(Box, {
  fontSize: 13,
  fontWeight: 400,
  textAlign: 'center',
});
