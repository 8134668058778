import { HiCheck, HiPause, HiPlay } from 'react-icons/hi';

import { Campaign, CampaignStatus } from '@/shared/types/campaigns';
import { IconButton, Tooltip, TooltipContent, TooltipTrigger } from '@/shared/ui';

export const CampaignActions = ({
  current,
  pauseOneCampaign,
}: {
  current: Campaign | null;
  pauseOneCampaign: (id: string, campaign: Campaign) => void;
}) => {
  return (
    <>
      {current?.status === CampaignStatus.IN_PROGRESS && (
        <TooltipButton text="Pause Campaign Sending">
          <IconButton
            variant="outline"
            size="2"
            onClick={() =>
              current &&
              current.id &&
              pauseOneCampaign(current.id, {
                ...current,
                status: CampaignStatus.PAUSED,
              })
            }
            data-testid="pause-campaign-button"
          >
            <HiPause />
          </IconButton>
        </TooltipButton>
      )}
      {current?.status === CampaignStatus.PAUSED && (
        <TooltipButton text="Resume Campaign">
          <IconButton
            variant="outline"
            size="2"
            onClick={() =>
              pauseOneCampaign(current?.id || '', {
                ...current,
                status: CampaignStatus.IN_PROGRESS,
              })
            }
            data-testid="resume-campaign-button"
          >
            <HiPlay />
          </IconButton>
        </TooltipButton>
      )}
      {current?.status === CampaignStatus.PAUSED && (
        <TooltipButton text="Complete Campaign">
          <IconButton
            variant="outline"
            size="2"
            onClick={() =>
              pauseOneCampaign(current?.id || '', {
                ...current,
                status: CampaignStatus.COMPLETE,
              })
            }
            data-testid="complete-campaign-button"
          >
            <HiCheck />
          </IconButton>
        </TooltipButton>
      )}
    </>
  );
};

type TooltipButtonProps = {
  /* The button component */
  children: React.ReactNode;
  /* The text to display in the tooltip */
  text: string;
};

export const TooltipButton = (props: TooltipButtonProps) => {
  const { children, text } = props;
  return (
    <Tooltip>
      <TooltipTrigger asChild>{children}</TooltipTrigger>
      <TooltipContent>{text}</TooltipContent>
    </Tooltip>
  );
};
