import { FormikValues, useFormikContext } from 'formik';
import React from 'react';

import { TextArea } from '../../ui';
import { TextAreaInputProps } from './types';

/** A basic input that can be used in conjunction with Formik, must be placed within a FormFieldWrapper */
export function TextAreaInput(props: TextAreaInputProps): JSX.Element {
  const formik = useFormikContext<FormikValues>();

  return (
    <TextArea
      {...formik.getFieldProps(props?.name || '')}
      placeholder={props.placeholder}
      aria-label={props.ariaLabel}
      disabled={props.disabled}
      autoComplete="chrome-off"
      data-intercom-target={props.intercomId}
      maxLength={props.maxLength || 99999}
    />
  );
}
