import React from 'react';

import { HelpCard } from '@/pages/settings/help/HelpCard';
import { Audience } from '@/shared/types/audience';
import { AudienceContacts, Contact } from '@/shared/types/campaigns';
import { Avatar, Badge, Box, Button, Flex, Input, Skeleton, VStack } from '@/shared/ui';
import { initials } from '@/shared/utils/initials/initials';
import { formatPhoneNumber } from '@/shared/utils/validations/validations';
import { styled } from '@/stitches.config';

type AudiencePanelProps = {
  /* calculating audience? */
  loadingAudience?: boolean;
  /** array of contacts object that will get the campaign */
  audience?: AudienceContacts | Array<null>;
  /** audience definition object */
  audience_params?: Audience | null;
  /** function to remove a contact from the audience */
  handleExclude?: (id: string) => void;
};

export const AudiencePanel = (props: AudiencePanelProps) => {
  const { audience, handleExclude, loadingAudience } = props;

  // reduce object to array of contacts
  const contacts = (Object.values(audience || {})[0] as Array<Contact>) || [];

  // input search field value and filter contacts
  const [search, setSearch] = React.useState('');

  // filter by name or phone number
  // sort by name alphabetically
  const filteredContacts = contacts
    .filter((contact) => {
      return (
        (contact?.name || '').toLowerCase().includes(search.toLowerCase()) ||
        (contact?.phone || '').toLowerCase().includes(search.toLowerCase())
      );
    })
    .sort((a, b) => {
      return (a.name || '').localeCompare(b.name || '');
    })
    .map((contact) => {
      return {
        ...contact,
        initials: initials(contact.name),
        phone: formatPhoneNumber(contact.phone),
      };
    });

  if (loadingAudience) {
    return <AudienceLoadingState />;
  }

  if (contacts.length === 0 && !loadingAudience) {
    return (
      <AudienceEmptyState
        title="Create Audience"
        description="Select who to send your campaign to"
        loom_id="40308ba501a04ce987b39f8fb2bd8691"
      />
    );
  }

  return (
    <Flex css={{ p: 20, width: '100%' }} direction="column">
      {contacts.length > 0 && (
        <>
          <ContactsCount>Total Contacts: {contacts.length}</ContactsCount>
          <Box css={{ width: '100%', pb: 20 }}>
            <Input
              placeholder="Filter contacts"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </Box>
          <Box>
            {filteredContacts.map((contact) => (
              <ContactCard
                key={contact.id}
                id={contact.id}
                name={contact.name}
                phone={contact?.phone || ''}
                handleExclude={handleExclude}
              />
            ))}
          </Box>
        </>
      )}
    </Flex>
  );
};

type ContactCardProps = {
  /** Contact ID */
  id: string;
  /** Contact name */
  name: string | null;
  /** Contact phone number */
  phone: string;
  /** Array of reasons why a contact is excluded */
  exclusion_criteria?: Array<string>;
  /** Function to remove a contact from the audience */
  handleExclude?: (id: string) => void;
};

export const ContactCard = (props: ContactCardProps) => {
  const { id, name, phone, exclusion_criteria, handleExclude } = props;

  return (
    <Flex css={{ width: '100%', py: 20 }} justify="between">
      <Flex align="center">
        <Box>
          <Avatar fallback={initials(name)} variant="gray" />
        </Box>
        <Flex direction="column" css={{ ml: 12 }}>
          <ContactName>{name || '-'}</ContactName>
          <ContactPhone>{formatPhoneNumber(phone)}</ContactPhone>
        </Flex>
      </Flex>
      {!exclusion_criteria && handleExclude && (
        <Box>
          <Button variant="gray" onClick={() => handleExclude(id)}>
            Exclude
          </Button>
        </Box>
      )}
      {exclusion_criteria && exclusion_criteria.length > 0 && (
        <Box>
          <Badge css={{ mr: 2 }}>{exclusion_criteria[0]}</Badge>
        </Box>
      )}
    </Flex>
  );
};

type AudienceEmptyStateProps = {
  title: string;
  description: string;
  loom_id: string;
};

export const AudienceEmptyState = (props: AudienceEmptyStateProps) => {
  const { title, description, loom_id } = props;
  return (
    <Flex
      css={{
        p: 20,
        height: '100%',
        width: '100%',
      }}
      direction="column"
    >
      <HelpCard title={title} description={description} loom_id={loom_id} />
    </Flex>
  );
};

export const AudienceLoadingState = () => {
  return (
    <Flex css={{ width: '100%', p: 20 }} direction="column">
      <VStack gap="2">
        {Array.from({ length: 20 }, (_: any, k: React.Key | null | undefined) => (
          <Flex
            key={k}
            align="center"
            css={{
              fontSize: 13,
              py: 5,
              px: 5,
              width: '100%',
              height: 40,
              borderRadius: 4,
            }}
          >
            <Skeleton key={k} css={{ height: 20, width: 20, borderRadius: 4, mr: 10 }} />
            <Skeleton key={k} css={{ height: 20, width: '100%', borderRadius: 4 }} />
          </Flex>
        ))}
      </VStack>
    </Flex>
  );
};

export const ContactsCount = styled(Box, {
  fontSize: 16,
  fontWeight: 500,
  pb: 20,
});

export const ContactPhone = styled(Box, {
  color: '#7F7F86',
  fontSize: 14,
});

export const ContactName = styled(Box, {
  color: 'rgba(43, 42, 52, 1)',
  fontSize: 14,
  fontWeight: 500,
  display: '-webkit-box',
  '-webkit-line-clamp': 1,
  '-webkit-box-orient': 'vertical',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  textAlign: 'left',
});
