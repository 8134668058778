import React from 'react';
import { toast } from 'sonner';

import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogPortal,
  AlertDialogTitle,
  Button,
  HStack,
  Text,
} from '@/shared/ui';

type DeleteConfirmationDialogProps = {
  /* is the dialog open? */
  isDeleteConfirmationDialogOpen: boolean;
  /* set the dialog open state */
  setIsDeleteConfirmationDialogOpen: (isOpen: boolean) => void;
  /* the focused api key */
  currentApiKeyId: string | null;
  /* api function to delete the api key */
  deleteApiKey: (id: string) => void;
};

const DeleteConfirmationDialog = (props: DeleteConfirmationDialogProps): JSX.Element => {
  const {
    isDeleteConfirmationDialogOpen,
    setIsDeleteConfirmationDialogOpen,
    deleteApiKey,
    currentApiKeyId,
  } = props;

  const handleDeleteConfirm = async () => {
    try {
      currentApiKeyId && deleteApiKey(currentApiKeyId);
      toast.success('API Key deleted successfully');
    } catch (error) {
      toast.error('Error in deleting API Key');
    }
    setIsDeleteConfirmationDialogOpen(false);
  };

  return (
    <AlertDialog open={isDeleteConfirmationDialogOpen}>
      <AlertDialogPortal>
        <AlertDialogOverlay />
        <AlertDialogContent
          onEscapeKeyDown={() => setIsDeleteConfirmationDialogOpen(false)}
        >
          <AlertDialogTitle>Delete API Key</AlertDialogTitle>
          <Text>Are you sure you want to delete this API Key?</Text>
          <HStack css={{ marginTop: 20, justifyContent: 'flex-end' }}>
            <Button
              variant={'gray'}
              onClick={() => setIsDeleteConfirmationDialogOpen(false)}
            >
              Cancel
            </Button>
            <Button variant={'red'} onClick={handleDeleteConfirm}>
              Confirm
            </Button>
          </HStack>
        </AlertDialogContent>
      </AlertDialogPortal>
    </AlertDialog>
  );
};

export default DeleteConfirmationDialog;
