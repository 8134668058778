import React from 'react';
import { HiX } from 'react-icons/hi';

import {
  Button,
  Dialog,
  DialogClose,
  DialogCloseIcon,
  DialogContent,
  DialogFooter,
  DialogOverlay,
  DialogPortal,
  DialogTitle,
  DialogTrigger,
} from '@/shared/ui';

type ActionDialogProps = {
  /* The content of the dialog */
  children: React.ReactNode;
  /* The trigger to open the dialog */
  trigger: React.ReactNode;
  /* The action to perform when the save button is clicked */
  saveAction?: () => void;
  /* The title of the dialog */
  title?: string;
  /* The copy for the save button */
  saveButtonCopy?: string;
  /* Whether the dialog is open or not */
  isOpen?: boolean;
  /* Callback for when the dialog is opened */
  onOpen?: () => void;
  /* Callback for when the dialog is closed */
  onClose?: () => void;
};

export function ActionDialog(props: ActionDialogProps): JSX.Element {
  const {
    children,
    trigger,
    saveAction,
    title,
    saveButtonCopy,
    isOpen,
    onOpen,
    onClose,
  } = props;

  const handleCloseDialog = () => {
    if (onClose) {
      onClose();
    }
  };

  const handleSave = () => {
    if (saveAction) {
      saveAction();
      if (onClose) {
        onClose();
      }
    }
  };

  const handleOpen = () => {
    if (onOpen) {
      onOpen();
    }
  };

  return (
    <Dialog open={isOpen}>
      <DialogTrigger asChild onClick={() => handleOpen()}>
        {trigger}
      </DialogTrigger>
      <DialogPortal>
        <DialogOverlay />
        <DialogContent
          onEscapeKeyDown={() => handleCloseDialog()}
          onPointerDownOutside={() => handleCloseDialog()}
        >
          <DialogTitle variant="bold">{title || 'Edit Action'}</DialogTitle>
          {children}
          <DialogFooter
            css={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}
          >
            <DialogClose>
              <Button onClick={() => handleSave()}>
                {saveButtonCopy || 'Save Action'}
              </Button>
            </DialogClose>
          </DialogFooter>
          <DialogCloseIcon onClick={() => handleCloseDialog()} size="2">
            <HiX size="15px" />
          </DialogCloseIcon>
        </DialogContent>
      </DialogPortal>
    </Dialog>
  );
}
