import { LayoutContent, MainLayout } from '@/shared/layouts';

import DeveloperProvider from '../developer/context/DevelopersContext';
import IntegrationConfigurationsState from './configurations/context/IntegrationConfigurationContext';
import IntegrationState from './context/IntegrationsContext';

export const IntegrationProvider = ({ children }: { children: React.ReactNode }) => {
  return (
    <DeveloperProvider>
      <IntegrationState>
        <IntegrationConfigurationsState>
          <MainLayout>
            <LayoutContent>{children}</LayoutContent>
          </MainLayout>
        </IntegrationConfigurationsState>
      </IntegrationState>
    </DeveloperProvider>
  );
};
