import { CheckboxFormFieldWrapper, CheckboxInput } from '@/shared/components/forms';
import { Box } from '@/shared/ui';

export const DomainSetup = () => {
  return (
    <Box css={{ color: '#1C2024', fontSize: 14 }}>
      <Box css={{ fontWeight: 500, mb: 4 }}>Set up this domain for:</Box>
      <Box
        css={{
          border: '1px solid #0000301B',
          padding: '12px 12px 0 12px',
          borderRadius: 4,
        }}
      >
        <CheckboxFormFieldWrapper label="Links" name="links">
          <CheckboxInput />
        </CheckboxFormFieldWrapper>
        <CheckboxFormFieldWrapper label="Emails" name="emails">
          <CheckboxInput />
        </CheckboxFormFieldWrapper>
      </Box>
    </Box>
  );
};
