/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useMedia } from 'react-use';

import { useAuth } from '@/pages/auth/context/AuthProvider';
import { CampaignGlobalTemplateTriggersPreview } from '@/pages/campaigns/templates/browse/CampaignGlobalTemplateTriggersPreview';
import { useChannels } from '@/pages/settings/organization/channels/context/ChannelContext';
import { fillPipeFields } from '@/shared/components/editor/Pipes';
import { PageLayout } from '@/shared/layouts/PageLayout';
import {
  AccordionValue,
  BatchDelayTimeUnit,
  Campaign,
  CampaignPermissionTypes,
  CampaignSettings,
  PanelType,
} from '@/shared/types/campaigns';
import {
  Drawer,
  DrawerContent,
  DrawerPortal,
  Fieldset,
  Flex,
  Label,
  VStack,
} from '@/shared/ui';

import { ViewCampaignMessage } from '../../analytics/overview/ViewCampaignMessage';
import { useCampaignsContext } from '../../context/CampaignsContext';
import { Description, getIntervalValue } from '../../create';
import { Accordion, CampaignAccordion } from '../../create/CampaignAccordion';
import { CampaignBasicInfo } from '../../create/CampaignBasicInfo';
import { CampaignSchedule } from '../../create/CampaignSchedule';
import { CampaignToolbar } from '../../create/CampaignToolbar';
import { CampaignPanel } from '../../create/panel';

export const CampaignGlobalTemplatePreview = () => {
  const campaigns = useCampaignsContext();
  const { campaignsState, getCampaignTemplate, setCurrentTemplate } = campaigns;
  const { currentTemplate } = campaignsState;

  const params = useParams<{ id: string }>();

  // on mount get the id from the url and fetch the campaign if the id does not match the current campaign
  useEffect(() => {
    if (params.id && currentTemplate?.id !== params.id) {
      getCampaignTemplate(params.id);
    }
  }, [params.id, currentTemplate?.id]);

  // create current template on unmount
  useEffect(() => {
    return () => {
      setCurrentTemplate(null);
    };
  }, []);

  return <>{currentTemplate && <GlobalTemplatePreview />}</>;
};

export const GlobalTemplatePreview = (): JSX.Element => {
  const campaigns = useCampaignsContext();
  const { campaignsState } = campaigns;
  const { currentTemplate } = campaignsState;
  const auth = useAuth();

  const [accordion, setAccordion] = useState<AccordionValue>(
    AccordionValue.CREATE_MESSAGE
  );

  // campaign title
  const [campaignTitle, setCampaignTitle] = useState(currentTemplate?.title || '');

  // location to set the campaign from
  const [location, setLocation] = useState<string>(
    currentTemplate?.audience?.location || ''
  );

  // permission to use of the campaign template
  const [permission, setPermission] = useState<CampaignPermissionTypes>(
    currentTemplate?.access_level || CampaignPermissionTypes.ORGANIZATION
  );

  // is this a big screen?
  const isLargeDesktop = useMedia('(min-width: 1350px)');

  // update accordion state when basic campaign details are set
  // always default accordion to be CREATE_MESSAGE so that the message preview is opened by default
  useEffect(() => {
    if (currentTemplate && currentTemplate?.title && location) {
      setAccordion(AccordionValue.CREATE_MESSAGE);
    }
  }, [currentTemplate?.title]);

  // handle campaign side panel state
  const [showPanel, setShowPanel] = useState(true);
  const [panel, setPanel] = useState<PanelType>(PanelType.VIEW_PREVIEW);

  // open or close the campaign side panel
  const handlePanelClick = (panelType: PanelType): void => {
    if (panelType === panel) {
      setShowPanel(!showPanel);
    } else {
      setPanel(panelType);
      setShowPanel(true);
    }
  };

  // message body state
  const body = currentTemplate?.body || '';

  // message attachments state
  const attachments = {
    attachment_urls: currentTemplate?.attachment_urls || [],
  };

  // number of messages to send per batch
  const [batchSize, setBatchSize] = useState<number | null>(
    currentTemplate?.delivery_options?.messages_per_period || 100
  );

  // period time unit for batch sending
  const [batchDelayTimeUnit, setBatchDelayTimeUnit] = useState(
    BatchDelayTimeUnit.MINUTES
  );

  // number of minutes between batches
  const [batchDelay, setBatchDelay] = useState<number | null>(
    currentTemplate?.delivery_options?.period
      ? currentTemplate?.delivery_options?.period / 60
      : 5
  );

  // show the user the campaign settings
  const [enableCampaignSettings, setEnableCampaignSettings] = useState(false);
  // show the user the campaign link tracking settings
  const [enableLinkTrackingSettings, setEnableLinkTrackingSettings] = useState(false);

  // id any of the three currentTemplate campaign settings are enabled
  // then show the user the campaign settings on mount
  useEffect(() => {
    if (
      currentTemplate?.settings?.support_sms_quiet_hours?.enabled ||
      currentTemplate?.settings?.skip_sending_on_weekend?.enabled ||
      currentTemplate?.settings?.only_send_during_business_hours?.enabled
    ) {
      setEnableCampaignSettings(true);
    }
    if (
      currentTemplate?.settings?.link_tracking?.disabled ||
      currentTemplate?.settings?.link_tracking?.domain_id
    ) {
      setEnableLinkTrackingSettings(true);
    }
  }, []);

  // campaign settings options for sending and scheduling messages
  const [campaignSettings, setCampaignSettings] = useState<CampaignSettings>({
    support_sms_quiet_hours: {
      enabled: currentTemplate?.settings?.support_sms_quiet_hours?.enabled || false,
      use_contact_timezone:
        currentTemplate?.settings?.support_sms_quiet_hours?.use_contact_timezone || false,
    },
    skip_sending_on_weekend: {
      enabled: currentTemplate?.settings?.skip_sending_on_weekend?.enabled || false,
      use_contact_timezone:
        currentTemplate?.settings?.skip_sending_on_weekend?.use_contact_timezone || false,
    },
    only_send_during_business_hours: {
      enabled:
        currentTemplate?.settings?.only_send_during_business_hours?.enabled || false,
      use_contact_timezone:
        currentTemplate?.settings?.only_send_during_business_hours
          ?.use_contact_timezone || false,
    },
    link_tracking: {
      disabled: currentTemplate?.settings?.link_tracking?.disabled || false,
      domain_id: currentTemplate?.settings?.link_tracking?.domain_id || null,
    },
  });

  // show the batch delay in correct time units
  useEffect(() => {
    if (currentTemplate?.delivery_options?.period) {
      const delayInMinutes = currentTemplate?.delivery_options?.period / 60;
      const hourInMinutes = getIntervalValue(BatchDelayTimeUnit.HOURS);
      const dayInMinutes = getIntervalValue(BatchDelayTimeUnit.DAYS);
      if (delayInMinutes >= hourInMinutes && delayInMinutes % hourInMinutes === 0) {
        setBatchDelay(delayInMinutes / hourInMinutes);
        setBatchDelayTimeUnit(BatchDelayTimeUnit.HOURS);
      }
      if (delayInMinutes >= dayInMinutes && delayInMinutes % dayInMinutes === 0) {
        setBatchDelay(delayInMinutes / dayInMinutes);
        setBatchDelayTimeUnit(BatchDelayTimeUnit.DAYS);
      }
    }
  }, [currentTemplate?.delivery_options?.period]);

  // We don't allow user to save template when we are under preview
  const handleSave = () => {
    return;
  };

  const hasTriggersOrTemplates =
    (currentTemplate &&
      currentTemplate.triggers &&
      currentTemplate.triggers.length > 0) ||
    (currentTemplate &&
      currentTemplate.associated_automation_templates &&
      currentTemplate.associated_automation_templates.length > 0)
      ? true
      : false;

  const channels = useChannels();
  const { getChannelById } = channels;
  const locationObject = getChannelById(location);

  const previewName = auth?.tokens?.name || 'John Doe';

  const fillDemoMessage = () => {
    const organizationName = auth?.organizationName;

    // fill message pipe fields
    const filled_body = fillPipeFields(
      body,
      previewName || 'John Doe',
      locationObject?.name || '',
      locationObject?.address || '',
      locationObject?.google_place_id || '',
      organizationName || ''
    );

    return filled_body;
  };

  return (
    <Flex css={{ minHeight: '100%' }}>
      <PageLayout
        breadcrumbs={[
          { title: 'Campaigns', path: '/campaigns' },
          { title: 'Templates', path: '/campaigns/templates/browse' },
          {
            title: `${currentTemplate?.title || 'Create'}`,
            path: `/campaigns/templates/browse/${currentTemplate?.id || 'create'}`,
          },
        ]}
        width={'calc(100% - 55px)'}
      >
        <Flex direction="column" css={{ pb: 200 }}>
          <Accordion
            type="single"
            defaultValue={AccordionValue.SELECT_LOCATION}
            value={accordion}
          >
            <VStack css={{ p: 20 }} gap="2">
              <CampaignBasicInfo
                accordion={accordion}
                setAccordion={setAccordion}
                campaignTitle={campaignTitle}
                setCampaignTitle={setCampaignTitle}
                location={location}
                setLocation={setLocation}
                handleSave={handleSave}
                permission={permission}
                setPermission={setPermission}
                showSaveButton={false} // don't allow edit and don't show the save button
                showSelectLocation={false} // don't show the location select
                showSelectPermission={false} // don't show the permission select
                showSelectPhoneNumber={false} // don't show the phone number select
                disableEdit={true} // don't allow edit
                hideIndex={true} // don't show the index
              />
              {/* The following section is for Messages Preview */}
              <ViewCampaignMessage
                campaign={currentTemplate as Campaign}
                accordion={accordion}
                setAccordion={setAccordion}
                showExpand={true}
                hideIndex={true} // don't show the index
              />
              <CampaignSchedule
                accordion={accordion}
                showSchedulePicker={false} // don't show the schedule picker
                setAccordion={setAccordion}
                handleSave={handleSave}
                enableBatchSend={false} // in preview, we don't allow user to change batch send
                setEnableBatchSend={() => null} // in preview, we don't allow user to change batch send
                batchSize={batchSize}
                setBatchSize={setBatchSize}
                batchDelay={batchDelay}
                setBatchDelay={setBatchDelay}
                batchDelayTimeUnit={batchDelayTimeUnit}
                setBatchDelayTimeUnit={setBatchDelayTimeUnit}
                enableCampaignSettings={enableCampaignSettings}
                setEnableCampaignSettings={setEnableCampaignSettings}
                campaignSettings={campaignSettings}
                setCampaignSettings={setCampaignSettings}
                enableLinkTrackingSettings={enableLinkTrackingSettings}
                setEnableLinkTrackingSettings={setEnableLinkTrackingSettings}
                showSaveButton={false} // don't allow edit and don't show the save button
                disableEdit={true} // don't allow edit
                hideIndex={true} // don't show the index
              />
              {/* The following section is for Automations accordion */}
              <CampaignAccordion
                index={4}
                title="Automations"
                description="Automate campaign follow up tasks."
                currentAccordionValue={accordion}
                itemValue={AccordionValue.CREATE_AUTOMATION}
                setItemValue={setAccordion}
                isValid={hasTriggersOrTemplates}
                buttonCopy="Expand"
                hideIndex={true} // don't show the index
              >
                <VStack gap="2">
                  <Fieldset>
                    <Flex direction="column">
                      <Label>Create Campaign Automation (optional)</Label>
                      <Description>
                        Campaign automations let you take actions when contacts respond to
                        your campaigns.
                      </Description>
                    </Flex>
                  </Fieldset>
                  {/* The following section is for automations (triggers) under this template */}
                  {/* Global template won't have any as-is templates because as-is templates are linked to personal account */}
                  <CampaignGlobalTemplateTriggersPreview campaign={currentTemplate} />
                </VStack>
              </CampaignAccordion>
            </VStack>
          </Accordion>
        </Flex>
      </PageLayout>

      {/* The following section is for Campaign Side Panel */}
      <Flex css={{ height: '100%' }}>
        {showPanel && isLargeDesktop && (
          <CampaignPanel
            setShowPanel={setShowPanel}
            panel={panel}
            attachments={attachments.attachment_urls || []}
            preview={fillDemoMessage()}
            body={body}
          />
        )}
        {!isLargeDesktop && (
          <Drawer open={showPanel}>
            <DrawerPortal>
              <DrawerContent
                side="right"
                css={{ width: 360, overflowY: 'scroll' }}
                onEscapeKeyDown={() => setShowPanel(false)}
                onPointerDownOutside={() => setShowPanel(false)}
              >
                <CampaignPanel
                  setShowPanel={setShowPanel}
                  panel={panel}
                  attachments={attachments.attachment_urls || []}
                  preview={fillDemoMessage()}
                  body={body}
                />
              </DrawerContent>
            </DrawerPortal>
          </Drawer>
        )}
        <CampaignToolbar
          handlePanelClick={handlePanelClick}
          open={showPanel}
          hideViewAudience={true}
          hideViewExcludedAudience={true}
        />
      </Flex>
    </Flex>
  );
};
