import React from 'react';
import { HiChartBar, HiPaperAirplane, HiSpeakerphone } from 'react-icons/hi';
import { useLocation } from 'react-router-dom';
import { useMedia } from 'react-use';

import { useSidenavigationSettings } from '@/shared/components/navigation/context/SideNavigationContext';
import {
  SideNavigation,
  sideNavigationGroup,
} from '@/shared/components/navigation/SideNavigation';
import {
  SidebarNavigationContainer,
  SidebarNavigationWrapper,
} from '@/shared/components/navigation/SideNavigationContainer';
import { LayoutContent, MainLayout } from '@/shared/layouts';

import ReportsState from '../context/ReportsContext';

export function ReportsNavigation(): JSX.Element {
  const location = useLocation();
  const { navigationSettings, toggleExpandedState } = useSidenavigationSettings();

  const reportsSideNavigationTabs: Array<sideNavigationGroup> = [
    {
      sideNavigationGroup: [
        {
          title: 'Overview',
          image: <HiChartBar size={18} />,
          isActive: location.pathname.includes('/reports/overview'),
          link: '/reports/overview',
        },
        {
          title: 'Messaging',
          image: <HiPaperAirplane size={18} style={{ transform: 'rotate(90deg)' }} />,
          isActive: location.pathname.includes('/reports/messaging'),
          link: '/reports/messaging',
        },
        {
          title: 'Campaigns',
          image: <HiSpeakerphone size={18} />,
          isActive: location.pathname.includes('/reports/campaigns'),
          link: '/reports/campaigns',
        },
      ],
    },
  ];

  return (
    <SidebarNavigationContainer>
      <SidebarNavigationWrapper>
        <SideNavigation
          expanded={navigationSettings.expanded}
          header="Reports"
          sideNavigationTabs={reportsSideNavigationTabs}
          toggleExpandedState={toggleExpandedState}
        />
      </SidebarNavigationWrapper>
    </SidebarNavigationContainer>
  );
}

export const ReportsContainer = ({ children }: { children: React.ReactNode }) => {
  const isDesktop = useMedia('(min-width: 912px)');

  return (
    <ReportsState>
      <MainLayout>
        {isDesktop && <ReportsNavigation />}
        <LayoutContent>{children}</LayoutContent>
      </MainLayout>
    </ReportsState>
  );
};
