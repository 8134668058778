import dayjs from 'dayjs';
import { useCallback } from 'react';
import { HiDuplicate, HiRefresh } from 'react-icons/hi';
import { useMedia } from 'react-use';
import { toast } from 'sonner';

import { ConfirmationDialog } from '@/shared/components/ConfirmationDialog';
import { Domain, DomainSetupType, DomainStatusType } from '@/shared/types/domains';
import {
  Badge,
  Box,
  Button,
  Flex,
  HStack,
  IconButton,
  Skeleton,
  VStack,
} from '@/shared/ui';
import { MONTH_DAY_YEAR } from '@/shared/utils/timestamps';
import i18next from '@/shared/utils/translation';
import { keyframes, styled } from '@/stitches.config';

import { CreatedByUser } from '../list/CreatedByUser';
import { EditDomain } from '../list/EditDomain';
import { getBadgeVariant } from '../utils/utils';
import { DNSRecords } from './DNSRecords';
import { SetupResults } from './SetupResults';
import { SetupSteps } from './SetupSteps';
import { VerificationStatusBadge } from './VerificationStatusBadge';

export const SetupProgress = ({
  loadingDomain,
  current,
  refreshing,
  handleRefresh,
  handleDelete,
  handleUpdate,
  type,
  title,
}: {
  loadingDomain: boolean;
  current: Domain | null;
  refreshing: boolean;
  handleRefresh: () => Promise<void>;
  handleDelete: () => Promise<void>;
  handleUpdate: (type: DomainSetupType) => Promise<void>;
  type?: DomainSetupType;
  title: string;
}) => {
  const isMobile = useMedia('(max-width: 700px)');

  const handleCopy = useCallback(() => {
    navigator.clipboard.writeText(current?.name || '');
    toast.success(i18next.t('domain_name_copied') as string);
  }, [current?.name]);

  return (
    <ContentWrapper gap={0} data-testid="setup-progress">
      <DomainHeading align="center" justify="between">
        {loadingDomain ? (
          <HStack>
            <Skeleton variant="title" css={{ width: 101, m: 0, borderRadius: 7 }} />
            <Skeleton
              variant="title"
              css={{ width: 57, m: 0, ml: 12, borderRadius: 7 }}
            />
          </HStack>
        ) : (
          <Flex direction={'row'} align={'center'}>
            {type ? (
              <>
                <Box
                  data-testid="setup-progress-title"
                  css={{ fontSize: 18, fontWeight: 700 }}
                >
                  {title}
                </Box>
                {current?.[`${type}_status`] && (
                  <StatusBadge variant={getBadgeVariant(current?.[`${type}_status`])}>
                    {current?.[`${type}_status`]}
                  </StatusBadge>
                )}
              </>
            ) : (
              <>
                <DomainName data-testid="setup-progress-domain">
                  {current?.name}
                </DomainName>
                <IconButton onClick={handleCopy}>
                  <HiDuplicate size={'20px'} color="gray" />
                </IconButton>
                {current?.status && (
                  <StatusBadge variant={getBadgeVariant(current?.status)}>
                    {current?.status}
                  </StatusBadge>
                )}
              </>
            )}
          </Flex>
        )}
        <Flex direction={'row'} gap={2}>
          <IconButton
            onClick={handleRefresh}
            variant={'outline'}
            size={2}
            data-testid="refresh-domain-button"
          >
            <RefreshIcon size={'16px'} spin={refreshing} />
          </IconButton>
          {!type && <EditDomain />}
          {current && (
            <ConfirmationDialog
              title={`Delete ${type ? `${type} setup` : 'domain'}`}
              description={`Are you sure want to delete this ${type ? `${type} setup` : 'domain'}? This action cannot be undone.`}
              onConfirm={type ? () => handleUpdate(type) : handleDelete}
              confirmButtonTitle={`Yes, Delete  ${type ? `${type} setup` : 'domain'}`}
              testID="delete-domain-button"
            >
              <Button
                css={{ boxShadow: 'inset 0 0 0 1px #D100036F!important' }}
                onClick={(e) => e.preventDefault()}
                variant="red"
                disabled={current?.is_domain_shared || loadingDomain}
              >
                Delete
              </Button>
            </ConfirmationDialog>
          )}
        </Flex>
      </DomainHeading>
      {!type && (
        <CreatedRow isMobile={isMobile}>
          <CreatedText isMobile={isMobile}>
            Created at{' '}
            {loadingDomain ? (
              <Skeleton
                variant="title"
                css={{ width: 86, m: 0, ml: 4, borderRadius: 7 }}
              />
            ) : (
              <Black>{dayjs(current?.inserted_at).format(MONTH_DAY_YEAR)}</Black>
            )}
          </CreatedText>
          {current?.created_by && (
            <>
              <CreatedText isMobile={isMobile}>
                <span>by </span>
                {loadingDomain ? (
                  <Skeleton
                    variant="title"
                    css={{ width: 122, m: 0, ml: 4, borderRadius: 7 }}
                  />
                ) : (
                  <CreatedByUser user={current?.created_by} />
                )}
              </CreatedText>
            </>
          )}
        </CreatedRow>
      )}
      {loadingDomain ? (
        <HStack css={{ mt: 5 }}>
          <Skeleton variant="title" css={{ width: 157, m: 0, mr: 32 }} />
        </HStack>
      ) : (
        <>
          {current?.status !== DomainStatusType.DELETED &&
            current?.status !== DomainStatusType.EXPIRED && (
              <VerificationStatusBadge type={type} domain={current} />
            )}
        </>
      )}
      {!type && <SetupResults domain={current} loading={loadingDomain} />}
      {!loadingDomain && (
        <>
          <SetupSteps domain={current} title={title} type={type} />
          <DNSRecords type={type} domain={current} />
        </>
      )}
    </ContentWrapper>
  );
};

const CreatedRow = styled(HStack, {
  mb: 12,
  variants: {
    isMobile: {
      true: {
        fd: 'column',
      },
    },
  },
});

const CreatedText = styled(HStack, {
  color: '#687076',
  mr: 20,
  fontSize: '14px',
  fontWeight: '500',
  variants: {
    isMobile: {
      true: {
        mx: 0,
        mb: 10,
        width: '100%',
      },
    },
  },
});

const Black = styled('span', {
  color: '$blackA11',
  ml: 6,
});

export const ContentWrapper = styled(VStack, {
  backgroundColor: 'white',
  overflow: 'auto',
  width: '100%',
  border: '1px solid #EDEDED',
  borderRadius: 8,
  p: 24,
});

export const DomainName = styled(Box, {
  fontSize: 20,
  fontWeight: 700,
  color: '$primaryButtonColor',
  mr: 4,
});

export const DomainHeading = styled(Flex, {
  width: '100%',
  marginBottom: 12,
});

export const Value = styled(Box, {
  fontWeight: '500',
  wordBreak: 'break-word',
});

export const VerificationTable = styled(HStack, {
  p: 20,
  backgroundColor: '$slate3',
  variants: {
    isMobile: {
      true: {
        fd: 'column',
      },
    },
  },
});

export const Column = styled(VStack, {
  mr: 60,
  variants: {
    isMobile: {
      true: {
        mr: 0,
        mb: 20,
        width: '100%',
      },
    },
    isLast: {
      true: {
        mr: 0,
        mb: 0,
      },
    },
  },
});

const spinner = keyframes({
  to: {
    transform: 'rotate(360deg)',
  },
});

const RefreshIcon = styled(HiRefresh, {
  variants: {
    spin: {
      true: {
        animation: `${spinner} 1.5s infinite`,
      },
    },
  },
});

const StatusBadge = styled(Badge, {
  textTransform: 'capitalize',
  ml: 15,
  fontSize: '$1',
  fontWeight: '500',
});
