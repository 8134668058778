export const assignmentMethods = [
  { label: 'Manual', value: 'manual' },
  { label: 'Round Robin', value: 'round_robin' },
  { label: 'Balanced', value: 'balanced' },
];

export const weekDays = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
];

export const initialWorkSchedules = [
  {
    ends_at: '23:59',
    starts_at: '00:00',
    weekday: 'Monday',
    state: 'closed',
  },
  {
    ends_at: '23:59',
    starts_at: '00:00',
    weekday: 'Tuesday',
    state: 'closed',
  },
  {
    ends_at: '23:59',
    starts_at: '00:00',
    weekday: 'Wednesday',
    state: 'closed',
  },
  {
    ends_at: '23:59',
    starts_at: '00:00',
    weekday: 'Thursday',
    state: 'closed',
  },
  {
    ends_at: '23:59',
    starts_at: '00:00',
    weekday: 'Friday',
    state: 'closed',
  },
  {
    ends_at: '23:59',
    starts_at: '00:00',
    weekday: 'Saturday',
    state: 'closed',
  },
  {
    ends_at: '23:59',
    starts_at: '00:00',
    weekday: 'Sunday',
    state: 'closed',
  },
];
