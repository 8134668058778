import { api } from './api';

export type WebchatSettingsParams = {
  id?: string;
  color?: string;
  greeting?: string;
  position?: string;
  show_when?: string;
  attachments?: any;
};

export type WebchatInstallParams = {
  organization_name: string;
  organization_id: string;
  user_name: string;
  user_email: string;
  to: string;
};
/*
  PUT - update a users's role
*/

export const updateWebchat = (webchatSettingsParams: WebchatSettingsParams) =>
  api.put(`/widget_settings`, { widget_settings: webchatSettingsParams }).then((res) => {
    return res.data.data;
  });

export const sendInstructions = (webchatInstallParams: WebchatInstallParams) =>
  api
    .post('/widget_settings/instructions', {
      widget_settings: webchatInstallParams,
    })
    .then((res) => {
      return res.data.data;
    });
