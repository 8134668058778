import { styled } from '@stitches/react';
import { ActionId, ActionImpl, useKBar } from 'kbar';
import { forwardRef, Fragment, useMemo } from 'react';

import { KbdStyled } from '../utils';

export const ActionItem = forwardRef(
  (
    {
      action,
      active,
      currentRootActionId,
    }: {
      action: ActionImpl;
      active: boolean;
      currentRootActionId: ActionId | string | null | undefined;
    },
    ref: React.Ref<HTMLDivElement>
  ) => {
    const { query } = useKBar();

    const ancestors = useMemo(() => {
      if (!currentRootActionId) return action.ancestors ?? [];
      const index = action.ancestors.findIndex(
        (ancestor) => ancestor.id === currentRootActionId
      );
      // +1 removes the currentRootAction; e.g.
      // if we are on the "Set theme" parent action,
      // the UI should not display "Set theme… > Dark"
      // but rather just "Dark"
      return action.ancestors.slice(index + 1) ?? [];
    }, [action.ancestors, currentRootActionId]);

    const shortcutAction = action.shortcut?.map((sc) => sc).join('+');

    return (
      <ActionContainer
        active={active}
        ref={ref}
        onClick={() => {
          action?.command?.perform();
          query.toggle();
        }}
      >
        <div
          style={{
            display: 'flex',
            gap: '8px',
            alignItems: 'center',
            fontSize: 14,
          }}
        >
          {action.icon && action.icon}
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div>
              {ancestors.length > 0 &&
                ancestors.map((ancestor) => (
                  <Fragment key={ancestor.id}>
                    <span
                      style={{
                        opacity: 0.5,
                        marginRight: 8,
                      }}
                    >
                      {ancestor.name}
                    </span>
                    <span
                      style={{
                        marginRight: 8,
                      }}
                    >
                      &rsaquo;
                    </span>
                  </Fragment>
                ))}
              <span>{action.name}</span>
            </div>
            {action.subtitle && <span style={{ fontSize: 12 }}>{action.subtitle}</span>}
          </div>
        </div>
        {action.shortcut?.length ? (
          <div
            aria-hidden
            style={{ display: 'grid', gridAutoFlow: 'column', gap: '4px' }}
          >
            <KbdStyled>{shortcutAction}</KbdStyled>
          </div>
        ) : null}
      </ActionContainer>
    );
  }
);

const ActionContainer = styled('div', {
  padding: '12px 16px',
  background: 'transparent',
  borderLeft: '2px solid transparent',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  cursor: 'pointer',
  '&:hover': {
    background: '$gray1',
    backgroundColor: '$gray3',
  },
  variants: {
    active: {
      true: {
        background: '$gray1',
        borderLeftColor: '$foreground',
        backgroundColor: '$gray3',
      },
    },
  },
});
