export enum ActionTypes {
  SEND_MESSAGE = 'send_message',
  ADD_TAGS = 'add_tags',
  REMOVE_TAGS = 'remove_tags',
  ASSIGN_USER = 'assign_user',
  UNASSIGN_USER = 'unassign_user',
  REMOVE_FROM_SEQUENCE = 'remove_from_sequence',
  ADD_TO_SEQUENCE = 'add_to_sequence',
  OPT_IN = 'opt_in',
  OPT_OUT = 'opt_out',
  NOTIFY_USERS = 'notify_users',
  SEND_SMS = 'send_sms',
  SEND_EMAIL = 'send_email',
  TRIGGER_WEBHOOK = 'trigger_webhook',
  UPDATE_CONVERSATION_STATUS = 'update_conversation_status',
  MOVE_TO_STEP = 'move_to_step',
}

export type Action =
  | SendMessageAction
  | AddTagsAction
  | RemoveTagsAction
  | AssignUserAction
  | UnassignUserAction
  | RemoveFromSequenceAction
  | MoveToStepAction
  | AddToSequenceAction
  | OptInAction
  | OptOutAction
  | NotifyUsersAction
  | SendSmsAction
  | SendEmailAction
  | TriggerWebhookAction
  | UpdateConversationStatusAction;

interface BaseAction {
  /** action uuid */
  id?: string;
  /** temp uuid */
  tempId?: string;
  /** is the action enabled? */
  enabled: boolean;
  /** action type */
  type: ActionTypes;
  /** organization_id */
  organization_id: string;
  /** when the action was created */
  inserted_at: string;
  /** when the action was updated */
  updated_at: string;
}

export type ActionParams =
  | SendMessageActionParams
  | AddTagsActionParams
  | RemoveTagsActionParams
  | AssignUserActionParams
  | UnassignUserActionParams
  | AddToSequenceActionParams
  | RemoveFromSequenceActionParams
  | OptInActionParams
  | OptOutActionParams
  | NotifyUsersActionParams
  | SendSmsActionParams
  | SendEmailActionParams
  | TriggerWebhookActionParams
  | MoveToStepActionParams
  | UpdateConversationStatusActionParams;

export type ActionProps = {
  /* The action to display */
  index: number;
  /* The action to display */
  action: Action;
  /* The action to add when the delete button is clicked */
  removeAction?: (action: Action) => void;
};

export interface SendMessageAction extends BaseAction {
  type: ActionTypes.SEND_MESSAGE;
  params: SendMessageActionParams;
}

export interface AddTagsAction extends BaseAction {
  type: ActionTypes.ADD_TAGS;
  params: AddTagsActionParams;
}

export interface RemoveTagsAction extends BaseAction {
  type: ActionTypes.REMOVE_TAGS;
  params: RemoveTagsActionParams;
}

export interface AssignUserAction extends BaseAction {
  type: ActionTypes.ASSIGN_USER;
  params: AssignUserActionParams;
}

export interface UnassignUserAction extends BaseAction {
  type: ActionTypes.UNASSIGN_USER;
  params: UnassignUserActionParams;
}

export interface RemoveFromSequenceAction extends BaseAction {
  type: ActionTypes.REMOVE_FROM_SEQUENCE;
  params: RemoveFromSequenceActionParams;
}

export interface AddToSequenceAction extends BaseAction {
  type: ActionTypes.ADD_TO_SEQUENCE;
  params: AddToSequenceActionParams;
}

export interface MoveToStepAction extends BaseAction {
  type: ActionTypes.MOVE_TO_STEP;
  params: MoveToStepActionParams;
}

export interface OptInAction extends BaseAction {
  type: ActionTypes.OPT_IN;
  params: OptInActionParams;
}

export interface OptOutAction extends BaseAction {
  type: ActionTypes.OPT_OUT;
  params: OptOutActionParams;
}

export interface NotifyUsersAction extends BaseAction {
  type: ActionTypes.NOTIFY_USERS;
  params: NotifyUsersActionParams;
}

export interface SendSmsAction extends BaseAction {
  type: ActionTypes.SEND_SMS;
  params: SendSmsActionParams;
}

export interface SendEmailAction extends BaseAction {
  type: ActionTypes.SEND_EMAIL;
  params: SendEmailActionParams;
}

export interface TriggerWebhookAction extends BaseAction {
  type: ActionTypes.TRIGGER_WEBHOOK;
  params: TriggerWebhookActionParams;
}

export interface UpdateConversationStatusAction extends BaseAction {
  type: ActionTypes.UPDATE_CONVERSATION_STATUS;
  params: UpdateConversationStatusActionParams;
}

type SendMessageActionParams = {
  body: string;
  attachment_urls: string[];
  attachments: string[];
};

type AddTagsActionParams = {
  tag_ids: string[];
};

type RemoveTagsActionParams = {
  tag_ids: string[];
};

type MoveToStepActionParams = {
  step_id: string;
  sequence_id: string;
};

type AssignUserActionParams = {
  user_id: string;
};

type UnassignUserActionParams = {
  user_id: null;
};

type AddToSequenceActionParams = {
  sequence_id: string;
};

type RemoveFromSequenceActionParams = {
  sequence_id: string;
};

type OptInActionParams = {
  location_ids: string[];
};

type OptOutActionParams = {
  location_ids: string[];
};

type NotifyUsersActionParams = {
  user_ids: string[];
};

type SendSmsActionParams = {
  to: string[];
  location_id: string;
  body: string;
  attachment_urls?: string[];
};

type SendEmailActionParams = {
  to: string[];
  cc: string[];
  bcc: string[];
  from: string;
  reply_to: string;
  subject: string;
  body: string;
  attachment_urls?: string[];
};

type TriggerWebhookActionParams = {
  url: string;
};

type UpdateConversationStatusActionParams = {
  status: 'open' | 'closed';
};
