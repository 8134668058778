import { CreateSignatureParams, UpdateSignatureParams } from '@/shared/types/signatures';

import { Signature } from '../types';
import { api } from './api';

export const getSignature = (signatureId: string): Promise<Signature | null> =>
  api.get(`/signatures/${signatureId}`).then((res) => {
    return res.data.data as Signature | null;
  });

export const getSignatures = () =>
  api.get(`/signatures`).then((res) => {
    return res.data.data;
  });

export const getSignaturesV2 = async (params: any) =>
  api.post(`/v2/signatures/search`, { ...params }).then((res) => {
    return res.data;
  });

export const getPartSignatures = (params: { offset: number; limit: number }) =>
  api.get(`/signatures?offset=${params.offset}&limit=${params.limit}`).then((res) => {
    return res.data.data;
  });

export const createSignature = (params: CreateSignatureParams) =>
  api
    .post(`/signatures`, {
      signature: params,
    })
    .then((res) => {
      return res.data.data as Signature;
    });

export const editSignature = (signature: UpdateSignatureParams) =>
  api.put(`/signatures/${signature.id}`, { signature: { ...signature } }).then((res) => {
    return res.data.data as Signature;
  });

export const deleteSignature = (signatureId: string) =>
  api.delete(`/signatures/${signatureId}`).then((res) => {
    return res.data.data;
  });

export const setDefaultSignature = (signatureId: string | null, userId: number) =>
  api
    .post(`/signatures/set_default`, { user_id: userId, signature_id: signatureId })
    .then((res) => {
      return res.data.data as Signature;
    });
