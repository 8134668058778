/* eslint-disable react-hooks/exhaustive-deps */
import dayjs from 'dayjs';
import EmojiPicker from 'emoji-picker-react';
import React, { useEffect, useRef, useState } from 'react';
import { HiOutlineEmojiHappy, HiTrash } from 'react-icons/hi';
import TextareaAutosize from 'react-textarea-autosize';

import { useUsers } from '@/pages/settings/organization/users/context/UserContext';
import { EmojiData } from '@/shared/components/editor/v2/constants';
import { renderText } from '@/shared/components/markdown/MarkdownRenderer';
import { NoteType } from '@/shared/types';
import { User } from '@/shared/types/users';
import {
  Avatar,
  Box,
  Button,
  Flex,
  IconButton,
  Popover,
  PopoverContent,
  PopoverTrigger,
  VStack,
} from '@/shared/ui';
import { initials } from '@/shared/utils/initials/initials';
import { DATE_TIME_STAMP } from '@/shared/utils/timestamps';
import { styled } from '@/stitches.config';

import { useContacts } from '../context/ContactContext';
import { ContactAccordion } from './v2/ContactAccordion';
import { AccordionValue } from './v2/ContactView';

type NoteEditorProps = {
  /** note text */
  newNote: string;
  /** note text setter */
  setNewNote: (text: string) => void;
  /** execute function when note text changes */
  handleNoteChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  /** editor children for buttons */
  children: React.ReactNode;
};

type NoteProps = {
  /** note object */
  note: NoteType;
  /** contact id */
  contactId: string;
};

export function ContactNotes(): JSX.Element {
  const {
    contactState: { current },
    deleteNote,
    getUserNotes,
    addNote,
  } = useContacts();

  const [newNote, setNewNote] = useState('');

  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const inputValue = e.target.value;
    setNewNote(inputValue);
  };

  const addNewNote = () => {
    addNote({ contact_id: current?.id, body: newNote });
    setNewNote('');
  };

  useEffect(() => {
    current?.id && getUserNotes(current?.id);
  }, [current?.id]);

  return (
    <ContactAccordion
      title="Notes"
      accordionValue={AccordionValue.CONTACT_NOTES}
      defaultValue={AccordionValue.CONTACT_NOTES}
    >
      <VStack gap="2">
        {!!current?.notes?.length && (
          <VStack gap="2" css={{ mb: 6 }}>
            {current?.notes?.map((note: NoteType) => (
              <Flex justify="between" key={note.id}>
                <ContactNote note={note} contactId={current?.id} />
                <IconButton
                  onClick={() => deleteNote(note.id, current?.id)}
                  css={{ mt: -2 }}
                >
                  <HiTrash size="16" style={{ marginLeft: '-1px' }} color="#60646C" />
                </IconButton>
              </Flex>
            ))}
          </VStack>
        )}
        <ContactNoteEditor
          newNote={newNote}
          handleNoteChange={handleInputChange}
          setNewNote={setNewNote}
        >
          <Button aria-label="Add note" variant="send" onClick={addNewNote}>
            Add Note
          </Button>
        </ContactNoteEditor>
      </VStack>
    </ContactAccordion>
  );
}

export function ContactNote(props: NoteProps): JSX.Element {
  const { userState } = useUsers();
  const { users } = userState;
  const [userPhoto, setUserPhoto] = useState<string | null | undefined>(null);

  useEffect(() => {
    //set the profile picture of the current user
    if (props.note?.user?.id) {
      try {
        const userProfilePicture = users.filter(
          (user: User) => user.id === props.note?.user?.id
        )[0].attachment?.url;
        setUserPhoto(userProfilePicture);
      } catch {
        setUserPhoto('');
      }
    }
  }, [props.note.user.id]);

  return (
    <Box css={{ width: '100%' }}>
      <Flex>
        <Flex css={{ pt: 2, mr: 10 }}>
          <Avatar
            variant="pink"
            size="2"
            src={userPhoto || ''}
            fallback={initials(props.note?.user?.name || props.note?.user?.email || '')}
          />
        </Flex>
        <Flex direction="column" css={{ verticalAlign: 'top', width: '100%' }}>
          <Flex align="center" justify="between">
            <NotesUser>{props.note?.user?.name || props.note?.user?.email}</NotesUser>
            <Box css={{ fontSize: 12, mr: 5 }}>
              {dayjs(props.note.updated_at).format(DATE_TIME_STAMP)}
            </Box>
          </Flex>
          <NoteText>{renderText(props.note.body)}</NoteText>
        </Flex>
      </Flex>
    </Box>
  );
}

export const ContactNoteEditor = (props: NoteEditorProps): JSX.Element => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const emojiButton = useRef<HTMLButtonElement>(null);
  const [focused, setFocused] = useState(false);

  const onFocus = () => setFocused(true);
  const onBlur = () => setFocused(false);

  const insertIntoEditor = (text: string) => {
    try {
      const editor = textareaRef?.current;
      if (!editor) throw new Error('editor not found');

      const { selectionStart, selectionEnd } = editor;
      const editorValue = editor.value || '';
      const newValue = `${editorValue.substring(
        0,
        selectionStart
      )}${text}${editorValue.substring(selectionEnd)}`;
      console.log('newValue', newValue);

      props.setNewNote(newValue);

      // When content is inserted in the editor,
      // the cursor is moved to the end of the inserted content
      // and the editor is focused so the user can continue typing
      const endCursorPosition = selectionEnd + text.length;
      editor.focus();
      editor.setSelectionRange(endCursorPosition, endCursorPosition);
    } catch (error) {
      console.log(error);
      console.error(error);
    }
  };

  const onEmojiClick = (selectedEmoji: EmojiData) => {
    console.log('selectedEmoji', selectedEmoji);
    insertIntoEditor(selectedEmoji.emoji);
    emojiButton.current?.click();
    if (textareaRef.current) textareaRef.current.focus();
  };

  return (
    <NotesTextareaContainer direction="column" focused={focused}>
      <NotesTextarea
        ref={textareaRef}
        minRows={2.5}
        placeholder="Add note"
        value={props.newNote}
        onChange={props.handleNoteChange}
        onFocus={onFocus}
        onBlur={onBlur}
      />
      <Flex justify="between" align="center">
        <Popover>
          <PopoverTrigger asChild>
            <IconButton ref={emojiButton} size={1} type="button">
              <HiOutlineEmojiHappy title="Emoji Picker" type="button" size={16} />
            </IconButton>
          </PopoverTrigger>
          <PopoverContent align="start" side="top" sideOffset={4}>
            <EmojiPicker
              onEmojiClick={onEmojiClick}
              height={300}
              previewConfig={{
                showPreview: false,
              }}
              autoFocusSearch={true}
            />
          </PopoverContent>
        </Popover>

        {props.children}
      </Flex>
    </NotesTextareaContainer>
  );
};

const NotesUser = styled(Box, {
  pr: '5px',
  fontWeight: 700,
  fontSize: 14,
  mr: '3px',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  maxWidth: 100,
  wordBreak: 'break-all',
  wordWrap: 'break-word',
});

const NotesTextarea = styled(TextareaAutosize, {
  background: 'none',
  border: 'none',
  outline: 'none',
  resize: 'none',
  width: '100%',
  fontSize: 14,
  fontWeight: 500,
});

const NotesTextareaContainer = styled(Flex, {
  width: '100%',
  backgroundColor: '#FFECB7',
  boxShadow: 'inset 0px 0px 0px 1px $colors$gray4, 0px 0px 0px 1px $colors$gray4',
  br: '4px',
  p: 12,
  variants: {
    focused: {
      true: {
        boxShadow:
          'inset 0px 0px 0px 1px $colors$primaryColor, 0px 0px 0px 1px $colors$primaryColor',
        '&:-webkit-autofill': {
          boxShadow:
            'inset 0px 0px 0px 1px $colors$primaryColor, 0px 0px 0px 1px $colors$primaryColor',
        },
      },
    },
  },
});

const NoteText = styled(Box, {
  fontSize: 14,
  position: 'relative',
  display: 'inline-block',
  overflowWrap: 'break-word',
  wordBreak: 'break-word',
  color: '#60646C',
  mt: 4,
});
