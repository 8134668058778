import React from 'react';
import { Helmet } from 'react-helmet-async';

import ConversationState from '@/pages/inbox/context/ConversationContext';
import { SettingsLayout } from '@/shared/layouts';

type TrustLayoutProps = {
  children: React.ReactNode;
};

export const TrustLayout = (props: TrustLayoutProps) => {
  const { children } = props;
  return (
    <>
      <Helmet>
        <title>Whippy | Onboarding</title>
      </Helmet>
      <ConversationState>
        <SettingsLayout
          breadcrumbs={[
            { title: 'Settings', path: '/settings/trust' },
            { title: '10DLC', path: `/settings/trust` },
          ]}
        >
          {children}
        </SettingsLayout>
      </ConversationState>
    </>
  );
};
