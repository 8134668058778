import { isNumber, isString } from 'lodash';
import { useMemo } from 'react';
import {
  HiChevronDown,
  HiChevronLeft,
  HiChevronRight,
  HiChevronUp,
  HiQuestionMarkCircle,
  HiSelector,
} from 'react-icons/hi';
import { usePagination, useRowSelect, useSortBy, useTable } from 'react-table';

import {
  Box,
  Button,
  Flex,
  HStack,
  IconButton,
  Label,
  Skeleton,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
  Tr,
  VStack,
} from '@/shared/ui';
import { countries } from '@/shared/utils/countries';
import { styled } from '@/stitches.config';

import { AnalyticsTableType } from '../context/types';
import { COLORS } from './DevicesSection';

export type ItemType = {
  count: number;
  key: string;
};

type ConfigItem = {
  /** key to match accessor in the column */
  key: string;
  /** header of the column */
  header: string;
  /** tooltip content */
  tooltip?: string;
};

export type AnalyticsTableProps = {
  /** data to display */
  data: any;
  /** config file to know on which column what tooltip content and header to display */
  config: Array<ConfigItem>;
  /** is table sortable */
  sortable?: boolean;
  /** table title */
  tableTitle?: string;
  /** is dating loading */
  loading: boolean;
  /** is response failed */
  error: boolean;
  /** retry request */
  errorCallback?: () => void;
  /** type of analytics table */
  type?: AnalyticsTableType;
};

export const AnalyticsTable = (props: AnalyticsTableProps) => {
  const { data, config, sortable, loading, error, errorCallback } = props;

  const renderCellValue = (cell: any) => {
    if (props.type === AnalyticsTableType.DEVICE && isString(cell.value)) {
      const deviceColor = COLORS.find(
        (c: { device: string; color: string }) => c.device === cell.value
      )?.color;
      return (
        <HStack>
          <DeviceIcon css={{ background: deviceColor }} />
          <span>{cell.value === 'unknown' ? 'other' : cell.value}</span>
        </HStack>
      );
    }
    if (props.type === AnalyticsTableType.COUNTRY && isString(cell.value)) {
      const countryFlag = countries.find(
        (c: { name: string; flag: string }) => c.name === cell.value
      )?.flag;
      return (
        <HStack>
          <CountryFlag>{countryFlag}</CountryFlag>
          <span>{cell.value === 'unknown' ? 'other' : cell.value}</span>
        </HStack>
      );
    }
    if (props.type === AnalyticsTableType.CITY && isString(cell.value)) {
      const cityData = data?.find(
        (c: { key: string; count: number; country: ItemType[] }) => c.key === cell.value
      );
      const cityFlag = countries.find(
        (c: { name: string; flag: string }) => c.name === cityData.country[0].key
      )?.flag;
      return (
        <HStack>
          <CountryFlag>{cityFlag}</CountryFlag>
          <span>{cell.value === 'unknown' ? 'other' : cell.value}</span>
        </HStack>
      );
    }
    return cell.value === 'unknown' ? 'other' : cell.value;
  };

  const columns = useMemo(() => {
    return data
      ? config?.map((configItem) => ({
          Header: configItem.header,
          accessor: configItem.key,
          tooltip: configItem.tooltip,
        }))
      : [];
  }, [data]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data,
    },
    useSortBy,
    usePagination,
    useRowSelect
  );

  // loading state
  if (loading) {
    return (
      <Table>
        <Thead>
          <Tr>
            {config.map((column) => (
              <Th key={column.header}>{column.header}</Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {Array.from({ length: 2 }, (_, i) => (
            <Tr key={i}>
              {config.map((column) => (
                <Td key={column.header}>
                  <Skeleton variant="heading" />
                </Td>
              ))}
            </Tr>
          ))}
        </Tbody>
      </Table>
    );
  }

  // error state
  if (error && !loading) {
    return (
      <VStack gap={2} align="center" css={{ mb: 25 }}>
        <TableTitle>Fail to Load Analytics</TableTitle>
        <TableErrorDescription>
          An error occurred while loading this report.
        </TableErrorDescription>
        {error && (
          <Box css={{ width: '100%', textAlign: 'center' }}>
            <Button variant="gray" onClick={errorCallback}>
              Try Again
            </Button>
          </Box>
        )}
      </VStack>
    );
  }

  return (
    <Box>
      <Table {...getTableProps()}>
        <Thead css={{ borderBottom: '1px solid lightgrey' }}>
          {headerGroups.map((headerGroup, index) => (
            <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column: any) => (
                <Th
                  key={column.Header}
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                >
                  <Flex align="center">
                    <Box>{column.render('Header')}</Box>
                    {column.tooltip && (
                      <Tooltip>
                        <TooltipTrigger asChild>
                          <IconButton>
                            <HiQuestionMarkCircle />
                          </IconButton>
                        </TooltipTrigger>
                        <TooltipContent side="top">
                          <Box
                            css={{
                              width: 270,
                              p: 5,
                              borderRadius: 4,
                              color: '#FFFFFF',
                              fontStyle: 'normal',
                              fontWeight: 400,
                              fontSize: 14,
                              lineHeight: '20px',
                            }}
                          >
                            {column.tooltip}
                          </Box>
                        </TooltipContent>
                      </Tooltip>
                    )}
                    {sortable ? (
                      column.isSorted ? (
                        column.isSortedDesc ? (
                          <HiChevronDown
                            style={{
                              opacity: '0.6',
                              marginLeft: 5,
                            }}
                          />
                        ) : (
                          <HiChevronUp
                            style={{
                              opacity: '0.6',
                              marginLeft: 5,
                            }}
                          />
                        )
                      ) : // This prevents rendering arrows on empty headers
                      column.Header === '' || column.Header === ' ' ? (
                        ''
                      ) : (
                        <HiSelector
                          style={{
                            opacity: '0.6',
                            marginLeft: 5,
                          }}
                        />
                      )
                    ) : null}
                  </Flex>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {page.length > 0 ? (
            page.map((row) => {
              prepareRow(row);
              return (
                <Tr
                  {...row.getRowProps()}
                  key={row.id}
                  css={{
                    '&:last-child': { borderBottom: 'none' },
                    width: '100%',
                  }}
                >
                  {row.cells.map((cell, idx) => {
                    return (
                      <Td
                        {...cell.getCellProps()}
                        css={{
                          width: '100%',
                        }}
                        key={`${row.id}-${idx}`}
                      >
                        <Flex
                          align="center"
                          justify={isNumber(cell.value) ? 'end' : 'start'}
                          css={{ width: '100%', textTransform: 'capitalize' }}
                          gap={1}
                        >
                          {renderCellValue(cell)}
                        </Flex>
                      </Td>
                    );
                  })}
                </Tr>
              );
            })
          ) : (
            <Tr>
              <Td colSpan={columns.length} css={{ textAlign: 'center' }}>
                <VStack gap={2}>
                  <Box css={{ p: 20, pt: 30 }}>
                    <TableTitle>No data to display yet. Try again later</TableTitle>
                  </Box>
                </VStack>
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>
      {pageOptions.length > 1 && (
        <HStack gap={3} css={{ justifyContent: 'center' }}>
          <IconButton onClick={previousPage} disabled={!canPreviousPage} variant="ghost">
            <HiChevronLeft />
          </IconButton>
          <Flex>
            <Text>Page&nbsp;</Text>
            <Text variant="semibold">
              {pageIndex + 1} of {pageOptions.length}
            </Text>
          </Flex>
          <IconButton onClick={nextPage} disabled={!canNextPage} variant="ghost">
            <HiChevronRight />
          </IconButton>
        </HStack>
      )}
    </Box>
  );
};

const TableTitle = styled(Label, {
  fontSize: 18,
  fontWeight: 600,
});

const TableErrorDescription = styled(Box, {
  pr: 10,
  fontSize: 12,
  color: '$slate11',
});

const DeviceIcon = styled('span', {
  width: 7,
  height: 7,
  borderRadius: 7,
});

const CountryFlag = styled('span', {
  fontSize: 16,
});
