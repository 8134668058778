import { useRef } from 'react';
import { toast } from 'sonner';

import { OpeningHours } from '@/shared/components/openingHours';
import { initialWorkSchedules } from '@/shared/components/openingHours/constants';
import { OpeningHour } from '@/shared/types';
import { Channel } from '@/shared/types/channels';
import i18next from '@/shared/utils/translation';

import { useChannels } from '../context/ChannelContext';

const order: Record<string, number> = {
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
  Saturday: 6,
  Sunday: 7,
};

// Sorts the days of the week from Monday to Sunday
const sortDays = (openingHours: Array<OpeningHour>) => {
  return openingHours?.sort((a: OpeningHour, b: OpeningHour) => {
    return order[a.weekday] - order[b.weekday];
  });
};

const applyNewOpeningHour = (channel: Channel, newOpeningHour: OpeningHour): Channel => {
  const updatedOpeningHours = [
    ...(channel?.opening_hours?.filter(
      (existingOpeningHour: OpeningHour) => existingOpeningHour.id !== newOpeningHour.id
    ) || []),
    newOpeningHour,
  ];
  return {
    ...channel,
    opening_hours: updatedOpeningHours,
  };
};

export const BusinessHours = () => {
  const { channelsState, updateChannel } = useChannels();
  const { current } = channelsState;
  const onUpdateInPromise = useRef<Promise<Channel> | null>(null);

  // Updates the opening hours
  const onChange = async (openingHour: OpeningHour, channel?: Channel | null) => {
    // Throws an error when there are no opens_at or closes_at and the state is open
    if (
      (openingHour.opens_at === null || openingHour.closes_at === null) &&
      openingHour.state === 'open'
    ) {
      toast.error(i18next.t('working_hours_not_set') as string);
      return;
    }

    // Update the location with the new opening hours
    if (channel && channel?.id) {
      delete openingHour.isSwitch;
      delete openingHour.timezone;
      const modifiedLocation = applyNewOpeningHour(channel, openingHour);
      const data = await updateChannel(modifiedLocation);
      return data;
    }
  };

  const handleChange = (openingHour: OpeningHour) => {
    const p = (channel: Channel) => {
      new Promise<void>((resolve) => {
        onUpdateInPromise.current = null;
        resolve();
      });
      return channel;
    };

    if (onUpdateInPromise.current) {
      onUpdateInPromise.current.then((channel) => {
        onUpdateInPromise.current = onChange(openingHour, channel).then(p as any);
      });
    } else {
      onUpdateInPromise.current = onChange(openingHour, current).then(p as any);
    }
  };

  // Enforces the order of the days of the week, because the API returns them in a random order
  const sortedOpeningHours = sortDays(current?.opening_hours || []);

  // Applies the opening hours to the selected days
  const onApply = async (openingHour: OpeningHour, targetDays: string[]) => {
    // If there is no team, return
    if (!current || !current.id) {
      return;
    }

    const weekDays = initialWorkSchedules.map((day: OpeningHour) => {
      const workDay = current?.opening_hours?.find(
        (currentDay: OpeningHour) => currentDay.weekday === day.weekday
      );
      if (workDay) {
        return workDay;
      }
      return day;
    });

    const updatedOpeningHours = weekDays?.map((day) => {
      if (targetDays.includes(day.weekday)) {
        return {
          ...day,
          opens_at: openingHour.opens_at,
          closes_at: openingHour.closes_at,
          state: openingHour.state,
        };
      }
      return day;
    });

    updateChannel({ ...current, opening_hours: updatedOpeningHours });
  };

  // Update the existing opening hours with timezone
  const updateTimezone = async (timezoneName: string) => {
    updateChannel({
      ...(current as Channel),
      timezone: timezoneName,
      id: current?.id || '',
    });
  };

  return (
    <OpeningHours
      onChange={handleChange}
      onApply={onApply}
      updateTimezone={updateTimezone}
      timezone={current?.timezone}
      current={{ ...current, openingHours: sortedOpeningHours }}
    />
  );
};
