import { Keyword } from '../../types/keywords';
import { api } from '../api';

export const getKeywords = (params: {
  offset: number;
  limit: number;
}): Promise<Array<Keyword> | Array<null>> =>
  api
    .get(`/automations/keywords?limit=${params.limit}&offset=${params.offset}`)
    .then((res) => {
      return res.data.data.keywords as Array<Keyword> | Array<null>;
    });

export const getKeyword = (id: string): Promise<Keyword | null> =>
  api.get(`/automations/keywords/${id}`).then((res) => {
    return res.data.data as Keyword | null;
  });

export const searchKeyword = (value: string): Promise<Array<Keyword> | Array<null>> =>
  api.post(`/automations/keywords/search`, { body: value }).then((res) => {
    return res.data.data as Array<Keyword> | Array<null>;
  });

export const createKeyword = (keyword: Keyword): Promise<Keyword> =>
  api.post(`/automations/keywords`, { keyword }).then((res) => {
    return res.data.data as Keyword;
  });

export const updateKeyword = (id: string, keyword: Keyword): Promise<Keyword> =>
  api.put(`/automations/keywords/${id}`, { keyword }).then((res) => {
    return res.data.data as Keyword;
  });

export const deleteKeyword = (id: string) =>
  api.delete(`/automations/keywords/${id}`).then((res) => {
    return res.data.data;
  });
