import { Audience } from '@/shared/types/audience';

import { AudienceState } from './AudienceSelection';

type UnformattedAudience = {
  // access key/value pair by string
  [key: string]: string | any;
  location?: string;
  tag_ids?: Array<string>;
  dynamic_group_ids?: Array<string>;
  upload_tag_ids?: Array<string>;
  location_ids?: Array<string>;
  contacts?: Array<string>;
  campaign_delivered?: Array<string>;
  campaign_not_delivered?: Array<string>;
  campaign_responded?: Array<string>;
  campaign_not_responded?: Array<string>;
  campaign_link_clicked?: Array<string>;
  campaign_link_not_clicked?: Array<string>;
  campaign_unfulfilled?: Array<string>;
};

//remove the empty fields from the audience object
export const removeEmptyFields = (audience: UnformattedAudience): Audience => {
  const formattedAudience = Object.keys(audience)
    .filter(
      (k: keyof UnformattedAudience) => audience[k]?.length > 0 || audience[k] === ''
    )
    .reduce((a: any, k: keyof UnformattedAudience) => ({ ...a, [k]: audience[k] }), {});

  return formattedAudience || {};
};

export const getCampaignsContactTypeString = (type: string) => {
  switch (type) {
    case 'delivered':
      return 'Delivered';
    case 'not_delivered':
      return 'Not Delivered';
    case 'responded':
      return 'Responded';
    case 'unresponded':
      return 'Unresponded';
    case 'clicks':
      return 'Link Clicks';
    case 'not_clicked':
      return 'Not Clicked';
    case 'unsubscribed':
      return 'Unsubscribed';
    case 'unfulfilled':
      return 'Pending/Cancelled';
    default:
      return '';
  }
};

export const getCampaignsContactTypeStringFromKeys = (type: string) => {
  switch (type) {
    case 'campaign_delivered':
      return 'Delivered';
    case 'campaign_not_delivered':
      return 'Not Delivered';
    case 'campaign_responded':
      return 'Responded';
    case 'campaign_not_responded':
      return 'Unresponded';
    case 'campaign_link_clicked':
      return 'Link Clicks';
    case 'campaign_link_not_clicked':
      return 'Not Clicked';
    case 'campaign_unfulfilled':
      return 'Pending/Cancelled';
    default:
      return '';
  }
};

export const formatPreselectedAudience = (preselectedAudience: UnformattedAudience) => {
  if (Object.keys(preselectedAudience).includes('campaign_delivered')) {
    return {
      ...preselectedAudience,
      campaign_delivered: preselectedAudience.campaign_delivered,
      contacts: [],
    };
  } else if (Object.keys(preselectedAudience).includes('campaign_not_delivered')) {
    return {
      ...preselectedAudience,
      campaign_not_delivered: preselectedAudience.campaign_not_delivered,
      contacts: [],
    };
  } else if (Object.keys(preselectedAudience).includes('campaign_responded')) {
    return {
      ...preselectedAudience,
      campaign_responded: preselectedAudience.campaign_responded,
      contacts: [],
    };
  } else if (Object.keys(preselectedAudience).includes('campaign_not_responded')) {
    return {
      ...preselectedAudience,
      campaign_not_responded: preselectedAudience.campaign_not_responded,
      contacts: [],
    };
  } else if (Object.keys(preselectedAudience).includes('campaign_link_clicked')) {
    return {
      ...preselectedAudience,
      campaign_link_clicked: preselectedAudience.campaign_link_clicked,
      contacts: [],
    };
  } else if (Object.keys(preselectedAudience).includes('campaign_link_not_clicked')) {
    return {
      ...preselectedAudience,
      campaign_link_not_clicked: preselectedAudience.campaign_link_not_clicked,
      contacts: [],
    };
  } else if (Object.keys(preselectedAudience).includes('campaign_unfulfilled')) {
    return {
      ...preselectedAudience,
      campaign_unfulfilled: preselectedAudience.campaign_unfulfilled,
      contacts: [],
    };
  } else {
    return preselectedAudience;
  }
};

export const getCampaignIdFromAudience = (preselectedAudience: UnformattedAudience) => {
  if (preselectedAudience.campaign_delivered) {
    return preselectedAudience.campaign_delivered[0];
  } else if (preselectedAudience.campaign_not_delivered) {
    return preselectedAudience.campaign_not_delivered[0];
  } else if (preselectedAudience.campaign_responded) {
    return preselectedAudience.campaign_responded[0];
  } else if (preselectedAudience.campaign_not_responded) {
    return preselectedAudience.campaign_not_responded[0];
  } else if (preselectedAudience.campaign_link_clicked) {
    return preselectedAudience.campaign_link_clicked[0];
  } else if (preselectedAudience.campaign_link_not_clicked) {
    return preselectedAudience.campaign_link_not_clicked[0];
  } else if (preselectedAudience.campaign_unfulfilled) {
    return preselectedAudience.campaign_unfulfilled[0];
  } else {
    return '';
  }
};

export function createIncludeAudience(
  includeAudience: AudienceState,
  includePreSelectAudience: Audience,
  location: string
) {
  return {
    location: location,
    tag_ids: [
      ...includeAudience.tags.map((tag) => tag.id).filter((tag) => tag),
      ...(includePreSelectAudience?.tag_ids?.filter((tag) => tag) || []),
    ],
    dynamic_group_ids: [
      ...includeAudience.groups.map((group) => group.id).filter((group) => group),
      ...(includePreSelectAudience?.dynamic_group_ids?.filter((group) => group) || []),
    ],
    upload_tag_ids: [
      ...includeAudience.uploads.map((upload) => upload.id).filter((upload) => upload),
      ...(includePreSelectAudience?.upload_tag_ids?.filter((upload) => upload) || []),
    ],
    contacts: [
      ...includeAudience.contacts,
      ...includeAudience.manuallyAddedContacts
        .filter((contact) => contact)
        .map((contact) => contact.id),
      ...(includePreSelectAudience?.contacts?.filter((contact) => contact) || []),
    ],
  };
}

// format the include audience params
export const reduceAudienceToParams = (
  includeAudience: AudienceState,
  includePreSelectAudience: Audience,
  location: string
) => {
  // object of campaign tabs
  const campaign_tabs = removeEmptyFields({
    campaign_delivered: includePreSelectAudience.campaign_delivered
      ? includePreSelectAudience.campaign_delivered
      : [],
    campaign_not_delivered: includePreSelectAudience.campaign_not_delivered
      ? includePreSelectAudience.campaign_not_delivered
      : [],
    campaign_responded: includePreSelectAudience.campaign_responded
      ? includePreSelectAudience.campaign_responded
      : [],
    campaign_not_responded: includePreSelectAudience.campaign_not_responded
      ? includePreSelectAudience.campaign_not_responded
      : [],
    campaign_link_clicked: includePreSelectAudience.campaign_link_clicked
      ? includePreSelectAudience.campaign_link_clicked
      : [],
    campaign_link_not_clicked: includePreSelectAudience.campaign_link_not_clicked
      ? includePreSelectAudience.campaign_link_not_clicked
      : [],
  });

  // if there is a campaign tab this formats it like that [campaign_tab_name, campaign_tab_value]
  const campaign_tab = [Object.keys(campaign_tabs)[0], Object.values(campaign_tabs)[0]];

  const include = createIncludeAudience(
    includeAudience,
    includePreSelectAudience,
    location
  );

  // if there is a campaign tab include it in the body
  if (campaign_tab[0]) {
    return {
      ...include,
      [campaign_tab[0].toString()]: campaign_tab[1],
    };
  } else {
    return include;
  }
};

export const reduceExcludeAudienceToParams = (
  excludeAudience: AudienceState,
  excludePreSelectAudience: Audience,
  excludeCampaignSince: number,
  excludeContactsWithOpenConversation: boolean
) => {
  const exclude = {
    tag_ids: [
      // if there is a null value in the array remove it
      ...excludeAudience.tags.map((element) => element.id).filter((element) => element),
      ...(excludePreSelectAudience?.tag_ids?.map((element) => element) || []),
    ],
    dynamic_group_ids: [
      // if there is a null value in the array remove it
      ...excludeAudience.groups.map((element) => element.id).filter((element) => element),
      ...(excludePreSelectAudience?.dynamic_group_ids?.map((element) => element) || []),
    ],
    upload_tag_ids: [
      // if there is a null value in the array remove it
      ...excludeAudience.uploads
        .map((element) => element.id)
        .filter((element) => element),
      ...(excludePreSelectAudience?.upload_tag_ids?.map((element) => element) || []),
    ],
    contacts: [
      ...excludeAudience.contacts,
      ...excludeAudience.manuallyAddedContacts.map((element) => element.id),
      ...(excludePreSelectAudience?.contacts || []),
    ],
    time_since_last_campaign: excludeCampaignSince,
    exclude_contacts_with_open_conversations: excludeContactsWithOpenConversation,
  };

  return exclude;
};
