import { useMedia } from 'react-use';

import { MultiSelect } from '@/shared/components/MultiSelect';
import { CampaignIndustryOptions } from '@/shared/types/campaigns/index';
import { Box, Flex, HStack, Input } from '@/shared/ui';

import { CurrentTemplatesFilters } from '../hooks/useFilterCampaignTemplates';

type CampaignTemplatesSearchInputProps = {
  /* campaign template filters */
  filters: CurrentTemplatesFilters;
  /* campaign template filters to change */
  changeFilters: (newFilters: CurrentTemplatesFilters) => void;
  /* function to set campaign template filters */
  setDidFiltersChange: (newDidFiltersChange: boolean) => void;
  /* is the campaign template global */
  isGlobal?: boolean;
};

type FilterType = {
  users: string[];
  locations: string[];
};

export const TemplateSearch = (props: CampaignTemplatesSearchInputProps): JSX.Element => {
  const { filters, changeFilters, setDidFiltersChange, isGlobal } = props;
  const isLargeScreen = useMedia('(min-width: 1600px)');
  return (
    <Flex css={{ px: 30, mb: 20 }}>
      <HStack gap={1} css={{ width: '100%' }}>
        <Flex align="center" css={{ width: isLargeScreen ? '100%' : '50%' }}>
          <Input
            value={filters.title}
            placeholder="Search Templates"
            onChange={(e) => {
              changeFilters({ ...filters, title: e.target.value });
              setDidFiltersChange(true);
            }}
            css={{ width: '409px' }}
          />
        </Flex>
        {isGlobal && (
          <Box css={{ width: '30%' }}>
            <MultiSelect
              defaultPlaceholder="Industry"
              defaultSelectedItems={[]}
              isDropdown={true}
              options={CampaignIndustryOptions}
              parentSelectedItems={filters.industry}
              setParentSelectedItems={
                (newFilters: FilterType) =>
                  changeFilters({
                    ...filters,
                    industry: newFilters.users,
                  })
                // based on the defined behavior of MultiSelect component
                // newFilters here will have 2 fields: users, channels
                // and selected items will be parsed into .users field
              }
              isCampaigns={true}
            />
          </Box>
        )}
      </HStack>
    </Flex>
  );
};
