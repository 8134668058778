import { Preference } from '@/shared/types';
import { Channel } from '@/shared/types/channels';
import { UnsubscribePreferenceActionTypes } from '@/shared/types/contacts';

import { UnsubscribePreferenceState } from './UnsubscribePreferenceContext';

export type UnsubscribePreferenceActions =
  | {
      type: UnsubscribePreferenceActionTypes.SET_IS_LOADING;
      payload: boolean;
    }
  | {
      type: UnsubscribePreferenceActionTypes.SET_IS_EMPTY;
      payload: boolean;
    }
  | {
      type: UnsubscribePreferenceActionTypes.SET_ACTIVE_LOCATIONS;
      payload: Channel[];
    }
  | {
      type: UnsubscribePreferenceActionTypes.SET_PAUSED_LOCATIONS;
      payload: Channel[];
    }
  | {
      type: UnsubscribePreferenceActionTypes.SET_OPTED_OUT_LOCATIONS;
      payload: Channel[];
    }
  | {
      type: UnsubscribePreferenceActionTypes.SET_SELECTED_LOCATIONS;
      payload: Channel[];
    }
  | {
      type: UnsubscribePreferenceActionTypes.SET_SELECTED_ACTIVE_LOCATIONS;
      payload: Channel[];
    }
  | {
      type: UnsubscribePreferenceActionTypes.SET_SELECTED_PAUSED_LOCATIONS;
      payload: Channel[];
    }
  | {
      type: UnsubscribePreferenceActionTypes.SET_CONFIRMED_PAUSE_DURATION;
      payload: string;
    }
  | {
      type: UnsubscribePreferenceActionTypes.SET_ALL_LOCATIONS_TO_SHOW;
      payload: Channel[];
    }
  | {
      type: UnsubscribePreferenceActionTypes.SET_PAUSED_PREFERENCES;
      payload: Preference[];
    };

export const UnsubscribePreferenceReducer: React.Reducer<
  UnsubscribePreferenceState,
  UnsubscribePreferenceActions
> = (state: UnsubscribePreferenceState, action: UnsubscribePreferenceActions) => {
  switch (action.type) {
    case UnsubscribePreferenceActionTypes.SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case UnsubscribePreferenceActionTypes.SET_IS_EMPTY:
      return {
        ...state,
        isEmpty: action.payload,
      };
    case UnsubscribePreferenceActionTypes.SET_ALL_LOCATIONS_TO_SHOW:
      return {
        ...state,
        allLocationsToShow: action.payload,
      };
    case UnsubscribePreferenceActionTypes.SET_ACTIVE_LOCATIONS:
      return {
        ...state,
        activeLocations: action.payload,
      };
    case UnsubscribePreferenceActionTypes.SET_PAUSED_LOCATIONS:
      return {
        ...state,
        pausedLocations: action.payload,
      };
    case UnsubscribePreferenceActionTypes.SET_OPTED_OUT_LOCATIONS:
      return {
        ...state,
        optedOutLocations: action.payload,
      };
    case UnsubscribePreferenceActionTypes.SET_SELECTED_LOCATIONS:
      return {
        ...state,
        selectedLocations: action.payload,
      };
    case UnsubscribePreferenceActionTypes.SET_SELECTED_ACTIVE_LOCATIONS:
      return {
        ...state,
        selectedActiveLocations: action.payload,
      };
    case UnsubscribePreferenceActionTypes.SET_SELECTED_PAUSED_LOCATIONS:
      return {
        ...state,
        selectedPausedLocations: action.payload,
      };
    case UnsubscribePreferenceActionTypes.SET_CONFIRMED_PAUSE_DURATION:
      return {
        ...state,
        pauseDuration: action.payload,
      };
    case UnsubscribePreferenceActionTypes.SET_PAUSED_PREFERENCES:
      return {
        ...state,
        pausedPreferences: action.payload,
      };
    default:
      return state;
  }
};
