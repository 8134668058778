import { FilterType } from '@/shared/types/filter';
import { toE164 } from '@/shared/utils/validations/validations';

export const generateQuickSearchFilters = (value: string) => {
  const formattedValue = value.trim();
  const formattedPhone = toE164(formattedValue);
  return formattedValue
    ? [
        {
          column: 'name',
          comparison: 'ilike',
          value: `%${formattedValue}%`,
          resource: 'contact',
          or: [
            {
              column: 'email',
              comparison: 'ilike',
              value: `%${formattedValue}%`,
              resource: 'contact',
              or: [
                {
                  column: 'phone',
                  comparison: 'ilike',
                  value: `%${formattedPhone}%`,
                  resource: 'contact',
                },
              ],
            },
          ],
        },
      ]
    : ([] as Array<FilterType>);
};
