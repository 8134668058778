import { useFlags } from 'launchdarkly-react-client-sdk';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'sonner';

import {
  Fieldset,
  Flex,
  Label,
  Slider,
  SliderRange,
  SliderThumb,
  SliderTrack,
  Switch,
  SwitchThumb,
  Text,
  VStack,
} from '@/shared/ui';

import { useSettings } from '../general/context/SettingsContext';
import { BillingCard } from '.';

export const BillingControls = () => {
  const {
    settingsState: { settings },
    getOrganizationInfo,
    updateSettings,
  } = useSettings();

  const { showCreditLimitSlider, showEmojiToggle } = useFlags();

  useEffect(() => {
    getOrganizationInfo();
  }, []);

  const onSwitchClick = (state: boolean) => {
    const new_settings = {
      ...settings,
      sms_emoji_enabled: state,
    };
    updateSettings({ settings: new_settings })
      .then(() => {
        getOrganizationInfo();
        toast.success('Settings updated successfully');
      })
      .catch((error) => {
        console.error('Error updating settings', error);
      });
  };

  const [segments, setSegments] = useState(settings?.max_number_of_segments ?? 0);

  useEffect(() => {
    setSegments(settings?.max_number_of_segments ?? 0);
  }, [settings]);

  // Create a memoized version of the debounced update function
  const debouncedUpdate = useCallback(
    debounce((new_settings) => {
      updateSettings({ settings: new_settings })
        .then(() => {
          getOrganizationInfo();
          toast.success('Settings updated successfully');
        })
        .catch((error) => {
          console.error('Error updating settings', error);
        });
    }, 1000),
    [updateSettings, getOrganizationInfo]
  );

  const handleSliderChange = useCallback((value: number[]) => {
    console.log('value', value[0]);
    setSegments(value[0]);
    debouncedUpdate({
      ...settings,
      max_number_of_segments: value[0],
    });
  }, []);

  return (
    <BillingCard justify="between">
      <VStack gap="2">
        <Text size="3" variant="bold">
          Billing Controls
        </Text>
        {showEmojiToggle ||
          (showCreditLimitSlider && (
            <Text css={{ mb: 10 }}>
              Manage your message sending settings to control your usage.
            </Text>
          ))}
        {showEmojiToggle && (
          <Fieldset>
            <Label>Enable emojis in SMS messages</Label>
            <Text>Turn off to disable emojis in SMS messages.</Text>
            <Switch
              css={{ margin: '10px 0' }}
              checked={settings?.sms_emoji_enabled || false}
              onCheckedChange={() => onSwitchClick(!settings?.sms_emoji_enabled || false)}
            >
              <SwitchThumb />
            </Switch>
          </Fieldset>
        )}
        {showCreditLimitSlider && (
          <Fieldset>
            <Label>
              Max number of segments: {segments} (approx {segments * 160} characters)
            </Label>
            <Text>
              This is the maximum number of segments that can be sent in a single message.
            </Text>
            <Flex align="center" css={{ margin: '10px 0' }}>
              <Slider
                value={[segments]}
                defaultValue={[settings?.max_number_of_segments ?? 12]}
                min={1}
                max={12}
                step={1}
                onValueChange={(value: number[]) => handleSliderChange(value)}
              >
                <SliderTrack>
                  <SliderRange />
                </SliderTrack>
                <SliderThumb />
              </Slider>
            </Flex>
          </Fieldset>
        )}
      </VStack>
    </BillingCard>
  );
};
