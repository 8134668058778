import { styled } from '@/stitches.config';

const DEFAULT_TAG = 'input';

const StyledInput = styled(DEFAULT_TAG, {
  // Reset
  appearance: 'none',
  borderWidth: '0',
  boxSizing: 'border-box',
  fontFamily: 'inherit',
  margin: '0',
  outline: 'none',
  padding: '0',
  width: '100%',
  WebkitTapHighlightColor: 'rgba(0,0,0,0)',
  '&::before': {
    boxSizing: 'border-box',
  },
  '&::after': {
    boxSizing: 'border-box',
  },

  // Custom
  backgroundColor: '$loContrast',
  boxShadow: 'inset 0 0 0 1px $colors$slate7',
  color: '$hiContrast',
  fontVariantNumeric: 'tabular-nums',

  '&:-webkit-autofill': {
    boxShadow: 'inset 0 0 0 1px $colors$slate7',
  },

  '&:-webkit-autofill::first-line': {
    fontFamily: '$untitled',
    color: '$hiContrast',
  },

  '&:focus': {
    boxShadow:
      'inset 0px 0px 0px 1px $colors$primaryColor, 0px 0px 0px 1px $colors$primaryColor',
    '&:-webkit-autofill': {
      boxShadow:
        'inset 0px 0px 0px 1px $colors$primaryColor, 0px 0px 0px 1px $colors$primaryColor',
    },
  },
  '&::placeholder': {
    color: '$slate9',
  },
  '&:disabled': {
    pointerEvents: 'none',
    backgroundColor: '$slate2',
    color: '$slate8',
    cursor: 'not-allowed',
    '&::placeholder': {
      color: '$slate7',
    },
  },
  '&:read-only': {
    backgroundColor: '$slate2',
    '&:focus': {
      boxShadow: 'inset 0px 0px 0px 1px $colors$slate7',
    },
  },

  variants: {
    size: {
      '1': {
        borderRadius: '$1',
        height: '$5',
        fontSize: '$1',
        px: '$1',
        lineHeight: '$sizes$5',
        '&:-webkit-autofill::first-line': {
          fontSize: '$1',
        },
      },
      '2': {
        borderRadius: '$1',
        height: 35,
        fontSize: '$2',
        px: '$2',
        lineHeight: '$sizes$6',
        '&:-webkit-autofill::first-line': {
          fontSize: '$3',
        },
      },
      '3': {
        borderRadius: '$1',
        height: 40,
        fontSize: '$4',
        px: '$2',
        lineHeight: '$sizes$6',
        '&:-webkit-autofill::first-line': {
          fontSize: '$3',
        },
      },
    },
    variant: {
      ghost: {
        boxShadow: 'none',
        padding: 0,
        backgroundColor: 'transparent',
        '@hover': {
          '&:hover': {
            boxShadow: 'none',
          },
        },
        '&:focus': {
          backgroundColor: '$loContrast',
          boxShadow: 'none',
        },
        '&:disabled': {
          backgroundColor: 'transparent',
        },
        '&:read-only': {
          backgroundColor: 'transparent',
        },
      },
      select: {
        boxShadow: 'none',
        height: 33,
        padding: '$1',
        backgroundColor: 'transparent',
        '@hover': {
          '&:hover': {
            boxShadow: 'none',
          },
        },
        '&:focus': {
          backgroundColor: 'transparent',
          boxShadow: 'none',
        },
        '&:disabled': {
          backgroundColor: 'transparent',
        },
        '&:read-only': {
          backgroundColor: 'transparent',
        },
      },
      selectDropdown: {
        cursor: 'pointer',
        paddingRight: 36,
        textOverflow: 'ellipsis',
        '&::placeholder': {
          color: '$hiContrast',
        },
        '&:focus': {
          cursor: 'text',
        },
      },
      popover: {
        borderRadius: '$1 $1 0 0',
      },
      dialog: {
        borderRadius: '0',
      },
      contact: {
        boxShadow: 'none',
        backgroundColor: 'transparent',
        '&:hover': {
          boxShadow: 'inset 0 0 0 1px $colors$slate7',
        },
        '&:focus': {
          boxShadow:
            'inset 0px 0px 0px 1px $colors$primaryColor, 0px 0px 0px 1px $colors$primaryColor',
        },
        '&:disabled': {
          backgroundColor: 'transparent',
        },
        '&:read-only': {
          backgroundColor: 'transparent',
        },
      },
      invalid: {
        boxShadow: 'inset 0 0 0 2px red',
      },
    },
    state: {
      invalid: {
        boxShadow: 'inset 0 0 0 2px $colors$red7',
        '&:focus': {
          boxShadow: 'inset 0px 0px 0px 1px $colors$red8, 0px 0px 0px 1px $colors$red8',
        },
      },
      valid: {
        boxShadow: 'inset 0 0 0 1px $colors$green7',
        '&:focus': {
          boxShadow:
            'inset 0px 0px 0px 1px $colors$green8, 0px 0px 0px 1px $colors$green8',
        },
      },
    },
    cursor: {
      default: {
        cursor: 'default',
        '&:focus': {
          cursor: 'text',
        },
      },
      text: {
        cursor: 'text',
      },
    },
  },
  defaultVariants: {
    size: '2',
  },
});

export const Input = StyledInput;
