export const GET_CAMPAIGNS = 'GET_CAMPAIGNS';
export const UPDATE_CAMPAIGNS = 'UPDATE_CAMPAIGNS';
export const SET_UPDATING = 'SET_UPDATING';
export const GET_CAMPAIGN = 'GET_CAMPAIGN';
export const CREATE_CAMPAIGN = 'CREATE_CAMPAIGN';
export const UPDATE_CAMPAIGN = 'UPDATE_CAMPAIGN';
export const DELETE_CAMPAIGN = 'DELETE_CAMPAIGN';
export const SET_LOADING = 'SET_LOADING';
export const SET_CURRENT = 'SET_CURRENT';
export const CHANGE_CAMPAIGN_STATUS = 'CHANGE_CAMPAIGN_STATUS';
export const UPDATE_DELIVERED_CONTACTS = 'UPDATE_DELIVERED_CONTACTS';
export const UPDATE_RESPONDED_CONTACTS = 'UPDATE_RESPONDED_CONTACTS';
export const UPDATE_UNRESPONDED_CONTACTS = 'UPDATE_UNRESPONDED_CONTACTS';
export const UPDATE_NOT_DELIVERED_CONTACTS = 'UPDATE_NOT_DELIVERED_CONTACTS';
export const UPDATE_CLICKED_CONTACTS = 'UPDATE_CLICKED_CONTACTS';
export const UPDATE_NOT_CLICKED_CONTACTS = 'UPDATE_NOT_CLICKED_CONTACTS';
export const UPDATE_UNSUBSCRIBED_CONTACTS = 'UPDATE_UNSUBSCRIBED_CONTACTS';
export const UPDATE_UNFULFILLED_CONTACTS = 'UPDATE_UNFULFILLED_CONTACTS';
export const CLEAR_AUDIENCE = 'CLEAR_AUDIENCE';
export const GET_MESSAGE_COUNT = 'GET_MESSAGE_COUNT';
export const SET_SELECTED_CONTACTS = 'SET_SELECTED_CONTACTS';
export const SET_ALL_SELECTED = 'SET_ALL_SELECTED';
export const FILTER_CAMPAIGNS = 'FILTER_CAMPAIGNS';
export const CHANGE_TAB_TOTAL_CONTACTS = 'CHANGE_TAB_TOTAL_CONTACTS';
export const GET_CAMPAIGN_TEMPLATES = 'GET_CAMPAIGN_TEMPLATES';
export const SET_CURRENT_TEMPLATE = 'SET_CURRENT_TEMPLATE';
export const GET_CAMPAIGN_TEMPLATE = 'GET_CAMPAIGN_TEMPLATE';
export const CREATE_CAMPAIGN_TEMPLATE = 'CREATE_CAMPAIGN_TEMPLATE';
export const UPDATE_CAMPAIGN_TEMPLATE = 'UPDATE_CAMPAIGN_TEMPLATE';
export const DELETE_CAMPAIGN_TEMPLATE = 'DELETE_CAMPAIGN_TEMPLATE';
export const DUPLICATE_CAMPAIGN_TEMPLATE = 'DUPLICATE_CAMPAIGN_TEMPLATE';
export const SET_LOADING_CAMPAIGN_TEMPLATES = 'SET_LOADING_CAMPAIGN_TEMPLATES';
export const GET_CAMPAIGN_CONTACTS_V2 = 'GET_CAMPAIGN_CONTACTS_V2';
export const GET_CAMPAIGN_CONTACTS_V2_NEW_SEARCH = 'GET_CAMPAIGN_CONTACTS_V2_NEW_SEARCH';
export const SET_LOADING_CONTACTS = 'SET_LOADING_CONTACTS';
export const SET_CAMPAIGN_CONTACTS_PARAMS = 'SET_CAMPAIGN_CONTACTS_PARAMS';

export enum CampaignAnalyticsTab {
  DELIVERED = 'delivered',
  CLICKS = 'clicks',
  NOT_CLICKED = 'not_clicked',
  RESPONDED = 'responded',
  UNRESPONDED = 'unresponded',
  UNSUBSCRIBED = 'unsubscribed',
  UNFULFILLED = 'unfulfilled',
  NOT_DELIVERED = 'not_delivered',
  OVERVIEW = 'overview',
}
