import { styled } from '@/stitches.config';

const childWithGap = '> * + *';

const Stack = styled('div', {
  display: 'flex',
  fw: 'nowrap',
});

export const HStack = styled(Stack, {
  variants: {
    gap: {
      1: {
        [childWithGap]: {
          ml: '$space$1',
        },
      },
      2: {
        [childWithGap]: {
          ml: '$space$2',
        },
      },
      3: {
        [childWithGap]: {
          ml: '$space$3',
        },
      },
      4: {
        [childWithGap]: {
          ml: '$space$4',
        },
      },
      5: {
        [childWithGap]: {
          ml: '$space$5',
        },
      },
      6: {
        [childWithGap]: {
          ml: '$space$6',
        },
      },
      7: {
        [childWithGap]: {
          ml: '$space$7',
        },
      },
      9: {
        [childWithGap]: {
          ml: '$space$9',
        },
      },
      12: {
        [childWithGap]: {
          ml: '$space$12',
        },
      },
    },
    align: {
      start: {
        ai: 'start',
      },
      center: {
        ai: 'center',
      },
      stretch: {
        ai: 'stretch',
      },
    },
  },
  defaultVariants: {
    gap: '1',
    align: 'center',
  },
});

export const VStack = styled(Stack, {
  fd: 'column',

  variants: {
    gap: {
      0: {
        [childWithGap]: {
          mt: '0',
        },
      },
      1: {
        [childWithGap]: {
          mt: '3px',
        },
      },
      2: {
        [childWithGap]: {
          mt: '$space$2',
        },
      },
      3: {
        [childWithGap]: {
          mt: '$space$3',
        },
      },
      4: {
        [childWithGap]: {
          mt: '$space$4',
        },
      },
      5: {
        [childWithGap]: {
          mt: '$space$5',
        },
      },
      6: {
        [childWithGap]: {
          mt: '$space$6',
        },
      },
      7: {
        [childWithGap]: {
          mt: '$space$7',
        },
      },
      9: {
        [childWithGap]: {
          mt: '$space$9',
        },
      },
      12: {
        [childWithGap]: {
          mt: '$space$12',
        },
      },
    },
    align: {
      center: {
        ai: 'center',
      },
      stretch: {
        ai: 'stretch',
      },
    },
  },
  defaultVariants: {
    gap: '6',
    align: 'stretch',
  },
});
