/* eslint-disable react-hooks/exhaustive-deps */
import { PopoverPortal } from '@radix-ui/react-popover';
import { useCombobox } from 'downshift';
import React, { useEffect, useRef, useState } from 'react';
import { HiPlus } from 'react-icons/hi';

import * as API from '@/shared/api/contacts/v1';
import { useDisclosure } from '@/shared/hooks';
import {
  Box,
  ComboboxItem,
  ComboboxItemText,
  HStack,
  Input,
  Popover,
  PopoverContent,
  PopoverTrigger,
  ScrollArea,
  ScrollAreaCorner,
  ScrollAreaScrollbar,
  ScrollAreaThumb,
  ScrollAreaViewport,
} from '@/shared/ui';
import {
  formatPhoneNumber,
  isValidPhoneNumber,
  phoneRegex,
} from '@/shared/utils/validations/validations';
import { styled } from '@/stitches.config';

import { AddContact } from './AddAudienceContact';

type SelectOption = {
  type: string;
  value: string | number;
};

type AudienceSearchInputProps = {
  /** list of options in the [{type: string, value: string}] format */
  options: Array<SelectOption>;
  /** placeholder for the input field */
  defaultPlaceholder: string;
  /** will the component be a dropdown */
  isDropdown: boolean;
  /** if this is true close the dropdown on click */
  closeOnClick?: boolean;
  /** function to trigger on input value change */
  onChange?: (value: string) => void;
  /** function triggered on searched item clicked */
  onSearchedItemClick: (value: string | number, name: string) => void;
  /** if true, disables the input */
  disabled: boolean;
};

export const AudienceSearchInput = (props: AudienceSearchInputProps): JSX.Element => {
  const [inputItems, setInputItems] = useState(props.options);
  const inputRef = useRef() as React.MutableRefObject<HTMLInputElement>;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [anchorWidth, setAnchorWidth] = useState(0);
  const [placeholder, setPlaceholder] = useState(props.defaultPlaceholder || '');
  const [searchValue, setSearchValue] = useState('');

  const isPhoneNumber = (value: string) => {
    return phoneRegex.containsNumber.test(value);
  };

  const updateAnchorWidth = () => {
    setAnchorWidth(inputRef.current.offsetWidth);
  };

  const handleClick = () => {
    if (props.disabled) {
      return null;
    }
    if (!isOpen) {
      onOpen();
      updateAnchorWidth();
      setPlaceholder('');
    } else {
      setPlaceholder(props.defaultPlaceholder);
      onClose();
    }
  };

  const handlePointerDownOutside = (e: Event) => {
    if (e.currentTarget !== inputRef.current) {
      setPlaceholder(props.defaultPlaceholder);
      onClose();
    }
  };

  // pass this to AddAudienceContact to create a new contact
  // if a valid contact is created, add it to the audience
  const addNewContact = async (contact: any) => {
    const data = await API.createContact(contact);
    if (data.id) {
      props.onSearchedItemClick(data.id, data?.name || data?.phone || '');
    }
  };

  useEffect(() => {
    setPlaceholder(props.defaultPlaceholder || '');
  }, [props.defaultPlaceholder]);

  useEffect(() => {
    setInputItems(props.options);
  }, [props.options]);

  const { getMenuProps, getInputProps, getItemProps, highlightedIndex } = useCombobox({
    itemToString: (item) => (item ? item.type : ''),
    items: inputItems,
    onSelectedItemChange: ({ selectedItem }) => {
      props.closeOnClick ? onClose() : null;
      props.onSearchedItemClick(selectedItem?.value || '', selectedItem?.type || '');
      return null;
    },
    selectedItem: null,
    stateReducer: (state, actionAndChanges) => {
      const { type, changes } = actionAndChanges;
      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
        case useCombobox.stateChangeTypes.InputKeyDownEscape:
        case useCombobox.stateChangeTypes.InputBlur:
          return {
            ...changes,
            inputValue: '',
          };
        default:
          return {
            ...changes,
          };
      }
    },
    onInputValueChange: ({ inputValue }) => {
      setSearchValue(inputValue || '');
      if (!inputValue) return;

      props.onChange?.(inputValue);
      if (inputValue) {
        isOpen ? null : onOpen();
      }
    },
  });

  return (
    <Box>
      <Popover open={isOpen} onOpenChange={() => !isOpen} modal={false}>
        <PopoverTrigger onClick={handleClick} asChild>
          <Box>
            <Input
              {...getInputProps(
                {
                  placeholder: placeholder,
                  autoComplete: 'chrome-off',
                  type: 'text',
                  ref: inputRef,
                },
                { suppressRefError: true }
              )}
              variant="ghost"
            />
          </Box>
        </PopoverTrigger>
        <PopoverPortal>
          <StyledPopoverContent
            side="bottom"
            sideOffset={8}
            align="center"
            onOpenAutoFocus={(e: { preventDefault: () => any }) => e.preventDefault()}
            onPointerDownOutside={(e: Event) => handlePointerDownOutside(e)}
          >
            <ScrollArea variant="combobox">
              <ScrollAreaViewport
                variant="combobox"
                {...getMenuProps({}, { suppressRefError: true })}
              >
                <AddContact
                  hideOverlay={true}
                  addContact={addNewContact}
                  phone={isPhoneNumber(searchValue) ? searchValue : ''}
                  name={isPhoneNumber(searchValue) ? '' : searchValue}
                >
                  <ComboboxItem
                    justify="start"
                    align="center"
                    css={{ borderBottom: '2px solid $slate2' }}
                  >
                    <HStack>
                      <HiPlus />
                      <ComboboxItemText>Add New Contact</ComboboxItemText>
                    </HStack>
                  </ComboboxItem>
                </AddContact>
                {inputItems.length > 0 ? (
                  inputItems.map((item, index) => (
                    <Box
                      key={`${item.value}${index}`}
                      {...getItemProps({ item, index })}
                      css={{
                        width: props.isDropdown ? anchorWidth : 'auto',
                        minWidth: '100%',
                      }}
                    >
                      <ComboboxItem
                        selected={highlightedIndex === index}
                        css={{ width: '100%' }}
                      >
                        <HStack gap={2} css={{ overflow: 'hidden' }}>
                          <ComboboxItemText css={{ textTransform: 'capitalize' }}>
                            {isValidPhoneNumber(item.type) &&
                            !item.type.match(/[a-zA-Z]/i)
                              ? formatPhoneNumber(item.type)
                              : item.type}
                          </ComboboxItemText>
                        </HStack>
                      </ComboboxItem>
                    </Box>
                  ))
                ) : (
                  <Box
                    css={{
                      width: props.isDropdown ? anchorWidth : 'auto',
                      minWidth: '100%',
                    }}
                  >
                    <ComboboxItem
                      disabled="true"
                      justify="center"
                      align="center"
                      css={{ pointerEvent: 'none' }}
                    >
                      <ComboboxItemText>No Results Found</ComboboxItemText>
                    </ComboboxItem>
                  </Box>
                )}
              </ScrollAreaViewport>
              <ScrollAreaScrollbar orientation="vertical" variant="combobox">
                <ScrollAreaThumb />
              </ScrollAreaScrollbar>
              <ScrollAreaCorner />
            </ScrollArea>
          </StyledPopoverContent>
        </PopoverPortal>
      </Popover>
    </Box>
  );
};

const StyledPopoverContent = styled(PopoverContent, {
  width: 'calc(100% + 90px) !important',
  zIndex: 999999,
  ml: '-16px',
  boxSizing: 'border-box',
  backgroundColor: '$panel',
  border: '1px solid $slate4',
  boxShadow:
    '0px 10px 38px -10px rgba(22, 23, 24, 0), $colors$shadowDark 0px 10px 20px -15px',
});
