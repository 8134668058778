import { FormikValues, useFormikContext } from 'formik';
import React from 'react';
import CreatableSelect from 'react-select/creatable';

type KeywordBuilderProps = {
  name: string;
  options: { value: string; label: string }[];
};

// use creatable select to allow users to add new keywords

export function KeywordBuilder(props: KeywordBuilderProps): JSX.Element {
  const formik = useFormikContext<FormikValues>();

  const handleChange = (newValue: any) => {
    formik.setFieldValue(
      props.name,
      newValue.map((v: any) => v.value)
    );
  };

  return (
    <CreatableSelect
      isMulti
      options={props.options}
      defaultValue={props.options.map((o) => ({
        value: o.value,
        label: o.label,
      }))}
      onChange={handleChange}
    />
  );
}
