import React, { Dispatch } from 'react';

import { UtmBuilderOptions } from '@/shared/types/links';
import { Box, Grid, Input, Label } from '@/shared/ui';
import { styled } from '@/stitches.config';

type FieldType = {
  label: string;
  name: string;
  placeholder: string;
};

type UTMBuilderFormProps = {
  /* utmBuilder options */
  utmBuilder: UtmBuilderOptions;
  /* utmBuilder options setter */
  setUtmBuilder: Dispatch<React.SetStateAction<UtmBuilderOptions>>;
};

const fields = [
  {
    label: 'UTM Source',
    name: 'utm_source',
    placeholder: 'twitter, facebook',
  },
  {
    label: 'UTM Content',
    name: 'utm_content',
    placeholder: 'link, landing page',
  },
  {
    label: 'UTM Medium',
    name: 'utm_medium',
    placeholder: 'social, email',
  },
  {
    label: 'UTM Campaign',
    name: 'utm_campaign',
    placeholder: 'use to differentiate ads',
  },
  {
    label: 'UTM Term',
    name: 'utm_term',
    placeholder: 'identify the paid keywords',
  },
];

export const UTMBuilderForm = ({ utmBuilder, setUtmBuilder }: UTMBuilderFormProps) => {
  const renderField = (item: FieldType) => (
    <Column>
      <Label>{item.label}</Label>
      <Input
        placeholder={item.placeholder}
        value={utmBuilder?.[item.name as keyof UtmBuilderOptions] || ''}
        onChange={(e) => setUtmBuilder({ ...utmBuilder, [item.name]: e.target.value })}
      />
    </Column>
  );

  return <FormColumns columns={2}>{fields.map(renderField)}</FormColumns>;
};

const FormColumns = styled(Grid, {
  flexWrap: 'wrap',
  backgroundColor: '#F6F6F6',
  borderRadius: 2,
  mb: 10,
  p: 20,
  px: 12.5,
  pt: 5,
  '& > *': {
    mt: 15,
  },
});

const Column = styled(Box, {
  px: 7.5,
});
