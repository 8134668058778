/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { HiChevronLeft, HiChevronRight } from 'react-icons/hi';

import { TooltipButton } from '@/shared/components/TooltipButton';
import {
  ColumnMap,
  Filter,
  FilterConfig,
  Resource,
  Sort,
  SortColumnMap,
  SortConfig,
} from '@/shared/types/filter';
import { Badge, Box, Flex } from '@/shared/ui';
import { styled } from '@/stitches.config';

import FiltersMenuFilterRow from './FiltersMenuFilterRow';
import FiltersMenuSortRow from './FiltersMenuSortRow';

type FiltersMenuProps<
  R extends Resource,
  FC extends ColumnMap[R],
  SC extends SortColumnMap[R],
> =
  | {
      type: 'filter';
      config: FilterConfig<R, FC>;
      onFilterRowUpdate?: (rows: Filter[]) => void;
      activeFilters?: Filter[];
      children?: React.ReactNode;
      filterDropdownConfig?: {
        [key: string]: {
          label: string;
          column: string;
          resource: string;
          value: string;
        }[];
      };
    }
  | {
      type: 'sort';
      config: SortConfig<R, SC>;
      onFilterRowUpdate?: (rows: Sort[]) => void;
      activeFilters?: Sort[];
      children?: React.ReactNode;
      filterDropdownConfig?: {
        [key: string]: {
          label: string;
          column: string;
          resource: string;
          value: string;
        }[];
      };
    };

/**
 * The Filters Menu component which contains filters rows OR sort rows (as defined by the type prop).
 * Filter Rows can be added and removed from the Filters Menu component.
 * The comparison value can be updated by selecting the relevant option from the dropdown.
 * The row type will dictate whether a text input, datepicker or dropdown is rendered for
 * selecting / updating a filter value.
 *
 * @param config - The filter row with id, label, value, order & resource values.
 * @param type - 'Filter' or 'Sort' - determines which row components can be added.
 * @param onFilterRowUpdate - A callback function which is called when you add, remove or update the filters or sorts.
 * @param activeFilters - A list filters which are currently applied, which are used to populate the rows.
 * @param filterDropdownConfig - A list of options passed to the row dropdown when the row type === 'dropdown'.
 */

const FiltersMenu = <
  R extends Resource,
  FC extends ColumnMap[R],
  SC extends SortColumnMap[R],
>({
  config,
  type,
  onFilterRowUpdate,
  activeFilters,
  filterDropdownConfig,
  children,
}: FiltersMenuProps<R, FC, SC>) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isColumnDropdownOpen, setIsColumnDropdownOpen] = useState(false);
  const [filterRows, setFilterRows] = useState<(Filter | Sort)[]>(activeFilters || []);
  const [currentOptions, setCurrentOptions] = useState(
    config.columnOptions ? config.columnOptions : []
  );

  const filtersMenuRef = useRef<HTMLDivElement | null>(null);
  const columnDropdownRef = useRef<HTMLDivElement | null>(null);

  const rowId = useRef(0); // Keeping track of the last assigned ID with useRef so that it doesn't cause a re-render when updated.

  const isValueEmpty =
    filterRows.length &&
    (filterRows[filterRows.length - 1] as Filter).value !== undefined &&
    ((filterRows[filterRows.length - 1] as Filter).value === '' ||
      (filterRows[filterRows.length - 1] as Filter).value === null); // Check if the last filter row has an empty value. Used to disable "AddFilterButton".

  const addRow = (newRow: Filter | Sort) => {
    // Assign an ID to the newly added row in order to identify that
    // row later when we want to apply updates to it.
    const newRowWithId = { ...newRow, id: rowId.current++ };
    const updatedRows = [...filterRows, newRowWithId];

    setFilterRows(updatedRows);

    if ('type' in newRow && newRow.type === 'text' && newRow.value === '') return;
    if ('type' in newRow && newRow.type === 'dropdown' && newRow.value === null) return;

    if (type === 'filter') {
      onFilterRowUpdate ? onFilterRowUpdate(updatedRows as Filter[]) : null;
    } else if (type === 'sort') {
      onFilterRowUpdate ? onFilterRowUpdate(updatedRows as Sort[]) : null;
    }
  };

  const removeRow = (newRow: Filter | Sort) => {
    setFilterRows((prevRows) => {
      const updatedRows = prevRows.filter((row) => row.id !== newRow.id);
      if (type === 'filter') {
        onFilterRowUpdate ? onFilterRowUpdate(updatedRows as Filter[]) : null;
      } else if (type === 'sort') {
        onFilterRowUpdate ? onFilterRowUpdate(updatedRows as Sort[]) : null;
      }

      return updatedRows;
    });
  };

  const updateRow = (newRow: Filter | Sort) => {
    setFilterRows((prevRows) => {
      const existingRow = prevRows.find((row) => row.id === newRow.id);
      const updatedRows = prevRows.map((row) => (row.id === newRow.id ? newRow : row));

      // If both the previous value and the newRow.value are empty strings, return updated rows without making the API call.
      // Check if newRow is a Filter to access its value property
      if (
        'value' in newRow &&
        existingRow &&
        'value' in existingRow &&
        existingRow.value === '' &&
        newRow.value === ''
      ) {
        return updatedRows;
      }

      if (type === 'filter') {
        onFilterRowUpdate ? onFilterRowUpdate(updatedRows as Filter[]) : null;
      } else if (type === 'sort') {
        onFilterRowUpdate ? onFilterRowUpdate(updatedRows as Sort[]) : null;
      }

      return updatedRows;
    });
  };

  const handleColumnOptionClick = (option: Filter | Sort) => {
    // if (option.subOptions && option.subOptions.length > 0) {
    //   setCurrentOptions([{ label: option.label, type: 'header' }, ...option.subOptions]);
    // } else {
    setIsColumnDropdownOpen(false);
    addRow(option);
    // }
  };

  const toggleMenu = () => {
    setFilterRows(activeFilters || []);
    setIsMenuOpen((prev) => !prev);
  };

  const handleAddButtonClick = () => {
    if (isValueEmpty) return;
    setIsColumnDropdownOpen((prev) => !prev);
  };

  useEffect(() => {
    if (!isColumnDropdownOpen) {
      setCurrentOptions(config.columnOptions);
    }
  }, [isColumnDropdownOpen]);

  useEffect(() => {
    const clickOutside = (event: MouseEvent) => {
      const isDatepicker = document
        .getElementsByClassName('filter-date-picker')[0]
        ?.contains(event?.target as Node);

      if (!filtersMenuRef.current?.contains(event?.target as Node) && !isDatepicker) {
        setIsMenuOpen(false);
      }

      if (!columnDropdownRef.current?.contains(event?.target as Node)) {
        setIsColumnDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', clickOutside);

    return () => {
      document.removeEventListener('mousedown', clickOutside);
    };
  }, []);

  return (
    <FiltersMenuContainer ref={filtersMenuRef}>
      {children ? (
        <Flex
          align="center"
          css={{ height: 33, padding: '2px 9px' }}
          onClick={toggleMenu}
          data-testid="filters-menu-container"
        >
          {children}

          {activeFilters && activeFilters.length > 0 && (
            <Badge
              css={{ position: 'absolute', top: '-10px', right: '-10px' }}
              variant="darkBlue"
              data-testid="filters-menu-badge"
            >
              {activeFilters.length}
            </Badge>
          )}
        </Flex>
      ) : (
        <Flex
          align="center"
          css={{ height: 33, padding: '2px $space$3' }}
          onClick={toggleMenu}
          data-testid="filters-menu-container"
        >
          {children ?? <span>{config.label || ''}</span>}

          {activeFilters && activeFilters.length > 0 && (
            <Badge
              css={{ marginLeft: 10 }}
              variant="blue"
              data-testid="filters-menu-badge"
            >
              {activeFilters.length}
            </Badge>
          )}
        </Flex>
      )}

      {isMenuOpen && (
        <MenuDropdown css={children ? { right: '-5px', left: 'auto' } : {}}>
          {filterRows.map((row: Filter | Sort) => {
            const dropdownOptions = filterDropdownConfig
              ? filterDropdownConfig[row.column]
              : null;

            return type === 'filter' ? (
              <FiltersMenuFilterRow
                key={row.id}
                row={row as Filter}
                onRemove={() => removeRow(row)}
                onUpdateFilter={updateRow}
                filterDropdownOptions={dropdownOptions || []}
              />
            ) : (
              <FiltersMenuSortRow
                data-testid="filters-menu-sort-row"
                key={row.id}
                row={row as Sort}
                onRemove={() => removeRow(row)}
                onUpdateSort={updateRow}
              />
            );
          })}

          {isValueEmpty ? (
            <TooltipButton
              text="Please complete active filters before adding more."
              side="bottom"
              sideOffset={10}
              css={{ width: 200, lineHeight: '18px' }}
            >
              <AddButton
                disabled={true}
                onClick={handleAddButtonClick}
                data-testid="filters-menu-add-button"
              >
                <span>{`Add ${config.label} +`}</span>
              </AddButton>
            </TooltipButton>
          ) : (
            <AddButton
              disabled={false}
              onClick={handleAddButtonClick}
              data-testid="filters-menu-add-button"
            >
              <span>{`Add ${config.label} +`}</span>
            </AddButton>
          )}

          {isColumnDropdownOpen && (
            <ColumnDropdown ref={columnDropdownRef}>
              {currentOptions.map((option: any) => {
                if (option.type === 'header') {
                  return (
                    <Flex
                      key={option.column}
                      align="center"
                      css={{ padding: '$space$2 $space$2', cursor: 'pointer' }}
                      onClick={() => setCurrentOptions(config.columnOptions)}
                    >
                      <HiChevronLeft />
                      {option.label}
                    </Flex>
                  );
                }
                return (
                  <ColumnOption
                    data-testid="filters-menu-column-option"
                    key={option.column}
                    align="center"
                    justify="between"
                    onClick={() => handleColumnOptionClick(option)}
                  >
                    <div>{option.label}</div>
                    {option.subOptions && (
                      <Flex align="center">
                        <span>{option.subOptions.length}</span>
                        <HiChevronRight />
                      </Flex>
                    )}
                  </ColumnOption>
                );
              })}
            </ColumnDropdown>
          )}
        </MenuDropdown>
      )}
    </FiltersMenuContainer>
  );
};

export default FiltersMenu;

export const FiltersMenuContainer = styled(Box, {
  position: 'relative',
  cursor: 'pointer',
  fontSize: '14px',
  border: '1px solid $slate7',
  borderRadius: '4px',
  '&:hover': {
    borderColor: '$slate4',
  },
  backgroundColor: 'white',
});

const MenuDropdown = styled(Box, {
  position: 'absolute',
  top: '38px',
  left: '-2px',
  width: 'auto',
  minWidth: '528px',
  padding: '$space$3',
  backgroundColor: 'white',
  border: '1px solid $gray3',
  borderRadius: '4px',
  zIndex: 3,
  cursor: 'default',
  boxShadow: '0px 4px 12px 0px #e3e3e3',
});

const AddButton = styled(Box, {
  display: 'inline-block',
  position: 'relative',
  padding: '$space$1 $space$3',
  borderRadius: '4px',
  variants: {
    disabled: {
      true: {
        opacity: 0.5,
      },
      false: {
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: '$slate3',
        },
      },
    },
  },
});

const ColumnDropdown = styled(Box, {
  position: 'absolute',
  minWidth: '190px',
  backgroundColor: 'white',
  border: '1px solid $gray3',
  borderRadius: '4px',
  padding: '$space$2 0',
  boxShadow: '0px 4px 12px 0px #e3e3e3',
});

const ColumnOption = styled(Flex, {
  padding: '$space$2 $space$3',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: '$slate3',
  },
});
