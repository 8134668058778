/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-key */
import React, { useEffect, useMemo } from 'react';
import { HiChevronLeft, HiChevronRight } from 'react-icons/hi';
import { CellProps, Column, usePagination, useRowSelect, useTable } from 'react-table';

import {
  Box,
  Button,
  Checkbox,
  CustomScrollbar,
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogPortal,
  DialogTitle,
  Flex,
  HStack,
  IconButton,
  Input,
  Label,
  Text,
  VStack,
} from '@/shared/ui';
import { formatPhoneNumber } from '@/shared/utils/validations/validations';
import { styled } from '@/stitches.config';

import { useUploads } from '../uploads/context/UploadContext';

type PhoneObject = {
  phone: string;
};

type PasteContactsTableProps = {
  onClose: () => void;
  onOpen?: () => void;
  isOpen: boolean;
  contacts: Array<PhoneObject>;
  setContacts: (contacts: Array<PhoneObject>) => void;
};

// Be sure to pass our updateMyData and the skipPageReset option
export function PasteContactsTable(props: PasteContactsTableProps) {
  const { isOpen, onClose, contacts, setContacts } = props;
  const { uploadName, setUploadName } = useUploads();

  const columns: Column<PhoneObject>[] = useMemo(
    () => [
      {
        Header: 'Phone Number',
        accessor: 'phone',
        Cell: ({ cell: { value } }: CellProps<PhoneObject>) => (
          <Flex>{formatPhoneNumber(value)}</Flex>
        ),
      },
    ],
    []
  );

  const data = useMemo(() => contacts, [contacts]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    selectedFlatRows,
    toggleAllRowsSelected,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data,
    },
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        {
          id: 'selection',
          Header: ({ getToggleAllPageRowsSelectedProps }) => (
            <Flex css={{ maxWidth: 40, pl: 20 }}>
              <Checkbox
                {...getToggleAllPageRowsSelectedProps()}
                onCheckedChange={toggleAllRowsSelected}
              />
            </Flex>
          ),
          Cell: ({ row }: CellProps<PhoneObject>) => (
            <Flex css={{ maxWidth: 40, pl: 20 }}>
              <Checkbox
                {...row.getToggleRowSelectedProps()}
                onCheckedChange={row.toggleRowSelected}
              />
            </Flex>
          ),
        },
        ...columns,
      ]);
    }
  );

  useEffect(() => {
    toggleAllRowsSelected();
  }, [isOpen]);

  const selectedContacts: Array<PhoneObject> = selectedFlatRows.map((p: any) => p.values);

  // Render the UI for your table
  return (
    <Dialog open={isOpen}>
      <DialogPortal>
        <DialogContent
          onEscapeKeyDown={() => onClose()}
          onPointerDownOutside={() => onClose()}
          css={{ p: 0, width: 500, zIndex: 1000002 }}
        >
          <DialogTitle css={{ p: 20, pb: 0 }}>
            Add pasted contacts from clipboard
          </DialogTitle>
          <VStack gap={1} css={{ px: 20, pb: 20 }}>
            <Label htmlFor="name">
              <Flex>
                Name Contact List{' '}
                <Box css={{ color: 'red', ml: 5 }}>
                  {uploadName.length > 28
                    ? '(max 30 characters)'
                    : '(name must be unique)'}
                </Box>
              </Flex>
            </Label>
            <Input
              id="name"
              name="name"
              type="text"
              placeholder="New Upload..."
              maxLength={29}
              value={uploadName}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setUploadName(e.target.value)
              }
            />
          </VStack>
          <Box css={{ width: '100%' }}>
            <Styled>
              <CustomScrollbar>
                <Flex css={{ maxHeight: 303, width: '100%' }}>
                  <table {...getTableProps()}>
                    <thead>
                      {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                          {headerGroup.headers.map((column) => (
                            <th {...column.getHeaderProps()}>
                              {column.render('Header')}
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                      {page.map((row) => {
                        prepareRow(row);
                        return (
                          <tr {...row.getRowProps()}>
                            {row.cells.map((cell) => {
                              return (
                                <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                              );
                            })}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </Flex>
              </CustomScrollbar>
              <Flex
                align="center"
                justify="center"
                css={{ width: '100%', borderBottom: '1px solid $gray3', p: 5 }}
              >
                <HStack gap={3}>
                  <IconButton
                    onClick={() => previousPage()}
                    disabled={!canPreviousPage}
                    variant="ghost"
                  >
                    <HiChevronLeft />
                  </IconButton>{' '}
                  <Text>
                    Page{' '}
                    <strong>
                      {pageIndex + 1} of {pageOptions.length}
                    </strong>{' '}
                  </Text>
                  <IconButton
                    onClick={() => nextPage()}
                    disabled={!canNextPage}
                    variant="ghost"
                  >
                    <HiChevronRight />
                  </IconButton>{' '}
                </HStack>
              </Flex>
            </Styled>
          </Box>
          <DialogFooter justify="end" css={{ p: 20, pt: 0, mt: 20 }}>
            <DialogClose asChild>
              <Button
                aria-label="Close"
                variant="gray"
                type="button"
                onClick={() => onClose()}
                css={{ mr: 5 }}
              >
                Cancel
              </Button>
            </DialogClose>
            <DialogClose asChild>
              <Button onClick={() => setContacts(selectedContacts)}>Save Contacts</Button>
            </DialogClose>
          </DialogFooter>
        </DialogContent>
      </DialogPortal>
    </Dialog>
  );
}

const Styled = styled(Box, {
  '& table': {
    boxSizing: 'border-box',
    width: '100%',
    borderSpacing: 0,
    border: '1px solid $gray3',
    fontSize: 13,
    textAlign: 'left',

    '& tr': {
      ':last-child': {
        '& td': {
          borderBottom: 'none',
        },
      },
    },

    '& th, td': {
      margin: 0,
      height: 40,
      borderTop: '1px solid $gray3',
      ':last-child': {
        borderRight: 0,
      },
    },
  },
});
