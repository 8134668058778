export const default_contact_tag_object = {
  id: 'unique-id-for-contact_tag',
  label: 'Contact Tag',
  key: 'tag',
  inserted_at: 'current-timestamp',
  updated_at: 'current-timestamp',
  custom_properties: [
    {
      default: '',
      id: 'contact-tag',
      label: 'Tag',
      type: 'tag',
      key: 'id',
      required: true,
      inserted_at: 'current-timestamp',
      updated_at: 'current-timestamp',
      custom_object_id: 'unique-id-for-contact-tag',
    },
  ],
};
