import {
  Team,
  TeamMember,
  TeamsAction,
  TeamsActionType,
  TeamsState,
} from '@/shared/types/team';
import { WorkSchedule } from '@/shared/types/team/schedule';
import { removeDuplicates } from '@/shared/utils/removeDuplicates';

const TeamsReducer: React.Reducer<TeamsState, TeamsAction> = (
  state: TeamsState,
  action: TeamsAction
): TeamsState => {
  switch (action.type) {
    case TeamsActionType.GET_TEAMS:
      return {
        ...state,
        teams: action.payload.data,
        totalCount: action.payload.total,
        loading: false,
      } as TeamsState;
    case TeamsActionType.GET_ALL_TEAMS:
      return {
        ...state,
        allTeams: action.payload.data,
        totalCount: action.payload.total,
        loading: false,
      } as TeamsState;
    case TeamsActionType.GET_TEAM:
      return {
        ...state,
        current: action.payload,
        loading: false,
      } as TeamsState;
    case TeamsActionType.UPDATE_TEAM:
      return {
        ...state,
        current: action.payload,
        loading: false,
      } as TeamsState;
    case TeamsActionType.CREATE_TEAM:
      return {
        ...state,
        teams: [action.payload, ...state.teams],
        allTeams: [action.payload, ...state.allTeams],
        loading: false,
      } as TeamsState;
    case TeamsActionType.DELETE_TEAM:
      return {
        ...state,
        teams: state.teams.filter((team: Team) => {
          return team.id !== action.payload;
        }),
        allTeams: state.allTeams.filter((team: Team) => {
          return team.id !== action.payload;
        }),
        totalCount: state.totalCount - 1,
        loading: false,
      } as TeamsState;
    case TeamsActionType.GET_TEAM_MEMBERS:
      // by type its allow null so if no current return state
      if (!state.current) return state;
      return {
        ...state,
        current: {
          ...state.current,
          team_members: removeDuplicates([
            ...(state.current.team_members || []),
            ...action.payload,
          ]),
        },
        team_members: removeDuplicates([...state.team_members, ...action.payload]),
        loading: false,
      } as TeamsState;
    case TeamsActionType.GET_TEAM_MEMBER:
      return {
        ...state,
        currentTeamMember: action.payload,
        loading: false,
      } as TeamsState;
    case TeamsActionType.CREATE_TEAM_MEMBER:
      // by type its allow null so if no current return state
      if (!state.current) return state;
      return {
        ...state,
        current: {
          ...state.current,
          team_members: removeDuplicates([
            action.payload,
            ...(state.current.team_members || []),
          ]),
        },
        team_members: removeDuplicates([action.payload, ...state.team_members]),
        loading: false,
      } as TeamsState;
    case TeamsActionType.DELETE_TEAM_MEMBER:
      // by type its allow null so if no current return state
      if (!state.current) return state;
      return {
        ...state,
        current: {
          ...state.current,
          team_members: state.current?.team_members?.filter((team_member: TeamMember) => {
            return team_member.id !== action.payload;
          }),
        },
        team_members: state.team_members?.filter((team_member: TeamMember) => {
          return team_member.id !== action.payload;
        }),
        loading: false,
      } as TeamsState;
    case TeamsActionType.SET_CURRENT:
      return {
        ...state,
        current: action.payload,
      } as TeamsState;
    case TeamsActionType.CLEAR_CURRENT:
      return {
        ...state,
        current: null,
      } as TeamsState;
    case TeamsActionType.CLEAR_CURRENT_TEAM_MEMBER:
      return {
        ...state,
        currentTeamMember: null,
      } as TeamsState;
    case TeamsActionType.GET_WORK_SCHEDULES:
      // by type its allow null so if no current return state
      if (!state.current) return state;
      return {
        ...state,
        current: { ...state.current, work_schedules: action.payload },
      } as TeamsState;
    case TeamsActionType.CREATE_WORK_SCHEDULES:
      // by type its allow null so if no current return state
      if (!state.current) return state;
      return {
        ...state,
        current: {
          ...state.current,
          work_schedules: [...(state?.current?.work_schedules || []), action.payload],
        },
      } as TeamsState;
    case TeamsActionType.UPDATE_WORK_SCHEDULES:
      // by type its allow null so if no current return state
      if (!state.current) return state;
      return {
        ...state,
        current: {
          ...state.current,
          work_schedules: state?.current?.work_schedules?.map((workSchedule) =>
            workSchedule.id === action.payload.id ? action.payload : workSchedule
          ),
        },
      } as TeamsState;
    case TeamsActionType.DELETE_WORK_SCHEDULES:
      // by type its allow null so if no current return state
      if (!state.current) return state;
      return {
        ...state,
        current: {
          ...state.current,
          work_schedules: state?.current?.work_schedules?.filter(
            (work_schedule: WorkSchedule) => {
              return work_schedule.id !== action.payload;
            }
          ),
        },
      } as TeamsState;
    case TeamsActionType.CREATE_TEAM_MEMBER_WORK_SCHEDULES:
      // by type its allow null so if no current return state
      if (!state.currentTeamMember) return state;
      return {
        ...state,
        currentTeamMember: {
          ...state.currentTeamMember,
          work_schedules: [
            ...(state?.currentTeamMember?.work_schedules || []),
            action.payload,
          ],
        },
        current: {
          ...state.current,
          team_members: state?.current
            ? state?.current?.team_members?.map((t: TeamMember) => {
                if (t?.id === state.currentTeamMember?.id) {
                  return {
                    ...t,
                    work_schedules: [
                      ...(state?.currentTeamMember?.work_schedules || []),
                      action.payload,
                    ],
                  };
                }
                return t;
              })
            : [],
        },
      } as TeamsState;
    case TeamsActionType.UPDATE_TEAM_MEMBER_WORK_SCHEDULES:
      // by type its allow null so if no current return state
      if (!state.currentTeamMember) return state;
      return {
        ...state,
        currentTeamMember: {
          ...state.currentTeamMember,
          work_schedules: state?.currentTeamMember?.work_schedules?.map((workSchedule) =>
            workSchedule.id === action.payload.id ? action.payload : workSchedule
          ),
        },
        current: {
          ...state.current,
          team_members: state?.current
            ? state?.current?.team_members?.map((t: TeamMember) => {
                if (t?.id === state.currentTeamMember?.id) {
                  return {
                    ...t,
                    work_schedules: state?.currentTeamMember?.work_schedules?.map(
                      (workSchedule) =>
                        workSchedule.id === action.payload.id
                          ? action.payload
                          : workSchedule
                    ),
                  };
                }
                return t;
              })
            : [],
        },
      } as TeamsState;
    case TeamsActionType.DELETE_TEAM_MEMBER_WORK_SCHEDULES:
      // by type its allow null so if no current return state
      if (!state.currentTeamMember) return state;
      return {
        ...state,
        currentTeamMember: {
          ...state.currentTeamMember,
          work_schedules: state?.currentTeamMember?.work_schedules?.filter(
            (work_schedule: WorkSchedule) => {
              return work_schedule.id !== action.payload;
            }
          ),
        },
        current: {
          ...state.current,
          team_members: state?.current
            ? state?.current?.team_members?.map((t: TeamMember) => {
                if (t?.id === state.currentTeamMember?.id) {
                  return {
                    ...t,
                    work_schedules: state?.currentTeamMember?.work_schedules?.filter(
                      (work_schedule: WorkSchedule) => {
                        return work_schedule.id !== action.payload;
                      }
                    ),
                  };
                }
                return t;
              })
            : [],
        },
      } as TeamsState;
    case TeamsActionType.UPDATE_FILTER_PARAMS:
      return {
        ...state,
        filterParams: action.payload,
        loading: true,
      };
    default:
      return state as TeamsState;
  }
};

export default TeamsReducer;
