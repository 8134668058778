import { useCallback, useEffect, useState } from 'react';

const EVENT_KEY_DOWN = 'keydown';
const EVENT_KEY_UP = 'keyup';

/* Hook to verify state of Caplocks */
export function useCaplocks(): boolean {
  /* State for keeping track of whether caplocks is on */
  const [isCaplocksActive, setIsCapLocksActive] = useState<boolean>(false);

  /* Function to see if caplocks was activated - returns a memorized callback */
  const wasCapsLockActivated = useCallback(
    (event: KeyboardEvent) => {
      if (
        event.getModifierState &&
        event.getModifierState('CapsLock') &&
        isCaplocksActive === false
      ) {
        setIsCapLocksActive(true);
      }
    },
    [isCaplocksActive]
  );

  /* Function to see if caplocks was deactivated - returns a memorized callback */
  const wasCapsLockDeactivated = useCallback(
    (event: KeyboardEvent) => {
      if (
        event.getModifierState &&
        !event.getModifierState('CapsLock') &&
        isCaplocksActive === true
      ) {
        setIsCapLocksActive(false);
      }
    },
    [isCaplocksActive]
  );

  useEffect(() => {
    /* Add keydown event listener */
    document.addEventListener(EVENT_KEY_DOWN, wasCapsLockActivated);
    /* Remove keydown event listener on cleanup */
    return () => {
      document.removeEventListener(EVENT_KEY_DOWN, wasCapsLockActivated);
    };
  }, [wasCapsLockActivated]);

  useEffect(() => {
    /* Add keyup event listener */
    document.addEventListener(EVENT_KEY_UP, wasCapsLockDeactivated);
    return () => {
      /* Remove keyup event listener on cleanup */
      document.removeEventListener(EVENT_KEY_UP, wasCapsLockDeactivated);
    };
  }, [wasCapsLockDeactivated]);

  return isCaplocksActive;
}
