import * as SelectPrimitive from '@radix-ui/react-select';
import { FormikProps } from 'formik';
import React, { useEffect, useMemo, useState } from 'react';
import { HiChevronDown, HiChevronUp, HiTrash } from 'react-icons/hi';
import { Virtuoso } from 'react-virtuoso';

import { FormFieldWrapper, TextInput } from '@/shared/components/forms';
import {
  Box,
  Flex,
  HStack,
  IconButton,
  Select,
  SelectContent,
  SelectGroup,
  SelectIcon,
  SelectItem,
  SelectItemIndicator,
  SelectItemText,
  SelectLabel,
  SelectScrollUpButton,
  SelectTrigger,
  SelectValue,
  SelectViewport,
} from '@/shared/ui';

import {
  CustomObject,
  CustomProperty,
  PropertyFormType,
  PropertyType,
  ReferencePropertyType,
} from '../../../../../shared/types/data';
import { referenceTypes } from '../constants';

export const ReferenceProperty = ({
  index,
  formik,
  reference,
  remove,
  customObjects,
}: {
  index: number;
  formik: FormikProps<PropertyFormType>;
  reference: ReferencePropertyType;
  remove: (index: number) => void;
  customObjects: Array<CustomObject>;
}) => {
  const [properties, setProperties] = useState<Array<CustomProperty>>([]);

  useEffect(() => {
    if (reference.custom_object_id) {
      const object = customObjects.find((o) => o.id === reference.custom_object_id);
      setProperties(object?.custom_properties ?? []);
    }
  }, [reference.custom_object_id, customObjects]);

  const currentCustomObject = useMemo(() => {
    const item = customObjects.find((p) => p.id === reference.custom_object_id);
    return item?.label;
  }, [reference.custom_object_id]);

  const currentCustomProperty = useMemo(() => {
    const item = properties.find((p) => p.id === reference.custom_property_id);
    return item?.label;
  }, [reference.custom_property_id, properties]);

  return (
    <>
      <Flex align="center" justify="between" css={{ marginBottom: 6 }}>
        <Box css={{ color: '#3E63DD', fontSize: 15 }}>
          Reference {formik.values.references.length > 1 && index + 1}
        </Box>
        <IconButton
          aria-label="delete data object"
          variant="ghost"
          size="2"
          data-testid="delete-object-button"
          onClick={() => remove(index)}
        >
          <HiTrash size="15px" color="#60646C" />
        </IconButton>
      </Flex>
      <Box>
        <Box>
          <FormFieldWrapper name={`references.${index}.id`}>
            <TextInput css={{ display: 'none' }} />
          </FormFieldWrapper>
          <FormFieldWrapper
            label="Select the Custom Object"
            name={`references.${index}.custom_object_id`}
          >
            <Select
              value={reference.custom_object_id}
              onValueChange={(e: string) => {
                formik.setFieldValue(`references.${index}.custom_object_id`, e);
                formik.setFieldValue(`references.${index}.custom_property_id`, '');
                formik.setFieldValue(`references.${index}.type`, '');
              }}
            >
              <SelectTrigger aria-label={`references.${index}.custom_object_id`}>
                <HStack>{currentCustomObject}</HStack>
                <SelectIcon>
                  <HiChevronDown />
                </SelectIcon>
              </SelectTrigger>
              <SelectPrimitive.Portal>
                <SelectContent css={{ zIndex: 9999999, width: 450 }} position="popper">
                  <SelectScrollUpButton>
                    <HiChevronUp />
                  </SelectScrollUpButton>
                  <SelectViewport>
                    <SelectGroup>
                      <SelectLabel>Select the Custom Object</SelectLabel>
                      <Virtuoso
                        style={{
                          height: customObjects.length * 25,
                          maxHeight: 300,
                        }}
                        data={customObjects}
                        itemContent={(i: number, property: CustomObject) => (
                          <SelectItem
                            data-testid={property.id}
                            key={i}
                            value={property.id}
                          >
                            <SelectItemText>{property.label}</SelectItemText>
                            <SelectItemIndicator />
                          </SelectItem>
                        )}
                      />
                    </SelectGroup>
                  </SelectViewport>
                </SelectContent>
              </SelectPrimitive.Portal>
            </Select>
          </FormFieldWrapper>
        </Box>
        <Box>
          <FormFieldWrapper
            label="Select the Custom Property"
            name={`references.${index}.custom_property_id`}
          >
            <Select
              disabled={!reference.custom_object_id}
              value={reference.custom_property_id}
              onValueChange={(e: string) => {
                formik.setFieldValue(`references.${index}.custom_property_id`, e);
                formik.setFieldValue(`references.${index}.type`, '');
              }}
            >
              <SelectTrigger aria-label={`references.${index}.custom_property_id`}>
                <HStack>{currentCustomProperty}</HStack>
                <SelectIcon>
                  <HiChevronDown />
                </SelectIcon>
              </SelectTrigger>
              <SelectPrimitive.Portal>
                <SelectContent css={{ zIndex: 9999999, width: 450 }} position="popper">
                  <SelectScrollUpButton>
                    <HiChevronUp />
                  </SelectScrollUpButton>
                  <SelectViewport>
                    <SelectGroup>
                      <SelectLabel>Select the Custom Property</SelectLabel>
                      <Virtuoso
                        data={properties}
                        style={{
                          height: properties.length * 25,
                          maxHeight: 300,
                        }}
                        itemContent={(i: number, property: CustomProperty) => (
                          <SelectItem key={i} value={property.id}>
                            <SelectItemText>{property.label}</SelectItemText>
                            <SelectItemIndicator />
                          </SelectItem>
                        )}
                      />
                    </SelectGroup>
                  </SelectViewport>
                </SelectContent>
              </SelectPrimitive.Portal>
            </Select>
          </FormFieldWrapper>
        </Box>
        <Box>
          <FormFieldWrapper
            label="Select the Reference Type"
            name={`references.${index}.type`}
          >
            <Select
              disabled={!reference.custom_property_id}
              value={reference.type}
              onValueChange={(e: PropertyType) => {
                formik.setFieldValue(`references.${index}.type`, e);
              }}
            >
              <SelectTrigger aria-label={`references.${index}.type`}>
                <HStack>
                  <SelectValue />
                </HStack>
                <SelectIcon>
                  <HiChevronDown />
                </SelectIcon>
              </SelectTrigger>
              <SelectPrimitive.Portal>
                <SelectContent css={{ zIndex: 9999999, width: 450 }} position="popper">
                  <SelectScrollUpButton>
                    <HiChevronUp />
                  </SelectScrollUpButton>
                  <SelectViewport>
                    <SelectGroup>
                      <SelectLabel>Select the Reference Type</SelectLabel>
                      {referenceTypes.map((option) => (
                        <SelectItem key={option.value} value={option.value}>
                          <SelectItemText>{option.label}</SelectItemText>
                          <SelectItemIndicator />
                        </SelectItem>
                      ))}
                    </SelectGroup>
                  </SelectViewport>
                </SelectContent>
              </SelectPrimitive.Portal>
            </Select>
          </FormFieldWrapper>
        </Box>
      </Box>
    </>
  );
};
