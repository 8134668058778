/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { HiEye, HiEyeOff } from 'react-icons/hi';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'sonner';

import { usePageView } from '@/shared/hooks';
import {
  Box,
  Button,
  ControlGroup,
  Fieldset,
  Flex,
  Input,
  Label,
  Text,
  VStack,
} from '@/shared/ui';
import i18next from '@/shared/utils/translation';
import { isValidEmail } from '@/shared/utils/validations/validations';
import { isValidUuid } from '@/shared/utils/validations/validations';
import { styled } from '@/stitches.config';

import { useAuth } from './context/AuthProvider';

type ParamTypes = {
  id: string;
};

export const Login = () => {
  usePageView();

  const auth = useAuth();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [inviteToken, setInviteToken] = useState('');
  const [show, setShow] = useState(Boolean);
  const invite = useParams<ParamTypes>();

  useEffect(() => {
    if (isValidUuid(invite.id)) {
      setInviteToken(invite.id);
    }
  }, []);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!isValidEmail(email)) {
      return toast.error(i18next.t('enter_valid_email') as string);
    }

    try {
      await auth.login({ email, password, inviteToken });
    } catch (e: any) {
      const statusCode = e.response?.data?.error?.status;
      if (e.code === 'ERR_NETWORK') {
        return toast.error(i18next.t('network_error'));
      }

      switch (statusCode) {
        case 401:
          toast.error(i18next.t('incorrect_email_password') as string);
          break;
        case 500:
          toast.error(i18next.t('server_error'));
          break;
        case 403:
          toast.error(i18next.t('no_access_to_organizations_error'));
          break;
        default:
          toast.error(i18next.t('unknown_error'));
          break;
      }
    }
  };

  return (
    <>
      <Helmet>
        <title>Whippy | Login</title>
      </Helmet>
      <AuthPageWrapper align="center" justify="center">
        <AuthContainer>
          <AuthPageTitle css={{ fontSize: 18, pb: 15 }}>
            Sign in to your account
          </AuthPageTitle>
          <form onSubmit={handleSubmit}>
            <VStack gap={5} css={{ pb: 15 }}>
              <Fieldset>
                <Label htmlFor="email">Email</Label>
                <Input
                  id="email"
                  type="email"
                  autoComplete="username"
                  value={email}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setEmail(e.target.value)
                  }
                  required={true}
                />
              </Fieldset>
              <Fieldset>
                <Label htmlFor="password">Password</Label>
                <ControlGroup>
                  <Input
                    id="password"
                    data-testid="password"
                    type={show ? 'text' : 'password'}
                    value={password}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setPassword(e.target.value)
                    }
                    required={true}
                  />
                  <Button
                    type="button"
                    onClick={() => setShow(!show)}
                    aria-label="Show password"
                    variant="gray"
                  >
                    {show ? <HiEye /> : <HiEyeOff />}
                  </Button>
                </ControlGroup>
              </Fieldset>
              <Button type="submit" css={{ minWidth: '100%' }}>
                Login to Whippy
              </Button>
            </VStack>
            <VStack gap={2} css={{ mt: 15 }}>
              <Flex justify="between">
                <Link to="/forgot">Reset password</Link>
                <Link to="/sso">Login with SSO</Link>
              </Flex>
            </VStack>
          </form>
        </AuthContainer>
      </AuthPageWrapper>
    </>
  );
};

export const AuthPageWrapper = styled(Flex, {
  height: '100vh',
  backgroundColor: 'white',
  '@md': {
    backgroundColor: '$primaryColor',
  },
  variants: {
    whiteBackground: {
      true: {
        backgroundColor: 'white',
      },
    },
  },
});

export const AuthPageTitle = styled(Text, {
  fontWeight: 500,
  fontSize: '18px',
  marginBottom: 27,
  width: '100%',
  alignItems: 'center',
});

export const AuthContainer = styled(Box, {
  width: 450,
  p: 50,
  backgroundColor: 'white',
  borderRadius: 8,
  variants: {
    outline: {
      true: {
        border: '1px solid $primaryColor',
      },
    },
  },
});

// This is a temporary page to show when we are doing maintenance
// Replace the Login form with this component to show the maintenance page
export const Maintenance = () => {
  return (
    <Box>
      <AuthPageTitle css={{ fontSize: 18 }}>
        Scheduled Maintenance in-progress.
      </AuthPageTitle>
      <Box>
        <p>
          We are currently performing scheduled maintenance on our systems. We expect to
          be back online by 6:00 AM PST. Thank you for your patience.
        </p>
      </Box>
    </Box>
  );
};
