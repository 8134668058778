import React from 'react';
import { createContext, useContext, useReducer } from 'react';

import { GetAttachmentParams, getAttachments } from '@/shared/api/conversations';
import { Contact } from '@/shared/types';

import AttachmentsReducer from './AttachmentsReducer';
import { GET_ATTACHMENTS, SET_ERROR, SET_LOADING } from './types';

export type Attachment = {
  campaign_id: string | null;
  contact: Contact | null;
  content_type: string;
  conversation_id: string;
  inserted_at: string;
  url: string;
  user_id: number | null;
};

export type AttachmentsState = {
  attachments: Array<Attachment>;
  loading: boolean;
  error: boolean;
};

export const initialAttachmentsState: AttachmentsState = {
  attachments: [],
  error: false,
  loading: true,
};

export const AttachmentsContext = createContext<{
  attachmentsState: AttachmentsState;
  getAllAttachments: (params: GetAttachmentParams) => void;
  downloadAttachments: (params: GetAttachmentParams) => Promise<Array<Attachment>>;
}>({
  attachmentsState: initialAttachmentsState,
  getAllAttachments: () => Promise.resolve(),
  downloadAttachments: () => Promise.resolve([]),
});

export const useAttachments = () => useContext(AttachmentsContext);

const AttachmentsState = ({ children }: { children: React.ReactNode }) => {
  const [attachmentsState, dispatch] = useReducer(
    AttachmentsReducer,
    initialAttachmentsState
  );

  const getAllAttachments = async (params: GetAttachmentParams) => {
    try {
      // if offset is 0, we are fetching the first page
      if (params.offset === 0) {
        dispatch({
          type: SET_LOADING,
          payload: true,
        });

        const data = await getAttachments(params);

        dispatch({
          type: GET_ATTACHMENTS,
          payload: data,
        });
      } else {
        const data = await getAttachments(params);

        dispatch({
          type: GET_ATTACHMENTS,
          payload: [...attachmentsState.attachments, ...data],
        });
      }
    } catch (err) {
      dispatch({
        type: SET_ERROR,
        payload: true,
      });
    }
  };

  // Download all the attachments in the current date range
  const downloadAttachments = async (
    params: GetAttachmentParams
  ): Promise<Attachment[]> => {
    try {
      let offset = 0;
      let allData: Attachment[] = []; // Create an array to store all attachments
      let data = await getAttachments({ ...params, offset });

      // Concatenate the attachments from each request
      while (data.length === 50) {
        allData = allData.concat(data); // Add the current data to allData array
        offset += 50;
        data = await getAttachments({ ...params, offset });
      }

      // Make sure to add the last batch of data that has less than 50 records
      allData = allData.concat(data);

      return allData as Array<Attachment>;
    } catch (err) {
      dispatch({
        type: SET_ERROR,
        payload: true,
      });

      return Promise.reject(err);
    }
  };

  return (
    <AttachmentsContext.Provider
      value={{
        attachmentsState,
        getAllAttachments,
        downloadAttachments,
      }}
    >
      {children}
    </AttachmentsContext.Provider>
  );
};

export default AttachmentsState;
