import {
  createContext,
  Dispatch,
  ReactNode,
  useContext,
  useEffect,
  useReducer,
} from 'react';
import { toast } from 'sonner';

import { prepareFilters } from '@/pages/data/utils/prepareFilters';
import * as TeamsAPI from '@/shared/api/teams';
import {
  CreateTeamParams,
  CreateWorkSchedulesParams,
  UpdateTeamParams,
} from '@/shared/api/teams/types';
import { SearchFilters } from '@/shared/types/contacts';
import { Team, TeamsAction, TeamsActionType, TeamsState } from '@/shared/types/team';

import TeamsReducer from './TeamsReducer';

export const ITEMS_COUNT = 10;

export const initialTeamsState: TeamsState = {
  allTeams: [],
  teams: [],
  team_members: [],
  totalCount: 0,
  error: null,
  loading: true,
  current: null,
  currentTeamMember: null,
  filterParams: {
    offset: 0,
    limit: ITEMS_COUNT,
    sort: [
      {
        label: 'Updated At',
        column: 'updated_at',
        order: 'desc',
        resource: 'team',
        id: null,
      },
    ],
    filter: [],
    searchFilter: [],
  },
};

/** Teams Context Refactoring */
export const TeamsContext = createContext<{
  teamsState: TeamsState;
  getTeams: (params?: SearchFilters) => Promise<void>;
  getTeam: (id: string) => Promise<void>;
  createTeam: (params: CreateTeamParams) => Promise<Team | void>;
  getTeamMembers: (
    team_id: string,
    params: { offset: number; limit: number }
  ) => Promise<void>;
  getTeamMember: (team_id: string, id: string) => Promise<void>;
  createTeamMember: (
    team_id: string,
    user_id: number,
    isToastShow?: boolean
  ) => Promise<void>;
  deleteTeamMember: (team_id: string, team_member_id: string) => Promise<void>;
  updateTeam: (team_id: string, params: UpdateTeamParams) => Promise<void>;
  deleteTeam: (id: string) => Promise<void>;
  setCurrent: (team: Team | null) => void;
  clearCurrent: () => void;
  clearCurrentTeamMember: () => void;
  getWorkSchedules: (id: string) => Promise<void>;
  createWorkSchedules: (
    team_id: string,
    params: CreateWorkSchedulesParams
  ) => Promise<void>;
  updateWorkSchedules: (
    team_id: string,
    work_schedule_id: string,
    params: CreateWorkSchedulesParams,
    isToastShow?: boolean
  ) => Promise<void>;
  deleteWorkSchedules: (team_id: string, work_schedule_id: string) => Promise<void>;
  createTeamMemberWorkSchedules: (
    team_id: string,
    team_member_id: string,
    params: CreateWorkSchedulesParams
  ) => Promise<void>;
  updateTeamMemberWorkSchedules: (
    team_id: string,
    team_member_id: string,
    work_schedule_id: string,
    params: CreateWorkSchedulesParams,
    isToastShow?: boolean
  ) => Promise<void>;
  deleteTeamMemberWorkSchedules: (
    team_id: string,
    team_member_id: string,
    work_schedule_id: string,
    isToastShow?: boolean
  ) => Promise<void>;
  updateFilterParams: (params: SearchFilters) => void;
}>({
  teamsState: initialTeamsState,
  getTeams: () => Promise.resolve(),
  getTeam: () => Promise.resolve(),
  createTeam: () => Promise.resolve(),
  getTeamMembers: () => Promise.resolve(),
  getTeamMember: () => Promise.resolve(),
  createTeamMember: () => Promise.resolve(),
  deleteTeamMember: () => Promise.resolve(),
  updateTeam: () => Promise.resolve(),
  deleteTeam: () => Promise.resolve(),
  setCurrent: () => Promise.resolve(),
  clearCurrent: () => Promise.resolve(),
  clearCurrentTeamMember: () => Promise.resolve(),
  getWorkSchedules: () => Promise.resolve(),
  createWorkSchedules: () => Promise.resolve(),
  updateWorkSchedules: () => Promise.resolve(),
  deleteWorkSchedules: () => Promise.resolve(),
  createTeamMemberWorkSchedules: () => Promise.resolve(),
  updateTeamMemberWorkSchedules: () => Promise.resolve(),
  deleteTeamMemberWorkSchedules: () => Promise.resolve(),
  updateFilterParams: () => Promise.resolve(),
});

export const useTeams = () => useContext(TeamsContext);

const TeamsProvider = ({ children }: { children: ReactNode }) => {
  const [teamsState, dispatch]: [TeamsState, Dispatch<TeamsAction>] = useReducer(
    TeamsReducer,
    initialTeamsState
  );

  useEffect(() => {
    getTeams(teamsState.filterParams);
  }, [teamsState.filterParams]);

  const getTeams = async (params?: SearchFilters) => {
    try {
      const data = await TeamsAPI.getTeams(params ? prepareFilters(params) : undefined);
      if (params) {
        dispatch({
          type: TeamsActionType.GET_TEAMS,
          payload: data,
        });
      } else {
        dispatch({
          type: TeamsActionType.GET_ALL_TEAMS,
          payload: data,
        });
      }
    } catch (err) {
      if (err?.response) {
        dispatch({
          type: TeamsActionType.SET_ERROR,
          payload: err.response.msg,
        });
      }
    }
  };

  const getTeam = async (id: string) => {
    try {
      const { data } = await TeamsAPI.getTeam(id);

      dispatch({
        type: TeamsActionType.GET_TEAM,
        payload: data,
      });
    } catch (err) {
      if (err?.response) {
        dispatch({
          type: TeamsActionType.SET_ERROR,
          payload: err.response.msg,
        });
      }
    }
  };

  const createTeam = async (params: CreateTeamParams) => {
    try {
      const { data } = await TeamsAPI.createTeam(params);

      dispatch({
        type: TeamsActionType.CREATE_TEAM,
        payload: data,
      });

      if (teamsState.teams.length) {
        getTeams({ ...teamsState.filterParams });
      }

      toast.success('Team created successfully!');
    } catch (err) {
      toast.error('Team creation failed!');
      if (err?.response) {
        dispatch({
          type: TeamsActionType.SET_ERROR,
          payload: err.response.msg,
        });
      }
    }
  };

  const updateTeam = async (team_id: string, params: UpdateTeamParams) => {
    try {
      const { data } = await TeamsAPI.updateTeam(team_id, params);

      if (data) {
        dispatch({
          type: TeamsActionType.UPDATE_TEAM,
          payload: data,
        });
        toast.success('Team updated successfully!');
      }
    } catch (err) {
      if (err?.response) {
        dispatch({
          type: TeamsActionType.SET_ERROR,
          payload: err.response.msg,
        });
      }
      toast.error('Team update failed!');
    }
  };

  const deleteTeam = async (id: string) => {
    try {
      const data = await TeamsAPI.deleteTeam(id);

      if (data) {
        dispatch({
          type: TeamsActionType.DELETE_TEAM,
          payload: id,
        });
        if (teamsState.teams.length) {
          getTeams({ ...teamsState.filterParams });
        }
        toast.success('Team deleted successfully!');
      }
    } catch (err) {
      if (err?.response) {
        dispatch({
          type: TeamsActionType.SET_ERROR,
          payload: err.response.msg,
        });
      }
      toast.error('Team deletion failed!');
    }
  };

  const getTeamMembers = async (
    team_id: string,
    params: {
      offset: number;
      limit: number;
    }
  ) => {
    try {
      const { data } = await TeamsAPI.getTeamMembers(team_id, params);

      dispatch({
        type: TeamsActionType.GET_TEAM_MEMBERS,
        payload: data,
      });
    } catch (err) {
      if (err?.response) {
        dispatch({
          type: TeamsActionType.SET_ERROR,
          payload: err.response.msg,
        });
      }
    }
  };

  const getTeamMember = async (team_id: string, id: string) => {
    try {
      const { data } = await TeamsAPI.getTeamMember(team_id, id);

      if (data) {
        dispatch({
          type: TeamsActionType.GET_TEAM_MEMBER,
          payload: data,
        });
      }
    } catch (err) {
      if (err?.response) {
        dispatch({
          type: TeamsActionType.SET_ERROR,
          payload: err.response.msg,
        });
      }
    }
  };

  const createTeamMember = async (
    team_id: string,
    user_id: number,
    isToastShow?: boolean
  ) => {
    try {
      const { data } = await TeamsAPI.createTeamMember(team_id, user_id);

      if (data) {
        dispatch({
          type: TeamsActionType.CREATE_TEAM_MEMBER,
          payload: data,
        });
        isToastShow && toast.success('Team member created successfully!');
      }
    } catch (err) {
      if (err?.response) {
        dispatch({
          type: TeamsActionType.SET_ERROR,
          payload: err.response.msg,
        });
      }

      isToastShow && toast.error('Team member creation failed!');
    }
  };

  const deleteTeamMember = async (team_id: string, team_member_id: string) => {
    try {
      const data = await TeamsAPI.deleteTeamMember(team_id, team_member_id);
      if (data) {
        dispatch({
          type: TeamsActionType.DELETE_TEAM_MEMBER,
          payload: team_member_id,
        });
        toast.success('Team member deleted successfully!');
      }
    } catch (err) {
      if (err?.response) {
        dispatch({
          type: TeamsActionType.SET_ERROR,
          payload: err.response.msg,
        });
      }
      toast.error('Team member deletion failed!');
    }
  };

  const setCurrent = (team: Team | null) => {
    dispatch({ type: TeamsActionType.SET_CURRENT, payload: team });
  };

  const clearCurrent = () => {
    dispatch({ type: TeamsActionType.CLEAR_CURRENT });
  };

  const clearCurrentTeamMember = () => {
    dispatch({ type: TeamsActionType.CLEAR_CURRENT_TEAM_MEMBER });
  };

  const getWorkSchedules = async (team_id: string) => {
    try {
      const { data } = await TeamsAPI.getWorkSchedules(team_id);

      dispatch({
        type: TeamsActionType.GET_WORK_SCHEDULES,
        payload: data,
      });
    } catch (err) {
      if (err?.response) {
        dispatch({
          type: TeamsActionType.SET_ERROR,
          payload: err.response.msg,
        });
      }
    }
  };

  const createWorkSchedules = async (
    team_id: string,
    params: CreateWorkSchedulesParams
  ) => {
    try {
      const { data } = await TeamsAPI.createWorkSchedules(team_id, params);

      if (data) {
        dispatch({
          type: TeamsActionType.CREATE_WORK_SCHEDULES,
          payload: data,
        });
        toast.success('Work schedule created successfully!');
      }
    } catch (err) {
      if (err?.response) {
        dispatch({
          type: TeamsActionType.SET_ERROR,
          payload: err.response.msg,
        });
      }
      toast.error('Work schedule creation failed!');
    }
  };

  const updateWorkSchedules = async (
    team_id: string,
    work_schedule_id: string,
    params: CreateWorkSchedulesParams,
    isToastShow?: boolean
  ) => {
    try {
      const { data } = await TeamsAPI.updateWorkSchedules(
        team_id,
        work_schedule_id,
        params
      );

      if (data) {
        dispatch({
          type: TeamsActionType.UPDATE_WORK_SCHEDULES,
          payload: data,
        });
        isToastShow && toast.success('Work schedule updated successfully!');
      }
    } catch (err) {
      if (err?.response) {
        dispatch({
          type: TeamsActionType.SET_ERROR,
          payload: err.response.msg,
        });
      }
      isToastShow && toast.error('Work schedule update failed!');
    }
  };

  const deleteWorkSchedules = async (team_id: string, work_schedule_id: string) => {
    try {
      const data = await TeamsAPI.deleteWorkSchedules(team_id, work_schedule_id);

      if (data) {
        dispatch({
          type: TeamsActionType.DELETE_WORK_SCHEDULES,
          payload: work_schedule_id,
        });
        toast.success('Work schedule deleted successfully!');
      }
    } catch (err) {
      if (err?.response) {
        dispatch({
          type: TeamsActionType.SET_ERROR,
          payload: err.response.msg,
        });
      }
      toast.error('Work schedule deletion failed!');
    }
  };

  const createTeamMemberWorkSchedules = async (
    team_id: string,
    team_member_id: string,
    params: CreateWorkSchedulesParams
  ) => {
    try {
      const { data } = await TeamsAPI.createTeamMemberWorkSchedules(
        team_id,
        team_member_id,
        params
      );

      if (data) {
        dispatch({
          type: TeamsActionType.CREATE_TEAM_MEMBER_WORK_SCHEDULES,
          payload: data,
        });
        toast.success('Team member work schedule created successfully!');
      }
    } catch (err) {
      if (err?.response) {
        dispatch({
          type: TeamsActionType.SET_ERROR,
          payload: err.response.msg,
        });
      }
      toast.error('Team member work schedule creation failed!');
    }
  };

  const updateTeamMemberWorkSchedules = async (
    team_id: string,
    team_member_id: string,
    work_schedule_id: string,
    params: CreateWorkSchedulesParams,
    isToastShow?: boolean
  ) => {
    try {
      const { data } = await TeamsAPI.updateTeamMemberWorkSchedules(
        team_id,
        team_member_id,
        work_schedule_id,
        params
      );

      if (data) {
        dispatch({
          type: TeamsActionType.UPDATE_TEAM_MEMBER_WORK_SCHEDULES,
          payload: data,
        });
        isToastShow && toast.success('Team member work schedule updated successfully');
      }
    } catch (err) {
      if (err?.response) {
        dispatch({
          type: TeamsActionType.SET_ERROR,
          payload: err.response.msg,
        });
      }
      isToastShow && toast.error('Team member work schedule update failed!');
    }
  };

  const deleteTeamMemberWorkSchedules = async (
    team_id: string,
    team_member_id: string,
    work_schedule_id: string,
    isToastShow?: boolean
  ) => {
    try {
      const data = await TeamsAPI.deleteTeamMemberWorkSchedules(
        team_id,
        team_member_id,
        work_schedule_id
      );

      if (data) {
        dispatch({
          type: TeamsActionType.DELETE_TEAM_MEMBER_WORK_SCHEDULES,
          payload: work_schedule_id,
        });
        isToastShow && toast.success('Team member work schedule deleted successfully');
      }
    } catch (err) {
      if (err?.response) {
        dispatch({
          type: TeamsActionType.SET_ERROR,
          payload: err.response.msg,
        });
      }
      isToastShow && toast.error('Team member work schedule deletion failed!');
    }
  };

  const updateFilterParams = async (params: SearchFilters) => {
    try {
      dispatch({
        type: TeamsActionType.UPDATE_FILTER_PARAMS,
        payload: params,
      });
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <TeamsContext.Provider
      value={{
        getTeams,
        getTeam,
        teamsState,
        createTeam,
        updateTeam,
        deleteTeam,
        getTeamMembers,
        getTeamMember,
        createTeamMember,
        deleteTeamMember,
        setCurrent,
        clearCurrent,
        clearCurrentTeamMember,
        getWorkSchedules,
        createWorkSchedules,
        updateWorkSchedules,
        deleteWorkSchedules,
        createTeamMemberWorkSchedules,
        updateTeamMemberWorkSchedules,
        deleteTeamMemberWorkSchedules,
        updateFilterParams,
      }}
    >
      {children}
    </TeamsContext.Provider>
  );
};

export default TeamsProvider;
