import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { SettingsLayout } from '@/shared/layouts';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
  Button,
  Divider,
  Flex,
  Text,
  VStack,
} from '@/shared/ui';

import { TenDlcBrandsList } from './brands/TenDlcBrandsList';
import { TenDlcCampaignsList } from './campaigns/TenDlcCampaignsList';
import { useTenDlc } from './context/TenDlcContext';

export const TrustCenter = () => {
  const history = useHistory();
  const tenDlcContext = useTenDlc();
  const { getAllBrands, getAllCampaigns, tenDlcState } = tenDlcContext;

  useEffect(() => {
    getAllBrands();
    getAllCampaigns();
  }, []);

  return (
    <SettingsLayout
      background="#fafafa"
      padding="0"
      width="100%"
      breadcrumbs={[
        { title: 'Settings', path: '/settings/general' },
        { title: 'Trust Center', path: `/settings/trust` },
      ]}
    >
      <Flex css={{ p: 30 }} direction="column">
        <VStack>
          <Accordion css={{ background: 'white' }} type="single" collapsible>
            <AccordionItem value="brand-verification" variant="neumorphic">
              <Flex justify="between" align="center">
                <VStack gap={2}>
                  <Text css={{ fontWeight: 600 }}>10LDC Brand Verifications</Text>
                  <Text>
                    Create, verify and manage your brands in order to create a 10DLC
                    Campaign and verify your numbers.
                  </Text>
                </VStack>
                <AccordionTrigger />
              </Flex>
              <AccordionContent variant="neumorphic">
                <Divider css={{ mt: -20, mb: 20 }} />
                <Button
                  variant="gray"
                  size={4}
                  css={{ width: '250px' }}
                  onClick={() => history.push('/settings/trust/10dlc/brands/create')}
                >
                  Create 10DLC Brand
                </Button>
                <TenDlcBrandsList />
              </AccordionContent>
            </AccordionItem>
          </Accordion>
          <Accordion css={{ background: 'white' }} type="single" collapsible>
            <AccordionItem value="brand-verification" variant="neumorphic">
              <Flex justify="between" align="center">
                <VStack gap={2}>
                  <Text css={{ fontWeight: 600 }}>10DLC Campaigns</Text>
                  <Text>Create and manage your 10DLC Campaigns.</Text>
                </VStack>
                <AccordionTrigger />
              </Flex>
              <AccordionContent variant="neumorphic">
                <Divider css={{ mt: -20, mb: 20 }} />
                <Button
                  variant="gray"
                  size={4}
                  css={{ width: '250px' }}
                  disabled={tenDlcState.brands.length < 1}
                  onClick={() => history.push('/settings/trust/10dlc/campaigns/create')}
                >
                  Create 10DLC Campaign
                </Button>
                <TenDlcCampaignsList />
              </AccordionContent>
            </AccordionItem>
          </Accordion>
        </VStack>
      </Flex>
    </SettingsLayout>
  );
};
