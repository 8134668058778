import { createContext, useContext, useReducer } from 'react';

import * as API from '@/shared/api/integrations';
import {
  AddToSequenceActionParams,
  ConfigurationActionTypes,
  CreateLeadActionParams,
  IntegrationConfiguration,
  JobBoardAutomationKeys,
  SendMessageActionParams,
} from '@/shared/types/integrations';

import { ConfigurationReducer } from './IntegrationConfigurationReducer';

export type ConfigurationsState = {
  configurations: Array<IntegrationConfiguration> | [];
  current: IntegrationConfiguration | null;
  loading: boolean;
};

export const initialConfigurationsState: ConfigurationsState = {
  configurations: [],
  current: null,
  loading: true,
};

export const IntegrationConfigurationContext = createContext<{
  configurationsState: ConfigurationsState;
  getConfigurations: (applicationId: string) => Promise<IntegrationConfiguration[]>;
  getConfiguration: (
    applicationId: string,
    id: string
  ) => Promise<IntegrationConfiguration | null>;
  createJobBoardConfiguration: (
    apiKey: string,
    jobBoardAutomationType: JobBoardAutomationKeys,
    applicationId: string,
    organizationId: string,
    configurationParams: SendMessageActionParams &
      CreateLeadActionParams &
      AddToSequenceActionParams & {
        action_type: string;
        api_key: string;
        forwarding_email: string;
      }
  ) => Promise<IntegrationConfiguration>;
  setCurrentConfiguration: (configuration: IntegrationConfiguration | null) => void;
  deleteConfiguration: (applicationId: string, id: string) => void;
}>({
  configurationsState: initialConfigurationsState,
  getConfigurations: () => Promise.resolve([]),
  getConfiguration: () => Promise.resolve(null),
  createJobBoardConfiguration: () => Promise.resolve({} as IntegrationConfiguration),
  setCurrentConfiguration: () => Promise.resolve([]),
  deleteConfiguration: () => Promise.resolve([]),
});

export const useIntegrationConfigurations = () =>
  useContext(IntegrationConfigurationContext);

const IntegrationConfigurationsState = ({ children }: { children: React.ReactNode }) => {
  const [configurationsState, dispatch] = useReducer(
    ConfigurationReducer,
    initialConfigurationsState
  );

  /**
   * Makes an external request to fetch the configurations
   * tied to the applicationId provided. It will then update the
   * configurations state.
   */
  const getConfigurations = async (applicationId: string) => {
    try {
      const data = await API.getConfigurations(applicationId);
      dispatch({
        type: ConfigurationActionTypes.GET_CONFIGURATIONS,
        payload: data,
      });
      return data;
    } catch (err) {
      console.error(err);
      return Promise.reject(err);
    }
  };

  const setCurrentConfiguration = (configuration: IntegrationConfiguration | null) => {
    dispatch({
      type: ConfigurationActionTypes.SET_CURRENT_CONFIGURATION,
      payload: configuration,
    });
  };

  const getConfiguration = async (applicationId: string, id: string) => {
    try {
      const configuration = await API.getConfiguration(applicationId, id);
      dispatch({
        type: ConfigurationActionTypes.GET_CONFIGURATION,
        payload: configuration,
      });
      return configuration;
    } catch (err) {
      console.error(err);
      return Promise.reject(err);
    }
  };

  /*
  Right now this code is coupled to the UI. Since the ConfigurationEditor could have multiple
  types of Whippy Actions initially we need to provide the Formik all the possible fields and the 
  initial values. Therefore the data that is actually passed to the configurationParams 
  is the superset of the WhippyActionParams as well as the action_type and api_key since
  those are all the values we need to get from the form. 
  */
  const createJobBoardConfiguration = async (
    apiKey: string,
    jobBoard: string,
    applicationId: string,
    organizationId: string,
    configurationParams: AddToSequenceActionParams &
      SendMessageActionParams &
      CreateLeadActionParams & {
        action_type: string;
        api_key: string;
        forwarding_email: string;
      }
  ): Promise<IntegrationConfiguration> => {
    try {
      // we want to remove the job_board_automation prefix
      // from this integration key
      // splits it into something like ['job_board_automation_', 'indeed']
      const jobBoardSplit = jobBoard.split('job_board_automation_');
      const configuration = await API.createJobBoardConfiguration(
        apiKey,
        jobBoardSplit[1] as JobBoardAutomationKeys,
        applicationId,
        organizationId,
        configurationParams
      );
      dispatch({
        type: ConfigurationActionTypes.CREATE_CONFIGURATION,
        payload: configuration,
      });
      return configuration;
    } catch (err) {
      console.error(err);
      return Promise.reject(err);
    }
  };

  const deleteConfiguration = async (
    applicationId: string,
    id: string
  ): Promise<void> => {
    try {
      await API.deleteConfiguration(applicationId, id);
      dispatch({
        type: ConfigurationActionTypes.DELETE_CONFIGURATION,
        payload: id,
      });
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  };
  return (
    <IntegrationConfigurationContext.Provider
      value={{
        configurationsState,
        getConfigurations,
        getConfiguration,
        createJobBoardConfiguration,
        deleteConfiguration,
        setCurrentConfiguration,
      }}
    >
      {children}
    </IntegrationConfigurationContext.Provider>
  );
};

export default IntegrationConfigurationsState;
