import React, { useState } from 'react';
import {
  HiAnnotation,
  HiChevronDown,
  HiChevronRight,
  HiStar,
  HiThumbDown,
  HiThumbUp,
} from 'react-icons/hi';
import { Link, useParams } from 'react-router-dom';
import { useMedia } from 'react-use';

import { useChannels } from '@/pages/settings/organization/channels/context/ChannelContext';
import { useSidenavigationSettings } from '@/shared/components/navigation/context/SideNavigationContext';
import {
  SideNavigation,
  sideNavigationGroup,
} from '@/shared/components/navigation/SideNavigation';
import {
  SidebarNavigationContainer,
  SidebarNavigationWrapper,
} from '@/shared/components/navigation/SideNavigationContainer';
import {
  SideNavigationHeading,
  SideNavigationItem,
} from '@/shared/components/navigation/SideNavigationItem';
import { LayoutContent, MainLayout } from '@/shared/layouts';
import { Channel } from '@/shared/types/channels';
import {
  Box,
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
  Flex,
  IconButton,
  Text,
  VStack,
} from '@/shared/ui';

export const ReviewNavigation = () => {
  const { navigationSettings, toggleExpandedState } = useSidenavigationSettings();

  const { id } = useParams<{ id: string }>();

  const reviewSideNavigationTabs: Array<sideNavigationGroup> = [
    {
      sideNavigationGroup: [
        {
          title: 'All Reviews',
          image: <HiStar size={18} />,
          isActive: id === 'all',
          link: '/reviews/all',
        },
        {
          title: 'Needs Response',
          image: <HiAnnotation size={18} />,
          isActive: id === 'unresponded',
          link: '/reviews/unresponded',
        },
        {
          title: 'Highest',
          image: <HiThumbUp size={18} />,
          isActive: id === 'highest',
          link: '/reviews/highest',
        },
        {
          title: 'Lowest',
          image: <HiThumbDown size={18} />,
          isActive: id === 'lowest',
          link: '/reviews/lowest',
        },
      ],
    },
  ];

  const { channelsState } = useChannels();
  const { channels } = channelsState;

  const [openLocations, setLocationsOpen] = useState(true);

  return (
    <SidebarNavigationContainer>
      <SidebarNavigationWrapper>
        <SideNavigation
          expanded={navigationSettings.expanded}
          header="Reviews"
          sideNavigationTabs={reviewSideNavigationTabs}
          toggleExpandedState={toggleExpandedState}
        >
          <Box css={{ p: 8, pt: 0, marginTop: '10px' }}>
            <VStack gap={2}>
              {channels.filter((channel: Channel) => channel.google_place_id !== null)
                .length > 1 && (
                <Collapsible open={openLocations} onOpenChange={setLocationsOpen}>
                  <CollapsibleTrigger asChild>
                    <Flex align="center">
                      <IconButton>
                        {openLocations ? (
                          <HiChevronDown size={19} />
                        ) : (
                          <HiChevronRight size={19} />
                        )}
                      </IconButton>
                      <SideNavigationHeading
                        align="center"
                        size={1}
                        css={{ cursor: 'pointer', mb: 2, mr: 5 }}
                      >
                        Channels
                      </SideNavigationHeading>
                    </Flex>
                  </CollapsibleTrigger>
                  <CollapsibleContent>
                    <VStack gap={1} css={{ overflow: 'auto' }}>
                      {channels
                        .filter((channel: Channel) => channel.google_place_id !== null) // Filter out locations with null place_id
                        .sort((a: Channel, b: Channel) =>
                          (a?.name || '').localeCompare(b?.name || '')
                        )
                        .map((channel: Channel) => {
                          return (
                            <LocationItem
                              key={`sidebar-${channel.id}`}
                              id={channel.id}
                              name={channel.name}
                              filter={id}
                            />
                          );
                        })}
                    </VStack>
                  </CollapsibleContent>
                </Collapsible>
              )}
            </VStack>
          </Box>
        </SideNavigation>
      </SidebarNavigationWrapper>
    </SidebarNavigationContainer>
  );
};

export const ReviewLayoutContainer = ({ children }: { children: React.ReactNode }) => {
  const isDesktop = useMedia('(min-width: 912px)');

  return (
    <MainLayout>
      {isDesktop && <ReviewNavigation />}
      <LayoutContent>{children}</LayoutContent>
    </MainLayout>
  );
};

type LocationItemProps = {
  id: string;
  name?: string;
  filter: string;
};

const LocationItem = (props: LocationItemProps) => {
  const { id, name, filter } = props;
  return (
    <Link to={`/reviews/${id}`}>
      <SideNavigationItem active={filter === id}>
        <Flex direction="column" css={{ overflow: 'hidden' }}>
          <Text variant="sidenavigation">{name}</Text>
        </Flex>
      </SideNavigationItem>
    </Link>
  );
};
