import { styled } from '@stitches/react';

import { useSearch } from './SearchContext';

export const SearchTabs = () => {
  const { selectedTab, setSelectedTab, tabs } = useSearch();
  return (
    <TabContainer className="grid grid-flow-col auto-cols-fr">
      {tabs.map((tab) => (
        <Tab
          key={tab}
          onClick={() => {
            setSelectedTab(tab);
          }}
          selected={tab === selectedTab}
          className={`text-center`}
        >
          {tab}
        </Tab>
      ))}
    </TabContainer>
  );
};

const TabContainer = styled('div', {
  gap: '10px',
  margin: '10px',
  userSelect: 'none',
});

const Tab = styled('span', {
  alignItems: 'center',
  padding: '3px 8px',
  fontSize: 14,
  cursor: 'pointer',
  borderRadius: 4,
  variants: {
    selected: {
      true: {
        background: '$gray3',
      },
    },
  },
});
