/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable react-hooks/exhaustive-deps */
import { captureException } from '@sentry/react';
import { debounce } from 'lodash';
import { useCallback, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import { contactSearch } from '@/shared/api/conversations';
import {
  SearchV2Type,
  WhippyQueryLanguage,
  WhippyQueryLanguageFilterSchema,
} from '@/shared/types/conversations';

import { TabOptions, useSearch } from '../SearchContext';
import { ContactSkeleton } from '../skeletons/contacts';
import { extractNumber, GroupName, KeepResultsFocused } from '../utils';
import { EmptyResults } from './emptyResults';
import { ErrorResults } from './errorResults';

export function RenderContactSearch({
  limit = 50,
  showEmptyResults = false,
  showResults = true,
}: {
  limit?: number;
  showEmptyResults?: boolean;
  showResults?: boolean;
}) {
  // State and context hooks
  const {
    searchInput,
    setSearchInput,
    loadings,
    setLoadings,
    cancelPreviousRequest,
    setControllers,
    searchResults,
    setSearchResults,
    errors,
    setErrors,
    offsets,
    setOffsets,
    setPaginatings,
  } = useSearch();
  const history = useHistory();

  // Trigger contact search when searchInput changes
  useEffect(() => {
    setSearchResults((prev) => ({ ...prev, Contacts: [] }));

    if (searchInput) {
      setLoadings((prev) => ({ ...prev, Contacts: true }));
      debounceContactSearch({ inputValue: searchInput });
    }
  }, [searchInput]);

  // Debounce contact search to avoid excessive API calls
  const debounceContactSearch = useCallback(
    debounce((search: SearchV2Type) => {
      searchContacts(search);
    }, 350),
    []
  );

  // Perform contact search based on input value
  const searchContacts = async ({
    inputValue,
    offset = 0,
    shouldAppendResults = false,
  }: {
    inputValue: string;
    offset?: number;
    shouldAppendResults?: boolean;
  }) => {
    try {
      cancelPreviousRequest('Contacts');
      const controller = new AbortController();
      setControllers((prev) => ({ ...prev, Contacts: controller }));

      const formattedValue = inputValue.trim();
      // Extracts and standardizes phone number from input value
      const formattedPhone = extractNumber(formattedValue);
      const hasPhoneNumber = formattedPhone != '+1';

      // Initialize the base search filter for name and email
      let searchFilter: WhippyQueryLanguageFilterSchema = {
        resource: 'contact',
        column: 'name',
        comparison: 'ilike',
        value: `%${formattedValue.replace(' ', '%')}%`,
        or: [
          {
            column: 'email',
            comparison: 'ilike',
            value: `${formattedValue}%`,
            resource: 'contact',
          },
        ],
      };

      // Conditionally add phone search conditions if a phone number is present
      if (hasPhoneNumber) {
        searchFilter = {
          ...searchFilter,
          or: [
            {
              column: 'phone',
              comparison: '==',
              value: formattedPhone,
              resource: 'contact',
              or: [
                {
                  column: 'phone',
                  comparison: 'ilike',
                  value: `%${formattedValue}%`,
                  resource: 'contact',
                  or: [searchFilter],
                },
              ],
            },
          ],
        };
      }

      const searchPayload: WhippyQueryLanguage = {
        filter: [searchFilter],
        limit,
        offset,
      };

      // Perform contact search using conversationSearch API
      const searchResults = await contactSearch(searchPayload, controller);
      setLoadings((prev) => ({ ...prev, Contacts: false }));

      const results = searchResults.data.map((conversation) => {
        const item = {
          id: conversation.id,
          name: inputValue,
          perform: () => handleClick(conversation.id),
          shortcut: [],
          section: 'Contacts Search',
          subtitle: '',
          keywords: `search contactSearch ${inputValue}`,
          conversation: conversation,
        };
        return item;
      });

      // If end reached, prevent further pagination
      if (results.length === 0) setOffsets((prev) => ({ ...prev, Contacts: -1 }));

      setSearchResults((prev) => ({
        ...prev,
        Contacts: shouldAppendResults ? [...prev.Contacts, ...results] : results,
      }));
    } catch (error) {
      console.error(error);

      if (error.name === 'AbortError' || error.name === 'CanceledError') {
        return console.log('AbortError: The operation was aborted');
      }

      const errorMessage =
        error?.response?.data?.errors?.[0]?.description ?? 'Failed to search contacts';
      setLoadings((prev) => ({ ...prev, Contacts: false }));
      setSearchResults((prev) => ({ ...prev, Contacts: [] }));
      setErrors((prev) => ({
        ...prev,
        Contacts: { hasError: true, error: errorMessage },
      }));
      captureException(error, {
        tags: {
          area: 'globalSearch',
          feature: 'contacts',
        },
      });
    }
  };

  const hasNoResults = useMemo(
    () => searchResults?.Contacts?.length === 0 && searchInput !== '',
    [searchResults, searchInput]
  );

  const handleMoreResults = () => {
    const offset = offsets[TabOptions.Contacts];
    const isLoading = loadings[TabOptions.Contacts];
    if (offset > 0 && !hasNoResults && !isLoading) {
      searchContacts({
        inputValue: searchInput,
        offset,
        shouldAppendResults: true,
      }).finally(() => {
        setPaginatings((prev) => ({ ...prev, Contacts: false }));
      });
    }
  };

  useEffect(() => {
    handleMoreResults();
  }, [offsets[TabOptions.Contacts]]);

  // Handle click on a contact search result
  const handleClick = (conversationId: string) => {
    history.push(`/inbox/all/open/${conversationId}`);

    setSearchResults((prev) => ({ ...prev, Contacts: [] }));
    setSearchInput('');
  };

  if (loadings.Contacts) {
    return (
      <>
        <GroupName>Searching contacts...</GroupName>
        <ContactSkeleton />
      </>
    );
  }

  // Display error message if search request fails
  if (showResults && errors?.Contacts?.hasError) {
    return <ErrorResults errorMessage={errors?.Contacts?.error} />;
  }

  if (hasNoResults && !loadings.Contacts && showEmptyResults) {
    return <EmptyResults />;
  }

  // Display search results
  if (!hasNoResults && showResults) {
    return <KeepResultsFocused results={searchResults?.Contacts} label="Contacts" />;
  }
}
