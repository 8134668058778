import React from 'react';

import { Flex } from '@/shared/ui';

type ImageViewerProps = {
  src: string;
};

export const ImageViewer = (props: ImageViewerProps) => {
  const { src } = props;
  return (
    <Flex align="center" justify="center" css={{ height: '100%', maxWidth: '100%' }}>
      <img
        src={src}
        alt="Media preview"
        tabIndex={-1}
        style={{
          position: 'absolute',
          outline: 'none',
          maxHeight: 'calc(100vh - 60px)',
        }}
      />
    </Flex>
  );
};
