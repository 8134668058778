import React, { RefObject, useState } from 'react';
import { useMedia } from 'react-use';

import ImageCropper, {
  getCroppedImg,
} from '@/pages/settings/organization/webchat/ImageCropper';

import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogOverlay,
  AlertDialogPortal,
  AlertDialogTitle,
  Box,
  Button,
  Dialog,
  DialogClose,
  DialogContent,
  DialogOverlay,
  DialogPortal,
  DialogTitle,
  Flex,
  Image,
  Label,
  VStack,
} from '../ui';
import { deleteAttachment, directUpload, renderDeleteButton } from './attachments';
import { FileUpload } from './editor/v2/constants';
import { Spinner } from './Icons';

const UploadButton = (props: any) => {
  return (
    <Button as={Label} htmlFor="input" data-intercom-target="upload-image-button">
      Upload Picture
      <input
        value=""
        id="input"
        type="file"
        accept=".jpg, .jpeg, .png"
        hidden
        {...props}
      />
    </Button>
  );
};

export type AttachmentType = {
  url: string;
  presigned_url?: string;
};

/**
 * Props for the AttachmentUploader component
 */
type Props = {
  /**
   * Function that handles the file upload
   */
  handleUpdate: () => void;
  /**
   * Function that handles the file deletion
   */
  handleDelete: () => void;
  /**
   * Flag that shows/hides the alert dialog
   */
  isAlertOpen: boolean;
  /**
   * Function that sets the alert dialog visibility
   */
  setIsAlertOpen: (isOpen: boolean) => void;
  /**
   * Function that handles the closing of the alert dialog
   */
  onAlertClose: () => void;
  /**
   * Ref for the cancel button
   */
  cancelRef: RefObject<any>;
  /**
   * The current attachment
   */
  attachment: AttachmentType | null | undefined;
  /**
   * The current attachment
   */
  currentAttachment: AttachmentType;
  /**
   * Function that sets the current attachment
   */
  setCurrent: (attachment: Required<AttachmentType>) => void;
  /**
   * Function that handles the closing of the modal dialog
   */
  onOpen: () => void;
  /**
   * Function that handles the closing of the modal dialog
   */
  onClose: () => void;
  /**
   * Flag that shows/hides the modal dialog
   */
  isOpen: boolean;
  /**
   * Flag that triggers a re-render
   */
  timeoutFlag: boolean;
  /**
   * Function that sets the cropped image
   */
  setCropped: (croppedBlob: any) => void;
};

export const CreateCropImage = (props: Props): JSX.Element => {
  const {
    handleUpdate,
    handleDelete,
    isAlertOpen,
    setIsAlertOpen,
    onAlertClose,
    cancelRef,
    attachment,
    currentAttachment,
    setCurrent,
    onOpen,
    onClose,
    isOpen,
    timeoutFlag,
    setCropped,
  } = props;
  const isDesktop = useMedia('(min-width: 912px)');
  // fallback image
  const placeholderImg = 'https://dummyimage.com/300/ddd.jpg';
  const [loading, setLoading] = useState(false);

  const sleep = (ms: number) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  // handles the upload of the original attached image to s3
  // sets the state to loading and opens the modal used for resizing
  const handleUpload = async (e: {
    target: { files: { [s: string]: File } | ArrayLike<File> };
  }) => {
    const files = Object.values(e.target.files) as File[];

    onOpen();
    setLoading(true);
    // directUpload(files, handleSuccessUpload, () => console.log('error'));
    try {
      const uploads = await directUpload(files);
      const data: FileUpload = uploads[0];
      const attachment = {
        url: data.public_url || '',
        presigned_url: data.presigned_url || '',
      };

      await sleep(4000);

      setCurrent(attachment);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  // deleting the newly added attachment from s3
  // if the widget attachment was not updated with it
  const handleClose = () => {
    if (currentAttachment.url !== attachment?.url) {
      deleteAttachment(currentAttachment.url);
    }
    onClose();
  };

  const onCropComplete = async (
    croppedArea: any,
    croppedAreaPixels: { x: number; y: number; width: number; height: number }
  ) => {
    const croppedBlob = await getCroppedImg(currentAttachment.url, croppedAreaPixels);

    setCropped(croppedBlob);
  };

  return (
    <Box>
      <VStack gap={6}>
        <Flex>
          <Box
            css={{
              border: '2px solid rgb(245,245,245,1)',
              borderRadius: '100%',
              position: 'relative',
            }}
          >
            <Image
              css={{ width: 150, height: 150, borderRadius: 1000 }}
              src={attachment?.url || placeholderImg}
            />
            {renderDeleteButton(() => {
              setIsAlertOpen(true);
            }, attachment?.url || '')}
          </Box>
        </Flex>

        <Flex>
          <UploadButton onChange={handleUpload} />
        </Flex>
      </VStack>

      {/* Image Resizing Modal Dialog */}
      <Dialog open={isOpen}>
        <DialogPortal>
          <DialogOverlay />
          <DialogContent css={{ maxWidth: isDesktop ? '500px' : '200px' }}>
            <DialogTitle>Resize Image</DialogTitle>
            <Flex
              justify="center"
              align="center"
              css={{
                position: 'relative',
                height: '100%',
                minHeight: '400px',
                maxHeight: '600px',
                minWidth: isDesktop ? '400px' : '300px',
              }}
            >
              {loading ? (
                <Flex align="center" justify="center">
                  <Spinner size={2} />
                </Flex>
              ) : (
                currentAttachment && (
                  <ImageCropper
                    imageUrl={currentAttachment.url}
                    onCropComplete={onCropComplete}
                    timeoutFlag={timeoutFlag}
                  />
                )
              )}
            </Flex>
            <Flex justify="end" css={{ mt: 45 }}>
              <DialogClose asChild>
                <Button
                  aria-label="Close"
                  variant="gray"
                  onClick={handleClose}
                  css={{ mr: 5 }}
                >
                  Cancel
                </Button>
              </DialogClose>
              <DialogClose asChild>
                <Button aria-label="Close" onClick={handleUpdate}>
                  Save
                </Button>
              </DialogClose>
            </Flex>
          </DialogContent>
        </DialogPortal>
      </Dialog>

      {/* Confirm Attachment Deletion Dialog */}
      <AlertDialog open={isAlertOpen} leastDestructiveRef={cancelRef}>
        <AlertDialogPortal>
          <AlertDialogOverlay />
          <AlertDialogContent onEscapeKeyDown={() => setIsAlertOpen(false)}>
            <AlertDialogTitle>Delete Image</AlertDialogTitle>
            <AlertDialogDescription>
              {"Are you sure? You can't undo this action afterwards."}
            </AlertDialogDescription>
            <Flex justify="end">
              <AlertDialogCancel asChild>
                <Button variant="gray" css={{ mr: 10 }} onClick={onAlertClose}>
                  Cancel
                </Button>
              </AlertDialogCancel>
              <AlertDialogAction asChild>
                <Button variant="red" onClick={handleDelete}>
                  Delete
                </Button>
              </AlertDialogAction>
            </Flex>
          </AlertDialogContent>
        </AlertDialogPortal>
      </AlertDialog>
    </Box>
  );
};
