import React, { MutableRefObject, useEffect, useMemo, useRef, useState } from 'react';

import { useSequences } from '@/pages/sequences/context/SequenceContext';
import { useTags } from '@/pages/settings/organization/tags/context/TagsContext';
import { useUsers } from '@/pages/settings/organization/users/context/UserContext';
import { useResizeObserver } from '@/shared/hooks/useResizeObserver';
import {
  Action,
  ActionTypes,
  AddTagsAction,
  NotifyUsersAction,
} from '@/shared/types/triggers/actions';
import { Badge, Box, HStack } from '@/shared/ui';
import { styled } from '@/stitches.config';

const ActionItems = ({ items = [] }: { items?: string[] }) => {
  const itemsRow = useRef<HTMLDivElement>(null);
  const itemsRef = useRef<HTMLSpanElement[]>([]);
  const [itemsList, setItemsList] = useState(items);
  const [maxWidth, setMaxWidth] = useState(500);

  useResizeObserver(
    (data: ResizeObserverEntry[]) => setMaxWidth(data[0].contentRect.width),
    itemsRow as MutableRefObject<HTMLDivElement>
  );

  useEffect(() => {
    const containerWidth = maxWidth - 40;
    if (itemsRef?.current?.length === items?.length) {
      const kList = items?.reduce((list: string[], item: string, index: number) => {
        const newWidth = list?.reduce((acc, el, i) => {
          return acc + (itemsRef?.current?.[i] as HTMLSpanElement)?.offsetWidth + 5;
        }, 0);
        if (
          itemsRef?.current?.[index] &&
          (itemsRef?.current?.[index]?.offsetWidth + newWidth < containerWidth ||
            items.length === 1)
        ) {
          list.push(item);
        }
        return list;
      }, []);
      setItemsList(kList);
    }
  }, [items, itemsRef?.current, maxWidth]);

  const listDiff = useMemo(() => {
    return items?.length - (itemsList?.length || 0);
  }, [itemsList, items]);

  if (items && items.length > 0) {
    return (
      <ItemsRowContainer align="center" ref={itemsRow}>
        <HiddenRow align="center">
          {items?.map((item, i) => (
            <Badge
              key={i}
              size="2"
              ref={(el: HTMLSpanElement) => (itemsRef.current[i] = el)}
            >
              {item}
            </Badge>
          ))}
        </HiddenRow>
        <HStack align="center">
          {itemsList?.map((item, i) => (
            <Badge
              key={i}
              size="2"
              css={{
                maxWidth,
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                justifyContent: 'flex-start',
                display: itemsList?.length === 1 ? 'inline-block' : 'inline-flex',
                py: itemsList?.length === 1 ? '$1' : 0,
              }}
            >
              {item}
            </Badge>
          ))}
        </HStack>
        {listDiff > 0 && <Badge size="2">{`+${listDiff}`}</Badge>}
      </ItemsRowContainer>
    );
  }
  return null;
};

export const ActionDescription = (action: Action) => {
  const {
    userState: { users },
  } = useUsers();
  const {
    tagsState: { allTags },
  } = useTags();
  const { getSequenceTitle, findSequenceStep } = useSequences();

  const tagsList = () => {
    return (action as AddTagsAction)?.params?.tag_ids?.map((id: string) => {
      const tag = allTags.find((t) => t.id === id);
      return tag?.name || '';
    });
  };

  const usersList = () => {
    return (action as NotifyUsersAction)?.params?.user_ids?.map((id: string) => {
      const user = users.find((u) => u.id === Number(id));
      return user?.name || '';
    });
  };

  switch (action.type) {
    case ActionTypes.SEND_MESSAGE:
      return (
        <MessageActionDescription>
          <Box css={{ width: '100%', overflow: 'hidden', textOverflow: 'ellipsis' }}>
            {action?.params?.body}
          </Box>
        </MessageActionDescription>
      );
    case ActionTypes.ADD_TAGS:
      return (
        <ActionDescriptionContainer>
          <ActionItems items={tagsList()} />
        </ActionDescriptionContainer>
      );
    case ActionTypes.REMOVE_TAGS:
      return (
        <ActionDescriptionContainer>
          <ActionItems items={tagsList()} />
        </ActionDescriptionContainer>
      );
    case ActionTypes.ASSIGN_USER:
      return (
        <Box>
          {action?.params?.user_id && (
            <Badge size="2">
              {(users.find((u) => u.id === Number(action?.params?.user_id))
                ?.name as string) ||
                (users.find((u) => u.id === Number(action?.params?.user_id))
                  ?.email as string)}
            </Badge>
          )}
        </Box>
      );
    case ActionTypes.UNASSIGN_USER:
      return <Box>Un-assigns a user from the conversation</Box>;
    case ActionTypes.NOTIFY_USERS:
      return (
        <ActionDescriptionContainer>
          <ActionItems items={usersList()} />
        </ActionDescriptionContainer>
      );
    case ActionTypes.OPT_OUT:
      return <Box>Opts the contact out of receiving messages</Box>;
    case ActionTypes.OPT_IN:
      return <Box>Opts the contact in to receiving messages</Box>;
    case ActionTypes.ADD_TO_SEQUENCE:
      return (
        <Box>
          {action?.params?.sequence_id && (
            <Badge size="2">{getSequenceTitle(action?.params?.sequence_id)}</Badge>
          )}
        </Box>
      );
    case ActionTypes.REMOVE_FROM_SEQUENCE:
      return (
        <Box>
          {action?.params?.sequence_id && (
            <Badge size="2">{getSequenceTitle(action?.params?.sequence_id)}</Badge>
          )}
        </Box>
      );
    case ActionTypes.UPDATE_CONVERSATION_STATUS:
      return (
        <Box>
          {action?.params?.status && <Badge size="2">{action?.params?.status}</Badge>}
        </Box>
      );
    case ActionTypes.SEND_EMAIL:
      return (
        <MessageActionDescription>
          <Box css={{ width: '100%', overflow: 'hidden', textOverflow: 'ellipsis' }}>
            {action?.params?.body}
          </Box>
        </MessageActionDescription>
      );
    case ActionTypes.MOVE_TO_STEP:
      return (
        <Box>
          {action?.params?.sequence_id && (
            <Badge size="2">
              {getSequenceTitle(action?.params?.sequence_id)} -{' '}
              {
                findSequenceStep(action?.params?.sequence_id, action?.params?.step_id)
                  ?.title
              }
            </Badge>
          )}
        </Box>
      );
    default:
      return '';
  }
};

const MessageActionDescription = styled(Box, {
  maxWidth: '100%',
  overflowY: 'hidden',
  overflowX: 'hidden',
  textOverflow: 'ellipsis',
  textAlign: 'left',
  display: 'flex',
  whiteSpace: 'nowrap',
  mr: 5,
});

const ActionDescriptionContainer = styled(Box, {
  maxWidth: '100%',
  overflowY: 'wrap',
});

const ItemsRowContainer = styled(HStack, {
  width: '100%',
  position: 'relative',
  overflow: 'hidden',
});

const HiddenRow = styled(HStack, {
  opacity: 0,
  position: 'absolute',
  pointerEvents: 'none',
});
