import React, { useState } from 'react';
import { HiBan, HiUserRemove } from 'react-icons/hi';
import { toast } from 'sonner';

import { blockContact } from '@/shared/api/contacts/v1';
import { ConfirmationDialog } from '@/shared/components/ConfirmationDialog';
import { Contact } from '@/shared/types';
import { Box, Button, VStack } from '@/shared/ui';
import i18next from '@/shared/utils/translation';

import { UnsubscribePreferenceErrorBoundary } from './preferences/boundaries/UnsubscribePreferenceErrorBoundaries';
import { UnsubscribePreference } from './preferences/UnsubscribePreference';

type OptContactOutProps = {
  /** contact object */
  contact: Contact;
  /** setCurrent hook from ContactEditorLayout (upper) layer */
  setCurrent: (contact: Contact | null) => void;
};

export const OptoutContact = (props: OptContactOutProps): JSX.Element => {
  // setCurrent hook from ContactEditorLayout (upper) layer */
  const { setCurrent } = props;
  // is the contact blocked?
  const [isBlocked, setIsBlocked] = useState(props.contact.blocked || false);

  const onBlockContact = async () => {
    try {
      // make a request to block the contact
      await blockContact(props.contact.id, !isBlocked);
      setIsBlocked(!isBlocked);
      /*
        After hitting "block" button, current contact's "blocked" property get reversed,
        but the "current" object (of type contact) of this page is not updated yet.
        Therefore, we need to copy a current object and reverse its "blocked" property
        and setCurrent into the copy to trigger a page refresh
      */
      const updatedContact = { ...props.contact, blocked: !isBlocked };
      setCurrent(updatedContact);

      toast.success(i18next.t('contact_blocked_success') as string);
    } catch (err) {
      console.log(err);
      toast.error(i18next.t('contact_blocked_failure') as string);
    }
  };

  return (
    <VStack css={{ py: 20 }} gap={3}>
      <Box css={{ px: 30, width: '100%' }}>
        <ConfirmationDialog
          title={isBlocked ? 'Un-block Contact' : 'Block Contact'}
          description={
            isBlocked
              ? 'Un-blocking a contact will set their conversations to a closed state. Messages you missed while the contact was blocked are still preserved.'
              : 'Blocking a contact means you will no longer see their messages in your inbox. You can unblock a contact at any time.'
          }
          onConfirm={onBlockContact}
          confirmButtonTitle={isBlocked ? 'Yes, Un-block' : 'Yes, Block'}
          testID="block-contact-button"
        >
          <Button variant="red" css={{ width: '100%' }}>
            <HiBan />
            {isBlocked ? 'Un-block Contact' : 'Block Contact'}
          </Button>
        </ConfirmationDialog>
      </Box>
      <Box css={{ px: 30, width: '100%' }}>
        <UnsubscribePreferenceErrorBoundary feature="UnsubscribePreference">
          <UnsubscribePreference contact={props.contact}>
            <Button variant="red" css={{ width: '100%' }}>
              <HiUserRemove />
              Un-subscribe Contact
            </Button>
          </UnsubscribePreference>
        </UnsubscribePreferenceErrorBoundary>
      </Box>
    </VStack>
  );
};
