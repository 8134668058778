/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';

import { useTags } from '@/pages/settings/organization/tags/context/TagsContext';
import { Flex } from '@/shared/ui';

import { TAGS } from '../AudienceSelection';
import { AudienceTag } from '.';

type TagsProps = {
  /** array of tag ids from the audience */
  tag_ids: string[];
  /** function to remove the tag from the audience */
  onItemDelete: (type: string, id?: string) => void;
  /** if removing the tag is disabled */
  disabled?: boolean;
};

export const Tags = (props: TagsProps) => {
  const tag_ids = props.tag_ids;
  const tags = useTags();
  const { tagsState, getTag } = tags;
  const { allTags: tagsList } = tagsState;

  return (
    <Flex wrap="wrap">
      {tag_ids &&
        tag_ids?.map((tag_id) => {
          return (
            <AudienceTag
              key={tag_id}
              id={tag_id}
              list={tagsList}
              getObject={getTag}
              onItemDelete={props.onItemDelete}
              itemType={TAGS}
              disabled={props.disabled}
            />
          );
        })}
    </Flex>
  );
};
