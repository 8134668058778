import { GET_ATTACHMENTS, SET_ERROR, SET_LOADING } from './types';

type AttachmentsAction =
  | { type: 'GET_ATTACHMENTS'; payload: any }
  | { type: 'SET_ERROR'; payload: any }
  | { type: 'SET_LOADING'; payload: boolean };

const AttachmentsReducer = (state: any, action: AttachmentsAction) => {
  switch (action.type) {
    case GET_ATTACHMENTS:
      return {
        ...state,
        attachments: action.payload,
        loading: false,
        error: false,
      };
    case SET_LOADING:
      return {
        ...state,
        loading: action.payload,
        error: false,
      };
    case SET_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

export default AttachmentsReducer;
