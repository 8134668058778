import React from 'react';
import { Helmet } from 'react-helmet-async';
import {
  HiArchive,
  HiCalendar,
  HiCheckCircle,
  HiDocument,
  HiSearch,
  HiSpeakerphone,
  HiTemplate,
} from 'react-icons/hi';
import { useRouteMatch } from 'react-router-dom';
import { useMedia } from 'react-use';

import { useSidenavigationSettings } from '@/shared/components/navigation/context/SideNavigationContext';
import {
  SideNavigation,
  sideNavigationGroup,
} from '@/shared/components/navigation/SideNavigation';
import {
  SidebarNavigationContainer,
  SidebarNavigationWrapper,
} from '@/shared/components/navigation/SideNavigationContainer';
import { LayoutContent, MainLayout } from '@/shared/layouts';

export function CampaignsNavigation() {
  const { navigationSettings, toggleExpandedState } = useSidenavigationSettings();

  const isCampaigns = useRouteMatch([
    '/campaigns/sms',
    '/campaigns/email',
    '/campaigns',
  ])?.isExact;
  const isDrafts = useRouteMatch([
    '/campaigns/draft',
    '/campaigns/sms/draft',
    '/campaigns/email/draft',
  ])?.isExact;
  const isScheduled = useRouteMatch([
    '/campaigns/scheduled',
    '/campaigns/sms/scheduled',
    '/campaigns/email/scheduled',
  ])?.isExact;
  const isComplete = useRouteMatch([
    '/campaigns/complete',
    '/campaigns/sms/complete',
    '/campaigns/email/complete',
  ])?.isExact;
  const isArchived = useRouteMatch([
    '/campaigns/archive',
    '/campaigns/sms/archive',
    '/campaigns/email/archive',
  ])?.isExact;
  const isGlobalTemplates = useRouteMatch('/campaigns/templates/browse')?.isExact;
  const isMyTemplates = useRouteMatch('/campaigns/templates')?.isExact;

  const isEmailCampaigns = useRouteMatch('/campaigns/email*') !== null;
  const isNewSMSCampaigns = useRouteMatch('/campaigns/sms*') !== null;

  const templates = [
    {
      title: 'My Templates',
      image: <HiTemplate size={18} />,
      isActive: !!isMyTemplates,
      link: '/campaigns/templates',
    },
    {
      title: 'Browse Templates',
      image: <HiSearch size={18} />,
      isActive: !!isGlobalTemplates,
      link: '/campaigns/templates/browse',
    },
  ];
  const campaignsSideNavigationTabs: Array<sideNavigationGroup> = [
    {
      sideNavigationGroup: [
        {
          title: 'All Campaigns',
          image: <HiSpeakerphone size={18} />,
          isActive: !!isCampaigns,
          link: getAllCampaignsRoute(isEmailCampaigns, isNewSMSCampaigns),
        },
        {
          title: 'Drafts',
          image: <HiDocument size={18} />,
          isActive: !!isDrafts,
          link: getDraftCampaignsRoute(isEmailCampaigns, isNewSMSCampaigns),
        },
        {
          title: 'Scheduled',
          image: <HiCalendar size={18} />,
          isActive: !!isScheduled,
          link: getScheduledCampaignsRoute(isEmailCampaigns, isNewSMSCampaigns),
        },
        {
          title: 'Complete',
          image: <HiCheckCircle size={18} />,
          isActive: !!isComplete,
          link: getCompletedCampaignsRoute(isEmailCampaigns, isNewSMSCampaigns),
        },
        {
          title: 'Archived',
          image: <HiArchive size={18} />,
          isActive: !!isArchived,
          link: getArchivedCampaignsRoute(isEmailCampaigns, isNewSMSCampaigns),
        },
      ],
    },
  ];
  return (
    <SidebarNavigationContainer>
      <SidebarNavigationWrapper>
        <SideNavigation
          expanded={navigationSettings.expanded}
          header={getCampaignHeader(isEmailCampaigns, isNewSMSCampaigns)}
          sideNavigationTabs={
            isEmailCampaigns
              ? campaignsSideNavigationTabs
              : [
                  ...campaignsSideNavigationTabs,
                  {
                    sideNavigationGroup: isEmailCampaigns ? [] : templates,
                  },
                ]
          }
          toggleExpandedState={toggleExpandedState}
        />
      </SidebarNavigationWrapper>
    </SidebarNavigationContainer>
  );
}

export const CampaignsLayoutContainer = ({ children }: { children: React.ReactNode }) => {
  const isDesktop = useMedia('(min-width: 912px)');
  return (
    <MainLayout>
      {isDesktop && <CampaignsNavigation />}
      <LayoutContent>{children}</LayoutContent>
    </MainLayout>
  );
};

export const CampaignContainer = ({ children }: { children: React.ReactNode }) => {
  return (
    <>
      <Helmet>
        <title>Whippy | Campaigns</title>
      </Helmet>
      <CampaignsLayoutContainer>{children}</CampaignsLayoutContainer>
    </>
  );
};

function getCampaignHeader(isEmailCampaigns?: boolean, isNewSMSCampaigns?: boolean) {
  if (isEmailCampaigns) {
    return 'Email';
  } else if (isNewSMSCampaigns) {
    return 'SMS';
  }
  return 'Campaigns';
}

function getAllCampaignsRoute(isEmailCampaigns?: boolean, isNewSMSCampaigns?: boolean) {
  if (isEmailCampaigns) {
    return '/campaigns/email';
  } else if (isNewSMSCampaigns) {
    return '/campaigns/sms';
  }
  return '/campaigns';
}

function getDraftCampaignsRoute(isEmailCampaigns?: boolean, isNewSMSCampaigns?: boolean) {
  if (isEmailCampaigns) {
    return '/campaigns/email/draft';
  } else if (isNewSMSCampaigns) {
    return '/campaigns/sms/draft';
  }
  return '/campaigns/draft';
}

function getArchivedCampaignsRoute(
  isEmailCampaigns?: boolean,
  isNewSMSCampaigns?: boolean
) {
  if (isEmailCampaigns) {
    return '/campaigns/email/archive';
  } else if (isNewSMSCampaigns) {
    return '/campaigns/sms/archive';
  }
  return '/campaigns/archive';
}

function getCompletedCampaignsRoute(
  isEmailCampaigns?: boolean,
  isNewSMSCampaigns?: boolean
) {
  if (isEmailCampaigns) {
    return '/campaigns/email/complete';
  } else if (isNewSMSCampaigns) {
    return '/campaigns/sms/complete';
  }
  return '/campaigns/complete';
}

function getScheduledCampaignsRoute(
  isEmailCampaigns?: boolean,
  isNewSMSCampaigns?: boolean
) {
  if (isEmailCampaigns) {
    return '/campaigns/email/scheduled';
  } else if (isNewSMSCampaigns) {
    return '/campaigns/sms/scheduled';
  }
  return '/campaigns/scheduled';
}
