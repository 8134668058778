import { useEffect, useMemo, useState } from 'react';

import { ContactTagType } from '@/shared/types';
import { IconButton, Tooltip, TooltipContent, TooltipTrigger, VStack } from '@/shared/ui';

import { useContacts } from '../../context/ContactContext';
import { useUploads } from '../../uploads/context/UploadContext';
import { ContactNotes } from '../ContactNotes';
import { ContactDetails } from './ContactDetails';
import { ContactTags } from './ContactTags';

type ContactViewProps = {
  contactId?: string;
  onClose?: () => void;
  isInbox?: boolean;
};

// contact accordion types
export enum AccordionValue {
  CONTACT_DETAILS = 'contact_details',
  CONTACT_NOTES = 'contact_notes',
  CONTACT_TAGS = 'contact_tags',
  CONTACT_LISTS = 'contact_lists',
  CONTACT_SEQUENCES = 'contact_sequences',
  CONTACT_CAMPAIGNS = 'contact_campaigns',
  CONTACT_CONVERSATIONS = 'contact_conversations',
  DEFAULT_VALUE = '',
}

export const ContactView = ({ contactId }: ContactViewProps) => {
  const {
    contactState: { current, loadingContact },
    setCurrent,
    findAndSetCurrent,
    getAndSetCurrent,
  } = useContacts();
  const {
    uploadsState: { allUploads },
  } = useUploads();

  const [accordion, setAccordion] = useState<AccordionValue>(
    AccordionValue.DEFAULT_VALUE
  );

  useEffect(() => {
    if (contactId && current?.id !== contactId) {
      setCurrent(null);
      if (!current?.contact_tags) {
        getAndSetCurrent(contactId);
      } else {
        findAndSetCurrent(contactId);
      }
    }
  }, [contactId]);

  const currentContactTags = useMemo(
    () => current?.contact_tags?.map((contact_tag) => contact_tag.tag.id) || [],
    [current?.contact_tags, current?.id]
  );

  const currentContactLists = useMemo(
    () => current?.contact_lists?.map((contact_list) => contact_list.tag.id) || [],
    [current?.contact_lists, current?.id]
  );

  console.log('currentContactTags', currentContactTags);
  console.log('currentContactLists', currentContactLists);

  return (
    <VStack gap={1} css={{ width: '100%' }}>
      <ContactDetails
        contact={current}
        accordion={accordion}
        setAccordion={setAccordion}
        loading={loadingContact}
      />
      <ContactTags
        contact={current}
        loading={loadingContact}
        defaultSelectedTags={currentContactTags}
      />
      <ContactNotes />
      {allUploads?.length ? (
        <ContactTags
          contact={current}
          loading={loadingContact}
          defaultSelectedTags={currentContactLists}
          type={ContactTagType.UPLOAD}
          accordionValue={AccordionValue.CONTACT_LISTS}
        />
      ) : null}
    </VStack>
  );
};

type ContactButtonProps = {
  icon?: React.ReactElement;
  text?: string;
  backgroundColor?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

export const ContactButton = (props: ContactButtonProps) => {
  const sanitizedText = props?.text?.toLowerCase()?.replace(/\s/g, '');
  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <IconButton
          onClick={props.onClick}
          size={2}
          variant="ghost"
          css={{ width: 32, height: 32, color: '#60646C' }}
          data-testid={`contact-button-${sanitizedText}`}
        >
          {props.icon}
        </IconButton>
      </TooltipTrigger>
      <TooltipContent>{props.text}</TooltipContent>
    </Tooltip>
  );
};
