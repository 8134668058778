import React, { memo, useMemo } from 'react';

import { useUsers } from '@/pages/settings/organization/users/context/UserContext';
import { CreatedByUserType } from '@/shared/types/domains';
import { User } from '@/shared/types/users';
import { Avatar, Box, HStack } from '@/shared/ui';
import { TooltipAvatar } from '@/shared/ui/TooltipAvatar';
import { initials } from '@/shared/utils/initials/initials';

export const CreatedByUser = memo(
  ({
    isTable,
    userId,
    user,
  }: {
    isTable?: boolean;
    userId?: number;
    user?: CreatedByUserType;
  }) => {
    const {
      userState: { users },
    } = useUsers();

    const getUser = useMemo(() => {
      return user || users.filter((user: User) => user.id === userId)?.[0];
    }, [userId, users, user]);

    return (userId || user) && getUser ? (
      <>
        {isTable ? (
          <TooltipAvatar user={getUser} />
        ) : (
          <HStack>
            <Avatar
              variant="pink"
              size="1"
              src={getUser?.attachment?.url}
              fallback={initials(getUser?.name || getUser?.email)}
            />
            <Box css={{ fontSize: 14, color: '$blackA11' }}>
              {getUser?.name || getUser?.email}
            </Box>
          </HStack>
        )}
      </>
    ) : null;
  }
);
