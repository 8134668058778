import React, { useEffect, useRef, useState } from 'react';

import { useAuth } from '@/auth/context/AuthProvider';
import { CreateCropImage } from '@/shared/components/CreateCropImage';
import { useDisclosure } from '@/shared/hooks';

export type AttachmentType = {
  url: string;
  presigned_url: string;
};

export type OrganizationLogoProps = {
  organizationAttachment: string;
};

type Blob = {
  size: number;
  type: string;
  arrayBuffer(): Promise<ArrayBuffer>;
  slice: (start?: number, end?: number, contentType?: string) => Blob;
  stream: any;
  text: any;
};

const OrganizationLogo = (props: OrganizationLogoProps): JSX.Element => {
  const auth = useAuth();
  const placeholderImg = 'https://dummyimage.com/300/ddd.jpg';
  const [attachment, setAttachment] = useState<AttachmentType | undefined | null>({
    url: placeholderImg,
    presigned_url: '',
  });
  const [currentAttachment, setCurrent] = useState<AttachmentType>({
    url: '',
    presigned_url: '',
  });

  const [croppedBlob, setCropped] = useState<Blob | null>(null);
  const [timeoutFlag, setTimeoutFlag] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    setAttachment({ url: props.organizationAttachment, presigned_url: '' });
  }, [props.organizationAttachment]);

  const sleep = (ms: number) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  // overwrites the original s3 attachment with the cropped one
  // updates the organization with the new attachment
  const handleUpdate = () => {
    if (croppedBlob) {
      fetch(currentAttachment.presigned_url, {
        method: 'PUT',
        body: croppedBlob as BodyInit,
      }).then(async () => {
        setTimeoutFlag(true);
        await sleep(4000).then(() => setTimeoutFlag(false));
        setAttachment(currentAttachment);
        auth.updateOrganizationAttachment({
          id: auth.organizationId,
          url: currentAttachment.url,
          content_type: croppedBlob?.type,
        });
        onClose();
      });
    } else {
      const regexForContentType = /(?:\.([^.]+))?$/;
      const contentType = regexForContentType.exec(currentAttachment?.url);
      // Non-null assertion is also an option -> regexForContentType.exec(currentAttachment?.url)![1]
      let type;
      if (contentType) {
        type = contentType[1];
      }
      setAttachment(currentAttachment);
      auth.updateOrganizationAttachment({
        id: auth.organizationId,
        url: currentAttachment.url,
        content_type: type || 'image/jpeg',
      });
      onClose();
    }
  };

  /* Attachment Deletion Functionality */
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const onAlertClose = () => setIsAlertOpen(false);
  const cancelRef = useRef();

  const handleDelete = () => {
    auth.updateOrganizationAttachment({
      id: auth.organizationId,
    });
    setAttachment({ url: placeholderImg, presigned_url: '' });
    onAlertClose();
  };

  return (
    <CreateCropImage
      handleDelete={handleDelete}
      handleUpdate={handleUpdate}
      isAlertOpen={isAlertOpen}
      setIsAlertOpen={setIsAlertOpen}
      onAlertClose={onAlertClose}
      cancelRef={cancelRef}
      attachment={attachment}
      currentAttachment={currentAttachment}
      setCurrent={setCurrent}
      onOpen={onOpen}
      onClose={onClose}
      isOpen={isOpen}
      timeoutFlag={timeoutFlag}
      setCropped={setCropped}
    />
  );
};

export default OrganizationLogo;
