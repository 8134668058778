import { keyframes, styled } from '@/stitches.config';

export const pulse = keyframes({
  '0%': { opacity: 0 },
  '100%': { opacity: '100%' },
});

export const Skeleton = styled('div', {
  backgroundColor: '$slate4',
  position: 'relative',
  overflow: 'hidden',
  my: '$3',

  '&::after': {
    animationName: `${pulse}`,
    animationDuration: '500ms',
    animationDirection: 'alternate',
    animationIterationCount: 'infinite',
    animationTimingFunction: 'ease-in-out',
    backgroundColor: '$slate6',
    borderRadius: 'inherit',
    bottom: 0,
    content: '""',
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0,
  },

  variants: {
    variant: {
      avatar: {
        borderRadius: '$round',
        height: '$4',
        width: '$4',
      },
      text: {
        height: '$1',
        borderRadius: '$1',
      },
      title: {
        height: '$5',
        borderRadius: '$1',
      },
      heading: {
        height: '$3',
        borderRadius: '$1',
      },
      subheading: {
        height: '$2',
        borderRadius: '$1',
      },
      button: {
        borderRadius: '$1',
        height: '$5',
        width: '$8',
      },
      icon: {
        borderRadius: '$1',
        height: '$4',
        width: '$4',
      },
      fill: {
        height: '100%',
        width: '100%',
      },
      tag: {
        height: 30,
        width: '100px',
        boxShadow: 'inset 0 0 0 1px $colors$gray4',
        borderRadius: 4,
        m: 3,
      },
    },
  },
  defaultVariants: {
    variant: 'text',
  },
});
