import {
  AutomationTemplatesActions,
  AutomationTemplatesActionTypes,
} from '@/shared/types/automations';

import { AutomationTemplatesState } from './AutomationsTemplatesContext';

export const AutomationTemplatesReducer: React.Reducer<
  AutomationTemplatesState,
  AutomationTemplatesActions
> = (state: AutomationTemplatesState, action: AutomationTemplatesActions) => {
  switch (action.type) {
    case AutomationTemplatesActionTypes.GET_AUTOMATION_TEMPLATES:
      return {
        ...state,
        templates:
          action.payload.offset === 0
            ? action.payload.data
            : [...state.templates, ...action.payload.data],
        loading: false,
        total: action.payload.total,
      } as AutomationTemplatesState;
    case AutomationTemplatesActionTypes.GET_AUTOMATION_TEMPLATE:
      return {
        ...state,
        current: action.payload,
      } as AutomationTemplatesState;
    case AutomationTemplatesActionTypes.CREATE_AUTOMATION_TEMPLATE:
      return {
        ...state,
        templates: [...state.templates, action.payload],
      } as AutomationTemplatesState;
    case AutomationTemplatesActionTypes.UPDATE_AUTOMATION_TEMPLATE:
      return {
        ...state,
        templates: state.templates.map((template) =>
          template.id === action.payload.id ? action.payload : template
        ),
      } as AutomationTemplatesState;
    case AutomationTemplatesActionTypes.DELETE_AUTOMATION_TEMPLATE:
      return {
        ...state,
        templates: state.templates.filter((template) => template.id !== action.payload),
      } as AutomationTemplatesState;
    case AutomationTemplatesActionTypes.SET_CURRENT_AUTOMATION_TEMPLATE:
      return {
        ...state,
        current: action.payload,
      } as AutomationTemplatesState;
    default:
      return state;
  }
};
