import i18next from 'i18next';
import React, { useEffect, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { HiDotsHorizontal, HiDownload } from 'react-icons/hi';
import { useHistory } from 'react-router-dom';
import { toast } from 'sonner';

import {
  CampaignsContacts,
  useCampaignsContext,
} from '@/campaigns/context/CampaignsContext';
import { LIMIT } from '@/campaigns/context/constants';
import { CampaignAnalyticsTab } from '@/campaigns/context/types';
import { PageLayout } from '@/shared/layouts/PageLayout';
import { CampaignStatus } from '@/shared/types/campaigns';
import {
  Box,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  HStack,
  IconButton,
} from '@/shared/ui';

import { CampaignActions, TooltipButton } from '../v2/analytics/CampaignActions';
import { CampaignAnalyticsTabs } from './CampaignAnalyticsTabs';
import { BulkCampaignContactsActions } from './CampaignContactsActions';
import { EditCampaignName } from './EditCampaignName';

type DownloadDataType = {
  /* name of the contact */
  name?: string;
  /* email of the contact */
  email?: string;
  /* phone of the contact */
  phone?: string;
  /* campaign response of the contact */
  response?: string[] | string;
  /* date the contact responded */
  responded_at?: string;
};

export const CampaignAnalytics = (): JSX.Element => {
  // campaigns state
  const campaigns = useCampaignsContext();
  const {
    campaignsState,
    currentTab,
    getCampaignContacts,
    duplicateOneCampaign,
    updateOneCampaign,
    pauseOneCampaign,
  } = campaigns;
  const { current } = campaignsState;

  const history = useHistory();

  const onDuplicateClick = async () => {
    if (current && current.id) {
      const data = await duplicateOneCampaign(current.id);
      data && history.push('/campaigns/all');
    }
  };

  const onShareClick = () => {
    if (current && current.id) {
      navigator.clipboard.writeText(
        `https://app.whippy.co/campaigns/${current.id}` || ''
      );
      toast.success(i18next.t('copy_campaign_link_success') as string);
    }
  };

  const [downloadData, setDownloadData] = useState<DownloadDataType[]>([]);
  const ref = useRef<{
    link: HTMLAnchorElement | null;
  }>();

  /**
   * Retrieves and combines all campaign contacts.
   * @returns {Promise<Array>} - A promise that resolves to an array of all campaign contacts.
   */
  const getAllCampaignContacts = async () => {
    // Add a default value for 'current' to prevent possible errors
    const currentId = current?.id || '';

    const response: CampaignsContacts | null = await (getCampaignContacts(
      currentId,
      currentTab,
      0,
      LIMIT
    ) as Promise<CampaignsContacts | null>);
    if (!response) return [];
    const { total, campaignsContacts } = response;

    // Calculate remaining contacts and query them
    const remaining = total - campaignsContacts.length;

    // Check if there are any remaining contacts before making another request
    if (remaining > 0) {
      const remainingCampaign = await getCampaignContacts(
        currentId,
        currentTab,
        campaignsContacts.length,
        remaining
      );
      if (!remainingCampaign) return [];
      const { campaignsContacts: remainingContacts } = remainingCampaign;

      // All contacts is the initial query combined with remaining
      return [...campaignsContacts, ...remainingContacts];
    }

    return campaignsContacts;
  };

  /**
   * Prepares export data based on the currentTab and sets it for download.
   * @returns {Promise<void>}
   */
  const getExportData = async () => {
    const campaignContacts = await getAllCampaignContacts();

    if (campaignContacts) {
      type MappedCampaignContact = {
        contact: {
          name: string | null;
          phone: string | null;
          email: string | null;
        };
        contact_messages: Array<{
          body: string | null | undefined;
          inserted_at?: string | null | undefined;
        }>;
      };

      const mapCampaignData = (campaign: MappedCampaignContact) => {
        const data = {
          name: campaign.contact.name || '',
          phone: campaign.contact.phone || '',
          email: campaign.contact.email || '',
          response: campaign.contact_messages[0]?.body || '',
          responded_at: campaign.contact_messages[0]?.inserted_at || '',
        };

        return data;
      };

      // Map the campaign data and set it for download
      const downloadData = campaignContacts.map(mapCampaignData);
      setDownloadData(downloadData);
      ref.current && ref.current.link ? ref.current.link.click() : null;
    }
  };

  const onArchiveClick = () => {
    if (current && current.id) {
      updateOneCampaign(
        current?.id,
        { ...current, status: CampaignStatus.ARCHIVED },
        false
      );
      history.replace(`/campaigns/archive`);
      toast.success(i18next.t('campaign_archive_success') as string);
    }
  };

  useEffect(() => {
    setDownloadData([]);
  }, [currentTab]);

  return (
    <PageLayout
      border={false}
      breadcrumbs={[
        { title: 'Campaigns', path: '/campaigns' },
        {
          title: `${current?.title || 'Create New Campaign'}`,
          path: `/campaigns/${current?.id || 'create'}`,
        },
      ]}
      actions={
        <HStack gap={2}>
          <CampaignActions current={current} pauseOneCampaign={pauseOneCampaign} />
          {/* All tabs except for Overview have the download button as there is nothing to download in Overview tab */}
          {currentTab !== CampaignAnalyticsTab.OVERVIEW && (
            <Box>
              <TooltipButton text="Download Campaign Contacts">
                <IconButton
                  variant="outline"
                  size="2"
                  onClick={getExportData}
                  data-testid="download-campaign-button"
                >
                  <HiDownload />
                </IconButton>
              </TooltipButton>
              <CSVLink
                data={downloadData}
                ref={ref as any}
                filename={`${currentTab}_contacts_${current?.id}.csv`}
              />
            </Box>
          )}
          <DropdownMenu>
            <DropdownMenuTrigger>
              <IconButton variant="outline" size="2" data-testid="dots-button">
                <HiDotsHorizontal />
              </IconButton>
            </DropdownMenuTrigger>
            <DropdownMenuContent sideOffset={10} css={{ zIndex: 999 }}>
              <DropdownMenuItem onClick={onDuplicateClick}>
                Duplicate Campaign
              </DropdownMenuItem>
              <DropdownMenuItem onClick={onShareClick}>Share Campaign</DropdownMenuItem>
              <EditCampaignName initialCampaignName={current?.title || ''}>
                <DropdownMenuItem onClick={(e) => e.preventDefault()}>
                  Edit Campaign Name
                </DropdownMenuItem>
              </EditCampaignName>
              {current?.status === CampaignStatus.COMPLETE && (
                <DropdownMenuItem onClick={onArchiveClick}>
                  Archive Campaign
                </DropdownMenuItem>
              )}
            </DropdownMenuContent>
          </DropdownMenu>
          <BulkCampaignContactsActions />
        </HStack>
      }
    >
      <Box css={{ backgroundColor: '$panel' }}>
        <CampaignAnalyticsTabs />
      </Box>
    </PageLayout>
  );
};
