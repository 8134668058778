import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { HiSearch } from 'react-icons/hi';
import { useHistory, useParams } from 'react-router-dom';
import { useMedia } from 'react-use';
import { toast } from 'sonner';

import { useCampaignsContext } from '@/campaigns/context/CampaignsContext';
import { bulkAddTagsToContacts } from '@/shared/api/contacts/v1';
import { useDisclosure } from '@/shared/hooks';
import { ContactTagItem } from '@/shared/types';
import { Contact } from '@/shared/types';
import { Contact as CampaignContact } from '@/shared/types/campaigns';
import { Tag as TagType } from '@/shared/types/tags';
import {
  Box,
  Button,
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogPortal,
  DialogTrigger,
  Flex,
  Input,
  ScrollArea,
  VStack,
} from '@/shared/ui';
import { equals } from '@/shared/utils/equals/equals';
import i18next from '@/shared/utils/translation';
import { styled } from '@/stitches.config';

import { useContacts } from '../../context/ContactContext';
import { ContactTag } from './ContactTag';
import { ContactTagListItem } from './ContactTagListItem';

type AddContactTagsProps = {
  /** array of tags */
  tags: Array<TagType>;
  /** array of checked contacts */
  checkedContacts: Array<Contact> | string[];
  /** set array of checked contacts */
  setCheckedContacts: (
    checkedContacts: Array<CampaignContact> | string[]
  ) => void | Dispatch<SetStateAction<Contact[]>>;
  /** true if we are adding tags to more than 1 contact */
  isBulkAdd?: boolean;
  /** true if on campaigns page */
  isCampaignsPage?: boolean;
  /** true if on groups page */
  isGroupsPage?: boolean;
  /** true if on uploads page */
  isUploadsPage?: boolean;
  /** true if on inbox page */
  isInboxPage?: boolean;
  /** campaign id */
  campaignId?: string;
  /** current campaign analytics tag e.g. delivered */
  currentTab?: string;
  /** set true if on contacts page */
  setSearchResultChecked?: (b: boolean) => void;
  /** boolean if all contacts should be checked */
  setAllContactsChecked?: (b: boolean) => void;
  /** children that will be used as a dialog trigger */
  children: React.ReactNode;
};

type ParamsType = {
  id: string;
};

export const AddContactTags = (props: AddContactTagsProps): JSX.Element => {
  const {
    checkedContacts,
    setCheckedContacts,
    isBulkAdd,
    isCampaignsPage,
    isGroupsPage,
    isUploadsPage,
    isInboxPage,
    campaignId,
    currentTab,
    setSearchResultChecked,
    setAllContactsChecked,
    children,
  } = props;

  // dialog state
  const { isOpen, onOpen, onClose } = useDisclosure();

  // utils
  const params = useParams<ParamsType>();
  const history = useHistory();
  const isDesktop = useMedia('(min-width: 912px)');

  // contact context
  const { contactState, updateContactTags, setCurrent } = useContacts();
  const { current, allContactsChecked } = contactState;

  // campaigns context
  const campaigns = useCampaignsContext();
  const { campaignsState, setAllSelected } = campaigns;
  const { allSelected } = campaignsState;

  // list of only standard tags
  const tagsList = props.tags.filter((tag: TagType) => tag.type === 'standard');

  // current contact standard type tags
  const currentContactTags = current?.contact_tags?.filter(
    (contact_tag: ContactTagItem) => contact_tag.tag.type === 'standard'
  );

  // When we open inbox page and click 1 contact, props.checkedContact will be empty
  // props.checkedContact is not empty when we are inside Contacts Page
  // Bug fix - When inside Campaign Page, selected tags will always be initialized as empty
  const [selectedTags, setSelectedTags] = useState<string[]>(
    checkedContacts.length == 0 &&
      currentContactTags?.map((e) => e.tag_id).length &&
      currentContactTags?.map((e) => e.tag_id).length > 0 &&
      !isCampaignsPage
      ? currentContactTags?.map((e) => e.tag_id)
      : []
  );

  // are tags searched right now
  const [isSearched, setIsSearched] = useState(false);

  // list of searched tags
  const [searchedTags, setSearchedTags] = useState<Array<TagType>>([]);

  // list of tags of the current contact
  const arrayOfCurrentContactTags =
    current && current?.contact_tags
      ? current?.contact_tags
          .filter((contact_tag: ContactTagItem) => contact_tag.tag.type === 'standard')
          .map((contact_tag: ContactTagItem) => contact_tag.tag.id)
      : [];

  // list of tags that are not assigned to the contact?
  const unassignedTags = tagsList.filter(
    ({ id: id1 }) => !arrayOfCurrentContactTags?.some((id2) => id2 === id1)
  );

  // redirects to tags page if there are no tags created
  const redirectToTagsPage = () => {
    history.replace({ pathname: '/settings/tags' });
  };

  // generate payload depending on page and number of selected contacts
  const generateBulkAddTagsPayload = (tags: Array<string>, contactIds: Array<string>) => {
    if (isCampaignsPage) {
      if (allSelected) {
        setAllSelected(false);
        return {
          tags,
          selection: {
            campaign_id: campaignId,
            campaign_contacts_type: currentTab,
          },
        };
      } else {
        return { tags, selection: { contacts: contactIds } };
      }
    }
    if (allContactsChecked && !isInboxPage) {
      if (isGroupsPage) {
        return { tags, selection: { group_id: params?.id } };
      } else if (isUploadsPage) {
        return { tags, selection: { upload_id: params?.id } };
      } else {
        return { tags, selection: { contacts: 'all' } };
      }
    } else {
      return { tags, selection: { contacts: contactIds } };
    }
  };

  // When tags are selected can user hit "Save" button
  const onSubmit = async () => {
    try {
      // On Campaign Page, even if we want to add tags to a single contact without checking the box
      // the single contact being reviewed will still be marked as checked
      if (checkedContacts && checkedContacts.length === 1 && isCampaignsPage) {
        // checkedContacts are passed in as an array of string (contactIds)
        const contactIds = checkedContacts;
        const res = await bulkAddTagsToContacts(
          generateBulkAddTagsPayload(selectedTags, contactIds as string[])
        );
        // add new contact tags to current if we are updating only 1 contact
        if (contactIds.length < 1 && current) {
          const oldContactTags = current.contact_tags || [];
          const newContactTags = res[current.id || ''] || [];
          setCurrent({
            ...current,
            contact_tags: [...oldContactTags, ...newContactTags],
          });
        }
        if (Object.values(res).length > 0) {
          toast.success(i18next.t('tags_updated_success') as string);
        }
        if (!isCampaignsPage && setAllContactsChecked && setSearchResultChecked) {
          setAllContactsChecked(false);
          setSearchResultChecked(false);
        }
        setCheckedContacts([]);
        handleClose();
      } // On Inbox Page, props.checkedContacts is always an empty array []
      // However, [] will be evaluated as true
      // Here we need to verify if props.checkedContacts actually have any contact (length > 0)
      else if (checkedContacts && checkedContacts.length > 1) {
        const contactIds = (checkedContacts as Contact[]).map(
          (contact: Contact) => contact?.id
        );
        const res = await bulkAddTagsToContacts(
          generateBulkAddTagsPayload(
            selectedTags,
            contactIds.length > 1 ? contactIds : [current?.id || '']
          )
        );
        if (Object.values(res).length > 0) {
          toast.success(i18next.t('tags_updated_success') as string);
        }
        if (!isCampaignsPage && setAllContactsChecked && setSearchResultChecked) {
          setAllContactsChecked(false);
          setSearchResultChecked(false);
        }
        setCheckedContacts([]);

        handleClose();
      } else {
        // There's no checkedContact -> we're at Inbox Page
        // Or we're at Campaign Page and haven't checked any contact
        // Add all selected ContactTags into DB
        for (const item of selectedTags) {
          updateContactTags(current?.id || '', item);
        }

        handleClose();

        if (!equals(selectedTags, arrayOfCurrentContactTags)) {
          toast.success(i18next.t('tags_updated_success') as string);
        }
      }
    } catch (e) {
      toast.error(i18next.t('tags_updated_failed') as string);
      handleClose();
    }
  };

  // This function is triggered when a user types in the tag search bar.
  const onTagSearch = (value: string) => {
    // If the search bar is not empty
    if (value) {
      // Set the isSearched state to true, indicating that a search is in progress
      setIsSearched(true);

      // Filter the tagsList to only include tags whose name includes the search value
      // The search is case-insensitive as both the tag name and search value are converted to lower case
      const searchedTagsArray =
        tagsList.filter((tag: TagType) =>
          tag.name.toLowerCase().includes(value.toLowerCase())
        ) || [];

      // Update the state with the filtered tags
      setSearchedTags(searchedTagsArray);
    } else {
      // If the search bar is empty, reset the isSearched state to false and clear the searchedTags array
      setIsSearched(false);
      setSearchedTags([]);
    }
  };

  // Function to handle the click event on a tag
  const onTagClick = (id: string) => {
    // If the clicked tag is 'select-all'
    if (id === 'select-all') {
      // If all tags are already selected (either in the searched tags or in the full tags list)
      if (
        isSearched
          ? selectedTags.length === searchedTags.length
          : selectedTags.length === tagsList.length
      ) {
        // Deselect all tags
        setSelectedTags([]);
      } else {
        // If not all tags are selected, select all tags (either in the searched tags or in the full tags list)
        setSelectedTags(
          isSearched
            ? searchedTags.map((tag: TagType) => tag.id)
            : tagsList.map((tag: TagType) => tag.id)
        );
      }
      return null;
    }

    // If the clicked tag is not already selected, add it to the selected tags
    // We don't let users remove tags from here
    setSelectedTags([...selectedTags, id]);
  };

  // handle close
  const handleClose = () => {
    setSelectedTags(selectedTags);
    setSearchedTags([]);
    setIsSearched(false);
    onClose();
  };

  // on unmount, reset sate values
  useEffect(() => {
    return () => {
      setSelectedTags([]);
      setSearchedTags([]);
      setIsSearched(false);
    };
  }, []);

  return (
    <Flex
      css={{
        m: checkedContacts || isCampaignsPage ? 0 : '10px 0 0 10px',
        position: 'relative',
        flexWrap: 'wrap',
      }}
    >
      <Dialog open={isOpen} onOpenChange={() => !isOpen}>
        {/* 
            If there's not tag exist, we need to render a "Create Tag" button (tagId = 1), 
            else, we need to render an "Add Tag" button (tagId = 0).
            However, when isBuildAdd == true, we're at Contacts page.
            We need to just render a box with asChild=true.
            In this way, ContactTag will not override "+" button under Contacts Page.
          */}
        {unassignedTags.length === 0 &&
        !isCampaignsPage &&
        !isGroupsPage &&
        !isUploadsPage &&
        !isBulkAdd ? (
          <Box onClick={redirectToTagsPage}>
            <ContactTag isClosable={false} tagId="1" contactId="" index={0} />
          </Box>
        ) : (
          <DialogTrigger asChild={true}>
            <Box onClick={onOpen}>{children}</Box>
          </DialogTrigger>
        )}
        <DialogPortal>
          <DialogContent
            onEscapeKeyDown={handleClose}
            onPointerDownOutside={handleClose}
            style={{
              minWidth: isDesktop ? '620px' : '350px',
              padding: 0,
            }}
          >
            <VStack gap={2}>
              {/* tags search bar */}
              <Box css={{ position: 'relative' }}>
                <Box css={{ position: 'absolute', top: '32%', left: '1.5%' }}>
                  <HiSearch />
                </Box>
                <Input
                  css={{
                    minHeight: 45,
                    padding: '10px 10px 10px 30px',
                    borderBottomLeftRadius: 0,
                    borderBottomRightRadius: 0,
                  }}
                  placeholder="Search Tags"
                  onChange={(e) => onTagSearch(e.target.value)}
                />
              </Box>
              {/* tags list */}
              {/* TODO: the entire scroll area should be treated as a component to filter out disabled tags */}
              <ScrollArea
                variant="combobox"
                css={{ maxHeight: 250, overflowY: 'auto', marginTop: 0 }}
              >
                <ContactTagListItem
                  key={'select-all-tag-option'}
                  tag={{ name: 'Select All', color: 'black', id: 'select-all' }}
                  selectedTags={selectedTags}
                  tagsList={tagsList}
                  searchedTags={searchedTags}
                  isSearched={isSearched}
                  onTagClick={onTagClick}
                />
                {/* need to render included tags as checked */}
                {isSearched
                  ? searchedTags.map((tag: TagType) => (
                      <ContactTagListItem
                        key={tag.id}
                        tag={tag}
                        selectedTags={selectedTags}
                        tagsList={tagsList}
                        searchedTags={searchedTags}
                        isSearched={isSearched}
                        onTagClick={onTagClick}
                      />
                    ))
                  : tagsList.map((tag: TagType) => (
                      <ContactTagListItem
                        key={tag.id}
                        tag={tag}
                        selectedTags={selectedTags}
                        tagsList={tagsList}
                        searchedTags={searchedTags}
                        isSearched={isSearched}
                        onTagClick={onTagClick}
                      />
                    ))}
              </ScrollArea>
            </VStack>
            <DialogFooter
              justify="end"
              css={{
                mt: 0,
                p: 20,
                borderTop: '1px solid $slate7',
              }}
            >
              <DialogClose asChild>
                <Button variant="gray" size={2} css={{ mr: '$1' }} onClick={onClose}>
                  Cancel
                </Button>
              </DialogClose>
              <DialogClose asChild>
                <Button onClick={onSubmit} size={2}>
                  Add Tags
                </Button>
              </DialogClose>
            </DialogFooter>
          </DialogContent>
        </DialogPortal>
      </Dialog>
    </Flex>
  );
};

export const ContactTagLayout = styled(Flex, {
  maxWidth: '220px',
  width: 'max-content',
  fontSize: '12px',
  fontWeight: 'medium',
  borderRadius: '4px',
  padding: '5px 7px',
  paddingRight: '7px',
  paddingLeft: '7px',
  margin: '3px',
  backgroundColor: '$panel',
  cursor: 'pointer',
  boxShadow: 'inset 0 0 0 1px $colors$gray4',
  color: '$hiContrast',
  '@hover': {
    '&:hover': {
      boxShadow: 'inset 0 0 0 1px $colors$gray4',
    },
  },
  '&:active': {
    backgroundColor: '$slate2',
    boxShadow: 'inset 0 0 0 1px $colors$gray4',
  },
  '&:focus': {
    boxShadow: 'inset 0 0 0 1px $colors$gray4, 0 0 0 1px $colors$gray4',
  },
});
