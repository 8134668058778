import i18next from 'i18next';
import React, { Dispatch, memo, useCallback, useState } from 'react';
import { HiQuestionMarkCircle } from 'react-icons/hi';
import { toast } from 'sonner';

import { directUpload } from '@/shared/components/attachments/utils';
import { TooltipIconButton } from '@/shared/components/TooltipIconButton';
import {
  AccordionValue,
  CustomCardOptions,
  UtmBuilderOptions,
} from '@/shared/types/links';
import {
  Box,
  Button,
  Divider,
  Fieldset,
  Flex,
  HStack,
  Input,
  Label,
  Text,
  TextArea,
  VStack,
} from '@/shared/ui';
import { Switch, SwitchThumb } from '@/shared/ui/Switch';
import { styled } from '@/stitches.config';

import { LinkAccordion } from './LinkAccordion';
import { UploadImage } from './UploadImage';
import { UTMBuilderForm } from './UTMBuilderForm';

const MAX_DESCRIPTION = 255;
const MAX_IMAGE_SIZE = 5;

type LinkSettingsProps = {
  /* accordion value */
  accordion: AccordionValue;
  /* accordion setter */
  setAccordion: Dispatch<React.SetStateAction<AccordionValue>>;
  /* link description */
  description: string;
  /* link description setter */
  setDescription: Dispatch<React.SetStateAction<string>>;
  /* handle save */
  handleSave: (accordion: AccordionValue) => void;
  /* is link settings form dirty? */
  isDirty: boolean;
  /* is link settings form submitting? */
  isSubmitting: boolean;
  /* show save button or not */
  showSaveButton?: boolean;
  /* customCard options */
  customCard: CustomCardOptions;
  /* customCard options setter */
  setCustomCard: Dispatch<React.SetStateAction<CustomCardOptions>>;
  /* utmBuilder options */
  utmBuilder: UtmBuilderOptions;
  /* utmBuilder options setter */
  setUtmBuilder: Dispatch<React.SetStateAction<UtmBuilderOptions>>;
  /* show description */
  showDescription?: boolean;
  /* show Custom Card */
  showCustomCard?: boolean;
  /* show Utm Builder */
  showUtmBuilder?: boolean;
  /* description setter */
  setShowDescription?: Dispatch<React.SetStateAction<boolean>>;
  /* custom card setter */
  setShowCustomCard?: Dispatch<React.SetStateAction<boolean>>;
  /* Utm Builder setter */
  setShowUtmBuilder?: Dispatch<React.SetStateAction<boolean>>;
  /* disable edit */
  disableEdit?: boolean;
  /* hide index and green box */
  hideIndex?: boolean;
};

const sleep = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

const LinkSettings = (props: LinkSettingsProps): JSX.Element => {
  const {
    accordion,
    setAccordion,
    description,
    setDescription,
    handleSave,
    showSaveButton = true,
    customCard,
    setCustomCard,
    utmBuilder,
    setUtmBuilder,
    showCustomCard = true,
    showUtmBuilder = true,
    setShowCustomCard,
    setShowUtmBuilder,
    disableEdit,
    hideIndex,
    isDirty,
    isSubmitting,
  } = props;

  const [loading, setLoading] = useState(false);

  const handleUpload = useCallback(async (file: File) => {
    setLoading(true);
    try {
      const currentFileExtension = file.name.split('.').slice(-1)[0];
      const maxAllowedSize = MAX_IMAGE_SIZE * 1024 * 1024;
      if (file?.size > maxAllowedSize) {
        return toast.error(i18next.t('file_size_limit') as string);
      }
      if (!['jpeg', 'jpg', 'png'].includes(currentFileExtension)) {
        return toast.error(i18next.t('upload_file_should_be_image') as string);
      }
      const uploads = await directUpload([file]);
      const data = uploads[0];
      const attachment = {
        url: data.public_url || '',
        content_type: file.type,
      };
      await sleep(2000);
      setCustomCard((options) => ({
        ...options,
        custom_card_image: attachment,
      }));
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, []);

  const handleRemoveImage = useCallback(() => {
    setCustomCard((options) => ({
      ...options,
      custom_card_image: null,
    }));
  }, []);

  const handleLinkSave = useCallback(() => {
    handleSave(AccordionValue.SETTINGS);
  }, [handleSave]);

  return (
    <LinkAccordion
      index={2}
      hideIndex={hideIndex}
      title="Settings (Optional)"
      description="Add description, social media card metadata and UTM parameters"
      currentAccordionValue={accordion}
      itemValue={AccordionValue.SETTINGS}
      setItemValue={setAccordion}
      isValid={
        description.length > 1 ||
        (showCustomCard &&
          !!customCard?.custom_card_title &&
          !!customCard?.custom_card_image)
      }
      buttonCopy={!showSaveButton ? 'Expand' : 'Edit'}
    >
      <VStack gap="2">
        <Fieldset css={{ mb: 0 }}>
          <Flex justify="between" align="end" css={{ mb: 7 }}>
            <HStack align="center">
              <Label css={{ mb: 3 }}>Description</Label>
              <TooltipIconButton
                text="Use description to add context to your short links – for you and your team."
                icon={<HiQuestionMarkCircle />}
                size={1}
                css={{ maxWidth: 300, lineHeight: 1.2 }}
              />
            </HStack>
          </Flex>
          <Box>
            <Input
              placeholder="Enter your description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              maxLength={MAX_DESCRIPTION}
            />
          </Box>
        </Fieldset>
        <Fieldset css={{ mb: 0 }}>
          <Flex justify="between" align="end" css={{ mb: 7 }}>
            <HStack align="center">
              <Label css={{ mb: 3 }}>Custom Social Media Cards</Label>
              <TooltipIconButton
                text="Adding custom social media card metadata will help encourage people to check out your content and inevitably click through to your content."
                icon={<HiQuestionMarkCircle />}
                size={1}
                css={{ width: 300, lineHeight: 1.2 }}
              />
            </HStack>
            <Box css={{ mt: 5 }}>
              <Switch
                checked={showCustomCard}
                onCheckedChange={(checked: boolean) => setShowCustomCard?.(checked)}
              >
                <SwitchThumb />
              </Switch>
            </Box>
          </Flex>
          {showCustomCard && (
            <FormContainer gap="3">
              <UploadImage
                onUpload={handleUpload}
                loading={loading}
                url={customCard?.custom_card_image?.url}
                onRemove={handleRemoveImage}
              />
              <Box>
                <Label>Title</Label>
                <Input
                  placeholder="Social card title"
                  value={customCard.custom_card_title || ''}
                  onChange={(e) =>
                    setCustomCard((options) => ({
                      ...options,
                      custom_card_title: e.target.value,
                    }))
                  }
                  maxLength={MAX_DESCRIPTION}
                />
              </Box>
              <Box>
                <Label>Description</Label>
                <TextAreaField
                  placeholder="Social card description"
                  value={customCard?.custom_card_description || ''}
                  onChange={(e) =>
                    setCustomCard((options: CustomCardOptions) => ({
                      ...options,
                      custom_card_description: e.target.value,
                    }))
                  }
                  maxLength={MAX_DESCRIPTION}
                />
                <Flex justify="end">
                  <Text>{`${
                    customCard?.custom_card_description?.length || 0
                  }/${MAX_DESCRIPTION}`}</Text>
                </Flex>
              </Box>
            </FormContainer>
          )}
        </Fieldset>
        <Divider />
        <Fieldset css={{ mb: 0 }}>
          <Flex justify="between" align="end" css={{ mb: 7 }}>
            <HStack align="center">
              <Label css={{ mb: 3 }}>UTM Builder</Label>
              <TooltipIconButton
                text="Urchin Tracking Module (UTM) parameters are five variants of URL parameters used by marketers to track the effectiveness of online marketing campaigns across traffic sources and publishing media."
                icon={<HiQuestionMarkCircle />}
                size={1}
                css={{ width: 300, lineHeight: 1.2 }}
              />
            </HStack>
            <Box css={{ mt: 5 }}>
              <Switch
                checked={showUtmBuilder}
                onCheckedChange={(checked: boolean) => setShowUtmBuilder?.(checked)}
              >
                <SwitchThumb />
              </Switch>
            </Box>
          </Flex>
          {showUtmBuilder && (
            <UTMBuilderForm utmBuilder={utmBuilder} setUtmBuilder={setUtmBuilder} />
          )}
        </Fieldset>
        <HStack css={{ mt: 15 }}>
          {showSaveButton && (
            <Button
              onClick={handleLinkSave}
              disabled={
                isSubmitting ||
                !isDirty ||
                (showCustomCard &&
                  (!customCard?.custom_card_title || !customCard?.custom_card_image))
              }
              data-testid="save-link-settings-button"
            >
              Save
            </Button>
          )}
          {!disableEdit && (
            <Button
              variant="gray"
              ghost={true}
              onClick={() => setAccordion(AccordionValue.DEFAULT_VALUE)}
            >
              Cancel
            </Button>
          )}
        </HStack>
      </VStack>
    </LinkAccordion>
  );
};

const FormContainer = styled(VStack, {
  p: 20,
  backgroundColor: '#F6F6F6',
  borderRadius: 2,
  width: '100%',
  mb: 10,
});

const TextAreaField = styled(TextArea, {
  width: '100%',
  maxWidth: '100%',
  borderRadius: 2,
  resize: 'vertical',
  padding: '$2',
  lineHeight: 1.5,
  '@lg': {
    minHeight: 80,
  },
  '&:focus': {
    outline: 'none',
  },
});

export default memo(LinkSettings);
