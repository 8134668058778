/* eslint-disable react-hooks/exhaustive-deps */
import dayjs from 'dayjs';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useHistory } from 'react-router-dom';
import { useMedia } from 'react-use';
import { toast } from 'sonner';

import { useCampaignsContext } from '@/campaigns/context/CampaignsContext';
import CampaignCardDetail from '@/campaigns/list/CampaignCardDetail';
import CampaignCardLocation from '@/campaigns/list/CampaignCardLocation';
import CampaignCardStatistic from '@/campaigns/list/CampaignCardStatistic';
import CampaignNavigationButton from '@/campaigns/list/CampaignNavigationButton';
import CampaignStatusBadge from '@/campaigns/list/CampaignStatusBadge';
import CampaignUnscheduleDialog from '@/campaigns/list/CampaignUnscheduleDialog';
import {
  CampaignCardContainer,
  CampaignDescription,
  CampaignTitle,
  Divider,
} from '@/campaigns/list/styled';
import { Campaign, CampaignStatus } from '@/shared/types/campaigns';
import { Flex, HStack } from '@/shared/ui';
import i18next from '@/shared/utils/translation';

type CampaignCardProps = {
  campaign: Campaign;
};

const CampaignCard = ({ campaign }: CampaignCardProps) => {
  const isLargeScreen = useMedia('(min-width: 1490px)');
  const isMobile = useMedia('(max-width: 912px)');

  const history = useHistory();

  const campaigns = useCampaignsContext();
  const { setCurrentCampaign, updateOneCampaign, changeCampaignStatus } = campaigns;

  const isDraft = !(
    campaign.status === CampaignStatus.COMPLETE ||
    campaign.status === CampaignStatus.IN_PROGRESS ||
    campaign.status === CampaignStatus.ARCHIVED ||
    campaign.status === CampaignStatus.PAUSED ||
    campaign.status === CampaignStatus.QUEUED ||
    campaign.status === CampaignStatus.FAILED
  );

  const { campaignContactsV2 } = useFlags();

  const onEditClick = () => {
    // if the campaign is currently scheduled, convert it to draft
    // we ask the user to confirm this action first
    if (campaign.id && campaign.status === CampaignStatus.SCHEDULED) {
      // Convert to draft
      updateOneCampaign(
        campaign.id,
        { ...campaign, status: CampaignStatus.DRAFT },
        false
      );

      // Change status in the frontend
      changeCampaignStatus(campaign.id);

      toast.success(i18next.t('campaign_set_draft') as string);

      // Set current campaign
      setCurrentCampaign({ ...campaign, status: CampaignStatus.DRAFT });
    } else {
      setCurrentCampaign(campaign);
    }

    if ((isDraft || !campaignContactsV2) && campaign.channel_id) {
      history.push(`/campaigns/${campaign.type ?? 'sms'}/${campaign.id}/edit`);
    } else if (isDraft || !campaignContactsV2) {
      history.push(`/campaigns/${campaign.id}`);
    } else {
      history.push(`/campaigns/v2/${campaign.id}/contacts`);
    }
  };

  const onArchiveClick = () => {
    if (campaign.id) {
      updateOneCampaign(
        campaign.id,
        { ...campaign, status: CampaignStatus.ARCHIVED },
        false
      );
    }
  };

  const onCardClick = () => {
    if (campaign.status === CampaignStatus.SCHEDULED) {
      return null;
    } else {
      onEditClick();
    }
  };

  return (
    <CampaignUnscheduleDialog
      onConvertToDraft={onEditClick}
      enabled={campaign.status === CampaignStatus.SCHEDULED}
    >
      <CampaignCardContainer align="center" justify="between">
        <Flex
          direction="column"
          css={{ pr: 44, minWidth: 200, width: '100%', height: '100%' }}
          onClick={onCardClick}
          justify="center"
        >
          <Flex align="center">
            <CampaignTitle>{campaign.title}</CampaignTitle>
            <CampaignStatusBadge
              status={campaign.status}
              scheduleParams={campaign?.schedule_options || null}
            />
          </Flex>
          <CampaignDescription>{getCampaignDescription(campaign)}</CampaignDescription>
        </Flex>
        <Flex css={{ width: '100%', height: '100%' }} justify="end" align="center">
          {!isMobile && (
            <Flex
              align="center"
              justify="between"
              css={{ width: 325, height: '100%' }}
              onClick={onCardClick}
            >
              <CampaignCardDetail
                label="Last Edited By"
                value={campaign.updated_by?.name || 'API'}
              />
              <CampaignCardDetail
                label="Last Edited On"
                value={dayjs(campaign.updated_at).format('MMM D')}
              />
              <CampaignCardLocation campaign={campaign} />
            </Flex>
          )}
          <Flex css={{ height: '100%' }} align="center">
            <Flex onClick={onCardClick} css={{ height: '100%' }} align="center">
              {isLargeScreen && (
                <>
                  <Divider />
                  <Flex justify="between">
                    <CampaignCardStatistic
                      label="Delivered"
                      value={
                        Math.round(
                          Number(campaign?.analytics?.sent_messages || 0) *
                            (Number(campaign?.analytics?.delivery_rate || 0) / 100)
                        ) || '-'
                      }
                      rate={campaign.analytics?.delivery_rate || '-'}
                    />
                    <CampaignCardStatistic
                      label="Responded"
                      value={campaign.analytics?.responses || '-'}
                      rate={campaign.analytics?.response_rate || '-'}
                    />
                    <CampaignCardStatistic
                      label="Unsubscribed"
                      value={campaign.analytics?.unsubscribes || '-'}
                      rate={campaign.analytics?.unsubscribe_rate || '-'}
                    />
                  </Flex>
                </>
              )}
            </Flex>
            <HStack css={{ ml: 44 }}>
              <CampaignNavigationButton
                campaign={campaign}
                onEditClick={onEditClick}
                onShowClick={onEditClick}
                onArchiveClick={onArchiveClick}
              />
            </HStack>
          </Flex>
        </Flex>
      </CampaignCardContainer>
    </CampaignUnscheduleDialog>
  );
};

function getCampaignDescription(campaign: Campaign) {
  if (campaign.type == 'email') {
    return campaign.email_metadata?.subject ?? 'No draft message available';
  } else {
    return campaign.body || 'No draft message available';
  }
}
export default CampaignCard;
