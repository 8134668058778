/* eslint-disable react-hooks/exhaustive-deps */
import dayjs from 'dayjs';
import { debounce } from 'lodash';
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { HiChat, HiCheck, HiMinusSm, HiPencilAlt } from 'react-icons/hi';
import { CellProps, Column } from 'react-table';
import { VirtuosoHandle } from 'react-virtuoso';
import { toast } from 'sonner';

import { ContactPanel } from '@/pages/data/contacts/ContactPanel';
import { useData } from '@/pages/data/context/DataContext';
import { ContactActionsMenu } from '@/pages/data/utils/ContactsTable';
import {
  handleFilterChange,
  handleQuickSearch,
  handleSortUpdate,
} from '@/pages/data/utils/filterActions';
import { ConversationDrawer } from '@/pages/inbox/drawer';
import { useChannels } from '@/pages/settings/organization/channels/context/ChannelContext';
import { useSettings } from '@/pages/settings/organization/general/context/SettingsContext';
import { OptOutOptions } from '@/pages/settings/organization/general/context/types';
import * as SequencesAPI from '@/shared/api/sequences';
import { ToolTipIconButton } from '@/shared/components/attachments/previewer';
import { CombinedFilters } from '@/shared/components/filterBuilder/CombinedFilters';
import { default_contact_object } from '@/shared/components/filterBuilder/objects/contact';
import { sequence_contact_object } from '@/shared/components/filterBuilder/objects/sequence_contact';
import { BulkTagModal } from '@/shared/components/filters/bulkAction/modals/BulkTagModal';
import { UnsubscribeContactsFromCampaignModal } from '@/shared/components/filters/bulkAction/modals/UnsubscribeContactsFromCampaignModal';
import { BulkAction, FilteredTable } from '@/shared/components/filters/FiltersTable';
import { FilterTabs } from '@/shared/components/filters/FilterTabs';
import { prepareFilter } from '@/shared/components/filters/utils';
import { AddToSequenceDialog } from '@/shared/components/modals/AddToSequenceDialog/AddToSequenceDialog';
import { TooltipButton } from '@/shared/components/TooltipButton';
import { SearchFilters } from '@/shared/types/contacts';
import {
  FilterItem,
  FilterType,
  Sort,
  TabFilter,
  TabValueMapping,
} from '@/shared/types/filter';
import {
  SequenceBulkActionFilter,
  SequenceContactStatusType,
  SequenceResponseContact,
  SequenceStep,
} from '@/shared/types/sequences';
import { TagBulkActionType } from '@/shared/types/tags';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogOverlay,
  AlertDialogPortal,
  AlertDialogTitle,
  AlertDialogTrigger,
  Box,
  Button,
  Flex,
  HStack,
  IconButton,
} from '@/shared/ui';
import { formatPhoneNumber } from '@/shared/utils/validations/validations';
import { styled } from '@/stitches.config';

import {
  initialSequencesState,
  ITEMS_COUNT,
  useSequences,
} from '../../context/SequenceContext';
import { sequenceResponsesSortConfig } from './filterConfig';

const noResponsesFound = (
  <Box css={{ textAlign: 'center' }}>
    <Box>No responses found</Box>
  </Box>
);

const sequenceResponsesTabFilters = [
  {
    key: 'total',
    label: 'All Contacts',
    value: {
      filter: [],
      extended_filter: [
        {
          type: 'tab',
          value: 'total',
        },
      ],
    },
  },
];

const formatDate = (value: string) => {
  return dayjs(value).format('ddd D MMM hh:mm A');
};

const showRemoveContact = (status: SequenceContactStatusType) => {
  if (
    status === SequenceContactStatusType.ACTIVE ||
    status === SequenceContactStatusType.PENDING
  ) {
    return true;
  } else {
    return false;
  }
};

export const defaultSort: Array<Sort> = [
  {
    label: 'Start Time',
    column: 'inserted_at',
    order: 'desc',
    resource: 'step_contact',
    id: null,
  },
];

export const SequenceResponses = () => {
  const location = useChannels();
  const { channelsState } = location;
  const { channels } = channelsState;
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [selectedSequenceRunIds, setSelectedSequenceRunIds] = useState([] as string[]);
  const [totalContactsSelected, setTotalContactsSelected] = useState(0);
  const [quickSearchValue, setQuickSearchValue] = useState('');
  const [selectedTabFilter, setSelectedTabFilter] = useState<TabFilter>(
    sequenceResponsesTabFilters[0]
  );
  const [isAddToSequenceModalOpen, setAddToSequenceModalOpen] = useState(false);
  const [isBulkTagModalOpen, setBulkTagModalOpen] = useState(false);
  const [isUnsubscribeContactsModalOpen, setUnsubscribeContactsModalOpen] =
    useState(false);
  const [activeFilters, setActiveFilters] = useState<FilterType[]>([]);
  const [tagModalAction, setTagModalAction] = useState<TagBulkActionType>('unassign');

  const sequenceContext = useSequences();
  const { sequencesState, removeContactsFromSequence, updateSequenceResponsesParams } =
    sequenceContext;

  const { current, loadingResponses, sequenceResponsesParams } = sequencesState;
  const tableRef = useRef<VirtuosoHandle>(null);
  const { settingsState } = useSettings();
  const { settings } = settingsState;

  const contacts = useData();
  const { blockContact, deleteContact } = contacts;

  const contactTabs = current?.contact_tabs || {
    total: 0,
  };

  const tabValueMappings: TabValueMapping = {
    total: () => contactTabs.total,
  };

  const data = useMemo(
    () => sequencesState.sequenceResponses,
    [sequencesState.sequenceResponses]
  ) as SequenceResponseContact[] | [];

  const handleOnEndReached = () => {
    if (data.length < sequencesState.totalSequenceResponses && !loadingResponses) {
      updateSequenceResponsesParams({
        ...sequenceResponsesParams,
        offset: sequenceResponsesParams.offset + ITEMS_COUNT,
      });
    }
  };

  const getStepColumns = () => {
    if (current && current?.steps) {
      return current?.steps.map((step: SequenceStep) => {
        const position = step.position ?? 0;
        return {
          Header: `Step ${position + 1}`,
          tooltipText: step.title || null,
          colWidth: 200,
          accessor: (row: any) => getBodyForStep(row, step.id ?? '-'),
          Cell: (row: { value: string }) => (
            <Cell css={{ width: 180, paddingRight: 5 }}>{row.value || '-'}</Cell>
          ),
        };
      });
    }
    return [];
  };

  const getBodyForStep = (data: SequenceResponseContact, stepId: string) => {
    // Find the step_contact with the matching step_id
    const stepContact = data.step_contacts.find((sc) => sc.step_id === stepId);

    // If found and has at least one message, return its body. Else, return undefined
    return stepContact && stepContact.contact_messages[0]?.body;
  };

  const stepColumns = getStepColumns();

  interface IndeterminateCheckboxProps
    extends React.InputHTMLAttributes<HTMLInputElement> {
    indeterminate?: boolean;
  }

  const IndeterminateCheckbox = forwardRef<HTMLInputElement, IndeterminateCheckboxProps>(
    ({ indeterminate, ...rest }, ref) => {
      const defaultRef = useRef<HTMLInputElement>(null);
      // Use the passed ref if it exists; otherwise, use the default ref
      const resolvedRef = ref ?? defaultRef;

      useEffect(() => {
        // Type guard to check if resolvedRef is a RefObject
        if (resolvedRef && typeof resolvedRef !== 'function' && resolvedRef.current) {
          resolvedRef.current.indeterminate = !!indeterminate;
        }
      }, [resolvedRef, indeterminate]);

      return (
        <>
          <CustomCheckbox checked={rest.checked}>
            {rest.checked ? (
              <HiCheck style={{ position: 'absolute', color: 'white' }} size={12} />
            ) : null}
            {indeterminate ? (
              <HiMinusSm style={{ position: 'absolute', color: '#1d2124' }} size={12} />
            ) : null}
            <HiddenCheckbox type="checkbox" ref={resolvedRef} {...rest} />
          </CustomCheckbox>
        </>
      );
    }
  );

  const bulkActions: BulkAction[] = [
    {
      label: 'Add to Sequence',
      action: 'addToSequenceV2',
      title: 'Add to Sequence',
      getConfirmationMessage: (selected: number) =>
        `Are you sure you want to add **${selected} contact(s)** to an existing sequence?`,
      onManualSelectedSubmit: (selectedSequenceRunIds: string[]) => {
        setAddToSequenceModalOpen(true);
        setSelectedSequenceRunIds(selectedSequenceRunIds);
        setIsAllSelected(false);
        setTotalContactsSelected(selectedSequenceRunIds.length);
      },
      onAllSelectedSubmit: () => {
        setAddToSequenceModalOpen(true);
        setIsAllSelected(true);
        setTotalContactsSelected(sequencesState.totalSequenceResponses);
      },
    },
    {
      action: 'sequences.contacts.remove',
      label: 'Remove from Sequence',
      title: 'Remove from Sequence',
      type: 'destructive',
      getConfirmationMessage: (selected: number) =>
        `Are you sure you want to remove **${selected} contact(s)**?`,
      onManualSelectedSubmit: async (selectedSequenceRunIds: string[]) => {
        if (current && current.id) {
          const filter = combineFilter(
            selectedSequenceRunIds,
            sequenceResponsesParams,
            false
          );

          try {
            await SequencesAPI.sequenceBulkActions(
              current.id,
              'sequences.contacts.remove',
              filter,
              ['sequence', current.id]
            );
            toast.success('Contact(s) removed from sequence.');
          } catch (err) {
            console.error(err);
            toast.error('Something went wrong.');
          }
        }
      },
      onAllSelectedSubmit: async () => {
        if (current && current.id) {
          const filter = combineFilter([], sequenceResponsesParams, true);
          try {
            await SequencesAPI.sequenceBulkActions(
              current.id,
              'sequences.contacts.remove',
              filter,
              ['sequence', current.id]
            );
            toast.success('Contact(s) removed from sequence.');
          } catch (err) {
            console.error(err);
            toast.error('Something went wrong.');
          }
        }
      },
    },
    {
      label: 'Assign Tags',
      action: 'assignTagsV2',
      title: 'Assign Tags',
      getConfirmationMessage: (selected: number) => {
        setTagModalAction('assign');
        return `Are you sure you want to assign tags from **${selected} contact(s)**`;
      },
      onManualSelectedSubmit: (selectedSequenceRunIds: string[]) => {
        setBulkTagModalOpen(true);
        setSelectedSequenceRunIds(selectedSequenceRunIds);
        setIsAllSelected(false);
        setTotalContactsSelected(selectedSequenceRunIds.length);
      },
      onAllSelectedSubmit: () => {
        setBulkTagModalOpen(true);
        setIsAllSelected(true);
        setTotalContactsSelected(sequencesState.totalSequenceResponses);
      },
    },
    {
      label: 'Overwrite Tags',
      action: 'overwriteTagsV2',
      title: 'Overwrite Tags',
      type: 'destructive',
      getConfirmationMessage: (selected: number) => {
        setTagModalAction('assign.overwrite');
        return `Are you sure you want to overwrite tags from **${selected} contact(s)**`;
      },
      onManualSelectedSubmit: (selectedSequenceRunIds: string[]) => {
        setBulkTagModalOpen(true);
        setSelectedSequenceRunIds(selectedSequenceRunIds);
        setIsAllSelected(false);
        setTotalContactsSelected(selectedSequenceRunIds.length);
      },
      onAllSelectedSubmit: () => {
        setBulkTagModalOpen(true);
        setIsAllSelected(true);
        setTotalContactsSelected(sequencesState.totalSequenceResponses);
      },
    },
    {
      label: 'Un-assign Tags',
      action: 'unassignTagV2',
      title: 'Un-assign Tags',
      type: 'destructive',
      getConfirmationMessage: (selected: number) => {
        setTagModalAction('unassign');
        return `Are you sure you want to unassign tags from **${selected} contact(s)**`;
      },
      onManualSelectedSubmit: (selectedSequenceRunIds: string[]) => {
        setBulkTagModalOpen(true);
        setSelectedSequenceRunIds(selectedSequenceRunIds);
        setIsAllSelected(false);
        setTotalContactsSelected(selectedSequenceRunIds.length);
      },
      onAllSelectedSubmit: () => {
        setBulkTagModalOpen(true);
        setIsAllSelected(true);
        setTotalContactsSelected(sequencesState.totalSequenceResponses);
      },
    },
    {
      action: 'unsubscribeFromCampaignV2',
      label: 'Un-subscribe',
      title: 'Un-subscribe Contacts from Campaign',
      type: 'destructive',
      getConfirmationMessage: (selected: number) =>
        `Are you sure you want to unsubscribe **${selected} contact(s)** from an existing Campaign?`,
      onAllSelectedSubmit: () => {
        if (settings.contact_opt_out_default == OptOutOptions.LOCATION)
          setUnsubscribeContactsModalOpen(true);
        setIsAllSelected(true);
        setTotalContactsSelected(sequencesState.totalSequenceResponses);
      },
      onManualSelectedSubmit(selectedSequenceRunIds: string[]) {
        if (settings.contact_opt_out_default == OptOutOptions.LOCATION)
          setUnsubscribeContactsModalOpen(true);
        setIsAllSelected(false);
        setSelectedSequenceRunIds(selectedSequenceRunIds);
        setTotalContactsSelected(selectedSequenceRunIds.length);
      },
    },
  ];

  const columns: Column<SequenceResponseContact>[] = useMemo(
    () => [
      {
        id: 'selection',
        Header: ({ getToggleAllRowsSelectedProps }) => (
          <Flex align="center" justify="start">
            {/* Checkbox for selecting all rows */}
            <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            <span style={{ marginLeft: '8px' }}>Name</span>
          </Flex>
        ),
        accessor: (row: any) => ({
          contact: row.contact,
          name: row.contact.name,
          id: row.contact.id,
          phone: row.contact.phone,
          location_id: row.location_id,
          status: row.step_contacts[row?.step_contacts?.length - 1]?.status,
        }),
        colWidth: 360,
        colMinWidth: 360,
        Cell: ({ row, value }: any) => (
          <Cell css={{ width: '100%' }}>
            <Flex gap={2} align="center" justify="between" css={{ width: '100%' }}>
              <Flex align="center">
                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
                <Box css={{ ...ellipsis, width: 150, ml: 8 }}>{value?.name || '-'}</Box>
              </Flex>
              <HStack css={{ minWidth: 115, justifyContent: 'flex-end' }}>
                {showRemoveContact(value.status) ? (
                  <AlertDialog>
                    <TooltipButton text="Remove Contact" sideOffset={5}>
                      <AlertDialogTrigger>
                        <IconButton size="2">
                          <HiCheck />
                        </IconButton>
                      </AlertDialogTrigger>
                    </TooltipButton>
                    <AlertDialogPortal>
                      <AlertDialogOverlay>
                        <AlertDialogContent>
                          <AlertDialogTitle>Remove Contact</AlertDialogTitle>
                          <AlertDialogDescription>
                            Are you sure you want to remove this contact from the
                            sequence?
                          </AlertDialogDescription>
                          <Flex justify="end">
                            <HStack>
                              <AlertDialogCancel>
                                <Button variant="gray">Cancel</Button>
                              </AlertDialogCancel>
                              <AlertDialogAction
                                onClick={() => {
                                  removeContactsFromSequence(
                                    [value.contact?.id] || [],
                                    current?.id || ''
                                  );
                                }}
                              >
                                <Button variant="red">Remove Contact</Button>
                              </AlertDialogAction>
                            </HStack>
                          </Flex>
                        </AlertDialogContent>
                      </AlertDialogOverlay>
                    </AlertDialogPortal>
                  </AlertDialog>
                ) : null}
                {value.location_id && (
                  <ConversationDrawer
                    contact_id={value.id || ''}
                    contact_name={value.name || ''}
                    contact_phone={value.phone || ''}
                    location_id={value.location_id || ''}
                  >
                    <IconButton size="2">
                      <HiChat />
                    </IconButton>
                  </ConversationDrawer>
                )}
                <ContactPanel contact={value.contact} deleteContact={deleteContact}>
                  <ToolTipIconButton
                    description="Edit Contact r"
                    icon={<HiPencilAlt />}
                    size={1}
                  />
                </ContactPanel>
                <Box>
                  <ContactActionsMenu
                    contact={value.contact}
                    isBlocked={value.contact?.blocked}
                    blockContact={blockContact}
                    deleteContact={deleteContact}
                  />
                </Box>
              </HStack>
            </Flex>
          </Cell>
        ),
      },
      {
        Header: 'Phone',
        accessor: 'contact',
        colWidth: 150,
        Cell: ({ row }: CellProps<SequenceResponseContact>) => (
          <Cell css={{ width: 130 }}>
            {formatPhoneNumber(row.original.contact.phone)}
          </Cell>
        ),
      },
      ...stepColumns,
      {
        Header: 'Start Time',
        accessor: 'inserted_at',
        colWidth: 160,
        Cell: ({ row }: CellProps<SequenceResponseContact>) => (
          <Cell css={{ width: 140 }}>{formatDate(row.original.inserted_at)}</Cell>
        ),
      },
    ],
    [data]
  );

  const debouncedUpdate = useCallback(
    debounce((props: SearchFilters) => {
      updateSequenceResponsesParams(props);
    }, 1000),
    []
  );

  useEffect(() => {
    resetFilters();
  }, [current?.id]);

  const resetFilters = () => {
    setQuickSearchValue('');
    setActiveFilters([]);
    updateSequenceResponsesParams({
      ...initialSequencesState.sequenceResponsesParams,
      sort: defaultSort,
    });
  };

  return (
    <Box css={{ backgroundColor: 'white', height: '100%', overflowY: 'hidden' }}>
      <FilterTabs
        tabs={sequenceResponsesTabFilters}
        activeTab={selectedTabFilter}
        onTabChange={(value) => {
          setSelectedTabFilter(value);
          debouncedUpdate({
            ...sequenceResponsesParams,
            defaultFilters: value.value.filter,
            extended_filter: value.value.extended_filter,
            offset: 0,
          });
        }}
        tabValueMappings={tabValueMappings}
      />
      <Box css={{ padding: 24 }}>
        <CombinedFilters
          quickSearchPlaceholder="Search Contacts"
          quickSearchValue={quickSearchValue}
          setQuickSearchValue={(value: string) => {
            setQuickSearchValue(value);
            handleQuickSearch(debouncedUpdate, sequenceResponsesParams, value);
          }}
          customObjects={[]}
          defaultObjects={[default_contact_object, sequence_contact_object]}
          activeFilters={activeFilters}
          setFilters={(value: Array<FilterType>) => {
            setActiveFilters(value);
            handleFilterChange(debouncedUpdate, sequenceResponsesParams, value);
          }}
          sortConfig={sequenceResponsesSortConfig}
          activeSort={sequenceResponsesParams.sort}
          onSortUpdate={(value: Array<Sort>) =>
            handleSortUpdate(debouncedUpdate, sequenceResponsesParams, value)
          }
        />
      </Box>
      <FilteredTable
        isLoading={loadingResponses}
        columns={columns}
        data={data}
        totalCount={sequencesState.totalSequenceResponses}
        onEndReached={() => handleOnEndReached()}
        bulkActions={bulkActions}
        emptyStateElement={noResponsesFound}
        tableRef={tableRef}
        heightOffset={325}
      />
      {current && current.id && (
        <>
          <AddToSequenceDialog
            isOpen={isAddToSequenceModalOpen}
            showTimeZonePicker={false}
            locations={channels}
            sequence={current}
            totalContacts={totalContactsSelected}
            setIsOpen={setAddToSequenceModalOpen}
            filterSource={['sequence', current.id]}
            filter={combineFilter(
              selectedSequenceRunIds,
              sequenceResponsesParams,
              isAllSelected
            )}
          />
          <BulkTagModal
            action={tagModalAction}
            filterSource={['sequence', current.id]}
            open={isBulkTagModalOpen}
            totalContacts={totalContactsSelected}
            onOpenChange={setBulkTagModalOpen}
            filter={
              combineFilter(
                selectedSequenceRunIds,
                sequenceResponsesParams,
                isAllSelected
              ).filter as FilterItem[]
            }
          />
          <UnsubscribeContactsFromCampaignModal
            onOpenChange={setUnsubscribeContactsModalOpen}
            open={isUnsubscribeContactsModalOpen}
            totalContacts={totalContactsSelected}
            filterSource={['sequence', current.id]}
            filter={
              combineFilter(
                selectedSequenceRunIds,
                sequenceResponsesParams,
                isAllSelected
              ).filter as FilterItem[]
            }
          />
        </>
      )}
    </Box>
  );
};

function combineFilter(
  contactIds: string[],
  searchParam: SearchFilters,
  isAllSelected: boolean
): SequenceBulkActionFilter {
  const filter = prepareFilter(searchParam, contactIds, isAllSelected, 'sequence_run');
  return { filter: filter };
}

export default SequenceResponses;

const ellipsis = {
  display: 'block',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

const Cell = styled(Flex, {
  width: 120,
  alignItems: 'center',
  justifyContent: 'center',
  display: '-webkit-box',
  '-webkit-line-clamp': 1,
  '-webkit-box-orient': 'vertical',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  textAlign: 'left',
  borderBottom: '1px solid $gray300',
});

const CustomCheckbox = styled('label', {
  height: 14,
  width: 14,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  border: '1px solid $slate7',
  borderRadius: 4,
  variants: {
    checked: {
      true: {
        borderColor: '#3f54cf',
        backgroundColor: '#3f54cf',
      },
    },
  },
});

const HiddenCheckbox = styled('input', {
  opacity: 0,
});
