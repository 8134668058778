import React, { memo, ReactNode, useCallback } from 'react';

import { useDisclosure } from '@/shared/hooks';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogOverlay,
  AlertDialogPortal,
  AlertDialogTitle,
  AlertDialogTrigger,
  Button,
  Flex,
} from '@/shared/ui';

type ButtonVariant = 'primary' | 'gray' | 'redBackground' | 'grayBackground';

type ConfirmationDialogProps = {
  /** Dialog Title */
  title: string;
  /** Dialog Description */
  description?: JSX.Element | string;
  /** Title of the Cancel button */
  cancelButtonTitle?: string;
  /** Title of the Confirm button */
  confirmButtonTitle?: string;
  cancelButtonVariant?: ButtonVariant;
  confirmButtonVariant?: ButtonVariant;
  /** JSX element that is going to be the dialog trigger */
  children?: ReactNode;
  /** function that is executed on confirmation */
  onConfirm?: () => void;
  /** trigger testID */
  testID?: string;
  width?: string;
};

export const ConfirmationDialog = memo(
  ({
    title,
    description,
    cancelButtonTitle,
    confirmButtonTitle,
    confirmButtonVariant,
    cancelButtonVariant,
    children,
    onConfirm,
    testID,
    width,
  }: ConfirmationDialogProps) => {
    const { isOpen, onOpen, onClose } = useDisclosure();

    const handleConfirm = useCallback(() => {
      onConfirm?.();
      onClose();
    }, [onConfirm]);

    return (
      <AlertDialog open={isOpen}>
        <AlertDialogTrigger onClick={onOpen} asChild data-testid={testID}>
          {children}
        </AlertDialogTrigger>
        <AlertDialogPortal>
          <AlertDialogOverlay css={{ zIndex: 999999 }}>
            <AlertDialogContent
              onEscapeKeyDown={onClose}
              css={{ zIndex: 999999, width: width ?? '500px' }}
            >
              <AlertDialogTitle>{title}</AlertDialogTitle>
              {description && (
                <AlertDialogDescription>{description}</AlertDialogDescription>
              )}
              <Flex justify="end">
                <AlertDialogCancel asChild>
                  <Button
                    variant={cancelButtonVariant ? cancelButtonVariant : 'gray'}
                    css={{ mr: 10 }}
                    onClick={onClose}
                  >
                    {cancelButtonTitle || 'No'}
                  </Button>
                </AlertDialogCancel>
                <AlertDialogAction asChild>
                  <Button
                    data-testid="confirm-button"
                    variant={confirmButtonVariant ? confirmButtonVariant : 'red'}
                    onClick={handleConfirm}
                  >
                    {confirmButtonTitle || 'Yes'}
                  </Button>
                </AlertDialogAction>
              </Flex>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialogPortal>
      </AlertDialog>
    );
  }
);
