import { User, UsersAction, UsersActionTypes, UserStates } from '@/shared/types/users';

import { UsersState } from './UserContext';

const UserReducer: React.Reducer<UsersState, UsersAction> = (
  state: UsersState,
  action: UsersAction
) => {
  switch (action.type) {
    case UsersActionTypes.GET_USERS:
      return {
        ...state,
        users: action.payload,
        loading: false,
      };
    case UsersActionTypes.GET_INVITES:
      return {
        ...state,
        invites: action.payload.data,
        totalCount: action.payload.total,
        loading: false,
      };
    case UsersActionTypes.DELETE_INVITE:
      return {
        ...state,
        invites: state.invites.filter((invite) => invite.email !== action.payload),
        totalCount: state.totalCount - 1,
        loading: false,
      };
    case UsersActionTypes.ADD_INVITES:
      return {
        ...state,
        invites: [...action.payload, ...state.invites],
      };
    case UsersActionTypes.UPDATE_USER:
      return {
        ...state,
        users: state.users.map((user: User) =>
          user.id === action.payload.id ? action.payload : user
        ),
        loading: false,
      };
    case UsersActionTypes.ARCHIVE_USER:
      return {
        ...state,
        users: state.users.map((upload: User) => {
          return upload.id === action.payload.id
            ? { ...upload, state: UserStates.ARCHIVED }
            : upload;
        }),
        loading: false,
      };
    case UsersActionTypes.DISABLE_USER:
      return {
        ...state,
        users: state.users.map((upload: User) => {
          return upload.id === action.payload.id
            ? { ...action.payload, state: UserStates.DISABLED }
            : upload;
        }),
      };
    case UsersActionTypes.ENABLE_USER:
      return {
        ...state,
        users: state.users.map((upload: User) => {
          return upload.id === action.payload.id
            ? { ...action.payload, state: UserStates.ENABLED }
            : upload;
        }),
      };
    case UsersActionTypes.SET_CURRENT:
      return {
        ...state,
        current: action.payload,
      };
    case UsersActionTypes.UPDATE_FILTER_PARAMS:
      return {
        ...state,
        filterParams: action.payload,
        loading: true,
      };
    case UsersActionTypes.UPDATE_INVITES_FILTER_PARAMS:
      return {
        ...state,
        invitesFilterParams: action.payload,
        loading: true,
      };
    case UsersActionTypes.SEARCH_USERS:
      return {
        ...state,
        filteredUsers: action.payload.data,
        totalCount: action.payload.total,
        loading: false,
      };
    case UsersActionTypes.GET_ALL_PERMISSIONS:
      return {
        ...state,
        allPermissions: action.payload,
      };
    case UsersActionTypes.GET_USER_PERMISSIONS:
      // by type its allow null so if no current.id return state
      if (!state.current?.id) return state;
      return {
        ...state,
        current: { ...state.current, permissions: action.payload },
        loadingPermissions: false,
      };
    case UsersActionTypes.UPDATE_USER_PERMISSIONS:
      // by type its allow null so if no current.id return state
      if (!state.current?.id) return state;
      return {
        ...state,
        current: { ...state.current, permissions: action.payload },
      };
    case UsersActionTypes.SET_LOADING_PERMISSIONS:
      return {
        ...state,
        loadingPermissions: action.payload,
      };
    default:
      return state;
  }
};

export default UserReducer;
