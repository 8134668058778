import _ from 'lodash';

import { CustomPlaceholderProps } from './types';

/** A function to render the selected option in a Select as the combobox placeholder */
export const displaySelectedItem = ({
  formik,
  props,
  setPlaceholder,
}: CustomPlaceholderProps): void => {
  const value = _.get(formik.values, props.name as string) || '';

  const selectedItem = props.options.find((item) => item.value === value);

  if (selectedItem) {
    return setPlaceholder(selectedItem.type);
  } else {
    return setPlaceholder('');
  }
};

/** A function to render the selected options in a MultiSelect as the combobox placeholder */
export const displaySelectedItems = ({
  formik,
  props,
  setPlaceholder,
}: CustomPlaceholderProps): void => {
  const value = _.get(formik.values, props.name as string) || [];

  const firstSelectedItem = props.options.find((item) => item?.value === value[0]);

  if (firstSelectedItem) {
    if (value.length === 1) {
      return setPlaceholder(firstSelectedItem.type);
    } else {
      return setPlaceholder(`${firstSelectedItem.type} & ${value.length - 1} more`);
    }
  } else {
    return setPlaceholder('');
  }
};

export const helpers = {
  displaySelectedItem,
  displaySelectedItems,
};
