import { debounce } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { searchCalls } from '@/shared/api/calls';
import { CombinedFilters } from '@/shared/components/filterBuilder/CombinedFilters';
import { areFiltersValid } from '@/shared/components/filterBuilder/utils/areValidFilters';
import { cleanFilters } from '@/shared/components/filterBuilder/utils/cleanFilters';
import { Call } from '@/shared/types/calls';
import { FilterType, Sort } from '@/shared/types/filter';
import { Box, Flex } from '@/shared/ui';

import { CallsTable } from '../../calls/CallsTable';
import { default_call_object } from '../../calls/config/filterConfig';
import { sortConfig } from '../../calls/config/sortConfig';

export const AgentVersionCalls = () => {
  const [calls, setCalls] = useState<{ data: Call[]; total: number }>({
    data: [],
    total: 0,
  });
  const [loading, setLoading] = useState(true);

  const [quickSearchValue, setQuickSearchValue] = useState('');
  const [activeFilters, setActiveFilters] = useState<FilterType[]>([]);
  const [activeSort, setActiveSort] = useState<Sort[]>([
    {
      label: 'Updated At',
      column: 'updated_at',
      resource: 'message_attachment',
      order: 'desc',
      id: 1,
    },
  ]);
  const [offset, setOffset] = useState(0);
  const limit = 10;

  const { id } = useParams<{ id: string }>();
  const history = useHistory();

  useEffect(() => {
    resetFilters();
  }, []);

  const resetFilters = () => {
    setQuickSearchValue('');
    setActiveFilters([]);
    fetchCalls([], activeSort, limit, offset);
  };

  const fetchCalls = async (
    filter: Array<FilterType>,
    sort: Array<Sort>,
    limit: number,
    offset: number
  ) => {
    try {
      setLoading(true);
      const agentFilter = {
        resource: 'agent_version',
        column: 'agent_id',
        comparison: '==',
        value: id,
      };
      const data = await searchCalls([agentFilter, ...filter], sort, limit, offset);
      setCalls(data);
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const updateFilters = useCallback((filter: Array<FilterType>) => {
    setActiveFilters(filter);
  }, []);

  const debouncedFetchCalls = useCallback(
    debounce(async (filter: Array<FilterType>) => {
      await fetchCalls(cleanFilters(filter), [], limit, offset);
    }, 500),
    []
  );

  const handleFilterChange = useCallback(
    (filter: Array<FilterType>) => {
      updateFilters(filter);
      if (areFiltersValid(cleanFilters(filter))) {
        debouncedFetchCalls(filter);
      }
    },
    [updateFilters, debouncedFetchCalls]
  );

  const handleSortChange = useCallback((sort: Array<Sort>) => {
    setActiveSort(sort);
    fetchCalls(cleanFilters(activeFilters), sort, limit, offset);
  }, []);

  const handleOffsetChange = useCallback((newOffset: number) => {
    setOffset(newOffset);
    fetchCalls(cleanFilters(activeFilters), activeSort, limit, newOffset);
  }, []);

  const handleQuickSearchChange = useCallback((value: string) => {
    setQuickSearchValue(value);
    const filter = {
      resource: 'contact',
      column: 'name',
      comparison: 'ilike',
      value: `%${value}%`,
    };
    fetchCalls(cleanFilters([filter]), activeSort, limit, offset);
  }, []);

  const handleCallClick = (callId: string) => {
    history.push(`/ai/agents/${id}/calls/${callId}`);
  };

  return (
    <Flex direction="column" css={{ flex: '1 1 auto', p: 30 }}>
      <Box css={{ paddingBottom: '$space$3' }}>
        <CombinedFilters
          quickSearchPlaceholder="Search Calls"
          quickSearchValue={quickSearchValue}
          setQuickSearchValue={handleQuickSearchChange}
          defaultObjects={[default_call_object]}
          customObjects={[]}
          activeFilters={activeFilters}
          setFilters={handleFilterChange}
          sortConfig={sortConfig}
          activeSort={activeSort}
          onSortUpdate={handleSortChange}
        />
      </Box>
      <CallsTable
        data={calls.data}
        loading={loading}
        totalCount={calls.total}
        onCardClick={handleCallClick}
        setOffset={handleOffsetChange}
      />
    </Flex>
  );
};
