import { useFlags } from 'launchdarkly-react-client-sdk';
import React from 'react';
import {
  HiClock,
  HiInbox,
  HiPaperClip,
  HiUserCircle,
  HiUserRemove,
} from 'react-icons/hi';
import { useLocation, useRouteMatch } from 'react-router-dom';
import { useMedia } from 'react-use';

import { useSidenavigationSettings } from '@/shared/components/navigation/context/SideNavigationContext';
import {
  SideNavigation,
  sideNavigationGroup,
} from '@/shared/components/navigation/SideNavigation';
import {
  SidebarNavigationContainer,
  SidebarNavigationWrapper,
} from '@/shared/components/navigation/SideNavigationContainer';
import { LayoutContent, MainLayout } from '@/shared/layouts';

import { MatchParams } from '../context/ConversationContext';
import { InboxSideNavigation } from './InboxSideNavigation';

export function InboxNavigation(): JSX.Element {
  const location = useLocation();
  const { navigationSettings, toggleExpandedState } = useSidenavigationSettings();

  const isScheduledInbox = /\/scheduled(\/|$)/.test(location.pathname);
  const isDraftInbox = /\/drafts(\/|$)/.test(location.pathname);
  const isAttachmentsInbox = /\/attachments(\/|$)/.test(location.pathname);
  const isAllInbox =
    /\/all(\/|$)/.test(location.pathname) &&
    !isScheduledInbox &&
    !isAttachmentsInbox &&
    !isDraftInbox;
  const isMyInbox =
    /\/me(\/|$)/.test(location.pathname) &&
    !isScheduledInbox &&
    !isAttachmentsInbox &&
    !isDraftInbox;
  const isUnassignedInbox =
    /\/unassigned\//.test(location.pathname) &&
    !isScheduledInbox &&
    !isAttachmentsInbox &&
    !isDraftInbox;
  const isGhostedInbox =
    /\/ghosted\//.test(location.pathname) &&
    !isScheduledInbox &&
    !isAttachmentsInbox &&
    !isDraftInbox;
  // const isUnresponsiveInbox =
  //   /\/unresponsive\//.test(location.pathname) &&
  //   !isScheduledInbox &&
  //   !isAttachmentsInbox &&
  //   !isDraftInbox;
  const match = useRouteMatch<MatchParams>('/inbox/:filter/:tab');

  const { showUnrespondedTab } = useFlags();

  const sideNavigationTabs: Array<sideNavigationGroup> = [
    {
      sideNavigationGroup: [
        {
          title: 'All Conversations',
          image: <HiInbox size={18} />,
          isActive: isAllInbox,
          link: `/inbox/all/${
            match?.params.tab !== 'spam' ? match?.params.tab : 'open' || 'open'
          }`,
        },
        {
          title: 'Assigned to me',
          image: <HiUserCircle size={18} />,
          isActive: isMyInbox,
          link: `/inbox/me/${
            match?.params.tab !== 'spam' ? match?.params.tab : 'open' || 'open'
          }`,
        },
        {
          title: 'Unassigned',
          image: <HiUserRemove size={18} />,
          isActive: isUnassignedInbox,
          link: `/inbox/unassigned/${
            match?.params.tab !== 'spam' ? match?.params.tab : 'open' || 'open'
          }`,
        },
        showUnrespondedTab && {
          title: 'Ghosted',
          image: '👻',
          isActive: isGhostedInbox,
          link: `/inbox/ghosted/${
            match?.params.tab !== 'spam' ? match?.params.tab : 'open' || 'open'
          }`,
        },
        // showUnrespondedTab && {
        //   title: 'Unresponsive',
        //   image: <HiDocumentRemove size={18} />,
        //   isActive: isUnresponsiveInbox,
        //   link: `/inbox/unresponsive/${
        //     match?.params.tab !== 'spam' ? match?.params.tab : 'open' || 'open'
        //   }`,
        // },
        {
          title: 'Scheduled',
          image: <HiClock size={18} />,
          isActive: isScheduledInbox,
          link: `/inbox/${match?.params.filter}/${
            match?.params.tab !== 'spam' ? match?.params.tab : 'open'
          }/scheduled`,
        },
        {
          title: 'Attachments',
          image: <HiPaperClip size={18} />,
          isActive: isAttachmentsInbox,
          link: `/inbox/${match?.params.filter}/${
            match?.params.tab !== 'spam' ? match?.params.tab : 'open'
          }/attachments`,
        },
      ].filter(Boolean), // Filter out false values
    },
  ];

  return (
    <SidebarNavigationContainer>
      <SidebarNavigationWrapper>
        <SideNavigation
          expanded={navigationSettings.expanded}
          header="Inbox"
          sideNavigationTabs={sideNavigationTabs}
          toggleExpandedState={toggleExpandedState}
        >
          <InboxSideNavigation />
        </SideNavigation>
      </SidebarNavigationWrapper>
    </SidebarNavigationContainer>
  );
}

export const InboxLayoutContainer = ({ children }: { children: React.ReactNode }) => {
  const isDesktop = useMedia('(min-width: 912px)');
  return (
    <MainLayout>
      {isDesktop && <InboxNavigation />}
      <LayoutContent>{children}</LayoutContent>
    </MainLayout>
  );
};
