export const CREATE_QUESTION = 'CREATE_QUESTION';
export const GET_QUESTIONS = 'GET_QUESTIONS';
export const SEARCH_QUESTION = 'SEARCH_QUESTION';
export const SEARCH_ANSWER = 'SEARCH_ANSWER';
export const EDIT_QUESTION = 'EDIT_QUESTION';
export const DELETE_QUESTION = 'DELETE_QUESTION';
export const ENABLE_QUESTION = 'ENABLE_QUESTION';
export const DISABLE_QUESTION = 'DISABLE_QUESTION';
export const SET_LOADING = 'SET_LOADING';
export const SET_CURRENT = 'SET_CURRENT';
export const SET_COUNT = 'SET_COUNT';
