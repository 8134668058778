import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';

import { TokensType } from '@/pages/auth/context/AuthProvider';

import { API_BASE_URL } from '../utils/config';
import { getAuthTokens, getOrganizationId, setAuthTokens } from '../utils/storage';
import { isValidUuid } from '../utils/validations/validations';
import { renew } from './auth';

/*
  Get access tokens from local storage
*/

export const getAccessToken = () => {
  const tokens = getAuthTokens();

  return tokens && tokens.access_token;
};

/*
  Tries to load organizationId from session storage.
  If it can't be found in the storage, loads the
  organization ID from the tokens.
*/

export const findOrganizationId = () => {
  const organizationId = getOrganizationId();

  // If the organization ID is a valid UUID, return it.
  if (isValidUuid(organizationId)) {
    return organizationId;
  }

  // If the organization ID is not a valid UUID, try to load it from the tokens.
  const tokens = getAuthTokens();

  // If the tokens are available, return the organization ID from the tokens.
  return tokens && tokens?.organization_id;
};

/*
  Declare custom axios config
*/

export const api = axios.create({
  baseURL: `${API_BASE_URL}/api`,
});

type CustomPromise<T> = Promise<T> & { cancel: () => void };

export const customInstance = <T>(config: AxiosRequestConfig): CustomPromise<T> => {
  const source = axios.CancelToken.source();
  const promise = api({ ...config, cancelToken: source.token }).then(
    ({ data }) => data
  ) as CustomPromise<T>;

  promise.cancel = () => {
    source.cancel('Query was cancelled');
  };

  return promise;
};

/*
  Request interceptor to add token to request headers
*/

api.interceptors.request.use(
  async (config) => {
    const token = getAccessToken();
    const organizationId = findOrganizationId();

    if (token) {
      config.headers.set('authorization', `${token}`);
      config.headers.set('organization_id', `${organizationId}`);
    }

    return config;
  },
  (error) => Promise.reject(error)
);

/*
  Refresh the auth tokens and update the local storage.
*/

const refreshAndSetToken = async () => {
  // Add a random delay between 0 and 500 milliseconds
  await new Promise((resolve) => setTimeout(resolve, Math.random() * 500));

  const tokens = await renew();
  await setAuthTokenAwait(tokens);
};

const setAuthTokenAwait = async (tokens: TokensType) => {
  setAuthTokens(tokens);
};

interface ErrorResponse {
  error: {
    message: string;
  };
}

createAuthRefreshInterceptor(api, refreshAndSetToken, {
  retryInstance: api,
  shouldRefresh: (error: AxiosError<unknown, any>) => {
    const data = error?.response?.data as ErrorResponse;
    return (
      data?.error?.message !== 'Invalid email or password' &&
      data?.error?.message !== 'No access to any organizations.'
    );
  },
});
