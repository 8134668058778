/* eslint-disable react-hooks/exhaustive-deps */
import dayjs from 'dayjs';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Table } from '@/shared/components/Table';
import { PageLayout } from '@/shared/layouts/PageLayout';
import { Tag } from '@/shared/types/tags';
import { Box, Button, Flex, HStack, Skeleton } from '@/shared/ui';
import { styled } from '@/stitches.config';

import { useUploads } from './context/UploadContext';
import { CreateUpload } from './CreateUpload';

export const AllUploads = () => {
  const uploads = useUploads();
  const loading = uploads.uploadsState.loading || false;
  const { getUploads, searchUploads, setCurrentUpload } = uploads;

  const data = useMemo(
    () => (loading ? Array(5).fill({}) : uploads.uploadsState.uploads),
    [loading, uploads.uploadsState.uploads]
  );

  const searchedData = useMemo(
    () => (loading ? Array(5).fill({}) : uploads.uploadsState.searchedUploads),
    [loading, uploads.uploadsState.searchedUploads]
  );

  const history = useHistory();

  const handleNavigate = (upload: Tag) => {
    setCurrentUpload(upload);
    history.push(`/contacts/uploads/${upload.id}`);
  };

  const loading_columns = [
    {
      Header: 'Uploads',
      colWidth: '100%',
      Cell: (
        <>
          <Skeleton variant="subheading" css={{ maxWidth: '50%', m: 0 }} />
          <Skeleton variant="subheading" css={{ maxWidth: '80%', mb: 0 }} />
        </>
      ),
    },
    {
      Header: (): null => null,
      disableSortBy: true,
      id: 'expander',
      Cell: <Skeleton variant="icon" />,
    },
  ];

  const columns = useMemo(
    () =>
      loading
        ? loading_columns
        : [
            {
              Header: 'Uploads',
              id: 'name',
              accessor: 'name',
              colWidth: '100%',
              Cell: (props: { row: { original: Tag } }) => (
                <Flex align="center">
                  <UploadColor css={{ backgroundColor: props.row.original.color }} />
                  <Box>{props.row.original.name}</Box>
                </Flex>
              ),
            },
            {
              Header: 'Created At',
              id: 'created_at',
              accessor: 'created_at',
              colWidth: '100%',
              Cell: (props: { row: { original: Tag } }) => (
                <Flex align="center" css={{ minWidth: 125 }}>
                  <Box>{dayjs(props.row.original.inserted_at).format('MMM D YYYY')}</Box>
                </Flex>
              ),
            },
            {
              Header: '',
              id: 'count',
              Cell: (props: { row: { original: Tag } }) => (
                <Box css={{ minWidth: 120 }}>
                  <Button
                    variant="gray"
                    onClick={() => handleNavigate(props.row.original)}
                  >
                    View Upload
                  </Button>
                </Box>
              ),
            },
          ],
    [loading]
  );

  const limit = 50;
  const [offset, setOffset] = useState(0);

  const [searchValue, setSearchValue] = useState('');

  const debounceFn = useCallback(
    debounce((value) => {
      searchUploads(value);
    }, 250),
    []
  );

  // function returned by debounce to works as expected
  useEffect(() => {
    if (searchValue.length > 1) {
      debounceFn(searchValue);
    }
  }, [searchValue]);

  useEffect(() => {
    getUploads({ offset, limit });
  }, [offset]);

  return (
    <PageLayout
      breadcrumbs={[
        { title: 'Contacts', path: '/contacts' },
        { title: 'Uploads', path: '/contacts/uploads' },
      ]}
      actions={
        <HStack gap={1}>
          <CreateUpload>
            <Button variant="primary" type="button">
              Upload Contacts
            </Button>
          </CreateUpload>
        </HStack>
      }
    >
      <Box css={{ p: 30 }}>
        <Table
          data={searchValue.length <= 1 ? data : searchedData}
          columns={columns}
          sortable={true}
          caption="Uploads Table"
          searchFilter={true}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          limit={limit}
          offset={offset}
          setOffset={setOffset}
          totalCount={uploads.uploadsState.totalCount}
        />
      </Box>
    </PageLayout>
  );
};

export const SearchIconContainer = styled(Box, {
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  left: '10px',
  pointerEvents: 'none',
});

export const UploadColor = styled(Box, {
  height: 7,
  width: 7,
  borderRadius: '100%',
  mt: 2,
  mr: 10,
});
