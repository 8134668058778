import { FormMessageEditorV2 } from '@/shared/components/forms/FormMessageEditorV2';

import { FormFieldWrapper } from '../../../forms';
import { ActionProps } from '.';

export const SendMessageAction = (props: ActionProps) => {
  const { index } = props;
  return (
    <FormFieldWrapper
      label="Auto-response"
      tooltip="Automatic response when the keyword is found."
      name={`actions[${index}].params`}
    >
      <FormMessageEditorV2
        placeholder="We are open from..."
        showAddAttachment={true}
        enableAttachments={true}
        field={`actions[${index}].params`}
        showAddTemplate={true}
        showAddEmoji={true}
        showAddVariable={true}
        showCharacterCount={true}
      />
    </FormFieldWrapper>
  );
};
