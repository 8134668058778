import React from 'react';
import { HiChevronLeft, HiChevronRight } from 'react-icons/hi';

import { Button, Flex, HStack, Text } from '@/shared/ui';

type PaginationProps = {
  currentPage: number;
  setCurrentPage: (value: number) => void;
  totalCount: number;
  pageSize: number;
};

export const Pagination = ({
  setCurrentPage,
  totalCount,
  currentPage,
  pageSize,
}: PaginationProps) => {
  const onNext = () => {
    setCurrentPage(currentPage + 1);
  };

  const onPrevious = () => {
    setCurrentPage(currentPage - 1);
  };

  return (
    <HStack data-testid="pagination-container">
      <Button
        data-testid="previous-button"
        css={{ height: 32, width: 32, padding: 0 }}
        onClick={onPrevious}
        disabled={currentPage === 1}
        variant="gray"
      >
        <HiChevronLeft style={{ margin: 0 }} />
      </Button>
      <Flex css={{ fontSize: 12, fontWeight: 400, margin: '0 17px' }}>
        <Text>Page&nbsp;</Text>
        <Text variant="semibold">
          {currentPage} of {Math.ceil(totalCount / pageSize)}
        </Text>
      </Flex>
      <Button
        data-testid="next-button"
        css={{ margin: 0, width: 32, height: 32, padding: 0 }}
        onClick={onNext}
        disabled={currentPage === Math.ceil(totalCount / pageSize)}
        variant="gray"
      >
        <HiChevronRight style={{ margin: 0 }} />
      </Button>
    </HStack>
  );
};
