import React from 'react';
import { Helmet } from 'react-helmet-async';

import ConversationState from '@/pages/inbox/context/ConversationContext';
import { SettingsLayout } from '@/shared/layouts';

type OnboardingLayoutProps = {
  children: React.ReactNode;
};

export const OnboardingLayout = (props: OnboardingLayoutProps) => {
  const { children } = props;
  return (
    <>
      <Helmet>
        <title>Whippy | Onboarding</title>
      </Helmet>
      <ConversationState>
        <SettingsLayout
          breadcrumbs={[
            { title: 'Settings', path: '/settings/onboarding' },
            { title: 'Onboarding', path: `/settings/onboarding` },
          ]}
        >
          {children}
        </SettingsLayout>
      </ConversationState>
    </>
  );
};
