import { User } from '@/shared/types/users';

import { Signature } from '..';
import { SearchFilters } from '../contacts';

export enum SignatureActionTypes {
  GET_ALL_SIGNATURES = 'GET_ALL_SIGNATURES',
  CREATE_SIGNATURE = 'CREATE_SIGNATURE',
  EDIT_SIGNATURE = 'EDIT_SIGNATURE',
  DELETE_SIGNATURE = 'DELETE_SIGNATURE',
  SET_CURRENT = 'SET_CURRENT',
  SET_LOADING = 'SET_LOADING',
  SET_DEFAULT_USER_SIGNATURE = 'SET_DEFAULT_USER_SIGNATURE',
  GET_SIGNATURES = 'GET_SIGNATURES',
  UPDATE_FILTER_PARAMS = 'UPDATE_FILTER_PARAMS',
}

export type SignaturesActions =
  | { type: SignatureActionTypes.GET_ALL_SIGNATURES; payload: Array<Signature | null> }
  | { type: SignatureActionTypes.CREATE_SIGNATURE; payload: Signature }
  | { type: SignatureActionTypes.EDIT_SIGNATURE; payload: Signature }
  | { type: SignatureActionTypes.DELETE_SIGNATURE; payload: string }
  | { type: SignatureActionTypes.SET_CURRENT; payload: Signature | null | undefined }
  | { type: SignatureActionTypes.SET_LOADING; payload: boolean }
  | {
      type: SignatureActionTypes.SET_DEFAULT_USER_SIGNATURE;
      payload: Signature | User | null;
    }
  | {
      type: SignatureActionTypes.GET_SIGNATURES;
      payload: { data: Array<Signature>; total: number };
    }
  | { type: SignatureActionTypes.UPDATE_FILTER_PARAMS; payload: SearchFilters };

export type CreateSignatureParams = {
  name: string;
  body: string;
  location_id?: string;
  location_ids?: Array<string>;
  created_by_id?: number | null;
  organization_id?: string;
};

export type UpdateSignatureParams = {
  id: string;
  name?: string;
  body?: string;
  location_ids?: Array<string>;
};
