/* eslint-disable react-hooks/exhaustive-deps */
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { HiMinus, HiPlus, HiTrash } from 'react-icons/hi';
import { useHistory, useParams } from 'react-router-dom';
import { useMedia } from 'react-use';

import { useAuth } from '@/pages/auth/context/AuthProvider';
import { useChannels } from '@/pages/settings/organization/channels/context/ChannelContext';
import { useUserPreferences } from '@/pages/settings/user/preferences/context/PreferencesContext';
import { getAutomationTemplate } from '@/shared/api/automations/templates';
import { getAudienceContacts } from '@/shared/api/campaigns';
import { ConfirmationDialog } from '@/shared/components/ConfirmationDialog';
import { fillPipeFields } from '@/shared/components/editor/Pipes';
import { SidebarNavigationContainer } from '@/shared/components/navigation/SideNavigationContainer';
import { PageLayout } from '@/shared/layouts/PageLayout';
import { Audience } from '@/shared/types/audience';
import { AutomationTemplate } from '@/shared/types/automations';
import {
  AccordionValue,
  AudiencePreviewType,
  BatchDelayTimeUnit,
  Campaign,
  CampaignPermissionTypes,
  CampaignSettings,
  CampaignStatus,
  DeliveryOptions,
  PanelType,
} from '@/shared/types/campaigns';
import {
  Box,
  Button,
  ControlGroup,
  Drawer,
  DrawerContent,
  DrawerPortal,
  Fieldset,
  Flex,
  HStack,
  IconButton,
  Input,
  Label,
  VStack,
} from '@/shared/ui';
import { Switch, SwitchThumb } from '@/shared/ui/Switch';
import { getDefaultChannel } from '@/shared/utils/defaultChannel';

import { AudienceSelection, AudienceState } from '../../audience/AudienceSelection';
import {
  reduceAudienceToParams,
  reduceExcludeAudienceToParams,
} from '../../audience/utils';
import { CampaignAutomations } from '../../automations';
import { useCampaignsContext } from '../../context/CampaignsContext';
import { Description, getIntervalValue } from '../../create';
import { Accordion, CampaignAccordion } from '../../create/CampaignAccordion';
import { CampaignBasicInfo } from '../../create/CampaignBasicInfo';
import { CampaignMessageEditor } from '../../create/CampaignMessageEditor';
import { CampaignPreview } from '../../create/CampaignPreview';
import { CampaignSchedule } from '../../create/CampaignSchedule';
import { CampaignToolbar } from '../../create/CampaignToolbar';
import { CampaignPanel } from '../../create/panel';
import { pre_selected_audience_initial_state } from '../../quick';

export const CampaignTemplate = () => {
  const campaigns = useCampaignsContext();
  const { campaignsState, getCampaignTemplate, setCurrentTemplate } = campaigns;
  const { currentTemplate } = campaignsState;

  const params = useParams<{ id: string }>();

  // on mount get the id from the url and fetch the campaign if the id does not match the current campaign
  useEffect(() => {
    if (params.id && currentTemplate?.id !== params.id) {
      getCampaignTemplate(params.id);
    }
  }, [params.id, currentTemplate?.id]);

  // create current template on unmount
  useEffect(() => {
    return () => {
      setCurrentTemplate(null);
    };
  }, []);

  return <>{currentTemplate && <TemplateEditor />}</>;
};

export const TemplateEditor = (): JSX.Element => {
  const campaigns = useCampaignsContext();
  const {
    campaignsState,
    setCurrentTemplate,
    deleteCampaignTemplate,
    createCampaignTemplate,
    updateCampaignTemplate,
  } = campaigns;
  const { currentTemplate } = campaignsState;

  // if the currentTemplate.access_level is GLOBAL then we need to navigate back to the templates page
  useEffect(() => {
    if (currentTemplate?.access_level === CampaignPermissionTypes.GLOBAL) {
      history.push('/campaigns/templates');
    }
  }, [currentTemplate?.access_level]);

  const history = useHistory();

  // campaign state
  const [currentTemplateEditor, setCurrentEditor] = useState();

  const [accordion, setAccordion] = useState<AccordionValue>(
    AccordionValue.SELECT_LOCATION
  );

  // campaign title
  const [campaignTitle, setCampaignTitle] = useState(currentTemplate?.title || '');

  const channels = useChannels();
  const { getChannelById } = channels;
  const { preferences } = useUserPreferences();

  // location to set the campaign from
  const [location, setLocation] = useState<string>('');
  const locationObject = getChannelById(location);

  useEffect(() => {
    setLocation(
      getDefaultChannel(
        currentTemplate?.audience?.location,
        channels.channelsState.channels,
        preferences?.inbox?.preferred_location_id
      )
    );
  }, [preferences?.inbox?.preferred_location_id]);

  // permission to use of the campaign template
  const [permission, setPermission] = useState<CampaignPermissionTypes>(
    currentTemplate?.access_level || CampaignPermissionTypes.ORGANIZATION
  );

  // is this a big screen?
  const isLargeDesktop = useMedia('(min-width: 1350px)');

  // update accordion state when basic campaign details are set
  useEffect(() => {
    if (currentTemplate && currentTemplate?.title && location) {
      setAccordion(AccordionValue.SELECT_AUDIENCE);
    }
  }, [currentTemplate?.title]);

  // contacts to message audience
  const [includeAudience, setIncludeAudience] = useState<AudienceState>({
    contacts: [],
    uploads: [],
    groups: [],
    tags: [],
    manuallyAddedContacts: [],
    wholeCampaignTab: {},
  });

  // contacts to exclude audience
  const [excludeAudience, setExcludeAudience] = useState<AudienceState>({
    contacts: [],
    uploads: [],
    groups: [],
    tags: [],
    manuallyAddedContacts: [],
    wholeCampaignTab: {},
  });

  // should we exclude contacts with open conversation from the campaign?
  const [excludeContactsWithOpenConversation, setExcludeContactsWithOpenConversation] =
    useState<boolean>(
      currentTemplate?.excluded_contacts?.exclude_contacts_with_open_conversations ||
        false
    );

  // should we exclude contacts who got a campaign recently?
  const [excludeContactsWithOtherCampaigns, setExcludeContactsWithOtherCampaigns] =
    useState<boolean>(
      currentTemplate &&
        currentTemplate.excluded_contacts?.time_since_last_campaign &&
        currentTemplate.excluded_contacts.time_since_last_campaign > 0
        ? true
        : false
    );

  // how many days if excludeContactsWithOtherCampaigns is true
  const [excludeCampaignSince, setExcludeCampaignSince] = useState<number>(
    currentTemplate?.excluded_contacts?.time_since_last_campaign || 0
  );

  // preselected audience from saved campaign object
  const [includePreSelectAudience, setIncludePreSelectAudience] = useState<Audience>(
    currentTemplate?.audience || pre_selected_audience_initial_state || {}
  );

  // preselected excluded audience from saved campaign object
  const [excludePreSelectAudience, setExcludePreSelectAudience] = useState(
    currentTemplate?.excluded_contacts
      ? currentTemplate?.excluded_contacts
      : pre_selected_audience_initial_state
  );

  useEffect(() => {
    // merge pre_selected_audience_initial_state with currentTemplate.audience
    if (currentTemplate?.audience) {
      setIncludePreSelectAudience({
        ...pre_selected_audience_initial_state,
        ...currentTemplate.audience,
      });
    }
  }, []);

  useEffect(() => {
    // merge pre_selected_audience_initial_state with currentTemplate.excluded_contacts
    if (currentTemplate?.excluded_contacts) {
      setExcludePreSelectAudience({
        ...pre_selected_audience_initial_state,
        ...currentTemplate.excluded_contacts,
      });
    }
  }, []);

  // exclude an individual contact from included audience panel
  const handleUpdateExcludePreSelectedAudience = (id: string) => {
    setExcludePreSelectAudience({
      tag_ids: [...(excludePreSelectAudience?.tag_ids || [])],
      dynamic_group_ids: [...(excludePreSelectAudience?.dynamic_group_ids || [])],
      upload_tag_ids: [...(excludePreSelectAudience?.upload_tag_ids || [])],
      contacts: [...[id], ...(excludePreSelectAudience?.contacts || [])],
      time_since_last_campaign: excludeCampaignSince,
      exclude_contacts_with_open_conversations: excludeContactsWithOpenConversation,
    });
  };

  // does the reduceAudienceToParams objects keys have a value
  // used to check if the audience is empty
  const hasIncludeAudience = () => {
    const audience = reduceAudienceToParams(
      includeAudience,
      includePreSelectAudience,
      location
    );
    return Object.keys(audience).some(
      (key) =>
        (key !== 'location' && audience[key as keyof typeof audience].length > 0) || false
    );
  };

  // the contacts in the audience
  const [audiencePreview, setAudiencePreview] = useState<AudiencePreviewType>({
    audience: [],
    excluded_contacts: [],
  });

  // are we loading the contacts in the audience?
  const [loadingAudience, setLoadingAudience] = useState<boolean>(false);

  // handle campaign side panel state
  const [showPanel, setShowPanel] = useState(false);
  const [panel, setPanel] = useState<PanelType>(PanelType.VIEW_AUDIENCE);

  // open or close the campaign side panel
  const handlePanelClick = (panelType: PanelType): void => {
    if (panelType === panel) {
      setShowPanel(!showPanel);
    } else {
      setPanel(panelType);
      setShowPanel(true);
    }
  };

  // if an upload is added to the audience, and its still pending processing
  // then we want to disable the audience calculation
  const [disableAudienceCalculation, setDisableAudienceCalculation] = useState(false);
  console.log('disableAudienceCalculation', disableAudienceCalculation);

  // get the list of contacts that will be included or excluded
  useEffect(() => {
    console.log('useEffect: getAudienceContacts');
    // if there is no location lets not calculate the audience
    if (location && !disableAudienceCalculation) {
      setLoadingAudience(true);

      // do this to invalidate the old contacts
      // while recalculating the new contacts
      setAudiencePreview({
        audience: [],
        excluded_contacts: [],
      });

      const audience = reduceAudienceToParams(
        includeAudience,
        includePreSelectAudience,
        location
      );

      const exclude = reduceExcludeAudienceToParams(
        excludeAudience,
        excludePreSelectAudience,
        excludeCampaignSince,
        excludeContactsWithOpenConversation
      );

      // do this to invalidate the old contacts
      // while recalculating the new contacts
      setAudiencePreview({
        audience: [],
        excluded_contacts: [],
      });

      // this is probably not the best way to do this
      // but it works for now, we should probably add advanced more retry logic
      const getContacts = async () => {
        try {
          const res = await getAudienceContacts(audience, exclude);
          // if the response is empty try again
          if (res.audience_count === 0) {
            retryGetContacts();
          } else {
            setAudiencePreview(res);
            setLoadingAudience(false);
            return res;
          }
        } catch (err) {
          console.error('campaign:getContacts', err);
          retryGetContacts();
        }
      };

      const retryGetContacts = async () => {
        try {
          const res = await getAudienceContacts(audience, exclude);
          setAudiencePreview(res);
          setLoadingAudience(false);
        } catch (err) {
          setLoadingAudience(false);
          console.error('campaign:getContacts', err);
        }
      };

      getContacts();
    }

    if (disableAudienceCalculation) {
      setLoadingAudience(false);
    }
  }, [
    currentTemplate?.id,
    includeAudience,
    excludeAudience,
    includePreSelectAudience,
    excludePreSelectAudience,
    location,
    excludeCampaignSince,
    excludeContactsWithOpenConversation,
  ]);

  // message body state
  const [message, setMessage] = useState<string>(currentTemplate?.body || '');

  // message attachments state
  const [attachments, setAttachments] = useState<any>({
    attachment_urls: currentTemplate?.attachment_urls || [],
  });

  // send campaign in batches?
  const [enableBatchSend, setEnableBatchSend] = useState(
    currentTemplate?.delivery_options?.period &&
      currentTemplate?.delivery_options?.messages_per_period
      ? true
      : false
  );

  // number of messages to send per batch
  const [batchSize, setBatchSize] = useState<number | null>(
    currentTemplate?.delivery_options?.messages_per_period || 100
  );

  // period time unit for batch sending
  const [batchDelayTimeUnit, setBatchDelayTimeUnit] = useState(
    BatchDelayTimeUnit.MINUTES
  );

  // number of minutes between batches
  const [batchDelay, setBatchDelay] = useState<number | null>(
    currentTemplate?.delivery_options?.period
      ? currentTemplate?.delivery_options?.period / 60
      : 5
  );

  // show the user the campaign settings
  const [enableCampaignSettings, setEnableCampaignSettings] = useState(false);
  // show the user the campaign link tracking settings
  const [enableLinkTrackingSettings, setEnableLinkTrackingSettings] = useState(false);

  // id any of the three currentTemplate campaign settings are enabled
  // then show the user the campaign settings on mount
  useEffect(() => {
    if (
      currentTemplate?.settings?.support_sms_quiet_hours?.enabled ||
      currentTemplate?.settings?.skip_sending_on_weekend?.enabled ||
      currentTemplate?.settings?.only_send_during_business_hours?.enabled
    ) {
      setEnableCampaignSettings(true);
    }
    if (
      currentTemplate?.settings?.link_tracking?.disabled ||
      currentTemplate?.settings?.link_tracking?.domain_id
    ) {
      setEnableLinkTrackingSettings(true);
    }
  }, []);

  // campaign settings options for sending and scheduling messages
  const [campaignSettings, setCampaignSettings] = useState<CampaignSettings>({
    support_sms_quiet_hours: {
      enabled: currentTemplate?.settings?.support_sms_quiet_hours?.enabled || false,
      use_contact_timezone:
        currentTemplate?.settings?.support_sms_quiet_hours?.use_contact_timezone || false,
    },
    skip_sending_on_weekend: {
      enabled: currentTemplate?.settings?.skip_sending_on_weekend?.enabled || false,
      use_contact_timezone:
        currentTemplate?.settings?.skip_sending_on_weekend?.use_contact_timezone || false,
    },
    only_send_during_business_hours: {
      enabled:
        currentTemplate?.settings?.only_send_during_business_hours?.enabled || false,
      use_contact_timezone:
        currentTemplate?.settings?.only_send_during_business_hours
          ?.use_contact_timezone || false,
    },
    link_tracking: {
      disabled: currentTemplate?.settings?.link_tracking?.disabled || false,
      domain_id: currentTemplate?.settings?.link_tracking?.domain_id || null,
    },
  });

  // show the batch delay in correct time units
  useEffect(() => {
    if (currentTemplate?.delivery_options?.period) {
      const delayInMinutes = currentTemplate?.delivery_options?.period / 60;
      const hourInMinutes = getIntervalValue(BatchDelayTimeUnit.HOURS);
      const dayInMinutes = getIntervalValue(BatchDelayTimeUnit.DAYS);
      if (delayInMinutes >= hourInMinutes && delayInMinutes % hourInMinutes === 0) {
        setBatchDelay(delayInMinutes / hourInMinutes);
        setBatchDelayTimeUnit(BatchDelayTimeUnit.HOURS);
      }
      if (delayInMinutes >= dayInMinutes && delayInMinutes % dayInMinutes === 0) {
        setBatchDelay(delayInMinutes / dayInMinutes);
        setBatchDelayTimeUnit(BatchDelayTimeUnit.DAYS);
      }
    }
  }, [currentTemplate?.delivery_options?.period]);

  const delivery_options: DeliveryOptions = {
    // convert minutes to seconds
    period: batchDelay ? batchDelay * 60 : 300,
    messages_per_period: batchSize || 100,
  };

  const template_payload: Campaign = {
    title:
      campaignTitle ||
      `untitled-template-${dayjs(new Date()).format('YYYY-MM-DD')}T${dayjs(
        new Date()
      ).format('H:mm:ss')}`,
    status: CampaignStatus.TEMPLATE,
    body: message,
    attachment_urls: attachments.attachment_urls,
    audience: reduceAudienceToParams(includeAudience, includePreSelectAudience, location),
    excluded_contacts: reduceExcludeAudienceToParams(
      excludeAudience,
      excludePreSelectAudience,
      excludeCampaignSince,
      excludeContactsWithOpenConversation
    ),
    schedule_options: null,
    delivery_options: enableBatchSend ? delivery_options : null,
    settings: campaignSettings,
    access_level: permission || CampaignPermissionTypes.ORGANIZATION,
    // removed for now
    // location_ids: permissionLocation,
  };

  // update the currentTemplate campaign
  const updateTemplate = async () => {
    // update the campaign if not null
    if (currentTemplate && currentTemplate?.id) {
      updateCampaignTemplate(currentTemplate.id, template_payload);
    }
  };

  // save the currentTemplate campaign, either create or update
  const handleSave = async (accordion: AccordionValue) => {
    if (currentTemplate && currentTemplate?.id) {
      await updateTemplate();
      setAccordion(accordion);
    } else {
      const res = await createCampaignTemplate(template_payload);
      if (res && res.id) {
        setCurrentTemplate(res);
        history.push(`/campaigns/templates/${res.id}`);
      }
    }
  };

  const auth = useAuth();

  const [previewName, setPreviewName] = useState(auth?.tokens?.name || 'John Doe');

  const fillDemoMessage = () => {
    const organizationName = auth?.organizationName;

    // fill message pipe fields
    const filled_body = fillPipeFields(
      message,
      previewName || 'John Doe',
      locationObject?.name || '',
      locationObject?.address || '',
      locationObject?.google_place_id || '',
      organizationName || ''
    );

    return filled_body;
  };

  const handleDelete = () => {
    if (currentTemplate && currentTemplate?.id) {
      deleteCampaignTemplate(currentTemplate.id);
      history.push('/campaigns/templates');
    }
  };

  const hasTriggersOrTemplates =
    (currentTemplate &&
      currentTemplate.triggers &&
      currentTemplate.triggers.length > 0) ||
    (currentTemplate &&
      currentTemplate.associated_automation_templates &&
      currentTemplate.associated_automation_templates.length > 0)
      ? true
      : false;

  // all as-is templates under this campaign
  const [existingAllAutomationTemplates, setExistingAllAutomationTemplates] = useState<
    Array<AutomationTemplate>
  >([]);

  // fetch the existing as-is templates under this campaign
  const fetchTemplates = async () => {
    const existingAssociatedAutomationTemplates =
      currentTemplate?.associated_automation_templates || [];
    // extract the automation_template_id from each existingAssociatedAutomationTemplates
    const existingAllAutomationTemplateIds = existingAssociatedAutomationTemplates.map(
      (template: {
        automation_template_id: string;
        campaign_automation_template_id: string;
      }) => template.automation_template_id
    );
    const existingAllAutomationTemplatesPromises: Array<Promise<AutomationTemplate>> =
      existingAllAutomationTemplateIds.map((id: string) => getAutomationTemplate(id));
    try {
      const templates = await Promise.all(existingAllAutomationTemplatesPromises);
      setExistingAllAutomationTemplates(templates);
    } catch (error) {
      console.error('Error fetching automation templates:', error);
    }
  };

  useEffect(() => {
    fetchTemplates();
  }, [currentTemplate]);

  return (
    <Flex css={{ minHeight: '100%' }}>
      <PageLayout
        breadcrumbs={[
          { title: 'Campaigns', path: '/campaigns' },
          { title: 'Templates', path: '/campaigns/templates' },
          {
            title: `${currentTemplate?.title || 'Create'}`,
            path: `/campaigns/templates/${currentTemplate?.id || 'create'}`,
          },
        ]}
        actions={
          <HStack gap={2}>
            {currentTemplate?.id && (
              <ConfirmationDialog
                title="Delete Campaign Template"
                description="Are you sure you want to delete your campaign template? This action
                 cannot be undone."
                onConfirm={handleDelete}
                cancelButtonTitle="Cancel"
                confirmButtonTitle="Yes, Delete Template"
                testID="delete-template-button"
              >
                <IconButton size="2" variant="outline">
                  <HiTrash />
                </IconButton>
              </ConfirmationDialog>
            )}
            <CampaignPreview
              body={message}
              attachments={attachments.attachment_urls}
              location_id={location}
              campaignTitle={campaignTitle}
              previewName={previewName}
              setPreviewName={setPreviewName}
              fillDemoMessage={fillDemoMessage}
            />
            <Button
              onClick={() => handleSave(AccordionValue.DEFAULT_VALUE)}
              disabled={campaignTitle.length < 3}
            >
              Save Template
            </Button>
          </HStack>
        }
        width={'calc(100% - 55px)'}
      >
        <Flex direction="column" css={{ pb: 200 }}>
          <Accordion
            type="single"
            defaultValue={AccordionValue.SELECT_LOCATION}
            value={accordion}
          >
            <VStack css={{ p: 20 }} gap="2">
              <CampaignBasicInfo
                accordion={accordion}
                setAccordion={setAccordion}
                campaignTitle={campaignTitle}
                setCampaignTitle={setCampaignTitle}
                location={location}
                setLocation={setLocation}
                handleSave={handleSave}
                permission={permission}
                setPermission={setPermission}
                // setPermissionLocation={setPermissionLocation}
              />
              <CampaignAccordion
                index={2}
                title="Audience"
                description="Contacts who would receive this campaign"
                currentAccordionValue={accordion}
                itemValue={AccordionValue.SELECT_AUDIENCE}
                isValid={hasIncludeAudience()}
                isError={false}
                setItemValue={setAccordion}
              >
                <VStack gap="2">
                  <Fieldset data-testid="field-audience">
                    <Label>Select Audience</Label>
                    <Box css={{ p: 0, m: 0 }}>
                      <AudienceSelection
                        locationId={location}
                        selectedAudience={includeAudience}
                        setSelectedAudience={setIncludeAudience}
                        isInbox={false}
                        isContactsPage={false}
                        isGroupsPage={false}
                        isUploadsPage={false}
                        isCampaignsEditPage={false}
                        isCampaignsPage={false}
                        preSelectedAudience={includePreSelectAudience}
                        setPreSelectedAudience={setIncludePreSelectAudience}
                        clearStatesOnUnmount={false}
                        setDisableAudienceCalculation={setDisableAudienceCalculation}
                      />
                    </Box>
                  </Fieldset>
                  <Fieldset>
                    <Label>Exclude</Label>
                    <Box>
                      <AudienceSelection
                        locationId={location}
                        selectedAudience={excludeAudience}
                        setSelectedAudience={setExcludeAudience}
                        isInbox={false}
                        isContactsPage={false}
                        isGroupsPage={false}
                        isUploadsPage={false}
                        isCampaignsEditPage={false}
                        isCampaignsPage={false}
                        preSelectedAudience={excludePreSelectAudience}
                        setPreSelectedAudience={setExcludePreSelectAudience}
                        clearStatesOnUnmount={false}
                        setDisableAudienceCalculation={setDisableAudienceCalculation}
                      />
                    </Box>
                  </Fieldset>
                  <Fieldset>
                    <Flex align="center" justify="between">
                      <Flex direction="column">
                        <Label>
                          Exclude Contacts with Open Conversations in any Channel
                          (regardless of access)
                        </Label>
                        <Description>
                          Excludes contacts from this campaign that have open
                          conversations in your inbox.
                        </Description>
                      </Flex>
                      <Box css={{ mt: 5 }}>
                        <Switch
                          checked={excludeContactsWithOpenConversation}
                          onCheckedChange={() =>
                            setExcludeContactsWithOpenConversation(
                              !excludeContactsWithOpenConversation
                            )
                          }
                        >
                          <SwitchThumb />
                        </Switch>
                      </Box>
                    </Flex>
                  </Fieldset>
                  <Fieldset>
                    <Flex align="center" justify="between">
                      <Flex direction="column">
                        <Label>
                          Exclude Contacts who recently received another Campaign
                        </Label>
                        <Description>
                          Excludes contacts from this campaign that have recently received
                          another campaign.
                        </Description>
                      </Flex>
                      <Box css={{ mt: 5 }}>
                        <Switch
                          checked={excludeContactsWithOtherCampaigns}
                          onCheckedChange={() =>
                            setExcludeContactsWithOtherCampaigns(
                              !excludeContactsWithOtherCampaigns
                            )
                          }
                        >
                          <SwitchThumb />
                        </Switch>
                      </Box>
                    </Flex>
                    {excludeContactsWithOtherCampaigns && (
                      <Box
                        css={{
                          mt: 10,
                          p: 16,
                          backgroundColor: '#F6F6F6',
                          borderRadius: 4,
                        }}
                      >
                        <HStack>
                          <ControlGroup>
                            <IconButton
                              size={2}
                              css={{ backgroundColor: 'white' }}
                              onClick={() =>
                                excludeCampaignSince > 0
                                  ? setExcludeCampaignSince(excludeCampaignSince - 1)
                                  : setExcludeCampaignSince(excludeCampaignSince)
                              }
                            >
                              <HiMinus />
                            </IconButton>
                            <Input
                              type="number"
                              value={excludeCampaignSince}
                              onChange={(e) =>
                                setExcludeCampaignSince(
                                  Math.max(Math.round(Number(e.target.value)), 0)
                                )
                              }
                              css={{ textAlign: 'center', width: 50 }}
                            />
                            <IconButton
                              size={2}
                              css={{ backgroundColor: 'white' }}
                              onClick={() =>
                                setExcludeCampaignSince(excludeCampaignSince + 1)
                              }
                            >
                              <HiPlus />
                            </IconButton>
                          </ControlGroup>
                          <Box css={{ fontSize: 13 }}>days</Box>
                        </HStack>
                      </Box>
                    )}
                  </Fieldset>
                  <HStack>
                    <Button
                      onClick={() => handleSave(AccordionValue.CREATE_MESSAGE)}
                      disabled={!hasIncludeAudience() || false}
                    >
                      Save
                    </Button>
                    <Button
                      variant="gray"
                      ghost={true}
                      onClick={() => setAccordion(AccordionValue.DEFAULT_VALUE)}
                    >
                      Cancel
                    </Button>
                  </HStack>
                </VStack>
              </CampaignAccordion>
              <CampaignMessageEditor
                message={message}
                setMessage={setMessage}
                attachments={attachments}
                setAttachments={setAttachments}
                accordion={accordion}
                setAccordion={setAccordion}
                setCurrentEditor={setCurrentEditor}
                handleSave={handleSave}
              />
              <CampaignSchedule
                accordion={accordion}
                showSchedulePicker={false}
                setAccordion={setAccordion}
                handleSave={handleSave}
                enableBatchSend={enableBatchSend}
                setEnableBatchSend={setEnableBatchSend}
                batchSize={batchSize}
                setBatchSize={setBatchSize}
                batchDelayTimeUnit={batchDelayTimeUnit}
                setBatchDelayTimeUnit={setBatchDelayTimeUnit}
                batchDelay={batchDelay}
                setBatchDelay={setBatchDelay}
                enableCampaignSettings={enableCampaignSettings}
                setEnableCampaignSettings={setEnableCampaignSettings}
                enableLinkTrackingSettings={enableLinkTrackingSettings}
                setEnableLinkTrackingSettings={setEnableLinkTrackingSettings}
                campaignSettings={campaignSettings}
                setCampaignSettings={setCampaignSettings}
              />
              <CampaignAccordion
                index={5}
                title="Automations"
                description="Automate campaign follow up tasks."
                currentAccordionValue={accordion}
                itemValue={AccordionValue.CREATE_AUTOMATION}
                setItemValue={setAccordion}
                isValid={hasTriggersOrTemplates}
              >
                <VStack gap="2">
                  <Fieldset>
                    <Flex direction="column">
                      <Label>Create Campaign Automation (optional)</Label>
                      <Description>
                        Campaign automations let you take actions when contacts respond to
                        your campaigns.
                      </Description>
                    </Flex>
                  </Fieldset>
                  <CampaignAutomations
                    current={currentTemplate}
                    setCurrentCampaign={setCurrentTemplate}
                    accordion={accordion}
                    setAccordion={setAccordion}
                    existingAllAutomationTemplates={existingAllAutomationTemplates}
                    setExistingAllAutomationTemplates={setExistingAllAutomationTemplates}
                  />
                </VStack>
              </CampaignAccordion>
            </VStack>
          </Accordion>
        </Flex>
      </PageLayout>
      <Flex css={{ height: '100%' }}>
        {showPanel && isLargeDesktop && (
          <SidebarNavigationContainer
            defaultWidth={360}
            minWidth={300}
            maxWidth={500}
            dragDirection="right"
            name="CREATE_TEMPLATE"
            disableCollapse
          >
            <CampaignPanel
              setShowPanel={setShowPanel}
              panel={panel}
              loadingAudience={loadingAudience}
              audience={audiencePreview?.audience || []}
              audience_params={
                reduceAudienceToParams(
                  includeAudience,
                  includePreSelectAudience,
                  location
                ) || {}
              }
              excluded_audience={audiencePreview?.excluded_contacts || []}
              excluded_audience_params={
                reduceExcludeAudienceToParams(
                  excludeAudience,
                  excludePreSelectAudience,
                  excludeCampaignSince,
                  excludeContactsWithOpenConversation
                ) || {}
              }
              attachments={attachments.attachment_urls || []}
              preview={fillDemoMessage()}
              body={message}
              handleExclude={handleUpdateExcludePreSelectedAudience}
              editor={currentTemplateEditor}
            />
          </SidebarNavigationContainer>
        )}
        {!isLargeDesktop && (
          <Drawer open={showPanel}>
            <DrawerPortal>
              <DrawerContent
                side="right"
                css={{ width: 360, overflowY: 'scroll' }}
                onEscapeKeyDown={() => setShowPanel(false)}
                onPointerDownOutside={() => setShowPanel(false)}
              >
                <CampaignPanel
                  setShowPanel={setShowPanel}
                  panel={panel}
                  loadingAudience={loadingAudience}
                  audience={audiencePreview?.audience || []}
                  audience_params={
                    reduceAudienceToParams(
                      includeAudience,
                      includePreSelectAudience,
                      location
                    ) || {}
                  }
                  excluded_audience={audiencePreview?.excluded_contacts || []}
                  excluded_audience_params={
                    reduceExcludeAudienceToParams(
                      excludeAudience,
                      excludePreSelectAudience,
                      excludeCampaignSince,
                      excludeContactsWithOpenConversation
                    ) || {}
                  }
                  attachments={attachments.attachment_urls || []}
                  preview={fillDemoMessage()}
                  body={message}
                  handleExclude={handleUpdateExcludePreSelectedAudience}
                  editor={currentTemplateEditor}
                />
              </DrawerContent>
            </DrawerPortal>
          </Drawer>
        )}
        <CampaignToolbar handlePanelClick={handlePanelClick} open={showPanel} />
      </Flex>
    </Flex>
  );
};
