import { Campaign, CampaignContact, CampaignStatus } from '@/shared/types/campaigns';
import { Campaign as CampaignTemplate } from '@/shared/types/campaigns';
import { FilterParams } from '@/shared/types/filter';
import { removeDuplicates } from '@/shared/utils/removeDuplicates';

import { CampaignsState } from './CampaignsContext';
import {
  CHANGE_CAMPAIGN_STATUS,
  CHANGE_TAB_TOTAL_CONTACTS,
  CLEAR_AUDIENCE,
  CREATE_CAMPAIGN,
  CREATE_CAMPAIGN_TEMPLATE,
  DELETE_CAMPAIGN,
  DELETE_CAMPAIGN_TEMPLATE,
  GET_CAMPAIGN,
  GET_CAMPAIGN_CONTACTS_V2,
  GET_CAMPAIGN_CONTACTS_V2_NEW_SEARCH,
  GET_CAMPAIGN_TEMPLATES,
  GET_CAMPAIGNS,
  GET_MESSAGE_COUNT,
  SET_ALL_SELECTED,
  SET_CAMPAIGN_CONTACTS_PARAMS,
  SET_CURRENT,
  SET_CURRENT_TEMPLATE,
  SET_LOADING,
  SET_LOADING_CAMPAIGN_TEMPLATES,
  SET_LOADING_CONTACTS,
  SET_SELECTED_CONTACTS,
  SET_UPDATING,
  UPDATE_CAMPAIGN,
  UPDATE_CAMPAIGN_TEMPLATE,
  UPDATE_CAMPAIGNS,
  UPDATE_CLICKED_CONTACTS,
  UPDATE_DELIVERED_CONTACTS,
  UPDATE_NOT_CLICKED_CONTACTS,
  UPDATE_NOT_DELIVERED_CONTACTS,
  UPDATE_RESPONDED_CONTACTS,
  UPDATE_UNFULFILLED_CONTACTS,
  UPDATE_UNRESPONDED_CONTACTS,
  UPDATE_UNSUBSCRIBED_CONTACTS,
} from './types';

type CampaignsAction =
  | {
      type: 'GET_CAMPAIGNS';
      payload: {
        status: string;
        campaigns: Campaign[];
        total: number;
      };
    }
  | {
      type: 'UPDATE_CAMPAIGNS';
      payload: {
        status: string;
        campaigns: Campaign[];
        total: number;
      };
    }
  | { type: 'SET_UPDATING'; payload: boolean }
  | { type: 'GET_CAMPAIGN'; payload: any }
  | { type: 'GET_PART_OF_CAMPAIGNS'; payload: any }
  | { type: 'GET_CAMPAIGN_CONTACTS'; payload: any }
  | { type: 'GET_MESSAGE_COUNT'; payload: any }
  | { type: 'UPDATE_CAMPAIGN'; payload: any }
  | { type: 'DELETE_CAMPAIGN'; payload: any }
  | { type: 'CREATE_CAMPAIGN'; payload: any }
  | { type: 'SET_CURRENT'; payload: any }
  | { type: 'CHANGE_CAMPAIGN_STATUS'; payload: any }
  | { type: 'CLEAR_AUDIENCE'; payload: any }
  | { type: 'SET_LOADING'; payload: boolean }
  | { type: 'UPDATE_CLICKED_CONTACTS'; payload: any }
  | { type: 'UPDATE_DELIVERED_CONTACTS'; payload: any }
  | { type: 'UPDATE_RESPONDED_CONTACTS'; payload: any }
  | { type: 'UPDATE_UNRESPONDED_CONTACTS'; payload: any }
  | { type: 'UPDATE_UNSUBSCRIBED_CONTACTS'; payload: any }
  | { type: 'UPDATE_NOT_CLICKED_CONTACTS'; payload: any }
  | { type: 'UPDATE_NOT_DELIVERED_CONTACTS'; payload: any }
  | { type: 'UPDATE_UNFULFILLED_CONTACTS'; payload: any }
  | { type: 'FILTER_CAMPAIGNS'; payload: any }
  | { type: 'SET_ALL_SELECTED'; payload: any }
  | { type: 'CHANGE_TAB_TOTAL_CONTACTS'; payload: any }
  | { type: 'GET_CAMPAIGN_TEMPLATES'; payload: any }
  | { type: 'GET_CAMPAIGN_TEMPLATE'; payload: any }
  | { type: 'CREATE_CAMPAIGN_TEMPLATE'; payload: any }
  | { type: 'UPDATE_CAMPAIGN_TEMPLATE'; payload: any }
  | { type: 'DELETE_CAMPAIGN_TEMPLATE'; payload: any }
  | { type: 'DUPLICATE_CAMPAIGN_TEMPLATE'; payload: any }
  | { type: 'SET_CURRENT_TEMPLATE'; payload: any }
  | { type: 'SET_LOADING_CAMPAIGN_TEMPLATES'; payload: boolean }
  | { type: 'SET_SELECTED_CONTACTS'; payload: any }
  | { type: 'GET_CAMPAIGN_CONTACTS_V2'; payload: any }
  | { type: 'GET_CAMPAIGN_CONTACTS_V2_NEW_SEARCH'; payload: any }
  | { type: 'SET_LOADING_CONTACTS'; payload: boolean }
  | { type: 'SET_CAMPAIGN_CONTACTS_PARAMS'; payload: FilterParams };

const CampaignsReducer = (state: CampaignsState, action: CampaignsAction) => {
  switch (action.type) {
    case GET_CAMPAIGNS:
      return {
        ...state,
        campaigns: action.payload.status ? state.campaigns : action.payload.campaigns,
        draftCampaigns:
          action.payload.status === CampaignStatus.DRAFT
            ? action.payload.campaigns
            : state.draftCampaigns,
        completeCampaigns:
          action.payload.status === CampaignStatus.COMPLETE
            ? action.payload.campaigns
            : state.completeCampaigns,
        scheduledCampaigns:
          action.payload.status === CampaignStatus.SCHEDULED
            ? action.payload.campaigns
            : state.scheduledCampaigns,
        archivedCampaigns:
          action.payload.status === CampaignStatus.ARCHIVED
            ? action.payload.campaigns
            : state.archivedCampaigns,
        totalCount: action.payload.total,
        loading: false,
        isCampaignsInitialized: true,
      } as CampaignsState;
    case UPDATE_CAMPAIGNS:
      return {
        ...state,
        campaigns: action.payload.status
          ? state.campaigns
          : removeDuplicates([...state.campaigns, ...action.payload.campaigns]),
        draftCampaigns:
          action.payload.status === CampaignStatus.DRAFT
            ? removeDuplicates([...state.draftCampaigns, ...action.payload.campaigns])
            : state.draftCampaigns,
        completeCampaigns:
          action.payload.status === CampaignStatus.COMPLETE
            ? removeDuplicates([...state.completeCampaigns, ...action.payload.campaigns])
            : state.completeCampaigns,
        scheduledCampaigns:
          action.payload.status === CampaignStatus.SCHEDULED
            ? removeDuplicates([...state.scheduledCampaigns, ...action.payload.campaigns])
            : state.scheduledCampaigns,
        archivedCampaigns:
          action.payload.status === CampaignStatus.ARCHIVED
            ? removeDuplicates([...state.archivedCampaigns, ...action.payload.campaigns])
            : state.archivedCampaigns,
        totalCount: action.payload.total,
        isUpdating: false,
        loading: false,
      };
    case GET_CAMPAIGN:
      return {
        ...state,
        campaigns: state.campaigns.map((campaign: Campaign) => {
          return campaign.id === action.payload.id ? action.payload : campaign;
        }),
        current: action.payload,
        currentMessageCount: 0,
        loading: false,
      };
    case CHANGE_CAMPAIGN_STATUS:
      return {
        ...state,
        campaigns: state.campaigns.map((campaign: Campaign) =>
          campaign.id === action.payload
            ? { ...campaign, status: CampaignStatus.DRAFT }
            : campaign
        ),
        scheduledCampaigns: state.scheduledCampaigns
          .map((campaign: Campaign) => (campaign.id === action.payload ? null : campaign))
          .filter((campaign: any) => campaign !== null),
        loading: false,
      } as CampaignsState;
    case CREATE_CAMPAIGN:
      return {
        ...state,
        campaigns: [...state.campaigns, action.payload].sort(function (
          a: Campaign,
          b: Campaign
        ) {
          // sort campaigns by inserted_at
          //The right-hand side of an arithmetic operation must be of type 'number', 'bigint' or an enum type.
          return (
            Number(new Date(b?.inserted_at ?? 0)) - Number(new Date(a?.inserted_at ?? 0))
          );
        }),
        totalCount: state.totalCount + 1,
        loading: false,
      } as CampaignsState;
    case UPDATE_CAMPAIGN:
      return {
        ...state,
        // if the campaign is in the current state, update the
        // current campaign with the new data
        current:
          state.current && state.current.id === action.payload.id
            ? action.payload
            : state.current,
        campaigns: state.campaigns.map((campaign: Campaign) => {
          return campaign.id === action.payload.id ? action.payload : campaign;
        }),
      } as CampaignsState;
    case DELETE_CAMPAIGN:
      return {
        ...state,
        draftCampaigns: state.draftCampaigns.filter((campaign: Campaign) => {
          return campaign.id !== action.payload;
        }),
        scheduledCampaigns: state.scheduledCampaigns.filter((campaign: Campaign) => {
          return campaign.id !== action.payload;
        }),
        campaigns: state.campaigns.filter((campaign: Campaign) => {
          return campaign.id !== action.payload;
        }),
        totalCount: state.totalCount - 1,
      } as CampaignsState;
    case SET_CURRENT:
      return {
        ...state,
        current: action.payload,
        currentMessageCount: 0,
      } as CampaignsState;
    case CLEAR_AUDIENCE:
      return {
        ...state,
        clickedContacts: [],
        deliveredContacts: [],
        respondedContacts: [],
        unrespondedContacts: [],
        unsubscribedContacts: [],
      } as CampaignsState;
    case SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      } as CampaignsState;
    case SET_UPDATING:
      return {
        ...state,
        isUpdating: action.payload,
      } as CampaignsState;
    case UPDATE_CLICKED_CONTACTS:
      return {
        ...state,
        clickedContacts:
          action.payload.search || action.payload.offset === 0
            ? removeDuplicatesById(action.payload.data)
            : removeDuplicates([...state.clickedContacts, ...action.payload.data]),
        isSearched: action.payload.search,
        currentTabTotalContacts: action.payload.total,
        loading: false,
        selectedContacts: state.allSelected
          ? removeDuplicates([
              ...state.selectedContacts,
              ...action.payload.data.map(
                (campaignContact: CampaignContact) => campaignContact.contact
              ),
            ])
          : state.selectedContacts,
      } as CampaignsState;
    case UPDATE_DELIVERED_CONTACTS:
      return {
        ...state,
        deliveredContacts:
          action.payload.search || action.payload.offset === 0
            ? removeDuplicatesById(action.payload.data)
            : removeDuplicates([...state.deliveredContacts, ...action.payload.data]),
        isSearched: action.payload.search,
        currentTabTotalContacts: action.payload.total,
        loading: false,
        selectedContacts: state.allSelected
          ? removeDuplicates([
              ...state.selectedContacts,
              ...action.payload.data.map(
                (campaignContact: CampaignContact) => campaignContact.contact
              ),
            ])
          : state.selectedContacts,
      } as CampaignsState;
    case UPDATE_NOT_DELIVERED_CONTACTS:
      return {
        ...state,
        notDeliveredContacts:
          action.payload.search || action.payload.offset === 0
            ? removeDuplicatesById(action.payload.data)
            : removeDuplicates([...state.notDeliveredContacts, ...action.payload.data]),
        isSearched: action.payload.search,
        currentTabTotalContacts: action.payload.total,
        loading: false,
        selectedContacts: state.allSelected
          ? removeDuplicates([
              ...state.selectedContacts,
              ...action.payload.data.map(
                (campaignContact: CampaignContact) => campaignContact.contact
              ),
            ])
          : state.selectedContacts,
      } as CampaignsState;
    case UPDATE_RESPONDED_CONTACTS:
      return {
        ...state,
        respondedContacts:
          action.payload.search || action.payload.offset === 0
            ? removeDuplicatesById(action.payload.data)
            : removeDuplicates([...state.respondedContacts, ...action.payload.data]),
        isSearched: action.payload.search,
        currentTabTotalContacts: action.payload.total,
        loading: false,
        selectedContacts: state.allSelected
          ? removeDuplicates([
              ...state.selectedContacts,
              ...action.payload.data.map(
                (campaignContact: CampaignContact) => campaignContact.contact
              ),
            ])
          : state.selectedContacts,
      } as CampaignsState;
    case UPDATE_UNRESPONDED_CONTACTS:
      return {
        ...state,
        unrespondedContacts:
          action.payload.search || action.payload.offset === 0
            ? removeDuplicatesById(action.payload.data)
            : removeDuplicates([...state.unrespondedContacts, ...action.payload.data]),
        isSearched: action.payload.search,
        currentTabTotalContacts: action.payload.total,
        loading: false,
        selectedContacts: state.allSelected
          ? removeDuplicates([
              ...state.selectedContacts,
              ...action.payload.data.map(
                (campaignContact: CampaignContact) => campaignContact.contact
              ),
            ])
          : state.selectedContacts,
      } as CampaignsState;
    case UPDATE_UNSUBSCRIBED_CONTACTS:
      return {
        ...state,
        unsubscribedContacts:
          action.payload.search || action.payload.offset === 0
            ? removeDuplicatesById(action.payload.data)
            : removeDuplicates([...state.unsubscribedContacts, ...action.payload.data]),
        isSearched: action.payload.search,
        currentTabTotalContacts: action.payload.total,
        loading: false,
        selectedContacts: state.allSelected
          ? removeDuplicates([
              ...state.selectedContacts,
              ...action.payload.data.map(
                (campaignContact: CampaignContact) => campaignContact.contact
              ),
            ])
          : state.selectedContacts,
      } as CampaignsState;
    case UPDATE_NOT_CLICKED_CONTACTS:
      return {
        ...state,
        notClickedContacts:
          action.payload.search || action.payload.offset === 0
            ? removeDuplicatesById(action.payload.data)
            : removeDuplicates([...state.notClickedContacts, ...action.payload.data]),
        isSearched: action.payload.search,
        currentTabTotalContacts: action.payload.total,
        loading: false,
        selectedContacts: state.allSelected
          ? removeDuplicates([
              ...state.selectedContacts,
              ...action.payload.data.map(
                (campaignContact: CampaignContact) => campaignContact.contact
              ),
            ])
          : state.selectedContacts,
      } as CampaignsState;
    case UPDATE_UNFULFILLED_CONTACTS:
      return {
        ...state,
        unfulfilledContacts:
          action.payload.search || action.payload.offset === 0
            ? removeDuplicatesById(action.payload.data)
            : removeDuplicates([...state.unfulfilledContacts, ...action.payload.data]),
        isSearched: action.payload.search,
        currentTabTotalContacts: action.payload.total,
        loading: false,
        selectedContacts: state.allSelected
          ? removeDuplicates([
              ...state.selectedContacts,
              ...action.payload.data.map(
                (campaignContact: CampaignContact) => campaignContact.contact
              ),
            ])
          : state.selectedContacts,
      } as CampaignsState;
    case GET_MESSAGE_COUNT:
      return {
        ...state,
        currentMessageCount: action.payload,
      } as CampaignsState;
    case SET_SELECTED_CONTACTS:
      return {
        ...state,
        selectedContacts: action.payload,
      } as CampaignsState;
    case SET_ALL_SELECTED:
      return {
        ...state,
        allSelected: action.payload,
      } as CampaignsState;
    case CHANGE_TAB_TOTAL_CONTACTS:
      return {
        ...state,
        currentTabTotalContacts: action.payload,
      } as CampaignsState;
    case GET_CAMPAIGN_TEMPLATES:
      return {
        ...state,
        // merge current state by id
        templates:
          action.payload.offset === 0
            ? action.payload.campaigns
            : [...state.templates, ...action.payload.campaigns].reduce(
                (acc: Array<CampaignTemplate>, current: CampaignTemplate) => {
                  const x = acc.find((item: CampaignTemplate) => item.id === current.id);
                  if (!x) {
                    return acc.concat([current]);
                  }
                  return acc;
                },
                []
              ),
        totalCampaignTemplates: action.payload.total,
        loadingTemplates: false,
      } as CampaignsState;
    case CREATE_CAMPAIGN_TEMPLATE:
      return {
        ...state,
        templates: [...state.templates, action.payload],
      } as CampaignsState;
    case UPDATE_CAMPAIGN_TEMPLATE:
      return {
        ...state,
        templates: state.templates.map((template: CampaignTemplate) => {
          if (template.id === action.payload.id) {
            return action.payload;
          }
          return template;
        }),
      } as CampaignsState;
    case DELETE_CAMPAIGN_TEMPLATE:
      return {
        ...state,
        templates: state.templates.filter(
          (template: CampaignTemplate) => template.id !== action.payload
        ),
      } as CampaignsState;
    case SET_CURRENT_TEMPLATE:
      return {
        ...state,
        currentTemplate: action.payload,
      } as CampaignsState;
    case GET_CAMPAIGN_CONTACTS_V2:
      return {
        ...state,
        campaignContacts: mergeCampaignData(state.campaignContacts, action.payload.data),
        totalCampaignContacts: action.payload.total,
      } as CampaignsState;
    case GET_CAMPAIGN_CONTACTS_V2_NEW_SEARCH:
      return {
        ...state,
        campaignContacts: action.payload.data,
        totalCampaignContacts: action.payload.total,
      } as CampaignsState;
    case SET_LOADING_CONTACTS:
      return {
        ...state,
        loadingContacts: action.payload,
      } as CampaignsState;
    case SET_CAMPAIGN_CONTACTS_PARAMS:
      return {
        ...state,
        campaignContactsParams: action.payload,
      } as CampaignsState;
    case SET_LOADING_CAMPAIGN_TEMPLATES:
      return {
        ...state,
        loadingTemplates: action.payload,
      } as CampaignsState;
    default:
      return state;
  }
};

// remove duplicities from array by id
const removeDuplicatesById = (array: Array<CampaignContact>) => {
  return array.filter((campaignContact: CampaignContact, index: number) => {
    return (
      index ===
      array.findIndex(
        (item: CampaignContact) => item.contact.id === campaignContact.contact.id
      )
    );
  });
};

const mergeCampaignData = (currentData: any, newData: any) => {
  const mergedData = [...currentData];
  newData.forEach((data: any) => {
    if (data === null) {
      return;
    }

    const index = mergedData.findIndex(
      (mergedData) => mergedData && mergedData.id === data.id
    );

    if (index > -1) {
      mergedData[index] = data;
    } else {
      mergedData.push(data);
    }
  });

  return mergedData;
};

export default CampaignsReducer;
