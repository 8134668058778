import {
  CustomDataActions,
  CustomDataActionsType,
  CustomDataState,
  CustomObject,
  CustomProperty,
} from '../../../../../shared/types/data';

const CustomDataReducer = (
  state: CustomDataState,
  action: CustomDataActions
): CustomDataState => {
  switch (action.type) {
    case CustomDataActionsType.GET_CUSTOM_OBJECTS:
      return {
        ...state,
        customObjects: action.payload.data,
        totalCount: action.payload.total,
        loading: false,
      };
    case CustomDataActionsType.ADD_CUSTOM_OBJECT:
      return {
        ...state,
        customObjects: [...state.customObjects, action.payload],
        totalCount: state.totalCount + 1,
      };
    case CustomDataActionsType.UPDATE_CUSTOM_OBJECT:
      return {
        ...state,
        current: action.payload,
        customObjects: state.customObjects.map((object: CustomObject) => {
          return object.id === action.payload.id ? action.payload : object;
        }),
      };
    case CustomDataActionsType.GET_CUSTOM_OBJECT:
      return {
        ...state,
        current: action.payload,
      };
    case CustomDataActionsType.DELETE_CUSTOM_OBJECT:
      return {
        ...state,
        customObjects: state.customObjects.filter((object: CustomObject) => {
          return object.id !== action.payload;
        }),
        totalCount: state.totalCount - 1,
        loading: false,
      };
    case CustomDataActionsType.ADD_CUSTOM_OBJECT_PROPERTY:
      // by type its allow null so if no current return state
      if (!state.current) return state;
      return {
        ...state,
        current: {
          ...state.current,
          custom_properties: [
            action.payload,
            ...(state.current?.custom_properties || []),
          ],
        },
        properties: [action.payload, ...(state.properties || [])],
        propertiesTotalCount: state.propertiesTotalCount + 1,
      };
    case CustomDataActionsType.UPDATE_CUSTOM_OBJECT_PROPERTY:
      // by type its allow null so if no current return state
      if (!state.current) return state;
      return {
        ...state,
        current: {
          ...state.current,
          custom_properties: state.current?.custom_properties.map((property) =>
            property.id === action.payload.id ? action.payload : property
          ),
        },
        properties: state.properties.map((property) =>
          property.id === action.payload.id ? action.payload : property
        ),
      };
    case CustomDataActionsType.GET_CUSTOM_OBJECT_PROPERTIES:
      return {
        ...state,
        properties: action.payload.data,
        propertiesTotalCount: action.payload.total,
        loadingProperties: false,
      };
    case CustomDataActionsType.DELETE_CUSTOM_OBJECT_PROPERTY:
      // by type its allow null so if no current return state
      if (!state.current) return state;
      return {
        ...state,
        current: {
          ...state.current,
          custom_properties: state.current?.custom_properties?.filter(
            (property: CustomProperty) => {
              return property.id !== action.payload;
            }
          ),
        },
        properties: state.properties.filter((property: CustomProperty) => {
          return property.id !== action.payload;
        }),
        propertiesTotalCount: state.propertiesTotalCount - 1,
      };
    case CustomDataActionsType.SET_CURRENT:
      return {
        ...state,
        current: action.payload,
      };
    case CustomDataActionsType.CLEAR_CURRENT:
      return {
        ...state,
        current: null,
      };
    case CustomDataActionsType.CLEAR_PROPERTIES:
      return {
        ...state,
        properties: [],
        loadingProperties: true,
      };
    case CustomDataActionsType.UPDATE_FILTER_PARAMS:
      return {
        ...state,
        filterParams: action.payload,
        loading: true,
      };
    case CustomDataActionsType.UPDATE_CUSTOM_OBJECTS_FILTER_PARAMS:
      return {
        ...state,
        customPropertiesFilterParams: action.payload,
        loadingProperties: true,
      };
    default:
      return state;
  }
};

export default CustomDataReducer;
