import { cloneElement, useEffect, useRef, useState } from 'react';

import { styled } from '@/stitches.config';

import { EditorPosition } from './utils';

const StyledMenu = styled('div', {
  position: 'fixed',
  right: 0,
  zIndex: 10,
  marginTop: '0.5rem',
  width: '12rem',
  transformOrigin: 'top right',
  backgroundColor: 'white',
  boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)',
  borderRadius: '0.375rem',
  border: '1px solid rgba(0, 0, 0, 0.1)',
  overflowY: 'auto', // Make the menu scrollable
  maxHeight: '10rem', // Limit the visible options to 5
  display: 'flex',
  flexDirection: 'column',
});

const StyledUserRow = styled('div', {
  padding: '4px',
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  variants: {
    selected: {
      true: {
        color: 'white',
        background: '#CFD4F2',
      },
    },
  },
});

const StyledUserImage = styled('img', {
  display: 'inline-block',
  height: '1.5rem',
  width: '1.5rem',
  borderRadius: '50%',
});

const StyledUserName = styled('p', {
  marginLeft: '0.75rem',
  fontSize: '0.875rem',
  fontWeight: 500,
  variants: {
    selected: {
      true: {
        color: '#2F3AC3',
      },
      false: {
        color: '#201F27',
      },
    },
  },
});

export type Option = {
  id: number | string;
  attachment?: {
    url?: string;
  };
  action?: string;
  icon?: any;
  name: string;
  email?: string;
  value?: string;
};

const avatarSvg = (index: number, i: number): JSX.Element => (
  <svg
    style={{
      height: '1.5rem',
      width: '1.5rem',
      color: index === i ? 'white' : '#d1d5db',
      borderRadius: '50%',
    }}
    fill="currentColor"
    viewBox="0 0 24 24"
  >
    <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
  </svg>
);

export const Menu = ({
  source,
  options,
  index,
  complete,
  position,
}: {
  source: string;
  options: Option[];
  index: number;
  complete: (option: Option) => void;
  position: EditorPosition | null;
}) => {
  const [mouseHoverIndex, setMouseHoverIndex] = useState<number | null>(null);
  const menuRef = useRef<HTMLDivElement>(null);

  // Scroll to the selected option (move the menu up or down)
  useEffect(() => {
    if (menuRef.current && index !== null) {
      const selectedElement = menuRef.current.children[index] as HTMLElement;
      selectedElement.scrollIntoView({ block: 'nearest' });
    }
  }, [index]);

  if (options.length === 0) return null;
  if (!position) return;

  const renderImage = (option: Option, index: number, i: number) => {
    if (option?.attachment?.url) {
      return (
        <StyledUserImage
          src={option?.attachment?.url ?? avatarSvg(index, i).toString()}
          alt={avatarSvg(index, i).toString()}
        />
      );
    } else if (option?.icon) {
      return cloneElement(option.icon, {
        style: {
          margin: 4,
          color: index !== i ? '#d1d5db' : undefined,
        },
      });
    } else {
      return avatarSvg(index, i);
    }
  };

  // Mouse hover index overwrites the keyboard index
  const isHighlighted = (optionIndex: number) =>
    index === optionIndex || mouseHoverIndex === optionIndex;
  const isHighlightedMouse = (optionIndex: number) => mouseHoverIndex === optionIndex;

  return (
    <StyledMenu
      ref={menuRef}
      id={source}
      style={{
        bottom: window.innerHeight - position?.top + 30,
        left: position?.left,
      }}
    >
      {options.map((option: Option, optionIndex: number) => (
        <StyledUserRow
          key={option.id}
          selected={isHighlighted(optionIndex)}
          style={
            isHighlightedMouse(optionIndex)
              ? { backgroundColor: 'rgba(207, 212, 242, 0.4)' }
              : {}
          }
          onMouseDown={(e) => {
            e.preventDefault();
            if (mouseHoverIndex === optionIndex)
              return complete(options[mouseHoverIndex]);
            complete(option);
          }}
          onMouseEnter={() => setMouseHoverIndex(optionIndex)}
          onMouseLeave={() => setMouseHoverIndex(null)}
        >
          {renderImage(option, index, optionIndex)}

          <StyledUserName selected={isHighlighted(optionIndex)}>
            {option.name || option.email}
          </StyledUserName>
        </StyledUserRow>
      ))}
    </StyledMenu>
  );
};
