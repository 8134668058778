import { ErrorBoundary, Scope } from '@sentry/react';
import { Helmet } from 'react-helmet-async';

import { usePageView } from '@/shared/hooks';
import { SettingsLayout } from '@/shared/layouts/SettingsLayout';
import { Box, Button, Flex, VStack } from '@/shared/ui';

import { AddObject } from './AddObject';
import { DataView } from './DataView';

export const CustomData = () => {
  usePageView();

  return (
    <>
      <Helmet>
        <title>Whippy | Custom data</title>
      </Helmet>
      <SettingsLayout
        background="#fafafa"
        breadcrumbs={[
          { title: 'Data', path: '/settings/data' },
          { title: 'Custom Objects', path: `/settings/data` },
        ]}
        actions={<AddObject />}
        width="100%"
      >
        <DataErrorBoundary>
          <DataView />
        </DataErrorBoundary>
      </SettingsLayout>
    </>
  );
};

export const DataErrorBoundary = ({ children }: { children: React.ReactElement }) => {
  if (!children) return null;

  const errorTag = `custom_data`;

  return (
    <ErrorBoundary
      fallback={<ErrorFallback />}
      beforeCapture={(scope: Scope) => {
        scope.setTag('ErrorLocation', errorTag);
      }}
      showDialog={false}
    >
      {children}
    </ErrorBoundary>
  );
};

const ErrorFallback = () => {
  return (
    <Flex
      direction="column"
      align="center"
      justify="center"
      css={{
        width: '100%',
        height: '100%',
      }}
    >
      <VStack align={'center'} gap={3}>
        <Box
          css={{
            fontWeight: 'bold',
          }}
        >
          Oops something went wrong ;(
        </Box>
        <Box>Please refresh, if the issue persists contact support.</Box>
        <Box>
          <Button variant="gray" onClick={() => window.location.reload()}>
            Refresh
          </Button>
        </Box>
      </VStack>
    </Flex>
  );
};
