/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';

import { FormFieldWrapper, helpers, SelectCombobox } from '../../../forms';
import { ActionProps } from '.';

export const UpdateConversationStatusAction = (props: ActionProps) => {
  const { index } = props;
  return (
    <FormFieldWrapper
      label="Update Conversation Status"
      tooltip="Update the status of the conversation when this action is executed"
      name={`actions[${index}].params.status`}
    >
      <SelectCombobox
        placeholder={helpers.displaySelectedItem}
        isDropdown={true}
        name="update_conversation_status"
        options={[
          {
            value: 'open',
            type: 'Open',
          },
          {
            value: 'closed',
            type: 'Closed',
          },
        ]}
      />
    </FormFieldWrapper>
  );
};
