export const comparisonOperatorsMapping = {
  text: [
    { label: 'Contains', value: 'contains' },
    { label: 'Does not contain', value: 'not_contains' },
    { label: 'Starts with', value: 'start' },
    { label: 'Does not start with', value: 'not_start' },
    { label: 'Ends with', value: 'end' },
    { label: 'Does not end with', value: 'not_end' },
    { label: 'Is exactly', value: '==' },
    { label: 'Is not exactly', value: '!=' },
    { label: 'Has any value', value: '!null' },
    { label: 'Has no value', value: 'null' },
  ],
  tag: [
    { label: 'Is exactly', value: '==' },
    { label: 'Is not exactly', value: '!=' },
  ],
  list: [
    { label: 'Is exactly', value: '==' },
    { label: 'Is not exactly', value: '!=' },
  ],
  campaign: [
    { label: 'Is exactly', value: '==' },
    { label: 'Is not exactly', value: '!=' },
  ],
  sequence: [
    { label: 'Is exactly', value: '==' },
    { label: 'Is not exactly', value: '!=' },
    { label: 'Has any value', value: '!null' },
    { label: 'Has no value', value: 'null' },
  ],
  join: [{ label: 'Is exactly', value: '==' }],
  number: [
    { label: 'Equals', value: '==' },
    { label: 'Does not equal', value: '!=' },
    { label: 'Less than', value: '<' },
    { label: 'Less than or equal', value: '<=' },
    { label: 'Greater than', value: '>' },
    { label: 'Greater than or equal', value: '>=' },
    { label: 'Has any value', value: '!null' },
    { label: 'Has no value', value: 'null' },
  ],
  float: [
    { label: 'Equals', value: '==' },
    { label: 'Does not equal', value: '!=' },
    { label: 'Less than', value: '<' },
    { label: 'Less than or equal', value: '<=' },
    { label: 'Greater than', value: '>' },
    { label: 'Greater than or equal', value: '>=' },
    { label: 'Has any value', value: '!null' },
    { label: 'Has no value', value: 'null' },
  ],
  boolean: [
    { label: 'Is', value: '==' },
    { label: 'Is Not', value: '!=' },
  ],
  date: [
    { label: 'Equals', value: '==' },
    { label: 'Does not equal', value: '!=' },
    { label: 'Before', value: '<' },
    { label: 'On or before', value: '<=' },
    { label: 'After', value: '>' },
    { label: 'On or after', value: '>=' },
    { label: 'Has any value', value: '!null' },
    { label: 'Has no value', value: 'null' },
  ],
  map: [
    { label: 'Has key', value: 'has_key' },
    { label: 'Does not have key', value: 'not_has_key' },
  ],
  'birth-date': [
    { label: 'Equals', value: '==' },
    { label: 'Has any value', value: '!null' },
    { label: 'Has no value', value: 'null' },
  ],
};
