import { SequenceContactStatusType } from '@/shared/types/sequences';

export const step_contact_object = {
  id: 'unique-id-for-contact_message',
  label: 'Step Contact',
  key: 'step_contact',
  inserted_at: 'current-timestamp',
  updated_at: 'current-timestamp',
  custom_properties: [
    {
      default: '',
      id: 'step_contact-step-created',
      label: 'Step Created',
      type: 'date',
      key: 'inserted_at',
      required: true,
      inserted_at: 'current-timestamp',
      updated_at: 'current-timestamp',
      custom_object_id: 'unique-id-for-contact',
    },
    {
      default: '',
      id: 'tep_contact_status',
      label: 'Step Status',
      type: 'combobox',
      key: 'status',
      required: true,
      inserted_at: 'current-timestamp',
      updated_at: 'current-timestamp',
      custom_object_id: 'unique-id-for-contact',
      options: [
        {
          label: 'Active',
          value: SequenceContactStatusType.ACTIVE,
        },
        {
          label: 'Pending',
          value: SequenceContactStatusType.PENDING,
        },
        {
          label: 'Complete',
          value: SequenceContactStatusType.COMPLETE,
        },
        {
          label: 'Finished',
          value: SequenceContactStatusType.FINISHED,
        },
        {
          label: 'Moved',
          value: SequenceContactStatusType.MOVED,
        },
        {
          label: 'Removed',
          value: SequenceContactStatusType.REMOVED,
        },
        {
          label: 'Inactive',
          value: SequenceContactStatusType.INACTIVE,
        },
      ],
    },
  ],
};

export const step_position_filter = {
  default: '',
  id: 'step_contact-step_id',
  label: 'Step Position',
  type: 'combobox',
  key: 'step_id',
  required: true,
  inserted_at: 'current-timestamp',
  updated_at: 'current-timestamp',
  custom_object_id: 'unique-id-for-contact',
};
