import { ReportsCollapsible } from '@/shared/components/reports/ReportsCollapsible';
import { Box, VStack } from '@/shared/ui';
import { styled } from '@/stitches.config';

type EmptyStateAnalyticsProps = {
  /** title of the chart */
  title?: string;
  /** the purpose of the chart */
  tooltipContent?: string;
};

export const EmptyStateAnalytics = ({
  title,
  tooltipContent,
}: EmptyStateAnalyticsProps) => {
  return (
    <ReportsCollapsible title={title || ''} description={tooltipContent || ''}>
      <VStack gap={2} css={{ height: 450, mt: 24 }}>
        <ChartTitle css={{ textAlign: 'center', m: 'auto' }}>
          No data to display yet. Try again later
        </ChartTitle>
      </VStack>
    </ReportsCollapsible>
  );
};

const ChartTitle = styled(Box, {
  fontSize: 18,
  fontWeight: 700,
  mr: 10,
});
