import { styled } from '@stitches/react';
import { HiCheck } from 'react-icons/hi';

import { Avatar, Checkbox, Flex } from '@/shared/ui';

import { ComboboxItemProps, TestDataType } from './types';

export const ComboboxMultiselectItem = ({
  checked,
  label,
  color,
}: ComboboxItemProps<string>) => {
  return (
    <>
      <ComboboxCheckbox selected={checked}>{checked && <HiCheck />}</ComboboxCheckbox>
      <StyledItem
        data-testid="combobox-option-label"
        css={
          color
            ? {
                color: color === 'white' || color === '#fff' ? '$blackA' : '$hiContrast',
                padding: '2px 8px',
                fontSize: 12,
                position: 'relative',
                '&:before': {
                  content: '',
                  display: 'block',
                  width: '100%',
                  height: '100%',
                  backgroundColor: color,
                  opacity: 0.184,
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  borderRadius: 3,
                },
                '[data-active-item] &': {
                  color: '$white',
                },
              }
            : {}
        }
      >
        {label}
      </StyledItem>
    </>
  );
};

export const ComboboxMultiselectItemWithAvatar = ({
  checked,
  label,
}: ComboboxItemProps<TestDataType>) => {
  return (
    <>
      <Checkbox css={{ minWidth: 15, mr: 5 }} checked={checked} />
      <div
        style={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        <Flex>
          <Avatar css={{ marginRight: 5 }} fallback={label.image} src={label.image} />
          {label?.name}
        </Flex>
      </div>
    </>
  );
};

const ComboboxCheckbox = styled('div', {
  width: '$3',
  height: '$3',
  minWidth: '$3',
  borderRadius: '$1',
  color: 'primary',
  padding: '2px',
  fontSize: '14px',
  alignItems: 'center',
  display: 'inline-flex',
  justifyContent: 'center',
  boxShadow: 'inset 0 0 0 1px $colors$slate7 !important',
  marginRight: '7px',
  variants: {
    selected: {
      true: {
        border: 'none',
        backgroundColor: '$primaryColor',
        color: 'white',
        boxShadow: 'inset 0 0 0 1px $primaryColor !important',
      },
    },
  },
});

const StyledItem = styled('div', {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  borderRadius: 3,
});
