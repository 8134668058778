export const ContactSource = {
  FACEBOOK: 'facebook',
  GOOGLE_ORGANIC: 'googleorganic',
  DIRECT: 'direct',
  YELP: 'yelp',
  REFERRAL: 'referral',
  OTHER: 'other',
  WEBSITE: 'website',
  GOOGLE_AD: 'googlead',
  INSTAGRAM: 'instagram',
  BING: 'bing',
  DUCKDUCKGO: 'duckduckgo',
  YAHOO: 'yahoo',
  LINKEDIN: 'linkedin',
};

export const ContactSourceCopy = {
  [ContactSource.FACEBOOK]: 'Facebook',
  [ContactSource.GOOGLE_ORGANIC]: 'Google Organic',
  [ContactSource.GOOGLE_AD]: 'Google Ad',
  [ContactSource.DIRECT]: 'Direct Search',
  [ContactSource.YELP]: 'Yelp',
  [ContactSource.REFERRAL]: 'Referral',
  [ContactSource.OTHER]: 'Other',
  [ContactSource.WEBSITE]: 'Website',
  [ContactSource.INSTAGRAM]: 'Instagram',
  [ContactSource.BING]: 'Bing',
  [ContactSource.DUCKDUCKGO]: 'Duckduckgo',
  [ContactSource.YAHOO]: 'Yahoo',
  [ContactSource.LINKEDIN]: 'LinkedIn',
};

export const ContactSourceColor = {
  [ContactSource.FACEBOOK]: '#4299E1',
  [ContactSource.GOOGLE_ORGANIC]: '#48BB78',
  [ContactSource.GOOGLE_AD]: '#38B2AC',
  [ContactSource.DIRECT]: '#0BC5EA',
  [ContactSource.YELP]: 'red',
  [ContactSource.REFERRAL]: '#9F7AEA',
  [ContactSource.OTHER]: '#ED8936',
  [ContactSource.WEBSITE]: '#A0AEC0',
  [ContactSource.INSTAGRAM]: '#97266D',
  [ContactSource.BING]: '#333333',
  [ContactSource.DUCKDUCKGO]: '#2F855A',
  [ContactSource.YAHOO]: '#9F7AEA',
  [ContactSource.LINKEDIN]: '#2C5282',
};
