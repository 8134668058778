import React, { useCallback, useEffect } from 'react';

import { useDomainsContext } from '@/pages/domains/context/DomainsContext';
import { SingleSelect } from '@/shared/components/SingleSelect';
import { Domain } from '@/shared/types/domains';
import { Fieldset, Label } from '@/shared/ui';

type DomainSelectProps = {
  /** the domain id value */
  value: string;
  /** change the domain id value */
  onChange: (domain: string) => void;
  /** is the select disabled? */
  disabled?: boolean;
  /** is the label hidden? */
  hideLabel?: boolean;
};

export const DomainSelect = (props: DomainSelectProps) => {
  const { value, onChange, disabled, hideLabel } = props;

  const {
    domainsState: { activeDomains },
    getActiveDomains,
  } = useDomainsContext();

  useEffect(() => {
    getActiveDomains();
  }, []);

  const getDomainById = (id: string) =>
    activeDomains.find((domain: Domain) => domain.id === id);

  const handleChangeDomain = useCallback(
    async (newDomainId: string) => {
      onChange?.(newDomainId);
    },
    [onChange]
  );

  return activeDomains?.length > 0 ? (
    <Fieldset>
      {!hideLabel && <Label>Select a Default Domain</Label>}
      <SingleSelect
        defaultPlaceholder={getDomainById(value || '')?.name || 'Select a domain'}
        selectItem={value || ''}
        setSelectItem={handleChangeDomain}
        options={activeDomains.map((domain: Domain) => ({
          type: domain?.name || '',
          value: domain.id,
        }))}
        disabled={disabled}
        isDropdown
      />
    </Fieldset>
  ) : null;
};
