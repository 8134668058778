/* eslint-disable react-hooks/exhaustive-deps */
import Fuse from 'fuse.js';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useMedia, usePrevious } from 'react-use';

import { SidebarNavigationContainer } from '@/shared/components/navigation/SideNavigationContainer';
import { usePageView } from '@/shared/hooks';
import { Contact } from '@/shared/types';
import { Drawer, DrawerContent, DrawerOverlay, DrawerPortal } from '@/shared/ui';

import { ContactsList } from './ContactsList';
import { useContacts } from './context/ContactContext';
import { ContactEditor } from './editor';
import { ContactBulkActions } from './editor/ContactBulkActions';

export const AllContacts = (): JSX.Element => {
  usePageView();
  const contactContext = useContacts();
  const {
    getPartOfContacts,
    isOpen,
    onClose,
    contactState,
    setAllContactsChecked,
    setSearchResultChecked,
  } = contactContext;
  const {
    contacts,
    filteredContacts,
    loading,
    isFiltered,
    isSearched,
    contactsTotal,
    allContactsChecked,
    searchResultChecked,
  } = contactState;

  const isDesktop = useMedia('(min-width: 912px)');
  const [offset, setOffset] = useState(0);
  const [sortedContacts, setSortedContacts] = useState(contacts);
  const [filters, setFilters] = useState({});
  const previousFilters = usePrevious(filters) || {};

  const saveFilters = (filters: any) => {
    setFilters(filters);
  };

  const [checkedContacts, setCheckedContacts] = useState<Array<Contact>>([]);
  const [renderedContacts, setRenderedContacts] = useState<Array<Contact>>([]);

  useEffect(() => {
    // make request to load next batch
    // when filters have not been applied
    if (Object.keys(filters).length === 0) {
      // using this condition instead of isFiltered bcs it seems like this useEffect executes before the isFiltered update
      getPartOfContacts({ offset });
    }
  }, [offset]);

  useEffect(() => {
    // this fixes the bug where when removing a filter no contacts are loaded
    if (Object.keys(previousFilters).length > 0) {
      getPartOfContacts({ offset: 0 });
    }
  }, [filters]);

  const [search, setSearch] = useState('');

  useEffect(() => {
    const sorted = renderedContacts.sort((a: Contact, b: Contact) =>
      ((a || {}).name || (a || {}).phone || '').localeCompare(
        (b || {}).name || (b || {}).name || ''
      )
    );

    if (isSearched) {
      const fuse = new Fuse(renderedContacts, {
        keys: ['name', 'phone'],
      });

      const result = fuse.search(search);

      setSortedContacts(result.map((r) => r.item));
    } else {
      setSortedContacts(sorted);
    }

    // if all contacts should be checked
    // set the newly rendered contact batch as checked
    if (allContactsChecked) {
      setCheckedContacts(sorted);
    }
  }, [renderedContacts]);

  useEffect(() => {
    setCheckedContacts([]);
  }, []);

  useEffect(() => {
    // if filters are applied set the filtered list
    // as the current rendered contacts
    if (isFiltered) {
      setRenderedContacts(filteredContacts);
    } else {
      isSearched ? null : setRenderedContacts(contacts);
    }
  }, [contacts, filteredContacts]);

  return (
    <>
      <Helmet>
        <title>Whippy | All Contacts</title>
      </Helmet>

      {/* list of contacts for the given page */}
      <ContactsList
        title="All Contacts"
        contacts={isFiltered ? filteredContacts : contacts}
        setOffset={setOffset}
        setCheckedContacts={setCheckedContacts}
        saveFilters={saveFilters}
        filters={filters}
        renderedContacts={renderedContacts}
        setRenderedContacts={setRenderedContacts}
        checkedContacts={checkedContacts}
        sortedContacts={sortedContacts}
        isGroup={false}
        isUpload={false}
        loading={loading}
        isFiltered={isFiltered}
        searchOrFiltersApplied={isFiltered || isSearched}
        totalContacts={contactsTotal}
        setSearchResultChecked={setSearchResultChecked}
        searchResultChecked={searchResultChecked}
        setAllContactsChecked={setAllContactsChecked}
        allContactsChecked={allContactsChecked}
        search={search}
        setSearch={setSearch}
      />
      {/* desktop contact panel */}
      {isDesktop ? (
        <SidebarNavigationContainer
          defaultWidth={360}
          minWidth={360}
          maxWidth={450}
          name="CONTACTS"
          dragDirection="right"
          disableCollapse
        >
          {checkedContacts.length > 0 ? (
            <ContactBulkActions
              totalContacts={contactsTotal}
              checkedContacts={checkedContacts}
              checkContact={(contacts: string[] | Contact[] | Contact[]) => {
                if (typeof contacts[0] === 'string') {
                  // Handle the case where contacts is a string array
                  // You need to convert the strings to Contact objects
                  return;
                } else {
                  // Handle the case where contacts is a Contact array
                  setCheckedContacts(contacts as Contact[]);
                }
              }}
              allContactsChecked={allContactsChecked}
              setSearchResultChecked={setSearchResultChecked}
              setAllContactsChecked={setAllContactsChecked}
              bulkMessageAudience={{ contacts: 'all' }}
              isContactsPage={true}
              isGroupsPage={false}
              isUploadsPage={false}
            />
          ) : (
            <ContactEditor />
          )}
        </SidebarNavigationContainer>
      ) : null}
      {/* mobile contact panel */}
      {!isDesktop ? (
        <Drawer open={isOpen}>
          <DrawerPortal>
            <DrawerOverlay />
            <DrawerContent
              onEscapeKeyDown={onClose}
              onPointerDownOutside={onClose}
              side="bottom"
              css={{ height: '90%', overflowY: 'scroll' }}
            >
              <ContactEditor />
            </DrawerContent>
          </DrawerPortal>
        </Drawer>
      ) : null}
    </>
  );
};
