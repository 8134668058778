import { useEffect, useState } from 'react';
import { useMedia } from 'react-use';

// New custom hook that uses useMedia
export const useResponsiveColumns = () => {
  const isLargeDesktop = useMedia('(min-width: 1500px)');
  const isDesktop = useMedia('(min-width: 1200px)');
  const isTablet = useMedia('(min-width: 768px) and (max-width: 1200px)');
  const isMobile = useMedia('(max-width: 767px)');

  const [columns, setColumns] = useState(1);

  useEffect(() => {
    if (isLargeDesktop) {
      setColumns(4);
    } else if (isDesktop) {
      setColumns(3);
    } else if (isTablet) {
      setColumns(2);
    } else if (isMobile) {
      setColumns(1);
    }
  }, [isLargeDesktop, isDesktop, isTablet, isMobile]);

  return columns;
};
