import dayjs from 'dayjs';
import React from 'react';

import { Box, Flex, Text, VStack } from '@/shared/ui';
import { MONTH_DAY_YEAR } from '@/shared/utils/timestamps';

type ResponseProps = {
  comment: string;
  updateTime: string;
};

export const ReviewResponse = (props: ResponseProps): JSX.Element => {
  const { updateTime, comment } = props;

  return (
    <VStack gap={4}>
      <VStack gap={2}>
        <Flex justify="between">
          <Text variant="bold" size={3}>
            Response
          </Text>
          <Text variant="gray" size={2} css={{ fontWeight: '500' }}>
            {dayjs(updateTime).format(MONTH_DAY_YEAR)}
          </Text>
        </Flex>
        <Text variant="review">{comment}</Text>
      </VStack>
    </VStack>
  );
};

export const ReviewFooter = ({ children }: { children: React.ReactNode }) => {
  return <Box>{children}</Box>;
};
