import { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { HiTrash } from 'react-icons/hi';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { useMedia } from 'react-use';

import { ConfirmationDialog } from '@/shared/components/ConfirmationDialog';
import { usePageView } from '@/shared/hooks';
import { SettingsLayout } from '@/shared/layouts';
import { Button, Flex, IconButton, VStack } from '@/shared/ui';
import { styled } from '@/stitches.config';

import { TeamsErrorBoundary } from '../';
import { useTeams } from '../context/TeamsContext';
import { BusinessHours } from './BusinessHours';
import { TeamMembers } from './TeamMembers';
import { UpdateTeam } from './UpdateTeam';

export const Team = () => {
  usePageView();
  const isDesktop = useMedia('(min-width: 912px)');

  const {
    teamsState: { current },
    createTeamMember,
    getTeam,
    deleteTeam,
    updateTeam,
  } = useTeams();

  const params = useParams<{ id: string }>();
  const history = useHistory();
  const isTeamPage = useRouteMatch('/settings/teams/:id');

  const [selectedUsers, setSelectedUsers] = useState({
    users: [],
  });

  // on mount get the id from the url and fetch the team if the id does not match the current team
  useEffect(() => {
    if (params?.id && current?.id !== params?.id) {
      getTeam(params?.id);
    }
  }, [params?.id, current?.id]);

  // add team members
  const handleAddMembers = useCallback(async () => {
    if (selectedUsers.users.length) {
      const addingMembers = selectedUsers.users.map((id: number, index: number) =>
        createTeamMember(current?.id as string, id, index === 0)
      );
      try {
        if (current?.id) {
          await Promise.all(addingMembers);
        }
      } catch (error) {
        console.error('Error add team members schedule:', error);
      } finally {
        setSelectedUsers({ users: [] });
      }
    }
  }, [selectedUsers.users, current?.id]);

  const handleDeleteTeam = useCallback(() => {
    current?.id && deleteTeam(current?.id);
    isTeamPage && history.push(`/settings/teams`);
  }, [deleteTeam, current?.id]);

  return (
    <>
      <Helmet>
        <title>Whippy | Team</title>
      </Helmet>
      <SettingsLayout
        breadcrumbs={[
          { title: 'Teams', path: '/settings/teams' },
          { title: current?.name || '', path: `/settings/teams/${current?.id}` },
        ]}
        actions={
          current && (
            <HeaderButtons justify={'end'}>
              <ConfirmationDialog
                title="Delete Team"
                description="Are you sure want to delete your team? This action cannot be undone."
                onConfirm={handleDeleteTeam}
                confirmButtonTitle="Yes, Delete Team"
              >
                <IconButton size={2} variant="outline">
                  <HiTrash />
                </IconButton>
              </ConfirmationDialog>
              <UpdateTeam team={current} onUpdate={updateTeam} />
              <SaveButton
                onClick={handleAddMembers}
                size={2}
                data-testid="create-team-member-button"
                disabled={!selectedUsers.users.length}
              >
                Save
              </SaveButton>
            </HeaderButtons>
          )
        }
      >
        <TeamsErrorBoundary>
          <PageContainer
            align="start"
            justify="center"
            css={{ padding: isDesktop ? '30px' : '40px 20px' }}
          >
            <ContentWrapper gap={6}>
              <TeamMembers
                selectedUsers={selectedUsers}
                setSelectedUsers={setSelectedUsers}
              />
              <BusinessHours />
            </ContentWrapper>
          </PageContainer>
        </TeamsErrorBoundary>
      </SettingsLayout>
    </>
  );
};

export const SideNavigation = styled(Flex, {
  position: 'relative',
  width: '100%',
  flexShrink: 0,
  mb: 32,
  pb: '$4',
  variants: {
    border: {
      false: {
        borderBottom: 'none !important',
      },
      true: {
        borderBottom: 'thin solid $gray4',
      },
    },
  },
  '&::after': {
    content: '""',
    position: 'absolute',
    right: '-$6',
    bottom: 0,
    left: '-$6',
    height: '1px',
    background: '$gray4',
  },
});

export const PageContainer = styled(Flex, {
  backgroundColor: 'white',
  minHeight: '100%',
  overflow: 'auto',
});

export const ContentWrapper = styled(VStack, {
  width: '100%',
  maxWidth: '600px',
  margin: '0 auto',
});

const HeaderButtons = styled(Flex, {
  minWidth: '150px',
});

const SaveButton = styled(Button, {
  ml: 10,
});
