import { RefObject, useRef } from 'react';

/* General focus hook */
export const useFocus = (): (RefObject<HTMLDivElement> | (() => void) | any)[] => {
  /* 
    useRef returns a mutable ref object whose .current property is initialized
    to the passed argument (initialValue). The returned object will persist for 
    the full lifetime of the component. - https://reactjs.org/docs/hooks-reference.html
    */
  const htmlElRef = useRef<HTMLDivElement>(null);
  const setFocus = () => {
    if (htmlElRef.current) {
      htmlElRef.current.focus();
    }
  };

  /* 
     The hook returns a reference to the HTMLElement to focus and function to
     set focus to that element. 
    */
  return [htmlElRef, setFocus];
};
