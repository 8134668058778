/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Table } from '@/shared/components/Table';
import {
  Box,
  Button,
  Flex,
  Skeleton,
  Switch,
  SwitchThumb,
  Td,
  Tr,
  VStack,
} from '@/shared/ui';
import { styled } from '@/stitches.config';

import { AddQuestion } from './AddQuestion';
import { useQuestions } from './context/QuestionsContext';
import { DeleteQuestion } from './DeleteQuestion';
import { EditQuestion } from './EditQuestion';

type QueryParams = 'from' | 'size';
type QueryMap = Record<QueryParams, string>;

export const QuestionsTable = (): JSX.Element => {
  const questionsContext = useQuestions();
  const {
    getQuestions,
    enableQuestion,
    disableQuestion,
    deleteQuestion,
    questionsState,
  } = questionsContext;
  const { questions, loading, count } = questionsState;

  const [searchValue, setSearchValue] = useState('');
  const [filteredData, setFilteredData] = useState({});

  const [offset, setOffset] = useState(0);
  const limit = 20;

  useEffect(() => {
    const params: QueryMap = {
      from: offset.toString(),
      size: limit.toString(),
    };
    const queryParams = new URLSearchParams(params).toString();

    getQuestions(queryParams);
  }, [offset]);

  const data = useMemo(
    () => (loading ? Array(5).fill({}) : questions),
    [loading, questions]
  );

  const dataToDisplay = searchValue.length ? filteredData : data;

  const isEnabled = (props: any) => {
    const rowStatus = props.row.original.enabled;
    if (rowStatus === true) {
      return true;
    } else {
      return false;
    }
  };

  const toggleQuestion = (props: any) => {
    if (props.row.original.enabled) {
      disableQuestion(props.row.original);
    } else {
      enableQuestion(props.row.original);
    }
  };

  const handleSearch = (value: string) => {
    setSearchValue(value);
    const filter = questions.filter((question) => {
      return question.questions[0]?.question?.toLowerCase().includes(value.toLowerCase());
    });
    setFilteredData(filter);
  };

  const columns = useMemo(
    () =>
      loading
        ? [
            {
              Header: 'Automated Questions',
              colWidth: '100%',
              Cell: (
                <>
                  <Skeleton variant="subheading" css={{ maxWidth: '50%', m: 0 }} />
                  <Skeleton variant="subheading" css={{ maxWidth: '80%', mb: 0 }} />
                </>
              ),
            },
            {
              Header: (): null => null,
              disableSortBy: true,
              id: 'expander',
              Cell: <Skeleton variant="icon" />,
            },
          ]
        : [
            {
              Header: 'Automated Questions',
              accessor: 'question',
              colWidth: '100%',
              Cell: (props: { value: any; row: any }) => (
                <Flex direction="column">
                  <QuestionTitle>
                    {props.row.original.questions[0].question}
                  </QuestionTitle>
                </Flex>
              ),
            },
            {
              Header: 'Enabled',
              disableSortBy: true,
              id: 'status',
              Cell: (props: any) => (
                <Switch checked={isEnabled(props)} onClick={() => toggleQuestion(props)}>
                  <SwitchThumb />
                </Switch>
              ),
            },
            {
              // Make an expander cell
              Header: (): null => null,
              disableSortBy: true,
              id: 'expander',
              Cell: ({ row }: any) => (
                <Flex css={{ width: 155 }} justify="end">
                  <DeleteQuestion
                    alertAction={() => {
                      deleteQuestion(row.original);
                    }}
                    isWithIcon={true}
                  />
                  <span {...row.getToggleRowExpandedProps()}>
                    <Button size={2} variant="gray">
                      Edit Question
                    </Button>
                  </span>
                </Flex>
              ),
            },
          ],
    [loading]
  );

  const renderRowSubComponent = useCallback(
    ({ row }: any) => <EditQuestion row={row.original} />,
    []
  );

  return (
    <Table
      data={dataToDisplay}
      columns={columns}
      sortable={true}
      totalCount={count}
      offset={offset}
      setOffset={setOffset}
      limit={limit}
      renderRowSubComponent={renderRowSubComponent}
      searchFilter={true}
      searchValue={searchValue}
      setSearchValue={handleSearch}
      empty={
        <Tr>
          <Td
            colSpan={columns.length}
            css={{ textAlign: 'center', backgroundColor: 'white' }}
          >
            <Flex css={{ width: '100%' }} direction="column">
              <VStack gap={3}>
                <Box>
                  Create your first automation. Save time by automating common questions.
                </Box>
                <Box>
                  <AddQuestion />
                </Box>
              </VStack>
            </Flex>
          </Td>
        </Tr>
      }
    />
  );
};

const QuestionTitle = styled(Box, {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  maxWidth: 250,
  textOverflow: 'ellipsis',
});
