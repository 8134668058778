import { FilterType, JoinType } from '@/shared/types/filter';

export const calculateFilterCount = (filters: (FilterType | JoinType)[]) => {
  let count = 0;

  const countFilters = (filterArray: (FilterType | JoinType)[]) => {
    filterArray.forEach((filter: FilterType | JoinType) => {
      count += 1; // Count the current filter
      if ('and' in filter && filter.and.length > 0) {
        countFilters(filter.and); // Recursively count 'and' conditions
      }
      if ('or' in filter && filter.or.length > 0) {
        countFilters(filter.or); // Recursively count 'or' conditions
      }
      if ('on' in filter && filter.on.length > 0) {
        countFilters(filter.on); // Recursively count 'on' conditions
      }
    });
  };

  countFilters(filters);
  return count;
};
