/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';

import { useUploads } from '@/contacts/uploads/context/UploadContext';
import { Flex } from '@/shared/ui';

import { UPLOADS } from '../AudienceSelection';
import { AudienceTag } from '.';

type UploadTagsProps = {
  /* an array of upload IDs from campaigns audience */
  upload_ids: string[];
  /** function to remove the tag from the audience */
  onItemDelete: (type: string, id?: string) => void;
  /** if removing the tag is disabled */
  disabled?: boolean;
};

export const UploadTags = (props: UploadTagsProps) => {
  const upload_ids = props.upload_ids;
  const uploads = useUploads();
  const { uploadsState, getUpload } = uploads;
  const { uploads: uploadsList } = uploadsState;

  return (
    <Flex wrap="wrap">
      {upload_ids &&
        upload_ids?.map((upload_id) => {
          return (
            <AudienceTag
              key={upload_id}
              id={upload_id}
              list={uploadsList}
              getObject={getUpload}
              onItemDelete={props.onItemDelete}
              itemType={UPLOADS}
              disabled={props.disabled}
            />
          );
        })}
    </Flex>
  );
};
