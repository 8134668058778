import React from 'react';

import { SingleSelect } from '@/shared/components/SingleSelect';
import { CampaignPermissionTypes } from '@/shared/types/campaigns';
import { Box } from '@/shared/ui';

import { CAMPAIGN_TEMPLATE_PERMISSIONS } from '../context/constants';

type SelectPermissionProps = {
  /** the permission of the campaign template */
  permission?: CampaignPermissionTypes;
  /** set the permission of the campaign template */
  setPermission: (permission: CampaignPermissionTypes) => void;
  /** is the select disabled? */
  disabled?: boolean;
};

export const SelectPermission = (props: SelectPermissionProps): JSX.Element => {
  const {
    permission = CampaignPermissionTypes.ORGANIZATION,
    setPermission,
    disabled,
  } = props;

  const getPermissionName = (value: string) =>
    CAMPAIGN_TEMPLATE_PERMISSIONS.find((permission) => permission.value === value)?.label;

  return (
    <Box>
      <SingleSelect
        disabled={disabled ? true : false}
        selectItem={permission}
        setSelectItem={(permission) =>
          setPermission(permission as CampaignPermissionTypes)
        }
        closeOnClick={true}
        options={CAMPAIGN_TEMPLATE_PERMISSIONS.map((permission) => ({
          type: permission.label,
          value: permission.value,
        }))}
        defaultPlaceholder={getPermissionName(permission || '') || 'Select Permission'}
        isDropdown={true}
      />
    </Box>
  );
};
