import { Signature } from '@/shared/types';
import { SignatureActionTypes, SignaturesActions } from '@/shared/types/signatures';
import { removeDuplicates } from '@/shared/utils/removeDuplicates';

import { SignaturesState } from './SignaturesContext';

const SignaturesReducer: React.Reducer<SignaturesState, SignaturesActions> = (
  state: SignaturesState,
  action: SignaturesActions
) => {
  switch (action.type) {
    case SignatureActionTypes.GET_ALL_SIGNATURES:
      return {
        ...state,
        // merge state and action.payload to remove duplicates
        allSignatures: removeDuplicates([...state.allSignatures, ...action.payload]),
      } as SignaturesState;
    case SignatureActionTypes.CREATE_SIGNATURE:
      return {
        ...state,
        allSignatures: action.payload.name
          ? [...state.allSignatures, action.payload]
          : state.allSignatures,
        signatures: action.payload.name
          ? [...state.allSignatures, action.payload]
          : state.allSignatures,
        totalCount: state.totalCount + 1,
      } as SignaturesState;
    case SignatureActionTypes.EDIT_SIGNATURE:
      return {
        ...state,
        allSignatures: state.allSignatures.map((signature: Signature) => {
          return signature.id === action.payload.id ? action.payload : signature;
        }),
        signatures: state.allSignatures.map((signature: Signature) => {
          return signature.id === action.payload.id ? action.payload : signature;
        }),
      } as SignaturesState;
    case SignatureActionTypes.DELETE_SIGNATURE:
      return {
        ...state,
        allSignatures: state.allSignatures.filter((signature: Signature) => {
          return signature.id !== action.payload;
        }),
        signatures: state.allSignatures.filter((signature: Signature) => {
          return signature.id !== action.payload;
        }),
        totalCount: state.totalCount - 1,
      } as SignaturesState;
    case SignatureActionTypes.SET_CURRENT:
      return {
        ...state,
        current: action.payload,
      } as SignaturesState;
    case SignatureActionTypes.SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      } as SignaturesState;
    case SignatureActionTypes.SET_DEFAULT_USER_SIGNATURE:
      return {
        ...state,
        defaultUserSignatureId: action.payload?.default_signature_id || null,
      } as SignaturesState;
    case SignatureActionTypes.UPDATE_FILTER_PARAMS:
      return {
        ...state,
        filterParams: action.payload,
        loading: true,
      };
    case SignatureActionTypes.GET_SIGNATURES:
      return {
        ...state,
        signatures: action.payload.data,
        totalCount: action.payload.total,
        loading: false,
      };
    default:
      return state;
  }
};

export default SignaturesReducer;
