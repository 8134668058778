import { Box, Card, Flex } from '@/shared/ui';
import { styled } from '@/stitches.config';

export const TemplateCard = styled(Card, {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
  height: '100%',
  boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.01)',
  borderRadius: '$3',
});

export const TemplateCardContainer = styled(Card, {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
  height: 395,
  maxHeight: '100%',
  boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.01)',
  borderRadius: '$3',
});

export const TemplateCardTop = styled(Flex, {
  width: '100%',
  p: 20,
  height: 287,
  borderTopRightRadius: '$3',
  borderTopLeftRadius: '$3',
  maxHeight: 287,
  overflow: 'auto',
});

export const TemplateMessage = styled(Box, {
  px: 20,
  py: 8,
  fontWeight: 500,
  fontSize: '12px',
  color: '#11181C',
  backgroundColor: '$slate3 !important',
  lineHeight: 1.4,
  borderRadius: '20px 20px 20px 6.5px',
  alignItems: 'center',
  display: '-webkit-box',
  '-webkit-line-clamp': 10,
  '-webkit-box-orient': 'vertical',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

export const TemplateMessageContainer = styled(Box, {
  display: 'inline-block',
  borderRadius: '20px 20px 20px 6.5px',
  maxHeight: '100%',
  overflowY: 'auto',
});

export const TemplateTitle = styled(Box, {
  fontSize: '16px',
  fontWeight: 500,
  textTransform: 'capitalize',
  lineHeight: '19px',
  display: '-webkit-box',
  '-webkit-line-clamp': 1,
  '-webkit-box-orient': 'vertical',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

export const TemplateDescription = styled(Box, {
  fontSize: '13px',
  fontWeight: 500,
  lineHeight: '19px',
  display: '-webkit-box',
  '-webkit-line-clamp': 2,
  '-webkit-box-orient': 'vertical',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  color: '$gray9',
});

export const TemplateCardBottom = styled(Flex, {
  py: 20,
  px: 15,
  width: '100%',
  justifyContent: 'space-between',
  borderTop: '1px solid $slate6',
  borderBottomRightRadius: '$1',
  borderBottomLeftRadius: '$1',
});

export const TemplateCardContainerBottom = styled(Flex, {
  py: 10,
  width: '100%',
  justifyContent: 'space-between',
  borderTop: '1px solid $slate6',
  borderBottomRightRadius: '$1',
  borderBottomLeftRadius: '$1',
});
