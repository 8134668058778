/* eslint-disable react-hooks/exhaustive-deps */
import dayjs from 'dayjs';
import React, { useMemo } from 'react';
import { HiChat, HiDotsHorizontal, HiPencilAlt } from 'react-icons/hi';
import { CellProps, Column } from 'react-table';

import { UnsubscribePreference } from '@/pages/contacts/editor/preferences/UnsubscribePreference';
import { AddSingleContactTags } from '@/pages/contacts/editor/tags/AddSingleContactTags';
import { ConversationDrawer } from '@/pages/inbox/drawer';
import { useChannels } from '@/pages/settings/organization/channels/context/ChannelContext';
import { ConfirmationDialogDescription } from '@/pages/settings/organization/users/UsersTable';
import { useUserPreferences } from '@/pages/settings/user/preferences/context/PreferencesContext';
import { ToolTipIconButton } from '@/shared/components/attachments/previewer';
import { ConfirmationDialog } from '@/shared/components/ConfirmationDialog';
import { CopyToClipboard } from '@/shared/components/CopyToClipboard';
import { languages } from '@/shared/components/editor/v2/utils';
import { Contact } from '@/shared/types/contacts';
import {
  Box,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  Flex,
  HStack,
  IconButton,
} from '@/shared/ui';
import { IndeterminateCheckbox } from '@/shared/ui/IndeterminateCheckbox';
import { formatPhoneNumber, isValidUuid } from '@/shared/utils/validations/validations';
import { styled } from '@/stitches.config';

import { ContactPanel } from '../contacts/ContactPanel';
import { useData } from '../context/DataContext';

export const ContactsTableColumns = () => {
  const location = useChannels();
  const { channelsState } = location;
  const { allChannels, channels } = channelsState;

  const isLocationIdValid = (id: string) => {
    return isValidUuid(id) && channels.some((location) => location.id === id);
  };

  const contacts = useData();
  const { blockContact, deleteContact } = contacts;

  // if the location id is passed in, use that
  // otherwise use the preferred location id if it is valid
  // else use the first location in the list
  // fallback to empty string
  const getDrawerLocationId = () => {
    // Check if there's a preferred location and it's valid
    const preferredLocationId = preferences?.inbox?.preferred_location_id;
    if (preferredLocationId && isLocationIdValid(preferredLocationId)) {
      return preferredLocationId;
    }

    // Fallback to the first location if available
    if (channels.length > 0) {
      return channels[0]?.id || '';
    }

    return '';
  };

  // if no prop is given for location id, use the preferred location id
  // if it is set, or else user the first location in the list
  const user_preferences = useUserPreferences();
  const { preferences } = user_preferences;

  // take the default_channel_id and return the name of the channel
  // if it is not found, return the default_channel_id
  const getChannelName = (default_channel_id: string | null) => {
    if (default_channel_id == null || !isValidUuid(default_channel_id)) {
      return default_channel_id || '-';
    }
    const channel = allChannels.find((channel) => channel.id === default_channel_id);
    return channel?.name;
  };

  const columns: Column<Contact>[] = useMemo(() => {
    return [
      {
        Header: ({ getToggleAllRowsSelectedProps }) => (
          <Flex align="center" justify="start">
            {/* Checkbox for selecting all rows */}
            <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            <span style={{ marginLeft: '8px' }}>Name</span>
          </Flex>
        ),
        accessor: 'name',
        key: 'name',
        colWidth: 400,
        Cell: ({ row }: CellProps<Contact>) => (
          <Flex align="center" justify="between">
            <Flex align="center">
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
              <StyledName>{row.original.name || '-'}</StyledName>
            </Flex>
            <HStack gap="2">
              <ContactPanel contact={row.original} deleteContact={deleteContact}>
                <ToolTipIconButton
                  description="Edit Contact"
                  icon={<HiPencilAlt />}
                  size={1}
                />
              </ContactPanel>
              <Box>
                {channels.length > 0 && (
                  <ConversationDrawer
                    contact_id={row.original.id}
                    contact_name={
                      row.original?.name ||
                      formatPhoneNumber(row.original?.phone || '') ||
                      ''
                    }
                    contact_phone={row.original?.phone || ''}
                    location_id={getDrawerLocationId()}
                  >
                    <IconButton>
                      <HiChat />
                    </IconButton>
                  </ConversationDrawer>
                )}
              </Box>
              <Box>
                <ContactActionsMenu
                  contact={row.original}
                  isBlocked={!!row.original?.blocked}
                  blockContact={blockContact}
                  deleteContact={deleteContact}
                />
              </Box>
            </HStack>
          </Flex>
        ),
      },
      {
        Header: 'Phone',
        accessor: 'phone',
        colWidth: 250,
        Cell: ({ value }: { value: string | null }) => (
          <Flex>
            <CopyToClipboard
              copy={formatPhoneNumber(value || '') || ''}
              description="Copy Phone Number"
              successMessage="Phone number copied to clipboard"
            >
              <Box>{formatPhoneNumber(value || '') || '-'}</Box>
            </CopyToClipboard>
          </Flex>
        ),
      },
      {
        Header: 'Email',
        accessor: 'email',
        key: 'email',
        colWidth: 250,
        Cell: ({ value }: { value: string | null }) => (
          <StyledCell>
            <CopyToClipboard
              copy={value || ''}
              description="Copy Email"
              successMessage="Email copied to clipboard"
            >
              <Box>{value || '-'}</Box>
            </CopyToClipboard>
          </StyledCell>
        ),
      },
      {
        Header: 'Address',
        accessor: 'address',
        key: 'address',
        colWidth: 250,
        Cell: ({
          value,
        }: {
          value: {
            address_line_one: string | null;
            address_line_two?: string | null;
            city?: string | null;
            state?: string | null;
            post_code?: string | null;
            country?: string | null;
          } | null;
        }) => <Cell>{value ? value.address_line_one || '-' : '-'}</Cell>,
      },
      {
        Header: 'Birth Date',
        accessor: 'birth_date',
        key: 'birth_date',
        colWidth: 250,
        Cell: ({
          value,
        }: {
          value: {
            day: number | null;
            month: number | null;
            year: number | null;
          } | null;
        }) => (
          <Flex>
            {value && value.day && value.month && value.year
              ? dayjs(`${value.year}-${value.month}-${value.day}`).format('MMM D, YYYY')
              : value && value.month && value.day
                ? dayjs(`2000-${value.month}-${value.day}`).format('MMM D') // Year is arbitrary, used for formatting purposes
                : '-'}
          </Flex>
        ),
      },
      {
        Header: 'Default Channel',
        accessor: 'default_channel_id',
        key: 'default_channel_id',
        colWidth: 250,
        Cell: ({ value }: { value: string | null }) => (
          <Flex>{getChannelName(value)}</Flex>
        ),
      },
      {
        Header: 'Language',
        accessor: 'language',
        key: 'language',
        colWidth: 250,
        Cell: ({ value }: { value: string | null }) => (
          <Flex>{languages.find((lang) => lang.value === value)?.label || '-'}</Flex>
        ),
      },
      {
        Header: 'External ID',
        accessor: 'external_id',
        key: 'external_id',
        colWidth: 250,
        Cell: ({ value }: { value: string | null }) => <Flex>{value || '-'}</Flex>,
      },
      {
        Header: 'Source',
        accessor: 'source',
        key: 'source',
        colWidth: 250,
        Cell: ({ value }: { value: string | null }) => <Flex>{value || '-'}</Flex>,
      },
      {
        Header: 'Blocked',
        accessor: 'blocked',
        key: 'blocked',
        colWidth: 250,
        Cell: ({ value }: { value: boolean }) => <Flex>{value ? 'True' : 'False'}</Flex>,
      },
      {
        Header: 'Created At',
        accessor: 'inserted_at',
        key: 'inserted_at',
        colWidth: 250,
        Cell: ({ value }: { value: string | null }) => (
          <Flex>{dayjs(value).format('MMM D[,] YYYY[,] h:mmA')}</Flex>
        ),
      },
      {
        Header: 'Updated At',
        accessor: 'updated_at',
        key: 'updated_at',
        colWidth: 250,
        Cell: ({ value }: { value: string | null }) => (
          <Flex>{dayjs(value).format('MMM D[,] YYYY[,] h:mmA')}</Flex>
        ),
      },
    ];
  }, [channels]);

  return columns;
};

export const Cell = ({
  value,
  children,
}: {
  value?: string | number | null;
  children?: React.ReactNode;
}) => {
  return <StyledCell>{children || value}</StyledCell>;
};

export const StyledCell = styled(Flex, {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  display: 'block',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 1,
  boxSizing: 'border-box',
  breakInside: 'avoid',
  wordBreak: 'break-word',
});

export const StyledName = styled(Box, {
  marginLeft: 8,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'normal',
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 1,
  boxSizing: 'border-box',
  breakInside: 'avoid',
  wordBreak: 'break-word',
  maxWidth: '185px',
});

interface ContactActionsMenuProps {
  contact: Contact;
  isBlocked: boolean;
  blockContact: (id: string, block: boolean) => void;
  deleteContact: (id: string) => void;
}

export const ContactActionsMenu: React.FC<ContactActionsMenuProps> = ({
  contact,
  isBlocked,
  blockContact,
  deleteContact,
}) => {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <IconButton data-testid="actions-trigger">
          <HiDotsHorizontal />
        </IconButton>
      </DropdownMenuTrigger>
      <DropdownMenuContent css={{ zIndex: 1 }}>
        <AddSingleContactTags contactId={contact.id} isCampaignsPage={false}>
          <DropdownMenuItem data-testid="add-tags" onSelect={(e) => e.preventDefault()}>
            Add Tags
          </DropdownMenuItem>
        </AddSingleContactTags>
        <UnsubscribePreference contact={contact}>
          <DropdownMenuItem onSelect={(e) => e.preventDefault()}>
            <Box>Un-subscribe Contact</Box>
          </DropdownMenuItem>
        </UnsubscribePreference>
        <ConfirmationDialog
          width="432px"
          title={isBlocked ? 'Un-block Contact' : 'Block Contact'}
          description={
            <ConfirmationDialogDescription
              value=""
              description={
                isBlocked
                  ? 'Un-blocking a contact will set their conversations to a closed state. Messages you missed while the contact was blocked are still preserved.'
                  : 'Blocking a contact means you will no longer see their messages in your inbox. You can unblock a contact at any time.'
              }
            />
          }
          onConfirm={() => blockContact(contact.id, !isBlocked)}
          confirmButtonTitle={isBlocked ? 'Yes, Un-block' : 'Yes, Block'}
          cancelButtonTitle="Cancel"
          confirmButtonVariant="redBackground"
          cancelButtonVariant="grayBackground"
        >
          <DropdownMenuItem
            data-testid="block-contact"
            onSelect={(e) => e.preventDefault()}
          >
            {isBlocked ? 'Un-block Contact' : 'Block Contact'}
          </DropdownMenuItem>
        </ConfirmationDialog>
        <ConfirmationDialog
          width="432px"
          title="Delete Contact"
          description={
            <ConfirmationDialogDescription
              value=""
              description="Deleting a contact will archive all conversations and data associated with this contact. This action cannot be undone."
            />
          }
          onConfirm={() => deleteContact(contact.id)}
          confirmButtonTitle="Delete Contact"
          cancelButtonTitle="Cancel"
          confirmButtonVariant="redBackground"
          cancelButtonVariant="grayBackground"
        >
          <DropdownMenuItem
            data-testid="delete-contact"
            onSelect={(e) => e.preventDefault()}
          >
            Delete Contact
          </DropdownMenuItem>
        </ConfirmationDialog>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
