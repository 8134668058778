import { memo, useMemo } from 'react';

import { Domain, DomainSetupType, DomainStatusType } from '@/shared/types/domains';
import { Box, Text } from '@/shared/ui';
import { styled } from '@/stitches.config';

import { getProgressVariant, getStep } from '../utils/utils';
import { StepProgressBar } from './StepProgressBar';

type SetupStepsProps = {
  title: string;
  domain: Domain | null;
  type?: DomainSetupType;
};

export const SetupSteps = memo(({ domain, title, type }: SetupStepsProps) => {
  const statusKey: 'status' | 'links_status' | 'emails_status' = type
    ? `${type}_status`
    : 'status';
  const setupStateKey: 'setup_state' | 'links_setup_state' | 'emails_setup_state' = type
    ? `${type}_setup_state`
    : 'setup_state';

  const step = useMemo(() => {
    return getStep(domain?.[setupStateKey]);
  }, [domain?.[setupStateKey]]);

  return domain?.[statusKey] === DomainStatusType.PENDING ||
    domain?.[statusKey] === DomainStatusType.CANCELLED ? (
    <SetupContainer>
      <Text size="3" variant="fieldTitle">
        {title}
      </Text>
      <StepProgressBar
        step={step?.value}
        color={getProgressVariant(domain?.[statusKey])}
        status={domain?.[statusKey]}
      />
      {step && (
        <Text
          css={{ fontSize: 14, fontWeight: '500', mt: 12 }}
        >{`Step ${step?.value} of 7: ${step?.label}`}</Text>
      )}
    </SetupContainer>
  ) : null;
});

const SetupContainer = styled(Box, {
  marginTop: '24px!important',
  borderRadius: 8,
  border: '1px solid #02023414',
  padding: '16px 24px',
  background: '#F9F9FB',
});
