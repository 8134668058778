export interface ColorOption {
  readonly value: string;
  readonly label: string;
  readonly color: string;
  readonly isFixed?: boolean;
  readonly isDisabled?: boolean;
}

export const colorOptions: readonly ColorOption[] = [
  { value: 'ocean', label: 'Ocean', color: '#00B8D9', isFixed: true },
  { value: 'blue', label: 'Blue', color: '#0052CC', isDisabled: true },
  { value: 'purple', label: 'Purple', color: '#5243AA' },
  { value: 'red', label: 'Red', color: '#FF5630', isFixed: true },
  { value: 'orange', label: 'Orange', color: '#FF8B00' },
  { value: 'yellow', label: 'Yellow', color: '#FFC400' },
  { value: 'green', label: 'Green', color: '#36B37E' },
  { value: 'forest', label: 'Forest', color: '#00875A' },
  { value: 'slate', label: 'Slate', color: '#253858' },
  { value: 'silver', label: 'Silver', color: '#666666' },
];

export interface FlavourOption {
  readonly value: string;
  readonly label: string;
  readonly rating: string;
}

export const flavourOptions: readonly FlavourOption[] = [
  { value: 'vanilla', label: 'Vanilla', rating: 'safe' },
  { value: 'chocolate', label: 'Chocolate', rating: 'good' },
  { value: 'strawberry', label: 'Strawberry', rating: 'wild' },
  { value: 'salted-caramel', label: 'Salted Caramel', rating: 'crazy' },
];

export interface StateOption {
  readonly value: string;
  readonly label: string;
}

export const stateOptions: readonly StateOption[] = [
  { value: 'AL', label: 'Alabama' },
  { value: 'AK', label: 'Alaska' },
  { value: 'AS', label: 'American Samoa' },
  { value: 'AZ', label: 'Arizona' },
  { value: 'AR', label: 'Arkansas' },
  { value: 'CA', label: 'California' },
  { value: 'CO', label: 'Colorado' },
  { value: 'CT', label: 'Connecticut' },
  { value: 'DE', label: 'Delaware' },
  { value: 'DC', label: 'District Of Columbia' },
  { value: 'FM', label: 'Federated States Of Micronesia' },
  { value: 'FL', label: 'Florida' },
  { value: 'GA', label: 'Georgia' },
  { value: 'GU', label: 'Guam' },
  { value: 'HI', label: 'Hawaii' },
  { value: 'ID', label: 'Idaho' },
  { value: 'IL', label: 'Illinois' },
  { value: 'IN', label: 'Indiana' },
  { value: 'IA', label: 'Iowa' },
  { value: 'KS', label: 'Kansas' },
  { value: 'KY', label: 'Kentucky' },
  { value: 'LA', label: 'Louisiana' },
  { value: 'ME', label: 'Maine' },
  { value: 'MH', label: 'Marshall Islands' },
  { value: 'MD', label: 'Maryland' },
  { value: 'MA', label: 'Massachusetts' },
  { value: 'MI', label: 'Michigan' },
  { value: 'MN', label: 'Minnesota' },
  { value: 'MS', label: 'Mississippi' },
  { value: 'MO', label: 'Missouri' },
  { value: 'MT', label: 'Montana' },
  { value: 'NE', label: 'Nebraska' },
  { value: 'NV', label: 'Nevada' },
  { value: 'NH', label: 'New Hampshire' },
  { value: 'NJ', label: 'New Jersey' },
  { value: 'NM', label: 'New Mexico' },
  { value: 'NY', label: 'New York' },
  { value: 'NC', label: 'North Carolina' },
  { value: 'ND', label: 'North Dakota' },
  { value: 'MP', label: 'Northern Mariana Islands' },
  { value: 'OH', label: 'Ohio' },
  { value: 'OK', label: 'Oklahoma' },
  { value: 'OR', label: 'Oregon' },
  { value: 'PW', label: 'Palau' },
  { value: 'PA', label: 'Pennsylvania' },
  { value: 'PR', label: 'Puerto Rico' },
  { value: 'RI', label: 'Rhode Island' },
  { value: 'SC', label: 'South Carolina' },
  { value: 'SD', label: 'South Dakota' },
  { value: 'TN', label: 'Tennessee' },
  { value: 'TX', label: 'Texas' },
  { value: 'UT', label: 'Utah' },
  { value: 'VT', label: 'Vermont' },
  { value: 'VI', label: 'Virgin Islands' },
  { value: 'VA', label: 'Virginia' },
  { value: 'WA', label: 'Washington' },
  { value: 'WV', label: 'West Virginia' },
  { value: 'WI', label: 'Wisconsin' },
  { value: 'WY', label: 'Wyoming' },
];

export const optionLength = [
  { value: 1, label: 'general' },
  {
    value: 2,
    label:
      'Evil is the moment when I lack the strength to be true to the Good that compels me.',
  },
  {
    value: 3,
    label:
      "It is now an easy matter to spell out the ethic of a truth: 'Do all that you can to persevere in that which exceeds your perseverance. Persevere in the interruption. Seize in your being that which has seized and broken you.",
  },
];

export const dogOptions = [
  { id: 1, label: 'Chihuahua' },
  { id: 2, label: 'Bulldog' },
  { id: 3, label: 'Dachshund' },
  { id: 4, label: 'Akita' },
];

// let bigOptions = [];
// for (let i = 0; i < 10000; i++) {
// 	bigOptions = bigOptions.concat(colorOptions);
// }

export interface GroupedOption {
  readonly label: string;
  readonly options: readonly ColorOption[] | readonly FlavourOption[];
}

export const groupedOptions: readonly GroupedOption[] = [
  {
    label: 'Colors',
    options: colorOptions,
  },
  {
    label: 'Flavours',
    options: flavourOptions,
  },
];

export interface IndustryOptions {
  readonly value: string;
  readonly label: string;
}

export const IndustryOptions: readonly StateOption[] = [
  {
    label: 'Accounting',
    value: 'Accounting',
  },
  {
    label: 'Airlines/Aviation',
    value: 'Airlines/Aviation',
  },
  {
    label: 'Alternative Dispute Resolution',
    value: 'Alternative Dispute Resolution',
  },
  {
    label: 'Alternative Medicine',
    value: 'Alternative Medicine',
  },
  {
    label: 'Animation',
    value: 'Animation',
  },
  {
    label: 'Apparel/Fashion',
    value: 'Apparel/Fashion',
  },
  {
    label: 'Architecture/Planning',
    value: 'Architecture/Planning',
  },
  {
    label: 'Arts/Crafts',
    value: 'Arts/Crafts',
  },
  {
    label: 'Automotive',
    value: 'Automotive',
  },
  {
    label: 'Aviation/Aerospace',
    value: 'Aviation/Aerospace',
  },
  {
    label: 'Banking/Mortgage',
    value: 'Banking/Mortgage',
  },
  {
    label: 'Biotechnology/Green Tech',
    value: 'Biotechnology/Green Tech',
  },
  {
    label: 'Broadcast Media',
    value: 'Broadcast Media',
  },
  {
    label: 'Building Materials',
    value: 'Building Materials',
  },
  {
    label: 'Business Supplies/Equipment',
    value: 'Business Supplies/Equipment',
  },
  {
    label: 'Capital Markets/Hedge Fund/Private Equity',
    value: 'Capital Markets/Hedge Fund/Private Equity',
  },
  {
    label: 'Chemicals',
    value: 'Chemicals',
  },
  {
    label: 'Civic/Social Organization',
    value: 'Civic/Social Organization',
  },
  {
    label: 'Civil Engineering',
    value: 'Civil Engineering',
  },
  {
    label: 'Commercial Real Estate',
    value: 'Commercial Real Estate',
  },
  {
    label: 'Computer Games',
    value: 'Computer Games',
  },
  {
    label: 'Computer Hardware',
    value: 'Computer Hardware',
  },
  {
    label: 'Computer Networking',
    value: 'Computer Networking',
  },
  {
    label: 'Computer Software/Engineering',
    value: 'Computer Software/Engineering',
  },
  {
    label: 'Computer/Network Security',
    value: 'Computer/Network Security',
  },
  {
    label: 'Construction',
    value: 'Construction',
  },
  {
    label: 'Consumer Electronics',
    value: 'Consumer Electronics',
  },
  {
    label: 'Consumer Goods',
    value: 'Consumer Goods',
  },
  {
    label: 'Consumer Services',
    value: 'Consumer Services',
  },
  {
    label: 'Cosmetics',
    value: 'Cosmetics',
  },
  {
    label: 'Dairy',
    value: 'Dairy',
  },
  {
    label: 'Defense/Space',
    value: 'Defense/Space',
  },
  {
    label: 'Design',
    value: 'Design',
  },
  {
    label: 'E-Learning',
    value: 'E-Learning',
  },
  {
    label: 'Education Management',
    value: 'Education Management',
  },
  {
    label: 'Electrical/Electronic Manufacturing',
    value: 'Electrical/Electronic Manufacturing',
  },
  {
    label: 'Entertainment/Movie Production',
    value: 'Entertainment/Movie Production',
  },
  {
    label: 'Environmental Services',
    value: 'Environmental Services',
  },
  {
    label: 'Events Services',
    value: 'Events Services',
  },
  {
    label: 'Executive Office',
    value: 'Executive Office',
  },
  {
    label: 'Facilities Services',
    value: 'Facilities Services',
  },
  {
    label: 'Farming',
    value: 'Farming',
  },
  {
    label: 'Financial Services',
    value: 'Financial Services',
  },
  {
    label: 'Fine Art',
    value: 'Fine Art',
  },
  {
    label: 'Fishery',
    value: 'Fishery',
  },
  {
    label: 'Food Production',
    value: 'Food Production',
  },
  {
    label: 'Food/Beverages',
    value: 'Food/Beverages',
  },
  {
    label: 'Fundraising',
    value: 'Fundraising',
  },
  {
    label: 'Furniture',
    value: 'Furniture',
  },
  {
    label: 'Gambling/Casinos',
    value: 'Gambling/Casinos',
  },
  {
    label: 'Glass/Ceramics/Concrete',
    value: 'Glass/Ceramics/Concrete',
  },
  {
    label: 'Government Administration',
    value: 'Government Administration',
  },
  {
    label: 'Government Relations',
    value: 'Government Relations',
  },
  {
    label: 'Graphic Design/Web Design',
    value: 'Graphic Design/Web Design',
  },
  {
    label: 'Health/Fitness',
    value: 'Health/Fitness',
  },
  {
    label: 'Higher Education/Academia',
    value: 'Higher Education/Academia',
  },
  {
    label: 'Hospital/Health Care',
    value: 'Hospital/Health Care',
  },
  {
    label: 'Hospitality',
    value: 'Hospitality',
  },
  {
    label: 'Human Resources/HR',
    value: 'Human Resources/HR',
  },
  {
    label: 'Import/Export',
    value: 'Import/Export',
  },
  {
    label: 'Individual/Family Services',
    value: 'Individual/Family Services',
  },
  {
    label: 'Industrial Automation',
    value: 'Industrial Automation',
  },
  {
    label: 'Information Services',
    value: 'Information Services',
  },
  {
    label: 'Information Technology/IT',
    value: 'Information Technology/IT',
  },
  {
    label: 'Insurance',
    value: 'Insurance',
  },
  {
    label: 'International Affairs',
    value: 'International Affairs',
  },
  {
    label: 'International Trade/Development',
    value: 'International Trade/Development',
  },
  {
    label: 'Internet',
    value: 'Internet',
  },
  {
    label: 'Investment Banking/Venture',
    value: 'Investment Banking/Venture',
  },
  {
    label: 'Investment Management/Hedge Fund/Private Equity',
    value: 'Investment Management/Hedge Fund/Private Equity',
  },
  {
    label: 'Judiciary',
    value: 'Judiciary',
  },
  {
    label: 'Law Enforcement',
    value: 'Law Enforcement',
  },
  {
    label: 'Law Practice/Law Firms',
    value: 'Law Practice/Law Firms',
  },
  {
    label: 'Legal Services',
    value: 'Legal Services',
  },
  {
    label: 'Legislative Office',
    value: 'Legislative Office',
  },
  {
    label: 'Leisure/Travel',
    value: 'Leisure/Travel',
  },
  {
    label: 'Library',
    value: 'Library',
  },
  {
    label: 'Logistics/Procurement',
    value: 'Logistics/Procurement',
  },
  {
    label: 'Luxury Goods/Jewelry',
    value: 'Luxury Goods/Jewelry',
  },
  {
    label: 'Machinery',
    value: 'Machinery',
  },
  {
    label: 'Management Consulting',
    value: 'Management Consulting',
  },
  {
    label: 'Maritime',
    value: 'Maritime',
  },
  {
    label: 'Market Research',
    value: 'Market Research',
  },
  {
    label: 'Marketing/Advertising/Sales',
    value: 'Marketing/Advertising/Sales',
  },
  {
    label: 'Mechanical or Industrial Engineering',
    value: 'Mechanical or Industrial Engineering',
  },
  {
    label: 'Media Production',
    value: 'Media Production',
  },
  {
    label: 'Medical Equipment',
    value: 'Medical Equipment',
  },
  {
    label: 'Medical Practice',
    value: 'Medical Practice',
  },
  {
    label: 'Mental Health Care',
    value: 'Mental Health Care',
  },
  {
    label: 'Military Industry',
    value: 'Military Industry',
  },
  {
    label: 'Mining/Metals',
    value: 'Mining/Metals',
  },
  {
    label: 'Motion Pictures/Film',
    value: 'Motion Pictures/Film',
  },
  {
    label: 'Museums/Institutions',
    value: 'Museums/Institutions',
  },
  {
    label: 'Music',
    value: 'Music',
  },
  {
    label: 'Nanotechnology',
    value: 'Nanotechnology',
  },
  {
    label: 'Newspapers/Journalism',
    value: 'Newspapers/Journalism',
  },
  {
    label: 'Non-Profit/Volunteering',
    value: 'Non-Profit/Volunteering',
  },
  {
    label: 'Oil/Energy/Solar/Green tech',
    value: 'Oil/Energy/Solar/Green tech',
  },
  {
    label: 'Online Publishing',
    value: 'Online Publishing',
  },
  {
    label: 'Other Industry',
    value: 'Other Industry',
  },
  {
    label: 'Outsourcing/Offshoring',
    value: 'Outsourcing/Offshoring',
  },
  {
    label: 'Package/Freight Delivery',
    value: 'Package/Freight Delivery',
  },
  {
    label: 'Packaging/Containers',
    value: 'Packaging/Containers',
  },
  {
    label: 'Paper/Forest Products',
    value: 'Paper/Forest Products',
  },
  {
    label: 'Performing Arts',
    value: 'Performing Arts',
  },
  {
    label: 'Pharmaceuticals',
    value: 'Pharmaceuticals',
  },
  {
    label: 'Philanthropy',
    value: 'Philanthropy',
  },
  {
    label: 'Photography',
    value: 'Photography',
  },
  {
    label: 'Plastics',
    value: 'Plastics',
  },
  {
    label: 'Political Organization',
    value: 'Political Organization',
  },
  {
    label: 'Primary/Secondary Education',
    value: 'Primary/Secondary Education',
  },
  {
    label: 'Printing',
    value: 'Printing',
  },
  {
    label: 'Professional Training',
    value: 'Professional Training',
  },
  {
    label: 'Program Development',
    value: 'Program Development',
  },
  {
    label: 'Public Relations/PR',
    value: 'Public Relations/PR',
  },
  {
    label: 'Public Safety',
    value: 'Public Safety',
  },
  {
    label: 'Publishing Industry',
    value: 'Publishing Industry',
  },
  {
    label: 'Railroad Manufacture',
    value: 'Railroad Manufacture',
  },
  {
    label: 'Ranching',
    value: 'Ranching',
  },
  {
    label: 'Real Estate/Mortgage',
    value: 'Real Estate/Mortgage',
  },
  {
    label: 'Recreational Facilities/Services',
    value: 'Recreational Facilities/Services',
  },
  {
    label: 'Religious Institutions',
    value: 'Religious Institutions',
  },
  {
    label: 'Renewable/Environment',
    value: 'Renewable/Environment',
  },
  {
    label: 'Research Industry',
    value: 'Research Industry',
  },
  {
    label: 'Restaurants',
    value: 'Restaurants',
  },
  {
    label: 'Retail Industry',
    value: 'Retail Industry',
  },
  {
    label: 'Security/Investigations',
    value: 'Security/Investigations',
  },
  {
    label: 'Semiconductors',
    value: 'Semiconductors',
  },
  {
    label: 'Shipbuilding',
    value: 'Shipbuilding',
  },
  {
    label: 'Sporting Goods',
    value: 'Sporting Goods',
  },
  {
    label: 'Sports',
    value: 'Sports',
  },
  {
    label: 'Staffing/Recruiting',
    value: 'Staffing/Recruiting',
  },
  {
    label: 'Supermarkets',
    value: 'Supermarkets',
  },
  {
    label: 'Telecommunications',
    value: 'Telecommunications',
  },
  {
    label: 'Textiles',
    value: 'Textiles',
  },
  {
    label: 'Think Tanks',
    value: 'Think Tanks',
  },
  {
    label: 'Tobacco',
    value: 'Tobacco',
  },
  {
    label: 'Translation/Localization',
    value: 'Translation/Localization',
  },
  {
    label: 'Transportation',
    value: 'Transportation',
  },
  {
    label: 'Utilities',
    value: 'Utilities',
  },
  {
    label: 'Venture Capital/VC',
    value: 'Venture Capital/VC',
  },
  {
    label: 'Veterinary',
    value: 'Veterinary',
  },
  {
    label: 'Warehousing',
    value: 'Warehousing',
  },
  {
    label: 'Wholesale',
    value: 'Wholesale',
  },
  {
    label: 'Wine/Spirits',
    value: 'Wine/Spirits',
  },
  {
    label: 'Wireless',
    value: 'Wireless',
  },
  {
    label: 'Writing/Editing',
    value: 'Writing/Editing',
  },
];
