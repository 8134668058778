import { ErrorBoundary, Scope } from '@sentry/react';
import { Helmet } from 'react-helmet-async';

import { usePageView } from '@/shared/hooks';
import { SettingsLayout } from '@/shared/layouts';
import { Box, Button, Flex, VStack } from '@/shared/ui';

import { AddTeam } from './AddTeam';
import { TeamsView } from './TeamsView';

export const Teams = () => {
  usePageView();

  return (
    <>
      <Helmet>
        <title>Whippy | Teams</title>
      </Helmet>
      <SettingsLayout
        background="#fafafa"
        breadcrumbs={[
          { title: 'Teams', path: '/settings/teams' },
          { title: 'All Teams', path: `/settings/teams` },
        ]}
        actions={<AddTeam />}
        width="100%"
      >
        <TeamsErrorBoundary>
          <TeamsView />
        </TeamsErrorBoundary>
      </SettingsLayout>
    </>
  );
};

export const TeamsErrorBoundary = ({ children }: { children: React.ReactNode }) => {
  if (!children) return null;

  const errorTag = `teams`;

  return (
    <ErrorBoundary
      fallback={<ErrorFallback />}
      beforeCapture={(scope: Scope) => {
        scope.setTag('ErrorLocation', errorTag);
      }}
      showDialog={false}
    >
      {children}
    </ErrorBoundary>
  );
};

const ErrorFallback = () => {
  return (
    <Flex
      direction="column"
      align="center"
      justify="center"
      css={{
        width: '100%',
        height: '100%',
      }}
    >
      <VStack align={'center'} gap={3}>
        <Box
          css={{
            fontWeight: 'bold',
          }}
        >
          Oops something went wrong ;(
        </Box>
        <Box>Please refresh, if the issue persists contact support.</Box>
        <Box>
          <Button variant="gray" onClick={() => window.location.reload()}>
            Refresh
          </Button>
        </Box>
      </VStack>
    </Flex>
  );
};
