import { api } from './api';

export const getOrganizationBilling = () =>
  api.get('/billing/organization/details').then((res) => res.data.data);

type GetUsageParams = {
  // the month and year to get usage for
  monthYear: string;
};

export const getOrganizationUsageByMonth = (params: GetUsageParams) =>
  api.get('/billing/organization/usage', { params: params }).then((res) => res.data);

type GetBillingParams = {
  limit?: number;
  offset?: number;
};

export const getOrganizationBillingHistory = (params: GetBillingParams) =>
  api
    .get('/billing/organization/history', { params: params })
    .then((res) => res.data.data);

export const getOrganizationCreditGrants = (params: GetBillingParams) =>
  api
    .get('/billing/organization/grants', { params: params })
    .then((res) => res.data.data);
