import dayjs from 'dayjs';

import { ColumnType, FilterConfig, Resource, SortConfig } from '@/shared/types/filter';

export const sequenceContactsFiltersConfig: FilterConfig<
  Resource.Contact | Resource.StepContact,
  | ColumnType.Name
  | ColumnType.Email
  | ColumnType.Phone
  | ColumnType.InsertedAt
  | ColumnType.Status
  | ColumnType.StepId
> = {
  label: 'Filter',
  columnOptions: [
    {
      label: 'Contact Name',
      resource: Resource.Contact,
      column: ColumnType.Name,
      operator: null,
      type: 'text',
      value: '',
    },
    {
      label: 'Contact Email',
      resource: Resource.Contact,
      column: ColumnType.Email,
      operator: null,
      type: 'text',
      value: '',
    },
    {
      label: 'Contact Phone',
      resource: Resource.Contact,
      column: ColumnType.Phone,
      operator: null,
      type: 'text',
      value: '',
    },
    {
      label: 'Step Created',
      resource: Resource.StepContact,
      column: ColumnType.InsertedAt,
      operator: '<=',
      type: 'date',
      value: dayjs(),
    },
    {
      label: 'Step Status',
      resource: Resource.StepContact,
      column: ColumnType.Status,
      operator: 'exact',
      type: 'dropdown',
      value: null,
    },
    {
      label: 'Step Position',
      resource: Resource.StepContact,
      column: ColumnType.StepId,
      operator: 'exact',
      type: 'dropdown',
      value: null,
    },
  ],
};

export const sequenceContactsSortConfig: SortConfig<
  Resource.Contact | Resource.StepContact | Resource.ContactMessage,
  | ColumnType.Name
  | ColumnType.Email
  | ColumnType.Phone
  | ColumnType.InsertedAt
  | ColumnType.UpdatedAt
> = {
  label: 'Sort',
  columnOptions: [
    {
      label: 'Contact Name',
      resource: Resource.Contact,
      column: ColumnType.Name,
      order: 'asc',
    },
    {
      label: 'Contact Phone',
      resource: Resource.Contact,
      column: ColumnType.Phone,
      order: 'asc',
    },
    {
      label: 'Contact Email',
      resource: Resource.Contact,
      column: ColumnType.Email,
      order: 'asc',
    },
    {
      label: 'Step Created',
      resource: Resource.StepContact,
      column: ColumnType.InsertedAt,
      order: 'asc',
    },
    {
      label: 'Step Updated',
      resource: Resource.StepContact,
      column: ColumnType.UpdatedAt,
      order: 'asc',
    },
    {
      label: 'Message Created',
      resource: Resource.ContactMessage,
      column: ColumnType.InsertedAt,
      order: 'asc',
    },
    {
      label: 'Message Updated',
      resource: Resource.ContactMessage,
      column: ColumnType.UpdatedAt,
      order: 'asc',
    },
  ],
};
