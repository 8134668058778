import { Attachment } from '@/shared/types';
import { FilterItem, SortItem } from '@/shared/types/filter';

import { api } from '../api';

export type LinksParams = {
  offset?: number;
  limit?: number;
  filter?: Array<FilterItem>;
  sort?: Array<SortItem>;
};

export type LinkParams = {
  url: string;
  use_custom_card: boolean;
  custom_card_description?: string;
  custom_card_image?: Attachment;
  custom_card_title?: string;
  description?: string;
  domain_id?: string;
  short_key?: string;
  utm_campaign?: string;
  utm_content?: string;
  utm_medium?: string;
  utm_source?: string;
  utm_term?: string;
};

export const linksAPI = {
  /*
    POST - Applies searching on the Links Settings and returns a list of them.
  */
  searchLinks: async (linksParams: LinksParams) => {
    const { data } = await api.post('/v2/link_settings/search', linksParams);
    return data;
  },
  /*
    POST - Create Link Settings
  */
  createLink: async (link_setting: LinkParams) => {
    const { data } = await api.post('/v2/link_settings', { link_setting });
    return data;
  },
  /*
    GET - Get Link Settings by id
  */
  getLink: async (id: string) => {
    const { data } = await api.get(`/v2/link_settings/${id}`);
    return data;
  },
  /*
    PUT - Update Link Settings by id
  */
  updateLink: async (id: string, link_setting: LinkParams) => {
    const { data } = await api.put(`/v2/link_settings/${id}`, { link_setting });
    return data;
  },
  /*
    DELETE - Delete Link Settings by id
  */
  deleteLink: async (id: string) => {
    const { data } = await api.delete(`/v2/link_settings/${id}`);
    return data;
  },
  /*
    POST - Search analytics for link
  */
  linkAnalytics: async (linkAnalyticsParams: any) => {
    const { data } = await api.post(`/v2/links/analytics`, linkAnalyticsParams);
    return data;
  },
  /*
    GET - Returns details containing the long url that the Link shortened.
  */
  getShortLink: async (shortKey: string) => {
    const { data } = await api.get(`/links/${shortKey}`);
    return data;
  },
};
