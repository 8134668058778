import dayjs from 'dayjs';
import { toast } from 'sonner';

import * as CampaignsAPI from '@/shared/api/campaigns';
import { Attachments } from '@/shared/components/editor/v2/constants';
import { Campaign, CampaignStatus, ScheduleOptions } from '@/shared/types/campaigns';
import { Channel } from '@/shared/types/channels';
import { Filter, FilterItem, FilterParams } from '@/shared/types/filter';

export type CollectiveFilterData = {
  tabFilter: FilterItem[];
  searchInputValue: string;
  activeFilters: Filter[];
};
export async function createQuickCampaign({
  message,
  attachments,
  location,
  scheduleOptions,
  filter,
}: {
  message: string;
  attachments: Attachments;
  location: Channel | null;
  scheduleOptions: ScheduleOptions | null;
  filter: FilterParams;
}): Promise<void> {
  if (!location?.id) {
    console.error('Failed to find a location id for quick campaign');
    toast.error('Something went wrong creating quick campaign.');
    return;
  }
  const pendingCampaign: Campaign = {
    title: `quick-campaign-${dayjs(new Date()).format('YYYY-MM-DD')}T${dayjs(
      new Date()
    ).format('H:mm:ss')}`,
    body: message,
    schedule_options: scheduleOptions,
    attachment_urls: attachments.attachment_urls,
    status: scheduleOptions ? CampaignStatus.SCHEDULED : CampaignStatus.IN_PROGRESS,
    settings: null,
    channel_id: location.id,
  };

  pendingCampaign.included_audience_filter = filter;
  pendingCampaign.excluded_audience_filter = {
    filter: [],
  };

  //update the channel id
  pendingCampaign.channel_id = location.id;

  try {
    await CampaignsAPI.createCampaignV2(pendingCampaign);
    toast.success('New Campaign Created!');
  } catch (err) {
    console.error(err);
    toast.error('Something went wrong.');
  }
}
