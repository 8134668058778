import { ErrorBoundary } from '@sentry/react';
import React from 'react';
import { HiStatusOffline } from 'react-icons/hi';

import { PageLayout } from '@/shared/layouts/PageLayout';
import { Box, Text } from '@/shared/ui';

type SequenceTemplatesFallbackProps = {
  isGlobal?: boolean;
};

// Fallback Component to be rendered in case of an error
const SequenceTemplatesFallback = ({ isGlobal }: SequenceTemplatesFallbackProps) => (
  <PageLayout>
    <Box
      css={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
      }}
    >
      <HiStatusOffline color="red" />
      <Text css={{ marginLeft: 10 }}>{`${
        isGlobal ? 'Global' : ''
      } Sequences Templates Unavailable`}</Text>
    </Box>
  </PageLayout>
);

type ErrorBoundaryProps = {
  feature: string;
  children: React.ReactNode;
  isGlobal?: boolean;
};

export const SequenceTemplatesErrorBoundary: React.FC<ErrorBoundaryProps> = ({
  feature,
  children,
  isGlobal,
}) => {
  // need to have a feature signal
  if (!feature || feature.length < 1) return null;
  // need to have children to render
  if (!children) return null;

  const errorTag = `SequenceTemplatesErrorBoundary.${feature}`;

  return (
    <ErrorBoundary
      fallback={<SequenceTemplatesFallback isGlobal={isGlobal} />}
      beforeCapture={(scope) => {
        scope.setTag('ErrorLocation', errorTag);
      }}
      showDialog={false}
    >
      {children}
    </ErrorBoundary>
  );
};
