/* eslint-disable react-hooks/exhaustive-deps */
import dayjs from 'dayjs';
import { debounce, DebouncedFunc } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
  initialUploadsState,
  ITEMS_COUNT,
  useUploads,
} from '@/pages/contacts/uploads/context/UploadContext';
import {
  ConfirmationDialogDescription,
  CustomDropdownMenuItem,
  CustomDropdownMenuItemWarning,
} from '@/pages/settings/organization/users/UsersTable';
import { ConfirmationDialog } from '@/shared/components/ConfirmationDialog';
import { CombinedFilters } from '@/shared/components/filterBuilder/CombinedFilters';
import { default_lists_object } from '@/shared/components/filterBuilder/objects/lists';
import { PageLayout } from '@/shared/layouts/PageLayout';
import { SearchFilters } from '@/shared/types/contacts';
import { FilterType, Sort } from '@/shared/types/filter';
import { Tag } from '@/shared/types/tags';
import { Box, Flex } from '@/shared/ui';
import { TooltipAvatar } from '@/shared/ui/TooltipAvatar';
import { Table, TableColumn } from '@/shared/v2/components/table/Table';
import { TableActionMenu } from '@/shared/v2/components/table/TableActionMenu';
import { styled } from '@/stitches.config';

import { RenameDialog } from '../segments/RenameDialog';
import { ContactActions } from '../utils/ContactActions';
import { handleFilterChange, handleSortUpdate } from '../utils/filterActions';
import { sortConfig } from './filterConfig';

const defaultSort: Array<Sort> = [
  {
    label: 'Created At',
    resource: 'list',
    column: 'inserted_at',
    order: 'desc',
    id: null,
  },
];

export const Lists = () => {
  const [quickSearchValue, setQuickSearchValue] = useState('');
  const [activeFilters, setActiveFilters] = useState<FilterType[]>([]);

  const uploadContext = useUploads();

  const { deleteUpload, updateUpload, updateFilterParams } = uploadContext;
  const { loading, uploads, filterParams, totalCount } = uploadContext.uploadsState;

  const data = useMemo(() => (loading ? Array(5).fill({}) : uploads), [loading, uploads]);

  const history = useHistory();

  const handleNavigate = (upload: Tag) => {
    history.push(`/data/lists/${upload.id}`);
  };

  const columns: Array<TableColumn<Tag>> = useMemo(
    () => [
      {
        Header: 'Name',
        id: 'name',
        accessor: 'name',
        colWidth: '100%',
        Cell: (props: { row: { original: Tag } }) => (
          <Flex align="center">
            <UploadColor css={{ backgroundColor: props.row.original.color }} />
            <Box>{props.row.original.name}</Box>
          </Flex>
        ),
      },
      {
        Header: 'Created by',
        accessor: 'created_by',
        Cell: (props: { row: { original: Tag } }) => (
          <>
            {!!props.row.original.created_by && (
              <TooltipAvatar user={props.row.original.created_by} />
            )}
          </>
        ),
      },
      {
        Header: 'Updated by',
        accessor: 'updated_by',
        Cell: (props: { row: { original: Tag } }) => (
          <>
            {!!props.row.original.updated_by && (
              <TooltipAvatar user={props.row.original.updated_by} />
            )}
          </>
        ),
      },
      {
        Header: 'Created At',
        id: 'created_at',
        accessor: 'created_at',
        colWidth: '100%',
        Cell: (props: { row: { original: Tag } }) => (
          <Flex align="center" css={{ minWidth: 125 }}>
            <Box>{dayjs(props.row.original.inserted_at).format('MMM D YYYY')}</Box>
          </Flex>
        ),
      },
      {
        Header: 'Updated At',
        id: 'updated_at',
        accessor: 'updated_at',
        colWidth: '100%',
        Cell: (props: { row: { original: Tag } }) => (
          <Flex align="center" css={{ minWidth: 125 }}>
            <Box>{dayjs(props.row.original.updated_at).format('MMM D YYYY')}</Box>
          </Flex>
        ),
      },
      {
        Header: 'Actions',
        accessor: 'id',
        Cell: (props: { row: { original: Tag } }) => {
          return (
            <TableActionMenu width={155}>
              <>
                <CustomDropdownMenuItem
                  data-testid="view-list-option"
                  onClick={() => handleNavigate(props.row.original)}
                >
                  View list
                </CustomDropdownMenuItem>
                <RenameDialog
                  title="Rename List"
                  description="Rename List"
                  value={props.row.original}
                  action={updateUpload}
                />

                <ConfirmationDialog
                  width="432px"
                  title="Delete this list?"
                  description={
                    <ConfirmationDialogDescription
                      value={props.row.original.name}
                      description="list will be permanently deleted."
                    />
                  }
                  onConfirm={() => deleteUpload(props.row.original.id)}
                  confirmButtonTitle="Confirm"
                  cancelButtonTitle="Cancel"
                  confirmButtonVariant="redBackground"
                  cancelButtonVariant="grayBackground"
                >
                  <CustomDropdownMenuItemWarning
                    data-testid="delete-list-option"
                    onClick={(e) => e.preventDefault()}
                  >
                    Delete list
                  </CustomDropdownMenuItemWarning>
                </ConfirmationDialog>
              </>
            </TableActionMenu>
          );
        },
      },
    ],
    [loading]
  );

  const handleQuickSearch = (
    debouncedUpdate: DebouncedFunc<(props: SearchFilters) => void>,
    filters: SearchFilters,
    value: string
  ) => {
    const formattedValue = value.trim();
    const quickFilters = [
      {
        column: 'name',
        comparison: 'ilike',
        value: `%${formattedValue}%`,
        resource: 'list',
      },
    ];
    debouncedUpdate({
      ...filters,
      searchFilter: quickFilters,
      offset: 0,
    });
  };

  useEffect(() => {
    resetFilters();
  }, []);

  const debouncedUpdate = useCallback(
    debounce((props: SearchFilters) => {
      updateFilterParams(props);
    }, 1000),
    []
  );

  const resetFilters = () => {
    setQuickSearchValue('');
    setActiveFilters([]);
    updateFilterParams({
      ...initialUploadsState.filterParams,
      sort: defaultSort,
    });
  };

  return (
    <PageLayout
      breadcrumbs={[
        { title: 'Data', path: '/data/contacts' },
        { title: 'Lists', path: '/data/lists' },
      ]}
      actions={<ContactActions />}
    >
      <Flex direction="column" css={{ m: 24, flex: '1 1 auto' }}>
        <Box css={{ paddingBottom: 24 }}>
          <CombinedFilters
            quickSearchPlaceholder="Search Lists"
            quickSearchValue={quickSearchValue}
            setQuickSearchValue={(value: string) => {
              setQuickSearchValue(value);
              handleQuickSearch(debouncedUpdate, filterParams, value);
            }}
            defaultObjects={[default_lists_object]}
            customObjects={[]}
            activeFilters={activeFilters}
            setFilters={(value: Array<FilterType>) => {
              setActiveFilters(value);
              handleFilterChange(debouncedUpdate, filterParams, value);
            }}
            sortConfig={sortConfig}
            activeSort={filterParams.sort}
            onSortUpdate={(value: Array<Sort>) =>
              handleSortUpdate(debouncedUpdate, filterParams, value, true)
            }
          />
        </Box>
        <Table
          data={data}
          columns={columns}
          caption="Uploads Table"
          totalCount={totalCount}
          isLoading={loading}
          setOffset={(offset) => {
            updateFilterParams({
              ...filterParams,
              offset,
            });
          }}
          pageSize={ITEMS_COUNT}
          emptyTitle="No lists were found."
        />
      </Flex>
    </PageLayout>
  );
};

export const SearchIconContainer = styled(Box, {
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  left: '10px',
  pointerEvents: 'none',
});

export const UploadColor = styled(Box, {
  height: 7,
  width: 7,
  borderRadius: '100%',
  mt: 2,
  mr: 10,
});
