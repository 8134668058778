/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unnecessary-type-constraint */
/* eslint-disable react/jsx-key */
import React, { useEffect, useState } from 'react';
import { HiSearch } from 'react-icons/hi';
import {
  Column,
  Row,
  useExpanded,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table';
import { useMedia } from 'react-use';

import { Box, Caption, Checkbox, Flex, HStack, Input, Skeleton } from '@/shared/ui';

import { Table as StyledTable, Tbody, Td, Th, Thead, Tr } from '../ui/Table';
import { EmptyTable } from './EmptyTable';
import { Pagination } from './Pagination';

export type TableColumn<T> = {
  Header: string;
  colWidth?: string;
  accessor?: string;
  Cell: JSX.Element | ((props: { row: { original: T } }) => JSX.Element);
  id?: string;
};

export type TableOverflow = {
  desktop: string | number;
  mobile: string | number;
};

type TableProps<T> = {
  columns: Array<TableColumn<T>>;
  data: Array<object>;
  caption: string;
  emptyTitle: string;
  empty?: JSX.Element;
  totalCount: number; // show the number of all pages (including ones that are not loaded)
  setOffset: (value: number) => void;
  pageSize: number;
  overflow?: TableOverflow;
  fixed?: boolean;
  selectable?: boolean;
  isLoading?: boolean;
  colHeight?: number;
  renderRowSubComponent?: ({ row }: { row: Row }) => JSX.Element;
};

export const Table = <T extends unknown>({
  columns,
  data,
  caption,
  emptyTitle,
  empty,
  overflow,
  fixed,
  selectable,
  renderRowSubComponent,
  totalCount, // show the number of all pages (including ones that are not loaded)
  setOffset,
  pageSize,
  isLoading,
  colHeight,
}: TableProps<T>) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    visibleColumns,
  } = useTable(
    {
      columns: columns as Array<Column>,
      data,
      disableSortBy: true,
      autoResetPage: false,
    },
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
    (hooks) => {
      if (selectable) {
        hooks.visibleColumns.push((columns) => [
          // Create a column for selection
          {
            id: 'selection',
            Header: ({ getToggleAllRowsSelectedProps, toggleAllRowsSelected }) => (
              <div data-testid="selection">
                <Checkbox
                  {...getToggleAllRowsSelectedProps()}
                  onCheckedChange={toggleAllRowsSelected}
                />
              </div>
            ),
            disableSortBy: true,
            Cell: ({ row }) => (
              <div>
                <Checkbox
                  {...row.getToggleRowSelectedProps()}
                  onCheckedChange={row.toggleRowSelected}
                />
              </div>
            ),
          },
          ...columns,
        ]);
      }
    }
  );
  const isDesktop = useMedia('(min-width: 912px)');

  const [pageIndex, setPageIndex] = useState(1);

  useEffect(() => {
    const currentPage = pageIndex - 1;
    const numberOfLoadedItems = currentPage * pageSize;
    setOffset(numberOfLoadedItems);
  }, [pageIndex]);

  useEffect(() => {
    if (totalCount <= pageSize) {
      setPageIndex(1);
    }
    if (pageIndex > 1 && totalCount <= pageSize * (pageIndex - 1)) {
      setPageIndex(pageIndex - 1);
    }
  }, [totalCount]);

  return (
    <Box
      css={{
        border: '1px solid rgb(226, 232, 240)',
        borderBottom: 0,
        borderRadius: 8,
        flex: '0 1 auto',
      }}
    >
      <Box
        css={{
          flexGrow: 1,
          overflowX: isDesktop
            ? overflow?.desktop
              ? 'scroll'
              : 'auto'
            : overflow?.mobile
              ? 'scroll'
              : 'auto',
          width: isDesktop ? overflow?.desktop || '100%' : overflow?.mobile || '100%',
          borderBottomLeftRadius: totalCount <= pageSize ? 8 : 0,
          borderBottomRightRadius: totalCount <= pageSize ? 8 : 0,
        }}
      >
        {page.length > 0 || isLoading ? (
          <StyledTable
            data-testid="table"
            {...getTableProps()}
            css={{ tableLayout: fixed ? 'fixed' : 'auto' }}
          >
            {caption ? <Caption>{caption}</Caption> : null}
            <Thead>
              {headerGroups.map((headerGroup) => (
                <Tr {...headerGroup.getHeaderGroupProps()} variant="header">
                  {headerGroup.headers.map((column) => (
                    <Th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      css={{
                        // Adjust width of a column if colWidth is set in header cell template
                        width: column.colWidth || 'auto',
                        maxWidth: column.colWidth || 'auto',
                      }}
                      // Turn overflow into ellipsis if ellipsis is set to true in header cell template
                      ellipsis={column.ellipsis}
                    >
                      <HStack data-testid="header-item" align="center" gap={2}>
                        {column.render('Header')}
                      </HStack>
                    </Th>
                  ))}
                </Tr>
              ))}
            </Thead>
            {isLoading ? (
              <Tbody data-testid="table-loading-placeholder" {...getTableBodyProps()}>
                <Loading
                  itemsCount={pageSize}
                  colSpan={columns.length}
                  colHeight={colHeight}
                />
              </Tbody>
            ) : (
              <Tbody {...getTableBodyProps()}>
                {page.map((row) => {
                  prepareRow(row);
                  return (
                    <React.Fragment key={row.getRowProps().key}>
                      <Tr
                        data-testid="table-row"
                        {...(row.values.id ? { key: row.values.id } : row.getRowProps())}
                        variant={row.isExpanded ? 'expanded' : undefined}
                      >
                        {row.cells.map((cell) => {
                          return (
                            <Td
                              {...cell.getCellProps()}
                              // Adjust width of a column if colWidth is set in row cell template
                              css={{
                                width: cell.column.colWidth || 'auto',
                                maxWidth: cell.column.colWidth || 'auto',
                                padding: '16px 20px',
                                backgroundColor: 'white',
                              }}
                              // Turn overflow into ellipsis if ellipsis is set to true in row cell template
                              ellipsis={cell.column.ellipsis}
                            >
                              {cell.render('Cell')}
                            </Td>
                          );
                        })}
                      </Tr>
                      {row.isExpanded && renderRowSubComponent ? (
                        <Tr css={{ backgroundColor: 'white' }}>
                          <Td colSpan={visibleColumns.length}>
                            {renderRowSubComponent({ row })}
                          </Td>
                        </Tr>
                      ) : null}
                    </React.Fragment>
                  );
                })}
              </Tbody>
            )}
          </StyledTable>
        ) : (
          <EmptyTable height={680} title={emptyTitle}>
            {empty}
          </EmptyTable>
        )}
      </Box>
      {!!data.length && totalCount > pageSize && (
        <Flex
          justify="center"
          css={{
            padding: '$3',
            position: 'relative',
            background: '#fff',
            borderBottom: '1px solid rgb(226, 232, 240)',
            borderBottomLeftRadius: 8,
            borderBottomRightRadius: 8,
          }}
        >
          <Pagination
            setCurrentPage={setPageIndex}
            currentPage={pageIndex}
            totalCount={totalCount}
            pageSize={pageSize}
          />
        </Flex>
      )}
    </Box>
  );
};

type SearchFilterProps = {
  searchValue: string;
  setSearchValue: (value: string) => void;
  placeholder: string;
};

export const SearchFilter = ({
  searchValue,
  setSearchValue,
  placeholder,
}: SearchFilterProps) => {
  const onValueChange = (value: string) => {
    setSearchValue(value);
  };

  return (
    <Box css={{ position: 'relative', width: 360 }}>
      <HiSearch
        style={{
          position: 'absolute',
          top: '50%',
          transform: 'translateY(-50%)',
          left: '10px',
          pointerEvents: 'none',
        }}
      />
      <Input
        placeholder={placeholder}
        css={{
          paddingLeft: '30px',
        }}
        value={searchValue}
        onChange={(e: { target: { value: string } }) => onValueChange(e.target.value)}
      />
    </Box>
  );
};

export const Loading = ({
  itemsCount,
  colSpan,
  colHeight,
}: {
  itemsCount: number;
  colSpan: number;
  colHeight?: number;
}) => (
  <>
    {Array.from({ length: itemsCount }, (_: React.ReactNode, key: React.Key) => (
      <Tr key={key} style={{ pointerEvents: 'none' }}>
        <Td colSpan={colSpan} style={{ padding: 0, background: 'white' }}>
          <Flex align="center" css={{ height: colHeight ?? 32 }}>
            <Skeleton css={{ width: '100%', height: 18, margin: '0' }} />
          </Flex>
        </Td>
      </Tr>
    ))}
  </>
);
