import React, { useCallback } from 'react';
import { HiPlus, HiX } from 'react-icons/hi';
import { toast } from 'sonner';

import { ConfirmationDialog } from '@/shared/components/ConfirmationDialog';
import { CircleIcon } from '@/shared/components/Icons';
import { ContactTagItem } from '@/shared/types';
import { Box, Flex, HStack, IconButton } from '@/shared/ui';
import i18next from '@/shared/utils/translation';
import { styled } from '@/stitches.config';

import { useContacts } from '../../context/ContactContext';

type ContactTagProps = {
  /** tag icon color */
  iconColor?: string;
  /** tag text */
  tagText?: string;
  /** tag id */
  tagId?: string;
  /** contact id */
  contactId?: string;
  /** true if tag can be removed */
  isClosable?: boolean;
  /** the tag itself */
  contact_tag?: ContactTagItem;
  /** selectedTags under current state*/
  currentSelectedTags?: string[];
  /** index of the tag */
  index?: number;
  /** function to remove the tag */
  removeTagFromCombobox?: (tagId: string) => void;
  /** function to set the current selected tag */
  setSelectedTags?: (selectedTags: string[]) => void;
};

export function ContactTag(props: ContactTagProps) {
  const {
    isClosable,
    tagText,
    iconColor,
    tagId,
    contactId,
    contact_tag,
    setSelectedTags,
    currentSelectedTags,
  } = props;

  const { deleteOneContactTag } = useContacts();

  // Inside Panel, click "X" of real tags can trigger removeTagFromContact() function
  const removeTagFromContact = useCallback(() => {
    try {
      if (contactId && tagId) {
        // 1) make API call
        deleteOneContactTag(contactId, tagId, contact_tag);
        // 2) remove this tag from selectedTags of current state
        if (setSelectedTags && currentSelectedTags) {
          const newCurrentSelectedTag = currentSelectedTags.filter(
            (e) => e != contact_tag?.tag_id
          );
          setSelectedTags(newCurrentSelectedTag);
        }
        toast.success(i18next.t('tags_updated_success') as string);
      }
    } catch (e) {
      toast.success(i18next.t('tags_updated_failed') as string);
    }
  }, [contactId, tagId, contact_tag, currentSelectedTags, deleteOneContactTag]);

  /* 
    There exist some tags, so we render a "Add Tag" button.
    An "Add Tag" button has tagId = "", contactId = "".
    Bug fix - the purpose of hasAnyTags:
      under Contacts Page, as long as there exist any single tag created
      by the current user, we will render "Add Tag"
  */
  if (!isClosable && tagId != '1' && !contactId) {
    return (
      <AddTagButton>
        <HStack gap={1}>
          <HiPlus size={15} />
          Add Tag
        </HStack>
      </AddTagButton>
    );
  }

  /* 
    An "Create Tag" button
    There doesn't exist any single tag created by the current user
  */
  if (!isClosable && tagId === '1') {
    return (
      <AddTagButton>
        <HStack gap={1}>
          <HiPlus size={15} />
          Create Tag
        </HStack>
      </AddTagButton>
    );
  }

  // Not "Add Tag" or "Create Tag" -> A real tag with colorful round in front
  return (
    <ContactTagLayout css={{ height: 30 }}>
      <HStack gap={1}>
        <CircleIcon color={iconColor} />
        <Box css={{ whiteSpace: 'nowrap', cursor: 'default', userSelect: 'none' }}>
          {tagText}
        </Box>
        {isClosable && tagId && (
          <ConfirmationDialog
            title="Delete Tag"
            description={`Are you sure to delete ${
              tagText || ''
            } from contact? You can retag any time.`}
            confirmButtonTitle="Yes, Delete"
            onConfirm={removeTagFromContact}
            testID={`delete-button-tag-${tagId}`}
          >
            <IconButton size={0}>
              <HiX size={15} />
            </IconButton>
          </ConfirmationDialog>
        )}
      </HStack>
    </ContactTagLayout>
  );
}

export const ContactTagLayout = styled(Flex, {
  maxWidth: '220px',
  width: 'max-content',
  fontSize: '12px',
  fontWeight: 'medium',
  borderRadius: '4px',
  padding: '5px 7px',
  paddingRight: '7px',
  paddingLeft: '7px',
  margin: '3px',
  backgroundColor: '$panel',
  cursor: 'pointer',
  boxShadow: 'inset 0 0 0 1px $colors$gray4',
  color: '$hiContrast',
  '@hover': {
    '&:hover': {
      boxShadow: 'inset 0 0 0 1px $colors$gray4',
    },
  },
  '&:active': {
    backgroundColor: '$slate2',
    boxShadow: 'inset 0 0 0 1px $colors$gray4',
  },
  '&:focus': {
    boxShadow: 'inset 0 0 0 1px $colors$gray4, 0 0 0 1px $colors$gray4',
  },
});

const AddTagButton = styled('button', {
  display: 'flex',
  alignItems: 'center',
  maxWidth: '220px',
  width: 'max-content',
  fontSize: '12px',
  fontWeight: 'medium',
  borderRadius: '4px',
  padding: '5px 7px',
  paddingRight: '7px',
  paddingLeft: '7px',
  margin: '3px',
  backgroundColor: '$panel',
  borderColor: '$gray4',
  cursor: 'pointer',
  height: 30,
  '& svg': {
    marginRight: 5,
  },
  boxShadow: 'inset 0 0 0 1px $colors$gray4',
  color: '$hiContrast',
  '@hover': {
    '&:hover': {
      boxShadow: 'inset 0 0 0 1px $colors$gray6',
    },
  },
  '&:active': {
    backgroundColor: '$slate2',
    boxShadow: 'inset 0 0 0 1px $colors$gray5',
  },
  '&:focus': {
    boxShadow: 'inset 0 0 0 1px $colors$gray4, 0 0 0 1px $colors$gray4',
  },
});
