import { SettingsState } from './SettingsContext';
import { SettingsActionType } from './types';

type SettingsAction =
  | { type: SettingsActionType.GET_ORGANIZATION_INFO; payload: any }
  | { type: SettingsActionType.UPDATE_SETTINGS; payload: any }
  | { type: SettingsActionType.SETTINGS_ERROR; payload: any };

const SettingsReducer = (state: SettingsState, action: SettingsAction) => {
  switch (action.type) {
    case SettingsActionType.GET_ORGANIZATION_INFO:
      return {
        ...state,
        organizationName: action.payload.organization_name,
        organizationAttachment: action.payload.attachment?.url,
        selectedIndustries: action.payload.industry,
        settings: action.payload.settings,
        loading: false,
      };
    case SettingsActionType.UPDATE_SETTINGS:
      return {
        ...state,
        settings: action.payload.settings,
        organizationName: action.payload.organization_name,
        selectedIndustries: action.payload.industry,
        loading: false,
      };
    case SettingsActionType.SETTINGS_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default SettingsReducer;
