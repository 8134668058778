import React from 'react'; // for unit tests "ReferenceError: React is not defined"
import {
  HiBell,
  HiChat,
  HiCheck,
  HiCheckCircle,
  HiMail,
  HiOutlineArrowNarrowRight,
  HiOutlineMinusCircle,
  HiOutlinePlusCircle,
  HiTag,
  HiUserAdd,
  HiUserRemove,
  HiXCircle,
} from 'react-icons/hi';
import { v4 as uuidv4 } from 'uuid';

import { Action, ActionTypes } from '@/shared/types/triggers/actions';

export type ActionProps = {
  /* The action to display */
  index: number;
  /* The action to display */
  action: Action;
  /* The action to add when the delete button is clicked */
  removeAction?: (action: Action) => void;
};

export const sendMessageAction = {
  id: '',
  enabled: true,
  type: ActionTypes.SEND_MESSAGE,
  params: {
    body: '',
    attachment_urls: [],
    attachments: [],
  },
};

export const addTagsAction = {
  id: '',
  enabled: true,
  type: ActionTypes.ADD_TAGS,
  params: {
    tag_ids: [],
  },
};

export const removeTagsAction = {
  id: '',
  enabled: true,
  type: ActionTypes.REMOVE_TAGS,
  params: {
    tag_ids: [],
  },
};

export const assignUserAction = {
  id: '',
  enabled: true,
  type: ActionTypes.ASSIGN_USER,
  params: {
    user_id: '',
  },
};

export const unassignUserAction = {
  id: '',
  enabled: true,
  type: ActionTypes.UNASSIGN_USER,
  params: {
    user_id: null,
  },
};

export const removeFromSequenceAction = {
  id: '',
  enabled: true,
  type: ActionTypes.REMOVE_FROM_SEQUENCE,
  params: {
    sequence_id: '',
  },
};

export const moveToSequenceStepAction = {
  id: '',
  enabled: true,
  type: ActionTypes.MOVE_TO_STEP,
  params: {
    sequence_id: '',
    step_id: '',
  },
};

export const addToSequenceAction = {
  id: '',
  enabled: true,
  type: ActionTypes.ADD_TO_SEQUENCE,
  params: {
    sequence_id: '',
  },
};

export const optInAction = {
  id: '',
  enabled: true,
  type: ActionTypes.OPT_IN,
  params: {
    location_ids: [],
  },
};

export const optOutAction = {
  id: '',
  enabled: true,
  type: ActionTypes.OPT_OUT,
  params: {
    location_ids: [],
  },
};

export const notifyUsersAction = {
  id: '',
  enabled: true,
  type: ActionTypes.NOTIFY_USERS,
  params: {
    user_ids: [],
  },
};

export const sendSmsAction = {
  id: '',
  tempId: uuidv4(),
  enabled: true,
  type: ActionTypes.SEND_SMS,
  params: {
    to: [],
    location_id: '',
    body: '',
    attachment_urls: [],
  },
};

export const sendEmailAction = {
  id: '',
  tempId: uuidv4(),
  enabled: true,
  type: ActionTypes.SEND_EMAIL,
  params: {
    to: [],
    cc: [],
    bcc: [],
    from: '',
    reply_to: '',
    subject: '',
    body: '',
    attachment_urls: [],
  },
};

export const triggerWebhookAction = {
  id: '',
  tempId: uuidv4(),
  enabled: true,
  type: ActionTypes.TRIGGER_WEBHOOK,
  params: {
    url: '',
  },
};

export const updateConversationStatusAction = {
  id: '',
  tempId: uuidv4(),
  enabled: true,
  type: ActionTypes.UPDATE_CONVERSATION_STATUS,
  params: {
    status: 'open',
  },
};

export const defaultActions = [
  {
    type: ActionTypes.SEND_MESSAGE,
    default: sendMessageAction,
  },
  {
    type: ActionTypes.ADD_TAGS,
    default: addTagsAction,
  },
  {
    type: ActionTypes.REMOVE_TAGS,
    default: removeTagsAction,
  },
  {
    type: ActionTypes.ASSIGN_USER,
    default: assignUserAction,
  },
  {
    type: ActionTypes.UNASSIGN_USER,
    default: unassignUserAction,
  },
  {
    type: ActionTypes.OPT_IN,
    default: optInAction,
  },
  {
    type: ActionTypes.OPT_OUT,
    default: optOutAction,
  },
  {
    type: ActionTypes.NOTIFY_USERS,
    default: notifyUsersAction,
  },
  {
    type: ActionTypes.REMOVE_FROM_SEQUENCE,
    default: removeFromSequenceAction,
  },
  {
    type: ActionTypes.ADD_TO_SEQUENCE,
    default: addToSequenceAction,
  },
  {
    type: ActionTypes.MOVE_TO_STEP,
    default: moveToSequenceStepAction,
  },
  {
    type: ActionTypes.UPDATE_CONVERSATION_STATUS,
    default: updateConversationStatusAction,
  },
  {
    type: ActionTypes.SEND_EMAIL,
    default: sendEmailAction,
  },
  // {
  //   type: ActionTypes.SEND_SMS,
  //   default: sendSmsAction,
  // },
  // {
  //   type: ActionTypes.TRIGGER_WEBHOOK,
  //   default: triggerWebhookAction,
  // },
];

export const action_values = [
  {
    label: 'Send Message',
    value: ActionTypes.SEND_MESSAGE,
    title: 'Send Response',
    description: 'Respond with an automated message.',
    color: '#5653DF',
    icon: <HiChat />,
  },
  {
    label: 'Add Tags to Contact',
    value: ActionTypes.ADD_TAGS,
    title: 'Add Tags',
    description: 'Add tags to a contact.',
    color: '#20A666',
    icon: <HiTag />,
  },
  {
    label: 'Remove Tags from Contact',
    value: ActionTypes.REMOVE_TAGS,
    title: 'Remove Tags',
    description: 'Remove tags from a contact.',
    color: '#EA5757',
    icon: <HiTag />,
  },
  {
    label: 'Assign User',
    value: ActionTypes.ASSIGN_USER,
    title: 'Assign User',
    description: 'Assign a user to a conversation.',
    color: '#7BC2ED',
    icon: <HiUserAdd />,
  },
  {
    label: 'Unassign Conversation',
    value: ActionTypes.UNASSIGN_USER,
    title: 'Unassign Conversation Action',
    description: 'Any assigned user will be unassigned from this conversation.',
    color: '#F8BE3E',
    icon: <HiUserRemove />,
  },
  {
    label: 'Opt Contact In',
    value: ActionTypes.OPT_IN,
    title: 'Opt-in Contact',
    description: 'Add contact to a list of opted-in contacts.',
    color: '#28D07F',
    icon: <HiCheckCircle />,
  },
  {
    label: 'Opt Contact Out',
    value: ActionTypes.OPT_OUT,
    title: 'Opt-out Contact',
    description: 'Remove contact from a list of opted-out contacts.',
    color: '#FC6E51',
    icon: <HiXCircle />,
  },
  {
    label: 'Notify Users',
    value: ActionTypes.NOTIFY_USERS,
    title: 'Notify Users',
    description: 'Notify users of an event.',
    color: '#EC87C0',
    icon: <HiBell />,
  },
  {
    label: 'Remove From Sequence',
    value: ActionTypes.REMOVE_FROM_SEQUENCE,
    title: 'Remove from Sequence Action',
    description: 'Remove a contact from a sequence.',
    color: '#EE7878',
    icon: <HiOutlineMinusCircle />,
  },
  {
    label: 'Add To Sequence',
    value: ActionTypes.ADD_TO_SEQUENCE,
    title: 'Add to Sequence Action',
    description: 'Add a contact to a sequence.',
    color: '#379492',
    icon: <HiOutlinePlusCircle />,
  },
  {
    label: 'Move Sequence Step',
    value: ActionTypes.MOVE_TO_STEP,
    title: 'Move Sequence Step Action',
    description: 'Move a contact to a specific step in a sequence.',
    color: '#379492',
    icon: <HiOutlineArrowNarrowRight />,
  },
  {
    label: 'Update Conversation Status',
    value: ActionTypes.UPDATE_CONVERSATION_STATUS,
    title: 'Update Conversation Status Action',
    description: 'Update the status of a conversation.',
    color: '#F8BE3E',
    icon: <HiCheck />,
  },
  {
    label: 'Send Email',
    value: ActionTypes.SEND_EMAIL,
    title: 'Send Email',
    description: 'Send an email to any email address.',
    color: '#5653DF',
    icon: <HiMail />,
  },
  // {
  //   label: 'Send SMS',
  //   value: ActionTypes.SEND_SMS,
  //   title: 'Send SMS Action',
  //   description: 'Send an SMS to any phone number.',
  // },
  // {
  //   label: 'Trigger Webhook',
  //   value: ActionTypes.TRIGGER_WEBHOOK,
  //   title: 'Trigger Webhook Action',
  //   description: 'Trigger a webhook endpoint.',
  // },
];
