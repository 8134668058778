import React from 'react';

import { usePageView } from '@/shared/hooks';
import { PageLayout } from '@/shared/layouts/PageLayout';
import { HStack } from '@/shared/ui';

import DeveloperProvider from '../context/DevelopersContext';
import { AddDeveloperApplication } from './AddApplication';
import { WebhookEndpointsList } from './WebhookEndpointsList';

export function Webhooks() {
  usePageView();

  return (
    <DeveloperProvider>
      <PageLayout
        breadcrumbs={[
          { title: 'Developer', path: '/developer/keys' },
          { title: 'Webhooks', path: '/developer/webhooks' },
        ]}
        actions={
          <HStack gap="2">
            <AddDeveloperApplication />
          </HStack>
        }
      >
        <WebhookEndpointsList />
      </PageLayout>
    </DeveloperProvider>
  );
}
