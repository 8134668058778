import { useEffect } from 'react';

export const useResizeObserver = (
  callback: (resizeObserverData: ResizeObserverEntry[]) => void,
  ref: React.MutableRefObject<HTMLInputElement | HTMLDivElement>
) => {
  useEffect(() => {
    if (!ref.current) return;
    const resizeObserver = new ResizeObserver((data) => {
      callback(data);
    });
    resizeObserver.observe(ref.current);
    return () => resizeObserver.disconnect(); // clean up
  }, []);
};
