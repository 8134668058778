import { ErrorBoundary } from '@sentry/react';
import React from 'react';
import { HiStatusOffline } from 'react-icons/hi';

import { Box, HStack, Text } from '@/shared/ui';

// Fallback Component to be rendered in case of an error
const ApiKeyListErrorBoundariesFallback = () => (
  <Box
    css={{
      display: 'flex', // Added this line to make the Box a flex container
      flexDirection: 'column', // Added this line for vertical alignment
      alignItems: 'center', // Added this line to center content horizontally
      justifyContent: 'center', // Added this line to center content vertically
      width: '100%',
      height: '100%',
      margin: '30px', // have a margin to separate from the edges of the page
      border: '1px solid rgb(226, 232, 240)',
      borderRadius: 8,
      background: 'rgb(255, 255, 255)',
    }}
  >
    <HStack>
      <HiStatusOffline color="red" />
      <Text>API Keys Unavailable </Text>
    </HStack>
  </Box>
);

type ErrorBoundaryProps = {
  feature: string;
  children: React.ReactNode;
};

export const ApiKeyListErrorBoundaries: React.FC<ErrorBoundaryProps> = ({
  feature,
  children,
}) => {
  // need to have a feature signal
  if (!feature || feature.length < 1) return null;
  // need to have children to render
  if (!children) return null;

  const errorTag = `developer.${feature}`;

  return (
    <ErrorBoundary
      fallback={<ApiKeyListErrorBoundariesFallback />}
      beforeCapture={(scope) => {
        scope.setTag('ErrorLocation', errorTag);
      }}
      showDialog={false}
    >
      {children}
    </ErrorBoundary>
  );
};
