/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Virtuoso } from 'react-virtuoso';

import { DEFAULT_CAMPAIGNS_LIMIT } from '@/campaigns/context/constants';
import useFilterCampaigns from '@/pages/campaigns/hooks/useFilterCampaigns';
import { PageLayout } from '@/shared/layouts/PageLayout';
import { Campaign } from '@/shared/types/campaigns';
import { ChannelTypes } from '@/shared/types/channels';
import { Box, Button, Flex, Text, VStack } from '@/shared/ui';
import { styled } from '@/stitches.config';

import { useCampaignsContext } from '../context/CampaignsContext';
import CampaignCard from './CampaignCard';
import CampaignLoadingCard from './CampaignLoadingCard';
import { CampaignSearch } from './CampaignSearch';

type CampaignsPageProps = {
  /* campaign page title */
  title: string;
  /* campaign filter url */
  url: string;
  /* an array of campaign objects */
  campaigns: Array<Campaign>;
  /* is the page loading? */
  loading?: boolean;
  /* what state are the campaigns in? closed */
  status?: string;
};

export const CampaignsList = (props: CampaignsPageProps): JSX.Element => {
  const { title, url, campaigns, loading, status = '' } = props;

  const {
    filters,
    changeFilters,
    increaseOffsetCoefficient,
    offsetCoefficient,
    campaignsRef,
  } = useFilterCampaigns({
    status,
    type: 'sms',
  });

  const campaignsContext = useCampaignsContext();
  const {
    setCurrentCampaign,
    campaignsState: { isUpdating, totalCount, isCampaignsInitialized },
  } = campaignsContext;

  // if filters changed just now, we don't want to
  // display SearchedCampaignEmptyState or CampaignEmptyState immediately
  const [didFiltersChange, setDidFiltersChange] = useState(false);

  const history = useHistory();

  const isCampaignsListUpdating = isUpdating;
  const isListComplete = (offsetCoefficient + 1) * DEFAULT_CAMPAIGNS_LIMIT < totalCount;

  // when a users clicks create
  const handleCreateCampaign = () => {
    // reset the current campaign state
    setCurrentCampaign({} as any);
    // navigate to the campaign builder
    history.push('/campaigns/create');
  };

  // check if loading next is enabled
  const enabledLoadingNext =
    // Is campaign list updating
    !isCampaignsListUpdating &&
    // If the list is already complete
    isListComplete &&
    // Is campaigns Initialized
    isCampaignsInitialized;

  // this is an event hook to prevent the UI "blinking" of the following sequence
  // CampaignEmptyState -> SearchedCampaignEmptyState -> loading -> SearchedCampaignEmptyState
  // when user search something inside an empty campaign list
  useEffect(() => {
    // if loading changed from true to false
    // it means the fetch campaigns api call has finished
    // we can now set didFiltersChange back to false
    // to allow SearchedCampaignEmptyState or CampaignEmptyState to be rendered
    if (!loading) {
      setDidFiltersChange(false);
    }
  }, [loading]);

  {
    /** Campaigns state - filter out duplicate ids */
  }
  const filteredCampaigns = useMemo(
    () =>
      campaigns.filter((campaign, index, self) => {
        return index === self.findIndex((t) => t.id === campaign.id);
      }),
    [campaigns]
  );

  return (
    <PageLayout
      breadcrumbs={[
        { title: 'Campaigns', path: '/campaigns' },
        { title: title, path: `/campaigns/${url}` },
      ]}
      actions={<Button onClick={handleCreateCampaign}>Create Campaign</Button>}
    >
      <ListContainer ref={campaignsRef} gap={1}>
        <CampaignSearch
          campaigns={campaigns}
          filters={filters}
          changeFilters={changeFilters}
          setDidFiltersChange={setDidFiltersChange}
          channelsType={ChannelTypes.PHONE}
        />

        {/** Loading state - don't show the loading state when the request is running but we already have some campaigns in state */}
        {(campaigns.length === 0 ||
          (filters.title === '' &&
            filters.locations.length === 0 &&
            filters.users.length === 0)) &&
          !didFiltersChange &&
          loading &&
          Array.from({ length: 20 }, (_: any, k: React.Key | null | undefined) => (
            <ItemContainer key={k}>
              <CampaignLoadingCard key={k} />
            </ItemContainer>
          ))}

        {/** Empty state - show the empty state when the request is finished and we don't have any campaigns in state that match the filters */}
        {!didFiltersChange &&
          !loading &&
          campaigns.length === 0 &&
          !isCampaignsListUpdating && (
            <>
              {filters.title !== '' ||
              filters.locations.length !== 0 ||
              filters.users.length !== 0 ? (
                <SearchedCampaignEmptyState />
              ) : (
                <CampaignEmptyState />
              )}
            </>
          )}
        {(!loading || didFiltersChange) && filteredCampaigns.length > 0 && (
          <Virtuoso
            data={filteredCampaigns}
            atBottomStateChange={(isAtBottom) => {
              if (isAtBottom && enabledLoadingNext) {
                increaseOffsetCoefficient();
              }
            }}
            itemContent={(index: number, campaign: Campaign) => (
              <ItemContainer key={campaign.id}>
                <CampaignCard key={campaign.id} campaign={campaign} />
              </ItemContainer>
            )}
            totalCount={totalCount}
            components={{
              Footer: () =>
                enabledLoadingNext ? (
                  <ItemContainer>
                    <CampaignLoadingCard />
                  </ItemContainer>
                ) : (
                  <Box css={{ pb: 10 }} />
                ),
            }}
          />
        )}
      </ListContainer>
    </PageLayout>
  );
};

export const SearchedCampaignEmptyState = () => {
  return (
    <EmptyStateContainer align="center" justify="center" direction="column">
      <Text css={{ fontWeight: 600 }}>No Campaign Matches Your Search</Text>
      <Text css={{ mt: 10 }}>Please try a different filter</Text>
    </EmptyStateContainer>
  );
};

export const CampaignEmptyState = () => {
  return (
    <EmptyStateContainer align="center" justify="center" direction="column">
      <Text css={{ fontWeight: 600 }}>No Campaigns</Text>
      <Text css={{ mt: 10 }}>{`Click "Create Campaign" to get started`}</Text>
    </EmptyStateContainer>
  );
};

const EmptyStateContainer = styled(Flex, {
  p: 20,
  mb: 20,
  height: '100%',
  width: '100%',
});

export const ListContainer = styled(VStack, {
  flexGrow: 1,
  flexShrink: 1,
  flexBasis: 'auto',
  minHeight: '100%',
  overflow: 'auto',
  maxWidth: '100%',
  pt: 30,
  pb: 0,
});

export const ItemContainer = styled(Box, {
  px: 30,
  pb: 10,
});
