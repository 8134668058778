export const default_agents_object = {
  id: 'unique-id-for-agents',
  label: 'Agent',
  key: 'agent',
  inserted_at: 'current-timestamp',
  updated_at: 'current-timestamp',
  custom_properties: [
    {
      default: '',
      id: 'agent-created-at',
      label: 'Created At',
      type: 'date',
      key: 'inserted_at',
      required: true,
      inserted_at: 'current-timestamp',
      updated_at: 'current-timestamp',
      custom_object_id: 'unique-id-for-agent',
    },
    {
      default: '',
      id: 'agent-updated-at',
      label: 'Updated At',
      type: 'date',
      key: 'updated_at',
      required: true,
      inserted_at: 'current-timestamp',
      updated_at: 'current-timestamp',
      custom_object_id: 'unique-id-for-agent',
    },
  ],
};

export const current_agent_version_object = {
  id: 'unique-id-for-agents',
  label: 'Current Agent Version',
  key: 'current_version',
  inserted_at: 'current-timestamp',
  updated_at: 'current-timestamp',
  custom_properties: [
    {
      default: '',
      id: 'current-agent-version-name',
      label: 'Name',
      type: 'text',
      key: 'name',
      required: true,
      inserted_at: 'current-timestamp',
      updated_at: 'current-timestamp',
      custom_object_id: 'unique-id-for-agent',
    },
    {
      default: '',
      id: 'current-agent-version-description',
      label: 'Agent Description',
      type: 'text',
      key: 'description',
      required: true,
      inserted_at: 'current-timestamp',
      updated_at: 'current-timestamp',
      custom_object_id: 'unique-id-for-agent',
    },
  ],
};

export const updated_by = {
  id: 'unique-id-for-agents',
  label: 'Updated By',
  key: 'updated_by',
  inserted_at: 'current-timestamp',
  updated_at: 'current-timestamp',
  custom_properties: [
    {
      default: '',
      id: 'updated-by-user',
      label: 'User',
      type: 'user',
      key: 'id',
      required: true,
      inserted_at: 'current-timestamp',
      updated_at: 'current-timestamp',
      custom_object_id: 'unique-id-for-agent',
    },
  ],
};

export const created_by = {
  id: 'unique-id-for-agents',
  label: 'Created By',
  key: 'created_by',
  inserted_at: 'current-timestamp',
  updated_at: 'current-timestamp',
  custom_properties: [
    {
      default: '',
      id: 'created-by-user',
      label: 'User',
      type: 'user',
      key: 'id',
      required: true,
      inserted_at: 'current-timestamp',
      updated_at: 'current-timestamp',
      custom_object_id: 'unique-id-for-agent',
    },
  ],
};
