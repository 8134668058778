import { HiChevronRight, HiDotsHorizontal } from 'react-icons/hi';

import { ConfirmationDialog } from '@/shared/components/ConfirmationDialog';
import {
  Box,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  DropdownSubMenu,
  DropdownSubMenuContent,
  DropdownSubTrigger,
  Flex,
  IconButton,
} from '@/shared/ui';

type SequenceActionButtonProps = {
  onDuplicateSequence: () => void;
  onDuplicateSequenceTemplate: () => void;
  onDelete: () => void;
};

const SequenceActionButton = (props: SequenceActionButtonProps): JSX.Element => {
  const { onDuplicateSequenceTemplate, onDuplicateSequence, onDelete } = props;

  return (
    <Box>
      <Flex justify="end">
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <IconButton aria-label="open dropdown" variant="outline" size="2">
              <HiDotsHorizontal size="15px" />
            </IconButton>
          </DropdownMenuTrigger>
          <DropdownMenuContent sideOffset={10}>
            <ConfirmationDialog
              title="Delete Sequence"
              description="Are you sure you want to delete this sequence?"
              onConfirm={onDelete}
              confirmButtonTitle="Yes, Delete Sequence"
              testID="delete-sequence-button"
              confirmButtonVariant="redBackground"
              cancelButtonVariant="grayBackground"
            >
              <DropdownMenuItem
                data-testid="delete-sequence-button"
                onClick={(e) => e.preventDefault()}
              >
                Delete Sequence
              </DropdownMenuItem>
            </ConfirmationDialog>
            <DropdownSubMenu>
              <DropdownSubTrigger data-testid="duplicate-sequence-trigger">
                <Flex justify="between" align="center" css={{ width: '100%' }}>
                  <Box>Duplicate Sequence</Box>
                  <Box>
                    <HiChevronRight />
                  </Box>
                </Flex>
              </DropdownSubTrigger>
              <DropdownSubMenuContent>
                <ConfirmationDialog
                  title="Duplicate Sequence"
                  description="Are you sure you want to duplicate this sequence?"
                  onConfirm={onDuplicateSequence}
                  confirmButtonTitle="Yes, Duplicate Sequence"
                  testID="duplicate-sequence-button"
                  confirmButtonVariant="primary"
                  cancelButtonVariant="grayBackground"
                >
                  <DropdownMenuItem
                    data-testid="duplicate-sequence-button"
                    onClick={(e) => e.preventDefault()}
                  >
                    Duplicate Sequence
                  </DropdownMenuItem>
                </ConfirmationDialog>
                <ConfirmationDialog
                  title="Duplicate as Template"
                  description="Are you sure you want to duplicate this sequence as template?"
                  onConfirm={onDuplicateSequenceTemplate}
                  confirmButtonTitle="Yes, Duplicate as Template"
                  testID="duplicate-as-template-button"
                  confirmButtonVariant="primary"
                  cancelButtonVariant="grayBackground"
                >
                  <DropdownMenuItem
                    data-testid="duplicate-as-template-button"
                    onClick={(e) => e.preventDefault()}
                  >
                    Duplicate as Template
                  </DropdownMenuItem>
                </ConfirmationDialog>
              </DropdownSubMenuContent>
            </DropdownSubMenu>
          </DropdownMenuContent>
        </DropdownMenu>
      </Flex>
    </Box>
  );
};

export default SequenceActionButton;
