import { useRef } from 'react';
import { HiCog } from 'react-icons/hi';

import { useClickOutside } from '@/shared/hooks/useClickOutside';
import { Box, IconButton } from '@/shared/ui';

import { VoIPSettings } from './VoIPSettings';

export const VoIPSettingsPopup = ({
  isOpen,
  setIsOpen,
}: {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}) => {
  const ref = useRef<HTMLDivElement>(null);
  useClickOutside(ref, () => setIsOpen(false));

  return (
    <Box ref={ref}>
      <IconButton
        onClick={() => setIsOpen(!isOpen)}
        variant="darkGray"
        css={{ background: 'transparent', width: 24, height: 24 }}
      >
        <HiCog />
      </IconButton>
      {isOpen && (
        <Box
          css={{
            position: 'absolute',
            top: 35,
            borderRadius: 8,
            left: '50%',
            background: '#1C2024',
            border: '1px solid #BDC8FF17',
            boxShadow: '0px 12px 60px 0px #0000000E',
            zIndex: 100,
          }}
        >
          <VoIPSettings />
        </Box>
      )}
    </Box>
  );
};
