import { ColumnType, Resource, SortConfig } from '@/shared/types/filter';

export const usersSortConfig: SortConfig<
  Resource.User | Resource.UserOrganization,
  ColumnType.Name | ColumnType.Email | ColumnType.Role
> = {
  label: 'Sort',
  columnOptions: [
    {
      label: 'Name',
      resource: Resource.User,
      column: ColumnType.Name,
      order: 'asc',
    },
    {
      label: 'Email',
      resource: Resource.User,
      column: ColumnType.Email,
      order: 'asc',
    },
    {
      label: 'Role',
      resource: Resource.UserOrganization,
      column: ColumnType.Role,
      order: 'asc',
    },
  ],
};

export const invitesSortConfig: SortConfig<
  Resource.Invite | Resource.ContactMessage,
  ColumnType.Email | ColumnType.InsertedAt
> = {
  label: 'Sort',
  columnOptions: [
    {
      label: 'Email',
      resource: Resource.Invite,
      column: ColumnType.Email,
      order: 'asc',
    },
    {
      label: 'Invited At',
      resource: Resource.Invite,
      column: ColumnType.InsertedAt,
      order: 'asc',
    },
  ],
};
