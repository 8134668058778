import { api } from './api';

export const getUserPreferences = () => {
  return api.get(`/preferences/user`).then((res) => {
    return res.data.data;
  });
};

export const updateUserPreferences = (params: any) => {
  return api.put(`/preferences/user`, params).then((res) => {
    return res.data.data;
  });
};
