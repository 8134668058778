import React from 'react';

import { VStack } from '@/shared/ui';

import { ReportsContentContainer } from '../layout/ReportsContentContainer';
import { Overview } from './Overview';
import { UserMessaging } from './UserMessaging';

export const MessagingReport = () => {
  return (
    <ReportsContentContainer title="Messaging">
      <VStack gap={2}>
        <Overview />
        <UserMessaging />
      </VStack>
    </ReportsContentContainer>
  );
};
