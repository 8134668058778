import React from 'react';

import { useDisclosure } from '@/shared/hooks';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogOverlay,
  AlertDialogPortal,
  AlertDialogTitle,
  AlertDialogTrigger,
  Button,
  Flex,
} from '@/shared/ui';

type CampaignUnscheduleDialog = {
  /** pass the component that is going to trigger the alert dialog */
  children: React.ReactNode;
  /** execute function on alert dialog confirmation */
  onConvertToDraft: () => void;
  /** if true the component works as expected, if false no dialog will be triggered */
  enabled: boolean;
};

const CampaignUnscheduleDialog = (props: CampaignUnscheduleDialog) => {
  const { children, onConvertToDraft, enabled } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleClose = () => {
    onConvertToDraft();
    onClose();
  };

  if (enabled) {
    return (
      <AlertDialog open={isOpen}>
        <AlertDialogTrigger asChild onClick={onOpen}>
          {children}
        </AlertDialogTrigger>
        <AlertDialogPortal>
          <AlertDialogOverlay css={{ zIndex: 9999999999 }}>
            <AlertDialogContent onEscapeKeyDown={onClose} css={{ zIndex: 99999999999 }}>
              <AlertDialogTitle>Un-schedule &amp; Edit Campaign</AlertDialogTitle>
              <AlertDialogDescription>
                You are about to edit a scheduled campaign. Editing a scheduled campaign
                will first unschedule it. You will need to re-schedule the campaign if you
                want to send it.
              </AlertDialogDescription>
              <Flex justify="end">
                <AlertDialogCancel asChild>
                  <Button variant="gray" css={{ mr: 10 }} onClick={onClose}>
                    Cancel
                  </Button>
                </AlertDialogCancel>
                <AlertDialogAction asChild>
                  <Button variant="red" onClick={handleClose}>
                    Yes, Un-schedule &amp; Edit
                  </Button>
                </AlertDialogAction>
              </Flex>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialogPortal>
      </AlertDialog>
    );
  } else {
    return <>{children}</>;
  }
};

export default CampaignUnscheduleDialog;
