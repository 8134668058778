import { styled } from '@stitches/react';
import { useKBar } from 'kbar';
import { forwardRef } from 'react';

import { showContactIcon } from '@/pages/inbox/list/ConversationPreview';
import { HighlightText } from '@/pages/inbox/list/utils';
import { Avatar, Box, Flex } from '@/shared/ui';
import { initials } from '@/shared/utils/initials/initials';
import { formatPhoneNumber } from '@/shared/utils/validations/validations';

import { useSearch } from '../SearchContext';
import {
  extractEmailParts,
  extractNumber,
  extractNumberParts,
  SearchResults,
} from '../utils';

export const ContactItem = forwardRef(
  (
    {
      action,
      active,
    }: {
      action: SearchResults;
      active: boolean;
    },
    ref: React.Ref<HTMLDivElement>
  ) => {
    const { highlightWords, searchInput } = useSearch();
    const { query } = useKBar();

    // Extract contact name, email, and phone from action data
    const contactName =
      action?.conversation?.contact?.name ??
      action?.conversation?.contact?.email ??
      action?.conversation?.contact?.phone;
    const contactEmail = action?.conversation?.contact?.email ?? '';
    const contactPhone = action?.conversation?.contact?.phone ?? '';

    // Check if search input is an email to determine email highlighting
    const inputIsEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(searchInput);
    const highlightEmailParts = extractEmailParts(searchInput);

    // Format phone number for display
    const phoneFormatted = formatPhoneNumber(contactPhone) || '';

    // Format search input phone number for highlighting
    const searchNumberFormatted = formatPhoneNumber(extractNumber(searchInput)) || '';
    const highlightPhoneParts = extractNumberParts(searchNumberFormatted);

    return (
      <StyledContactItem
        active={active}
        ref={ref}
        onClick={() => {
          action?.perform();
          query.toggle();
        }}
      >
        <Flex direction="column" css={{ width: '100%' }}>
          <Box
            css={{
              position: 'relative',
            }}
          >
            <NameContainer align="center" css={{ width: '100%' }}>
              <Avatar
                size="3"
                src={
                  showContactIcon(contactName || '')
                    ? `${window.location.origin}/outline.svg`
                    : ''
                }
                alt={contactName || 'No name'}
                fallback={initials(contactName || '')}
              />
              <StyledName>
                <HighlightText
                  highlightStyle={{ fontWeight: 500, backgroundColor: '#FFC106' }}
                  sentence={contactName || ''}
                  wordsToHighlight={highlightWords}
                  sensitivity={2}
                />
              </StyledName>
              <StyledPhone>
                <HighlightText
                  highlightStyle={{ fontWeight: 500, backgroundColor: '#FFC106' }}
                  sentence={phoneFormatted}
                  wordsToHighlight={highlightPhoneParts}
                  sensitivity={1}
                />
              </StyledPhone>
            </NameContainer>

            <NameContainer>
              {/* Highlight email if search input is an email */}
              {inputIsEmail && (
                <StyledEmail>
                  <HighlightText
                    highlightStyle={{ fontWeight: 500, backgroundColor: '#FFC106' }}
                    sentence={contactEmail}
                    wordsToHighlight={highlightEmailParts}
                  />
                </StyledEmail>
              )}
            </NameContainer>
            <MessagePreview>
              {action?.conversation?.conversationItemsPage?.conversationItems?.[0]?.body}
            </MessagePreview>
          </Box>
        </Flex>
      </StyledContactItem>
    );
  }
);

export const StyledContactItem = styled('div', {
  padding: '12px 16px',
  background: 'transparent',
  borderLeft: '2px solid transparent',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  cursor: 'pointer',
  '&:hover': {
    background: '$gray1',
    backgroundColor: '$gray3',
  },
  variants: {
    active: {
      true: {
        background: '$gray1',
        borderLeftColor: '$foreground',
        backgroundColor: '$gray3',
      },
    },
  },
});

const NameContainer = styled(Flex, {
  flexDirection: 'column',
  alignItems: 'flex-start',
  pb: 4,
  width: '100%',
});

const StyledPhone = styled(Box, {
  fontSize: 11,
  marginLeft: 'auto',
});

export const StyledName = styled(Box, {
  overflow: 'hidden',
  fontWeight: 500,
  fontSize: 13,
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  maxWidth: 350,
  marginLeft: 8,
});

export const StyledEmail = styled(Box, {
  fontSize: 11,
  marginLeft: 'auto',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  maxWidth: '100%',
  marginTop: -2,
});

const MessagePreview = styled(Box, {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  fontSize: 12,
  margin: '0 2px 0 4px',
});
