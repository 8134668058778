import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { HiExternalLink } from 'react-icons/hi';

import { HelpCard } from '@/settings/help/HelpCard';
import * as API from '@/shared/api/campaigns';
import { Campaign } from '@/shared/types/campaigns';
import {
  Box,
  Flex,
  HStack,
  IconButton,
  Skeleton,
  Text,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
  VStack,
} from '@/shared/ui';
import { CSS, styled } from '@/stitches.config';

type CampaignsPanelProps = {
  contactId?: string;
};

export const CampaignsPanel = (props: CampaignsPanelProps) => {
  const { contactId } = props;

  const [loading, setLoading] = useState(true);
  const [campaigns, setCampaigns] = useState<Campaign[]>([]);

  useEffect(() => {
    if (contactId) {
      setLoading(true);
      listCampaigns(contactId);
    } else {
      setLoading(false);
    }
  }, [contactId]);

  const listCampaigns = async (id: string) => {
    if (id) {
      const campaigns = await API.getContactCampaigns(id);
      setCampaigns(campaigns?.campaigns || []);
      setLoading(false);
    }
  };

  if (loading) {
    return <CampaignsLoadingState />;
  }

  if (campaigns.length === 0 && !loading) {
    return <CampaignsEmptyState />;
  }

  return (
    <StyledCampaignsContainer direction="column">
      <VStack>
        {campaigns
          .sort((a: Campaign, b: Campaign) =>
            ((b || {}).inserted_at || '').localeCompare((a || {}).inserted_at || '')
          )
          .map((campaign: Campaign) => {
            return <CampaignItem campaign={campaign} key={campaign.id} />;
          })}
      </VStack>
    </StyledCampaignsContainer>
  );
};

const CampaignsLoadingState = () => {
  return (
    <StyledCampaignsContainer direction="column">
      <VStack gap={3}>
        {Array.from({ length: 10 }, (_: any, k: React.Key | null | undefined) => (
          <CampaignCardContainer direction="column" key={k}>
            <Skeleton
              variant="title"
              css={{ height: 10, width: '50%', minWidth: '50%' }}
            />
            <Skeleton variant="subheading" css={{ width: '100%', minWidth: '100%' }} />
          </CampaignCardContainer>
        ))}
      </VStack>
    </StyledCampaignsContainer>
  );
};

const CampaignsEmptyState = () => {
  return (
    <StyledCampaignsContainer>
      <HelpCard
        title="Create a Campaign"
        description="Broadcast a Message to your subscribers."
        loom_id="b17b10b9d00c4fba8c8d7442ad0d5893"
      />
    </StyledCampaignsContainer>
  );
};

type CampaignItemProps = {
  campaign: Campaign;
};

const CampaignItem = (props: CampaignItemProps) => {
  const { campaign } = props;

  return (
    <CampaignCardContainer direction="column" justify="between">
      <Flex justify="between" align="center">
        <Flex direction="column" align="start">
          <CampaignTitle>{campaign.title}</CampaignTitle>
          <Text size={1} css={{ mt: 2 }}>
            Sent by {campaign?.updated_by?.name || campaign?.updated_by?.email || 'API'}{' '}
            on {dayjs(campaign.updated_at).format('DD/MM/YY')}
          </Text>
        </Flex>
        <HStack gap={1}>
          <Tooltip>
            <TooltipTrigger asChild>
              <a
                href={`${window.location.origin}/campaigns/${campaign.id}`}
                target="_blank"
                rel="noreferrer"
              >
                <IconButton size={2}>
                  <HiExternalLink size={17} />
                </IconButton>
              </a>
            </TooltipTrigger>
            <TooltipContent>View Campaign</TooltipContent>
          </Tooltip>
        </HStack>
      </Flex>
    </CampaignCardContainer>
  );
};

const clampStyle: CSS = {
  display: '-webkit-box',
  '-webkit-line-clamp': 1,
  '-webkit-box-orient': 'vertical',
  overflow: 'hidden !important',
  textOverflow: 'ellipsis',
};

const StyledCampaignsContainer = styled(Flex, {
  p: 20,
  height: '100%',
  width: '100%',
});

const CampaignCardContainer = styled(Flex, {
  fontSize: 13,
  py: 10,
  px: 10,
  width: '100%',
  minWidth: '100%',
  borderRadius: 4,
  boxShadow: 'rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;',
});

const CampaignTitle = styled(Box, {
  fontSize: 13,
  fontWeight: 600,
  mb: 2,
  ...clampStyle,
});
