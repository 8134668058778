import { useUsers } from '@/pages/settings/organization/users/context/UserContext';
import { User } from '@/shared/types/users';

import { FormFieldWrapper, helpers } from '../../../forms';
import { MultiSelectCombobox } from '../../../forms';
import { ActionProps } from '.';

export const NotifyUserAction = (props: ActionProps) => {
  const { index } = props;

  const users = useUsers();
  const { userState } = users;
  const { users: allUsers } = userState;

  return (
    <FormFieldWrapper
      label="Notify Users"
      tooltip="Select users to notify when this action is executed"
      name={`actions[${index}].params.user_ids`}
    >
      <MultiSelectCombobox
        placeholder={helpers.displaySelectedItems}
        isDropdown={true}
        options={allUsers.map((user: User) => ({
          type: user.name || user.email,
          value: `${user.id}`,
        }))}
      />
    </FormFieldWrapper>
  );
};
