import { styled } from '@stitches/react';

import { ArrowIcon } from './icons';
import { KbdStyled } from './utils';

export const SearchFooter = () => {
  //   const { searchInput } = useSearch();
  return (
    <ShortCutSection>
      <Shortcut>
        <KbdStyled>
          <ArrowIcon direction="up" />
        </KbdStyled>
        <KbdStyled>
          <ArrowIcon direction="down" />
        </KbdStyled>{' '}
        <TextStyled>to navigate</TextStyled>
      </Shortcut>
      {/* TODO: Possibly only render these if there is a search input */}
      {/* {searchInput && ( */}
      <Shortcut>
        <KbdStyled>
          <ArrowIcon direction="left" />
        </KbdStyled>
        <KbdStyled>
          <ArrowIcon direction="right" />
        </KbdStyled>{' '}
        <TextStyled>to switch tabs</TextStyled>
      </Shortcut>

      <Shortcut style={{ marginLeft: 'auto' }}>
        <KbdStyled>Esc</KbdStyled>
        <TextStyled>to close</TextStyled>
      </Shortcut>
      {/* )} */}
    </ShortCutSection>
  );
};

const ShortCutSection = styled('div', {
  display: 'flex',
  alignItems: 'center',
  padding: 6,
  fontSize: 12,
  background: '$gray1',
  backgroundColor: '#F4F4F4',
  borderTop: '1px solid #E5E5E5',
  position: 'absolute',
  bottom: 0,
  width: '100%',
});

const Shortcut = styled('div', {
  display: 'flex',
  alignItems: 'center',
  padding: 6,
  fontSize: 12,
  height: 32,
});

const TextStyled = styled('span', {
  color: '$gray11',
  userSelect: 'none',
});
