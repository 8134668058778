import DromoUploader from 'dromo-uploader-react';
import React from 'react';

import { useAuth } from '@/auth/context/AuthProvider';
import { DROMO_API_KEY } from '@/shared/utils/config';

import { useTemplates } from './context/TemplatesContext';

const TemplatesBulkImport = ({ children }: { children: React.ReactNode }) => {
  const auth = useAuth();
  const templatesContext = useTemplates();

  const fields: Array<any> = [
    {
      label: 'Title',
      key: 'title',
      validators: [{ validate: 'required' }],
    },
    {
      label: 'Message',
      key: 'message',
      validators: [{ validate: 'required' }],
    },
  ];

  const settings = {
    version: 'v2',
    importIdentifier: 'Templates',
    displayEncoding: false,
    allowInvalidSubmit: false,
    backendSync: true,
    manualInputDisabled: false,
    manualInputOnly: false,
    allowCustomFields: false,
    maxRecords: null,
    developmentMode: window.location.hostname !== 'app.whippy.co',
    styleOverrides: {
      primaryButton: {
        backgroundColor: '#3E54CF',
        textColor: '#FFFFFF',
      },
    },
  };

  const user = {
    id: `${auth?.tokens?.user_id}`,
    name: auth?.tokens?.name || '',
    email: auth?.tokens?.email || '',
    companyId: auth.organizationId || '',
    companyName: auth.organizationName || '',
  };

  return (
    <DromoUploader
      licenseKey={DROMO_API_KEY}
      settings={settings}
      fields={fields}
      user={user}
      onResults={(data) => templatesContext.bulkImportMultipleTemplates(data)}
    >
      {children}
    </DromoUploader>
  );
};

export default TemplatesBulkImport;
