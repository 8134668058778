/* eslint-disable react-hooks/exhaustive-deps */
import 'react-dayjs-picker/dist/index.css';

import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { HiSpeakerphone, HiTag } from 'react-icons/hi';
import { useMedia } from 'react-use';

import { useAuth } from '@/auth/context/AuthProvider';
import { QuickCampaignDialog } from '@/campaigns/quick';
import { useTags } from '@/pages/settings/organization/tags/context/TagsContext';
import { Contact } from '@/shared/types';
import { Contact as CampaignContact } from '@/shared/types/campaigns';
import { Avatar, Box, Flex, HStack, VStack } from '@/shared/ui';

import { useContacts } from '../context/ContactContext';
import { useUploads } from '../uploads/context/UploadContext';
import { ContactButton, ContactEditorLayout, ContactName } from '.';
import { ArchiveContact } from './ArchiveContact';
import { AddContactTags } from './tags/AddContactTags';
import { ContactTag } from './tags/ContactTag';

type ContactBulkActionsProps = {
  /* the array of contacts that are checked, could be contact objects or contact ids */
  checkedContacts: Contact[] | string[] | CampaignContact[];
  /* the function to check a contact */
  checkContact: (
    contacts: Contact[] | string[] | CampaignContact[]
  ) => void | Dispatch<SetStateAction<Contact[]>>;
  /* whether all contacts are checked */
  allContactsChecked: boolean;
  /* the total number of contacts e.g. could be 10,000 contacts in the org but 50 in state, so this is the 10,000 number */
  totalContacts: number | undefined | null;
  /* the audience of the bulk message */
  bulkMessageAudience: any; // Replace 'any' with the actual type if known
  /* whether the current page is the contacts page */
  isContactsPage: boolean;
  /* whether the current page is the groups page */
  isGroupsPage: boolean;
  /* whether the current page is the uploads page */
  isUploadsPage: boolean;
  /* the function to set the checked contacts to the search result */
  setSearchResultChecked: (checked: boolean) => void;
  /* the function to set all contacts to checked */
  setAllContactsChecked: (checked: boolean) => void;
};

export const ContactBulkActions = (props: ContactBulkActionsProps): JSX.Element => {
  const {
    checkedContacts,
    checkContact,
    allContactsChecked,
    totalContacts,
    bulkMessageAudience,
    isContactsPage,
    isGroupsPage,
    isUploadsPage,
    setSearchResultChecked,
    setAllContactsChecked,
  } = props;
  const isDesktop = useMedia('(min-width: 912px)');
  const { contactState, archiveContact, archiveContacts, clearCurrent, onClose } =
    useContacts();
  const { current } = contactState;

  const { getUploads } = useUploads();
  const [quickCampaignDialogState, setQuickCampaignDialogState] = useState(false);

  const tagContext = useTags();
  const { getTags, tagsState } = tagContext;
  const { allTags } = tagsState;

  const auth = useAuth();
  const { isAdmin } = auth;

  useEffect(() => {
    getTags();
    getUploads();
  }, []);

  const onArchive = () => {
    if (checkedContacts.length > 1) {
      const checkedContactsIds = (checkedContacts as Contact[]).map(
        (contact: Contact) => {
          return contact.id;
        }
      );
      archiveContacts(checkedContactsIds);
      checkContact([]);
    } else {
      archiveContact(current?.id as string);
      checkContact([]);
    }

    clearCurrent();
    if (!isDesktop) {
      onClose();
    }
  };

  const [showSchedule, setShowSchedule] = useState(false);

  const toggleSchedule = () => {
    setShowSchedule(!showSchedule);
  };

  const quickCampaignDialogStateChange = () => {
    setQuickCampaignDialogState(!quickCampaignDialogState);
    setShowSchedule(false);
  };

  return (
    <ContactEditorLayout>
      <VStack gap={3} css={{ p: '$5' }}>
        <Flex direction="column" align="center" justify="center">
          <Avatar
            size="profile"
            fallback={`${
              props.allContactsChecked ? totalContacts : checkedContacts.length
            }`}
          />
          <ContactName>Selected</ContactName>
        </Flex>
        <HStack gap={1} css={{ justifyContent: 'center' }}>
          <Box>
            <QuickCampaignDialog
              quickCampaignDialogState={quickCampaignDialogState}
              setQuickCampaignDialogState={setQuickCampaignDialogState}
              quickCampaignDialogStateChange={quickCampaignDialogStateChange}
              showSchedule={showSchedule}
              toggleSchedule={toggleSchedule}
              checkedContacts={checkedContacts as CampaignContact[]}
              setCheckedContacts={checkContact}
              allContactsChecked={allContactsChecked}
              audience={bulkMessageAudience}
              isContactsPage={isContactsPage}
              isGroupsPage={isGroupsPage}
              isUploadsPage={isUploadsPage}
            >
              <ContactButton icon={<HiSpeakerphone size={18} />} text="Quick Campaign" />
            </QuickCampaignDialog>
          </Box>
          <Box>
            <AddContactTags
              tags={allTags}
              checkedContacts={props.checkedContacts as CampaignContact[]}
              setCheckedContacts={checkContact}
              isBulkAdd={true}
              setSearchResultChecked={setSearchResultChecked}
              setAllContactsChecked={setAllContactsChecked}
              isGroupsPage={isGroupsPage}
              isUploadsPage={isUploadsPage}
              isCampaignsPage={false}
            >
              <>
                {/* As long as there exists a single tag under current user, we can render a big "+" button
                   Otherwise, we can render a "Create Tag" button (index = 1).
                   The logic behind "Create Tag" button is implemented inside <ContactTag>
                */}
                {allTags.length > 0 ? (
                  <ContactButton icon={<HiTag size={18} />} text={'Add Tag'} />
                ) : (
                  <ContactTag isClosable={false} index={1} />
                )}
              </>
            </AddContactTags>
          </Box>

          {isAdmin && (
            <Box>
              <ArchiveContact alertAction={onArchive} groups={true} />
            </Box>
          )}
        </HStack>
      </VStack>
    </ContactEditorLayout>
  );
};
