import React from 'react';

import { IntegrationActions, IntegrationActionTypes } from '@/shared/types/integrations';

import { IntegrationsState } from './IntegrationsContext';

export const IntegrationReducer: React.Reducer<IntegrationsState, IntegrationActions> = (
  state: IntegrationsState,
  action: IntegrationActions
) => {
  switch (action.type) {
    case IntegrationActionTypes.SET_CURRENT:
      return {
        ...state,
        current: action.payload,
      } as IntegrationsState;

    case IntegrationActionTypes.GET_INTEGRATION:
      return {
        ...state,
        current: action.payload,
        loading: false,
      } as IntegrationsState;

    case IntegrationActionTypes.GET_INTEGRATIONS:
      return {
        ...state,
        integrations: action.payload,
        loading: false,
      } as IntegrationsState;

    case IntegrationActionTypes.SET_INTEGRATIONS:
      return {
        ...state,
        integrations: action.payload,
        loading: false,
      } as IntegrationsState;

    case IntegrationActionTypes.SET_INTEGRATION_RESPONSES_LOADING:
      return {
        ...state,
        loading: action.payload,
      } as IntegrationsState;

    case IntegrationActionTypes.FILTER_INTEGRATIONS: {
      return {
        ...state,
        integrations: action.payload,
        loading: false,
      } as IntegrationsState;
    }
  }
};
