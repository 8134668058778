import { FormikValues, useFormikContext } from 'formik';
import { AsYouType, CountryCode } from 'libphonenumber-js';
import React from 'react';

import { Input } from '@/shared/ui';

import { TextInputProps } from './types';

type PhoneNumberInputProps = TextInputProps & {
  countryCode?: CountryCode;
};

/** A phone input that can be used in conjunction with Formik, must be placed within a FormFieldWrapper */
export function PhoneNumberInput(props: PhoneNumberInputProps): JSX.Element {
  const formik = useFormikContext<FormikValues>();

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let phoneNumber = e.target.value;
    if (
      formik.values[props.name as string].endsWith(')') &&
      phoneNumber.length === formik.values[props.name as string].length - 1
    ) {
      phoneNumber = phoneNumber.slice(0, -1);
    }
    formik.setFieldValue(
      props.name as string,
      new AsYouType(props?.countryCode || 'US').input(phoneNumber)
    );
  };

  return (
    <Input
      {...formik.getFieldProps(props?.name as string)}
      type="tel"
      css={{ ...props.css }}
      placeholder={props.placeholder}
      aria-label={props.ariaLabel}
      autoComplete="chrome-off"
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleOnChange(e)}
      data-intercom-target={props.intercomId}
    />
  );
}
