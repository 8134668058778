export enum SettingsActionType {
  GET_ORGANIZATION_INFO = 'GET_ORGANIZATION_INFO',
  UPDATE_SETTINGS = 'UPDATE_SETTINGS',
  SETTINGS_ERROR = 'SETTINGS_ERROR',
}

export enum OptOutOptions {
  LOCATION = 'location',
  CHANNEL_TYPE = 'channel_type',
  ALL = 'all',
}
