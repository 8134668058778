// example response from the API for stripe charges
export const demo_stripe_charges = [
  {
    id: 'ch_1IvL7zKHz6fcJLC84iwTEIob',
    amount: 100,
    created: 1622029343,
    receipt_url:
      'https://pay.stripe.com/receipts/payment/CAcaFwoVYWNjdF8xR25SNDZLSHo2ZmNKTEM4KMPE7JoGMgbb6eYwZFE6LBaf725ca35mlPFLfomnWBVrVHy201MIHpNs9F78gXeVVGpPdmhTlLEQW8C4',
    status: 'succeeded',
  },
  {
    id: 'ch_1IvL7zKHz6fcJLC8Q4Z5Z7Zg',
    amount: 100,
    created: 1622029343,
    receipt_url:
      'https://pay.stripe.com/receipts/payment/CAcaFwoVYWNjdF8xR25SNDZLSHo2ZmNKTEM4KMPE7JoGMgbb6eYwZFE6LBaf725ca35mlPFLfomnWBVrVHy201MIHpNs9F78gXeVVGpPdmhTlLEQW8C4',
    status: 'succeeded',
  },
];

// example response from the API for credit grants
export const demo_credits_grants = {
  total_available_credits: 100,
  credit_grants_count: 10,
  credit_grants: [
    {
      id: 'credit_1IvL7zKHz6fcJLC8Q4Z5Z7Zg',
      credits_granted: 100,
      credits_used: 0,
      description: 'test',
      inserted_at: '2021-05-26T15:55:43.000Z',
      updated_at: '2021-05-26T15:55:43.000Z',
      expires_at: '2021-05-26T15:55:43.000Z',
    },
    {
      id: 'credit_1IvL7zKHz6fcJLC8Q4Z5Z7Zg',
      credits_granted: 100,
      credits_used: 0,
      description: 'test',
      inserted_at: '2021-05-26T15:55:43.000Z',
      updated_at: '2021-05-26T15:55:43.000Z',
      expires_at: '2021-05-26T15:55:43.000Z',
    },
  ],
};

// example response from the API for a months usage
export const demo_usage = {
  date: '2021-05-26',
  total_credits_used: 100,
  sms_credits_used: 100,
  mms_credits_used: 100,
  overage_charge: 5000,
  credits_applied: 1000,
};

const demo_billing_contacts = [
  {
    id: 'contact_1IvL7zKHz6fcJLC8Q4Z5Z7Zg',
    billing_contact_name: 'Jack Kennedy',
    billing_contact_email: 'jack@example.com',
    billing_contact_phone: '+15555555555',
  },
  {
    id: 'contact_1IvL7zKHz6fcJLC8Q4Z5Z7Zg',
    billing_contact_name: 'Jack Kennedy',
    billing_contact_email: 'john@example.com',
    billing_contact_phone: '+15555555554',
  },
];

const demo_billing_contracts = [
  {
    id: 'contract_1IvL7zKHz6fcJLC8Q4Z5Z7Zg',
    billing_contract_url: 'https://www.google.com',
    billing_contract_start_date: '2020-05-26T15:55:43.000Z',
    billing_contract_end_date: '2021-05-26T15:55:43.000Z',
  },
  {
    id: 'contract_1IvL7zKHz6fcJLC8Q4Z5Z7Zg',
    billing_contract_url: 'https://www.google.com',
    billing_contract_start_date: '2022-05-26T15:55:43.000Z',
    billing_contract_end_date: '2024-05-26T15:55:43.000Z',
  },
];

// example response from the API for an organizations current plan
export const demo_current_plan = {
  id: 'org_1IvL7zKHz6fcJLC8Q4Z5Z7Zg',
  plan: 'free',
  subscription_amount: 10000,
  credits_included: 40000,
  credits_per_overage_charge: 1000,
  minimum_overage_charge: 50,
  free_inbound_sms: true,
  credit_rollover_allowed: true,
  credit_rollover_months: 1,
  billing_status: 'active',
  billing_frequency: 'monthly',
  billing_contacts: demo_billing_contacts,
  billing_contracts: demo_billing_contracts,
};
