import * as AccordionPrimitive from '@radix-ui/react-accordion';
import React from 'react';
import { HiBan, HiCheck } from 'react-icons/hi';

import { AccordionValue } from '@/shared/types/links';
import { Box, Button, Flex } from '@/shared/ui';
import { CSS, styled } from '@/stitches.config';

type LinkAccordionProps = {
  /* index in list of accordion items */
  index: number;
  /* accordion item title */
  title: string;
  /* accordion item description */
  description: string;
  /* currently open accordion */
  currentAccordionValue: AccordionValue;
  /* the accordion value */
  itemValue: AccordionValue;
  /* set the accordion value */
  setItemValue: React.Dispatch<React.SetStateAction<AccordionValue>>;
  /* is the accordion content valid for sending */
  isValid?: boolean;
  /* is there an error the user needs to fix? */
  isError?: boolean;
  /* custom per item accordion content */
  children: React.ReactNode;
  /* custom button copy */
  buttonCopy?: string;
  /* hide index and green box */
  hideIndex?: boolean;
};

export const LinkAccordion = (props: LinkAccordionProps) => {
  const {
    currentAccordionValue,
    itemValue,
    setItemValue,
    isValid,
    isError,
    index,
    title,
    description,
    children,
    buttonCopy,
    hideIndex,
  } = props;

  return (
    <StyledItem value={itemValue} error={isError}>
      <Box
        onClick={() =>
          setItemValue(
            currentAccordionValue === itemValue ? AccordionValue.DEFAULT_VALUE : itemValue
          )
        }
      >
        <StyledHeader>
          <StyledTrigger>
            <Flex css={{ py: 16 }} align="center">
              {!isValid && !isError && !hideIndex && (
                <StyledIcon align="center" justify="center" variant="default">
                  {index}
                </StyledIcon>
              )}
              {isValid && !isError && !hideIndex && (
                <StyledIcon align="center" justify="center" variant="valid">
                  <HiCheck size={16} />
                </StyledIcon>
              )}
              {isError && (
                <StyledIcon align="center" justify="center" variant="error">
                  <HiBan size={16} />
                </StyledIcon>
              )}
              <Flex direction="column">
                <Box
                  css={{
                    fontSize: 16,
                    fontWeight: 600,
                  }}
                >
                  {title}
                </Box>
                <Box
                  css={{
                    fontSize: 12,
                    fontWeight: 400,
                    color: '#7F7F86',
                  }}
                >
                  {description}
                </Box>
              </Flex>
            </Flex>
            <Button
              css={{ minWidth: 70 }}
              variant="gray"
              data-testid={`${itemValue}-button`}
            >
              {buttonCopy || 'Edit'}
            </Button>
          </StyledTrigger>
        </StyledHeader>
      </Box>
      <StyledContent>{children}</StyledContent>
    </StyledItem>
  );
};

type AccordionPrimitiveProps = React.ComponentProps<typeof AccordionPrimitive.Root>;
type AccordionProps = AccordionPrimitiveProps & { css?: CSS };

export const Accordion = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Root>,
  AccordionProps
>(({ children, ...props }, forwardedRef) => (
  <AccordionPrimitive.Root
    ref={forwardedRef}
    {...props}
    {...(props.type === 'single' ? { collapsible: true } : {})}
  >
    {children}
  </AccordionPrimitive.Root>
));

const StyledItem = styled(AccordionPrimitive.Item, {
  borderRadius: '6px',
  backgroundColor: 'white',

  '&[data-state="closed"]': {
    border: '1px solid #E8E8E8',
  },

  '&[data-state="open"]': {
    border: '1px solid #3E54CF',
    filter: 'drop-shadow(0px 4px 32px rgba(62, 84, 207, 0.08))',
  },

  // if there is an error make the border red
  variants: {
    error: {
      true: {
        '&[data-state="closed"]': {
          border: '1px solid #FF4D4F',
        },

        '&[data-state="open"]': {
          border: '1px solid #FF4D4F',
          filter: 'drop-shadow(0px 4px 32px rgba(255, 77, 79, 0.08))',
        },
      },
    },
  },
});

const StyledHeader = styled(AccordionPrimitive.Header, {
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',

  '&[data-state="open"]': {
    borderBottom: '1px solid #E8E8E8',
  },
});

const StyledTrigger = styled(AccordionPrimitive.Trigger, {
  all: 'unset',
  boxSizing: 'border-box',
  userSelect: 'none',
  '&::before': {
    boxSizing: 'border-box',
  },
  '&::after': {
    boxSizing: 'border-box',
  },

  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  px: '24px',
  color: '$hiContrast',
  width: '100%',

  cursor: 'pointer',
});

const StyledContent = styled(AccordionPrimitive.Content, {
  p: 24,
});

const StyledIcon = styled(Flex, {
  mr: 16,
  height: 25,
  width: 25,
  borderRadius: '100%',

  variants: {
    variant: {
      default: {
        color: 'rgba(189, 189, 192, 1)',
        borderWidth: 1.5,
        fontSize: 12,
        fontWeight: 'bold',
      },
      valid: {
        color: 'white',
        backgroundColor: 'rgba(40, 208, 127, 1)',
      },
      error: {
        color: 'white',
        backgroundColor: '$red10',
      },
    },
  },
});
