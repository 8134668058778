import React from 'react';

import { Box } from '@/shared/ui';

import { ReportsContentContainer } from '../layout/ReportsContentContainer';

export const TagsReport = () => {
  return (
    <ReportsContentContainer title="Tags">
      <Box>Tags</Box>
    </ReportsContentContainer>
  );
};
