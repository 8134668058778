/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-empty-function */
import { KBarProvider } from 'kbar';
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { BsArrowRight } from 'react-icons/bs';
import { useHistory } from 'react-router-dom';

import { generateSearchWords } from '@/pages/inbox/list/utils';

import { GlobalSearch } from '.';
import { SearchResults } from './utils';

export enum TabOptions {
  All = 'All',
  Contacts = 'Contacts',
  Messages = 'Messages',
  Campaigns = 'Campaigns',
  Sequences = 'Sequences',
}

// Define context structure for global search functionality
export const SearchContext = createContext<{
  searchInput: string;
  setSearchInput: React.Dispatch<React.SetStateAction<string>>;
  highlightWords: string[];
  selectedTab: TabOptions;
  setSelectedTab: React.Dispatch<React.SetStateAction<TabOptions>>;
  activeItemIndex: number;
  setActiveItemIndex: React.Dispatch<React.SetStateAction<number>>;
  tabs: TabOptions[];
  loadings: {
    [TabOptions.All]: boolean;
    [TabOptions.Contacts]: boolean;
    [TabOptions.Messages]: boolean;
    [TabOptions.Campaigns]: boolean;
    [TabOptions.Sequences]: boolean;
  };
  setLoadings: React.Dispatch<
    React.SetStateAction<{
      [TabOptions.All]: boolean;
      [TabOptions.Contacts]: boolean;
      [TabOptions.Messages]: boolean;
      [TabOptions.Campaigns]: boolean;
      [TabOptions.Sequences]: boolean;
    }>
  >;
  errors: {
    [TabOptions.All]: { hasError: boolean; error: string };
    [TabOptions.Contacts]: { hasError: boolean; error: string };
    [TabOptions.Messages]: { hasError: boolean; error: string };
    [TabOptions.Campaigns]: { hasError: boolean; error: string };
    [TabOptions.Sequences]: { hasError: boolean; error: string };
  };
  setErrors: React.Dispatch<
    React.SetStateAction<{
      [TabOptions.All]: { hasError: boolean; error: string };
      [TabOptions.Contacts]: { hasError: boolean; error: string };
      [TabOptions.Messages]: { hasError: boolean; error: string };
      [TabOptions.Campaigns]: { hasError: boolean; error: string };
      [TabOptions.Sequences]: { hasError: boolean; error: string };
    }>
  >;
  offsets: {
    [TabOptions.All]: number;
    [TabOptions.Contacts]: number;
    [TabOptions.Messages]: number;
    [TabOptions.Campaigns]: number;
    [TabOptions.Sequences]: number;
  };
  setOffsets: React.Dispatch<
    React.SetStateAction<{
      [TabOptions.All]: number;
      [TabOptions.Contacts]: number;
      [TabOptions.Messages]: number;
      [TabOptions.Campaigns]: number;
      [TabOptions.Sequences]: number;
    }>
  >;
  cancelPreviousRequest: (key: string) => void;
  controllers: {
    [key: string]: AbortController | null;
  };
  setControllers: React.Dispatch<
    React.SetStateAction<{
      [key: string]: AbortController | null;
    }>
  >;
  resetAllLoadings: () => void;
  searchResults: { [key in TabOptions]: SearchResults[] };
  setSearchResults: React.Dispatch<
    React.SetStateAction<{ [key in TabOptions]: SearchResults[] }>
  >;
  paginatings: {
    [TabOptions.All]: boolean;
    [TabOptions.Contacts]: boolean;
    [TabOptions.Messages]: boolean;
    [TabOptions.Campaigns]: boolean;
    [TabOptions.Sequences]: boolean;
  };
  setPaginatings: React.Dispatch<
    React.SetStateAction<{
      [TabOptions.All]: boolean;
      [TabOptions.Contacts]: boolean;
      [TabOptions.Messages]: boolean;
      [TabOptions.Campaigns]: boolean;
      [TabOptions.Sequences]: boolean;
    }>
  >;
  resetAllSearchResults: () => void;
}>({
  searchInput: '',
  setSearchInput: () => {},
  searchResults: {
    [TabOptions.All]: [],
    [TabOptions.Contacts]: [],
    [TabOptions.Messages]: [],
    [TabOptions.Campaigns]: [],
    [TabOptions.Sequences]: [],
  },
  setSearchResults: () => {},
  activeItemIndex: 0,
  setActiveItemIndex: () => {},
  highlightWords: [],
  selectedTab: TabOptions.All,
  setSelectedTab: () => {},
  tabs: [
    TabOptions.All,
    TabOptions.Contacts,
    TabOptions.Messages,
    TabOptions.Campaigns,
    TabOptions.Sequences,
  ],
  loadings: {
    [TabOptions.All]: false,
    [TabOptions.Contacts]: false,
    [TabOptions.Messages]: false,
    [TabOptions.Campaigns]: false,
    [TabOptions.Sequences]: false,
  },
  setLoadings: () => {},
  errors: {
    [TabOptions.All]: { hasError: false, error: '' },
    [TabOptions.Contacts]: { hasError: false, error: '' },
    [TabOptions.Messages]: { hasError: false, error: '' },
    [TabOptions.Campaigns]: { hasError: false, error: '' },
    [TabOptions.Sequences]: { hasError: false, error: '' },
  },
  setErrors: () => {},
  offsets: {
    [TabOptions.All]: 0,
    [TabOptions.Contacts]: 0,
    [TabOptions.Messages]: 0,
    [TabOptions.Campaigns]: 0,
    [TabOptions.Sequences]: 0,
  },
  setOffsets: () => {},
  cancelPreviousRequest: () => {},
  controllers: {},
  setControllers: () => {},
  resetAllLoadings: () => {},
  paginatings: {
    [TabOptions.All]: false,
    [TabOptions.Contacts]: false,
    [TabOptions.Messages]: false,
    [TabOptions.Campaigns]: false,
    [TabOptions.Sequences]: false,
  },
  setPaginatings: () => {},
  resetAllSearchResults: () => {},
});

// Convert searchActions into a function
export const useSearchActions = () => {
  const history = useHistory();

  // TODO: device specific shortcuts
  // const isMac = window.navigator.platform.toUpperCase().indexOf('MAC') >= 0;
  // const shortcutSymbol = isMac ? <PiCommand /> : 'Ctrl';
  // const shortcutSymbol = isMac ? '⌘' : 'Ctrl';

  return [
    {
      id: 'inbox',
      name: 'Go to Inbox',
      shortcut: ['i'],
      keywords: 'inbox messages conversations',
      perform: () => {
        history.push('/inbox/all/open');
      },
      section: 'Navigation',
      icon: <BsArrowRight />,
    },
    {
      id: 'new-message',
      name: 'Create New Message',
      shortcut: ['n'],
      keywords: 'new message',
      section: 'Navigation',
      perform: () => {
        history.push('/inbox/all/open/new');
      },
      icon: <BsArrowRight />,
    },
    {
      id: 'contact',
      name: 'Go to Contacts',
      shortcut: ['c'],
      keywords: 'contact email',
      section: 'Navigation',
      icon: <BsArrowRight />,
      perform: () => {
        history.push('/contacts');
      },
    },
    {
      id: 'campaign',
      name: 'Go to Campaigns',
      shortcut: ['g', 'c'],
      keywords: 'campaign',
      section: 'Navigation',
      icon: <BsArrowRight />,
      perform: () => {
        history.push('/campaigns');
      },
    },
    {
      id: 'sequence',
      name: 'Go to Sequences',
      shortcut: ['g', 's'],
      keywords: 'sequence',
      section: 'Navigation',
      icon: <BsArrowRight />,
      perform: () => {
        history.push('/sequences');
      },
    },
  ];
};

export const GlobalSearchProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  // State hooks for search input, term, selected tab, loading states, and request controllers
  const [searchInput, setSearchInput] = useState('');
  const [highlightWords, setHighlightWords] = useState<string[]>([]);
  const [selectedTab, setSelectedTab] = useState<TabOptions>(TabOptions.All);
  const [activeItemIndex, setActiveItemIndex] = useState<number>(0);
  const [searchResults, setSearchResults] = useState<{
    [key in TabOptions]: SearchResults[];
  }>({
    [TabOptions.All]: [],
    [TabOptions.Contacts]: [],
    [TabOptions.Messages]: [],
    [TabOptions.Campaigns]: [],
    [TabOptions.Sequences]: [],
  });

  const [loadings, setLoadings] = useState({
    [TabOptions.All]: false,
    [TabOptions.Contacts]: false,
    [TabOptions.Messages]: false,
    [TabOptions.Campaigns]: false,
    [TabOptions.Sequences]: false,
  });

  const [errors, setErrors] = useState({
    [TabOptions.All]: { hasError: false, error: '' },
    [TabOptions.Contacts]: { hasError: false, error: '' },
    [TabOptions.Messages]: { hasError: false, error: '' },
    [TabOptions.Campaigns]: { hasError: false, error: '' },
    [TabOptions.Sequences]: { hasError: false, error: '' },
  });

  const [offsets, setOffsets] = useState({
    [TabOptions.All]: 0,
    [TabOptions.Contacts]: 0,
    [TabOptions.Messages]: 0,
    [TabOptions.Campaigns]: 0,
    [TabOptions.Sequences]: 0,
  });

  const [paginatings, setPaginatings] = useState({
    [TabOptions.All]: false,
    [TabOptions.Contacts]: false,
    [TabOptions.Messages]: false,
    [TabOptions.Campaigns]: false,
    [TabOptions.Sequences]: false,
  });

  const [controllers, setControllers] = useState<{
    [key: string]: AbortController | null;
  }>({});

  const tabs: TabOptions[] = [
    TabOptions.All,
    TabOptions.Contacts,
    TabOptions.Messages,
    TabOptions.Campaigns,
    TabOptions.Sequences,
  ];
  const navigationActions = useSearchActions();

  // Generate search words whenever the search input changes
  useMemo(() => {
    // Update the search words state with the generated search words
    setHighlightWords(generateSearchWords(searchInput));
  }, [searchInput]);

  // Cancels an ongoing request for a given key
  const cancelPreviousRequest = (key: string) => {
    const controller = controllers[key];

    if (!controller) return;
    controller.abort();
    setControllers((prev) => ({ ...prev, [key]: null }));
  };

  // Resets loading states for all categories
  const resetAllLoadings = () => {
    setLoadings({
      [TabOptions.All]: false,
      [TabOptions.Contacts]: false,
      [TabOptions.Messages]: false,
      [TabOptions.Campaigns]: false,
      [TabOptions.Sequences]: false,
    });
  };

  // Reset error states for all categories
  const resetAllErrors = () => {
    setErrors({
      [TabOptions.All]: { hasError: false, error: '' },
      [TabOptions.Contacts]: { hasError: false, error: '' },
      [TabOptions.Messages]: { hasError: false, error: '' },
      [TabOptions.Campaigns]: { hasError: false, error: '' },
      [TabOptions.Sequences]: { hasError: false, error: '' },
    });
  };

  // Reset offsets for all categories
  const resetAllOffsets = () => {
    setOffsets({
      [TabOptions.All]: 0,
      [TabOptions.Contacts]: 0,
      [TabOptions.Messages]: 0,
      [TabOptions.Campaigns]: 0,
      [TabOptions.Sequences]: 0,
    });
  };

  // Reset paginatings for all categories
  const resetAllpaginatings = () => {
    setPaginatings({
      [TabOptions.All]: false,
      [TabOptions.Contacts]: false,
      [TabOptions.Messages]: false,
      [TabOptions.Campaigns]: false,
      [TabOptions.Sequences]: false,
    });
  };

  // Reset all requests in progress
  const resetAllRequests = () => {
    Object.keys(controllers).forEach((key) => {
      cancelPreviousRequest(key);
    });
  };

  // Reset all search results
  const resetAllSearchResults = () => {
    setSearchResults(() => ({
      [TabOptions.All]: [],
      [TabOptions.Contacts]: [],
      [TabOptions.Messages]: [],
      [TabOptions.Campaigns]: [],
      [TabOptions.Sequences]: [],
    }));
  };

  // Cleanup effect for aborting requests and resetting loadings on tab change
  useEffect(() => {
    return () => {
      resetAllSearchResults();
      setActiveItemIndex(0);
      resetAllRequests();
      resetAllLoadings();
      resetAllErrors();
      resetAllOffsets();
      resetAllpaginatings();
    };
  }, [selectedTab]);

  return (
    <SearchContext.Provider
      value={{
        highlightWords,
        searchResults,
        setSearchResults,
        selectedTab,
        setSelectedTab,
        activeItemIndex,
        setActiveItemIndex,
        tabs,
        searchInput,
        setSearchInput,
        loadings,
        setLoadings,
        errors,
        setErrors,
        offsets,
        setOffsets,
        paginatings,
        setPaginatings,
        cancelPreviousRequest,
        controllers,
        setControllers,
        resetAllLoadings,
        resetAllSearchResults,
      }}
    >
      <KBarProvider
        actions={navigationActions}
        options={{
          enableHistory: true,
          callbacks: {
            onQueryChange: (query) => {
              // Reset search results and loadings when query is empty
              if (query?.length === 0) {
                resetAllLoadings();
                resetAllErrors();
                resetAllRequests();
              }
              resetAllSearchResults();
              resetAllOffsets();
              resetAllpaginatings();
              setSearchInput(query);
              resetAllErrors();
            },
            onClose: () => {
              setSearchInput('');
              resetAllErrors();
              resetAllLoadings();
              setSelectedTab(tabs[0]);
              resetAllSearchResults();
              resetAllRequests();
              resetAllpaginatings();
            },
            onSelectAction: (action) => {
              action?.command?.perform();
              setSearchInput('');
            },
            onOpen: () => {
              resetAllRequests();
              resetAllErrors();
              resetAllLoadings();
              resetAllSearchResults();
            },
          },
        }}
      >
        {children}
        <GlobalSearch />
      </KBarProvider>
    </SearchContext.Provider>
  );
};

export const useSearch = () => useContext(SearchContext);
