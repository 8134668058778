import { useUsers } from '@/pages/settings/organization/users/context/UserContext';
import { Attachment, isValidAttachment } from '@/shared/components/attachments';
import { renderText } from '@/shared/components/markdown/MarkdownRenderer';
import {
  ConversationAttachmentType,
  ConversationMessageType,
} from '@/shared/types/conversations';
import { Box, Flex } from '@/shared/ui';

import {
  MessageSuccess,
  replaceUserIdsWithNames,
  StyledAttachmentContainer,
  StyledMessage,
  StyledMessageFooter,
} from '.';
import { SenderIcon } from './SenderIcon';

type NoteProps = {
  message: ConversationMessageType;
  /* the date the message was sent */
  date: string | null;
  /* the email of the user who sent the message */
  user_email?: string;
};

export const ConversationNote = (props: NoteProps) => {
  const { message, date, user_email } = props;
  const { body, attachments, translated_body, visibility_status } = message;

  // filter attachments with .sml extension
  const filteredAttachments = attachments.filter(
    (a) => a !== null && isValidAttachment(a.url)
  );

  // default to `visible` when the visibility is not set
  const visibility = visibility_status ?? 'visible';

  const usersContext = useUsers();
  const { users } = usersContext.userState;

  return (
    <Box>
      {/* Show `Message Removed` bubble when the visibility is `removed` */}
      {visibility === 'removed' ? (
        <StyledMessage direction="note">
          {renderText('This note has been deleted...')}
        </StyledMessage>
      ) : null}

      {(body !== null || filteredAttachments.length > 0) && (
        <Box>
          {body !== null ? (
            <Flex justify="end" align="end">
              <StyledMessage visibility={`outbound_${visibility}`} direction="note">
                {renderText(replaceUserIdsWithNames(body, users))}
              </StyledMessage>
              <SenderIcon message={message} />
            </Flex>
          ) : null}
          <Box>
            {translated_body && translated_body !== null ? (
              <StyledMessage
                visibility={`outbound_${visibility}`}
                direction="outbound_sms_translation"
              >
                {renderText(`Original - ${translated_body}`)}
              </StyledMessage>
            ) : null}
          </Box>
          <Box>
            {filteredAttachments.length > 0 ? (
              visibility === 'hidden' ? (
                'Attachments have been hidden'
              ) : (
                <Box>
                  {filteredAttachments.map((a: ConversationAttachmentType | null) => {
                    if (a === null) {
                      return null;
                    }
                    return (
                      <StyledAttachmentContainer align="end" justify="end" key={a.url}>
                        <Flex css={{ width: '300px !important' }} justify="end">
                          <Attachment src={a.url} />
                        </Flex>
                      </StyledAttachmentContainer>
                    );
                  })}
                </Box>
              )
            ) : null}
          </Box>
          <StyledMessageFooter align="end" justify="end" css={{ alignItems: 'center' }}>
            <MessageSuccess date={date} user={user_email} />
          </StyledMessageFooter>
        </Box>
      )}
    </Box>
  );
};
