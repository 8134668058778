/* eslint-disable react-hooks/exhaustive-deps */
import { ErrorBoundary } from '@sentry/react';
import moment from 'moment';
import React, { useMemo, useState } from 'react';
import { HiMinus, HiPlus } from 'react-icons/hi';

import { AdvancedFilterBuilder } from '@/shared/components/filterBuilder/AdvancedFilterItem';
import {
  campaign_contact_campaign_id,
  default_campaign_contact_object,
} from '@/shared/components/filterBuilder/objects/campaign_contact';
import { default_communication_preference_object } from '@/shared/components/filterBuilder/objects/communication_preference';
import { default_contact_object } from '@/shared/components/filterBuilder/objects/contact';
import { default_contact_list_object } from '@/shared/components/filterBuilder/objects/contact_list';
import { default_contact_tag_object } from '@/shared/components/filterBuilder/objects/contact_tag';
import { default_conversation_object } from '@/shared/components/filterBuilder/objects/conversation';
import { default_sequence_run_object } from '@/shared/components/filterBuilder/objects/sequence_run';
import { rebuildFilters } from '@/shared/components/filterBuilder/utils/rebuildFilters';
import { AccordionValue, Campaign } from '@/shared/types/campaigns';
import { FilterItem, FilterType } from '@/shared/types/filter';
import {
  Box,
  ControlGroup,
  Fieldset,
  Flex,
  HStack,
  IconButton,
  Input,
  Label,
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
  VStack,
} from '@/shared/ui';
import { Switch, SwitchThumb } from '@/shared/ui/Switch';
import { styled } from '@/stitches.config';

import { Description } from '../../create';
import { CampaignAccordion } from '../../create/CampaignAccordion';
import AudienceQuickFilter from '../../editor/components/AudienceQuickFilter';
import {
  hasCampaignTimeSinceFilter,
  hasOpenConversationFilter,
} from '../../editor/components/utils';
import {
  isValidQuickFilter,
  removeExcludedSystemDefault,
  removeOrCondition,
  translateToAdvancedFilter,
} from '../../editor/utils';
import { CampaignErrorBoundaryFallback } from './CampaignErrorBoundaryFallback';

type ViewCampaignAudienceProps = {
  /* campaign object */
  campaign?: Campaign;
  /* accordion state */
  accordion: AccordionValue;
  /* accordion setter */
  setAccordion: React.Dispatch<React.SetStateAction<AccordionValue>>;
};

export const ViewCampaignV2Audience = (props: ViewCampaignAudienceProps): JSX.Element => {
  const { campaign, accordion, setAccordion } = props;

  // should we exclude contacts with open conversation from the campaign?
  const excludeContactsWithOpenConversation =
    hasOpenConversationFilter(campaign?.excluded_audience_filter?.filter ?? []) == null
      ? false
      : true;
  // should we exclude contacts who got a campaign recently?
  let daysDifference = null;
  const maybeCampaignTimeSinceFilter = hasCampaignTimeSinceFilter(
    campaign?.excluded_audience_filter?.filter ?? []
  );
  if (maybeCampaignTimeSinceFilter) {
    const today = moment();
    daysDifference = today.diff(maybeCampaignTimeSinceFilter.value as string, 'days');
  }

  const customContactObject = structuredClone(default_contact_object);
  customContactObject.custom_properties.unshift({
    default: '',
    id: 'contact-id',
    label: 'ID',
    type: 'text',
    key: 'id',
    required: true,
    inserted_at: 'current-timestamp',
    updated_at: 'current-timestamp',
    custom_object_id: 'unique-id-for-contact',
  });
  const customDefaultObjects = useMemo(() => {
    return [
      customContactObject,
      default_contact_tag_object,
      default_contact_list_object,
      {
        ...default_campaign_contact_object,
        custom_properties: [
          campaign_contact_campaign_id,
          ...default_campaign_contact_object.custom_properties,
        ],
      },
      default_sequence_run_object,
      default_conversation_object,
      default_communication_preference_object,
    ];
  }, []);
  const includedAudienceFilter = campaign?.included_audience_filter?.filter ?? [];
  const excludedAudienceFilter = campaign?.excluded_audience_filter?.filter ?? [];
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [includedFilterType, _setIncludedAudienceFilterType] = useState<FilterType[]>(
    () => {
      const advancedFilter = translateToAdvancedFilter(
        includedAudienceFilter ?? []
      ) as FilterType[];
      return rebuildFilters([], advancedFilter, customDefaultObjects);
    }
  );
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [excludedFilterType, _setExcludedAudienceFilterType] = useState<FilterType[]>(
    () => {
      const excludedFilterWithoutSystem = removeNonUserFilters(excludedAudienceFilter);
      const advancedFilter = translateToAdvancedFilter(
        excludedFilterWithoutSystem ?? []
      ) as FilterType[];
      return rebuildFilters([], advancedFilter, customDefaultObjects);
    }
  );

  const isFilterValidQuickFilter = useMemo(() => {
    const excludedUserFilters = removeNonUserFilters(excludedAudienceFilter);
    return (
      isValidQuickFilter(includedAudienceFilter, 'included') &&
      isValidQuickFilter(excludedUserFilters, 'excluded')
    );
  }, [includedAudienceFilter, excludedAudienceFilter]);

  const [audienceTabValue, setAudienceTabValue] = useState<
    'advanced-filter' | 'quick-filter'
  >(() => {
    return isFilterValidQuickFilter ? 'quick-filter' : 'advanced-filter';
  });
  const CustomStyleTabsTrigger = styled(TabsTrigger, {
    '&:hover': { cursor: isFilterValidQuickFilter ? 'pointer' : 'not-allowed' },
  });

  return (
    <CampaignAccordion
      index={2}
      title="Audience"
      description="Audience who received this campaign"
      currentAccordionValue={accordion}
      itemValue={AccordionValue.SELECT_AUDIENCE}
      isError={false}
      setItemValue={setAccordion}
      buttonCopy="View"
      isValid={true}
      forceMountContent
      contentStyle={{ padding: accordion == AccordionValue.SELECT_AUDIENCE ? '' : '0px' }}
    >
      <ErrorBoundary
        fallback={<CampaignErrorBoundaryFallback />}
        beforeCapture={(scope) => {
          scope.setTag('Campaign', 'CampaignErrorBoundary.ViewCampaignAudience');
        }}
        showDialog={false}
      >
        <VStack
          gap="2"
          data-testid="campaign-audience-content"
          css={{
            display: accordion == AccordionValue.SELECT_AUDIENCE ? 'flex' : 'none',
            alignItems: 'stretch',
          }}
        >
          <Fieldset>
            <Tabs
              value={audienceTabValue}
              onValueChange={(value) => {
                setAudienceTabValue(value as 'advanced-filter' | 'quick-filter');
              }}
              css={{
                display: 'flex',
                gap: '16px',
                borderRadius: '8px',
                border: '1px solid rgba(2, 2, 52, 0.08)',
                background: '#FFF',
              }}
            >
              <Box css={{ p: 0, m: 0 }}>
                <TabsList
                  css={{
                    whiteSpace: 'nowrap',
                    marginBottom: '0px',
                    borderBottom: 'none',
                  }}
                >
                  <CustomStyleTabsTrigger
                    value="quick-filter"
                    disabled={!isFilterValidQuickFilter}
                  >
                    Quick filters
                  </CustomStyleTabsTrigger>
                  <TabsTrigger value="advanced-filter">Advanced filters</TabsTrigger>
                </TabsList>
              </Box>
              <TabsContent
                forceMount
                hidden={audienceTabValue == 'advanced-filter'}
                value="quick-filter"
                css={{ paddingLeft: '16px', paddingRight: '16px' }}
              >
                <Box
                  css={{
                    borderBottom: '2px solid var(--colors-slate2)',
                  }}
                >
                  <Fieldset>
                    <Label>Include Contacts</Label>
                    <AudienceQuickFilter
                      readOnly
                      data-testid="included_audience_filter"
                      channelId={campaign?.channel_id ?? null}
                      selectedItems={includedAudienceFilter}
                    />
                  </Fieldset>
                  <Fieldset>
                    <Label>Exclude Contacts</Label>
                    <AudienceQuickFilter
                      readOnly
                      data-testid="excluded_audience_filter"
                      channelId={campaign?.channel_id ?? null}
                      selectedItems={excludedAudienceFilter}
                      removeSystemDefaultItems={(filterItems) => {
                        return removeNonUserFilters(filterItems);
                      }}
                    />
                  </Fieldset>
                </Box>
              </TabsContent>
              <TabsContent
                forceMount
                hidden={audienceTabValue == 'quick-filter'}
                value="advanced-filter"
                css={{ paddingLeft: '16px', paddingRight: '16px' }}
              >
                <Box
                  css={{
                    borderBottom: '2px solid var(--colors-slate2)',
                  }}
                >
                  <Fieldset>
                    <Label>Include Contacts</Label>
                    <Box
                      css={{
                        border: '1px solid #0134DB72 ',
                        borderRadius: '3px',
                        width: '100%',
                        padding: '8px 4px',
                      }}
                    >
                      <AdvancedFilterBuilder
                        readOnly
                        defaultObjects={customDefaultObjects}
                        customObjects={[]}
                        filters={includedFilterType}
                        // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
                        setFilters={(filters) => {}}
                      />
                    </Box>
                  </Fieldset>
                  <Fieldset>
                    <Label>Exclude Contacts</Label>
                    <Box
                      css={{
                        border: '1px solid #0134DB72 ',
                        borderRadius: '3px',
                        width: '100%',
                        padding: '8px 4px',
                      }}
                    >
                      <AdvancedFilterBuilder
                        readOnly
                        defaultObjects={customDefaultObjects}
                        customObjects={[]}
                        filters={excludedFilterType}
                        // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
                        setFilters={(filters) => {}}
                      />
                    </Box>
                  </Fieldset>
                </Box>
              </TabsContent>
            </Tabs>
          </Fieldset>
          {excludeContactsWithOpenConversation && (
            <Fieldset>
              <Flex align="center" justify="between">
                <Flex direction="column">
                  <Label>
                    Exclude Contacts with Open Conversations in any Channel (regardless of
                    access)
                  </Label>
                  <Description>
                    Exclude contacts that have an open conversation with any phone number
                    in your organization regardless of your access.
                  </Description>
                </Flex>
                <Box css={{ mt: 5 }}>
                  <Switch
                    checked={excludeContactsWithOpenConversation}
                    disabled={true}
                    css={{ cursor: 'not-allowed' }}
                  >
                    <SwitchThumb />
                  </Switch>
                </Box>
              </Flex>
            </Fieldset>
          )}
          {daysDifference !== null && (
            <Fieldset>
              <Flex align="center" justify="between">
                <Flex direction="column">
                  <Label>Exclude Contacts who recently received another Campaign</Label>
                  <Description>
                    Excludes contacts from this campaign that have recently received
                    another campaign.
                  </Description>
                </Flex>
                <Box css={{ mt: 5 }}>
                  <Switch
                    checked={daysDifference !== null}
                    disabled={true}
                    css={{ cursor: 'not-allowed' }}
                  >
                    <SwitchThumb />
                  </Switch>
                </Box>
              </Flex>
              {daysDifference !== null && (
                <Box
                  css={{
                    mt: 10,
                    p: 16,
                    backgroundColor: '#F6F6F6',
                    borderRadius: 4,
                  }}
                >
                  <HStack>
                    <ControlGroup>
                      <IconButton
                        size={2}
                        disabled={true}
                        css={{ backgroundColor: 'white' }}
                      >
                        <HiMinus />
                      </IconButton>
                      <Input
                        type="number"
                        disabled={true}
                        value={daysDifference}
                        css={{ textAlign: 'center', width: 50 }}
                      />
                      <IconButton
                        size={2}
                        disabled={true}
                        css={{ backgroundColor: 'white' }}
                      >
                        <HiPlus />
                      </IconButton>
                    </ControlGroup>
                    <Box css={{ fontSize: 13 }}>days</Box>
                  </HStack>
                </Box>
              )}
            </Fieldset>
          )}
        </VStack>
      </ErrorBoundary>
    </CampaignAccordion>
  );
};

function removeNonUserFilters(filterItems: FilterItem[]): FilterItem[] {
  let currentExcludedAudienceFilter = removeExcludedSystemDefault(filterItems, []);
  // remove the contacts in recent campaigns rule
  currentExcludedAudienceFilter = removeOrCondition(
    currentExcludedAudienceFilter,
    (filterItem: FilterItem) => {
      return (
        filterItem.column == 'last_campaign_date' &&
        filterItem.comparison == '>' &&
        filterItem.resource == 'communication_preference'
      );
    }
  );
  // remove the open conversation rule
  currentExcludedAudienceFilter = removeOrCondition(
    currentExcludedAudienceFilter,
    (filterItem: FilterItem) => {
      return (
        filterItem.column == 'status' &&
        filterItem.comparison == 'in' &&
        filterItem.resource == 'conversation' &&
        Array.isArray(filterItem.value) &&
        filterItem.value[0] == 'open' &&
        filterItem.value[1] == 'automated'
      );
    }
  );
  return currentExcludedAudienceFilter;
}
