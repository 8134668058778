import React from 'react';

import { Flex, VStack } from '@/shared/ui';

import { campaigns_help } from '../../../settings/help';
import { HelpCard } from '../../../settings/help/HelpCard';

export const HelpPanel = () => {
  return (
    <Flex css={{ p: 20, pb: 100 }}>
      <VStack>
        {campaigns_help[0].videos.map(({ title, description, loom_id }) => (
          <HelpCard
            key={title}
            title={title}
            description={description}
            loom_id={loom_id}
          />
        ))}
      </VStack>
    </Flex>
  );
};
