import { CampaignPermissionTypes } from '@/shared/types/campaigns';

// export const DELIVERED = 'delivered';
// export const CLICKS = 'clicks';
// export const NOT_CLICKED = 'not_clicked';
// export const RESPONDED = 'responded';
// export const UNRESPONDED = 'unresponded';
// export const UNSUBSCRIBED = 'unsubscribed';
// export const UNFULFILLED = 'unfulfilled';
// export const NOT_DELIVERED = 'not_delivered';
// export const OVERVIEW = 'overview';
export const DEFAULT_CAMPAIGNS_LIMIT = 50;
export const LIMIT = 50;
export const CAMPAIGN_TEMPLATE_PERMISSIONS = [
  { label: 'User', value: CampaignPermissionTypes.USER },
  // removed at the moment
  // { label: 'Channel', value: 'location' },
  { label: 'Organization', value: CampaignPermissionTypes.ORGANIZATION },
];
