import { Box, Flex, Skeleton, VStack } from '@/shared/ui';

import { StyledContactItem, StyledName } from '../items/contacts';

export const ContactSkeleton = ({ quantity = 10 }: { quantity?: number }) => {
  return (
    <>
      {[...Array(quantity)].map((_, index) => (
        <StyledContactItem key={index}>
          <VStack gap={1} css={{ width: '100%' }}>
            <Flex css={{ width: '100%' }} align="center" justify="between">
              <Skeleton
                css={{
                  width: '35px !important',
                  height: '35px !important',
                  m: 0,
                  borderRadius: '100%',
                }}
              />
              <StyledName>
                <Skeleton key={index} variant="tag" css={{ height: 10, width: 100 }} />
              </StyledName>
              <Skeleton
                key={index}
                variant="tag"
                css={{ height: 10, width: 80, marginLeft: 'auto' }}
              />
            </Flex>
            <Box css={{ fontSize: 13 }}>
              <Skeleton
                key={index}
                variant="tag"
                css={{ height: 10, width: 300, paddingLeft: 2 }}
              />
            </Box>
          </VStack>
        </StyledContactItem>
      ))}
    </>
  );
};
