/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import {
  StepsContainer,
  TabsContainer,
} from '@/pages/sequences/sequence/SequenceContainer';
import { useDisclosure } from '@/shared/hooks';
import { PageLayout } from '@/shared/layouts/PageLayout';
import { Sequence } from '@/shared/types/sequences';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogOverlay,
  AlertDialogPortal,
  AlertDialogTitle,
  AlertDialogTrigger,
  Box,
  Button,
  Flex,
  HStack,
} from '@/shared/ui';

import { useSequences } from '../../context/SequenceContext';
import { SequenceSubNavigationItem } from '../SequenceTemplateContainer';

type SequenceGlobalContainerProps = {
  children: React.ReactNode;
};

export const SequenceGlobalTemplateContainer = ({
  children,
}: SequenceGlobalContainerProps) => {
  const sequenceContext = useSequences();
  const { sequencesState, getSequence, setCurrentSequence } = sequenceContext;
  const { current } = sequencesState;

  // get the sequence id from the url
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    // if the current sequence is not set in state, get it from the api
    getSequence(id);

    // on unmount, set the current sequence to null
    return () => {
      setCurrentSequence(null);
    };
  }, []);

  return (
    <SequencePageLayout current={current} tabTitle={current?.title}>
      <Box css={{ height: '100%' }}>{children}</Box>
    </SequencePageLayout>
  );
};

// This is the wrapper for a sequence page,
// it generated the breadcrumbs from the current sequence
export const SequencePageLayout = ({
  children,
  current,
  tabTitle,
}: {
  children: React.ReactNode;
  current?: Sequence | null;
  tabTitle?: string;
}) => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();

  const sequences = useSequences();
  const { duplicateSequence } = sequences;

  const handleDuplicate = async () => {
    if (current?.id) {
      const sequence = await duplicateSequence(current.id);
      if (sequence) {
        history.push(`/sequences`);
      }
    }
  };

  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <PageLayout
      breadcrumbs={[
        { title: 'Sequences', path: '/sequences' },
        {
          title: 'Browse Templates',
          path: `/sequences/templates/browse/`,
        },
        {
          title: tabTitle || '',
          path: `/sequences/templates/browse/${current?.id}/steps`,
        },
      ]}
      actions={
        <HStack gap={2} css={{ mt: 3 }}>
          <AlertDialog open={isOpen}>
            <AlertDialogTrigger asChild>
              <Button onClick={onOpen}>Use Template</Button>
            </AlertDialogTrigger>
            <AlertDialogPortal>
              <AlertDialogOverlay>
                <AlertDialogContent onEscapeKeyDown={onClose}>
                  <AlertDialogTitle>Duplicate Sequence</AlertDialogTitle>
                  <AlertDialogDescription>
                    Are you sure you want to duplicate this sequence?
                  </AlertDialogDescription>
                  <Flex justify="end">
                    <AlertDialogCancel asChild>
                      <Button variant="gray" css={{ mr: 10 }} onClick={onClose}>
                        No
                      </Button>
                    </AlertDialogCancel>
                    <AlertDialogAction asChild>
                      <Button variant="red" onClick={handleDuplicate}>
                        Yes, duplicate
                      </Button>
                    </AlertDialogAction>
                  </Flex>
                </AlertDialogContent>
              </AlertDialogOverlay>
            </AlertDialogPortal>
          </AlertDialog>
        </HStack>
      }
    >
      <TabsContainer align="center">
        <HStack>
          <SequenceSubNavigationItem
            selected={history.location.pathname.includes('steps')}
            onClick={() => history.push(`/sequences/templates/${id}/steps`)}
          >
            Steps
          </SequenceSubNavigationItem>
        </HStack>
      </TabsContainer>
      <StepsContainer direction="column">{children}</StepsContainer>
    </PageLayout>
  );
};
