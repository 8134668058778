import { Formik, FormikProps } from 'formik';
import React from 'react';
import { CirclePicker } from 'react-color';
import { HiX } from 'react-icons/hi';
import * as Yup from 'yup';

import { FormFieldWrapper, TextInput } from '@/shared/components/forms';
import { useDisclosure } from '@/shared/hooks';
import { Group } from '@/shared/types/contacts/groups';
import {
  Button,
  Dialog,
  DialogClose,
  DialogCloseIcon,
  DialogContent,
  DialogFooter,
  DialogOverlay,
  DialogPortal,
  DialogTitle,
  DialogTrigger,
  VStack,
} from '@/shared/ui';

import { complicateContactFilters } from '../utils';

type CreateGroupProps = {
  filters: Record<string, any>;
  createGroup: (
    groupName: string,
    groupColor: string,
    filters: Record<string, any>,
    filters_version: 'v1' | 'v2'
  ) => Promise<Group>;
};

export function AddGroup(props: CreateGroupProps): JSX.Element {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleClose = (formik: FormikProps<any>) => {
    formik.handleReset();
    onClose();
  };

  return (
    <Dialog open={isOpen}>
      <DialogTrigger asChild>
        <Button variant="gray" onClick={onOpen}>
          Save as Group
        </Button>
      </DialogTrigger>
      <Formik
        initialValues={{
          name: '',
          color: '',
          filters: props.filters,
        }}
        validationSchema={Yup.object({
          name: Yup.string().required('Required'),
          color: Yup.string().required('Required'),
        })}
        onSubmit={async (values, actions) => {
          try {
            props.createGroup(
              values.name,
              values.color,
              complicateContactFilters(props.filters),
              'v1'
            );
            actions.resetForm({
              values: {
                name: '',
                color: '',
                filters: [],
              },
            });
            onClose();
          } catch (e) {
            console.log(e);
          }
        }}
      >
        {(formik) => (
          <DialogPortal>
            <DialogOverlay />
            <DialogContent
              onEscapeKeyDown={() => handleClose(formik)}
              onPointerDownOutside={() => handleClose(formik)}
            >
              <form onSubmit={formik.handleSubmit}>
                <DialogTitle variant="bold">Add Group</DialogTitle>
                <VStack gap={2}>
                  <FormFieldWrapper label="Group Name" name="name">
                    <TextInput placeholder="Example name" />
                  </FormFieldWrapper>
                  <FormFieldWrapper label="Group Color" name="color">
                    <CirclePicker
                      color={formik.values['color']}
                      onChangeComplete={(e: { hex: any }) =>
                        formik.setFieldValue('color', e.hex)
                      }
                    />
                  </FormFieldWrapper>
                </VStack>
                <DialogFooter justify="end">
                  <DialogClose asChild>
                    {/* Add onclick for Close button */}
                    <Button
                      variant="gray"
                      type="button"
                      css={{ marginRight: '5px' }}
                      onClick={onClose}
                    >
                      Close
                    </Button>
                  </DialogClose>
                  <DialogClose asChild>
                    <Button type="submit">Create Group with Filters</Button>
                  </DialogClose>
                </DialogFooter>
                <DialogClose asChild>
                  <DialogCloseIcon onClick={onClose} size="2">
                    <HiX size="15px" />
                  </DialogCloseIcon>
                </DialogClose>
              </form>
            </DialogContent>
          </DialogPortal>
        )}
      </Formik>
    </Dialog>
  );
}
