import { AsYouType } from 'libphonenumber-js';
import React, { ChangeEvent, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useHistory, useParams } from 'react-router-dom';

import { createAgentWebCall } from '@/shared/api/ai/agents';
import { Button, Fieldset, Input, Label, VStack } from '@/shared/ui';

import { AuthContainer, AuthPageTitle, AuthPageWrapper } from '../../auth/Login';

export const WebCallLogin = () => {
  const [phone, setPhone] = useState('');
  const [name, setName] = useState('');
  const { agent_id, channel_id } = useParams<{ agent_id: string; channel_id: string }>();
  const history = useHistory();

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    createAgentWebCall(agent_id, channel_id, { name, phone }).then((res) => {
      history.push(`/call/${res.data.access_token}/start`);
    });
  };

  const handleChangePhone = (e: ChangeEvent<HTMLInputElement>) => {
    if (phone?.endsWith(')') && e.target.value.length === phone?.length - 1) {
      e.target.value = e.target.value.slice(0, -1);
    }
    setPhone(new AsYouType('US').input(e.target.value));
  };

  return (
    <>
      <Helmet>
        <title>Whippy | Web Call</title>
      </Helmet>
      <AuthPageWrapper align="center" justify="center" css={{ background: 'white' }}>
        <AuthContainer>
          <AuthPageTitle css={{ fontSize: 18, pb: 15 }} align="center">
            👋 Lets Start your Call!
          </AuthPageTitle>
          <form onSubmit={handleSubmit}>
            <VStack gap={3} css={{ pb: 15 }}>
              <Fieldset>
                <Label htmlFor="name">Full Name</Label>
                <Input
                  id="name"
                  type="text"
                  placeholder="John Doe"
                  value={name}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setName(e.target.value)
                  }
                  required={true}
                />
              </Fieldset>
              <Fieldset>
                <Label htmlFor="phoneNumber">Phone Number</Label>
                <Input
                  id="phoneNumber"
                  type="tel"
                  placeholder="(323) 456-7890"
                  value={phone}
                  onChange={handleChangePhone}
                  required={true}
                />
              </Fieldset>
              <Button type="submit" css={{ minWidth: '100%' }}>
                Submit and Start Call
              </Button>
            </VStack>
          </form>
        </AuthContainer>
      </AuthPageWrapper>
    </>
  );
};
