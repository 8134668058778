type Params = {
  [key: string]: string | string[] | number;
};

/**
 * This function removes empty arrays from an object and creates a query string that can be used in a request.
 *
 * Note: If you also want to remove empty strings, uncomment the "if" statement below.
 */

export default function customParamsSerializer(params: Params): string {
  const parts = [];
  for (const key in params) {
    const value = params[key];
    // if (value === '' || !params?.key) {
    //   continue;
    // }
    if (Array.isArray(value)) {
      for (let i = 0; i < value.length; i++) {
        parts.push(`${encodeURIComponent(key)}[]=${encodeURIComponent(value[i])}`);
      }
    } else {
      parts.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
    }
  }
  return parts.join('&');
}
