/* eslint-disable react-hooks/exhaustive-deps */
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { DatePicker } from 'react-dayjs-picker';
import { useMedia } from 'react-use';

import { TimePicker } from '@/shared/components/timepicker/TimePicker';
import { TimezonePicker } from '@/shared/components/timezonepicker/TimezonePicker';
import { Box, HStack } from '@/shared/ui';

/**
 *
 * Campaign Schedule Selection
 *
 */

type SelectScheduleProps = {
  /** is the campaign scheduled? */
  isScheduled: boolean;
  /** set is the campaign scheduled? */
  setIsScheduled?: (isScheduled: boolean) => void;
  /** the day the campaign is scheduled */
  date: string;
  /** set the day the campaign is scheduled */
  setDate: (date: string) => void;
  /** the time the campaign is scheduled */
  time: string;
  /** set the time the campaign is scheduled */
  setTime: (time: string) => void;
  /** the timezone the campaign is scheduled */
  timezone: string;
  /** set the timezone the campaign is scheduled */
  setTimezone: (timezone: string) => void;
};

export const SelectSchedule = (props: SelectScheduleProps): JSX.Element => {
  const [open, setOpen] = useState(false);

  const isMobile = useMedia('(max-width: 700px)');

  const dateSelect = (e: any) => {
    const date = dayjs(e.$d).format('MM/DD/YYYY');
    props.setDate(date);
  };

  const DatePickerColors = {
    highlighted: 'hsl(0 0% 90.9%)',
    disabled: 'hsl(0 0% 78.0%',
    default: '',
    active: '',
  };

  const getDatePickerStyles = (isScheduled: boolean) => ({
    padding: '2px 3px',
    border: '1px solid #D7DBDF',
    height: '32px',
    borderRadius: '4px',
    maxWidth: '92px',
    fontSize: '14px',
    cursor: 'pointer',
    textAlign: 'center',
    pointerEvents: isScheduled ? 'all' : 'none',
    backgroundColor: isScheduled ? 'white' : '#F8F9FA',
    color: isScheduled ? '#000000' : '#C4CCD0',
    outlineColor: '#3E54CF',
  });

  return (
    <HStack
      gap={2}
      data-testid="schedule-fields"
      css={{ flexWrap: isMobile ? 'wrap' : 'nowrap' }}
    >
      <Box css={{ fontSize: 13, minWidth: 50 }}>Send on</Box>
      <DatePicker
        isOpen={open}
        setIsOpen={() => setOpen(!open)}
        zIndex={999999999999}
        value={dayjs(props.date).format('ddd MMM D')}
        markToday={true}
        format="MMM-dd-yyyy"
        disableBeforeToday={true}
        inputStyle={
          getDatePickerStyles(props.isScheduled) as React.CSSProperties | undefined
        }
        colors={DatePickerColors}
        onSelect={dateSelect}
        popoverPositions={['bottom']}
        closeOnSelect={true}
      />
      <Box css={{ fontSize: 13 }}>at</Box>
      <TimePicker
        setTime={props.setTime}
        time={props.time}
        currentDate={props.date}
        timezone={props.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone}
        disabled={props.isScheduled ? false : true}
        variant="medium"
      />
      <Box css={{ fontSize: 13 }}>in</Box>
      <Box css={{ minWidth: 210, mt: isMobile ? '5px' : 0 }}>
        <TimezonePicker
          timezone={props.timezone}
          setTimezone={props.setTimezone}
          isSmall={false}
          disabled={props.isScheduled ? false : true}
        />
      </Box>
    </HStack>
  );
};
