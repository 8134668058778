import React from 'react';
import { BsFillChatDotsFill } from 'react-icons/bs';
import { HiOutlinePaperClip } from 'react-icons/hi';
import { useHistory } from 'react-router-dom';

import { DraftMessage as DraftMessageType } from '@/shared/types';
import {
  Avatar,
  Box,
  Flex,
  HStack,
  IconButton,
  Skeleton,
  Text,
  Tooltip,
  TooltipArrow,
  TooltipContent,
  TooltipTrigger,
  VStack,
} from '@/shared/ui';
import { initials } from '@/shared/utils/initials/initials';
import { formatPhoneNumber, isValidUuid } from '@/shared/utils/validations/validations';
import { styled } from '@/stitches.config';

import { showContactIcon } from '../list/ConversationPreview';
import { useDraftMessages } from './context/DraftMessagesContext';
import { DeleteDraftMessage } from './DeleteDraftMessage';

export type DraftMessageProps = {
  draftMessage: DraftMessageType;
};

export const DraftMessage = (props: DraftMessageProps) => {
  const { draftMessage } = props;

  const history = useHistory();

  const draftMessages = useDraftMessages();
  const { deleteOneDraftMessage } = draftMessages;

  const deleteMessage = () => {
    deleteOneDraftMessage(draftMessage.id);
  };

  const onViewConversationClick = () => {
    history.push(`/inbox/all/open/${draftMessage.conversation_id}`);
  };

  // if the draftMessage has an attachment, return true
  const hasAttachment = draftMessage.attachments.length > 0;

  return (
    <DraftMessageContainer direction="column">
      <VStack gap={2}>
        <Flex justify="between" css={{ width: '100%' }}>
          <Flex>
            <Box css={{ mr: 10, mb: 10 }}>
              <Avatar
                src={
                  showContactIcon(draftMessage?.contact?.name || '')
                    ? `${window.location.origin}/outline.svg`
                    : ''
                }
                fallback={initials(draftMessage?.contact?.name || '')}
                size="assign"
              />
            </Box>
            <Text css={{ fontWeight: 500, mb: 5 }}>
              {draftMessage?.contact?.name ||
                formatPhoneNumber(draftMessage?.contact?.phone)}
            </Text>
          </Flex>
          <Flex>
            <HStack>
              <DeleteDraftMessage alertAction={deleteMessage} />
              {isValidUuid(draftMessage?.conversation_id || '') && (
                <Tooltip>
                  <TooltipTrigger asChild>
                    <IconButton
                      size={2}
                      variant="outline"
                      onClick={onViewConversationClick}
                    >
                      <BsFillChatDotsFill />
                    </IconButton>
                  </TooltipTrigger>
                  <TooltipContent>
                    View Conversation
                    <TooltipArrow />
                  </TooltipContent>
                </Tooltip>
              )}
            </HStack>
          </Flex>
        </Flex>
        <Flex>
          <Flex align="center">
            {hasAttachment && (
              <Box css={{ mr: 5 }}>
                <HiOutlinePaperClip
                  size={15}
                  style={{
                    marginRight: '2px',
                    transform: 'rotate(90deg) scaleY(-1)',
                  }}
                />
              </Box>
            )}
            <MessagePreview>{draftMessage?.body}</MessagePreview>
          </Flex>
        </Flex>
      </VStack>
    </DraftMessageContainer>
  );
};

export const LoadingDraftMessage = () => {
  return (
    <DraftMessageContainer direction="column" css={{ mb: 0 }}>
      <Flex css={{ width: '100%' }}>
        <Skeleton variant="heading" css={{ width: '50%' }} />
      </Flex>
      <Flex>
        <Skeleton variant="heading" css={{ width: '100%' }} />
      </Flex>
    </DraftMessageContainer>
  );
};

const DraftMessageContainer = styled(Flex, {
  width: '98%',
  p: 20,
  borderWidth: 2,
  borderColor: '$gray3',
  borderRadius: 8,
  mb: 30,
});

const MessagePreview = styled(Box, {
  fontSize: '14px',
  lineHeight: '20px',
  m: '0',
  p: '0',
  fontWeight: 400,
  display: '-webkit-box',
  '-webkit-line-clamp': '1 !important',
  '-webkit-box-orient': 'vertical',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});
