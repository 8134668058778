/* eslint-disable react-hooks/exhaustive-deps */
import dayjs from 'dayjs';
import { debounce } from 'lodash';
import React, { useCallback, useRef, useState } from 'react';
import Highlighter from 'react-highlight-words';
import { HiChevronRight, HiSearch, HiX } from 'react-icons/hi';
import { useHistory } from 'react-router-dom';

import { useConversation } from '@/pages/inbox/context/ConversationContext';
import { conversationSearch } from '@/shared/api/conversations';
import {
  ConversationItemTypeV2,
  ConversationType,
  WhippyQueryLanguage,
  WhippyQueryLanguageFilterSchema,
} from '@/shared/types/conversations';
import { Box, Flex, IconButton, Input, Skeleton, VStack } from '@/shared/ui';
import { formatPhoneNumber } from '@/shared/utils/validations/validations';
import { styled } from '@/stitches.config';

type SearchPanelProps = {
  conversation: ConversationType | null;
  toggleConversationPanel?: (value: boolean) => Promise<void>;
  filter: string;
  tab: string;
};

export const SearchPanelV2 = (props: SearchPanelProps) => {
  const { conversation, filter, tab, toggleConversationPanel } = props;
  const { sideBarSearchInput, setSideBarSearchInput, sideBarResults, setSideBarResults } =
    useConversation();
  const [isLoading, setIsLoading] = useState(false);
  const currentController = useRef<AbortController | null>(null);
  const history = useHistory();

  // Handles input change and triggers search with debounce
  const handleSearch = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setSideBarSearchInput(e.target.value);
    if (conversation && conversation.id && sideBarSearchInput.length >= 2) {
      setIsLoading(true);
      debounceConversationSearch(e.target.value);
    } else {
      // Clear messages if search term is too short or no conversation selected
      setSideBarResults([]);
    }
  };

  // Searches conversation messages based on input, with cancellation for previous requests
  const searchConversationMessages = async (searchInput: string) => {
    cancelPreviousRequest(); // Cancel any ongoing search to prevent race conditions

    const controller = new AbortController(); // For cancelable fetch requests
    currentController.current = controller;

    // Defines search criteria for API request
    const searchFilter: WhippyQueryLanguageFilterSchema = {
      resource: 'message',
      column: 'body',
      comparison: 'ilike',
      value: `%${searchInput}%`,
      and: [
        {
          resource: 'conversation',
          column: 'id',
          comparison: '==',
          value: conversation?.id || '',
        },
      ],
    };

    const searchPayload: WhippyQueryLanguage = {
      filter: [searchFilter],
      limit: 10,
      offset: 0,
    };

    try {
      const conversationMessages = await conversationSearch(searchPayload, controller);
      // Flatten the conversation items from the response data
      // This extracts the individual messages from the conversation items page
      const flattenedMessages = conversationMessages.data.flatMap((conversation) => {
        return conversation?.conversationItemsPage?.conversationItems || [];
      });

      setSideBarResults(flattenedMessages); // Updates UI with search results
    } catch (error) {
      if (error.name !== 'AbortError') {
        console.error('Search failed:', error);
      }
    } finally {
      setIsLoading(false); // Ensures loading state is reset
    }
  };

  // Aborts the current fetch request if it exists
  const cancelPreviousRequest = () => {
    if (currentController.current) {
      currentController.current.abort();
      currentController.current = null;
    }
  };

  // Reduces frequency of API calls while typing
  const debounceConversationSearch = useCallback(
    debounce((searchInput: string) => {
      searchConversationMessages(searchInput);
    }, 350),
    [conversation, filter, tab]
  );

  // Navigates to message detail view and closes conversation panel
  const handleSetMessage = (messageId: string) => {
    history.push(`/inbox/${filter}/${tab}/${conversation?.id}?message_id=${messageId}`);
    if (toggleConversationPanel) toggleConversationPanel(false);
  };

  // Clears the search input and results
  const handleClearSearch = () => {
    setSideBarSearchInput('');
    setSideBarResults([]);
  };

  return (
    <Flex css={{ width: '100%', height: '100%' }} direction="column">
      <Flex css={{ p: 20 }}>
        <Box css={{ position: 'relative', width: '100%' }}>
          <SearchIconContainer>
            <HiSearch />
          </SearchIconContainer>
          <Input
            placeholder="Search Messages"
            value={sideBarSearchInput}
            css={{ pl: 38 }}
            onChange={handleSearch}
          />
          {sideBarSearchInput.length > 2 && (
            <SearchControlsContainer css={{ right: 5 }}>
              <IconButton onClick={() => handleClearSearch()}>
                <HiX />
              </IconButton>
            </SearchControlsContainer>
          )}
        </Box>
      </Flex>
      {isLoading && <LoadingSkeleton quantity={7} />}
      {!isLoading &&
        sideBarResults.length > 0 &&
        sideBarResults
          .sort(
            (prevMessage: ConversationItemTypeV2, currMessage: ConversationItemTypeV2) =>
              (currMessage?.inserted_at ?? '').localeCompare(
                prevMessage?.inserted_at ?? ''
              )
          )
          .map((message) => (
            <MessageCard
              key={message.id}
              message={message}
              search={sideBarSearchInput}
              onClick={() => handleSetMessage(message.id)}
            />
          ))}
      {!isLoading && sideBarResults.length === 0 && sideBarSearchInput.length < 2 && (
        <StyledEmptyState>
          Search for messages in your conversation with,{' '}
          {conversation?.contact.name ||
            formatPhoneNumber(conversation?.contact?.phone || '')}
        </StyledEmptyState>
      )}
      {!isLoading && sideBarSearchInput.length > 2 && sideBarResults.length === 0 && (
        <StyledEmptyState>
          {`No messages found containing "${sideBarSearchInput}" in your conversation with,
          ${
            conversation?.contact.name ||
            formatPhoneNumber(conversation?.contact?.phone || '')
          }`}
        </StyledEmptyState>
      )}
    </Flex>
  );
};

type MessageCardProps = {
  message: ConversationItemTypeV2;
  search: string;
  onClick: (id: string) => void;
};

const MessageCard = (props: MessageCardProps) => {
  const { message, search, onClick } = props;

  return (
    // need to change onClick to MessagedCard
    <MessagedCardContainer onClick={() => onClick(message.id)}>
      <VStack gap={1} css={{ width: '100%' }}>
        <Flex css={{ width: '100%' }} align="center" justify="between">
          <StyledDate>{dayjs(message?.inserted_at).format('DD/MM/YY')}</StyledDate>
          <HiChevronRight />
        </Flex>
        <Box css={{ fontSize: 13 }}>
          <Highlighter
            searchWords={[search]}
            autoEscape={true}
            textToHighlight={message?.body || ''}
            highlightStyle={{ backgroundColor: '#FFC106' }}
          />
        </Box>
      </VStack>
    </MessagedCardContainer>
  );
};

const LoadingSkeleton = ({ quantity = 10 }: { quantity: number }) => {
  return [...Array(quantity)].map((_, i) => (
    <MessagedCardContainer key={i}>
      <VStack gap={1} css={{ width: '100%' }}>
        <Flex css={{ width: '100%' }} align="center" justify="between">
          <StyledDate>
            <Skeleton key={i} variant="tag" css={{ height: 10, width: 50 }} />
          </StyledDate>
        </Flex>
        <Box css={{ fontSize: 13 }}>
          <Skeleton key={i} variant="tag" css={{ height: 30, width: 300 }} />
        </Box>
      </VStack>
    </MessagedCardContainer>
  ));
};

const MessagedCardContainer = styled(Flex, {
  p: 20,
  width: '100%',
  borderTopWidth: 1,
  borderTopColor: '$slate3',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: '$slate2',
  },
  '&:last-child': {
    borderBottomWidth: 1,
    borderBottomColor: '$slate3',
  },
});

const StyledDate = styled(Flex, {
  fontSize: 12,
});

const StyledEmptyState = styled(Flex, {
  fontSize: 12,
  px: 20,
  pb: 20,
  height: '100%',
  width: '100%',
  justifyContent: 'center',
  textAlign: 'center',
});

const SearchIconContainer = styled(Box, {
  position: 'absolute',
  top: '18px',
  transform: 'translateY(-50%)',
  pointerEvents: 'none',
  left: 10,
});

const SearchControlsContainer = styled(Box, {
  position: 'absolute',
  top: '18px',
  transform: 'translateY(-50%)',
  right: '5px',
});
