import { VStack } from '@/shared/ui';

import { FormFieldWrapper, TextInput } from '../../../forms';
import { ActionsEmailEditor } from '../ActionsEmailEditor';
import { ActionProps } from '.';

export const SendEmailAction = (props: ActionProps) => {
  const { index } = props;

  return (
    <VStack gap={1}>
      <FormFieldWrapper
        label="To"
        name={`actions[${index}].params.to`}
        tooltip="Enter multiple email addresses as comma separated list."
      >
        <TextInput
          type="email"
          multiple={true}
          required={true}
          placeholder="bob@example.com, alice@example.com"
        />
      </FormFieldWrapper>
      <FormFieldWrapper
        label="Cc"
        name={`actions[${index}].params.cc`}
        tooltip="Enter multiple email addresses as comma separated list."
      >
        <TextInput type="email" multiple={true} placeholder="Cc" />
      </FormFieldWrapper>
      <FormFieldWrapper
        label="Bcc"
        name={`actions[${index}].params.bcc`}
        tooltip="Enter multiple email addresses as comma separated list."
      >
        <TextInput type="email" multiple={true} placeholder="Bcc" />
      </FormFieldWrapper>
      <FormFieldWrapper
        label="Reply To"
        name={`actions[${index}].params.reply_to`}
        tooltip="Enter an email address to receive replies."
      >
        <TextInput type="email" required={true} placeholder="Reply To" />
      </FormFieldWrapper>
      <FormFieldWrapper label="Subject" name={`actions[${index}].params.subject`}>
        <TextInput placeholder="Subject" />
      </FormFieldWrapper>
      <FormFieldWrapper label="Body" name="body">
        <ActionsEmailEditor placeholder="Body" index={index} />
      </FormFieldWrapper>
    </VStack>
  );
};
