import { useMemo } from 'react';

import { useSearch } from '../SearchContext';
import { KeepAllResultsFocused } from '../utils';
import { RenderCampaignSearch } from './campaigns';
import { RenderContactSearch } from './contacts';
import { EmptyResults } from './emptyResults';
import { ErrorResults } from './errorResults';
import { RenderMessageSearch } from './messages';
import { RenderSequenceSearch } from './sequences';

export const RenderAllSearch = () => {
  // Get search state from context
  const { loadings, searchInput, searchResults, errors } = useSearch();

  // Check if any search is still loading
  const isAnyLoading = useMemo(
    () => Object.values(loadings).some((loading) => loading),
    [loadings]
  );
  // Check if there are any search results with non-empty arrays
  const hasResults = useMemo(
    () => Object.values(searchResults).some((array) => array.length > 0),
    [searchResults]
  );
  // Check if any errors occurred during search
  const hasErrors = useMemo(
    () => Object.values(errors).some((error) => error?.hasError),
    [errors]
  );

  if (hasErrors) {
    return <ErrorResults errorMessage={'Contact support if the issue persists'} />;
  }

  return (
    <>
      <KeepAllResultsFocused results={searchResults} label="All" />
      <RenderContactSearch limit={5} showResults={false} />
      <RenderMessageSearch limit={5} showResults={false} />
      <RenderCampaignSearch limit={5} showResults={false} />
      <RenderSequenceSearch limit={5} showResults={false} />
      {/* Display empty results message if no loading and no results */}
      {!isAnyLoading && !hasResults && searchInput && <EmptyResults />}
    </>
  );
};
