import { Keyword, KeywordActions, KeywordActionTypes } from '@/shared/types/keywords';
import { removeDuplicates } from '@/shared/utils/removeDuplicates';

import { KeywordState } from './KeywordsContext';

const KeywordsReducer = (state: KeywordState, action: KeywordActions) => {
  switch (action.type) {
    case KeywordActionTypes.GET_KEYWORDS:
      return {
        ...state,
        // action.payload is a state; action.payload.keywords is an array of keyword objects
        // Remove keywords with the same id. Allow repeated keywords with different ids.
        keywords: removeDuplicates([...state.keywords, ...(action.payload || [])]),
        totalCount: removeDuplicates([...state.keywords, ...action.payload]).length,
        loading: false, // set back to false after fetching
      };
    case KeywordActionTypes.CREATE_KEYWORD:
      return {
        ...state,
        keywords: [...state.keywords, action.payload],
        totalCount: state.totalCount + 1,
        loading: false,
      };
    case KeywordActionTypes.UPDATE_KEYWORD:
      return {
        ...state,
        keywords: state.keywords.map((keyword: Keyword) => {
          return keyword.id === action.payload?.id ? action.payload : keyword;
        }),
        current: action.payload,
      };
    case KeywordActionTypes.DELETE_KEYWORD:
      return {
        ...state,
        keywords: state.keywords.filter((keyword: Keyword) => {
          return keyword.id !== action.payload;
        }),
        totalCount: state.totalCount - 1,
        loading: false,
      };
    case KeywordActionTypes.SET_CURRENT:
      return {
        ...state,
        current: action.payload,
      };
    case KeywordActionTypes.SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
};

export default KeywordsReducer;
