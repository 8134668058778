/* eslint-disable @typescript-eslint/no-unused-vars */
import * as TabsPrimitive from '@radix-ui/react-tabs';
import React from 'react';

import { Box } from '@/shared/ui';
import { styled } from '@/stitches.config';

type ReportsTabs = {
  /* the report content */
  children: React.ReactNode | Iterable<React.ReactNode>;
  /* tab values */
  values: string[];
  /* tab titles */
  tabTitles: string[];
};

export const ReportsTabs = (props: ReportsTabs) => {
  const { values, tabTitles } = props;

  return (
    <TabsPrimitive.Root defaultValue="overview">
      <StyledItem>
        <StyledTabsList>
          {values.map((value, index) => (
            <StyledTabsTrigger key={value} value={value}>
              {tabTitles[index]}
            </StyledTabsTrigger>
          ))}
        </StyledTabsList>
        <StyledContent>
          {React.Children.map(props.children, (child, index) => {
            return (
              <TabsPrimitive.Content key={values[index]} value={values[index]}>
                {child}
              </TabsPrimitive.Content>
            );
          })}
        </StyledContent>
      </StyledItem>
    </TabsPrimitive.Root>
  );
};

const StyledTabsList = styled(TabsPrimitive.List, {
  width: '100%',
  alignItems: 'center',
  borderBottom: '1px solid #E8E8E8',
  display: 'flex',
  justifyContent: 'space-between',
});

const StyledTabsTrigger = styled(TabsPrimitive.Trigger, {
  py: 24,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  fontSize: 14,
  fontWeight: 600,
  letterSpacing: '0.5px',
  color: '$slate12',
  cursor: 'pointer',
  '&:focus': { outline: 'none' },
  '&[data-state="active"]': {
    borderBottomWidth: '2px',
    borderBottomColor: '$primaryColor',
    color: '$primaryColor',
  },
  '&:first-child': { pl: 24 },
});

const StyledItem = styled(Box, {
  mb: 15,
  backgroundColor: 'white',
  border: '1px solid $slate5',
  borderRadius: '$3',
});

const StyledContent = styled(Box, {
  backgroundColor: 'white',
  py: 24,
  px: 12,
  borderRadius: '$3',
});
