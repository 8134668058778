import React, { useEffect } from 'react';
import { HiX } from 'react-icons/hi';

import { CreateContact as CreateContactForm } from '@/pages/contacts/editor/v2/CreateContact';
import { NewContactParams } from '@/shared/api/contacts/v2';
import { useDisclosure } from '@/shared/hooks/useDisclosure';
import {
  Box,
  DialogTitle,
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerPortal,
  DrawerTrigger,
  Flex,
} from '@/shared/ui';
import { styled } from '@/stitches.config';

type CreateContactProps = {
  /**
   * Whether the drawer is open or not
   */
  open?: boolean;
  /**
   * Function to open the drawer
   */
  setOpen?: (value: boolean) => void;
  /**
   * The drawer trigger button
   */
  children?: React.ReactNode;
  /**
   * The function to create a contact
   */
  handleCreateContact?: (params: NewContactParams) => void;
  /**
   * The initial full name of the contact
   */
  name?: string;
  /**
   * The initial full phone of the contact
   */
  phone?: string;
  /**
   * The initial full email of the contact
   */
  email?: string;
};

export function CreateContact(props: CreateContactProps): JSX.Element {
  const { children, handleCreateContact, name, phone, email, open, setOpen } = props;

  const { isOpen, onClose, onOpen } = useDisclosure();

  // if props.open is true open the dialog
  useEffect(() => {
    if (open) {
      onOpen();
    }
  }, [open]);

  // This function closes the dialog by calling the onClose function and
  // setting the open variable to false if it exists.
  const closeDialog = () => {
    onClose();
    setOpen?.(false);
  };

  return (
    <Drawer open={isOpen}>
      <DrawerTrigger asChild>
        <Box onClick={onOpen}>{children}</Box>
      </DrawerTrigger>
      <DrawerPortal>
        <DrawerContent
          aria-describedby="Create Contact Drawer"
          css={{
            maxWidth: '420px',
            minWidth: '360px',
            top: 0,
            height: '100%',
            zIndex: 999999,
          }}
        >
          <DrawerHeaderContainer align="center" justify="between">
            <DialogTitle
              variant="bold"
              css={{ textTransform: 'capitalize', fontSize: 18, mb: 0 }}
            >
              Create Contact
            </DialogTitle>
            <DrawerClose onClick={closeDialog}>
              <HiX size="16px" />
            </DrawerClose>
          </DrawerHeaderContainer>
          <DrawerContentContainer
            css={{ height: 'calc(100% - 64px)', overflow: 'hidden' }}
          >
            <CreateContactForm
              handleCreateContact={handleCreateContact}
              onClose={closeDialog}
              name={name}
              phone={phone}
              email={email}
            />
          </DrawerContentContainer>
        </DrawerContent>
      </DrawerPortal>
    </Drawer>
  );
}

export const DrawerHeaderContainer = styled(Flex, {
  minHeight: 64,
  position: 'relative',
  height: 64,
  width: '100%',
  flexShrink: 0,
  borderBottom: 'thin solid $gray4',
  px: 12,
  '@lg': {
    px: 24,
  },
});

export const DrawerContentContainer = styled(Flex, {
  position: 'relative',
  height: '100%',
  flex: 1,
  overflow: 'auto',
});

export const Heading = styled(Box, {
  fontSize: 18,
  lineHeight: '26px',
  fontWeight: 700,
});
