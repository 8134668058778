import React, { memo } from 'react';
import { HiCheckCircle, HiExclamationCircle, HiXCircle } from 'react-icons/hi';

import {
  Domain,
  DomainSetupState,
  DomainSetupType,
  DomainStatusType,
} from '@/shared/types/domains';
import { Flex, Text } from '@/shared/ui';

type VerificationStatusBadgeProps = {
  domain: Domain | null;
  type?: DomainSetupType;
};

const getBadgeIcon = (status?: DomainStatusType) => {
  switch (status) {
    case DomainStatusType.PENDING:
      return <HiExclamationCircle color="#F8BE3E" size={20} />;
    case DomainStatusType.ACTIVE:
      return <HiCheckCircle color="green" size={20} />;
    case DomainStatusType.CANCELLED:
      return <HiXCircle color="red" size={20} />;
    default:
      return null;
  }
};

const getBadgeLabel = (domain: Domain | null, type?: DomainSetupType) => {
  const statusKey: 'status' | 'links_status' | 'emails_status' = type
    ? `${type}_status`
    : 'status';
  const setupStateKey: 'setup_state' | 'links_setup_state' | 'emails_setup_state' = type
    ? `${type}_setup_state`
    : 'setup_state';
  if (domain?.[statusKey] === DomainStatusType.ACTIVE) return 'Ready to Use';
  if (
    domain?.[statusKey] === DomainStatusType.PENDING &&
    domain?.[setupStateKey] === DomainSetupState.CHECKING_VERIFIED &&
    domain?.verification
  ) {
    return 'Pending Verification';
  }
  if (
    domain?.[statusKey] === DomainStatusType.PENDING &&
    domain?.[setupStateKey] === DomainSetupState.CHECKING_MISCONFIGURED &&
    domain?.required_dns_records?.length
  ) {
    return 'Pending Configuration';
  }
  if (
    domain?.[statusKey] === DomainStatusType.PENDING &&
    (domain?.[setupStateKey] === DomainSetupState.PURCHASING_DOMAIN ||
      domain?.[setupStateKey] === DomainSetupState.PURCHASED_DOMAIN ||
      domain?.[setupStateKey] === DomainSetupState.PURCHASING_DOMAIN_PENDING)
  ) {
    return 'Name servers are configuring…';
  }

  if (type && domain?.[statusKey] === DomainStatusType.PENDING) {
    return 'Pending Configuration';
  }
  if (domain?.[statusKey] === DomainStatusType.CANCELLED) return 'Setup Failed';
  return null;
};

export const VerificationStatusBadge = memo(
  ({ domain, type }: VerificationStatusBadgeProps) => {
    return getBadgeLabel(domain) ? (
      <Flex justify="start" align="center">
        {getBadgeIcon(type ? domain?.[`${type}_status`] : domain?.status)}
        <Text css={{ ml: 3, fontSize: 14, fontWeight: '500' }}>
          {getBadgeLabel(domain, type)}
        </Text>
      </Flex>
    ) : null;
  }
);
