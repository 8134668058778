import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import * as API from '@/shared/api/ai';
import { MessageEditorV2 } from '@/shared/components/editor/v2';
import { DotsTyping } from '@/shared/components/Icons';
import { Template, TemplateContentType } from '@/shared/types/templates';
import { Box, Button, Flex, Text, VStack } from '@/shared/ui';

import { useTemplates } from '../settings/organization/templates/context/TemplatesContext';
import { useReviews } from './context/ReviewContext';

type ReplyToReviewProps = {
  reviewId: string;
  googleLocationId: string;
  displayName: string;
  starRating: string;
  comment: string | undefined | null;
};

export const ReviewWriteResponseButton = (props: ReplyToReviewProps): JSX.Element => {
  const [showTextArea, setShowTextArea] = useState(false);
  const isToggledRef = useRef(showTextArea);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const reviewContext = useReviews();
  const { createResponse } = reviewContext;
  const { reviewId, googleLocationId, displayName, starRating, comment } = props;

  const {
    templatesState: { allTemplates },
  } = useTemplates();

  const reviewResponseTemplates = useMemo(() => {
    return allTemplates.filter(
      (item: Template) => item.content_type === TemplateContentType.REVIEW_RESPONSE
    );
  }, [allTemplates]);

  // Reference to the textarea HTML element
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const toggle = useCallback(
    () => setShowTextArea(!isToggledRef.current),
    [isToggledRef, setShowTextArea]
  );

  useEffect(() => {
    isToggledRef.current = showTextArea;
  }, [showTextArea]);

  const toggleTextArea = async () => {
    setLoading(true);
    try {
      const params = {
        model: 'gpt-3.5-turbo',
        options: {
          frequency_penalty: 0.5,
          presence_penalty: 0.5,
          max_tokens: 700,
          temperature: 0.7,
          top_p: 1,
          n: 1,
        },
        messages: [
          {
            role: 'system',
            content:
              "You are an AI Google Review Response Writing assistant. You're here to help write a response to a review. Response to the review below, give a generic response if the reviewer left no comment. Don't start with Dear and dont use paceholders like [name] or [business name].",
          },
          {
            role: 'user',
            content:
              'From: ' +
              displayName +
              ', with star rating ' +
              starRating +
              ' stars' +
              ' and comment: ' +
              comment,
          },
        ],
      };

      const response = await API.createChatCompletion(params);

      setMessage(response.response);
      toggle();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toggle();
    }
  };

  const respondHandler = () => {
    toggle();
    createResponse({
      reviewId: reviewId,
      message,
      google_location_id: googleLocationId,
    });
    setMessage('');
  };

  return (
    <Box>
      {showTextArea ? (
        <VStack gap={2}>
          <Text variant="bold" size={3}>
            Response
          </Text>
          <Flex direction="column">
            <MessageEditorV2
              message={message}
              setMessage={setMessage}
              textareaRef={textareaRef}
              sendMessageAction={respondHandler}
              placeholder={'Write a response...'}
              enableAttachments={false}
              showSendButton
              showAddEmoji
              showAddTemplate={reviewResponseTemplates.length > 0}
              isReviewResponse
            />
          </Flex>
        </VStack>
      ) : (
        <VStack>
          <Button onClick={toggleTextArea}>
            {loading ? <DotsTyping /> : 'Draft Response'}
          </Button>
        </VStack>
      )}
    </Box>
  );
};
