import { HiCloudUpload } from 'react-icons/hi';

import { CreateUpload } from '@/pages/contacts/uploads/CreateUpload';
import { ToolTipIconButton } from '@/shared/components/attachments/previewer';
import { Button, HStack } from '@/shared/ui';

import { CreateContact } from '../contacts/CreateContact';
import { useData } from '../context/DataContext';

export const ContactActions = ({ children }: { children?: JSX.Element }): JSX.Element => {
  const contacts = useData();

  const { createContact } = contacts;
  return (
    <HStack>
      <CreateUpload navigateTo="/data/lists/">
        <ToolTipIconButton
          size={2}
          variant="outline"
          icon={<HiCloudUpload data-testid="upload-contacts-button" />}
          description="Create Contacts List"
        />
      </CreateUpload>
      {children}
      <CreateContact handleCreateContact={createContact}>
        <Button data-testid="add-contact-button" size="2">
          Add Contact
        </Button>
      </CreateContact>
    </HStack>
  );
};
