/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { HiX } from 'react-icons/hi';
import { useHistory } from 'react-router-dom';
import { toast } from 'sonner';

import { useAuth } from '@/auth/context/AuthProvider';
import * as API from '@/shared/api/organization';
import { useDisclosure } from '@/shared/hooks';
import { Organization } from '@/shared/types';
import {
  Button,
  Dialog,
  DialogCloseIcon,
  DialogContent,
  DialogOverlay,
  DialogPortal,
  DialogTitle,
  DialogTrigger,
  DropdownMenuItem,
  Fieldset,
  Flex,
  HStack,
  Input,
  Label,
} from '@/shared/ui';
import { handleScriptLoad, loadScript } from '@/shared/utils/setup/google';
import i18next from '@/shared/utils/translation';

export function CreateOrganization() {
  const auth = useAuth();
  const history = useHistory();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [organizationDetails, setOrganizationDetails] = useState<{
    name?: string;
    website?: string;
  }>();

  const autoCompleteRef = useRef(null);

  useEffect(() => {
    loadScript(
      `https://maps.googleapis.com/maps/api/js?key=AIzaSyDvZkCD8dE8AHfGya8Z_l8coxbhcsKiDvM&libraries=places`,
      () => handleScriptLoad(setOrganizationDetails, autoCompleteRef)
    );
  }, []);

  const getValidationError = () => {
    if (!organizationDetails?.name) {
      return toast.error(i18next.t('enter_valid_organization_name') as string);
    } else {
      return null;
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const error = getValidationError();

    if (error) {
      return;
    }

    const organization_name = organizationDetails?.name;
    const website = organizationDetails?.website || '';

    try {
      const newOrganization: Organization = await API.createOrganization({
        organization_name,
        website,
      });
      await auth.changeOrganization(newOrganization?.id);
      await auth.updateOnboardingStatus();
      history.go(0);
    } catch (e) {
      toast.error(i18next.t('account_creation_failure') as string);
    }
  };

  return (
    <Dialog open={isOpen} modal={false}>
      <DialogTrigger asChild onClick={(e) => e.preventDefault()}>
        <DropdownMenuItem onClick={onOpen}>Create Organization</DropdownMenuItem>
      </DialogTrigger>
      <DialogPortal>
        <DialogOverlay as="div" />
        <DialogContent
          onEscapeKeyDown={onClose}
          onPointerDownOutside={onClose}
          css={{ overflow: 'auto' }}
        >
          <DialogTitle variant="bold">Create New Organization</DialogTitle>
          <form onSubmit={handleSubmit}>
            <Fieldset>
              <Label css={{ mr: 11 }}>Name</Label>
              <Input
                ref={autoCompleteRef}
                id="organizationName"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setOrganizationDetails({ name: e.target.value })
                }
                placeholder="Example: Law offices of Mauro Fiore"
                value={organizationDetails?.name || ''}
              />
            </Fieldset>
            <Flex css={{ justifyContent: 'flex-end' }}>
              <HStack>
                <Button type="button" variant="gray">
                  Cancel
                </Button>
                <Button type="submit">Create</Button>
              </HStack>
            </Flex>
          </form>
          <DialogCloseIcon onClick={onClose} size="2">
            <HiX size="15px" />
          </DialogCloseIcon>
        </DialogContent>
      </DialogPortal>
    </Dialog>
  );
}
