/* eslint-disable react-hooks/exhaustive-deps */
import { findPhoneNumbersInText } from 'libphonenumber-js';
import React, { useEffect, useState } from 'react';

import { PasteContactsTable } from '@/contacts/paste/PasteContactsTable';
import { useUploads } from '@/contacts/uploads/context/UploadContext';
import { useDisclosure } from '@/shared/hooks';

declare global {
  interface WindowEventMap {
    paste: React.ClipboardEvent<HTMLElement>;
  }
}

type PhoneObject = {
  phone: string;
};

type AudiencePasteProps = {
  addPastedDataToAudience: (upload: any) => void;
  setQuickCampaignDialogState: any;
  closePasteDialog: any;
};

export const AudiencePaste = (props: AudiencePasteProps) => {
  const upload = useUploads();
  const { largeUploadContacts } = upload;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [contacts, setContacts] = useState<Array<PhoneObject>>([]);

  const handleOnPaste = (e: React.ClipboardEvent<HTMLElement>) => {
    // edit event
    e.stopPropagation();
    e.preventDefault();

    // get text from paste event
    const items = e.clipboardData?.getData('Text');
    // replace skip lines with commas
    const replace = items?.replace(/\n/g, ',');
    // format phone numbers
    const formatPhoneNumber = (number: string) => {
      return number.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
    };
    // format numbers
    const formattedNumbers = replace.split(',').map(formatPhoneNumber).join(',');

    try {
      // find phone numbers in text
      const numbersObject = findPhoneNumbersInText(formattedNumbers, 'US');
      // filter object for correct values
      const numbers: Array<string> = numbersObject.map((p) => p.number.number);
      const uniqueNumbers = [...new Set(numbers)];
      // transform to array of objects
      const contactObject = uniqueNumbers.map((n) => ({ phone: n }));
      // create array of phone numbers
      setContacts(contactObject);
      // open dialog
      if (contactObject.length > 0) {
        onOpen();
      }
    } catch (error) {
      console.error('Error occurred while parsing phone numbers: ', error);
    }
  };

  useEffect(() => {
    window.addEventListener('paste', handleOnPaste, true);
    return () => {
      window.removeEventListener('paste', handleOnPaste, true);
    };
  }, []);

  const handleOnSetContacts = (contacts: Array<PhoneObject>) => {
    // update state
    setContacts(contacts);

    // bulk create contacts or an upload
    async function bulkCreate() {
      const data = await largeUploadContacts(contacts);
      props.addPastedDataToAudience({ id: data?.id, name: data?.name });
      if (props.setQuickCampaignDialogState) {
        props.setQuickCampaignDialogState(true);
      }
      props.closePasteDialog();
      return data;
    }

    // if there are any contacts selected
    // then create them
    if (contacts.length > 0) {
      bulkCreate();
    }

    // close dialog
    onClose();
  };

  return (
    <div onPaste={handleOnPaste}>
      <PasteContactsTable
        onClose={onClose}
        onOpen={onOpen}
        isOpen={isOpen}
        contacts={contacts}
        setContacts={handleOnSetContacts}
      />
    </div>
  );
};
