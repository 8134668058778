/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Flex } from '@/shared/ui';
import { styled } from '@/stitches.config';

export const CampaignTitle = styled(Box, {
  mr: 5,
  fontWeight: 500,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  maxWidth: 200,
  textOverflow: 'ellipsis',
});

export const CampaignDescription = styled(Box, {
  fontSize: 13,
  fontColor: 'rgba(127, 127, 134, 1)',
  mt: 12,
  maxWidth: 200,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

export const CampaignCardContainer = styled(Flex, {
  width: '100%',
  height: 100,
  borderWidth: 1,
  px: 24,
  borderRadius: '12px',
  borderColor: 'rgba(232, 232, 232, 1)',
  backgroundColor: 'white',
  '&:hover': {
    border: '1px solid #3E54CF',
    filter: 'drop-shadow(0px 4px 32px rgba(62, 84, 207, 0.08))',
    cursor: 'pointer',
  },
});

export const Divider = styled(Box, {
  height: '40px',
  borderWidth: 1,
  borderColor: '#E8E8E8',
  mx: 44,
});
