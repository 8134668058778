import React from 'react';

import { CSS, styled, VariantProps } from '@/stitches.config';

import { Divider, Flex, Text, VStack } from '.';

const DEFAULT_TAG = 'h1';

type TextSizeVariants = Pick<VariantProps<typeof Text>, 'size'>;
type HeadingSizeVariants = '1' | '2' | '3' | '4';
type HeadingVariants = { size?: HeadingSizeVariants } & Omit<
  VariantProps<typeof Text>,
  'size'
>;
type HeadingProps = React.ComponentProps<typeof DEFAULT_TAG> &
  HeadingVariants & { css?: CSS; as?: any };

export const Heading = React.forwardRef<
  React.ElementRef<typeof DEFAULT_TAG>,
  HeadingProps
>((props, forwardedRef) => {
  // '2' here is the default heading size variant
  const { size = '1', ...textProps } = props;
  // This is the mapping of Heading Variants to Text variants
  const textSize: Record<HeadingSizeVariants, TextSizeVariants['size']> = {
    1: { '@initial': '4', '@bp2': '5' },
    2: { '@initial': '6', '@bp2': '7' },
    3: { '@initial': '7', '@bp2': '8' },
    4: { '@initial': '8', '@bp2': '9' },
  };

  return (
    <Text as={DEFAULT_TAG} {...textProps} ref={forwardedRef} size={textSize[size]} />
  );
});

type PageHeadingProps = {
  title: string;
  description?: string | null;
  children?: JSX.Element;
  variant?: string;
};

export const SettingsHeading = ({ title, description }: PageHeadingProps) => {
  return (
    <VStack gap={3} css={{ mb: 30 }}>
      <Flex>
        <PageTitle css={{ mr: 5 }}>{title}</PageTitle>
      </Flex>
      <Text css={{ minHeight: 20 }}>{description}</Text>
      <Divider />
    </VStack>
  );
};

export const PageTitle = styled(Flex, {
  flex: 'initial 0 initial',
  fontSize: 18,
  fontWeight: 800,
});

export const PageDescription = styled(Text, {
  minHeight: 20,
});

export const PageHeading = (props: PageHeadingProps) => {
  return (
    <VStack gap={3} css={{ mb: 30 }}>
      <Flex justify="between">
        <PageTitle css={{ mr: 5 }}>{props.title}</PageTitle>
        {props.children}
      </Flex>
      <PageDescription>{props.description}</PageDescription>
      <Divider />
    </VStack>
  );
};

export const CampaignHeading = (props: PageHeadingProps) => {
  return (
    <Flex css={{ mb: 20 }}>
      <PageTitle css={{ mr: 5 }}>{props.title}</PageTitle>
      {props.children}
    </Flex>
  );
};
