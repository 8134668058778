import React from 'react';

import { VStack } from '@/shared/ui';

import { ReportsContentContainer } from '../layout/ReportsContentContainer';
import { Overview } from './Overview';
import { UserCampaign } from './UserCampaign';

export const CampaignsReport = () => {
  return (
    <ReportsContentContainer title="Campaigns">
      <VStack gap={2}>
        <Overview />
        <UserCampaign />
      </VStack>
    </ReportsContentContainer>
  );
};
