/* eslint-disable react-hooks/exhaustive-deps */
import { Formik, useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import * as Yup from 'yup';

import { ActionDescription } from '@/pages/automation/ActionDescription';
import { KeywordFormValuesType } from '@/pages/automation/keywords/EditKeywordForm';
import { ActionRenderer } from '@/shared/components/triggers';
import {
  ActionWrapper,
  getActionColor,
  getActionIcon,
  getActionTitle,
} from '@/shared/components/triggers/actions/ActionWrapper';
import { AddAction } from '@/shared/components/triggers/actions/AddAction';
import { ReplyTriggerType } from '@/shared/components/triggers/AddTrigger';
import { Action } from '@/shared/types/triggers/actions';
import { Button, Flex, HStack, Label, VStack } from '@/shared/ui';

import { AutomationTriggerContainer } from './KeywordAutomations';

type ReplyAutomationsProps = {
  /* array of reply triggers */
  reply_triggers: Array<ReplyTriggerType>;
  /* function to update a reply trigger */
  updateTrigger: any;
  /* function to delete a reply trigger */
  deleteTrigger: (trigger_id: string) => void;
  /* variant of the automation */
  variant?: 'campaign' | 'template';
};

export const ReplyAutomations = (props: ReplyAutomationsProps) => {
  const { reply_triggers, updateTrigger, deleteTrigger, variant } = props;
  return (
    <VStack gap={4}>
      {reply_triggers &&
        reply_triggers?.map((reply_trigger: ReplyTriggerType, i: number) => (
          <AutomationTriggerContainer key={i} variant={variant || 'campaign'}>
            <ReplyTriggerForm
              reply_trigger={reply_trigger}
              updateTrigger={updateTrigger}
              deleteTrigger={deleteTrigger}
            />
          </AutomationTriggerContainer>
        ))}
    </VStack>
  );
};

type ReplyTriggerFormProps = {
  reply_trigger: ReplyTriggerType;
  updateTrigger: any;
  deleteTrigger: (trigger_id: string) => void;
};

export const ReplyTriggerForm = (props: ReplyTriggerFormProps) => {
  const { reply_trigger, deleteTrigger, updateTrigger } = props;

  const [initialValues, setInitialValues] = useState({
    actions: reply_trigger?.actions || [],
  });

  useEffect(() => {
    if (reply_trigger) {
      setInitialValues({
        actions: reply_trigger?.actions || [],
      });
    }

    return () => {
      setInitialValues({
        actions: [],
      });
    };
  }, [props.reply_trigger]);

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={Yup.object({
        actions: Yup.array().test(
          'len',
          'Required',
          (arr) => Array.isArray(arr) && arr.length > 0
        ),
      })}
      onSubmit={async (values) => {
        try {
          const params = {
            id: reply_trigger.id,
            type: reply_trigger.type,
            actions: values.actions,
          };
          updateTrigger(reply_trigger.id, params);
        } catch (e) {
          console.log(e);
        }
      }}
    >
      {(formik) => (
        <form onSubmit={formik.handleSubmit}>
          <ReplyTriggerFormFields
            setInitialValues={setInitialValues}
            reply_trigger={reply_trigger}
            deleteTrigger={deleteTrigger}
          />
        </form>
      )}
    </Formik>
  );
};

type ReplyTriggerFormFieldsProps = {
  setInitialValues: any;
  reply_trigger: ReplyTriggerType;
  deleteTrigger: (trigger_id: string) => void;
};

export const ReplyTriggerFormFields = (props: ReplyTriggerFormFieldsProps) => {
  const { setInitialValues, reply_trigger, deleteTrigger } = props;
  const formik = useFormikContext();
  const values = formik.values as KeywordFormValuesType;

  const addAction = (action: Action) => {
    // take the action and merge in a tempId key with a uuid v4 value
    const newAction = {
      ...action,
      tempId: uuidv4(),
    };

    if (reply_trigger && values.actions) {
      const newActions = [...values.actions, newAction];
      setInitialValues({
        ...values,
        actions: newActions,
      });
    }
  };

  const removeAction = (action: Action) => {
    if (action.id) {
      const newActions = values.actions?.filter((a) => a.id !== action.id);
      setInitialValues({
        ...values,
        actions: newActions,
      });
    } else {
      const newActions = values.actions?.filter((a) => a.tempId !== action.tempId);
      setInitialValues({
        ...values,
        actions: newActions,
      });
    }
  };

  const handleDeleteTrigger = () => {
    if (reply_trigger.id) {
      deleteTrigger(reply_trigger.id);
    }
  };

  return (
    <VStack gap={2}>
      <Label>When a contact response with any message then:</Label>
      {values.actions.map((action, index) => (
        <ActionWrapper
          key={index}
          title={`${getActionTitle(action)}` || ''}
          description={ActionDescription(action) || ''}
          removeAction={removeAction as any}
          action={action}
          saveAction={formik.handleSubmit}
          actionColor={`${getActionColor(action)}` || '#2C7675'}
          actionIcon={getActionIcon(action) || ''}
        >
          <ActionRenderer
            key={index}
            index={index}
            action={action}
            removeAction={removeAction}
          />
        </ActionWrapper>
      ))}
      <Flex css={{ mt: 20 }}>
        <HStack>
          <Button type="submit">Save Automation</Button>
          <AddAction addAction={addAction} />
          <Button type="button" variant="red" onClick={() => handleDeleteTrigger()}>
            Delete Automation
          </Button>
        </HStack>
      </Flex>
    </VStack>
  );
};
