import { useState } from 'react';
import { ChangeEvent, useEffect, useRef } from 'react';

import { Input } from '@/shared/ui';
import {
  handleAddressScriptLoad,
  loadScript,
  removeScript,
} from '@/shared/utils/setup/google';

export type Address = {
  fullAddress?: string;
  address_line_one?: string;
  address_line_two?: string;
  city?: string;
  state?: string;
  country?: string;
  post_code?: string;
};

type AddressInputParams = {
  /** A channel object in Whippy */
  address: Partial<Address>;
  /** Set the channel object in Whippy */
  setAddress: (address: Partial<Address>) => void;
  /** input placeholder */
  placeholder: string;
};

export const AddressInput = ({
  address,
  setAddress,
  placeholder,
}: AddressInputParams) => {
  const autoCompleteRef = useRef(null);
  const [addressParsed, setAddressParsed] = useState<{
    formatted_address: string;
    address_components: google.maps.GeocoderAddressComponent[];
  }>({
    formatted_address: '',
    address_components: [],
  });

  useEffect(() => {
    // If the google script is not loaded, load it
    loadScript(
      `https://maps.googleapis.com/maps/api/js?key=AIzaSyDvZkCD8dE8AHfGya8Z_l8coxbhcsKiDvM&libraries=places`,
      () => handleAddressScriptLoad(setAddressParsed, autoCompleteRef)
    );

    // Remove the script tag from the document body on unmount
    return () => {
      removeScript(
        `https://maps.googleapis.com/maps/api/js?key=AIzaSyDvZkCD8dE8AHfGya8Z_l8coxbhcsKiDvM&libraries=places`
      );
    };
  }, []);

  useEffect(() => {
    if (addressParsed.address_components && addressParsed.formatted_address) {
      setAddress({});
      handleAutocomplete(addressParsed);
    }
  }, [addressParsed]);

  const handleAutocomplete = (details: {
    formatted_address: string;
    address_components: google.maps.GeocoderAddressComponent[];
  }) => {
    const fullAddress = details.formatted_address;
    let address1 = '';
    let postcode = '';
    let city = '';
    let country = '';
    let state = '';

    for (const component of details.address_components) {
      const componentType = component?.types[0];
      switch (componentType) {
        case 'street_number': {
          address1 = `${component?.long_name} ${address1}`;
          break;
        }
        case 'route': {
          address1 += component?.short_name;
          break;
        }
        case 'postal_code': {
          postcode = `${component?.long_name}${postcode}`;
          break;
        }
        case 'postal_code_suffix': {
          postcode = `${postcode}-${component.long_name}`;
          break;
        }
        case 'locality':
          city = component?.long_name;
          break;

        case 'administrative_area_level_1': {
          state = component?.short_name;
          break;
        }
        case 'country':
          country = component?.short_name;
          break;
      }
    }
    setAddress({
      fullAddress,
      address_line_one: address1,
      city,
      state,
      country,
      post_code: postcode,
    });
  };

  const onChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (!address) return;
    setAddress({ ...address, fullAddress: e.target.value });
  };

  return (
    <Input
      id="contact_address"
      name="address"
      ref={autoCompleteRef}
      placeholder={placeholder}
      value={address?.fullAddress || ''}
      onChange={onChange}
    />
  );
};
