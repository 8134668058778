import { ColumnType, Resource, SortConfig } from '@/shared/types/filter';

export const sortConfig: SortConfig<Resource.AgentVersion, ColumnType.InsertedAt> = {
  label: 'Sort',
  columnOptions: [
    {
      label: 'Updated At',
      column: ColumnType.InsertedAt,
      resource: Resource.AgentVersion,
      order: 'desc',
    },
  ],
};
