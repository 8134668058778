import {
  Sequence,
  SequenceActions,
  SequenceActionTypes,
  SequenceStep,
  SequenceStepContact,
} from '@/shared/types/sequences';
import { removeDuplicates } from '@/shared/utils/removeDuplicates';

import { SequencesState } from './SequenceContext';

export const SequenceReducer: React.Reducer<SequencesState, SequenceActions> = (
  state: SequencesState,
  action: SequenceActions
) => {
  switch (action.type) {
    case SequenceActionTypes.GET_SEQUENCES:
      return {
        ...state,
        sequences:
          action.payload.offset === 0
            ? removeDuplicates(action.payload.sequences)
            : removeDuplicates([...state.sequences, ...action.payload.sequences]),
        loading: false,
        totalCount: action.payload.total,
      } as SequencesState;
    case SequenceActionTypes.GET_ALL_SEQUENCES:
      return {
        ...state,
        allSequences: action.payload,
      } as SequencesState;
    case SequenceActionTypes.GET_SEQUENCE:
      return {
        ...state,
        current: action.payload,
        loading: false,
      } as SequencesState;
    case SequenceActionTypes.UPDATE_SEQUENCE:
      return {
        ...state,
        allSequences: state.allSequences.map((sequence: Sequence | null) => {
          return sequence
            ? sequence.id === action.payload.id
              ? action.payload
              : sequence
            : null;
        }),
        sequences: state.sequences.map((sequence: Sequence | null) => {
          return sequence
            ? sequence.id === action.payload.id
              ? action.payload
              : sequence
            : null;
        }),
        current: action.payload,
        loading: false,
      } as SequencesState;
    case SequenceActionTypes.DELETE_SEQUENCE:
      return {
        ...state,
        allSequences: (state.allSequences as Sequence[]).filter(
          (sequence: Sequence | null) => sequence && sequence.id !== action.payload
        ),
        sequences: (state.sequences as Sequence[]).filter(
          (sequence: Sequence | null) => sequence && sequence.id !== action.payload
        ),
        totalCount: state.totalCount - 1,
        current: null,
        loading: false,
      } as SequencesState;
    case SequenceActionTypes.GET_SEQUENCE_STEPS:
      return {
        ...state,
        currentSequenceSteps: action.payload,
        loading: false,
      } as SequencesState;
    case SequenceActionTypes.GET_SEQUENCE_STEP:
      return {
        ...state,
        loading: false,
      } as SequencesState;
    case SequenceActionTypes.CREATE_SEQUENCE_STEP:
      return {
        ...state,
        loading: false,
      } as SequencesState;
    case SequenceActionTypes.UPDATE_SEQUENCE_STEP:
      return {
        ...state,
        currentSequenceSteps: state.currentSequenceSteps.map(
          (step: SequenceStep | null) => {
            if (step === null) {
              return step;
            }

            return step.id === action.payload.id ? action.payload : step;
          }
        ),
        loading: false,
      } as SequencesState;
    case SequenceActionTypes.ADD_SEQUENCE:
      return {
        ...state,
        allSequences: [action.payload, ...state.allSequences],
        sequences: [action.payload, ...state.sequences],
        currentSequenceSteps: [],
        current: action.payload,
        loading: false,
      } as SequencesState;
    case SequenceActionTypes.ADD_SEQUENCE_STEP:
      return {
        ...state,
        // add this step to the current sequence steps
        current: {
          ...(state.current as Sequence),
          steps: [...(state.current?.steps as SequenceStep[]), action.payload],
        },
        currentSequenceSteps: [...state.currentSequenceSteps, action.payload],
        loading: false,
      } as SequencesState;
    case SequenceActionTypes.DELETE_STEP:
      return {
        ...state,
        // if the current sequence is the one we are deleting from then remove it from the current sequence
        current: {
          ...(state.current as Sequence),
          steps: (state.current?.steps as SequenceStep[]).filter(
            (step: SequenceStep) => step.id !== action.payload
          ),
        },
        // remove the step from the current sequence steps
        currentSequenceSteps: state.currentSequenceSteps
          ? (state.currentSequenceSteps as SequenceStep[]).filter(
              (step: SequenceStep) => step.id !== action.payload
            )
          : [],
        loading: false,
      } as SequencesState;
    case SequenceActionTypes.ADD_CONTACT_TO_SEQUENCE:
      return {
        ...state,
        loading: false,
      } as SequencesState;
    case SequenceActionTypes.SET_CURRENT:
      return {
        ...state,
        current: action.payload,
      } as SequencesState;
    case SequenceActionTypes.SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      } as SequencesState;
    case SequenceActionTypes.SET_SEQUENCE_CONTACTS_TAB:
      return {
        ...state,
        sequenceContactsTab: action.payload,
      } as SequencesState;
    case SequenceActionTypes.GET_SEQUENCE_CONTACTS:
      return {
        ...state,
        sequenceContacts: mergeSequenceData(state.sequenceContacts, action.payload.data),
        totalSequenceContacts: action.payload.total,
      } as SequencesState;
    case SequenceActionTypes.SET_SEQUENCE_CONTACTS_PARAMS:
      return {
        ...state,
        sequenceContactsParams: action.payload,
      } as SequencesState;
    case SequenceActionTypes.GET_SEQUENCE_CONTACTS_NEW_SEARCH:
      return {
        ...state,
        sequenceContacts: action.payload.data,
        totalSequenceContacts: action.payload.total,
      } as SequencesState;
    case SequenceActionTypes.UPDATE_SEQUENCE_STEP_CONTACT:
      return {
        ...state,
        sequenceContacts: state.sequenceContacts.map(
          (contact: SequenceStepContact | null) => {
            return contact === null
              ? null
              : contact.id === action.payload.id
                ? action.payload
                : contact;
          }
        ),
      } as SequencesState;
    case SequenceActionTypes.RESET_SEQUENCE_CONTACTS:
      return {
        ...state,
        sequenceContacts: [],
      } as SequencesState;
    case SequenceActionTypes.SET_LOADING_CONTACTS:
      return {
        ...state,
        loadingContacts: action.payload,
      } as SequencesState;
    case SequenceActionTypes.REMOVE_CONTACTS_FROM_SEQUENCE:
      return {
        ...state,
        sequenceContacts: removeContactsFromSequence(
          state.sequenceContacts,
          action.payload
        ),
      } as SequencesState;
    case SequenceActionTypes.GET_SEQUENCE_TEMPLATES:
      return {
        ...state,
        sequenceTemplates:
          action.payload.offset === 0
            ? action.payload.data
            : [...state.sequenceTemplates, ...action.payload.data],
        loadingTemplates: false,
        totalSequencesTemplates: action.payload.total,
      };
    case SequenceActionTypes.GET_ALL_SEQUENCE_TEMPLATES:
      return {
        ...state,
        allSequenceTemplates: action.payload,
      };
    case SequenceActionTypes.CREATE_SEQUENCE_TEMPLATE:
      return {
        ...state,
        allSequenceTemplates: [...state.allSequenceTemplates, action.payload],
        sequenceTemplates: [...state.sequenceTemplates, action.payload],
      };
    case SequenceActionTypes.UPDATE_SEQUENCE_TEMPLATE:
      return {
        ...state,
        allSequenceTemplates: state.allSequenceTemplates.map(
          (sequence: Sequence | null) => {
            return sequence
              ? sequence.id === action.payload.id
                ? action.payload
                : sequence
              : null;
          }
        ),
        sequenceTemplates: state.sequenceTemplates.map((sequence: Sequence | null) => {
          return sequence
            ? sequence.id === action.payload.id
              ? action.payload
              : sequence
            : null;
        }),
      };
    case SequenceActionTypes.DELETE_SEQUENCE_TEMPLATE:
      return {
        ...state,
        allSequenceTemplates: (state.allSequenceTemplates as Sequence[]).filter(
          (sequenceTemplate: Sequence | null) =>
            sequenceTemplate && sequenceTemplate.id !== action.payload
        ),
        sequenceTemplates: (state.sequenceTemplates as Sequence[]).filter(
          (sequenceTemplate: Sequence | null) =>
            sequenceTemplate && sequenceTemplate.id !== action.payload
        ),
      };
    case SequenceActionTypes.SET_CURRENT_TEMPLATE:
      return {
        ...state,
        currentTemplate: action.payload,
      };
    case SequenceActionTypes.SET_LOADING_TEMPLATES:
      return {
        ...state,
        loadingTemplates: action.payload,
      } as SequencesState;
    case SequenceActionTypes.GET_SEQUENCE_RESPONSES:
      return {
        ...state,
        sequenceResponses: mergeSequenceData(
          state.sequenceResponses,
          action.payload.data
        ),
        totalSequenceResponses: action.payload.total,
      } as SequencesState;
    case SequenceActionTypes.GET_SEQUENCE_RESPONSES_NEW_SEARCH:
      return {
        ...state,
        sequenceResponses: action.payload.data,
        totalSequenceResponses: action.payload.total,
      } as SequencesState;
    case SequenceActionTypes.SET_SEQUENCE_RESPONSES_LOADING:
      return {
        ...state,
        loadingResponses: action.payload,
      };
    case SequenceActionTypes.SET_SEQUENCE_RESPONSES_PARAMS:
      return {
        ...state,
        sequenceResponsesParams: action.payload,
      } as SequencesState;
    default:
      return state as SequencesState;
  }
};

type StepContacts = SequenceStepContact[] | null[];
type StepResponses = any;

const mergeSequenceData = (
  currentStepContacts: StepContacts | StepResponses,
  newStepContacts: StepContacts | StepResponses
) => {
  const mergedContacts = [...currentStepContacts];
  newStepContacts.forEach((contact: any) => {
    if (contact === null) {
      return;
    }

    const index = mergedContacts.findIndex(
      (mergedContact) => mergedContact && mergedContact.id === contact.id
    );

    if (index > -1) {
      mergedContacts[index] = contact;
    } else {
      mergedContacts.push(contact);
    }
  });

  return mergedContacts;
};

// take the sequence contacts and an array of contact ids
// filter out the contacts that match the ids
const removeContactsFromSequence = (
  sequenceContacts: StepContacts,
  contactIds: string[]
) => {
  return (sequenceContacts as SequenceStepContact[]).filter(
    (contact: SequenceStepContact) => {
      return contact.contact === null ? true : !contactIds.includes(contact.contact.id);
    }
  );
};
