/* eslint-disable react/no-unescaped-entities */
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { HiChevronDown, HiChevronRight } from 'react-icons/hi';

import { getOrganizationBilling } from '@/shared/api/billing';
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
  Divider,
  Flex,
  IconButton,
  Link,
  Text,
  VStack,
} from '@/shared/ui';

import { BillingCard, ErrorCard, LoadingCard } from '.';
import { demo_current_plan } from './utils';

export type CurrentPlan = {
  /* billing details record id */
  id?: string;
  /* billing plan name */
  plan?: string | null;
  /* billing plan price */
  subscription_amount?: number | null;
  /* credits included in plan */
  credits_included?: number | null;
  /* cost of credits over the number included in a plan */
  credits_per_overage_charge?: number | null;
  /* minimum overage charge */
  minimum_overage_charge?: number | null;
  /* free inbound sms messages included in plan */
  free_inbound_sms?: boolean | null;
  /* are credits allowed to rollover */
  credit_rollover_allowed?: boolean | null;
  /* number of months credits can roll over for */
  credit_rollover_months?: number | null;
  /* status of account billing */
  billing_status?: string | null;
  /* does the organization pay monthly, quarterly, yearly? */
  billing_frequency?: string | null;
  /* billing contacts */
  billing_contacts?: Array<BillingContact> | null;
  /* billing contracts */
  billing_contracts?: Array<BillingContract> | null;
};

type BillingContact = {
  /* billing contact id */
  id?: string;
  /* billing contact name */
  billing_contact_name?: string | null;
  /* billing contact email */
  billing_contact_email?: string | null;
  /* billing contact phone */
  billing_contact_phone?: string | null;
};

type BillingContract = {
  /* billing contract id */
  id?: string;
  /* billing contract url */
  billing_contract_url?: string | null;
  /* billing contract start date */
  billing_contract_start_date?: string | null;
  /* billing contract end date */
  billing_contract_end_date?: string | null;
};

export const CurrentPlan = () => {
  const [currentPlan, setCurrentPlan] = useState<CurrentPlan>(demo_current_plan);
  const {
    plan,
    billing_contacts,
    billing_contracts,
    subscription_amount,
    credits_included,
    credits_per_overage_charge,
    minimum_overage_charge,
    billing_frequency,
  } = currentPlan || {};

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [open, setOpen] = useState(false);

  // on mount, fetch billing details
  useEffect(() => {
    getCurrentPlan();
  }, []);

  const getCurrentPlan = async () => {
    try {
      setLoading(true);
      const data = await getOrganizationBilling();

      // const data = demo_current_plan;

      setCurrentPlan(data);
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  // find the current billing contract by start and end date
  const currentBillingContract = billing_contracts?.find(
    (contract) =>
      moment(contract?.billing_contract_start_date).isBefore(moment()) &&
      moment(contract?.billing_contract_end_date).isAfter(moment())
  );

  if (loading) {
    return <LoadingCard />;
  }

  if (error) {
    return (
      <ErrorCard
        title="Error Loading Billing Details"
        description="We were unable to load your billing details. Please refresh the page to try
      again. If the problem persists, please contact support."
      />
    );
  }

  if (!plan) {
    return (
      <ErrorCard
        title="No Billing Details"
        description="We were unable to find your billing details. Please refresh the page to try again. If the problem persists, please contact support."
      />
    );
  }

  return (
    <BillingCard justify="between">
      <VStack gap="2">
        <Text size="3" variant="bold">
          {toTitleCase(plan || '')} Plan
        </Text>
        <Text css={{ mb: 10 }}>
          You're currently on the {toTitleCase(plan || '')} plan. This plan includes a
          suite of features and you can {''}
          <Link href="https://pricing.whippy.ai">
            learn more about what features are included in each plan here
          </Link>
          .
        </Text>
        {billing_contacts && billing_contacts?.length > 0 && (
          <Collapsible open={open} onOpenChange={setOpen}>
            <CollapsibleTrigger asChild>
              <Flex align="center" justify="between">
                <Text size="1" variant="bold">
                  Billing Contacts
                </Text>
                <IconButton>
                  {open ? <HiChevronDown size={18} /> : <HiChevronRight size={18} />}
                </IconButton>
              </Flex>
            </CollapsibleTrigger>
            <CollapsibleContent>
              <VStack gap={2}>
                {billing_contacts?.map((contact) => (
                  <Flex key={contact.id} css={{ width: '100%', mt: 5 }} justify="between">
                    <Text size="1">{contact.billing_contact_name}</Text>
                    <Text size="1">{contact.billing_contact_email}</Text>
                  </Flex>
                ))}
              </VStack>
            </CollapsibleContent>
          </Collapsible>
        )}
        {billing_contracts && billing_contracts?.length > 0 && <Divider />}
        <Flex css={{ width: '100%' }} justify="between">
          <Text size="1" variant="bold">
            Whippy Credits / Month
          </Text>
          <Text>{numberWithCommas(credits_included || 0)}</Text>
        </Flex>
        <Divider />
        <Flex css={{ width: '100%' }} justify="between">
          <Text size="1" variant="bold">
            Subscription Charge
          </Text>
          <Text>{`$${centsToDollars(subscription_amount || 0)}`}</Text>
        </Flex>
        <Divider />
        <Flex css={{ width: '100%' }} justify="between">
          <Text size="1" variant="bold">
            Billing Frequency
          </Text>
          <Text>{toTitleCase(billing_frequency || '')}</Text>
        </Flex>
        <Divider />
        <Flex css={{ width: '100%' }} justify="between">
          <Text size="1" variant="bold">
            Additional Credits per $
            {numberWithCommas(centsToDollars(minimum_overage_charge || 0))} Charged
          </Text>
          <Text>{numberWithCommas(credits_per_overage_charge || 0)}</Text>
        </Flex>
        {/* don't show these if there is no current billing contract */}
        {currentBillingContract && <Divider />}
        {currentBillingContract && (
          <Flex css={{ width: '100%' }} justify="between">
            <Text size="1" variant="bold">
              Start Date
            </Text>
            <Text>
              {moment(currentBillingContract?.billing_contract_start_date).format(
                'MMMM Do, YYYY'
              )}
            </Text>
          </Flex>
        )}
        {currentBillingContract && <Divider />}
        {currentBillingContract && (
          <Flex css={{ width: '100%' }} justify="between">
            <Text size="1" variant="bold">
              Term Length
            </Text>
            <Text>
              {moment(currentBillingContract?.billing_contract_end_date).diff(
                moment(currentBillingContract?.billing_contract_start_date),
                'months'
              )}{' '}
              Months
            </Text>
          </Flex>
        )}
      </VStack>
    </BillingCard>
  );
};

// convert string too title case
const toTitleCase = (str: string) => {
  return str.replace(
    /\w\S*/g,
    (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  );
};

// convert int to have commas
const numberWithCommas = (x: number) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

// convert cents to dollars
const centsToDollars = (x: number) => {
  return x / 100;
};
