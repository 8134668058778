import { ErrorBoundary } from '@sentry/react';
import i18next from 'i18next';
import React from 'react';
import { HiStatusOffline } from 'react-icons/hi';
import { toast } from 'sonner';

import { TooltipButton } from '@/pages/campaigns/v2/analytics/CampaignActions';
import { Button } from '@/shared/ui';

// Fallback Component to be rendered in case of an error
const UnsubscribePreferenceFallback = () => (
  <TooltipButton text="Un-subscribe Contact">
    <Button variant="red" css={{ width: '100%' }}>
      <HiStatusOffline />
      Un-Subscribe Unavailable
    </Button>
  </TooltipButton>
);

type ErrorBoundaryProps = {
  feature: string;
  children: React.ReactNode;
};

export const UnsubscribePreferenceErrorBoundary: React.FC<ErrorBoundaryProps> = ({
  feature,
  children,
}) => {
  // need to have a feature signal
  if (!feature || feature.length < 1) return null;
  // need to have children to render
  if (!children) return null;

  const localizationError = `${feature}_error`;
  const errorTag = `unsubscribePreference.${feature}`;

  return (
    <ErrorBoundary
      fallback={<UnsubscribePreferenceFallback />}
      beforeCapture={(scope) => {
        toast.error(
          (i18next.t(localizationError) as string) ||
            'An error occurred in un-subscribe contact.'
        );
        scope.setTag('ErrorLocation', errorTag);
      }}
      showDialog={false}
    >
      {children}
    </ErrorBoundary>
  );
};
