/* eslint-disable @typescript-eslint/no-unused-vars */
import moment from 'moment';
import { Dispatch, SetStateAction, useMemo } from 'react';
import { HiMinus, HiPlus } from 'react-icons/hi';

import { useCampaignsContext } from '@/pages/campaigns/context/CampaignsContext';
import { Description } from '@/pages/campaigns/create';
import { CampaignAccordion } from '@/pages/campaigns/create/CampaignAccordion';
import { AdvancedFilterBuilder } from '@/shared/components/filterBuilder/AdvancedFilterItem';
import { default_contact_object } from '@/shared/components/filterBuilder/objects/contact';
import { cleanFilters } from '@/shared/components/filterBuilder/utils/cleanFilters';
import { rebuildFilters } from '@/shared/components/filterBuilder/utils/rebuildFilters';
import { AccordionValue, Campaign } from '@/shared/types/campaigns';
import { DefaultObject } from '@/shared/types/data';
import { FilterItem, FilterType } from '@/shared/types/filter';
import {
  Box,
  Button,
  ControlGroup,
  Fieldset,
  Flex,
  HStack,
  IconButton,
  Input,
  Label,
  Switch,
  SwitchThumb,
  VStack,
} from '@/shared/ui';

import AudienceQuickFilter from '../editor/components/AudienceQuickFilter';
import {
  appendFilterItemsToExcludedSystemDefault,
  isValidQuickFilter,
  removeExcludedSystemDefault,
  removeOrCondition,
  translateToAdvancedFilter,
} from '../editor/utils';

const customContactObject = structuredClone(default_contact_object);
customContactObject.custom_properties.unshift({
  default: '',
  id: 'contact-id',
  label: 'ID',
  type: 'text',
  key: 'id',
  required: true,
  inserted_at: 'current-timestamp',
  updated_at: 'current-timestamp',
  custom_object_id: 'unique-id-for-contact',
});

export const CampaignAudience = ({
  handleSave,
  setMessageCount,
  accordion,
  setAccordion,
  excludedAudienceFilter,
  setExcludedAudienceFilter,
  includedAudienceFilter,
  setIncludedAudienceFilter,
  excludedFilterType,
  setExcludedAudienceFilterType,
  includedFilterType,
  setIncludedAudienceFilterType,
  setExcludeCampaignTimeSinceFilter,
  includeAudienceTabValue,
  setIncludeAudienceTabValue,
  excludeContactsWithConversations,
  setExcludeContactsWithConversations,
  setExcludeOpenConversationFilter,
  showExcludeContactsWithOtherCampaigns,
  setShowExcludeContactsWithOtherCampaigns,
  excludeCampaignSince,
  setExcludeCampaignSince,
  isValidAudience,
  customDefaultObjects,
}: {
  handleSave: (accordion: AccordionValue) => void;
  setMessageCount: (value: number) => void;
  accordion: AccordionValue;
  setAccordion: Dispatch<SetStateAction<AccordionValue>>;
  excludedAudienceFilter: FilterItem[];
  setExcludedAudienceFilter: Dispatch<SetStateAction<FilterItem[]>>;
  includedAudienceFilter: FilterItem[] | null;
  setIncludedAudienceFilter: Dispatch<SetStateAction<FilterItem[] | null>>;
  excludedFilterType: FilterType[];
  setExcludedAudienceFilterType: Dispatch<SetStateAction<FilterType[]>>;
  includedFilterType: FilterType[];
  setIncludedAudienceFilterType: Dispatch<SetStateAction<FilterType[]>>;
  setExcludeCampaignTimeSinceFilter: Dispatch<SetStateAction<FilterItem | null>>;
  includeAudienceTabValue: 'advanced-filter' | 'quick-filter';
  setIncludeAudienceTabValue: Dispatch<
    SetStateAction<'advanced-filter' | 'quick-filter'>
  >;
  excludeContactsWithConversations: boolean;
  setExcludeContactsWithConversations: Dispatch<SetStateAction<boolean>>;
  setExcludeOpenConversationFilter: Dispatch<SetStateAction<FilterItem | null>>;
  showExcludeContactsWithOtherCampaigns: boolean;
  setShowExcludeContactsWithOtherCampaigns: Dispatch<SetStateAction<boolean>>;
  excludeCampaignSince: number | null;
  setExcludeCampaignSince: Dispatch<SetStateAction<number | null>>;
  isValidAudience: boolean;
  customDefaultObjects: Array<DefaultObject> | [];
}): JSX.Element => {
  // props needed for advanced
  // campaign state
  const {
    campaignsState: { current },
  } = useCampaignsContext();

  const maybeUpdateLastCampaignSinceFilter = (
    showExcludeCampaignSince: boolean,
    excludeCampaignSince: number | null
  ) => {
    if (showExcludeCampaignSince) {
      const daysAgo = moment().subtract(excludeCampaignSince, 'day').format('YYYY-MM-DD');
      setExcludeCampaignTimeSinceFilter({
        column: 'last_campaign_date',
        comparison: '>',
        resource: 'communication_preference',
        value: daysAgo,
      });
    } else {
      setExcludeCampaignTimeSinceFilter(null);
    }
  };

  const updateExcludedAudienceFilters = (items: FilterItem[]) => {
    if (current?.channel_id) {
      const fullFilter = appendFilterItemsToExcludedSystemDefault(
        items,
        current.channel_id
      );
      setExcludedAudienceFilter(fullFilter);
      // also update the Filter Types for the advanced filter
      const advancedExcludeFilter = translateToAdvancedFilter(
        items ?? []
      ) as FilterType[];
      setExcludedAudienceFilterType(
        rebuildFilters([], advancedExcludeFilter, customDefaultObjects)
      );
    }
  };

  const isIncludedFilterValidQuickFilter = useMemo(() => {
    return isValidQuickFilter(includedAudienceFilter, 'included');
  }, [includedAudienceFilter]);

  const isExcludedFilterValidQuickFilter = useMemo(() => {
    const excludedFilterWithoutSystem = removeExcludedSystemDefault(
      excludedAudienceFilter,
      []
    );
    return isValidQuickFilter(excludedFilterWithoutSystem, 'excluded');
  }, [excludedAudienceFilter]);

  return (
    <CampaignAccordion
      index={2}
      title="Audience"
      description="Contacts who would receive this campaign"
      currentAccordionValue={accordion}
      itemValue={AccordionValue.SELECT_AUDIENCE}
      isValid={isValidAudience}
      isError={false}
      setItemValue={setAccordion}
    >
      <VStack gap="2" css={{ gap: '24px' }}>
        <Flex gap="1">
          <Button
            data-testid="quick-filters-btn"
            variant="grayBackground"
            disabled={
              !isIncludedFilterValidQuickFilter || !isExcludedFilterValidQuickFilter
            }
            css={
              includeAudienceTabValue === 'advanced-filter'
                ? { background: 'transparent' }
                : {}
            }
            onClick={() => setIncludeAudienceTabValue('quick-filter')}
          >
            Quick filters
          </Button>
          <Button
            data-testid="advanced-filters-btn"
            variant="grayBackground"
            css={
              includeAudienceTabValue === 'quick-filter'
                ? { background: 'transparent' }
                : {}
            }
            onClick={() => setIncludeAudienceTabValue('advanced-filter')}
          >
            Advanced filters
          </Button>
        </Flex>
        <Flex direction="column" css={{ gap: '8px', marginTop: '0px' }}>
          <Label>Include Contacts</Label>
          {includeAudienceTabValue == 'quick-filter' ? (
            <Fieldset data-testid="field-include-audience">
              <Box
                css={{
                  p: 0,
                  m: 0,
                  background: 'white',
                }}
              >
                <AudienceQuickFilter
                  data-testid="included_audience_filter"
                  channelId={current?.channel_id ?? null}
                  onSelectedItemsChanged={async (filterItems) => {
                    if (filterItems.length == 0) {
                      setMessageCount(0);
                    } else if (current?.channel_id) {
                      setIncludedAudienceFilter(
                        filterItems.length > 0 ? filterItems : null
                      );
                      const advancedIncludeFilter = translateToAdvancedFilter(
                        filterItems ?? []
                      ) as FilterType[];
                      setIncludedAudienceFilterType(
                        rebuildFilters([], advancedIncludeFilter, customDefaultObjects)
                      );
                    }
                  }}
                  selectedItems={includedAudienceFilter}
                />
              </Box>
            </Fieldset>
          ) : (
            <Fieldset>
              <AdvancedFilterBuilder
                defaultObjects={customDefaultObjects}
                customObjects={[]}
                filters={includedFilterType}
                setFilters={(filters) => {
                  setIncludedAudienceFilter(cleanFilters(filters));
                  setIncludedAudienceFilterType(filters);
                }}
              />
            </Fieldset>
          )}
        </Flex>
        <Flex direction="column" css={{ gap: '8px', marginTop: '0px' }}>
          <Label>Exclude Contacts</Label>
          {includeAudienceTabValue == 'quick-filter' ? (
            <Fieldset data-testid="field-exclude-audience">
              <Box
                css={{
                  p: 0,
                  m: 0,
                  background: 'white',
                }}
              >
                <AudienceQuickFilter
                  data-testid="excluded_audience_filter"
                  channelId={current?.channel_id ?? null}
                  onSelectedItemsChanged={async (items) => {
                    updateExcludedAudienceFilters(items);
                  }}
                  selectedItems={excludedAudienceFilter}
                  removeSystemDefaultItems={(filterItems) => {
                    let currentExcludedAudienceFilter = removeExcludedSystemDefault(
                      filterItems,
                      []
                    );
                    // remove the contacts in recent campaigns rule
                    currentExcludedAudienceFilter = removeOrCondition(
                      currentExcludedAudienceFilter,
                      (filterItem: FilterItem) => {
                        return (
                          filterItem.column == 'last_campaign_date' &&
                          filterItem.comparison == '>' &&
                          filterItem.resource == 'communication_preference'
                        );
                      }
                    );
                    // remove the open conversation rule
                    currentExcludedAudienceFilter = removeOrCondition(
                      currentExcludedAudienceFilter,
                      (filterItem: FilterItem) => {
                        return (
                          filterItem.column == 'status' &&
                          filterItem.comparison == 'in' &&
                          filterItem.resource == 'conversation' &&
                          Array.isArray(filterItem.value) &&
                          filterItem.value[0] == 'open' &&
                          filterItem.value[1] == 'automated'
                        );
                      }
                    );
                    return currentExcludedAudienceFilter;
                  }}
                />
              </Box>
            </Fieldset>
          ) : (
            <Fieldset>
              <AdvancedFilterBuilder
                defaultObjects={customDefaultObjects}
                customObjects={[]}
                filters={excludedFilterType}
                setFilters={(filters) => {
                  updateExcludedAudienceFilters(filters);
                }}
              />
            </Fieldset>
          )}
        </Flex>
        <Fieldset css={{ margin: '0px' }}>
          <Flex align="center" justify="between">
            <Flex direction="column">
              <Label>
                Exclude Contacts with Open Conversations in any Location (regardless of
                access)
              </Label>
              <Description>
                Exclude contacts that have an open conversation with any phone number in
                your organization regardless of your access.
              </Description>
            </Flex>
            <Box>
              <Switch
                data-testid="exclude-contacts-with-conversations-switch"
                checked={excludeContactsWithConversations}
                onCheckedChange={(checked) => {
                  setExcludeContactsWithConversations(checked);
                  if (checked) {
                    setExcludeOpenConversationFilter({
                      column: 'status',
                      comparison: 'in',
                      resource: 'conversation',
                      value: ['open', 'automated'],
                    });
                  } else {
                    setExcludeOpenConversationFilter(null);
                  }
                }}
              >
                <SwitchThumb />
              </Switch>
            </Box>
          </Flex>
        </Fieldset>
        <Fieldset css={{ margin: '0px' }}>
          <Flex align="center" justify="between">
            <Flex direction="column">
              <Label>Exclude Contacts who recently received another Campaign</Label>
              <Description>
                Excludes contacts from this campaign that have recently received another
                campaign.
              </Description>
            </Flex>
            <Box>
              <Switch
                data-testid="exclude-contacts-with-recent-campaigns"
                checked={showExcludeContactsWithOtherCampaigns}
                onCheckedChange={(checked) => {
                  setShowExcludeContactsWithOtherCampaigns(checked);
                  maybeUpdateLastCampaignSinceFilter(checked, excludeCampaignSince);
                  if (!checked) setExcludeCampaignSince(0);
                }}
              >
                <SwitchThumb />
              </Switch>
            </Box>
          </Flex>
          {showExcludeContactsWithOtherCampaigns && (
            <Box
              css={{
                mt: 10,
                p: 16,
                backgroundColor: '#F6F6F6',
                borderRadius: 4,
              }}
            >
              <HStack>
                <ControlGroup>
                  <IconButton
                    data-testid="subtract-from-campaign-time-since"
                    size={2}
                    css={{ backgroundColor: 'white' }}
                    onClick={() => {
                      excludeCampaignSince
                        ? setExcludeCampaignSince(excludeCampaignSince - 1)
                        : setExcludeCampaignSince(excludeCampaignSince);

                      const daysAgo = moment()
                        .subtract(
                          excludeCampaignSince
                            ? excludeCampaignSince - 1
                            : excludeCampaignSince,
                          'day'
                        )
                        .format('YYYY-MM-DD');
                      setExcludeCampaignTimeSinceFilter({
                        column: 'last_campaign_date',
                        comparison: '>',
                        resource: 'communication_preference',
                        value: daysAgo,
                      });
                    }}
                  >
                    <HiMinus />
                  </IconButton>
                  <Input
                    type="number"
                    value={excludeCampaignSince ?? 0}
                    onChange={(e) => {
                      maybeUpdateLastCampaignSinceFilter(
                        showExcludeContactsWithOtherCampaigns,
                        parseInt(e.target.value)
                      );
                      setExcludeCampaignSince(Number(e.target.value));
                    }}
                    css={{ textAlign: 'center', width: 50 }}
                  />
                  <IconButton
                    data-testid="add-to-campaign-time-since"
                    size={2}
                    css={{ backgroundColor: 'white' }}
                    onClick={() => {
                      const nextValue = excludeCampaignSince
                        ? excludeCampaignSince + 1
                        : 1;
                      maybeUpdateLastCampaignSinceFilter(
                        showExcludeContactsWithOtherCampaigns,
                        nextValue
                      );
                      setExcludeCampaignSince(nextValue);
                    }}
                  >
                    <HiPlus />
                  </IconButton>
                </ControlGroup>
                <Box css={{ fontSize: 13 }}>days</Box>
              </HStack>
            </Box>
          )}
        </Fieldset>
        <HStack>
          <Button
            onClick={() => handleSave(AccordionValue.CREATE_MESSAGE)}
            disabled={!isValidAudience}
          >
            Save
          </Button>
          <Button
            variant="gray"
            ghost={true}
            onClick={() => setAccordion(AccordionValue.DEFAULT_VALUE)}
          >
            Cancel
          </Button>
        </HStack>
      </VStack>
    </CampaignAccordion>
  );
};
