import { Box, Checkbox, Flex, VStack } from '@/shared/ui/index';
import { styled } from '@/stitches.config';

type PermissionActionItemProps = {
  title: string;
  description: string;
  onToggle: (checked: boolean) => void;
  checked: boolean;
  index: number;
};

export const PermissionActionItem = (props: PermissionActionItemProps) => {
  const { title, description, onToggle, checked, index } = props;

  return (
    <ActionItem
      justify="between"
      align="center"
      css={index > 0 ? { borderTop: '1px solid #02023414' } : {}}
    >
      <VStack gap="1">
        <Title>{title}</Title>
        <StyledText>{description}</StyledText>
      </VStack>
      <Checkbox
        css={{
          minWidth: '15px',
        }}
        checked={checked}
        onCheckedChange={onToggle}
        aria-label={title}
      />
    </ActionItem>
  );
};

const Title = styled(Box, {
  fontSize: 16,
  lineHeight: '20px',
  fontWeight: '500',
  color: '#1C2024',
  textTransform: 'capitalize',
});

const StyledText = styled(Box, {
  fontSize: 14,
  lineHeight: '20px',
  color: '#60646C',
  mt: 4,
});

const ActionItem = styled(Flex, {
  px: 24,
  py: 12,
});
