import {
  CallStatusTypes,
  CallType,
  VoIPActions,
  VoIPActionTypes,
  VoIPStateType,
} from '@/shared/types/voip';

const VoIPReducer = (state: VoIPStateType, action: VoIPActions): VoIPStateType => {
  switch (action.type) {
    case VoIPActionTypes.ADD_DEVICE:
      return {
        ...state,
        device: action.payload,
      };
    case VoIPActionTypes.DESTROY_DEVICE:
      return {
        ...state,
        device: null,
      };
    case VoIPActionTypes.ADD_INCOMING_CALL:
      return {
        ...state,
        incomingCall: {
          call: action.payload,
        },
        callStatus: CallStatusTypes.INCOMING,
      };
    case VoIPActionTypes.DESTROY_INCOMING_CALL:
      return {
        ...state,
        incomingCall: null,
        callStatus: null,
      };
    case VoIPActionTypes.ADD_OUTGOING_CALL:
      return {
        ...state,
        outgoingCall: {
          call: action.payload,
        },
        callStatus: CallStatusTypes.OUTGOING,
      };
    case VoIPActionTypes.DESTROY_OUTGOING_CALL:
      return {
        ...state,
        outgoingCall: null,
      };
    case VoIPActionTypes.ADD_CALL_STATUS:
      return {
        ...state,
        callStatus: action.payload,
      };
    case VoIPActionTypes.START_INCOMING_CALL_RECORDING:
      return {
        ...state,
        incomingCall: {
          ...state.incomingCall,
          recording: true,
          ...action.payload,
        } as CallType,
      };
    case VoIPActionTypes.START_OUTGOING_CALL_RECORDING:
      return {
        ...state,
        outgoingCall: {
          ...state.outgoingCall,
          recording: true,
          ...action.payload,
        } as CallType,
      };
    case VoIPActionTypes.STOP_INCOMING_CALL_RECORDING:
      return {
        ...state,
        incomingCall: {
          ...state.incomingCall,
          recording: false,
          recording_id: null,
        } as CallType,
      };
    case VoIPActionTypes.STOP_OUTGOING_CALL_RECORDING:
      return {
        ...state,
        outgoingCall: {
          ...state.outgoingCall,
          recording: false,
          recording_id: null,
        } as CallType,
      };
    default:
      return { ...state };
  }
};

export default VoIPReducer;
