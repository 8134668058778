import { useEffect, useState } from 'react';
import { HiStatusOffline } from 'react-icons/hi';

import { Box, HStack } from '@/shared/ui';

type CountdownToastProps = {
  /**
   * The initial count value for the countdown.
   */
  initialCount: number;
  /**
   * Callback function that is executed when the countdown ends (reaches zero).
   */
  onEnd: () => void;
};

// CountdownToast is a functional component that displays a countdown timer.
// It takes two props: initialCount which is the starting number for the countdown,
// and onEnd which is a callback function that gets executed when the countdown reaches zero.
export const CountdownToast: React.FC<CountdownToastProps> = ({
  initialCount,
  onEnd,
}) => {
  // count is a state variable that holds the current countdown number.
  // It is initialized with the value of initialCount.
  const [count, setCount] = useState(initialCount);

  // useEffect hook is used to handle side effects in the component.
  // In this case, it is used to handle the countdown logic.
  useEffect(() => {
    // If count reaches zero, execute the onEnd callback and exit the effect.
    if (count <= 0) {
      onEnd();
      return;
    }

    // If count is greater than zero, set a timeout to decrease the count by 1 after 1 second.
    const timerId = setTimeout(() => {
      setCount(count - 1);
    }, 1000);

    // The cleanup function of the effect clears the timeout when the component unmounts
    // or before the next time the effect runs.
    return () => clearTimeout(timerId);
  }, [count, onEnd]); // The effect depends on count and onEnd. It runs whenever any of these values change.

  // The component renders a div that displays the current countdown number.
  return (
    <HStack>
      <HiStatusOffline />
      <Box>Lost Connection. Reconnecting in {count} ...</Box>
    </HStack>
  );
};
