import { useCombobox, UseComboboxStateChangeOptions } from 'downshift';
import { DebouncedFunc } from 'lodash';
import { useEffect, useState } from 'react';
import Highlighter from 'react-highlight-words';
import { HiPencil } from 'react-icons/hi';
import { useMedia } from 'react-use';

import { AddSignature } from '@/pages/settings/organization/signatures/AddSignature';
import { useSignatures } from '@/pages/settings/organization/signatures/context/SignaturesContext';
import { Signature } from '@/shared/types';
import {
  Badge,
  Box,
  Button,
  ComboboxItem,
  Flex,
  IconButton,
  Input,
  ScrollArea,
  ScrollAreaCorner,
  ScrollAreaScrollbar,
  ScrollAreaThumb,
  ScrollAreaViewport,
  Text,
} from '@/shared/ui';
import { styled } from '@/stitches.config';

type SignatureComboboxProps = {
  anchorDimensions?: {
    height: number;
    width: number;
  };
  updateMessageFromSignature: DebouncedFunc<(signature: Signature | undefined) => void>;
  onClose: () => void;
};

const SignatureComboboxItem = styled(ComboboxItem, {
  py: 6,
  mb: '$2',
});

// Main Signature Combobox component!
// filter and select signature from list of signatures
export const SignatureCombobox = (props: SignatureComboboxProps) => {
  const isLargeScreen = useMedia('(min-width: 1600px)');

  const signaturesContext = useSignatures();
  const { signaturesState, getSignatures, setCurrentSignature } = signaturesContext;
  const { allSignatures, defaultUserSignatureId } = signaturesState;
  const [inputItems, setInputItems] = useState(allSignatures);
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    // Check if the current signature is the default signature and save it to the state
    const current = signaturesState.allSignatures.find((current: Signature) => {
      return current.id === signaturesState.defaultUserSignatureId;
    });
    setCurrentSignature(current);
  }, []);

  useEffect(() => {
    if (allSignatures.length < 1) {
      getSignatures();
    }
  }, []);

  useEffect(() => {
    if (searchValue) {
      setInputItems(
        allSignatures.filter(
          (item: Signature) =>
            item?.name?.toLowerCase().includes(searchValue?.toLowerCase()) ||
            item?.body?.toLowerCase().includes(searchValue?.toLowerCase())
        )
      );
    } else {
      setInputItems(allSignatures);
    }
  }, [allSignatures]);

  const { getMenuProps, getInputProps, getItemProps } = useCombobox({
    items: inputItems,
    itemToString: (item) => item?.name || '',
    stateReducer: (
      state,
      actionAndChanges
    ): Partial<UseComboboxStateChangeOptions<Signature>> => {
      const { type, changes } = actionAndChanges;
      // this prevents the menu from being closed when the user selects an item with 'Enter' or mouse
      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
          if (changes.selectedItem) {
            props.updateMessageFromSignature(changes.selectedItem);
            props.onClose();
          }
          return {
            changes, // default Downshift new state changes on item selection
          };
        case useCombobox.stateChangeTypes.ItemClick:
          if (changes.selectedItem) {
            props.updateMessageFromSignature(changes.selectedItem);
            props.onClose();
          }
          return {
            changes, // default Downshift new state changes on item selection
          };
        default:
          return changes; // otherwise business as usual
      }
    },
    onInputValueChange: ({ inputValue }) => {
      const inputString = inputValue ? inputValue : '';
      setSearchValue(inputString);
      setInputItems(
        allSignatures.filter(
          (item: Signature) =>
            item?.name?.toLowerCase().includes(inputString?.toLowerCase()) ||
            item?.body?.toLowerCase().includes(inputString?.toLowerCase())
        )
      );
    },
  });

  const calculateComboboxItemWidth = () => {
    const isSidePanelOpen = Number(props.anchorDimensions?.width) < 900;
    if (isLargeScreen) {
      if (isSidePanelOpen) {
        return Number(props.anchorDimensions?.width) > 650 ? '94%' : '91%';
      }
      return '96%';
    } else {
      return '93%';
    }
  };

  return (
    <Box css={{ p: '$4' }}>
      <Box css={{ pb: '$3' }}>
        <Text css={{ fontWeight: 600, fontSize: 15 }}>{'Signatures'}</Text>
      </Box>
      <Flex css={{ pb: '$4' }}>
        <Input
          {...getInputProps({}, { suppressRefError: true })}
          placeholder="Search signatures..."
          css={{ mr: '$1' }}
        />
        <AddSignature>
          <Button onClick={(e) => e.preventDefault()} css={{ minWidth: '120px' }}>
            Add Signature
          </Button>
        </AddSignature>
      </Flex>
      <ScrollArea variant="combobox" {...getMenuProps({}, { suppressRefError: true })}>
        <ScrollAreaViewport variant="combobox" css={{ minHeight: '192px' }}>
          {inputItems.length < 1 && searchValue ? (
            <Flex
              align="center"
              justify="center"
              css={{ height: '100%', fontSize: 14, margin: '85px 0' }}
            >
              No signatures match this search
            </Flex>
          ) : (
            inputItems.map((item, index) => (
              <Flex
                key={item.id}
                align="center"
                css={{
                  width: props.anchorDimensions
                    ? props.anchorDimensions?.width - 42
                    : undefined,
                  '&:hover': {
                    backgroundColor: '$slate2',
                    borderRadius: 4,
                  },
                }}
              >
                <SignatureComboboxItem
                  direction="column"
                  css={{
                    height: '100%',
                    width: calculateComboboxItemWidth(),
                    mb: 0,
                    borderRadius: 4,
                  }}
                  data-testid={`signature-item-${item.id}`}
                  {...getItemProps({ item, index })}
                >
                  <Flex align="center" justify="between">
                    <Flex direction="column" css={{ maxWidth: '80%' }}>
                      <Box css={{ fontWeight: 600, mb: 3 }}>
                        <Highlighter
                          searchWords={[searchValue]}
                          autoEscape={true}
                          textToHighlight={item.name || ''}
                          highlightStyle={{ backgroundColor: '#FFC106' }}
                        />
                        {item.id == defaultUserSignatureId && (
                          <Badge
                            size="1"
                            variant="gray"
                            css={{ padding: '0 4px', ml: 5, verticalAlign: 'middle' }}
                          >
                            Default
                          </Badge>
                        )}
                      </Box>
                      <Box
                        css={{
                          width: '100%',
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        <Highlighter
                          searchWords={[searchValue]}
                          autoEscape={true}
                          textToHighlight={item.body || ''}
                          highlightStyle={{ backgroundColor: '#FFC106' }}
                        />
                      </Box>
                    </Flex>
                  </Flex>
                </SignatureComboboxItem>
                <Box css={{ pr: 15, borderRadius: 4 }}>
                  <AddSignature signature={item}>
                    <IconButton variant="outline" size="2">
                      <HiPencil />
                    </IconButton>
                  </AddSignature>
                </Box>
              </Flex>
            ))
          )}
        </ScrollAreaViewport>
        <ScrollAreaScrollbar orientation="vertical" variant="combobox">
          <ScrollAreaThumb />
        </ScrollAreaScrollbar>
        <ScrollAreaCorner />
      </ScrollArea>
    </Box>
  );
};
