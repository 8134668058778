/* eslint-disable react/jsx-key */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react';
import { HiChevronDown, HiChevronUp } from 'react-icons/hi';
import { useSortBy, useTable } from 'react-table';
import { TableVirtuoso } from 'react-virtuoso';

import { Contact } from '@/shared/types/campaigns';
import { Box, HStack } from '@/shared/ui';
import { styled } from '@/stitches.config';

type VirtualisedContactsTableProps = {
  /** table data array of objects */
  data: any[];
  /** table columns */
  columns: any[];
  /** is the list currently searched */
  isSearched: boolean;
  /** update the offset for scrolling to bottom */
  setOffset: (offset: number) => void;
  /** currently checked contacts in the list */
  selectedContacts?: Array<Contact>;
  /** update the selected contacts */
  setSelectedContacts?: (contacts: Array<Contact>) => void;
};

export const VirtualisedContactsTable = (props: VirtualisedContactsTableProps) => {
  const { data, columns, isSearched, setOffset } = props;
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
    },
    useSortBy
  );

  // Render the UI for your table
  return (
    <Styled>
      <TableVirtuoso
        data={data}
        initialItemCount={data.length}
        endReached={() =>
          isSearched ? console.log('end reached after search') : setOffset(data.length)
        }
        style={{ height: 'calc(100vh - 270px)' }}
        components={{
          Table: ({ style, ...props }) => <table {...getTableProps()} {...props} />,
          TableBody: React.forwardRef(({ style, ...props }, ref) => (
            <tbody {...getTableBodyProps()} {...props} ref={ref} />
          )),
          TableRow: (props) => {
            const index = props['data-index'];
            const row = rows[index];
            return <tr {...props} {...row.getRowProps()} />;
          },
        }}
        fixedHeaderContent={() => {
          return headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  style={{
                    height: 50,
                    maxHeight: 50,
                    width: column.colWidth || 'auto',
                    maxWidth: column.colWidth || 'auto',
                    paddingTop: 5,
                    paddingBottom: 5,
                    fontSize: 13,
                    justifyContent: 'center',
                    cursor: 'pointer',
                  }}
                >
                  <HStack align="center" gap={2}>
                    {column.render('Header')}
                    <span>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <HiChevronDown
                            style={{
                              opacity: '0.6',
                              marginLeft: 5,
                            }}
                          />
                        ) : (
                          <HiChevronUp
                            style={{
                              opacity: '0.6',
                              marginLeft: 5,
                            }}
                          />
                        )
                      ) : (
                        ''
                      )}
                    </span>
                  </HStack>
                </th>
              ))}
            </tr>
          ));
        }}
        itemContent={(index) => {
          const row = rows[index];
          prepareRow(row);
          return row.cells.map((cell) => {
            return (
              <td
                {...cell.getCellProps()}
                style={{
                  height: 50,
                  maxHeight: 50,
                  width: cell.column.colWidth || 'auto',
                  maxWidth: cell.column.colWidth || 'auto',
                }}
              >
                {cell.render('Cell')}
              </td>
            );
          });
        }}
      />
    </Styled>
  );
};

const Styled = styled(Box, {
  '& table': {
    boxSizing: 'border-box',
    fontSize: '13px',
    width: '100%',
    borderSpacing: 0,
    textAlign: 'left',

    '& tr': {
      boxSizing: 'border-box',
      fontSize: '13px',
      position: 'relative',
      backgroundColor: 'white',
      '&:hover': {
        backgroundColor: '$slate2',
      },
      ':last-child': {
        '& td': {
          borderBottom: 'none',
        },
      },
    },

    '& td': {
      boxSizing: 'border-box',
      position: 'relative',
      margin: 0,
      py: 5,
      paddingLeft: 15,
      borderBottom: '1px solid $gray3',
      ':last-child': {
        borderRight: 0,
      },
    },

    '& th': {
      boxSizing: 'border-box',
      position: 'relative',
      margin: 0,
      py: 5,
      paddingLeft: 15,
      borderBottom: '1px solid $gray3',
      backgroundColor: 'white',
      ':last-child': {
        borderRight: 0,
      },
    },
  },
});
