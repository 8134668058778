import React from 'react';
import { HiOutlineMenu } from 'react-icons/hi';
import { useMedia } from 'react-use';

import { useDisclosure } from '@/shared/hooks';
import { styled } from '@/stitches.config';

import {
  Box,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  DrawerPortal,
  Flex,
  IconButton,
} from '../../ui';
import { MainNavigation } from '.';

export const PageHeaderContainer = styled(Flex, {
  height: '58px',
  maxWidth: '100vw',
  flexShrink: 0,
  flexWrap: 'wrap',
  borderBottom: 'thin solid $gray4',
  padding: 15,
  paddingLeft: 10,
});

type PageHeaderProps = {
  children: Array<React.ReactNode>;
  isContact?: boolean;
};

export function PageHeader(props: PageHeaderProps) {
  const isDesktop = useMedia('(min-width: 912px)');
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <PageHeaderContainer justify="between" align="center">
      {props.isContact ? (
        <Flex>{props.children[0]}</Flex>
      ) : (
        <Box>
          <Box css={{ '@lg': { display: 'none' } }}>
            <IconButton onClick={onOpen}>
              <HiOutlineMenu fontSize="1rem" />
            </IconButton>
          </Box>
          <Box css={{ display: 'none', '@lg': { display: 'flex' } }}>
            {props.children[0]}
          </Box>

          {!isDesktop ? (
            <Drawer open={isOpen}>
              <DrawerPortal>
                <DrawerOverlay />
                <DrawerContent
                  onEscapeKeyDown={onClose}
                  onPointerDownOutside={onClose}
                  css={{ width: 230 }}
                  side="left"
                >
                  <MainNavigation />
                </DrawerContent>
              </DrawerPortal>
            </Drawer>
          ) : null}
        </Box>
      )}
      <Box>{props.children[1]}</Box>
    </PageHeaderContainer>
  );
}
