/* eslint-disable react-hooks/exhaustive-deps */
import { debounce } from 'lodash';
import { useEffect, useState } from 'react';

import * as API from '@/shared/api/campaigns';
import { Campaign } from '@/shared/types/campaigns';
import { Box } from '@/shared/ui';

import { ValueCombobox } from './Combobox';

type CampaignValuesComboboxProps = {
  selected: { label?: string; value: string | number } | null | undefined;
  onSelect: (option: { label: string; value: string | number }) => void;
  selectLabel?: string;
  searchLabel?: string;
  selectorStyles?: { [key: string]: any };
  isOpen?: boolean;
  disabled?: boolean;
};

type CampaignOption = { label: string; value: string };

export const CampaignValuesCombobox = (
  props: CampaignValuesComboboxProps
): JSX.Element => {
  const { selected, onSelect, selectLabel, searchLabel } = props;

  const [searchValue, setSearchValue] = useState('');
  const [campaignOptions, setCampaignOptions] = useState<CampaignOption[]>([]);

  const handleSearchValue = (value: string) => {
    setSearchValue(value);
    debounceSearchTags(value);
  };

  const searchCampaigns = async (value: string) => {
    if (value) {
      const data = await API.getCampaigns({
        title: value,
        status: 'complete',
        offset: 0,
        limit: 10,
      });
      const options = data.data.campaigns.map((campaign: Campaign) => ({
        label: campaign.title || '',
        value: campaign.id || '',
      }));
      setCampaignOptions((prevOptions) => {
        const newOptions = options.filter(
          (option: { value: string }) =>
            !prevOptions.some((prevOption) => prevOption.value === option.value)
        );
        return [...prevOptions, ...newOptions];
      });
    }
  };

  const debounceSearchTags = debounce(searchCampaigns, 500);

  const getCampaigns = async () => {
    const data = await API.getCampaigns({
      status: 'complete',
      offset: 0,
      limit: 10,
    });
    const options = data.data.campaigns.map((campaign: Campaign) => ({
      label: campaign.title || '',
      value: campaign.id || '',
    }));
    setCampaignOptions(options);
  };

  const getCampaignById = async (id: string) => {
    const data = await API.getOneCampaign(id);
    // add the campaign to the options if it doesn't exist
    if (!campaignOptions.some((option) => option.value === data.id)) {
      setCampaignOptions((prevOptions) => [
        ...prevOptions,
        {
          label: data.title || '',
          value: data.id || '',
        },
      ]);
    }
  };

  useEffect(() => {
    getCampaigns();
  }, []);

  // if selected campaign is not in the options, add it
  useEffect(() => {
    if (selected?.value) {
      getCampaignById(String(selected.value));
    }
  }, [selected?.value]);

  return (
    <Box>
      <ValueCombobox
        disabled={props.disabled}
        isOpen={props.isOpen}
        selectorStyles={props.selectorStyles}
        options={campaignOptions}
        selected={{ label: selected?.label || '', value: selected?.value || '' }}
        onSelect={onSelect}
        searchValue={searchValue}
        handleSearchValue={handleSearchValue}
        selectLabel={selectLabel}
        searchLabel={searchLabel}
      />
    </Box>
  );
};
