/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { HiSortDescending } from 'react-icons/hi';

import { ConversationSortTypes } from '@/shared/types/conversations';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  IconButton,
} from '@/shared/ui';

import { useConversation } from '../context/ConversationContext';
import { SEARCH_CONVERSATIONS_LIMIT } from './utils';

export const ConversationsSort = () => {
  const conversations = useConversation();
  const { setSort, advancedSearchConversations, inbox, conversationState } =
    conversations;
  const { sort } = conversationState;

  const onSortClick = (
    sortBy: ConversationSortTypes,
    limit?: number,
    offset?: number
  ) => {
    setSort(sortBy);

    localStorage.setItem('conversations-sort', sortBy);

    const sortParams =
      sortBy === 'unread'
        ? {
            sort: {
              column: 'unread_count',
              order: 'desc',
              resource: 'conversation',
            },
          }
        : {
            sort: {
              column: 'last_message_timestamp',
              order: sortBy === 'newest' ? 'desc' : 'asc',
              resource: 'conversation',
            },
          };

    const sortParamsWithLimitAndOffset =
      sortBy === 'unread'
        ? {
            limit,
            offset,
            sort: {
              column: 'unread_count',
              order: 'desc',
              resource: 'conversation',
            },
          }
        : {
            limit,
            offset,
            sort: {
              column: 'last_message_timestamp',
              order: sortBy === 'newest' ? 'desc' : 'asc',
              resource: 'conversation',
            },
          };

    const sort =
      offset !== undefined && limit !== undefined
        ? sortParamsWithLimitAndOffset
        : sortParams;

    // merge sort params with search params
    advancedSearchConversations({
      ...inbox,
      ...sort,
    });
  };

  useEffect(() => {
    setSort(
      (localStorage.getItem('conversations-sort') as ConversationSortTypes) ||
        ConversationSortTypes.NEWEST
    );
    onSortClick(
      (localStorage.getItem('conversations-sort') as ConversationSortTypes) ||
        ConversationSortTypes.NEWEST,
      SEARCH_CONVERSATIONS_LIMIT,
      0
    );
  }, []);

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <IconButton size="2" variant="outline">
          <HiSortDescending />
        </IconButton>
      </DropdownMenuTrigger>
      <DropdownMenuContent sideOffset={2}>
        <DropdownMenuItem
          onClick={() => onSortClick(ConversationSortTypes.NEWEST)}
          css={{ fontWeight: sort === ConversationSortTypes.NEWEST ? 600 : 400 }}
        >
          <span>Newest</span>
        </DropdownMenuItem>
        <DropdownMenuItem
          onClick={() => onSortClick(ConversationSortTypes.OLDEST)}
          css={{ fontWeight: sort === ConversationSortTypes.OLDEST ? 600 : 400 }}
        >
          <span>Oldest</span>
        </DropdownMenuItem>
        <DropdownMenuItem
          onClick={() => onSortClick(ConversationSortTypes.UNREAD)}
          css={{ fontWeight: sort === ConversationSortTypes.UNREAD ? 600 : 400 }}
        >
          <span>Unread</span>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
