import React, { useContext } from 'react';

import * as API from '@/shared/api/auth';
import { Organization } from '@/shared/types';
import { UserRoles } from '@/shared/types/users';
import analytics from '@/shared/utils/setup/analytics';
import {
  getAuthTokens,
  getOrganizationId,
  removeAuthTokens,
  removeOrganizationId,
  setAuthTokens,
  setOrganizationId,
} from '@/shared/utils/storage';

export type TokensType = {
  /* json web token */
  access_token?: string | null;
  /* current user's profile picture */
  attachment?: {
    id?: string | null;
    url?: string;
    content_type?: string | null;
  } | null;
  /* current user's email */
  email?: string | null;
  /* current user's invite token */
  invite_token?: string | null;
  /* current user's name */
  name?: string | null;
  /* current user's organization id */
  organization_id?: string | null;
  /* current user's role in current organization */
  role?: UserRoles | null;
  /* current user's renewal token */
  renewal_token?: string | null;
  /* current user's id */
  user_id?: number | null;
};

export enum OnboardingStatus {
  COMPLETE = 'complete',
  IN_PROGRESS = 'in_progress',
}

export type OrganizationInfo = {
  organization?: Organization;
  organizations?: Array<Organization>;
};

export type OrganizationAttachment = {
  url?: string | null;
  content_type?: string | null;
  id?: string | null;
};

export const AuthContext = React.createContext<{
  isAuthenticated: boolean;
  isAdmin: boolean;
  onboardingStatus: string;
  tokens: TokensType | null;
  loading: boolean;
  organizationName: string;
  organizationId: string;
  organizationInfo: OrganizationInfo | null;
  organizations: Array<Organization>;
  google_access_token: string | null;
  authorization_url: string;
  register: (params: API.RegisterParams) => Promise<void>;
  login: (params: API.LoginParams) => Promise<void>;
  sso: (params: API.LoginWithSsoParams) => Promise<void>;
  logout: () => Promise<void>;
  updateAttachment: (params: UpdateAttachmentParams | null) => void;
  acceptInvite: (params: API.InviteParams) => Promise<void>;
  changeOrganization: (organization_id: string) => Promise<void>;
  updateOrganizationAttachment: (attachment: OrganizationAttachment) => Promise<void>;
  updateOnboardingStatus: () => Promise<void>;
  startNewAccountOnboarding: () => void;
  refresh: () => Promise<void>;
  handleAuthSuccess: (tokens: TokensType) => Promise<void>;
}>({
  isAuthenticated: false,
  isAdmin: false,
  onboardingStatus: OnboardingStatus.COMPLETE,
  tokens: null,
  loading: false,
  organizationInfo: {},
  organizationName: '',
  organizationId: '',
  organizations: [],
  google_access_token: null,
  authorization_url: '',
  register: () => Promise.resolve(),
  login: () => Promise.resolve(),
  sso: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  updateAttachment: () => Promise.resolve(),
  acceptInvite: () => Promise.resolve(),
  changeOrganization: () => Promise.resolve(),
  updateOrganizationAttachment: () => Promise.resolve(),
  updateOnboardingStatus: () => Promise.resolve(),
  startNewAccountOnboarding: () => Promise.resolve(),
  refresh: () => Promise.resolve(),
  handleAuthSuccess: () => Promise.resolve(),
});

export const useAuth = () => useContext(AuthContext);

type UpdateAttachmentParams = {
  url: string;
  content_type?: string;
};

// Refresh every 5 hours
const ONE_HOUR = 60 * 60 * 1000;
const AUTH_SESSION_TTL = 5 * ONE_HOUR;

type Props = React.PropsWithChildren<Record<string, unknown>>;
type State = {
  /* Is the user an admin? */
  isAdmin: boolean;
  /* Loading user auth */
  loading: boolean;
  /* Auth tokens */
  tokens: TokensType | null;
  /* Is the user authenticated? */
  isAuthenticated: boolean;
  /* Onboarding status - complete or in-progress */
  onboardingStatus: string;
  /* Current organization name */
  organizationName: string;
  /* Current organization id */
  organizationId: string;
  /* The list of organizations the user has access to */
  organizations: Array<Organization>;
  /* The current organization info */
  organizationInfo: OrganizationInfo | null;
  /* The google access token */
  google_access_token: string | null;
  /* The authorization url */
  authorization_url: string;
};

export class AuthProvider extends React.Component<Props, State> {
  timeout: any = null;

  constructor(props: Props) {
    super(props);

    this.state = {
      isAdmin: false,
      organizationName: '',
      organizationId: '',
      organizations: [],
      loading: true,
      isAuthenticated: false,
      onboardingStatus: OnboardingStatus.COMPLETE,
      tokens: getAuthTokens(),
      organizationInfo: null,
      google_access_token: null,
      authorization_url: '',
    };
  }

  async componentDidMount() {
    const { tokens } = this.state;
    const refreshToken = tokens && tokens.renewal_token;

    if (!refreshToken) {
      this.setState({ loading: false });

      return;
    }

    // attempt refresh auth session on load
    await this.refresh();

    this.setState({ loading: false });
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);

    this.timeout = null;
  }

  handleAuthSuccess = async (tokens: TokensType) => {
    setAuthTokens(tokens);

    if (tokens?.organization_id) {
      setOrganizationId(tokens?.organization_id);
    }

    this.setState({
      tokens,
      isAuthenticated: true,
      isAdmin: tokens?.role === UserRoles.ADMIN ? true : false,
    });

    // get the organization name if auth success
    const orgInfo = await this.getOrganizations();

    // init error tracking & analytics
    analytics.identify(
      tokens?.user_id?.toString() || '',
      tokens?.email || '',
      tokens?.name || '',
      tokens?.organization_id || '',
      orgInfo.organization.organization_name,
      orgInfo.organizations
    );

    this.setState({
      organizationInfo: orgInfo,
      organizations: orgInfo.organizations,
      organizationName: orgInfo.organization.organization_name,
      organizationId: orgInfo.organization.id,
      onboardingStatus: orgInfo.organization.onboarding_status,
      google_access_token: orgInfo.organization.google_access_token,
    });

    // refresh every 5 hours
    this.timeout = setTimeout(() => this.refresh(), AUTH_SESSION_TTL);

    if (tokens.invite_token) {
      window.location.href = `/join/${tokens.invite_token}`;
    }
  };

  handleClearAuth = () => {
    removeOrganizationId();
    removeAuthTokens();
    this.setState({ tokens: null, isAuthenticated: false });
  };

  refresh = async () => {
    return API.renew()
      .then((tokens) => this.handleAuthSuccess(tokens))
      .catch((err) => {
        // console warn when session is invalid - can filter in Highlight for these
        console.warn('Invalid session:', err);
      });
  };

  register = async (params: API.RegisterParams): Promise<void> => {
    // set user, authenticated status, etc
    return API.register(params).then((tokens) => this.handleAuthSuccess(tokens));
  };

  login = async (params: API.LoginParams): Promise<void> => {
    // set user, authenticated status, etc
    return API.login(params).then((tokens) => this.handleAuthSuccess(tokens));
  };

  sso = async (params: API.LoginWithSsoParams): Promise<void> => {
    // redirect user to Identity Provider
    return API.sso(params).then(
      (authorization_url) => (window.location.href = authorization_url)
    );
  };

  logout = async (): Promise<void> => {
    // set user, authenticated status, etc
    return API.logout().then(() => this.handleClearAuth());
  };

  // get all the organizations the user is a part of
  getOrganizations = async () => {
    const organizationInfo = API.fetchOrganizationsInfo();

    return organizationInfo;
  };

  // update the users profile picture
  updateAttachment = (params: UpdateAttachmentParams | null) => {
    this.setState({
      tokens: { ...this.state.tokens, attachment: params },
    });
  };

  // accept an invite for a new organization
  acceptInvite = async (params: API.InviteParams): Promise<void> => {
    return API.acceptInvite(params).then(() => this.refresh());
  };

  // change the current organization of the user
  changeOrganization = async (organization_id: string) => {
    return API.changeOrganization(organization_id, this.state?.tokens?.access_token).then(
      (organization) => this.setOrganizationInfo(organization)
    );
  };

  // update the organization attachment profile picture
  updateOrganizationAttachment = async (params: OrganizationAttachment) => {
    this.setState({
      organizations: this.state?.organizations?.map((organization: Organization) => {
        return organization.id === params.id
          ? {
              ...organization,
              attachment: params.url
                ? {
                    url: params.url,
                    content_type: params?.content_type || '',
                  }
                : null,
            }
          : organization;
      }),
    });
    return API.updateOrganizationInfo({
      attachments: params.url
        ? [
            {
              url: params.url,
              content_type: params.content_type,
            },
          ]
        : [],
    });
  };

  // given the current organization, set the organization id and name
  setOrganizationInfo = (organization: Organization) => {
    setOrganizationId(organization.id);

    this.setState({
      organizationId: organization.id,
      organizationName: organization.organization_name,
    });
  };

  // set the onboarding status to complete
  updateOnboardingStatus = () => {
    return API.updateOrganizationInfo({
      onboarding_status: OnboardingStatus.COMPLETE,
    }).then(() => this.setState({ onboardingStatus: OnboardingStatus.COMPLETE }));
  };

  // set the onboarding status to in_progress
  // this renders the onboarding steps
  startNewAccountOnboarding = () => {
    return this.setState({ onboardingStatus: OnboardingStatus.IN_PROGRESS });
  };

  maybeSetOrganizationId = (id: string) => {
    const orgId = getOrganizationId();

    if (!orgId) {
      setOrganizationId(id);
    }
  };

  render() {
    const {
      isAdmin,
      loading,
      isAuthenticated,
      onboardingStatus,
      tokens,
      organizationName,
      organizationId,
      organizations,
      google_access_token,
      organizationInfo,
      authorization_url,
    } = this.state;

    return (
      <AuthContext.Provider
        value={{
          isAdmin,
          organizations,
          google_access_token,
          organizationName,
          organizationInfo,
          organizationId,
          isAuthenticated,
          onboardingStatus,
          tokens,
          loading,
          authorization_url,
          register: this.register,
          login: this.login,
          sso: this.sso,
          logout: this.logout,
          updateAttachment: this.updateAttachment,
          changeOrganization: this.changeOrganization,
          updateOrganizationAttachment: this.updateOrganizationAttachment,
          acceptInvite: this.acceptInvite,
          updateOnboardingStatus: this.updateOnboardingStatus,
          startNewAccountOnboarding: this.startNewAccountOnboarding,
          refresh: this.refresh,
          handleAuthSuccess: this.handleAuthSuccess,
        }}
      >
        {this.props.children}
      </AuthContext.Provider>
    );
  }
}
