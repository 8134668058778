import * as SelectPrimitive from '@radix-ui/react-select';

import { styled } from '@/stitches.config';

const StyledTrigger = styled(SelectPrimitive.SelectTrigger, {
  backgroundColor: '$loContrast',
  boxShadow: 'inset 0 0 0 1px $colors$slate7',
  color: '$hiContrast',
  fontFamily: '$untitled',
  fontVariantNumeric: 'tabular-nums',
  fontWeight: 400,

  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',

  borderRadius: '$1',
  height: 35,
  fontSize: '$2',
  px: '$2',
  lineHeight: '$sizes$6',
  '&:-webkit-autofill::first-line': {
    fontSize: '$3',
  },

  '&:focus-within': {
    zIndex: 1,
    boxShadow:
      'inset 0px 0px 0px 1px $colors$primaryColor, 0px 0px 0px 1px $colors$primaryColor',
  },

  '&:disabled': {
    pointerEvents: 'none',
    backgroundColor: '#00003B0D',
  },
});

const StyledContent = styled(SelectPrimitive.Content, {
  overflow: 'hidden',
  backgroundColor: 'white',
  borderRadius: 6,
  boxShadow:
    '0px 10px 38px -10px rgba(22, 23, 24, 0.35), 0px 10px 20px -15px rgba(22, 23, 24, 0.2)',
});

const StyledViewport = styled(SelectPrimitive.Viewport, {
  padding: 5,
});

const StyledItem = styled(SelectPrimitive.Item, {
  all: 'unset',
  fontSize: 14,
  lineHeight: 1,
  borderRadius: 3,
  display: 'flex',
  alignItems: 'center',
  height: 25,
  padding: '6px 35px 6px 25px',
  position: 'relative',
  userSelect: 'none',

  '&[data-state="checked"]': {
    backgroundColor: '#00003B0D',
  },

  '&[data-disabled]': {
    color: '$primaryColor',
    pointerEvents: 'none',
  },

  '&:focus': {
    backgroundColor: '$slate3',
  },

  '&:hover': {
    backgroundColor: '#3E63DD',
    color: '#FFFFFF',
  },
});

const StyledLabel = styled(SelectPrimitive.Label, {
  padding: '0 25px',
  fontSize: 12,
  lineHeight: '25px',
  color: '$gray9',
});

const StyledSeparator = styled(SelectPrimitive.Separator, {
  height: 1,
  backgroundColor: '$primaryColor',
  margin: 5,
});

const StyledItemIndicator = styled(SelectPrimitive.ItemIndicator, {
  position: 'absolute',
  left: 0,
  width: 25,
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const scrollButtonStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: 25,
  backgroundColor: 'white',
  color: '$primaryColor',
  cursor: 'default',
};

const StyledScrollUpButton = styled(SelectPrimitive.ScrollUpButton, scrollButtonStyles);

const StyledScrollDownButton = styled(
  SelectPrimitive.ScrollDownButton,
  scrollButtonStyles
);

// Exports
export const Select = SelectPrimitive.Root;
export const SelectTrigger = StyledTrigger;
export const SelectValue = SelectPrimitive.Value;
export const SelectIcon = SelectPrimitive.Icon;
export const SelectContent = StyledContent;
export const SelectViewport = StyledViewport;
export const SelectGroup = SelectPrimitive.Group;
export const SelectItem = StyledItem;
export const SelectItemText = SelectPrimitive.ItemText;
export const SelectItemIndicator = StyledItemIndicator;
export const SelectLabel = StyledLabel;
export const SelectSeparator = StyledSeparator;
export const SelectScrollUpButton = StyledScrollUpButton;
export const SelectScrollDownButton = StyledScrollDownButton;
