import { Call, Device } from '@twilio/voice-sdk';

export enum CallStatusTypes {
  INCOMING = 'incoming',
  OUTGOING = 'outgoing',
  NEW_OUTGOING = 'new_outgoing',
  INCOMING_ACCEPTED = 'incoming_accepted',
}

export type VoIPStateType = {
  device: Device | null;
  outgoingCall: CallType | null;
  incomingCall: CallType | null;
  callStatus: CallStatusTypes | null;
};

export type CallType = {
  call?: Call | null;
  recording?: boolean;
  recording_id?: string | null;
};

export enum VoIPActionTypes {
  ADD_DEVICE = 'ADD_DEVICE',
  DESTROY_DEVICE = 'GET_CONTACTS',
  ADD_OUTGOING_CALL = 'ADD_OUTGOING_CALL',
  DESTROY_OUTGOING_CALL = 'DESTROY_OUTGOING_CALL',
  ADD_INCOMING_CALL = 'ADD_INCOMING_CALL',
  DESTROY_INCOMING_CALL = 'DESTROY_INCOMING_CALL',
  ADD_CALL_STATUS = 'ADD_CALL_STATUS',
  START_INCOMING_CALL_RECORDING = 'START_INCOMING_CALL_RECORDING',
  STOP_INCOMING_CALL_RECORDING = 'STOP_INCOMING_CALL_RECORDING',
  START_OUTGOING_CALL_RECORDING = 'START_OUTGOING_CALL_RECORDING',
  STOP_OUTGOING_CALL_RECORDING = 'STOP_OUTGOING_CALL_RECORDING',
}

export type VoIPActions =
  | {
      type: VoIPActionTypes.ADD_DEVICE;
      payload: Device;
    }
  | {
      type: VoIPActionTypes.DESTROY_DEVICE;
    }
  | {
      type: VoIPActionTypes.ADD_OUTGOING_CALL;
      payload: Call;
    }
  | {
      type: VoIPActionTypes.DESTROY_OUTGOING_CALL;
    }
  | {
      type: VoIPActionTypes.ADD_INCOMING_CALL;
      payload: Call;
    }
  | {
      type: VoIPActionTypes.DESTROY_INCOMING_CALL;
    }
  | {
      type: VoIPActionTypes.ADD_CALL_STATUS;
      payload: CallStatusTypes | null;
    }
  | {
      type: VoIPActionTypes.START_INCOMING_CALL_RECORDING;
      payload: { recording_id: string };
    }
  | {
      type: VoIPActionTypes.STOP_INCOMING_CALL_RECORDING;
    }
  | {
      type: VoIPActionTypes.START_OUTGOING_CALL_RECORDING;
      payload: { recording_id: string };
    }
  | {
      type: VoIPActionTypes.STOP_OUTGOING_CALL_RECORDING;
    };

export enum CallTypes {
  INCOMING = 'incoming',
  OUTGOING = 'outgoing',
}
