export function initials(name: any) {
  if (name != null) {
    const rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu');
    const letters = [...name.matchAll(rgx)] || [];
    const initials = (
      (letters.shift()?.[1] || '') + (letters.pop()?.[1] || '')
    ).toUpperCase();
    return initials;
  } else {
    return '';
  }
}
