/* eslint-disable @typescript-eslint/no-explicit-any */
import DromoUploader from 'dromo-uploader-react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import React from 'react';

import { useAuth } from '@/auth/context/AuthProvider';
import { ContactParams } from '@/shared/types/contacts/uploads';
import { DROMO_API_KEY } from '@/shared/utils/config';
import {
  dromo_contact_fields,
  dromo_contact_row_hooks,
  dromo_contact_step_hooks,
  dromo_settings_small,
  SMALL_UPLOAD_LIMIT,
} from '@/shared/utils/dromo';

import { formatContactName } from '../utils';
import { useUploads } from './context/UploadContext';

export type ImportedDataToAudienceObject = {
  /*
   * The ID of the upload that was created
   */
  id: string;
  /*
   * The name of the upload that was created
   */
  name: string;
  /*
   * The status of the upload that was created
   */
  import_status?: string | null;
};

type Props = {
  /** parent content in child */
  children?: React.ReactNode;
  /** adds the pasted upload to audience */
  addImportedDataToAudience?: (object: ImportedDataToAudienceObject) => void;
  onSuccess?: (id: string) => void;
};

export const BulkImport: React.FC<Props> = (props) => {
  const { children } = props;
  const auth = useAuth();

  const uploadsContext = useUploads();
  const { smallUploadContacts, bulkContactsOptInImport } = uploadsContext;

  const { maxUploadLength, minAsyncUploadLength } = useFlags();

  const settings = {
    importIdentifier: 'Contacts',
    maxRecords: maxUploadLength || SMALL_UPLOAD_LIMIT,
    version: 'v2',
    ...dromo_settings_small,
  };

  const user = {
    id: `${auth?.tokens?.user_id}`,
    name: auth?.tokens?.name || '',
    email: auth?.tokens?.email || '',
    companyId: auth.organizationId || '',
    companyName: auth.organizationName || '',
  };

  const stepHooks = dromo_contact_step_hooks;

  const rowHooks = dromo_contact_row_hooks;

  const onResults = async (data: ContactParams[]) => {
    // filter or format names that are just spaces
    const dataWithFormattedNames = data.map((contact: ContactParams) => {
      return {
        ...contact,
        name: formatContactName(contact?.name || ''),
      };
    });

    // if contacts are less than 600, use the small upload endpoint
    // otherwise use the bulk upload endpoint (via import)
    const results =
      dataWithFormattedNames.length <= (minAsyncUploadLength || 600)
        ? await smallUploadContacts(dataWithFormattedNames)
        : await bulkContactsOptInImport(dataWithFormattedNames);
    // if were uploading contacts to a campaign, add them to the campaign audience
    if (props.addImportedDataToAudience !== undefined) {
      props.addImportedDataToAudience({
        id: results?.id,
        name: results?.name,
        import_status: results?.import_status,
      });
    }
    if (props.onSuccess) {
      props.onSuccess(results.id);
    }
  };

  return (
    <DromoUploader
      licenseKey={DROMO_API_KEY}
      settings={settings}
      fields={dromo_contact_fields}
      user={user}
      rowHooks={rowHooks}
      stepHooks={stepHooks}
      onResults={(data) => onResults(data)}
    >
      {children}
    </DromoUploader>
  );
};
