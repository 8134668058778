import { DraftMessage } from '@/shared/types';
import { removeDuplicates } from '@/shared/utils/removeDuplicates';

import {
  CREATE_DRAFT_MESSAGE,
  DELETE_DRAFT_MESSAGE,
  GET_PART_OF_DRAFT_MESSAGES,
  SET_CURRENT,
  SET_LOADING,
} from './types';

type DraftMessagesAction =
  | { type: 'GET_PART_OF_DRAFT_MESSAGES'; payload: any }
  | { type: 'CREATE_DRAFT_MESSAGE'; payload: any }
  | { type: 'DELETE_DRAFT_MESSAGE'; payload: any }
  | { type: 'SET_CURRENT'; payload: any }
  | { type: 'SET_LOADING'; payload: boolean };

const DraftMessagesReducer = (state: any, action: DraftMessagesAction) => {
  switch (action.type) {
    case GET_PART_OF_DRAFT_MESSAGES:
      return {
        ...state,
        draftMessages: removeDuplicates(action.payload),
        loading: false,
      };
    case CREATE_DRAFT_MESSAGE:
      return {
        ...state,
        // check if draft with the same id exists, if it does replace it, if not just add it to the array
        draftMessages: state.draftMessages.find(
          (draftMessage: DraftMessage) =>
            draftMessage.conversation_id === action.payload.conversation_id &&
            draftMessage.user_id === action.payload.user_id
        )
          ? state.draftMessages.map((draftMessage: DraftMessage) => {
              return draftMessage.conversation_id === action.payload.conversation_id &&
                draftMessage.user_id === action.payload.user_id
                ? action.payload
                : draftMessage;
            })
          : removeDuplicates([...state.draftMessages, action.payload]),
      };
    case DELETE_DRAFT_MESSAGE:
      return {
        ...state,
        draftMessages: state.draftMessages.filter(
          (draftMessage: DraftMessage) => draftMessage.id !== action.payload
        ),
      };
    case SET_CURRENT:
      return {
        ...state,
        current: action.payload,
      };
    case SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
};

export default DraftMessagesReducer;
