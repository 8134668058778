/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { HiCog, HiDuplicate } from 'react-icons/hi';
import { useHistory, useParams } from 'react-router-dom';

import { ToolTipIconButton } from '@/shared/components/attachments/previewer';
import { useDisclosure } from '@/shared/hooks';
import { PageLayout } from '@/shared/layouts/PageLayout';
import { Sequence } from '@/shared/types/sequences';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogOverlay,
  AlertDialogPortal,
  AlertDialogTitle,
  AlertDialogTrigger,
  Box,
  Button,
  Flex,
  HStack,
} from '@/shared/ui';
import { styled } from '@/stitches.config';

import { useSequences } from '../context/SequenceContext';
import { SequenceEditor } from '../sequence/SequenceEditor';

type SequenceContainerProps = {
  children: React.ReactNode;
};

export const SequenceTemplateContainer = ({ children }: SequenceContainerProps) => {
  const sequenceContext = useSequences();
  const { sequencesState, getSequence, setCurrentSequence } = sequenceContext;
  const { current } = sequencesState;

  // get the sequence id from the url
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    // if the current sequence is not set in state, get it from the api
    getSequence(id);

    // on unmount, set the current sequence to null
    return () => {
      setCurrentSequence(null);
    };
  }, []);

  return (
    <SequencePageLayout current={current} tabTitle={current?.title}>
      <Box css={{ height: '100%' }}>{children}</Box>
    </SequencePageLayout>
  );
};

// This is the wrapper for a sequence page,
// it generated the breadcrumbs from the current sequence
const SequencePageLayout = ({
  children,
  current,
  tabTitle,
}: {
  children: React.ReactNode;
  current?: Sequence | null;
  tabTitle?: string;
}) => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();

  const { duplicateSequence } = useSequences();

  const handleDuplicate = async () => {
    if (current?.id) {
      const sequence = await duplicateSequence(current.id);
      if (sequence) {
        history.push(`/sequences`);
      }
    }
  };

  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <PageLayout
      breadcrumbs={[
        { title: 'Sequences', path: '/sequences' },
        {
          title: 'My Templates',
          path: `/sequences/templates/`,
        },
        {
          title: tabTitle || '',
          path: `/sequences/templates/${current?.id}`,
        },
      ]}
      actions={
        <HStack gap={2} css={{ mt: 3 }}>
          <AlertDialog open={isOpen}>
            <AlertDialogTrigger asChild>
              <ToolTipIconButton
                variant="outline"
                description="Duplicate Sequence Template"
                icon={<HiDuplicate />}
                onClick={onOpen}
                dataTestID="duplicate-sequence-template-button"
              />
            </AlertDialogTrigger>
            <AlertDialogPortal>
              <AlertDialogOverlay>
                <AlertDialogContent onEscapeKeyDown={onClose}>
                  <AlertDialogTitle>Duplicate Sequence</AlertDialogTitle>
                  <AlertDialogDescription>
                    Are you sure you want to duplicate this sequence?
                  </AlertDialogDescription>
                  <Flex justify="end">
                    <AlertDialogCancel asChild>
                      <Button variant="gray" css={{ mr: 10 }} onClick={onClose}>
                        No
                      </Button>
                    </AlertDialogCancel>
                    <AlertDialogAction asChild>
                      <Button variant="red" onClick={handleDuplicate}>
                        Yes, duplicate
                      </Button>
                    </AlertDialogAction>
                  </Flex>
                </AlertDialogContent>
              </AlertDialogOverlay>
            </AlertDialogPortal>
          </AlertDialog>
          {current?.id && (
            <SequenceEditor sequence={current} isTemplateEditor={true}>
              <ToolTipIconButton
                variant="outline"
                description="Edit Sequence Template Settings"
                icon={<HiCog />}
                dataTestID="edit-sequence-template-button"
              />
            </SequenceEditor>
          )}
        </HStack>
      }
    >
      <TabsContainer align="center">
        <HStack>
          <SequenceSubNavigationItem
            selected={history.location.pathname.includes('steps')}
            onClick={() => history.push(`/sequences/templates/${id}/steps`)}
          >
            Steps
          </SequenceSubNavigationItem>
        </HStack>
      </TabsContainer>
      <StepsContainer direction="column">{children}</StepsContainer>
    </PageLayout>
  );
};

export const SequenceSubNavigationItem = styled(Flex, {
  px: 10,
  py: 8,
  borderRadius: 4,
  fontWeight: 500,
  alignContent: 'center',
  cursor: 'pointer',
  fontSize: 13,
  position: 'relative',
  '&:hover': {
    backgroundColor: '$slate3',
  },
  variants: {
    selected: {
      true: {
        backgroundColor: '$slate3',
      },
    },
  },
});

const TabsContainer = styled(Flex, {
  width: '100%',
  height: 60,
  px: 20,
  borderBottom: 'thin solid $gray4',
  backgroundColor: 'white',
});

const StepsContainer = styled(Flex, {
  position: 'relative',
  height: '100%',
  flex: 1,
  overflow: 'auto',
  backgroundColor: '#fafafa',
});
