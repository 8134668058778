/* eslint-disable no-unused-vars */
import React from 'react';
import { createContext, useContext, useReducer } from 'react';
import { toast } from 'sonner';

import * as KeywordsAPI from '@/shared/api/automations/keywords';
import { Keyword } from '@/shared/types/keywords';
import { KeywordActionTypes } from '@/shared/types/keywords';
import i18next from '@/shared/utils/translation';

import KeywordsReducer from './KeywordsReducer';

export type KeywordState = {
  keywords: Array<Keyword>;
  totalCount: number;
  loading: boolean;
  current: Keyword | null;
  error: any;
};

export const initialKeywordsState: KeywordState = {
  keywords: [],
  totalCount: 0,
  loading: true,
  current: null,
  error: null,
};

export const KeywordsContext = createContext<{
  keywordsState: KeywordState;
  getKeywords: (params: { offset: number; limit: number }) => Promise<Keyword[] | null[]>;
  getKeyword: (id: string) => Promise<Keyword | null>;
  searchKeywords: (name: string) => Promise<Keyword[] | null[]>;
  createKeyword: (keyword: Keyword) => Promise<Keyword>;
  updateKeyword: (id: string, keyword: Keyword) => Promise<Keyword>;
  deleteKeyword: (id: string) => void;
  setCurrentKeyword: (keyword: Keyword | null) => void;
  setLoading: (loading: boolean) => void;
}>({
  keywordsState: initialKeywordsState,
  getKeywords: () => Promise.resolve([] as Keyword[]),
  getKeyword: () => Promise.resolve({} as Keyword),
  searchKeywords: () => Promise.resolve([] as Keyword[]),
  createKeyword: () => Promise.resolve({} as Keyword),
  updateKeyword: () => Promise.resolve({} as Keyword),
  deleteKeyword: () => Promise.resolve(),
  setCurrentKeyword: () => Promise.resolve(),
  setLoading: () => Promise.resolve(),
});

export const useKeywords = () => useContext(KeywordsContext);

const KeywordsState = ({ children }: { children: React.ReactNode }) => {
  const [keywordsState, dispatch] = useReducer(KeywordsReducer, initialKeywordsState);

  // we fetch keywords in batches of limit number, starting from offset
  const getKeywords = async (params: {
    offset: number;
    limit: number;
  }): Promise<Keyword[] | null[]> => {
    try {
      const data = await KeywordsAPI.getKeywords(params);

      dispatch({
        type: KeywordActionTypes.GET_KEYWORDS,
        payload: data,
      });

      return data;
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  };

  const getKeyword = async (id: string): Promise<Keyword | null> => {
    try {
      const data = await KeywordsAPI.getKeyword(id);

      dispatch({
        type: KeywordActionTypes.SET_CURRENT,
        payload: data,
      });

      return data;
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  };

  const searchKeywords = async (name: string): Promise<Keyword[] | null[]> => {
    try {
      const data = await KeywordsAPI.searchKeyword(name);

      dispatch({
        type: KeywordActionTypes.GET_KEYWORDS,
        payload: data,
      });

      return data;
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  };

  const createKeyword = async (keyword: Keyword): Promise<Keyword> => {
    try {
      const data = await KeywordsAPI.createKeyword(keyword);

      dispatch({
        type: KeywordActionTypes.CREATE_KEYWORD,
        payload: data,
      });

      toast.success(i18next.t('keyword_created_success') as string);

      return data;
    } catch (err) {
      toast.error(i18next.t('keyword_created_failure') as string);

      console.error(err);

      return Promise.reject(err);
    }
  };

  const updateKeyword = async (id: string, keyword: Keyword): Promise<Keyword> => {
    try {
      const data = await KeywordsAPI.updateKeyword(id, keyword);

      dispatch({
        type: KeywordActionTypes.UPDATE_KEYWORD,
        payload: data,
      });

      toast.success(i18next.t('keyword_updated_success') as string);

      return data;
    } catch (err) {
      toast.error(i18next.t('keyword_updated_failure') as string);

      console.error(err);

      return Promise.reject(err);
    }
  };

  const deleteKeyword = async (id: string) => {
    try {
      await KeywordsAPI.deleteKeyword(id);

      dispatch({
        type: KeywordActionTypes.DELETE_KEYWORD,
        payload: id,
      });

      toast.success(i18next.t('keyword_deleted_success') as string);
    } catch (err) {
      toast.error(i18next.t('keyword_deleted_failure') as string);
      console.error(err);
    }
  };

  const setCurrentKeyword = (keyword: Keyword | null) => {
    try {
      dispatch({
        type: KeywordActionTypes.SET_CURRENT,
        payload: keyword,
      });
    } catch (err) {
      console.error(err);
    }
  };

  const setLoading = (loading: boolean) => {
    try {
      dispatch({
        type: KeywordActionTypes.SET_LOADING,
        payload: loading,
      });
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <KeywordsContext.Provider
      value={{
        keywordsState,
        getKeywords,
        getKeyword,
        createKeyword,
        updateKeyword,
        deleteKeyword,
        searchKeywords,
        setCurrentKeyword,
        setLoading,
      }}
    >
      {children}
    </KeywordsContext.Provider>
  );
};

export default KeywordsState;
