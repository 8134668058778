import { useMedia } from 'react-use';

import { Box, Button, HStack } from '@/shared/ui';
import { styled } from '@/stitches.config';

import { BulkAction } from '../FiltersTable';

type BulkActionButtonProp = {
  bulkAction: BulkAction;
  handleClick: () => void;
};

export const BulkActionButton = ({ bulkAction, handleClick }: BulkActionButtonProp) => {
  const isLarge = useMedia('(min-width: 1200px)');

  return (
    <HStack
      css={{
        cursor: 'pointer',
        color: 'white',
        paddingLeft: isLarge ? '0px' : '0px',
        paddingRight: isLarge ? '0px' : '0px',
        marginLeft: '0px',
        borderColor: 'white',
      }}
      onClick={handleClick}
    >
      {bulkAction.icon && (
        <BulkActionIcon css={{ paddingLeft: '0px' }}>{bulkAction.icon}</BulkActionIcon>
      )}
      <Button
        variant="primary"
        size="2"
        css={{
          color: 'White',
          paddingLeft: isLarge ? '12px' : '8px',
          paddingRight: isLarge ? '12px' : '8px',
          backgroundColor:
            bulkAction.type == 'destructive' ? 'rgb(217, 61, 66)' : '#3E63DD',
          boxShadow: 'none',
          '&:focus': {
            boxShadow: 'none',
          },
          '&:hover': {
            boxShadow: 'none',
          },
          '&:active': {
            boxShadow: 'none',
          },
        }}
      >
        {' '}
        {bulkAction.label}
      </Button>
    </HStack>
  );
};

const BulkActionIcon = styled(Box, {
  cursor: 'pointer',
  padding: 5,
  fontSize: 16,
});
