import { ErrorBoundary, Scope } from '@sentry/react';
import React from 'react';
import { Helmet } from 'react-helmet-async';

import { usePageView } from '@/shared/hooks';
import { PageLayout } from '@/shared/layouts/PageLayout';
import { Box, Button, Flex, HStack, VStack } from '@/shared/ui';

import { KeywordForm } from './CreateKeywordForm';
import { KeywordDialog } from './KeywordDialog';
import { KeywordsTable } from './KeywordsTable';

export const Keywords = () => {
  usePageView();

  return (
    <>
      <Helmet>
        <title>Whippy | Keywords</title>
      </Helmet>
      <PageLayout
        breadcrumbs={[
          { title: 'Automations', path: '/automations/keywords' },
          { title: 'Keywords', path: '/automations/keywords' },
        ]}
        actions={
          <HStack gap={2}>
            <KeywordDialog trigger={<Button>Create Keyword</Button>}>
              <KeywordForm />
            </KeywordDialog>
          </HStack>
        }
      >
        <KeywordsErrorBoundary>
          <Box css={{ p: 30 }}>
            <KeywordsTable />
          </Box>
        </KeywordsErrorBoundary>
      </PageLayout>
    </>
  );
};

export const KeywordsErrorBoundary = ({ children }: { children: React.ReactNode }) => {
  if (!children) return null;

  const errorTag = `keywords_table`;

  return (
    <ErrorBoundary
      fallback={<ErrorFallback />}
      beforeCapture={(scope: Scope) => {
        scope.setTag('ErrorLocation', errorTag);
      }}
      showDialog={false}
    >
      {children}
    </ErrorBoundary>
  );
};

const ErrorFallback = () => {
  return (
    <Flex
      direction="column"
      align="center"
      justify="center"
      css={{
        width: '100%',
        height: '100%',
      }}
    >
      <VStack align={'center'} gap={3}>
        <Box
          css={{
            fontWeight: 'bold',
          }}
        >
          Oops something went wrong ;
        </Box>
        <Box>Please refresh, if the issue persists contact support.</Box>
        <Box>
          <Button variant="gray" onClick={() => window.location.reload()}>
            Refresh
          </Button>
        </Box>
      </VStack>
    </Flex>
  );
};
