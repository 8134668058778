import React from 'react';
import { useEffect, useState } from 'react';

import { Flex } from '@/shared/ui';

export const Timer = ({ start }: { start: Date }) => {
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  const getTime = () => {
    const time = Date.now() - start.getTime();

    setDays(Math.floor(time / (1000 * 60 * 60 * 24)));
    setHours(Math.floor((time / (1000 * 60 * 60)) % 24));
    setMinutes(Math.floor((time / 1000 / 60) % 60));
    setSeconds(Math.floor((time / 1000) % 60));
  };

  useEffect(() => {
    const interval = setInterval(() => getTime(), 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <Flex>
      {days > 0 && <div>{days}:</div>}
      {hours > 0 && <div>{hours}:</div>}
      <div>{minutes}:</div>
      <div>{seconds < 10 ? '0' + seconds : seconds}</div>
    </Flex>
  );
};
