import React from 'react';
import { createContext, useContext, useReducer } from 'react';
import { toast } from 'sonner';

import {
  deleteScheduledMessage,
  editScheduledMessage,
  getFilteredScheduledMessages,
  GetFilteredScheduledMessagesParams,
  getScheduledMessages,
  sendScheduledMessage,
} from '@/shared/api/conversations';
import { ConversationScheduledMessageType } from '@/shared/types/conversations';
import i18next from '@/shared/utils/translation';

import ScheduledMessagesReducer from './ScheduledMessagesReducer';
import {
  DELETE_SCHEDULED_MESSAGE,
  EDIT_SCHEDULED_MESSAGE,
  GET_PART_OF_SCHEDULED_MESSAGES,
  GET_SCHEDULED_MESSAGES,
  SEND_SCHEDULED_MESSAGE_NOW,
  SET_CURRENT,
} from './types';

export type ScheduledMessagesState = {
  scheduledMessages: Array<ConversationScheduledMessageType>;
  loading: boolean;
  current: ConversationScheduledMessageType | null | undefined;
  error: Error | null;
};

export const initialScheduledMessagesState: ScheduledMessagesState = {
  scheduledMessages: [],
  current: null,
  error: null,
  loading: true,
};

export const ScheduledMessagesContext = createContext<{
  scheduledMessagesState: ScheduledMessagesState;
  getAllScheduledMessages: () => void;
  getPartOfScheduledMessages: (params: GetFilteredScheduledMessagesParams) => void;
  editOneScheduledMessage: (
    id: number,
    scheduledMessage: any,
    conversationId: string
  ) => void;
  sendScheduledMessageNow: (id: number) => void;
  deleteOneScheduledMessage: (id: number) => void;
  setCurrentScheduledMessage: (
    scheduledMessage: ConversationScheduledMessageType | null | undefined
  ) => void;
}>({
  scheduledMessagesState: initialScheduledMessagesState,
  getAllScheduledMessages: () => Promise.resolve(),
  getPartOfScheduledMessages: () => Promise.resolve(),
  editOneScheduledMessage: () => Promise.resolve(),
  sendScheduledMessageNow: () => Promise.resolve(),
  deleteOneScheduledMessage: () => Promise.resolve(),
  setCurrentScheduledMessage: () => Promise.resolve(),
});

export const useScheduledMessages = () => useContext(ScheduledMessagesContext);

const ScheduledMessageState = ({ children }: { children: React.ReactNode }) => {
  const [scheduledMessagesState, dispatch] = useReducer(
    ScheduledMessagesReducer,
    initialScheduledMessagesState
  );

  const getAllScheduledMessages = async () => {
    try {
      const data = await getScheduledMessages();

      dispatch({
        type: GET_SCHEDULED_MESSAGES,
        payload: data,
      });
    } catch (err) {
      console.error(err);
    }
  };

  const getPartOfScheduledMessages = async (
    params: GetFilteredScheduledMessagesParams
  ) => {
    try {
      const data = await getFilteredScheduledMessages(params);

      dispatch({
        type: GET_PART_OF_SCHEDULED_MESSAGES,
        payload: data,
      });
    } catch (err) {
      console.error(err);
    }
  };

  const deleteOneScheduledMessage = async (id: number) => {
    try {
      await deleteScheduledMessage(id);

      dispatch({
        type: DELETE_SCHEDULED_MESSAGE,
        payload: id,
      });
      toast.success(i18next.t('scheduled_message_deleted_success') as string);
    } catch (err) {
      toast.success(i18next.t('scheduled_message_deleted_failure') as string);
      console.error(err);
    }
  };

  const editOneScheduledMessage = async (
    id: number,
    scheduledMessage: any,
    conversationId: string
  ) => {
    try {
      const data = await editScheduledMessage(id, scheduledMessage, conversationId);

      dispatch({
        type: EDIT_SCHEDULED_MESSAGE,
        payload: data,
      });

      toast.success(i18next.t('scheduled_message_updated_success') as string);
    } catch (err) {
      toast.success(i18next.t('scheduled_message_updated_failure') as string);

      console.error(err);
    }
  };

  const sendScheduledMessageNow = async (id: number) => {
    try {
      await sendScheduledMessage(id);

      dispatch({
        type: SEND_SCHEDULED_MESSAGE_NOW,
        payload: id,
      });

      toast.success(i18next.t('scheduled_message_sent_success') as string);
    } catch (err) {
      toast.success(i18next.t('scheduled_message_sent_failure') as string);

      console.error(err);
    }
  };

  const setCurrentScheduledMessage = (
    scheduledMessage: ConversationScheduledMessageType | null | undefined
  ) => {
    try {
      dispatch({
        type: SET_CURRENT,
        payload: scheduledMessage,
      });
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <ScheduledMessagesContext.Provider
      value={{
        scheduledMessagesState,
        getAllScheduledMessages,
        getPartOfScheduledMessages,
        editOneScheduledMessage,
        sendScheduledMessageNow,
        deleteOneScheduledMessage,
        setCurrentScheduledMessage,
      }}
    >
      {children}
    </ScheduledMessagesContext.Provider>
  );
};

export default ScheduledMessageState;
