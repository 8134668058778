/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { HiChevronDown, HiChevronRight } from 'react-icons/hi';
import { Link, useHistory, useLocation } from 'react-router-dom';

import { useContacts } from '@/pages/contacts/context/ContactContext';
import { useGroups } from '@/pages/contacts/groups/context/GroupContext';
import { useUploads } from '@/pages/contacts/uploads/context/UploadContext';
import {
  initialCustomDataState,
  useCustomData,
} from '@/pages/settings/organization/data/context/CustomDataContext';
import { CircleIcon } from '@/shared/components/Icons';
import {
  SideNavigationHeading,
  SideNavigationItem,
} from '@/shared/components/navigation/SideNavigationItem';
import { Group } from '@/shared/types/contacts/groups';
import { Tag } from '@/shared/types/tags';
import {
  Box,
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
  Flex,
  IconButton,
  Text,
  VStack,
} from '@/shared/ui';

export function DataSideNavigation(): JSX.Element {
  const { uploadsState, getUploads, setCurrentUpload } = useUploads();
  const { recentUploads: lists } = uploadsState;
  const previewLists = lists.slice(0, 5);

  const groupContext = useGroups();
  const { getGroups, setCurrentGroup, groupsState } = groupContext;

  const contactContext = useContacts();
  const { setAllContactsChecked } = contactContext;

  const { recentGroups: segments } = groupsState;
  const previewSegments = segments
    .filter((group) => group.filters_version === 'v2')
    .slice(0, 5);

  const history = useHistory();
  const location = useLocation();

  const custom = useCustomData();
  const { customDataState, getCustomObjects } = custom;
  const { customObjects } = customDataState;

  useEffect(() => {
    getGroups({ offset: 0, limit: 20 }, true);
    getUploads({ offset: 0, limit: 20 }, true);
  }, []);

  // Fetch custom objects if not already fetched
  useEffect(() => {
    if (customObjects.length === 0) {
      getCustomObjects({ ...initialCustomDataState.filterParams, offset: 0, limit: 5 });
    }
  }, []);

  useEffect(() => {
    if (history.location.pathname.includes('/data/lists/')) {
      const uploadId = history.location.pathname.split('/')[3];
      const uploadRendered = lists.filter((list: Tag) => list.id === uploadId)[0];
      setCurrentUpload(uploadRendered);
    }
  }, [history.location.pathname, lists, segments]);

  const onNavigateToContacts = () => {
    setAllContactsChecked(false);
    setCurrentUpload(null);
    setCurrentGroup(null);
  };

  const onNavigateToGroup = () => {
    setCurrentUpload(null);
    setAllContactsChecked(false);
  };

  const onNavigateToUpload = (list: Tag) => {
    setCurrentUpload(list);
    setCurrentGroup(null);
    setAllContactsChecked(false);
  };

  const onNavigateToObject = () => {
    setCurrentGroup(null);
    setAllContactsChecked(false);
  };

  return (
    <Box css={{ pl: 8, pr: 8 }}>
      <Link to="/data/contacts">
        <Box onClick={() => onNavigateToContacts()} css={{ mb: 3 }}>
          <SideNavigationItem active={location.pathname === '/data/contacts'}>
            <CircleIcon color={'#6C6F75'} />
            <Text>All Contacts</Text>
          </SideNavigationItem>
        </Box>
      </Link>
      {customObjects.length > 0 && (
        <ContactCollapsibleNavigation title="Objects">
          <>
            {customObjects.map((object) => {
              return (
                <Link to={`/data/objects/${object.id}`} key={`sidebar-${object.id}`}>
                  <Box onClick={() => onNavigateToObject()} css={{ mb: 3 }}>
                    <SideNavigationItem
                      active={object.id === location.pathname.split('/')[3]}
                    >
                      <CircleIcon css={{ mt: 2 }} color="#333333" />
                      <Text variant="sidenavigation">{object.label}</Text>
                    </SideNavigationItem>
                  </Box>
                </Link>
              );
            })}
          </>
        </ContactCollapsibleNavigation>
      )}
      {segments.length > 0 && (
        <ContactCollapsibleNavigation title="Segments">
          <>
            <Link to="/data/segments">
              <Box onClick={() => onNavigateToContacts()} css={{ mb: 3 }}>
                <SideNavigationItem active={location.pathname === '/data/segments'}>
                  <CircleIcon color={'#6C6F75'} />
                  <Text>All Segments</Text>
                </SideNavigationItem>
              </Box>
            </Link>
            {previewSegments.map((segment: Group) => {
              return (
                <Link to={`/data/segments/${segment.id}`} key={`sidebar-${segment.id}`}>
                  <Box onClick={() => onNavigateToGroup()} css={{ mb: 3 }}>
                    <SideNavigationItem
                      active={segment.id === location.pathname.split('/')[3]}
                    >
                      <CircleIcon css={{ mt: 2 }} color={segment.color} />
                      <Text variant="sidenavigation">{segment.name}</Text>
                    </SideNavigationItem>
                  </Box>
                </Link>
              );
            })}
          </>
        </ContactCollapsibleNavigation>
      )}
      {lists.length > 0 && (
        <ContactCollapsibleNavigation title="Lists">
          <>
            <Link to="/data/lists">
              <Box onClick={() => onNavigateToContacts()} css={{ mb: 3 }}>
                <SideNavigationItem active={location.pathname === '/data/uploads'}>
                  <CircleIcon color={'#6C6F75'} />
                  <Text>All Lists</Text>
                </SideNavigationItem>
              </Box>
            </Link>
            {previewLists.map((list: Tag) => {
              return (
                <Link to={`/data/lists/${list.id}`} key={`sidebar-${list.id}`}>
                  <Box onClick={() => onNavigateToUpload(list)} css={{ mb: 3 }}>
                    <SideNavigationItem
                      active={list.id === location.pathname.split('/')[3]}
                    >
                      <CircleIcon css={{ mt: 2 }} color={list.color} />
                      <Text variant="sidenavigation">{list.name}</Text>
                    </SideNavigationItem>
                  </Box>
                </Link>
              );
            })}
          </>
        </ContactCollapsibleNavigation>
      )}
    </Box>
  );
}

type ContactCollapsibleNavigationProps = {
  children: JSX.Element;
  title: string;
};

export const ContactCollapsibleNavigation = (
  props: ContactCollapsibleNavigationProps
) => {
  const [open, setOpen] = useState(true);

  return (
    <Collapsible open={open} onOpenChange={setOpen}>
      <CollapsibleTrigger asChild>
        <Flex align="center">
          <IconButton>
            {open ? <HiChevronDown size={19} /> : <HiChevronRight size={19} />}
          </IconButton>
          <SideNavigationHeading
            align="center"
            size={1}
            css={{ cursor: 'pointer', mb: 2, mr: 5 }}
          >
            {props.title}
          </SideNavigationHeading>
        </Flex>
      </CollapsibleTrigger>
      <CollapsibleContent>
        <VStack gap={1}>{props.children}</VStack>
      </CollapsibleContent>
    </Collapsible>
  );
};
