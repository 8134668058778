import React from 'react';

import { Box, Flex } from '@/shared/ui';

import { Spinner, WhippyLogoXL } from './Icons';

export const Loading = () => {
  return (
    <Flex align="center" justify="center" css={{ height: '100vh' }}>
      <Spinner size={3} color="primary" />
      <Box css={{ position: 'absolute' }}>
        <WhippyLogoXL />
      </Box>
    </Flex>
  );
};
