import { Dispatch, SetStateAction } from 'react';

import { useSettings } from '@/pages/settings/organization/general/context/SettingsContext';
import { Text, Tooltip, TooltipContent, TooltipTrigger } from '@/shared/ui';
import { SegmentedMessage } from '@/shared/utils/segments';

type MessageSegmentPopoverProps = {
  message: string;
  setMessage?: Dispatch<SetStateAction<string>>;
  isNote?: boolean;
};

// This component shows the character count in the toolbar
// When the user hovers over the character count, it will show the credit count
// The button if we can save credits by removing non-GSM7 characters
export const MessageSegmentPopover = (props: MessageSegmentPopoverProps) => {
  const { message, isNote } = props;
  const segmentedMessage = new SegmentedMessage(message);
  const characterCount = segmentedMessage.numberOfCharacters;
  const segmentCount = segmentedMessage.segments.length;

  const nonGSM7Characters = hasNonGSM7Characters(message);

  const {
    settingsState: { settings },
  } = useSettings();

  const maxSegments = settings?.max_number_of_segments || 12;
  const maxCharacters = maxSegments * 160;
  const ninetyPercentOfMaxCharacters = maxCharacters * 0.9;

  // This function generates the description for the tooltip
  // It will show the number of credits the message will cost
  // If there are no characters, it will show 0 credits, even though all
  // SMS messages cost 1 credit, this is because we block messages with no characters
  const generateToolTipDescription = () => {
    if (isNote) {
      return '0 Credits';
    }

    return characterCount > 0 ? `${segmentCount} / ${maxSegments} Credits` : '0 Credits';
  };

  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <Text
          css={{
            color:
              !isNote &&
              (characterCount > ninetyPercentOfMaxCharacters || nonGSM7Characters)
                ? '$red10'
                : '$slate12',
            fontWeight:
              !isNote &&
              (characterCount > ninetyPercentOfMaxCharacters || nonGSM7Characters)
                ? 600
                : 400,
          }}
        >
          {characterCount} {!isNote ? `/ ${maxSegments * 160}` : ''}
        </Text>
      </TooltipTrigger>
      <TooltipContent>{generateToolTipDescription()}</TooltipContent>
    </Tooltip>
  );
};

export const returnSegmentLength = (message: string) => {
  const segmentedMessage = new SegmentedMessage(message);
  return segmentedMessage.segments.length;
};

// This function removes all non-GSM7 characters from the message
// and returns the message with only GSM7 characters
export const removeNonGSM7Characters = (message: string): string => {
  const segmentedMessage = new SegmentedMessage(message);
  const filteredChars = segmentedMessage.encodedChars
    .filter((char) => char.isGSM7)
    .map((char) => char.raw);
  return filteredChars.join('');
};

// This function checks if the message has any non-GSM7 characters
export const hasNonGSM7Characters = (message: string): boolean => {
  const segmentedMessage = new SegmentedMessage(message);
  return segmentedMessage.encodedChars.some((char) => !char.isGSM7);
};

// This component visualizes the message with non-GSM7 characters
// This is so the user can see which characters are non-GSM7 and that will
// Cost them more credits
export const MessageSegmentVisualizer = ({ message }: { message: string }) => {
  const segmentedMessage = new SegmentedMessage(message);

  return (
    <div>
      {segmentedMessage.encodedChars.map((char, index) => {
        switch (char.isGSM7) {
          case true:
            return (
              <span
                style={{
                  color: 'gray',
                }}
                key={index}
              >
                {char.raw}
              </span>
            );
          case false:
            return (
              <span
                style={{
                  color: 'red',
                  border: '1px solid red',
                }}
                key={index}
              >
                {char.raw}
              </span>
            );
          default:
            return null;
        }
      })}
    </div>
  );
};
