import * as SelectPrimitive from '@radix-ui/react-select';
import { FieldArray, Formik } from 'formik';
import React, { useEffect, useMemo, useState } from 'react';
import { HiChevronDown, HiChevronUp, HiPlus, HiX } from 'react-icons/hi';
import * as Yup from 'yup';

import { prepareFilters } from '@/pages/data/utils/prepareFilters';
import { getCustomObjects } from '@/shared/api/data';
import {
  CheckboxFormFieldWrapper,
  CheckboxInput,
  FormFieldWrapper,
  TextInput,
} from '@/shared/components/forms';
import { useDisclosure } from '@/shared/hooks';
import {
  Box,
  Button,
  Dialog,
  DialogClose,
  DialogCloseIcon,
  DialogContent,
  DialogFooter,
  DialogOverlay,
  DialogPortal,
  DialogTitle,
  DialogTrigger,
  HStack,
  Select,
  SelectContent,
  SelectGroup,
  SelectIcon,
  SelectItem,
  SelectItemIndicator,
  SelectItemText,
  SelectLabel,
  SelectScrollUpButton,
  SelectTrigger,
  SelectValue,
  SelectViewport,
} from '@/shared/ui';
import { generateKey } from '@/shared/utils/generateKey/generateKey';
import { styled } from '@/stitches.config';

import {
  CustomObject,
  CustomProperty,
  PropertyFormType,
  PropertyType,
  ReferencePropertyType,
} from '../../../../../shared/types/data';
import { CustomDropdownMenuItem } from '../../users/UsersTable';
import { propertyType } from '../constants';
import { initialCustomDataState, useCustomData } from '../context/CustomDataContext';
import { ReferenceProperty } from './ReferenceProperty';

const ITEMS_COUNT = 20;

export const AddProperty = ({
  currentObjectId,
  data,
}: {
  currentObjectId: string;
  data?: CustomProperty;
}) => {
  const {
    createCustomProperty,
    updateCustomProperty,
    customDataState: { current },
  } = useCustomData();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [type, setType] = useState(data?.type ?? propertyType[0].value);
  const [customObjects, setCustomObjects] = useState<Array<CustomObject>>([]);
  const [deletedItems, setDeletedItems] = useState<Array<ReferencePropertyType>>([]);

  const getObjects = async (offset: number) => {
    const filters = prepareFilters({
      ...initialCustomDataState.filterParams,
      offset,
      limit: ITEMS_COUNT,
    });
    const data = await getCustomObjects(filters);
    setCustomObjects(offset ? [...customObjects, ...data.data] : data.data);
    if (offset + data.data.length > data.total) {
      getObjects(offset + ITEMS_COUNT);
    }
  };

  useEffect(() => {
    getObjects(0);
  }, []);

  // handle create property
  const handleSubmit = async (values: PropertyFormType, actions: any) => {
    try {
      const params = {
        key: generateKey(values.label),
        label: values.label,
        type: type,
        default: values.default,
        required: values.required,
        references: data
          ? [
              ...values.references.map((reference) => {
                return { ...reference, delete: null, id: reference.id ?? null };
              }),
              ...deletedItems,
            ]
          : values.references,
      };
      if (data) {
        current?.id && (await updateCustomProperty(current?.id, data.id, params));
      } else {
        current?.id && (await createCustomProperty(current?.id, params));
      }
      actions.resetForm({
        values: {
          label: '',
          default: '',
          required: false,
          references: [],
        },
      });
      onClose();
    } catch (e) {
      console.log(e);
    }
  };

  const initialValue: PropertyFormType = {
    label: data?.label ?? '',
    type: data?.type ?? propertyType[0].value,
    default: data?.default ?? '',
    required: data?.required ?? false,
    references: data?.references ?? [],
  };

  const currentIconType = useMemo(() => {
    const item = propertyType.find((p) => p.value === type);
    return item?.icon;
  }, [type]);

  return (
    <Dialog open={isOpen} onOpenChange={() => !isOpen}>
      <DialogTrigger asChild>
        {data ? (
          <CustomDropdownMenuItem
            data-testid="edit-option"
            onClick={(e) => {
              e.preventDefault();
              onOpen();
            }}
          >
            Edit Property
          </CustomDropdownMenuItem>
        ) : (
          <Button onClick={onOpen} data-intercom-target="add-property-button">
            Add New Property
          </Button>
        )}
      </DialogTrigger>
      <DialogPortal>
        <DialogOverlay as="div" css={{ zIndex: 999999 }}>
          <DialogContent
            css={{ maxHeight: '90vh', overflow: 'auto', zIndex: 9999999 }}
            onEscapeKeyDown={onClose}
            onPointerDownOutside={onClose}
          >
            <DialogTitle variant="bold">
              {data ? 'Edit Custom Property' : 'Create New Property'}
            </DialogTitle>
            <Formik
              initialValues={initialValue}
              validationSchema={Yup.object({
                label: Yup.string().test(
                  'len',
                  'Must be at least 3 characters',
                  (val) => val !== undefined && val.length > 2
                ),
                references: Yup.array().of(
                  Yup.object().shape({
                    custom_object_id: Yup.string().required('Required'),
                    custom_property_id: Yup.string().required('Required'),
                    type: Yup.string().required('Required'),
                  })
                ),
              })}
              onSubmit={handleSubmit}
            >
              {(formik) => (
                <form onSubmit={formik.handleSubmit} data-testid="create-property-form">
                  <FormFieldWrapper label="Property Name" name="label">
                    <TextInput placeholder="Enter name" />
                  </FormFieldWrapper>
                  <FormFieldWrapper label="Type" name="type">
                    <Select value={type} onValueChange={(e: PropertyType) => setType(e)}>
                      <SelectTrigger aria-label="type-option-select-trigger">
                        <HStack>
                          <SelectIcon>{currentIconType}</SelectIcon>
                          <SelectValue />
                        </HStack>
                        <SelectIcon>
                          <HiChevronDown />
                        </SelectIcon>
                      </SelectTrigger>
                      <SelectPrimitive.Portal>
                        <SelectContent
                          css={{ zIndex: 9999999, width: 450 }}
                          position="popper"
                        >
                          <SelectScrollUpButton>
                            <HiChevronUp />
                          </SelectScrollUpButton>
                          <SelectViewport>
                            <SelectGroup>
                              <SelectLabel>Type</SelectLabel>
                              {propertyType.map((option) => (
                                <SelectItem key={option.value} value={option.value}>
                                  <SelectIconContainer>{option.icon}</SelectIconContainer>
                                  <SelectItemText style={{ marginLeft: '5px' }}>
                                    {option.label}
                                  </SelectItemText>
                                  <SelectItemIndicator />
                                </SelectItem>
                              ))}
                            </SelectGroup>
                          </SelectViewport>
                        </SelectContent>
                      </SelectPrimitive.Portal>
                    </Select>
                  </FormFieldWrapper>
                  <FormFieldWrapper label="Default Value" name="default">
                    <TextInput placeholder="Enter default value" />
                  </FormFieldWrapper>
                  <CheckboxFormFieldWrapper
                    label="Required"
                    tooltip="Is the property required?"
                    name="required"
                  >
                    <CheckboxInput />
                  </CheckboxFormFieldWrapper>
                  <FieldArray
                    name="references"
                    render={(arrayHelpers) => (
                      <>
                        {formik.values.references.map(
                          (reference: ReferencePropertyType, index: number) => (
                            <ReferenceProperty
                              customObjects={customObjects.filter(
                                (o) => o.id !== currentObjectId
                              )}
                              key={index}
                              index={index}
                              formik={formik}
                              reference={reference}
                              remove={(index: number) => {
                                arrayHelpers.remove(index);
                                setDeletedItems([
                                  ...deletedItems,
                                  { ...reference, delete: true },
                                ]);
                              }}
                            />
                          )
                        )}
                        <Box css={{ mt: 0, mb: 15 }}>
                          <Button
                            data-testid="add-reference-button"
                            css={{
                              fontSize: '14px',
                              border: 'none',
                              background: 'transparent',
                              boxShadow: 'none',
                              color: '#00259ECB',
                              padding: '6px 12px',
                              height: 32,
                              '@hover': {
                                '&:hover': {
                                  background: '#0144FF0F',
                                  boxShadow: 'none',
                                },
                              },
                            }}
                            size={1}
                            variant="gray"
                            type="button"
                            onClick={() =>
                              arrayHelpers.insert(formik.values.references.length, {
                                custom_object_id: '',
                                custom_property_id: '',
                                type: '',
                              })
                            }
                          >
                            <HiPlus />{' '}
                            {formik.values.references.length
                              ? 'Add another reference'
                              : 'Create a reference'}
                          </Button>
                        </Box>
                      </>
                    )}
                  />
                  <DialogFooter justify="end" css={{ mt: 15 }}>
                    <DialogClose asChild>
                      <Button variant="gray" css={{ mr: '$1' }} onClick={onClose}>
                        Cancel
                      </Button>
                    </DialogClose>
                    <DialogClose asChild>
                      <Button
                        data-testid="submit-form-btn"
                        type="submit"
                        disabled={!formik.isValid}
                      >
                        {data ? 'Update' : 'Create'}
                      </Button>
                    </DialogClose>
                  </DialogFooter>
                </form>
              )}
            </Formik>
            <DialogClose asChild>
              <DialogCloseIcon onClick={onClose} size="2">
                <HiX />
              </DialogCloseIcon>
            </DialogClose>
          </DialogContent>
        </DialogOverlay>
      </DialogPortal>
    </Dialog>
  );
};

const SelectIconContainer = styled(SelectIcon, {
  display: 'flex',
  width: 20,
  height: 20,
  alignItems: 'center',
  justifyContent: 'center',
  marginRight: 5,
});
