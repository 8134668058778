import { Action } from './actions';

export enum TriggerTypes {
  REPLY = 'reply',
  KEYWORD = 'reply_containing_keyword',
  LINK_CLICK = 'link_click',
  NO_REPLY = 'no_reply',
}

export type KeywordType = {
  /** keyword uuid */
  id?: string;
  /** is the keyword enabled */
  enabled: boolean;
  /** does the keyword need to be an exact match */
  exact_match: boolean;
  /** an array of strings to match keywords */
  keywords: string[];
  /** an array of strings to negate keywords */
  negate_keywords: string[];
  /** the organization uuid */
  organization_id?: string;
  /** when the keyword was created */
  inserted_at?: string;
};

export type Trigger = {
  /** trigger uuid */
  id?: string;
  /** the type of trigger */
  type: TriggerTypes;
  /** the associated actions */
  actions: Action[];
  /** the keyword object if there is one */
  keyword?: KeywordType | null;
  /** the schedule options object if there is one */
  schedule_options?: {
    /** the day of the week */
    days: string;
    /** the hour */
    hours: string;
    /** the month */
    minutes: string;
  };
};
