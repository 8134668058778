import React from 'react';
import { Helmet } from 'react-helmet-async';

import { SettingsLayout } from '@/shared/layouts';

import { PreferencesView } from './PreferencesView';

export const UserPreferences = (): JSX.Element => {
  return (
    <>
      <Helmet>
        <title>Whippy | My Preferences</title>
      </Helmet>
      <SettingsLayout
        breadcrumbs={[
          { title: 'Settings', path: '/settings/preferences' },
          { title: 'My Preferences', path: `/settings/preferences` },
        ]}
      >
        <PreferencesView />
      </SettingsLayout>
    </>
  );
};
