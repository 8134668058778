/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { HiEye, HiEyeOff, HiOutlineCheck } from 'react-icons/hi';
import { useParams } from 'react-router-dom';
import { toast } from 'sonner';

import {
  Box,
  Button,
  ControlGroup,
  Fieldset,
  Flex,
  Heading,
  Input,
  Label,
  Link,
  Text,
  VStack,
} from '@/shared/ui';
import i18next from '@/shared/utils/translation';
import { isValidEmail, isValidUuid } from '@/shared/utils/validations/validations';
import { styled } from '@/stitches.config';

import { useAuth } from './context/AuthProvider';
import { AuthContainer, AuthPageTitle, AuthPageWrapper } from './Login';

type ParamTypes = {
  id: string;
};

export function Register() {
  const auth = useAuth();

  const [inviteToken, setInviteToken] = useState('');
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const [email, setEmail] = useState(params.get('email') || '');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [password, setPassword] = useState('');
  const [show, setShow] = useState(Boolean);
  const [validPassword, setValidPassword] = useState(false);

  // Grab the invite token if it exists and setState
  const invite = useParams<ParamTypes>();

  // Check if the invite id is a value UUID
  useEffect(() => {
    if (isValidUuid(invite.id)) {
      // if true set the invite token
      setInviteToken(invite.id);
    }
  }, []);

  const getValidationError = () => {
    if (!firstName) {
      return toast.error(i18next.t('first_name_required') as string);
    } else if (!lastName) {
      return toast.error(i18next.t('last_name_required') as string);
    } else if (!email || !isValidEmail(email)) {
      return toast.error(i18next.t('enter_valid_email') as string);
    } else if (password.length < 8) {
      return toast.error(i18next.t('password_too_short') as string);
    } else {
      return null;
    }
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const password = e.target.value;
    if (password.length > 7) {
      setValidPassword(true);
    } else {
      setValidPassword(false);
    }
    setPassword(password);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const error = getValidationError();

    if (error) {
      return;
    }

    const name = firstName + ' ' + lastName;
    try {
      if (inviteToken) {
        await auth.register({
          inviteToken,
          email,
          password,
          name,
        });
      }
    } catch (e) {
      if (e.response?.data.error.errors?.invite && !!e.response)
        toast.error(i18next.t(`invite_${e.response.data.error.errors.invite}`) as string);
      else toast.error('This email is already taken.');
    }
  };

  return (
    <>
      <Helmet>
        <title>Whippy | Sign-up</title>
      </Helmet>
      <AuthPageWrapper align="center" justify="center">
        <AuthContainer>
          <VStack gap={3}>
            {!inviteToken ? (
              <AuthPageTitle css={{ fontSize: 22 }}>Get Started for FREE.</AuthPageTitle>
            ) : (
              <AuthPageTitle css={{ fontSize: 22 }}>
                Join your team on Whippy
              </AuthPageTitle>
            )}
            <form onSubmit={handleSubmit}>
              <VStack gap={3}>
                <Flex justify="between" align="stretch">
                  <Fieldset css={{ marginRight: 2 }}>
                    <Label>First Name</Label>
                    <Input
                      id="firstName"
                      type="text"
                      autoComplete="none"
                      value={firstName}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setFirstName(e.target.value)
                      }
                    />
                  </Fieldset>
                  <Fieldset>
                    <Label>Last Name</Label>
                    <Input
                      id="lastName"
                      type="text"
                      autoComplete="none"
                      value={lastName}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setLastName(e.target.value)
                      }
                    />
                  </Fieldset>
                </Flex>

                <Fieldset>
                  <Label htmlFor="email">Email Address</Label>
                  <Input
                    id="email"
                    type="email"
                    placeholder="name@example.com"
                    autoComplete="username"
                    value={email}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setEmail(e.target.value)
                    }
                  />
                </Fieldset>

                <Fieldset>
                  <Label htmlFor="password">Password</Label>
                  <ControlGroup>
                    <Input
                      id="password"
                      type={show ? 'text' : 'password'}
                      autoComplete="new-password"
                      value={password}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handlePasswordChange(e)
                      }
                    />
                    <Button
                      variant="gray"
                      type="button"
                      onClick={() => setShow(!show)}
                      aria-label="Show password"
                    >
                      {show ? <HiEye /> : <HiEyeOff />}
                    </Button>
                  </ControlGroup>
                </Fieldset>
                <Flex css={{ fontSize: 13, width: '100%' }} justify="between">
                  <Text as={validPassword ? 's' : 'i'}>At least 8 characters long</Text>
                  {validPassword && (
                    <Box>
                      <HiOutlineCheck size="12px" color="#38A169" />
                    </Box>
                  )}
                </Flex>
                <Button type="submit" css={{ width: '100%' }}>
                  {!inviteToken ? 'Create your free account' : 'Join your team in Whippy'}
                </Button>
                <Flex justify="center">
                  <Link href="/login">Already have an account?</Link>
                </Flex>
              </VStack>
            </form>
          </VStack>
        </AuthContainer>
      </AuthPageWrapper>
    </>
  );
}

export const AuthHeading = styled(Heading, {
  fontSize: 28,
  marginBottom: 20,
  fontWeight: 600,
  maxWidth: 400,
});
