/* eslint-disable react-hooks/exhaustive-deps */

import * as SelectPrimitive from '@radix-ui/react-select';
import React from 'react';
import { HiChevronDown, HiChevronUp } from 'react-icons/hi';

import { SingleSelect } from '@/shared/components/SingleSelect';
import { usePageView } from '@/shared/hooks';
import {
  Badge,
  Flex,
  HStack,
  Label,
  Select,
  SelectContent,
  SelectGroup,
  SelectIcon,
  SelectItem,
  SelectItemIndicator,
  SelectItemText,
  SelectLabel,
  SelectScrollUpButton,
  SelectTrigger,
  SelectValue,
  SelectViewport,
  Switch,
  SwitchThumb,
  Text,
  VStack,
} from '@/shared/ui';

import { useChannels } from '../../organization/channels/context/ChannelContext';
import { useUserPreferences } from './context/PreferencesContext';

export const PreferencesView = (): JSX.Element => {
  usePageView();

  // user preferences state
  const user_preferences = useUserPreferences();
  const { preferences, updatePreferences } = user_preferences;

  // locations state
  const channels = useChannels();
  // just list the channels that the user has access to
  const { channels: channels_list } = channels.channelsState;

  // sets the preferred_location_id in inbox preferences
  // from the channels list
  const handleChangeLocation = (selected_location_id: string) => {
    updatePreferences({
      inbox: {
        ...preferences.inbox,
        preferred_location_id: selected_location_id,
      },
    });
  };

  const getLocationName = (location_id: string) => {
    const location = channels_list.find((location) => location.id === location_id);
    return location?.name || '';
  };

  // general settings are disabled for now
  // we can add them once we update the UI
  return (
    <VStack>
      <HStack>
        <Text variant="bold" size="3">
          General Settings
        </Text>
        <Badge variant="cyan">
          <Text>Coming Soon</Text>
        </Badge>
      </HStack>
      <Flex align="center" justify="between">
        <Flex css={{ width: '80%' }}>
          <VStack gap={1}>
            <Label>Language</Label>
            <Text>
              Choose your preferred language. This will change the language of the Whippy
              interface.
            </Text>
          </VStack>
        </Flex>
        <Select defaultValue={preferences?.general?.language || 'en'} disabled={true}>
          <SelectTrigger css={{ minWidth: 100 }}>
            <SelectValue />
            <SelectIcon>
              <HiChevronDown />
            </SelectIcon>
          </SelectTrigger>
          <SelectPrimitive.Portal>
            <SelectContent css={{ zIndex: 9999999 }}>
              <SelectScrollUpButton>
                <HiChevronUp />
              </SelectScrollUpButton>
              <SelectViewport>
                <SelectGroup>
                  <SelectLabel>Language</SelectLabel>
                  {languageOptions.map((language) => (
                    <SelectItem
                      key={language.value}
                      value={language.value}
                      onSelect={() =>
                        updatePreferences({ general: { language: language.value } })
                      }
                    >
                      <SelectItemIndicator />
                      <SelectItemText>{language.label}</SelectItemText>
                    </SelectItem>
                  ))}
                </SelectGroup>
              </SelectViewport>
            </SelectContent>
          </SelectPrimitive.Portal>
        </Select>
      </Flex>
      <Flex align="center" justify="between">
        <Flex css={{ width: '80%' }}>
          <VStack gap={1}>
            <Label>Theme</Label>
            <Text>
              Choose your preferred theme. This will change the color scheme of the Whippy
              interface.
            </Text>
          </VStack>
        </Flex>
        <Select defaultValue={preferences?.general?.theme || 'light'} disabled={true}>
          <SelectTrigger css={{ minWidth: 100 }}>
            <SelectValue />
            <SelectIcon>
              <HiChevronDown />
            </SelectIcon>
          </SelectTrigger>
          <SelectPrimitive.Portal>
            <SelectContent css={{ zIndex: 9999999 }}>
              <SelectScrollUpButton>
                <HiChevronUp />
              </SelectScrollUpButton>
              <SelectViewport>
                <SelectGroup>
                  <SelectLabel>Themes</SelectLabel>
                  {themeOptions.map((theme) => (
                    <SelectItem
                      key={theme.value}
                      value={theme.value}
                      onSelect={() =>
                        updatePreferences({ general: { theme: theme.value } })
                      }
                    >
                      <SelectItemIndicator />
                      <SelectItemText>{theme.label}</SelectItemText>
                    </SelectItem>
                  ))}
                </SelectGroup>
              </SelectViewport>
            </SelectContent>
          </SelectPrimitive.Portal>
        </Select>
      </Flex>
      <HStack>
        <Text variant="bold" size="3">
          Inbox Settings
        </Text>
        <Badge variant="green">
          <Text>Beta</Text>
        </Badge>
      </HStack>
      <Flex align="center" justify="between">
        <Flex css={{ width: '80%' }}>
          <VStack gap={1}>
            <Label>Enter to send</Label>
            <Text>
              When enabled, pressing enter will send your message. When disabled, you will
              need to click the send button.
            </Text>
          </VStack>
        </Flex>
        <Switch
          checked={preferences?.inbox?.enter_to_send}
          onCheckedChange={(checked) => {
            updatePreferences({
              inbox: {
                ...preferences?.inbox,
                enter_to_send: checked,
              },
            });
          }}
        >
          <SwitchThumb />
        </Switch>
      </Flex>
      <Flex direction="column">
        <Flex css={{ width: '100%', mb: 10 }}>
          <VStack gap={1}>
            <Label>Preferred Channel</Label>
            <Text>
              When enabled, Whippy will try to use your preferred channel when creating a
              new message, in All Conversations.
            </Text>
          </VStack>
        </Flex>
        <SingleSelect
          defaultPlaceholder={
            getLocationName(preferences?.inbox?.preferred_location_id || '') ||
            'Select a channel'
          }
          isDropdown={true}
          selectItem={preferences?.inbox?.preferred_location_id || ''}
          setSelectItem={handleChangeLocation}
          options={channels_list?.map((channel) => ({
            type: channel?.name || channel.address || '',
            value: channel?.id,
          }))}
        />
      </Flex>
    </VStack>
  );
};

// example interface theme options
const themeOptions = [
  { value: 'light', label: 'Light' },
  { value: 'dark', label: 'Dark' },
];

// example language options
const languageOptions = [
  { value: 'en', label: 'English' },
  { value: 'es', label: 'Spanish' },
];
