import { FormikValues, useFormikContext } from 'formik';
import { get, set } from 'lodash';
import React from 'react';

import { Checkbox } from '@/shared/ui';

import { CheckboxInputProps } from './types';

export const CheckboxInput = (props: CheckboxInputProps) => {
  const formik = useFormikContext<FormikValues>();

  return (
    <Checkbox
      defaultChecked={get(formik.initialValues, props.name ?? '')}
      checked={get(formik.values, props?.name ?? '')}
      onCheckedChange={(e: boolean) => {
        const updatedValues = set({ ...formik.values }, props.name ?? '', e);
        formik.setValues(updatedValues);
      }}
      aria-label={props.ariaLabel}
    />
  );
};
