export const sequence_contact_object = {
  id: 'unique-id-for-contact_message',
  label: 'Sequence Contact',
  key: 'step_contact',
  inserted_at: 'current-timestamp',
  updated_at: 'current-timestamp',
  custom_properties: [
    {
      default: '',
      id: 'sequence_contact-inserted_at',
      label: 'Start Time',
      type: 'date',
      key: 'inserted_at',
      required: true,
      inserted_at: 'current-timestamp',
      updated_at: 'current-timestamp',
      custom_object_id: 'unique-id-for-contact',
    },
  ],
};
