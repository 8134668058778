export const default_call_object = {
  id: 'unique-id-for-calls',
  label: 'Call',
  key: 'message_attachment',
  inserted_at: 'current-timestamp',
  updated_at: 'current-timestamp',
  custom_properties: [
    {
      default: '',
      id: 'call-created-at',
      label: 'Created At',
      type: 'date',
      key: 'inserted_at',
      required: true,
      inserted_at: 'current-timestamp',
      updated_at: 'current-timestamp',
      custom_object_id: 'unique-id-for-agent',
    },
    {
      default: '',
      id: 'call-updated-at',
      label: 'Updated At',
      type: 'date',
      key: 'updated_at',
      required: true,
      inserted_at: 'current-timestamp',
      updated_at: 'current-timestamp',
      custom_object_id: 'unique-id-for-agent',
    },
  ],
};
