// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useEffect, useState } from 'react';
import { HiInformationCircle, HiMinus, HiSearch } from 'react-icons/hi';

import { Contact, Preference } from '@/shared/types';
import { Channel } from '@/shared/types/channels';
import {
  Checkbox,
  Flex,
  IconButton,
  Input,
  Text,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '@/shared/ui';
import { styled } from '@/stitches.config';

export type ChannelsTableProps = {
  /** All channels that this user has **/
  allChannels?: Channel[];
  /** Channels that this contact has opted in and not paused **/
  optedInAndNotPausedLocations?: Channel[];
  /** Channels that this contact has paused **/
  pausedLocations?: Channel[];
  /** Preferences that this contact has paused **/
  pausedPreferences?: Preference[];
  /** Channels that this contact has unsubscribed **/
  unsubscribedLocations?: Channel[];
  /** Channels that are selected in this table **/
  selectedLocations?: Channel[];
  /** Function to set the selected channels **/
  setSelectedLocations: (items: Channel[]) => void;
  /** Whether the table is loading **/
  isLoading?: boolean;
  /** the current contact **/
  contact?: Contact;
};

const ChannelsTable: React.FC<ChannelsTableProps> = (props: ChannelsTableProps) => {
  const {
    allChannels,
    optedInAndNotPausedLocations,
    pausedLocations,
    pausedPreferences,
    unsubscribedLocations,
    selectedLocations = [],
    setSelectedLocations,
    contact,
  } = props;

  // a section to identify the contact's opt-in statuses
  const isOptInSms = contact?.opt_in_sms ?? true; // default to true if the field is null
  const isOptInEmail = contact?.opt_in_email ?? true; // default to true if the field is null
  const isOptOutOfAll = contact?.opt_out_of_all ?? false; // default to false if the field is null

  // a section for search channels
  const [inputValue, setInputValue] = useState('');

  const handleInputValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  // only filtered channels will be shown
  const filteredItems = allChannels
    ? allChannels.filter((location: Channel) =>
        location.name?.toLowerCase().includes(inputValue.toLowerCase())
      )
    : [];

  // a section for location selection
  const handleCheckboxChange = (item: Channel) => {
    const prev = selectedLocations || []; // directly reference the current state

    // Check if the location is already in the selected channels
    const locationExists = prev.some((loc: Channel) => loc.id === item.id);

    // If it exists, filter it out
    if (locationExists) {
      setSelectedLocations(prev.filter((loc: Channel) => loc.id !== item.id));
    }
    // Otherwise, add it to the selected channels
    else {
      setSelectedLocations([...prev, item]);
    }
  };

  const selectAll = () => {
    setSelectedLocations(filteredItems);
  };

  const unselectAll = () => {
    setSelectedLocations([]);
  };

  const areAllSelected = filteredItems.every(
    (item: Channel) => selectedLocations?.includes(item) ?? false
  );

  // Get the date that the location is paused until in 'Month DD, YYYY' format
  const getPauseUntilDate = (location: Channel) => {
    const preference = pausedPreferences?.find(
      (pref: Preference) => pref.location_id === location.id
    );
    const dateStr = preference?.paused_until?.slice(0, 10);
    // If the date is 2099-12-31, it means the location is paused forever
    return dateStr === '2099-12-31'
      ? 'forever'
      : dateStr
        ? new Intl.DateTimeFormat('en-US', {
            year: 'numeric',
            month: 'long',
            day: '2-digit',
          }).format(new Date(dateStr))
        : '';
  };

  return (
    <div>
      {/* An intermediate container for borderline control */}
      <div style={{ paddingLeft: 20, paddingRight: 20 }}>
        {/* Container for search input */}
        <SearchInputContainer css={{ pl: 0 }}>
          <Flex align="center" justify="center" css={{ pl: 10 }}>
            <HiSearch />
          </Flex>
          <SearchInput
            placeholder="Search Channel Name"
            value={inputValue}
            onChange={handleInputValueChange}
          />
        </SearchInputContainer>
      </div>
      {inputValue === '' && filteredItems.length === 0 ? (
        <EmptyStateContainer>
          <Text>This contact has not subscribed any channels before.</Text>
        </EmptyStateContainer>
      ) : inputValue !== '' && filteredItems.length === 0 ? (
        <NoFoundStateContainer>
          <Text>No channels found.</Text>
        </NoFoundStateContainer>
      ) : (
        <>
          <ScrollableContainer>
            <LocationRowContainer onClick={areAllSelected ? unselectAll : selectAll}>
              <Flex align="center" css={{ pr: 10 }}>
                <Checkbox checked={areAllSelected} />
              </Flex>
              <Flex align="center">{areAllSelected ? 'Unselect All' : 'Select All'}</Flex>
            </LocationRowContainer>

            {filteredItems.map((location: Channel) => {
              const isActive = optedInAndNotPausedLocations?.includes(location); // check if the location is in the optedInAndNotPausedLocations array
              const isPaused = pausedLocations?.includes(location); // check if the location is in the pausedLocations array
              const isUnsubscribed = unsubscribedLocations?.includes(location); // check if the location is in the unsubscribedLocations array
              const isChecked = selectedLocations?.some(
                (loc: Channel) => loc.id === location.id
              );

              // the current contact also needs to satisfy 3 conditions to be opt-in in this location
              // 1. not opt-out of all
              // 2. opt-in in SMS if the location type is SMS, OR
              // 3. opt-in in Email if the location type is Email
              const locationType = location.type;
              const isOptInThisLocation =
                !isOptOutOfAll &&
                (!locationType || // if the locationType is undefined | null, we judge it as opt-in
                  (locationType === 'phone' && isOptInSms) ||
                  (locationType === 'email' && isOptInEmail));

              // Determine the variant for the ContactRowContainer based on the type of location
              let variant: 'paused' | 'optedInAndNotPaused' | 'unsubscribed' | 'error';
              if (unsubscribedLocations?.includes(location) || !isOptInThisLocation) {
                // if isOptInThisLocation is false, the variant also should be 'unsubscribed'
                variant = 'unsubscribed';
              } else if (optedInAndNotPausedLocations?.includes(location)) {
                variant = 'optedInAndNotPaused';
              } else if (pausedLocations?.includes(location)) {
                variant = 'paused';
              } else {
                variant = 'error';
              }

              return (
                <LocationRowContainer
                  key={location.id}
                  variant={variant}
                  onClick={() => (isUnsubscribed ? null : handleCheckboxChange(location))}
                >
                  <Flex align="center" css={{ pr: 10 }}>
                    {isUnsubscribed || !isOptInThisLocation ? (
                      <HiMinus />
                    ) : (
                      <Checkbox checked={isChecked} />
                    )}
                  </Flex>
                  <Flex align="center">{location.name}</Flex>
                  <Flex align="center">
                    <IndicatorText>
                      {isUnsubscribed || !isOptInThisLocation
                        ? null
                        : isActive
                          ? 'Active'
                          : isPaused
                            ? 'Paused until ' + getPauseUntilDate(location)
                            : 'Error - Unknown status'}
                    </IndicatorText>
                    {(isUnsubscribed || !isOptInThisLocation) && (
                      <Flex align="center">
                        <IndicatorText css={{ pr: '40px' }}>Unsubscribed</IndicatorText>
                        <TooltipContainer css={{ pr: '10px' }}>
                          <Tooltip>
                            <TooltipTrigger asChild>
                              <IconButton>
                                <HiInformationCircle color="gray" />
                              </IconButton>
                            </TooltipTrigger>
                            <TooltipContent>
                              <UnsubscribedTooltipContent />
                            </TooltipContent>
                          </Tooltip>
                        </TooltipContainer>
                      </Flex>
                    )}
                  </Flex>
                </LocationRowContainer>
              );
            })}
          </ScrollableContainer>
        </>
      )}
    </div>
  );
};

const UnsubscribedTooltipContent = () => {
  return (
    <div>
      <div style={{ marginBottom: '8px' }}>Due to SMS regulations, you need</div>
      <div style={{ marginBottom: '8px' }}>
        contacts to reply &quot;{'START'}&quot; in SMS
      </div>

      <div style={{ marginBottom: '8px' }}>to re-subscribe. You can also find</div>
      <div style={{ marginBottom: '2px' }}>
        more information via{' '}
        <a
          href="https://intercom.help/whippy/en/articles/8302056-how-to-re-subscribe-opt-in-contact"
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: 'underline' }}
        >
          this link
        </a>
        .
      </div>
    </div>
  );
};

const ScrollableContainer = styled('div', {
  maxHeight: '300px',
  overflowY: 'auto',
});

const LocationRowContainer = styled(Flex, {
  width: '100%',
  position: 'relative',
  height: '35px',
  pl: 20,
  fontWeight: 400,
  fontSize: 13,
  alignItems: 'center',
  align: 'center',
  justify: 'between',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: '$slate2',
  },
  variants: {
    variant: {
      optedInAndNotPaused: {},
      paused: {},
      unsubscribed: {
        color: 'gray',
      },
      error: {
        color: 'red',
        pointerEvents: 'none',
      },
    },
  },
});

const TooltipContainer = styled('div', {
  color: 'gray',
  position: 'absolute',
  right: '5px',
  top: '50%',
  transform: 'translateY(-50%)',
});

const IndicatorText = styled('div', {
  color: 'gray',
  position: 'absolute',
  right: '0px',
  top: '50%',
  transform: 'translateY(-50%)',
  pr: 20,
});

const SearchInputContainer = styled(Flex, {
  position: 'relative',
  height: 38,
  backgroundColor: 'hsl(204 10% 10% / 0.05)',
  border: '1px solid $gray4',
  borderRadius: '4px',
  marginBottom: '10px', // to have some space between the search input and the table
});

const SearchInput = styled(Input, {
  backgroundColor: 'transparent',
  borderRadius: 1,
  boxShadow: 'none',
  padding: '0px 5px 0px 5px',
  height: '30px',
  '&:focus': {
    boxShadow: 'none',
  },
});

// Styles for Empty state
const EmptyStateContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '20px',
  textAlign: 'center',
  color: '$gray5',
});

// Styles for No Found state
const NoFoundStateContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '20px',
  textAlign: 'center',
  color: '$gray5',
});

export default ChannelsTable;
