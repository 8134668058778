export const default_api_key_object = {
  id: 'unique-id-for-api-keys',
  label: 'Api Key',
  key: 'api_key',
  inserted_at: 'current-timestamp',
  updated_at: 'current-timestamp',
  custom_properties: [
    {
      default: '',
      id: 'api-key-created-at',
      label: 'Created At',
      type: 'date',
      key: 'inserted_at',
      required: true,
      inserted_at: 'current-timestamp',
      updated_at: 'current-timestamp',
      custom_object_id: 'unique-id-for-api-keys',
    },
    {
      default: '',
      id: 'api-key-updated-at',
      label: 'Updated At',
      type: 'date',
      key: 'updated_at',
      required: true,
      inserted_at: 'current-timestamp',
      updated_at: 'current-timestamp',
      custom_object_id: 'unique-id-for-api-keys',
    },
    {
      default: '',
      id: 'api-key-active',
      label: 'Active',
      type: 'boolean',
      key: 'active',
      required: true,
      inserted_at: 'current-timestamp',
      updated_at: 'current-timestamp',
      custom_object_id: 'unique-id-for-api-keys',
    },
  ],
};

export const updated_by = {
  id: 'unique-id-for-api-keys',
  label: 'Updated By',
  key: 'updated_by',
  inserted_at: 'current-timestamp',
  updated_at: 'current-timestamp',
  custom_properties: [
    {
      default: '',
      id: 'updated-by-user',
      label: 'User',
      type: 'user',
      key: 'id',
      required: true,
      inserted_at: 'current-timestamp',
      updated_at: 'current-timestamp',
      custom_object_id: 'unique-id-for-agent',
    },
  ],
};

export const created_by = {
  id: 'unique-id-for-api-keys',
  label: 'Created By',
  key: 'created_by',
  inserted_at: 'current-timestamp',
  updated_at: 'current-timestamp',
  custom_properties: [
    {
      default: '',
      id: 'created-by-user',
      label: 'User',
      type: 'user',
      key: 'id',
      required: true,
      inserted_at: 'current-timestamp',
      updated_at: 'current-timestamp',
      custom_object_id: 'unique-id-for-agent',
    },
  ],
};
