import { SearchFilters } from '@/shared/types/contacts';
import { Domain } from '@/shared/types/domains';

export type DomainsState = {
  domains: Domain[];
  activeDomains: Domain[];
  current: Domain | null;
  totalCount: number;
  loading: boolean;
  loadingDomain: boolean;
  error?: any;
  filterParams: SearchFilters;
};

export enum DomainsActionType {
  GET_DOMAINS = 'GET_DOMAINS',
  GET_ACTIVE_DOMAINS = 'GET_ACTIVE_DOMAINS',
  GET_DOMAIN = 'GET_DOMAIN',
  DELETE_DOMAIN = 'DELETE_DOMAIN',
  ADD_DOMAIN = 'ADD_DOMAIN',
  BUY_DOMAIN = 'BUY_DOMAIN',
  GET_DOMAIN_AVAILABILITY = 'GET_DOMAIN_AVAILABILITY',
  SET_LOADING = 'SET_LOADING',
  SET_LOADING_DOMAIN = 'SET_LOADING_DOMAIN',
  SET_CURRENT = 'SET_CURRENT',
  SET_ERROR = 'SET_ERROR',
  UPDATE_FILTER_PARAMS = 'UPDATE_FILTER_PARAMS',
  UPDATE_DOMAIN = 'UPDATE_DOMAIN',
}
