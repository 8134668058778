export const default_locations_object = {
  id: 'unique-id-for-tags',
  label: 'Channel',
  key: 'location',
  inserted_at: 'current-timestamp',
  updated_at: 'current-timestamp',
  custom_properties: [
    {
      default: '',
      id: 'Channel-name',
      label: 'Name',
      type: 'text',
      key: 'name',
      required: true,
      inserted_at: 'current-timestamp',
      updated_at: 'current-timestamp',
      custom_object_id: 'unique-id-for-segments',
    },
    {
      default: '',
      id: 'Channel-description',
      label: 'Description',
      type: 'text',
      key: 'description',
      required: false,
      inserted_at: 'current-timestamp',
      updated_at: 'current-timestamp',
      custom_object_id: 'unique-id-for-description',
    },
    {
      default: '',
      id: 'Channel-address',
      label: 'Address',
      type: 'text',
      key: 'address',
      required: false,
      inserted_at: 'current-timestamp',
      updated_at: 'current-timestamp',
      custom_object_id: 'unique-id-for-address',
    },
    {
      default: '',
      id: 'Channel-updated_by',
      label: 'Updated By',
      type: 'user',
      key: 'updated_by_id',
      required: false,
      inserted_at: 'current-timestamp',
      updated_at: 'current-timestamp',
      custom_object_id: 'unique-id-for-updated-by',
    },
    {
      default: '',
      id: 'Channel-created_by',
      label: 'Created By',
      type: 'user',
      key: 'created_by_id',
      required: false,
      inserted_at: 'current-timestamp',
      updated_at: 'current-timestamp',
      custom_object_id: 'unique-id-for-created-by',
    },
    {
      default: 'current-timestamp',
      id: 'Channel-created_at',
      label: 'Created At',
      type: 'date',
      key: 'inserted_at',
      required: true,
      inserted_at: 'current-timestamp',
      updated_at: 'current-timestamp',
      custom_object_id: 'unique-id-for-contact',
    },
    {
      default: 'current-timestamp',
      id: 'Channel-updated_at',
      label: 'Updated At',
      type: 'date',
      key: 'updated_at',
      required: true,
      inserted_at: 'current-timestamp',
      updated_at: 'current-timestamp',
      custom_object_id: 'unique-id-for-contact',
    },
  ],
};
