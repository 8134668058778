import React from 'react';
import { HiX } from 'react-icons/hi';

import { useUserWebNotifications } from '@/shared/components/notifications/context/UserWebNotificationsContext';
import { Box, Flex, Text } from '@/shared/ui';

const NotificationsDrawerHeader = () => {
  const { closeNotificationsDrawer } = useUserWebNotifications();

  return (
    <Flex
      align="center"
      justify="between"
      css={{
        padding: '20px 35px 20px 25px',
        borderBottom: '1px solid $gray4',
      }}
    >
      <Text
        css={{
          fontWeight: 700,
          fontSize: 18,
        }}
      >
        Notifications
      </Text>
      <Box css={{ cursor: 'pointer' }}>
        <HiX size={20} onClick={closeNotificationsDrawer} />
      </Box>
    </Flex>
  );
};

export default NotificationsDrawerHeader;
