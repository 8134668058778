/* eslint-disable react-hooks/exhaustive-deps */
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';

import { useUsers } from '@/pages/settings/organization/users/context/UserContext';
import { getMessagesOverview } from '@/shared/api/reports';
import { DAY } from '@/shared/components/reports/constants';
import { ReportsCollapsible } from '@/shared/components/reports/ReportsCollapsible';
import { ReportsTable } from '@/shared/components/reports/ReportsTable';
import { User } from '@/shared/types/users';

import { useReport } from '../context/ReportsContext';

export type UserMessagesGroupedResult = {
  /** data to display in table */
  grouped_result: UserMessagesData[];
};

export type UserMessagesData = {
  /** total message count for user for given time period */
  messages_count: number;
  /** total segments count for user for given time period */
  segments_count: number;
  /** average segments usage per message for given time period */
  avg_segments_per_message: number;
  /** user information */
  user_id: number;
  /** This will be filled with calling findUserAndPutUser function */
  user: User;
};

export const UserMessaging = () => {
  const reports = useReport();
  const { dates, initialState } = reports;

  const [data, setData] = useState<UserMessagesGroupedResult>();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    getData();
  }, [dates.startDate, dates.endDate]);

  const getData = async () => {
    setLoading(true);
    setError(false);
    try {
      const response = await getMessagesOverview(
        dates.startDate && dates.endDate
          ? {
              type: 'user_messages',
              start_date: dayjs(dates.startDate as string).format('YYYY-MM-DD'),
              end_date: dayjs(dates.endDate as string).format('YYYY-MM-DD'),
              granularity: DAY,
            }
          : { ...initialState, type: 'user_messages' }
      );
      setData(response);
    } catch (error) {
      setError(true);
      return null;
    } finally {
      setLoading(false);
    }
  };

  const table_config = [
    {
      header: 'User',
      tooltip: 'The user who sent the messages',
      key: 'user',
    },
    {
      header: 'Total Credits',
      tooltip: 'Total Credits used by the User during the selected time period',
      key: 'segments_count',
    },
    {
      header: 'Total Messages',
      tooltip: 'Total Messages Sent by a User during the selected time period',
      key: 'messages_count',
    },
    {
      header: 'Avg. Credits per Message',
      tooltip:
        'Average number of credits the user used per message during the selected time period',
      key: 'avg_segments_per_message',
    },
  ];

  // Prepare data for CSV export
  const headers = [
    { label: 'User Email', key: 'user.email' },
    { label: 'Total Credits', key: 'segments_count' },
    { label: 'Total Messages', key: 'messages_count' },
    { label: 'Avg. Credits per Message', key: 'avg_segments_per_message' },
  ];

  const u = useUsers();
  const { users } = u.userState;

  const csvData =
    data?.grouped_result.map((item) => ({
      ...item,
      'user.email': users.find((user) => user.id === item.user_id)?.email || '',
    })) || [];

  return (
    <ReportsCollapsible
      title="Messages by User"
      description=""
      headers={headers}
      data={csvData}
      filename="messages_by_user"
    >
      <ReportsTable
        data={data?.grouped_result}
        config={table_config}
        sortable
        tableTitle="Messages by User"
        loading={loading}
        error={error}
        errorCallback={getData}
      />
    </ReportsCollapsible>
  );
};
