/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

import { Flex } from '../ui';

export function LayoutContainer({ children }: any) {
  return (
    <Flex
      css={{
        width: '100%',
        height: '100%',
        minHeight: '100%',
        alignItems: 'stretch',
        position: 'fixed',
        '@lg': {
          height: 'calc(100vh - 80px)',
          minHeight: 'calc(100vh - 80px)',
        },
      }}
    >
      {children}
    </Flex>
  );
}

export function LayoutContent({ children }: any) {
  return (
    <Flex
      direction="column"
      css={{
        position: 'relative',
        align: 'stretch',
        justifyItems: 'stretch',
        flexFrow: 1,
        flexShrink: 'initial',
        flexBasis: 'initial',
        width: '100%',
        height: '100%',
        minHeight: '100%',
        overflow: 'hidden',
      }}
    >
      {children}
    </Flex>
  );
}

export function MainLayout({ children }: any) {
  return <LayoutContainer>{children}</LayoutContainer>;
}
