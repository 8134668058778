export const DNSRecordsWarnings = [
  {
    type: 'A',
    warning:
      'Warning: The A record will direct traffic from the domain to a new service, breaking any existing site or service using the domain. Please exercise caution when setting this record.',
  },
  {
    type: 'TXT',
    warning:
      'Warning: The TXT record will transfer the domain ownership, breaking any existing site or service using the domain. Please exercise caution when setting this record.',
  },
  {
    type: 'CNAME',
    warning:
      'Warning: The CNAME record will direct traffic from the subdomain to a new service, breaking any existing site or service using the subdomain. Please exercise caution when setting this record.',
  },
  {
    type: 'CAA',
    warning: '',
  },
];

export const setupResultsColumns = [
  {
    id: 'setup_type',
    label: 'Setup type',
  },
  {
    id: 'renew',
    label: 'Auto renew',
  },
  {
    id: 'expires_at',
    label: 'Expiration date',
  },
  {
    id: 'updated_at',
    label: 'Updated at',
  },
];
