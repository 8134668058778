/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/no-children-prop */
import * as linkify from 'linkifyjs';
import uniqBy from 'lodash';
import React from 'react';
import ReactMarkdown from 'react-markdown';

import { styled } from '@/stitches.config';

export const renderText = (text?: string) => {
  // translate links
  if (!text) return null;

  // you need this line to render the line breaks the same as SMS
  let newText = text;
  const markdownLinks = matchMarkdownLinks(newText);
  const detectHttp = /(http(s?):\/\/)?(www\.)?/;

  // extract all valid links/emails within text and replace it with proper markup
  uniqBy(linkify.find(newText)).forEach(({ href, type, value }: any) => {
    // check if message is already  markdown
    const noParsingNeeded =
      markdownLinks &&
      markdownLinks.filter((text) => {
        const strippedHref = href?.replace(detectHttp, '');
        const strippedText = text?.replace(detectHttp, '');

        if (!strippedHref || !strippedText) return false;

        return strippedHref.includes(strippedText) || strippedText.includes(strippedHref);
      });

    if (noParsingNeeded.length > 0) return;

    const displayLink = type === 'email' ? value : value.replace(detectHttp, '');

    newText = newText.replace(
      new RegExp(escapeRegExp(value), 'g'),
      `[${displayLink}](${encodeURI('http://' + displayLink)})`
    );
  });

  // Define demo variables for testing and demonstration purposes
  const demoVariables = {
    '{{first_name}}': 'John',
    '{{last_name}}': 'Doe',
    '{{full_name}}': 'John Doe',
    '{{location_name}}': 'Channel Name',
    '{{location_address}}': 'Channel Address',
    '{{organization_name}}': 'Whippy',
    '{{review_link}}': 'https://search.google.com/local/writereview',
    '{{today}}': new Date().toLocaleDateString('en-US'),
    '{{tomorrow}}': new Date(Date.now() + 86400000).toLocaleDateString('en-US'),
  };

  // Iterate over the demo variables and replace them in the text
  // This is done to simulate the replacement of actual variables in a production environment
  Object.entries(demoVariables).forEach(([key, value]) => {
    newText = newText.replace(new RegExp(escapeRegExp(key), 'g'), value);
  });

  return (
    <ReactMarkdown
      children={newText}
      transformLinkUri={(uri: any) => uri}
      unwrapDisallowed={true}
      linkTarget={'_blank'}
      components={{
        ol: ({ node, ...props }: any) => <StyledOL {...props} />,
        ul: ({ node, ...props }: any) => <StyledUL {...props} />,
        p: ({ node, ...props }: any) => <StyledP {...props} />,
        li: ({ node, ...props }: any) => <StyledLI {...props} />,
        a: ({ node, ...props }: any) => <StyledA {...props}>{props.children}</StyledA>,
        strong: ({ node, ...props }: any) => <StyledStrong {...props} />,
        em: ({ node, ...props }: any) => <StyledEm {...props} />,
        pre: ({ node, ...props }: any) => <StyledPre {...props} />,
        code: ({ node, ...props }: any) => <StyledCode {...props} />,
      }}
    />
  );
};

const StyledOL = styled('ol', {
  paddingLeft: 20,
  backgroundColor: 'inherit !important',
});

const StyledLI = styled('li', {
  backgroundColor: 'inherit !important',
});

const StyledUL = styled('ul', {
  paddingLeft: 20,
  paddingBottom: 2,
  backgroundColor: 'inherit !important',
});

const StyledP = styled('p', {
  lineHeight: 1.65,
  paddingBottom: 15,
  whiteSpace: 'pre-wrap',
  backgroundColor: 'inherit !important',
  '&:last-child': {
    paddingBottom: 0,
  },
});

const StyledStrong = styled('strong', {
  lineHeight: 1.65,
  whiteSpace: 'pre-wrap',
  backgroundColor: 'inherit !important',
  '&:last-child': {
    paddingBottom: 0,
  },
});

const StyledEm = styled('em', {
  lineHeight: 1.65,
  paddingBottom: 15,
  whiteSpace: 'pre-wrap',
  backgroundColor: 'inherit !important',
  '&:last-child': {
    paddingBottom: 0,
  },
});

const StyledA = styled('a', {
  textDecoration: 'underline',
  backgroundColor: 'inherit !important',
});

// pre
const StyledPre = styled('pre', {
  lineHeight: 1.65,
  paddingBottom: 15,
  whiteSpace: 'pre-wrap',
  backgroundColor: 'inherit !important',
  '&:last-child': {
    paddingBottom: 0,
  },
});

// code
const StyledCode = styled('code', {
  lineHeight: 1.65,
  paddingBottom: 15,
  whiteSpace: 'pre-wrap',
  backgroundColor: 'inherit !important',
  '&:last-child': {
    paddingBottom: 0,
  },
});

const matchMarkdownLinks = (message: string) => {
  const regexMdLinks = /\[([^[]+)\](\(.*\))/gm;
  const matches = message.match(regexMdLinks);
  const singleMatch = /\[([^[]+)\]\((.*)\)/;

  const links = matches
    ? matches.map((match) => {
        const i = singleMatch.exec(match);
        return i && [i[1], i[2]];
      })
    : [];

  return links.flat();
};

export function escapeRegExp(text: string) {
  return text.replace(/[-[\]{}()*+?.,\\^$|#]/g, '\\$&');
}

export type RenderTextOptions = {
  customMarkDownRenderers?: {
    [nodeType: string]: React.ElementType;
  };
};
