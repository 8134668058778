import { SearchFilters } from '../contacts';
import { User } from '../users';

export type TagBulkActionType = 'unassign' | 'assign' | 'assign.overwrite';

export type Tag = {
  id: string;
  name: string;
  color: string;
  type?: string;
  organization_id?: string;
  inserted_at?: string;
  updated_at?: string;
  import_id?: string;
  import_status?: string;
  state?: string;
  created_by?: User;
  updated_by?: User;
};

export enum TagActionType {
  GET_ALL_TAGS = 'GET_ALL_TAGS',
  GET_TAGS = 'GET_TAGS',
  GET_PART_OF_TAGS = 'GET_PART_OF_TAGS',
  SEARCH_TAG = 'SEARCH_TAG',
  CREATE_TAG = 'CREATE_TAG',
  EDIT_TAG = 'EDIT_TAG',
  DELETE_TAG = 'DELETE_TAG',
  SET_CURRENT = 'SET_CURRENT',
  SET_LOADING = 'SET_LOADING',
  UPDATE_FILTER_PARAMS = 'UPDATE_FILTER_PARAMS',
}

export type TagActions =
  | { type: TagActionType.GET_ALL_TAGS; payload: Tag[] }
  | { type: TagActionType.GET_TAGS; payload: { data: Array<Tag>; total: number } }
  | { type: TagActionType.GET_PART_OF_TAGS; payload: { tags: Tag[]; total: number } }
  | { type: TagActionType.SEARCH_TAG; payload: Tag[] }
  | { type: TagActionType.CREATE_TAG; payload: Tag }
  | { type: TagActionType.EDIT_TAG; payload: Tag }
  | { type: TagActionType.DELETE_TAG; payload: Tag }
  | { type: TagActionType.SET_CURRENT; payload: Tag | null | undefined }
  | { type: TagActionType.SET_LOADING; payload: boolean }
  | { type: TagActionType.UPDATE_FILTER_PARAMS; payload: SearchFilters };
