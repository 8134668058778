import { DebouncedFunc } from 'lodash';

import { Contact, SearchFilters } from '@/shared/types/contacts';
import { CustomObjectRecord } from '@/shared/types/data';
import { FilterType, Sort } from '@/shared/types/filter';

import { areFiltersValid } from '../../../shared/components/filterBuilder/utils/areValidFilters';
import { cleanFilters } from '../../../shared/components/filterBuilder/utils/cleanFilters';
import { generateQuickSearchFilters } from '../../../shared/components/filterBuilder/utils/generateQuickSearchFilters';

export const defaultSort: Array<Sort> = [
  {
    label: 'Updated At',
    column: 'updated_at',
    order: 'desc',
    resource: 'contact',
    id: null,
  },
];

export const handleSortChange = (
  updateFilters: (param: SearchFilters) => void,
  filters: SearchFilters,
  sort: Sort[]
) => {
  const newSort = sort.map((item) => {
    return {
      label: item.label,
      column: item.column,
      order: item.order,
      resource: item.resource,
      id: item.id,
    };
  });

  updateFilters({
    ...filters,
    sort: newSort,
    offset: 0,
  });
};

export const handleQuickSearch = (
  debouncedUpdate: DebouncedFunc<(props: SearchFilters) => void>,
  filters: SearchFilters,
  value: string
) => {
  const quickFilters = generateQuickSearchFilters(value);
  debouncedUpdate({
    ...filters,
    searchFilter: quickFilters,
    offset: 0,
  });
};

export const handleFilterChange = (
  debouncedUpdate: DebouncedFunc<(props: SearchFilters) => void>,
  filters: SearchFilters,
  newFilters: FilterType[]
) => {
  const cleanActiveFilters = cleanFilters(newFilters);
  const valid = areFiltersValid(cleanActiveFilters);

  if (valid) {
    debouncedUpdate({
      ...filters,
      filter: cleanActiveFilters,
      offset: 0,
    });
  }
};

export const handleSortUpdate = (
  debouncedUpdate: DebouncedFunc<(props: SearchFilters) => void>,
  searchFilters: SearchFilters,
  sort: Sort[],
  keepOffset?: boolean
) => {
  // Taking the selected sort array and mapping it to the query param
  // structure setting the state variable and making the api call.
  const newSort = sort.map((item) => {
    return {
      label: item.label,
      column: item.column,
      order: item.order,
      resource: item.resource,
      id: item.id,
    };
  });

  debouncedUpdate({
    ...searchFilters,
    sort: newSort,
    offset: keepOffset ? searchFilters.offset : 0,
  });
};

export const handleInfiniteScroll = (
  updateFilters: (param: SearchFilters) => void,
  filters: SearchFilters,
  data: Array<Contact | CustomObjectRecord>,
  totalCount: number,
  loadingMore: boolean
) => {
  if (data.length < totalCount && !loadingMore) {
    updateFilters({
      ...filters,
      offset: filters.offset + filters.limit,
    });
  }
};
