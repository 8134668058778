import React from 'react';

import { Box, Flex } from '@/shared/ui';

type CampaignCardDetailProps = {
  label: string;
  value?: string;
};

const CampaignCardDetail = (props: CampaignCardDetailProps): JSX.Element => {
  const { label, value } = props;
  return (
    <Flex direction="column" css={{ minWidth: 80 }}>
      <Flex css={{ fontSize: 12, fontColor: '#7F7F86' }}>{label}</Flex>
      <Box
        css={{
          fontSize: 14,
          mt: 8,
          fontColor: '#2B2A34',
          textOverflow: 'ellipsis !important',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          width: 80,
        }}
      >
        {value}
      </Box>
    </Flex>
  );
};

export default CampaignCardDetail;
