/* eslint-disable react-hooks/exhaustive-deps */
import dayjs from 'dayjs';
import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Table } from '@/shared/components/Table';
import { PageLayout } from '@/shared/layouts/PageLayout';
import { Group } from '@/shared/types/contacts/groups';
import { Box, Button, Flex, Skeleton } from '@/shared/ui';
import { styled } from '@/stitches.config';

import { useGroups } from './context/GroupContext';

export const AllGroups = () => {
  const groups = useGroups();
  const { getGroups, searchGroups, setCurrentGroup } = groups;
  const loading = groups.groupsState.loading || false;

  const data = useMemo(
    () => (loading ? Array(5).fill({}) : groups.groupsState.groups),
    [loading, groups.groupsState.groups]
  );

  const searchedData = useMemo(
    () => (loading ? Array(5).fill({}) : groups.groupsState.searchedGroups),
    [loading, groups.groupsState.searchedGroups]
  );

  const history = useHistory();

  const handleNavigate = (group: Group) => {
    setCurrentGroup(group);
    history.push(`/contacts/groups/${group.id}`);
  };

  const loading_columns = [
    {
      Header: 'Uploads',
      colWidth: '100%',
      Cell: (
        <>
          <Skeleton variant="subheading" css={{ maxWidth: '50%', ma: 0 }} />
          <Skeleton variant="subheading" css={{ maxWidth: '80%', mb: 0 }} />
        </>
      ),
    },
    {
      Header: (): null => null,
      disableSortBy: true,
      id: 'expander',
      Cell: <Skeleton variant="icon" />,
    },
  ];

  const columns = useMemo(
    () =>
      loading
        ? loading_columns
        : [
            {
              Header: 'Groups',
              id: 'name',
              accessor: 'name',
              colWidth: '100%',
              Cell: (props: { row: { original: Group } }) => (
                <Flex align="center">
                  <GroupColor css={{ backgroundColor: props.row.original.color }} />
                  <Box>{props.row.original.name}</Box>
                </Flex>
              ),
            },
            {
              Header: 'Created At',
              id: 'created_at',
              accessor: 'created_at',
              colWidth: '100%',
              Cell: (props: { row: { original: Group } }) => (
                <Flex align="center" css={{ minWidth: 125 }}>
                  <Box>{dayjs(props.row.original.inserted_at).format('MMM D YYYY')}</Box>
                </Flex>
              ),
            },
            {
              Header: '',
              id: 'count',
              Cell: (props: { row: { original: Group } }) => (
                <Box css={{ minWidth: 120 }}>
                  <Button
                    variant="gray"
                    onClick={() => handleNavigate(props.row.original)}
                  >
                    View Group
                  </Button>
                </Box>
              ),
            },
          ],
    [loading]
  );

  const limit = 50;
  const [offset, setOffset] = useState(0);

  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    if (searchValue.length > 1) {
      searchGroups(searchValue);
    }
  }, [searchValue]);

  useEffect(() => {
    getGroups({ offset, limit });
  }, [offset]);

  return (
    <PageLayout
      breadcrumbs={[
        { title: 'Contacts', path: '/contacts' },
        { title: 'Groups', path: '/contacts/groups' },
      ]}
    >
      <Box css={{ p: 30 }}>
        <Table
          data={searchValue.length <= 1 ? data : searchedData}
          columns={columns}
          sortable={true}
          caption="Groups Table"
          searchFilter={true}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          limit={limit}
          offset={offset}
          setOffset={setOffset}
          totalCount={groups.groupsState.totalCount}
        />
      </Box>
    </PageLayout>
  );
};

const GroupColor = styled(Box, {
  height: 7,
  width: 7,
  borderRadius: '100%',
  mt: 2,
  mr: 10,
});
