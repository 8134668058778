/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { HiKey } from 'react-icons/hi';

import { ActionDescription } from '@/pages/automation/ActionDescription';
import { KeywordDescription } from '@/pages/automation/KeywordDescription';
import { ActionRenderer } from '@/shared/components/triggers';
import { KeywordTriggerType } from '@/shared/components/triggers/AddTrigger';
import { VStack } from '@/shared/ui';

import {
  getActionColor,
  getActionIcon,
  getActionTitle,
  PreviewActionWrapper,
} from './PreviewActionWrapper';

type KeywordAutomationsProps = {
  keyword_triggers: Array<KeywordTriggerType>;
};

export const KeywordAutomationsPreview = (props: KeywordAutomationsProps) => {
  const { keyword_triggers } = props;

  // render each trigger in this template
  return keyword_triggers.map((keyword_trigger: KeywordTriggerType, i: number) => (
    <KeywordTriggerWrapper key={i} keyword_trigger={keyword_trigger} />
  ));
};

export const KeywordTriggerWrapper = (props: any) => {
  const { keyword_trigger } = props;
  const keywordsObject = keyword_trigger.keyword;

  return (
    <VStack gap={2} style={{ paddingTop: '10px' }}>
      <PreviewActionWrapper
        title={'Keyword Trigger'}
        description={<KeywordDescription keywords={keywordsObject.keywords} />}
        actionColor={'#00A3FF'}
        actionIcon={<HiKey />}
      />
      {keyword_trigger.actions.map((action: any, index: number) => (
        <PreviewActionWrapper
          key={index}
          title={`${getActionTitle(action)}` || ''}
          description={ActionDescription(action)}
          actionColor={`${getActionColor(action)}` || '#2C7675'}
          actionIcon={getActionIcon(action) || ''}
          action={action}
        >
          <ActionRenderer key={index} index={index} action={action} />
        </PreviewActionWrapper>
      ))}
    </VStack>
  );
};
