import React from 'react';
import { HiTrash } from 'react-icons/hi';

import { ConfirmationDialog } from '@/shared/components/ConfirmationDialog';

import { ContactButton } from '.';

type ArchiveContactProps = {
  /** on click trigger this function */
  alertAction: () => void;
  /** is groups page */
  groups?: boolean;
  /** child button component **/
  children?: React.ReactNode;
};

export function ArchiveContact(props: ArchiveContactProps) {
  const handleDelete = () => {
    props.alertAction();
  };

  return (
    <ConfirmationDialog
      title="Delete Contact"
      description="Are you sure you want to remove this contact from your organization?"
      onConfirm={handleDelete}
      confirmButtonTitle="Yes, Delete Contact"
      testID="delete-contact-button"
    >
      {props?.children || (
        <ContactButton icon={<HiTrash size={18} />} text={'Delete Contact'} />
      )}
    </ConfirmationDialog>
  );
}
