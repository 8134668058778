import { styled } from '@stitches/react';

export const ErrorConversation = ({ errorMessage }: { errorMessage: string }) => {
  return (
    <EmptyContainer>
      <Title>There was an error with your search</Title>
      <Subtitle>{errorMessage}</Subtitle>
    </EmptyContainer>
  );
};

const EmptyContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  height: '100%',
  width: '100%',
  flexDirection: 'column',
  padding: 64,
  marginTop: 10,
});

const Title = styled('h2', {
  fontSize: 18,
  fontWeight: 500,
  marginBottom: 4,
});

const Subtitle = styled('p', {
  fontSize: 14,
  color: '$gray10',
});
