import React from 'react';

import { ContactView } from '@/contacts/editor/v2/ContactView';
import { ContactPanelHeader } from '@/pages/contacts/editor/v2/ContactPanelHeader';
import { Contact } from '@/shared/types';

type ContactPanelProps = {
  contact?: Contact;
  onClose?: () => void;
};

export const ContactPanel = ({ contact, onClose }: ContactPanelProps) => {
  return (
    <>
      <ContactPanelHeader contact={contact} onClose={onClose} isInbox />
      <ContactView isInbox={true} contactId={contact?.id} />
    </>
  );
};
