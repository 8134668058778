import React from 'react';

import { inbox_help } from '@/settings/help';
import { HelpCard } from '@/settings/help/HelpCard';
import { Flex, VStack } from '@/shared/ui';

export const HelpPanel = () => {
  return (
    <Flex css={{ p: 20 }}>
      <VStack>
        {inbox_help[0].videos.map(({ title, description, loom_id }) => (
          <HelpCard
            key={title}
            title={title}
            description={description}
            loom_id={loom_id}
          />
        ))}
      </VStack>
    </Flex>
  );
};
