import dayjs, { Dayjs } from 'dayjs';
import { debounce } from 'lodash';
import React from 'react';
import { useMemo, useState } from 'react';
import { DatePicker } from 'react-dayjs-picker';
import { HiX } from 'react-icons/hi';

import { DropdownOption, Filter } from '@/shared/types/filter';
import { Box, Flex, Input } from '@/shared/ui';
import { styled } from '@/stitches.config';

import FiltersMenuDropdown from './FiltersMenuDropdown';

type FilterDropdownOptions = {
  label: string;
  value: string;
};

type FiltersMenuSortRowProps = {
  // The filter row.
  row: Filter;
  // Callback function when you click the remove button.
  onRemove: () => void;
  // Callback function when you update the filter comparison or value.
  onUpdateFilter: (row: Filter) => void;
  // A list of options passed to the dropdown when the row type === 'dropdown'.
  filterDropdownOptions?: FilterDropdownOptions[];
};

export const DEBOUNCE_DELAY = 500;

const dateComparisonOptions = [
  {
    label: 'before',
    operator: '<=',
  },
  {
    label: 'after',
    operator: '>=',
  },
];

const textComparisonOptions = [
  {
    label: 'contains',
    operator: 'contains',
  },
  {
    label: 'does not contain',
    operator: 'not_contains',
  },
  {
    label: 'starts with',
    operator: 'start',
  },
  {
    label: 'ends with',
    operator: 'end',
  },
  {
    label: 'is',
    operator: 'exact',
  },
  {
    label: 'does not start with',
    operator: 'not_start',
  },
  {
    label: 'does not end with',
    operator: 'not_end',
  },
  {
    label: 'is not',
    operator: 'not_exact',
  },
];

/**
 * The Filters Row component which is used to manage table filters.
 * Filter Rows can be added and removed from the Filters Menu component.
 * The comparison value can be updated by selecting the relevant option from the dropdown.
 * The row type will dictate whether a text input, datepicker or dropdown is rendered for
 * selecting / updating a filter value.
 *
 * @param row - The filter row with id, label, value, order & resource values.
 * @param onRemove - A callback function which is called when you click the remove button.
 * @param onUpdateFilter - A callback function which is called when you update the filter comparison or value.
 * @param filterDropdownConfig - A list of options passed to the dropdown when the row type === 'dropdown'.
 */

const FiltersMenuFilterRow: React.FC<FiltersMenuSortRowProps> = ({
  row,
  onRemove,
  onUpdateFilter,
  filterDropdownOptions,
}) => {
  const [inputValue, setInputValue] = useState(row.value || '');
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

  const handleDateSelect = (date: Dayjs) => {
    setIsDatePickerOpen(false);
    onUpdateFilter({
      ...row,
      value: date,
    });
  };

  const comparisonOptions =
    row.type === 'date' ? dateComparisonOptions : textComparisonOptions;

  // Dictating the comparison dropdown options based on the type of filter row.
  const getModifiedOptions = () => {
    if (row.type === 'text' && row.column === 'name') {
      return comparisonOptions;
    } else if (row.type === 'dropdown') {
      return [textComparisonOptions[textComparisonOptions.length - 1]];
    } else {
      return comparisonOptions;
    }
  };

  const mapCurrentComparison = (row: Filter) => {
    return comparisonOptions.find((option) => {
      return option.operator === row.operator;
    });
  };

  const mapCurrentValue = (row: Filter) => {
    if (row.value === null || !filterDropdownOptions) return null;

    return filterDropdownOptions.find((option) => {
      return option.value === row.value;
    });
  };

  const handleFilterSelection = (option: DropdownOption) => {
    onUpdateFilter({
      ...row,
      operator: option.operator ? option.operator : null,
    });
  };

  const debouncedUpdate = useMemo(
    () =>
      debounce((value) => {
        onUpdateFilter({
          ...row,
          value: value,
        });
      }, DEBOUNCE_DELAY),
    [onUpdateFilter, row]
  );

  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setInputValue(value);
    debouncedUpdate(value);
  };

  const handleDropdownValueSelection = (option: DropdownOption) => {
    onUpdateFilter({
      ...row,
      value: option.value ? option.value : null,
    });
  };

  return (
    <FilterRowContainer
      justify="between"
      align="center"
      data-testid="filters-menu-filter-row"
    >
      <FilterRowDetails align="center">
        <Box
          css={{
            minWidth: 128,
            p: '6px 14px',
            textWrap: 'nowrap',
            overflow: 'hidden',
            ...FilterBox,
          }}
        >
          {row.label}
        </Box>
        <Flex
          align="center"
          justify="end"
          css={{
            minWidth: 'auto',
            height: 35,
            ...FilterBox,
            width: 'auto', // Ensure width can grow
            maxWidth: '100%',
          }}
        >
          <FiltersMenuDropdown
            options={getModifiedOptions()}
            selected={mapCurrentComparison(row) || comparisonOptions[0]}
            onSelect={handleFilterSelection}
          />
        </Flex>
        {row.type === 'date' ? (
          <DateBox css={{ width: 190 }} data-testid="filters-menu-row-datepicker">
            <DatePicker
              className="filter-date-picker"
              isOpen={isDatePickerOpen}
              setIsOpen={setIsDatePickerOpen}
              popoverPositions={['bottom']}
              closeOnSelect={true}
              format="MMM Do YYYY"
              date={dayjs(row.value)}
              onSelect={handleDateSelect}
              inputStyle={{
                padding: '7px 14px',
                caretColor: 'transparent',
                outline: 'none',
                cursor: 'pointer',
                borderRadius: 4,
              }}
              colors={{
                active: '#0b68cb',
                default: '',
                highlighted: '',
                disabled: '',
              }}
            />
          </DateBox>
        ) : row.type === 'text' ? (
          <Box css={{ width: 190 }}>
            <Input
              data-testid="filters-menu-row-input"
              id="filter-row-input"
              type="text"
              placeholder={`Search by ${row.label}`}
              value={inputValue.toString()}
              onChange={handleValueChange}
            />
          </Box>
        ) : (
          <Flex
            align="center"
            css={{
              width: 190,
              height: 35,
              ...FilterBox,
            }}
            data-testid="filters-menu-row-dropdown"
          >
            <FiltersMenuDropdown
              options={filterDropdownOptions ?? []}
              selected={mapCurrentValue(row)}
              onSelect={handleDropdownValueSelection}
            />
          </Flex>
        )}
      </FilterRowDetails>
      <RemoveButton onClick={onRemove} data-testid="filters-menu-remove-filter-button">
        <HiX size={16} />
      </RemoveButton>
    </FilterRowContainer>
  );
};

export default FiltersMenuFilterRow;

const FilterRowContainer = styled(Flex, {
  marginBottom: '$space$2',
});

const FilterRowDetails = styled(Flex, {
  width: '100%',
});

const FilterBox = {
  mr: 5,
  border: '1px solid $slate7',
  borderRadius: 4,
};

const DateBox = styled(Box, {
  cursor: 'pointer',
  ...FilterBox,
  '&:hover': {
    borderColor: '$slate4',
  },
});

const RemoveButton = styled(Box, {
  cursor: 'pointer',
  borderRadius: '4px',
  padding: '$space$2',
  ml: '$space$1',
  '&:hover': {
    backgroundColor: '$slate3',
  },
});
