import React from 'react';
import { useHistory } from 'react-router-dom';
import { useMedia } from 'react-use';
import { toast } from 'sonner';

import { duplicateSequenceTemplate } from '@/shared/api/sequences/templates';
import { Sequence, SequenceStatus } from '@/shared/types/sequences';
import { Box, Button, Flex, HStack, Skeleton } from '@/shared/ui';
import i18next from '@/shared/utils/translation';
import { styled } from '@/stitches.config';

import { useSequences } from '../context/SequenceContext';
import SequenceActionButton from './SequenceActionButton';

export const SequenceCard = (props: { sequence: Sequence }) => {
  const { sequence } = props;

  const isMobile = useMedia('(max-width: 700px)');
  const sequenceContext = useSequences();
  const { setCurrentSequence, deleteSequence, duplicateSequence } = sequenceContext;

  const history = useHistory();

  const onEditClick = () => {
    // set the current sequence in the context
    setCurrentSequence(sequence);
    // navigate to the sequence steps page
    history.push(`/sequences/${sequence?.id}/steps`);
  };

  const onDelete = () => {
    deleteSequence({
      ...sequence,
      status: SequenceStatus.DELETED,
    });
  };

  const onDuplicateSequenceTemplate = async () => {
    try {
      if (sequence.id) {
        await duplicateSequenceTemplate(sequence.id);
        toast.success(i18next.t('sequence_duplicated_success') as string);
      }
    } catch (err) {
      toast.error(i18next.t('sequence_duplicated_failure') as string);
      console.error(err);
    }
  };

  const onDuplicateSequence = async () => {
    if (sequence.id) {
      await duplicateSequence(sequence.id);
    }
  };

  return (
    <SequenceCardContainer align="center" justify="between">
      <SequenceTitleContainer
        align="center"
        css={{ width: isMobile ? '100%' : 'calc(100% - 185px)' }}
      >
        <Flex
          align="center"
          onClick={onEditClick}
          css={{ width: '100%', height: '100%' }}
        >
          <Box css={{ width: '100%' }}>
            <SequenceTitle>{sequence.title}</SequenceTitle>
            <SequenceSteps>
              <>
                {(sequence && sequence.created_by && sequence.created_by.name) ||
                  sequence.created_by}{' '}
                {sequence.created_by && '- '}
                {sequence && sequence.steps && sequence.steps.length} Steps
              </>
            </SequenceSteps>
          </Box>
        </Flex>
      </SequenceTitleContainer>
      {!isMobile && (
        <Flex justify="end" css={{ width: '100%', height: '100%' }}>
          <Flex css={{ pl: 20 }}>
            <HStack gap="2">
              <SequenceActionButton
                onDelete={onDelete}
                onDuplicateSequence={onDuplicateSequence}
                onDuplicateSequenceTemplate={onDuplicateSequenceTemplate}
              />
              <Button
                variant="gray"
                data-testid={`sequence-${sequence?.id}-button`}
                onClick={onEditClick}
              >
                Edit Sequence
              </Button>
            </HStack>
          </Flex>
        </Flex>
      )}
    </SequenceCardContainer>
  );
};

type SequenceAnalyticsCardProps = {
  /* The value of the card */
  value: string;
  /* The type of the card */
  type: string;
};

export const SequenceAnalyticsCard = (props: SequenceAnalyticsCardProps) => {
  const { value, type } = props;
  return (
    <Box css={{ px: 10, width: 65 }}>
      <BoldStats>{value}</BoldStats>
      <StatsDescription>{type}</StatsDescription>
    </Box>
  );
};

export const SequenceLoadingCard: React.FC = () => {
  return (
    <SequenceCardContainer align="center" justify="between">
      <Flex align="center">
        <Box>
          <Skeleton variant="title" css={{ height: 15, margin: '5px 0', width: 200 }} />
        </Box>
      </Flex>
      <Flex>
        <StatsWrapper gap={4}>
          <Box>
            <BoldStats>
              <Skeleton css={{ height: 25, width: 25, m: '5px 0 0 0' }} />
            </BoldStats>
          </Box>
          <Box>
            <BoldStats>
              <Skeleton css={{ height: 25, width: 25, m: '5px 0 0 0' }} />
            </BoldStats>
          </Box>
          <Box>
            <BoldStats>
              <Skeleton css={{ height: 25, width: 25, m: '5px 0 0 0' }} />
            </BoldStats>
          </Box>
        </StatsWrapper>
        <Flex css={{ pl: 20 }}>
          <HStack>
            <Skeleton variant="avatar" css={{ height: '$5', width: '$5' }} />
          </HStack>
          <Skeleton variant="button" css={{ height: 40, width: 40, ml: 20 }} />
        </Flex>
      </Flex>
    </SequenceCardContainer>
  );
};

// Sequence Card Styled Components
const SequenceCardContainer = styled(Flex, {
  px: '24px',
  backgroundColor: '#FFFFFF',
  height: 74,
  border: '1px solid #E8E8E8',
  borderRadius: 12,
  position: 'relative',
  '&:hover': {
    border: '1px solid #3E54CF',
    filter: 'drop-shadow(0px 4px 32px rgba(62, 84, 207, 0.08))',
    cursor: 'pointer',
  },
});

const SequenceTitleContainer = styled(Flex, {
  minWidth: '400px',
  height: '100%',
});

const SequenceTitle = styled(Box, {
  fontSize: 16,
  fontWeight: 500,
  lineHeight: '19px',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  pb: 5,
});

const SequenceSteps = styled(Box, {
  color: '#7F7F86',
  fontSize: 14,
  lineHeight: '17px',
});

const StatsWrapper = styled(HStack, {
  ml: 50,
  pr: 20,
  borderRight: '1px solid #E8E8E8',
});

const BoldStats = styled(Box, {
  fontSize: 16,
  fontWeight: 600,
  lineHeight: '19px',
  pb: 5,
});

const StatsDescription = styled(Box, {
  color: '#7F7F86',
  fontSize: 12,
  lineHeight: '15px',
  fontWeight: 500,
});
