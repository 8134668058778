import React from 'react';

import { Box, Divider } from '@/shared/ui/index';

import NotificationPreferenceItemRow from './NotificationPreferenceItemRow';

type NotificationPreferenceItemProps = {
  /* key for the NotificationPreferenceItemRow to be unique*/
  key: string;
  /* name of the sub group */
  title: string;
  /* sub group items */
  items: string[];
  /* on press of the checkbox */
  onToggle: (item: string) => void;
  /* check if the notification type is already subscribed */
  isChecked: (type: string) => boolean;
};

const NotificationPreferenceItem = (props: NotificationPreferenceItemProps) => {
  const { title, key, items, onToggle, isChecked } = props;

  return (
    <Box key={key}>
      <Box
        css={{
          fontSize: 16,
          fontWeight: 600,
          width: '100%',
          marginBottom: 5,
        }}
      >
        {title}
      </Box>
      <Divider css={{ mb: 15 }} />
      {items.map((item) => (
        <NotificationPreferenceItemRow
          checked={isChecked(item)}
          onToggle={() => onToggle(item)}
          item={item}
          key={`${key}-${item}`}
        />
      ))}
    </Box>
  );
};

export default NotificationPreferenceItem;
