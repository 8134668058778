/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useEffect, useState } from 'react';
import { HiStar } from 'react-icons/hi';
import { useParams } from 'react-router-dom';

import { useChannels } from '@/pages/settings/organization/channels/context/ChannelContext';
import { PageLayout } from '@/shared/layouts/PageLayout';
import { Review } from '@/shared/types';
import { Center, EmptyState, Flex, Skeleton } from '@/shared/ui';
import { isValidUuid } from '@/shared/utils/validations/validations';

import {
  EmptyStateDescription,
  getReviewRatingNumber,
  ReviewConsentField,
  ReviewSpacer,
} from './AllReviews';
import { useReviews } from './context/ReviewContext';
import { ReviewCard, ReviewContainer } from './ReviewCard';

export const LocationReviews = () => {
  const { channelsState } = useChannels();

  const reviewContext = useReviews();
  const { getReviews, reviewState, getConsentUrl } = reviewContext;
  const { reviews, loading, consentUrl } = reviewState;

  const [reviewsList, setReviewsList] = useState(reviews);

  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    if (channelsState.channels.length > 0 && isValidUuid(id)) {
      getReviews([id]);
    }
  }, [channelsState.channels, id]);

  useEffect(() => {
    getConsentUrl();
  }, []);

  // reviews with no response
  const reviewsUnresponded = reviews.slice();
  const filterUnresponded = reviewsUnresponded.filter(
    (review: Review) => !review.reviewReply
  );

  // sort lowest rating
  const reviewsLowest = reviews.slice();
  const sortLowestRating = reviewsLowest.sort(
    (a: { starRating: any }, b: { starRating: any }) =>
      b.starRating.localeCompare(a.starRating)
  );

  // sort highest rating
  const reviewsHigh = reviews.slice();
  const sortHighestRating = reviewsHigh.sort(
    (a: { starRating: any }, b: { starRating: any }) =>
      a.starRating.localeCompare(b.starRating)
  );

  const getSortedReviews = (filter: string) => {
    const reviewsList: Array<any> = reviews;
    switch (filter) {
      case 'all':
        setReviewsList(reviews);
        break;
      case 'unresponded':
        setReviewsList(filterUnresponded);
        break;
      case 'lowest':
        setReviewsList(sortLowestRating);
        break;
      case 'highest':
        setReviewsList(sortHighestRating);
        break;
      default:
        break;
    }
    return reviewsList;
  };

  useEffect(() => {
    getSortedReviews(reviewState.filter);
  }, [reviewState.filter, reviewState.reviews]);

  return (
    <PageLayout breadcrumbs={[{ title: 'Reviews', path: '/reviews' }]}>
      {/* List Reviews */}
      {!loading ? (
        reviews.length > 0 ? (
          reviewsList?.map((review: Review, index: number) => {
            return (
              <ReviewSpacer key={`review-${index}`}>
                <ReviewCard
                  review={review}
                  index={index}
                  stars={getReviewRatingNumber(review.starRating)}
                  showDate={true}
                />
              </ReviewSpacer>
            );
          })
        ) : (
          //  Empty State
          <Center>
            <EmptyState
              icon={<HiStar size="30px" />}
              description={<EmptyStateDescription />}
              title={'Reputation Management'}
              buttonComponent={<ReviewConsentField consentUrl={consentUrl} />}
            />
          </Center>
        )
      ) : (
        // Loading State
        Array.from({ length: 4 }, (_, k) => (
          <ReviewSpacer key={k}>
            <ReviewContainer>
              <Flex direction="column">
                <Flex align="center">
                  <Skeleton variant="avatar" css={{ mr: '$3' }} />
                  <Skeleton variant="heading" css={{ width: '50%' }} />
                </Flex>
                <Skeleton variant="title" />
                <Skeleton variant="heading" />
              </Flex>
            </ReviewContainer>
          </ReviewSpacer>
        ))
      )}
    </PageLayout>
  );
};
