/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';

import { usePageView } from '@/shared/hooks';
import { CampaignStatus } from '@/shared/types/campaigns';

import { useCampaignsContext } from './context/CampaignsContext';
import { CampaignsList } from './list';

export const ArchiveCampaign = (): JSX.Element => {
  // Change analytics page
  usePageView();

  const {
    campaignsState: { archivedCampaigns, loading },
  } = useCampaignsContext();

  return (
    <CampaignsList
      title="Archived"
      url="archived"
      campaigns={archivedCampaigns}
      loading={loading}
      status={CampaignStatus.ARCHIVED}
    />
  );
};
