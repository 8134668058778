import React, { useEffect } from 'react';
import { createContext, useContext, useReducer } from 'react';
import { toast } from 'sonner';

import * as API from '@/shared/api/contacts/uploads';
import { useDisclosure } from '@/shared/hooks';
import { Contact } from '@/shared/types';
import { SearchFilters } from '@/shared/types/contacts';
import {
  ContactParams,
  UploadsActionTypes,
  UploadsType,
} from '@/shared/types/contacts/uploads';
import { Tag } from '@/shared/types/tags';
import { get, set } from '@/shared/utils/storage';
import i18next from '@/shared/utils/translation';

import UploadsReducer from './UploadReducer';

export const ITEMS_COUNT = 10;

export type UploadsState = {
  uploads: Array<Tag>;
  allUploads: Array<Tag>;
  recentUploads: Array<Tag>;
  searchedUploads: Array<Tag>;
  totalCount: number;
  loading: boolean;
  current: Tag | null;
  error: Error | null;
  filterParams: SearchFilters;
};

export const initialUploadsState: UploadsState = {
  uploads: [],
  allUploads: [],
  recentUploads: [],
  searchedUploads: [],
  totalCount: 0,
  current: null,
  error: null,
  loading: true,
  filterParams: {
    offset: 0,
    limit: ITEMS_COUNT,
    sort: [],
    filter: [],
    searchFilter: [],
    defaultFilters: [
      {
        column: 'type',
        comparison: '==',
        resource: 'list',
        value: 'upload',
      },
      {
        column: 'state',
        comparison: '!=',
        resource: 'list',
        value: 'deleted',
      },
    ],
  },
};

type PhoneObject = {
  /** array of phone numbers to upload contacts */
  phone: string;
};

export const UploadsContext = createContext<{
  uploadsState: UploadsState;
  getUploads: (
    params?: {
      offset: number;
      limit: number;
    },
    updateRecentUploads?: boolean
  ) => Promise<UploadsType | Array<Tag> | null>;
  getUpload: (id: string) => Promise<Tag | null>;
  searchUploads: (name: string) => Promise<Array<Tag> | Array<null>>;
  updateUpload: (upload: Partial<Tag>) => Promise<Tag>;
  deleteUpload: (uploadId: string) => void;
  setCurrentUpload: (upload: Tag | null) => void;
  largeUploadContacts: (
    contacts: ContactParams[] | PhoneObject[]
  ) => void | Contact | any;
  smallUploadContacts: (contacts: ContactParams[] | PhoneObject[]) => void | Tag | any;
  bulkContactsOptInImport: (
    contacts: Array<ContactParams>,
    target?: 'opt_in' | 'bulk_opt_in'
  ) => void | Tag | any;
  bulkContactsOptOutImport: (contacts: Array<ContactParams>) => void;
  uploadName: string;
  uploadColor: string;
  uploadLocations: Array<string>;
  setUploadName: (name: string) => void;
  setUploadColor: (color: string) => void;
  setUploadLocations: (locations: Array<string>) => void;
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  getUploadsV2: (params: SearchFilters) => Promise<void>;
  updateFilterParams: (params: SearchFilters) => void;
}>({
  uploadsState: initialUploadsState,
  getUploads: () => Promise.resolve({} as UploadsType),
  getUpload: () => Promise.resolve({} as Tag),
  searchUploads: () => Promise.resolve([]),
  updateUpload: () => Promise.resolve({} as Tag),
  deleteUpload: () => Promise.resolve(),
  setCurrentUpload: () => Promise.resolve(),
  largeUploadContacts: () => Promise.resolve(),
  smallUploadContacts: () => Promise.resolve(),
  bulkContactsOptInImport: () => Promise.resolve(),
  bulkContactsOptOutImport: () => Promise.resolve(),
  uploadName: '',
  uploadColor: '',
  uploadLocations: [],
  setUploadName: () => Promise.resolve(),
  setUploadColor: () => Promise.resolve(),
  setUploadLocations: () => Promise.resolve(),
  isOpen: false,
  onOpen: () => Promise.resolve(),
  onClose: () => Promise.resolve(),
  getUploadsV2: () => Promise.resolve(),
  updateFilterParams: () => Promise.resolve(),
});

export const useUploads = () => useContext(UploadsContext);

const UploadState = ({ children }: { children: React.ReactNode }) => {
  const [uploadsState, dispatch] = useReducer(UploadsReducer, initialUploadsState);
  const [uploadName, setName] = React.useState('');
  const [uploadColor, setColor] = React.useState('');
  const [uploadLocations, setLocations] = React.useState<Array<string>>([]);
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    getUploadsV2(uploadsState.filterParams);
  }, [uploadsState.filterParams]);

  useEffect(() => {
    getALLUploads();
    getUploads({ offset: 0, limit: 10 }, true);
  }, []);

  const getUploads = async (
    params?: { offset: number; limit: number },
    updateRecentUploads?: boolean
  ) => {
    try {
      const data = await API.getUploads(params);
      dispatch({
        type: UploadsActionTypes.GET_UPLOADS,
        payload: data,
      });

      if (updateRecentUploads) {
        dispatch({
          type: UploadsActionTypes.GET_RECENT_UPLOADS,
          payload: data,
        });
      }

      return data;
    } catch (err) {
      console.error(err);
      return Promise.reject(err);
    }
  };

  const getALLUploads = async () => {
    try {
      const data = await API.getUploads();
      dispatch({
        type: UploadsActionTypes.GET_ALL_UPLOADS,
        payload: data,
      });
    } catch (err) {
      console.error(err);
      return Promise.reject(err);
    }
  };

  const getUploadsV2 = async (params: SearchFilters) => {
    try {
      const filter = params.defaultFilters
        ? [...params.defaultFilters, ...params.filter]
        : params.filter;
      const data = await API.getUploadsV2({
        filter: [...filter, ...params.searchFilter],
        sort: params.sort,
        limit: ITEMS_COUNT,
        offset: params.offset || 0,
      });
      dispatch({
        type: UploadsActionTypes.GET_UPLOADS_V2,
        payload: data,
      });
    } catch (err) {
      console.error(err);
    }
  };

  const updateFilterParams = async (params: SearchFilters) => {
    try {
      dispatch({
        type: UploadsActionTypes.UPDATE_FILTER_PARAMS,
        payload: params,
      });
    } catch (err) {
      console.error(err);
    }
  };

  const getUpload = async (id: string) => {
    try {
      const data = await API.getUpload(id);

      dispatch({
        type: UploadsActionTypes.GET_UPLOAD,
        payload: data,
      });

      return data;
    } catch (err) {
      console.error(err);
      return Promise.reject(err);
    }
  };

  const searchUploads = async (name: string) => {
    try {
      const data = await API.searchUpload(name);
      dispatch({
        type: UploadsActionTypes.SEARCH_UPLOAD,
        payload: data,
      });

      return data;
    } catch (err) {
      console.error(err);
      return Promise.reject(err);
    }
  };

  const updateUpload = async (upload: Partial<Tag>) => {
    try {
      const data = await API.updateUpload(upload);

      dispatch({
        type: UploadsActionTypes.EDIT_UPLOAD,
        payload: data,
      });

      toast.success(i18next.t('upload_updated_success') as string);
      return data;
    } catch (err) {
      toast.error(i18next.t('upload_updated_failure') as string);
      console.error(err);
      return Promise.reject(err);
    }
  };

  const deleteUpload = async (uploadId: string) => {
    try {
      await API.deleteUpload(uploadId);

      dispatch({
        type: UploadsActionTypes.DELETE_UPLOAD,
        payload: uploadId,
      });

      toast.success(i18next.t('upload_deleted_success') as string);
      getUploadsV2(uploadsState.filterParams);
    } catch (err) {
      console.error(err);
      toast.error(i18next.t('upload_deleted_failure') as string);
    }
  };

  const largeUploadContacts = async (contacts: ContactParams[]) => {
    try {
      const data = await API.importContacts(
        get('uploadName'),
        get('uploadColor'),
        contacts,
        get('uploadLocations')
      );

      dispatch({
        type: UploadsActionTypes.UPLOAD_CONTACTS,
        payload: data,
      });

      toast.success(i18next.t('contacts_import_success') as string);

      clearCreateUpload();

      return data;
    } catch (error) {
      console.error(error);
      if (error?.response?.data?.error?.message) {
        toast.error(error.response.data.error.message);
      }
      clearCreateUpload();
    }
  };

  const smallUploadContacts = async (contacts: ContactParams[]) => {
    try {
      const data = await API.smallImportContacts(
        get('uploadName'),
        get('uploadColor'),
        contacts,
        get('uploadLocations')
      );

      dispatch({
        type: UploadsActionTypes.UPLOAD_CONTACTS,
        payload: data,
      });

      toast.success(i18next.t('contacts_import_success') as string);

      clearCreateUpload();

      return data;
    } catch (error) {
      console.error(error);
      toast.error(error.response.data.error.message);
      clearCreateUpload();
    }
  };

  const bulkContactsOptInImport = async (
    contacts: Array<ContactParams>,
    target?: 'opt_in' | 'bulk_opt_in'
  ) => {
    try {
      const data = await API.bulkOptInImport(
        get('uploadName'),
        get('uploadColor'),
        contacts,
        get('uploadLocations'),
        target || 'opt_in'
      );

      dispatch({
        type: UploadsActionTypes.UPLOAD_CONTACTS,
        payload: data,
      });

      toast.success(i18next.t('contacts_bulk_opt_in_import_success') as string);
      clearCreateUpload();

      return data;
    } catch (error) {
      console.error(error);
      toast.error(error.response.data.error.message);
      clearCreateUpload();
    }
  };

  const bulkContactsOptOutImport = async (contacts: Array<ContactParams>) => {
    try {
      await API.bulkOptOutImport(contacts);
      toast.success(i18next.t('contacts_bulk_opt_out_import_success') as string);
    } catch (err) {
      console.error(err);
      toast.error(i18next.t('contacts_bulk_opt_out_failure') as string);
    }
  };

  const clearCreateUpload = () => {
    setUploadName('');
    setUploadColor('');
    setUploadLocations([]);
    onClose();
  };

  const setCurrentUpload = (upload: Tag | null) => {
    try {
      dispatch({
        type: UploadsActionTypes.SET_CURRENT,
        payload: upload,
      });
    } catch (err) {
      console.error(err);
    }
  };

  // This is basically a hack because Dromo
  // forces use to lose state when we upload

  const setUploadName = (name: string) => {
    setName(name);
    set(`uploadName`, name);
  };

  const setUploadColor = (color: string) => {
    setColor(color);
    set(`uploadColor`, color);
  };

  const setUploadLocations = (locations: Array<string>) => {
    setLocations(locations);
    set(`uploadLocations`, locations);
  };

  return (
    <UploadsContext.Provider
      value={{
        uploadsState,
        getUploads,
        getUpload,
        searchUploads,
        updateUpload,
        deleteUpload,
        setCurrentUpload,
        largeUploadContacts,
        smallUploadContacts,
        bulkContactsOptOutImport,
        bulkContactsOptInImport,
        uploadName,
        uploadColor,
        uploadLocations,
        setUploadName,
        setUploadColor,
        setUploadLocations,
        isOpen,
        onOpen,
        onClose,
        getUploadsV2,
        updateFilterParams,
      }}
    >
      {children}
    </UploadsContext.Provider>
  );
};

export default UploadState;
