import React from 'react';
import { Virtuoso } from 'react-virtuoso';

import NotificationsCard from '@/shared/components/notifications/NotificationsCard';
import { UserWebNotification } from '@/shared/types/notifications';
import { Box, Flex, Skeleton } from '@/shared/ui';
import { styled } from '@/stitches.config';

type NotificationsListProps = {
  notifications: UserWebNotification[];
  onEndReached: () => void;
  emptyMessage: string;
  isLoading?: boolean;
};

const NotificationsList: React.FC<NotificationsListProps> = ({
  notifications,
  onEndReached,
  emptyMessage,
  isLoading,
}) => {
  if (notifications.length === 0 && isLoading) {
    return Array.from({ length: 20 }, (key: React.Key) => (
      <Flex
        direction="column"
        justify="between"
        key={key}
        css={{
          height: 125,
          p: '25px 25px',
          borderBottom: '1px solid #EDEDED',
        }}
      >
        <Box>
          <Skeleton css={{ width: '60%', minHeight: '15px', m: 0 }} />
        </Box>
        <Box>
          <Skeleton css={{ width: '90%', minHeight: '15px', m: 0 }} />
        </Box>
      </Flex>
    ));
  }

  if (notifications.length === 0 && !isLoading) {
    return <EmptyNotificationsList>{emptyMessage}</EmptyNotificationsList>;
  }

  return (
    <Virtuoso
      data={notifications}
      atBottomStateChange={(isAtBottom) => {
        if (isAtBottom) {
          onEndReached();
        }
      }}
      itemContent={(index, notification) => (
        <NotificationsCard notification={notification} />
      )}
    />
  );
};

export default NotificationsList;

const EmptyNotificationsList = styled('div', {
  p: '15px 25px',
  fontSize: 14,
});
